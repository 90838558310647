import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Modal, useModalControl } from '@frontend/design-system';
import { FreeTrialActionButton } from '@frontend/free-trials';

import { FreeTrialsTrackingIds } from '../../tracking-ids';
import { MultipleTrials } from '../../components/multiple-trials';
import { useTranslation } from '@frontend/i18n';
import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { formatDateInMonthAndDate } from '@frontend/date';

export const FreeTrialButton = () => {
  const { t } = useTranslation('multipleTrialsModal');
  const { modalProps: multipleTrialsModalProps, triggerProps: multipleTrialsTriggerProps } = useModalControl();

  const { flags } = useCustomizationFlagShallowStore('flags');

  const freeTrialCustomizationFlagDetails = Object.values(flags).find(
    (flag) => flag?.isFreeTrial && flag?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE
  );

  const trialEndDate = freeTrialCustomizationFlagDetails?.endDate
    ? formatDateInMonthAndDate(new Date(freeTrialCustomizationFlagDetails?.endDate))
    : '';

  if (!freeTrialCustomizationFlagDetails || !trialEndDate) return null;

  return (
    <>
      <div css={freeTrialButtonContainer}>
        <FreeTrialActionButton
          trackingId={FreeTrialsTrackingIds.freeTrialButton}
          onActionClick={multipleTrialsTriggerProps.onClick}
          color={theme.colors.white}
        />
      </div>
      <Modal {...multipleTrialsModalProps} maxWidth={600}>
        <Modal.Header css={{ margin: theme.spacing(0, 3) }} onClose={multipleTrialsModalProps.onClose}>
          {t('Active Free Trials')}
        </Modal.Header>
        <Modal.Body>
          <MultipleTrials onModalClose={multipleTrialsModalProps.onClose} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const freeTrialButtonContainer = css({ padding: theme.spacing(2), display: 'flex', justifyContent: 'center' });
