import { ComponentType, Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { Page } from '@frontend/components';
import { SelectedLocationProvider, useMerchant, useMerchantsInfo, useMultiQueryUtils } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { BreadcrumbPathProps, ContentLoader } from '@frontend/design-system';
import { useCanDoAction } from '../../hooks';
import { AppVariantProvider, PaymentsPageVariant } from '../../providers/';
import { MerchantAlertBanner } from '../merchant-status';
import { MerchantError } from '../MerchantError';
import { NoInvoices } from '../NoInvoices';
import { PaymentError } from '../PaymentError';
import { PageTitleWithLocationChip } from '../Shared';

interface CommonProps {
  title?: string;
  subtitle?: ReactNode;
  action?: ReactNode;
  maxWidth?: number;
  css?: SerializedStyles | SerializedStyles[];
  error?: Error | null;
  useFirstActiveLocation?: boolean;
  showLocationChip?: boolean;
  showLocationCount?: boolean;
  variant?: PaymentsPageVariant;
  breadcrumbs?: BreadcrumbPathProps[];
}
interface PaymentsPagePropsWithoutPrint extends CommonProps {
  printDialogue?: undefined;
  showPrint?: undefined;
  setShowPrint?: undefined;
}
interface PaymentsPagePropsWithPrint extends CommonProps {
  printDialogue: ComponentType<React.PropsWithChildren<{ onClose: () => void }>>;
  showPrint: boolean;
  setShowPrint: Dispatch<SetStateAction<boolean>>;
}
type PaymentsPageProps = PaymentsPagePropsWithoutPrint | PaymentsPagePropsWithPrint;

const styles = {
  pageTitleSection: css`
    & .title {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing(2, 2)};
    }
  `,
};

export const PaymentsPage: FC<React.PropsWithChildren<PaymentsPageProps>> = ({
  title,
  subtitle,
  action,
  children,
  printDialogue,
  showPrint,
  setShowPrint,
  maxWidth,
  error,
  showLocationChip = false,
  useFirstActiveLocation = false,
  showLocationCount = false,
  variant = 'portal',
  ...rest
}) => {
  const PrintDialogue = printDialogue;
  const { firstActiveLocationId, stripeCreatedNotOnboarded, paymentsUrl, activeProcessor } = useMerchant();
  const { hasMerchantsError, allMerchantsLoaded, anyLocationHasPayments } = useMerchantsInfo();
  const { isPaymentsBillingManager } = useCanDoAction();
  const { locationId } = useMultiQueryUtils();

  if (!allMerchantsLoaded) {
    return <ContentLoader show={true} />;
  }
  return error || !paymentsUrl ? (
    <PaymentError />
  ) : !anyLocationHasPayments ? (
    <NoInvoices
      isPaymentsBillingManager={isPaymentsBillingManager}
      locationId={locationId}
      paymentsUrl={paymentsUrl}
      stripeId={activeProcessor?.stripeId}
      stripeCreatedNotOnboarded={stripeCreatedNotOnboarded}
    />
  ) : (
    <SelectedLocationProvider locationId={firstActiveLocationId!} bypassContext={!useFirstActiveLocation}>
      <AppVariantProvider variant={variant}>
        <Page
          title={
            showLocationChip ? (
              <PageTitleWithLocationChip title={title} showLocationCount={showLocationCount} hasMargin={true} />
            ) : (
              title
            )
          }
          subtitle={!hasMerchantsError && anyLocationHasPayments ? subtitle : ''}
          action={action}
          css={styles.pageTitleSection}
          maxWidth={maxWidth ?? 1420}
          {...rest}
        >
          {hasMerchantsError ? (
            <MerchantError />
          ) : (
            <>
              <MerchantAlertBanner />
              {children}
              {showPrint && PrintDialogue && <PrintDialogue onClose={() => setShowPrint?.(false)} />}
            </>
          )}
        </Page>
      </AppVariantProvider>
    </SelectedLocationProvider>
  );
};
