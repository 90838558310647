import { css } from '@emotion/react';

type GlobalSearchContentProps = {
  children: React.ReactNode;
};

export const GlobalSearchContent = ({ children }: GlobalSearchContentProps) => {
  return (
    <section
      css={css`
        overflow-y: auto;
        flex: 1;
      `}
    >
      {children}
    </section>
  );
};
