import { css } from '@emotion/react';
import { FormsSyncApp } from '@frontend/api';
import { theme } from '@frontend/theme';

const lineMargin = css`
  margin: ${theme.spacing(0.5, 0)};
`;

export const syncAppNameStyle = [
  lineMargin,
  css`
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 20px;
  `,
];

const syncAppHealthIconStyle = css`
  margin-left: ${theme.spacing(1)};
`;

interface SyncAppStatus {
  health: FormsSyncApp.Types.SyncAppHealthStatus;
  showModal?: boolean;
}

export function getSyncAppHealthIconStyle({ health }: SyncAppStatus) {
  const styles = [syncAppHealthIconStyle];
  let color: string = theme.colors.status.success;

  switch (health) {
    case 'Unhealthy':
      color = theme.colors.status.critical;
      break;

    case 'Dead':
      color = theme.colors.neutral30;
      break;
  }

  styles.push(
    css`
      color: ${color};
    `
  );

  return styles;
}

export function getPMSTypeStyle({ health, showModal }: SyncAppStatus) {
  const styles = [
    lineMargin,
    css`
      font-size: ${theme.fontSize(14)};
      line-height: 20px;
    `,
  ];

  switch (health) {
    case 'Healthy':
      styles.push(css`
        color: ${theme.colors.status.successPressed};
      `);
      break;

    case 'Unhealthy':
      styles.push(css`
        color: ${theme.colors.status.critical};

        span {
          cursor: ${showModal ? 'pointer' : 'default'};
        }
      `);
      break;

    case 'Dead':
      styles.push(css`
        color: ${theme.colors.neutral30};

        span {
          cursor: ${showModal ? 'pointer' : 'default'};
        }
      `);
      break;
  }

  return styles;
}

export const lastSyncedStyle = [
  lineMargin,
  css`
    font-size: ${theme.fontSize(14)};
    color: ${theme.colors.text.subdued};
    display: inline-flex;
    align-items: center;
    margin: 0;
    line-height: 24px;
  `,
];

export const lastSyncTimeStyle = css`
  font-weight: 600;
  line-height: 20px;
  position: relative;
  top: 1px;
`;

const modalPadding = css`
  padding: ${theme.spacing(0, 4.5)} !important;
`;

export const modalStyle = css`
  min-width: 550px;
`;

export const modalHeaderStyle = [
  modalPadding,
  css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
];

export const modalBodyStyle = modalPadding;

export const modalFooterStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${theme.colors.neutral20};
  padding-top: ${theme.spacing(3)};
`;
