import { css } from '@emotion/react';
import { SubscriptionStatusForSelfUpgrade } from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { FreeTrialsQueries } from '@frontend/api-free-trials';
import { VideoFrame } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';
import { ManageSubscriptionAccessWarning } from '../components';
import { CallIntelSubscribeCard } from '../components/call-intel-self-signup';

export const CallIntelSignUp = () => {
  const { t } = useTranslation('analytics');
  const { flags } = useCustomizationFlagShallowStore('flags');
  const isCallIntelEnabled = flags.callintelligence?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;

  const { data: upgradeEligibility } = FreeTrialsQueries.useGetIsEligibleForSelfUpgradeOrTrial(
    Feature.CALL_INTELLIGENCE
  );

  const showSignUpCard =
    upgradeEligibility?.subscriptionStatus === SubscriptionStatusForSelfUpgrade.SCHEDULE_CALL ||
    upgradeEligibility?.subscriptionStatus === SubscriptionStatusForSelfUpgrade.SUBSCRIBE;

  return (
    <>
      <ManageSubscriptionAccessWarning isLocationEligible={showSignUpCard} isSubscriptionActive={isCallIntelEnabled} />
      <div css={styles.container}>
        <section>
          <Heading level={3}>
            {t(
              "With Call Intelligence, Weave's AI powered platform, get deeper insights into what each patient is calling about, a list of missed booking opportunities you can follow up on, and the sentiment of participants during each call - thereby unlocking growth opportunities for your office."
            )}
          </Heading>
          <VideoFrame css={styles.videoFrame} src='https://fast.wistia.com/embed/medias/00q24rsmga' />
          <Text>
            {t(
              "Call Intelligence leverages advanced machine learning to analyze all outgoing and incoming calls to your office, generating a word-for-word transcript of the call + a crisp AI generated summary. It detects missed revenue opportunities for you to follow up on - aka calls where a patient wanted to book an appointment but the booking was scheduled.. It detects whether they're a new patient, and other issues such as billing / complaints / cancellation etc. they called about. Call Intelligence evaluates the sentiment of participants during a call, helping you identify how different staff members are performing and thereby guide targeted training efforts. Call Intelligence also stacks the performance of all your office locations against each other, helping identify key areas and opportunities for revenue growth."
            )}
          </Text>
        </section>
        <aside>{showSignUpCard && !isCallIntelEnabled && <CallIntelSubscribeCard />}</aside>
      </div>
    </>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(6)};

    > section {
      flex: 1;
      max-width: 860px;
      min-width: 380px;
    }

    > aside {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: ${theme.spacing(4)};
    }
  `,

  videoFrame: css`
    margin: ${theme.spacing(4, 0)};
    width: 100%;
  `,
};
