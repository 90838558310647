export type Filters = {
  endDate?: string;
  locations?: string[];
  startDate?: string;
};

export type MetricProps = {
  clickNoop?: boolean;
  isDrillDownPage?: boolean;
  onFetchStateChange?: (isFetching: boolean) => void;
};

export type MetricSubViewProps = {
  exportEnabled?: boolean;
  isDemoAccount?: boolean;
  onPatientsLoad?: (patients: PatientInfo[]) => void;
};

export type HistoricalDataTotal = {
  label: string;
  leadTime?: number;
  persons?: PatientInfo[];
  production?: number;
  timestamp?: string;
  total: number;
};

export type HistoricalDataProduction = HistoricalDataTotal & {
  production: number;
};

export type HistoricalDataTreatment = {
  acceptedPercent: number;
  diagnosedPercent: number;
  label: string;
};

export type HistoricalDataPercentage = {
  label: string;
  percentage: number;
};

export type HistoricalDataPercentageSameDay = {
  label: string;
  percentageSameDay: number;
};

type ActivePatients = {
  historicalData?: HistoricalDataTotal[];
  total?: number;
};

export type Benchmark = {
  label: string;
  value: number;
};

type ActivePatientsScheduled = {
  benchmarks?: Benchmark[];
  percentage?: number;
};

type InsuranceProviders = {
  insuranceProviders: HistoricalDataTotal[];
};

type ActivePatientsTotal = {
  total: number;
};

type ActivePatientsScheduledPercentage = {
  percentage: number;
};

export type ActivePatientsResponseLocation = {
  activePatients: ActivePatients;
};

export type ActivePatientsResponse = {
  location: ActivePatientsResponseLocation;
};

export type ActivePatientsDemographicsResponseLocation = {
  activePatients?: ActivePatientsTotal;
  activePatientsScheduled?: ActivePatientsScheduledPercentage;
  patientDemographics?: {
    buckets?: Buckets[];
    details?: InsuranceProviders;
  };
};

export type ActivePatientsDemographicsResponse = {
  location: ActivePatientsDemographicsResponseLocation;
};

export type ActivePatientsDemographicsScheduledResponseLocation = {
  activePatients?: ActivePatientsTotal;
  activePatientsScheduled?: ActivePatientsScheduledPercentage;
  unscheduledHygienePatients?: PatientsTotalBuckets;
  unscheduledNonhygienePatients?: PatientsTotalBuckets;
};

export type ActivePatientsDemographicsScheduledResponse = {
  location: ActivePatientsDemographicsScheduledResponseLocation;
};

export type ActivePatientsScheduledResponseLocation = {
  activePatientsScheduled: ActivePatientsScheduled;
};

export type ActivePatientsScheduledResponse = {
  location: ActivePatientsScheduledResponseLocation;
};

export type RecapturedPatientsTotals = {
  leadTime?: number;
  patients?: number;
  production?: number;
};

type RecapturedPatients = {
  details?: PatientsDetails;
  historicalData?: HistoricalDataTotal[];
  totals?: RecapturedPatientsTotals;
};

export type RecapturedPatientsTrendLocation = {
  recapturedPatients: RecapturedPatients;
};

export type RecapturedPatientsTrend = {
  location: RecapturedPatientsTrendLocation;
};

export type ProductionPerDay = {
  amount: number;
  date: string;
};

export type NewPatientsTotals = {
  needsAppointment?: number;
  patients?: number;
  production?: number;
  productionPerDay?: ProductionPerDay[];
  unscheduledProduction?: number;
  unscheduledProductionPerDay?: ProductionPerDay[];
};

type NewPatients = {
  historicalData?: HistoricalDataTotal[];
  details?: PatientsDetails;
  totals?: NewPatientsTotals;
};

export type NewPatientsTrendLocation = {
  newPatients: NewPatients;
};

export type NewPatientsTrend = {
  location: NewPatientsTrendLocation;
};

export type HygieneReappointmentBreakdown = {
  date: string;
  patients: number;
  rescheduledSameDay: number;
  unscheduled: number;
};

export type HygieneFollowUpTotals = {
  hygieneReappointments?: HygieneReappointmentBreakdown[];
  patients?: number;
  percentageSameDay?: number;
  rescheduledSameDay?: number;
  unscheduled?: number;
};

type HygieneReappointment = {
  benchmarks?: Benchmark[];
  details?: PatientsDetails;
  totals?: HygieneFollowUpTotals;
};

type HygieneFollowUpIndustryAvg = {
  totals: {
    hygieneFollowUpNotYetRescheduled: number;
    hygieneFollowUpReappointmentSameDay: number;
    hygieneFollowUpTotalVisits: number;
  };
};

export type HygieneFollowUpResponseLocation = {
  hygieneReappointment?: HygieneReappointment;
  industryAvg?: HygieneFollowUpIndustryAvg;
};

export type HygieneFollowUpResponse = {
  location: HygieneFollowUpResponseLocation;
};

export type TreatmentPlanTotals = {
  accepted?: number;
  acceptedPercent: number;
  diagnosed?: number;
  diagnosedPercent: number;
  qualifiedVisits?: number;
  unscheduledTreatment: number;
};

type TreatmentPlan = {
  benchmarks?: Benchmark[];
  details?: PatientsDetails;
  totals?: TreatmentPlanTotals;
};

type RestorativeTreatmentPlanIndustryAvg = {
  totals: {
    totalAcceptedRestorative: number;
    totalDiagnosedRestorative: number;
    totalUnscheduledTreatmentRestorative: number;
  };
};

export type RestorativeTreatmentPlanResponseLocation = {
  industryAvg?: RestorativeTreatmentPlanIndustryAvg;
  restorativeTreatmentPlan?: TreatmentPlan;
};

export type RestorativeTreatmentPlanResponse = {
  location: RestorativeTreatmentPlanResponseLocation;
};

export type PractitionerAnalysisResponseLocation = {
  hygieneTreatmentPlan: {
    totals: TreatmentPlanTotals;
  };
  restorativeTreatmentPlan: {
    totals: TreatmentPlanTotals;
  };
};

export type PractitionerAnalysisResponse = {
  location: PractitionerAnalysisResponseLocation;
};

type HygieneTreatmentPlanIndustryAvg = {
  totals: {
    totalAcceptedHygiene: number;
    totalDiagnosedHygiene: number;
    totalUnscheduledTreatmentHygiene: number;
  };
};

export type HygieneTreatmentPlanResponseLocation = {
  hygieneTreatmentPlan?: TreatmentPlan;
  industryAvg?: HygieneTreatmentPlanIndustryAvg;
};

export type HygieneTreatmentPlanResponse = {
  location: HygieneTreatmentPlanResponseLocation;
};

export type MissedAppointmentsTotals = {
  cancelled?: number;
  cancelledUnscheduled?: number;
  percentage?: number;
  recapturedProduction?: number;
  rescheduled?: number;
  totalPersonsCount?: number;
  totalProduction?: number;
  totalProductionRisk?: number;
  unscheduledOpportunity?: number;
};

type MissedAppointments = {
  benchmarks?: Benchmark[];
  details?: PatientsDetails;
  totals?: MissedAppointmentsTotals;
};

export type CancellationsResponseLocation = {
  missedAppointments: MissedAppointments;
};

export type CancellationsResponse = {
  location: CancellationsResponseLocation;
};

export type PADataApiQueryVariables = {
  end?: number;
  explicit?: boolean;
  start?: number;
  step?: string;
  summaryCount?: number;
  trendCount?: number;
};

type MHDataApiQueryVariables = {
  avgEnd?: number;
  avgStart?: number;
  date?: number;
  end?: number;
  lmtdEnd?: number;
  lmtdStart?: number;
  monthEnd?: number;
  mtdEnd?: number;
  mtdStart?: number;
  start?: number;
};

type ErrorMessage = {
  message: string;
};

export type Errors = ErrorMessage[];

export type MonthlyTrendHistoricalData = {
  location: {
    activePatients: {
      historicalData: HistoricalDataTotal[];
    };
    activePatientsScheduled: {
      historicalData: HistoricalDataPercentage[];
    };
    cancellations: {
      historicalData: HistoricalDataPercentage[];
    };
    hygieneReappointment: {
      historicalData: HistoricalDataPercentageSameDay[];
    };
    hygieneTreatmentPlan: {
      historicalData: HistoricalDataTreatment[];
    };
    newPatients: {
      historicalData: HistoricalDataProduction[];
    };
    recapturedPatients: {
      historicalData: HistoricalDataProduction[];
    };
    restorativeTreatmentPlan: {
      historicalData: HistoricalDataTreatment[];
    };
  };
};

export type Metric =
  | 'activePatients'
  | 'activePatientsScheduled'
  | 'cancellations'
  | 'hygieneReappointment'
  | 'hygieneTreatmentPlan'
  | 'newPatients'
  | 'recapturedPatients'
  | 'restorativeTreatmentPlan';

export type Buckets = HistoricalDataTotal & {
  subbuckets: HistoricalDataTotal[];
};

type PatientsTotal = {
  patients: number;
};

export type PatientsTotalBuckets = {
  buckets: HistoricalDataTotal[];
  totals: PatientsTotal;
};

export type Procedure = {
  adaCode: string;
  date?: string;
  procedureAmount: number;
};

export type PatientInfo = {
  age?: number;
  Birthdate: string;
  cancelledAppointmentDate?: string;
  cancelledAppointmentType?: string;
  classification?: OpportunityPatientClassificationEnum;
  diagnosed?: string;
  dueDate?: string;
  Email: string;
  firstAppointmentDate?: string;
  firstAppointmentType?: string;
  FirstName: string;
  Gender: string;
  HomePhone: string;
  hygieneAppointmentDate?: string;
  id: string;
  isPerio?: boolean;
  LastName: string;
  lastVisitDate?: string;
  locationId?: string;
  MobilePhone: string;
  nextAppointmentDate: string;
  nextAppointmentScheduledDate?: string;
  nextHygieneAppointment?: string;
  nextHygieneAppointmentDate?: string;
  nextVisit?: string;
  PMID: string;
  PreferredName: string;
  primaryInsurance?: string;
  procedures?: Procedure[];
  productionAmount: number;
  scheduledSameDay?: boolean;
  WorkPhone: string;
};

type PatientsDetails = {
  patients: PatientInfo[];
};

export type DataSyncStatus = {
  location: {
    locationSyncStatus?: boolean;
  };
};

export type MultiLocationStatus = {
  location: {
    getChildLocationCFState: {
      getCFState: [
        {
          isFreeTrial: boolean;
          locationId: string;
          state: 'PROMOTE' | 'ACTIVE' | 'HIDE';
        }
      ];
    };
  };
};

export enum OpportunityPatientClassificationEnum {
  CANCELLED_PATIENTS = 'CANCELLED_PATIENTS',
  HYGIENE_FOLLOW_UP = 'HYGIENE_FOLLOW_UP',
  HYGIENE_TREATMENT_PLAN = 'HYGIENE_TREATMENT_PLAN',
  RESTORATIVE_TREATMENT_PLAN = 'RESTORATIVE_TREATMENT_PLAN',
}

type OpportunityDetails = {
  details: {
    patients: PatientInfo[];
  };
};

export type OpportunityList = {
  location: {
    hygieneReappointment: OpportunityDetails;
    hygieneTreatmentPlan: OpportunityDetails;
    missedAppointments: OpportunityDetails;
    restorativeTreatmentPlan: OpportunityDetails;
  };
};

export type AnalyticsDataRequest = {
  locationIds: string[];
  queries: string[];
  variables?: PADataApiQueryVariables | MHDataApiQueryVariables;
};

export type PractitionerTreatmentPlanAnalytics = {
  accepted?: number;
  acceptedPercent?: number;
  diagnosed?: number;
  diagnosedPercent?: number;
  practitionerName?: string;
  qualifiedVisits?: number;
  unscheduledTreatment?: number;
};

export type PractitionerAnalysisDetailsResponseLocation = {
  hygieneTreatmentPlan?: TreatmentPlan;
  restorativeTreatmentPlan?: TreatmentPlan;
  treatmentPlan: {
    practitionerTreatmentPlanAnalytics?: {
      practitionerHygieneTreatmentPlanTotals?: PractitionerTreatmentPlanAnalytics[];
      practitionerRestorativeTreatmentPlanTotals?: PractitionerTreatmentPlanAnalytics[];
    };
  };
};

export type PractitionerAnalysisDetailsResponse = {
  location: PractitionerAnalysisDetailsResponseLocation;
};
