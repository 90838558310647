import { FC } from 'react';
import { css } from '@emotion/react';
import { Address } from '@weave/schema-gen-ts/dist/schemas/phone/address/v1/address_service.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ContentLoader, Modal, ModalControlModalProps } from '@frontend/design-system';
import { E911AddressForm } from '.';

interface Props extends ModalControlModalProps {
  address?: Address;
  isLoading?: boolean;
  onSave: (address: Address) => void;
  onBack?: () => void;
  onCancel?: () => void;
}

export const E911AddressModal: FC<React.PropsWithChildren<Props>> = ({
  address,
  isLoading,
  onBack,
  onSave,
  onCancel,
  ...rest
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'e911' });

  return (
    <>
      <Modal disableCloseOnEscape disableCloseOnOverlayClick {...rest} minWidth={450} maxWidth={450}>
        <Modal.Header
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
          textAlign='left'
        >
          {address ? t('Edit E911 Address') : t('Add E911 Address')}
        </Modal.Header>
        <Modal.Body>
          <E911AddressForm address={address} onCancel={onCancel} onBack={onBack} onSave={onSave} />
        </Modal.Body>
        <ContentLoader show={isLoading} />
      </Modal>
    </>
  );
};
