import { useState, useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { PopoverMenu, PopoverMenuItem, PrimaryButton, TextButton, usePopoverMenu, Text } from '@frontend/design-system';
import {
  useInboxFiltersShallowStore,
  inboxFiltersAreEqual,
  useInboxFilterStatusLabels,
  DEFAULT_INBOX_FILTER_VALUE,
} from '../../../stores';
import { InboxFilter, InboxFilters } from '../../../types';
import { InboxFilterPopoverMenuItem } from './inbox-filter-popover-menu-item';

const clearButtonId = 'clear-inbox-filters-btn';

type InboxFiltersPopoverProps = Pick<
  ReturnType<typeof usePopoverMenu<HTMLButtonElement>>,
  'getMenuProps' | 'getItemProps' | 'activeIndex' | 'close'
> & {
  groupIds?: string[];
  hideFilters?: InboxFilter[];
};

export const InboxFiltersPopover = ({
  getMenuProps,
  getItemProps,
  activeIndex,
  close,
  groupIds,
  hideFilters,
}: InboxFiltersPopoverProps) => {
  const { t } = useTranslation('inbox');
  const statusLabels = useInboxFilterStatusLabels();
  const { inboxFilters, setInboxFilters } = useInboxFiltersShallowStore('inboxFilters', 'setInboxFilters');
  const shownInboxFilters = Object.fromEntries(
    Object.entries(inboxFilters).filter(([key]) => !hideFilters?.includes(key))
  ) as Partial<InboxFilters>;
  const [newSelection, setNewSelection] = useState<typeof shownInboxFilters>();
  const currentSelection = newSelection ?? shownInboxFilters;
  const hasChangedSelection = !!newSelection && !inboxFiltersAreEqual(currentSelection, shownInboxFilters);
  const [clearIsFocused, setClearIsFocused] = useState(false);

  const shownFiltersOrder: InboxFilter[] = ['tags', 'departments'].filter(
    (filter) => !hideFilters?.includes(filter)
  ) as InboxFilter[];

  const handleToggle = <K extends InboxFilter>(key: K, values: InboxFilters[K]) => {
    setNewSelection((prev) => {
      const currentValues = prev ?? shownInboxFilters;
      const prevValues = currentValues[key];
      const { toRemove, toAdd } = (values as string[]).reduce<{ toRemove: string[]; toAdd: string[] }>(
        (acc, value) => {
          if (prevValues?.includes(value)) {
            acc.toRemove.push(value);
          } else {
            acc.toAdd.push(value);
          }
          return acc;
        },
        { toRemove: [], toAdd: [] }
      ) as { toRemove: InboxFilters[K]; toAdd: InboxFilters[K] };
      return {
        ...currentValues,
        [key]: prevValues ? prevValues.filter((v) => !toRemove.includes(v)).concat(toAdd) : toAdd,
      };
    });
  };

  const handleApply = () => {
    setInboxFilters({ ...inboxFilters, ...newSelection });
    setNewSelection(undefined);
  };
  const handleClear = () => {
    setNewSelection(DEFAULT_INBOX_FILTER_VALUE);
  };

  const hasSelection = Object.values(currentSelection).some((v) => v.length);

  useEffect(() => {
    if (clearIsFocused && activeIndex !== 0) setClearIsFocused(false);
    else if (!clearIsFocused && activeIndex === 0) setClearIsFocused(true);
  }, [activeIndex]);

  return (
    <PopoverMenu
      {...getMenuProps()}
      css={{
        width: 220,
      }}
    >
      <PopoverMenuItem
        {...getItemProps({
          index: 0,
          disableCloseOnSelect: true,
          onKeyDownCapture: (e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              e.stopPropagation();
              handleClear();
            }
          },
          onFocusCapture: (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (hasSelection) setClearIsFocused(true);
          },
          onBlurCapture: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setClearIsFocused(false);
          },
        })}
        css={[
          {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.colors.neutral20}`,
            ':hover': {
              backgroundColor: 'inherit',
            },
            ':focus': {
              backgroundColor: 'inherit',
            },
          },
          clearIsFocused && {
            [`#${clearButtonId}`]: {
              backgroundColor: theme.colors.neutral5,
            },
          },
        ]}
        tabIndex={hasSelection ? undefined : -1}
      >
        <Text weight='semibold'>{t('Filter Inbox')}</Text>
        <TextButton
          id={clearButtonId}
          onClick={handleClear}
          css={[
            {
              color: hasSelection ? theme.font.colors.primary : theme.font.colors.disabled,
              fontWeight: theme.font.weight.semibold,
            },
          ]}
          tabIndex={-1}
          disabled={!hasSelection}
          trackingId={`${InboxPrefixes.List}-filter-popover-clear`}
        >
          {t('Clear')}
        </TextButton>
      </PopoverMenuItem>
      {shownFiltersOrder.map((filter, index) => (
        <InboxFilterPopoverMenuItem
          filter={filter}
          key={filter + index}
          getItemProps={getItemProps}
          startingIndex={index + 1}
          selectedItems={currentSelection[filter] ?? []}
          toggleItems={(values) => handleToggle(filter, values)}
          divider={index > 0 ? 'top' : undefined}
          groupIds={groupIds}
          closeMenu={close}
        />
      ))}
      <PopoverMenuItem
        {...getItemProps({
          index: Object.entries(statusLabels).length + shownFiltersOrder.length,
          onClick: handleApply,
          trackingId: `${InboxPrefixes.List}-filter-popover-apply`,
        })}
      >
        <PrimaryButton disabled={!hasChangedSelection}>{t('Apply')}</PrimaryButton>
      </PopoverMenuItem>
    </PopoverMenu>
  );
};
