import { InstructionFields } from './base';
import { InstructionFieldsProps, ForwardDevicePayload } from '../types';
import { useQuery } from 'react-query';
import { queryKeys } from '../../../query-keys';
import { SkeletonLoader } from '@frontend/design-system';
import { useAppScopeStore } from '@frontend/scope';
import { DevicesApi } from '@frontend/api-devices';

export const ForwardDeviceInstructionFields = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<ForwardDevicePayload>['onChange'];
  option: ForwardDevicePayload;
}) => {
  const { singleLocationId, accessibleLocationData } = useAppScopeStore();
  const location = accessibleLocationData[singleLocationId];

  const { data = [], isLoading } = useQuery({
    queryKey: [...queryKeys.devicesWithAddresses(), location.id],
    queryFn: () => DevicesApi.listDevicesWithHeaders(location.id),
    select: (data) => {
      return data
        .sort((a, b) => a.sipDevice.name.localeCompare(b.sipDevice.name))
        .map((device) => ({
          value: device.sipDevice.ID,
          label: device.sipDevice.name,
        }));
    },
  });

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<ForwardDevicePayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          deviceId: data,
        },
        value: {
          deviceId: option.deviceId,
          callerName: option.callerName,
        },
      }}
    />
  );
};

const schema = {
  type: 'forwardDevice',
  fields: {
    deviceId: {
      label: 'Device',
      name: 'deviceId',
      type: 'dropdown',
      helperText: 'This is some helper text for A',
    },
    callerName: {
      label: 'Caller Label',
      name: 'callerName',
      type: 'text',
      helperText: 'This is some helper text for Caller Label',
    },
  },
} as const;
