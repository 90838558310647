import { ReactNode, useLayoutEffect } from 'react';
import { SerializedStyles } from '@emotion/react';
import { Modal } from '@frontend/design-system';
import { useMultiStepModal } from './use-multi-step-modal';

export interface ModalStepProps {
  id: string;
  children: ReactNode;
  title: string;
  style?: SerializedStyles;
  hideModalHeader?: boolean;
  hideModalBody?: boolean;
  disableCloseOnOverlayClick?: boolean;
  disableCloseOnEscape?: boolean;
}

export const ModalStep = ({
  children,
  id,
  title,
  style,
  hideModalBody = false,
  disableCloseOnOverlayClick = false,
  disableCloseOnEscape = false,
  hideModalHeader = false,
}: ModalStepProps) => {
  const { activeStep, setTitle, setDisableCloseOnOverlayClick, setDisableCloseOnEscape, setHideHeader } =
    useMultiStepModal();
  const isActiveStep = activeStep === id;

  useLayoutEffect(() => {
    if (isActiveStep) {
      if (title) {
        setTitle?.(title);
      }
      setDisableCloseOnOverlayClick(disableCloseOnOverlayClick);
      setDisableCloseOnEscape(disableCloseOnEscape);
      setHideHeader(hideModalHeader);
    }
  }, [title, isActiveStep, disableCloseOnOverlayClick, disableCloseOnEscape, hideModalHeader]);

  if (!isActiveStep) return null;
  return <>{!hideModalBody ? <Modal.Body css={style}>{children}</Modal.Body> : children}</>;
};
