import { FC } from 'react';
import { FormsSourceTenants } from '@frontend/api';
import { useControlledField, RadioField } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../../../../../shared/constants';
import { useWritebackWidgetStore } from '../../../../../../providers';
import { childListStyle } from './child-list.styles';

interface ChildListProps {
  sites: FormsSourceTenants.Types.WritebackSite[];
  sourceId: string;
}

const ChildList: FC<ChildListProps> = ({ sites, sourceId }) => {
  const { sourceTenantId, setSourceTenantId, setSourceId } = useWritebackWidgetStore([
    'sourceTenantId',
    'setSourceTenantId',
    'setSourceId',
  ]);

  const radioFieldProps = useControlledField({
    type: 'radio',
    value: sourceTenantId,
    onChange: (newSourceTenantId: string) => {
      setSourceTenantId(newSourceTenantId);
      setSourceId(sourceId);
    },
  });

  return (
    <RadioField {...radioFieldProps} name='tenants-list'>
      {sites.map(({ name, sourceTenantId }) => {
        return (
          <RadioField.Radio
            key={sourceTenantId}
            value={sourceTenantId}
            css={childListStyle}
            trackingId={pendoTags.writbackWidgetV2.changeSource}
          >
            {name}
          </RadioField.Radio>
        );
      })}
    </RadioField>
  );
};

export default ChildList;
