import { css } from '@emotion/react';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text, Chip, NakedButton } from '@frontend/design-system';

interface Props {
  selectApptType: (apptType: AppointmentTypesTypes.AppointmentType) => void;
  apptTypes: AppointmentTypesTypes.AppointmentType[] | undefined;
  selectedApptType: AppointmentTypesTypes.AppointmentType | undefined;
}

export default function ApptTypeSelect({ apptTypes, selectedApptType, selectApptType }: Props) {
  const { t } = useTranslation('schedule');

  return (
    <div>
      <Text>{t('To get started, simply select the type of appointment you need from our list of options.')}</Text>
      <Text as='h3' color='light' size='small'>
        {t('Appointment Types')}
      </Text>

      <section css={styles.apptCardContainer}>
        {apptTypes?.map((appt) => {
          const { name, durationMinutes, id } = appt;
          return (
            <NakedButton
              data-cy='request-appointment-appointment-select'
              trackingId={id}
              key={id}
              className={selectedApptType?.id === id ? 'card selected' : 'card'}
              onClick={() => selectApptType(appt)}
            >
              <Heading as='h3'>{name}</Heading>
              <Chip>{durationMinutes} min</Chip>
            </NakedButton>
          );
        })}
      </section>
    </div>
  );
}

const styles = {
  apptCardContainer: css({
    maxHeight: 300,
    overflowY: 'auto',
    '.card': {
      border: `1px solid ${theme.colors.neutral20}`,
      borderRadius: 4,
      width: `calc(100% - ${theme.spacing(1)})`,
      textAlign: 'left',
      padding: theme.spacing(2),
      margin: theme.spacing(1, 1, 1, 0),
      display: 'grid',
      gridTemplateColumns: '3fr 1fr',
      gridTemplateRows: 'auto',
      gridTemplateAreas: '"title chip" "desc desc"',
      h3: {
        fontSize: theme.fontSize(20),
        margin: theme.spacing(0),
        marginRight: theme.spacing(1),
        gridArea: 'title',
      },
      p: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
        gridArea: 'desc',
      },
      'div.chip': {
        float: 'right',
        gridArea: 'chip',
        justifySelf: 'end',
        '@media (max-width: 699px)': {
          justifySelf: 'start',
        },
      },
      ':hover div.chip': {
        backgroundColor: theme.colors.primary50,
        color: theme.colors.white,
      },
      ':hover': {
        backgroundColor: theme.colors.neutral10,
      },
      '@media (max-width: 699px)': {
        gridTemplateAreas: '"title title" "chip chip" "desc desc"',
        'h3, p': {
          fontSize: theme.fontSize(16),
          marginBottom: theme.spacing(1),
        },
      },
    },
    'button.card.selected': {
      border: `2px solid ${theme.colors.primary50}`,
      padding: `calc(${theme.spacing(2)} - 1px)`,
    },
  }),
};
