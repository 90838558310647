import { useState } from 'react';
import { ComponentProps } from '@frontend/types';
import { FormPacketList } from '../components';
import { FormPacketFilters } from '../components/form-packet-filters';
import { Category, FormOrPacketV2 } from '../types/form-packet-selector.types';
import { useFormPacketList } from './use-form-packet-list';

type UseFormPacketSelectorArgs = {
  groupId: string;
  onSelect?: (item: FormOrPacketV2, type: Category) => void;
};

export const useFormPacketSelector = ({
  groupId,
  onSelect,
}: UseFormPacketSelectorArgs): {
  selectorProps: {
    formPacketFiltersProps: ComponentProps<typeof FormPacketFilters>;
    formPacketListProps: ComponentProps<typeof FormPacketList>;
  };
  selectedItem: FormOrPacketV2 | undefined;
  selectedCategory: Category | undefined;
  reset: () => void;
} => {
  const { searchFieldProps, switchChipGroupProps, formsQuery, packetsQuery } = useFormPacketList({ groupId });
  const [selectedItem, setSelectedItem] = useState<FormOrPacketV2>();

  const selectedCategory = switchChipGroupProps.value[0] as Category | undefined;
  const items =
    selectedCategory === Category.FORMS
      ? formsQuery.data?.pages.flatMap((page) => page.data ?? []) ?? []
      : packetsQuery.data?.pages.flatMap((page) => page.data ?? []) ?? [];
  const isLoading = selectedCategory === Category.FORMS ? formsQuery.isLoading : packetsQuery.isLoading;

  const reset = () => {
    setSelectedItem(undefined);
    switchChipGroupProps.setValue([]);
  };

  return {
    selectorProps: {
      formPacketFiltersProps: {
        searchFieldProps,
        switchChipGroupProps,
      },
      formPacketListProps: {
        items,
        isLoading,
        onSelect: (item) => {
          onSelect?.(item, selectedCategory ?? (Object.values(Category)[0] as Category) ?? Category.PACKETS);
          setSelectedItem(item);
        },
        selectedId: selectedItem?.id,
        category: selectedCategory || Category.PACKETS,
      },
    },
    selectedItem,
    selectedCategory,
    reset,
  };
};
