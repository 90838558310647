import { AuthService, VerifyAuthResponse } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import {
  AuthStorage,
  localStorageHelper,
  LOGIN_ERROR_CAUSE,
  SignInMethod,
  isWeaveTokenActive,
} from '@frontend/auth-helpers';
import appConfig from '@frontend/env';
import { sentry } from '@frontend/tracking';
import { AuthFlow, SignInProps, ClientTokens } from './AuthFlow';

export class LegacyClient implements AuthFlow {
  public getSignInMethod(): Promise<SignInMethod> {
    return Promise.resolve('legacy');
  }

  public getTokens(): Promise<ClientTokens> {
    const weaveJWT = localStorageHelper.get<string>(AuthStorage.weave_token);
    const tokens = {
      weaveToken: weaveJWT,
    };
    return Promise.resolve(tokens);
  }

  public handleCallback(): Promise<ClientTokens> {
    return Promise.resolve(this.getTokens());
  }

  public isUserAuthenticated(): boolean {
    return isWeaveTokenActive();
  }

  public async signIn(signInInfo: SignInProps | undefined): Promise<VerifyAuthResponse> {
    const APIfetchNoAuth = (): ((url: string, reqInit: RequestInit) => Promise<Response>) => {
      return async (url: string, reqInit: RequestInit) => {
        const res = await fetch(`${appConfig.BACKEND_API}${url}`, reqInit);
        if (res.status !== 200) {
          throw new Error(res.body?.toString());
        }
        return await res.json();
      };
    };

    return AuthService.VerifyAuth(
      APIfetchNoAuth() as (url: string, reqInit: RequestInit) => Promise<VerifyAuthResponse>,
      {
        data: {
          credentials: {
            username: signInInfo?.username,
            password: signInInfo?.password,
          },
        },
      }
    )
      .then((res) => {
        if (res.token) {
          localStorageHelper.create(AuthStorage.weave_token, res.token);
          return Promise.resolve(res);
        } else {
          throw new Error('No token returned');
        }
      })
      .catch((err) => {
        sentry.error({
          topic: 'auth',
          error: err,
          addContext: {
            name: 'auth',
            context: {
              errMessage: 'Fetching verify token',
            },
          },
        });
        throw new Error('Unknown login error', { cause: LOGIN_ERROR_CAUSE.unknown });
      });
  }

  public signOut(opts: { logoutRedirect?: string } = {}): Promise<void> {
    localStorageHelper.delete(AuthStorage.weave_token);
    if (opts.logoutRedirect) {
      window.location.href = opts.logoutRedirect;
    } else {
      window.location.href = '/'; // we default to the home page
    }
    return new Promise<void>((_, reject) => {
      setTimeout(() => {
        reject('error timed out while waiting for redirect');
      }, 30000); // 30 seconds
    }); // return a promise that never resolves
  }
}
