import { useState } from 'react';
import { css } from '@emotion/react';
import { BulkMessagesQueries, MessagesTypes, MessagesUtils } from '@frontend/api-messaging';
import {
  Accordion,
  ButtonBar,
  CaretLeftIcon,
  Heading,
  IconButton,
  PrimaryButton,
  SecondaryButton,
  Tray,
  SpinningLoader,
  Text,
  TextLink,
} from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { BulkPrefixes } from '@frontend/tracking-prefixes';
import { AgeFilter } from './age-filter';
import { DateFilter } from './date-filter';
import { DropdownFilter } from './dropdown-filter';
import { MultiselectFilter } from './multiselect-filter';
import { theme } from '@frontend/theme';
import { RecipientsFooter } from '../recipients-footer';
import { RecipientsMode } from '../recipients';
import { RecipientFilterCount } from './filter-count';
import { FilterAccordion } from './filter-accordion';
import { AppointmentDateFilter } from './appointment-date-filter';

interface Props {
  filtersToApply: MessagesTypes.Filters;
  setFiltersToApply: (filters: MessagesTypes.Filters) => void;
  setMode: (mode: RecipientsMode) => void;
}

export const FilterModal = ({ filtersToApply, setFiltersToApply, setMode }: Props) => {
  const { t } = useTranslation('messages');
  const [filters, setFilters] = useState<MessagesTypes.Filters>({ ...filtersToApply });

  const { data: filterOptions = [], isLoading: isFiltersLoading } = BulkMessagesQueries.useRecipientFilterOptions();
  const { data: totalRecipients, isLoading: isTotalRecipientsLoading } = BulkMessagesQueries.useCountRecipients();
  const { data: recipients, isLoading: isRecipientsLoading } = BulkMessagesQueries.useCountRecipients(filters);
  const isFilterCountLoading = isTotalRecipientsLoading || isRecipientsLoading;

  const primaryInsurance = filterOptions.find((option) => option.filterType === 'PRIMARY_INSURANCE');
  const appointmentStatus = filterOptions.find((option) => option.filterType === 'APPOINTMENT_STATUS');
  const appointmentType = filterOptions.find((option) => option.filterType === 'APPOINTMENT_TYPE');
  const recipientStatus = filterOptions.find((option) => option.filterType === 'RECIPIENT_STATUS');
  const serviceProvider = filterOptions.find((option) => option.filterType === 'APPOINTMENT_PRACTITIONER');
  const recallType = filterOptions.find((option) => option.filterType === 'RECALL_TYPE');
  const sourceTenant = filterOptions.find((option) => option.filterType === 'SOURCE_TENANT');
  const lastReceivedBulkMessageOptions = ['30+ days', '60+ days', '90+ days', '120+ days', '180+ days'];

  const numberOfPrimaryInsurances = Object.keys(primaryInsurance?.filterOptions ?? {}).length;
  const numberOfAppointmentStatuses = Object.keys(appointmentStatus?.filterOptions ?? {}).length;
  const numberOfAppointmentTypes = Object.keys(appointmentType?.filterOptions ?? {}).length;
  const numberOfRecipientStatuses = Object.keys(recipientStatus?.filterOptions ?? {}).length;
  const numberOfServiceProviders = Object.keys(serviceProvider?.filterOptions ?? {}).length;
  const numberOfRecallTypes = Object.keys(recallType?.filterOptions ?? {}).length;
  const numberOfIntegrations = Object.keys(sourceTenant?.filterOptions ?? {}).length;

  const updateFilters = () => {
    setFiltersToApply(filters);
    goBack();
  };

  const goBack = () => {
    setMode('contacts');
  };

  return (
    <>
      <Tray.Header>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <IconButton
            css={css`
              margin-right: ${theme.spacing(1)};
            `}
            label={t('Back')}
            onClick={goBack}
            trackingId={`${BulkPrefixes.Recipients}-filter-title-back-btn`}
            showLabelOnHover
          >
            <CaretLeftIcon />
          </IconButton>
          <Heading
            level={2}
            css={css`
              margin: 0;
            `}
          >
            {t('Filter Recipients List')}
          </Heading>
        </div>
      </Tray.Header>
      <Tray.Body>
        {isFiltersLoading ? (
          <div
            css={css`
              text-align: center;
            `}
          >
            <SpinningLoader />
          </div>
        ) : (
          <div
            css={css`
              display: grid;
              gap: ${theme.spacing(2)};
              margin: ${theme.spacing(1, 0, 0, 1)};
            `}
          >
            {numberOfAppointmentStatuses || numberOfRecallTypes ? (
              <Accordion
                variant='blank'
                css={css`
                  border-bottom: solid 1px ${theme.colors.neutral20};

                  /* Setting overflow: visible allows the multi-select dropdowns to expand beyond the Accordion's container.
                  The CSS needs to be structured like this because Accordion.Item and Accordion.Body currently don't pass CSS to any rendered elements. */
                  > div {
                    overflow: visible;
                    > section {
                      overflow: visible;
                    }
                  }
                `}
              >
                {numberOfAppointmentStatuses || numberOfAppointmentTypes ? (
                  <FilterAccordion.Item value='by-appointment-accordion'>
                    <FilterAccordion.Header
                      filters={filters}
                      filterTypes={['appointmentDate', 'appointmentTypes', 'appointmentStatuses']}
                    >
                      {t('By Appointment Details')}
                    </FilterAccordion.Header>
                    <FilterAccordion.Body>
                      {numberOfAppointmentTypes || numberOfAppointmentStatuses ? (
                        <AppointmentDateFilter filters={filters} setFilters={setFilters} />
                      ) : null}
                      {numberOfAppointmentTypes ? (
                        <MultiselectFilter
                          label={t('Appointment Type')}
                          options={Object.values(appointmentType?.filterOptions ?? {}).filter((item) => item) ?? []}
                          filters={filters}
                          setFilters={setFilters}
                          filterType={'appointmentTypes'}
                        />
                      ) : null}
                      {numberOfAppointmentStatuses ? (
                        <MultiselectFilter
                          label={t('Appointment Status')}
                          options={Object.values(appointmentStatus?.filterOptions ?? {}).filter((item) => item) ?? []}
                          filters={filters}
                          setFilters={setFilters}
                          filterType={'appointmentStatuses'}
                        />
                      ) : null}
                    </FilterAccordion.Body>
                  </FilterAccordion.Item>
                ) : null}
                {numberOfRecallTypes ? (
                  <FilterAccordion.Item value='by-recall-accordion'>
                    <FilterAccordion.Header filters={filters} filterTypes={['recallTypes', 'recallDate']}>
                      {t('By Recall Details')}
                    </FilterAccordion.Header>
                    <FilterAccordion.Body>
                      <MultiselectFilter
                        label={t('Recall Type')}
                        options={Object.values(recallType?.filterOptions ?? {}).filter((item) => item) ?? []}
                        filters={filters}
                        setFilters={setFilters}
                        filterType={'recallTypes'}
                      />
                      <Text>{t('Recall Date')}</Text>
                      <DateFilter filters={filters} setFilters={setFilters} filterType='recallDate' />
                    </FilterAccordion.Body>
                  </FilterAccordion.Item>
                ) : null}
              </Accordion>
            ) : null}
            {numberOfPrimaryInsurances ? (
              <MultiselectFilter
                label={t('Insurance Type')}
                options={Object.values(primaryInsurance?.filterOptions ?? {}).filter((item) => item) ?? []}
                filters={filters}
                setFilters={setFilters}
                filterType={'primaryInsurance'}
              />
            ) : null}
            {numberOfRecipientStatuses ? (
              <MultiselectFilter
                label={t('Recipient Status')}
                options={Object.values(recipientStatus?.filterOptions ?? {}).filter((item) => item) ?? []}
                filters={filters}
                setFilters={setFilters}
                filterType={'recipientStatuses'}
              />
            ) : null}
            {numberOfServiceProviders ? (
              <MultiselectFilter
                label={t('Service Provider')}
                options={Object.values(serviceProvider?.filterOptions ?? {}).filter((item) => item) ?? []}
                filters={filters}
                setFilters={setFilters}
                filterType={'appointmentPractitioners'}
              />
            ) : null}
            <DropdownFilter
              label={t('Last Received Bulk Message')}
              options={lastReceivedBulkMessageOptions}
              filters={filters}
              setFilters={setFilters}
              filterType={'minDaysSinceLastBulkMessage'}
            />
            <AgeFilter
              labelMin={t('Minimum Age')}
              labelMax={t('Maximum Age')}
              filters={filters}
              setFilters={setFilters}
            />
            {numberOfIntegrations ? (
              <MultiselectFilter
                label={t('Provider Location')}
                options={
                  Object.entries(sourceTenant?.filterOptions ?? {})
                    .map(([key, value]) => [key, value])
                    .filter((item) => item.length) ?? []
                }
                filters={filters}
                setFilters={setFilters}
                filterType={'sourceTenantIds'}
              />
            ) : null}
          </div>
        )}
      </Tray.Body>
      <RecipientsFooter>
        <Text
          size='small'
          css={css`
            color: ${theme.colors.neutral50};
            background: ${theme.colors.neutral5};
            border-radius: ${theme.borderRadius.medium};
            text-align: left;
            padding: ${theme.spacing(1)};
          `}
        >
          <Trans t={t}>
            Please note: The HIPAA Privacy Rule requires an individual's written authorization before their PHI can be
            used for marketing. Compliance with the Privacy Rule while using Weave's Bulk Messaging feature will depend
            on the content of the text and other factors. You can learn more about HIPAA and marketing by visiting the{' '}
            <TextLink
              to='https://www.hhs.gov/hipaa/for-professionals/privacy/guidance/marketing/index.html'
              target='_blank'
            >
              HHS website
            </TextLink>
            .
          </Trans>
        </Text>
        <RecipientFilterCount
          isLoading={isFilterCountLoading}
          recipients={recipients?.count}
          totalRecipients={totalRecipients?.count}
        />
        <ButtonBar
          css={css`
            justify-content: flex-end;
            margin-top: ${theme.spacing(2)};
            padding: 0;
          `}
        >
          <SecondaryButton onClick={goBack} css={buttonStyle} trackingId={`${BulkPrefixes.Recipients}-filter-back-btn`}>
            {t('Back')}
          </SecondaryButton>
          <PrimaryButton
            onClick={updateFilters}
            css={buttonStyle}
            trackingId={`${BulkPrefixes.Recipients}-filter-apply-btn`}
            disabled={!MessagesUtils.validateFilters(filters)}
          >
            {t('Apply Filters')}
          </PrimaryButton>
        </ButtonBar>
      </RecipientsFooter>
    </>
  );
};

const buttonStyle = css`
  max-width: fit-content;
`;
