import { BuilderFieldDivisionTypes } from '../../types';

export const PRIMARY_FIELD_CATEGORIES: BuilderFieldDivisionTypes.PrimaryFieldsDivisionCategory[] = [
  {
    label: 'Personal Information',
    fieldsOrder: [
      {
        key: 'firstName',
        syncable: true,
      },
      {
        key: 'middleName',
        syncable: true,
      },
      {
        key: 'lastName',
        syncable: true,
      },
      {
        key: 'preferredName',
        syncable: true,
      },
      {
        key: 'birthdate',
        syncable: true,
      },
      {
        key: 'gender',
        syncable: true,
      },
      {
        key: 'maritalStatus',
        syncable: true,
      },
      {
        key: 'socialSecurityNumber',
        syncable: false,
      },
      {
        key: 'driversLicenseNumber',
        syncable: true,
      },
    ],
  },
  {
    label: 'Contact Information',
    fieldsOrder: [
      {
        key: 'mobilePhone',
        syncable: true,
      },
      {
        key: 'homePhone',
        syncable: true,
      },
      {
        key: 'workPhone',
        syncable: true,
      },
      {
        key: 'email',
        syncable: true,
      },
    ],
  },
  {
    label: 'Residential Address',
    fieldsOrder: [
      {
        key: 'address1',
        syncable: true,
      },
      {
        key: 'address2',
        syncable: true,
      },
      {
        key: 'city',
        syncable: true,
      },
      {
        key: 'state',
        syncable: true,
      },
      {
        key: 'postalCode',
        syncable: true,
      },
    ],
  },
  {
    label: 'Insurance Information',
    fieldsOrder: [
      {
        key: 'primaryInsuranceId',
        syncable: false,
      },
      {
        key: 'primaryInsuranceAddress1',
        syncable: false,
      },
      {
        key: 'primaryInsuranceAddress2',
        syncable: false,
      },
      {
        key: 'primaryInsuranceCity',
        syncable: false,
      },
      {
        key: 'primaryInsuranceName',
        syncable: false,
      },
      {
        key: 'primaryInsurancePostalCode',
        syncable: false,
      },
      {
        key: 'primaryInsuranceRelationship',
        syncable: false,
      },
      {
        key: 'primaryInsuranceSSN',
        syncable: false,
      },
      {
        key: 'primaryInsuranceSpouseName',
        syncable: false,
      },
      {
        key: 'primaryInsuranceSpouseSSN',
        syncable: false,
      },
      {
        key: 'primaryInsuranceState',
        syncable: false,
      },
      {
        key: 'primaryPolicyHolderName',
        syncable: false,
      },
      {
        key: 'primaryGroupNumber',
        syncable: false,
      },
      {
        key: 'primaryRxNumber',
        syncable: false,
      },
      {
        key: 'secondaryInsuranceId',
        syncable: false,
      },
      {
        key: 'secondaryInsuranceAddress1',
        syncable: false,
      },
      {
        key: 'secondaryInsuranceAddress2',
        syncable: false,
      },
      {
        key: 'secondaryInsuranceCity',
        syncable: false,
      },
      {
        key: 'secondaryInsuranceName',
        syncable: false,
      },
      {
        key: 'secondaryInsurancePostalCode',
        syncable: false,
      },
      {
        key: 'secondaryInsuranceRelationship',
        syncable: false,
      },
      {
        key: 'secondaryInsuranceSSN',
        syncable: false,
      },
      {
        key: 'secondaryInsuranceSpouseName',
        syncable: false,
      },
      {
        key: 'secondaryInsuranceSpouseSSN',
        syncable: false,
      },
      {
        key: 'secondaryInsuranceState',
        syncable: false,
      },
      {
        key: 'secondaryPolicyHolderName',
        syncable: false,
      },
      {
        key: 'secondaryGroupNumber',
        syncable: false,
      },
      {
        key: 'secondaryRxNumber',
        syncable: false,
      },
    ],
  },
];

interface BuilderFieldsMapping {
  [key: string]: {
    categoryIndex: number;
    orderIndex: number;
  };
}

/**
 * Mapping of primary fields to their category and order index.
 * This is used to determine the position of a field in the primary fields section.
 *
 * @example
 * {
 *  firstName: { categoryIndex: 0, orderIndex: 0 },
 *  middleName: { categoryIndex: 0, orderIndex: 1 },
 * }
 */
export const PRIMARY_FIELDS_MAPPING = PRIMARY_FIELD_CATEGORIES.reduce<BuilderFieldsMapping>(
  (acc, category, categoryIndex) => {
    category.fieldsOrder.forEach(({ key }, orderIndex) => {
      acc[key] = {
        categoryIndex,
        orderIndex,
      };
    });

    return acc;
  },
  {}
);
