import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { Nav } from '@frontend/components';
import { useMediaMatches, NavSize } from '@frontend/responsiveness';
import { useOnClickOutside } from '@frontend/design-system';
import { PortalNav } from '../';
import { primaryNavContent } from '../nav-content';
import { GlobalNavNav } from './global-nav-nav';
import { globalNavStyles } from './styles';

export const FULL_NAV_WIDTH = 267;
const PORTAL_PATH = '/portal';

interface INavContext {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  hamburgerButtonRef?: React.RefObject<HTMLButtonElement>;
}

const NavContext = createContext<INavContext>({ open: false, setOpen: () => {}, hamburgerButtonRef: undefined });

export const NavContextProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const hamburgerButtonRef = useRef<HTMLButtonElement>(null);
  return <NavContext.Provider value={{ open, setOpen, hamburgerButtonRef }}>{children}</NavContext.Provider>;
};

export const useNavTrigger = () => {
  const { open = false, setOpen, hamburgerButtonRef } = useContext(NavContext);

  return { open, setOpen, hamburgerButtonRef };
};

interface IGlobalNav {
  navWidth?: number;
}

export const GlobalNav = ({ navWidth = FULL_NAV_WIDTH }: IGlobalNav) => {
  const { open, setOpen, hamburgerButtonRef } = useNavTrigger();
  const insideRef = useRef<HTMLDivElement>(null);
  const { matches } = useMediaMatches();
  const isWiderThanMobile = matches.mediumMin();
  const isMediumRange = matches.mediumMinMax();
  const navigate = useNavigate();
  const { current } = useLocation();
  const navItems = primaryNavContent();

  // TODO: this could use the useNavSize, but since elsewhere in this fn uses the `matches` var,
  // I'm not using it, since it would call `useMediaMatches` twice, which is expensive.
  const navSize: NavSize = !isWiderThanMobile ? NavSize.Mobile : isMediumRange ? NavSize.Mini : NavSize.Desktop;

  const closeOnClickOutside = useCallback(() => {
    setOpen(false);
  }, []);

  useOnClickOutside({
    ref: insideRef,
    handler: closeOnClickOutside,
    active: matches.largeMax(),
    captureClick: false,
    ignoreConditions: [() => document.getElementById('pendo-resource-center-container') !== null],
    ...(hamburgerButtonRef && { ignoreRefs: [hamburgerButtonRef] }),
  });

  const isWeaveApp = !current.pathname.startsWith(PORTAL_PATH);
  const isPortal = current.pathname.startsWith(PORTAL_PATH);

  return (
    <div className='hideforprint' style={{ height: '100%' }}>
      {isWeaveApp && (
        <Nav
          open={open}
          ref={insideRef}
          setOpen={setOpen}
          items={navItems}
          currentPath={current.pathname as (typeof navItems)[number]['root']}
          onClick={(path) => {
            navigate({ to: path });
          }}
        />
      )}
      {isPortal && (
        <>
          {(navSize === NavSize.Mobile || navSize === NavSize.Mini) && (
            <section
              id='mobile-nav'
              ref={insideRef}
              css={[
                globalNavStyles({ navWidth, open }),
                css`
                  position: absolute;
                `,
              ]}
            >
              <PortalNav />
            </section>
          )}
          {navSize === NavSize.Desktop && (
            <section id='desktop-nav' css={globalNavStyles({ navWidth, open: false })}>
              <PortalNav />
            </section>
          )}
        </>
      )}
    </div>
  );
};

GlobalNav.Nav = GlobalNavNav;
