import { memo, useMemo } from 'react';
import { AnalyticsCommonTypes, PracticeAnalyticsAggregations, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { Chart, XAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { usePracticeAnalyticsStore } from '../../hooks';
import { formatters } from '../../utils';
import { DemoChip } from '../demo-chip';

type LabelAndColors = {
  hygienePatients: AnalyticsCommonTypes.StringRecord;
  nonHygienePatients: AnalyticsCommonTypes.StringRecord;
};

type Props = PracticeAnalyticsTypes.MetricProps & {
  colors: LabelAndColors;
  data?: ReturnType<typeof PracticeAnalyticsAggregations.activePatientsScheduledDetails>;
  isLoading?: boolean;
  labels: LabelAndColors;
};

export const UnscheduledPatientsMetricSingle = memo(({ colors, data, isLoading, labels }: Props) => {
  const { t } = useTranslation('analytics');
  const { isDemoAccount } = usePracticeAnalyticsStore();

  const chartData = useMemo(() => {
    const { unscheduledHygienePatients, unscheduledNonhygienePatients } = data?.aggregatedData || {};

    const hygienePatients = {
      centerMetric: unscheduledHygienePatients?.totals.patients,
      groups:
        unscheduledHygienePatients?.buckets.map(({ label, total }) => {
          let labelMod = label.split(' ')[0];
          if (labelMod.includes('not')) {
            labelMod = label;
          }
          return {
            name: labelMod,
            value: total,
          };
        }) || [],
    };

    const nonHygienePatients = {
      groups:
        unscheduledNonhygienePatients?.buckets.map(({ label, total }) => {
          const name = label.replaceAll('last visit', '').replaceAll('months ago', '').trim();
          return {
            name,
            values: {
              [name]: total,
            },
          };
        }) || [],
    };

    return {
      hygienePatients,
      nonHygienePatients,
    };
  }, [data]);

  return (
    <>
      <Chart
        colors={colors.hygienePatients}
        commonTooltipLabel={t('Patients')}
        isLoading={isLoading}
        labels={labels.hygienePatients}
      >
        <Chart.Header
          bottomElement={
            <Text color='subdued' size='medium'>
              {t('Filter by Months Overdue')}
            </Text>
          }
          leftElement={isDemoAccount ? <DemoChip /> : null}
          title={t('Unscheduled Hygiene Patients')}
        />
        <Chart.Legends style={{ marginBottom: theme.spacing(2) }} />
        <Chart.PieChart data={chartData.hygienePatients} />
      </Chart>

      <Chart
        colors={colors.nonHygienePatients}
        commonTooltipLabel={t('Patients')}
        isLoading={isLoading}
        labels={labels.nonHygienePatients}
      >
        <Chart.Header
          bottomElement={
            <Text color='subdued' size='medium'>
              {t('Filter by Months Overdue')}
            </Text>
          }
          leftElement={isDemoAccount ? <DemoChip /> : null}
          title={t('Unscheduled Other Patients')}
        />
        <Chart.BarChart
          appearance={{
            collectiveTooltip: true,
            customXAxisTick: ({ labels, groupName, value, ...rest }) => {
              const key = groupName as keyof typeof colors.nonHygienePatients;
              return (
                <XAxisLabelValueTick
                  {...rest}
                  color={colors.nonHygienePatients[key]}
                  label={labels?.[groupName] || groupName}
                  value={value}
                />
              );
            },
            margin: { bottom: 20 },
            showXAxis: true,
          }}
          data={chartData.nonHygienePatients}
          formatValue={formatters.value.format}
        />
      </Chart>
    </>
  );
});

UnscheduledPatientsMetricSingle.displayName = 'UnscheduledPatientsMetricSingle';
