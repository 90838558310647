import { FC } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Heading, PrimaryButton, SecondaryButton, Text } from '@frontend/design-system';

type Props = {
  title: string;
  subTitle: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  primaryButtonOnClick: () => void;
  secondaryButtonOnClick?: () => void;
};

export const ErrorPage: FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
  subTitle,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonOnClick,
  secondaryButtonOnClick,
}) => (
  <section
    css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${theme.spacing(4)};
      h1,
      p {
        margin-bottom: ${theme.spacing(2)};
      }
    `}
  >
    {children}
    <Heading>{title}</Heading>
    <Text>{subTitle}</Text>
    <div>
      {!!primaryButtonText && (
        <PrimaryButton
          onClick={primaryButtonOnClick}
          size='large'
          css={css`
            width: auto;
            margin-right: ${theme.spacing(2)};
          `}
        >
          {primaryButtonText}
        </PrimaryButton>
      )}
      {!!secondaryButtonText && (
        <SecondaryButton
          onClick={secondaryButtonOnClick}
          size='large'
          css={css`
            width: auto;
          `}
        >
          {secondaryButtonText}
        </SecondaryButton>
      )}
    </div>
  </section>
);
