const key = 'tab-id';

const generateId = () => {
  return Math.floor(Math.random() * 10e9).toString();
};

export const registerTabSessionId = () => {
  try {
    //sets a tab id in the session for tab management
    const id = generateId();
    sessionStorage.setItem('tab-id', id);
    return id;
  } catch (e) {
    console.warn('sessionStorage is not available');
  }
  return null;
};

export const getTabSessionId = () => {
  try {
    if (!sessionStorage.getItem(key)) {
      registerTabSessionId();
    }
    return sessionStorage.getItem(key);
  } catch (e) {
    console.warn('sessionStorage is not available');
  }
  return null;
};
