import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { Avatar, ChatItem } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { MediaQueries } from '@frontend/api-media';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { useMessagePersonNames, useScheduledSmsBubbleActions } from '../../../../hooks';
import { Direction } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';

type PopoutScheduledSmsItemProps = {
  message: ScheduledSms;
  onEdit?: (scheduledSms: ScheduledSms) => void;
  onDelete: (messageId: string) => void;
  mediaQueries: Record<string, MediaQueries.UseMmsMediaItem>;
};

export const PopoutScheduledSmsItem = ({ message, onEdit, onDelete, mediaQueries }: PopoutScheduledSmsItemProps) => {
  const { t } = useTranslation('inbox');
  const getBubbleActions = useScheduledSmsBubbleActions({
    scheduledSms: message,
    onEdit: onEdit ? () => onEdit(message) : undefined,
    onDelete: () => onDelete(message.id),
  });
  const { senderName } = useMessagePersonNames({
    direction: Direction.DIRECTION_OUTBOUND,
    autogeneratedBy: '',
    createdBy: message.scheduledBy,
    deletedAt: '',
    deletedBy: '',
  });
  const isPaused = message.status === OutboundMessageStatus.PAUSED;
  const timestampFormatter = useTimestampFormatter();

  return (
    <ChatItem
      direction='outbound'
      avatar={<Avatar size='small' title={senderName || t('Unknown User')} name={senderName} />}
      senderName={senderName || t('Unknown User')}
      timestamp={isPaused ? t('Scheduled send paused') : timestampFormatter(message.sendAt)}
      isFuture
      isPaused={isPaused}
    >
      {message.mediaIds.map((mediaId) => {
        const mediaQuery = mediaQueries[mediaId];
        if (!mediaQuery) return null;
        return (
          <ChatItem.Image
            key={mediaId}
            loadingText={t('Loading image...')}
            src={mediaQuery.data?.src}
            contextActions={getBubbleActions({ mediaId })}
          />
        );
      })}
      {!!message.body && <ChatItem.Bubble contextActions={getBubbleActions()} text={message.body} />}
    </ChatItem>
  );
};
