import { ElementType } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { PolymorphicComponentPropWithoutRef, SearchField, SwitchChipGroup } from '@frontend/design-system';
import { useFormPacketList } from '../../hooks';
import { Category } from '../../types/form-packet-selector.types';

type FormPacketFiltersProps = Pick<ReturnType<typeof useFormPacketList>, 'searchFieldProps' | 'switchChipGroupProps'>;

type PolymorphicProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<C, FormPacketFiltersProps>;

export const FormPacketFilters = <C extends ElementType = 'div'>({
  as,
  switchChipGroupProps,
  searchFieldProps,
  ...rest
}: PolymorphicProps<C>) => {
  const { t } = useTranslation('form-packet-selector');
  const Component = as || 'div';

  const labelsMap: Record<Category, string> = {
    [Category.FORMS]: t('Forms'),
    [Category.PACKETS]: t('Packets'),
  };

  return (
    <Component
      css={{
        display: 'flex',
        gap: theme.spacing(1),
        width: '100%',
        paddingTop: theme.spacing(1),
        marginLeft: theme.spacing(0.5),
      }}
      {...rest}
    >
      <SwitchChipGroup {...switchChipGroupProps}>
        {Object.values(Category).map((value) => (
          <SwitchChipGroup.Option key={value} value={value}>
            {labelsMap[value]}
          </SwitchChipGroup.Option>
        ))}
      </SwitchChipGroup>
      <SearchField
        {...searchFieldProps}
        // @ts-ignore - outerProps does in fact exist on SearchField, and is the only way to get the width to be 100%
        outerProps={{
          css: {
            width: '100%',
          },
        }}
      />
    </Component>
  );
};
