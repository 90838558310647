import { useLocalizedQuery } from '@frontend/location-helpers';
import { getCurrentUserSoftphoneData, getUserSoftphone } from './api';
import { UseQueryOptions } from 'react-query';

const queryKeys = {
  getUserSoftphone: (userId: string) => ['softphone', userId],
  getCurrentUserSoftphone: ['softphone', 'current'],
};

export const useGetUserSoftphoneQuery = (
  userId: string,
  opts?: UseQueryOptions<Awaited<ReturnType<typeof getUserSoftphone>>>
) =>
  useLocalizedQuery({
    queryKey: queryKeys.getUserSoftphone(userId),
    queryFn: () => getUserSoftphone(userId),
    ...opts,
  });

export const useGetCurrentUserSoftphoneQuery = (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof getCurrentUserSoftphoneData>>>
) =>
  useLocalizedQuery({
    queryKey: queryKeys.getCurrentUserSoftphone,
    queryFn: getCurrentUserSoftphoneData,
    ...opts,
  });
