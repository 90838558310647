import { ListThreadsRequest_Sort } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/manual-sms/v1/manualsms.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { useTranslation } from '@frontend/i18n';
import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';
import { ScheduledFilters } from '../types';

export const DEFAULT_SCHEDULED_FILTER_VALUE: ScheduledFilters = {
  statuses: [],
  tags: [],
  departments: [],
};

export interface ScheduledFiltersStore {
  scheduledFilters: ScheduledFilters;
  setScheduledFilters: (val: ScheduledFilters) => void;
  clearScheduledFilters: () => void;
  sort: ListThreadsRequest_Sort;
  setScheduledSorting: (val: ListThreadsRequest_Sort) => void;
  clearScheduledSorting: () => void;
}

export const useScheduledFiltersAndSortStore = createStoreWithPersistAndSubscribe<ScheduledFiltersStore>(
  (set) => ({
    scheduledFilters: DEFAULT_SCHEDULED_FILTER_VALUE,
    setScheduledFilters: (args: Partial<ScheduledFilters>) =>
      set(
        (state) => {
          state.scheduledFilters = { ...DEFAULT_SCHEDULED_FILTER_VALUE, ...state.scheduledFilters, ...args };
        },
        false,
        'SET_SCHEDULED_FILTERS'
      ),
    clearScheduledFilters: () => {
      set(
        (state) => {
          state.scheduledFilters = DEFAULT_SCHEDULED_FILTER_VALUE;
        },
        false,
        'CLEAR_SCHEDULED_FILTERS'
      );
    },
    sort: ListThreadsRequest_Sort.REQUESTED_SEND_AT_DESC,
    setScheduledSorting: (arg: ListThreadsRequest_Sort) =>
      set(
        (state) => {
          state.sort = arg;
        },
        false,
        'SET_SCHEDULED_SORTING'
      ),
    clearScheduledSorting: () => {
      set(
        (state) => {
          state.sort = ListThreadsRequest_Sort.REQUESTED_SEND_AT_DESC;
        },
        false,
        'CLEAR_SCHEDULED_FILTERS'
      );
    },
  }),
  { name: 'ScheduledFiltersStorage', version: 1 },
  { name: 'ScheduledFilters', trace: true }
);

export const useScheduledShallowStore = createShallowStore(useScheduledFiltersAndSortStore);

type ScheduledFiltersStoreKey = typeof ALL_STATUS_KEY | OutboundMessageStatus.SCHEDULED | OutboundMessageStatus.PAUSED;
export const ALL_STATUS_KEY = 'all';
export const useScheduledFilterStatusLabels = (): Record<ScheduledFiltersStoreKey, string> => {
  const { t } = useTranslation('inbox-scheduled');
  return {
    [ALL_STATUS_KEY]: t('All'),
    [OutboundMessageStatus.SCHEDULED]: t('Scheduled'),
    [OutboundMessageStatus.PAUSED]: t('Paused'),
  };
};
