import { FC } from 'react';
import { ExtraAction } from '@frontend/super-textarea';
import { theme } from '@frontend/theme';
import { IconButton, PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';

type TemplatesPopoverProps = Pick<
  ReturnType<typeof usePopoverMenu<HTMLButtonElement>>,
  'getMenuProps' | 'getItemProps'
> & {
  templateItems: ExtraAction[];
};

export const TemplatesPopover: FC<TemplatesPopoverProps> = ({ getMenuProps, getItemProps, templateItems }) => {
  return (
    <PopoverMenu
      {...getMenuProps()}
      css={{
        minWidth: 'max-content',
        width: 'max-content',
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 0),
      }}
    >
      {templateItems?.map((action, index) => {
        const { icon, ...rest } = action;
        return (
          <PopoverMenuItem
            key={`${action.label}-${index}`}
            css={{ padding: theme.spacing(0, 1) }}
            {...getItemProps({ index })}
          >
            <IconButton type='button' showLabelAlways size='small' {...rest} showLabelOnHover={false}>
              {icon}
            </IconButton>
          </PopoverMenuItem>
        );
      })}
    </PopoverMenu>
  );
};
