import { useEffect, useRef, useCallback } from 'react';
import { FormsSubmission } from '@frontend/api';
import {
  useDigitalFormsSourceTenantsContext,
  useDigitalFormsPreferredSourceTenantsContext,
} from '@frontend/digital-forms-scope';
import { useWritebackWidgetStore } from '../store';

const INVALID_SOURCE_ID = '00000000-0000-0000-0000-000000000000';
const APPLICABLE_SOURCE_TENANTS_FROM_SUBMISSION_FALLBACK: string[] = [];

interface UseWritebackDestinationConfigProps {
  submission: FormsSubmission.Types.NormalizedSubmissionDetailResponse;
  locationId: string;
}

export const useWritebackDestinationConfig = ({ submission, locationId }: UseWritebackDestinationConfigProps) => {
  const initRef = useRef(false);

  const {
    hasFetchedSourceTenants,
    getSupportedSourceTenantsForLocation,
    getApplicableSourceTenantsForWriteback,
    getSourceTenantValidity,
  } = useDigitalFormsSourceTenantsContext();

  const { arePreferredSourceTenantIdsReady, getPreferredSourceTenantIdForLocation } =
    useDigitalFormsPreferredSourceTenantsContext();

  const {
    setApplicableSourceTenants,
    setSourceId,
    setSourceTenantId,
    setAreSourceTenantsReady,
    setWritebackMode,
    setIsSourceTenantIdFromSubmissionNotSupported,
    setFailedToSearchRecords,
    setSourceTenantIdsWithDuplicateRecords,
    setIsSourceTenantIdFromSubmissionTheRootSourceId,
    setCanWriteback,
  } = useWritebackWidgetStore([
    'setApplicableSourceTenants',
    'setSourceTenantId',
    'setSourceId',
    'setAreSourceTenantsReady',
    'setWritebackMode',
    'setIsSourceTenantIdFromSubmissionNotSupported',
    'setFailedToSearchRecords',
    'setSourceTenantIdsWithDuplicateRecords',
    'setIsSourceTenantIdFromSubmissionTheRootSourceId',
    'setCanWriteback',
  ]);

  const {
    sourceTenantId: sourceTenantIdFromSubmission = '',
    applicableSourceTenants:
      applicableSourceTenantIdsFromSubmission = APPLICABLE_SOURCE_TENANTS_FROM_SUBMISSION_FALLBACK,
    writebackStatusCode,
    reasonPhrase,
  } = submission;

  const markConfigAsCompleted = useCallback(() => {
    initRef.current = true;
    setAreSourceTenantsReady(true);
  }, []);

  /**
   * Re-initialize the writeback destination config
   * if the submission changes (especially when the submission
   * is re-fetched after retrying the sync).
   */
  useEffect(() => {
    initRef.current = false;
    setAreSourceTenantsReady(false);
  }, [sourceTenantIdFromSubmission, applicableSourceTenantIdsFromSubmission]);

  /**
   * Check if the submission can be written back to the source tenant
   * based on the reason phrase. (CC and CSV contact submissions cannot be written back).
   */
  useEffect(() => {
    switch (reasonPhrase) {
      case 'WRITEBACK_NA_CC':
      case 'WRITEBACK_NA_CSV':
      case 'WRITEBACK_INCAPABLE_SRC':
        setCanWriteback(false);
        break;

      default:
        setCanWriteback(true);
        break;
    }
  }, [reasonPhrase]);

  /**
   * 1. Check if the submission failed to search records.
   * 2. Check if the submission has a duplicate source tenant IDs.
   */
  useEffect(() => {
    if (!reasonPhrase) {
      setFailedToSearchRecords(false);
      return;
    }

    switch (reasonPhrase) {
      case 'UNABLE_TO_SEARCH_PATIENT':
        setFailedToSearchRecords(true);
        break;

      case 'DUPLICATE_PATIENT':
      case 'Duplicate Patient': {
        const duplicateSourceTenantIds = findDuplicateSourceTenantIds(applicableSourceTenantIdsFromSubmission);
        setSourceTenantIdsWithDuplicateRecords(duplicateSourceTenantIds);
        break;
      }

      default:
        setFailedToSearchRecords(false);
        setSourceTenantIdsWithDuplicateRecords([]);
        break;
    }
  }, [reasonPhrase, applicableSourceTenantIdsFromSubmission]);

  // Set the source tenants for the writeback
  useEffect(() => {
    if (initRef.current || !hasFetchedSourceTenants || !arePreferredSourceTenantIdsReady) {
      return;
    }

    // Check if the submission has a valid source tenant ID to writeback to
    if (sourceTenantIdFromSubmission && sourceTenantIdFromSubmission !== INVALID_SOURCE_ID) {
      const { isValidSource, sourceId, isSiteId, matchesSourceId } = getSourceTenantValidity(
        locationId,
        sourceTenantIdFromSubmission
      );

      if (isValidSource && sourceId) {
        const applicableSourceTenants = getApplicableSourceTenantsForWriteback(locationId, [
          sourceTenantIdFromSubmission,
        ]);

        /**
         * This is to handle the case where the sourceTenantIdFromSubmission
         * is the root source ID or the root source tenant ID of
         * a multi tenant source.
         */
        const filteredApplicableSourceTenants = applicableSourceTenants.map((sourceTenant) => {
          if (
            sourceTenant.id === sourceTenantIdFromSubmission ||
            sourceTenant.sourceTenantId === sourceTenantIdFromSubmission
          ) {
            return {
              ...sourceTenant,
              sites: [], // Make the sites empty so that the writeback happens in the root source
            };
          }

          return sourceTenant;
        });

        if (matchesSourceId) {
          setIsSourceTenantIdFromSubmissionTheRootSourceId(true);
        }

        setApplicableSourceTenants(filteredApplicableSourceTenants);
        setSourceId(sourceId);
        setSourceTenantId(isSiteId ? sourceTenantIdFromSubmission : filteredApplicableSourceTenants[0].sourceTenantId);
        setIsSourceTenantIdFromSubmissionNotSupported(false);
      } else {
        setIsSourceTenantIdFromSubmissionNotSupported(true);
      }

      markConfigAsCompleted();
      return;
    }

    // Check is the submission has a list of applicable source tenants
    if (applicableSourceTenantIdsFromSubmission.length > 0) {
      const applicableSourceTenants = getApplicableSourceTenantsForWriteback(
        locationId,
        applicableSourceTenantIdsFromSubmission
      );

      if (applicableSourceTenants.length > 0) {
        setApplicableSourceTenants(applicableSourceTenants);

        // Set the first source tenant as the selected source tenant
        const firstApplicableSourceTenant = applicableSourceTenants[0];
        setSourceId(firstApplicableSourceTenant.id);

        const sites = firstApplicableSourceTenant.sites ?? [];

        if (sites.length > 0) {
          setSourceTenantId(sites[0].sourceTenantId);
        } else {
          setSourceTenantId(firstApplicableSourceTenant.sourceTenantId);
        }
      } else {
        setIsSourceTenantIdFromSubmissionNotSupported(true);
      }

      markConfigAsCompleted();
      return;
    }

    // Set all the supported source tenants for the location as applicable source tenants
    const supportedSourceTenants = getSupportedSourceTenantsForLocation(locationId);
    setApplicableSourceTenants(supportedSourceTenants);

    // Check if there is a preferred source tenant for the location
    const preferredSourceTenantId = getPreferredSourceTenantIdForLocation(locationId);

    if (preferredSourceTenantId) {
      // Check if the preferred source tenant is valid
      const { isValidSource, sourceId } = getSourceTenantValidity(locationId, preferredSourceTenantId);

      if (isValidSource && sourceId) {
        setSourceId(sourceId);
        setSourceTenantId(preferredSourceTenantId);
      } else {
        // Set the first supported source tenant as the selected source tenant
        const firstSupportedSourceTenant = supportedSourceTenants[0];
        setSourceId(firstSupportedSourceTenant.id);

        const sites = firstSupportedSourceTenant.sites ?? [];

        if (sites.length > 0) {
          setSourceTenantId(sites[0].sourceTenantId);
        } else {
          setSourceTenantId(firstSupportedSourceTenant.sourceTenantId);
        }
      }
    }

    markConfigAsCompleted();
    return;
  }, [
    hasFetchedSourceTenants,
    sourceTenantIdFromSubmission,
    applicableSourceTenantIdsFromSubmission,
    locationId,
    arePreferredSourceTenantIdsReady,
    getSourceTenantValidity,
    getApplicableSourceTenantsForWriteback,
    getSupportedSourceTenantsForLocation,
    getPreferredSourceTenantIdForLocation,
    markConfigAsCompleted,
  ]);

  // Set the writeback mode based on the writeback status code
  useEffect(() => {
    setWritebackMode(writebackStatusCode === 'Failed' ? 'Retry' : 'Manual');
  }, [writebackStatusCode]);
};

/**
 * Find duplicate source tenant IDs from the list of
 * applicable source tenant IDs.
 */
function findDuplicateSourceTenantIds(applicableSourceTenantsIds: string[]) {
  const originalSourceTenantIds: string[] = [];
  const duplicateSourceTenantIds: string[] = [];

  for (const applicableSourceTenantId of applicableSourceTenantsIds) {
    if (duplicateSourceTenantIds.includes(applicableSourceTenantId)) {
      continue;
    }

    if (originalSourceTenantIds.includes(applicableSourceTenantId)) {
      duplicateSourceTenantIds.push(applicableSourceTenantId);
    } else {
      originalSourceTenantIds.push(applicableSourceTenantId);
    }
  }

  return duplicateSourceTenantIds;
}
