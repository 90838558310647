import { css } from '@emotion/react';
import { SwitchField, Text, useFormField } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useSoftphoneSettings } from '../../providers/softphone-settings-provider';
import { useEffect } from 'react';

export const DnDToggle = () => {
  const { t } = useTranslation('softphone');
  const enableDoNotDisturb = useSoftphoneSettings((ctx) => ctx.enableDoNotDisturb);
  const disableDoNotDisturb = useSoftphoneSettings((ctx) => ctx.disableDoNotDisturb);
  const isDoNotDisturb = useSoftphoneSettings((ctx) => ctx.isDoNotDisturb);

  const switchProps = useFormField({
    type: 'switch',
    value: isDoNotDisturb,
  });

  useEffect(() => {
    if (switchProps.value) enableDoNotDisturb();
    else {
      disableDoNotDisturb();
    }
  }, [switchProps]);

  return (
    <li>
      <SwitchField
        css={css`
          justify-content: start;
          label {
            color: white;
            width: fit-content;
            font-weight: bold;
            margin-right: auto;
          }
        `}
        labelPlacement='left'
        label={t('Do Not Disturb (DND)')}
        name=''
        trackingId='softphone-settings-DND'
        {...switchProps}
      />
      <Text className='settings-li-helper-text' color='light' size='small'>
        {t('Turning on Do Not Disturb (DND) will redirect incoming calls to voicemail.')}
      </Text>
    </li>
  );
};
