import { useEffect } from 'react';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { theme } from '@frontend/theme';
import { ChipVariants } from '@frontend/design-system';

export type CallIntelSubViewType = '' | 'appointment-type' | 'category' | 'sentiment';

export type CallIntelSubViewId =
  | string
  | CallIntelligenceTypes.AppointmentTypeEnum
  | CallIntelligenceTypes.CategoryEnum
  | CallIntelligenceTypes.SentimentEnum;

export type CallIntelSubView = {
  id?: CallIntelSubViewId;
  type?: CallIntelSubViewType;
};

export type MultiLocationsView = 'summary' | 'betweenLocations' | 'withinLocations';

type DataLabelsAndColors = {
  appointmentTypes?: Record<CallIntelligenceTypes.AppointmentTypeEnum | string, string>;
  categories?: Record<CallIntelligenceTypes.CategoryEnum | string, string>;
  contactType?: Record<CallIntelligenceTypes.ContactTypeEnum | string, string>;
  sentiments?: Record<CallIntelligenceTypes.SentimentEnum | string, string>;
  sentimentsWithEmoji?: Record<CallIntelligenceTypes.SentimentEnum | string, string>;
  taskTypes?: Record<CallIntelligenceTypes.TaskTypeEnum | string, string>;
};

interface CallIntelStore {
  activeMultiView?: MultiLocationsView;
  availableAppointmentTypes: CallIntelligenceTypes.AppointmentTypeEnum[];
  availableCategories: CallIntelligenceTypes.CategoryEnum[];
  chipVariants: Record<string, ChipVariants>;
  dataColors: DataLabelsAndColors;
  dataLabels: DataLabelsAndColors;
  defaultFilters: CallIntelligenceTypes.Filters;
  filterHintText: string;
  filters: CallIntelligenceTypes.Filters;
  filtersToRestore?: CallIntelligenceTypes.Filters;
  hasCustomFilters: boolean;
  initialQueryParamsFilters: CallIntelligenceTypes.Filters;
  locationId: string;
  subView: CallIntelSubView;

  setActiveMultiView: (activeMultiView: MultiLocationsView) => void;
  setAvailableAppointmentTypes: (availableAppointmentTypes: CallIntelligenceTypes.AppointmentTypeEnum[]) => void;
  setAvailableCategories: (availableCategories: CallIntelligenceTypes.CategoryEnum[]) => void;
  setChipVariants: (chipVariants: Record<string, ChipVariants>) => void;
  setDataColors: (dataColors: DataLabelsAndColors) => void;
  setDataLabels: (dataLabels: DataLabelsAndColors) => void;
  setDefaultFilters: (defaultFilters: Partial<CallIntelligenceTypes.Filters>) => void;
  setFilterHintText: (filterHintText: string) => void;
  setFilters: (filters: Partial<CallIntelligenceTypes.Filters>) => void;
  setFiltersToRestore: (filtersToRestore: CallIntelligenceTypes.Filters) => void;
  setInitialQueryParamsFilters: (filters: CallIntelligenceTypes.Filters) => void;
  setLocationId: (locationId: string) => void;
  setSubView: (subView: CallIntelSubView) => void;

  resetFilters: () => void;
  resetStore: () => void;
}

export const useCallIntelStore = createStoreWithSubscribe<CallIntelStore>(
  (set, get) => ({
    availableAppointmentTypes: [],
    availableCategories: [],
    chipVariants: {},
    dataColors: {},
    dataLabels: {},
    defaultFilters: {},
    filterHintText: '',
    filters: {},
    filtersToRestore: undefined,
    hasCustomFilters: false,
    initialQueryParamsFilters: {},
    locationId: '',
    subView: {},

    setActiveMultiView: (activeMultiView) => {
      set({ activeMultiView });
    },
    setAvailableAppointmentTypes: (availableAppointmentTypes) => {
      set({ availableAppointmentTypes });
    },
    setAvailableCategories: (availableCategories) => {
      set({ availableCategories });
    },
    setChipVariants: (chipVariants) => {
      set({ chipVariants });
    },
    setDataColors: (dataColors) => {
      set({ dataColors });
    },
    setDataLabels: (dataLabels) => {
      set({ dataLabels });
    },
    setDefaultFilters: (defaultFilters) => {
      set({ defaultFilters });
    },
    setFilterHintText: (filterHintText) => {
      set({ filterHintText });
    },
    setFilters: (filters) => {
      const { defaultFilters = {}, filters: currentFilters } = get();
      const newFilters: CallIntelligenceTypes.Filters = { ...currentFilters, ...filters };

      const {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        locations: defaultLocations,
        ...otherDefaults
      } = defaultFilters;

      const areDatesEqual =
        dayjs(newFilters.startDate).isSame(dayjs(defaultStartDate), 'date') &&
        dayjs(newFilters.endDate).isSame(dayjs(defaultEndDate), 'date');

      const areLocationsEqual = isEqual(newFilters.locations?.sort(), defaultLocations?.sort());

      set({
        filters: newFilters,
        hasCustomFilters:
          !areDatesEqual ||
          !areLocationsEqual ||
          Object.keys(otherDefaults).some(
            (key) =>
              !isEqual(
                newFilters[key as keyof CallIntelligenceTypes.Filters],
                defaultFilters[key as keyof CallIntelligenceTypes.Filters]
              )
          ),
      });
    },
    setFiltersToRestore: (filtersToRestore) => {
      set({ filtersToRestore });
    },
    setInitialQueryParamsFilters: (filters) => {
      set({ initialQueryParamsFilters: filters });
    },
    setLocationId: (locationId) => {
      set({ locationId });
    },
    setSubView: (subView) => {
      set({ subView });
    },
    resetFilters: () => {
      const { defaultFilters, setFilters } = get();
      setFilters(defaultFilters);
    },
    resetStore: () => {
      set({
        activeMultiView: undefined,
        defaultFilters: {},
        filterHintText: '',
        filters: {},
        filtersToRestore: undefined,
        initialQueryParamsFilters: {},
        hasCustomFilters: false,
        locationId: '',
        subView: {},
      });
    },
  }),
  {
    name: 'CallIntelStore',
    trace: true,
  }
);

export const useCallIntelShallowStore = createShallowStore<CallIntelStore>(useCallIntelStore);

export const useUpdateDefaultCallIntelStore = () => {
  const { t } = useTranslation('analytics');
  const { setAvailableAppointmentTypes, setAvailableCategories, setChipVariants, setDataColors, setDataLabels } =
    useCallIntelShallowStore(
      'setAvailableAppointmentTypes',
      'setAvailableCategories',
      'setChipVariants',
      'setDataColors',
      'setDataLabels'
    );

  useEffect(() => {
    setChipVariants({
      // Appointment Types
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: 'seaweed',
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: 'critical',
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: 'primary',
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: 'warn',
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: 'eggplant',
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE]: 'critical',
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_RESTORATIVE]: 'seaweed',
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SURGERY]: 'success',
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN]: 'neutral',

      // Categories
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_BILLING]: 'success',
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_COMPLAINT]: 'warn',
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_EMERGENCY]: 'critical',
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_MEDICATION]: 'eggplant',
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_OTHER]: 'neutral',
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_SCHEDULING]: 'primary',
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_UNKNOWN]: 'neutral',
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_VENDOR]: 'seaweed',

      // Contact Types
      [CallIntelligenceTypes.ContactTypeEnum.CONTACT_EXISTING_PATIENT]: 'primary',
      [CallIntelligenceTypes.ContactTypeEnum.CONTACT_NEW_PATIENT]: 'warn',
      [CallIntelligenceTypes.ContactTypeEnum.CONTACT_NOT_A_PATIENT]: 'neutral',
      [CallIntelligenceTypes.ContactTypeEnum.CONTACT_UNKNOWN]: 'neutral',

      // Task Types
      [CallIntelligenceTypes.TaskTypeEnum.TYPE_BILLING]: 'success',
      [CallIntelligenceTypes.TaskTypeEnum.TYPE_INSURANCE]: 'eggplant',
      [CallIntelligenceTypes.TaskTypeEnum.TYPE_OTHER]: 'neutral',
      [CallIntelligenceTypes.TaskTypeEnum.TYPE_PATIENTCARE]: 'warn',
      [CallIntelligenceTypes.TaskTypeEnum.TYPE_SCHEDULING]: 'primary',
      [CallIntelligenceTypes.TaskTypeEnum.TYPE_WAITLIST]: 'critical',
    });

    setDataColors({
      appointmentTypes: {
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: theme.colors.secondary.seaweed30,
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: theme.colors.critical30,
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: theme.colors.primary50,
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: theme.colors.warning50,
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: theme.colors.secondary.eggplant30,
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE]: theme.colors.critical20,
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_RESTORATIVE]: theme.colors.secondary.seaweed40,
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SURGERY]: theme.colors.success30,
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN]: theme.colors.neutral20,
      },

      categories: {
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_BILLING]: theme.colors.success30,
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_COMPLAINT]: theme.colors.warning50,
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_EMERGENCY]: theme.colors.critical30,
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_MEDICATION]: theme.colors.secondary.eggplant40,
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_OTHER]: theme.colors.neutral20,
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_SCHEDULING]: theme.colors.primary30,
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_UNKNOWN]: theme.colors.neutral20,
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_VENDOR]: theme.colors.secondary.seaweed40,
      },

      sentiments: {
        [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE]: theme.colors.critical40,
        [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL]: theme.colors.primary20,
        [CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE]: theme.colors.success30,
        [CallIntelligenceTypes.SentimentEnum.SENTIMENT_UNKNOWN]: theme.colors.neutral30,
      },
    });

    setDataLabels({
      appointmentTypes: {
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: t('Cosmetic'),
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: t('Emergency'),
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: t('Hygiene'),
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: t('Imaging'),
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: t('Orthodontics'),
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE]: t('Preventive'),
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_RESTORATIVE]: t('Restorative'),
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SURGERY]: t('Surgery'),
        [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN]: t('Unknown'),
      },

      categories: {
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_BILLING]: t('Billing'),
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_COMPLAINT]: t('Complaint'),
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_EMERGENCY]: t('Emergency'),
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_MEDICATION]: t('Medication'),
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_OTHER]: t('Other'),
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_SCHEDULING]: t('Scheduling'),
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_UNKNOWN]: t('Unknown'),
        [CallIntelligenceTypes.CategoryEnum.CATEGORY_VENDOR]: t('Vendor'),
      },

      contactType: {
        [CallIntelligenceTypes.ContactTypeEnum.CONTACT_EXISTING_PATIENT]: t('Existing Patient'),
        [CallIntelligenceTypes.ContactTypeEnum.CONTACT_NEW_PATIENT]: t('New Patient'),
        [CallIntelligenceTypes.ContactTypeEnum.CONTACT_NOT_A_PATIENT]: t('Not a Patient'),
        [CallIntelligenceTypes.ContactTypeEnum.CONTACT_UNKNOWN]: t('Unavailable Data'),
      },

      sentiments: {
        [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE]: t('Unhappy'),
        [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL]: t('Neutral'),
        [CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE]: t('Happy'),
        [CallIntelligenceTypes.SentimentEnum.SENTIMENT_UNKNOWN]: t('Unknown'),
      },

      sentimentsWithEmoji: {
        [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE]: `😒 ${t('Unhappy')}`,
        [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL]: `😐 ${t('Neutral')}`,
        [CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE]: `😊 ${t('Happy')}`,
        [CallIntelligenceTypes.SentimentEnum.SENTIMENT_UNKNOWN]: `🤔 ${t('Unknown')}`,
      },

      taskTypes: {
        [CallIntelligenceTypes.TaskTypeEnum.TYPE_BILLING]: t('Billing'),
        [CallIntelligenceTypes.TaskTypeEnum.TYPE_INSURANCE]: t('Insurance'),
        [CallIntelligenceTypes.TaskTypeEnum.TYPE_OTHER]: t('Other'),
        [CallIntelligenceTypes.TaskTypeEnum.TYPE_PATIENTCARE]: t('Patient Care'),
        [CallIntelligenceTypes.TaskTypeEnum.TYPE_SCHEDULING]: t('Scheduling'),
        [CallIntelligenceTypes.TaskTypeEnum.TYPE_WAITLIST]: t('Waitlist'),
      },
    });

    setAvailableAppointmentTypes([
      CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC,
      CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY,
      CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE,
      CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING,
      CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS,
      CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE,
      CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_RESTORATIVE,
      CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SURGERY,
      CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN,
    ]);

    setAvailableCategories([
      CallIntelligenceTypes.CategoryEnum.CATEGORY_BILLING,
      CallIntelligenceTypes.CategoryEnum.CATEGORY_COMPLAINT,
      CallIntelligenceTypes.CategoryEnum.CATEGORY_EMERGENCY,
      CallIntelligenceTypes.CategoryEnum.CATEGORY_MEDICATION,
      CallIntelligenceTypes.CategoryEnum.CATEGORY_OTHER,
      CallIntelligenceTypes.CategoryEnum.CATEGORY_SCHEDULING,
      CallIntelligenceTypes.CategoryEnum.CATEGORY_UNKNOWN,
      CallIntelligenceTypes.CategoryEnum.CATEGORY_VENDOR,
    ]);
  }, []);
};
