import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { Autocomplete } from '.';
import { Fragment } from 'react';
import { useTranslation } from '@frontend/i18n';
import { InputState } from '@frontend/generic-dialpad-accessories';
import { AutocompleteGroupItems, useDialerAutocomplete } from '../../hooks/use-dialer-autocomplete';

type AutocompleteItems = {
  [P in AutocompleteGroupItems]: string;
};

export type AutocompleteWrapsProps = {
  isDirectoryLoading: boolean;
  hideAutocomplete?: boolean;
  hideAutocompleteOnEmpty?: boolean;
  hideAutocompleteOnBlur?: boolean;
  inputState: InputState;
  currentDial: string | undefined;
};

export const AutocompleteWraps = ({
  isDirectoryLoading: isLoading,
  autocompleteItems,
  hideAutocomplete = false,
  hideAutocompleteOnEmpty = false,
  hideAutocompleteOnBlur = false,
  inputState,
  currentDial,
}: AutocompleteWrapsProps & ReturnType<typeof useDialerAutocomplete>) => {
  const { t } = useTranslation('softphone');

  const groupHeader: AutocompleteItems = {
    no_group_name: '',
    contacts: t('Contacts'),
    last_called: t('Last Called'),
    most_contacted: t('Most Contacted'),
    extensions: t('Extensions'),
  };

  const showAutocomplete =
    ((hideAutocompleteOnBlur && inputState.focused) || !hideAutocompleteOnBlur) &&
    ((hideAutocompleteOnEmpty && currentDial?.length) || !hideAutocompleteOnEmpty) &&
    !hideAutocomplete;

  const isAutocompleteLoading = isLoading && !!currentDial;

  return (
    <Autocomplete isLoading={isAutocompleteLoading} show={!!showAutocomplete}>
      {!!autocompleteItems &&
        (Object.keys(autocompleteItems) as AutocompleteGroupItems[])
          .filter((group) => !!autocompleteItems[group]?.length)
          .map((groupName) => {
            if (!autocompleteItems[groupName].length) return;
            return (
              <Fragment key={groupName}>
                {groupName !== 'no_group_name' && (
                  <Text
                    css={css`
                      padding: ${theme.spacing(1, 2)};
                    `}
                    weight='bold'
                    color='white'
                  >
                    {groupHeader[groupName]}
                  </Text>
                )}
                {autocompleteItems[groupName]}
              </Fragment>
            );
          })}
    </Autocomplete>
  );
};
