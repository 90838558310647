import React, { ReactNode } from 'react';

import { css } from '@emotion/react';
import { Heading } from '@frontend/design-system';
import { theme } from '@frontend/theme';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  title: string;
  renderSubtitle: ReactNode;
  renderPageContent?: ReactNode;
  renderSubscriptionPanel?: ReactNode;
}

export const SignUpLandingPage = React.memo(
  ({ title, renderSubtitle, renderPageContent, renderSubscriptionPanel, ...rest }: IProps) => {
    return (
      <main css={signUpPageLandingPageStyles} {...rest}>
        <div css={{ marginRight: theme.spacing(4) }}>
          <Heading>{title}</Heading>
          <div css={subtitleContainerStyle}>{renderSubtitle}</div>
          <div css={signupPageContentStyle}>{renderPageContent}</div>
        </div>
        <div css={{ marginLeft: theme.spacing(4) }}>{renderSubscriptionPanel}</div>
      </main>
    );
  }
);

const signUpPageLandingPageStyles = css({
  display: 'flex',
});

const subtitleContainerStyle = css({
  marginTop: theme.spacing(4),
});

const signupPageContentStyle = css({
  marginTop: theme.spacing(4),
});
