import { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useContainerQuery } from '@frontend/responsiveness';
import { Text, usePopoverMenu, SpinningLoader, IconButton } from '@frontend/design-system';
import {
  actionsContainerStyles,
  actionItemStyles,
  actionTextStyles,
  actionButtonStyles,
  actionSpinnerStyles,
  actionButtonWithText,
} from './ai-response-actions.styles';
import { ActionsPopover } from './components';

type VoidFnType = () => void;

type AiResponseActionsProps = {
  onRegenerate: VoidFnType;
  onShorten: VoidFnType;
  onLengthen: VoidFnType;
  onUndo: VoidFnType;
  onRedo: VoidFnType;
  onThumbsUp: VoidFnType;
  onThumbsDown: VoidFnType;
  closeActions: VoidFnType;
  shouldRender: boolean;
  isGenerateAiResponseLoading: boolean;
  canShowResponseFeedback: boolean;
  isRefinementsDisabled: boolean;
  isUndoDisabled: boolean;
  isRedoDisabled: boolean;
  shouldOpenInRefinementMode?: boolean;
};

export const ActionsComponent: FC<Omit<AiResponseActionsProps, 'isGenerateAiResponseLoading' | 'shouldRender'>> = ({
  onRegenerate,
  onThumbsUp,
  onThumbsDown,
  onShorten,
  onLengthen,
  onUndo,
  onRedo,
  closeActions,
  canShowResponseFeedback,
  isRefinementsDisabled = false,
  isUndoDisabled = true,
  isRedoDisabled = true,
  shouldOpenInRefinementMode,
}) => {
  const { t } = useTranslation('ai-response-actions');

  const [isContainerNarrow, containerRef] = useContainerQuery({
    maxWidth: shouldOpenInRefinementMode ? 91.5 : 105,
  });

  const { getMenuProps, getItemProps, getTriggerProps, isOpen, close } = usePopoverMenu<
    HTMLButtonElement | HTMLAnchorElement
  >({
    placement: 'top-start',
    middlewareOptions: { offset: 10 },
  });

  return (
    <>
      <motion.div
        css={actionsContainerStyles}
        exit={{ x: -37, opacity: 0 }}
        transition={{ duration: 0.5, type: 'linear' }}
        key={'ai-response-actions'}
        layout={'position'}
      >
        <div css={actionItemStyles} ref={containerRef}>
          <IconButton
            label={!!shouldOpenInRefinementMode ? t('Generate') : t('Regenerate')}
            showLabelOnHover={isContainerNarrow}
            css={actionButtonWithText(isContainerNarrow)}
            onClick={onRegenerate}
          >
            <Icon name='ai-response-regenerate-small' />
            <Text css={actionTextStyles(20, isContainerNarrow)} weight='bold' size='medium'>
              {shouldOpenInRefinementMode ? t('Generate') : t('Regenerate')}
            </Text>
          </IconButton>
        </div>

        <div css={actionItemStyles}>
          <IconButton
            css={actionButtonStyles(40, 40, 40)}
            label={t('Refine')}
            showLabelOnHover
            disabled={isRefinementsDisabled}
            {...getTriggerProps()}
          >
            <Icon name='filter-small' />
            <Icon
              name='caret-down-tiny'
              size={8}
              css={{
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          </IconButton>
          <IconButton
            css={actionButtonStyles()}
            label={t('Redo')}
            showLabelOnHover
            disabled={isRedoDisabled}
            onClick={onRedo}
          >
            <Icon name='send-forward-small' />
          </IconButton>
          <IconButton
            css={actionButtonStyles()}
            label={t('Undo')}
            showLabelOnHover
            disabled={isUndoDisabled}
            onClick={onUndo}
          >
            <Icon name='send-back-small' />
          </IconButton>
        </div>

        <AnimatePresence>
          {canShowResponseFeedback && (
            <motion.div
              exit={{ x: -5, opacity: 0 }}
              transition={{ duration: 0.25, ease: 'linear' }}
              css={actionItemStyles}
            >
              <Text css={actionTextStyles(16, isContainerNarrow)} weight='medium' size='small'>
                {t('Is this helpful?')}
              </Text>
              <IconButton
                css={actionButtonStyles()}
                label={t('Helpful')}
                showLabelOnHover={isContainerNarrow}
                onClick={onThumbsUp}
              >
                <Icon name='feedback-small' />
              </IconButton>
              <IconButton
                css={actionButtonStyles()}
                label={t('Not helpful')}
                showLabelOnHover={isContainerNarrow}
                onClick={onThumbsDown}
              >
                <Icon name='feedback-bad-small' />
              </IconButton>
            </motion.div>
          )}
        </AnimatePresence>

        <div>
          <IconButton css={actionButtonStyles()} label={t('Close')} onClick={() => closeActions()}>
            <Icon name='x-small' />
          </IconButton>
        </div>
      </motion.div>
      <ActionsPopover
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        onShorten={onShorten}
        onLengthen={onLengthen}
        close={close}
      />
    </>
  );
};

// For unmounting controlled by shouldRender logic
export const AiResponseActions: FC<AiResponseActionsProps> = ({
  shouldRender,
  isGenerateAiResponseLoading,
  ...rest
}) => {
  if (isGenerateAiResponseLoading) {
    return <SpinningLoader size='small' css={actionSpinnerStyles} />;
  }

  // shouldRender check must be inside AnimatePresence to animate component exit
  return <AnimatePresence>{!!shouldRender && <ActionsComponent {...rest} />}</AnimatePresence>;
};
