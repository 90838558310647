import { css } from '@emotion/react';
import { Text, TextLink } from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { useState } from 'react';

export const DialpadInfoRow = () => {
  const { t } = useTranslation('dialpad');
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <section
      css={css`
        padding: ${theme.spacing(2)};
        border-radius: 8px;
        gap: 4px;
        background-color: ${theme.colors.neutral5};
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
        onClick={toggleOpen}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: left;
            gap: 4px;
            padding: ${theme.spacing(0, 0, 0.5, 0)};
          `}
        >
          <Icon name='info-small' size={14} />
          <Text as='span' weight='bold' size='medium'>
            {t('About the Dial Pad')}
          </Text>
        </div>
        <div>
          {open ? (
            <Icon name='caret-up' color='default' size={16} css={cardIcon} />
          ) : (
            <Icon name='caret-down' color='default' size={16} css={cardIcon} />
          )}
        </div>
      </div>
      <></>
      {open && (
        <div
          css={css`
            line-height: 20px;
          `}
        >
          <Text size='medium' color='subdued'>
            {t(`Use the Dial Pad to remotely dial numbers to your desk phone.`)}
          </Text>
          <br />
          <Text size='medium' color='subdued'>
            <Trans t={t}>
              Want to place and receive calls all within the Weave app? Learn more about the new{' '}
              <TextLink
                target='_blank'
                to='https://www.weavehelp.com/hc/en-us/articles/12311867097627-Using-a-Softphone'
                trackingId='phn-dialpad-lnk-weavesoftphone'
              >
                Weave Softphone
              </TextLink>
              .
            </Trans>
          </Text>
        </div>
      )}
    </section>
  );
};

export const cardIcon = css`
  margin-left: auto;
  cursor: pointer;
`;
