import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Dayjs } from 'dayjs';
import { AnalyticsCommonTypes, PhoneAnalyticsApi, SMSAnalyticsApi } from '@frontend/api-analytics';
import { breakpoints } from '@frontend/responsiveness';
import { useScopedQuery } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { featureFlags } from '../../feature-flags';
import { queryKeys } from '../../query-keys';
import { CallAnswerRate, CallVolume, TotalMessages } from './summary-cards';
import { MessagingSummary, PhoneSummary } from './types';
import { MessagingCategoriesOnDashboard, PhoneIncomingCategoriesOnDashboard } from '.';

type Props = {
  isLoading?: boolean;
  filters: AnalyticsCommonTypes.AnalyticsSummaryFilters;
};

const appendTime = (date?: Dayjs) => {
  if (!date) {
    return;
  }
  const localDateTime = new Date();
  return `${date.format(
    'YYYY-MM-DDT'
  )}${localDateTime.getHours()}:${localDateTime.getMinutes()}:${localDateTime.getSeconds()}`;
};

const defaultPhoneSummaryData: PhoneSummary = {
  answered: 0,
  incoming: 0,
  total: 0,
};

const defaultMessagingSummaryData: MessagingSummary = {
  total: 0,
};

export const HomepageAnalyticsBody = ({ filters }: Props) => {
  const messagingAnalyticsEnabled = useHasFeatureFlag(featureFlags.messagingAnalytics);
  const phoneAnalyticsEnabled = useHasFeatureFlag(featureFlags.phoneAnalytics);

  const queryString = useMemo(() => `${JSON.stringify({ ...filters })}`, [filters]);

  const { data: phoneData, isFetching: isLoadingPhoneData } = useScopedQuery({
    queryKey: queryKeys.analyticsDashboard(`${queryString}-homepage-phone-summary`),
    queryFn: () =>
      PhoneAnalyticsApi.getPhoneReportingCharts({
        EndDate: appendTime(filters.endDate),
        LocationID: filters.locationIds,
        StartDate: appendTime(filters.startDate),
        TimeZone: filters.timeZone,
      }),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: messagingData, isFetching: isLoadingMessagingData } = useScopedQuery({
    queryKey: queryKeys.analyticsDashboard(`${queryString}-homepage-messaging-summary`),
    queryFn: () =>
      SMSAnalyticsApi.getSMSReportingCharts({
        end_date: appendTime(filters.endDate),
        location_id: filters.locationIds,
        start_date: appendTime(filters.startDate),
        time_zone: filters.timeZone,
      }),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const phoneSummaryData: PhoneSummary = useMemo(
    () =>
      phoneData
        ? phoneData.reduce((final, { TotalInboundCalls, TotalInboundAnsweredCalls, TotalOutboundCalls }) => {
            return {
              ...final,
              answered: final.answered + (TotalInboundAnsweredCalls as number),
              incoming: final.incoming + (TotalInboundCalls as number),
              total: final.total + (TotalInboundCalls as number) + (TotalOutboundCalls as number),
            };
          }, defaultPhoneSummaryData)
        : defaultPhoneSummaryData,
    [phoneData]
  );

  const messagesSummaryData: MessagingSummary = useMemo(
    () =>
      messagingData
        ? messagingData.reduce((final, { total_incoming_messages, total_outgoing_messages }) => {
            return {
              ...final,
              total: final.total + (total_incoming_messages as number) + (total_outgoing_messages as number),
            };
          }, defaultMessagingSummaryData)
        : defaultMessagingSummaryData,
    [messagingData]
  );

  return (
    <>
      <div css={styles.summaryCardsWrapper}>
        {phoneAnalyticsEnabled && (
          <>
            <CallVolume data={phoneSummaryData} isLoading={isLoadingPhoneData} />
            <CallAnswerRate data={phoneSummaryData} isLoading={isLoadingPhoneData} />
          </>
        )}
        {messagingAnalyticsEnabled && <TotalMessages data={messagesSummaryData} isLoading={isLoadingMessagingData} />}
      </div>

      <div css={styles.flexibleCharts}>
        {phoneAnalyticsEnabled && (
          <PhoneIncomingCategoriesOnDashboard data={phoneData} isLoading={isLoadingPhoneData} />
        )}
        {messagingAnalyticsEnabled && (
          <MessagingCategoriesOnDashboard data={messagingData} isLoading={isLoadingMessagingData} />
        )}
      </div>
    </>
  );
};

const styles = {
  summaryCardsWrapper: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};

    > * {
      flex-basis: calc(100% / 3 - ${theme.spacing(2)});
      min-width: 300px;
    }
  `,

  flexibleCharts: css`
    display: flex;
    flex-wrap: wrap;
    column-gap: ${theme.spacing(3)};

    > section {
      flex-basis: calc(50% - ${theme.spacing(1.5)});
      flex-grow: 1;

      @media screen and (min-width: ${breakpoints.small.min}px) {
        min-width: 400px;
      }
    }
  `,
};
