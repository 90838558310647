import { useTranslation } from '@frontend/i18n';
import { useInboxNavigate } from '../../hooks';
import { MessagePopupMeta } from '@frontend/popup-bar';
import { useAlert } from '@frontend/design-system';

export const useMessagePopupHandler = () => {
  const { navigateToThread } = useInboxNavigate();
  const alert = useAlert();
  const { t } = useTranslation('chat');

  return {
    onMaximize: ({ meta }: { id: string; meta?: MessagePopupMeta }) => {
      if (!meta) {
        alert.error({
          message: t('Something went wrong while maximizing the chat window.'),
        });
        return;
      }

      navigateToThread(meta);
    },
  };
};
