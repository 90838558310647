import { useMemo } from 'react';
import { css } from '@emotion/react';
import { ReviewsTypes } from '@frontend/api-reviews';
import { ScheduleApi, ScheduleTypes } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMutation } from '@frontend/react-query-helpers';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Text, useModalControl, useTooltip, useAlert } from '@frontend/design-system';
import { BookingSiteContext, BookingSiteContextType, useBookingSiteContext } from '../../../context/BookingSiteContext';
import { AddToGoogleBusinessProfileIllustration, GoogleIcon } from '../../../images';
import { isValidBookingLink } from '../../../utils';
import { AddBookingLinkButton } from './AddBookingLinkButton';
import { AddBookingLinkSuccessModal } from './AddBookingLinkSuccessModal';
import { DeleteBookingLinkContainer } from './DeleteBookingLinkContainer';
import { UpdateAsPreferredBookingLink } from './UpdateAsPreferredBookingLink';

type AddBookingLinkToBusinessProfileLinkProps = {
  googleMapping: ReviewsTypes.Mapping;
  bookingSiteURL: string;
  locationId: string;
  isLocationVerified: boolean;
};

const AddBookingLinkToBusinessProfileStaticIllustration = () => {
  const { t } = useTranslation('schedule');
  return (
    <>
      <Text css={{ marginTop: theme.spacing(2) }} size='medium' color='light'>
        {t('You may only add the booking link to the same location linked to your reviews account.')}
      </Text>
      <div css={{ marginTop: theme.spacing(2) }}>
        <AddToGoogleBusinessProfileIllustration />
      </div>
    </>
  );
};

const VerifiedIcon = () => {
  const { t } = useTranslation('schedule');
  const { isLocationVerified } = useBookingSiteContext();
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'top',
  });
  return (
    <>
      {isLocationVerified && (
        <>
          <Tooltip {...tooltipProps}>{t('Verified')}</Tooltip>
          <div {...triggerProps}>
            <Icon css={badgeIconStyles} name='confirm-badge-small' color='primary' />
          </div>
        </>
      )}
    </>
  );
};

export const AddBookingLinkToBusinessProfile = ({
  googleMapping: { name, city, state, zip },
  bookingSiteURL,
  locationId,
  isLocationVerified,
}: AddBookingLinkToBusinessProfileLinkProps) => {
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });
  const alert = useAlert();
  const { t } = useTranslation('schedule');
  const address = city ? `${city}, ${state} ${zip}` : '';

  const bookingLinkAddedToBusinessProfileModalProps = useModalControl();
  const bookingLinkDeletedFromBusinessProfileModalProps = useModalControl();

  const {
    data: allBookingLinksDetails,
    isFetching: isFetchingAllBookingLinksDetails,
    refetch: refetchAllBookingLinksDetails,
  } = useLocalizedQuery({
    queryFn: () => ScheduleApi.getAllGoogleBusinessProfileAppointmentLinkDetails({ locationId }),
    queryKey: ['all-google-business-profile-appointment-links-detail', locationId],
  });

  const googleBusinessProfileBookingLinkDetails = allBookingLinksDetails?.googleBusinessProfileAppointmentLinks?.find(
    (link) => link.uri && isValidBookingLink(link.uri, locationId)
  );

  const { mutateAsync: addBookingSiteLinkToGoogleMarketPlace, isLoading: isLoadingAddBookingSiteLink } = useMutation({
    mutationFn: () =>
      ScheduleApi.setGoogleBusinessProfileAppointmentLink({ locationId: locationId, uri: bookingSiteURL }),
  });

  const { mutateAsync: deleteBookingSiteLinkToGoogleMarketPlace, isLoading: isLoadingDeleteBookingSiteLink } =
    useMutation({
      mutationFn: (resourceName?: string) =>
        ScheduleApi.deleteGoogleBusinessProfileAppointmentLink({ locationId, resourceName: resourceName || '' }),
    });

  const { mutateAsync: updateBookingSiteLinkToGoogleMarketPlace, isLoading: isLoadingUpdateBookingSiteLink } =
    useMutation({
      mutationFn: (req: ScheduleTypes.UpdateGoogleBusinessProfileAppointmentLinkType['input']) =>
        ScheduleApi.updateGoogleBusinessProfileAppointmentLink(req),
    });

  const handleAddBookingSiteLinkToGoogleBusinessProfile = () => {
    addBookingSiteLinkToGoogleMarketPlace()
      .then(() => {
        refetchAllBookingLinksDetails();
        bookingLinkAddedToBusinessProfileModalProps.openModal();
        alert.success(t('Booking Link successfully added to Google Business Profile'));
      })
      .catch(() => {
        alert.error(t('Failed to add link to Google Business Profile'));
      });
  };

  const handleDeleteBookingSiteLinkToGoogleBusinessProfile = (resourceName?: string) => {
    const hasResourceName =
      !!resourceName || (googleBusinessProfileBookingLinkDetails?.name && googleBusinessProfileBookingLinkDetails?.uri);

    if (hasResourceName) {
      deleteBookingSiteLinkToGoogleMarketPlace(googleBusinessProfileBookingLinkDetails?.name || resourceName || '')
        .then(() => {
          refetchAllBookingLinksDetails();
          bookingLinkDeletedFromBusinessProfileModalProps.openModal();
          alert.success(t('Booking Link deleted from Google Business Profile'));
        })
        .catch(() => {
          alert.error(t('Failed to delete link from Google Business Profile'));
        });
    }
  };

  const handleUpdateBookingSiteLinkToGoogleBusinessProfile = (
    req: ScheduleTypes.UpdateGoogleBusinessProfileAppointmentLinkType['input']
  ) => {
    const hasResourceName =
      !!req.resourceName ||
      (googleBusinessProfileBookingLinkDetails?.name && googleBusinessProfileBookingLinkDetails?.uri);

    if (hasResourceName) {
      updateBookingSiteLinkToGoogleMarketPlace(req)
        .then(() => {
          refetchAllBookingLinksDetails();
          alert.success(t('Booking Link Updated as preferred to Google Business Profile'));
        })
        .catch(() => {
          alert.error(t('Failed to update booking link as preferred to Google Business Profile'));
        });
    }
  };

  const weaveBookingLinkDetails = allBookingLinksDetails?.googleBusinessProfileAppointmentLinks?.filter(
    (link) => link.uri && isValidBookingLink(link.uri, locationId)
  );

  const providerValue = useMemo<BookingSiteContextType>(
    () => ({
      bookingSiteURL,
      googleBusinessProfileBookingLinkDetails: googleBusinessProfileBookingLinkDetails ?? {},
      isLocationVerified,
      weaveBookingLinkDetails: weaveBookingLinkDetails ?? [],
      handleAddBookingSiteLinkToGoogleBusinessProfile,
      handleDeleteBookingSiteLinkToGoogleBusinessProfile,
      handleUpdateBookingSiteLinkToGoogleBusinessProfile,
    }),
    [googleBusinessProfileBookingLinkDetails, weaveBookingLinkDetails, isLocationVerified, bookingSiteURL]
  );

  const hasBookingLinkInGoogleBusinessProfile =
    (googleBusinessProfileBookingLinkDetails?.name && googleBusinessProfileBookingLinkDetails?.uri) ||
    !!weaveBookingLinkDetails?.length ||
    false;

  const isAddingBookingSiteLinkToGoogleBusinessProfile =
    isLoadingAddBookingSiteLink || isFetchingAllBookingLinksDetails;

  const isDeletingBookingSiteLinkFromGoogleBusinessProfile =
    isLoadingDeleteBookingSiteLink || isFetchingAllBookingLinksDetails;

  const isUpdatingBookingSiteLinkFromGoogleBusinessProfile =
    isLoadingUpdateBookingSiteLink || isFetchingAllBookingLinksDetails;

  return (
    <BookingSiteContext.Provider value={providerValue}>
      <section css={{ marginTop: theme.spacing(4) }}>
        <div css={addBookingLinkToBusinessProfileCardStyles(isMobile)}>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <GoogleIcon />
            <section css={locationNamesStyles}>
              <div css={{ display: 'inline-flex', alignItems: 'center' }}>
                <Text as='span' size='large' weight='bold'>
                  {name}
                </Text>
                <VerifiedIcon />
              </div>
              <Text size='medium' color={!!address ? 'default' : 'light'}>
                {!!address ? address : t('No Address')}
              </Text>
            </section>
          </div>
          {googleBusinessProfileBookingLinkDetails && !googleBusinessProfileBookingLinkDetails.isPreferred ? (
            <UpdateAsPreferredBookingLink
              isMobile={isMobile}
              locationId={locationId}
              googleBookingLinkDetails={googleBusinessProfileBookingLinkDetails}
              isLoading={
                isUpdatingBookingSiteLinkFromGoogleBusinessProfile || isDeletingBookingSiteLinkFromGoogleBusinessProfile
              }
              bookingURL={googleBusinessProfileBookingLinkDetails?.uri ?? ''}
            />
          ) : hasBookingLinkInGoogleBusinessProfile ? (
            <div css={deleteContainerStyles(isMobile)}>
              <DeleteBookingLinkContainer
                isLoading={isDeletingBookingSiteLinkFromGoogleBusinessProfile}
                bookingURL={googleBusinessProfileBookingLinkDetails?.uri ?? ''}
              />
            </div>
          ) : (
            <AddBookingLinkButton isMobile={isMobile} isLoading={isAddingBookingSiteLinkToGoogleBusinessProfile} />
          )}
        </div>
        <AddBookingLinkToBusinessProfileStaticIllustration />
      </section>
      <AddBookingLinkSuccessModal
        title={t('Success!')}
        modalProps={bookingLinkAddedToBusinessProfileModalProps.modalProps}
      />
    </BookingSiteContext.Provider>
  );
};

const locationNamesStyles = css({ marginLeft: theme.spacing(2), maxWidth: theme.spacing(40) });

const badgeIconStyles = css({ margin: theme.spacing(0, 1), cursor: 'pointer', flexShrink: 0, maxWidth: 'none' });

const deleteContainerStyles = (isMobile: boolean) =>
  css({
    marginLeft: theme.spacing(2),
    ...(isMobile && { display: 'flex', justifyContent: 'flex-end' }),
  });

const addBookingLinkToBusinessProfileCardStyles = (isMobile: boolean) =>
  css({
    display: isMobile ? 'block' : 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    borderRadius: theme.borderRadius.medium,
    boxShadow: theme.shadows.light,
    width: 'fit-content',
  });
