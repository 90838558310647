import { TrialStatus } from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
import { FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { i18next } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { SelfUpgradeActionType } from './constants/self-upgrade';
import { FreeTrialEligibilityData, SelfSubscribeEligibilityData } from './types';

export const getActionText = (
  actionType: SelfUpgradeActionType
): { primaryButtonText?: string; actionInfoText?: string } => {
  switch (actionType) {
    case SelfUpgradeActionType.REQUEST_UPGRADE:
      return {
        primaryButtonText: i18next.t('Request Upgrade', { ns: 'featurePromotion>>self-upgrade-actions' }),
        actionInfoText: i18next.t('A request will be sent to your admin', {
          ns: 'featurePromotion>>self-upgrade-actions',
        }),
      };
    case SelfUpgradeActionType.SCHEDULE_CALL:
      return {
        primaryButtonText: i18next.t('Call to Upgrade', { ns: 'featurePromotion>>self-upgrade-actions' }),
      };
    case SelfUpgradeActionType.FREE_TRIAL:
      return {
        primaryButtonText: i18next.t('Try for Free', { ns: 'featurePromotion>>self-upgrade-actions' }),
      };
    case SelfUpgradeActionType.UPGRADE_NOW:
      return {
        primaryButtonText: i18next.t('Upgrade Now', { ns: 'featurePromotion>>self-upgrade-actions' }),
      };
    default:
      return {
        primaryButtonText: '',
        actionInfoText: '',
      };
  }
};

export const getActionIconName = (actionType: SelfUpgradeActionType): IconName => {
  // switch case added for future use
  switch (actionType) {
    default:
      return 'award';
  }
};

export const checkCanStartTrial = (freeTrialEligibilityData: FreeTrialEligibilityData) => {
  return (
    freeTrialEligibilityData.canManageAddons && freeTrialEligibilityData.trialStatus === TrialStatus.TRIAL_ELIGIBLE
  );
};

export const checkCanSelfSubscribe = (selfSubscribeEligibilityData: SelfSubscribeEligibilityData) => {
  return (
    selfSubscribeEligibilityData.canManageAddons &&
    !selfSubscribeEligibilityData.hasOverdueBalance &&
    !selfSubscribeEligibilityData.isStateActive &&
    selfSubscribeEligibilityData.subscriptionStatus ===
      FreeTrialSubscriptionTypes.SubscriptionStatusForSelfUpgrade.SUBSCRIBE
  );
};
