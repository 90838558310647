import { useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import { PickerLocation } from '@frontend/scope';
import { useFormField, styles, DropdownFilterChipField } from '@frontend/design-system';
import { settingsLocationPickerStyle } from '../../views/settings/overrides-styles';

export const PhoneSettingsLocationPicker = ({
  tenantLocation,
  filteredLocations,
  setFilteredLocations,
}: {
  tenantLocation: PickerLocation;
  filteredLocations: string[];
  setFilteredLocations: (locations: string[]) => void;
}) => {
  const { t } = useTranslation('phone');

  const childLocations = tenantLocation.children ?? [];
  const checkListField = useFormField({
    type: 'checklist',
    value: filteredLocations.length === 0 ? childLocations.map((loc) => loc.locationID) : filteredLocations,
  });

  useEffect(() => {
    setFilteredLocations(checkListField.value);
  }, [checkListField.value]);

  if (!childLocations) {
    return null;
  }

  return (
    <div css={settingsLocationPickerStyle}>
      <DropdownFilterChipField
        {...checkListField}
        getCustomLabel={(items) => (items.length > 1 ? `${items.length} Locations` : items[0])}
        label={t('Locations')}
        name='location-filter'
        onApply={({ close }) => {
          close();
        }}
      >
        {childLocations.map((location) => (
          <DropdownFilterChipField.Option
            key={location.locationID}
            value={location.locationID}
            css={{ label: styles.truncate }}
          >
            {location.name}
          </DropdownFilterChipField.Option>
        ))}
      </DropdownFilterChipField>
    </div>
  );
};
