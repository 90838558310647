import { http } from '@frontend/fetch';
import { ScheduleAvailabilityTypes, ScheduleAvailabilityUtils } from '@frontend/api-schedule-availability';
import { LegacySchedule } from './types';
import { transformLegacySchedule, transformScheduleToLegacy } from './utils';
import { ScheduleTypes } from '@frontend/api-schedule';

const officeHoursEndPoint = 'schedule/api/v2/officehours';
const officeHoursLegacyEndpoint = 'location/officehours';

export const getLocationLegacyOfficeHours = async (locationId: string) => {
  if (!locationId) {
    return Promise.reject('required locationId');
  }

  return await http
    .get<LegacySchedule>(officeHoursLegacyEndpoint, {
      headers: { 'Location-Id': locationId },
    })
    .then(transformLegacySchedule);
};

export const updateLocationLegacyOfficeHours = async (
  locationId: string,
  requestBody: (ScheduleTypes.Schedule | ScheduleTypes.ScheduleWithRouting)[]
) => {
  if (!locationId) {
    return Promise.reject('required locationId');
  }

  const transformed = transformScheduleToLegacy(requestBody);

  return await http.put<LegacySchedule>(officeHoursLegacyEndpoint, transformed, {
    headers: { 'Location-Id': locationId },
  });
};

export const getOfficeHours = async (locationId: string) => {
  if (!locationId) {
    return Promise.reject('requires provider id');
  }

  const schedule = await http.get<ScheduleAvailabilityTypes.Schedule>(`${officeHoursEndPoint}/${locationId}`);

  if (Object.keys(schedule).length === 0 && locationId) {
    return ScheduleAvailabilityUtils.defaultSchedule;
  }

  return schedule;
};

export const updateOfficeHours = async (locationId: string, requestBody: ScheduleAvailabilityTypes.Schedule) => {
  const scheduleClone: any = {};
  Object.entries(requestBody).map(([day, value]) => {
    scheduleClone[day as ScheduleAvailabilityTypes.DayOfWeek] = value.map((s) => ({
      ...s,
      locationId: s.locationId || locationId,
      dayOfWeek: day,
    }));
  });
  const dataObject = {
    locationId,
    schedule: scheduleClone,
  };
  return await http.put<ScheduleAvailabilityTypes.Schedule>(`${officeHoursEndPoint}/${locationId}`, dataObject, {
    headers: { 'Location-Id': locationId },
  });
};
