import { useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ModalControlModalProps } from '@frontend/design-system';
import { usePracticeAnalyticsStore } from '../../hooks';
import {
  MonthProduction,
  MorningHuddleSettingsModal,
  ProductionSummary,
  ProductionTypes,
  ScheduledProduction,
  SummaryStats,
} from '../morning-huddle';

type Props = {
  reportDate: string;
  settingModalProps: ModalControlModalProps;
};

export const MorningHuddleTabPanel = ({ reportDate, settingModalProps }: Props) => {
  const { isDemoAccount } = usePracticeAnalyticsStore();
  const [summaryStats, setSummaryStats] = useState<ProductionSummary | undefined>();
  const [isLoadingSummaryStats, setIsLoadingSummaryStats] = useState<boolean | undefined>();

  return (
    <>
      <div css={styles.contentWrapper}>
        <div css={styles.monthProductionWrapper}>
          <MonthProduction isDemoAccount={isDemoAccount} reportDate={reportDate} />
          <SummaryStats isDemoAccount={isDemoAccount} isLoading={isLoadingSummaryStats} summaryStats={summaryStats} />
        </div>
        <ProductionTypes
          isDemoAccount={isDemoAccount}
          onLoadData={setSummaryStats}
          onLoadingStatusChange={setIsLoadingSummaryStats}
          reportDate={reportDate}
        />
        <ScheduledProduction isDemoAccount={isDemoAccount} reportDate={reportDate} />
      </div>
      <MorningHuddleSettingsModal {...settingModalProps} />
    </>
  );
};

const styles = {
  contentWrapper: css`
    width: 100%;
  `,

  monthProductionWrapper: css`
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
  `,
};
