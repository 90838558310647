import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { ScheduleRequestsApi, ScheduleTypes } from '@frontend/api-schedule';
import { HttpError } from '@frontend/fetch';
import { useMultiQueryUtils } from '@frontend/payments-hooks';

const { getProviders } = ScheduleRequestsApi;

export const useGetProviders = (locationId?: string | string[]) => {
  const { locationIds } = useMultiQueryUtils();
  const queryLocationIds = Array.isArray(locationId) ? locationId : locationId ? [locationId] : locationIds;
  const providersResult = useQueries(
    queryLocationIds.map((locationId) => ({
      queryKey: ['providers', locationId],
      queryFn: () => getProviders(locationId!),
      enabled: !!locationId,
      retry: 1,
    }))
  );

  const { providers, isLoading, isFetched, error } = useMemo(
    () =>
      providersResult.reduce(
        (acc, result) => {
          const { data, isLoading, isFetched, error } = result;
          acc.providers.push(...(data ?? []));
          acc.isLoading = acc.isLoading || isLoading;
          acc.isFetched = acc.isFetched || isFetched;
          if (error instanceof HttpError) acc.error = acc.error || (error as HttpError);

          return acc;
        },
        {
          providers: [] as ScheduleTypes.Provider[],
          isLoading: false,
          isFetched: false,
          error: undefined as HttpError | undefined,
        }
      ),
    [providersResult]
  );

  return { providers, isLoading, isFetched, error };
};
