import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { theme } from '@frontend/theme';
import { ChipVariants, Text, variantsTheme } from '@frontend/design-system';
import { callIntelligenceUtils } from '../../utils';

type Citation = { text: string; time?: string; variant: ChipVariants };

type Props = {
  caller?: CallIntelligenceTypes.Person | null;
  citations?: Citation[];
  highlightedCitation?: CallIntelligenceTypes.HighlightedCitation;
  isDemoAccount?: boolean;
  officeUser?: CallIntelligenceTypes.OfficeUser | null;
  speaker: string;
  text: string;
  time: string;
};

const extractBackground = (variant: keyof typeof variantsTheme): string | undefined => {
  const cssString = variantsTheme[variant]?.styles;

  if (!cssString) return 'transparent';

  const backgroundMatch = cssString.match(/background:\s*([^;]+);/);
  return backgroundMatch ? backgroundMatch[1].trim() : 'transparent';
};

const highlightCitations = (transcript: string, citations: Citation[]): string => {
  let highlightedTranscript = transcript;
  const sortedCitations = [...citations].sort((a, b) => b.text.length - a.text.length);

  const processedTexts = new Set<string>();

  sortedCitations.forEach(({ text, variant, time }) => {
    let isSubset = false;
    for (const processedText of processedTexts) {
      if (processedText.includes(text)) {
        isSubset = true;
        break;
      }
    }

    if (!isSubset) {
      highlightedTranscript = highlightedTranscript.replace(
        text,
        `<span class="highlight" style="background-color: ${extractBackground(
          variant
        )};" data-citation="citation-${time}">${text}</span>`
      );
      processedTexts.add(text);
    }
  });

  return highlightedTranscript;
};

export const TranscriptCard = ({
  caller,
  citations = [],
  highlightedCitation,
  isDemoAccount,
  officeUser,
  speaker,
  text,
  time,
}: Props) => {
  const isOfficeUserInactive = speaker === 'office' && callIntelligenceUtils.isUserInactive(officeUser);

  const content = citations.length ? highlightCitations(text, citations) : text;

  const highlightRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    if (highlightedCitation?.time && highlightRef.current) {
      const highlightedElement = highlightRef.current.querySelector(
        `[data-citation="citation-${highlightedCitation.time}"]`
      );

      if (highlightedElement) {
        setTimeout(() => {
          highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 300);
      }
    }
  }, [highlightedCitation?.time, citations]);

  return (
    <li css={styles.wrapper}>
      <Text className='time' weight='bold'>
        {time}
      </Text>
      <Text className='transcript'>
        <strong style={{ color: isOfficeUserInactive ? theme.colors.neutral50 : 'inherit' }}>
          {speaker === 'office'
            ? !isDemoAccount
              ? `${officeUser?.firstName || ''} ${officeUser?.lastName || ''}`.trim() || speaker
              : speaker
            : !isDemoAccount
            ? `${caller?.firstName || ''} ${caller?.lastName || ''}`.trim() || speaker
            : speaker}
        </strong>
        : <span dangerouslySetInnerHTML={{ __html: content }} ref={highlightRef} />
      </Text>
    </li>
  );
};

const styles = {
  wrapper: css`
    column-gap: ${theme.spacing(2)};
    display: flex;

    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(2)};
    }

    .time {
      color: ${theme.colors.neutral30};
      flex-basis: ${theme.spacing(7)};
    }

    .transcript {
      flex: 1;

      strong {
        text-transform: capitalize;
      }
    }
  `,
};
