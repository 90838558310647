import { FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { i18next } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { SubscriptionChipData } from '../types';

type GetSubscriptionChipDataReq = {
  subscriptionStatus: FreeTrialSubscriptionTypes.SubscriptionStatusForSelfUpgrade;
  isStateActive?: boolean;
};
export const getSubscriptionChipData = ({
  subscriptionStatus,
  isStateActive,
}: GetSubscriptionChipDataReq): SubscriptionChipData => {
  if (isStateActive) {
    return {
      label: i18next.t('Subscribed', { ns: 'subscription>>location-subscription-table' }),
      variant: 'success',
    };
  }
  switch (subscriptionStatus) {
    case FreeTrialSubscriptionTypes.SubscriptionStatusForSelfUpgrade.SUBSCRIBE:
      return {
        label: i18next.t('Upgrade Now', { ns: 'subscription>>location-subscription-table' }),
        variant: 'primary',
      };
    case FreeTrialSubscriptionTypes.SubscriptionStatusForSelfUpgrade.SCHEDULE_CALL:
      return {
        label: i18next.t('Call to Upgrade', { ns: 'subscription>>location-subscription-table' }),
        variant: 'primary',
      };
    default:
      return {
        label: i18next.t('Not Eligible', { ns: 'subscription>>location-subscription-table' }),
        variant: 'default',
      };
  }
};

type GetTrialChipDataReq = {
  trialStatus: FreeTrialSubscriptionTypes.TrialStatus;
  isStateActive?: boolean;
};
export const getTrialChipData = ({ trialStatus, isStateActive }: GetTrialChipDataReq): SubscriptionChipData => {
  if (isStateActive) {
    return {
      label: i18next.t('Not Eligible', { ns: 'subscription>>location-subscription-table' }),
      variant: 'default',
    };
  }
  switch (trialStatus) {
    case FreeTrialSubscriptionTypes.TrialStatus.TRIAL_ELIGIBLE:
      return {
        label: i18next.t('Trial Available', { ns: 'subscription>>location-subscription-table' }),
        variant: 'eggplant',
        icon: <Icon name='award' size={14} />,
      };
    case FreeTrialSubscriptionTypes.TrialStatus.TRIAL_ACTIVE:
      return {
        label: i18next.t('Active Trial', { ns: 'subscription>>location-subscription-table' }),
        variant: 'success',
      };
    default:
      return {
        label: i18next.t('Not Eligible', { ns: 'subscription>>location-subscription-table' }),
        variant: 'default',
      };
  }
};
