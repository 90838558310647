import React, { ElementRef, useRef } from 'react';
import useMeasure from 'react-use-measure';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import composeRefs from '@seznam/compose-react-refs';

import {
  IconButton,
  SearchField,
  PopoverDialog,
  usePopoverDialog,
  useControlledField,
  NakedButton,
  useDebouncedFn,
  useEventListener,
} from '@frontend/design-system';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Icon } from '@frontend/icons';
import { closeGlobalSearchEvent, useGlobalSearch } from './provider';
import { useTranslation } from '@frontend/i18n';
import { RecentSearches } from './molecules';
import { useShellTheme } from '@frontend/shell-theme';
import { useGlobalRecentSearchesShallowStore } from './stores';
import { useShell } from '@frontend/shell-utils';
import { GlobalSearchTrackingIds } from './constants';

const sidePadding = 5;
const addedWidth = sidePadding * 2;

type GlobalSearchMainProps = {
  children: React.ReactNode;
};

export const TOP_BAR_HEIGHT = 72;

export const GlobalSearchMain = ({ children }: GlobalSearchMainProps) => {
  const { t } = useTranslation('global-search');
  const isXIconFlex = useMatchMedia({ maxWidth: breakpoints.medium.min + 70 });
  const isTablet = useMatchMedia({ maxWidth: breakpoints.medium.max });
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });
  const { addSearch } = useGlobalRecentSearchesShallowStore('addSearch');
  const { colorObject } = useShellTheme('colorObject');
  const { isShell, featureAvailability, isWindows } = useShell();
  const hasShellThemeEnabled = isShell && featureAvailability?.has('shell-theme');

  const [ref, { height }] = useMeasure();
  const {
    searchTerm,
    setSearchTerm,
    isExpandedView,
    setIsExpandedView,
    debouncedSearchTerm,
    setDebouncedSearchTerm,
    searchFor,
    reset,
  } = useGlobalSearch([
    'searchTerm',
    'setSearchTerm',
    'isExpandedView',
    'setIsExpandedView',
    'debouncedSearchTerm',
    'setDebouncedSearchTerm',
    'searchFor',
    'reset',
  ]);

  const onSearchChange = useDebouncedFn((searchTerm: string) => {
    setDebouncedSearchTerm(searchTerm);
  }, 300);
  const searchFieldProps = useControlledField({
    type: 'text',
    value: searchTerm,
    onChange: (value) => {
      setSearchTerm(value);
      onSearchChange(value);
    },
  });

  const { getDialogProps, getTriggerProps, open, close, isOpen } = usePopoverDialog({
    placement: 'bottom',
    middlewareOptions: {
      offset: { mainAxis: hasShellThemeEnabled ? (isExpandedView ? -40 : isTablet ? -10 : -40) : -56 },
      size: {
        padding: 0,
      },
    },
    onClose: () => {
      reset();
    },
    transform: false,
  });

  useEventListener(
    closeGlobalSearchEvent as any,
    () => {
      reset();
      close();
    },
    true
  );

  const containerRef = useRef<ElementRef<'div'>>(null);

  /**
   * TODO:
   * requesting getBoundingClientRect on every render is not performant.
   * Gotta rethink this approach
   */
  const { left, width } = containerRef.current?.getBoundingClientRect() ?? {};
  const containerWidth = width ?? 0;
  const containerLeft = left ?? 0;

  return (
    <div
      ref={composeRefs(getTriggerProps().ref as unknown as React.MutableRefObject<HTMLDivElement>, containerRef)}
      css={[
        css`
          flex-basis: 518px;
          flex-shrink: 1;
        `,
        hasShellThemeEnabled &&
          isTablet &&
          !isWindows &&
          css`
            margin-left: auto;
          `,
        hasShellThemeEnabled &&
          isTablet &&
          isWindows &&
          css`
            margin-right: auto;
          `,
      ]}
      onClick={() => {
        searchFieldProps.onFocus();
        open();
      }}
    >
      {isTablet && (
        <IconButton
          ref={getTriggerProps().ref as unknown as React.MutableRefObject<HTMLButtonElement>}
          trackingId={GlobalSearchTrackingIds.mobileSearchOpen}
          label={t('Open Global Search')}
          css={[
            css`
              margin-left: auto;
            `,
            isOpen &&
              css`
                opacity: 0;
              `,
            hasShellThemeEnabled &&
              css`
                color: ${colorObject.iconColor};
                -webkit-app-region: no-drag;
                :hover {
                  background: ${colorObject.hover} !important;
                }
              `,
            hasShellThemeEnabled &&
              isWindows &&
              css`
                margin-right: auto;
                margin-left: 0;
              `,
          ]}
          onClick={() => {
            searchFieldProps.onFocus();
            open();
          }}
        >
          <Icon name='search' />
        </IconButton>
      )}
      {!isTablet && (
        <SearchField
          name='Global Search'
          data-trackingid={GlobalSearchTrackingIds.searchField}
          onSearchSubmit={() => {
            if (!debouncedSearchTerm) return;
            setIsExpandedView(true);
          }}
          placeholder={t('Search Weave')}
          readOnly
          clearable={false}
          css={[
            css`
              height: 48px;
              width: 100%;
              max-width: 518px;
              border-radius: ${theme.borderRadius.medium};
              border: 1px solid ${theme.colors.neutral20};
              :focus {
                border: 1px solid ${theme.colors.primary20};
              }
              transition: 0.3s ease;
            `,
            isOpen &&
              css`
                opacity: 0;
              `,
            hasShellThemeEnabled &&
              css`
                height: 34px;
                border-radius: ${theme.borderRadius.medium};
                border: 1px solid ${colorObject.iconColor};
                outline: none;
                :focus {
                  border: 1px solid ${theme.colors.neutral30};
                }
              `,
          ]}
          containerCss={[
            css`
              background: ${hasShellThemeEnabled ? 'transparent' : theme.colors.white};
              border-radius: ${hasShellThemeEnabled ? 0 : theme.borderRadius.medium};
              transition: background-color 0.2s ease;
              caret-color: ${hasShellThemeEnabled ? theme.colors.white : theme.colors.neutral50};
              opacity: 1;
              & input {
                color: ${theme.colors.neutral90};
              }
              & input::placeholder {
                color: ${hasShellThemeEnabled ? colorObject.iconColor : theme.colors.neutral50} !important;
              }

              label {
                color: ${colorObject.iconColor};
              }

              button {
                width: ${hasShellThemeEnabled ? 30 : 40}px;
                height: ${hasShellThemeEnabled ? 30 : 40}px;
                ${hasShellThemeEnabled &&
                `:hover {
                  background: ${colorObject.hover} !important;
                }`}
              }

              svg {
                width: ${hasShellThemeEnabled ? 20 : 24}px;
                height: ${hasShellThemeEnabled ? 20 : 24}px;
                color: ${hasShellThemeEnabled ? colorObject.iconColor : theme.colors.neutral50};
              }
            `,
            isOpen &&
              css`
                background-color: ${theme.colors.neutral10};
                opacity: 0;
              `,
            hasShellThemeEnabled &&
              css`
                -webkit-app-region: no-drag;
                label {
                  color: ${colorObject.iconColor};
                }
              `,
          ]}
          {...searchFieldProps}
        />
      )}
      <PopoverDialog
        initial={{
          opacity: 0,
          height: 'auto',
          width: containerWidth + addedWidth,
          left: isMobile ? 20 : containerLeft - sidePadding,
          top: hasShellThemeEnabled ? (isTablet ? 40 : 6) : 4,
        }}
        animate={{
          opacity: 1,
          width: isExpandedView ? '100%' : containerWidth + addedWidth,
          height: isExpandedView ? (hasShellThemeEnabled ? '95%' : '100%') : 'auto',
          left: isExpandedView ? 0 : isMobile ? 20 : containerLeft - sidePadding,
          top: hasShellThemeEnabled ? (isExpandedView ? 40 : isTablet ? 40 : 6) : 4,
        }}
        exit={undefined}
        transition={{
          duration: 0.3,
          ease: 'linear',
        }}
        {...getDialogProps()}
        css={[
          css`
            width: ${containerWidth + addedWidth}px;
            min-width: ${isMobile ? 'min(100% - 30px , 520px)' : '440px'};
            padding: 8px 5px 16px 5px;
            border-radius: 16px;
            box-shadow: ${theme.shadows.light};
            overflow: hidden;
          `,
          hasShellThemeEnabled &&
            css`
              min-width: ${isMobile ? 'min(100% - 40px , 520px)' : '440px'};
              padding: 5px 5px 16px 5px;
              border-radius: 8px;
              top: 90px;
              -webkit-app-region: no-drag;
            `,
          isExpandedView &&
            css`
              width: 100%;
              height: ${hasShellThemeEnabled ? 95 : 100}%;
            `,
        ]}
      >
        <motion.div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            form {
              flex: 1;
              max-width: ${isExpandedView ? '748px' : '518px'};
              transition: 0.3s ease;
            }
          `}
        >
          <SearchField
            name='Global Search'
            onSearchSubmit={() => {
              if (!debouncedSearchTerm) return;
              setIsExpandedView(true);
              addSearch(searchTerm, searchFor);
            }}
            placeholder='Search'
            clearable={false}
            css={css`
              height: ${hasShellThemeEnabled ? 34 : 48}px;

              width: 100%;
              border-radius: ${theme.borderRadius.medium};
              border: 1px solid ${theme.colors.neutral20};
              box-shadow: none !important;
              outline: none;
            `}
            containerCss={[
              css`
                background: ${theme.colors.white};
                border-radius: ${theme.borderRadius.medium};
                transition: 0.2s linear;
                caret-color: ${theme.colors.neutral50};
                :focus-within {
                  background: ${theme.colors.neutral10};
                }

                & input {
                  color: ${theme.colors.neutral90};
                }
                & input::placeholder {
                  color: ${theme.colors.neutral50};
                }

                button {
                  width: ${hasShellThemeEnabled ? 30 : 40}px;
                  height: ${hasShellThemeEnabled ? 30 : 40}px;
                }

                svg {
                  width: ${hasShellThemeEnabled ? 20 : 24}px;
                  height: ${hasShellThemeEnabled ? 20 : 24}px;
                  color: ${theme.colors.neutral50};
                }
              `,
            ]}
            {...searchFieldProps}
            onFocus={(e) => {
              searchFieldProps.onFocus(e);
              open();
            }}
            onBlur={(e) => {
              searchFieldProps.onBlur(e);
            }}
          />
          {isExpandedView && (
            <IconButton
              trackingId={GlobalSearchTrackingIds.closeExpandedView}
              css={[
                !isXIconFlex &&
                  css`
                    position: absolute;
                    top: 12px;
                    right: 8px;
                  `,
              ]}
              label={t('Close Global Search')}
              onClick={() => {
                setIsExpandedView(false);
                close();
              }}
            >
              <Icon name='x' />
            </IconButton>
          )}
        </motion.div>

        <motion.div
          css={css`
            margin: 0 -5px -9px -5px;
          `}
          animate={{ height: height }}
        >
          <motion.div
            ref={ref}
            css={(theme) => css`
              max-width: ${isExpandedView ? '748px' : '518px'};
              transition: 0.3s ease;
              margin: auto;
              display: flex;
              flex-direction: column;
              max-height: ${hasShellThemeEnabled
                ? `calc(100vh - ${TOP_BAR_HEIGHT}px - ${theme.heightOffset}px)`
                : `calc(100vh - ${TOP_BAR_HEIGHT}px)`};
            `}
          >
            {!debouncedSearchTerm ? (
              <RecentSearches />
            ) : (
              <>
                {children}
                <AllResultsButton />
              </>
            )}
          </motion.div>
        </motion.div>
      </PopoverDialog>
    </div>
  );
};

const AllResultsButton = () => {
  const { t } = useTranslation('global-search');
  const { searchFor, isExpandedView, debouncedSearchTerm, setIsExpandedView } = useGlobalSearch([
    'searchFor',
    'isExpandedView',
    'debouncedSearchTerm',
    'setIsExpandedView',
  ]);

  if (isExpandedView) return null;

  return (
    <div
      css={css`
        margin: 0 -5px -8px -5px;
        border-top: 1px solid ${theme.colors.neutral20};
      `}
    >
      <NakedButton
        trackingId={GlobalSearchTrackingIds.viewAllResults}
        onClick={() => setIsExpandedView(true)}
        css={css`
          padding: ${theme.spacing(2)};
          width: 100%;
          color: ${theme.font.colors.primary};
          font-size: ${theme.font.size.medium};
          font-weight: ${theme.font.weight.bold};

          :hover {
            background-color: ${theme.colors.neutral5};
          }
        `}
      >
        {searchFor === 'all' ? t("All Results for '{{debouncedSearchTerm}}'", { debouncedSearchTerm }) : t('View More')}
      </NakedButton>
    </div>
  );
};
