import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { formatters } from '../../../utils';

export const collectPatientIds = (patients?: PracticeAnalyticsTypes.PatientInfo[], startList?: string[]): string[] => {
  return (patients || []).reduce((acc, { id }) => {
    if (!acc.includes(id)) {
      acc.push(id);
    }

    return acc;
  }, startList || []);
};

/**
 * Based on the the columns names, derive the real value to be inserted into exported file
 */

type DeriveExportValue = {
  column: string;
  data: PracticeAnalyticsTypes.PatientInfo;
  locationNames: Record<string, string>;

  insuranceNames?: Record<string, string>;
  lastContactedDates?: Record<string, string>;
};

export const deriveExportValue = ({
  column,
  data,
  insuranceNames,
  lastContactedDates,
  locationNames,
}: DeriveExportValue) => {
  switch (column) {
    case 'Patient Name':
      return `${data.FirstName} ${data.LastName ?? ''}`.trim();

    case 'Location Name':
      return data.locationId ? locationNames[data.locationId] : '';

    case 'Phone Number': {
      const phoneNumber = data.MobilePhone || data.HomePhone || data.WorkPhone;
      return phoneNumber ? formatPhoneNumber(phoneNumber) : '';
    }

    case 'Gender':
      return data.Gender || '';

    case 'Age':
      return data.age || '';

    case 'Status':
      // No translation needed as this is for export purpose
      return data.nextVisit ? 'Scheduled' : 'Unscheduled';

    case 'Last Visit':
      return data.lastVisitDate ? formatters.date.format(data.lastVisitDate) : '';

    case 'Last Contacted':
      return lastContactedDates?.[data.id] ? formatters.date.format(lastContactedDates?.[data.id]) : '';

    case 'Insurance':
      return insuranceNames?.[data.id] || '';

    case 'Smart Due Date':
      return data.dueDate ? formatters.date.format(data.dueDate) : '';

    case 'Completed Hygiene':
      return data.hygieneAppointmentDate ? formatters.date.format(data.hygieneAppointmentDate) : '';

    case '$ Value':
      return data.productionAmount ? formatters.currency.format(data.productionAmount) : '';

    case 'Procedure Codes':
      return data.procedures?.length
        ? data.procedures
            .map(({ adaCode }) => adaCode)
            .sort()
            .join(', ')
        : '';

    case 'Diagnosed':
      return data.diagnosed ? formatters.date.format(data.diagnosed) : '';

    case 'First Appt':
      return data.firstAppointmentDate ? formatters.date.format(data.firstAppointmentDate) : '';

    case 'Recapture Date':
      return data.nextAppointmentScheduledDate ? formatters.date.format(data.nextAppointmentScheduledDate) : '';

    case 'Cancelled Appt':
      return data.cancelledAppointmentDate ? formatters.date.format(data.cancelledAppointmentDate) : '';

    case 'Classification': {
      switch (data.classification) {
        case PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.HYGIENE_FOLLOW_UP:
          return 'Hygiene Follow Up';

        case PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.HYGIENE_TREATMENT_PLAN:
          return 'Hygiene Treatment Plan';

        case PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.RESTORATIVE_TREATMENT_PLAN:
          return 'Restorative Treatment Plan';

        default:
          return '';
      }
    }

    default:
      return '';
  }
};
