import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMutation } from 'react-query';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { SchemaPlatformLocationFeature, SchemaSubscriptionManagerService } from '@frontend/schema';

import { TrialSignupRequest, UpdateTrialBannerUserActionRequestType } from './types';
import { freeTrialsQueryKeys } from './queryKeys';
import {
  GetSubscriptionAddOnsStatusesResponse,
  GetSubscriptionAddOnsStatusesRequest,
} from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';

const twelveHrs = 1000 * 60 * 60 * 12;

export const useFreeTrialSignUp = () => {
  const { locationId } = useLocationDataShallowStore('locationId');
  return useMutation({
    mutationFn: (request: TrialSignupRequest) => SchemaPlatformLocationFeature.TrialSignup(request),
    retry: false,
    mutationKey: freeTrialsQueryKeys.freeTrialSignUp(locationId),
  });
};

export const useUpdateTrialBannerUserAction = () => {
  const { locationId } = useLocationDataShallowStore('locationId');
  return useMutation({
    mutationFn: (request: UpdateTrialBannerUserActionRequestType) =>
      SchemaPlatformLocationFeature.TrialBannerUserAction(request),
    retry: false,
    mutationKey: freeTrialsQueryKeys.updateTrialBannerUserAction(locationId),
  });
};

type UseGetTrialStageBannerParamsType = {
  enabled: boolean | undefined;
  enabledFeatureFlags: string[];
};

export const useGetTrialStageBanner = ({ enabled, enabledFeatureFlags }: UseGetTrialStageBannerParamsType) => {
  const { locationId } = useLocationDataShallowStore('locationId');
  return useLocalizedQuery({
    queryFn: () => SchemaPlatformLocationFeature.GetTrialStageBanner({ locationId, enabledFeatureFlags }),
    queryKey: freeTrialsQueryKeys.getTrialStageBanner,
    retry: false,
    enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
  });
};

type UseIsFreeTrialEligibleParamsType = { featureEnum: Feature; enabled: boolean };
export const useIsFreeTrialEligible = ({ featureEnum, enabled }: UseIsFreeTrialEligibleParamsType) => {
  const { locationId } = useLocationDataShallowStore('locationId');
  const request = { locationId, featureEnum };
  return useLocalizedQuery({
    queryFn: async () => await SchemaPlatformLocationFeature.IsEligibleForFreeTrial(request),
    queryKey: freeTrialsQueryKeys.isFreeTrialEligible(featureEnum),
    select: (data) => data?.isEligible,
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: twelveHrs,
    staleTime: twelveHrs,
    enabled,
  });
};

export const getSubscriptionAddOnsStatuses = async (
  locationId: GetSubscriptionAddOnsStatusesRequest
): Promise<GetSubscriptionAddOnsStatusesResponse> => {
  const resp = await SchemaSubscriptionManagerService.GetSubscriptionAddOnsStatuses(locationId);
  return resp;
};

export const useTrialHistory = (locationId: string) => {
  return useLocalizedQuery({
    queryFn: async () => await SchemaPlatformLocationFeature.GetTrialHistory({ locationId }),
    queryKey: freeTrialsQueryKeys.getTrialHistory,
    select: (data) => data,
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: true,
    cacheTime: 0,
    staleTime: 0,
  });
};
