import { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { RequestStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { ScheduleRequestStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { SendRequest } from '@weave/schema-gen-ts/dist/schemas/sms/send/v3/send_service.pb';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { ScheduleQueries, ScheduleTypes } from '@frontend/api-schedule';
import { getUser } from '@frontend/auth-helpers';
import { ActionsUI } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { SchemaSMSSendService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  IconButton,
  PrimaryButton,
  SecondaryButton,
  TextLink,
  Tray,
  Text,
  useTooltip,
  ContentLoader,
  useAlert,
} from '@frontend/design-system';
import { useAppointmentsInfoShallowStore } from '../../../../../../hooks';
import { getIsIntegratedOffice } from '../../../../../../utils';
import { usePersonSelector } from '../../../../components/PersonSelector';
import { useScheduleActionsContext } from '../../ScheduleActionsContext';
import {
  defaultTemplate,
  successIntegrateOfficeWithNoWritebackMessage,
  successIntegratedMessage,
  successNonIntegrateOfficeMessage,
} from '../constant';
import { useScheduleRequestModalContext } from '../Context/ScheduleRequestModalContext';
import { ScheduleRequestModalFormContext } from '../Context/ScheduleRequestModalFormContext';
import { useManageScheduleRequestModalDetails } from '../hooks/useManageScheduleRequestModalDetails';
import { useManageScheduleRequestModalForm } from '../hooks/useManageScheduleRequestModalForm';
import { ScheduleRequestsTrackingIds } from '../trackingIds';
import { getRequestTags, getScheduleRequestFullName, replaceTemplateTags } from '../utils';
import { ScheduleRequestModalForm } from './ScheduleRequestModalForm';
import { ScheduleRequestModalHeader } from './ScheduleRequestModalHeader';
import { ScheduleRequestModalManager } from './ScheduleRequestModalManager';
import { CreateAppointmentRequestParamsType, ScheduleRequestModalTypeEnum } from './types';

export const ScheduleRequestModal = () => {
  const {
    handleCloseScheduleRequestModal,
    refetchScheduleRequestList,
    scheduleRequestModalProps,
    selectedScheduleRequest,
  } = useScheduleRequestModalContext();
  const alert = useAlert();
  const { getLocationName } = useAppScopeStore();
  const { closeModal: closeActionsModal } = useScheduleActionsContext();
  const { t } = useTranslation('scheduleCalendarRequest');

  const { refreshAppointments } = useAppointmentsInfoShallowStore('refreshAppointments');

  const { refetchAppointmentRequestCount } = useScheduleActionsContext();

  const [modalType, setModalType] = useState<ScheduleRequestModalTypeEnum | ''>('');

  const { hasActiveFlag } = useCustomizationFlagShallowStore('flags', 'hasActiveFlag');

  const hasAppointmentWritebacks = hasActiveFlag('appointmentwritebacks');

  const [template, setTemplate] = useState<string>(defaultTemplate);

  const { Tooltip, tooltipProps, triggerProps: tooltipTriggerProps } = useTooltip({ placement: 'top' });

  const manageScheduleRequestModalDetails = useManageScheduleRequestModalDetails(selectedScheduleRequest);

  const isIntegratedOffice = getIsIntegratedOffice(manageScheduleRequestModalDetails.syncAppDataSources);

  const isSourceRequired = isIntegratedOffice && !!manageScheduleRequestModalDetails.syncAppDataSources.length;

  const isWorkstationRequired = isIntegratedOffice && !!manageScheduleRequestModalDetails.workstations.length;

  const manageScheduleRequestFormDetails = useManageScheduleRequestModalForm(selectedScheduleRequest, isSourceRequired);

  const { personSelectorDialogProps, selectedPerson, personSelectorProps } = usePersonSelector({
    placement: 'bottom-start',
    initialOffset: { x: 0, y: 45 },
    formFields: {
      firstName: { value: selectedScheduleRequest?.schedulee?.firstName ?? '', required: true },
      lastName: { value: selectedScheduleRequest?.schedulee?.lastName ?? '', required: true },
      phoneNumber: { value: selectedScheduleRequest?.schedulee?.phoneNumber ?? '', required: true },
      dateOfBirth: { value: selectedScheduleRequest?.schedulee?.birthDate ?? '' },
      email: { value: selectedScheduleRequest?.schedulee?.email ?? '', required: true },
      gender: { value: '' },
      source: {
        value: '',
        required: isSourceRequired,
      },
      clientLocationId: { value: '', required: isWorkstationRequired },
    },
    dataSources: manageScheduleRequestModalDetails.syncAppDataSources,
    clientLocationIds: manageScheduleRequestModalDetails?.access?.ClientLocations || [],
  });

  const personId = selectedPerson?.PersonID || '';
  const locationId = selectedScheduleRequest?.locationId || '';
  const phoneNumber = selectedScheduleRequest?.schedulee?.phoneNumber || selectedPerson?.MobilePhone || '';

  const {
    mutateAsync: createScheduleAppointment,
    isLoading: isLoadingCreateAppointment,
    isError: isCreateAppointmentError,
  } = ScheduleQueries.useCreateAppointment();

  const {
    mutateAsync: approveRequest,
    isLoading: isUpdateScheduleRequestLoading,
    isError: isUpdateScheduleRequestError,
  } = ScheduleQueries.useUpdateScheduleRequest();

  const {
    mutateAsync: sendSMS,
    isLoading: isSendSMSLoading,
    isError: isSendSMSError,
  } = useMutation({
    mutationFn: (req: SendRequest) => SchemaSMSSendService.Send(req),
    onError: () => {
      alert.error('Failed to send message');
    },
  });

  useEffect(() => {
    if (isCreateAppointmentError || isUpdateScheduleRequestError || isSendSMSError) {
      alert.error('Something went wrong! Please try again.');
    }
  }, [isCreateAppointmentError, isUpdateScheduleRequestError, isSendSMSError]);

  const {
    Modal: MessageModal,
    triggerProps: messageTriggerProps,
    disabledDetails,
    disabled: disabledMessage,
  } = ActionsUI.actions.useMessageAction({
    context: { personId, locationId, phoneNumber },
    onClose: () => {
      handleCloseScheduleRequestModal();
      closeActionsModal();
    },
  });

  const handleCloseModal = () => {
    scheduleRequestModalProps.closeModal();
    handleCloseScheduleRequestModal();
    refetchScheduleRequestList();
    refetchAppointmentRequestCount();
    refreshAppointments?.();
  };

  const getScheduleRequestCustomDateAndTime = () => {
    const { customDateField, customTimeField } = manageScheduleRequestFormDetails;
    if (customDateField.value && customTimeField.value) {
      const date = dayjs(customDateField.value).format('MM/DD/YYYY');
      const time = dayjs(`${date} ${customTimeField.value}`).format('hh:mm a');
      return `${date} ${time}`;
    }
    return '';
  };

  const getApproveScheduleRequestData = useCallback(() => {
    const { selectedPerson, selectedApptType, selectedProviderId, startDate } = getAppointmentRequestFormDetails();
    const approveData: ScheduleTypes.UpdateScheduleRequestType['input'] = {
      status: RequestStatus.ACCEPTED,
      reviewedBy: getUser()?.userID,
      id: selectedScheduleRequest?.id ?? '',
      locationId: selectedScheduleRequest?.locationId ?? '',
      personId: selectedPerson?.PersonID ?? '',
      appointmentTypeId: selectedApptType?.id ?? '',
      practitionerId: selectedProviderId ?? '',
      bookingDuration: selectedApptType?.durationMinutes?.toString() ?? '0',
      dateTime: dayjs(startDate).toISOString(),
      scheduleRequestStatus: ScheduleRequestStatus.ACCEPTED,
    };
    return approveData;
  }, [manageScheduleRequestModalDetails, manageScheduleRequestFormDetails, selectedPerson]);

  const getIsAppointmentDateValid = () => {
    const requestedDate = new Date(selectedScheduleRequest?.dateTime ?? '');
    const currentDate = dayjs();
    const validDate = dayjs(requestedDate).isAfter(currentDate);
    const newCustomDate = getScheduleRequestCustomDateAndTime();
    const isCustomDateValid = dayjs(newCustomDate).isAfter(currentDate);

    return validDate || isCustomDateValid;
  };

  const getSendSMSForScheduleRequestData = () => {
    const newCustomDate = getScheduleRequestCustomDateAndTime();
    const tags = getRequestTags(
      (newCustomDate || selectedScheduleRequest?.dateTime) ?? '',
      selectedScheduleRequest?.schedulee,
      getLocationName(locationId)
    );
    const templateText = replaceTemplateTags(template, tags.map);
    const mobilePhone = selectedPerson?.MobilePhone
      ? selectedPerson.MobilePhone
      : selectedScheduleRequest?.schedulee?.phoneNumber ?? '';
    const messageOptions: SendRequest = {
      locationId: selectedScheduleRequest?.locationId ?? '',
      programSlugId: 'manual-messages',
      personPhone: mobilePhone,
      body: templateText,
      shortenUrls: true,
      createdBy: getUser()?.userID,
    };
    return messageOptions;
  };

  const getApproveScheduleRequestAndSMSRequestData = useCallback(() => {
    const approveScheduleRequestData = getApproveScheduleRequestData();
    const sendSMSForScheduleRequestData = !disabledMessage ? getSendSMSForScheduleRequestData() : null;
    return {
      approveScheduleRequestData,
      sendSMSForScheduleRequestData,
    };
  }, [disabledMessage, getApproveScheduleRequestData]);

  const validateAndApproveRequest = async (appointmentId = '') => {
    const isAppointmentDateValid = getIsAppointmentDateValid();

    if (isAppointmentDateValid) {
      const { approveScheduleRequestData, sendSMSForScheduleRequestData } =
        getApproveScheduleRequestAndSMSRequestData();

      Promise.all([
        approveRequest({ appointmentId, ...approveScheduleRequestData }),
        ...(sendSMSForScheduleRequestData ? [sendSMS(sendSMSForScheduleRequestData)] : []),
      ])
        .then((res) => {
          if (res) {
            const message = hasAppointmentWritebacks
              ? successIntegratedMessage
              : isIntegratedOffice
              ? successIntegrateOfficeWithNoWritebackMessage
              : successNonIntegrateOfficeMessage;
            alert.success(message);
            scheduleRequestModalProps.closeModal();
            setModalType(ScheduleRequestModalTypeEnum.MESSAGE_SENT);
            refreshAppointments?.();
          }
        })
        .catch((err) => {
          if (err?.message?.includes('phone')) {
            setModalType(ScheduleRequestModalTypeEnum.MESSAGE_ERROR);
          } else {
            setModalType(ScheduleRequestModalTypeEnum.ERROR);
          }
        });
    } else {
      setModalType(ScheduleRequestModalTypeEnum.ERROR);
    }
  };

  const getAppointmentRequestFormDetails = useCallback((): CreateAppointmentRequestParamsType => {
    const { appointmentTypes, providers } = manageScheduleRequestModalDetails;

    const {
      scheduleRequestApprovalForm: { values },
      customDateField,
      customTimeField,
    } = manageScheduleRequestFormDetails;

    const selectedProviderId = values?.provider;
    const selectedApptType = appointmentTypes.find((item) => item.id === values.appointmentType);
    const selectedWorkstationId = values?.workstation;
    const selectedProvider = providers.find((item) => item.id === selectedProviderId);

    const getStartDate = () => {
      if (values.dateAndTime === 'custom') {
        const justDate = dayjs(customDateField.value).format('MM/DD/YYYY');
        const dateTime = dayjs(`${justDate} ${customTimeField.value}`).format('MM/DD/YYYY hh:mm a');
        return dateTime;
      }
      return dayjs(values.dateAndTime).toISOString();
    };

    return {
      selectedPerson,
      selectedProviderId: selectedProviderId ?? '',
      selectedApptType,
      selectedWorkstationId,
      startDate: getStartDate(),
      sourceId: values.source ?? '',
      selectedProviderName: selectedProvider ? `${selectedProvider.firstName} ${selectedProvider.lastName}` : '',
    };
  }, [manageScheduleRequestModalDetails, manageScheduleRequestFormDetails, selectedPerson]);

  const getCreateAppointmentRequestBody = ({
    selectedPerson,
    selectedApptType,
    selectedProviderId,
    selectedWorkstationId,
    startDate,
    sourceId,
    selectedProviderName,
  }: CreateAppointmentRequestParamsType) => {
    const createAppointmentRequestBody: ScheduleTypes.ScheduleCreateAppointmentType['input'] = {
      appointment: {
        customerIds: [selectedPerson?.PersonID ?? ''],
        start: dayjs(startDate).toISOString(),
        providerIds: selectedProviderId ? [selectedProviderId] : [],
        workstationIds: selectedWorkstationId ? [selectedWorkstationId] : [],
        duration: selectedApptType?.durationMinutes,
        type: selectedApptType?.id,
        createdBySourceId: sourceId ?? '',
        createdByLocationId: locationId ?? '',
        locationIds: [locationId],
        practitionerName: selectedProviderName,
        clientLocationId: selectedPerson?.ClientLocationID,
      },
    };
    return createAppointmentRequestBody;
  };

  const handlePostApprove = async () => {
    const isAppointmentDateValid = getIsAppointmentDateValid();
    const { selectedPerson, selectedApptType, ...restDetails } = getAppointmentRequestFormDetails();

    // TODO: Check whether we need to add a hasApptWritebacks check here
    if (isAppointmentDateValid) {
      if (selectedPerson?.PersonID && selectedApptType) {
        const createAppointmentRequestBody = getCreateAppointmentRequestBody({
          selectedApptType,
          selectedPerson,
          ...restDetails,
        });

        try {
          const appointmentData = await createScheduleAppointment(createAppointmentRequestBody);
          await validateAndApproveRequest(appointmentData?.appointment?.id || '');
        } catch (err) {
          const errorMessage = hasAppointmentWritebacks
            ? t('Failed to write back appointment')
            : t('Failed to create appointment');
          alert.error(errorMessage);
          setModalType(
            hasAppointmentWritebacks ? ScheduleRequestModalTypeEnum.WRITEBACK_ERROR : ScheduleRequestModalTypeEnum.ERROR
          );
        }
      }
    } else {
      alert.error(t('Invalid date and time. Please select a future date and time.'));
    }
  };

  const providerValue = useMemo<ScheduleRequestModalFormContext>(() => {
    return {
      manageScheduleRequestFormDetails,
      manageScheduleRequestModalDetails,
      personSelectorDialogProps,
      setTemplate,
      hasAppointmentWritebacks,
      selectedPerson,
      personSelectorProps,
    };
  }, [
    manageScheduleRequestFormDetails,
    manageScheduleRequestModalDetails,
    personSelectorDialogProps,
    hasAppointmentWritebacks,
    selectedPerson,
    personSelectorProps,
  ]);

  const isLoading = isLoadingCreateAppointment || isUpdateScheduleRequestLoading || isSendSMSLoading;

  const shouldHaveCustomFieldValues =
    manageScheduleRequestFormDetails.scheduleRequestApprovalForm.values.dateAndTime === 'custom';

  const hasCustomFieldValues = !!(
    manageScheduleRequestFormDetails.customDateField.value && manageScheduleRequestFormDetails.customTimeField.value
  );

  const disableApproveBtn =
    !manageScheduleRequestFormDetails.scheduleRequestApprovalForm.isComplete ||
    (shouldHaveCustomFieldValues && !hasCustomFieldValues) ||
    isLoading;

  return (
    <ScheduleRequestModalFormContext.Provider value={providerValue}>
      <Tray
        css={{ padding: 0 }}
        width='medium'
        {...scheduleRequestModalProps.modalProps}
        onClose={handleCloseScheduleRequestModal}
        mountTarget='#app-container'
        autoFocusTimeout={3600000 * 2}
      >
        <ContentLoader show={isLoading} />
        <Tray.Header
          css={scheduleRequestModalHeaderStyle}
          Buttons={
            <IconButton label={t('Close Schedule Request Modal')} onClick={handleCloseScheduleRequestModal}>
              <Icon name='x' />
            </IconButton>
          }
        >
          <ScheduleRequestModalHeader />
        </Tray.Header>
        <Tray.Body css={scheduleRequestModalBodyStyle}>
          <section css={{ height: '100%', backgroundColor: theme.colors.neutral5, minHeight: 400, overflow: 'scroll' }}>
            <div css={{ padding: theme.spacing(2) }}>
              <ScheduleRequestModalForm />
            </div>
          </section>
          <footer css={scheduleRequestModalFooterStyle}>
            <div {...tooltipTriggerProps}>
              <TextLink
                trackingId={ScheduleRequestsTrackingIds.requestFormMessageLinkBtn}
                weight='bold'
                disabled={!!disabledDetails}
                css={{ display: 'flex', alignItems: 'center', width: 130 }}
                onClick={() => {
                  messageTriggerProps?.onClick();
                }}
              >
                <Icon
                  style={{ marginRight: theme.spacing(0.5) }}
                  color={!!disabledDetails ? 'light' : 'primary'}
                  name='message'
                />
                {t('Message')}
                {!!disabledDetails && (
                  <Icon
                    color={!!disabledDetails ? 'light' : 'error'}
                    style={{ marginLeft: theme.spacing(0.5) }}
                    name='alert-invert'
                  />
                )}
              </TextLink>
              {!!disabledDetails && <Tooltip {...tooltipProps}>{disabledDetails}</Tooltip>}
            </div>
            <ButtonBar>
              <SecondaryButton
                trackingId={ScheduleRequestsTrackingIds.requestCancelBtn}
                css={{ width: 'max-content' }}
                onClick={handleCloseScheduleRequestModal}
              >
                <Text as='span' weight='bold'>
                  {t('Cancel')}
                </Text>
              </SecondaryButton>
              <PrimaryButton
                trackingId={ScheduleRequestsTrackingIds.requestApproveBtn}
                disabled={disableApproveBtn}
                css={scheduleRequestApproveBtnStyle}
                onClick={handlePostApprove}
              >
                <Icon name='check-small' color='white' />
                <Text color='white' css={{ marginLeft: theme.spacing(0.5) }} as='span' weight='bold'>
                  {t('Approve')}
                </Text>
              </PrimaryButton>
            </ButtonBar>
          </footer>
        </Tray.Body>
      </Tray>
      <ScheduleRequestModalManager
        modalType={modalType}
        onConfirm={validateAndApproveRequest}
        fullName={getScheduleRequestFullName(selectedScheduleRequest)}
        onClose={handleCloseModal}
        setModalType={setModalType}
      />
      {MessageModal}
    </ScheduleRequestModalFormContext.Provider>
  );
};

const scheduleRequestModalHeaderStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(3, 3, 1, 3),
});

const scheduleRequestModalBodyStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const scheduleRequestModalFooterStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  backgroundColor: theme.colors.white,
});

const scheduleRequestApproveBtnStyle = css({ width: 'max-content', '> svg': { margin: 0 } });
