import { InfiniteData, useQueryClient } from 'react-query';
import { queryKeys } from '../inbox-queries';
import { SchemaIO, SchemaSMSService } from '@frontend/schema';
import { useInvalidateInboxList } from './use-invalidate-inbox-list';
import { useCallback, useMemo } from 'react';

type GetThreadQueryData = InfiniteData<SchemaIO<(typeof SchemaSMSService)['GetThread']>['output']> | undefined;

export const useUpdateSmsTags = () => {
  const queryClient = useQueryClient();
  const invalidateInboxList = useInvalidateInboxList();

  const updateMessageTags = useCallback(
    ({
      method,
      smsId,
      tagId,
      threadId,
      locationId,
      userId,
      taggedSmsId,
    }: {
      method: 'apply' | 'dismiss';
      smsId: string;
      tagId: string;
      threadId: string;
      locationId: string;
      userId: string;
      taggedSmsId?: string;
    }) => {
      const queryKey = queryKeys.getThread({ threadId, locationId, taggedSmsId });
      const queryExists = !!queryClient.getQueryState(queryKey, { exact: true })?.data;
      if (queryExists) {
        queryClient.setQueryData<GetThreadQueryData>(queryKey, (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              thread: {
                ...page.thread,
                messages: page.thread.messages.map((message) =>
                  message.id === smsId
                    ? {
                        ...message,
                        tags:
                          method === 'apply'
                            ? message.tags.includes(tagId)
                              ? message.tags
                              : [...message.tags, tagId]
                            : message.tags.filter((id) => id !== tagId),
                        tagsDetailed:
                          method === 'apply'
                            ? [
                                ...(message.tagsDetailed ?? []),
                                { tagId, smsId: message.id, smsCreatedAt: message.createdAt, appliedBy: userId },
                              ]
                            : message.tagsDetailed?.filter((tag) => tag.tagId !== tagId),
                      }
                    : message
                ),
              },
            })),
          };
        });
      }
      invalidateInboxList();
    },
    [invalidateInboxList, queryClient]
  );

  return useMemo(() => ({ updateMessageTags }), [updateMessageTags]);
};
