import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import React from 'react';

const styles = css`
  background-color: ${theme.colors.neutral5};
  transition: background-color 0.2s;
  color: ${theme.colors.neutral70};
  margin: ${theme.spacing(0, 1)};
  padding: ${theme.spacing(2, 4)};
  border-radius: 75px;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  position: relative;
  cursor: pointer;
  * {
    cursor: pointer;
    .disabled {
      cursor: no-drop;
    }
  }

  &.green {
    background-color: ${theme.colors.success40};

    &:hover:not(:disabled) {
      background-color: ${theme.colors.success50};
      color: ${theme.colors.white};
    }

    &:active:not(:disabled) {
      background-color: ${theme.colors.success30};
    }
  }

  &:disabled {
    background-color: ${theme.colors.neutral20};
  }

  &:hover:not(:disabled) {
    background-color: ${theme.colors.neutral40};
    color: ${theme.colors.white};
  }

  &:active:not(:disabled) {
    background-color: ${theme.colors.neutral20};
  }

  label.alt-label {
    position: absolute;
    display: block;
    width: 100%;
    left: 1px;
    text-align: center;
    cursor: pointer;
    letter-spacing: 2px;
    text-transform: uppercase;
    transform: translateY(-4px);
    font-size: ${theme.fontSize(10)};
    letter-spacing: 3px;
    bottom: 6px;
  }

  label.main-label {
    display: inline-block;
    transform: translateY(-4px);
    font-size: ${theme.fontSize(28)};
  }

  label.only-label {
    display: inline-block;
    font-size: ${theme.fontSize(28)};
  }
`;

export const Button: React.FC<React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button {...props} css={styles} className={className}>
      {children}
    </button>
  );
};
