import { useMemo } from 'react';
import { useLocation } from '@tanstack/react-location';
import { AnalyticsCommonTypes, PracticeAnalyticsAggregations, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { AreaChartData, Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DemoChip } from '..';
import { usePaNavigate, usePracticeAnalyticsStore } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { formatters } from '../../utils';
import { CompareLocationsButton } from '../compare-locations-button';
import { PracticeAnalyticsInfoTips, monthlyTrendChartModal } from '.';

type Props = PracticeAnalyticsTypes.MetricProps & {
  colors: AnalyticsCommonTypes.StringRecord;
  data?: ReturnType<typeof PracticeAnalyticsAggregations.recapturedPatientsSummary>;
  isLoading?: boolean;
  labels: AnalyticsCommonTypes.StringRecord;
};

export const RecapturedMetricSingle = ({ clickNoop, colors, data, isDrillDownPage, isLoading, labels }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = usePaNavigate();
  const { current } = useLocation();
  const { filters, isDemoAccount } = usePracticeAnalyticsStore();

  const monthlyTrendModal = monthlyTrendChartModal({
    defaultKey: 'total',
    metric: 'recapturedPatients',
    tabs: { total: t('Patients'), production: t('Production') },
    title: t('Trend: Recaptured Patients'),
    trackingId: trackingIds.practiceAnalytics.recapturedPatientsTrend,
  });

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

  const chartData = useMemo(() => {
    const { recapturedPatients } = data?.aggregatedData || {};

    const processedData = recapturedPatients?.historicalData.reduce(
      (acc, { label, leadTime = 0, production = 0, timestamp, total = 0 }) => {
        const x = timestamp ? formatters.date.format(timestamp, true) : label.replace('\\n', '-');

        return {
          ...acc,
          groups: [
            ...(acc.groups || []),
            {
              name: x,
              values: {
                leadTime,
                patients: total,
                production,
              },
            },
          ],
        };
      },
      {
        groups: [],
      } as AreaChartData
    );

    return {
      data: processedData,
      totalLeadTime: recapturedPatients?.totals?.leadTime || 0,
      totalRecaupturedPatients: recapturedPatients?.totals?.patients || 0,
      totalRecaupturedProduction: recapturedPatients?.totals?.production || 0,
    };
  }, [data]);

  return (
    <>
      <Chart
        colors={colors}
        isLoading={isLoading}
        labels={labels}
        onClick={
          clickNoop
            ? undefined
            : () => {
                navigate({
                  to: `${current.pathname}/recaptured-patients`,
                });
              }
        }
        trackingId={trackingIds.practiceAnalytics.recapturedPatientsChart}
      >
        {isDrillDownPage ? (
          isDemoAccount && <DemoChip />
        ) : (
          <Chart.Header
            actions={[monthlyTrendModal.triggerProps]}
            bottomElement={
              multipleLocationsSelected ? <CompareLocationsButton style={{ marginBottom: theme.spacing(2) }} /> : null
            }
            infoTip={<PracticeAnalyticsInfoTips tip='recapturedPatients' />}
            leftElement={isDemoAccount ? <DemoChip /> : null}
            title={t('Recaptured')}
          />
        )}
        <Chart.Legends
          customData={{
            patients: {
              value: formatters.value.format(chartData?.totalRecaupturedPatients),
            },
            production: {
              value: formatters.currency.format(chartData?.totalRecaupturedProduction),
            },
            leadTime: {
              label: t('Avg. Lead Time (Days)'),
              value: formatters.value.format(Math.round(chartData?.totalLeadTime)),
            },
          }}
          fullSpacing
        />
        <Chart.AreaChart
          appearance={{
            showXAxis: true,
            showYAxis: true,
          }}
          data={chartData.data}
          formatValue={{
            leadTime: formatters.value.format,
            patients: formatters.value.format,
            production: formatters.currency.format,
          }}
          multiAxisClubIds={{
            leadTime: 'value',
            patients: 'value',
            production: 'production',
          }}
        />
      </Chart>
      {monthlyTrendModal.modalRenderer()}
    </>
  );
};
