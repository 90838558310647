import { css } from '@emotion/react';
import { ScheduleTypes } from '@frontend/api-schedule';
import { theme } from '@frontend/theme';
import { Heading, NakedButton } from '@frontend/design-system';

interface Props {
  selectLocation: (location: ScheduleTypes.ChildrenLocation) => void;
  location: ScheduleTypes.ChildrenLocation | undefined;
  selectedLocation: ScheduleTypes.ChildrenLocation | undefined;
}

export default function LocationCard({ selectLocation, location, selectedLocation }: Props) {
  return (
    <section css={styles.cardContainer}>
      <NakedButton
        data-cy='request-appointment-location-select'
        trackingId={location?.locationId}
        className={selectedLocation?.locationId === location?.locationId ? 'card selected' : 'card'}
        onClick={() => location && selectLocation(location)}
      >
        <Heading as='h3'>{location?.officeName}</Heading>
      </NakedButton>
    </section>
  );
}

const styles = {
  cardContainer: css({
    maxHeight: 300,
    overflowY: 'auto',
    '.card': {
      border: `1px solid ${theme.colors.neutral20}`,
      borderRadius: 4,
      width: `calc(100% - ${theme.spacing(1)})`,
      textAlign: 'left',
      padding: theme.spacing(2),
      margin: theme.spacing(1, 1, 1, 0),
      display: 'grid',
      gridTemplateColumns: '3fr 1fr',
      gridTemplateRows: 'auto',
      gridTemplateAreas: '"title chip" "desc desc"',
      h3: {
        fontSize: theme.fontSize(20),
        margin: theme.spacing(0),
        marginRight: theme.spacing(1),
        gridArea: 'title',
      },
      p: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
        gridArea: 'desc',
      },
      ':hover': {
        backgroundColor: theme.colors.neutral10,
      },
    },
    'button.card.selected': {
      border: `2px solid ${theme.colors.primary50}`,
      padding: `calc(${theme.spacing(2)} - 1px)`,
    },
  }),
};
