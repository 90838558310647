import React, { ComponentProps } from 'react';
import dayjs from 'dayjs';
import { css } from '@emotion/react';
import { ActionableListRow } from '@frontend/components';
import { NakedButton, XIconSmall, Text, NakedUl, styles } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { sentry } from '@frontend/tracking';

export const DefaultCloseNotificationButton = ({ onClick }: { onClick?: (e: React.MouseEvent) => void }) => {
  return (
    <NakedButton
      onClick={(e) => {
        onClick?.(e);
      }}
      css={{
        alignItems: 'center',
        background: 'white',
        borderRadius: '50%',
        boxShadow: theme.shadows.heavy,
        display: 'flex',
        height: 24,
        justifyContent: 'center',
        left: -28,
        position: 'absolute',
        width: 24,
      }}
    >
      <XIconSmall />
    </NakedButton>
  );
};

export const DefaultNotificationHeader = ({
  title,
  timestamp,
  stacked,
}: {
  title: React.ReactNode;
  stacked?: boolean;
  timestamp: string | number;
}) => {
  const getFormattedTimestamp = (timestamp: string | number) => {
    const formattedTimestamp = dayjs(timestamp).fromNow();
    if (formattedTimestamp.includes('NaN')) {
      sentry.error({
        error: "Couldn't format timestamp",
        topic: 'ephemeral-notifications',
        addContext: {
          name: "Couldn't format timestamp",
          context: { timestamp, formattedTimestamp },
        },
      });
      return 'just now';
    }
    return formattedTimestamp;
  };

  return (
    <header
      css={css`
        -webkit-user-select: none;
        user-select: none;
        -webkit-app-region: drag;
        align-items: center;
        background: ${theme.colors.neutral10};
        border-radius: ${stacked ? 0 : '8px 8px 0 0'};
        display: inline-grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
      `}
    >
      <Text as='span' css={{ gridColumn: 'span 2', padding: theme.spacing(1.5, 0.5, 1.5, 2) }}>
        {title}
      </Text>
      <Text as='span' size='small' textAlign='right' css={{ padding: theme.spacing(1.5, 1.5, 1.5, 0) }}>
        {getFormattedTimestamp(timestamp)}
      </Text>
    </header>
  );
};

export const DefaultNotificationInnerWrapper = ({
  children,
  hasActions,
}: {
  children: React.ReactNode;
  hasActions: boolean;
}) => {
  return (
    <div
      css={{
        width: '100%',
        minHeight: 92,
        ...(hasActions
          ? {
              display: 'inline-grid',
              gridTemplateColumns: '1fr 1fr 1fr',
            }
          : undefined),
      }}
    >
      {children}
    </div>
  );
};

export const DefaultNotificationBodyWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      as='span'
      css={css`
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        grid-column: span 2;
        margin: ${theme.spacing(2)};
        overflow-y: hidden;
        text-align: left;
      `}
    >
      {children}
    </Text>
  );
};

export const DefaultNotificationBody = ({ name, body }: { body: string; name: string }) => (
  <>
    {name && (
      <Text size='medium' weight='bold'>
        {name}
      </Text>
    )}
    <Text size='medium' css={styles.multilineTruncate(2)}>
      {body}
    </Text>
  </>
);

export const DefaultNotificationActions = ({
  actions,
  notificationType,
}: {
  actions: Required<ComponentProps<typeof ActionableListRow>>['actions'];
  notificationType: string;
}) => {
  return (
    <NakedUl
      css={css`
        border-left: 1px solid ${theme.colors.neutral10};
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
        /* need margin 0 for the notification app */
        margin: 0;
        button {
          font-weight: ${theme.font.weight.bold};
          height: 100%;
          width: 100%;
          color: ${theme.colors.primary50};
        }
      `}
    >
      {actions.map(({ action, label }, index) => (
        <li css={{ height: '100%' }} key={label + index}>
          <NakedButton
            trackingId={`notification-action-${notificationType}-${label}`}
            onClick={action}
            css={{
              height: '100%',
              padding: theme.spacing(1, 2),
              width: '100%',
            }}
          >
            {label}
          </NakedButton>
        </li>
      ))}
    </NakedUl>
  );
};
