import { css } from '@emotion/react';
import { LocationVoicemailBox, VoicemailBox } from '@weave/schema-gen-ts/dist/schemas/phone-exp/voicemail/mailbox.pb';
import { MailBoxType } from '@weave/schema-gen-ts/dist/schemas/phone-exp/voicemail/voicemail.pb';
import { isEqual } from 'lodash-es';
import { Chips } from '@frontend/chips';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Accordion,
  ChecklistField,
  ModalControlModalProps,
  TableFilters,
  Text,
  useFormField,
} from '@frontend/design-system';
import { VoicemailFiltersType } from '../../hooks/use-phone-config-state-store';

interface FilterProps {
  defaultFilters: VoicemailFiltersType;
  filters: VoicemailFiltersType;
  onChange: (args: VoicemailFiltersType) => void;
  mailboxes: LocationVoicemailBox[] | undefined;
}

type ConditionalProps = {
  modalProps: ModalControlModalProps;
  setShowNotificationBadge: (showNotification: boolean) => void;
};

const getMailboxDetails = (mailbox: VoicemailBox) => {
  const { mailbox: mailboxData, mailboxType, deptInfo } = mailbox;
  let vmboxName = '';
  if (!!mailboxData?.id) {
    if (mailboxType === MailBoxType.MAILBOX_TYPE_DEPARTMENT) {
      vmboxName = deptInfo?.isMainline ? 'Main Line' : deptInfo?.departmentName ?? '--';
    } else {
      vmboxName = mailboxData.name ?? '--';
    }
  }
  return {
    name: vmboxName,
    id: mailboxData?.id,
    isShared: mailboxData?.sharedAccess,
  };
};

const sortByMailboxName = (a: VoicemailBox, b: VoicemailBox) => {
  const aName = getMailboxDetails(a).name;
  const bName = getMailboxDetails(b).name;
  return aName.localeCompare(bName);
};

export const VoicemailsMultiFilters = ({
  defaultFilters,
  filters,
  onChange,
  mailboxes,
  modalProps,
  setShowNotificationBadge,
}: FilterProps & ConditionalProps) => {
  const { t } = useTranslation('calls', { keyPrefix: 'voicemails-filter' });

  const { selectedLocationIds, getLocationName } = useAppScopeStore();

  const mailboxFilterProps = useFormField(
    {
      type: 'checklist',
      value: filters.mailboxLevelOptions.mailboxIds,
    },
    [filters.mailboxLevelOptions.mailboxIds]
  );

  const personalMailboxData =
    mailboxes
      ?.flatMap(({ voicemailBoxes }) => voicemailBoxes ?? [])
      .find(({ mailboxType }) => mailboxType === 'MAILBOX_TYPE_PERSONAL') ?? null;

  const handleFiltersReset = () => {
    onChange(defaultFilters);
    modalProps?.onClose();
  };

  const handleSidebarFilters = (filters: VoicemailFiltersType) => {
    const newFilters = {
      ...filters,
      mailboxLevelOptions: {
        mailboxIds: mailboxFilterProps.value,
        syncedDeviceSipProfileId: filters.mailboxLevelOptions.syncedDeviceSipProfileId,
      },
    };
    onChange(newFilters);

    setShowNotificationBadge && setShowNotificationBadge(!isEqual(newFilters, defaultFilters));
  };

  return (
    <>
      <TableFilters
        width='medium'
        {...modalProps}
        onApplyClick={() => handleSidebarFilters(filters)}
        onClearAllClick={handleFiltersReset}
        clearAllLabel={t('Reset')}
      >
        <TableFilters.Section sectionHeaderLabel=''>
          <ChecklistField
            {...mailboxFilterProps}
            css={styles.heading}
            label={t('Voicemail Box')}
            name='voicemailBoxType'
          >
            {!!personalMailboxData && (
              <ChecklistField.Option
                css={styles.singleCheckStyle}
                name={personalMailboxData?.mailbox?.id ?? ''}
                key={personalMailboxData?.mailbox?.id ?? ''}
              >
                {personalMailboxData?.mailbox?.name ?? ''}
              </ChecklistField.Option>
            )}
            {selectedLocationIds.length === 1 &&
              mailboxes?.[0]?.voicemailBoxes
                ?.sort(sortByMailboxName)
                .map((mailbox) =>
                  getCheckListOption({ mailbox, personalMailboxId: personalMailboxData?.mailbox?.id ?? '' })
                )}
            {selectedLocationIds.length > 1 &&
              mailboxes?.map((locationData) => {
                const { locationId, voicemailBoxes } = locationData!;
                const sortedVoicemailBoxes = voicemailBoxes
                  ?.filter((box) => box?.mailbox?.id !== personalMailboxData?.mailbox?.id && box?.mailbox?.sharedAccess)
                  ?.sort(sortByMailboxName);
                return (
                  <Accordion css={styles.accordion} variant='blank' key={locationId}>
                    <Accordion.Item value={locationId ?? ''}>
                      <LocationFiltersHeader
                        locationName={locationId ? getLocationName(locationId) : ''}
                        vmBoxesCount={sortedVoicemailBoxes?.length ?? 0}
                      />
                      <Accordion.Body>
                        {sortedVoicemailBoxes?.map((mailbox) =>
                          getCheckListOption({ mailbox, personalMailboxId: personalMailboxData?.mailbox?.id ?? '' })
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })}
          </ChecklistField>
        </TableFilters.Section>
      </TableFilters>
    </>
  );
};

const getCheckListOption = ({ mailbox, personalMailboxId }: { mailbox: VoicemailBox; personalMailboxId: string }) => {
  const { id, name, isShared } = getMailboxDetails(mailbox);
  if (id === personalMailboxId || !isShared) {
    // don't show non shared mailboxes,
    // personal mailbox will be already shown in the top so don't show it again
    return null;
  }
  return (
    <ChecklistField.Option css={styles.singleCheckStyle} name={id ?? ''} key={id ?? ''}>
      {name}
    </ChecklistField.Option>
  );
};

interface LocationFiltersHeaderProps {
  locationName: string;
  vmBoxesCount: number;
}

export const LocationFiltersHeader = ({ locationName, vmBoxesCount }: LocationFiltersHeaderProps) => {
  const { t } = useTranslation('calls', { keyPrefix: 'voicemails-filter' });

  return (
    <Accordion.Header>
      <div css={styles.containerStyle}>
        <Chips.LocationChip variant='success'>{locationName}</Chips.LocationChip>
        <Text as='span' size='medium' color='light'>
          {vmBoxesCount} {t('voicemail boxes')}
        </Text>
      </div>
    </Accordion.Header>
  );
};

const styles = {
  heading: css`
    > label {
      font-weight: 700;
      font-size: ${theme.fontSize(20)};
    }
  `,

  singleCheckStyle: css`
    padding: ${theme.spacing(0, 0, 0, 2)};
  `,

  containerStyle: css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  `,

  accordion: css`
    h3 {
      button {
        border-bottom: none;
      }
    }
  `,
};
