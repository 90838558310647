import { FC, useCallback } from 'react';
import { useTranslation } from '@frontend/i18n';
import { ContentLoader, Heading, Text } from '@frontend/design-system';
import { useSendFormsStore } from '../../providers';
import { SendFormStep } from '../../types';
import SendFormsFooter from '../footer/footer.component';
import SendFormsHeader from '../header/header.component';
import AppointmentSelectionStep from './appointment-selection/appointment-selection.component';
import { DeliveryDetailComponent } from './delivery-method-selection/delivery-detail-component';
import RecipientSelectionStep from './recipient-selection/recipient-selection.component';
import SelectDocumentStep from './select-document/select-document.component';
import SelectedMessageMode from './selected-message-mode/selected-message-mode';
import { containerStyle, headingStyle } from './send-forms-steps.styles';

const SendFormsSteps: FC = () => {
  const { t } = useTranslation('forms', { keyPrefix: 'SEND_FORMS_STEPS_TITLE' });
  const { currentStep, isGeneratingSolicitedLink, messageMode } = useSendFormsStore([
    'currentStep',
    'isGeneratingSolicitedLink',
    'messageMode',
  ]);
  const getHeaderTitle = useCallback(() => {
    switch (currentStep) {
      case SendFormStep.SELECT_CONTACT:
        return t('SELECT_RECIPIENT');

      case SendFormStep.SELECT_APPOINTMENT:
        return (
          <>
            <Heading level={2} css={headingStyle}>
              {t('Link Appointment & Attach Documents')}
            </Heading>
            <Text color='subdued'>{t('(Optional)')}</Text>
          </>
        );

      case SendFormStep.SELECT_DELIVERY_METHOD:
        return t('SELECT_DELIVERY_METHOD');

      case SendFormStep.SELECT_DOCUMENT:
        return t('SELECT_DOCUMENT');

      case SendFormStep.SELECTED_MESSAGE_MODE:
        return messageMode === 'email' ? t('Share via Email') : t('Share via Kiosk');

      default:
        return '';
    }
  }, [t, currentStep]);

  const showStepContent = useCallback(() => {
    switch (currentStep) {
      case SendFormStep.SELECT_CONTACT:
        return <RecipientSelectionStep />;

      case SendFormStep.SELECT_APPOINTMENT:
        return <AppointmentSelectionStep />;

      case SendFormStep.SELECT_DELIVERY_METHOD:
        return <DeliveryDetailComponent />;

      case SendFormStep.SELECT_DOCUMENT:
        return <SelectDocumentStep />;

      case SendFormStep.SELECTED_MESSAGE_MODE:
        return <SelectedMessageMode />;

      default:
        return null;
    }
  }, [currentStep]);

  const shouldShowFooter = useCallback(() => {
    if (currentStep === SendFormStep.SELECT_CONTACT) {
      return false;
    }

    return true;
  }, [currentStep]);

  const shouldShowLocationBadge = useCallback(() => {
    return currentStep === SendFormStep.SELECT_CONTACT;
  }, [currentStep]);

  return (
    <div css={containerStyle}>
      <SendFormsHeader title={getHeaderTitle()} showLocationBadge={shouldShowLocationBadge()} />
      <section className='content'>{showStepContent()}</section>
      {shouldShowFooter() && <SendFormsFooter />}
      <ContentLoader show={isGeneratingSolicitedLink} />
    </div>
  );
};

export default SendFormsSteps;
