import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { ConfirmationModal, IconButton, useModalControl } from '@frontend/design-system';
import { useAutoReminderCheck } from '../../../auto-reminder/use-auto-reminder-check';
import { LibraryListType } from '../../../library.types';

export interface DeleteActionProps {
  id: string;
  type: LibraryListType;
  locationId: string;
  disabled: boolean;
  deleteButtonTrackingId: string;
  confirmDeleteTrackingId: string;
  cancelDeleteTrackingId: string;

  onDelete: () => void;
}

const DeleteAction: FC<DeleteActionProps> = ({
  id,
  type,
  locationId,
  disabled,
  deleteButtonTrackingId,
  confirmDeleteTrackingId,
  cancelDeleteTrackingId,
  onDelete,
}) => {
  const {
    checkIfPartOfAutoReminder,
    AutoReminderWarningModal,
    autoReminders,
    closeModal: closeAutoReminderWarningModal,
    modalProps: autoReminderWarningModalProps,
  } = useAutoReminderCheck();
  const { t } = useTranslation('forms', { keyPrefix: 'FORMS_PACKETS_HEADER' });

  const { modalProps: deleteModalProps, openModal: openDeleteFormConfirmation } = useModalControl();

  const onDeleteLibraryType = async () => {
    const { canProceed } = await checkIfPartOfAutoReminder(locationId, {
      entityId: id,
      type: type,
    });

    if (canProceed) {
      onDelete();
    }
  };

  const modalMessage =
    type === LibraryListType.PACKETS ? t('DELETE_CONFIRMATION_MESSAGE_PACKET') : t('DELETE_CONFIRMATION_MESSAGE_FORM');

  return (
    <>
      <IconButton
        disabled={disabled}
        key='trash'
        label={t('DELETE')}
        onClick={openDeleteFormConfirmation}
        trackingId={deleteButtonTrackingId}
        showLabelOnHover
      >
        <Icon name='trash' />
      </IconButton>

      <ConfirmationModal
        {...deleteModalProps}
        onConfirm={onDeleteLibraryType}
        confirmLabel={t('DELETE')}
        destructive
        message={modalMessage}
        title={t('DELETE_MODAL_TITLE')}
        cancelTrackingId={cancelDeleteTrackingId}
        confirmTrackingId={confirmDeleteTrackingId}
      />
      <AutoReminderWarningModal
        modalProps={autoReminderWarningModalProps}
        entityType={type}
        autoReminders={autoReminders}
        onClose={closeAutoReminderWarningModal}
      />
    </>
  );
};

export default DeleteAction;
