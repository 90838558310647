import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { Heading, IconButton, Text, styles } from '@frontend/design-system';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { CardsDragDrop } from './cards-drag-drop';
import { Dispatch, SetStateAction, useRef } from 'react';
import { DeviceType_Enum, ListDevice } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { DevicesQueries } from '@frontend/api-devices';
import { Icon } from '@frontend/icons';
import { NavSize, useNavSize } from '@frontend/responsiveness';

type Props = {
  deviceId: string;
  setShowDeviceLayoutPreview: Dispatch<SetStateAction<boolean>>;
};

export const LineKeyCards = ({ deviceId, setShowDeviceLayoutPreview }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const containerRef = useRef<HTMLElement | null>(null);
  const navSize = useNavSize();
  const isMobile = navSize === NavSize.Mobile;

  const { data: device } = DevicesQueries.useGetDevicesList<ListDevice | undefined>(
    {
      locationIds,
      deviceType: DeviceType_Enum.DESK_PHONE,
    },
    {
      select: ({ devices }) => {
        return devices.find((device) => device.deviceId === deviceId);
      },
    }
  );

  return (
    <section
      css={[
        containerStyles,
        css`
          ${!isMobile &&
          `
            border-right: 1px solid ${theme.colors.neutral20};
            width: ${theme.spacing(50)};
            min-width: ${theme.spacing(50)};
          `}
        `,
      ]}
    >
      <article css={{ padding: theme.spacing(3) }}>
        <Heading>{t('{{name}} Line Keys', { name: device?.name })}</Heading>
        <Text color='light'>{t('Extension {{number}}', { number: device?.extensions[0].extensionNumber })}</Text>
      </article>
      {isMobile && <MobileLayoutButton setShowDeviceLayoutPreview={setShowDeviceLayoutPreview} />}
      <article ref={containerRef} css={cardContainerStyles}>
        <CardsDragDrop containerRef={containerRef} />
      </article>
    </section>
  );
};

const MobileLayoutButton = ({
  setShowDeviceLayoutPreview,
}: {
  setShowDeviceLayoutPreview: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });

  return (
    <IconButton
      css={{ marginLeft: theme.spacing(3) }}
      onClick={() => {
        setShowDeviceLayoutPreview(true);
      }}
      showLabelAlways
      label={t('Preview Layout')}
    >
      <Icon name='view' />
    </IconButton>
  );
};

const cardContainerStyles = css`
  padding: ${theme.spacing(3)};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
  overflow-y: auto;
  width: 100%;
`;

const containerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${styles.smallScrollbar(theme)};
`;
