import { FC, useMemo } from 'react';
import { css } from '@emotion/react';
import { VoicePhoneNumber } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-numbers/phone_numbers_service.pb';
import { Text, Modal, DropdownField, ModalControlModalProps, useForm, useAlert } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { useMutation } from '@frontend/react-query-helpers';
import { formatPhoneNumber, removeNonDigits } from '@frontend/phone-numbers';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { useTranslation } from '@frontend/i18n';
import { DialApi, DialUtils, DialpadTypes } from '@frontend/api-dialpad';
import { PersonHelpers, PersonTypes } from '@frontend/api-person';
import { useSoftphoneManager } from '@frontend/weave-softphone-manager';
import { getContactLabelValue } from './contact-number-selection';

interface OutboundSelectionModalProps {
  modalProps: ModalControlModalProps;
  outboundNumbers: VoicePhoneNumber[] | undefined;
  useOutboundNumber?: boolean;
  dialNumber: string;
  person?: PersonTypes.Person;
}

export const OutboundCallerSelectionModal: FC<React.PropsWithChildren<OutboundSelectionModalProps>> = ({
  modalProps,
  outboundNumbers = [],
  dialNumber,
  useOutboundNumber,
  person,
}) => {
  const alerts = useAlert();
  const { t } = useTranslation('phone-call');
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');

  const softphoneIsConnected = useSoftphoneManager((ctx) => ctx.isConnected);

  const personName = !!person ? PersonHelpers.getFullName(person) : t('Unknown');
  const multipleContacts = PersonHelpers.getAllPhoneNumbers(person) ?? [];
  const requiresContactNumberSelection = !dialNumber && !!person && !(multipleContacts?.length === 1);
  const defaultContact = multipleContacts?.find((phone) => !!phone.number);

  const sortedVoiceNumbers = useMemo(() => {
    return DialUtils.getSortedVoiceNumbers(outboundNumbers);
  }, [outboundNumbers]);

  const defaultVoiceData = useMemo(() => DialUtils.getDefaultVoiceData(sortedVoiceNumbers), [sortedVoiceNumbers]);
  const hasSingleLocationData = sortedVoiceNumbers.length === 1;

  const { getFieldProps } = useForm({
    fields: {
      contactNumber: {
        type: 'dropdown',
        value: defaultContact ? getContactLabelValue(defaultContact) : formatPhoneNumber(dialNumber),
        required: true,
      },
      outboundNumber: {
        type: 'dropdown',
        value: defaultVoiceData.defaultNumber,
      },
    },
  });

  const outboundFieldProps = getFieldProps('outboundNumber');
  const contactFieldProps = getFieldProps('contactNumber');

  const { mutate: makeACall } = useMutation({
    mutationFn: (req: DialpadTypes.Dial['input']) => DialApi.dialUser(req),
    onSuccess: () => {
      if (!softphoneIsConnected) {
        alerts.success(t(`Call sent to desk phone.`));
      }
    },
  });

  const numberToLocationMap = DialUtils.useNumbersToLocationMap(outboundNumbers);

  const handleClose = () => {
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps} maxWidth={400}>
      <Modal.Header onClose={handleClose}>{t('Select Caller ID Number')}</Modal.Header>
      <Modal.Body>
        <section css={styles.attribute}>
          <Text as='span'>{t("Choose the Outbound Caller ID number you'd like to call from.")}</Text>
        </section>

        {!!personName && (
          <section css={styles.attribute}>
            <Text color='light' size='small' css={styles.heading}>
              {t('Contact')}
            </Text>
            <Text size='medium' css={styles.descStyles}>
              {personName}
            </Text>
          </section>
        )}

        {requiresContactNumberSelection && (
          <section css={styles.attribute}>
            <DropdownField
              {...contactFieldProps}
              css={styles.dropdownField}
              label={t('Contact Number')}
              name='contactNumber'
              required
            >
              {multipleContacts?.map((phone) => {
                return (
                  <DropdownField.OptionGroup key={phone?.label} label={phone?.label ?? ''}>
                    <DropdownField.Option
                      key={phone?.number}
                      value={getContactLabelValue(phone)}
                      displayValue={getContactLabelValue(phone)}
                    >
                      {phone?.number ?? ''}
                    </DropdownField.Option>
                  </DropdownField.OptionGroup>
                );
              })}
            </DropdownField>
          </section>
        )}

        {!requiresContactNumberSelection && (
          <section css={styles.attribute}>
            <Text color='light' size='small' css={styles.heading}>
              {t('Contact Number')}
            </Text>
            {useOutboundNumber && !!dialNumber && (
              <Text size='medium' css={styles.descStyles}>
                {formatPhoneNumber(dialNumber) ?? '-'}
              </Text>
            )}
            {!useOutboundNumber && (
              <>
                {!!dialNumber && !person && (
                  <Text size='medium' css={styles.descStyles}>
                    {formatPhoneNumber(dialNumber) ?? '-'}
                  </Text>
                )}
                {!!person && (
                  <Text size='medium' css={styles.descStyles}>
                    {formatPhoneNumber(defaultContact?.number ?? dialNumber) ?? '-'}
                  </Text>
                )}
              </>
            )}
          </section>
        )}

        <section css={styles.attribute}>
          <DropdownField
            {...outboundFieldProps}
            css={styles.dropdownField}
            label={t('Outbound Caller ID Number')}
            name='outboundNumber'
            helperText={t('This number will display as the incoming caller ID')}
          >
            {hasSingleLocationData &&
              sortedVoiceNumbers?.map((callerData) =>
                callerData?.phoneNumbers?.map((phoneNumber) => (
                  <DropdownField.Option
                    key={phoneNumber?.id}
                    value={phoneNumber?.number?.nationalNumber?.toString() ?? ''}
                  >
                    {formatPhoneNumber(phoneNumber?.number?.nationalNumber?.toString() ?? '')}
                  </DropdownField.Option>
                ))
              )}
            {!hasSingleLocationData &&
              sortedVoiceNumbers?.map((callerData) => {
                return (
                  <DropdownField.OptionGroup label={callerData?.location?.name ?? ''} key={callerData?.location?.id}>
                    {callerData?.phoneNumbers?.map((phoneNumber) => {
                      return (
                        <DropdownField.Option
                          key={phoneNumber?.id}
                          value={phoneNumber?.number?.nationalNumber?.toString() ?? ''}
                          displayValue={`${formatPhoneNumber(phoneNumber?.number?.nationalNumber?.toString() ?? '')} ${
                            callerData?.location?.name ? `(${callerData?.location.name})` : null
                          }`}
                        >
                          {formatPhoneNumber(phoneNumber?.number?.nationalNumber?.toString() ?? '')}
                        </DropdownField.Option>
                      );
                    })}
                  </DropdownField.OptionGroup>
                );
              })}
          </DropdownField>
        </section>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Dial')}
        onPrimaryClick={() => {
          makeACall({
            fromName: numberToLocationMap[outboundFieldProps.value] ?? '',
            fromNumber: outboundFieldProps.value,
            toNumber: !!dialNumber ? removeNonDigits(dialNumber) : removeNonDigits(contactFieldProps.value),
            sipProfileId: phoneConfig?.sipProfileId ?? '',
          });
          handleClose();
        }}
        disablePrimary={!dialNumber && !contactFieldProps.value}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={handleClose}
      />
    </Modal>
  );
};

type AttributeProps = {
  title: string;
  description: string;
};

export const Attribute = ({ title, description }: AttributeProps) => {
  return (
    <section css={styles.attribute}>
      <Text css={styles.heading}>{title}</Text>
      <Text size='medium' css={styles.descStyles}>
        {description}
      </Text>
    </section>
  );
};

const styles = {
  heading: css`
    text-transform: capitalize;
    color: ${theme.colors.neutral50};
    font-size: ${theme.fontSize(12)};
    margin-bottom: ${theme.spacing(0.5)};
    line-height: ${theme.spacing(2)};
  `,

  attribute: css`
    margin-top: ${theme.spacing(2)};
    max-width: 400px;

    @media (max-width: 400px) {
      max-width: auto;
    }
  `,

  descStyles: css`
    line-height: ${theme.spacing(2)};
  `,

  dropdownField: css`
    margin-top: ${theme.spacing(3)};
  `,
};
