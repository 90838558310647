import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Chip, LocationIcon, Text, useTooltip } from '@frontend/design-system';

type Props = {
  locationName?: string;
} & (
  | {
      maxWidth?: never;
      noTruncating?: boolean;
    }
  | {
      maxWidth?: number;
      noTruncating?: never;
    }
);

export const LocationChip = ({ locationName, maxWidth, noTruncating }: Props) => {
  const { Tooltip: Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top' });

  // TODO :: show tooltip only when text is truncated

  return locationName ? (
    <>
      <span {...triggerProps}>
        <Chip
          leftElement={
            <span css={styles.locationIcon}>
              <LocationIcon color='light' size={16} />
            </span>
          }
          style={noTruncating ? { maxWidth: 'none' } : maxWidth ? { maxWidth } : {}}
          variant='neutral'
        >
          {locationName}
        </Chip>
      </span>

      <Tooltip {...tooltipProps}>
        <Text size='medium' color='white'>
          {locationName}
        </Text>
      </Tooltip>
    </>
  ) : null;
};

const styles = {
  locationIcon: css`
    display: block;
    min-height: ${theme.spacing(2)};
    min-width: ${theme.spacing(2)};
  `,
};
