import { useQueryClient } from 'react-query';
import { useLocationDataShallowStore } from '@frontend/location-helpers';

import { FreeTrialsTypes } from '..';
import { freeTrialsQueryKeys } from '../queryKeys';

export const useResetIsFreeTrialEligibleQuery = () => {
  const queryClient = useQueryClient();
  const { locationId } = useLocationDataShallowStore('locationId');

  const resetQueries = (featureEnums: FreeTrialsTypes.Feature[]) => {
    for (const featureEnum of featureEnums) {
      const queryKey = [locationId, ...freeTrialsQueryKeys.isFreeTrialEligible(featureEnum)];
      queryClient.resetQueries({
        queryKey,
        exact: true,
      });
    }
  };

  return { resetQueries };
};
