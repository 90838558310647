import { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart, YAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { AnalyticsTrackingIds } from '../..';
import {
  useAnalyticsOrgLocations,
  useIsPADemoAccount,
  usePracticeAnalyticsDemoData,
  useShowPracticeAnalyticsNav,
} from '../../hooks';
import { queryKeys } from '../../query-keys';
import { DemoChip } from '../demo-chip';
import { DataSyncInProgress } from '../practice';
import { Insights } from './insights';

const query = gql`
  query {
    location {
      activePatientsScheduled {
        percentage
        benchmarks {
          value
          label
        }
      }
    }
  }
`;

type Props = {
  isLoading?: boolean;
};

const colors = {
  activePractice: theme.colors.warning50,
  averagePractice: theme.colors.primary20,
  topPractice: theme.colors.secondary.seaweed30,
};

export const PracticeAnalyticsOnDashboard = memo(({ isLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const { current } = useLocation();
  const showPACard = useShowPracticeAnalyticsNav();
  const isDemoAccount = useIsPADemoAccount();
  const demoData = usePracticeAnalyticsDemoData();
  const { hasDataSynced, isLoadingLocations, locationIds } = useAnalyticsOrgLocations({ module: 'PA' });

  const labels = {
    activePractice: locationIds.length > 1 ? t('Your Avg Practice') : t('Your Practice'),
    averagePractice: t('Average Practice'),
    topPractice: t('Top Practice'),
  };

  const { data: practiceData, isLoading: isLoadingPracticeData } = useScopedQuery({
    queryKey: queryKeys.analyticsDashboard(`homepage-practice-summary-${isDemoAccount}-${locationIds}`),
    queryFn: () =>
      isDemoAccount || !locationIds.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.ActivePatientsScheduledResponse>({
            locationIds: locationIds,
            queries: [query],
          }),
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => (isDemoAccount ? demoData?.activePatientsScheduled : data),
    staleTime: 1000 * 60 * 5,
  });

  const aggregatedData = useMemo(() => {
    const percentages = PracticeAnalyticsAggregations.activePatientsScheduledSummary(practiceData, locationIds.length);

    const activePatientsScheduledData: BarChartData = {
      groups: [
        {
          name: 'activePractice',
          values: {
            activePractice: percentages?.activePracticePercent || 0,
          },
        },
        {
          name: 'averagePractice',
          values: {
            averagePractice: percentages?.averagePracticePercent || 0,
          },
        },
        {
          name: 'topPractice',
          values: {
            topPractice: percentages?.topPracticePercent || 0,
          },
        },
      ],
    };

    return {
      chartData: activePatientsScheduledData,
      values: percentages,
    };
  }, [practiceData]);

  const getInsightText = (value: number) => {
    // If the value is between -1 and 1 then show 2 decimal places, otherwise show rounded value
    const displayValue = value < 1 && value > -1 ? Math.abs(value).toFixed(2) : Math.abs(Math.round(value));

    if (value < 0) {
      return t('Your practice is seeing {{value}}% fewer patients than the average practice.', { value: displayValue });
    } else if (value > 0) {
      return t('Your practice is seeing {{value}}% more patients than the average practice.', { value: displayValue });
    } else {
      return t('Your practice is seeing about equal patients as other practices.');
    }
  };

  if (!showPACard) {
    return null;
  }

  return (
    <Chart colors={colors} isLoading={isLoading || isLoadingLocations || isLoadingPracticeData} labels={labels}>
      <Chart.Header
        actions={[
          {
            label: t('Show More'),
            onClick: () => {
              navigate({ to: current.pathname.replaceAll('dashboard', 'practice') });
            },
            trackingId: AnalyticsTrackingIds.analytics.showMorePracticeAnalytics,
          },
        ]}
        expandable={false}
        leftElement={isDemoAccount ? <DemoChip /> : null}
        title={t('Practice Analytics')}
      />
      {!isLoadingPracticeData && (
        <>
          {!isDemoAccount && !hasDataSynced ? (
            <DataSyncInProgress miniView />
          ) : (
            <>
              <Chart.BarChart
                appearance={{
                  customXAxisTickFormat: (value) => `${value}%`,
                  customYAxisTick: ({ labels, groupName, ...rest }) => {
                    const typedVal = `${groupName}Percent` as keyof typeof aggregatedData.values;

                    return (
                      <YAxisLabelValueTick
                        {...rest}
                        clipLength={20}
                        label={labels?.[groupName] || groupName}
                        value={`${aggregatedData.values?.[typedVal] || 0}%`}
                      />
                    );
                  },
                  groupsGap: 24,
                  hideTooltip: true,
                  layout: 'vertical',
                  margin: { right: 20, left: 100 },
                  showGridLines: true,
                  showXAxis: true,
                  showYAxis: true,
                  wrapperStyles: css`
                    align-items: center;
                    display: flex;
                    height: 100%;
                  `,
                }}
                data={aggregatedData.chartData}
              />
              <Insights
                isDemoAccount={isDemoAccount}
                title={getInsightText(
                  aggregatedData.values.activePracticePercent - aggregatedData.values.averagePracticePercent
                )}
              />
            </>
          )}
        </>
      )}
    </Chart>
  );
});

PracticeAnalyticsOnDashboard.displayName = 'PracticeAnalyticsOnDashboard';
