import { FC, useMemo } from 'react';
import { PersonTypes } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { useDigitalFormsLocationsContext, DigitalFormsLocationsWrapper } from '@frontend/digital-forms-scope';
import {
  useDigitalFormsSendingExperience,
  FormsSendingExperienceTypes,
} from '@frontend/digital-forms-sending-experience';
import { FormPacketSelectorTypes } from '@frontend/form-packet-selector';
import { Icon } from '@frontend/icons';
import { FormsPromotionActionButtonWrapper } from '@frontend/self-upgrade';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';
import { ActionButton } from '@frontend/design-system';

export interface SendFormButtonProps {
  label?: string;
  context: {
    locationId: string;
    person: PersonTypes.Person;
  };
  trackingIdSuffix?: string;
}

const SendFormButtonContent: FC<SendFormButtonProps> = ({ label, context, trackingIdSuffix }) => {
  const { DigitalFormsSendingExperience, sendFormsModalControls } = useDigitalFormsSendingExperience();
  const { isValidFormsLocation } = useDigitalFormsLocationsContext();

  const minimalPersonData = useMemo<FormPacketSelectorTypes.MinimalPersonData>(() => {
    return {
      id: context.person.PersonID,
      firstName: context.person.FirstName,
      lastName: context.person.LastName,
    };
  }, [context.person]);

  function shouldDisable() {
    if (!isValidFormsLocation(context.locationId)) {
      return true;
    }

    const person = context.person;
    const hasEmail = !!person.Email;
    const hasHomePhone = !!person.HomePhone;
    const hasMobilePhone = !!person.MobilePhone;
    const hasWorkPhone = !!person.WorkPhone;

    if (!hasEmail && !hasHomePhone && !hasMobilePhone && !hasWorkPhone) {
      return true;
    }

    return false;
  }

  return (
    <>
      <ActionButton
        {...sendFormsModalControls.triggerProps}
        trackingId={`${ContactActionPrefixes.SendForm}:${trackingIdSuffix}`}
        label={label}
        disabled={shouldDisable()}
      >
        <Icon name='forms' size={16} />
      </ActionButton>

      <DigitalFormsSendingExperience
        origin={FormsSendingExperienceTypes.SendFormsOrigin.CONTACTS}
        locationId={context.locationId}
        sendFormsModalControls={sendFormsModalControls}
        person={minimalPersonData}
      />
    </>
  );
};

export const SendFormButton: FC<SendFormButtonProps> = (props) => {
  return (
    <DigitalFormsLocationsWrapper>
      <SendFormButtonContent {...props} />
    </DigitalFormsLocationsWrapper>
  );
};

export interface SendFormActionProps {
  label: string;
  context: {
    locationId: string;
    person: PersonTypes.Person;
  };
  trackingIdSuffix?: string;
}

const SendFormActionContent: FC<SendFormActionProps> = ({ label, context, trackingIdSuffix }) => {
  const { DigitalFormsSendingExperience, sendFormsModalControls } = useDigitalFormsSendingExperience();
  const { isValidFormsLocation, refreshFormsLocationValidity } = useDigitalFormsLocationsContext();

  const minimalPersonData = useMemo<FormPacketSelectorTypes.MinimalPersonData>(() => {
    return {
      id: context.person.PersonID,
      firstName: context.person.FirstName,
      lastName: context.person.LastName,
    };
  }, [context.person]);

  function shouldDisable() {
    const person = context.person;
    const hasEmail = !!person.Email;
    const hasHomePhone = !!person.HomePhone;
    const hasMobilePhone = !!person.MobilePhone;
    const hasWorkPhone = !!person.WorkPhone;

    if (!hasEmail && !hasHomePhone && !hasMobilePhone && !hasWorkPhone) {
      return true;
    }

    return false;
  }

  return (
    <>
      <FormsPromotionActionButtonWrapper
        onUpgradeSuccess={refreshFormsLocationValidity}
        shouldShowFeaturePromotion={() => {
          return !isValidFormsLocation(context.locationId) && !shouldDisable();
        }}
      >
        <AdaptoActions.Action
          {...sendFormsModalControls.triggerProps}
          trackingId={`${ContactActionPrefixes.SendForm}:${trackingIdSuffix}`}
          label={label}
          icon='forms'
        />
      </FormsPromotionActionButtonWrapper>

      <DigitalFormsSendingExperience
        origin={FormsSendingExperienceTypes.SendFormsOrigin.CONTACTS}
        locationId={context.locationId}
        sendFormsModalControls={sendFormsModalControls}
        person={minimalPersonData}
      />
    </>
  );
};

export const SendFormAction: FC<SendFormActionProps> = (props) => {
  return (
    <DigitalFormsLocationsWrapper>
      <SendFormActionContent {...props} />
    </DigitalFormsLocationsWrapper>
  );
};
