import { memo, useMemo } from 'react';
import { capitalize } from 'lodash-es';
import { PracticeAnalyticsAggregations, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import {
  BarChartAppearance,
  BarChartData,
  Chart,
  CustomAxisTickParams,
  CustomTooltipTitle,
  sequentialChartColors,
  YAxisLabelValueTick,
} from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useAnalyticsOrgLocations, usePracticeAnalyticsStore } from '../../hooks';
import { formatters } from '../../utils';
import { DemoChip } from '../demo-chip';
import { LocationChip } from '../location-chip';

type Props = PracticeAnalyticsTypes.MetricProps & {
  data?: ReturnType<typeof PracticeAnalyticsAggregations.activePatientsDetails>;
  isLoading?: boolean;
};

const subBucketTotal = (subbucket: PracticeAnalyticsTypes.Buckets['subbuckets']) => {
  return subbucket.reduce((acc, { label, total }) => (label.toLowerCase() === 'other' ? acc : acc + total), 0);
};

export const collectAllInsuranceLabels = (
  data?: ReturnType<typeof PracticeAnalyticsAggregations.activePatientsDetails>
) => {
  return Object.values(data?.rawData || {}).reduce((acc, locationDetails) => {
    const { location } = locationDetails || {};
    return [...acc, ...(location?.patientDemographics?.details?.insuranceProviders.map(({ label }) => label) || [])];
  }, [] as string[]);
};

export const ActivePatientsMetricMulti = memo(({ data, isLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const { locationNames } = useAnalyticsOrgLocations({ module: 'PA' });
  const { isDemoAccount } = usePracticeAnalyticsStore();

  const chartData = useMemo(() => {
    const entries = Object.entries(data?.rawData ?? {});
    const insuranceLabels = [...new Set(collectAllInsuranceLabels(data))];

    const ageGender: BarChartData = {
      groups: entries.map(([locationId, locationDetails]) => ({
        name: locationNames[locationId] || locationId,
        values:
          locationDetails?.location.patientDemographics?.buckets?.reduce((acc, { label, subbuckets }) => {
            return { ...acc, [label]: subBucketTotal(subbuckets) };
          }, {}) || {},
      })),
    };

    const insurance: BarChartData = {
      groups: entries.map(([locationId, locationDetails]) => ({
        name: locationNames[locationId] || locationId,
        values:
          locationDetails?.location.patientDemographics?.details?.insuranceProviders?.reduce(
            (acc, { label, total }) => {
              return { ...acc, [label]: total };
            },
            {}
          ) || {},
      })),
    };

    return {
      ageGender,
      insurance,
      insuranceColors: insuranceLabels.reduce(
        (acc, label, index) => ({ ...acc, [label]: sequentialChartColors[index] || theme.colors.neutral5 }),
        {}
      ),
      insuranceLabels: insuranceLabels.reduce((acc, label) => ({ ...acc, [label]: capitalize(label) }), {}),
    };
  }, [locationNames, data]);

  const commonProps = useMemo(() => {
    return {
      appearance: {
        collectiveTooltip: true,
        customTooltipTitle: ({ groupName }: CustomTooltipTitle) => (
          <LocationChip locationName={groupName} noTruncating />
        ),
        customYAxisTick: ({ labels, groupName, ...rest }: CustomAxisTickParams) => {
          return <YAxisLabelValueTick {...rest} clipLength={25} label={labels?.[groupName] || groupName} />;
        },
        groupsGap: 24,
        layout: 'vertical' as BarChartAppearance['layout'],
        margin: { left: 114 },
        mode: 'stacked' as BarChartAppearance['mode'],
        showGridLines: true,
        showXAxis: true,
        showYAxis: true,
      },
    };
  }, []);

  return (
    <>
      <Chart
        colors={{
          '0-12': theme.colors.secondary.seaweed30,
          '12-20': theme.colors.critical40,
          '21-30': theme.colors.primary50,
          '31-42': theme.colors.secondary.eggplant20,
          '43-55': theme.colors.warning50,
          '56-69': theme.colors.success20,
          '70+': theme.colors.secondary.eggplant50,
        }}
        isLoading={isLoading}
        labels={{
          '0-12': t('0-12'),
          '12-20': t('12-20'),
          '21-30': t('21-30'),
          '31-42': t('31-42'),
          '43-55': t('43-55'),
          '56-69': t('56-69'),
          '70+': t('70+'),
        }}
      >
        <Chart.Header
          expandable={false}
          leftElement={isDemoAccount ? <DemoChip /> : null}
          title={t('Patients by Age/Gender')}
        />
        <Chart.Legends style={{ marginBottom: theme.spacing(2) }} />
        <Chart.BarChart
          appearance={commonProps.appearance}
          data={chartData.ageGender}
          formatValue={formatters.value.format}
        />
      </Chart>

      <Chart colors={chartData.insuranceColors} isLoading={isLoading} labels={chartData.insuranceLabels}>
        <Chart.Header
          expandable={false}
          leftElement={isDemoAccount ? <DemoChip /> : null}
          title={t('Patients by Insurance')}
        />
        <Chart.Legends style={{ marginBottom: theme.spacing(2) }} />
        <Chart.BarChart
          appearance={commonProps.appearance}
          data={chartData.insurance}
          formatValue={formatters.value.format}
        />
      </Chart>
    </>
  );
});

ActivePatientsMetricMulti.displayName = 'ActivePatientsMetricMulti';
