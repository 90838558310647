import { useCallback } from 'react';
import { useMutation, useQueryClient, UseMutateAsyncFunction } from 'react-query';
import { ProviderReview, FormsQueryKeys } from '@frontend/api';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';

interface UseApproveSubmissionResults {
  approveSubmission: UseMutateAsyncFunction<
    {
      success: boolean;
    },
    unknown,
    Payload,
    unknown
  >;
  isMarkingAsApproved: boolean;
}

export const useApproveSubmission = (): UseApproveSubmissionResults => {
  const { t } = useTranslation('forms');
  const alert = useAlert();
  const queryClient = useQueryClient();

  const refetchSubmission = useCallback(
    (payload: Payload) => {
      return Promise.all([
        queryClient.invalidateQueries(FormsQueryKeys.formSubmissions),
        queryClient.invalidateQueries([FormsQueryKeys.formSubmission, payload.submissionId]),
      ]);
    },
    [queryClient]
  );

  const { mutateAsync, isLoading } = useMutation(approveSubmission, {
    onSuccess: (res) => {
      if (!res.success) {
        alert.error(t('Failed to approve the submission!'));
        return;
      }

      alert.success(t('Submission approved successfully!'));
    },
    onError: () => {
      alert.error(t('Failed to approve the submission!'));
    },
    onSettled: (_res, _err, payload) => {
      if (!payload.skipRefetch) {
        refetchSubmission(payload);
      }
    },
  });

  return {
    approveSubmission: mutateAsync,
    isMarkingAsApproved: isLoading,
  };
};

interface Payload {
  submissionId: string;
  note: string;
  signature: ProviderReview.Types.ProviderSignatureResponse;
  user: ProviderReview.Types.User;
  locationId: string;
  skipRefetch?: boolean;
}

async function approveSubmission({ submissionId, note, signature, user, locationId }: Payload) {
  const payload: ProviderReview.Types.ApproveSubmissionPayload = {
    company_id: locationId,
    submission_id: submissionId,
    note,
    review_status: ProviderReview.Types.ReviewStatus.APPROVED,
    ...signature,
    provider_details: {
      ...user,
    },
  };

  return ProviderReview.API.submitFormSubmissionReview(payload);
}
