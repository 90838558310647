import { Dispatch, SetStateAction, useState } from 'react';
import { css } from '@emotion/react';
import { ListDevice, ModelInfo } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { capitalize } from 'lodash-es';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  BannerNotification,
  Modal,
  ModalControlModalProps,
  Heading,
  Text,
  Table,
  CaretRightIconSmall,
  Chip,
} from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { PhoneSettingsLocationPicker } from '../../../settings/location-picker';
import { ShowMaxIconWithName } from '../modals/duplicate-layout.modal';

type TableProps = ModalControlModalProps & {
  device: ListDevice | undefined;
  devices: ListDevice[];
  models: Record<string, ModelInfo> | undefined;
  setPreviewDevice: Dispatch<SetStateAction<ListDevice | undefined>>;
  filteredLocations: string[];
  setFilteredLocations: Dispatch<SetStateAction<string[]>>;
};

export const ExistingLayoutDevicesTable = ({
  filteredLocations,
  setFilteredLocations,
  device,
  devices,
  models,
  setPreviewDevice,
  ...rest
}: TableProps) => {
  const [filteredGlobalData, setFilteredGlobalData] = useState<ListDevice[] | null>(null);
  const [showWarningText, setShowWarningText] = useState(false);
  const { name: deviceName } = device ?? {};
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');

  return (
    <>
      <Modal.Header textAlign='left' onClose={rest.onClose}>
        <Heading level={2} as='span'>
          {t('Use an Existing Layout')}
        </Heading>
        <Text weight='regular' color='light'>
          <Trans t={t} deviceName={deviceName}>
            Choose a device’s Line Key layout to apply to{' '}
            <Text as='span' weight='bold'>
              {deviceName}
            </Text>
            .
          </Trans>
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Table
          data={filteredGlobalData ?? devices ?? []}
          isPaginated
          hasGlobalSearch
          fullHeight
          fullHeightConfig={{
            minHeight: 300,
            maxHeight: 550,
          }}
          customToolbarRender={
            settingsTenantLocation && settingsTenantLocation?.locationType !== 'single'
              ? () => (
                  <div css={{ marginRight: 'auto' }}>
                    <PhoneSettingsLocationPicker
                      filteredLocations={filteredLocations}
                      tenantLocation={settingsTenantLocation}
                      setFilteredLocations={setFilteredLocations}
                    />
                  </div>
                )
              : undefined
          }
          globalSearchConfig={{
            searchHandler: (term) => {
              const globalSearch = devices?.filter(
                (data) =>
                  data.name?.toLocaleLowerCase().includes(term.toLocaleLowerCase()) ||
                  data.extensions?.some((ext) => ext.extensionNumber === +term)
              );
              setFilteredGlobalData(globalSearch ?? null);
            },
          }}
          rowActions={{
            actions: [
              {
                Icon: CaretRightIconSmall,
                label: '',
                onClick: (device) => {
                  setPreviewDevice(device);
                },
              },
            ],
          }}
          colConfig={[
            {
              id: 'name',
              Header: t('Name'),
              accessor: (data) => data,
              cellRenderer: ({ name, lineKeyCount }) => {
                if (!device?.model) return <Text>{name}</Text>;
                const maxKeys = models?.[device.model]?.lineKeys?.maxKeys ?? Infinity;
                const showIcon = lineKeyCount > maxKeys;
                if (showIcon) {
                  setShowWarningText(true);
                  return <ShowMaxIconWithName name={name ?? ''} />;
                }
                return <Text>{name}</Text>;
              },
            },
            {
              id: 'extension-number',
              Header: t('Extension'),
              accessor: ({ extensions }) => extensions?.[0].extensionNumber,
              minWidth: 115,
              maxWidth: 150,
            },
            {
              Header: t('Location'),
              id: 'location',
              accessor: (device) => device,
              cellRenderer: (device) => {
                if (!device.location) return '-';
                return <Chip.SingleChip>{device.location?.name}</Chip.SingleChip>;
              },
              disableSortBy: true,
              omit: settingsTenantLocation?.locationType === 'single',
              width: 150,
            },
            {
              id: 'model-name',
              Header: t('Model'),
              accessor: (data) => data,
              cellRenderer: ({ model, make }) => {
                if (!make) return '-';
                return <Text>{capitalize(make?.toLocaleLowerCase()) + ' ' + model?.toLocaleUpperCase()}</Text>;
              },
            },
          ]}
          wrapperStyle={css`
            margin: ${theme.spacing(2, 0, 2)};
            .table-toolbar {
              justify-content: space-between;
            }
          `}
        />
      </Modal.Body>
      {showWarningText && (
        <BannerNotification
          fullWidth
          css={css`
            margin: ${theme.spacing(0, 2)};
          `}
          status='warn'
          message={t(
            '{{deviceName}} does not have enough Line Key slots for these layouts. The additional Line Keys of these layouts will not appear on the {{deviceName}} device when the layout is applied.',
            {
              deviceName,
            }
          )}
        />
      )}
    </>
  );
};
