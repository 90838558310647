import { useCallback } from 'react';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { useSchemaQueryUpdaters } from '@frontend/react-query-helpers';
import { serviceName } from '../service';
import { IsBlockedIO, ServiceQueries } from '../types';

/**
 * This hook is intended to be used by react-query mutation hooks internally in api libraries.
 * Other use cases should use the corresponding mutations instead.
 */
export const useNumberBlockV1QueryUpdaters = () => {
  const queryUpdaters = useSchemaQueryUpdaters<ServiceQueries>(serviceName);
  const smsDataQueryUpdaters = SMSDataV3._QueryUpdaters.useQueryUpdaters();

  // Add custom query updaters for this service here
  const setNumberBlocked = useCallback(
    ({ locationId, personPhone: unformattedPersonPhone, isBlocked }: IsBlockedIO['input'] & { isBlocked: boolean }) => {
      const personPhone = formatPhoneNumberE164(unformattedPersonPhone);
      const updateContext: {
        previousBlockStatus?: boolean;
      } = {};

      // Update IsBlocked cache
      queryUpdaters.updateQuery({
        endpointName: 'IsBlocked',
        queryFilters: {
          queryKey: queryUpdaters.getQueryKey({
            endpointName: 'IsBlocked',
            request: {
              locationId,
              personPhone: personPhone,
            },
          }),
          exact: true,
        },
        updater: (oldData) => {
          if (updateContext.previousBlockStatus === undefined) updateContext.previousBlockStatus = oldData.isBlocked;

          return {
            ...oldData,
            isBlocked,
          };
        },
      });

      // Invalidate relevant ListThreads
      smsDataQueryUpdaters.invalidateQueries({
        endpointName: 'ListThreads',
        queryFilters: {
          predicate: ({ queryKey }) => {
            const request = queryKey[2];
            const locationIdMatches = request.locationId === locationId || !!request.groupIds?.includes(locationId);
            const personPhoneMatches = request.personPhones?.length
              ? request.personPhones.some((phone) => formatPhoneNumberE164(phone) === personPhone)
              : true;
            return locationIdMatches && personPhoneMatches;
          },
        },
      });

      // Update relevant GetThread
      smsDataQueryUpdaters.updateQuery<'GetThread', true>({
        endpointName: 'GetThread',
        queryFilters: {
          predicate: ({ state }) => {
            const threadPersonPhone = formatPhoneNumberE164(
              state?.data?.pages[0]?.thread.messages[0]?.personPhone ?? ''
            );

            if (!threadPersonPhone) return false;
            const threadIsBlocked = state?.data?.pages[0]?.thread.isBlocked;
            if (updateContext.previousBlockStatus === undefined) updateContext.previousBlockStatus = threadIsBlocked;
            return threadPersonPhone === personPhone && threadIsBlocked !== isBlocked;
          },
        },
        updater: (oldData) => {
          return {
            ...oldData,
            isBlocked,
          };
        },
      });
      return updateContext;
    },
    [
      queryUpdaters.updateQuery,
      smsDataQueryUpdaters.invalidateQueries,
      formatPhoneNumberE164,
      queryUpdaters.getQueryKey,
    ]
  );

  return {
    ...queryUpdaters,
    setNumberBlocked,
  };
};
