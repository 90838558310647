import { css } from '@emotion/react';
import {
  DepartmentPhonesUtils,
  DeptPhoneNumberApi,
  DeptPhoneNumberTypes,
} from '@frontend/api-department-phone-numbers';
import { DepartmentsTypes } from '@frontend/api-departments';
import {
  ButtonBar,
  ConfirmBadgeIcon,
  DropdownField,
  FormRow,
  IconButton,
  Modal,
  phone,
  SecondaryButton,
  Text,
  useForm,
  XIconSmall,
  useAlert,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { queryKeys } from '../../../query-keys';
import { useAppScopeStore } from '@frontend/scope';

type Props = {
  department: DepartmentsTypes.DepartmentModel;
  onAssign: (assignPayload: DeptPhoneNumberTypes.DepartmentPhoneNumberType[]) => void;
  onClose: () => void;
  phoneNumberId: string;
  phoneNumbers: DeptPhoneNumberTypes.DepartmentPhoneNumberType[];
};

/**
 *  We allow user to delete the last number from a non-mainline department, they can also assign a new number to be a default Voice/SMS number.
 */
export const ConfirmRemoveLastNumber = ({ department, onAssign, onClose, phoneNumberId, phoneNumbers }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  const alerts = useAlert();
  const [isDeletionSuccess, setIsDeletionSuccess] = useState<boolean>(false);
  const { singleLocationId: locationId } = useAppScopeStore();
  const queryClient = useQueryClient();
  const departmentId = department.id ?? '';

  const { mutate: deletePhoneNumber } = useMutation(
    (payload: DeptPhoneNumberTypes.DeletePayloadModel) => {
      return DeptPhoneNumberApi.removeNumber({
        departmentId: payload.departmentId,
        phoneNumberId: payload.phoneNumberId,
      });
    },
    {
      onSuccess: (_data, payload) => {
        alerts.success(t(`Your number {{number}} has been removed`, { number: payload.number }));
        queryClient.invalidateQueries([locationId, ...queryKeys.listDepartmentPhoneNumbers(departmentId)]);
        setIsDeletionSuccess(true);
      },
      onError: (_err, payload) => {
        alerts.error(t(`Failed to remove phone number {{number}}`, { number: payload?.number }));
      },
    }
  );

  const onConfirmDelete = () => {
    const phoneNumberData = phoneNumbers?.find(
      (item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => item.id === phoneNumberId
    );
    deletePhoneNumber({ departmentId: departmentId, phoneNumberId, number: phoneNumberData?.number });
  };

  const selectedNumber = useMemo(() => {
    return phoneNumbers.find((item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => item.id === phoneNumberId);
  }, [phoneNumberId, phoneNumbers]);

  const { availableSMSOnlyNumbers, availableVoiceOnlyNumbers, availableVoiceSMSNumbers } = useMemo(() => {
    const availableNumbers = phoneNumbers.filter(
      (item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => !item.isAssigned
    );
    return DepartmentPhonesUtils.splitNumbersByCapability(availableNumbers);
  }, [phoneNumbers]);

  const { formProps, getFieldProps, values, isComplete } = useForm({
    fields: {
      voiceID: { type: 'dropdown', required: true },
      smsID: { type: 'dropdown', required: false },
    },
  });

  const onAssignNewDefaultNumbers = () => {
    let defaults: DeptPhoneNumberTypes.DepartmentPhoneNumberType[] = [];
    const smsDefaultNumber = phoneNumbers?.find(
      (item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => item.id === values.smsID
    );
    if (values.smsID && !!smsDefaultNumber) {
      const smsDefault: DeptPhoneNumberTypes.DepartmentPhoneNumberType = {
        ...smsDefaultNumber,
        smsConfig: { ...smsDefaultNumber.smsConfig, isDefaultSms: true },
      };
      defaults.push(smsDefault);
    }
    const voiceDefaultNumber = phoneNumbers?.find(
      (item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => item.id === values.voiceID
    );
    if (values.voiceID && !!voiceDefaultNumber) {
      const voiceDefault: DeptPhoneNumberTypes.DepartmentPhoneNumberType = {
        ...voiceDefaultNumber,
        voiceConfig: { ...voiceDefaultNumber.voiceConfig, isDefaultVoice: true },
      };
      defaults.push(voiceDefault);
    }
    if (values.voiceID === values.smsID) {
      defaults = [{ ...defaults[0], smsConfig: { ...defaults[0].smsConfig, isDefaultSms: true } }];
    }
    onAssign(defaults);
    onClose();
  };

  return (
    <>
      <IconButton css={styles.closeIcon} onClick={onClose} label=''>
        <XIconSmall />
      </IconButton>
      <Modal.Header
        textAlign='left'
        css={css`
          font-size: ${theme.fontSize(24)};
        `}
      >
        {t('Remove Number')}
      </Modal.Header>
      <Modal.Body>
        <div css={styles.descDivStyles}>
          <Text weight='bold' size='large'>
            {t('If you remove the last number from this department:')}
          </Text>
          <ul css={styles.ulStyles}>
            <li>
              {t('Your SMS messages for this department will not be accessible until a new SMS number is assigned')}
            </li>
            <li>{t('You will still be able to access your call records for this department')}</li>
            <li>{t(`You can't call this department through the removed number`)}</li>
          </ul>

          {!isDeletionSuccess && (
            <ButtonBar>
              <SecondaryButton css={styles.removeBtn} onClick={onConfirmDelete}>
                {t('Remove Number')}
              </SecondaryButton>
            </ButtonBar>
          )}
          {isDeletionSuccess && (
            <Text size='medium' css={styles.successMessage}>
              <ConfirmBadgeIcon
                color='success'
                css={css`
                  margin-right: 8px;
                `}
              />{' '}
              {t('This number {{selectedNumber}} has been removed', { selectedNumber: selectedNumber?.number })}
            </Text>
          )}
        </div>
        <form {...formProps}>
          <div>
            <Text
              weight='bold'
              css={css`
                font-size: ${theme.fontSize(24)};
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              {t('Assign New Number')}
            </Text>
            <Text weight='bold' css={styles.defaultLabel}>
              {t('New Default Voice Outbound')}
            </Text>

            <FormRow css={styles.formWidth}>
              <DropdownField
                label={t('Select a number')}
                helperText={t('This number will become the default voice outbound number')}
                {...getFieldProps('voiceID')}
              >
                {[...availableVoiceSMSNumbers, ...availableVoiceOnlyNumbers].map((phoneNumber) => (
                  <DropdownField.Option
                    key={phoneNumber.id}
                    value={phoneNumber.id}
                    searchValue={`${phoneNumber.name} ${phoneNumber.number}`}
                  >
                    {phone(phoneNumber.number)}
                    {phoneNumber.smsConfig.provider ===
                    DeptPhoneNumberTypes.EmptyProviderType.SMS_PROVIDER_UNSPECIFIED ? (
                      <span> ({t('Voice only')})</span>
                    ) : (
                      ''
                    )}
                  </DropdownField.Option>
                ))}
                {availableSMSOnlyNumbers.map((phoneNumber) => (
                  <DropdownField.Option
                    key={phoneNumber.id}
                    value={phoneNumber.id}
                    searchValue={`${phoneNumber.name} ${phoneNumber.number}`}
                    disabled
                  >
                    {phone(phoneNumber.number)} ({t('SMS only')})
                  </DropdownField.Option>
                ))}
              </DropdownField>
            </FormRow>
          </div>
          <div>
            <Text weight='bold' css={styles.defaultLabel}>
              {t('New Default SMS Outbound')}
            </Text>

            <FormRow css={styles.formWidth}>
              <DropdownField
                label={t('Select a number')}
                helperText={t('This number will become the default SMS outbound number')}
                {...getFieldProps('smsID')}
              >
                {[...availableVoiceSMSNumbers, ...availableSMSOnlyNumbers].map((phoneNumber) => (
                  <DropdownField.Option
                    key={phoneNumber.id}
                    value={phoneNumber.id}
                    searchValue={`${phoneNumber.name} ${phoneNumber.number}`}
                  >
                    {phone(phoneNumber.number)}
                    {phoneNumber.voiceConfig.provider ===
                    DeptPhoneNumberTypes.EmptyProviderType.VOICE_PROVIDER_UNSPECIFIED ? (
                      <span> ({t('SMS only')})</span>
                    ) : (
                      ''
                    )}
                  </DropdownField.Option>
                ))}
                {availableVoiceOnlyNumbers.map((phoneNumber) => (
                  <DropdownField.Option
                    key={phoneNumber.id}
                    value={phoneNumber.id}
                    searchValue={`${phoneNumber.name} ${phoneNumber.number}`}
                    disabled
                  >
                    {phone(phoneNumber.number)} ({t('Voice only')})
                  </DropdownField.Option>
                ))}
              </DropdownField>
            </FormRow>
          </div>
        </form>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Assign New Number')}
        onPrimaryClick={onAssignNewDefaultNumbers}
        disablePrimary={!isComplete}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
      />
    </>
  );
};

const styles = {
  closeIcon: css`
    padding: ${theme.spacing(0.5)};
    box-sizing: border-box;
    position: absolute;
    top: ${theme.spacing(1)};
    right: ${theme.spacing(1)};
  `,

  defaultLabel: css`
    font-size: ${theme.fontSize(18)};
    margin-bottom: ${theme.spacing(2)};
  `,

  descDivStyles: css`
    line-height: ${theme.spacing(2.5)};
    margin-bottom: ${theme.spacing(1)};
  `,

  formWidth: css`
    width: 350px;
  `,

  removeBtn: css`
    width: 182px;
    border: 2px solid ${theme.colors.status.criticalPressed};
    box-sizing: border-box;
    border-radius: 100px;
    color: ${theme.colors.status.criticalPressed};
  `,

  successMessage: css`
    display: flex;
    margin-top: ${theme.spacing(2)};
    align-items: center;
  `,

  ulStyles: css`
    margin-top: ${theme.spacing(2)};
    font-size: ${theme.fontSize(14)};
  `,
};
