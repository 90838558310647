import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { InfoIcon, Modal, Text } from '@frontend/design-system';

type RefundErrorProps = {
  errorMessage?: string;
  onTryAgain: () => void;
};

export const RefundError = ({ onTryAgain }: RefundErrorProps) => {
  const { t } = useTranslation('payments');
  return (
    <>
      <Text
        color='white'
        textAlign='center'
        css={css`
          padding: ${theme.spacing(2)};
          background-color: ${theme.colors.status.warning};
          margin: 0;
          display: flex;
          justify-content: center;
        `}
      >
        <InfoIcon
          color='white'
          css={css`
            margin-right: ${theme.spacing(2)};
          `}
        />
        {t('Refund is processing!')}
      </Text>
      <Text
        textAlign='center'
        css={css`
          padding: ${theme.spacing(3, 3, 0)};
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
      >
        {t('It is taking longer than usual for your refund to be processed.')}
      </Text>
      <Text
        textAlign='center'
        css={css`
          padding: ${theme.spacing(3, 3, 0)};
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
      >
        {t('To see if the refund was successful, please wait up to 3 minutes and refresh this page.')}
      </Text>
      <Modal.Actions
        primaryLabel={t('Okay')}
        onPrimaryClick={onTryAgain}
        css={css`
          padding: ${theme.spacing(0, 3)};
          margin-top: ${theme.spacing(3)};
        `}
      />
    </>
  );
};
