import React, { useState } from 'react';
import { css } from '@emotion/react';
import { StatsType, AnalyticTile } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DigitalFormsQueries } from '@frontend/api-digital-forms';
import { Chart } from '@frontend/charts';
import { Unit } from '@frontend/date';
import {
  DashboardWidget,
  TimePeriodListBoxMenu,
  useDashboardWidget,
  type DashboardWidgetFC,
} from '@frontend/grid-dashboard';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Info, NakedButton, SpinningLoader, Text } from '@frontend/design-system';
import { formatNumber } from './helpers';

/**
 * @dashboard-widget
 *
 * id: form-sent-comprehensive
 * title: Form Sent
 * description: Shows the delivery method of Forms sent.
 * icon: forms-small
 */

export const FormsSentComprehensiveWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('dashboard');
  const { currentSize } = useDashboardWidget();
  const [selectedSection, setSelectedSection] = useState<'unique' | 'public'>('unique');
  const startDate = dayjs().subtract(1, Unit.MONTH).toISOString();
  const endDate = dayjs().toISOString();
  const { selectedLocationIds } = useAppScopeStore();

  const { data, isLoading } = DigitalFormsQueries.useFormStats({
    companyIds: selectedLocationIds,
    startDate,
    endDate,
    statsType: StatsType.COMPREHENSIVE_TOTAL_FORM_SENT,
  });

  const uniqueLinkDelivered = data?.categories?.[0];
  const publicLinkDelivered = data?.categories?.[1];

  const isLargeWide = currentSize === 'large-wide';
  const isMediumNarrow = currentSize === 'medium-narrow';

  return (
    <DashboardWidget>
      <DashboardWidget.Header>
        <TimePeriodListBoxMenu value={Unit.MONTH} readonly />
      </DashboardWidget.Header>
      <DashboardWidget.Content
        css={[
          dashboardContentStyles,
          !isLargeWide &&
            css`
              width: 100%;
              justify-content: center;
            `,
          isLoading &&
            css`
              align-items: center;
              justify-content: center;
            `,
          isMediumNarrow &&
            css`
              .recharts-responsive-container {
                max-height: 30px;
              }
            `,
        ]}
      >
        {isLoading ? (
          <SpinningLoader />
        ) : (
          <>
            <div
              css={[
                statsContainerStyles,
                isMediumNarrow &&
                  css`
                    flex-direction: column;
                    align-items: initial;
                    gap: ${theme.spacing(1.5)};
                  `,
              ]}
            >
              <div
                css={[
                  totalStatsStyles,
                  isMediumNarrow &&
                    css`
                      text-align: center;
                    `,
                ]}
              >
                <Text weight='bold' className='total-count'>
                  {formatNumber(data?.totalCount ?? 0)}
                </Text>
                <Text size='medium' color='subdued' className='total-label'>
                  {t('Sent this month')}
                </Text>
              </div>
              <DynamicFragmentWrapper showFragment={!isMediumNarrow}>
                <FormsSentComprehensiveSection
                  chartColor={theme.colors.success30}
                  dataSet={uniqueLinkDelivered?.dataSet?.map((val) => val.value ?? 0)}
                  label={uniqueLinkDelivered?.label}
                  description={uniqueLinkDelivered?.infoTag}
                  value={uniqueLinkDelivered?.totalCount}
                  onClick={isLargeWide ? () => setSelectedSection('unique') : undefined}
                  isSelected={selectedSection === 'unique' && isLargeWide}
                />
                <FormsSentComprehensiveSection
                  chartColor={theme.colors.secondary.eggplant30}
                  dataSet={publicLinkDelivered?.dataSet?.map((val) => val.value ?? 0)}
                  label={publicLinkDelivered?.label}
                  description={publicLinkDelivered?.infoTag}
                  value={publicLinkDelivered?.totalCount}
                  onClick={isLargeWide ? () => setSelectedSection('public') : undefined}
                  isSelected={selectedSection === 'public' && isLargeWide}
                />
              </DynamicFragmentWrapper>
            </div>
            {isLargeWide && (
              <DetailedTiles
                label={selectedSection === 'unique' ? uniqueLinkDelivered?.label : publicLinkDelivered?.label}
                detailedTiles={
                  selectedSection === 'unique' ? uniqueLinkDelivered?.detailedTiles : publicLinkDelivered?.detailedTiles
                }
              />
            )}
          </>
        )}
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

FormsSentComprehensiveWidget.config = {
  size: {
    large: 'large-wide',
    medium: 'medium-wide',
    small: 'medium-narrow',
    extraSmall: 'medium-narrow',
  },
  feature: 'forms',
};

interface FormsSentComprehensiveSectionProps {
  value?: number;
  dataSet?: number[];
  chartColor: string;
  label?: string;
  description?: string;
  onClick?: () => void;
  isSelected?: boolean;
}

const FormsSentComprehensiveSection: React.FC<FormsSentComprehensiveSectionProps> = ({
  value = 0,
  dataSet,
  chartColor,
  label,
  description,
  onClick,
  isSelected,
}) => {
  const { currentSize } = useDashboardWidget();
  const isLargeWide = currentSize === 'large-wide';
  const isMediumNarrow = currentSize === 'medium-narrow';

  return (
    <NakedButton
      onClick={onClick}
      css={[
        sectionStyles,
        isSelected &&
          css`
            &::before {
              border: 2px solid ${theme.colors.primary50};
            }
          `,
        isLargeWide &&
          css`
            height: 120px;
          `,
        isMediumNarrow &&
          css`
            height: auto;
          `,
      ]}
    >
      <div css={sectionContentStyles}>
        <div css={sectionHeaderStyles}>
          {label && (
            <Text size='small' weight='semibold'>
              {isMediumNarrow ? label?.replace('Delivered via', '') : label}
            </Text>
          )}
          <Info hoverDelay={500} trigger='hover'>
            {description}
          </Info>
        </div>
        <div
          css={[
            sectionChartStyles,
            isMediumNarrow &&
              css`
                height: 30px;
                margin-top: ${theme.spacing(2)};
              `,
          ]}
        >
          <Text weight='bold' css={sectionValueStyles}>
            {formatNumber(value)}
          </Text>
          <Chart.TinyAreaChart data={dataSet ?? []} color={chartColor} />
        </div>
      </div>
    </NakedButton>
  );
};

interface DetailedTilesProps {
  label?: string;
  detailedTiles: AnalyticTile[] | undefined;
}

const DetailedTiles: React.FC<DetailedTilesProps> = ({ detailedTiles, label }) => {
  return (
    <div css={detailedTilesStyles}>
      {label && <Text>{label}</Text>}
      <div css={tilesContainerStyles}>
        {detailedTiles?.map((tile, index) => (
          <DetailedTilesSection key={index} {...tile} />
        ))}
      </div>
    </div>
  );
};

interface DynamicFragmentWrapperProps {
  showFragment?: boolean;
  children: React.ReactNode;
}

const DynamicFragmentWrapper = ({ showFragment, children }: DynamicFragmentWrapperProps) => {
  return showFragment ? (
    <>{children}</>
  ) : (
    <div
      css={css`
        display: flex;
        gap: ${theme.spacing(2)};
      `}
    >
      {children}
    </div>
  );
};

const DetailedTilesSection: React.FC<AnalyticTile> = ({ value = 0, label, infoTag }) => {
  return (
    <div css={detailedTileStyles}>
      <Text weight='bold' className='tile-value'>
        {value}
      </Text>
      <div className='tile-label'>
        <Text size='medium'>{label}</Text>
        {infoTag && (
          <Info hoverDelay={500} trigger='hover'>
            {infoTag}
          </Info>
        )}
      </div>
    </div>
  );
};

const dashboardContentStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${theme.spacing(1.5)};

  .recharts-responsive-container {
    min-height: 0;
    height: 59px;
    width: 100%;
    flex: 1;
    pointer-events: none;
  }
`;

const statsContainerStyles = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1.5)};
`;

const totalStatsStyles = css`
  .total-count {
    font-size: ${theme.fontSize(64)};
    line-height: 1;
  }

  .total-label {
    white-space: nowrap;
    margin: 0;
  }
`;

const sectionStyles = css`
  position: relative;
  padding: ${theme.spacing(2)};
  height: 179px;
  border: 1px solid ${theme.colors.neutral10};
  border-radius: ${theme.borderRadius.medium};
  display: flex;
  align-items: center;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    pointer-events: none;
    left: -1px;
    top: -1px;
    border-radius: ${theme.borderRadius.medium};
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    transition: border 0.3s ease;
    border: 2px solid transparent;
  }

  &.selected::before {
    border: 2px solid ${theme.colors.primary50};
  }
`;

const sectionContentStyles = css`
  width: 100%;
`;

const sectionHeaderStyles = css`
  display: flex;
  gap: ${theme.spacing(0.5)};
  margin-bottom: ${theme.spacing(0.5)};
`;

const sectionChartStyles = css`
  display: flex;
  align-items: center;
  height: 59px;
  width: 100%;
`;

const sectionValueStyles = css`
  font-size: ${theme.fontSize(24)};
  line-height: 1;
`;

const detailedTilesStyles = css`
  border-radius: ${theme.borderRadius.medium};
  background-color: ${theme.colors.neutral10};
  flex: 1;
  width: 100%;
  padding: ${theme.spacing(3)};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1.5)};
`;

const tilesContainerStyles = css`
  width: 100%;
  display: flex;
  flex: 1;
  gap: ${theme.spacing(3)};
`;

const detailedTileStyles = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  text-align: center;
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};

  .tile-value {
    font-size: ${theme.fontSize(48)};
  }

  .tile-label {
    display: flex;
    gap: ${theme.spacing(0.5)};
    margin-bottom: ${theme.spacing(0.5)};
    justify-content: center;
  }
`;
