import { useCallback } from 'react';
import { Session } from 'sip.js';
import { useSoftphoneCallState } from '..';
import { isEstablishedCall, isIncomingCall, isOutgoingCall } from '../../../types';
import { useSoftphoneAudio } from '../../softphone-audio-provider';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';
import { useSoftphoneClient } from '../../softphone-client-provider';

/**
 *
 * for remote hangup handling
 */
export const useHandleHangup = () => {
  const play = useSoftphoneAudio((ctx) => ctx.play);
  const client = useSoftphoneClient((ctx) => ctx.client);
  const { emit } = useSoftphoneEventsEmitter();
  const setIsMuted = useSoftphoneCallState((ctx) => ctx.setIsMuted);
  const getCallById = useSoftphoneCallState((ctx) => ctx.getCallById);
  const terminateCall = useSoftphoneCallState((ctx) => ctx.terminateCall);
  const calls = useSoftphoneCallState((ctx) => ctx.calls);

  return useCallback(
    async (session: Session) => {
      const call = getCallById(session.id);
      if (!call) {
        //Call was ended by user. handled in `use-end-call.ts`
        return;
      }

      const terminatedCall = terminateCall(call, 'hangup');
      setIsMuted(false);
      if (isOutgoingCall(call)) {
        emit('outgoing-call.canceled', terminatedCall);
        play.hangup();
      } else if (isEstablishedCall(call)) {
        emit('active-call.terminated', terminatedCall);
        play.hangup();
      } else if (isIncomingCall(call)) {
        emit('incoming-call.rejected', terminatedCall);
      }
      return terminatedCall;
    },
    [client, calls, getCallById, terminateCall]
  );
};
