import { FC, useMemo, Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, useFormField, TextField, SecondaryButton, TextButton, useDebouncedValue } from '@frontend/design-system';
import { StreamUser } from '../../../types';
import { getFullName } from '../../../utils';
import { ChatAvatar } from '../../common';

interface SelectMembersProps {
  members: StreamUser[];
  updateMembers: Dispatch<SetStateAction<StreamUser[]>>;
  users: StreamUser[];
}

export const SelectMembers: FC<SelectMembersProps> = ({ members, users, updateMembers }) => {
  const textFieldProps = useFormField({ value: '', type: 'text' });
  const { t } = useTranslation('team-chat');
  const searchText = useDebouncedValue(textFieldProps.value);

  const filteredUsers = useMemo(() => {
    const input = searchText.trim();
    if (!input) {
      return users;
    } else {
      return users.filter((user) => {
        const fullName = getFullName(user).toLowerCase();
        return fullName.includes(input.toLowerCase());
      });
    }
  }, [users, searchText]);

  const addMember = async (user: StreamUser) => {
    updateMembers([...members, user]);
  };

  const removeMember = async (user: StreamUser) => {
    updateMembers(members.filter((member) => member.userID !== user.userID));
  };

  return (
    <>
      <TextField
        name=''
        label=''
        startAdornment={<Icon name='search' />}
        {...textFieldProps}
        placeholder={t('Search members')}
      />
      <div css={userListContainerStyle}>
        {filteredUsers.map((user) => {
          const isMemberAdded = members.some((member) => member.userID === user.userID);
          return (
            <div css={userContainerStyle} key={user.userID}>
              <ChatAvatar members={[user]} />
              <Text>{getFullName(user)}</Text>
              {isMemberAdded && (
                <TextButton onClick={() => removeMember(user)} css={marginLeftAuto}>
                  <Text weight='bold' color='error' size='medium'>
                    {t('Remove')}
                  </Text>
                </TextButton>
              )}
              {!isMemberAdded && (
                <SecondaryButton
                  color={theme.colors.primary50}
                  css={[marginLeftAuto, buttonStyle]}
                  onClick={() => addMember(user)}
                >
                  <Text weight='bold' color='primary'>
                    {t('Add')}
                  </Text>
                </SecondaryButton>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const userListContainerStyle = css({
  overflowX: 'scroll',
  maxHeight: '288px',
  boxShadow: theme.shadows.light,
  borderBottomLeftRadius: theme.borderRadius.medium,
  borderBottomRightRadius: theme.borderRadius.medium,
  marginBottom: theme.spacing(1),
});

const userContainerStyle = css({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  borderBottom: `1px solid ${theme.colors.neutral10}`,
});

const marginLeftAuto = css({ marginLeft: 'auto' });
const buttonStyle = css({ width: 'fit-content', borderColor: theme.colors.primary50 });
