import { SchemaSMSNumberService, SchemaSMSService, SchemaIO, SchemaSMSSendService } from '@frontend/schema';
import { SMSNumber, SMSNumberValidity } from './types';
import { ListThreadsCountResponse } from '@weave/schema-gen-ts/dist/schemas/sms/data/v3/sms_service.pb';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { MessagesTypes } from '.';
import { Merge } from 'ts-toolbelt/out/Object/Merge';
import { ValidNumberStatus } from '@weave/schema-gen-ts/dist/schemas/sms/number/v1/number_service.pb';

type GetValidityIO = SchemaIO<(typeof SchemaSMSNumberService)['GetValidity']>;

/**
 * A wrapper for the schema `GetValidity` method that treats 400 error responses as invalid numbers, and all other errors
 * as unspecified.
 */
export const getValidityGracefully = (req: GetValidityIO['input']): Promise<GetValidityIO['output']> =>
  SchemaSMSNumberService.GetValidity(req).catch((err) => {
    if (err.response && err.response.status === 400) {
      return { valid: ValidNumberStatus.VALID_NUMBER_STATUS_INVALID };
    }
    return { valid: ValidNumberStatus.VALID_NUMBER_STATUS_UNSPECIFIED };
  });

/**
 * A function for getting the validity of a list of phone numbers, by calling `getValidityGracefully` on each number
 * and returning an array of the results.
 *
 * Any numbers that have an unspecified validity will use the `defaultValidity` value, which defaults to `true`.
 */
export const getValidities = (
  req: {
    phoneNumbers: SMSNumber[];
  },
  defaultValidity = true
): Promise<SMSNumberValidity[]> =>
  Promise.all(
    req.phoneNumbers.map(async ({ number, label }) => {
      if (!number)
        return {
          number,
          label,
          isValid: false,
        };

      const res = await getValidityGracefully({ phoneNumber: number });

      switch (res.valid) {
        case ValidNumberStatus.VALID_NUMBER_STATUS_INVALID:
          return {
            number,
            label,
            isValid: false,
          };
        case ValidNumberStatus.VALID_NUMBER_STATUS_VALID:
          return {
            number,
            label,
            isValid: true,
          };
        default:
          return {
            number,
            label,
            isValid: defaultValidity,
          };
      }
    })
  );

type ListThreadsCountIO = SchemaIO<(typeof SchemaSMSService)['ListThreadsCount']>;
type GetUnreadCountRequest = Merge<ListThreadsCountIO['input'], { statuses?: ListThreadsCountIO['input']['statuses'] }>;
/**
 * A wrapper for the schema `ListThreadsCount` method that defaults to only counting threads with the `NEW` status.
 */
export const getUnreadCount = (req: GetUnreadCountRequest) =>
  SchemaSMSService.ListThreadsCount({
    ...req,
    statuses: [MessagesTypes.KnownThreadStatuses.NEW],
  }) as Promise<ListThreadsCountResponse>;

export type SendMessageWithIdRequest = Compulsory<SchemaIO<(typeof SchemaSMSSendService)['Send']>['input'], 'id'>;
/**
 * A wrapper for the schema `Send` method that enforces the `id` property to be set for usage with the local sent messages store.
 */
export const sendMessageWithId = (req: SendMessageWithIdRequest) => SchemaSMSSendService.Send(req);
