import { FC, useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from '@tanstack/react-location';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FormsSubmission } from '@frontend/api';
import { PersonHelpers } from '@frontend/api-person';
import { getUser } from '@frontend/auth-helpers';
import { useDigitalFormsLocationsContext } from '@frontend/digital-forms-scope';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { Avatar, IconButton, SpinningLoader, Text, useModalControl } from '@frontend/design-system';
import { useScopeLocation, useIntegrationCheck } from '../../../../shared/hooks';
import { isDefaultUTCDate, isUUID } from '../../../../shared/utils';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import AttachmentList from './attachment-list/attachment-list.component';
import {
  blankContainerDescriptionStyle,
  blankContainerStyle,
  containerStyle,
  contentContainerStyle,
  headerIconStyle,
  notesContainerStyle,
} from './detailed-view.style';
import FormsList from './forms-list/forms-list.component';
import AttachmentDetailsModal from './modals/attachment-details-modal/attachment-details-modal.component';
import FormDetailsModal from './modals/form-details-modal/form-details-modal.component';
import PatientInfo from './patient-info/patient-info.component';
import { useWritebackWidget } from './writeback-widget-v2';

dayjs.extend(relativeTime);

interface SubmissionDetailsProps {
  hasNext?: boolean;
  hasPrevious?: boolean;
  isModal?: boolean;
  onModalClose?: () => void;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
}

function splitName(form: string) {
  if (!form) return ['', ''];

  const splitted = form.split(' ');
  return [splitted[0], splitted[1] || ''];
}

export const SubmissionDetails: FC<React.PropsWithChildren<SubmissionDetailsProps>> = ({ isModal, onModalClose }) => {
  const { t } = useTranslation('forms');
  const user = getUser();
  const { params, search } = useMatch();
  const {
    current: { pathname },
  } = useLocation();
  const routeSubmissionId = params.submissionid;
  const { locationId } = useScopeLocation();
  const { validFormsLocations } = useDigitalFormsLocationsContext();
  const navigate = useNavigate();

  const submissionId = (routeSubmissionId || search.submission_id) as string;

  const { formSubmission, isLoading: isLoadingSubmission } = useFormSubmission();

  const {
    writebackWidgetModalControls,
    approveOrRejectMode,
    skipDisclaimerAndApprove,
    skipDisclaimerAndReject,
    resetApproveOrRejectMode,
  } = useWritebackWidget();
  const { hasPMSIntegration, isIntegrationCheckLoading } = useIntegrationCheck();
  const [activeFormIndex, setActiveFormIndex] = useState<number>(0);
  const [attachmentsToShow, setAttachmentsToShow] = useState<FormsSubmission.Types.AttachmentData[]>([]);
  const formDataModalControl = useModalControl();
  const attachmentModalControl = useModalControl();
  const [providerFirstName, providerLastName] = splitName(formSubmission?.providerName || '');

  const formatTimestamp = useTimestampFormatter();

  const userFullName = PersonHelpers.getFullName({ FirstName: user?.firstName, LastName: user?.lastName });

  useEffect(() => {
    setActiveFormIndex(0);
  }, [submissionId]);

  const isSubmitted = !!(formSubmission?.formSubmittedAt && !isDefaultUTCDate(formSubmission?.formSubmittedAt));

  function getAttachmentsInForm(formId: string) {
    if (!formSubmission?.attachments) {
      return [];
    }

    return formSubmission.attachments.filter((attachment) => attachment.formId === formId);
  }

  function showFormDetails(formIndex: number, navigateForm = false) {
    if (!formSubmission) {
      return;
    }

    setActiveFormIndex(formIndex);
    if (!navigateForm) formDataModalControl.openModal();
  }

  function showAttachmentHandler(attachments: FormsSubmission.Types.AttachmentData[]) {
    setAttachmentsToShow(attachments);
  }

  function showAttachmentsInFormHandler(formId: string) {
    const attachments = getAttachmentsInForm(formId);

    if (attachments.length) {
      formDataModalControl.closeModal();
      setAttachmentsToShow(attachments);
      attachmentModalControl.openModal();
    }
  }

  function showAttachmentFormHandler(formId: string) {
    const formIndex = (formSubmission?.data || []).findIndex((form) => form.formId === formId);
    if (formIndex > -1) {
      setActiveFormIndex(formIndex);
      attachmentModalControl.closeModal();
      formDataModalControl.openModal();
    }
  }

  const isLocationNotSelected = locationId && !validFormsLocations.includes(locationId);

  if (isLocationNotSelected) {
    const newPath = pathname.split('/');
    // prevent being redirected to all inbox by checking if the last path is a uuid
    if (isUUID(newPath[newPath.length - 1])) {
      newPath.pop();

      const redirectPath = newPath.join('/');

      navigate({
        to: redirectPath,
        replace: true,
      });
    }
  }

  if (isLoadingSubmission || isIntegrationCheckLoading || isLocationNotSelected) {
    return (
      <div css={blankContainerStyle}>
        {/* TODO: having a skeleton loader would look much better here */}
        <SpinningLoader size='large' />
      </div>
    );
  }

  if (!formSubmission) {
    return (
      <div css={blankContainerStyle}>
        <Text css={blankContainerDescriptionStyle}>{t('Form submission not found!')}</Text>
      </div>
    );
  }

  const isReviewRequired = !!(
    formSubmission.reviewStatus &&
    formSubmission.reviewStatus !== 'Not Applicable' &&
    formSubmission.reviewStatus === 'Pending'
  );

  return (
    <div css={containerStyle}>
      {/* TODO: add modal for this case */}
      {isModal && (
        <div css={headerIconStyle}>
          <IconButton label='Close' onClick={onModalClose}>
            <Icon name='x' />
          </IconButton>
        </div>
      )}

      <div css={contentContainerStyle}>
        <PatientInfo
          submission={formSubmission}
          showBadge={hasPMSIntegration(locationId)}
          hasPMSIntegration={hasPMSIntegration(locationId)}
          locationId={locationId}
          submissionId={submissionId}
          writebackWidgetModalControls={writebackWidgetModalControls}
          approveOrRejectMode={approveOrRejectMode}
          resetApproveOrRejectMode={resetApproveOrRejectMode}
        />
        <FormsList
          submission={formSubmission}
          isModalView={isModal}
          submissionId={submissionId}
          writebackStatusCode={formSubmission.writebackStatusCode}
          submittedAt={formSubmission.formSubmittedAt}
          hasPMSIntegration={hasPMSIntegration(locationId)}
          onFormClick={showFormDetails}
        />
        <AttachmentList
          submission={formSubmission}
          modalTriggerProps={attachmentModalControl.triggerProps}
          onSelectAttachment={showAttachmentHandler}
        />
        {formSubmission.note && (
          <div css={notesContainerStyle}>
            <Avatar name={formSubmission.providerName} size='xs' />
            <div className='provider-note'>
              <Text>
                {userFullName === formSubmission?.providerName ? 'You' : `${providerFirstName} ${providerLastName}`}{' '}
                <Text as='span' color='light' size='small'>
                  {formatTimestamp(formSubmission.updatedAt)}
                </Text>
              </Text>
              <Text color='light' size='medium'>
                {formSubmission.note}
              </Text>
            </div>
          </div>
        )}
      </div>

      <FormDetailsModal
        title={(formSubmission.formName || formSubmission.packetName) as string}
        firstName={formSubmission.firstName}
        lastName={formSubmission.lastName}
        modalProps={formDataModalControl.modalProps}
        activeFormIndex={activeFormIndex}
        formData={formSubmission.data}
        onViewAttachmentsInTheForm={showAttachmentsInFormHandler}
        onGetAttachments={getAttachmentsInForm}
        submissionId={submissionId}
        reviewRequired={isReviewRequired}
        isSubmitted={isSubmitted}
        onFormNavigate={showFormDetails}
        onApproveSubmission={skipDisclaimerAndApprove}
        onRejectSubmission={skipDisclaimerAndReject}
        locationId={locationId}
      />

      <AttachmentDetailsModal
        title={(formSubmission.formName || formSubmission.packetName) as string}
        firstName={formSubmission.firstName}
        lastName={formSubmission.lastName}
        modalProps={attachmentModalControl.modalProps}
        attachmentsToShow={attachmentsToShow}
        onShowForm={showAttachmentFormHandler}
      />
    </div>
  );
};
