import { FC, MutableRefObject, useEffect, useRef, useState, useCallback } from 'react';
import { format } from 'date-fns';
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from '@frontend/i18n';
import { TextButton, useAlert } from '@frontend/design-system';
import { useWritebackWidgetStore } from '../../../../../../providers';
import { useProviderSignature, useProviderReviewSteps } from '../../../../hooks';
import { signatureActionButtonStyle, wetSignatureWrapperStyle } from './wet-signature.styles';

const WetSignature: FC = () => {
  const { t } = useTranslation('forms');
  const alert = useAlert();
  const { goToNextStep } = useProviderReviewSteps();
  const [canvasWidth, setCanvasWidth] = useState(300);
  const containerDivRef = useRef<HTMLDivElement>(null);
  const signatureCanvasRef = useRef() as MutableRefObject<SignatureCanvas>;
  const { storeSignature } = useProviderSignature();

  const {
    saveSigntureTrigger,
    wetSign,
    activeSignatureTab,
    setWetSign,
    shouldSaveAsDefaultSignature,
    setSaveSignatureTrigger,
  } = useWritebackWidgetStore([
    'saveSigntureTrigger',
    'wetSign',
    'activeSignatureTab',
    'setWetSign',
    'shouldSaveAsDefaultSignature',
    'setSaveSignatureTrigger',
  ]);

  const clearSignatureHandler = useCallback(() => {
    signatureCanvasRef.current.clear();
  }, []);

  const saveWetSignature = useCallback(async () => {
    const dataPoints = signatureCanvasRef.current.toData();
    const isEmpty = dataPoints.length === 0;

    if (!isEmpty) {
      // source: https://stackoverflow.com/a/7261048/4339943
      const dataURL = signatureCanvasRef.current.toDataURL();
      setWetSign(dataPoints as any); //TODO: fix any
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      const byteString = window.atob(dataURL.split(',')[1]);

      // separate out the mime component
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([ab], { type: mimeString });
      const file = new File([blob], 'signature');

      await storeSignature(
        {
          type: 'image',
          data: file,
          font_type: '',
          timestamp: format(new Date(), 'MMMM d, yyyy hh:mm:ss'),
        },
        shouldSaveAsDefaultSignature
      );

      setSaveSignatureTrigger(false);
      goToNextStep();

      if (shouldSaveAsDefaultSignature) {
        // refetchDefaultSignature();
      }
    } else {
      alert.error(t('No signature drawn.'));
    }

    setSaveSignatureTrigger(false);
  }, [setWetSign, storeSignature, shouldSaveAsDefaultSignature, setSaveSignatureTrigger, goToNextStep]);

  useEffect(() => {
    const containerDivEl = containerDivRef.current;
    if (!containerDivEl || containerDivEl.clientWidth === canvasWidth) {
      return;
    }

    setCanvasWidth(containerDivEl.clientWidth);
  }, [containerDivRef.current]);

  useEffect(() => {
    if (wetSign.length > 0) {
      // TODO: fix "any" type
      signatureCanvasRef.current.fromData(wetSign as any);
    }
  }, []);

  useEffect(() => {
    if (!saveSigntureTrigger || activeSignatureTab !== 'draw') {
      return;
    }

    saveWetSignature();
  }, [saveSigntureTrigger, saveWetSignature]);

  return (
    <>
      <div css={wetSignatureWrapperStyle} ref={containerDivRef}>
        <SignatureCanvas
          ref={signatureCanvasRef}
          penColor={'black'}
          canvasProps={{
            width: `${canvasWidth}px`,
            height: '200px',
            className: 'form-signature-canvas',
          }}
        />
      </div>

      <div css={signatureActionButtonStyle}>
        <TextButton onClick={clearSignatureHandler}>{t('Clear drawing')}</TextButton>
      </div>
    </>
  );
};

export default WetSignature;
