import { useTranslation } from '@frontend/i18n';
import { css } from '@emotion/react';
import { NakedUl, SkeletonLoader, Text } from '@frontend/design-system';
import { MessageSearchResult } from '../inbox-panel/inbox-search/list-items';
import { useInboxSearch } from '../../hooks';
import { GlobalSearch, useGlobalSearch, GlobalSearchTrackingIds } from '@frontend/global-search';
import { theme } from '@frontend/theme';

const defaultMaxResults = 5;

export const MessagesGlobalSearchModule = () => {
  const { t } = useTranslation('inbox');
  const { debouncedSearchTerm, viewAllFeature, close, addSearch } = useGlobalSearch([
    'debouncedSearchTerm',
    'viewAllFeature',
    'close',
    'addSearch',
  ]);

  const { messagesQuery, logSearch } = useInboxSearch({ debouncedSearchValue: debouncedSearchTerm });
  const maxResults = viewAllFeature === 'messaging' ? messagesQuery.results.length : 5;
  return (
    <GlobalSearch.Section.Content
      showViewAll={messagesQuery.results.length > defaultMaxResults}
      hasResults={!!messagesQuery.results.length}
      count={`${messagesQuery.total}${messagesQuery.hasNextPage ? '+' : ''}`}
    >
      <NakedUl css={{ display: 'flex', flexDirection: 'column' }}>
        {messagesQuery.isLoading.firstPage ? (
          <div>
            {Array.from({ length: defaultMaxResults }).map((_, index) => (
              <MessageItemSkeletonLoader key={index} />
            ))}
          </div>
        ) : messagesQuery.results.length ? (
          messagesQuery.results.slice(0, maxResults).map(({ messages, ...thread }, index) => {
            const message = messages[0];
            if (!message) return null;

            return (
              <MessageSearchResult
                key={message.smsId}
                data-trackingid={GlobalSearchTrackingIds.searchResultItem('messaging')}
                onSelect={() => {
                  logSearch(debouncedSearchTerm);
                  addSearch(debouncedSearchTerm, 'messaging');
                  close();
                }}
                {...thread}
                message={message}
                searchValue={debouncedSearchTerm}
                hideBottomBorder={index === maxResults - 1 || index === messagesQuery.results.length - 1}
              />
            );
          })
        ) : (
          <Text textAlign='center' color='light' size='medium'>
            {t('No messages match this search.')}
          </Text>
        )}
      </NakedUl>
    </GlobalSearch.Section.Content>
  );
};

const MessageItemSkeletonLoader = () => {
  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        align-items: center;
        height: 60px;
        padding: ${theme.spacing(1)};
        gap: ${theme.spacing(2)};

        :not(:last-child) {
          border-bottom: 1px solid ${theme.colors.neutral10};
        }
      `}
    >
      <SkeletonLoader
        css={css`
          flex-shrink: 0;
        `}
        shape='circle'
        width={40}
        height={40}
      />
      <div
        css={css`
          width: 100%;
        `}
      >
        <SkeletonLoader
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
          height={20}
          width='40%'
        />
        <SkeletonLoader width='100%' height={18} />
      </div>
    </div>
  );
};
