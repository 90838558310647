import { forwardRef } from 'react';
import { Icon } from '@frontend/icons';
import { SpinningLoader } from '../loader';
import { ButtonProps } from './button.type';
import { getIconColor, getVariantStyles } from './styles';

const eventsToDisable = ['onClick', 'onMouseDown', 'onMouseUp', 'onKeyDown', 'onKeyUp'];

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      iconName,
      disabled = false,
      size = 'small',
      trackingId,
      type = 'button',
      loading = false,
      variant = 'primary',
      ...rest
    },
    ref
  ) => {
    const iconOnly = !children && !!iconName;
    const buttonStyles = getVariantStyles({ variant, size, loading, iconOnly });
    const effectivelyDisabled = disabled || loading;

    const eventHandlers = eventsToDisable.reduce((handlers, eventName) => {
      handlers[eventName] = (event) => {
        if (effectivelyDisabled) {
          event.preventDefault();
          return;
        }
        rest[eventName]?.(event);
      };
      return handlers;
    }, {});

    return (
      <button
        css={buttonStyles}
        type={type}
        ref={ref}
        data-trackingid={trackingId}
        aria-disabled={effectivelyDisabled}
        tabIndex={effectivelyDisabled ? -1 : 0}
        {...rest}
        {...eventHandlers}
      >
        {loading ? (
          <SpinningLoader color={variant === 'secondary' ? 'subdued' : 'primary'} css={{ margin: 0 }} size='xs' />
        ) : (
          <span css={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            {iconName && (
              <Icon name={iconName} color={getIconColor(variant, disabled)} size={size === 'large' ? 24 : 16} />
            )}
            {children}
          </span>
        )}
      </button>
    );
  }
);
