import { useMemo } from 'react';
import { useAppScopeStore } from '@frontend/scope';
import { messagingAnalyticsDemoData } from '../demo-data';
import { useIsMessagingAnalyticsDemoAccount } from './use-is-messaging-analytics-demo-account';

export const useMessagingAnalyticsDemoData = () => {
  const { getSelectedLocationData, selectedLocationIds } = useAppScopeStore();
  const isDemoAccount = useIsMessagingAnalyticsDemoAccount();

  const demoData = useMemo(() => {
    if (typeof isDemoAccount === 'undefined' || !isDemoAccount) {
      return null;
    }

    const locationsData = getSelectedLocationData();

    return {
      dailyData: messagingAnalyticsDemoData.charts.dailyData(selectedLocationIds, locationsData),
      hourlyData: messagingAnalyticsDemoData.charts.hourlyData(selectedLocationIds, locationsData),
      tableData: messagingAnalyticsDemoData.table.data(selectedLocationIds, locationsData),
    };
  }, [selectedLocationIds.sort().join('-'), isDemoAccount]);

  return demoData;
};
