import { serviceKey } from '../service';

export const endpointKeys = {
  copyFormsToTargetCompanies: [...serviceKey, 'copy-forms-to-target-companies'],
  createFormLinks: [...serviceKey, 'create-form-links'],
  createFormUsingTemplates: [...serviceKey, 'create-form-using-templates'],
  medicalHistoryAnswerOptionsToggle: [...serviceKey, 'toggle-medical-conditions'],
  onboardCompany: [...serviceKey, 'onboard-company'],
  syncMedicalConditions: [...serviceKey, 'medical-conditions'],
  updateCompanyConfig: [...serviceKey, 'update-company-config'],
  updateFavouriteStatus: [...serviceKey, 'update-favourite-status'],
  upsertForm: [...serviceKey, 'upsert-form'],
  updateKioskListingStatus: [...serviceKey, 'update-kiosk-listing-status'],
  updateReviewRequiredFlagForForm: [...serviceKey, 'update-review-required-flag-for-form'],
  updateReviewRequiredFlagForPacket: [...serviceKey, 'update-review-required-flag-for-packet'],
};
