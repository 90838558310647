import { IconButton, ModalControlModalProps, Tray, Text, Heading, useAlert } from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { AssignDevicesToDirectoryTable } from '../tables';
import { useState } from 'react';
import { ContactDirectoriesMutation, ContactDirectoriesQueryKeys } from '@frontend/api-contact-directories';
import { useQueryClient } from 'react-query';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';

type Props = ModalControlModalProps & {
  name: string;
  contactDirectoryId: string;
};

export const AssignDevicesToDirectoryModal = ({ onClose, name, contactDirectoryId }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const [selectedDevicesIds, setSelectedDevicesIds] = useState<string[]>();
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const locationId = locationIds?.[0] ?? '';
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { useReplaceContactDirectoryDevicesMutation } =
    ContactDirectoriesMutation.useContactDirectoryMutations(locationId);
  const { mutateAsync: mutateAssignDevices } = useReplaceContactDirectoryDevicesMutation({
    onSuccess: () => {
      onClose();
      alert.success(t('Successfully updated devices using this list'));
      queryClient.invalidateQueries(ContactDirectoriesQueryKeys.queryKeys.getContactDirectories(locationId));
      queryClient.invalidateQueries(
        ContactDirectoriesQueryKeys.queryKeys.getDevicesbyContactDirectoryId(contactDirectoryId, locationId)
      );
    },
    onSettled: () => {
      setSelectedDevicesIds(undefined);
    },
  });

  return (
    <>
      <Tray.Header
        css={{ alignItems: 'start' }}
        Buttons={
          <IconButton label='close' onClick={onClose}>
            <Icon name='x' />
          </IconButton>
        }
      >
        <div>
          <Heading level={2}>{t('Assign or Remove List')}</Heading>
          <Trans t={t}>
            <Text color='light'>Choose which devices use {name} in their phone directory.</Text>
          </Trans>
        </div>
      </Tray.Header>
      <Tray.Body>
        <AssignDevicesToDirectoryTable
          setSelectedDevicesIds={setSelectedDevicesIds}
          contactDirectoryId={contactDirectoryId}
        />
      </Tray.Body>
      <Tray.Actions
        onSecondaryClick={onClose}
        secondaryLabel='Cancel'
        primaryLabel='Save'
        primaryTrackingId='assign-devices-directories'
        onPrimaryClick={() => {
          mutateAssignDevices({ contactListId: contactDirectoryId, deviceIds: selectedDevicesIds ?? [] });
        }}
      />
    </>
  );
};
