import { ReactNode } from 'react';
import { useFormField, CheckboxField } from '@frontend/design-system';
import { theme } from '@frontend/theme';

type FilterItemPopoverCheckboxFieldProps = {
  label: ReactNode;
  name: string;
  isChecked: boolean;
};

export const FilterItemPopoverCheckboxField = ({ label, name, isChecked }: FilterItemPopoverCheckboxFieldProps) => {
  const fieldProps = useFormField(
    {
      type: 'checkbox',
      value: isChecked,
    },
    [isChecked]
  );

  return (
    <CheckboxField
      label={label}
      name={name}
      {...fieldProps}
      css={{
        fill: theme.colors.white,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
      onChange={() => {}}
      tabIndex={-1}
    />
  );
};
