import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton } from '@frontend/design-system';
import { useDialpadEstablishConnectionControl } from '../providers/dialpad-connection-provider';
import { useDialpadManagerClient } from '../providers/dialpad-manager-provider';
import { useDialpadWidgetControl } from '../providers/dialpad-widget-control-provider';

export const DialpadIconButton = () => {
  const availableDevices = useDialpadManagerClient((ctx) => ctx.availableDevices);
  const isDeviceConnected = useDialpadManagerClient((ctx) => ctx.isDeskphoneConnected);
  const pairModalTriggerProps = useDialpadEstablishConnectionControl((ctx) => ctx.pairDeviceTriggerProps);
  const connectDeviceTriggerProps = useDialpadEstablishConnectionControl((ctx) => ctx.connectDeviceTriggerProps);
  const isOpen = useDialpadWidgetControl((ctx) => ctx.isOpen);
  const open = useDialpadWidgetControl((ctx) => ctx.open);
  const close = useDialpadWidgetControl((ctx) => ctx.close);
  const hasAvailableDevices = !!availableDevices?.length;

  const openDialpad = () => {
    if (isOpen) {
      close?.();
    } else {
      open?.();
    }
  };

  const handleClick = () => {
    if (!hasAvailableDevices && !isDeviceConnected) {
      pairModalTriggerProps.onClick();
    } else if (hasAvailableDevices && !isDeviceConnected) {
      connectDeviceTriggerProps.onClick();
    } else if (!!isDeviceConnected) {
      openDialpad();
    }
  };

  return (
    <NakedButton
      trackingId='dialpad-icon'
      onClick={handleClick}
      css={css`
        cursor: pointer;
        position: relative;
        z-index: ${theme.zIndex.modal};
        border-box: box-sizing;
        background-color: ${theme.colors.white};
        border: 1px solid ${theme.colors.neutral20};
        border-radius: ${theme.borderRadius.medium};
        padding: ${theme.spacing(1)};
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <Icon name='dialpad' />
    </NakedButton>
  );
};
