import { ScheduleTypes } from '@frontend/api-schedule';
import { createStore, createShallowStore } from '@frontend/store';

export interface FilteredProvidersListStore {
  selectedProvidersList: ScheduleTypes.SchemaProvider[] | [];
  setSelectedProvidersList: (providersListData: ScheduleTypes.SchemaProvider[] | []) => void;
  resetSelectedProvidersList: () => void;
}

export const useCalendarProvidersListStore = createStore<FilteredProvidersListStore>(
  (set) => ({
    selectedProvidersList: [],
    setSelectedProvidersList: (providersListData: ScheduleTypes.SchemaProvider[]) =>
      set({ selectedProvidersList: providersListData }),
    resetSelectedProvidersList: () => set({ selectedProvidersList: [] }),
  }),
  { name: 'filteredProvidersList' }
);

export const useProvidersListShallowStore =
  createShallowStore<FilteredProvidersListStore>(useCalendarProvidersListStore);
