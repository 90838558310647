import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';
import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';

export const useSmsTagNotification = () => {
  useCommonActionSubscriptions('sms-tag');
  const { create, remove } = useNotification<'sms-tag'>();
  useNotificationActionSubscription('sms-tag', 'ignore', (e) => {
    remove(e.notification.id);
  });

  useNotificationActionSubscription('sms-tag', 'timed-out', (e) => {
    remove(e.notification.id);
  });

  useNotificationActionSubscription('sms-tag', 'view', (e) => {
    // to add the view action, we need to see if the message id is on the websocket event payload
    console.log('view', e.notification);
  });

  return {
    create,
    remove,
  };
};
