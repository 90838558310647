import { i18next } from '@frontend/i18n';
import {
  DentalCoverageRequest,
  InsuranceStatus,
  VerificationMetricsResponse,
} from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v1/insurance_verification_adapter.pb';
import { InsuranceResponse } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/api-service/v1/insurance_verification_api.pb';
import {
  ErrorDetails,
  ErrorType,
  UnknownErrorMessage,
  ValidationErrorMessage,
} from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v2/insurance_verification_adapter.pb';
import { ThirdPartyErrorMessage } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/third-party/onederful.pb';

import { InsuranceDetails, VerificationReportData } from './types';
import { ERROR_MESSAGES, RelationshipEnum } from './constants';
import { formatDate } from '@frontend/date';

export const getHoursSavedByInsuranceVerification = (totalNumberOfVerification: number): number => {
  const timeSavedPerVerification = 9; // in minutes
  return Number(((totalNumberOfVerification * timeSavedPerVerification) / 60).toFixed(1));
};

export const prepareVerificationReportData = (response?: VerificationMetricsResponse): VerificationReportData => {
  if (!response)
    return {
      totalCount: 0,
      automatedCount: 0,
      manualCount: 0,
      hoursSaved: 0,
    };

  const totalCount = Number(response.overallCount) || 0;
  const automatedCount = Number(response.automatedCount) || 0;
  const manualCount = Number(response.manualCount) || 0;
  const hoursSaved = getHoursSavedByInsuranceVerification(totalCount);

  return { totalCount, automatedCount, manualCount, hoursSaved };
};

export const validateNationalProviderId = (value: string): string =>
  value.length !== 10 ? i18next.t('NPI must be 10 characters long', { ns: 'insuranceDetails' }) : '';

export const validateNationalProviderTaxId = (value: string): string => {
  const allowOnlyNumericRegex = /^[0-9]+$/;
  const isNumeric = allowOnlyNumericRegex.test(value);
  const hasMatchingLength = value.length === 9;
  if (!isNumeric) {
    return i18next.t('Only numbers accepted for Tax ID', { ns: 'insuranceDetails' });
  } else if (!hasMatchingLength) {
    return i18next.t('Tax ID must be 9 characters long', { ns: 'insuranceDetails' });
  }
  return '';
};

export const removeNonAlphaNumericCharacters = (value = ''): string => value.replace(/\W/g, '').trim();
export const sanitizeText = (value = ''): string =>
  (value && value.length === 1 ? value.replace('-', '') : value) ?? '';

export const prepareInsuranceDetails = (
  details: InsuranceResponse,
  payerList: Record<'label' | 'value', string>[] = []
): InsuranceDetails => {
  let payerId = '';
  if (details?.payerCompanyId) {
    const payer = payerList.find((payer) => payer.value === details.payerCompanyId);
    payerId = payer?.label ? details.payerCompanyId : '';
  }
  if (!payerId && details?.onederfulPayerId) {
    const payer = payerList.find((payer) => payer.value === details.onederfulPayerId);
    payerId = payer?.label ? details.onederfulPayerId : '';
  }
  const subscriberInfo = (details.coverage?.thirdPartyCoverage?.details as DentalCoverageRequest)?.subscriber;
  return {
    payerId,
    onderfulPayerId: details.onederfulPayerId ?? '',
    pmsId: details.pmsId ?? '',
    payerName: details?.payerCompanyName ?? '',
    groupName: removeNonAlphaNumericCharacters(details?.groupName) ?? '',
    groupNumber: removeNonAlphaNumericCharacters(details?.groupNumber ?? ''),

    nationalProviderId: removeNonAlphaNumericCharacters(details?.nationalProviderId) ?? '',
    providerTaxId: removeNonAlphaNumericCharacters(details?.providerTaxId) ?? '',

    firstName: sanitizeText(details?.firstName) ?? '',
    lastName: sanitizeText(details?.lastName) ?? '',
    dateOfBirth: formatDate(details.birthDate, 'MM/DD/YYYY'),
    memberId: removeNonAlphaNumericCharacters(details?.memberId ?? ''),

    relationship: (details?.relationship as RelationshipEnum) ?? RelationshipEnum.Self,
    subscriberId: '',
    subscriberFirstName: subscriberInfo?.firstName ?? '',
    subscriberLastName: subscriberInfo?.lastName ?? '',
    subscriberDateOfBirth: subscriberInfo?.dateOfBirth ?? '',
    subscriberMemberId: subscriberInfo?.memberId ?? '',

    effectiveDate: formatDate(details.coverage?.thirdPartyCoverage?.coverage?.effectiveDate, 'MM/DD/YYYY'),
    // SP-TODO: remove this casting once BE update schema type
    thirdPartyCoverageStatus:
      (details.coverage?.thirdPartyCoverage?.coverage?.status as InsuranceStatus) ?? InsuranceStatus.UNKNOWN,
  };
};

export const getErrorMessageFromDentalCoverageErrorInfo = (
  errorDetails?: ErrorDetails
): { errorMessage: string; errorList: string[] } => {
  let errorMessage = '';
  const errorList: string[] = [];

  if (errorDetails) {
    if (errorDetails.type === ErrorType.ERROR_TYPE_VALIDATION) {
      // SP-TODO: remove this casting once BE update schema type
      const codes = ((errorDetails as any).validationErrorDetails as ValidationErrorMessage)?.codes ?? [];
      codes.forEach((code) => {
        const message = titleCase(code);
        if (message) {
          errorList.push(message);
        }
      });
    }
    if (errorDetails.type === ErrorType.ERROR_TYPE_PARTNER) {
      // SP-TODO: remove this casting once BE update schema type
      const messageInfo = (errorDetails as any).thirdPartyErrorDetails as ThirdPartyErrorMessage;
      messageInfo?.errors?.forEach((error) => {
        const messageKey = ((error.reason || error.message) ?? '').toLowerCase() as keyof typeof ERROR_MESSAGES;
        const message = ERROR_MESSAGES[messageKey] ?? titleCase(messageKey);
        if (message) {
          errorList.push(message);
        }
        const payerSpecificMessage = titleCase(error.payerSpecificMessage ?? '');
        if (payerSpecificMessage) {
          errorList.push(payerSpecificMessage);
        }
      });
      errorMessage = messageInfo?.message ?? '';
    }
    if (errorDetails.type === ErrorType.ERROR_TYPE_UNKNOWN) {
      // SP-TODO: remove this casting once BE update schema type
      errorMessage = ((errorDetails as any).unknownErrorDetails as UnknownErrorMessage)?.message ?? '';
    }
  }
  return { errorMessage, errorList };
};

/**
 * @desc: Function is defined to prettify the text in title case
 *        can handle camelCase, snake_case, lisp-case,
 *        removes repeated whitespace and leading/trailing spaces
 */
export const titleCase = (text: string): string => {
  return text
    ? text
        .replace(/([^A-Z])([A-Z])/g, '$1 $2') // for camelCase
        .replace(/[_\\-]+/g, ' ') // for snake_case and lisp-case
        .toLowerCase()
        .replace(/(^\w|\b\w)/g, (m) => m.toUpperCase()) // title case words
        .replace(/\s+/g, ' ') // collapses repeated whitespace
        .replace(/^\s+|\s+$/, '') // removes leading/trailing whitespace
    : '';
};
