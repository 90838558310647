import { HTMLAttributes, ReactNode } from 'react';
import { Photos } from '@frontend/photos';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useSendFormsStore } from '../../../providers';

type ThreadHeaderProps = HTMLAttributes<HTMLDivElement> & {
  children?: ReactNode;
};

export const ThreadHeader = ({ children }: ThreadHeaderProps) => {
  const { personId, fullName } = useSendFormsStore(['personId', 'fullName']);
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
        borderBottom: `1px solid ${theme.colors.neutral20}`,
        padding: theme.spacing(2),
      }}
    >
      <div
        css={{
          display: 'flex',
          gap: theme.spacing(1),
          alignItems: 'center',
          minWidth: '35%',
        }}
      >
        <Photos.ContactProfilePhoto
          personId={personId ?? ''}
          disableClick={true}
          name={fullName ? fullName : undefined}
        />
        <Text weight='bold' css={{ fontSize: theme.fontSize(18) }}>
          {fullName && fullName}
        </Text>
      </div>
      {children}
    </div>
  );
};
