import { SubscriptionsTypes } from '@frontend/api-subscription';
import { Success2Graphic } from '@frontend/assets';
import { CalendarIcon, Heading, SecondaryButton, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { SubscribeModal } from './SubscribeModal';
import { subscribeTrackingIds as ids } from './trackingIds';

type SuccessContentProps = {
  feature: SubscriptionsTypes.Feature;
  productServiceName: string;
  trackingIdPrefix?: string;
  shouldShowScheduleCallButton?: boolean;
  onScheduleOnboardingCall?: () => void;
};

interface SubscribeSuccessModalProps extends SuccessContentProps {
  onOkayButtonClick: () => void;
}

export const SubscribeSuccessModal = ({
  feature,
  productServiceName,
  trackingIdPrefix = '',
  onOkayButtonClick,
  shouldShowScheduleCallButton = true,
  onScheduleOnboardingCall,
}: SubscribeSuccessModalProps) => {
  const { t } = useTranslation('subscribe', { keyPrefix: 'successModal' });
  const updatedTrackingPrefix = `${trackingIdPrefix}${ids.success}`;

  return (
    <SubscribeModal
      title={t('You are subscribed!')}
      primaryButtonLabel={t('Okay')}
      image={<Success2Graphic />}
      onPrimaryBtnClick={onOkayButtonClick}
      primaryButtonTrackingId={`${updatedTrackingPrefix}${ids.okayButton}`}
      renderContent={
        <SubscribeSuccessContent
          feature={feature}
          productServiceName={productServiceName}
          trackingIdPrefix={updatedTrackingPrefix}
          shouldShowScheduleCallButton={shouldShowScheduleCallButton}
          onScheduleOnboardingCall={onScheduleOnboardingCall}
        />
      }
    />
  );
};

const SubscribeSuccessContent = ({
  productServiceName,
  trackingIdPrefix,
  shouldShowScheduleCallButton = true,
  onScheduleOnboardingCall,
}: SuccessContentProps) => {
  const { t } = useTranslation('subscribe', { keyPrefix: 'successModal' });

  return (
    <>
      <Text>
        {t(
          'You are successfully subscribed to {{productServiceName}}. If you need additional assistance, please schedule an optional onboarding call by clicking Schedule Call below.',
          { productServiceName }
        )}
      </Text>
      {shouldShowScheduleCallButton && (
        <article
          css={{
            border: `1px solid ${theme.colors.neutral20}`,
            borderRadius: theme.borderRadius.medium,
            padding: theme.spacing(2),
            marginTop: theme.spacing(3),
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Heading css={{ fontSize: theme.fontSize(16) }}>{t('Optional Onboarding Call - 45 min')}</Heading>
            <Text textAlign='left' size='small' color='light'>
              {t('with your onboarding specialist')}
            </Text>
          </div>
          <div>
            <SecondaryButton
              css={{ width: 150 }}
              onClick={onScheduleOnboardingCall}
              trackingId={`${trackingIdPrefix}${ids.scheduleBtn}`}
            >
              <CalendarIcon size={16} css={{ marginRight: theme.spacing(0.5), marginBottom: theme.spacing(0.25) }} />
              {t('Schedule Call')}
            </SecondaryButton>
          </div>
        </article>
      )}
    </>
  );
};
