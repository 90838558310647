import { useEffect } from 'react';
import { useAppScopeStore } from '@frontend/scope';
import { useModalControl } from '@frontend/design-system';
import {
  useCalendarEventsConfigurationShallowStore,
  useCalendarEventsFormTypeShallowStore,
  useGetIsIntegratedOffice,
} from '../../../../hooks';
import { CalendarEventSelector } from '../../components/CalendarEventsSelector';
import { CalendarEventsEnums } from '../../types';
import { isUUID } from '../../utils';
import { DEFAULT_CONFIGURED_CALENDAR_EVENT_VALUE } from '../constant';
import { TrayContainer } from '../TrayContainer';
import { CalendarEventsModalContent } from './CalendarEventsModalContent';

export const CalendarEventsContainer = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const { configuredCalendarEvent, setConfiguredCalendarEvent } = useCalendarEventsConfigurationShallowStore(
    'configuredCalendarEvent',
    'setConfiguredCalendarEvent'
  );
  const { isIntegratedOffice } = useGetIsIntegratedOffice();
  const { selectedForm, setSelectedForm } = useCalendarEventsFormTypeShallowStore('selectedForm', 'setSelectedForm');
  const calendarEventSelectionModalProps = useModalControl();
  const eventPanelModalProps = useModalControl({
    disableReturnFocus: true,
  });
  const { modalProps, openModal, closeModal } = eventPanelModalProps;

  // useEffect to manage the modal or popover triggers
  useEffect(() => {
    const hasProviderDetails = configuredCalendarEvent?.providerName && configuredCalendarEvent?.providerId;

    const isValidEvent = configuredCalendarEvent?.isValid;

    const calendarEventType = configuredCalendarEvent?.calendarEventType ?? '';

    if (isValidEvent && !hasProviderDetails) {
      setSelectedForm(CalendarEventsEnums.OFFICE_HOURS_OUT_OF_OFFICE_EVENT);
      openModal();
    } else if (
      isValidEvent &&
      calendarEventType &&
      calendarEventType !== CalendarEventsEnums.OFFICE_HOURS_OUT_OF_OFFICE_EVENT
    ) {
      setSelectedForm(calendarEventType);
      openModal();
    } else if (configuredCalendarEvent && configuredCalendarEvent.isValid) {
      setSelectedForm('');
      calendarEventSelectionModalProps.openModal();
    }
  }, [configuredCalendarEvent]);

  useEffect(() => {
    if (selectedForm) {
      openModal();
    }
  }, [selectedForm]);

  useEffect(() => {
    if (selectedLocationIds) {
      handleEventsSelectionCancelButtonClick();
      closeModal();
    }
  }, [selectedLocationIds]);

  const onCalendarEventSelect = (event: CalendarEventsEnums) => {
    if (event) {
      setSelectedForm(event);
      openModal();
    }
  };

  const handleModalClose = () => {
    setSelectedForm('');
    closeModal();
    setConfiguredCalendarEvent(DEFAULT_CONFIGURED_CALENDAR_EVENT_VALUE);
  };

  const handleEventsSelectionCancelButtonClick = () => {
    setSelectedForm('');
    calendarEventSelectionModalProps.closeModal();
    setConfiguredCalendarEvent(DEFAULT_CONFIGURED_CALENDAR_EVENT_VALUE);
  };

  const isValidProviderId = configuredCalendarEvent?.providerId && isUUID(configuredCalendarEvent.providerId);

  const shouldShowCalendarEventsPopover =
    !isIntegratedOffice &&
    ((!configuredCalendarEvent?.providerId && !configuredCalendarEvent?.locationId) || isValidProviderId);

  return (
    <>
      {shouldShowCalendarEventsPopover && (
        <CalendarEventSelector
          modalProps={calendarEventSelectionModalProps.modalProps}
          onCalendarEventSelect={onCalendarEventSelect}
          onModalClose={handleEventsSelectionCancelButtonClick}
        />
      )}

      <TrayContainer modalProps={modalProps} onModalClose={handleModalClose}>
        <CalendarEventsModalContent closeModal={handleModalClose} />
      </TrayContainer>
    </>
  );
};
