import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import {
  DeviceInclude_Enum,
  ReplaceDeviceExtensionCallGroupsResponse,
} from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { CallGroupsApi } from '@frontend/api-call-groups';
import { DevicesQueries } from '@frontend/api-devices';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Table, Text, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { usePhoneSettingsShallowStore } from '../../store/settings';
import { usePhoneSettingsQueryByLocations } from '../../utils/use-phone-settings-query';
import { PhoneSettingsLocationPicker } from '../settings/location-picker';

type Props = ModalControlModalProps & {
  callGroupIds: (string | undefined)[] | undefined;
  deviceName: string | undefined;
  locationIds: string[] | undefined;
  onSave: (ids: string[]) => Promise<ReplaceDeviceExtensionCallGroupsResponse>;
};
export const AssignDeviceToCallGroups = ({ callGroupIds, deviceName, locationIds, onSave, ...rest }: Props) => {
  const [filteredLocations, setFilteredLocations] = useState<string[]>([]);
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const { t } = useTranslation('phone', { keyPrefix: 'devices' });
  const [searchValue, setSearchValue] = useState('');
  const [selectedIds, setSelectedIds] = useState<string[]>();
  const alerts = useAlert();

  const { data: { devices } = { devices: [] } } = DevicesQueries.useGetDevicesList(
    {
      include: [DeviceInclude_Enum.CALL_GROUPS],
    },
    {
      locationId: locationIds?.[0] ?? '',
    }
  );

  const { data: callGroups, isLoading } = usePhoneSettingsQueryByLocations({
    queryKey: queryKeys.callGroups(),
    queryFn: () => CallGroupsApi.listCallGroups(),
    locationIds: settingsTenantLocation?.locationType === 'unify' ? filteredLocations : undefined,
    select: (data) => {
      return Object.entries(data)
        .map(([locationId, callGroups]) => {
          return callGroups.map((callGroup) => {
            if (callGroupIds?.includes(callGroup?.ID)) return;
            return {
              ...callGroup,
              devices: devices?.filter((device) => {
                if (!device.extensions || !device.extensions[0]?.callGroups?.length) return;
                return device.extensions[0].callGroups?.some((callgroup) => callgroup.id === callGroup.ID);
              }),
              locationId,
            };
          });
        })
        .flat()
        .filter(Boolean)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  });

  const searchedCallGroups = useMemo(() => {
    return callGroups?.filter((callgroup) => callgroup.name.includes(searchValue));
  }, [searchValue]);

  return (
    <Modal {...rest} maxWidth={600}>
      <Modal.Header>{t('Assign to Call Groups')}</Modal.Header>
      <Modal.Body>
        <Text>
          <Trans>
            Assign <strong>{deviceName}</strong> to any relevant call groups so that it will receive incoming calls to
            the office and ring with other devices.
          </Trans>
        </Text>
        <Table
          emptyStateConfig={{
            type: 'sync_your_phone',
            header: t('No Call Groups'),
          }}
          isSelectable
          isPaginated
          rowSelectionConfig={{
            hideBulkSelection: true,
            onSelectionToggle: (_s, data) => {
              setSelectedIds(data.map(({ ID }) => ID));
            },
          }}
          hasGlobalSearch
          globalSearchConfig={{
            searchHandler: setSearchValue,
            placeholder: t('Search Call Group or Device'),
            position: settingsTenantLocation?.locationType === 'unify' ? 'right' : 'left',
          }}
          colConfig={[
            {
              Header: t('Name'),
              accessor: ({ name }: { name: string }) => name,
              id: 'name',
              sortType: 'caseInsensitive',
            },
            {
              Header: t('Devices'),
              id: 'num-of-devices',
              accessor: ({ devices }) => devices,
              cellRenderer: (devices) => {
                return !!devices.length && <Text>{t('{{count}} devices', { count: devices.length })}</Text>;
              },
              disableSortBy: true,
            },
          ]}
          data={searchedCallGroups || callGroups || []}
          hasResponsiveColWidths
          isLoading={isLoading}
          customToolbarRender={
            settingsTenantLocation?.locationType === 'unify'
              ? () => (
                  <PhoneSettingsLocationPicker
                    filteredLocations={filteredLocations}
                    tenantLocation={settingsTenantLocation}
                    setFilteredLocations={setFilteredLocations}
                  />
                )
              : undefined
          }
          wrapperStyle={css`
            margin-top: ${theme.spacing(4)};

            .table-toolbar {
              justify-content: space-between;
            }
          `}
          expandableRowComponent={(row) => {
            return (
              <div
                css={css`
                  width: 100%;
                  padding: ${theme.spacing(2)} ${theme.spacing(4)};
                  overflow-y: auto;
                  height: 300px;
                  display: flex;
                  flex-direction: column;
                  height: auto;
                `}
              >
                <Text
                  size='medium'
                  color='light'
                  css={css`
                    margin-bottom: ${theme.spacing(2)};
                  `}
                >
                  {!!row.devices?.length ? t('Devices in Call Group') : t('No Devices Assigned Yet')}
                </Text>
                {row.devices?.map((device) => {
                  return (
                    <div key={device.deviceId}>
                      <Text
                        as='div'
                        css={css`
                          display: flex;
                          flex-direction: column;
                        `}
                      >
                        <Text
                          css={css`
                            margin: ${theme.spacing(2)} ${theme.spacing(1)};
                          `}
                        >
                          {device.name}
                        </Text>
                      </Text>
                    </div>
                  );
                })}
              </div>
            );
          }}
        />
      </Modal.Body>
      <Modal.Actions
        onSecondaryClick={rest.onClose}
        secondaryLabel={t('Cancel')}
        primaryLabel={t('Save')}
        disablePrimary={!selectedIds?.length}
        onPrimaryClick={() => {
          if (!!selectedIds?.length)
            onSave(selectedIds).then(() => {
              rest.onClose();
              alerts.success(t('Saved Call Group Assignments'));
            });
        }}
      />
    </Modal>
  );
};
