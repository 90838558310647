import { forwardRef } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton } from '@frontend/design-system';

export type Direction = 'left' | 'right';

interface Props {
  buttonStyles?: SerializedStyles;
  className?: string;
  direction: Direction;
  isVisible?: boolean;
  onClick: (arg: Direction) => void;
}

export const ScrollControl = forwardRef<HTMLButtonElement, Props>(
  ({ buttonStyles, className = '', direction, isVisible = true, onClick }, ref) => {
    const { t } = useTranslation('analytics');
    const isLeftScroll = direction === 'left';

    return isVisible ? (
      <IconButton
        className={`${isLeftScroll ? 'left-scroll' : 'right-scroll'} ${className} excluded-in-export`}
        css={[styles, buttonStyles]}
        label={isLeftScroll ? t('Scroll left') : t('Scroll right')}
        onClick={() => onClick(direction)}
        ref={ref}
        showLabelOnHover
      >
        {isLeftScroll ? <Icon name='caret-left' /> : <Icon name='caret-right' />}
      </IconButton>
    ) : null;
  }
);

ScrollControl.displayName = 'ScrollControl';

const styles = css`
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.light};
  cursor: pointer;
  min-width: auto;
  width: ${theme.spacing(4)};
`;
