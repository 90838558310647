import { SchemaIO, SchemaSMSService } from '@frontend/schema';
import { useMutation } from '@frontend/react-query-helpers';
import { useUpdateSmsTags } from './use-update-sms-tags';
import { queryKeys } from '../inbox-queries';
import { getUser } from '@frontend/auth-helpers';

type ApplyTagIO = SchemaIO<(typeof SchemaSMSService)['ApplyTag']>;
type RemoveTagIO = SchemaIO<(typeof SchemaSMSService)['DismissTag']>;

const mutationKeys = {
  baseKeys: ['inbox', 'sms-tags'],
  applyTag: () => [...mutationKeys.baseKeys, 'applyTag'],
  removeTag: () => [...mutationKeys.baseKeys, 'removeTag'],
};

export const useSmsTagsMutations = ({ queryContext }: { queryContext: Parameters<typeof queryKeys.getThread>[0] }) => {
  const user = getUser();
  const { updateMessageTags } = useUpdateSmsTags();

  // It shouldn't be the case that user is undefined, but we need to handle it just in case
  // We can't use an empty string, because that would indicate that it was auto-applied by weave assistant
  const currentUserId = user?.userID ?? 'unknown-user';

  const applyTag = useMutation({
    mutationKey: mutationKeys.applyTag(),
    mutationFn: (req: ApplyTagIO['input']) => SchemaSMSService.ApplyTag(req),
    onSuccess: (_res, req) => {
      updateMessageTags({
        method: 'apply',
        smsId: req.smsId,
        tagId: req.tagId,
        userId: currentUserId,
        ...queryContext,
      });
    },
  });

  const removeTag = useMutation({
    mutationKey: mutationKeys.removeTag(),
    mutationFn: (req: RemoveTagIO['input']) => SchemaSMSService.DismissTag(req),
    onSuccess: (_res, req) => {
      updateMessageTags({
        method: 'dismiss',
        smsId: req.smsId,
        tagId: req.tagId,
        userId: currentUserId,
        ...queryContext,
      });
    },
  });

  return {
    applyTag,
    removeTag,
  };
};
