import { useState } from 'react';
import { FormsAutoReminder } from '@frontend/api';
import { ModalControlResponse, useModalControl, useAlert } from '@frontend/design-system';
import { LibraryListType } from '../library.types';
import AutoReminderWarningModal, {
  AutoReminderWarningModalProps,
} from './auto-reminder-warning-modal/auto-reminder-warning-modal.component';

interface AutoReminderCheckParams {
  type: LibraryListType;
  entityId: string;
}

const ReminderType = FormsAutoReminder.Types.ReminderType;

interface UseAutoReminderCheckResults extends ModalControlResponse {
  AutoReminderWarningModal: React.ComponentType<AutoReminderWarningModalProps>;
  checkIfPartOfAutoReminder: (locationId: string, params: AutoReminderCheckParams) => Promise<{ canProceed: boolean }>;
  autoReminders: FormsAutoReminder.Types.AutoReminderCheckResult;
}

enum TemplateType {
  Individual = ' (Individual)',
  Family = ' (Family)',
  IndividualSpanish = ' (Individual - Spanish)',
  FamilySpanish = ' (Family - Spanish)',
}

export const useAutoReminderCheck = (): UseAutoReminderCheckResults => {
  const alert = useAlert();
  const modalControlProps = useModalControl();

  const [autoReminders, setAutoReminders] = useState<FormsAutoReminder.Types.AutoReminderCheckResult>({
    success: false,
    hasActiveReminders: false,
    appointmentReminders: [],
    appointmentFollowup: [],
    birthdays: [],
    recall: [],
    reviews: [],
    saveTheDates: [],
    missedCalls: [],
    orderReady: [],
    unspecified: [],
  });

  async function checkIfPartOfAutoReminder(
    locationId: string,
    params: AutoReminderCheckParams
  ): Promise<{ canProceed: boolean }> {
    try {
      const { rules } = await FormsAutoReminder.API.fetchAutoReminderRules(locationId);

      const results = processAutoRemindersRules(rules, params);
      setAutoReminders(results);

      if (results.hasActiveReminders) {
        modalControlProps.openModal();
        return { canProceed: false };
      }

      return { canProceed: true };
    } catch (err) {
      alert.error('Failed to check auto reminders!');

      setAutoReminders({
        success: false,
        hasActiveReminders: false,
        appointmentReminders: [],
        appointmentFollowup: [],
        birthdays: [],
        recall: [],
        reviews: [],
        saveTheDates: [],
        missedCalls: [],
        orderReady: [],
        unspecified: [],
      });

      return { canProceed: false };
    }
  }

  return {
    ...modalControlProps,
    autoReminders,
    AutoReminderWarningModal,
    checkIfPartOfAutoReminder,
  };
};

function processAutoRemindersRules(
  rules: FormsAutoReminder.Types.Rules[],
  entity: AutoReminderCheckParams
): FormsAutoReminder.Types.AutoReminderCheckResult {
  const result: FormsAutoReminder.Types.AutoReminderCheckResult = {
    success: false,
    hasActiveReminders: false,
    appointmentReminders: [],
    appointmentFollowup: [],
    birthdays: [],
    recall: [],
    reviews: [],
    saveTheDates: [],
    missedCalls: [],
    orderReady: [],
    unspecified: [],
  };

  for (const rule of rules) {
    for (const template of rule.templates) {
      if (template.form && template.form.documentType === entity.type && template.form.documentId === entity.entityId) {
        const suffix = getRuleNameSuffix(template.locale, template.personStrategy);
        pushTypesToResult(rule.autoMessageType.slug, rule.id + suffix, rule.name + suffix);
      }
    }

    if (
      result.appointmentReminders.length ||
      result.appointmentFollowup.length ||
      result.birthdays.length ||
      result.recall.length ||
      result.reviews.length ||
      result.saveTheDates.length ||
      result.missedCalls.length ||
      result.orderReady.length ||
      result.unspecified.length
    ) {
      result.hasActiveReminders = true;
    }
  }

  function getRuleNameSuffix(locale: string, personStrategy: string) {
    switch (locale) {
      case 'en_US':
        if (personStrategy === 'DEFAULT') {
          return TemplateType.Individual;
        }
        return TemplateType.Family;
      case 'es_ES':
        if (personStrategy === 'DEFAULT') {
          return TemplateType.IndividualSpanish;
        }
        return TemplateType.FamilySpanish;
    }
    return '';
  }

  function pushTypesToResult(appointmentType: string, ruleId: string, ruleName: string) {
    switch (appointmentType) {
      case ReminderType.AppointmentReminder:
        result.appointmentReminders.push({
          id: ruleId,
          name: ruleName,
        });
        break;
      case ReminderType.AppointmentFollowup:
        result.appointmentFollowup.push({
          id: ruleId,
          name: ruleName,
        });
        break;
      case ReminderType.BirthdayReminder:
        result.birthdays.push({
          id: ruleId,
          name: ruleName,
        });
        break;
      case ReminderType.RecallReminder:
        result.recall.push({
          id: ruleId,
          name: ruleName,
        });
        break;
      case ReminderType.ReviewReminder:
        result.reviews.push({
          id: ruleId,
          name: ruleName,
        });
        break;
      case ReminderType.SaveTheDateReminder:
        result.saveTheDates.push({
          id: ruleId,
          name: ruleName,
        });
        break;
      case ReminderType.MissedCall:
        result.missedCalls.push({
          id: ruleId,
          name: ruleName,
        });
        break;
      case ReminderType.OrderReady:
        result.orderReady.push({
          id: ruleId,
          name: ruleName,
        });
        break;
      default:
        result.unspecified.push({
          id: ruleId,
          name: ruleName,
        });
        break;
    }
  }

  return result;
}
