import { css } from '@emotion/react';
import { InvoiceModel } from '@frontend/api-invoices';
import { RefundReason, RefundRequestParams } from '@frontend/api-refunds';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  EmailField,
  Modal,
  MoneyField,
  Text,
  useForm,
  useModalLoadingState,
  ValidatorFieldState,
  useAlert,
} from '@frontend/design-system';
import { calcAvailableToRefund, formatCentsToCurrency, stringDollarsToCents } from '../../../utils';

type RefundFormProps = {
  invoice: InvoiceModel;
  payment: InvoiceModel['payment'];
  refundAction: (params: RefundRequestParams) => Promise<void>;
};

type RefundReasonType = keyof typeof RefundReason;

export const RefundForm = ({ refundAction, invoice }: RefundFormProps) => {
  const { t } = useTranslation('payments');
  const alert = useAlert();
  const { setLoading } = useModalLoadingState();

  const reasonMap = {
    FRAUDULENT: t('Fraudulent'),
    REQUESTED_BY_CUSTOMER: t('Requested by customer'),
    DUPLICATE: t('Duplicate'),
  };

  const { payment } = invoice;
  const availableForRefund = calcAvailableToRefund(invoice);
  // FORMFIELDS
  const { formProps, getFieldProps } = useForm({
    fields: {
      amount: {
        type: 'money',
        required: true,
        //TODO: can update this when the design-system supports proper cents format
        value: payment?.paidAmount.toFixed() ? formatCentsToCurrency(availableForRefund) : '',
        validator: ({ value = '' }: ValidatorFieldState<'money'>) => {
          if (payment?.paidAmount && +value > availableForRefund / 100) {
            return t('Cannot refund more than the available balance of {{balance}}', {
              balance: formatCentsToCurrency(availableForRefund),
            });
          } else {
            return '';
          }
        },
      },
      reason: {
        type: 'dropdown',
        required: true,
      },
      receipt: {
        type: 'email',
        value: invoice.person.emailAddress === 'UNKNOWN' ? '' : invoice.person.emailAddress,
      },
    },
    onSubmit: async (values) => {
      if (!payment?.paymentId) {
        alert.error(t('No payment id for this invoice'));
        return;
      }
      setLoading(true);

      await refundAction({
        amount: stringDollarsToCents(values.amount),
        reason: values.reason as keyof typeof RefundReason,
        paymentId: payment?.paymentId,
        receiptEmail: values.receipt,
      });

      setLoading(false);
    },
  });

  const amountProps = getFieldProps('amount');
  const reasonProps = getFieldProps('reason');
  const receiptProps = getFieldProps('receipt');

  // ids for labels
  const amountLabelId = `${amountProps.id}-label`;
  const reasonLabelId = `${reasonProps.id}-label`;
  const receiptLabelId = `${receiptProps.id}-label`;

  return (
    <form
      {...formProps}
      css={css`
        padding: ${theme.spacing(0, 4)};
        > label:not(:first-of-type) {
          margin: ${theme.spacing(3, 0, 1)};
        }
        > label:first-of-type {
          margin: ${theme.spacing(3, 0, 0)};
        }
      `}
    >
      <Text id={amountLabelId} weight='bold' as='label' css={{ margin: 0 }}>
        {t('Refund Amount')}
      </Text>
      <Text color='light' css={{ margin: theme.spacing(0, 0, 1) }}>
        {t('Available to refund {{amount}}', { amount: formatCentsToCurrency(availableForRefund) })}
      </Text>
      <MoneyField
        {...amountProps}
        aria-labelledby={amountLabelId}
        placeholder={t('Enter amount')}
        label=''
        max={Math.round(payment?.paidAmount ?? 0 * 100)}
        data-trackingid='pay-portal-refund-txt-amount'
      />
      <Text id={reasonLabelId} weight='bold' as='label'>
        {t('Refund Reason')}
      </Text>
      <DropdownField
        {...reasonProps}
        aria-labelledby={reasonLabelId}
        placeholder={t('Select reason')}
        label=''
        data-trackingid='pay-portal-refund-drp-reason'
      >
        {Object.keys(RefundReason).map((key) => (
          <DropdownField.Option
            key={key}
            value={key}
            data-trackingid={`pay-portal-refund-option-${key.toLocaleLowerCase()}`}
          >
            {reasonMap[key as RefundReasonType]}
          </DropdownField.Option>
        ))}
      </DropdownField>
      <Text id={receiptLabelId} weight='bold' as='label'>
        {t('Customer Receipt Email')}
      </Text>
      <EmailField
        {...receiptProps}
        aria-labelledby={receiptLabelId}
        placeholder='eg, dave.grohl@gmail.com'
        clearable
        label=''
        data-trackingid='pay-portal-refund-txt-email'
      />
      <Modal.Actions
        onPrimaryClick='submit'
        primaryLabel={t('Refund')}
        primaryTrackingId='pay-portal-refund-btn-submit'
        css={css`
          padding: 0;
          margin-top: ${theme.spacing(4)};
        `}
      />
    </form>
  );
};
