import { VoicePhoneNumber } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-numbers/phone_numbers_service.pb';

export const useNumbersToLocationMap = (outboundNumbers: VoicePhoneNumber[] | undefined) => {
  const map: Record<string, string> = {};
  outboundNumbers?.forEach((caller) => {
    const locationName = caller.location?.name;
    if (locationName) {
      caller.phoneNumbers?.forEach((phoneNumber) => {
        const nationalNumber = phoneNumber?.number?.nationalNumber?.toString();
        if (nationalNumber) {
          map[nationalNumber] = locationName;
        }
      });
    }
  });
  return map;
};

export const getDefaultVoiceData = (voiceNumbersList: VoicePhoneNumber[]) => {
  const firstVoicePhoneNumber = voiceNumbersList?.[0];
  if (!!firstVoicePhoneNumber) {
    const defaultVoiceNumberData = firstVoicePhoneNumber.phoneNumbers?.find((phone) => !!phone.default);
    return {
      defaultNumber: defaultVoiceNumberData?.number?.nationalNumber?.toString() ?? '',
      locationName: firstVoicePhoneNumber.location?.name ?? '',
    };
  }

  return { defaultNumber: '', locationName: '' };
};

export const getSortedVoiceNumbers = (voiceNumbers: VoicePhoneNumber[]): VoicePhoneNumber[] => {
  const outboundNumbers = voiceNumbers.reduce((acc, data) => {
    const sortedNumbers = (data.phoneNumbers || []).sort((a, b) => {
      const phoneNumerA = a.number?.nationalNumber?.toString() ?? '';
      const phoneNumerB = b.number?.nationalNumber?.toString() ?? '';
      return phoneNumerA.localeCompare(phoneNumerB);
    });
    return [
      ...acc,
      {
        location: data.location,
        phoneNumbers: sortedNumbers,
      },
    ];
  }, [] as VoicePhoneNumber[]);

  const sortedVoiceData = outboundNumbers.sort((a, b) => {
    const locationA = a.location?.name?.toLowerCase() ?? '';
    const locationB = b.location?.name?.toLowerCase() ?? '';

    return locationA.localeCompare(locationB);
  });

  return sortedVoiceData;
};
