import { FC } from 'react';
import { css } from '@emotion/react';
import { Channel } from 'stream-chat';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useAlert, TextLink, ConfirmationModal, useModalControl } from '@frontend/design-system';
import { MIN_MODAL_WIDTH } from '../../../constants';
import { useTeamChatStore } from '../../../providers';

interface ArchiveChannelProps {
  className?: string;
  isArchived: boolean;
  channel: Channel;
}

export const ArchiveChannel: FC<ArchiveChannelProps> = ({ isArchived, channel, className }) => {
  const { t } = useTranslation('team-chat');
  const { error } = useAlert();
  const { openModal, modalProps } = useModalControl();
  const { resetActiveConversation, removeConversation } = useTeamChatStore([
    'resetActiveConversation',
    'removeConversation',
  ]);

  const updateChannelVisibility = async () => {
    try {
      await channel.updatePartial({ set: { frozen: !isArchived } });
      if (!isArchived && channel.id) {
        resetActiveConversation();
        removeConversation(channel.id);
      }
    } catch (err) {
      const errorLabel = isArchived ? t('Failed to unarchive conversation') : t('Failed to archive conversation');
      error(errorLabel);
    }
  };

  const label = isArchived ? t('Unarchive Channel for everyone') : t('Archive Channel for everyone');

  return (
    <>
      <TextLink
        css={css({ padding: theme.spacing(2) })}
        onClick={isArchived ? updateChannelVisibility : openModal}
        className={className}
      >
        <Text color='default' weight='bold' textAlign='left'>
          {label}
        </Text>
      </TextLink>
      <ConfirmationModal
        onConfirm={updateChannelVisibility}
        title={t('Archive Conversation')}
        maxWidth={MIN_MODAL_WIDTH}
        {...modalProps}
      >
        <Text>{t('Are you sure you want to archive this conversation?')}</Text>
      </ConfirmationModal>
    </>
  );
};
