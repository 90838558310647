import { SMSSearchV2Mutations, SMSSearchV2Queries } from '@frontend/api-sms-search-v2';
import { PersonsV3 } from '@frontend/api-person';
import { useAppScopeStore } from '@frontend/scope';
import { sanitizePhoneNumber } from '@frontend/phone-numbers';
import { useInboxFiltersShallowStore } from '../stores';
import { useTranslation } from '@frontend/i18n';
import { containsNoAlphabets } from '../utils';
import { PERSON_SEARCH_PAGE_SIZE } from '../constants';
import { SearchRecord, ThreadResult } from '@weave/schema-gen-ts/dist/schemas/sms/search/v2/sms_search_service.pb';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { useAlert } from '@frontend/design-system';

type UseInboxSearchProps = {
  debouncedSearchValue: string;
};

type SearchResults<T> = {
  isLoading: boolean;
  results: T[];
  total: number;
};

type InfiniteSearchResults<T> = Omit<SearchResults<T>, 'isLoading'> & {
  isLoading: {
    firstPage: boolean;
    nextPage: boolean;
  };
  hasNextPage: boolean;
  loadMore: () => void;
};

export type UseInboxSearchResults = {
  recentSearches: SearchResults<SearchRecord>;
  personsQuery: InfiniteSearchResults<Person>;
  messagesQuery: InfiniteSearchResults<ThreadResult>;
  removeSearchResult: (searchId: string) => void;
  logSearch: (searchQuery: string) => void;
};

export const useInboxSearch = ({ debouncedSearchValue }: UseInboxSearchProps): UseInboxSearchResults => {
  const { t } = useTranslation('inbox');
  const alert = useAlert();
  const { selectedLocationIds } = useAppScopeStore();
  const { inboxFilters } = useInboxFiltersShallowStore('inboxFilters');
  const isPhoneNumberSearch = containsNoAlphabets(debouncedSearchValue);

  const recentSearchesQuery = SMSSearchV2Queries.useListSearchesQuery({});
  const removeRecentSearchMutation = SMSSearchV2Mutations.useDeleteSearchMutation({
    onSuccess: () => {
      alert.success(t('Search successfully deleted.'));
    },
    onError: () => {
      alert.error(t('Something went wrong. Please try again later.'));
    },
  });
  const logSearchMutation = SMSSearchV2Mutations.useLogSearchMutation();

  const personsQuery = PersonsV3.PersonQueries.usePersonSearchLegacyInfiniteQuery(
    {
      locationIds: selectedLocationIds,
      search: debouncedSearchValue,
      page: {
        size: PERSON_SEARCH_PAGE_SIZE,
      },
    },
    {
      enabled: !!debouncedSearchValue && !isPhoneNumberSearch,
      retry: false,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) =>
            page.persons
              ? page
              : {
                  ...page,
                  persons: [],
                }
          ),
        };
      },
      onError: (err) => {
        if (
          typeof err.data === 'object' &&
          typeof err.data.message === 'string' &&
          err.data.message.includes('multiple org ids found')
        ) {
          alert.error(
            t('Contacts could not be searched. Please ensure you have only one organization selected, and try again.')
          );
        }
      },
    }
  );

  const personsByPhoneQuery = PersonsV3.PersonQueries.useListPersonsByPhoneLegacyInfiniteQuery(
    {
      locationIds: selectedLocationIds,
      phoneNumber: sanitizePhoneNumber(debouncedSearchValue),
      partialSearch: true,
      page: {
        size: PERSON_SEARCH_PAGE_SIZE,
      },
    },
    {
      enabled: !!debouncedSearchValue && isPhoneNumberSearch,
      retry: false,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) =>
            page.persons
              ? page
              : {
                  ...page,
                  persons: [],
                }
          ),
        };
      },
      onError: (err) => {
        if (
          typeof err.data === 'object' &&
          typeof err.data.message === 'string' &&
          err.data.message.includes('multiple org ids found')
        ) {
          alert.error(
            t('Contacts could not be searched. Please ensure you have only one organization selected, and try again.')
          );
        }
      },
    }
  );

  const messagesQuery = SMSSearchV2Queries.useSMSSearchV2InfiniteQuery(
    {
      locationId: selectedLocationIds[0] ?? '',
      groupIds: selectedLocationIds,
      query: debouncedSearchValue,
      tagIds: inboxFilters.tags.length ? inboxFilters.tags : undefined,
      departmentIds: inboxFilters.departments.length ? inboxFilters.departments : undefined,
      pageSize: 25,
    },
    {
      enabled: !!debouncedSearchValue && !!selectedLocationIds[0],
      retry: 1,
    }
  );

  const personsResults: Person[] = personsQuery.data?.pages.flatMap((page) => page.persons ?? []) ?? [];
  const personsByPhoneResults: Person[] = personsByPhoneQuery.data?.pages.flatMap((page) => page.persons ?? []) ?? [];
  const messagesResults: ThreadResult[] = messagesQuery.data?.pages.flatMap((page) => page.threads) ?? [];

  return {
    recentSearches: {
      isLoading: recentSearchesQuery.isLoading,
      results: recentSearchesQuery.data?.searches ?? [],
      total: recentSearchesQuery.data?.searches.length ?? 0,
    },
    removeSearchResult: (searchId: string) => {
      removeRecentSearchMutation.mutate({ searchId });
    },
    logSearch: (searchQuery: string) => {
      logSearchMutation.mutate({ query: searchQuery });
    },
    personsQuery: isPhoneNumberSearch
      ? {
          isLoading: {
            firstPage: personsByPhoneQuery.isLoading,
            nextPage: personsByPhoneQuery.isFetchingNextPage,
          },
          loadMore: personsByPhoneQuery.fetchNextPage,
          hasNextPage: !!personsByPhoneQuery.hasNextPage,
          results: personsByPhoneResults,
          total: personsByPhoneResults.length,
        }
      : {
          isLoading: {
            firstPage: personsQuery.isLoading,
            nextPage: personsQuery.isFetchingNextPage,
          },
          loadMore: personsQuery.fetchNextPage,
          hasNextPage: !!personsQuery.hasNextPage,
          results: personsResults,
          total: personsResults.length,
        },
    messagesQuery: {
      isLoading: {
        firstPage: messagesQuery.isLoading,
        nextPage: messagesQuery.isFetchingNextPage,
      },
      loadMore: messagesQuery.fetchNextPage,
      hasNextPage: !!messagesQuery.hasNextPage,
      results: messagesResults,
      total: messagesResults.length,
    },
  };
};
