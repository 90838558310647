import { useState } from 'react';
import { css } from '@emotion/react';
import { ErrorBoundary } from '@sentry/react';
import {
  SoftphonePopout,
  useSoftphoneWidgetControl,
  useSoftphoneCallState,
  CallBar,
  SoftphoneIconButton,
} from '@frontend/softphone2';
import { useSoftphoneManager } from '@frontend/weave-softphone-manager';
import { theme } from '@frontend/theme';

type Props = {
  allowTrayView: boolean;
  allowButtonView: boolean;
  allowPopout: boolean;
};
export const SoftphoneWrap = ({ allowTrayView, allowButtonView, allowPopout }: Props) => {
  const isMounted = useSoftphoneManager((ctx) => ctx?.isMounted);
  const isLoading = useSoftphoneManager((ctx) => ctx?.isLoading);
  const restart = useSoftphoneManager((ctx) => ctx?.restart);
  const providersError = useSoftphoneManager((ctx) => ctx?.error);
  const primaryCall = useSoftphoneCallState((ctx) => ctx?.primaryCall);
  const isOpen = useSoftphoneWidgetControl((ctx) => ctx?.isOpen);

  const [isShowingError, setIsShowingError] = useState(false);
  if (!isMounted) {
    return null;
  }

  const showCallBar = allowTrayView && !providersError && !!primaryCall;
  const showButton = allowButtonView && !showCallBar;

  //TODO: this is very contextual logic. We should make this more generic.
  const isStyled = allowButtonView && (showButton || showCallBar);

  return (
    <ErrorBoundary
      fallback={({ error }) => (
        <>
          <Wrapper styled={allowButtonView}>
            {allowButtonView && (
              <SoftphoneIconButton error={error instanceof Error ? error : new Error('Unknown error has occurred!')} />
            )}
            {allowPopout && isOpen && (
              <SoftphonePopout
                error={error instanceof Error ? error : new Error('unknown error occurred!')}
                restart={restart}
                isLoading={isLoading}
                isMounted={isMounted}
              />
            )}
          </Wrapper>
        </>
      )}
    >
      <Wrapper styled={isStyled}>
        {showButton && (
          <SoftphoneIconButton
            error={providersError}
            onClick={providersError ? () => setIsShowingError(!isShowingError) : undefined}
          />
        )}
        {showCallBar && (
          <CallBar
            css={
              !isStyled
                ? css`
                    border-radius: 0;
                    color: white;
                    border: 1px solid ${theme.colors.neutral50};
                  `
                : null
            }
          />
        )}
        {allowPopout && (!providersError || (providersError && isShowingError)) && (
          <SoftphonePopout error={providersError} restart={restart} isLoading={isLoading} isMounted={isMounted} />
        )}
      </Wrapper>
    </ErrorBoundary>
  );
};

const Wrapper = ({ styled, children }: { styled: boolean; children: React.ReactNode }) => {
  if (!styled) {
    return (
      <div
        css={css`
          position: relative;
        `}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: ${theme.spacing(0.5)};
      `}
    >
      {children}
    </div>
  );
};
