import { useModalControl, useAlert } from '@frontend/design-system';
import { UseTemplateFlowArgs, UseTemplateFlowResult } from '../types';
import { useTranslation } from '@frontend/i18n';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { theme } from '@frontend/theme';
import { useAppScopeStore } from '@frontend/scope';
import {
  DynamicFieldProperty_Enum,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { Icon } from '@frontend/icons';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { ReviewInvitationMutations } from '@frontend/api-reviews-invitation';
import { RelatedType } from '@weave/schema-gen-ts/dist/shared/sms/v1/enums.pb';

export const useReviewInvitationTemplateFlow = ({
  groupId,
  personId,
  renderTemplate,
  onOpenSettings,
}: UseTemplateFlowArgs): UseTemplateFlowResult => {
  const { t } = useTranslation('inbox-templates');
  const alert = useAlert();
  const { accessibleLocationData } = useAppScopeStore();
  const isVet = accessibleLocationData[groupId]?.vertical === Vertical.VET ?? false;
  const modalControl = useModalControl();
  const hasReviewsAcl = hasSchemaACL(groupId, Permission.REVIEW_READ);

  const reviewInvitationMutation = ReviewInvitationMutations.useReviewInvitationMutation();

  return {
    extraAction: hasReviewsAcl
      ? [
          {
            icon: Icon({
              name: 'star',
              css: {
                color: theme.colors.neutral70,
              },
            }),
            label: t('Request Review'),
            trackingId: `${InboxPrefixes.Thread}-request-review-button`,
            onClick: () => {
              modalControl.openModal();
            },
          },
        ]
      : [],
    modalProps: {
      ...modalControl.modalProps,
      templateSelectorProps: {
        groupId,
        templateTypes: [isVet ? TemplateType_Slug.MANUAL_REVIEW_REQUEST_PET : TemplateType_Slug.MANUAL_REVIEW_REQUEST],
        onSelectTemplate: (template) => {
          reviewInvitationMutation.mutate(
            {
              locationId: groupId,
              personId,
            },
            {
              onSuccess: (data) => {
                renderTemplate({
                  template,
                  linkData: [
                    {
                      link: data.invitation.invitationLink,
                      relatedId: data.invitation.invitationId,
                      relatedType: RelatedType.RELATED_TYPE_REVIEW,
                    },
                  ],
                  additionalBindings: {
                    [DynamicFieldProperty_Enum.REVIEW_REQUEST_LINK]: data.invitation.invitationLink,
                  },
                });
                modalControl.closeModal();
              },
              onError: () => {
                alert.error(t('An error occurred generating the review invitation link. Please try again later.'));
              },
            }
          );
        },
        onOpenSettings,
      },
      title: t('Select a Review Template'),
    },
  };
};
