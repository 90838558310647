import { memo, useEffect, useMemo } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { AnalyticsCommonTypes, SMSAnalyticsApi, SMSAnalyticsTypes } from '@frontend/api-analytics';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { Chart, XAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { AnalyticsTrackingIds } from '../..';
import { featureFlags } from '../../feature-flags';
import { queryKeys } from '../../query-keys';
import { appendTime, formatters } from '../../utils';
import { DemoChip } from '../demo-chip';
import { useIsMessagingAnalyticsDemoAccount, useMessagingAnalyticsDemoData } from '../sms/hooks';
import { Insights } from './insights';

type Props = {
  data?: SMSAnalyticsTypes.SMSChartsData[];
  filters?: AnalyticsCommonTypes.AnalyticsSummaryFilters;
  isLoading?: boolean;
  onLoading?: (isLoading: boolean) => void;
};

export const MessagingCategoriesOnDashboard = memo(({ data, filters, isLoading, onLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const queryString = useMemo(() => `${JSON.stringify({ ...filters })}`, [filters]);
  const messagingAnalyticsEnabled = useHasFeatureFlag(featureFlags.messagingAnalytics);
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const isDemoAccount = useIsMessagingAnalyticsDemoAccount();
  const demoData = useMessagingAnalyticsDemoData();
  const locationId = selectedLocationIdsWithParents[0];
  const hasAclAccess =
    hasSchemaACL(locationId, Permission.ANALYTICS_READ_MESSAGING) ||
    hasSchemaACL(locationId, Permission.ANALYTICS_READ);

  const { data: messagingData, isFetching: isLoadingMessagingData } = useScopedQuery({
    queryKey: queryKeys.analyticsDashboard(`${queryString}-homepage-messaging-summary-${isDemoAccount}`),
    queryFn: () =>
      filters && !data && !isDemoAccount
        ? SMSAnalyticsApi.getSMSReportingCharts({
            end_date: appendTime(filters.endDate),
            location_id: filters.locationIds,
            start_date: appendTime(filters.startDate),
            time_zone: filters.timeZone,
          })
        : null,
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return isDemoAccount ? demoData?.dailyData : data;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const messageCategories = useMemo(
    () =>
      (data || messagingData || []).reduce(
        (
          acc,
          {
            total_category_manual_messages,
            total_category_missed_call,
            total_category_apt_rem,
            total_category_recall,
            total_category_birthday,
            total_category_marketing_messages,
            total_category_payment_reminders,
            total_category_product_ready,
            total_category_review_requests,
            total_category_save_the_date,
            total_category_messaging_preferences,
            total_category_blank,
            total_messages,
          }
        ) => ({
          apptReminder: (acc.apptReminder || 0) + (total_category_apt_rem as number),
          birthday: (acc.birthday || 0) + (total_category_birthday as number),
          blank: (acc.blank || 0) + (total_category_blank as number),
          manual: (acc.manual || 0) + (total_category_manual_messages as number),
          marketing: (acc.marketing || 0) + (total_category_marketing_messages as number),
          messagingPreference: (acc.messagingPreference || 0) + (total_category_messaging_preferences as number),
          missedCall: (acc.missedCall || 0) + (total_category_missed_call as number),
          paymentReminder: (acc.paymentReminder || 0) + (total_category_payment_reminders as number),
          productReady: (acc.productReady || 0) + (total_category_product_ready as number),
          recallText: (acc.recallText || 0) + (total_category_recall as number),
          reviewRequests: (acc.reviewRequests || 0) + (total_category_review_requests as number),
          saveTheDate: (acc.saveTheDate || 0) + (total_category_save_the_date as number),
          total: (acc.total || 0) + (total_messages as number),
        }),
        {} as Record<string, number>
      ),
    [data, messagingData]
  );

  const topCategories = useMemo(() => {
    // Retrieve the top 6 categories, where the category with the highest count is always at index 0.
    // The 'total' category is used to display the total number of messages outside the chart,
    // And the remaining 5 categories are used to draw the chart.
    const categories = Object.entries(messageCategories)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 6);

    return {
      counts: categories.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
      chartData: {
        groups: categories.slice(1).map(([name, value]) => ({
          name,
          values: {
            messages: value,
          },
        })),
      },
      total: categories[0]?.[1],
    };
  }, [messageCategories]);

  useEffect(() => {
    onLoading?.(isLoadingMessagingData);
  }, [isLoadingMessagingData]);

  if (!messagingAnalyticsEnabled) {
    return null;
  }

  return (
    <Chart
      colors={{
        messages: theme.colors.secondary.seaweed40,
      }}
      isLoading={isLoading || isLoadingMessagingData}
      labels={{
        apptReminder: t('Appt. Reminder'),
        birthday: t('Birthday'),
        blank: t('Incoming'),
        manual: t('Manual'),
        marketing: t('Marketing'),
        messages: t('Messages'),
        messagingPreference: t('Messaging Preference'),
        missedCall: t('Missed Call'),
        paymentReminder: t('Payment Reminder'),
        productReady: t('Product Ready'),
        recallText: t('Recall Text'),
        reviewRequests: t('Review Requests'),
        saveTheDate: t('Save The Date'),
      }}
    >
      <Chart.Header
        actions={[
          {
            disabled: !hasAclAccess,
            hoverText: hasAclAccess ? undefined : t('This action is only allowed by an account admin.'),
            label: t('Show More'),
            onClick: () => {
              navigate({
                to: '/analytics/messaging',
              });
            },
            trackingId: AnalyticsTrackingIds.analytics.showMoreMessagingAnalytics,
          },
        ]}
        leftElement={isDemoAccount && <DemoChip />}
        title={t('Top Message Categories')}
      />
      <Chart.BarChart
        appearance={{
          customXAxisTick: ({ labels, groupName, ...rest }) => {
            return (
              <XAxisLabelValueTick
                {...rest}
                label={labels?.[groupName] || groupName}
                value={formatters.value.format(topCategories.counts[groupName as keyof typeof topCategories.counts])}
              />
            );
          },
          groupsGap: 100,
          showXAxis: true,
          margin: { bottom: 20 },
        }}
        data={topCategories.chartData}
        formatValue={formatters.value.format}
      />
      {!!topCategories.total && (
        <Insights title={t('Total Outbound Messages')} value={formatters.value.shortenNumber(topCategories.total)} />
      )}
    </Chart>
  );
});

MessagingCategoriesOnDashboard.displayName = 'MessagingCategoriesOnDashboard';
