import { FC, ReactNode, useRef, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import jsPDF from 'jspdf';
import { delay } from 'lodash-es';
import { PortalLogo } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  DownloadIcon,
  Heading,
  IconButton,
  Text,
  TextButton,
  TextLink,
  WeaveLogoIcon,
  useAlert,
} from '@frontend/design-system';
import 'svg2pdf.js';

type IconType = 'descriptive' | 'tiny';

interface Props {
  children: ReactNode;
  HintIcon?: FC<React.PropsWithChildren<unknown>>;
  hintText?: string;
  iconType?: IconType;
  title: string;
  trackingId?: string;
  wrapperStyles?: SerializedStyles;
}

type RenderSvg = {
  elementX: number;
  elementY: number;
  pdf: jsPDF;
  svgIndex: number;
  svgList: Element[];
};

type SaveAsPDF = {
  callback?: () => void;
  element: HTMLElement;
  fileName: string;
};

const renderSvg = (props: RenderSvg): Promise<string | undefined> => {
  const { elementX, elementY, pdf, svgIndex, svgList } = props;
  const svg = svgList[svgIndex];
  const { x, y, width, height } = svg.getBoundingClientRect();
  return pdf.svg(svg, { x: x - elementX, y: y - elementY, width, height }).then(() => {
    if (svgIndex < svgList.length - 1) {
      return renderSvg({ ...props, svgIndex: svgIndex + 1 });
    }
    return;
  });
};

const saveFile = (pdf: jsPDF, fileName: string, callback?: () => void) => {
  // Remove the last blank page from the pdf that jsPDF is adding automatically
  const pageCount = pdf.getNumberOfPages();
  if (pageCount > 1) {
    pdf.deletePage(pageCount);
  }
  pdf.save(`${fileName}.pdf`);
  callback?.();
};

const saveAsPdf = ({ callback, element, fileName }: SaveAsPDF) => {
  const { x: elementX, y: elementY, width, height } = element.getBoundingClientRect();
  const pdf = new jsPDF({ format: [width, height], orientation: width > height ? 'l' : 'p', unit: 'px' });

  const svgList = Array.from(element.getElementsByTagName('svg')).filter(
    (svg) => !svg.classList.contains('excluded-in-export')
  );

  pdf.html(element, {
    autoPaging: true,
    callback: () => {
      if (svgList.length) {
        renderSvg({ elementX, elementY, pdf, svgIndex: 0, svgList }).then(() => {
          saveFile(pdf, fileName, callback);
        });
      } else {
        saveFile(pdf, fileName, callback);
      }
    },
    x: 0,
    html2canvas: {
      ignoreElements: (element) => element.tagName === 'svg',
    },
    y: 0,
  });
};

const setPrintProperties = (element: HTMLElement) => {
  element.style.opacity = '1';
  element.style.visibility = 'visible';
};

const resetPrintProperties = (element: HTMLElement) => {
  element.style.opacity = '0';
  element.style.visibility = 'hidden';
};

export const ExportableComponent: FC<React.PropsWithChildren<Props>> = ({
  children,
  HintIcon,
  hintText,
  iconType = 'tiny',
  title,
  trackingId,
  wrapperStyles,
}) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const ref = useRef<HTMLElement>(null);
  const [preparing, setPreparing] = useState<boolean>();

  const captureScreen = async (element: HTMLElement) => {
    setPrintProperties(element);
    try {
      saveAsPdf({
        element,
        fileName: title,
        callback: () => {
          resetPrintProperties(element);
          setPreparing(false);
        },
      });
    } catch (error) {
      alert.error(t('Failed to save as PDF.'));
      resetPrintProperties(element);
      setPreparing(false);
    }
  };

  const handleDownload = () => {
    if (!ref.current) {
      alert.warning(t('Component not present in the document to download.'));
      return;
    }

    setPreparing(true);
    // Give a little time to the ContentLoader to display loading effect properly before staring heavy lifting
    // Otherwise page looks like frozen
    // TODO :: Fix this to use a proper way instead of delay
    delay(() => captureScreen(ref.current as HTMLElement), 500);
  };

  return (
    <>
      <div>
        <div css={[pageStyles.downloadLinkWrapper, wrapperStyles]}>
          {(hintText || HintIcon) && (
            <div css={pageStyles.hintText}>
              {HintIcon && <HintIcon />}
              {hintText && <Text>{hintText}</Text>}
            </div>
          )}
          {iconType === 'tiny' ? (
            <IconButton label={t('Download PDF')} onClick={handleDownload} showLabelOnHover trackingId={trackingId}>
              <DownloadIcon />
            </IconButton>
          ) : (
            <TextButton
              css={[pageStyles.hintText, pageStyles.downloadButton]}
              onClick={handleDownload}
              trackingId={trackingId}
            >
              <DownloadIcon size={18} />
              {t('Download PDF')}
            </TextButton>
          )}
        </div>

        <section css={pageStyles.hiddenPrintContainer} ref={ref}>
          <header css={[pageStyles.common, pageStyles.header]}>
            <div css={pageStyles.headerLogo}>
              <PortalLogo fill={theme.colors.white} />
            </div>
            <Heading>{title}</Heading>
          </header>
          <div css={pageStyles.contentContainer}>{children}</div>
          <footer css={[pageStyles.common, pageStyles.footer]}>
            <div css={pageStyles.footerLogo}>
              <WeaveLogoIcon fill={theme.colors.white} />
            </div>
            <Text>{t('Copyrights @ Weave')}</Text>
            <TextLink to='http://www.getweave.com'>www.getweave.com</TextLink>
          </footer>
        </section>
      </div>
      <ContentLoader message={t('Preparing...')} show={preparing} />
    </>
  );
};

const pageStyles = {
  downloadLinkWrapper: css`
    align-items: center;
    color: ${theme.colors.neutral40};
    display: flex;
    font-weight: ${theme.font.weight.semibold};
    justify-content: space-between;
    padding: ${theme.spacing(2, 0, 0)};

    p {
      color: inherit;
    }
  `,

  hintText: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
  `,

  downloadButton: css`
    color: ${theme.colors.text.interactive};
    font-weight: ${theme.font.weight.regular};
    width: auto;
  `,

  common: css`
    align-items: center;
    color: ${theme.colors.white};
    display: flex;
    gap: ${theme.spacing(2)};

    h1,
    p,
    a {
      color: inherit;
      margin-bottom: ${theme.spacing(1.5)};
    }
  `,

  header: css`
    background-color: ${theme.colors.secondary.seaweed60};
    height: ${theme.spacing(10)};
    padding: ${theme.spacing(0, 4)};
  `,

  contentContainer: css`
    padding: ${theme.spacing(2, 4)};

    .legends-wrapper {
      span {
        margin-top: ${theme.spacing(1)};
      }
    }

    .impact-sign-icon {
      margin-top: ${theme.spacing(0.5)};
    }
  `,

  footer: css`
    background-color: ${theme.colors.neutral70};
    padding: ${theme.spacing(0, 4)};
    height: ${theme.spacing(4)};
  `,

  hiddenPrintContainer: css`
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 1200px;
    z-index: -1;
  `,

  headerLogo: css`
    margin-top: ${theme.spacing(1)};
    width: ${theme.spacing(23)};
  `,

  footerLogo: css`
    height: ${theme.spacing(3)};
    width: ${theme.spacing(3)};
  `,
};
