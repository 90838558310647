import { Entries } from '@frontend/types';
import { theme } from '@frontend/theme';
import {
  FieldProps,
  TextField,
  ResolvedFieldProps,
  DateField,
  PhoneField,
  SwitchField,
  DropdownField,
  NumberField,
} from '@frontend/design-system';
import { AudioPicker } from '../../audio-picker/audio-picker';
import { ForwardingNumberPicker } from '../../forwarding-number-picker';
import { LabeledFormFields, FormFieldsProps, PhoneTreeFormFields } from '../types';

const sharedStyle = {
  width: 200,
};

export const FormField = <T extends LabeledFormFields, G extends string>({
  name,
  field,
  type,
  kind,
  label,
  helperText,
  options,
  meta,
}: PhoneTreeFormFields & { name: G; field: FieldProps<T, G> }) => {
  switch (type) {
    case 'text':
      return (
        <TextField
          css={sharedStyle}
          {...(field as ResolvedFieldProps<'text'>)}
          name={name}
          label={label}
          helperText={helperText}
        />
      );
    case 'date':
      return (
        <DateField
          css={sharedStyle}
          {...(field as ResolvedFieldProps<'date'>)}
          name={name}
          label={label}
          helperText={helperText}
        />
      );
    case 'phone':
      return (
        <PhoneField
          css={sharedStyle}
          {...(field as ResolvedFieldProps<'phone'>)}
          name={name}
          label={label}
          helperText={helperText}
        />
      );
    case 'switch':
      return <SwitchField {...(field as ResolvedFieldProps<'switch'>)} name={name} label={label} />;
    case 'radio':
      return <input type='radio' />;
    case 'checkbox':
      return <input type='checkbox' />;
    case 'dropdown':
      if (kind === 'media') {
        return (
          <AudioPicker
            field={field as ResolvedFieldProps<'text'>}
            key={name}
            tenantId={meta.tenantId}
            mailboxId={meta.voicemailBoxId}
            allowedOptions={{ ...meta.requestedTypes, add: true }}
            name={name}
            widths={{ field: 200, scrubber: 200 }}
          />
        );
      } else if (kind === 'forwardingNumber') {
        return <ForwardingNumberPicker {...(field as ResolvedFieldProps<'text'>)} locationId={meta.locationId} />;
      }
      return (
        <DropdownField
          css={sharedStyle}
          {...(field as ResolvedFieldProps<'dropdown'>)}
          name={name}
          label={label}
          helperText={helperText}
        >
          {options?.map((option) => (
            <DropdownField.Option key={option.value} value={option.value}>
              {option.label}
            </DropdownField.Option>
          ))}
        </DropdownField>
      );
    // case 'textarea':
    //   return <input type='textarea' />;
    case 'number':
      return (
        <NumberField
          css={sharedStyle}
          {...(field as ResolvedFieldProps<'number'>)}
          name={name}
          label={label}
          helperText={helperText}
        />
      );
    default:
      return null;
  }
};

export const FormFields = ({ form, config }: FormFieldsProps) => {
  if (!config || !form) {
    return null;
  }

  return (
    <div
      style={{
        display: 'grid',

        gridTemplateColumns: 'repeat(auto-fit, 200px)',
        gridTemplateRows: 'auto',
        gap: theme.spacing(2),
      }}
    >
      {(Object.entries(config) as Entries<LabeledFormFields>).map(([key, fieldConfig]) => {
        const fieldProps = form.getFieldProps(key);

        /**
         * TODO: This chunk of code is to satisfy types in PhoneTreeFormFields
         * The cause is the dropdown type with kind and meta that are required
         */
        if (
          fieldConfig.type === 'dropdown' &&
          (fieldConfig.kind === 'media' || fieldConfig.kind === 'forwardingNumber')
        ) {
          return (
            // @ts-ignore - fix types later
            <FormField
              key={key}
              name={key}
              field={fieldProps}
              type={fieldConfig.type}
              kind={fieldConfig.kind}
              meta={fieldConfig.meta}
              options={fieldConfig.options}
              label={fieldConfig.label}
              helperText={fieldConfig.helperText}
            />
          );
        }

        return (
          <FormField
            key={key}
            name={key}
            field={fieldProps}
            type={fieldConfig.type}
            kind={fieldConfig.kind}
            meta={fieldConfig.meta}
            options={fieldConfig.options}
            label={fieldConfig.label}
            helperText={fieldConfig.helperText}
          />
        );
      })}
    </div>
  );
};
