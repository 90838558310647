import {
  ContactDirectoriesAPI,
  ContactDirectoriesQuery,
  ContactDirectoriesQueryKeys,
} from '@frontend/api-contact-directories';
import { ContentLoader, Modal, ModalControlModalProps, Text, useAlert } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useQueryClient } from 'react-query';
import { useMutation } from '@frontend/react-query-helpers';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { UnifyLocationsListView } from '../component';
import { CommonModalWrapper } from './modal-wrapper';

type Props = ModalControlModalProps & {
  contactDirectoryId: string;
  name?: string;
};
export const DeleteContactDirectory = ({ contactDirectoryId, name, ...modalProps }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const queryClient = useQueryClient();
  const alerts = useAlert();
  const { navigate } = useSettingsNavigate();
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const locationId = locationIds?.[0] ?? '';
  const { useGetContactDirectoryDevices } = ContactDirectoriesQuery.useContactDirectoryQueries(locationId);
  const { data: devices, isLoading: isLoading } = useGetContactDirectoryDevices({
    contactListId: contactDirectoryId,
  });

  const { mutate } = useMutation((payload: string) => ContactDirectoriesAPI.deleteContactList(payload, locationId), {
    onSuccess: () => {
      modalProps.onClose();
      queryClient.invalidateQueries(ContactDirectoriesQueryKeys.queryKeys.getContactDirectories(locationId));
      alerts.success(t('Successfully deleted contact list'));
      navigate({
        to: '/phone/contact-directories',
      });
    },
  });

  const handleSave = () => {
    mutate(contactDirectoryId);
  };

  return (
    <>
      <ContentLoader show={isLoading} />
      <CommonModalWrapper {...modalProps}>
        <Modal.Header onClose={modalProps.onClose}>{t('Delete Contact List')}</Modal.Header>
        <Modal.Body
          css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), margin: theme.spacing(2, 0) }}
        >
          <Text>
            {!!devices?.devices?.length ? (
              <Trans>
                Deleting this list{' '}
                <Text as='span' weight='bold'>
                  will not
                </Text>{' '}
                delete its contacts. This contact list will be removed from the following devices:
              </Trans>
            ) : (
              <Trans>
                Deleting this list{' '}
                <Text as='span' weight='bold'>
                  will not
                </Text>{' '}
                delete its contacts.
              </Trans>
            )}
          </Text>
          {(locationIds?.length ?? 1) > 1 ? (
            <UnifyLocationsListView devices={devices?.devices ?? []} />
          ) : (
            !!devices?.devices?.length && (
              <ul>
                {devices?.devices?.map(({ name, id }) => {
                  return (
                    <Text as={'li'} key={id}>
                      {name}
                    </Text>
                  );
                })}
              </ul>
            )
          )}
          <Text>
            <Trans t={t}>
              Are you sure you want to delete{' '}
              <Text as='span' weight='bold'>
                {name}
              </Text>
              ?
            </Trans>
          </Text>
        </Modal.Body>
        <Modal.Actions
          onPrimaryClick={handleSave}
          destructive
          primaryLabel={t('Delete List')}
          secondaryLabel={t('Cancel')}
          onSecondaryClick={modalProps.onClose}
        />
      </CommonModalWrapper>
    </>
  );
};
