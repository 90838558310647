import { Message } from '@weave/schema-gen-ts/dist/schemas/messaging/auto-worker/v1/models.pb';
import { MessageGroup } from '@weave/schema-gen-ts/dist/schemas/messaging/auto-worker/v1/service.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { AutoWorkerMutations, AutoWorkerTypes } from '@frontend/api-auto-worker';
import { PersonTypes, PersonHelpers, PersonsV3 } from '@frontend/api-person';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useFormatStatuses } from '@frontend/message-queue-hooks';
import { getMessageStatusColors } from '@frontend/message-status-colors';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { Photos } from '@frontend/photos';
import { kebabCase } from '@frontend/string';
import { theme } from '@frontend/theme';
import { Avatar, Chip, NakedButton, SecondaryButton, Text, useAlert, useTooltip } from '@frontend/design-system';

type DestinationLabel = {
  destination?: string;
  label?: string;
};

type Props = {
  avatarOnClick?: () => void;
  canSuppress?: boolean;
  cardType?: 'person' | 'rule';
  hideBadgeAndButton?: boolean;
  isNext?: boolean;
  message: Message;
  messageDetails?: string;
  person?: Partial<PersonTypes.Person>;
  queryKeyToInvalidate?: AutoWorkerTypes.ScopedMessageRequestQueryKey;
  showAvatar?: boolean;
  showContactInfo?: boolean;
  showTimestamp?: boolean;
  simplifiedTimestamp?: boolean;
  trackingPrefix: string;
};

const TIME_FORMAT = 'h:mm A';
const DATE_FORMAT = 'MMMM Do';
const DAY_FORMAT = 'dddd';

export const AutoMessageSuppressionCard = ({
  avatarOnClick,
  canSuppress = false,
  cardType = 'rule',
  hideBadgeAndButton = false,
  isNext,
  message,
  messageDetails,
  person,
  queryKeyToInvalidate = [],
  showAvatar = false,
  showContactInfo = false,
  showTimestamp = false,
  simplifiedTimestamp = false,
  trackingPrefix,
  ...rest
}: Props) => {
  const { t } = useTranslation('messages');
  const queryClient = useQueryClient();
  const alert = useAlert();

  const { destination, locationId = '', messageId, ruleId, ruleName, sendAt, statuses, suppressedAt } = message;
  const { userID: userId = '' } = getUser() ?? {};
  const lastStatus = statuses?.at(-1);
  const currentStatus = lastStatus?.status || message.currentStatus || OutboundMessageStatus.UNSPECIFIED;
  const statusDetails = lastStatus?.details ?? [];
  const statusDetail = statusDetails[0];
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  const contactName = PersonHelpers.getFullName({
    FirstName: person?.FirstName,
    LastName: person?.LastName,
    PreferredName: person?.PreferredName,
  });

  const { data: formattedDestination } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: person?.PersonID ?? '',
      locationIds: [locationId],
    },
    {
      enabled: !!person?.PersonID && !!locationId,
      select: (data) => {
        const contactInfo = data?.contactInfo?.map(({ destination, type }) => ({
          destination,
          label: PersonsV3.PersonHelpers.contactTypeLabels[type],
        }));
        const currentDestination = contactInfo?.find(
          (detail) => detail.destination && destination?.includes(detail.destination)
        );
        const formatDestination = ({ destination, label }: DestinationLabel) =>
          `${label ?? ''} ${formatPhoneNumber(destination)}`.trim();
        return formatDestination(currentDestination!);
      },
    }
  );

  const handleError = (context: unknown, message: string) => {
    const previous = (context as { previous: Record<string, MessageGroup> }).previous;
    queryClient.setQueryData(queryKeyToInvalidate, previous);
    alert.error(message);
  };

  const { mutate: suppressMessage } = AutoWorkerMutations.useSuppressMessage(queryKeyToInvalidate, {
    onError: (_err, _req, context: unknown) =>
      handleError(context, t('There was an error skipping the message. Please try again later.')),
  });
  const { mutate: unsuppressMessage } = AutoWorkerMutations.useUnsuppressMessage(queryKeyToInvalidate, {
    onError: (_err, _req, context: unknown) =>
      handleError(context, t('There was an error resuming the message. Please try again later.')),
  });

  const formatFutureTimestamp = (time?: string) => {
    const timestamp = dayjs(time);
    const now = dayjs();

    const withinHour =
      now.add(1, 'hour').isAfter(timestamp) &&
      timestamp.diff(now, 'minute') >= 0 &&
      timestamp.diff(now, 'minute') <= 60;
    const withinDay = now.endOf('day').isAfter(timestamp);
    const withinTomorrow = now.add(1, 'day').endOf('day').isAfter(timestamp);

    if (withinHour) {
      const minuteDifference = Math.abs(timestamp.diff(now, 'minute'));
      if (minuteDifference === 60) return t('Preparing to send in 1 hour');
      return canSuppress
        ? simplifiedTimestamp
          ? t('Today at {{time}}', { time: timestamp.format(TIME_FORMAT) })
          : t('Preparing to send in {{count}} minute', { count: minuteDifference })
        : t('Not sending in {{count}} minute', { count: minuteDifference });
    } else if (withinDay) {
      return canSuppress
        ? simplifiedTimestamp
          ? t('Today at {{time}}', { time: timestamp.format(TIME_FORMAT) })
          : t('Sending today at {{time}}', { time: timestamp.format(TIME_FORMAT) })
        : t('Not sending today at {{time}}', { time: timestamp.format(TIME_FORMAT) });
    } else if (withinTomorrow) {
      return canSuppress
        ? simplifiedTimestamp
          ? t('Tomorrow at {{time}}', { time: timestamp.format(TIME_FORMAT) })
          : t('Sending tomorrow at {{time}}', { time: timestamp.format(TIME_FORMAT) })
        : t('Not sending tomorrow at {{time}}', { time: timestamp.format(TIME_FORMAT) });
    } else {
      return canSuppress
        ? simplifiedTimestamp
          ? t('{{date}} at {{time}}', {
              date: timestamp.format(DATE_FORMAT),
              time: timestamp.format(TIME_FORMAT),
            })
          : t('Sending {{date}} at {{time}}', {
              date: timestamp.format(DATE_FORMAT),
              time: timestamp.format(TIME_FORMAT),
            })
        : t('Not sending {{date}} at {{time}}', {
            date: timestamp.format(DATE_FORMAT),
            time: timestamp.format(TIME_FORMAT),
          });
    }
  };

  const formatPastTimestamp = (time?: string) => {
    const timestamp = dayjs(time);
    const now = dayjs();

    const withinToday = now.startOf('day').isBefore(timestamp);
    const withinTomorrow = now.subtract(1, 'day').startOf('day').isBefore(timestamp);
    const withinWeek = now.subtract(1, 'week').startOf('week').isBefore(timestamp);

    const sent =
      currentStatus === OutboundMessageStatus.SENT || currentStatus === OutboundMessageStatus.DELIVERED
        ? 'Sent'
        : 'Not sent';

    if (withinToday) {
      return t('{{sent}} today at {{time}}', { time: timestamp.format(TIME_FORMAT), sent });
    } else if (withinTomorrow) {
      return t('{{sent}} yesterday at {{time}}', { time: timestamp.format(TIME_FORMAT), sent });
    } else if (withinWeek) {
      return t('{{sent}} {{day}} at {{time}}', {
        day: timestamp.format(DAY_FORMAT),
        time: timestamp.format(TIME_FORMAT),
        sent,
      });
    } else {
      return t('{{sent}} {{date}} at {{time}}', {
        date: timestamp.format(DATE_FORMAT),
        time: timestamp.format(TIME_FORMAT),
        sent,
      });
    }
  };

  const isNotChangeable = (time?: string) => {
    const WINDOW_TIME = 30; // cut off time defined by the backend in minutes
    const timestamp = dayjs(time);
    const now = dayjs();
    const isAfter = timestamp.isAfter(now);
    const withinWindowTime = now.add(WINDOW_TIME, 'minute');
    return isAfter && timestamp.isBefore(withinWindowTime);
  };

  const { backgroundColor, textColor: color } = getMessageStatusColors(currentStatus);
  const { formatDisplayedStatus, getGroupedStatus } = useFormatStatuses();
  const formattedCurrentStatus = formatDisplayedStatus(getGroupedStatus(currentStatus));
  const displayedCurrentStatus =
    currentStatus === OutboundMessageStatus.NOT_SENT
      ? isNext
        ? t('Not Sending')
        : t('Not Sent')
      : formattedCurrentStatus;

  return (
    <div
      className='auto-message-suppression-card'
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.colors.neutral20}`,
        '&:last-of-type': { borderBottom: 'none' },
      }}
      {...rest}
    >
      <div css={{ display: 'flex', columnGap: theme.spacing(1.5), alignItems: 'center' }}>
        {showAvatar &&
          (person ? (
            <Photos.ContactProfilePhoto
              name={contactName}
              personId={person.PersonID!}
              locationId={locationId}
              onClick={avatarOnClick}
              data-trackingid={trackingPrefix + '-suppression-card-contact-profile-photo'}
            />
          ) : (
            <Avatar size='large' data-trackingid={trackingPrefix + '-suppression-card-avatar'} />
          ))}
        <div css={{ display: 'flex', flexDirection: 'column', rowGap: theme.spacing(0.5) }}>
          <Text
            weight='bold'
            css={{ maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {cardType === 'person' ? contactName : ruleName}
          </Text>
          {showContactInfo && <Text size='small'>{formattedDestination}</Text>}
          {messageDetails && <Text size='small'>{messageDetails}</Text>}
          {showTimestamp && (
            <Text size='small' color='light'>
              {isNext ? formatFutureTimestamp(sendAt) : formatPastTimestamp(sendAt)}
            </Text>
          )}
        </div>
      </div>
      {!hideBadgeAndButton &&
        (canSuppress ? (
          suppressedAt ? (
            <NakedButton
              disabled={isNotChangeable(sendAt) || !locationId || !messageId}
              onClick={() => locationId && messageId && unsuppressMessage({ locationId, messageId })}
              css={{ cursor: isNotChangeable(sendAt) || !locationId || !messageId ? 'default' : 'pointer' }}
              trackingId={trackingPrefix + '-suppression-card-resume-button'}
            >
              <Text color={isNotChangeable(sendAt) ? 'disabled' : 'primary'} weight='bold'>
                {t('Resume')}
              </Text>
            </NakedButton>
          ) : (
            <SecondaryButton
              size='tiny'
              disabled={isNotChangeable(sendAt) || !locationId || !messageId || !ruleId || !userId}
              onClick={() =>
                locationId &&
                messageId &&
                ruleId &&
                userId &&
                suppressMessage({ locationId, messageId, ruleId, userId })
              }
              trackingId={trackingPrefix + '-suppression-card-skip-button'}
            >
              {t('Skip')}
            </SecondaryButton>
          )
        ) : (
          <>
            <Chip
              css={{ backgroundColor, maxWidth: theme.spacing(12), color }}
              hideTooltip
              {...triggerProps}
              trackingId={trackingPrefix + '-suppression-card-' + kebabCase(displayedCurrentStatus) + '-status'}
            >
              {t('{{status}}', { status: displayedCurrentStatus })}
            </Chip>
            <Tooltip {...tooltipProps} css={{ maxWidth: 'max(15%, 200px)' }}>
              <Text size='small' color='white'>
                {statusDetail}
              </Text>
            </Tooltip>
          </>
        ))}
    </div>
  );
};
