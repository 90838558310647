import { ElementType } from 'react';
import { LocationFilterButton, LocationFilterButtonProps } from '@frontend/location-filter';
import { theme } from '@frontend/theme';
import { PolymorphicComponentPropWithoutRef, SearchField, useFormField } from '@frontend/design-system';

export type PersonSelectionListFiltersProps = {
  locationFilterButtonProps: LocationFilterButtonProps;
  searchFieldProps: ReturnType<typeof useFormField<'text'>>;
  hideLocationFilter: boolean;
};

type PolymorphicProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<C, PersonSelectionListFiltersProps>;

export const PersonSelectionListFilters = <C extends ElementType = 'div'>({
  as,
  locationFilterButtonProps,
  searchFieldProps,
  hideLocationFilter,
  ...rest
}: PolymorphicProps<C>) => {
  const Component = as || 'div';

  return (
    <Component
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: hideLocationFilter ? 'end' : 'space-between',
        width: '100%',
        fontWeight: theme.font.weight.regular,
      }}
      {...rest}
    >
      {!hideLocationFilter && <LocationFilterButton {...locationFilterButtonProps} />}
      <SearchField name='person-search' {...searchFieldProps} autoFocus />
    </Component>
  );
};
