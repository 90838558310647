import { NotificationHistoryQueries } from '@frontend/api';
import {
  getAuth,
  signInWithCustomToken,
  Auth,
  // connectAuthEmulator
} from 'firebase/auth';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  getFirestore,
  // connectFirestoreEmulator
} from 'firebase/firestore';
import appConfig from '@frontend/env';
import { useRef } from 'react';
import { useLocalizedQuery } from '@frontend/location-helpers';

const firebaseConfig = {
  apiKey: appConfig.FIREBASE_API_KEY,
  authDomain: appConfig.FIREBASE_AUTH_DOMAIN,
  projectId: appConfig.FIREBASE_PROJECT_ID,
  storageBucket: appConfig.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: appConfig.FIREBASE_MESSAGING_SENDER_ID,
  appId: appConfig.FIREBASE_APP_ID,
};

const useFirestoreApp = () => {
  // using a ref to avoid re-initializing the app multiple times
  const app = useRef(initializeApp(firebaseConfig));

  return app.current;
};

/**
 * This function serves as a wrapper for getAuth to ensure proper error handling
 * @param app
 * @returns auth
 */
const getFirestoreAuth = (app: FirebaseApp) => {
  let auth: Auth;
  try {
    auth = getAuth(app);
  } catch (e) {
    console.error('Could not get Firestore auth', e);
    return null;
  }
  // add this back when tests are ready
  // if (location.hostname === 'localhost') {
  //   connectAuthEmulator(auth, `http://127.0.0.1:9099`);
  // }
  return auth;
};

/**
 * @returns firestore db instance if sign-in's were successful, otherwise null
 */
export const useFirestoreDbQuery = () => {
  const app = useFirestoreApp();
  const db = getFirestore(app);
  // add this back when tests are ready
  // if (location.hostname === 'localhost') {
  //   // @ts-expect-error
  //   if (!db._settingsFrozen) {
  //     connectFirestoreEmulator(db, '127.0.0.1', 8080);
  //   }
  // }

  const { customSignIn } = useFirestoreAuth();
  const firestoreTokenQuery = NotificationHistoryQueries.useFirestoreTokenQuery();
  const firestoreSignInQuery = useLocalizedQuery({
    queryKey: ['firestore-sign-in'],
    queryFn: async () => {
      if (!firestoreTokenQuery?.data?.token) return;
      const user = await customSignIn(firestoreTokenQuery?.data?.token);
      return user;
    },
    onError: (error) => {
      console.error('Failed to complete firestore custom sign in.', error);
    },
    // if we don't get a token, do not attempt to sign in to firestore
    enabled: !!firestoreTokenQuery?.data?.token,
  });

  if (firestoreTokenQuery.isError) {
    return { queryData: firestoreTokenQuery, db: null };
  }

  // if we got the token, but the sign in was not successful, do not return the db
  return firestoreSignInQuery.isSuccess
    ? { queryData: firestoreSignInQuery, db }
    : { queryData: firestoreSignInQuery, db: null };
};

const useFirestoreAuth = () => {
  const app = useFirestoreApp();

  const customSignIn = async (token: string) => {
    const auth = getFirestoreAuth(app);

    if (!auth) {
      throw new Error('No auth, unable to initialize firestore');
    }

    return signInWithCustomToken(auth, token)
      .then((user) => {
        return user;
      })
      .catch(() => {
        throw new Error('Could not validate firestore token');
      });
  };

  return { customSignIn };
};
