import { MessagesHooks } from '@frontend/api-messaging';
import { getUser } from '@frontend/auth-helpers';
import { PickRequired } from '@frontend/types';
import { useWebsocketEventSubscription } from '@frontend/websocket';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';

export const useScheduledSmsWSSubscription = () => {
  const user = getUser();
  const { remove, upsert, shallowUpdate } = MessagesHooks.useUpdateScheduledMessages();
  const invalidateInboxList = MessagesHooks.useInvalidateInboxList();

  return useWebsocketEventSubscription('ScheduledSMSNotification', (payload) => {
    const {
      params: { scheduledSms: newScheduledSms },
    } = payload;
    if (!newScheduledSms) return;

    if (newScheduledSms.status === OutboundMessageStatus.DELETED) {
      // If the current user deleted the message, do nothing (the mutation will update the query)
      if (user?.userID && newScheduledSms.deletedBy === user.userID) return;

      remove({
        threadId: newScheduledSms.threadId,
        groupId: newScheduledSms.locationId,
        smsId: newScheduledSms.id,
      });
    } else {
      const isNewSms = newScheduledSms.status === OutboundMessageStatus.SCHEDULED;
      // If the current user created the message, do nothing (the mutation will update the query)
      if (isNewSms && user?.userID && newScheduledSms.scheduledBy === user.userID) return;

      if (isNewSms) {
        upsert(newScheduledSms);
      } else {
        const smsNewVals: PickRequired<ScheduledSms, 'id' | 'threadId' | 'locationId'> = {
          ...Object.fromEntries(Object.entries(newScheduledSms).filter(([_, value]) => !!value)),
          id: newScheduledSms.id,
          threadId: newScheduledSms.threadId,
          locationId: newScheduledSms.locationId,
        };
        shallowUpdate(smsNewVals);
      }
      invalidateInboxList();
    }
  });
};
