import { useEffect } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { ScheduleRequestsApi, ScheduleApiQueryKeys, ScheduleDefaults } from '@frontend/api-schedule';
import { ServiceProvidersTypes } from '@frontend/api-service-providers';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { useQuery } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { Avatar, Heading, NakedButton, Text, Chip } from '@frontend/design-system';

const { getNewApptOpenings } = ScheduleRequestsApi;

interface Props {
  selectedProvider: ServiceProvidersTypes.ServiceProvider | string;
  day: string;
  locationId: string;
  weekInView: string;
  apptType: AppointmentTypesTypes.AppointmentType;
  selected: string[];
  setSelected: (dateTime: string[]) => void;
  reqNumApptTimes: number;
  setAvailableOpenings: (numOfOpenings: number) => void;
}

interface DateGroup {
  [key: string]: string[];
}

export const ProviderAvailability = ({
  selectedProvider,
  day,
  locationId,
  weekInView,
  apptType,
  selected,
  setSelected,
  reqNumApptTimes,
  setAvailableOpenings,
}: Props) => {
  const { t } = useTranslation('schedule');
  const providerId = typeof selectedProvider === 'string' ? '' : selectedProvider.id;

  useEffect(() => {
    setTimeout(() => {
      const element = document?.getElementsByClassName(`request-day-${day}`)[0];
      element?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, []);

  const { data: apptOpenings } = useQuery({
    queryKey: ScheduleApiQueryKeys.scheduleBookingQueryKeys.getAppointmentOpeningsKeys({
      providerId,
      apptTypeId: apptType.id,
      weekInView,
      locationId,
    }),
    queryFn: () => getNewApptOpenings(apptType.id, weekInView, locationId, providerId || 'any', 'isoWeek'),
    enabled: !!locationId && !!weekInView && !!apptType.id,
    cacheTime: ScheduleDefaults.CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS, // 3 mins
    staleTime: ScheduleDefaults.CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS, // 3 mins
    retry: 1,
  });

  const sortDays = () => {
    const sortedDates = apptOpenings?.reduce((dateGroups: DateGroup, opening) => {
      const date = formatDate(opening.startTime, 'YYYY-MM-DD');
      const time = opening.startTime;
      if (!dateGroups[date]) {
        dateGroups[date] = [];
      }
      dateGroups[date].push(time);
      return dateGroups;
    }, {});

    useEffect(() => {
      if (apptOpenings && apptOpenings?.length < reqNumApptTimes) {
        setAvailableOpenings(apptOpenings?.length);
      }
    }, [apptOpenings, reqNumApptTimes]);

    const selectDateTimes = (time: string) => {
      let newSelected = [...selected];
      if (selected?.includes(time)) {
        newSelected = selected.filter((t) => t !== time);
      } else {
        if (newSelected.length === reqNumApptTimes) {
          newSelected.shift();
        } else if (newSelected.length <= reqNumApptTimes) {
          newSelected = newSelected.slice(0, reqNumApptTimes - 1);
        }
        newSelected.push(time);
      }
      setSelected(newSelected);
    };

    return (
      sortedDates &&
      Object.entries(sortedDates)?.map(([date, times], index) => {
        const displayDate = formatDate(date, 'ddd, MMM D');
        return (
          !!times.length && (
            <div
              key={`${date}-${index}`}
              css={styles.dateGroup}
              className={`request-day-${displayDate.substring(0, 3).toLocaleLowerCase()}`}
            >
              <Text weight='bold' css={styles.date}>
                {displayDate}
              </Text>
              {times.map((time: string) => {
                const isAfterLeadTime = dayjs(time).isAfter(dayjs().format());
                return (
                  isAfterLeadTime && (
                    <NakedButton
                      key={time}
                      onClick={() => selectDateTimes(time)}
                      className={selected?.includes(time) ? 'timeButton selected' : 'timeButton'}
                    >
                      {formatDate(time, 'h:mm a')}
                    </NakedButton>
                  )
                );
              })}
            </div>
          )
        );
      })
    );
  };

  return (
    <div css={styles.availability}>
      {typeof selectedProvider === 'string' ? (
        <section className='provider-info'>
          <Avatar src={''} size='xl' />
          <Text weight='bold'>{t('Choose my provider for me')}</Text>
          <Chip css={styles.availabilityChip}>{t('Most Availability')}</Chip>
          <Text>
            {t(
              "Pick a time slot that works best with your schedule. We've got plenty of options available, so no need to stress. Once you've got your time sorted, we'll take care of picking the best provider for you."
            )}
          </Text>
        </section>
      ) : (
        <section className='provider-info'>
          <Avatar
            name={`${selectedProvider.firstName} ${selectedProvider.lastName}`}
            src={selectedProvider.publicDisplayImage}
            size='xl'
          />
          <Text weight='bold'>
            {selectedProvider.publicDisplayName || `${selectedProvider.firstName} ${selectedProvider.lastName}`}
          </Text>
        </section>
      )}
      <section className='time-selection'>
        <Heading as='h3' css={styles.heading}>
          {t('Availability')}
        </Heading>
        {reqNumApptTimes === 1 || apptOpenings?.length === 1 ? (
          <Text>
            {t(
              `Choose {{numTimeSlots}} time slot that fits into your schedule, and we'll reach out to you to confirm`,
              { numTimeSlots: apptOpenings?.length === 1 ? apptOpenings.length : reqNumApptTimes }
            )}
          </Text>
        ) : (
          <Text>
            {t(
              `Choose {{numTimeSlots}} time slots that fit into your schedule, and we'll reach out to you to confirm`,
              { numTimeSlots: apptOpenings?.length === 2 ? apptOpenings?.length : reqNumApptTimes }
            )}
          </Text>
        )}
        <div css={{ overflowY: 'auto', maxHeight: '350px' }}>{sortDays()}</div>
      </section>
    </div>
  );
};

const styles = {
  availability: css({
    display: 'flex',
    flexDirection: 'row',
    '@media only screen and (max-width: 650px)': {
      flexDirection: 'column',
    },
    '.provider-info, .time-selection': {
      '@media only screen and (max-width: 650px)': {
        width: '100%',
      },
    },
    '.provider-info': {
      width: '45%',
      padding: theme.spacing(0, 3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      '@media only screen and (max-width: 650px)': {
        padding: 0,
        width: '100%',
      },
    },
    '.time-selection': {
      width: '55%',
      borderLeft: `1px solid ${theme.colors.neutral20}`,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
      '@media only screen and (max-width: 650px)': {
        padding: theme.spacing(2, 1, 0, 0),
        borderTop: `1px solid ${theme.colors.neutral20}`,
        borderLeft: 'none',
        width: '100%',
      },
    },
  }),
  dateGroup: css({
    margin: theme.spacing(3, 0, 0, 0),
    '.timeButton': {
      fontSize: theme.fontSize(14),
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(0.5),
      border: `1px solid ${theme.colors.neutral50}`,
      borderRadius: theme.borderRadius.small,
    },
    '.timeButton:hover': {
      backgroundColor: theme.colors.neutral10,
    },
    '.selected': {
      border: `2px solid ${theme.colors.primary50}`,
      margin: `calc(${theme.spacing(0.5)} - 1px)`,
    },
  }),
  heading: css({
    fontSize: theme.fontSize(20),
    margin: theme.spacing(0),
  }),
  date: css({
    fontSize: theme.fontSize(16),
    margin: theme.spacing(0),
  }),
  availabilityChip: css({
    maxWidth: 'unset',
  }),
};
