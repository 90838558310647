import { ElementType } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { PersonsV3 } from '@frontend/api-person';
import { PolymorphicComponentPropWithoutRef } from '@frontend/design-system';
import { PersonSelectionListItem } from './person-selection-list-item';

type PersonSelectionListProps = {
  persons: Person[];
  onSelect: (person: Person) => void;
};

type PolymorphicProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<C, PersonSelectionListProps>;

export const PersonSelectionList = <C extends ElementType = 'div'>({
  as,
  persons,
  onSelect,
  ...rest
}: PolymorphicProps<C>) => {
  const Component = as || 'div';

  return (
    <Component
      css={{
        height: '100%',
        overflowY: 'auto',
      }}
      {...rest}
    >
      {persons.map((person) => (
        <PersonSelectionListItem
          key={person.personId}
          personId={person.personId}
          personName={PersonsV3.PersonHelpers.getFullName(person)}
          onSelect={() => onSelect(person)}
        />
      ))}
    </Component>
  );
};
