import { ElementType } from 'react';
import { PaymentPlanStatus } from '@frontend/api-payment-plans';
import { Chip, ChipVariants, useTooltip } from '@frontend/design-system';
import { prettify } from '../../utils';

type PaymentPlanStatusChipProps = {
  paymentPlanStatus?: PaymentPlanStatus;
  statusReason?: string;
  as?: ElementType;
};

const mapStatus = (status: PaymentPlanStatus): ChipVariants => {
  switch (status) {
    case PaymentPlanStatus.Completed:
      return 'primary';
    case PaymentPlanStatus.Active:
      return 'success';
    case PaymentPlanStatus.Paused:
      return 'warn';
    case PaymentPlanStatus.Failed:
      return 'critical';
    case PaymentPlanStatus.Canceled:
      return 'disabled';
    default:
      return 'disabled';
  }
};

export const PaymentPlanStatusChip = ({ paymentPlanStatus, statusReason, as, ...rest }: PaymentPlanStatusChipProps) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'top' });

  if (!(paymentPlanStatus && Object.values(PaymentPlanStatus).includes(paymentPlanStatus))) return null;
  return (
    <>
      <div {...(statusReason && paymentPlanStatus === PaymentPlanStatus.Failed ? triggerProps : {})}>
        <Chip variant={mapStatus(paymentPlanStatus)} {...rest}>
          {prettify(paymentPlanStatus, { split: '_' })}
        </Chip>
      </div>
      <Tooltip {...tooltipProps}>{statusReason}</Tooltip>
    </>
  );
};
