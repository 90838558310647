import { UseQueryOptions, useQuery } from 'react-query';
import { GetIO, ListDraftsCountIO, ListDraftsIO } from './types';
import { SchemaSMSDraftService } from './service';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

export const queryKeys = {
  baseKey: ['sms-draft'],
  getDraft: (req: GetIO['input']) => [...queryKeys.baseKey, 'get', { ...req }],
  listDrafts: (req: ListDraftsIO['input']) => [...queryKeys.baseKey, 'listDrafts', { ...req }],
  listDraftsCount: (req: ListDraftsCountIO['input']) => [...queryKeys.baseKey, 'listDraftsCount', { ...req }],
};

export type UseGetDraftQueryOptions<E = unknown, T = GetIO['output']> = UseQueryOptions<GetIO['output'], E, T>;
export const useGetDraftQuery = <E = unknown, T = GetIO['output']>(
  req: GetIO['input'],
  options?: UseGetDraftQueryOptions<E, T>
) =>
  useQuery({
    queryKey: queryKeys.getDraft(req),
    queryFn: () => SchemaSMSDraftService.Get(req),
    ...defaultOptions,
    ...options,
  });

export type UseListDraftsQueryOptions<E = unknown, T = ListDraftsIO['output']> = UseQueryOptions<
  ListDraftsIO['output'],
  E,
  T
>;
export const useListDraftsQuery = <E = unknown, T = ListDraftsIO['output']>(
  req: ListDraftsIO['input'],
  options?: UseListDraftsQueryOptions<E, T>
) =>
  useQuery({
    queryKey: queryKeys.listDrafts(req),
    queryFn: () => SchemaSMSDraftService.ListDrafts(req),
    ...defaultOptions,
    ...options,
  });

export type UseListDraftsCountsQueryOptions<E = unknown, T = ListDraftsCountIO['output']> = UseQueryOptions<
  ListDraftsCountIO['output'],
  E,
  T
>;
export const useListDraftsCountQuery = <E = unknown, T = ListDraftsCountIO['output']>(
  req: ListDraftsCountIO['input'],
  options?: UseListDraftsCountsQueryOptions<E, T>
) => {
  return useQuery({
    queryKey: queryKeys.listDraftsCount(req),
    queryFn: () => SchemaSMSDraftService.ListDraftsCount(req),
    ...defaultOptions,
    ...options,
    enabled: !!req?.locationId && (options?.enabled ?? true),
  });
};
