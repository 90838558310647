import { css } from '@emotion/react';
import { Direction } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { PersonHelpers, PersonsV3 } from '@frontend/api-person';
import { UsersQueries } from '@frontend/api-users';
import { useTranslation } from '@frontend/i18n';
import { Photos } from '@frontend/photos';
import { useAppScopeStore } from '@frontend/scope';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { theme } from '@frontend/theme';
import { Avatar, ChatItem } from '@frontend/design-system';
import { ShareInMessageVirtuosoContext, ThreadItem } from '../../types';
import { TimeDividerRow } from './time-divider-row';

type VirtuosoMessageBubbleProps = {
  message: ThreadItem;
  context: ShareInMessageVirtuosoContext;
};

export const VirtuosoMessageBubble = ({ message, context }: VirtuosoMessageBubbleProps) => {
  const { t } = useTranslation('integrated-messaging');
  const { accessibleLocationIds } = useAppScopeStore();
  const commonStyles = css({
    padding: theme.spacing(0, 2),
  });
  const users = UsersQueries.useGetMultiLocationsUsers({
    locationIds: accessibleLocationIds,
    params: { showChildren: true },
  }).flatMap(({ data }) => data || []);
  const timestampFormatter = useTimestampFormatter();

  if (typeof message === 'string') {
    return <TimeDividerRow timestamp={new Date(message)} />;
  }

  if ('scheduledAt' in message) {
    const sender = users.find((user) => user.UserID === message.scheduledBy);
    return (
      <ChatItem
        direction='outbound'
        avatar={
          <Avatar
            name={
              sender ? PersonHelpers.getFullName({ FirstName: sender.FirstName, LastName: sender.LastName }) : undefined
            }
            size='small'
            isUser
          />
        }
        timestamp={timestampFormatter(message.sendAt)}
        css={commonStyles}
        maxWidth='80%'
      >
        <ChatItem.Bubble text={message.body} />
      </ChatItem>
    );
  }

  const direction = message.direction === Direction.DIRECTION_INBOUND ? 'inbound' : 'outbound';
  const userSender = users.find((user) => user.UserID === message.createdBy);
  const isAutogenerated = !!message.autogeneratedBy;
  const userAvatarTitle = message.autogeneratedBy
    ? t('Weave')
    : userSender
    ? PersonHelpers.getFullName({ FirstName: userSender.FirstName, LastName: userSender.LastName })
    : undefined;
  const avatar =
    direction === 'inbound' ? (
      <Photos.ContactProfilePhoto
        disableClick
        personId={context.person?.personId ?? message.personId}
        locationId={message.locationId}
        name={context.person ? PersonsV3.PersonHelpers.getFullName(context.person) : undefined}
        size='small'
      />
    ) : (
      <Avatar
        title={userAvatarTitle ?? t('Unknown User')}
        name={isAutogenerated ? undefined : userAvatarTitle}
        size='small'
        isWeave={isAutogenerated}
        isUser
      />
    );
  const timestamp = new Date(message.createdAt);
  const deleter = message.deletedAt ? users.find((user) => user.UserID === message.deletedBy) : undefined;
  const deletedBodyText = message.deletedAt
    ? t('This message was deleted on {{date}} by {{name}}', {
        date: new Date(message.deletedAt).toLocaleDateString(),
        name: deleter
          ? PersonHelpers.getFullName({ FirstName: deleter.FirstName, LastName: deleter.LastName })
          : t('Unknown User'),
      })
    : undefined;

  return (
    <ChatItem direction={direction} avatar={avatar} timestamp={timestamp} css={commonStyles} maxWidth='80%'>
      {!message.deletedAt &&
        message.media.map((mediaItem) => {
          const mediaQuery = context.mediaQueries[mediaItem.mediaId];
          const isLoading = !!mediaQuery?.isLoading || !mediaQuery?.data?.src;
          return (
            <ChatItem.Image
              key={mediaItem.mediaId}
              src={mediaQuery?.data?.src}
              loadingText={t('Loading image...')}
              maxWidth={isLoading ? '320px' : 'min(60%, 320px)'}
              maxHeight={isLoading ? '320px' : 'min(40%, 480px)'}
            />
          );
        })}
      {(!!deletedBodyText || !!message.body) && (
        <ChatItem.Bubble text={deletedBodyText || message.body} textColor={deletedBodyText ? 'light' : undefined} />
      )}
    </ChatItem>
  );
};
