import { css } from '@emotion/react';
import {
  ContentLoader,
  IconButton,
  PrimaryButton,
  SecondaryButton,
  Text,
  useAlert,
  useModalControl,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useLineKeyContext, useLineKeyState } from './store/line-key-provider';
import { useCallback } from 'react';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useMatch } from '@tanstack/react-location';
import { LineKeysApi } from '@frontend/api-line-keys';
import { useMutation } from '@frontend/react-query-helpers';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { useQueryClient } from 'react-query';
import { UseExistingLayoutModal } from './modals/use-existing-layout.modal';
import { CancelEditModal } from './modals/cancel-edit.modal';
import { LineKey } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';

export const LineKeyFooter = () => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const {
    params: { id: deviceId },
  } = useMatch();
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const locationId = locationIds?.[0] ?? '';
  const { finalLineKeys, setFinalLineKeys } = useLineKeyState(['finalLineKeys', 'setFinalLineKeys']);
  const { device } = useLineKeyContext();
  const { navigate } = useSettingsNavigate();
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const { modalProps: cancelModalProps, triggerProps: cancelTriggerProps } = useModalControl();
  const { modalProps: copyLayoutModalProps, triggerProps: copyLayoutTriggerProps } = useModalControl();

  const { mutate: onSave, isLoading } = useMutation(
    (lineKeys: LineKey[]) => LineKeysApi.ReplaceLineKeys({ deviceId, lineKeys, locationId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['device', 'line-keys', deviceId, locationId]);
        queryClient.invalidateQueries([locationId, 'line-keys', 'devices']);
        setFinalLineKeys(undefined);
        navigate({ to: `/phone/line-keys` });
        alerts.success(t('Successfully saved Line Keys layout'));
      },
      onError: () => {
        throw new Error('Line Keys did not Save');
      },
    }
  );

  const handleSave = useCallback(() => {
    onSave(Object.values(finalLineKeys ?? {}));
  }, [finalLineKeys]);

  return (
    <>
      <ContentLoader show={isLoading} />
      <footer css={footerStyles}>
        <PrimaryButton onClick={handleSave}>{t('Save')}</PrimaryButton>
        <SecondaryButton onClick={cancelTriggerProps.onClick}>{t('Cancel')}</SecondaryButton>
        <IconButton
          label={t('Use an Existing Line Key Layout')}
          trackingId='duplicate-from-use-existing-layout'
          onClick={copyLayoutTriggerProps.onClick}
        >
          <Text color='primary' weight='bold'>
            {t('Use an Existing Line Key Layout')}
          </Text>
        </IconButton>
      </footer>
      <CancelEditModal {...cancelModalProps} />
      <UseExistingLayoutModal device={device} {...copyLayoutModalProps} />
    </>
  );
};

const footerStyles = css`
  display: flex;
  gap: ${theme.spacing(2)};
  align-items: center;
  padding: ${theme.spacing(3)};
  border-top: 1px solid ${theme.colors.neutral20};
  background: ${theme.colors.white};
  width: 100%;
  height: ${theme.spacing(10)};

  button {
    width: fit-content;
  }
`;
