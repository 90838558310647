import { memo } from 'react';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { usePracticeAnalyticsStore } from '../../hooks';
import { formatters } from '../../utils';
import { InlineStats } from '../inline-stats';

type Props = {
  data?: PracticeAnalyticsTypes.TreatmentPlanTotals;
  isLoading?: boolean;
};

export const PractitionerInlineStats = memo(({ data, isLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const { isDemoAccount } = usePracticeAnalyticsStore();

  return (
    <InlineStats
      data={[
        {
          label: t('Patients Diagnosed'),
          value: formatters.percent.format(data?.diagnosedPercent),
        },
        {
          label: t('Patients Accepted'),
          value: formatters.percent.format(data?.acceptedPercent),
        },
        {
          label: t('Unscheduled Treatment'),
          value: formatters.currency.format(data?.unscheduledTreatment),
        },
      ]}
      isDemoAccount={isDemoAccount}
      isLoading={isLoading}
    />
  );
});

PractitionerInlineStats.displayName = 'PractitionerInlineStats';
