import { FC, useRef } from 'react';
import { Channel } from 'stream-chat';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Text, IconButton, useAlert, ConfirmationModal, useModalControl, Modal } from '@frontend/design-system';
import { MIN_MODAL_WIDTH } from '../../../constants';
import { useTeamChatStore } from '../../../providers';
import { StreamUser } from '../../../types';
import { AddButton, ChatAvatar } from '../../common';
import { AddMembers } from './add-members';
import { containerStyle, memberContainerStyle, buttonStyle } from './member-section.styles';

interface MembersProps {
  channel?: Channel;
  members: StreamUser[];
}

export const Members: FC<MembersProps> = ({ channel, members }) => {
  const { t } = useTranslation('team-chat');
  const { currentUser } = useTeamChatStore(['currentUser']);
  const { error } = useAlert();
  const confirmationModalProps = useModalControl();
  const addMembersModalProps = useModalControl();
  const userRef = useRef<StreamUser | null>(null);

  const removeMember = async (user: StreamUser) => {
    try {
      channel &&
        (await channel.removeMembers([user.userID], {
          text: `${currentUser?.firstName} ${currentUser?.lastName} removed ${user.firstName} ${user.lastName}`,
        }));
    } catch (err) {
      error(t('Failed to remove member'));
    }
  };

  const onClickDelete = (member: StreamUser) => {
    userRef.current = member;
    confirmationModalProps.openModal();
  };

  const onConfirmDelete = () => {
    userRef.current && removeMember(userRef.current);
  };

  return (
    <section css={containerStyle}>
      <AddButton
        size={32}
        textSize='large'
        title='Add Members'
        onClick={addMembersModalProps.openModal}
        className='members-children'
        style={buttonStyle}
      />
      {members.map((member) => (
        <div
          css={memberContainerStyle}
          className='members-children'
          key={`channel-settings-member-list-${member.userID}`}
        >
          <ChatAvatar size='small' members={[member]} />
          <Text weight='regular' size='large'>
            {member.firstName} {member.lastName}
          </Text>
          <IconButton label={'Remove User'} onClick={() => onClickDelete(member)} css={{ marginLeft: 'auto' }}>
            <Icon name='trash' />
          </IconButton>
        </div>
      ))}
      <ConfirmationModal
        title={t('Remove User')}
        message={t('Are you sure you want to remove this user?')}
        onCancel={confirmationModalProps.closeModal}
        onConfirm={onConfirmDelete}
        destructive
        {...confirmationModalProps.modalProps}
      />
      <Modal {...addMembersModalProps.modalProps} minWidth={MIN_MODAL_WIDTH}>
        <Modal.Header onClose={addMembersModalProps.closeModal}>{t('Add Members')}</Modal.Header>
        <Modal.Body>
          <AddMembers currentUser={currentUser} channel={channel} members={members} />
        </Modal.Body>
      </Modal>
    </section>
  );
};
