import { FC, useState } from 'react';
import { Channel } from 'stream-chat';
import { useTranslation } from '@frontend/i18n';
import { useAlert, ContentLoader } from '@frontend/design-system';
import { InfoWithEditor, LeaveChannel } from '../../common';
import { channelSettingsContainerStyle } from '../../common-styles';

interface AboutProps {
  isDm: boolean;
  createdBy: string;
  createdAt: string;
  topic: string;
  description: string;
  channel?: Channel;
  channelName: string;
}

export const About: FC<AboutProps> = ({ isDm, createdBy, createdAt, topic, description, channel, channelName }) => {
  const { t } = useTranslation('team-chat');
  const { error } = useAlert();
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const updateTopic = async (topic: string) => {
    try {
      setShowLoader(true);
      channel && (await channel.updatePartial({ set: { topic } }));
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      error(t('Failed to update topics'));
    }
  };

  const updateDescription = async (description: string) => {
    try {
      setShowLoader(true);
      channel && (await channel.updatePartial({ set: { description } }));
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      error(t('Failed to update description'));
    }
  };

  return (
    <section css={channelSettingsContainerStyle}>
      <InfoWithEditor
        title={t('Topic:')}
        value={topic}
        placeholder={t('Add topic')}
        canEdit
        onClickSave={updateTopic}
        className='channel-settings-item'
        comment={t(
          'Let people know what {{channelName}} is focused on right now (ex. a project milestone). Topics are always visible in the header.',
          { channelName }
        )}
      />
      <InfoWithEditor
        title={t('Description:')}
        value={description}
        placeholder={t('Add description')}
        canEdit
        onClickSave={updateDescription}
        className='channel-settings-item'
        comment={t(
          'Let people know what {{channelName}} is focused on right now (ex. a project milestone). Descriptions are always visible in the header.',
          { channelName }
        )}
      />
      {/* TODO: commenting it now, will update after discussion with Product team */}
      {/* <InfoWithEditor title='Managed By:' value='' canEdit className='channel-settings-item' /> */}
      {!isDm && (
        <InfoWithEditor
          title={t('Created by:')}
          value={t('{{createdBy}} on {{createdAt}}', { createdBy, createdAt })}
          className='channel-settings-item'
        />
      )}
      {channel && !isDm && <LeaveChannel channel={channel} />}
      <ContentLoader show={showLoader} />
    </section>
  );
};
