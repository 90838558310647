import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const summaryHeaderStyle = css`
  padding-top: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(0.5)};
  border-bottom: 1px solid ${theme.colors.neutral20};

  h2 {
    padding-left: ${theme.spacing(2)};
  }
`;

export const titleStyle = css`
  margin-bottom: ${theme.spacing(2)};
  margin-left: ${theme.spacing(2)};
`;

export const summaryFilterPanelStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)};
  padding-left: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(1)};

  .sort-icon {
    transform: rotate(90deg);
  }
`;

export const radioChipsWrapperStyle = css`
  display: flex;
  overflow-x: scroll;
  padding-left: ${theme.spacing(1)};

  @media (max-width: ${breakpoints.xsmall.max}px) {
    padding-left: 0;
  }
`;

export const batchSelectStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};

  .select-all {
    margin-left: ${theme.spacing(1)};
  }
`;
