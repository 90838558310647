import { IconButton, IconProps, MoreIcon, PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';
import { onlyText } from 'react-children-utilities';

export type Action = {
  hide?: boolean;
  disabled?: boolean;
  Icon: React.FC<React.PropsWithChildren<IconProps>>;
  label: React.ReactNode;
  hoverLabel?: string;
  trackingId?: string;
  onClick?: () => void;
};

export type CustomActionCellProps = {
  actions: Action[];
  tableTrackingId: string;
};

export const CustomActionCell = ({ actions, tableTrackingId }: CustomActionCellProps) => {
  const { getTriggerProps, getMenuProps, getItemProps, close } = usePopoverMenu({
    interactionOptions: {
      listNavigation: {
        openOnArrowKeyDown: false,
      },
    },
    placement: 'left',
  });

  const triggerProps = getTriggerProps();
  const menuProps = getMenuProps();

  const hiddenActions = () => {
    if (actions) {
      return actions?.reduce((acc, { hide }) => {
        if (hide) {
          return acc + 1;
        }
        return acc;
      }, 0);
    }
    return 0;
  };

  const hasAllActionsHidden = hiddenActions() === actions?.length;

  const showSingleAction = actions
    ? (actions.length === 1 && !hasAllActionsHidden) || actions.length - hiddenActions() === 1
    : false;

  const renderActions = (
    getItemProps: ReturnType<typeof usePopoverMenu>['getItemProps'],
    showSingleButton?: boolean
  ) => {
    return actions?.map(({ onClick, Icon, hide, label, disabled, trackingId, ...dynamicProps }, id) => {
      const itemProps = getItemProps?.({
        onClick: () => {
          onClick?.();
          close();
        },
        index: id,
      });

      return !showSingleButton
        ? !hide && (
            <PopoverMenuItem
              {...itemProps}
              {...dynamicProps}
              css={disabled && { pointerEvents: 'auto' }}
              key={id}
              Icon={Icon}
              label={onlyText(label as React.ReactNode)}
              disabled={disabled}
              trackingId={trackingId}
            >
              {label ?? ''}
            </PopoverMenuItem>
          )
        : !hide && (
            <IconButton
              {...dynamicProps}
              key={id}
              onClick={() => {
                onClick?.();
              }}
              disabled={disabled}
              label={onlyText(label as React.ReactNode)}
              trackingId={trackingId}
              showLabelOnHover
            >
              <Icon color='light' />
            </IconButton>
          );
    });
  };

  return !showSingleAction ? (
    <IconButton
      color='light'
      size='small'
      label={'Action menu'}
      disabled={hasAllActionsHidden}
      trackingId={`${tableTrackingId}-${'icn-tablerowactionmenu'}`}
      {...triggerProps}
    >
      <MoreIcon color='light' size={16} />
      <PopoverMenu {...menuProps} title={''} css={{ maxWidth: 320 }} returnFocus={false}>
        {renderActions(getItemProps)}
      </PopoverMenu>
    </IconButton>
  ) : (
    <>{renderActions(getItemProps, true)}</>
  );
};
