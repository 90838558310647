import { useCallback } from 'react';
import { NavLinkHrefEnum, ParentSections } from '../types';

type ScrollToNavItem = {
  parentSection: ParentSections;
  href: NavLinkHrefEnum;
};

export const useScrollToNavItem = () => {
  const scrollToNavItem = useCallback(({ parentSection, href }: ScrollToNavItem) => {
    if (parentSection) {
      const parentNavElement = document.querySelector(
        `li[data-tour-guide='${parentSection.toLowerCase()}']`
      ) as HTMLElement;

      if (parentNavElement) {
        parentNavElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        const parentNavButtonElement = parentNavElement?.childNodes?.[0] as HTMLElement;
        if (parentNavButtonElement) parentNavButtonElement?.click();
      }
    }

    const element = document.querySelector(`a[href='${href}']`);
    if (element) {
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return { scrollToNavItem };
};
