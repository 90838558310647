import { css } from '@emotion/react';
import { useHistoryControl } from '@frontend/history';
import { useTranslation } from '@frontend/i18n';
import { TitleBarButton } from '@frontend/shell-theme';
import { useShell } from '@frontend/shell-utils';
import { theme } from '@frontend/theme';

export const HistoryControl = () => {
  const shell = useShell();
  const { back, forward, canGoBack, canGoForward } = useHistoryControl();
  const { t } = useTranslation('base');

  const handleNavigateBack = () => {
    back();
  };

  const handleNavigateForward = () => {
    forward();
  };

  return (
    <div
      css={[
        css`
          display: flex;
          align-items: center;
          margin-left: ${shell.isMac ? '100px' : '20px'};
          gap: ${theme.spacing(0.5)};
          -webkit-app-region: no-drag;
        `,
        !shell.isWindows &&
          css`
            margin-right: auto;
          `,
      ]}
    >
      <TitleBarButton
        onClick={handleNavigateBack}
        hasBorder
        disabled={!canGoBack}
        label={t('Go Back')}
        icon='caret-left'
      />
      <TitleBarButton
        onClick={handleNavigateForward}
        hasBorder
        disabled={!canGoForward}
        label={t('Go Forward')}
        icon='caret-right'
      />
    </div>
  );
};
