import { useState, useEffect } from 'react';

// TODO refactor with deepClone
export const useUndoRedo = <T>() => {
  const [history, setHistory] = useState<T[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const addToHistory = (item: T) => {
    if (item !== currentItem) {
      setCurrentIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        setHistory((prevItem) => [...prevItem.slice(0, newIndex), item]);
        return newIndex;
      });
    }
  };

  const undo = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const redo = () => {
    if (currentIndex < history.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const removeCurrentItem = () => {
    if (currentIndex >= 0 && currentIndex < history.length) {
      setHistory((prev) => [...prev.slice(0, currentIndex), ...prev.slice(currentIndex + 1)]);
    }
  };

  const clearHistory = () => {
    setHistory([]);
    setCurrentIndex(-1);
  };

  useEffect(() => {
    return () => {
      clearHistory();
    };
  }, []);

  const currentItem = currentIndex >= 0 ? history[currentIndex] : undefined;

  return {
    addToHistory,
    undo,
    redo,
    currentItem,
    removeCurrentItem,
    clearHistory,
    canUndo: currentIndex > 0,
    canRedo: currentIndex < history.length - 1,
  };
};
