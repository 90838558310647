import { FailureGraphic } from '@frontend/assets';
import { Modal, ModalControlModalProps, Text, TextLink } from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FC } from 'react';
import { SelfUpgradeModalTrackingIds } from '../../../constants/tracking-ids';

interface SelfUpgradeOverdueBalanceProps extends ModalControlModalProps {
  featureName: string;
}

const WEAVE_HELP_BILLING_LINK =
  'https://www.weavehelp.com/hc/en-us/articles/4409011007643-Update-Your-Weave-Billing-Information-';
const ACCOUNT_BILLING_LINK = '#settings/organization/billing-information';

export const SelfUpgradeOverdueBalance: FC<SelfUpgradeOverdueBalanceProps> = ({ featureName, ...modalProps }) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'self-upgrade-overdue-modal' });

  return (
    <Modal {...modalProps} maxWidth={600}>
      <Modal.Header
        onClose={modalProps.onClose}
        closeBtnTrackingId={SelfUpgradeModalTrackingIds.failModalCloseAction}
      />
      <Modal.Body
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(2),
          padding: theme.spacing(3, 0),
        }}
      >
        <FailureGraphic width={theme.spacing(24)} height={theme.spacing(24)} />
        <Trans t={t}>
          <Text textAlign='center'>
            You do not have valid billing information on file. To proceed with Weave {{ featureName }} sign up, please
            update your billing information{' '}
            <TextLink to={ACCOUNT_BILLING_LINK} target='_blank' trackingId={SelfUpgradeModalTrackingIds.billingLink}>
              here
            </TextLink>
            .
          </Text>

          <Text textAlign='center'>
            You can find more instructions on{' '}
            <TextLink
              rel='noreferrer'
              target='_blank'
              href={WEAVE_HELP_BILLING_LINK}
              trackingId={SelfUpgradeModalTrackingIds.weaveHelpLink}
            >
              WeaveHelp
            </TextLink>
            .
          </Text>
        </Trans>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Okay')}
        primaryTrackingId={SelfUpgradeModalTrackingIds.failModalCloseAction}
        onPrimaryClick={modalProps.onClose}
      />
    </Modal>
  );
};
