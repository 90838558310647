import { useEffect } from 'react';
import { css } from '@emotion/react';
import { Button } from '@frontend/generic-dialpad-accessories';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ContentLoader, DialPadErrorState, Heading, Text, TextLink } from '@frontend/design-system';
import { useRetryer } from '../../hooks/use-retryer';
import { SoftphoneClientContextValue, useSoftphoneClient } from '../../providers/softphone-client-provider';
import { useSoftphoneSettings } from '../../providers/softphone-settings-provider';

type Props = {
  status: Exclude<SoftphoneClientContextValue['status'], 'success'>;
  error?: Error;
};
export const ErrorPage = ({ error, status }: Props) => {
  return (
    <>
      {status === 'loading' && <SoftphoneLoading />}
      {status === 'error' && <SoftphoneRegistrationError error={error} />}
    </>
  );
};

export const SoftphoneLoading = () => {
  const { t } = useTranslation('softphone');
  return (
    <main>
      <ContentLoader
        message={t('Initializing Softphone...')}
        show={true}
        css={css`
          background: transparent;
          p {
            color: ${theme.colors.white};
          }
        `}
      />
    </main>
  );
};

export const SoftphoneRegistrationError = ({ error }: { error?: Error }) => {
  const { t } = useTranslation('softphone');
  const reconnect = useSoftphoneClient((ctx) => ctx.reconnect);
  const restart = useSoftphoneSettings((ctx) => ctx.restart);

  const { timeUntilNextRetry, lastRetryIsLoading, lastRetryResponse } = useRetryer({
    interval: 5000,
    resume: true,
    callback: reconnect,
    countdownFidelity: 250,
  });

  const secondsRemaining = ((timeUntilNextRetry % 60000) / 1000).toFixed(0);

  useEffect(() => {
    if (error) {
      console.error('Softphone Registration Error: ', error);
    }
  }, [error]);
  return (
    <main css={loadingPageStyle}>
      <div className='error-state-icon-container'>
        <DialPadErrorState />
      </div>
      <div>
        <Heading
          textAlign='center'
          color='white'
          level={3}
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          {t('We’re having trouble loading your Softphone.')}
        </Heading>
        {error && (
          <Text
            color='error'
            textAlign='center'
            css={css`
              margin-bottom: ${theme.spacing(1)};
            `}
            onClick={() => console.error(error)}
          >
            {error?.message}
          </Text>
        )}
        <Text
          textAlign='center'
          color='white'
          css={css`
            display: inline-block;
            text-indent: ${theme.spacing(2)};
          `}
        >
          <Trans>
            Please try to restart your Softphone. If the issue persists, please contact{' '}
            <TextLink onClick={() => console.log('CONNECT')} color='primary'>
              Weave Support
            </TextLink>
            .
          </Trans>
        </Text>
      </div>
      <Button.Rect onClick={restart}>{t('Restart Softphone')}</Button.Rect>
      {!lastRetryIsLoading && (
        <Text color='white'>
          {t('Retrying in')} {secondsRemaining}...
        </Text>
      )}
      {lastRetryResponse ? <Text color='white'>{lastRetryResponse}</Text> : null}
    </main>
  );
};

const loadingPageStyle = css`
  background: ${theme.colors.neutral[90]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: ${theme.spacing(75)};

  .error-state-icon-container {
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${theme.spacing(25)};
    height: ${theme.spacing(25)};
  }

  & > * {
    margin-bottom: ${theme.spacing(3)};
  }
`;
