import { css } from '@emotion/react';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { ChartHeaderAction } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, useModalControl } from '@frontend/design-system';
import { DemoChip } from '..';
import { usePracticeAnalyticsStore } from '../../hooks';
import { MonthlyTrend } from '.';

type Props = {
  defaultKey: string;
  metric: PracticeAnalyticsTypes.Metric;
  tabs?: Record<string, string>;
  title: string;
  trackingId?: string;
};

export const monthlyTrendChartModal = ({
  title,
  trackingId,
  ...rest
}: Props): {
  modalRenderer: () => JSX.Element;
  triggerProps: ChartHeaderAction;
} => {
  const { t } = useTranslation('analytics');
  const { closeModal, modalProps, openModal } = useModalControl();
  const { isDemoAccount } = usePracticeAnalyticsStore();

  return {
    modalRenderer: () => (
      <Modal {...modalProps} maxWidth={800}>
        <Modal.Header
          css={isDemoAccount ? demoHeaderStyles : null}
          onClose={closeModal}
          style={{ marginBottom: theme.spacing(1) }}
        >
          {isDemoAccount && <DemoChip />}
          {title}
        </Modal.Header>
        <Modal.Body>
          <MonthlyTrend {...rest} />
        </Modal.Body>
      </Modal>
    ),
    triggerProps: {
      iconName: 'analytics-info',
      label: t('Monthly Trend'),
      onClick: openModal,
      trackingId,
    },
  };
};

export const demoHeaderStyles = css`
  > h2 {
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
  }
`;
