import { useMemo } from 'react';
import dayjs from 'dayjs';
import { ServiceProvidersQueries } from '@frontend/api-service-providers';
import { DashboardWidget, DashboardWidgetFC } from '@frontend/grid-dashboard';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

const MAX_APPOINTMENTS = 999;

/**
 * @dashboard-widget
 *
 * id: appointment-count
 * title: Appointments
 * description: Show number of appointments scheduled for the day.
 * icon: calendar-small
 */

export const AppointmentCountWidget: DashboardWidgetFC = () => {
  const { selectedLocationIds } = useAppScopeStore();

  const { startDate, endDate } = useMemo(() => {
    const startDate = dayjs().startOf('day').toISOString();
    const endDate = dayjs().endOf('day').toISOString();
    return { startDate, endDate };
  }, []);

  const { data } = ServiceProvidersQueries.useListAppointments({
    startDate,
    endDate,
    locationIds: selectedLocationIds,
  });

  const appointmentCount = data?.appointments?.length ?? 0;

  return (
    <DashboardWidget>
      <DashboardWidget.Header />
      <DashboardWidget.Content>
        <Text weight='bold' css={{ fontSize: theme.fontSize(48), flexGrow: 1 }}>
          {appointmentCount > MAX_APPOINTMENTS ? `${MAX_APPOINTMENTS}+` : appointmentCount}
        </Text>
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

AppointmentCountWidget.config = {
  size: 'tiny-slim',
  feature: 'schedule',
};
