import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import { PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ContentLoader, IconButton, Table, useAlert } from '@frontend/design-system';
import { useAnalyticsOrgLocations, usePracticeAnalyticsStore, useShowBulkMessageButton } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { createAllSelectTableStateObject, ExportUtils, formatters } from '../../utils';
import { commonStyles } from '../../views';
import { ChipButton } from '../filter-selectors';
import { BulkMessageButton } from './bulk-message-button';
import { PatientsHelpers, isPatientScheduled, subViewTableColConfig } from './helpers';
import { usePatientsLastContacted } from './hooks';

type Props = {
  data?: PracticeAnalyticsTypes.PatientInfo[];
  isExportEnabled?: boolean;
  isLoading?: boolean;
  locationId?: string;
  tab?: string;
};

type CategorizedData = {
  all: PracticeAnalyticsTypes.PatientInfo[];
  unscheduled: PracticeAnalyticsTypes.PatientInfo[];
};

export const PractitionerAnalysisPatients = memo(
  ({ data = [], isExportEnabled, isLoading, locationId, tab }: Props) => {
    const alert = useAlert();
    const { t } = useTranslation('analytics');
    const { filters, isDemoAccount } = usePracticeAnalyticsStore();
    const { locationNames } = useAnalyticsOrgLocations({ module: 'PA' });
    const [unscheduled, setUnscheduled] = useState<boolean | undefined>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const multipleLocationsSelected = (filters.locations?.length || 0) > 1;
    const [selectedPatients, setSelectedPatients] = useState<PracticeAnalyticsTypes.PatientInfo[]>([]);
    const isBulkMessagingEnabled = useShowBulkMessageButton();

    const processedData = useMemo(() => {
      return {
        middleColumns: [
          {
            Header: t('Diagnosed'),
            headerLabel: t('Diagnosed'),
            accessor: ({ diagnosed }: PracticeAnalyticsTypes.PatientInfo) => diagnosed || '',
            cellRenderer: (date: string) => (date ? formatters.date.format(date) : '-'),
            id: 'diagnosed',
            width: 200,
          },
        ],
        patientIds: PatientsHelpers.collectPatientIds(data),
      };
    }, [data]);

    const patientsLastContacted = usePatientsLastContacted({
      patientIds: processedData.patientIds,
    });

    const filteredData = useMemo(() => {
      return data.reduce(
        (final, patient) => {
          const value = {
            ...final,
            all: [...final.all, patient],
          };

          if (!isPatientScheduled(patient)) {
            value.unscheduled.push(patient);
          }

          return value;
        },
        { all: [], unscheduled: [] } as CategorizedData
      );
    }, [data]);

    const handleExport = useCallback(async () => {
      setIsExporting(true);
      const fileName = `Practitioner Analysis Patients - ${tab}`; // Translation not needed
      const isAuthorized = await PracticeAnalyticsApi.auditPracticeAnalyticsExport(fileName);

      if (isAuthorized || isDemoAccount) {
        const csv = unparse(
          ExportUtils.processExportableData({
            columns: [
              'Patient Name',
              ...(multipleLocationsSelected ? ['Location Name'] : []),
              'Phone Number',
              'Diagnosed',
              'Last Contacted',
              '$ Value',
              'Procedure Codes',
            ],
            data,
            deriveExportValue: (params) =>
              PatientsHelpers.deriveExportValue({
                ...params,
                lastContactedDates: patientsLastContacted.lastContactedDates,
                locationNames,
              }),
            sortColumn: 'productionAmount',
          }),
          {}
        );
        saveAs(new Blob([csv], { type: 'text/csv;charset=utf-8' }), fileName + '.csv');
        setIsExporting(false);
      } else {
        alert.warning(t('You are not authorized to export data. This attempt has been logged.'));
        setIsExporting(false);
      }
    }, [data, isDemoAccount, patientsLastContacted.lastContactedDates, locationNames]);

    useEffect(() => {
      if (isBulkMessagingEnabled) {
        const data = unscheduled ? filteredData.unscheduled : filteredData.all;
        setSelectedPatients(data);
      }
    }, [filteredData, isBulkMessagingEnabled, unscheduled]);

    return (
      <div>
        <div css={styles.actionsWrapper}>
          <div className='filters'>
            <ChipButton disabled={isLoading} label={t('Unscheduled')} onChange={setUnscheduled} value={unscheduled} />
            {/* TODO ::  more to be added here after discussion with the PM and design */}
          </div>
          <BulkMessageButton selection={selectedPatients} />
          {isExportEnabled && (
            <IconButton
              disabled={isLoading || patientsLastContacted.isLoading}
              label={t('Export')}
              onClick={handleExport}
              showLabelOnHover
              trackingId={trackingIds.practiceAnalytics.exportPractitionerAnalysisPatients}
            >
              <Icon name='download' />
            </IconButton>
          )}
        </div>

        <Table
          clientPaginationConfig={{
            defaultRowsPerPage: 25,
          }}
          colConfig={subViewTableColConfig({
            actionsTrackingIdBase: 'practitioner-analysis-patient',
            hasProcedures: true,
            middleColumns: processedData.middleColumns,
            patientsLastContacted,
            predefinedLocationId: locationId,
          })}
          data={unscheduled ? filteredData.unscheduled : filteredData.all}
          disableMultiSort
          emptyStateConfig={{
            type: 'users',
          }}
          hasResizeColumns
          hasResponsiveColWidths
          isLoading={isLoading}
          isPaginated
          isSelectable={isBulkMessagingEnabled}
          rowSelectionConfig={{
            initialState: isBulkMessagingEnabled
              ? createAllSelectTableStateObject((unscheduled ? filteredData.unscheduled : filteredData.all).length)
              : {},
            onSelectionToggle: (selectedRows) => {
              setSelectedPatients(selectedRows.map(({ original }) => original));
            },
          }}
          tableInstanceId='practitioner-analysis-patients'
          wrapperStyle={commonStyles.drillDownTableSize}
        />

        <ContentLoader show={isExporting} size='small' />
      </div>
    );
  }
);

PractitionerAnalysisPatients.displayName = 'PractitionerAnalysisPatients';

const styles = {
  actionsWrapper: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(2)};
    margin-top: ${theme.spacing(1)};
    padding: ${theme.spacing(1, 0)};

    .filters {
      align-items: center;
      display: flex;
      gap: ${theme.spacing(2)};
      flex: 1;

      > button {
        width: auto;
      }
    }
  `,
};
