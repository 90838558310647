import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useQuery } from 'react-query';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { SchemaSubscriptionManagerService } from '@frontend/schema';
import { getSubscriptionAddOnsStatuses } from './api';

export const useGetSubscriptionAddonsStatuses = () => {
  const { locationId } = useLocationDataShallowStore('locationId');

  return useQuery(
    [locationId, 'addon-statuses'],
    async () => {
      return await getSubscriptionAddOnsStatuses({ locationId });
    },
    {
      cacheTime: 0,
      staleTime: 0,
      enabled: !!locationId,
    }
  );
};

export const useGetIsEligibleForSelfUpgradeOrTrial = (featureEnum: Feature) => {
  const { locationId } = useLocationDataShallowStore('locationId');

  return useQuery({
    queryKey: [locationId, 'is-eligible-for-self-upgrade-or-trial', featureEnum],
    queryFn: () => SchemaSubscriptionManagerService.IsEligibleForSelfUpgradeOrTrial({ locationId, featureEnum }),
    enabled: !!locationId && !!featureEnum,
  });
};
