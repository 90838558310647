import { Dispatch, SetStateAction } from 'react';
import { useSearch } from '@tanstack/react-location';
import { ScheduledSmsThread } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { ThreadDraft } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import type { Tag as SmsTag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { ClientSettingsApi } from '@frontend/api-client-settings';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { InboxQueries, MessagesHooks, MessagesTypes, SchemaSMSSharedEnums } from '@frontend/api-messaging';
import { PersonHelpers } from '@frontend/api-person';
import { getUser } from '@frontend/auth-helpers';
import { ActionableListRow, RenderListItemProps, StatusAlert } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { MultiSettingsHooks } from '@frontend/multi-settings';
import { useNotificationContext } from '@frontend/notifications';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { convertStringToMessagePopupThreadStatus, useMessagePopupBarManager } from '@frontend/popup-bar';
import { SchemaSMSService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { sentry } from '@frontend/tracking';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { ComponentProps } from '@frontend/types';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { useChildMatch, useInboxNavigate } from '../../../hooks';
import { ExtendedDepartment, InboxType } from '../../../types';
import { MessageStatusIndicator, MessageStatus } from '../../message-status-indicator';
import { InboxListItemContent, InboxListItemLead, InboxListItemTitle } from './components';
import { actionableListRowStyles } from './styles';
import { getUserFriendlyStatus, toggleBlock } from './utils';

dayjs.extend(calendar);

type RendererProps = {
  type: InboxType;
  isBulkSelect: boolean;
  selectedThreads: Record<string, string[]>;
  setSelectedThreads: Dispatch<SetStateAction<Record<string, string[]>>>;
  isSelectAll: boolean;
  departmentsByLocation: Record<string, ExtendedDepartment[]>;
  locationTags: SmsTag[];
  scheduledThreadsMap: Record<string, ScheduledSmsThread[]>;
  drafts: ThreadDraft[];
};

export const InboxListItem =
  ({
    type,
    isBulkSelect,
    selectedThreads,
    setSelectedThreads,
    isSelectAll,
    departmentsByLocation,
    scheduledThreadsMap,
    drafts,
  }: RendererProps) =>
  ({ listItem, index }: RenderListItemProps<MessagesTypes.InboxListItem>) => {
    const formatTimestamp = useTimestampFormatter();
    const notificationTrayContext = useNotificationContext();
    const { locationId, departmentId } = listItem;
    const childMatch = useChildMatch('/:groupId/:threadId');
    const currentThreadId = childMatch?.params['threadId'];
    const search = useSearch() as { smsId?: string; smsCreatedAt?: string; click?: number } & Record<string, unknown>;
    const departments = departmentsByLocation[listItem.locationId] ?? [];
    const department = departments.find((dept) => dept.id === departmentId);
    const alert = useAlert();
    const { t } = useTranslation('inbox');
    const { navigateToThread, closeThread } = useInboxNavigate();
    const user = getUser();
    const { selectedLocationIds: multiSelectedIds } = useAppScopeStore();
    const { addPopup } = useMessagePopupBarManager();
    const invalidateInboxList = MessagesHooks.useInvalidateInboxList();
    const personName = PersonHelpers.getFullName({
      FirstName: listItem?.person?.firstName,
      PreferredName: listItem.person?.preferredName,
      LastName: listItem?.person?.lastName,
    });
    const listItemTitle = personName || formatPhoneNumber(listItem.personPhone);
    const leadingIcons = [MessageStatus.UNREAD, MessageStatus.UNREPLIED];

    const showUnrepliedStatusSetting = MultiSettingsHooks.useBooleanClientSetting({
      set: ClientSettingsApi.querySetKeys.sets.showUnrepliedStatus,
      key: ClientSettingsApi.querySetKeys.keys.showUnrepliedStatus,
      defaultValue: 'false',
    });

    const isCheckboxSelected = isSelectAll
      ? !selectedThreads[locationId]?.includes(listItem.threadId)
      : !!selectedThreads[locationId]?.includes(listItem.threadId);

    const setCheckboxSelected = (selected: boolean) => {
      let threads: string[] = [];
      const filteredThreads = selectedThreads[locationId]?.filter((threadId) => threadId !== listItem.threadId) ?? [];
      const updatedThreads = [...(selectedThreads[locationId] ?? []), listItem.threadId];

      if (isSelectAll) {
        threads = selected ? filteredThreads : updatedThreads;
      } else {
        threads = selected ? updatedThreads : filteredThreads;
      }

      setSelectedThreads((prev) => ({ ...prev, [locationId]: threads }));
    };
    const modifyUnreadCount = InboxQueries.useModifyUnreadCount();

    const showDepartmentChip = departments.length > 1 && !!department;
    const showLocationChip = multiSelectedIds.length > 1;
    const shouldRenderChipsRow = showLocationChip || showDepartmentChip;
    // problem: when the searchPaginatedThreads data is shown, it's possible to see multiple list items with the same threadId.
    // this would result in the UI showing multiple threads selected, which is not desirable.
    // therefore, we never want to show a selected state for the data returned by searchPaginatedThreads.
    const isCurrentThread = listItem.isSearchResult ? false : listItem.threadId === currentThreadId;
    const isOutbound = listItem.direction === SchemaSMSSharedEnums.Direction.DIRECTION_OUTBOUND;

    const { data: flags } = FeatureFlagQueries.useMultiFeatureFlagIsEnabledQuery({
      flagName: 'comm-platform-thread-naming',
      groupIds: [listItem.locationId],
    });
    const usePersonId = !flags?.[listItem.locationId];

    const draft = drafts.find((draft) => draft.threadId === listItem.threadId);
    const isNew = listItem.status === MessagesTypes.KnownThreadStatuses.NEW;
    const hasError = listItem.status === MessagesTypes.KnownThreadStatuses.ERROR;
    const isRead = listItem.status === MessagesTypes.KnownThreadStatuses.READ;
    const getStatus = (): MessageStatus => {
      if (isNew) return MessageStatus.UNREAD;
      if (!!draft) return MessageStatus.DRAFT;
      if (listItem.isBlocked) return MessageStatus.BLOCKED;
      if (hasError) return MessageStatus.ERROR;
      if (scheduledThreadsMap[listItem.threadId]) {
        const scheduledThreads = scheduledThreadsMap[listItem.threadId];
        if (scheduledThreads?.length === 1) {
          if (scheduledThreads[0]?.status !== OutboundMessageStatus.PAUSED) {
            return MessageStatus.SCHEDULED;
          }
          return MessageStatus.PAUSED_SCHEDULED;
        }
        return MessageStatus.MULTIPLE_SCHEDULED;
      }
      if (type === InboxType.ARCHIVED) return MessageStatus.ARCHIVED;
      if (isOutbound) return MessageStatus.OUTGOING_SENT;
      if (showUnrepliedStatusSetting.aggregatedValue) return MessageStatus.UNREPLIED;
      return MessageStatus.READ;
    };

    const onToggleRead = async () => {
      if (isRead) {
        await SchemaSMSService.SetThreadsStatusNew({
          locationId,
          threadIds: [listItem.threadId],
          userId: user?.userID ?? '',
        })
          .then(() => {
            alert.success(t('Marked as unread'));
            modifyUnreadCount('increment', multiSelectedIds);
          })
          .catch(() => {
            alert.error(t('Error marking as unread'));
          });
      } else {
        await SchemaSMSService.SetThreadsStatusRead({
          locationId,
          threadIds: [listItem.threadId],
          userId: user?.userID ?? '',
        })
          .then(() => {
            alert.success(t('Marked as read'));
            modifyUnreadCount('decrement', multiSelectedIds);
          })
          .catch(() => {
            alert.error(t('Error marking as read'));
          });
      }
      invalidateInboxList();
    };

    const onToggleArchived = async () => {
      if (type === InboxType.ARCHIVED) {
        try {
          await SchemaSMSService.SetThreadsUnarchived({
            locationId,
            userId: user?.userID ?? '',
            threadIds: [listItem.threadId],
          });
          alert.success(t('Thread unarchived'));
          invalidateInboxList();
        } catch {
          alert.error(t('Error unarchiving thread'));
        }
      } else {
        try {
          await SchemaSMSService.SetThreadsArchived({
            locationId,
            userId: user?.userID ?? '',
            threadIds: [listItem.threadId],
          });
          alert.success(t('Thread archived'));
          if (!isRead && !hasError) {
            modifyUnreadCount('decrement', multiSelectedIds);
          }
          invalidateInboxList();
        } catch {
          alert.error(t('Error archiving thread'));
        }
        closeThread();
      }
    };

    const onBlockToggle = async () => {
      await toggleBlock({
        isBlocked: !!listItem.isBlocked,
        locationId,
        weaveUserId: user?.userID ?? '',
        personPhone: listItem.personPhone,
        onSuccess: (newVal) => {
          alert.success(newVal ? t('Contact blocked') : t('Contact unblocked'));
          if (!isRead && !hasError) {
            modifyUnreadCount(newVal ? 'decrement' : 'increment', multiSelectedIds);
          }
          if (newVal) {
            closeThread();
          }
          invalidateInboxList();
        },
        onError: (newVal) => {
          alert.error(newVal ? t('Failed to unblock contact') : t('Failed to block contact'));
        },
      });
    };

    const onPopOutClick = () => {
      const { threadId, person, personPhone, status } = listItem;
      const title = personName || formatPhoneNumber(personPhone);
      if (locationId && threadId) {
        addPopup({
          id: threadId,
          name: title,
          type: 'message',
          meta: {
            threadId,
            groupId: locationId,
            departmentId: department?.id,
            personPhone,
            isNew,
            personId: person?.personId,
            status: convertStringToMessagePopupThreadStatus(status ?? 'read'),
          },
        });

        /* 
         - Close thread opened in the right panel only if previously opened threadId = pop out threadId
         - Wait until the pop out has opened fully before closing threading opened
        */
        if (threadId === currentThreadId) {
          setTimeout(() => {
            closeThread();
          }, 500);
        }
      }
    };

    const actions = [
      !hasError && {
        Icon: () => <Icon name={isNew ? 'mark-read' : 'mark-unread'} css={{ color: theme.colors.neutral70 }} />,
        label: isNew ? t('Mark read') : t('Mark unread'),
        action: onToggleRead,
        trackingId: `${InboxPrefixes.List}-mark-read-button`,
      },
      {
        Icon: () => <Icon name='message' css={{ color: theme.colors.neutral70 }} />,
        label: t('Communication Preferences'),
        action: () => {
          window.open(
            `https://book2.getweave.com/${locationId}/preferences-portal/${listItem.personPhone.replace('+1', '')}`,
            '_blank',
            'noopener noreferrer'
          );
        },
        trackingId: `${InboxPrefixes.List}-communication-preferences-button`,
      },
      {
        Icon: () => <Icon name='block' css={{ color: theme.colors.neutral70 }} />,
        label: listItem.isBlocked ? t('Unblock Sender') : t('Block Sender'),
        action: onBlockToggle,
        trackingId: `${InboxPrefixes.List}-block-sender-button`,
      },
      {
        Icon: () => <Icon name={'pop-out'} css={{ color: theme.colors.neutral70 }} />,
        label: t('Pop Out Conversation'),
        action: onPopOutClick,
        trackingId: `${InboxPrefixes.Thread}-popout-conversation`,
      },
    ].filter(Boolean);

    const iconButtonProps: ComponentProps<typeof ActionableListRow>['iconButtonProps'] = {
      label: type === 'archived' ? t('Unarchive') : t('Archive'),
      Icon: () => <Icon name={type === 'archived' ? 'unarchive' : 'archive'} css={{ color: theme.colors.neutral70 }} />,
      onClick: onToggleArchived,
      trackingId: `${InboxPrefixes.List}-archive-button`,
      showLabelOnHover: true,
      size: 'normal',
    };

    const messageBodyPreviewText =
      listItem.body ||
      (listItem.numMedia ? t('{{count}} Attachments', { count: listItem.numMedia }) : t('Nothing to show'));

    const status = getStatus();

    const getMessageStatusAlert = ((status: MessageStatus): StatusAlert | null => {
      switch (status) {
        case MessageStatus.DRAFT:
          return { text: t('Draft'), color: 'light', weight: 'regular' };
        case MessageStatus.ERROR:
          return { text: t('Not Delivered'), color: 'error', weight: 'bold' };
        case MessageStatus.SCHEDULED:
          return {
            text: formatTimestamp(scheduledThreadsMap[listItem.threadId]?.[0]?.sendAt),
            customColor: theme.colors.warning60,
            weight: 'bold',
          };
        default:
          return null;
      }
    })(status);

    return (
      <ActionableListRow
        css={[
          actionableListRowStyles,
          index === 0 && {
            borderTop: 'none',
          },
          isCurrentThread && {
            '.inbox-item-chip': {
              backgroundColor: theme.colors.white,
            },
          },
        ]}
        Lead={
          <InboxListItemLead
            isBulkSelect={isBulkSelect}
            locationId={listItem.locationId}
            firstName={listItem.person?.firstName}
            lastName={listItem.person?.lastName}
            personId={listItem.person?.personId}
            isCheckboxSelected={isCheckboxSelected}
            setCheckboxSelected={setCheckboxSelected}
          />
        }
        actions={actions}
        Title={
          <InboxListItemTitle
            text={listItemTitle}
            isBold={isNew}
            leadingIcon={leadingIcons.includes(status) && <MessageStatusIndicator status={status} />}
            trailingIcon={
              !leadingIcons.includes(status) && (
                <MessageStatusIndicator
                  status={status}
                  inline
                  multiSchedule={
                    status === MessageStatus.MULTIPLE_SCHEDULED ? scheduledThreadsMap[listItem.threadId] : undefined
                  }
                />
              )
            }
            hoverText={getUserFriendlyStatus(status)}
          />
        }
        timestampOverride={getMessageStatusAlert?.text}
        timestampOverrideProps={getMessageStatusAlert && { ...getMessageStatusAlert }}
        iconButtonProps={iconButtonProps}
        Content={
          <InboxListItemContent
            locationId={locationId}
            threadNavData={{
              personPhone: listItem.personPhone,
              threadId: listItem.threadId,
              departmentId: listItem.departmentId,
              personId: listItem.personId,
            }}
            shouldRenderChipsRow={shouldRenderChipsRow}
            showLocationChip={showLocationChip}
            showDepartmentChip={showDepartmentChip}
            departmentName={department?.name}
            isBlocked={listItem.isBlocked}
            messageBodyPreviewText={messageBodyPreviewText}
            uniqueTags={listItem.uniqueTags}
          />
        }
        timestamp={listItem.createdAt}
        as='div'
        tabIndex={0}
        key={listItem.id}
        id={listItem.id}
        onClick={async () => {
          const messageData = listItem.isSearchResult
            ? {
                smsId: listItem.smsId,
                smsCreatedAt: listItem.createdAt,
                click: search.smsId === listItem.smsId ? (search.click ?? 0) + 1 : 1,
              }
            : {};
          navigateToThread({
            threadId: listItem.threadId,
            groupId: listItem.locationId,
            personId: usePersonId ? listItem.personId || undefined : undefined,
            personPhone: listItem.personPhone,
            ...messageData,
            replace: true,
          });
          if (!hasError && user?.userID)
            try {
              await SchemaSMSService.SetThreadsStatusRead({
                locationId,
                threadIds: [listItem.threadId],
                userId: user?.userID ?? '',
              });
            } catch (error) {
              sentry.warn({
                error,
                topic: 'messages',
                addContext: {
                  name: 'Request Context (InboxListItem onClick)',
                  context: {
                    request: {
                      locationId,
                      threadIds: [listItem.threadId],
                      userId: user?.userID ?? '',
                    },
                    errorMessage: JSON.stringify(error),
                    listItem,
                  },
                },
              });
            }
          if (!isRead && !hasError) {
            modifyUnreadCount('decrement', multiSelectedIds);
          }
          invalidateInboxList();
          notificationTrayContext.markThreadNotificationsAsRead(listItem.threadId, listItem.locationId);
        }}
        isSelected={isCurrentThread}
        data-trackingid={`${InboxPrefixes.List}-inbox-item`}
      />
    );
  };
