import { PaymentMethod, PaymentPlanPaymentMethod } from '@frontend/api-payment-plans';
import { CardBrand } from '@frontend/payments-card-brand';
import { getPaymentMethodNameParts } from '../utils';

type PaymentMethodInfo = PaymentMethod | PaymentPlanPaymentMethod | undefined;

export const PaymentMethodName = ({
  paymentMethod: pm,
  showCardBrand = false,
}: {
  paymentMethod: PaymentMethodInfo;
  showCardBrand?: boolean;
}) => {
  const { paymentMethodName, paymentMethodBrand, last4 } = getPaymentMethodNameParts(pm);
  return (
    <>
      {showCardBrand && paymentMethodBrand ? <CardBrand brand={paymentMethodBrand} /> : paymentMethodName} {last4}
    </>
  );
};
