import { Alert } from '@frontend/design-system';
import { FC, ReactNode } from 'react';

interface FeatureSignUpAlertProps {
  alertMessage: ReactNode;
  trackingId?: string;
  extraContent?: ReactNode;
}
export const FeatureSignUpAlert: FC<FeatureSignUpAlertProps> = ({ alertMessage, extraContent, trackingId }) => {
  if (!alertMessage) return null;

  return (
    <>
      <Alert type='warning' data-testid={trackingId}>
        {alertMessage}
      </Alert>
      {extraContent}
    </>
  );
};
