import { BreadcrumbsProps, BreadcrumbsProvider } from './provider';
import { createGetSpecificChildren } from '../../helpers';
import { CaretRightIconSmall, IconProps } from '../../icon';
import React, { Fragment } from 'react';
import { BreadcrumbsPath } from './atoms';
import { css } from '@emotion/react';
import { useThemeValues } from '../../hooks';
import { NakedOl } from '../../naked';

type BreadcrumbsChildrenTypes = 'BreadcrumbsPath';

type BreadcrumbsMainProps = {
  separator?: React.FC<React.PropsWithChildren<IconProps>> | string;
  className?: string;
} & BreadcrumbsProps;

export const Breadcrumbs = ({ children, separator, size = 'small', className, ...rest }: BreadcrumbsMainProps) => {
  const getChild = createGetSpecificChildren<BreadcrumbsChildrenTypes>(children, true);
  const filteredChildren = getChild('BreadcrumbsPath');
  const lastItemIndex = filteredChildren.length - 1;

  const iconSizeMap = {
    small: 8,
    medium: 9,
    large: 11,
  };

  const Separator = separator ?? CaretRightIconSmall;

  const { spacing } = useThemeValues();

  return (
    <BreadcrumbsProvider size={size} {...rest}>
      <nav className={className} aria-label='Breadcrumb'>
        <NakedOl
          css={css`
            display: flex;
            align-items: center;
            gap: ${spacing(1)};
          `}
        >
          {filteredChildren.map((child, index) => {
            if (index !== 0 && child) {
              return (
                <Fragment key={index}>
                  <li
                    aria-hidden='true'
                    key={index}
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    {typeof separator === 'string' ? separator : <Separator size={iconSizeMap[size]} />}
                  </li>

                  {lastItemIndex === index ? React.cloneElement(child, { currentPage: true }) : child}
                </Fragment>
              );
            }
            return lastItemIndex === index ? React.cloneElement(child, { currentPage: true }) : child;
          })}
        </NakedOl>
      </nav>
    </BreadcrumbsProvider>
  );
};

Breadcrumbs.Path = BreadcrumbsPath;
