import { FC, useEffect } from 'react';
import { ContentLoader } from '@frontend/design-system';
import { useContactDetails } from '../../../../hooks';
import { useSendFormsContext, useSendFormsStore } from '../../../../providers';
import { containerStyle } from './delivery-options.styles';
import SendAsEmail from './send-as-email/send-as-email.component';
import SendAsKioskQR from './send-as-kiosk-qr/send-as-kiosk-qr.component';
import SendAsMessage from './send-as-message/send-as-message.component';

const DeliveryOptions: FC = () => {
  const { personId, setShowMessageComponent } = useSendFormsStore(['personId', 'setShowMessageComponent']);
  const { locationId } = useSendFormsContext();
  const { getContactNumbers, isLoadingContact } = useContactDetails(personId, locationId);

  useEffect(() => {
    const contactNumbers = getContactNumbers();
    setShowMessageComponent(contactNumbers.length !== 0);
  }, [personId, locationId, getContactNumbers]);

  if (isLoadingContact) {
    return <ContentLoader show />;
  }

  return (
    <section css={containerStyle}>
      <SendAsMessage />
      <SendAsEmail />
      <SendAsKioskQR />
    </section>
  );
};

export default DeliveryOptions;
