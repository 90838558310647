import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { CallIntelSubViewId } from '../../../hooks';
import { callIntelligenceUtils, formatters } from '../../../utils';
import { InlineStats } from '../../inline-stats';

type Props = {
  data?: CallIntelligenceTypes.Overview | null;
  isDemoAccount?: boolean;
  isLoading?: boolean;
  metricsFor?: string;
  sentimentCode?: CallIntelSubViewId;
};

export const SubViewOpportunitiesInlineStats = ({
  data,
  isDemoAccount,
  isLoading,
  metricsFor,
  sentimentCode,
}: Props) => {
  const { t } = useTranslation('analytics');

  const getCallsCount = () => {
    if (!data) {
      return 0;
    }

    return callIntelligenceUtils.getCallsCount(data, sentimentCode);
  };

  return (
    <InlineStats
      data={[
        {
          label: t('{{name}} Calls', { name: metricsFor }),
          value: formatters.value.format(getCallsCount()),
        },
        {
          label: t('Unscheduled Opportunities'),
          value: formatters.value.format(
            (data?.summary.totalOpportunities || 0) - (data?.summary.scheduledOpportunities || 0)
          ),
        },
        {
          label: t('Conversion Rate'),
          value: formatters.percent.format(
            (data?.summary.scheduledOpportunities || 0) / (data?.summary.totalOpportunities || 0)
          ),
        },
      ]}
      isDemoAccount={isDemoAccount}
      isLoading={isLoading}
    />
  );
};
