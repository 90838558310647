import { FC } from 'react';
import { css } from '@emotion/react';
import { Address } from '@weave/schema-gen-ts/dist/schemas/phone/address/v1/address_service.pb';
import { stateOptions, CountryCodes, countryOptions } from '@frontend/geography';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  DropdownField,
  FormRow,
  Modal,
  PostalCodeField,
  TextField,
  Text,
} from '@frontend/design-system';
import { useAddressForm } from './use-e911-address-form';

interface Props {
  address?: Address;
  onBack?: () => void;
  onCancel?: () => void;
  onSave: (address: Address) => void;
  isForm?: boolean;
  isLoading?: boolean;
}

export const E911AddressForm: FC<React.PropsWithChildren<Props>> = ({
  address,
  onBack,
  onCancel,
  onSave,
  isForm = true,
  isLoading,
}) => {
  const { getFieldProps, formProps, getErrors, values } = useAddressForm({
    address,
    onSubmit: () => handleSave(),
  });

  const handleSave = () => onSave({ id: address?.id, ...values } as Address);

  const props = {
    onBack,
    onCancel,
    getErrors,
    getFieldProps,
    isForm,
  };

  const Container = isForm ? (
    <form css={formStyles} {...formProps}>
      <E911FormFields {...props} />
    </form>
  ) : (
    <div css={formStyles}>
      <E911FormFields onSave={handleSave} {...props} />
    </div>
  );

  return (
    <>
      <ContentLoader show={isLoading} />
      {Container}
    </>
  );
};

const E911FormFields = ({
  getFieldProps,
  getErrors,
  onBack,
  onCancel,
  onSave,
  isForm,
}: {
  getErrors: ReturnType<typeof useAddressForm>['getErrors'];
  getFieldProps: ReturnType<typeof useAddressForm>['getFieldProps'];
  onBack?: () => void;
  onCancel?: () => void;
  onSave?: (address?: Address) => void;
  isForm: boolean;
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'e911' });
  const countryProps = getFieldProps('country');

  return (
    <>
      <FormRow
        css={css`
          margin-top: ${theme.spacing(1)};
        `}
      >
        <TextField {...getFieldProps('name')} label={t('Address Name')} />
      </FormRow>
      <FormRow>
        <DropdownField {...getFieldProps('country')} label={t('Country')}>
          {countryOptions.map(({ label, value }) => (
            <DropdownField.Option key={value} value={value}>
              {label}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </FormRow>
      <FormRow>
        <TextField {...getFieldProps('street')} label={t('Street Address')} />
      </FormRow>
      <FormRow>
        <TextField {...getFieldProps('streetSecondary')} label={t('Address Line 2')} />
      </FormRow>
      <FormRow>
        <TextField {...getFieldProps('city')} label={t('City')} />
      </FormRow>
      <FormRow>
        <DropdownField
          {...getFieldProps('state')}
          label={countryProps.value === CountryCodes.USA ? t('State') : t('Province')}
        >
          {stateOptions[countryProps.value as CountryCodes]?.map(({ label, value }) => (
            <DropdownField.Option key={value} value={value} searchValue={label}>
              {label}
            </DropdownField.Option>
          ))}
        </DropdownField>
        <PostalCodeField {...getFieldProps('postalCode')} label={t('Zip Code')} />
      </FormRow>
      <Modal.Actions
        disablePrimary={Object.entries(getErrors()).length > 0}
        onPrimaryClick={isForm ? 'submit' : () => onSave?.()}
        onSecondaryClick={onCancel}
        secondaryLabel={t('Cancel')}
        onBackClick={onBack}
        primaryLabel={t('Save')}
        backLabel={
          <div css={{ display: 'flex', gap: theme.spacing(1) }}>
            <Icon name='back' />
            <Text color='primary' weight='bold'>
              {t('Back')}
            </Text>
          </div>
        }
      />
    </>
  );
};

const formStyles = css`
  & > div:last-of-type {
    padding: 0;
  }
`;
