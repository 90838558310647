import { PersonTypes } from '@frontend/api-person';
import { UsePopoverDialogResponse, contextFactory } from '@frontend/design-system';
import { PersonSelectorTypes } from '../../../../components/PersonSelector';
import { useManageScheduleRequestModalDetails } from '../hooks/useManageScheduleRequestModalDetails';
import { useManageScheduleRequestModalForm } from '../hooks/useManageScheduleRequestModalForm';

export type ScheduleRequestModalFormContext = {
  manageScheduleRequestModalDetails: ReturnType<typeof useManageScheduleRequestModalDetails>;
  manageScheduleRequestFormDetails: ReturnType<typeof useManageScheduleRequestModalForm>;
  personSelectorDialogProps: UsePopoverDialogResponse<HTMLButtonElement, false, HTMLDialogElement>;
  personSelectorProps: PersonSelectorTypes.PersonSelectorPropsType;
  setTemplate: (template: string) => void;
  hasAppointmentWritebacks: boolean;
  selectedPerson: PersonTypes.Person | null;
};

export const ScheduleRequestModalFormContextFactory = contextFactory<ScheduleRequestModalFormContext>();

export const [ScheduleRequestModalFormContext, useScheduleRequestModalFormContext] =
  ScheduleRequestModalFormContextFactory;
