import { KeyboardEvent, useEffect, useState } from 'react';
import { Media } from '@weave/schema-gen-ts/dist/shared/sms/v1/enums.pb';
import { Optional } from 'ts-toolbelt/out/Object/Optional';
import { MediaTypes } from '@frontend/api-media';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton, Modal, SpinningLoader, Text, useModalControl, useTooltip } from '@frontend/design-system';

export type SendingMediaItem = {
  id: string;
  uploading: boolean;
  hasError: boolean;
  file?: File;
  mediaObj?: Media;
  previewSrc?: string;
};
type MediaUploadPreviewProps = {
  media: SendingMediaItem[];
  removeMediaItem: (mediaId: string) => void;
  inPopout?: boolean;
  removeImageTrackingId?: string;
};

export const MediaUploadPreview = ({
  media,
  removeMediaItem,
  inPopout,
  removeImageTrackingId,
}: MediaUploadPreviewProps) => {
  const [selectedImage, setSelectedImage] = useState<string>();

  const previewModalControls = useModalControl();

  return (
    <ul
      css={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          height: media.length ? (inPopout ? theme.spacing(16) : '20vh') : '0',
          width: '100%',
          padding: media.length ? theme.spacing(2) : 0,
          borderTop: media.length ? (inPopout ? 'none' : `1px solid ${theme.colors.neutral20}`) : 'none',
          gap: theme.spacing(2),
          overflowX: 'auto',
        },
        !media.length && {
          transition: 'height 300ms ease-in-out, border-top 300ms ease-in-out, padding 300ms ease-in-out',
        },
        inPopout && {
          backgroundColor: theme.colors.neutral5,
          zIndex: theme.zIndex.higher,
        },
      ]}
    >
      {!!media.length &&
        media.map((mediaItem) => (
          <PreviewImage
            key={mediaItem.id}
            image={mediaItem}
            onDelete={() => {
              removeMediaItem(mediaItem.id);
            }}
            onPreview={() => {
              setSelectedImage(mediaItem.mediaObj?.url);
              previewModalControls.openModal();
            }}
            inPopout={inPopout}
            removeImageTrackingId={removeImageTrackingId}
          />
        ))}
      <Modal
        {...previewModalControls.modalProps}
        maxWidth={0}
        css={{
          padding: 0,
          borderRadius: theme.borderRadius.medium,
          overflow: 'hidden',
        }}
      >
        <img src={selectedImage} css={{ maxHeight: '70vh', maxWidth: '50vw', padding: 0 }} />
      </Modal>
    </ul>
  );
};

export type PreviewImageFile = Optional<MediaTypes.MediaUploadFile, 'file'> & { name: string };
type PreviewImageProps = {
  image: SendingMediaItem;
  onDelete: () => void;
  onPreview: (src: string) => void;
  inPopout?: boolean;
  removeImageTrackingId?: string;
};

const PreviewImage = ({ image, onDelete, onPreview, inPopout, removeImageTrackingId }: PreviewImageProps) => {
  const { t } = useTranslation('inbox');
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top' });
  const [imageError, setImageError] = useState(false);
  const [focus, setFocus] = useState<'image' | 'button' | 'none'>('none');
  const isUploading = image.uploading || !image.mediaObj;
  const hasError = image.hasError;
  const reader = new FileReader();
  const [fileSrc, setFileSrc] = useState<string>();
  const src = image.previewSrc || fileSrc;
  const name = image.file?.name || image.id;
  const fileExtension = image.file?.type.split('/').pop()?.toUpperCase();

  const handlePreview = () => {
    if (image.mediaObj?.url) {
      onPreview(image.mediaObj.url);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handlePreview();
    }
  };

  const handleReaderLoad = () => {
    setFileSrc(reader.result as string);
  };

  useEffect(() => {
    if (!image.file) return;
    reader.onload = handleReaderLoad;
    reader.readAsDataURL(image.file);

    return () => {
      setFileSrc(undefined);
      reader.removeEventListener('load', handleReaderLoad);
    };
  }, [image.file]);

  if (!src) return null;

  return (
    <>
      <li
        css={{
          listStyleType: 'none',
          height: '100%',
          maxWidth: inPopout ? '40%' : '25%',
          flexShrink: 0,
          cursor: isUploading ? 'auto' : 'pointer',
          position: 'relative',
          borderRadius: theme.borderRadius.medium,
          ':focus': {
            outline: 'none',
          },
        }}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onFocusCapture={() => {
          setFocus('image');
        }}
        onBlurCapture={() => {
          setFocus('none');
        }}
      >
        {isUploading && (
          <div
            css={{
              transition: 'background-color 300ms ease-in-out',
              zIndex: 1,
              maxHeight: '100%',
              height: 'auto',
              width: 'auto',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: `rgba(255, 255, 255, 0.5)`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SpinningLoader />
          </div>
        )}
        {!isUploading && (
          <IconButton
            css={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
              backgroundColor: hasError ? 'transparent' : theme.colors.white,
              padding: inPopout ? theme.spacing(0.5) : undefined,
            }}
            aria-label={
              hasError
                ? t('Issue uploading image {{name}}. Click to remove.', { name })
                : t('Remove image attachment {{name}}.', { name })
            }
            label={hasError ? t('Issue uploading image. Click to remove.') : t('Remove image attachment.')}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              !isUploading && onDelete();
            }}
            showLabelOnHover={hasError}
            onFocusCapture={() => setFocus('button')}
            size={inPopout ? 'xsmall' : undefined}
            {...(removeImageTrackingId && { trackingId: removeImageTrackingId })}
          >
            {hasError ? <Icon name='alert' color='error' /> : <Icon name='x' size={inPopout ? 16 : undefined} />}
          </IconButton>
        )}
        {!imageError ? (
          <img
            css={[
              {
                maxHeight: '100%',
                maxWidth: '100%',
                height: 'auto',
                width: 'auto',
                borderRadius: theme.borderRadius.medium,
                ':hover': {
                  outline: `2px solid ${theme.colors.primary50}`,
                },
              },
              focus === 'image' && {
                outline: `2px solid ${theme.colors.primary50}`,
              },
            ]}
            src={src}
            onClick={() => (image.mediaObj?.url ? handlePreview() : undefined)}
            onError={() => setImageError(true)}
          />
        ) : (
          <figure
            css={{
              height: '100%',
              width: '90px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.colors.white,
              color: theme.colors.black,
              borderRadius: theme.borderRadius.medium,
              padding: theme.spacing(1),
              ':hover': {
                outline: `2px solid ${theme.colors.primary50}`,
              },
            }}
            {...triggerProps}
          >
            <Icon name='document' size={24} />
            <Text color='subdued'>
              <figcaption>{fileExtension}</figcaption>
            </Text>
            <Tooltip {...tooltipProps}>
              <Text size='medium' color='white'>
                {name}
              </Text>
            </Tooltip>
          </figure>
        )}
      </li>
    </>
  );
};
