import {
  AuthService,
  ExchangeTokenResponse,
  VerifyAuthResponse,
} from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import {
  LoginFeatures,
  GetLoginFeaturesResponse,
} from '@weave/schema-gen-ts/dist/schemas/login-features/v1/service.pb';
import { CreateSessionResponse, SessionApi } from '@weave/schema-gen-ts/dist/schemas/session-api/session_api.pb';
import {
  AuthConfig,
  AuthStorage,
  HttpClient,
  localStorageHelper,
  PortalUser,
  SignInMethod,
  getDecodedSessionToken,
  getDecodedWeaveToken,
  getWeaveToken,
  isWeaveTokenActive,
} from '@frontend/auth-helpers';
import appConfig, { getInitialParams } from '@frontend/env';
import { sentry } from '@frontend/tracking';
import { AuthFlow, SignInProps, ClientTokens } from './AuthFlow';
import { LegacyClient } from './legacy.class';
import { OidcClient } from './oidc.class';
import { OktaClient } from './okta.class';

const oidc_code_hint = 'ory_ac_';
const { oktaId, backendApi, oidcIssuer, oidcClientID } = getInitialParams();
const redirectUri = `${window.location.origin}${appConfig.BASE_PATH ? `/${appConfig.BASE_PATH}` : ''}/sign-in/callback`;
const appSource = appConfig.APP_SOURCE || 'weave2';
const oktaIssuer = appConfig.OKTA_ISSUER || 'https://auth.weaveconnect.com/oauth2/default';

const APIFetchNoAuth = (backend: string): ((url: string, reqInit: RequestInit) => Promise<Response>) => {
  return (url: string, reqInit: RequestInit) => {
    return fetch(`${backend}${url}`, reqInit).then((res) => {
      if (res.status !== 200) {
        throw new Error(res.body?.toString());
      }
      return res.json();
    });
  };
};

const authorizedFetch = (token: string): ((url: string, reqInit: RequestInit) => Promise<Response>) => {
  return (url: string, reqInit: RequestInit) => {
    reqInit.headers = {
      ...reqInit.headers,
      Authorization: `Bearer ${token}`,
    };
    return fetch(`${backendApi}${url}`, reqInit)
      .then((res) => {
        if (res.status === 403) {
          return res.status;
        } else if (res.status !== 200) {
          throw new Error(res.body?.toString());
        } else {
          return res.json();
        }
      })
      .catch((err) => {
        console.info('ERROR', err);
      });
  };
};

export class Authn implements AuthFlow {
  private static instance: Authn;
  private authMethods: SignInMethod[] = ['okta', 'oidc', 'legacy'];
  private currentAuthMethod: 'okta' | 'oidc' | 'legacy' = 'oidc';
  private hasCheckedAuthMethod = false;
  private oidcClient: AuthFlow | undefined;
  private oktaClient: AuthFlow | undefined; // These get defined in the constructor
  private legacyClient: AuthFlow | undefined; // These get defined in the constructor
  private config: AuthConfig = {};

  private constructor() {
    this.setConfig(this.config);
  }

  static getInstance(): Authn {
    if (!Authn.instance) {
      Authn.instance = new Authn();
    }

    return Authn.instance;
  }

  public setConfig(incomingConfig: AuthConfig): void {
    this.config.oktaIssuer = incomingConfig.oktaIssuer || oktaIssuer;
    this.config.oktaClientID = incomingConfig.oktaClientID || oktaId;
    this.config.oktaRedirectUri = incomingConfig.oktaRedirectUri || redirectUri;
    this.config.oidcIssuer = incomingConfig.oidcIssuer || oidcIssuer;
    this.config.oidcClientID = incomingConfig.oidcClientID || oidcClientID;
    this.config.oidcRedirectUri = incomingConfig.oidcRedirectUri || redirectUri;
    this.config.backendAPI = incomingConfig.backendAPI || backendApi;
    this.config.appSource = incomingConfig.appSource || appSource;
    this.initializeValues();
  }

  private initializeValues(): void {
    if (!this.config.oidcIssuer || !this.config.oidcClientID) {
      this.authMethods = ['okta', 'legacy'];
    } else {
      this.oidcClient = new OidcClient({
        issuer: new URL(this.config.oidcIssuer),
        clientID: this.config.oidcClientID,
        redirectUri: new URL(this.config.oidcRedirectUri || ''),
        scopes: ['openid', 'email'],
      });
    }

    this.oktaClient = new OktaClient({
      issuer: this.config.oktaIssuer || '',
      clientId: this.config.oktaClientID || '',
      redirectUri: this.config.oktaRedirectUri || '',
      scopes: ['openid', 'email', 'profile'],
    });
    this.legacyClient = new LegacyClient();
  }

  public async getSignInMethod(): Promise<SignInMethod> {
    if (this.hasCheckedAuthMethod) {
      return Promise.resolve(this.currentAuthMethod);
    }
    // if we are in preview, we should always default to legacy auth
    // TODO: preview.weavelab.xyz is the old way of serving previews. This will be going away soon
    // f.weavedev.net will be replacing preview.weavelab.xyz
    const origin = window.location.origin;
    if (
      origin.includes('preview.weavelab.xyz') ||
      origin.includes('f.weavedev.net') ||
      localStorageHelper.get('backendEnv') === 'dev'
    ) {
      this.hasCheckedAuthMethod = true;
      this.currentAuthMethod = 'legacy';
      return Promise.resolve('legacy');
    }
    const lastSignInMethod = localStorageHelper.get(AuthStorage.sign_in_method);
    if (
      (!!lastSignInMethod && lastSignInMethod === 'okta') ||
      lastSignInMethod === 'legacy' ||
      lastSignInMethod === 'oidc'
    ) {
      this.hasCheckedAuthMethod = true;
      this.currentAuthMethod = lastSignInMethod;
      return Promise.resolve(lastSignInMethod);
    }
    const fingerprint = localStorageHelper.get(AuthStorage.sign_in_fingerprint);
    return LoginFeatures.GetLoginFeatures(
      APIFetchNoAuth(this.config.backendAPI as string) as (
        url: string,
        reqInit: RequestInit
      ) => Promise<GetLoginFeaturesResponse>,
      {
        fingerprint,
      }
    )
      .then((res: GetLoginFeaturesResponse) => {
        if (!res) {
          return 'okta';
        }
        if (res.fingerprint) {
          localStorageHelper.create(AuthStorage.sign_in_fingerprint, res.fingerprint);
        }
        this.hasCheckedAuthMethod = true;
        this.currentAuthMethod = res.features?.includes('oidc')
          ? 'oidc'
          : res.features?.includes('okta')
          ? 'okta'
          : 'legacy';
        return this.currentAuthMethod;
      })
      .catch(() => {
        return 'oidc'; // default for prod until OIDC is released
      });
  }

  public changeAuthMethod(authMethod?: SignInMethod): SignInMethod {
    if (!!authMethod) {
      localStorageHelper.create(AuthStorage.sign_in_method, authMethod);
      this.currentAuthMethod = authMethod;
      return authMethod;
    }

    // if no auth method is passed in, cycle through the available auth methods
    const currentMethodIndex = this.authMethods.indexOf(this.currentAuthMethod);
    const nextMethodIndex = currentMethodIndex === this.authMethods.length - 1 ? 0 : currentMethodIndex + 1;
    const currentSignInMethod: SignInMethod = this.authMethods[nextMethodIndex];
    localStorageHelper.create(AuthStorage.sign_in_method, currentSignInMethod);
    this.currentAuthMethod = currentSignInMethod;
    return currentSignInMethod;
  }

  public fetchUser() {
    const token = getWeaveToken();
    const decodedToken = getDecodedWeaveToken();
    if (!decodedToken) {
      throw new Error('No token found');
    }
    const options: HttpClient = {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    };
    return fetch(`${this.config.backendAPI}/portal/users/${decodedToken.user_id}`, options)
      .then<{ data: PortalUser }>((res) => res.json().catch((_err) => undefined))
      .then((res) => {
        if (!res.data) {
          throw new Error('User Not Found');
        }
        localStorageHelper.create(AuthStorage.user, res.data);
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        return undefined;
      });
  }

  private getAuthClient(): AuthFlow {
    switch (this.currentAuthMethod) {
      case 'oidc':
        return this.oidcClient as AuthFlow;
      case 'okta':
        return this.oktaClient as AuthFlow;
      case 'legacy':
        return this.legacyClient as AuthFlow;
      default:
        return this.oidcClient as AuthFlow;
    }
  }

  public assureSessionToken(orgID: string): Promise<string> {
    let needsRefresh = false;
    const sessionToken = getDecodedSessionToken();
    const weaveToken = getWeaveToken();

    if (!isWeaveTokenActive()) {
      return Promise.reject('Weave token is not active');
    }

    // Check current session token's expiration
    // or
    // if the current session token doesn't match the orgID, we need to refresh
    if (!sessionToken || sessionToken.decoded.org_id !== orgID) {
      needsRefresh = true;
    } else {
      const exp = sessionToken.decoded.exp;
      const now = Date.now();
      if (exp * 1000 > now) {
        // If not expired, return the current session token
        return Promise.resolve(sessionToken.token);
      }
      needsRefresh = true;
    }

    // If needsRefresh, create a new session token
    if (needsRefresh && weaveToken) {
      return this.createSessionToken(weaveToken, getDecodedWeaveToken()?.user_id, orgID).then(
        (newSessionToken: string) => {
          return newSessionToken;
        }
      );
    }

    if (!sessionToken) {
      throw new Error('No session token found');
    }
    return Promise.resolve(sessionToken.token);
  }

  public createSessionToken(weaveJWT: string, userID: string | undefined, orgID: string | undefined): Promise<string> {
    return SessionApi.CreateSession(
      authorizedFetch(weaveJWT) as (url: string, reqInit: RequestInit) => Promise<CreateSessionResponse>,
      {
        userId: userID,
        orgId: orgID,
      }
    )
      .then((res) => {
        if (res.token) {
          localStorageHelper.create(AuthStorage.weave_session_token, res.token);
          return res.token;
        } else if (res.toString() === '403') {
          return res.toString();
        } else {
          throw new Error('No session token returned');
        }
      })
      .catch((err) => {
        localStorageHelper.delete(AuthStorage.weave_session_token);
        throw new Error(err);
      });
  }

  public exchangeToken(tokens: ClientTokens): Promise<string> {
    if (this.currentAuthMethod === 'oidc' || this.config?.oktaIssuer?.includes('weaveworkforce')) {
      return AuthService.ExchangeToken(
        APIFetchNoAuth(this.config.backendAPI as string) as (
          url: string,
          reqInit: RequestInit
        ) => Promise<ExchangeTokenResponse>,
        {
          tokenId: tokens.IDToken,
          expiration: '240',
          source: this.config.appSource,
        }
      )
        .then((res) => {
          if (res.token) {
            localStorageHelper.create(AuthStorage.weave_token, res.token);
            return res.token;
          } else {
            throw new Error('No token returned');
          }
        })
        .catch((err) => {
          sentry.error({
            topic: 'auth',
            error: err,
            addContext: {
              name: 'auth',
              context: {
                errMessage: 'OIDC Login Token Exchange',
              },
            },
          });
          throw new Error(err);
        });
    } else {
      const options: HttpClient = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          idToken: tokens.IDToken,
          exp: '240',
          source: this.config.appSource,
        }),
      };
      return fetch(`${this.config.backendAPI}/auth/oktaexchange`, options)
        .then((res) => res.json())
        .then((res) => {
          if (!!res.data.token) {
            localStorageHelper.create(AuthStorage.weave_token, res.data.token);
          }
          return res.data || '';
        })
        .catch((err) => {
          sentry.error({
            topic: 'auth',
            error: err,
            addContext: {
              name: 'auth',
              context: {
                errMessage: 'Login Token Exchange.',
              },
            },
          });
          throw new Error(err);
        });
    }
  }

  public async refreshWeaveToken() {
    const token = getWeaveToken();

    if (!token) {
      throw new Error('No existing weave token');
    }

    const options: HttpClient = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body: JSON.stringify({ Expiration: '240' }),
    };

    return fetch(`${this.config.backendAPI}/portal/token/refresh`, options)
      .then((res) => res.json())
      .then((res) => res.data)
      .then((newToken: string) => {
        if (!!newToken) {
          localStorageHelper.create(AuthStorage.weave_token, newToken);
          return newToken;
        }
        throw new Error('Refresh token failed');
      });
  }

  public getTokens(): Promise<ClientTokens> {
    return this.getAuthClient().getTokens();
  }

  public async handleCallback(): Promise<ClientTokens> {
    // based on the code in the query params we should identify which auth method to use
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    if (code && code.indexOf(oidc_code_hint) != -1) {
      this.changeAuthMethod('oidc');
    }
    return this.getAuthClient()
      .handleCallback()
      .then((tokens) => {
        if (tokens?.IDToken) {
          return this.exchangeToken(tokens).then((weaveJWT: string) => {
            if (weaveJWT) {
              tokens.weaveToken = weaveJWT;
            }
            return tokens;
          });
        }
        return {};
      });
  }

  public isUserAuthenticated(): boolean {
    return this.getAuthClient().isUserAuthenticated();
  }

  public signIn(signInInfo?: SignInProps): Promise<VerifyAuthResponse | string> {
    return this.getAuthClient().signIn(signInInfo);
  }

  public signOut(opts: { logoutRedirect?: string } = {}): Promise<void> {
    // wrapping this with getSignInMethod to ensure that the most recently used sign in method is used
    return this.getSignInMethod().then(() => {
      localStorageHelper.delete(AuthStorage.user);
      localStorageHelper.delete(AuthStorage.weave_token);
      localStorageHelper.delete(AuthStorage.decoded_weave);
      localStorageHelper.delete(AuthStorage.okta_session_exp);
      localStorageHelper.delete(AuthStorage.sign_in_method);
      localStorageHelper.delete(AuthStorage.weave_session_token);
      return this.getAuthClient().signOut(opts); // usually will redirect the browser
    });
  }

  public frontChannelLogout(): Promise<void> {
    return this.getSignInMethod().then(() => {
      localStorageHelper.delete(AuthStorage.user);
      localStorageHelper.delete(AuthStorage.weave_token);
      localStorageHelper.delete(AuthStorage.decoded_weave);
      localStorageHelper.delete(AuthStorage.okta_session_exp);
      localStorageHelper.delete(AuthStorage.sign_in_method);
      localStorageHelper.delete(AuthStorage.weave_session_token);
      localStorageHelper.delete(AuthStorage.oidc_token_storage);
    });
  }
}

export const authnClient = Authn.getInstance();
