import { useMutation } from '@frontend/react-query-helpers';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import {
  ButtonBar,
  Modal,
  SecondaryButton,
  Text,
  useListboxState,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { ScheduleApi, ScheduleTypes } from '@frontend/api-schedule';
import { ScheduleModal } from './modals/schedule-modal.old';
import { WeeklyScheduler } from './weekly-scheduler';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useTranslation } from '@frontend/i18n';
import { useQueryClient } from 'react-query';
import { RoutingSettingsContext } from '../department/phone-routing-settings';

import { DepartmentScheduleResponse } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/schedule.pb';
import { queryKeys } from '../../query-keys';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { OfficeHoursViewBreaks } from './modals/view-breaks.component';
import { LocationsApi } from '@frontend/api-locations';
import dayjs from 'dayjs';

type Props = {
  departmentId: string;
  setNewBreak: (value: React.SetStateAction<DepartmentScheduleResponse | undefined>) => void;
};

export const OfficeHoursPortalDepartments = ({ departmentId, setNewBreak }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  const queryClient = useQueryClient();
  const { singleLocationId: locationId } = useAppScopeStore();
  const { routingData } = useContext(RoutingSettingsContext);
  const { data: schedules } = useLocalizedQuery({
    queryKey: queryKeys.listDepartmentSchedules(departmentId),
    queryFn: ({ queryKey }) => {
      return ScheduleApi.listSchedulesByDepartment({ departmentId: queryKey[3] }).then<
        ScheduleTypes.ScheduleWithRouting[]
      >((schedulesResponse: any) => schedulesResponse.schedules);
    },
  });
  const breakSchedules = schedules?.filter((schedule: { type: string }) => schedule.type === 'break');
  const { modalProps, triggerProps, openModal, closeModal } = useModalControl();
  const editBreakModal = useModalControl();
  const breakSelectProps = useListboxState('');
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleTypes.Schedule>();
  const alerts = useAlert();

  const schedulesWithoutRouting = useMemo(() => {
    return (
      routingData.schedules
        ?.filter((schedule) => schedule.type !== ScheduleTypes.ScheduleType.Closed)
        ?.map<ScheduleTypes.Schedule>((schedule) => {
          const { callRoute, ...rest } = schedule;
          return rest;
        }) ?? []
    );
  }, [routingData.schedules]);

  const openHoursSchedule = useMemo(
    () => schedulesWithoutRouting.find((s) => s.type === ScheduleTypes.ScheduleType.Open),
    [schedulesWithoutRouting]
  );

  const { data: officeTimezone } = useScopedQuery({
    queryKey: queryKeys.timezone(locationId),
    queryFn: () => LocationsApi.getOfficeTimezone({}, { locationId: locationId }),
    select: (data) => (data?.timezone ? dayjs().tz(data.timezone).format('z') : ''),
    enabled: !!locationId,
  });

  const { mutate: updateSchedule, isLoading: isUpdatingSchedule } = useMutation(
    (schedule: ScheduleTypes.Schedule) => {
      return ScheduleApi.updateScheduleForDepartment({ departmentId: departmentId, schedule: schedule });
    },
    {
      onSuccess: (schedule) => {
        schedule.schedule?.type === 'break'
          ? alerts.success(t('Break updated successfully.'))
          : alerts.success(t('Open hours updated successfully.'));
        queryClient.invalidateQueries([locationId, ...queryKeys.listDepartmentSchedules(departmentId)]);
        /// invalidate the queries used in the departments page
        queryClient.invalidateQueries([locationId, ...queryKeys.listDepartmentsWithSchedules()]);
      },
      onError: () => {
        alerts.error(t(`Schedule Update Failed`));
      },
      onSettled: () => {
        closeModal();
      },
    }
  );

  const { mutate: createSchedule, isLoading: isCreatingSchedule } = useMutation(
    (schedule: ScheduleTypes.Schedule) => {
      return ScheduleApi.createScheduleForDepartment({ departmentId: departmentId, schedule: schedule });
    },
    {
      onSuccess: (response: DepartmentScheduleResponse) => {
        setNewBreak(response);
        alerts.success(t('Break added successfully.'));
        queryClient.invalidateQueries([locationId, ...queryKeys.listDepartmentSchedules(departmentId)]);
        /// invalidate the queries used in the departments page
        queryClient.invalidateQueries([locationId, ...queryKeys.listDepartmentsWithSchedules()]);
      },
      onError: () => {
        alerts.error(t(`Schedule Update Failed`));
      },
      onSettled: () => {
        closeModal();
      },
    }
  );

  const onScheduleSave = (schedule: ScheduleTypes.Schedule) => {
    if (!schedule.scheduleId) {
      createSchedule(schedule);
    } else {
      updateSchedule(schedule);
    }
  };

  const { mutate: onScheduleDelete, isLoading: isDeletingSchedule } = useMutation(
    (scheduleId: string) => {
      if (!scheduleId) {
        throw new Error(t('No id passed to schedule DELETE request'));
      }
      return ScheduleApi.deleteScheduleForDepartment({ departmentId: departmentId, scheduleId: scheduleId });
    },
    {
      onSuccess: () => {
        alerts.success(t('Break deleted successfully.'));
        queryClient.invalidateQueries([locationId, ...queryKeys.listDepartmentSchedules(departmentId)]);
        /// invalidate the queries used in the departments page
        queryClient.invalidateQueries([locationId, ...queryKeys.listDepartmentsWithSchedules()]);
      },
      onError: () => {
        alerts.error(t(`Schedule Update Failed`));
      },
    }
  );

  const isUpdating = isCreatingSchedule || isUpdatingSchedule || isDeletingSchedule;

  const showSchedule = useCallback(
    (scheduleName: string | string[]) => {
      const selectedSchedule = schedulesWithoutRouting?.find(
        (schedule) => schedule.name === scheduleName || schedule.scheduleId === scheduleName
      );
      setSelectedSchedule(selectedSchedule);
      if (selectedSchedule) {
        openModal();
      }
    },
    [schedulesWithoutRouting, setSelectedSchedule, openModal]
  );

  const onAddScheduleClick = useCallback(() => {
    setSelectedSchedule(undefined);
    openModal();
  }, [setSelectedSchedule, openModal]);

  const onEditScheduleClick = useCallback(() => {
    editBreakModal.openModal();
  }, [setSelectedSchedule, editBreakModal.openModal]);

  const handleCancel = () => {
    editBreakModal.closeModal();
  };

  const handleNext = () => {
    showSchedule(breakSelectProps.value);
    editBreakModal.closeModal();
  };

  useEffect(() => {
    breakSelectProps.onSelect('');
  }, [editBreakModal.modalProps.show]);

  return (
    <div>
      <Text
        weight='bold'
        css={css`
          margin-top: ${theme.spacing(2)};
        `}
      >
        {t('Choose the days and times you are open')}
      </Text>
      <div
        css={css`
          display: flex;
          margin-bottom: ${theme.spacing(2)};
          align-items: center;
        `}
      >
        <Text
          color='light'
          css={css`
            flex-grow: 1;
            &:before {
              content: '';
              display: inline-block;
              vertical-align: middle;
              width: 48px;
              height: 23px;
              background-color: #e9f8fc;
              margin-right: 10px;
              border: 1px solid ${theme.colors.primary50};
            }
          `}
        >
          {t('Open')}
        </Text>
        <ButtonBar
          css={css`
            width: 650px;
            justify-content: end;
          `}
        >
          <SecondaryButton
            {...triggerProps}
            onClick={() => {
              openHoursSchedule && showSchedule(openHoursSchedule.scheduleId);
            }}
          >
            {t('Edit Open Hours')}
          </SecondaryButton>
          <SecondaryButton
            {...editBreakModal.triggerProps}
            onClick={onEditScheduleClick}
            disabled={!breakSchedules?.length}
            trackingId='phone-portal-department-editBreaks-btn'
          >
            {t('Edit Breaks')}
          </SecondaryButton>
          <SecondaryButton {...triggerProps} onClick={onAddScheduleClick}>
            {t('Add Break')}
          </SecondaryButton>
        </ButtonBar>
      </div>
      <WeeklyScheduler
        timezone={officeTimezone}
        height={550}
        schedules={schedulesWithoutRouting}
        onScheduleClick={showSchedule}
      />
      <Modal {...modalProps} maxWidth={568}>
        <ScheduleModal
          initialSchedule={selectedSchedule}
          updating={isUpdating}
          otherSchedules={schedulesWithoutRouting?.filter((s) => s?.scheduleId !== selectedSchedule?.scheduleId)}
          onSave={onScheduleSave}
          deleteSchedule={onScheduleDelete}
          onClose={modalProps.onClose}
        />
      </Modal>
      <Modal {...editBreakModal.modalProps} maxWidth={568}>
        <Modal.Header>{t('Edit Breaks')}</Modal.Header>
        <div
          css={css`
            padding: ${theme.spacing(0, 3)};
          `}
        >
          <Text weight={'bold'}>{t('Select a Break to Edit')}</Text>
          <Text size='medium'>{t('Select which existing break you would like to edit.')}</Text>
        </div>
        <Modal.Body>
          <OfficeHoursViewBreaks
            listBoxProps={breakSelectProps}
            schedules={breakSchedules}
            handleNext={handleNext}
            handleCancel={handleCancel}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
