import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { isEqual } from 'lodash-es';
import { OfficeHoursApi } from '@frontend/api-office-hours';
import { ScheduleAvailabilityTypes } from '@frontend/api-schedule-availability';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  ConfirmationModal,
  PrimaryButton,
  SecondaryButton,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { ScheduleAvailability } from '../../components/ScheduleAvailability';
import { Timezone } from './Timezone';

type OfficeHoursSettingsProps = {
  locationId: string;
  officeHoursData: ScheduleAvailabilityTypes.Schedule | undefined;
  refetchOfficeHours: () => void;
  isLoading: boolean;
};

export const OfficeHoursSettings = ({
  locationId,
  officeHoursData,
  refetchOfficeHours,
  isLoading,
}: OfficeHoursSettingsProps) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule');
  const [schedule, setSchedule] = useState<ScheduleAvailabilityTypes.Schedule>();

  const { modalProps: cancelModalProps, triggerProps: cancelTriggerProps } = useModalControl();

  const { mutate: updateOfficeHours, isLoading: isUpdatingOfficeHours } = useMutation({
    mutationFn: ({ locationId, schedule }: { locationId: string; schedule: ScheduleAvailabilityTypes.Schedule }) => {
      return OfficeHoursApi.updateOfficeHours(locationId, schedule);
    },
    onSuccess: () => {
      alert.success(t('Successfully updated schedule'));
      refetchOfficeHours();
    },
    onError: () => {
      alert.error(t('Failed to update schedule'));
    },
  });

  const handleCancel = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.stopPropagation();
    cancelTriggerProps.onClick();
  };

  const handleConfirmCancel = () => {
    if (!isEqual(schedule, officeHoursData)) {
      setSchedule(officeHoursData);
    }
    refetchOfficeHours();
  };

  const handleSave = async () => {
    if (schedule) {
      updateOfficeHours({ locationId, schedule });
    }
  };

  useEffect(() => {
    if (officeHoursData) {
      setSchedule(officeHoursData);
    }
  }, [officeHoursData]);

  return (
    <>
      <Timezone locationId={locationId} />
      <ScheduleAvailability
        schedule={schedule || officeHoursData}
        updateSchedule={setSchedule}
        isLoading={isLoading || isUpdatingOfficeHours}
      />
      <ButtonBar css={buttonBarStyles}>
        <PrimaryButton onClick={handleSave}>{t('Save')}</PrimaryButton>
        <SecondaryButton onClick={(e) => handleCancel(e)}>{t('Cancel')}</SecondaryButton>
      </ButtonBar>
      <ConfirmationModal
        {...cancelModalProps}
        title={t('Unsaved Changes')}
        destructive
        message={t('Your changes will not be saved. Are you sure you want to discard your changes?')}
        confirmLabel={t('Discard')}
        cancelLabel={t('Keep Editing')}
        onCancel={cancelModalProps.onClose}
        onConfirm={handleConfirmCancel}
      />
    </>
  );
};

const buttonBarStyles = css({
  borderTop: `thin solid ${theme.colors.neutral20}`,
  borderLeftBottomRadius: theme.borderRadius.small,
  borderRightBottomRadius: theme.borderRadius.small,
  justifyContent: 'flex-start',
  '> button': { width: 'fit-content' },
});
