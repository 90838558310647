import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { CustomizationFlagQueries, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { SubscriptionsHooks, SubscriptionsQueries, SubscriptionsTypes } from '@frontend/api-subscription';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { ONBOARDER_SCHEDULING_URLS, SignUpPageSubscriptionCard } from '@frontend/product-subscription-sign-up-page';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { CheckIcon, Text } from '@frontend/design-system';
import { useShouldShowSubscribeCard } from '../../hooks';
import { trackingIds } from '../../tracking-ids';

type CheckedListItemProps = { value: string };

const CheckedListItem = ({ value }: CheckedListItemProps) => (
  <div css={styles.checkItem}>
    <CheckIcon />
    <Text css={{ marginLeft: theme.spacing(1) }}>{value}</Text>
  </div>
);

const CardContent = () => {
  const { t } = useTranslation('analytics');

  return (
    <>
      <CheckedListItem value={t('Understand the health of your practice')} />
      <CheckedListItem value={t('Identify patients slipping through the cracks')} />
      <CheckedListItem value={t('Increase practice production and revenue')} />
      <CheckedListItem value={t('Provide actionable data to team members')} />
    </>
  );
};

export const PracticeAnalyticsSubscribeCard = () => {
  const { t } = useTranslation('analytics');
  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const canManageAddOns = hasSchemaACL(selectedLocationIdsWithParents[0], Permission.MANAGE_ADD_ON_SUBSCRIPTIONS);
  const navigate = useNavigate();

  const { flags: customizationFlags, setFlags: setCustomizationFlags } = useCustomizationFlagShallowStore(
    'flags',
    'setFlags'
  );

  const { isFetching: isCustomizationFlagLoading, refetch: refetchCustomizationFlags } =
    CustomizationFlagQueries.useCustomizationFlagsQuery({
      onSuccess: setCustomizationFlags,
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
    });

  const shouldShowSubscriptionCard = useShouldShowSubscribeCard({
    flagDetails: customizationFlags?.practiceanalytics,
    verify: 'showSubscriptionCard',
  });

  const isUISubscriptionReleaseEnabled = SubscriptionsHooks.useIsUISubscriptionReleaseFlagEnabled();

  const { isSalesforceMultiLocation } = SubscriptionsQueries.useIsCSMManagedMultiLocation({
    enabled: !!getSelectedLocationData()[selectedLocationIdsWithParents[0]]?.slug && isUISubscriptionReleaseEnabled,
  });

  const canSelfSubscribe = !isSalesforceMultiLocation;

  const handleSuccessSubscribeCloseClick = () => {
    // Refetch the customization flags again which should now include the newly enabled
    // one for the product that was subscribe to. This will cause the subscription panel
    // to no longer show.
    refetchCustomizationFlags();
    navigate({ to: '/analytics/practice' });
  };

  return shouldShowSubscriptionCard ? (
    <>
      <SignUpPageSubscriptionCard
        productServiceName={t('Practice analytics')}
        css={{ marginTop: theme.spacing(3) }}
        isSubscriptionButtonDisabled={!canManageAddOns}
        pricingValue={t('$199')}
        subscriptionTerm='monthly'
        renderSubscriptionCardContent={<CardContent />}
        onboarderSchedulingUrl={ONBOARDER_SCHEDULING_URLS.PRACTICE_ANALYTICS ?? ''}
        canSelfSubscribe={canSelfSubscribe}
        subscriptionButtonTrackingId={trackingIds.subscriptions.selfSubscribePA}
        title={t('Practice Analytics Can Help You')}
        feature={SubscriptionsTypes.Feature.PRACTICE_ANALYTICS}
        isLoading={isCustomizationFlagLoading}
        onSubscribeSuccessCloseClick={handleSuccessSubscribeCloseClick}
      />
    </>
  ) : null;
};

const styles = {
  checkItem: css`
    align-items: center;
    display: flex;
    margin-top: ${theme.spacing(1)};
  `,
};
