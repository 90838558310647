import { ErrorCode } from 'react-dropzone';
import { DigitalFormsTypes } from '@frontend/api-digital-forms';

export enum Category {
  PACKETS = 'PACKETS',
  FORMS = 'FORMS',
}

export type FormOrPacket =
  | Exclude<DigitalFormsTypes.GetFormListIO['output']['data'], undefined>[number]
  | Exclude<DigitalFormsTypes.GetPacketListIO['output']['data'], undefined>[number];

export type FormV2 = Exclude<DigitalFormsTypes.GetFormListV2IO['output']['data'], undefined>[number];
export type PacketV2 = Exclude<DigitalFormsTypes.GetPacketListV2IO['output']['data'], undefined>[number];
export type FormOrPacketV2 = FormV2 | PacketV2;

interface CommonDocumentProps {
  id: string;
  name: string;
  reviewRequired: boolean;
  isNonEditable: boolean;
}

interface FormDocument extends CommonDocumentProps {
  type: Category.FORMS;
}

interface PacketDocument extends CommonDocumentProps {
  type: Category.PACKETS;
  formIds: string[];
}

export type FormOrPacketToSend = FormDocument | PacketDocument;

export interface MinimalPersonData {
  id: string;
  firstName: string;
  lastName: string;
}

export interface Attachment {
  id: string;
  mediaUploadId: string;
  file: File;
  isUploading: boolean;
  isUploaded: boolean;
  eSignRequired: boolean;
  hasUploadError: boolean;
}

export enum AttachmentErrorCodes {
  FILE_INVALID_TYPE = ErrorCode.FileInvalidType,
  FILE_TOO_LARGE = ErrorCode.FileTooLarge,
  FILE_TOO_SMALL = ErrorCode.FileTooSmall,
  TOO_MANY_FILES = ErrorCode.TooManyFiles,
  UPLOAD_FAILED = 'upload_failed',
}
