import { Chip, Modal, ModalControlModalProps, Text, TextLink } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { FC } from 'react';
import { SelfUpgradeModalTrackingIds } from '../../../constants/tracking-ids';

interface SelfUpgradeFailModalProps extends ModalControlModalProps {
  partialFailedLocationIds: string[];
}

export const SelfUpgradeFailModal: FC<SelfUpgradeFailModalProps> = ({
  partialFailedLocationIds = [],
  ...modalProps
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'self-upgrade-fail-modal' });
  const { accessibleLocationData } = useAppScopeStore();

  const locationCount = partialFailedLocationIds.length;

  return (
    <Modal {...modalProps} maxWidth={600}>
      <Modal.Header onClose={modalProps.onClose} closeBtnTrackingId={SelfUpgradeModalTrackingIds.failModalCloseAction}>
        {locationCount > 0 ? t('Locations Upgrade Failed') : t('Contact Support')}
      </Modal.Header>
      <Modal.Body
        css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), padding: theme.spacing(3, 0) }}
      >
        {locationCount > 0 && (
          <>
            <Text>{t('{{count}} location failed to upgrade', { count: locationCount })}</Text>
            <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
              {partialFailedLocationIds.map((locationId) => (
                <Chip.SingleChip key={locationId} css={{ maxWidth: theme.spacing(30) }}>
                  {accessibleLocationData[locationId].name}
                </Chip.SingleChip>
              ))}
            </div>
          </>
        )}
        <Text>
          {t(
            'Call our support department for questions about any of our plans. We are available Monday thru Friday 8am - 5pm MST.'
          )}
        </Text>
        <TextLink href='tel:+18885795668' textAlign='left'>
          {t('+1 (888) 579-5668')}
        </TextLink>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Close')}
        primaryTrackingId={SelfUpgradeModalTrackingIds.failModalCloseAction}
        onPrimaryClick={modalProps.onClose}
      />
    </Modal>
  );
};
