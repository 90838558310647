import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { MediaTypes } from '@frontend/api-media';
import {
  InboxQueries,
  MMS_MAX_SIZE,
  MessagesHooks,
  SchemaSMSSharedEnums,
  SchemaSMSSharedModels,
} from '@frontend/api-messaging';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { SchemaSMSService } from '@frontend/schema';
import { sentry } from '@frontend/tracking';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import {
  ContextMenuActions as DSContextMenuActions,
  CopyIcon,
  DownloadIcon,
  LabelIcon,
  ModalControlResponse,
  TrashIcon,
  UpdateIcon,
  useAlert,
} from '@frontend/design-system';
import { HIDE_DELETE_MODAL } from '../constants';
import { LocalSendingStatus, SentMessage } from '../types';
import { MediaInteractionUtils } from '../utils';

type ContextMenuActions = (Omit<DSContextMenuActions[number], 'label'> & {
  label: string;
})[];

const sortContextMenuActions = (labelOrder: string[], actions: ContextMenuActions): DSContextMenuActions =>
  actions.sort((a, b) => {
    const aLabelIndex = labelOrder.findIndex((label) => label === a.label);
    const bLabelIndex = labelOrder.findIndex((label) => label === b.label);
    return aLabelIndex > bLabelIndex ? 1 : -1;
  });

type Props = {
  message: SentMessage;
  modalControl: ModalControlResponse;
  groupId: string;
  isDeleted: boolean;
  taggedSmsId?: string;
  onTagMessage: () => void;
};

type BubbleActions = {
  globalActions: DSContextMenuActions;
  mediaActions: (mediaItem: Partial<SchemaSMSSharedModels.Media>) => DSContextMenuActions;
  messageActions: DSContextMenuActions;
  handleSmsDelete: () => void;
};

export const useBubbleActions = ({
  message,
  modalControl,
  groupId,
  isDeleted,
  onTagMessage,
  taggedSmsId,
}: Props): BubbleActions => {
  const { t } = useTranslation('inbox');
  const queryClient = useQueryClient();
  const alert = useAlert();
  const user = getUser();
  const { clearFiles } = MessagesHooks.useMediaUploader({
    maxFileSize: MMS_MAX_SIZE,
    onExceedMaxFiles: () => {
      alert.error({ message: t('You can only attach up to 10 images at a time.') });
    },
  });
  const smsId = message.id;

  const [files, setFiles] = useState<MediaTypes.MediaUploadFile[]>([]);
  const { remove } = MessagesHooks.useUpdateThread();

  const labelOrder: string[] = [
    t('Copy Message'),
    t('Copy Image'),
    t('Tag Message'),
    t('Download Image'),
    t('Retry Message Send'),
    t('Retry Image Send'),
    t('Delete Message from Thread'),
  ];

  const handleSmsDelete = async () => {
    try {
      const deleteRequest = {
        smsId,
        locationId: groupId,
        userId: user?.userID ?? '',
      };
      await SchemaSMSService.DeleteSMS(deleteRequest);
      remove({ threadId: message.threadId ?? '', locationId: groupId, smsId, taggedSmsId, userId: user?.userID ?? '' });
      alert.success(t('Message deleted'));
    } catch {
      alert.error(t('Error deleting message'));
    }
  };

  const copySms = () => {
    navigator.clipboard.writeText(message?.body ?? '');
    alert.success(t('Message Copied'));
  };

  const { isLoading, mutate: sendMessage } = MessagesHooks.useSendMessageMutation(
    {
      locationId: groupId,
    },
    {
      onSuccess: () => {
        clearFiles();
      },
      onError: () => {
        alert.error(t('Error resending message'));
      },
    }
  );
  const hasRetry =
    !isLoading &&
    (message.status === SchemaSMSSharedEnums.Status.STATUS_ERROR ||
      message.status === SchemaSMSSharedEnums.Status.STATUS_NOT_SENT);

  const retrySend = async (mediaItem?: Partial<SchemaSMSSharedModels.Media>) => {
    if (!groupId) {
      alert.error(t('Error resending message.'));
      return;
    }
    const mediaId = mediaItem?.mediaId ?? '';
    if (mediaItem) {
      const blob = await MediaInteractionUtils.generateBlob(mediaId);
      if (!blob) return;
      const imageName = MediaInteractionUtils.getImageName({ id: mediaId, filename: mediaItem?.filename ?? '' });
      const file = new File([blob], imageName, { type: blob.type });
      setFiles((prev) => [
        ...prev,
        {
          file,
          uploaded: true,
          mediaId: mediaId,
          previewUrl: window.URL.createObjectURL(blob),
        },
      ]);
    }
    const deleteRequest = {
      smsId,
      locationId: groupId,
      userId: user?.userID ?? '',
    };
    await SchemaSMSService.DeleteSMS(deleteRequest);
    queryClient.invalidateQueries({
      queryKey: InboxQueries.queryKeys.getThread({
        locationId: message.locationId ?? '',
        threadId: message.threadId ?? '',
        taggedSmsId,
      }),
    });
    sendMessage({
      body: message.body ?? '',
      media: files.flatMap(({ mediaId }) => (mediaId ? [{ mediaId }] : [])),
      createdBy: user?.userID ?? '',
      departmentId: message.departmentId,
      locationId: groupId,
      personPhone: message.personPhone ?? '',
      locationPhone: message.locationPhone,
      programSlugId: message.programSlugId ?? '',
      personId: message.personId ?? '',
      excludeSignature: true,
      threadId: message?.threadId ?? '',
      targetSmsId: taggedSmsId,
    });
  };

  const globalActions: ContextMenuActions = [
    !isDeleted && {
      label: t('Copy Message'),
      Icon: CopyIcon,
      onClick: () => copySms(),
      trackingId: `${InboxPrefixes.Thread}-message-action-copy`,
    },
    {
      label: t('Tag Message'),
      Icon: LabelIcon,
      onClick: () => onTagMessage?.(),
      trackingId: `${InboxPrefixes.Tag}-tag-message`,
    },
    message.localSendingStatus !== LocalSendingStatus.SENDING &&
      !isDeleted && {
        label: t('Delete Message from Thread'),
        Icon: TrashIcon,
        onClick: () => {
          const showDeleteModal = localStorage.getItem(HIDE_DELETE_MODAL) !== 'true';
          if (showDeleteModal) {
            modalControl.openModal();
          } else {
            handleSmsDelete();
          }
        },
        trackingId: `${InboxPrefixes.Thread}-message-action-delete`,
      },
  ].filter(Boolean);

  const messageActions: ContextMenuActions = [
    ...globalActions,
    ...(hasRetry
      ? [
          {
            label: t('Retry Message Send'),
            Icon: UpdateIcon,
            onClick: () => retrySend(),
            trackingId: `${InboxPrefixes.Thread}-message-action-retry`,
          },
        ]
      : []),
  ];

  const mediaActions = (mediaItem: Partial<SchemaSMSSharedModels.Media>): DSContextMenuActions =>
    sortContextMenuActions(labelOrder, [
      ...globalActions,
      {
        label: t('Copy Image'),
        Icon: CopyIcon,
        onClick: () => {
          try {
            MediaInteractionUtils.copyImage(mediaItem.mediaId!);
            alert.success(t('Image Copied'));
          } catch (err) {
            alert.error(t('Error copying image'));
            sentry.error({
              error: err,
              topic: 'messages',
              severityLevel: 'error',
              addContext: {
                name: 'Message object & media item',
                context: {
                  message,
                  mediaItem,
                  errMessage: 'Error copying image from message',
                },
              },
            });
          }
        },
        disabled: !mediaItem.mediaId,
        trackingId: `${InboxPrefixes.Thread}-message-action-copy-image`,
      },
      {
        label: t('Download Image'),
        Icon: DownloadIcon,
        onClick: () => {
          try {
            MediaInteractionUtils.downloadImage({
              mediaId: mediaItem.mediaId!,
              filename: mediaItem.filename ?? `${message.personPhone}-${t('image-download')}`,
            });
            alert.success(t('Image downloaded'));
          } catch (err) {
            alert.error(t('Error downloading image'));
            sentry.error({
              error: err,
              topic: 'messages',
              severityLevel: 'error',
              addContext: {
                name: 'Message object & media item',
                context: {
                  message,
                  mediaItem,
                  errMessage: 'Error downloading image from message',
                },
              },
            });
          }
        },
        disabled: !mediaItem.mediaId,
        trackingId: `${InboxPrefixes.Thread}-message-action-download-image`,
      },
      ...(hasRetry
        ? [
            {
              label: t('Retry Image Send'),
              onClick: () => retrySend(mediaItem),
              trackingId: `${InboxPrefixes.Thread}-message-action-retry`,
            },
          ]
        : []),
    ]);

  return {
    globalActions: sortContextMenuActions(labelOrder, globalActions),
    mediaActions,
    messageActions: sortContextMenuActions(labelOrder, messageActions),
    handleSmsDelete,
  };
};
