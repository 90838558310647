import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { createShallowStore, createStore } from '@frontend/store';

type AppointmentsStoreType = {
  filteredAppointmentList: Appointment[] | [];
  setFilteredAppointmentList: (filteredAppointmentListData: Appointment[]) => void;
};

export const useFilteredAppointmentListStore = createStore<AppointmentsStoreType>(
  (set) => ({
    filteredAppointmentList: [],
    setFilteredAppointmentList: (filteredAppointmentListData: Appointment[] | []) =>
      set({ filteredAppointmentList: filteredAppointmentListData }),
  }),
  { name: 'filteredAppointmentList' }
);

export const useFilteredAppointmentListInfoShallowStore = createShallowStore<AppointmentsStoreType>(
  useFilteredAppointmentListStore
);
