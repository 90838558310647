import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { text } from '@storybook/addon-knobs';
import { VoicemailBox } from '@weave/schema-gen-ts/dist/schemas/phone-exp/voicemail/mailbox.pb';
import { MailBoxType } from '@weave/schema-gen-ts/dist/schemas/phone-exp/voicemail/voicemail.pb';
import { VoicemailApi } from '@frontend/api-voicemails';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import {
  Modal,
  Text,
  ModalControlModalProps,
  useControlledField,
  DropdownField,
  CheckboxField,
  useAlert,
  NumberField,
} from '@frontend/design-system';
import { CachedAudioScrubber } from '../all-calls/cached-audio-scrubber';
import { ForwardVoicemailModalData } from './voicemails-multi.component';

type Props = {
  modalProps: ModalControlModalProps;
  forwardData: ForwardVoicemailModalData;
};

const otherDestMBBox = 'other';

const getMailboxDetails = (mailbox: VoicemailBox) => {
  const { mailbox: mailboxData, mailboxType, deptInfo } = mailbox;
  let vmboxName = '';
  if (!!mailboxData?.id) {
    if (mailboxType === MailBoxType.MAILBOX_TYPE_DEPARTMENT) {
      vmboxName = deptInfo?.isMainline ? 'Main Line' : deptInfo?.departmentName ?? '--';
    } else {
      vmboxName = mailboxData.name ?? '--';
    }
  }
  return {
    name: vmboxName,
    id: mailboxData?.id,
  };
};

const getDropdownListOption = ({
  mailbox,
  personalMailboxId,
  currentMailBoxId,
}: {
  mailbox: VoicemailBox;
  personalMailboxId: string;
  currentMailBoxId: string;
}) => {
  const { id, name } = getMailboxDetails(mailbox);
  if (id === personalMailboxId) {
    return null;
  }
  return (
    <DropdownField.Option key={id ?? ''} value={id ?? ''} disabled={currentMailBoxId == id}>
      {name}
    </DropdownField.Option>
  );
};

export const Forwardvoicemail = ({ modalProps, forwardData }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation('calls', { keyPrefix: 'voicemails' });

  const { selectedLocationIds, getLocationName } = useAppScopeStore();

  const personalMailboxData =
    forwardData.voicemailBoxList
      ?.flatMap(({ voicemailBoxes }) => voicemailBoxes ?? [])
      .find(({ mailboxType }) => mailboxType === 'MAILBOX_TYPE_PERSONAL') ?? null;

  const otherMailboxList = forwardData.voicemailBoxList?.filter((box) => box.locationId !== 'PersonalMailBox');

  const [selectedMailboxIdToForward, setSelectedMailboxIdToForward] = useState<string>('');
  const [forwardMailboxExtn, setForwardMailboxExtn] = useState<string>('');
  const [deleteAfterForward, setDeleteAfterForward] = useState<boolean>(false);

  useEffect(() => {
    // reset input modal input data whenever the voicemail id is changed
    setSelectedMailboxIdToForward('');
    setDeleteAfterForward(false);
  }, [forwardData.vmId]);

  const forwardVoicemailBoxDropdownProps = useControlledField({
    type: 'dropdown',
    required: false,
    onChange: (value: string) => {
      setSelectedMailboxIdToForward(value);
    },
    value: selectedMailboxIdToForward,
  });

  const forwardVoicemailBoxExtentionNumber = useControlledField({
    type: 'number',
    required: false,
    allowDecimal: false,
    allowNegative: false,
    includeThousandsSeparator: false,
    value: forwardMailboxExtn,
    onChange: (value) => {
      setForwardMailboxExtn(value);
    },
  });

  const deleteCurrentVoicemailProps = useControlledField({
    onChange: (value) => {
      setDeleteAfterForward(value);
    },
    type: 'checkbox',
    value: deleteAfterForward,
  });

  const forwardVoicemail = async (
    voicemailId: string,
    fromMailboxId: string,
    toMailboxId: string,
    rowIndex: number
  ) => {
    try {
      if (toMailboxId == otherDestMBBox) {
        toMailboxId = '';
      }
      await VoicemailApi.forwardVoicemail({
        voicemailMessageId: voicemailId,
        originalVoicemailBoxId: fromMailboxId,
        destinationVoicemailBoxId: toMailboxId,
        destinationVoicemailBoxNumber: forwardMailboxExtn,
      });
      alert.success(t('VoicemailForwardedSuccess'));
      if (deleteAfterForward) {
        await forwardData.onDelete({ voicemailId: voicemailId, index: rowIndex });
      }
    } catch (error) {
      alert.error(t('VoicemailForwardError'));
    }
  };

  return (
    <>
      <Modal {...modalProps} maxWidth={900}>
        <Modal.Header>Forward Voicemail</Modal.Header>
        <Modal.Body>
          <Text
            css={css`
              margin-top: 0;
            `}
          >
            Choose which voicemail box you want to forward this voicemail to
          </Text>
          <br />
          <div css={{ display: 'flex', alignItems: 'flex-start', boxSizing: 'border-box', gap: '20px' }}>
            <div css={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: '10px' }}>
              <span css={{ fontSize: '12px', color: 'grey' }}>Voicemail</span>
              <CachedAudioScrubber filePath={forwardData.mediaPath} mediaId={forwardData.mediaId} />
            </div>
            <div css={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: '18px' }}>
              <span css={{ fontSize: '12px', color: 'grey' }}>Current Voicemail Box</span>
              <span>{forwardData.currentVoicemailBoxName}</span>
            </div>
          </div>
          <br />
          <div style={{ width: '350px' }}>
            <DropdownField
              {...forwardVoicemailBoxDropdownProps}
              label={t('Destination voicemail box')}
              name='dest-vm-box'
            >
              {!!personalMailboxData && (
                <DropdownField.Option
                  value={personalMailboxData?.mailbox?.id ?? ''}
                  disabled={forwardData.currentVoicemailBoxId == personalMailboxData?.mailbox?.id}
                >
                  {personalMailboxData?.mailbox?.name ?? ''}
                </DropdownField.Option>
              )}

              {selectedLocationIds.length === 1 &&
                otherMailboxList?.[0]?.voicemailBoxes?.map((mailbox) =>
                  getDropdownListOption({
                    mailbox,
                    personalMailboxId: personalMailboxData?.mailbox?.id ?? '',
                    currentMailBoxId: forwardData.currentVoicemailBoxId,
                  })
                )}

              {selectedLocationIds.length > 1 &&
                otherMailboxList?.map((locationData) => {
                  const { locationId, voicemailBoxes } = locationData!;
                  const sortedVoicemailBoxes = voicemailBoxes?.filter(
                    (box) => box?.mailbox?.id !== personalMailboxData?.mailbox?.id
                  );
                  return (
                    <DropdownField.OptionGroup label={locationId ? getLocationName(locationId) : ''}>
                      {sortedVoicemailBoxes?.map((mailbox) =>
                        getDropdownListOption({
                          mailbox,
                          personalMailboxId: personalMailboxData?.mailbox?.id ?? '',
                          currentMailBoxId: forwardData.currentVoicemailBoxId,
                        })
                      )}
                    </DropdownField.OptionGroup>
                  );
                })}
              <DropdownField.Option css={{ fontWeight: 'bold' }} key={otherDestMBBox} value={otherDestMBBox}>
                {'other (specify extension)'}
              </DropdownField.Option>
            </DropdownField>
          </div>
          {selectedMailboxIdToForward == otherDestMBBox && (
            <div style={{ width: '350px' }}>
              <br />
              <NumberField
                {...forwardVoicemailBoxExtentionNumber}
                label='Enter the extension number'
                name='extn-number'
              />
            </div>
          )}

          <br />
          <CheckboxField
            {...deleteCurrentVoicemailProps}
            name='delete'
            label='Remove voicemail from current Voicemail Box after forwarding'
          />
          <br />
        </Modal.Body>
        <Modal.Actions
          onSecondaryClick={() => {
            return true;
          }}
          onPrimaryClick={() => {
            forwardVoicemail(
              forwardData.vmId,
              forwardData.currentVoicemailBoxId,
              selectedMailboxIdToForward ?? '',
              forwardData.rowIndex
            );
            return true;
          }}
          disablePrimary={
            selectedMailboxIdToForward == otherDestMBBox ? forwardMailboxExtn == '' : selectedMailboxIdToForward == ''
          }
          primaryLabel={text('primaryLabel', 'Forward')}
          primaryTrackingId='modal-forward-voicemail'
          secondaryLabel={text('secondaryLabel', 'Cancel')}
        />
      </Modal>
    </>
  );
};
