import { FC } from 'react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, TextSize, IconButton } from '@frontend/design-system';
import { iconButtonStyle } from './add-button.styles';

interface AddButtonProps {
  title: string;
  onClick: () => void;
  className?: string;
  size?: number;
  textSize?: TextSize;
  style?: Record<string, string>;
}

export const AddButton: FC<AddButtonProps> = ({ title, onClick, className, size = 24, textSize = 'medium', style }) => {
  return (
    <IconButton
      onClick={onClick}
      css={iconButtonStyle}
      className={className}
      showLabelAlways
      label={
        <Text weight='bold' size={textSize}>
          {title}
        </Text>
      }
      style={style}
    >
      <Icon name='plus' fill={theme.colors.neutral90} size={size} />
    </IconButton>
  );
};
