import { useMemo } from 'react';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Accordion, NotificationBadge } from '@frontend/design-system';
import { useOnlineSchedulingMultiLocationCustomizationFlag } from '../../../../../context/OnlineSchedulingMultiLocationCustomizationFlagContext';
import { useScheduleActionsContext } from '../ScheduleActionsContext';
import { ScheduleRequestList } from './ScheduleRequestList';
import { ScheduleRequestsTrackingIds } from './trackingIds';

export const ScheduleRequestTabContent = () => {
  const { getLocationName, selectedLocationIds } = useAppScopeStore();

  const { scheduleRequestsCountByLocation } = useScheduleActionsContext();

  const { locationIdWiseCustomizationFlagDetails } = useOnlineSchedulingMultiLocationCustomizationFlag();

  const scheduleRequestCountByLocationMap: { [k: string]: number | string } = useMemo(() => {
    return (
      scheduleRequestsCountByLocation?.reduce((acc, { locationId, total }) => {
        if (locationId) {
          return {
            ...acc,
            [locationId]: total,
          };
        }
        return acc;
      }, {}) ?? {}
    );
  }, [scheduleRequestsCountByLocation]);

  if (selectedLocationIds.length === 1) {
    return <ScheduleRequestList height={592} locationId={selectedLocationIds[0]} />;
  }
  return (
    <Accordion variant='location' showBoxShadow chevronSize={16} startingValue='first' size='large'>
      {selectedLocationIds.map((locationId: string) => {
        const count = +scheduleRequestCountByLocationMap[locationId];
        const locationHasAccessToOnlineScheduling = !!locationIdWiseCustomizationFlagDetails?.[locationId];

        if (!locationHasAccessToOnlineScheduling) {
          return null;
        }

        return (
          <Accordion.Item
            trackingId={ScheduleRequestsTrackingIds.multiLocationAccordionItem}
            value={locationId}
            key={locationId}
          >
            <Accordion.Header css={{ height: '52px' }}>
              <Accordion.Header.Location title={getLocationName(locationId) ?? 'Default'} />
              <div css={{ marginLeft: 'auto' }}>
                {!!count && (
                  <NotificationBadge css={{ backgroundColor: theme.colors.primary50 }}>{count}</NotificationBadge>
                )}
              </div>
            </Accordion.Header>
            <Accordion.Body css={{ padding: 0 }}>
              <ScheduleRequestList height={330} locationId={locationId} />
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};
