import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const container = css`
  position: relative;
`;

export const errorStyle = css`
  position: absolute;
  top: 100%;
  left: 180px;
  @media (max-width: 699px) {
    top: calc(100% - 4px);
    left: 0px;
  }
`;

export const scheduleWeek = css`
  .schedule-day:not(:first-of-type) {
    border-top: ${theme.colors.neutral30} thin solid;
  }
`;

export const formStyles = css({
  marginBottom: theme.spacing(2),
  flexGrow: 1,
});

export default css`
  width: 720px;
  & .schedule-week {
    & .schedule-day:not(:first-of-type) {
      border-top: ${theme.colors.neutral30} thin solid;
    }
    & .schedule-day {
      margin: ${theme.spacing(2.5, 0)};
      padding: ${theme.spacing(2.5, 0, 0, 2)};
      & .schedule-day-title {
        & > div {
          transform: translateY(6px);
        }
        & label {
          margin: ${theme.spacing(0.5, 0, 0, 1.25)};
          transform: translateY(4px);
          font-size: ${theme.fontSize(18)};
          font-weight: 600;
        }
      }
      & .schedule-day-time-range {
        display: flex;
        margin-bottom: ${theme.spacing(3)};
        & .schedule-day-time-range-fields {
          display: flex;
        }
        & .add-break {
          margin: ${theme.spacing(1.25, 0, 0, 2)};
          cursor: pointer;
          font-size: ${theme.fontSize(16)};
          outline: none;
        }
        & .schedule-day-title {
          min-width: 180px;
          text-transform: capitalize;
        }
      }
      & .schedule-day-break {
        display: flex;
        margin: ${theme.spacing(4, 0)};
        & label.break-label {
          opacity: 0.6;
          display: flex;
          min-width: 180px;
          font-size: ${theme.fontSize(16)};
          transform: translateY(6px);
          padding-left: 50px;
          margin: auto 0;
          svg {
            transform: scale(0.8) translate(-8px, -5px);
          }
        }
        & .delete-break {
          opacity: 0.6;
          transform: translate(12px, 4px);
        }
      }
    }
  }

  @media (max-width: 699px) {
    width: 100%;
    & .schedule-week {
      & .schedule-day {
        padding: ${theme.spacing(2, 0, 0, 0)};
        & .schedule-day-time-range {
          display: flex;
          flex-direction: column;
          align-items: center;
          & .schedule-day-title {
            margin-bottom: ${theme.spacing(3)};
            justify-content: center;
          }
          & .add-break {
            margin: 0;
          }
        }
        & .schedule-day-breaks {
          display: flex;
          flex-direction: column;
          align-items: center;
          & > label {
            opacity: 0.6;
            text-transform: lowercase;
            font-style: italic;
          }
        }
        & .schedule-day-break {
          margin: ${theme.spacing(1.5, 0)};
          & .delete-break {
            transform: translate(0, 3px);
            position: absolute;
            right: ${theme.spacing(-4)};
          }
        }
        & .schedule-day-break label.break-label {
          min-width: initial;
          padding-left: ${theme.spacing(1)};
        }
        & .schedule-day-time-range-fields > div > span {
          display: none;
        }

        & .schedule-day-break > div > span {
          display: none;
        }
      }
    }
  }
`;
