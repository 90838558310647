import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { GetUsersInLocationsResponse, StatusType } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { UsageType } from '@weave/schema-gen-ts/dist/shared/phonedata/v1/phone_number.pb';
import dayjs from 'dayjs';
import { LiteApi } from '@frontend/api';
import {
  AnalyticsCommonTypes,
  CallIntelMockData,
  CallIntelligenceApi,
  CallIntelligenceTypes,
} from '@frontend/api-analytics';
import { UsersQueries } from '@frontend/api-users';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useScopedInfiniteQuery, useScopedQuery } from '@frontend/scope';
import { useContactPanelStore, useHasFeatureFlag } from '@frontend/shared';
import { useSlidePanelStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  Chip,
  DocumentErrorIcon,
  DocumentFilledIcon,
  IconButton,
  ListsIcon,
  SortingValue,
  PhoneOutgoingIcon,
  SpinningLoader,
  Table,
  TableColumnConfig,
  TableLoadingSkeleton,
  Text,
  DocumentWarningIcon,
  DocumentUndeterminedIcon,
  useAlert,
  TextLink,
} from '@frontend/design-system';
import { CallIntelFilters, CallIntelInfoTips, OfficeUserName } from '..';
import { Accept, ColumnHeaderInfoTip, LocationChip, Thumb, UserCard } from '../..';
import { featureFlags } from '../../../feature-flags';
import { useCallIntelLocations, useCallIntelStore, useCallTakeawayPanelStore } from '../../../hooks';
import { queryKeys } from '../../../query-keys';
import { trackingIds } from '../../../tracking-ids';
import { callIntelligenceUtils } from '../../../utils';
import { CallIntelSearch } from '../call-intel-search-field';

// Constants
const MIN_SEARCH_LENGTH = 3;

// Helper functions
const commonCellRenderer = (status?: CallIntelligenceTypes.CallStatusEnum) => {
  if (callIntelligenceUtils.isCallInProcessing(status)) {
    return <TableLoadingSkeleton />;
  }

  if (callIntelligenceUtils.isFailedCall(status) || callIntelligenceUtils.isSkippedCall(status)) {
    return ' '; // Need to render nothing, but need white space just for a check
  }

  return null;
};

const cellStyler = (status?: CallIntelligenceTypes.CallStatusEnum) => {
  if (callIntelligenceUtils.isFailedCall(status)) {
    return css`
      background: ${theme.colors.critical5};
    `;
  }

  if (callIntelligenceUtils.isSkippedCall(status)) {
    return css`
      background: ${theme.colors.neutral10};
    `;
  }

  return;
};

const getTrayIcon = (call?: CallIntelligenceTypes.Call, isDemoAccount?: boolean) => {
  if (!call?.status) {
    return;
  }

  const { status } = call;

  if (callIntelligenceUtils.isCallInProcessing(status)) {
    return <SpinningLoader size='small' style={{ marginLeft: theme.spacing(-0.5) }} />;
  }

  if (callIntelligenceUtils.isFailedCall(status)) {
    return <DocumentErrorIcon color='error' />;
  }

  if (callIntelligenceUtils.isSkippedCall(status)) {
    return <DocumentUndeterminedIcon color='subdued' />;
  }

  if (!isDemoAccount && callIntelligenceUtils.isPoorAudio(call)) {
    return <DocumentWarningIcon color='warn' />;
  }

  return (
    <span>
      <DocumentFilledIcon color='primary' />
    </span>
  );
};

type CallRecordsTableProps = {
  drillDownFilters?: CallIntelligenceTypes.DrillDownOptions;
  isDemoAccount?: boolean;
  marginTop?: number;
  showOnlyUnscheduled?: boolean;
};

export const CallRecordsTable = ({
  drillDownFilters,
  isDemoAccount,
  marginTop = 40,
  showOnlyUnscheduled,
}: CallRecordsTableProps) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const isSearchEnabled = useHasFeatureFlag(featureFlags.enableCallIntelSearch);
  const isFollowUpsEnabled = useHasFeatureFlag(featureFlags.enableCallIntelFollowUps);

  const { chipVariants, dataLabels, filters, setFilters } = useCallIntelStore();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<CallIntelligenceTypes.SearchState>({ query: '', value: '' });

  const { isMultiLocation, locations } = useCallIntelLocations({
    demoLocations: isDemoAccount ? CallIntelMockData.dummyLocationNames() : undefined,
  });
  const { allCalls, selectedCall, setAllCalls, setIsDemoAccount, setSelectedCall, setSelectedCallMetaData } =
    useCallTakeawayPanelStore();
  const { personId, setPersonId } = useContactPanelStore();
  const { setShow } = useSlidePanelStore();
  const personIdRef = useRef<string>(personId);
  const selectedCallRef = useRef<CallIntelligenceTypes.Call | null>(selectedCall);

  const [pageConfig, setPageConfig] = useState<AnalyticsCommonTypes.PageConfig>({
    pageNumber: 1,
    pageSize: 10,
  });

  const [sortType, setSortType] = useState<CallIntelligenceTypes.SortTypeEnum>(
    CallIntelligenceTypes.SortTypeEnum.SORT_DESC
  );

  const demoCallRecords = useMemo(() => {
    const { key, value } = drillDownFilters || {};

    if (key === 'appointment_types') {
      return CallIntelMockData.callRecordings({
        count: 10,
        appointmentType: value?.[0] as CallIntelligenceTypes.AppointmentTypeEnum,
        showOnlyUnscheduled,
      });
    } else if (key === 'categories') {
      return CallIntelMockData.callRecordings({
        count: 10,
        category: value?.[0] as CallIntelligenceTypes.CategoryEnum,
        showOnlyUnscheduled,
      });
    } else if (key === 'sentiments') {
      return CallIntelMockData.callRecordings({
        count: 10,
        sentiment: value?.[0] as CallIntelligenceTypes.SentimentEnum,
        showOnlyUnscheduled,
      });
    } else {
      return CallIntelMockData.callRecordings({
        count: 10,
        showOnlyUnscheduled,
      });
    }
  }, [isDemoAccount]);

  const filteredDemoCallRecords = useMemo(() => {
    return CallIntelMockData.getFilteredCallRecordings(demoCallRecords, filters);
  }, [demoCallRecords, filters]);

  const { data: officeNumbers } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(
      `office-numbers-${isDemoAccount}-${isMounted}-${JSON.stringify(filters.locations)}`
    ),
    queryFn: () =>
      isDemoAccount || !isMounted || !filters.locations?.length
        ? null
        : LiteApi.getPhoneNumbers(filters.locations.join('&location_ids=')),
    onError: (error) => {
      console.error(`Failed to get the phone numbers, ${error}`);
    },
    refetchOnWindowFocus: false,
    retry: false,
    select: (data) => {
      if (isDemoAccount) {
        return CallIntelMockData.phoneNumbers;
      }

      // Only return phone numbers for voice and sms and non e911 usage type

      return (
        data?.phoneNumbers
          .filter(
            ({ number }) =>
              (number.voiceProvider || number.smsProvider) && number.phoneNumber.usageType !== UsageType.USAGE_TYPE_E911
          )
          .map(({ number }) => number.phoneNumber.phoneNumber.nationalNumber) || []
      );
    },
    staleTime: 1000 * 60 * 5,
  });

  const { data: officeUsers } = UsersQueries.useGetUsersInLocations(
    !filters.locations || filters.locations?.[0]?.includes('demo') ? [] : filters.locations
  );

  const activeOfficeUsers = useMemo(() => {
    const users = isDemoAccount ? CallIntelMockData.officeUsers : (officeUsers as GetUsersInLocationsResponse)?.users;

    return (
      users
        ?.filter(({ status }) => !status || status === StatusType.ACTIVE)
        .reduce((acc, { firstName = '', lastName = '', userId = '' }) => {
          if (userId) {
            acc[userId] = `${firstName} ${lastName}`.trim();
          }
          return acc;
        }, {} as AnalyticsCommonTypes.StringRecord) || {}
    );
  }, [officeUsers, isDemoAccount]);

  const queryString = useMemo(
    () =>
      `call-records-${JSON.stringify(filters)}-${searchQuery.query}-${JSON.stringify(
        drillDownFilters
      )}-${showOnlyUnscheduled}-${pageConfig.pageSize}-${sortType}-isDemoAccount-${isDemoAccount}`,
    [filters, drillDownFilters, pageConfig.pageSize, searchQuery.query, sortType, isDemoAccount]
  );

  const { data, fetchNextPage, hasPreviousPage, isFetching } = useScopedInfiniteQuery({
    queryKey: queryKeys.callIntelligence(queryString),
    queryFn: ({ pageParam = {} }) =>
      isDemoAccount
        ? CallIntelligenceApi.noopMutationFn(null)
        : CallIntelligenceApi.getCalls({
            drillDownOptions: drillDownFilters,
            filters,
            pageConfig: { ...pageConfig, ...pageParam },
            showOnlyUnscheduled,
            searchQuery: searchQuery.query,
            sortType,
          }),
    getNextPageParam: (data) => ({
      pageNumber: (data?.pageConfig?.pageNumber || 0) + 1, // Defaults to page 1
      pageSize: data?.pageConfig?.pageSize || 10,
    }),
    getPreviousPageParam: (data) => ({
      pageNumber: (data?.pageConfig?.pageNumber || 2) - 1, // Defaults to page 1
      pageSize: data?.pageConfig?.pageSize || 10,
    }),
    onError: () => {
      if (filters.hideNonAnalyzedCalls) {
        alert.error(t('Failed to hide calls with no analysis. Please try again.'));
        setFilters({ hideNonAnalyzedCalls: false });
      } else {
        alert.error(t('Failed to fetch call records'));
      }
    },
    enabled: isMounted,
    refetchOnWindowFocus: false,
    select: (data) => (isDemoAccount ? filteredDemoCallRecords : data),
  });

  const { data: callsStats } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(
      `calls-stats-${JSON.stringify(
        Object.keys(filters)
          .filter((key) => key !== 'hideNonAnalyzedCalls')
          .map((key) => filters[key as keyof CallIntelligenceTypes.Filters])
      )}-${searchQuery.query}-${isMounted}`
    ),
    queryFn: () => (isDemoAccount || !isMounted ? null : CallIntelligenceApi.getCallsStats(filters, searchQuery.query)),
    onError: (error) => {
      console.error('Failed to fetch calls stats', error);
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const handlePanelClosure = () => {
    // If both values exists, it means contact panel was opened from the call takeaway panel
    // For such case, we need to remove the back navigation/button from the contact panel
    if (personIdRef.current && selectedCallRef.current) {
      setShow(false, 'contact');
      setSelectedCall(null);
      setSelectedCallMetaData(null);
      setPersonId(personIdRef.current, true);
    }
    // If only personId exists, it means contact panel was opened from the user card in the table
    // for such case we need to do nothing
    // If only selectedCall exists, it means call takeaway panel is open
    // For such case, we need to close the call takeaway panel while moving out of the component
    else if (selectedCallRef.current) {
      setSelectedCall(null);
      setSelectedCallMetaData(null);
    }
  };

  const handleSorting = (sortValues: SortingValue<string>[]) => {
    const { value } = sortValues[0] || {};
    setSortType(
      value === 'asc' ? CallIntelligenceTypes.SortTypeEnum.SORT_ASC : CallIntelligenceTypes.SortTypeEnum.SORT_DESC
    );
    setPageConfig({ ...pageConfig, pageNumber: 1 });
    handlePanelClosure();
  };

  const handlePaginationAfterApplyingFilters = useCallback(() => {
    setPageConfig((pageConfig) => ({ ...pageConfig, pageNumber: 1 }));
  }, []);

  const handleSearchOnChange = useCallback(
    (value: string, debounced?: boolean) => {
      if (!debounced) {
        if (value == '') {
          setSearchQuery({ query: '', value: '' });
        } else {
          setSearchQuery((prev) => ({ ...prev, value }));
        }
      } else {
        if (value.length >= MIN_SEARCH_LENGTH) {
          setSearchQuery({ value, query: value });
          handlePaginationAfterApplyingFilters();
        } else {
          setSearchQuery((prev) => ({ ...prev, value }));
        }
      }
    },
    [handlePaginationAfterApplyingFilters]
  );

  const handleSearchReset = useCallback(() => {
    setSearchQuery({ query: '', value: '' });
  }, []);

  const callConfig: TableColumnConfig<CallIntelligenceTypes.Call>[] = [
    {
      accessor: (call) => call,
      cellRenderer: (value: CallIntelligenceTypes.Call) => {
        // If the contact panel already open via call takeaway tray, do nothing on click
        const isContactPanelOpen = selectedCall?.id === value.id && personId === value.person?.id;

        return (
          <UserCard
            firstName={value?.person?.firstName}
            lastName={value?.person?.lastName}
            key={value?.person?.id}
            openProfileOnClick={!isDemoAccount && !isContactPanelOpen}
            phoneNumber={callIntelligenceUtils.getPhoneNumber(value.phoneNumber)}
            rightElement={
              value.direction === CallIntelligenceTypes.CallDirectionEnum.DIRECTION_OUTBOUND ? (
                <span style={{ color: theme.colors.neutral70 }}>
                  <PhoneOutgoingIcon size={16} />
                </span>
              ) : null
            }
            showOnlyName
            userId={value?.person?.id || ''}
            locationId={value?.locationId}
          />
        );
      },
      disableColumnFilter: true,
      disableSortBy: true,
      Header: t('Contact Name'),
      id: 'person',
      sticky: 'left',
      width: 280,
    },
    {
      accessor: ({ locationId }) => locationId,
      cellRenderer: (value: string) => {
        return value ? <LocationChip locationName={locations[value] || value} maxWidth={180} /> : '-';
      },
      disableSortBy: true,
      Header: t('Location'),
      id: 'locationName',
      omit: !isMultiLocation,
      width: 220,
    },
    {
      accessor: ({ startTime }) => dayjs(startTime).format('MMM D, YYYY, h:mm A'),
      Header: t('Time'),
      id: 'time',
      startingSortBy: 'desc',
      width: 220,
    },
    {
      accessor: ({ officeUser, startTime }) => ({ officeUser, startTime }),
      cellRenderer: ({ officeUser, startTime }) => (
        <OfficeUserName
          {...officeUser}
          isDataUnavailable={callIntelligenceUtils.isOfficeUserDataUnavailable(startTime)}
          isInactive={callIntelligenceUtils.isUserInactive(officeUser)}
        />
      ),
      disableSortBy: true,
      Header: t('Office User'),
      headerLabel: t('Office User'),
      id: 'officeUserId',
      width: 200,
    },
    {
      accessor: ({ contactType }) => ({ contactType }),
      cellRenderer: ({ contactType }) => {
        return (
          <div css={styles.chips}>
            <Chip variant={chipVariants[contactType]}>{dataLabels.contactType?.[contactType]}</Chip>
          </div>
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip columnTitle={t('Contact Type')} infoTip={<CallIntelInfoTips noCta tip='contactType' />} />
      ),
      headerLabel: t('Contact Type'),
      id: 'contactType',
      width: 240,
    },
    {
      accessor: (rowData) => rowData,
      cellRenderer: (rowData) => {
        const { status, taskCount } = rowData;

        const renderer = commonCellRenderer(status);

        return (
          renderer || (
            <>
              {taskCount > 0 ? (
                <TextLink
                  onClick={() => {
                    setPersonId('', false);
                    setSelectedCall(rowData);
                    setSelectedCallMetaData(null);
                  }}
                  trackingId={trackingIds.callIntel.taskLinkAllCallRecordings}
                >
                  {t(`{{ taskCount }} ${taskCount == 1 ? 'Task' : 'Tasks'}`, { taskCount })}
                </TextLink>
              ) : (
                <Text>-</Text>
              )}
            </>
          )
        );
      },
      disableSortBy: true,
      Header: <ColumnHeaderInfoTip columnTitle={t('Tasks')} infoTip={<CallIntelInfoTips noCta tip='tasks' />} />,
      headerLabel: t('Tasks'),
      id: 'tasks',
      omit: isFollowUpsEnabled ? false : true,
      width: 158,
    },
    {
      accessor: ({ taskTypes, status }) => ({ taskTypes, status }),
      cellRenderer: ({ taskTypes, status }) => {
        const renderer = commonCellRenderer(status);
        return renderer || taskTypes?.length ? (
          <div css={styles.chips}>
            {taskTypes?.map((taskType: string) =>
              taskType.trim() ? (
                <Chip key={taskType} variant={chipVariants[taskType] || 'neutral'}>
                  {dataLabels.taskTypes?.[taskType] || taskType}
                </Chip>
              ) : null
            )}
          </div>
        ) : (
          '-'
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Follow-up Reason')}
          infoTip={<CallIntelInfoTips noCta tip='followUpReason' />}
        />
      ),
      headerLabel: t('Follow-up Reason'),
      id: 'followUpReason',
      omit: isFollowUpsEnabled ? false : true,
      width: 281,
    },
    {
      accessor: ({ schedulingOpportunity, status }) => ({ schedulingOpportunity, status }),
      cellRenderer: ({ schedulingOpportunity, status }) => {
        const renderer = commonCellRenderer(status);
        return renderer || <Thumb up={schedulingOpportunity} />;
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Scheduling Opportunity')}
          infoTip={<CallIntelInfoTips noCta tip='schedulingOpportunities' />}
        />
      ),
      headerLabel: t('Scheduling Opportunity'),
      omit: showOnlyUnscheduled,
      id: 'schedulingOpportunity',
      width: 220,
    },
    {
      accessor: ({ schedulingOpportunity, schedulingOutcome, status }) => ({
        schedulingOpportunity,
        schedulingOutcome,
        status,
      }),
      cellRenderer: ({ schedulingOpportunity, schedulingOutcome, status }) => {
        const renderer = commonCellRenderer(status);
        return renderer || (schedulingOpportunity ? <Accept accepted={schedulingOutcome} /> : t('N/A'));
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Scheduling Outcome')}
          infoTip={<CallIntelInfoTips noCta tip='schedulingOutcome' />}
        />
      ),
      headerLabel: t('Scheduling Outcome'),
      id: 'schedulingOutcome',
      width: 200,
    },
    {
      accessor: ({ sentiment, status }) => ({ sentiment, status }),
      cellRenderer: ({ sentiment, status }) => {
        const renderer = commonCellRenderer(status);
        return (
          renderer ||
          (callIntelligenceUtils.isFailedCall(status) ||
          callIntelligenceUtils.isSkippedCall(status) ? null : sentiment ? (
            <Text>{dataLabels.sentimentsWithEmoji?.[sentiment] || sentiment}</Text>
          ) : (
            '-'
          ))
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Customer Sentiment')}
          infoTip={<CallIntelInfoTips noCta tip='sentiments' />}
        />
      ),
      headerLabel: t('Customer Sentiment'),
      id: 'sentiment',
      width: 225,
    },
    {
      accessor: ({ categories, status }) => ({ categories, status }),
      cellRenderer: ({ categories, status }) => {
        const renderer = commonCellRenderer(status);
        return (
          renderer ||
          (categories?.length ? (
            <div css={styles.chips}>
              {categories?.map((category: string) =>
                category.trim() ? (
                  <Chip key={category} variant={chipVariants[category] || 'neutral'}>
                    {dataLabels.categories?.[category] || category}
                  </Chip>
                ) : null
              )}
            </div>
          ) : (
            '-'
          ))
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Category')}
          infoTip={<CallIntelInfoTips customTipTitle={t('Category')} noCta tip='callsByCategory' />}
        />
      ),
      headerLabel: t('Category'),
      id: 'category',
      width: 270,
    },
    {
      accessor: ({ appointmentTypes, status }) => ({ appointmentTypes, status }),
      cellRenderer: ({ appointmentTypes, status }) => {
        const renderer = commonCellRenderer(status);
        return (
          renderer ||
          (appointmentTypes?.length ? (
            <div css={styles.chips}>
              {appointmentTypes?.map((appointmentType: string) =>
                appointmentType.trim() ? (
                  <Chip key={appointmentType} variant={chipVariants[appointmentType] || 'neutral'}>
                    {dataLabels.appointmentTypes?.[appointmentType] || appointmentType}
                  </Chip>
                ) : null
              )}
            </div>
          ) : (
            '-'
          ))
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Appointment Type')}
          infoTip={<CallIntelInfoTips customTipTitle={t('Appointment Type')} noCta tip='callsByAppointmentType' />}
        />
      ),
      headerLabel: t('Appointment Type'),
      id: 'appointmentType',
      width: 270,
    },
    {
      accessor: ({ officeNumber, startTime }) =>
        callIntelligenceUtils.isOfficeUserDataUnavailable(startTime)
          ? t('Unavailable Data')
          : officeNumber?.nationalNumber
          ? formatPhoneNumber(officeNumber.nationalNumber)
          : '-',
      disableSortBy: true,
      Header: t('Office Number'),
      headerLabel: t('Office Number'),
      id: 'officeNumber',
      omit: (officeNumbers?.length || 0) === 1,
      width: 180,
    },
  ];

  useEffect(() => {
    setPageConfig({ ...pageConfig, pageNumber: 1 });
    handlePanelClosure();
  }, [filters]);

  useEffect(() => {
    if (!data?.pages[pageConfig.pageNumber - 1]) {
      fetchNextPage();
    }
  }, [pageConfig.pageNumber]);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
    setAllCalls(isDemoAccount ? data?.pages[0]?.calls || [] : data?.pages[pageConfig.pageNumber - 1]?.calls || []);
  }, [isDemoAccount, data, pageConfig.pageNumber]);

  useEffect(() => {
    // Keeping a ref to the current values as they are always containing the previous render's values
    // Which is not allowing to manage the panels as requested by design
    personIdRef.current = personId;
    selectedCallRef.current = selectedCall;
  }, [personId, selectedCall]);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
      handlePanelClosure();
    };
  }, []);

  return (
    <>
      <Table
        colConfig={callConfig}
        customToolbarRender={() => (
          <CallIntelFilters
            activeTab='records'
            hideFilters={drillDownFilters ? ['locations', 'startDate', 'endDate', 'contactTypes'] : undefined}
            isDemoAccount={isDemoAccount}
            isLoadingData={isFetching}
            onApplyingFilters={handlePaginationAfterApplyingFilters}
            onlyChipFilters
          />
        )}
        data={allCalls}
        disableMultiSort
        emptyStateConfig={
          searchQuery.value.length
            ? {
                type: 'summary',
                header: t(''),
                description: () => (
                  <div css={styles.emptyStateWrapper}>
                    <Text css={styles.emptyStateText}>{t('No results that match your search.')}</Text>
                    <Text css={styles.emptyStateText}>
                      {t('Try adjusting your filters or searching for a different name or number.')}
                    </Text>
                  </div>
                ),
              }
            : {
                type: 'sync_your_phone',
              }
        }
        fullHeight={!drillDownFilters}
        globalTrackingId='call-intel-records'
        manualFiltersRender={(modalProps, setShowNotificationBadge) => (
          <div css={styles.filtersWrapper}>
            {isSearchEnabled && (
              <CallIntelSearch
                minSearchLength={MIN_SEARCH_LENGTH}
                onChange={handleSearchOnChange}
                value={searchQuery.value}
                trackingId={trackingIds.callIntel.allCallRecordingsSearch}
              />
            )}
            <CallIntelFilters
              activeTab='records'
              isDemoAccount={isDemoAccount}
              isLoadingData={isFetching}
              modalProps={modalProps}
              nonAnalyzedCallsCount={callsStats?.count}
              officeNumbers={(officeNumbers?.length || 0) > 1 ? officeNumbers : undefined}
              officeUsers={Object.keys(activeOfficeUsers || {}).length > 1 ? activeOfficeUsers : undefined}
              onApplyingFilters={handlePaginationAfterApplyingFilters}
              onSearchReset={handleSearchReset}
              onlyTrayFilters
              setShowNotificationBadge={setShowNotificationBadge}
              wrapperStyles={{ flexGrow: 1, margin: 0 }}
            />
          </div>
        )}
        manualFilters
        hasFilterColumns
        hasGlobalSearch={false}
        hasResizeColumns
        hasResponsiveColWidths
        isLoading={isFetching}
        isPaginated
        manualPaginationConfig={{
          handleChange: (action: 'next' | 'prev') => {
            if (action === 'next') {
              setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber + 1 });
            } else {
              setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber - 1 });
            }
            handlePanelClosure();
          },
          hasNext: isDemoAccount ? false : data?.pages[pageConfig.pageNumber - 1]?.hasNextPage,
          hasPrevious: hasPreviousPage,
          page: pageConfig.pageNumber,
          defaultRowsPerPage: pageConfig.pageSize,
          onNumRowsChange: (num) => {
            setPageConfig({ pageNumber: 1, pageSize: num });
            handlePanelClosure();
          },
          rowsPerPageOptions: [10, 25, 50, 100],
        }}
        manualSortBy
        onSortChange={handleSorting}
        rowActions={{
          actions: [
            {
              // Label and Icon both are required properties (as of now) even if renderCustom is provided
              // Hence, passing both of them to avoid typescript errors
              Icon: ListsIcon,
              label: t('Call Takeaways'),
              renderCustom: (_getItemProps, _index, _close, _isSingleAction, rowData) => {
                const isFailedCall = callIntelligenceUtils.isFailedCall(rowData.status);
                const isSkippedCall = callIntelligenceUtils.isSkippedCall(rowData.status);
                const isCallInProcessing = callIntelligenceUtils.isCallInProcessing(rowData.status);
                const isPoorAudio = isDemoAccount ? false : callIntelligenceUtils.isPoorAudio(rowData);

                return (
                  <IconButton
                    key={rowData.id}
                    label={
                      isCallInProcessing
                        ? t('We are analyzing this call.')
                        : isFailedCall
                        ? t('We are unable to transcribe and analyze this call.')
                        : isSkippedCall
                        ? t(
                            "We didn't analyze this call because the short audio length resulted in inaccurate outputs. Please review the transcript."
                          )
                        : isPoorAudio
                        ? t(
                            'The transcript and details of this call may be inaccurate due to poor audio quality. Please review the recording.'
                          )
                        : t('Call Takeaways')
                    }
                    onClick={() => {
                      setPersonId('', false);
                      setSelectedCall(rowData);
                      setSelectedCallMetaData(null);
                    }}
                    showLabelOnHover
                    trackingId={trackingIds.callIntel.openCallTakeAwayTray}
                  >
                    {getTrayIcon(rowData, isDemoAccount)}
                  </IconButton>
                );
              },
            },
          ],
        }}
        styleConfig={{
          columns: [
            {
              id: 'tasks',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'followUpReason',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'schedulingOpportunity',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'schedulingOutcome',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'sentiment',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'appointmentType',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'category',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'officeNumber',
              cellStyler: (_cellVal, _column, rowObject) => {
                const isDataUnavailable = callIntelligenceUtils.isOfficeUserDataUnavailable(
                  rowObject.original.startTime
                );

                return css`
                  color: ${isDataUnavailable ? theme.colors.neutral50 : 'inherit'};
                `;
              },
            },
          ],
          rows: (rowData) => {
            return rowData.id === selectedCall?.id ? { background: theme.colors.neutral5 } : {};
          },
        }}
        tableInstanceId='uploaded-call-records'
        wrapperStyle={css`
          margin-top: ${marginTop}px;

          .table-toolbar {
            gap: ${theme.spacing(2)};
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0;

            > div:last-of-type {
              padding-right: 0;
              padding-left: 0;
              margin-left: auto;
              align-items: flex-start;

              ${!drillDownFilters
                ? `
                @media screen and (max-width: 1500px) {
                margin-left: 0;
                flex: 1 1 100%; 
              }`
                : `
              @media screen and (max-width: 680px) {
              margin-left: 0;
              flex: 1 1 100%; 
            }`};
            }
          }
        `}
      />
    </>
  );
};

const styles = {
  chips: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
  `,

  emptyStateWrapper: css`
    width: 400px;
    text-align: center;
  `,

  emptyStateText: css`
    color: ${theme.colors.neutral50};
  `,

  filtersWrapper: css`
    order: -1;
    margin-right: ${theme.spacing(2)};

    @media screen and (max-width: 1500px) {
      flex: 1;
    }
  `,

  rowLoader: css`
    width: ${theme.spacing(7.5)};
  `,
};
