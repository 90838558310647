import { useMutation } from '@frontend/react-query-helpers';
import React from 'react';
import { css } from '@emotion/react';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { IconButton } from '../components/dialpad/icon-button';
import { AsteriskIcon, DeleteIcon, PhoneIcon, useModalControl, useAlert } from '@frontend/design-system';
import useDialer, { DialerInput } from '../components/dialpad/dialer-input';
import { DialChar } from '../components/dialpad/dialpad-utils';
import { ReactNode, useMemo } from 'react';
import { OutboundCallerSelectionModal } from '@frontend/contact-actions-place-call';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { removeNonDigits } from '@frontend/phone-numbers';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { DialpadUnavailableState, EmptySyncState } from '../components/dialpad/empty-state';
import { queryKeys } from '../query-keys';
import { DialApi, DialUtils, DialpadTypes } from '@frontend/api-dialpad';
import { DeviceType } from '@weave/schema-gen-ts/dist/schemas/phone/user/v1/user_service.pb';

export type DialButton = {
  label: DialChar;
  subLabel?: string;
  iconNode?: ReactNode;
};

const dialPadKeys: DialButton[] = [
  { label: 1 },
  { label: 2, subLabel: 'ABC' },
  { label: 3, subLabel: 'DEF' },
  { label: 4, subLabel: 'GHI' },
  { label: 5, subLabel: 'JKL' },
  { label: 6, subLabel: 'MNO' },
  { label: 7, subLabel: 'PQRS' },
  { label: 8, subLabel: 'TUV' },
  { label: 9, subLabel: 'WXYZ' },
  { label: '*', subLabel: '', iconNode: <AsteriskIcon /> },
  { label: 0, subLabel: '\uff0b' },
  { label: '#', subLabel: '' },
];
export const DialPad = () => {
  const alerts = useAlert();
  const { t } = useTranslation('dialpad');
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');

  const { modalProps, triggerProps } = useModalControl();
  const { dialNumber, addDialChar, setDialNumber, deleteLastChar, resetInput } = useDialer();

  const handleInputChange = (inputChar: string) => {
    setDialNumber(inputChar);
  };

  const handleDialKeysClick = (char: DialChar) => {
    addDialChar(char.toString());
  };

  const handleDelete = () => {
    deleteLastChar();
  };

  const handleReset = () => {
    resetInput();
  };
  const { data: { response: voiceNumbersList = [] } = {} } = useLocalizedQuery({
    queryKey: queryKeys.outboundNumbers(phoneConfig?.tenantId ?? ''),
    queryFn: () => DialApi.fetchVoicePhoneNumber({ tenantId: phoneConfig?.tenantId ?? '' }),
    retry: false,
    enabled: !!phoneConfig?.tenantId,
  });

  const { mutate: makeACall } = useMutation({
    mutationFn: (req: DialpadTypes.Dial['input']) => DialApi.dialUser(req),
    onSuccess: () => {
      alerts.success(t(`Call sent to desk phone.`));
    },
  });

  const defaultVoiceData = useMemo(() => DialUtils.getDefaultVoiceData(voiceNumbersList), [voiceNumbersList]);
  const isSingleLocationVoiceNumber = voiceNumbersList?.length === 1;

  const handleCall = () => {
    const toNumber = removeNonDigits(dialNumber);

    if (toNumber.length <= 6) {
      // Assume that the user is trying to dial an extension
      makeACall({
        fromName: defaultVoiceData.locationName,
        fromNumber: defaultVoiceData.defaultNumber,
        toNumber,
        sipProfileId: phoneConfig?.sipProfileId ?? '',
      });
    } else if (isSingleLocationVoiceNumber && voiceNumbersList[0]?.phoneNumbers?.length === 1) {
      makeACall({
        fromName: defaultVoiceData.locationName,
        fromNumber: defaultVoiceData.defaultNumber,
        toNumber,
        sipProfileId: phoneConfig?.sipProfileId ?? '',
      });
    } else {
      triggerProps.onClick();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleCall();
    }
  };

  return (
    <Page
      title={t('Dial Pad')}
      subtitle={t(
        'Input the number you\'d like to call into the dial pad, then either click the green phone button or press the "Enter" key to connect the call to your desk phone.'
      )}
    >
      {!phoneConfig && <EmptySyncState />}
      {!!phoneConfig && phoneConfig.deviceType === DeviceType.DEVICE_TYPE_SOFTPHONE && <DialpadUnavailableState />}
      {!!phoneConfig && phoneConfig.deviceType === DeviceType.DEVICE_TYPE_DESK_PHONE && (
        <section css={styles}>
          <DialerInput
            value={dialNumber}
            handleInputChange={(e) => handleInputChange(e.target.value)}
            handleKeyDown={handleKeyDown}
            handleReset={handleReset}
          />
          <div className='keypad-buttons'>
            <KeypadButtons addDialChar={handleDialKeysClick} />
            <div className='primary-btn'>
              <div className='button-primary'>
                <IconButton
                  type='submit'
                  color='green'
                  disabled={!dialNumber}
                  onClick={handleCall}
                  trackingId={`phone-portal-weave2.0-dialpad-dial-btn`}
                  iconNode={<PhoneIcon color='white' className={`inner-icon ${!dialNumber ? 'disabled' : ''}`} />}
                />
                <DeleteIcon className='delete-icon' color='light' onClick={handleDelete} />
              </div>
            </div>
          </div>
          <OutboundCallerSelectionModal
            key={phoneConfig?.sipProfileId}
            modalProps={modalProps}
            outboundNumbers={voiceNumbersList}
            dialNumber={dialNumber}
          />
        </section>
      )}
    </Page>
  );
};

type KeyPadProps = {
  disabled?: boolean;
  addDialChar: (char: DialChar) => void;
};

const KeypadButtons = ({ addDialChar, disabled = false }: KeyPadProps) => {
  return (
    <ul
      css={css`
        display: inline-grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1px;
        margin-left: 0;
        padding-left: 0;
      `}
    >
      {dialPadKeys.map(({ label, subLabel, iconNode }) => (
        <IconButton disabled={disabled} label={subLabel} key={label} onClick={() => addDialChar(label)}>
          {iconNode}
          {!iconNode && label}
        </IconButton>
      ))}
    </ul>
  );
};

const styles = css`
  display: block;
  width: 350px;
  padding: ${theme.spacing(6, 4)};
  border: 1px solid ${theme.colors.neutral30};
  border-radius: ${theme.spacing(1)};

  .keypad-buttons {
    display: flex;
    flex-direction: column;
    align-tems: center;
    margin-top: ${theme.spacing(2)};

    .primary-btn {
      display: flex;
      align-items: center;
      justify-content: center;

      .button-primary {
        margin-left: 24px; // size of the icon
        display: flex;
        align-items: center;
        justify-content: center;

        .delete-icon {
          &:hover {
            color: ${theme.colors.black};
          }
        }
      }
    }
  }
`;
