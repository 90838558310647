import { HistoryQueries } from '@frontend/api-messaging';
import { useEffect, useState } from 'react';
import { RecordDiff } from './record-diff';
import { ListsIcon, PreviewIcon, SpinningLoader, Table } from '@frontend/design-system';
import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { ContactInfo } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/history/v1/service.pb';
import { compareRecordStyles, currentRecordStyles } from './record-history-styles';

type Props = {
  recordId: string;
};

export const ContactInfoDetail = ({ recordId }: Props) => {
  const { t } = useTranslation('messages');

  const [currentRecord, setCurrentRecord] = useState<ContactInfo>({});
  const [compareRecord, setCompareRecord] = useState<ContactInfo>({});

  const { data: contactInfoData = [], isLoading, isFetching } = HistoryQueries.useContactInfoHistory(recordId);

  useEffect(() => {
    if (contactInfoData.length) {
      setCurrentRecord(contactInfoData?.filter((item) => item.isCurrent)[0].contactInfo || {});
    }
  }, [contactInfoData]);

  if (isLoading || isFetching) {
    return <SpinningLoader />;
  }
  return (
    <>
      <Table
        colConfig={[
          {
            Header: t('Action'),
            id: 'action',
            width: 30,
            accessor: (data) => data?.action,
            disableSortBy: true,
          },
          {
            Header: t('Priority'),
            id: 'priority',
            width: 80,
            accessor: (data) => data?.contactInfo?.priority,
            disableSortBy: true,
          },
          {
            Header: t('Modified At'),
            id: 'modifiedAt',
            width: 80,
            accessor: (data) =>
              data?.contactInfo?.modifiedAt ? dayjs(data?.contactInfo?.modifiedAt).format('YYYY/MM/DD, h:mm A') : '',
            disableSortBy: true,
          },
          {
            Header: t('Type'),
            id: 'type',
            width: 60,
            accessor: (data) =>
              data?.contactInfo?.type && Array.isArray(data?.contactInfo?.type)
                ? data?.contactInfo?.type.join(' ')
                : data?.contactInfo?.type,
            disableSortBy: true,
          },
          {
            Header: t('Person Id'),
            id: 'personId',
            width: 80,
            accessor: (data) => data?.contactInfo?.personId,
            disableSortBy: true,
          },
        ]}
        data={contactInfoData}
        isLoading={isLoading || isFetching}
        collapsedTableConfig={
          (contactInfoData?.length ?? 0) > 4
            ? {
                rowsToShow: 4,
                collapseLabel: t('Show Less...'),
                expandLabel: t('Show More...'),
                startingValue: false,
              }
            : undefined
        }
        rowActions={{
          rowActionMenuLabel: '',
          actions: [
            {
              label: t('Show Details'),
              Icon: ListsIcon,
              onClick: (item) => {
                setCurrentRecord(item.contactInfo || {});
              },
            },
            {
              label: t('Compare'),
              Icon: PreviewIcon,
              onClick: (item) => {
                setCompareRecord(item.contactInfo || {});
              },
            },
          ],
        }}
        styleConfig={{
          rows: (rowData) => {
            if (rowData.contactInfo?.modifiedAt === currentRecord.modifiedAt) {
              return currentRecordStyles;
            }
            if (rowData.contactInfo?.modifiedAt === compareRecord.modifiedAt) {
              return compareRecordStyles;
            }
            return;
          },
        }}
      />
      <RecordDiff
        currentObj={currentRecord}
        compareObj={compareRecord}
        currentDate={dayjs(currentRecord.modifiedAt).format('YYYY/MM/DD, h:mm A')}
        compareDate={dayjs(compareRecord.modifiedAt).format('YYYY/MM/DD, h:mm A')}
      />
    </>
  );
};
