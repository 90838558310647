import { ScheduleApi, ScheduleTypes } from '@frontend/api-schedule';
import { ServiceProvidersConstants } from '@frontend/api-service-providers';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { queryKeys } from '../../query-keys';

const { getProvidersListForMultipleLocationIds } = ScheduleApi;
const { ONE_MINUTE_IN_MILLISECONDS } = ServiceProvidersConstants;

type UseGetProvidersListByLocationIdsParamsType = ScheduleTypes.GetProvidersListForMultipleLocationIdsTypes['input'];

export const useGetProvidersListForMultipleLocationIds = (request: UseGetProvidersListByLocationIdsParamsType) => {
  const queryKeysParams = request.locationIds?.length ? request.locationIds : [request.locationId ?? ''];
  return useLocalizedQuery({
    queryKey: queryKeys.providersList(queryKeysParams),
    queryFn: () => getProvidersListForMultipleLocationIds(request),
    retry: 1,
    cacheTime: 5 * ONE_MINUTE_IN_MILLISECONDS, // 5 mins
    staleTime: 5 * ONE_MINUTE_IN_MILLISECONDS, // 5 mins
    enabled: !!request.locationIds?.length || !!request.locationId,
    select: (providersData) => {
      const { providers } = providersData ?? {};
      if (!providers) return [];

      return providers.sort((a, b) => {
        if (
          a?.publicDisplayName?.toLowerCase() &&
          b?.publicDisplayName?.toLowerCase() &&
          a.publicDisplayName?.toLowerCase() < b.publicDisplayName?.toLowerCase()
        ) {
          return -1;
        }
        return 1;
      });
    },
  });
};
