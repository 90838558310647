import { FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { contextFactory } from '@frontend/design-system';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';

export interface FeatureSubscriptionContextProps {
  selectedLocationIds?: string[];
  isFreeTrialEligible: boolean;
  isUpgradeEligible: boolean;
  canSelfSubscribe: boolean;
  feature: Feature;
  isLoading: boolean;
  refetchSubscriptionConfig: () => void;
  selfUpgradeData: FreeTrialSubscriptionTypes.IsMultiEligibleForSelfUpgradeOrTrialDetail[];
  locationWiseAdminAccess: Record<string, boolean>;
  hasAdminAccessInAtleastOne: boolean;
}

export const [FeatureSubscriptionContext, useFeatureSubscription] = contextFactory<FeatureSubscriptionContextProps>();
