import { css } from '@emotion/react';
import { EmojiClickData } from 'emoji-picker-react';
import { theme } from '@frontend/theme';
import { EmojiButton } from '@frontend/design-system';

export interface EmojiActionProps {
  onSelect: (emoji: EmojiClickData) => void;
}

export const EmojiAction = ({ onSelect }: EmojiActionProps) => {
  return <EmojiButton css={styles} onSelect={onSelect} trackingId='team-chat-v2-add-emoji' />;
};

const styles = css`
  height: ${theme.spacing(4)};
  min-width: ${theme.spacing(4)};
  width: ${theme.spacing(4)};
`;
