import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { StyleProps, ButtonStyleValues } from '../button.type';
import { baseButtonStyle, pressedStyle, hoverStyle } from './common.styles';

const primaryTheme: ButtonStyleValues = {
  backgroundColor: theme.colors.primary50,
  color: theme.colors.white,
  focusRingColor: theme.colors.primary50,
  hoverColor: theme.colors.primary30,
  pressed: {
    backgroundColor: theme.colors.primary70,
  },
  disabled: {
    backgroundColor: theme.colors.status.disabled,
    borderColor: theme.colors.status.disabled,
    color: theme.colors.white,
    secondaryColor: theme.colors.neutral20,
  },
  loading: {
    backgroundColor: theme.colors.primary10,
    borderColor: theme.colors.primary30,
  },
};

export const primaryStyles = ({ size, isLoading, iconOnly }: StyleProps) => [
  baseButtonStyle({
    focusRingColor: primaryTheme.focusRingColor,
    size: size ?? 'small',
    iconOnly,
  }),
  css`
    background: ${primaryTheme.backgroundColor};
    border: none;
    color: ${primaryTheme.color};
  `,
  isLoading
    ? css`
        border: 1px solid ${primaryTheme.loading.borderColor};
        background: ${primaryTheme.loading.backgroundColor};
        &[aria-disabled='true'] {
          box-shadow: none;
          color: ${primaryTheme.disabled.color};
        }
      `
    : css`
        :hover {
          ${hoverStyle({ hoverColor: primaryTheme.hoverColor })};
        }
        &[aria-disabled='true'] {
          background: ${primaryTheme.disabled.backgroundColor};
          border-color: ${primaryTheme.disabled.backgroundColor};
          box-shadow: none;
          color: ${primaryTheme.disabled.color};
        }
        ${pressedStyle({ pressed: primaryTheme.pressed })}
      `,
];
