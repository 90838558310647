import { Dispatch, SetStateAction, useEffect } from 'react';
import { css } from '@emotion/react';
import { MessagesTypes } from '@frontend/api-messaging';
import {
  DateRangeField,
  DropdownField,
  FormFieldActionTypes,
  SwitchField,
  Text,
  useForm,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { BulkPrefixes } from '@frontend/tracking-prefixes';
import { getTodaysDate } from '@frontend/date';
import { formatRange, rangeHasDate } from './utils';
import dayjs from 'dayjs';

type Props = {
  filters: MessagesTypes.Filters;
  setFilters: Dispatch<SetStateAction<MessagesTypes.Filters>>;
};

const createMinMax = (appointmentQualifier?: MessagesTypes.AppointmentQualifierOptions) => {
  switch (appointmentQualifier) {
    case 'Next Appointment':
      return {
        maxDate: '',
        minDate: dayjs().add(1, 'day').format('MM/DD/YYYY'),
      };

    case 'Last Seen':
      return {
        maxDate: getTodaysDate('MM/DD/YYYY'),
        minDate: '',
      };

    default: // 'No Appointment' and 'All'
      return {
        maxDate: '',
        minDate: '',
      };
  }
};

export const AppointmentDateFilter = ({ filters, setFilters }: Props) => {
  const { t } = useTranslation('messages');
  const options: MessagesTypes.AppointmentQualifierOptions[] = ['Next Appointment', 'Last Seen', 'No Appointment'];

  const updateAppointmentQualifierFilter = (value?: MessagesTypes.AppointmentQualifierOptions) => {
    if (value) {
      setFilters({ ...filters, appointmentQualifier: value });
    } else {
      const copy = { ...filters };
      delete copy.appointmentQualifier;
      setFilters(copy);
    }
  };

  const updateAppointmentDateFilter = (values?: [string, string]) => {
    if (values) {
      setFilters({ ...filters, appointmentDate: formatRange(values) });
    } else {
      const copy = { ...filters };
      delete copy.appointmentDate;
      setFilters(copy);
    }
  };

  const { getFieldProps, seedValues, validate, getErrors } = useForm({
    fields: {
      appointmentQualifier: {
        type: 'dropdown',
      },
      appointmentDate: {
        type: 'dateRange',
        required: true,
        ...createMinMax(filters.appointmentQualifier),
        validator: (dateRangeField) => {
          const value = dateRangeField.value;

          if (!value[0] || !value[1]) {
            return '';
          }

          if (dayjs(value[0]).isBefore(dayjs(dateRangeField.minDate))) {
            return t('Date range must be in the future');
          }

          if (dayjs(value[1]).isAfter(dayjs(dateRangeField.maxDate))) {
            return t('Date range must be in the past');
          }

          return '';
        },
      },
      enabled: {
        type: 'switch',
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update && action.payload.name === 'appointmentQualifier') {
        return {
          ...state,
          appointmentDate: {
            ...state.appointmentDate,
            ...createMinMax(state.appointmentQualifier.value as MessagesTypes.AppointmentQualifierOptions),
          },
        };
      }

      if (action.type === FormFieldActionTypes.Update && action.payload.name === 'appointmentDate') {
        // enable toggle when selecting at least one
        if (rangeHasDate(state.appointmentDate.value as string[]) && !state.enabled.value) {
          return {
            ...state,
            enabled: {
              ...state.enabled,
              value: true,
            },
          };
        }

        if (!rangeHasDate(state.appointmentDate.value as string[]) && state.enabled.value) {
          return {
            ...state,
            enabled: {
              ...state.enabled,
              value: false,
            },
          };
        }
      }

      if (action.type === FormFieldActionTypes.Update && action.payload.name === 'enabled') {
        // removing selections when toggle is turned off
        if (!state.enabled.value) {
          updateAppointmentQualifierFilter(undefined);
          updateAppointmentDateFilter(undefined);
          return {
            ...state,
            enabled: {
              ...state.enabled,
              value: false,
            },
            appointmentQualifier: {
              ...state.appointmentQualifier,
              value: '',
            },
            appointmentDate: {
              ...state.appointmentDate,
              value: ['', ''],
            },
          };
        }
      }
      return state;
    },
  });

  const appointmentQualifierFieldProps = getFieldProps('appointmentQualifier');
  const appointmentDateFieldProps = getFieldProps('appointmentDate');
  const enabledFieldProps = getFieldProps('enabled');
  const errors = getErrors();

  useEffect(() => {
    seedValues({
      appointmentQualifier: (filters.appointmentQualifier ?? '') as string,
      appointmentDate: formatRange(filters.appointmentDate as string[] | undefined, 'MM/DD/YYYY'),
      enabled:
        !!filters.appointmentQualifier ||
        !!((filters.appointmentDate as [string, string])?.[0] || (filters.appointmentDate as [string, string])?.[1]),
    });
  }, []);

  useEffect(() => {
    const value = appointmentQualifierFieldProps.value as MessagesTypes.AppointmentQualifierOptions;
    const filterValue = value ? value : undefined;
    validate();
    updateAppointmentQualifierFilter(filterValue);
  }, [appointmentQualifierFieldProps.value]);

  useEffect(() => {
    const value = appointmentDateFieldProps.value as [string, string];
    const filterValue = rangeHasDate(value) ? value : undefined;
    validate();
    updateAppointmentDateFilter(filterValue);
  }, [appointmentDateFieldProps.value]);

  useEffect(() => {
    if (!enabledFieldProps.value) {
      updateAppointmentQualifierFilter(undefined);
      updateAppointmentDateFilter(undefined);
    }
  }, [enabledFieldProps.value]);

  return (
    <>
      <Text>{t('Appointment Date')}</Text>
      <div>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr auto auto;
            gap: ${theme.spacing(2)};
            align-items: center;
            margin-right: ${theme.spacing(1)};
          `}
          data-trackingid={`${BulkPrefixes.Recipients}-appointment-date-filter-input`}
        >
          <DropdownField
            label={appointmentQualifierFieldProps.value === '' ? t('All') : ''}
            css={css`
              width: 120px;
            `}
            {...appointmentQualifierFieldProps}
          >
            <DropdownField.Option value={''}>{t('All')}</DropdownField.Option>
            {options.map((option, index) => (
              <DropdownField.Option key={index} value={option}>
                {option}
              </DropdownField.Option>
            ))}
          </DropdownField>
          <DateRangeField label='' {...appointmentDateFieldProps} />
          <SwitchField label='' {...enabledFieldProps}></SwitchField>
        </div>
        {errors.appointmentDate && (
          <Text
            size='small'
            color='error'
            css={css`
              margin-top: ${theme.spacing(0.5)};
            `}
          >
            {errors.appointmentDate}
          </Text>
        )}
      </div>
    </>
  );
};
