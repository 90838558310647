import { SchemaSMSSharedEnums, SchemaSMSSharedModels } from '@frontend/api-messaging';
import { SmsNewMessageEventPayload } from '@frontend/websocket';
import { convertStringToStatus } from './convert-string-to-status';

export const getSMSFromWebsocketEvent = (params: SmsNewMessageEventPayload['params']): SchemaSMSSharedModels.SMS => {
  const isOutbound = params.action === 'outbound';

  return {
    id: params.uuid,
    providerMsgId: '',
    threadId: params.thread_ids && Array.isArray(params.thread_ids) ? params.thread_ids[0] ?? '' : '',
    locationId: params.location_id,
    locationPhone: params.weave_number,
    personPhone: params.patient_number,
    direction: isOutbound
      ? SchemaSMSSharedEnums.Direction.DIRECTION_OUTBOUND
      : SchemaSMSSharedEnums.Direction.DIRECTION_INBOUND,
    body: params.body,
    status: convertStringToStatus(
      params.status,
      isOutbound ? SchemaSMSSharedEnums.Status.STATUS_SENT : SchemaSMSSharedEnums.Status.STATUS_NEW
    ),
    statusDetails: '',
    numMedia: params.num_media,
    media: params.media_ids.map((id, index) => ({ mediaId: id, url: params.media_url.at(index) })),
    encoding: SchemaSMSSharedEnums.Encoding.ENCODING_UNSPECIFIED,
    segments: 1,
    relatedIds: params.related_ids ?? [],
    labels: {},
    programSlugId: '',
    personId: params.person_id || params.patient_id,
    deadline: '',
    autogeneratedBy: '',
    readBy: '',
    deletedBy: '',
    createdBy: isOutbound ? params.weave_user_id : params.person_id || params.patient_id,
    createdAt: params.received_at,
    updatedBy: '',
    updatedAt: '',
    readAt: '',
    deletedAt: '',
    actionable: false,
    departmentId: params.department_id,
    tags: [],
  };
};
