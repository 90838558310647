import { DevicesQueries } from '@frontend/api-devices';
import { useMemo } from 'react';
import { DeviceMake_Enum, DeviceType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { ContactDirectoriesQuery } from '@frontend/api-contact-directories';

export const useGetContactDirectoriesForEachDeviceId = (locationIds: string[]) => {
  const { data: { devices } = { devices: [] }, isLoading: isDeviceListLoading } = DevicesQueries.useGetDevicesList(
    {
      include: [],
      locationIds,
    },
    {
      locationId: locationIds?.[0],
    }
  );

  const filteredDevices = useMemo(
    () =>
      devices.filter(
        (device) => device.type === DeviceType_Enum.SOFTPHONE || device.type === DeviceType_Enum.DESK_PHONE
      ),
    [devices]
  );

  const assignableDeviceIds = filteredDevices
    ?.filter((device) => device.make === DeviceMake_Enum.YEALINK)
    .map((device) => device.deviceId);

  const { useGetContactDirectoriesByDeviceIds } = ContactDirectoriesQuery.useContactDirectoryQueries(locationIds?.[0]);
  const { data: deviceWithContactDirectories = undefined, isLoading: isLoadingCDsByDeviceId } =
    useGetContactDirectoriesByDeviceIds(assignableDeviceIds);

  const isLoading = isDeviceListLoading || isLoadingCDsByDeviceId;

  return {
    devices: filteredDevices,
    deviceWithContactDirectories,
    isLoading,
    assignableDeviceIds,
  };
};
