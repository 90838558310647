import { useMultiStepModal } from '@frontend/payments-multistep-modal';

export enum CollectPaymentModalSteps {
  PaymentFlowList = 'payment-flow-list',
  ShareInMessage = 'share-in-message',
  CardOnFile = 'card-on-file',
  ManualCardEntry = 'manual-card-entry',
  Sunbit = 'send-sunbit-link',
  ReaderSelection = 'reader-selection',
  CollectReaderPayment = 'collect-reader-payment',
}

export const useCollectPaymentModal = () => {
  const { activeStep, goToStep: goToStepInContext } = useMultiStepModal();
  const goToStep = (step: CollectPaymentModalSteps) => goToStepInContext?.(step);

  return { activeStep, goToStep };
};
