import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';

export enum IntegrationPackagesEnum {
  PLUS = 'PLUS',
  CORE = 'CORE',
}

export enum BundlePackagesEnum {
  ELITE = 'ELITE',
  PRO = 'PRO',
  ESSENTIALS = 'ESSENTIALS',
}

export enum BundleOptionalsEnum {
  SOFTWARE_ONLY = 'SOFTWARE_ONLY',
}

export const IntegrationPackagesLabel: Record<IntegrationPackagesEnum, string> = {
  [IntegrationPackagesEnum.CORE]: 'Core',
  [IntegrationPackagesEnum.PLUS]: 'Plus',
};

export const BundlePackagesLabel: Record<BundlePackagesEnum, string> = {
  [BundlePackagesEnum.ELITE]: 'Elite',
  [BundlePackagesEnum.PRO]: 'Pro',
  [BundlePackagesEnum.ESSENTIALS]: 'Essentials',
};

const SalesforceBundlePrefix = 'PRODUCT_BUNDLE_WEAVE';
type SalesforcePackageParts = {
  integrationPackage: IntegrationPackagesEnum;
  bundlePackage: BundlePackagesEnum;
  bundleOptional?: BundleOptionalsEnum;
};

export const getSalesforceBundlePackage = (bundleParts: SalesforcePackageParts): SalesforceAccountProductBundle => {
  const { integrationPackage, bundlePackage, bundleOptional } = bundleParts;
  const bundlePartsList = [SalesforceBundlePrefix, integrationPackage, bundlePackage];
  if (bundleOptional) {
    bundlePartsList.splice(2, 0, bundleOptional);
  }
  return SalesforceAccountProductBundle[bundlePartsList.join('_') as SalesforceAccountProductBundle];
};
