import { ScheduledSmsThread } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Dot, Text } from '@frontend/design-system';
import { STATUS_ICON_MAP, StatusIconMapValue } from './constants';
import { MessageStatus } from './types';

export type MessageStatusIndicatorProps = {
  status: MessageStatus;
  size?: number;
  inline?: boolean;
  multiSchedule?: ScheduledSmsThread[];
} & React.HTMLAttributes<HTMLElement>;

export const MessageStatusIndicator = ({
  status,
  size = 16,
  inline,
  multiSchedule,
  ...rest
}: MessageStatusIndicatorProps) => {
  const { iconName, dotColor, iconColor, customColor } = STATUS_ICON_MAP[status];
  if (multiSchedule) {
    const { pausedSchedules, scheduledSchedules } = multiSchedule.reduce(
      (acc, icon) => {
        if (icon.status === OutboundMessageStatus.PAUSED) {
          acc.pausedSchedules++;
        } else {
          acc.scheduledSchedules++;
        }
        return acc;
      },
      { pausedSchedules: 0, scheduledSchedules: 0 }
    );

    return (
      <div>
        <div
          css={{
            display: 'inline-flex',
            alignItems: 'center',
            '.scheduled-count-text': {
              color: theme.colors.warning60,
            },
          }}
        >
          <Figure
            iconName='calendar'
            customColor={theme.colors.warning60}
            size={size}
            inline={inline}
            css={{ marginRight: theme.spacing(2 / 8) }}
          />
          <Text size='small' className='scheduled-count-text'>
            {scheduledSchedules}
          </Text>
        </div>
        <div
          css={{
            display: 'inline-flex',
            alignItems: 'center',
            marginLeft: theme.spacing(1),
          }}
        >
          <Figure
            iconName='calendar-pause'
            iconColor='light'
            size={size}
            inline={inline}
            css={{ marginRight: theme.spacing(2 / 8) }}
          />
          <Text size='small' color='light'>
            {pausedSchedules}
          </Text>
        </div>
      </div>
    );
  }

  if (dotColor) {
    return (
      <Dot
        color={dotColor}
        css={{ border: 'none', position: 'relative', width: 8, height: 8, display: 'inline-block' }}
      />
    );
  }

  return (
    <Figure
      iconName={iconName}
      dotColor={dotColor}
      iconColor={iconColor}
      customColor={customColor}
      size={size}
      inline={inline}
      {...rest}
    />
  );
};

type FigureProps = StatusIconMapValue & { size?: number; inline?: boolean };

const Figure = ({ iconName, dotColor, iconColor, customColor, size, inline, ...rest }: FigureProps) => {
  return (
    <figure
      css={{
        borderRadius: theme.borderRadius.full,
        minWidth: size,
        width: size,
        height: size,
        display: inline ? 'inline-flex' : 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
      }}
      {...rest}
    >
      {iconName && (
        <Icon
          name={iconName}
          size={size}
          color={iconColor}
          css={{
            color: customColor,
          }}
        />
      )}
    </figure>
  );
};
