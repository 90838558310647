import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { Direction_Enum, Status_Enum } from '@weave/schema-gen-ts/dist/schemas/fax/shared/v1/enums.pb';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/fax/v1/fax.pb';
import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { FaxHooks, FaxQueries, FaxTypes } from '@frontend/api-fax';
import { FaxMediaQueries } from '@frontend/api-fax-media';
import { TagsUtils } from '@frontend/api-tag';
import { CoreACLs, hasACL } from '@frontend/auth-helpers';
import { Chips } from '@frontend/chips';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { SchemaFaxService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { TagSelectionHooks } from '@frontend/tag-selection';
import { FaxPrefixes } from '@frontend/tracking-prefixes';
import { ComponentProps } from '@frontend/types';
import { theme } from '@frontend/theme';
import {
  Avatar,
  Chip,
  ChipCombobox,
  ListRow,
  Modal,
  PopoverMenu,
  SpinningLoader,
  Text,
  TextButton,
  phone,
  useModalControl,
  usePopoverMenu,
  useTooltip,
  useAlert,
} from '@frontend/design-system';
import { useFaxActions } from '../../hooks';
import { useSelectedFaxShallowStore } from '../../stores';
import { FaxInboxListItemLead } from './fax-inbox-list-item-lead';
import { FaxListRow } from './fax-list-row';
import { InboxListItemTags } from './list-inbox-item-tags';
import { ListItemStatus } from './list-item-status';

type RendererProps = {
  type: FaxTypes.InboxType;
  contacts?: Contact[];
  isBulkSelect: boolean;
  selectedFaxes: Record<string, string[]>;
  setSelectedFaxes: Dispatch<SetStateAction<Record<string, string[]>>>;
  isSelectAll: boolean;
  locationTags: Tag[];
  isBlocked: boolean;
};

export const InboxListItem =
  ({
    type,
    contacts,
    isBulkSelect,
    selectedFaxes,
    setSelectedFaxes,
    isSelectAll,
    locationTags,
    isBlocked = false,
  }: RendererProps) =>
  ({ listItem }: { listItem: FaxTypes.InboxItem }) => {
    const { locationId } = listItem;
    const alert = useAlert();
    const { t } = useTranslation('fax');
    const navigate = useNavigate();
    const { matchLoader } = useMatch();
    const { modalProps: forwardModalProps, openModal } = useModalControl();
    const { navigate: settingsNavigate } = useSettingsNavigate();
    const { selectedFax, setSelectedFax } = useSelectedFaxShallowStore('selectedFax', 'setSelectedFax');
    const { getLocationName, selectedLocationIds } = useAppScopeStore();
    const { previewFax, downloadFax, markReadFax, deleteFax, blockFax, archiveFax, tagFax, unblockFax, retryFax } =
      useFaxActions();
    const invalidateFaxInboxList = FaxHooks.useInvalidateFaxInboxList();
    const invalidateFaxCount = FaxHooks.useInvalidateFaxCount();
    const { setShow } = useSlidePanelShallowStore('setShow');
    const { flagValues } = useFeatureFlagShallowStore('flagValues');
    const faxUpdatedFeaturesFlag = flagValues['fax-2-0-features'];
    const faxArchiveFeatureFlag = flagValues['fax-2-0-archive'];
    const faxBlockFeatureFlag = flagValues['fax-2-0-block'];
    const enableFaxDeliveryReport = flagValues['fax-delivery-report'];
    const [profileMedia, setProfileMedia] = useState('');
    const [tagSearchValue, setTagSearchValue] = useState<string>('');
    const [showCustomMenuItem, setShowCustomMenuItem] = useState(false);
    const [to, setTo] = useState<Contact[]>([]);

    const {
      Tooltip,
      triggerProps: tooltipTriggerProps,
      tooltipProps,
    } = useTooltip({
      trigger: 'hover',
      placement: 'left-start',
      offset: Number(theme.spacing(1).replace('px', '')),
    });

    const getHoverStatusMessage = (status: FaxTypes.FaxStatus): string => {
      switch (status) {
        case FaxTypes.FaxStatus.UNREAD:
          return t('Unread');
        case FaxTypes.FaxStatus.READ:
          return t('Read');
        case FaxTypes.FaxStatus.OUTGOING_SENT:
          return t('Sent');
        case FaxTypes.FaxStatus.DELIVERY_SUCCESS:
          return t('Delivered');
        case FaxTypes.FaxStatus.ERROR:
          return t('Error');
        case FaxTypes.FaxStatus.DRAFT:
          return t('Draft');
        case FaxTypes.FaxStatus.BLOCKED:
          return t('Blocked');
        case FaxTypes.FaxStatus.ARCHIVED:
          return t('Archived');
      }
    };

    const getFaxId = (url: string, type: FaxTypes.InboxType) => {
      const regexPattern = new RegExp(`/${type}/([^/]+)`);

      const match = url.match(regexPattern);

      return match ? match[1] : null;
    };

    const { data: singleLocationContacts } = FaxQueries.useQueryFaxContacts(locationId!, [locationId!], 1000, {});

    const { data: blob } = FaxQueries.useGetFaxContactMedia(listItem.faxContact?.profileMediaId ?? '', locationId!, {
      enabled: !!listItem.faxContact?.profileMediaId,
    });

    const selectedFaxId = getFaxId(matchLoader?.location.pathname ?? '', type);

    const isSelectedFax = selectedFaxId ? selectedFaxId === listItem.id : false;

    const hasTagACL = hasACL(locationId!, CoreACLs.TAG_EDITING);

    const tagSelectionPopover = usePopoverMenu({
      placement: 'bottom-end',
    });

    const downloadPopover = usePopoverMenu<HTMLButtonElement>({
      placement: 'right-start',
    });

    const faxId = listItem.id!;

    const filteredLocationTags = TagsUtils.sortTagsAlphabetically(
      locationTags?.filter((tag) => !listItem.tags?.includes(tag.id || tag.smartTagId)) ?? []
    );

    const tags = locationTags.filter((tag) => listItem.tags?.includes(tag.id || tag.smartTagId));

    const tagIdsToExclude = tags.map((tag) => tag.id) ?? [];

    const addTag = async (tag: Tag) => {
      const tagId = tag.id || tag.smartTagId;
      try {
        await tagFax(locationId!, [faxId] ?? [], [tagId]);
        if (selectedFax.faxId === faxId) {
          setSelectedFax({
            ...selectedFax,
            tags: [...selectedFax.tags, tagId],
          });
        }
      } catch (error) {
        console.error('Error tagging fax', error);
      }
    };

    const {
      popoverProps: tagPopoverProps,
      open: openTagSelection,
      isOpen: tagSelectionIsOpen,
      close: closeTagSelection,
      getTriggerProps: getTagSelectionTriggerProps,
    } = TagSelectionHooks.useTagSelectionPopover({
      onClose: () => {
        setTagSearchValue('');
        closeTagSelection();
      },
      onTagSelect: async (tag) => {
        addTag(tag);
        closeTagSelection();
      },
      onTagCreate: (initName) => {
        settingsNavigate({
          to: '/tags/quick-create',
          context: {
            onCreate: (tag: Tag) => {
              addTag(tag);
            },
            name: initName,
          },
        });
      },
      trackingIds: {
        selectTag: `${FaxPrefixes.Tag}-add-existing-tag`,
        createTag: `${FaxPrefixes.Tag}-create-new-tag`,
        createNamedTag: `${FaxPrefixes.Tag}-create-new-tag-with-name`,
        openTagSettings: `${FaxPrefixes.Tag}-open-tag-settings`,
      },
      searchValue: tagSearchValue,
      tagIdsToExclude,
      placement: 'right-start',
    });

    const contactPhone = type !== FaxTypes.InboxType.DRAFTS ? listItem.contactPhone : listItem?.contactPhones?.[0];

    const formattedPhone = phone(contactPhone ?? '', { hideCountry: true });
    const isRead = !!listItem?.viewedAt;
    const isInbound = listItem.direction === Direction_Enum.INBOUND;
    const isOutbound = listItem.direction === Direction_Enum.OUTBOUND;

    const personName = `${listItem.faxContact?.name} ${listItem.faxContact?.secondName ?? ''}`;

    const title = personName.trim() || formattedPhone;

    const { mutateAsync: generateMediaAttributes } = FaxMediaQueries.useGenerateMediaAttributes({
      onError: () => {
        alert.error(t('Failed to retrieve fax info. Please try again.'));
      },
    });

    const { mutate: forwardFax, isLoading: isForwardingFax } = FaxQueries.useForwardFax({
      onSuccess: () => {
        handleForwardModalClose();
        alert.success(t('Fax has been sent successfully'));
        setTimeout(() => {
          invalidateFaxInboxList();
        }, 100);
      },
      onError: () => {
        alert.error(t('Failed to send fax. Please try again.'));
      },
    });

    const { mutate: upsertContact } = FaxQueries.useUpsertFaxContact({
      onSuccess: (newContact) => {
        invalidateFaxInboxList();
        setTo((prev) => [...prev, newContact]);
      },
      onError: () => {
        alert.error(t('Failed to Create Contact'));
      },
    });

    const getStatus = (): FaxTypes.FaxStatus => {
      if (listItem.status === Status_Enum.FAILED_FATAL || listItem.status === Status_Enum.FAILED_RETRYABLE)
        return FaxTypes.FaxStatus.ERROR;
      if (isBlocked) return FaxTypes.FaxStatus.BLOCKED;
      if (!!listItem.archivedAt) return FaxTypes.FaxStatus.ARCHIVED;
      if (listItem.direction === Direction_Enum.OUTBOUND && listItem.status === Status_Enum.DELIVERED)
        return FaxTypes.FaxStatus.DELIVERY_SUCCESS;
      if (listItem.direction === Direction_Enum.OUTBOUND) return FaxTypes.FaxStatus.OUTGOING_SENT;
      if (listItem.direction === Direction_Enum.INBOUND && !isRead) return FaxTypes.FaxStatus.UNREAD;
      return FaxTypes.FaxStatus.READ;
    };

    const handleForwardModalClose = () => {
      setTo([]);
      forwardModalProps.onClose();
    };

    const isCheckboxSelected = isSelectAll
      ? !selectedFaxes[locationId!]?.includes(faxId) && isInbound
      : !!selectedFaxes[locationId!]?.includes(faxId) && isInbound;

    const setCheckboxSelected = (selected: boolean) => {
      let faxes: string[] = [];
      const filteredFaxes = selectedFaxes[locationId!]?.filter((id) => id !== faxId) ?? [];
      const updatedFaxes = !selectedFaxes[locationId!]?.includes(faxId)
        ? [...(selectedFaxes[locationId!] ?? []), faxId]
        : selectedFaxes[locationId!];

      if (isSelectAll) {
        faxes = selected ? filteredFaxes : updatedFaxes;
      } else {
        faxes = selected ? updatedFaxes : filteredFaxes;
      }

      setSelectedFaxes((prev) => ({ ...prev, [locationId!]: faxes }));
    };

    const archiveButtonProps: ComponentProps<typeof FaxListRow>['archiveButtonProps'] =
      listItem.status === Status_Enum.FAILED_RETRYABLE
        ? {
            Icon: () => <Icon name='update' size={16} css={{ color: theme.colors.neutral70 }} />,
            label: t('Retry'),
            onClick: () => retryFax(listItem.id!, locationId!),
            showLabelOnHover: true,
            size: 'normal',
            trackingId: `${FaxPrefixes.List}-retry-button`,
          }
        : {
            label: type === 'archived' ? t('Unarchive') : t('Archive'),
            Icon:
              type === 'archived'
                ? () => <Icon name='unarchive' css={{ color: theme.colors.neutral70 }} />
                : () => <Icon name='archive' css={{ color: theme.colors.neutral70 }} />,
            onClick: () => archiveFax([listItem.id!] ?? [], locationId!, !listItem.archivedAt),
            trackingId:
              type === 'archived' ? `${FaxPrefixes.Archived}-unarchive-button` : `${FaxPrefixes.List}-archive-button`,
            showLabelOnHover: true,
            size: 'normal',
          };

    const threadInfo = {
      faxId: listItem.id ?? '',
      mediaId: listItem.mediaId ?? '',
      contactName: personName.trim(),
      contactPhone: contactPhone ?? '',
      contactId: listItem.faxContact?.contactId ?? '',
      profileMediaId: listItem.faxContact?.profileMediaId ?? '',
      profileMedia: profileMedia ?? '',
      locationId: locationId ?? '',
      direction: listItem.direction ?? '',
      status: listItem.status ?? '',
      isArchived: !!listItem.archivedAt,
      isBlocked,
      tags: listItem.tags ?? [],
      thumbnailIds: [],
      pages: 0,
    };

    const actions = [
      {
        actionIcon: () => <Icon name='preview' size={16} css={{ color: theme.colors.neutral70 }} />,
        label: t('Preview / Print'),
        action: () => previewFax(listItem.id ?? '', `${listItem.contactPhone}-fax-preview`, locationId!, true),
        trackingId: `${FaxPrefixes.List}-preview-button`,
      },
      {
        actionIcon: () => <Icon name='forward' size={16} css={{ color: theme.colors.neutral70 }} />,
        label: t('Forward'),
        action: () => {
          if (!selectedFax.faxId) {
            setSelectedFax(threadInfo);
          }
          openModal();
        },
        trackingId: `${FaxPrefixes.List}-forward-button`,
      },

      ...(type !== FaxTypes.InboxType.DRAFTS && !listItem.faxContact?.contactId
        ? [
            {
              actionIcon: () => <Icon name='user' size={16} css={{ color: theme.colors.neutral70 }} />,
              label: t('Add Contact'),
              action: () =>
                setShow(true, 'faxContactForm', {
                  contact: {
                    name: '',
                    secondName: '',
                    locationId: locationId!,
                    faxNumber: listItem.contactPhone,
                  },
                  action: FaxTypes.ContactAction.ADD,
                }),
              trackingId: `${FaxPrefixes.List}-add-contact-button`,
            },
          ]
        : [
            {
              actionIcon: () => <Icon name='user' size={16} css={{ color: theme.colors.neutral70 }} />,
              label: t('Edit Contact'),
              action: () => {
                setShow(true, 'faxContactForm', {
                  contactId: listItem.faxContact?.contactId ?? '',
                  locationId: locationId!,
                  action: FaxTypes.ContactAction.EDIT,
                });
              },
              trackingId: `${FaxPrefixes.List}-edit-contact-button`,
            },
          ]),
      ...(type !== FaxTypes.InboxType.DRAFTS && isInbound
        ? [
            {
              actionIcon: isRead
                ? () => <Icon name='message-unread' size={16} css={{ color: theme.colors.neutral70 }} />
                : () => <Icon name='message' size={16} css={{ color: theme.colors.neutral70 }} />,
              label: isRead ? t('Mark Unread') : t('Mark Read'),
              action: () => markReadFax(isRead, locationId!, [listItem.id!] ?? []),
              trackingId: `${FaxPrefixes.List}-mark-read-button`,
            },
          ]
        : []),
      ...(faxUpdatedFeaturesFlag
        ? [
            {
              actionIcon: () => <Icon name='label' size={16} css={{ color: theme.colors.neutral70 }} />,
              label: t('Add Tag'),
              action: () => openTagSelection(),
              trackingId: `${FaxPrefixes.List}-tag-button`,
            },
          ]
        : []),
      ...(type !== FaxTypes.InboxType.DRAFTS && isInbound && faxBlockFeatureFlag && type !== FaxTypes.InboxType.BLOCKED
        ? [
            {
              actionIcon: () => <Icon name='block' size={16} css={{ color: theme.colors.neutral70 }} />,
              label: t('Block'),
              action: () => blockFax(locationId!, listItem.contactPhone!),
              trackingId: `${FaxPrefixes.List}-block-sender-button`,
            },
          ]
        : []),
      ...(type === FaxTypes.InboxType.BLOCKED
        ? [
            ...(faxBlockFeatureFlag
              ? [
                  {
                    actionIcon: () => <Icon name='block' size={16} css={{ color: theme.colors.neutral70 }} />,
                    label: t('Unblock'),
                    action: () => unblockFax(locationId!, listItem.contactPhone!),
                    trackingId: `${FaxPrefixes.Blocked}-unblock-sender-button`,
                  },
                ]
              : []),
          ]
        : []),
      ...(enableFaxDeliveryReport && isOutbound && listItem.status === Status_Enum.DELIVERED
        ? [
            {
              actionIcon: () => <Icon name='download' size={16} css={{ color: theme.colors.neutral70 }} />,
              label: t('Download'),
              action: () => downloadFax(listItem.id ?? '', `${listItem.contactPhone}-fax-download`, locationId!, true),
              trackingId: `${FaxPrefixes.List}-download-button`,
              disableCloseOnSelect: true,
              popoverProps: downloadPopover,
              faxId,
              locationId,
            },
          ]
        : [
            {
              actionIcon: () => <Icon name='download' size={16} css={{ color: theme.colors.neutral70 }} />,
              label: t('Download'),
              action: () => downloadFax(listItem.id ?? '', `${listItem.contactPhone}-fax-download`, locationId!, true),
              trackingId: `${FaxPrefixes.List}-download-button`,
            },
          ]),
      {
        actionIcon: () => <Icon name='trash' size={16} css={{ color: theme.colors.neutral70 }} />,
        label: t('Delete Fax'),
        action: () => deleteFax(locationId!, [listItem.id!] ?? [], type),
        trackingId: `${FaxPrefixes.List}-delete-button`,
      },
    ];

    const status = getStatus();

    useEffect(() => {
      if (blob) {
        const url = URL.createObjectURL(blob);
        setProfileMedia(url);
      } else {
        setProfileMedia('');
      }

      return () => {
        if (profileMedia) {
          URL.revokeObjectURL(profileMedia);
        }
      };
    }, [blob]);

    const customOptionsFilter = (contact: Contact, inputValue: string) => {
      let formattedInput = inputValue.replace(/[\s-()]/g, '').toLowerCase();
      if (formattedInput.startsWith('+1')) {
        formattedInput = formattedInput.slice(2);
      } else if (formattedInput.startsWith('1')) {
        formattedInput = formattedInput.slice(1);
      }

      if (formattedInput.length === 10 && /^\d{10}$/.test(formattedInput)) {
        const filteredContacts = contacts?.filter(
          (contact) => contact.faxNumber?.replace(/[\s-()]/g, '') === formattedInput
        );
        if (filteredContacts?.length === 0) {
          setShowCustomMenuItem(true);
        }
      } else {
        setShowCustomMenuItem(false);
      }

      const value = `${contact.name ?? ''} ${contact.secondName ?? ''} ${contact.faxNumber}`.replace(/[\s-()]/g, '');

      return value.toLowerCase().includes(formattedInput);
    };

    const addCustomContactToForward = (value: string) => {
      const existingContact = contacts?.find(
        (contact) =>
          contact.faxNumber === value ||
          `${contact.name} ${contact.secondName}`.toLowerCase().includes(value.toLowerCase())
      );

      const number = phone(value, { format: 'numbers', hideCountry: true });

      const isNumeric = /^\d{10}$/.test(number);

      if (existingContact && existingContact.id) {
        setTo((prev) => [...prev, existingContact]);
        return;
      }

      if (isNumeric) {
        const contactData = {
          name: '',
          secondName: '',
          faxNumber: number ?? '',
          phoneNumber: '',
          profileMediaId: '',
          locationId: listItem.locationId,
        };

        upsertContact(contactData);
      } else {
        return alert.error(t('Invalid fax number'));
      }
      return;
    };

    return (
      <div ref={tagSelectionPopover.getTriggerProps().ref}>
        <FaxListRow
          css={actionableListRowStyles}
          Lead={
            <FaxInboxListItemLead
              isBulkSelect={isBulkSelect}
              personName={type !== FaxTypes.InboxType.DRAFTS ? personName : t('Draft')}
              contactId={listItem.faxContact?.contactId}
              locationId={locationId}
              isCheckboxSelected={isCheckboxSelected}
              setCheckboxSelected={setCheckboxSelected}
              profileMedia={profileMedia ?? ''}
              isInbound={isInbound}
            />
          }
          actions={actions}
          Title={
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                minWidth: 0,
                gap: theme.spacing(1),
              }}
              {...tooltipTriggerProps}
            >
              <ListItemStatus status={type === FaxTypes.InboxType.DRAFTS ? FaxTypes.FaxStatus.DRAFT : status} />
              <ListRow.Content.Title
                css={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                isBold={type !== FaxTypes.InboxType.DRAFTS && isInbound ? !isRead : false}
              >
                {type !== FaxTypes.InboxType.DRAFTS ? title : t('Draft')}
              </ListRow.Content.Title>
              <Tooltip {...tooltipProps}>{getHoverStatusMessage(status)}</Tooltip>
            </div>
          }
          Content={
            <>
              {selectedLocationIds.length > 1 && (
                <Chips.LocationChip
                  css={{
                    fontSize: theme.fontSize(12),
                    backgroundColor: isSelectedFax ? theme.colors.white : theme.colors.neutral5,
                  }}
                  className='inbox-item-chip'
                >
                  {getLocationName(locationId!)}
                </Chips.LocationChip>
              )}
              {faxUpdatedFeaturesFlag && type !== FaxTypes.InboxType.DRAFTS && (
                <InboxListItemTags
                  tags={tags}
                  locationId={locationId!}
                  faxId={listItem.id!}
                  tagSelectionIsOpen={tagSelectionIsOpen}
                  tagPopoverProps={tagPopoverProps}
                  getTagSelectionTriggerProps={getTagSelectionTriggerProps}
                  closeTagSelection={closeTagSelection}
                  setTagSearchValue={setTagSearchValue}
                  tagSearchValue={tagSearchValue}
                  addTag={addTag}
                />
              )}
            </>
          }
          archiveButtonProps={
            faxArchiveFeatureFlag &&
            type !== FaxTypes.InboxType.DRAFTS &&
            (listItem.direction === Direction_Enum.INBOUND || listItem.status === Status_Enum.FAILED_RETRYABLE)
              ? archiveButtonProps
              : undefined
          }
          timestamp={listItem.createdAt}
          tabIndex={0}
          key={listItem.id}
          id={listItem.id}
          onClick={async () => {
            navigate({
              to:
                type === FaxTypes.InboxType.DRAFTS ? `/fax/drafts/${listItem.id}/cover` : `/fax/${type}/${listItem.id}`,
            });
            if (!isRead && isInbound) {
              try {
                await SchemaFaxService.UpdateFaxes({
                  hasBeenRead: !isRead,
                  locationId,
                  faxIds: [listItem.id!] ?? [],
                });
                invalidateFaxInboxList();
                invalidateFaxCount();
              } catch (error) {
                console.error('Error updating fax', error);
              }
            }

            if (
              selectedFax.faxId === listItem.id &&
              selectedFax.mediaId === listItem.mediaId &&
              selectedFax.thumbnailIds.length > 0
            ) {
              return;
            }

            setSelectedFax(threadInfo);

            const res = await generateMediaAttributes({ mediaId: listItem.mediaId ?? '', locationId });

            setSelectedFax({
              ...threadInfo,
              pages: res.attributes.pages,
              thumbnailIds: res.attributes.largeThumbnails ?? [],
            });
          }}
          isSelected={isSelectedFax}
          data-trackingid={`${FaxPrefixes.List}-inbox-item`}
        />
        <PopoverMenu
          {...tagSelectionPopover.getMenuProps()}
          initialFocus={-1}
          returnFocus={false}
          css={{
            overflow: 'hidden',
            width: '300px',
            padding: theme.spacing(2),
            gap: theme.spacing(1),
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: theme.spacing(1),
            }}
          >
            {filteredLocationTags.length > 0 ? (
              filteredLocationTags.map((tag) => (
                <Chip.Tag
                  key={tag.id}
                  size='large'
                  color={TagsUtils.convertStringToTagColor(tag.color)}
                  onClick={async () => {
                    const tagId = tag.id || tag.smartTagId;
                    try {
                      await tagFax(locationId!, [listItem.id!] ?? [], [tagId]);
                      if (selectedFax.faxId === listItem.id) {
                        setSelectedFax({
                          ...selectedFax,
                          tags: [...selectedFax.tags, tagId],
                        });
                      }
                    } catch (error) {
                      console.error('Error tagging fax', error);
                    }

                    tagSelectionPopover.close();
                  }}
                  trackingId={`${FaxPrefixes.List}-add-existing-tag`}
                >
                  {tag.name}
                </Chip.Tag>
              ))
            ) : (
              <Text css={{ width: '100%', paddingBottom: theme.spacing(1) }} textAlign='center' color='light'>
                {t('No matching tags')}
              </Text>
            )}
          </div>
          {hasTagACL && (
            <div css={{ borderTop: `1px solid ${theme.colors.neutral20}`, paddingTop: theme.spacing(1) }}>
              <TextButton
                css={{ color: theme.colors.primary50, fontWeight: 'bolder' }}
                onClick={() => {
                  settingsNavigate({ to: '/tags/quick-create' });
                  tagSelectionPopover.close();
                }}
                trackingId={`${FaxPrefixes.List}-create-new-tag`}
              >
                {t('Create New Tag')}
              </TextButton>
            </div>
          )}
        </PopoverMenu>
        <Modal {...forwardModalProps} minWidth={480} onClose={handleForwardModalClose}>
          <Modal.Header>{t('Forward')}</Modal.Header>
          <Modal.Body css={{ paddingTop: theme.spacing(2) }}>
            {contacts ? (
              <ChipCombobox<Contact>
                label={t('Select Recipients')}
                clearable={false}
                options={selectedLocationIds.length > 1 ? singleLocationContacts ?? contacts : contacts}
                tags={to}
                name='to'
                onTagsChange={(tag) => {
                  setTo(tag);
                }}
                MenuItem={FaxContactItem}
                ChipComponent={ContactChip}
                accessor={(contact) => `${contact.name ?? ''} ${contact.secondName ?? ''} ${contact.faxNumber}`}
                itemHeight={62}
                itemWidth='100%'
                menuProps={{
                  placement: 'bottom-start',
                }}
                menuStyles={css`
                  width: min(100vw, 600px);
                `}
                onCustomSubmission={addCustomContactToForward}
                CustomSubmissionMenuItem={AddContactMenuItem}
                allowCustomSubmission={showCustomMenuItem}
                tabComplete
                customOptionsFilter={customOptionsFilter}
              />
            ) : null}
          </Modal.Body>
          {isForwardingFax ? (
            <SpinningLoader
              size='small'
              css={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: theme.spacing(3),
              }}
            />
          ) : (
            <Modal.Actions
              primaryLabel={t('Forward')}
              onPrimaryClick={() => {
                forwardFax({
                  faxId: listItem.id!,
                  locationId: locationId!,
                  contactIds: to.map((contact) => contact.id),
                });
              }}
              secondaryLabel={t('Cancel')}
              onSecondaryClick={handleForwardModalClose}
            />
          )}
        </Modal>
      </div>
    );
  };

type FaxContactItemProps = { item: Contact };
const FaxContactItem = ({ item: { name, secondName, faxNumber, profileMediaId } }: FaxContactItemProps) => {
  const { t } = useTranslation('fax');
  return (
    <ListRow style={{ padding: theme.spacing(1), cursor: 'pointer' }}>
      <ListRow.Lead>
        <Avatar
          size='small'
          src={profileMediaId || undefined}
          css={css`
            svg {
              margin-right: 0;
            }
            figure {
              font-size: 0.6em;
            }
          `}
        />
      </ListRow.Lead>
      <ListRow.Content css={{ textAlign: 'initial' }}>
        <Text weight='bold'>{`${name ?? ''} ${secondName ?? ''}`}</Text>
        <Text color='subdued' size={'medium'}>
          {faxNumber ? phone(faxNumber, { hideCountry: true }) : t('No Phone Number')}
        </Text>
      </ListRow.Content>
    </ListRow>
  );
};

const ContactChip = ({ children, onClick }: { children?: string; onClick?: () => void }) => {
  const { selectedFax } = useSelectedFaxShallowStore('selectedFax', 'setSelectedFax');
  const { locationId } = selectedFax;
  const { setShow } = useSlidePanelShallowStore('setShow');

  const inputString = children && children.length > 0 ? children.trim() : '';

  const { data: contacts } = FaxQueries.useQueryFaxContacts(locationId, [locationId], 1000, {});

  const lastIndex = inputString.lastIndexOf(' ');
  const contactName = lastIndex > -1 ? inputString.substring(0, lastIndex) : inputString;
  return (
    <div
      onClick={() => {
        const existingContact = contacts?.find(
          (contact) =>
            contact.faxNumber === contactName ||
            `${contact.name} ${contact.secondName}`.toLowerCase().includes(contactName.toLowerCase())
        );
        if (existingContact) {
          setShow(true, 'faxContactForm', {
            contactId: existingContact.id,
            locationId: locationId,
            action: FaxTypes.ContactAction.EDIT,
          });
        }
      }}
    >
      <Chip.Removable
        css={css`
          width: auto;
          max-width: 160px;
          cursor: pointer;
        `}
        variant='default'
        onClick={(e) => {
          e.stopPropagation();
          onClick?.();
        }}
        leftElement={<Avatar name={/^\d+$/.test(contactName.trim()) ? '' : contactName} size='xxs' />}
      >
        {/^\d+$/.test(contactName.trim()) ? phone(contactName, { hideCountry: true }) : contactName}
      </Chip.Removable>
    </div>
  );
};

const AddContactMenuItem = ({ value }: { value: string }) => {
  const number = phone(value, { hideCountry: true });
  return (
    <>
      <li
        css={{
          cursor: 'pointer',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {number}
      </li>
    </>
  );
};

const actionableListRowStyles = css`
  padding: ${theme.spacing(3)};
  padding-left: ${theme.spacing(2.75)};
  overflow-x: hidden;

  &:hover .inbox-item-chip {
    background-color: ${theme.colors.white};
  }
`;
