import { FC } from 'react';

import { Actions } from '@frontend/contact-actions';
import { PersonTypes } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';

interface ContactActionsProps {
  onCall?: () => void;
  person: PersonTypes.Person;
  locationId?: string;
}

export const ContactActions: FC<React.PropsWithChildren<ContactActionsProps>> = ({ person, locationId }) => {
  const { singleLocationId } = useAppScopeStore();
  const { t } = useTranslation('contacts');

  return (
    <AdaptoActions maxActions={5}>
      <Actions.Call label={t('Call')} context={{ person }} trackingIdSuffix='profile-contact-actions' />

      <Actions.Message
        label={t('Message')}
        context={{ person, locationId: locationId || singleLocationId }}
        trackingIdSuffix='contact-panel'
      />

      <Actions.SendForm
        label={t('Send Form')}
        context={{
          locationId: singleLocationId,
          person,
        }}
        trackingIdSuffix='profile-contact-actions'
      />

      <Actions.QuickFill
        label={t('Add to Quick Fill')}
        personId={person.PersonID}
        trackingIdSuffix='profile-contact-actions'
      />

      <Actions.Review
        label={t('Review')}
        groupId={locationId || singleLocationId}
        personId={person.PersonID}
        trackingIdSuffix='-profile-contact-actions'
      />

      <Actions.Payments
        label={t('Request Payment')}
        context={{ person, personId: person.PersonID, locationId: singleLocationId }}
        trackingIdSuffix='profile-contact-actions'
      />
    </AdaptoActions>
  );
};
