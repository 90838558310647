import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { FallbackRender } from '@sentry/react/types/errorboundary';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CaretDownIconSmall, CaretRightIconSmall, IconButton } from '@frontend/design-system';
import { ErrorPage } from './error-page';
// import Laugh from './kefka-laugh.mp3';

type Props = Parameters<FallbackRender>[0];

export const StackErrorPage = ({ error }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showingDetails, setShowingDetails] = useState(false);
  const loc = useLocation();

  //Once the error occurs, treat the next location change with a full refresh
  useEffect(() => {
    loc.subscribe(() => {
      window.location.reload();
    });
  }, []);

  return (
    <ErrorPage
      title={t('Hmm... ')}
      subTitle={t(
        'We ran into an error on the page you were viewing. The error has been reported. If it keeps happening please reach out to our Customer Support'
      )}
      primaryButtonText={t('Try Again')}
      primaryButtonOnClick={() => window.location.reload()}
      secondaryButtonText={t('Go to Home Page')}
      secondaryButtonOnClick={() => navigate({ to: '/portal/' })}
    >
      <IconButton
        size='small'
        label='Show Details'
        onClick={() => setShowingDetails(!showingDetails)}
        css={css`
          margin-top: ${theme.spacing(6)};
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        {t('Show Error Details')}{' '}
        {!showingDetails ? (
          <CaretDownIconSmall
            fontSize={7}
            css={css`
              margin-left: ${theme.spacing(1)};
            `}
          />
        ) : (
          <CaretRightIconSmall
            fontSize={7}
            css={css`
              margin-left: ${theme.spacing(1)};
            `}
          />
        )}
      </IconButton>
      {showingDetails && (
        <>
          {/* For when we decide to add some easter eggs
            <audio src={Laugh} controls={undefined} autoPlay={true} />
            <img
              src='https://media2.giphy.com/media/BzyTuYCmvSORqs1ABM/giphy-downsized.gif?cid=6104955ebbkj2kqna9wbod2slbou0l0pdccy6g6bp5c9ooia&rid=giphy-downsized.gif&ct=g'
              alt='Cat'
            /> */}
          <pre
            css={css`
              overflow: auto;
              padding: ${theme.spacing(2)};
              background: ${theme.colors.neutral10};
              border-radius: ${theme.borderRadius.small};
              max-width: 800px;
            `}
          >
            {error instanceof Error ? error?.stack : <code>Hmm, no error to speak of</code>}
          </pre>
        </>
      )}
    </ErrorPage>
  );
};
