import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const locationSectionStyle = css`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${breakpoints.small.min}px) {
    flex-direction: column;
    gap: ${theme.spacing(2)};
  }
`;

export const settingsLocationPickerStyle = css`
  @media only screen and (max-width: ${breakpoints.small.min}px) {
    width: 136px;
  }
`;

export const emptyOverrideSectionStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${breakpoints.small.min}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing(2)};
  }
`;

export const overrideSectionHeadingStyle = css`
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${breakpoints.small.min}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing(2)};
  }
`;
export const overrideSectionChipStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};

  @media only screen and (max-width: ${breakpoints.small.min}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const overrideSectionInfoStyle = css`
  @media only screen and (min-width: ${breakpoints.small.min}px) {
    display: grid;
    grid-template-areas: 'type media' 'start end';
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: ${theme.spacing(2)} ${theme.spacing(4)};
  }
`;

export const overrideSettingsStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
`;

export const overrideRadioFieldStyle = css`
  display: flex;
  gap: ${theme.spacing(2)};

  @media only screen and (max-width: ${breakpoints.small.min}px) {
    flex-direction: column;
    width: 300px;
  }
`;

export const overrideButtonSectionStyle = css`
  display: flex;
  justify-content: end;
  gap: ${theme.spacing(3)};
  position: absolute;
  bottom: ${theme.spacing(4)};
  right: ${theme.spacing(4)};

  @media only screen and (max-width: ${breakpoints.small.min}px) {
    position: relative;
    margin-top: ${theme.spacing(2)};
    bottom: 0;
    right: 0;
    gap: ${theme.spacing(2)};
  }
`;
