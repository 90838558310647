import { css } from '@emotion/react';
import { Button } from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useSoftphoneCallState } from '../../providers/softphone-call-state-provider';
import { useSoftphoneCallActions } from '../../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { useSoftphoneRouter } from '../../providers/softphone-router-provider';
import { isEstablishedCall, isOutgoingCall } from '../../types';

export const CallBarContent = () => {
  const { t } = useTranslation('softphone');
  const mergedCallGroup = useSoftphoneCallState((ctx) => ctx.mergedCallGroup);
  const endCall = useSoftphoneCallActions((ctx) => ctx.endCall);
  const toggleHold = useSoftphoneCallActions((ctx) => ctx.toggleHold);
  const toggleHoldMergedCalls = useSoftphoneCallActions((ctx) => ctx.toggleHoldMergedCalls);
  const calls = useSoftphoneCallState((ctx) => ctx.calls);
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const toggleMute = useSoftphoneCallActions((ctx) => ctx.toggleMute);
  const isMuted = useSoftphoneCallState((ctx) => ctx.isMuted);
  const currentRoute = useSoftphoneRouter((ctx) => ctx.currentRoute);

  const connected = primaryCall && isEstablishedCall(primaryCall);
  if (!primaryCall) return null;
  const otherCallsOnHold = calls.filter(
    (call) => isEstablishedCall(call) && !!call.onHoldSince && primaryCall.id !== call.id
  ).length;

  const hold = () => {
    if (!connected || !primaryCall || !isEstablishedCall(primaryCall)) {
      return;
    }
    if (mergedCallGroup?.length) {
      //if any calls are on hold, we will unhold all, other wise hold all
      const isHolding = mergedCallGroup?.some((call) => !!call.onHoldSince);
      toggleHoldMergedCalls(!isHolding);
    } else {
      toggleHold(primaryCall, !primaryCall.onHoldSince);
    }
  };

  const end = async () => {
    if (mergedCallGroup?.length) {
      const calls = mergedCallGroup.filter(isEstablishedCall);
      for (const call of calls) {
        await endCall(call);
      }
    } else {
      endCall(primaryCall);
    }
  };

  const holdIconSelected = connected && !!primaryCall.onHoldSince;
  return (
    <>
      <Button.Round
        trackingId={currentRoute.section === 'attended-transfer' ? 'softphone-transfer-mute' : 'softphone-active-mute'}
        onClick={(e) => {
          e.stopPropagation();
          toggleMute(!isMuted);
        }}
        selected={isMuted}
        disabled={!connected}
        color={isMuted ? 'inverted' : 'transparent'}
        size='small'
        iconName={isMuted ? 'mute' : 'microphone'}
      ></Button.Round>
      <Button.Round
        trackingId='softphone-active-hold'
        disabled={!connected}
        selected={holdIconSelected}
        onClick={(e) => {
          e.stopPropagation();
          hold();
        }}
        color={holdIconSelected ? 'inverted' : 'transparent'}
        size='small'
        iconName={'pause'}
      ></Button.Round>
      <Button.Round
        trackingId={
          currentRoute.section === 'attended-transfer' ? 'softphone-transfer-end' : 'softphone-active-endcall'
        }
        disabled={!(isEstablishedCall(primaryCall) || isOutgoingCall(primaryCall))}
        onClick={(e) => {
          e.stopPropagation();
          end();
        }}
        iconSize={20}
        size='small'
        color='red'
        iconName={'phone-hangup'}
      ></Button.Round>
      {otherCallsOnHold > 0 && (
        <Text
          weight='bold'
          size='medium'
          css={css`
            border-left: 1px solid ${theme.colors.neutral50};
            color: ${theme.colors.neutral30};
            padding: 8px;
          `}
        >
          {t('+{{count}} Hold', { count: otherCallsOnHold })}
        </Text>
      )}
    </>
  );
};
