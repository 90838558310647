import { useMutation } from 'react-query';
import { Options } from '@frontend/fetch';
import { LimitedSchemaMutationOptions } from '@frontend/react-query-helpers';
import { SchemaSmsReplyAssistantV1Service } from '../service';
import { SubmitFeedbackIO } from '../types';

export const getSubmitFeedbackMutationKey = () => ['SmsReplyAssistantV1', 'SubmitFeedback'] as const;

type MutationContext<C = unknown> = {
  // Add internal mutation context here if needed (useful for optimistic updates)
  otherContext?: C;
};

type UseSubmitFeedbackMutationArgs<
  E = unknown,
  C = unknown,
  RequestOverride extends SubmitFeedbackIO['input'] = SubmitFeedbackIO['input']
> = {
  options?: LimitedSchemaMutationOptions<SubmitFeedbackIO, E, C, never, RequestOverride>;
  httpOptions?: Options;
  optimisticUpdate?: boolean;
};
/**
 * A hook that returns a mutation for the `SubmitFeedback` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useSubmitFeedbackMutation = <
  E = unknown,
  C = unknown,
  RequestOverride extends SubmitFeedbackIO['input'] = SubmitFeedbackIO['input']
>({ options, httpOptions }: UseSubmitFeedbackMutationArgs<E, C | undefined, RequestOverride> = {}) =>
  useMutation<SubmitFeedbackIO['output'], E, RequestOverride, MutationContext<C>>({
    mutationKey: getSubmitFeedbackMutationKey(),
    mutationFn: (req) => SchemaSmsReplyAssistantV1Service.SubmitFeedback(req, httpOptions),
    ...options,
    onMutate: async (request) => {
      // Implement optimistic updates here

      return {
        // Add custom context type here (if exists in `MutationContext`)
        otherContext: await options?.onMutate?.(request),
      };
    },
    onSuccess: (response, request, context) => {
      // Implement query updates or invalidation for affected queries here

      return options?.onSuccess?.(response, request, context?.otherContext);
    },
    onError: (error, request, context) => {
      // Revert optimistic update here (if implemented)

      return options?.onError?.(error, request, context?.otherContext);
    },
    onSettled: (response, error, request, context) => {
      // Only pass context of type C into provided `onSettled` option
      return options?.onSettled?.(response, error, request, context?.otherContext);
    },
  });
