import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Chip } from '@frontend/design-system';
import { useAppointmentsCalendarViewProps } from '../../../../context/AppointmentsCalendarViewContext';

type LocationChipProps = {
  showNumberOfLocations?: boolean;
};

export const LocationChip = ({ showNumberOfLocations = false }: LocationChipProps) => {
  const { t } = useTranslation('schedule');
  const { hasOnlyOneLocation, selectedLocationIds } = useAppScopeStore();
  const { selectedLocationIds: calendarLocationIds } = useAppointmentsCalendarViewProps();

  const chipContent = () => {
    if (showNumberOfLocations) {
      return <>{t(`{{count}} Locations`, { count: calendarLocationIds.length })}</>;
    }
    return null;
  };

  const hasMultiLocation = calendarLocationIds.length > 1;

  return (
    <>
      {!hasOnlyOneLocation &&
        (calendarLocationIds.length <= selectedLocationIds.length && hasMultiLocation ? (
          <Chip.MultiChip
            css={{ alignSelf: 'flex-end', maxWidth: 'unset', margin: theme.spacing(2), marginBottom: '21px' }}
          >
            {chipContent()}
          </Chip.MultiChip>
        ) : (
          <Chip.SingleChip
            css={{
              alignSelf: 'flex-end',
              width: 'fit-content',
              maxWidth: 'unset',
              margin: theme.spacing(2),
              marginBottom: '21px',
            }}
          >
            {chipContent()}
          </Chip.SingleChip>
        ))}
    </>
  );
};
