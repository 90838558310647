import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { StyleProps, ButtonStyleValues } from '../button.type';
import { baseButtonStyle, pressedStyle, hoverStyle } from './common.styles';

const tertiaryButtonTheme: ButtonStyleValues = {
  backgroundColor: theme.colors.white,
  color: theme.colors.primary50,
  focusRingColor: theme.colors.primary50,
  hoverColor: theme.colors.primary5,
  pressed: {
    backgroundColor: theme.colors.primary5,
    color: theme.colors.primary70,
  },
  loading: {
    backgroundColor: 'transparent',
    borderColor: theme.colors.neutral30,
  },
  disabled: {
    backgroundColor: theme.colors.white,
    color: theme.colors.status.disabled,
    secondaryColor: theme.colors.neutral20,
    borderColor: theme.colors.status.disabled,
  },
};

export const tertiaryStyles = ({ size, isLoading, iconOnly }: StyleProps) => [
  baseButtonStyle({
    focusRingColor: tertiaryButtonTheme.focusRingColor,
    size: size ?? 'small',
    iconOnly,
  }),
  css`
    background: ${tertiaryButtonTheme.backgroundColor};
    border: none;
    color: ${tertiaryButtonTheme.color};
  `,
  isLoading
    ? css`
        background: ${tertiaryButtonTheme.loading.backgroundColor};
        &[aria-disabled='true'] {
          box-shadow: none;
          color: ${tertiaryButtonTheme.disabled.color};
        }
      `
    : css`
        :hover {
          ${hoverStyle({ hoverColor: tertiaryButtonTheme.hoverColor, borderColor: tertiaryButtonTheme.borderColor })};
        }
        &[aria-disabled='true'] {
          background: ${tertiaryButtonTheme.disabled.backgroundColor};
          border-color: ${tertiaryButtonTheme.disabled.borderColor};
          color: ${tertiaryButtonTheme.disabled.color};
        }
        ${pressedStyle({ pressed: tertiaryButtonTheme.pressed })}
      `,
];
