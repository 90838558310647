import { NakedButton } from '@frontend/design-system';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { RoundButton } from './round-button';
import { RectButton } from './rect-button';

export type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  type?: 'button' | 'submit';
  className?: string;
  active?: boolean;
  color?: 'green' | 'red' | 'blue' | 'default' | 'transparent' | 'inverted';
  selected?: boolean;
  trackingId?: string;
  children: React.ReactNode;
  useDisabledColor?: boolean;
};

export const Button = ({
  type = 'button',
  className,
  active,
  color = 'default',
  selected,
  children,
  trackingId,
  useDisabledColor = false, // by default, it will use opacity 0.3 for disabled state
  ...rest
}: IconButtonProps) => {
  const backgroundColorStyles = {
    default: `${theme.colors.neutral70}`,
    red: `${theme.colors.critical50}`,
    green: `${theme.colors.success40}`,
    blue: `${theme.colors.primary50}`,
    inverted: `${theme.colors.neutral10}`,
    transparent: 'transparent',
  } as const;

  const hoverStyles = {
    default: `${theme.colors.neutral80}`,
    red: `${theme.colors.critical40}`,
    green: `${theme.colors.success30}`,
    blue: `${theme.colors.primary40}`,
    inverted: `${theme.colors.neutral40}`,
    transparent: `${theme.colors.neutral80}`,
  } as const;

  const fontColor = {
    default: `${theme.colors.white}`,
    red: `${theme.colors.white}`,
    green: `${theme.colors.white}`,
    blue: `${theme.colors.white}`,
    inverted: `${theme.colors.neutral70}`,
    transparent: `${theme.colors.white}`,
  };

  const disabledBgColorStyles = {
    green: `${theme.colors.success20}`,
    // the below colors are given any lighter shade values, feel free to change this according the latest designs
    default: `${theme.colors.neutral50}`,
    red: `${theme.colors.critical30}`,
    blue: `${theme.colors.primary30}`,
    inverted: `${theme.colors.neutral30}`,
    transparent: 'transparent',
  } as const;

  const disabledFontColorStyles = {
    ...fontColor,
    green: `${theme.colors.neutral10}`,
  } as const;

  return (
    <NakedButton
      trackingId={trackingId}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${backgroundColorStyles[color]};
        color: ${fontColor[color]};

        &:disabled {
          pointer-events: none;
          color: ${!!useDisabledColor ? disabledFontColorStyles[color] : fontColor[color]};
          ${!!useDisabledColor && disabledBgColorStyles[color]
            ? `background-color: ${disabledBgColorStyles[color]};`
            : `opacity: 0.3;`}
        }

        &:hover:not([disabled]) {
          background-color: ${hoverStyles[color]};
        }

        ${selected &&
        `
          color: ${fontColor[color]};
          background-color: ${theme.colors.neutral20};
          svg path {
            fill: ${theme.colors.black};
          }

          &:hover:not([disabled]) {
            background-color: ${theme.colors.neutral20};
          }

        `}
      `}
      className={className}
      type={type}
      {...rest}
    >
      {children}
    </NakedButton>
  );
};

Button.Round = RoundButton;
Button.Rect = RectButton;
