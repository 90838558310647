import { useTranslation } from '@frontend/i18n';
import { useCallDuration } from '../../hooks/use-call-duration';
import { Row } from '../generic/row/row';
import { EstablishedCall } from '../../types';
import { useSoftphoneCallActions } from '../../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { UNKNOWN_TITLE_NAME, useCallerInfo } from '../../hooks/use-caller-info';
import { useState } from 'react';
import { useSoftphoneWidgetControl } from '../../providers/widget-control-provider';

type Props = { call: EstablishedCall; onClick?: () => void; disabled?: boolean };
export const HeldCall = ({ call, onClick, disabled }: Props) => {
  const { t } = useTranslation('softphone');
  const { title, isLoading, phoneNumber } = useCallerInfo({
    uri: call.remoteParty.uri,
    displayName: call.remoteParty.displayName,
  });
  const { unHoldAndSetAsPrimary } = useSoftphoneCallActions((ctx) => ctx);
  const duration = useCallDuration(call.onHoldSince ?? call.establishedAt);
  const [isHoverActive, setIsHoverActive] = useState(false);
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);

  if (isLoading) {
    return (
      <Row>
        <Row.StatusDot status='warning' />
        <Row.Title title={t('Loading')}></Row.Title>
      </Row>
    );
  }

  const heldCallerName = title === t(`${UNKNOWN_TITLE_NAME}`) && phoneNumber ? phoneNumber : title;

  return (
    <Row
      disabled={disabled}
      css={css`
        margin-bottom: ${theme.spacing(1)};
      `}
      onClick={() => (onClick ? onClick() : unHoldAndSetAsPrimary(call))}
      onMouseLeave={() => setIsHoverActive(false)}
      onMouseEnter={() => setIsHoverActive(true)}
    >
      <Row.StatusDot status='warning' />
      <Row.Title title={heldCallerName} />
      {!isHoverActive && <Row.Subtitle subtitle={duration ?? ''} />}
      {(isHoverActive || isMobile) && (
        <Row.Subtitle
          css={css`
            margin-left: auto;
          `}
          subtitle={t('Swap Call')}
        />
      )}
    </Row>
  );
};
