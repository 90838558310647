import { ElementType } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { NakedUl, PolymorphicComponentPropWithoutRef, SpinningLoader, Text } from '@frontend/design-system';
import { Category, FormOrPacketV2 } from '../../types/form-packet-selector.types';
import { FormPacketListItem } from './form-packet-list-item';

type FormPacketListProps = {
  items: FormOrPacketV2[];
  isLoading: boolean;
  onSelect: (item: FormOrPacketV2) => void;
  selectedId?: string;
  category: Category;
};

type PolymorphicProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<C, FormPacketListProps>;

export const FormPacketList = <C extends ElementType = 'ul'>({
  as,
  items,
  isLoading,
  onSelect,
  selectedId,
  category,
  ...rest
}: PolymorphicProps<C>) => {
  const { t } = useTranslation('form-packet-selector');
  const Component = as || NakedUl;

  return (
    <Component {...rest}>
      {isLoading ? (
        <div
          css={{
            width: '100%',
            paddingTop: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SpinningLoader />
        </div>
      ) : items.length ? (
        items.map((item) => (
          <FormPacketListItem
            key={item.id}
            isSelected={selectedId === item.id}
            name={item.name ?? ''}
            onSelect={() => onSelect(item)}
            iconName={category === Category.FORMS ? 'forms' : 'packet'}
          />
        ))
      ) : (
        <Text css={{ paddingTop: theme.spacing(2) }} textAlign='center'>
          {t('No items to display.')}
        </Text>
      )}
    </Component>
  );
};
