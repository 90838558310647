import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { theme } from '@frontend/theme';

export const getMessageStatusColors = (status: OutboundMessageStatus) => {
  switch (status) {
    case OutboundMessageStatus.DELIVERED:
      return { backgroundColor: theme.colors.primary10, textColor: theme.colors.primary70 };
    case OutboundMessageStatus.ERROR:
      return { backgroundColor: theme.colors.critical10, textColor: theme.colors.critical70 };
    case OutboundMessageStatus.INVALID_DESTINATION:
      return { backgroundColor: theme.colors.neutral20, textColor: theme.colors.neutral90 };
    case OutboundMessageStatus.NOT_SENT:
      return { backgroundColor: theme.colors.secondary.eggplant10, textColor: theme.colors.secondary.eggplant70 };
    case OutboundMessageStatus.OPTED_OUT:
      return { backgroundColor: theme.colors.neutral10, textColor: theme.colors.neutral30 };
    case OutboundMessageStatus.SCHEDULED:
      return { backgroundColor: theme.colors.warning5, textColor: theme.colors.warning60 };
    case OutboundMessageStatus.VOICEMAIL:
    default:
      return { backgroundColor: theme.colors.tangerine10, textColor: theme.colors.tangerine70 };
  }
};
