import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css({ display: 'flex', gap: theme.spacing(1), alignItems: 'center' });

export const iconButtonStyle = css({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0.5),
  minWidth: '100%',
  svg: {
    padding: theme.spacing(0.5),
    borderRadius: theme.borderRadius.small,
    backgroundColor: theme.colors.neutral10,
    hover: {
      backgroundColor: theme.colors.neutral10,
    },
  },
});
