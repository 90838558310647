import React, { PropsWithChildren, ReactNode, useMemo, MouseEventHandler } from 'react';
import { css } from '@emotion/react';
import { Params, useMatch, useNavigate } from '@tanstack/react-location';
import { pick } from 'lodash-es';
import { useQuery } from 'react-query';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import {
  AppLink,
  constructRoutePath,
  SettingsLink,
  settingsRouteLookup,
  SettingsRoutes,
  StaticSettingsRoutes,
  useSettingsPathLabelMap,
} from '@frontend/settings-routing';
import { extractAndFormatPathName } from '@frontend/string';
import { theme } from '@frontend/theme';
import {
  BreadcrumbPathProps,
  Breadcrumbs,
  Chip,
  SkeletonLoader,
  SpinningLoader,
  Text,
  TextLink,
} from '@frontend/design-system';
import { PageTitle } from '../page-title';
import { ExpandText } from './expand-text';

type SettingsBreadcrumbs = {
  to: SettingsRoutes;
  params?: Record<string, string>;
  label: string;
  onClick?: MouseEventHandler<HTMLElement>;
};

type MaxWidthProp = string | number | null;

export type PageProps<IsSettings extends boolean = false> = PropsWithChildren<{
  action?: React.ReactNode;
  breadcrumbs?: IsSettings extends false ? BreadcrumbPathProps[] : SettingsBreadcrumbs[];
  children?: ReactNode;
  className?: string;
  customBackUrl?: string;
  customBackFn?: () => void;
  icon?: string;
  id?: string;
  loading?: boolean;
  maxWidth?: MaxWidthProp;
  moreSubtitle?: string;
  showBackBtn?: boolean;
  style?: Record<string, any>;
  subtitle?: string | React.ReactNode;
  title?: React.ReactNode;
  isSettingsPage?: IsSettings;
  customHeader?: React.ReactNode;
  settingsLocationConfig?: SettingsLocationChipProps;
}>;

const maxWidthStyle = (val: PageProps['maxWidth']) => {
  if (val === null) {
    return null;
  } else if (typeof val === 'number') {
    return css`
      max-width: ${val}px;
    `;
  } else {
    return css`
      max-width: ${val};
    `;
  }
};

const page = (maxWidth: MaxWidthProp, isSettingsPage: boolean | undefined) => {
  return css`
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    min-height: 100%;
    container-type: inline-size;
    opacity: 0;
    animation: fadeIn ease 0.15s;
    animation-fill-mode: forwards;
    display: grid;
    grid-template-areas: 'header' 'content';
    grid-template-rows: auto 1fr;
    grid-template-columns: 100%;
    background-color: ${isSettingsPage ? theme.colors.neutral5 : theme.colors.white};
    width: 100%;
    font-size: ${theme.font.size.medium};
    padding: ${theme.spacing(4)};
    ${maxWidthStyle(maxWidth) ?? 'max-width: 1000px;'}

    @media (max-width: ${breakpoints.small.max}px) {
      padding: ${theme.spacing(3)};
    }
  `;
};

const pageHeader = css`
  margin-bottom: ${theme.spacing(4)};
  grid-area: header;
`;

const pageContent = css`
  grid-area: content;
`;

const renderBreadcrumbs = (isSettingsPage?: boolean, breadcrumbs?: BreadcrumbPathProps[] | SettingsBreadcrumbs[]) => {
  if (isSettingsPage) {
    return breadcrumbs ? (
      <SettingsBreadcrumbs breadcrumbs={breadcrumbs as SettingsBreadcrumbs[]} />
    ) : (
      <AutoSettingsBreadcrumbs />
    );
  }

  return (
    breadcrumbs && (
      <Breadcrumbs css={{ marginBottom: theme.spacing(1) }}>
        {breadcrumbs.map(({ to, label, ...rest }) => (
          <Breadcrumbs.Path key={label} as={AppLink} to={to} {...rest}>
            {label}
          </Breadcrumbs.Path>
        ))}
      </Breadcrumbs>
    )
  );
};

export const Page = <IsSettings extends boolean = false>({
  action,
  breadcrumbs,
  children,
  className,
  customBackUrl,
  customBackFn,
  customHeader,
  id,
  loading,
  maxWidth = 1400,
  moreSubtitle,
  showBackBtn = false,
  subtitle,
  title,
  isSettingsPage,
  settingsLocationConfig,
  ...rest
}: PageProps<IsSettings>) => {
  const navigate = useNavigate();
  if (loading) {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <SpinningLoader size='xl' />
      </div>
    );
  }

  let onBackClick: () => void = () => history.go(-1);
  if (customBackFn) {
    onBackClick = customBackFn;
  } else if (customBackUrl) {
    onBackClick = () => navigate({ to: customBackUrl });
  }

  const shouldShowHeaderContent = !!title || !!subtitle || !!action;

  return (
    <div css={page(maxWidth, isSettingsPage)} className={className} id={id} {...rest}>
      {customHeader ? (
        customHeader
      ) : (
        <header css={pageHeader} className='page-header'>
          {renderBreadcrumbs(isSettingsPage, breadcrumbs)}
          {shouldShowHeaderContent && (
            <HeaderContent
              title={title}
              subtitle={subtitle}
              moreSubtitle={moreSubtitle}
              action={action}
              showBackBtn={showBackBtn}
              onBackClick={onBackClick}
              isSettingsPage={isSettingsPage}
              settingsLocationConfig={settingsLocationConfig}
            />
          )}
        </header>
      )}
      <div css={pageContent}>{children}</div>
    </div>
  );
};

export const HeaderContent = <IsSettings extends boolean = false>({
  action,
  moreSubtitle,
  showBackBtn = false,
  onBackClick,
  subtitle,
  title,
  isSettingsPage,
  settingsLocationConfig,
}: PageProps<IsSettings> & { onBackClick?: () => void }) => {
  return (
    <div
      css={{
        display: 'grid',
        gridTemplateAreas: '"title title actions" "subtitle subtitle subtitle"',
        gridTemplateColumns: 'repeat(auto-fit, minmax(0, auto))',
        gridTemplateRows: 'auto auto',
        alignItems: 'center',
        gap: theme.spacing(1),

        [`@container (max-width:  ${breakpoints.small.max}px)`]: {
          gridTemplateAreas: '"title title" "subtitle subtitle" "actions actions"',
          gridTemplateRows: 'auto auto auto',
        },

        [`@container (max-width:  ${breakpoints.xsmall.max}px)`]: {
          gridTemplateAreas: '"title" "subtitle" "actions"',
          gridTemplateRows: 'auto auto auto',
        },
      }}
    >
      <div
        css={{
          gridArea: 'title',
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),

          [`@container (max-width:  ${breakpoints.small.max}px)`]: {
            minWidth: 0,
            flexDirection: 'column',
            alignItems: 'start',
            gap: 0,
          },
        }}
      >
        {typeof title === 'string' ? (
          <PageTitle title={title} showBackBtn={showBackBtn && history.length > 0} onBackClick={onBackClick} />
        ) : (
          title
        )}
        {isSettingsPage && !settingsLocationConfig?.hideLocationChip && (
          <SettingsLocationChip {...settingsLocationConfig} />
        )}
      </div>
      {subtitle && typeof subtitle === 'string' && (
        <Text
          css={{
            gridArea: 'subtitle',
            margin: 0,
          }}
        >
          <ExpandText
            css={css`
              max-width: 700px;
            `}
            text={subtitle || ''}
            hiddenText={moreSubtitle}
          />
        </Text>
      )}
      {subtitle && typeof subtitle !== 'string' && (
        <div style={{ gridArea: 'subtitle', margin: 0 }}>
          <ExpandText
            css={css`
              max-width: 700px;
            `}
            text={subtitle || ''}
            hiddenText={moreSubtitle}
          />
        </div>
      )}

      {action && (
        <div
          css={{
            gridArea: 'actions',
            width: 'fit-content',
            justifySelf: 'end',
            [`@container (max-width:  ${breakpoints.small.max}px)`]: {
              justifySelf: 'start',
            },
          }}
          className='page-action'
        >
          {action}
        </div>
      )}
    </div>
  );
};

type SettingsLocationChipProps = {
  isSingleLocation?: boolean;
  chipLabel?: string;
  onActionClick?: React.MouseEventHandler<HTMLElement> & React.MouseEventHandler<HTMLAnchorElement>;
  actionLabel?: string;
  hideLocationChip?: boolean;
};

const SettingsLocationChip = ({
  isSingleLocation,
  chipLabel,
  onActionClick,
  actionLabel,
}: SettingsLocationChipProps) => {
  const { t } = useTranslation('base');
  const { selectedLocationIds, getLocationName, hasOnlyOneLocation } = useAppScopeStore();

  if (hasOnlyOneLocation) return null;

  return (
    <div
      css={css`
        display: flex;
        gap: ${theme.spacing(1)};
        align-items: center;
      `}
    >
      {isSingleLocation ?? selectedLocationIds.length === 1 ? (
        <Chip.SingleChip>{chipLabel ?? getLocationName(selectedLocationIds[0])}</Chip.SingleChip>
      ) : (
        <Chip.MultiChip>{chipLabel ?? t(`{{count}} Locations`, { count: selectedLocationIds.length })}</Chip.MultiChip>
      )}
      {onActionClick ? (
        <TextLink
          weight='bold'
          css={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            ':hover, :focus': {
              textDecoration: 'none',
            },
          }}
          onClick={(e) => {
            onActionClick?.(e);
          }}
        >
          {actionLabel ?? t('Edit')}
        </TextLink>
      ) : null}
    </div>
  );
};

const SettingsBreadcrumbs = ({ breadcrumbs }: { breadcrumbs: SettingsBreadcrumbs[] }) => (
  <Breadcrumbs
    css={css`
      margin-bottom: ${theme.spacing(1)};
    `}
  >
    {breadcrumbs.map(({ to, label, params, ...rest }) => (
      <Breadcrumbs.Path key={label} as={SettingsLink} to={to} params={params as any} {...rest}>
        {label}
      </Breadcrumbs.Path>
    ))}
  </Breadcrumbs>
);

function extractParams(path: string, params: Record<string, string>): Record<string, string> | undefined {
  if (!path.includes(':')) {
    return undefined;
  }

  const extractedKeys = path.split('/').reduce((keys, segment) => {
    if (segment.startsWith(':')) {
      keys.push(segment.substring(1));
    }
    return keys;
  }, [] as string[]);

  return pick(params, extractedKeys);
}

const AutoSettingsBreadcrumbs = () => {
  const { params, route } = useMatch();
  const breadcrumbs = useMemo(() => settingsRouteLookup.searchPath(('/settings' + route.path) as string), [route.path]);

  if (breadcrumbs.length === 1) return null;

  return (
    <Breadcrumbs
      css={css`
        margin-bottom: ${theme.spacing(1)};
      `}
    >
      {breadcrumbs.map((crumb) => (
        <AutoSettingsLabel crumb={crumb.replace('/settings', '')} params={params} key={crumb} />
      ))}
    </Breadcrumbs>
  );
};

type AutoSettingsLabelProps = { params: Params<string>; crumb: string };

type AsyncSettingsLabel = AutoSettingsLabelProps;

const AsyncSettingsLabel = ({ params, crumb }: AsyncSettingsLabel) => {
  const pathLabelMap = useSettingsPathLabelMap();

  const fetchSettingsLabel = async () => {
    const mappedLabel = pathLabelMap[crumb as keyof typeof pathLabelMap]?.label;
    if (typeof mappedLabel === 'function') {
      try {
        return await mappedLabel(params as any);
      } catch (error) {
        const label = extractAndFormatPathName(crumb);
        return label.includes(':') ? params[label.slice(1)] : label;
      }
    }

    return '';
  };

  const { data: asyncLabel, isLoading } = useQuery(
    ['auto-settings-label', constructRoutePath(crumb, params)],
    fetchSettingsLabel,
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 0,
    }
  );

  return isLoading ? (
    <SkeletonLoader
      css={css`
        background-color: ${theme.colors.neutral10};
      `}
      animation='shimmer'
      height={14}
      width={50}
    />
  ) : (
    <>{asyncLabel}</>
  );
};

const AutoSettingsLabel = ({ params, crumb, currentPage }: AutoSettingsLabelProps & { currentPage?: boolean }) => {
  const pathLabelMap = useSettingsPathLabelMap();

  const labelContent = pathLabelMap[crumb as keyof typeof pathLabelMap]?.label;
  const isLabelFunction = typeof labelContent === 'function';

  return (
    <Breadcrumbs.Path
      key={crumb as StaticSettingsRoutes}
      as={SettingsLink}
      to={crumb as StaticSettingsRoutes}
      params={extractParams(crumb, params) as any}
      currentPage={currentPage}
    >
      {isLabelFunction ? (
        <AsyncSettingsLabel params={params} crumb={crumb} />
      ) : (
        labelContent || extractAndFormatPathName(crumb)
      )}
    </Breadcrumbs.Path>
  );
};

AutoSettingsLabel.displayName = 'BreadcrumbsPath';
