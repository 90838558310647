import { SchemaFinanceService } from '@frontend/schema';
import { MutationOptions, UseQueryOptions, useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import {
  BillingAccount,
  NewPaymentMethod,
  PaymentMethod,
} from '@weave/schema-gen-ts/dist/schemas/finance/finance_types.pb';
import {
  GetInvoiceBalancesMultiRequest,
  GetInvoiceBalancesMultiResponse,
  ListInvoicesResponse,
  UpdateBillingAccountContactRequest,
} from '@weave/schema-gen-ts/dist/schemas/finance/finance_service_api.pb';

import { GetInvoicesRequest, GetQueryOptions } from './types';

const queryKeys = {
  base: ['finance'] as const,
  billingAccountInfo: (locationId: string) => [locationId, ...queryKeys.base, 'billing-account-info'],
  paymentMethods: (locationId: string) => [locationId, ...queryKeys.base, 'payment-methods'],
  invoiceDetails: (locationId: string, invoiceId: string) => [
    locationId,
    ...queryKeys.base,
    'invoice-details',
    invoiceId,
  ],
  multiInvoiceBalanceDetails: (locationIds: string[]) => [...locationIds, 'multi-invoice-balance'],
  invoiceList: ({ locationId, pageSize, pageToken }: GetInvoicesRequest) => [
    locationId,
    ...queryKeys.base,
    'invoice-list',
    pageSize,
    pageToken,
  ],
  invoiceListInfiniteQuery: (locationId: string, pageSize: number) => [
    locationId,
    ...queryKeys.base,
    'invoice-list-infinite',
    pageSize,
  ],
};

const logErrorInConsole = (actionName: string) => (err: unknown) =>
  console.error(`An error occurred while ${actionName}`, err);

export const useGetBillingAccount = (locationId: string, options?: GetQueryOptions) => {
  return useQuery({
    queryKey: queryKeys.billingAccountInfo(locationId),
    queryFn: () => SchemaFinanceService.GetBillingAccount({ locationId }),
    enabled: Boolean(locationId && !options?.disabled),
    onError: logErrorInConsole('getting billing account information'),
    retry: false,
  });
};

export const useGetInvoices = (req: GetInvoicesRequest, options?: GetQueryOptions) => {
  return useQuery({
    queryKey: queryKeys.invoiceList(req),
    queryFn: () =>
      SchemaFinanceService.ListInvoices({
        locationId: req.locationId,
        pageSize: req.pageSize,
        pageToken: req.pageToken,
      }),
    enabled: Boolean(req.locationId && !options?.disabled),
    onError: logErrorInConsole('getting invoice list information'),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetInvoicesInfiniteQuery = (locationId: string, pageSize: number) => {
  return useInfiniteQuery({
    queryKey: queryKeys.invoiceListInfiniteQuery(locationId, pageSize),
    queryFn: ({ pageParam }) =>
      SchemaFinanceService.ListInvoices({
        locationId,
        pageSize,
        pageToken: pageParam,
      }),
    getNextPageParam: (lastPage: ListInvoicesResponse) => {
      if (!lastPage.nextPageToken) return undefined;
      return lastPage.nextPageToken;
    },
    onError: logErrorInConsole('getting invoice list information'),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetInvoiceDetails = (locationId: string, invoiceId: string, options?: GetQueryOptions) => {
  return useQuery({
    queryKey: queryKeys.invoiceDetails(locationId, invoiceId),
    queryFn: () => SchemaFinanceService.GetInvoiceDetails({ locationId, id: invoiceId }),
    enabled: Boolean(locationId && invoiceId && !options?.disabled),
    onError: logErrorInConsole('getting invoice details'),
    retry: false,
  });
};

export const useMultiInvoiceBalanceDetails = (
  request: GetInvoiceBalancesMultiRequest,
  options?: UseQueryOptions<GetInvoiceBalancesMultiResponse>
) => {
  return useQuery({
    queryKey: queryKeys.multiInvoiceBalanceDetails(request.locationIds ?? []),
    queryFn: () => SchemaFinanceService.GetInvoiceBalancesForMulti(request),
    enabled: Boolean(request.locationIds?.length),
    ...options,
  });
};

export const useUpdateBillingAccountContactMutation = (
  locationId: string,
  options?: MutationOptions<BillingAccount, unknown, UpdateBillingAccountContactRequest>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: UpdateBillingAccountContactRequest) => SchemaFinanceService.UpdateBillingAccountContact(req),
    ...options,
    onSuccess: (res, req, context) => {
      options?.onSuccess?.(res, req, context);
      queryClient.invalidateQueries(queryKeys.billingAccountInfo(locationId));
    },
  });
};

//#region Payment Method Queries

export const useGetPaymentMethods = (locationId: string, options?: GetQueryOptions) => {
  return useQuery({
    queryKey: queryKeys.paymentMethods(locationId),
    queryFn: async () => {
      const res = await SchemaFinanceService.ListPaymentMethods({ locationId });
      return res.paymentMethods ?? [];
    },
    enabled: Boolean(locationId && !options?.disabled),
    onError: logErrorInConsole('getting payment methods'),
    retry: false,
  });
};

export const useSetPaymentMethodAsDefaultMutation = (
  locationId: string,
  options?: MutationOptions<unknown, unknown, string>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (paymentMethodId: string) =>
      SchemaFinanceService.SetPaymentMethodAsDefault(
        {
          locationId,
          id: paymentMethodId,
        },
        {
          headers: {
            'Location-Id': locationId,
          },
        }
      ),
    ...options,
    onSuccess: (res, req, context) => {
      options?.onSuccess?.(res, req, context);
      queryClient.invalidateQueries(queryKeys.paymentMethods(locationId));
    },
  });
};

export const useDeletePaymentMethodMutation = (
  locationId: string,
  options?: MutationOptions<unknown, unknown, string>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (paymentMethodId: string) =>
      SchemaFinanceService.DeletePaymentMethod(
        {
          locationId,
          id: paymentMethodId,
        },
        {
          headers: {
            'Location-Id': locationId,
          },
        }
      ),
    ...options,
    onSuccess: (res, req, context) => {
      options?.onSuccess?.(res, req, context);
      queryClient.invalidateQueries(queryKeys.paymentMethods(locationId));
    },
  });
};

export const useCreatePaymentMethodMutation = (
  locationId: string,
  options?: MutationOptions<PaymentMethod, unknown, NewPaymentMethod>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (paymentMethod: NewPaymentMethod) =>
      SchemaFinanceService.CreatePaymentMethod({
        locationId,
        paymentMethod,
      }),
    ...options,
    onSuccess: (res, req, context) => {
      options?.onSuccess?.(res, req, context);
      queryClient.invalidateQueries(queryKeys.paymentMethods(locationId));
    },
  });
};

//#endregion
