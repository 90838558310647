import { FC, useMemo } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { useTranslation } from '@frontend/i18n';
import { PersonSelectorComponents, PersonSelectorHooks } from '@frontend/person-selector';
import { ContentLoader, emptyStateGraphics, Text } from '@frontend/design-system';
import { useSteps } from '../../../hooks';
import { useSendFormsContext, useSendFormsStore } from '../../../providers';
import {
  listContainerStyle,
  filterSpacingStyle,
  imageStyle,
  imageContainerStyle,
  centerTextStyle,
} from './recipient-selection.styles';

const { PersonSelectionList, PersonSelectionListFilters } = PersonSelectorComponents;
const { useSelectRecipientList } = PersonSelectorHooks;

const RecipientSelectionStep: FC = () => {
  const { locationId } = useSendFormsContext();
  const { goToNextStep } = useSteps();
  const groupIds = useMemo<string[]>(() => [locationId], [locationId]);
  const { t } = useTranslation('forms');
  const { persons, isLoading, filtersProps } = useSelectRecipientList({
    groupIds,
    shouldFilterWithoutContactInfo: false,
  });
  const { setPersonId, clearAppointmentInfo, setFirstName } = useSendFormsStore([
    'setPersonId',
    'clearAppointmentInfo',
    'setFirstName',
  ]);

  function selectPersonHandler(_person: Person) {
    setPersonId(_person?.personId);
    setFirstName(_person?.firstName ?? '');
    clearAppointmentInfo();
    goToNextStep();
  }
  const EmptyStateGraphicGeneric = emptyStateGraphics.generic;

  function getContent() {
    if (isLoading) {
      return <ContentLoader show />;
    }

    if (!persons.length) {
      return (
        <>
          <div css={imageContainerStyle}>
            <EmptyStateGraphicGeneric css={imageStyle} />
          </div>
          <Text css={centerTextStyle}>{t('No data to display')}</Text>
        </>
      );
    }

    return <PersonSelectionList persons={persons} onSelect={selectPersonHandler} />;
  }

  return (
    <>
      <div css={filterSpacingStyle}>
        <PersonSelectionListFilters {...filtersProps} />
      </div>
      <div css={listContainerStyle}>{getContent()}</div>
    </>
  );
};

export default RecipientSelectionStep;
