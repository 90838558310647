import { NakedUl, SkeletonLoader, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ContactSearchResult } from '../inbox-panel/inbox-search/list-items';
import { useInboxSearch } from '../../hooks';
import { GlobalSearch, useGlobalSearch, GlobalSearchTrackingIds } from '@frontend/global-search';
import { css } from '@emotion/react';

const defaultMaxResults = 4;

export const ContactsGlobalSearchModule = () => {
  const { t } = useTranslation('inbox');
  const { debouncedSearchTerm, viewAllFeature, close, addSearch } = useGlobalSearch([
    'debouncedSearchTerm',
    'viewAllFeature',
    'close',
    'addSearch',
  ]);

  const { personsQuery, logSearch } = useInboxSearch({ debouncedSearchValue: debouncedSearchTerm });
  const fullPersonsCount = personsQuery.results.length;
  const maxResults = viewAllFeature === 'contacts' ? fullPersonsCount : defaultMaxResults;

  return (
    <GlobalSearch.Section.Content
      showViewAll={personsQuery.results.length > defaultMaxResults}
      hasResults={!!personsQuery.results.length}
      count={`${fullPersonsCount}${personsQuery.hasNextPage ? '+' : ''}`}
    >
      <NakedUl css={{ display: 'flex', flexWrap: 'wrap', gap: theme.spacing(1), alignItems: 'stretch' }}>
        {personsQuery.isLoading.firstPage ? (
          <>
            {Array.from({ length: defaultMaxResults }).map((_, index) => (
              <ContactItemSkeletonLoader key={index} />
            ))}
          </>
        ) : personsQuery.results.length ? (
          personsQuery.results.slice(0, maxResults).map((person) => (
            <ContactSearchResult
              trackingId={GlobalSearchTrackingIds.searchResultItem('contacts')}
              isGlobalSearch
              key={person.personId}
              person={person}
              searchValue={debouncedSearchTerm}
              onSelect={() => {
                logSearch(debouncedSearchTerm);
                addSearch(debouncedSearchTerm, 'contacts');
                close();
              }}
            />
          ))
        ) : (
          <Text css={{ width: '100%' }} textAlign='center' color='light' size='medium'>
            {t('No contacts match this search.')}
          </Text>
        )}
      </NakedUl>
    </GlobalSearch.Section.Content>
  );
};

const ContactItemSkeletonLoader = () => {
  return (
    <li
      css={css`
        display: flex;
        gap: ${theme.spacing(2)};
        border-radius: ${theme.borderRadius.small};
        padding: ${theme.spacing(2)};
        border: 1px solid ${theme.colors.neutral10};
        align-items: left;
        min-width: 234px;
        max-height: 76px;
        flex: 1;
      `}
    >
      <SkeletonLoader
        css={css`
          flex-shrink: 0;
        `}
        height={40}
        width={40}
        shape='circle'
      />
      <div
        css={css`
          width: 100%;
        `}
      >
        <SkeletonLoader
          height={20}
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
          width='60%'
        />
        <SkeletonLoader height={18} width='95%' />
      </div>
    </li>
  );
};
