import { Text, IconButton } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { TagSelectionTypes } from '@frontend/tag-selection';
import { theme } from '@frontend/theme';
import { EntityType } from '@weave/schema-gen-ts/dist/schemas/intent-classifier/events/v1/event_types.pb';
import { useCallback } from 'react';
import { FeedbackType } from '@weave/schema-gen-ts/dist/schemas/office-assistant/v1/office_assistant_service.pb';
import { Icon } from '@frontend/icons';

type TagsFeedbackEndAdornmentProps = {
  smartTagId: string;
  smsId: string;
  onFeedbackClick: (context: TagSelectionTypes.FeedbackContext) => void;
};

export const TagsFeedbackEndAdornment = ({ smartTagId, smsId, onFeedbackClick }: TagsFeedbackEndAdornmentProps) => {
  const { t } = useTranslation('inbox');

  const handleFeedbackClick = useCallback(
    (feedbackType: 'positive' | 'negative') => {
      onFeedbackClick({
        smartTagId,
        entityType: EntityType.ENTITY_TYPE_SMS,
        entityId: smsId,
        feedbackType: feedbackType === 'positive' ? FeedbackType.FEEDBACK_TYPE_GOOD : FeedbackType.FEEDBACK_TYPE_BAD,
      });
    },
    [onFeedbackClick, smartTagId, smsId]
  );

  return (
    <>
      <span css={{ display: 'flex', alignItems: 'center' }}>
        <Text
          color='light'
          size='medium'
          css={{
            marginRight: theme.spacing(0.5),
          }}
        >
          {t('Is this helpful?')}
        </Text>
        <IconButton
          size='xsmall'
          css={{
            padding: theme.spacing(0.3),
          }}
          onClick={() => handleFeedbackClick('positive')}
          label={t('Mark tag as helpful')}
        >
          <Icon name='feedback-small' color='light' />
        </IconButton>
        <IconButton
          size='xsmall'
          css={{
            padding: theme.spacing(0.3),
          }}
          onClick={() => handleFeedbackClick('negative')}
          label={t('Mark tag as not helpful')}
        >
          <Icon name='feedback-bad-small' color='light' />
        </IconButton>
      </span>
    </>
  );
};
