import { Icon } from '@frontend/icons';
import { Photos, usePhotoStore } from '@frontend/photos';
import { theme } from '@frontend/theme';
import {
  Modal,
  NakedButton,
  SkeletonLoader,
  SpinningLoader,
  useFileUpload,
  useModalControl,
} from '@frontend/design-system';
import 'react-image-crop/dist/ReactCrop.css';

import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import { PersonQueries, PersonTypes } from '@frontend/api-person';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from '@frontend/i18n';

const getCroppedBlob = async (image: HTMLImageElement, crop: PixelCrop) => {
  if (!image || (image?.complete && image.naturalWidth === 0 && image.naturalHeight === 0)) {
    return;
  }
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const croppedWidth = crop.width * scaleX;
  const croppedHeight = crop.height * scaleY;

  const canvas = document.createElement('canvas');
  canvas.width = croppedWidth;
  canvas.height = croppedHeight;

  const startingX = crop.x * scaleX;
  const startingY = crop.y * scaleY;
  const ctx = canvas.getContext('2d');

  ctx?.drawImage(
    image,
    startingX, // starting x position where to start clipping
    startingY, // starting y position where to start clipping
    croppedWidth, // width of clipped image
    croppedHeight, // height of clipped image
    0, // x position where to place the image on the canvas
    0, // y position where to place the image on the canvas
    croppedWidth, // width of the image to use (stretch or reduce the image)
    croppedHeight // height of the image to use (stretch or reduce the image)
  );

  return await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve));
};

export const AvatarUpload = ({
  PersonID,
  FirstName,
  LastName,
}: Pick<PersonTypes.Person, 'PersonID' | 'FirstName' | 'LastName'>) => {
  const personId = PersonID;
  const firstName = FirstName;
  const lastName = LastName;
  const { t } = useTranslation();
  const { modalProps, triggerProps, closeModal } = useModalControl();
  const setPhotoRecord = usePhotoStore((state) => state.setPhotoRecord);
  const photoId = usePhotoStore((state) => state.photoRecord[personId || '']);
  const hasPhotoUploaded = Boolean(photoId);
  const [srcFromFile, setSrcFromFile] = useState<string>('');
  const [crop, setCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const { inputProps, resetFiles } = useFileUpload({
    acceptedFileType: 'image',
    onFileUpload: async (files) => {
      resetFiles();
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.addEventListener('load', () => setSrcFromFile(reader.result?.toString() || ''));
      triggerProps.onClick();
    },
  });

  const { mutateAsync: uploadMutation, isLoading: uploadLoading } = PersonQueries.useUploadPersonImage({
    onSuccess: () => closeModal(),
  });

  const { mutateAsync: deleteMutation, isLoading: deleteMutationIsLoading } = PersonQueries.useDeletePersonImage({
    onSuccess: () => {
      if (personId) setPhotoRecord(personId, '');
      closeModal();
    },
  });

  const deleteImage = async () => {
    if (personId) {
      await deleteMutation({ personId: personId });
    }
    setSrcFromFile('');
  };

  const addToPhotoUrlToRecord = (croppedBlob: Blob, personId: string) => {
    const reader = new FileReader();
    reader.readAsDataURL(croppedBlob);
    reader.addEventListener('load', () => {
      setPhotoRecord(personId, reader.result?.toString() || '');
    });
  };

  useEffect(() => {
    if (modalProps.show === false) {
      setCompletedCrop(undefined);
      setCrop(undefined);
    }
  }, [modalProps.show]);

  return (
    <>
      <Modal {...modalProps} css={{ minWidth: 400 }}>
        <Modal.Header>{t('Adjust image')}</Modal.Header>
        <Modal.Body>
          {!deleteMutationIsLoading && !uploadLoading && (
            <ReactCrop
              ruleOfThirds
              crop={crop}
              onChange={setCrop}
              circularCrop
              aspect={1}
              onComplete={setCompletedCrop}
            >
              <img
                src={photoId || srcFromFile}
                ref={imgRef}
                onLoad={(e) => {
                  const { naturalWidth, naturalHeight } = e.currentTarget;
                  const aspectRatio = 1;
                  const aspectCrop = makeAspectCrop(
                    {
                      unit: '%',
                      width: 100,
                      height: 100,
                    },
                    aspectRatio,
                    naturalWidth,
                    naturalHeight
                  );
                  const crop = centerCrop(aspectCrop, naturalWidth, naturalHeight);
                  setCrop(crop);
                }}
              />
            </ReactCrop>
          )}
          {deleteMutationIsLoading || uploadLoading ? <SpinningLoader /> : null}
        </Modal.Body>
        <Modal.Actions
          primaryLabel={t('Save')}
          secondaryLabel={t('Cancel')}
          backLabel={t('Delete Image')}
          onBackClick={deleteImage}
          onSecondaryClick={closeModal}
          onPrimaryClick={async () => {
            const image = imgRef.current;
            if (!image || !completedCrop) {
              throw new Error('Crop canvas does not exist');
            }
            if (!personId) {
              throw new Error('Person does not exist');
            }
            const croppedBlob = await getCroppedBlob(image, completedCrop);
            if (!croppedBlob) return;
            addToPhotoUrlToRecord(croppedBlob, personId);
            await uploadMutation({ personId: personId, image: croppedBlob });
            setSrcFromFile('');
          }}
        />
      </Modal>
      <div css={{ position: 'relative' }}>
        <SkeletonLoader shape='circle' width={64} height={64} css={{ marginTop: theme.spacing(1) }}>
          <Photos.ProfilePhoto
            disableClick
            size='xl'
            css={{ animation: 'fadeIn 0.3s ease-in-out', borderRadius: '50%' }}
            firstName={firstName}
            lastName={lastName}
            personId={personId || ''}
          >
            {hasPhotoUploaded ? (
              <NakedButton
                {...triggerProps}
                css={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  background: theme.colors.primary50,
                  borderRadius: '50%',
                  border: '2px solid white',
                }}
              >
                <Icon name='edit-small' color='white' css={{ padding: 2 }} />
              </NakedButton>
            ) : (
              <>
                <label
                  htmlFor='avatar-upload'
                  css={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    background: theme.colors.primary50,
                    borderRadius: '50%',
                    border: '2px solid white',
                    cursor: 'pointer',
                  }}
                >
                  <Icon name='plus-small' color='white' css={{ padding: 2 }} />
                </label>
                <input id='avatar-upload' type='file' {...inputProps} hidden />
              </>
            )}
          </Photos.ProfilePhoto>
        </SkeletonLoader>
      </div>
    </>
  );
};
