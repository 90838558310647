import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { CallIntelligenceApi, CallIntelligenceTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import {
  Accordion,
  Chip,
  Heading,
  NakedUl,
  NotificationBadge,
  SkeletonLoaders,
  Text,
  TextLink,
  styles as designSystemStyles,
  emptyStateGraphics,
  useAlert,
  useModalControl,
} from '@frontend/design-system';
import { useCallIntelStore, useCallTakeawayPanelStore } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { TaskModal } from './modal/task-modal';
import { TaskStatusSwitcher } from './task-status-switcher';

// Type definitions
type CallTaskProps = {
  openTaskModal: (idx: number) => void;
  onSelectingCitationTime?: (citationText: string, citationTime: string) => void;
  onStatusChange: (taskId: string, newStatus: CallIntelligenceTypes.TaskStatusEnum) => void;
  tasks: CallIntelligenceTypes.Task[];
};

type CallTasksProps = {
  call: CallIntelligenceTypes.Call;
  callMetadata?: CallIntelligenceTypes.CallMetadata | null;
  isDemoAccount?: boolean;
  isLoading?: boolean;
  onSelectingCitationTime?: (citationText: string, citationTime: string) => void;
};

type TaskLoaderProps = { count: number };

const DEFAULT_TASK_IDX = -1;

const TaskLoader = ({ count }: TaskLoaderProps) => {
  const loadersToShow = Math.min(count, 3);

  return (
    <>
      {Array.from({ length: loadersToShow }).map((_, idx) => (
        <div
          key={idx}
          css={css`
            padding: ${theme.spacing(1.5, 2)};
          `}
        >
          <SkeletonLoaders.Loader height={theme.spacing(3.5)} />
          <div
            css={css`
              margin-top: ${theme.spacing(1)};
              display: flex;
              gap: ${theme.spacing(1)};
            `}
          >
            <SkeletonLoaders.Loader height={theme.spacing(3)} width={theme.spacing(12)} />
            <SkeletonLoaders.Loader height={theme.spacing(3)} width={theme.spacing(12)} />
          </div>
        </div>
      ))}
    </>
  );
};

const EmptyTask = () => {
  const { t } = useTranslation('analytics');

  const EmptyStateGraphicNoTasks = emptyStateGraphics.no_task;

  return (
    <div css={styles.emptyStateWrapper}>
      <EmptyStateGraphicNoTasks height={200} width={200} />
      <Text css={styles.emptyStateText}>{t('No Tasks for this call')}</Text>
    </div>
  );
};

const CallTask = ({
  openTaskModal,
  onStatusChange,
  onSelectingCitationTime: handleSelectingCitationTime,
  tasks,
}: CallTaskProps) => {
  const { chipVariants, dataLabels } = useCallIntelStore();

  return (
    <NakedUl css={styles.list}>
      {tasks.map((task, idx) => (
        <li data-trackingid={trackingIds.callIntel.taskCardOpen} key={task.id} onClick={() => openTaskModal(idx)}>
          <div css={styles.taskTextWrapper}>
            <Text
              css={[
                styles.taskText,
                designSystemStyles.truncate,
                task.status === CallIntelligenceTypes.TaskStatusEnum.STATUS_COMPLETED && styles.strike,
              ]}
            >
              {task.title}
            </Text>
            {task.citationTime && (
              <span css={styles.citationTime}>
                (
                <TextLink
                  onClick={(e) => {
                    e.stopPropagation();
                    !!handleSelectingCitationTime &&
                      handleSelectingCitationTime(task.citation, task.citationTime ?? '');
                  }}
                  trackingId={trackingIds.callIntel.taskCardTimestamp}
                >
                  {task.citationTime}
                </TextLink>
                )
              </span>
            )}
            <Icon name='expand-small' css={styles.icon} />
          </div>
          <div css={styles.taskActionWrapper}>
            <TaskStatusSwitcher
              defaultOptionValue={task.status}
              onStatusChange={(newStatus) => onStatusChange(task.id, newStatus)}
              trackingId={trackingIds.callIntel.taskCardStatusSwitcher}
              value={task.status}
            />

            <Chip key={task.type} variant={chipVariants[task.type] || 'neutral'}>
              {dataLabels.taskTypes?.[task.type] || task.type}
            </Chip>
          </div>
        </li>
      ))}
    </NakedUl>
  );
};

export const CallTasks = ({
  call,
  callMetadata,
  isDemoAccount,
  isLoading,
  onSelectingCitationTime,
}: CallTasksProps) => {
  const { t } = useTranslation('analytics');

  const alert = useAlert();

  const { modalProps, triggerProps, closeModal } = useModalControl();

  const [selectedTaskIdx, setSelectedTaskIdx] = useState<number>(DEFAULT_TASK_IDX);

  const { allCalls, selectedCall, setSelectedCall, setSelectedCallMetaData, setAllCalls } = useCallTakeawayPanelStore();

  const handleOpenTaskModal = useCallback(
    (idx: number) => {
      setSelectedTaskIdx(idx);
      triggerProps.onClick();
    },
    [triggerProps]
  );

  const handlePreviousTask = useCallback(() => {
    setSelectedTaskIdx((prevIdx) => Math.max(prevIdx - 1, 0));
  }, []);

  const handleNextTask = useCallback(() => {
    setSelectedTaskIdx((prevIdx) => Math.min(prevIdx + 1, (callMetadata?.tasks.length || 0) - 1));
  }, [callMetadata]);

  const handleModalClose = useCallback(() => {
    closeModal();
    setSelectedTaskIdx(DEFAULT_TASK_IDX);
  }, [closeModal]);

  const notCompletedTasks = useMemo(
    () =>
      callMetadata?.tasks.filter(
        ({ status }) =>
          status === CallIntelligenceTypes.TaskStatusEnum.STATUS_TODO ||
          status === CallIntelligenceTypes.TaskStatusEnum.STATUS_IN_PROGRESS
      ) || [],
    [callMetadata]
  );

  const selectedTask = useMemo(() => callMetadata?.tasks[selectedTaskIdx], [callMetadata, selectedTaskIdx]);

  const { mutate: updateTask } = useMutation({
    mutationFn: !isDemoAccount ? CallIntelligenceApi.updateTask : CallIntelligenceApi.noopMutationFn,
    onSuccess: (_, taskPayload: { id: string; status: CallIntelligenceTypes.TaskStatusEnum }) => {
      if (!callMetadata) return;

      const updatedTasks = callMetadata.tasks.map((task) =>
        task.id === taskPayload.id ? { ...task, status: taskPayload.status } : task
      );

      setSelectedCallMetaData({ ...callMetadata, tasks: updatedTasks });
    },
    onError: () => {
      alert.error(t('Unable to update task. Please try again.'));
    },
  });

  const { mutate: deleteTask } = useMutation({
    mutationFn: !isDemoAccount ? CallIntelligenceApi.deleteTask : CallIntelligenceApi.noopMutationFn,
    onSuccess: (_, taskId) => {
      if (!callMetadata) return;

      const task = callMetadata.tasks.find((task) => task.id === taskId);

      if (task) {
        const call = allCalls.find((call) => call.id === task.sourceId);

        if (call) {
          const newTaskCount = call.taskCount ? Math.max(call.taskCount - 1, 0) : 0;

          const remainingTasksOfType = callMetadata.tasks.filter((t) => t.type === task.type && t.id !== taskId);

          const newTaskTypes =
            remainingTasksOfType.length === 0
              ? call.taskTypes.filter((taskType) => taskType !== task.type)
              : call.taskTypes;

          const updatedCall = { ...call, taskCount: newTaskCount, taskTypes: newTaskTypes };

          const updatedCalls = allCalls.map((call) => (call.id === updatedCall.id ? updatedCall : call));

          const updatedTasks = callMetadata.tasks.filter((task) => task.id !== taskId);

          setSelectedCallMetaData({ ...callMetadata, tasks: updatedTasks });
          setAllCalls(updatedCalls);
          setSelectedCall({
            ...(selectedCall as CallIntelligenceTypes.Call),
            taskCount: newTaskCount,
            taskTypes: newTaskTypes,
          });

          alert.success(t('Successfully deleted task!'));
        }
      }
    },
    onError: () => {
      alert.error(t('Unable to delete task. Please try again.'));
    },
  });

  const handleStatusChange = useCallback(
    (taskId: string, newStatus: CallIntelligenceTypes.TaskStatusEnum) => {
      updateTask({ id: taskId, status: newStatus });
    },
    [updateTask]
  );

  const handleTaskDelete = useCallback((taskId: string) => {
    deleteTask(taskId);
    handleModalClose();
  }, []);

  return (
    <>
      <Accordion.Header>
        <div css={styles.header}>
          <Heading level={3}>{t('Tasks')}</Heading>
          {notCompletedTasks.length > 0 && (
            <NotificationBadge css={styles.notificationBadge} truncateCount>
              {notCompletedTasks?.length}
            </NotificationBadge>
          )}
        </div>
      </Accordion.Header>

      <Accordion.Body css={styles.bodyWrapper}>
        {isLoading ? (
          <TaskLoader count={call.taskCount || 1} />
        ) : callMetadata?.tasks?.length === 0 ? (
          <EmptyTask />
        ) : (
          <CallTask
            onSelectingCitationTime={onSelectingCitationTime}
            onStatusChange={handleStatusChange}
            openTaskModal={handleOpenTaskModal}
            tasks={callMetadata?.tasks || []}
          />
        )}
      </Accordion.Body>

      {callMetadata?.tasks && !!callMetadata?.tasks.length && !!selectedTask && (
        <TaskModal
          call={call}
          modalProps={modalProps}
          onClose={handleModalClose}
          onNext={handleNextTask}
          onPrevious={handlePreviousTask}
          onStatusChange={handleStatusChange}
          onDeleteTaskConfirm={handleTaskDelete}
          paginateParams={{ start: selectedTaskIdx + 1, total: callMetadata.tasks.length }}
          task={selectedTask}
          callParticipantsMetadata={callMetadata.callParticipantsMetadata}
        />
      )}
    </>
  );
};

const styles = {
  bodyWrapper: css`
    padding: ${theme.spacing(0, 0)};
  `,

  citationTime: css`
    margin-right: ${theme.spacing(1)};
  `,

  emptyStateText: css`
    color: ${theme.colors.neutral50};
  `,

  emptyStateWrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing(4, 2)};
  `,

  header: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,

  icon: css`
    cursor: pointer;
  `,

  list: css`
    li {
      padding: ${theme.spacing(1.5, 2)};
    }

    li:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.neutral20};
    }

    li:hover {
      background-color: ${theme.colors.neutral5};
      cursor: pointer;
    }
  `,

  notificationBadge: css`
    height: ${theme.spacing(2)};
    width: ${theme.spacing(2)};
    padding: ${theme.spacing(0, 0.5)};
    border-radius: ${theme.borderRadius.full};
  `,

  strike: css`
    text-decoration: line-through;
  `,

  taskActionWrapper: css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing(1)};
    gap: ${theme.spacing(1)};
  `,

  taskText: css`
    max-width: 332px;
  `,

  taskTextWrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
};
