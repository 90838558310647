import { useCallback, useEffect, useRef } from 'react';
import {
  ConnectionStatusEvent,
  DisconnectEvent,
  EventHandler,
  ExposedError,
  FetchConnectionTokenFn,
  PaymentStatusEvent,
  Terminal,
} from '@stripe/terminal-js';
import { useQuery, useQueryClient } from 'react-query';
import { getConnectionToken } from '@frontend/api';
import { useTranslation } from '@frontend/i18n';
import {
  getInterScreenQueryOptions,
  paymentsQueryKeys,
  useMerchant,
  useMultiQueryUtils,
  paymentsSentry,
} from '@frontend/payments-hooks';
import { useAlert } from '@frontend/design-system';
import { devLogger, isReaderConnected } from '../../../reader-payment';
import { useTerminalShallowStore } from '../../store';
import { useStripeTerminalLib } from './use-stripe-terminal-lib';

const interScreenQueryOptions = getInterScreenQueryOptions(Infinity);

export const useTerminal = () => {
  const { t } = useTranslation('payments');
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const { locationId } = useMultiQueryUtils();

  const { paymentsUrl, stripeLocationId } = useMerchant();
  const { stripeTerminalLib } = useStripeTerminalLib();
  const stripeTerminalRef = useRef<Terminal | undefined>();
  const { setConnectionError } = useTerminalShallowStore('setConnectionError');

  const onFetchConnectionToken: FetchConnectionTokenFn = useCallback(async () => {
    let secret = '';
    if (stripeLocationId && paymentsUrl) {
      try {
        secret = (await getConnectionToken(paymentsUrl, locationId, stripeLocationId)) ?? '';
      } catch (error) {
        console.error('Connection token for Stripe failed.', error);
        paymentsSentry.error((error as ExposedError).message, 'stripe', 'Connection token for Stripe failed.');
      }
    }

    return secret;
  }, [paymentsUrl, stripeLocationId, locationId]);

  const onUnexpectedReaderDisconnect: EventHandler<DisconnectEvent> = (event) => {
    alerts.error(t('Terminal connection lost'));
    devLogger.log('onUnexpectedReaderDisconnect', event);
    setConnectionError(event.error);

    paymentsSentry.error(
      event.error?.message ?? 'Unexpected reader disconnect',
      'Terminal ID',
      stripeTerminalRef?.current?.getConnectedReader()?.id ?? ''
    );
  };

  const onPaymentStatusChange: EventHandler<PaymentStatusEvent> = (event) => {
    devLogger.log('onPaymentStatusChange', event);
  };

  const onConnectionStatusChange: EventHandler<ConnectionStatusEvent> = (event) => {
    devLogger.log('onConnectionStatusChange', event);
  };

  const {
    data: stripeTerminal,
    error: stripeTerminalError,
    isFetching: creatingNewStripeTerminal,
  } = useQuery({
    queryKey: [paymentsQueryKeys.createTerminal, stripeLocationId],
    queryFn: () =>
      stripeTerminalLib!.create({
        onFetchConnectionToken,
        onUnexpectedReaderDisconnect,
        onConnectionStatusChange,
        onPaymentStatusChange,
      }),
    enabled: !!stripeTerminalLib && !!stripeLocationId,
    cacheTime: Infinity,
    ...interScreenQueryOptions,
  });

  useEffect(() => {
    stripeTerminalRef.current = stripeTerminal;
  }, [stripeTerminal]);

  useEffect(() => {
    if (creatingNewStripeTerminal) console.log('creatingNewStripeTerminal', stripeLocationId);
  }, [creatingNewStripeTerminal, stripeLocationId]);

  useEffect(() => {
    if (stripeTerminalError) {
      console.error('Invalid state', stripeTerminal);
      paymentsSentry.log(`Error loading Stripe terminal: ${stripeTerminal}`, {
        stripeTerminal,
        stripeTerminalError,
      });
    }
  }, [stripeTerminalError, stripeTerminal]);

  const disconnectReader = async (stripeTerminal: Terminal, sentryContextName = 'disconnectReader') => {
    try {
      if (isReaderConnected(stripeTerminal)) {
        await stripeTerminal?.disconnectReader();
      }
    } catch (error) {
      console.error('Failed to disconnect reader in ', sentryContextName, error);
      paymentsSentry.error((error as ExposedError).message, sentryContextName, 'Failed to disconnect reader');
    }
  };
  const removePreviousTerminalsFromCache = (stripeLocationId: string) => {
    const prevQueries = queryClient
      .getQueryCache()
      .findAll([paymentsQueryKeys.createTerminal])
      .filter((query) => !query.queryKey.includes(stripeLocationId));
    prevQueries.forEach(async (query) => {
      const locationId = query.queryKey?.[1];
      const terminal = query.state.data as Terminal | undefined;
      if (locationId && terminal) {
        devLogger.log('stripeLocationId previous:', locationId, terminal);
        await disconnectReader(terminal, 'createStripeTerminalEffect');
      }
      queryClient.removeQueries(query);
    });
  };
  useEffect(() => {
    if (stripeLocationId) {
      removePreviousTerminalsFromCache(stripeLocationId);
    }
  }, [stripeLocationId]);

  return { stripeTerminal, disconnectReader };
};
