import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { isWeaveUser } from '@frontend/auth-helpers';
import { useIsPADemoAccount } from './use-is-pa-demo-account';

export const useShowPracticeAnalyticsNav = (): boolean => {
  const { flags } = useCustomizationFlagShallowStore('flags');

  const isCFActive = flags.practiceanalytics?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
  const isCFPromote = flags.practiceanalytics?.state === CustomizationFlagTypes.CustomizationFlagState.PROMOTE;
  const isDemoAccount = useIsPADemoAccount();

  return isCFActive || (isCFPromote && isWeaveUser()) || !!isDemoAccount;
};
