import { FC } from 'react';
import { useSendFormsStore } from '../../../providers';
import { FormsDirectDeliveryEmailForm } from './email-message-mode.component';
import { KioskMessageModeComponent } from './kiosk-message-mode.component';

const FormsKioskDelivery: FC = () => {
  const { messageMode } = useSendFormsStore(['messageMode']);

  if (messageMode === 'email') {
    return <FormsDirectDeliveryEmailForm />;
  }

  if (messageMode === 'kiosk') {
    return <KioskMessageModeComponent />;
  }
  return null;
};

export default FormsKioskDelivery;
