import { useMemo } from 'react';
import { VoicePhoneNumber } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-numbers/phone_numbers_service.pb';
import { PersonHelpers, PersonTypes } from '@frontend/api-person';
import { Modal, ModalControlResponse, SpinningLoader } from '@frontend/design-system';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { DialUtils } from '@frontend/api-dialpad';
import {
  DeviceSelectModal,
  PhoneSyncButton,
  PhoneSyncModal,
  usePhonePairSuccessHandler,
  usePhoneSyncControl,
} from '@frontend/phone-sync';
import { PhoneSyncApi, useQueryDevices } from '@frontend/api-phone-sync';
import { useAppScopeStore } from '@frontend/scope';
import { useTranslation } from '@frontend/i18n';
import { NoDevicePairedModal } from './no-device-paired';
import { OutboundCallerSelectionModal } from './outbound-caller-selection-modal';
import { ContactNumberSelection } from './contact-number-selection';

type ClickToCallSelectionProps = {
  person: PersonTypes.Person | undefined;
  personNumber: string;
  modal: ModalControlResponse;
  outboundNumbers: VoicePhoneNumber[];
  isLoading: boolean;
  useOutboundNumber: boolean;
};

export const ClickToCallSelection = ({
  person,
  personNumber,
  modal,
  outboundNumbers,
  isLoading,
  useOutboundNumber,
}: ClickToCallSelectionProps) => {
  const multipleContactNumbers = PersonHelpers.getAllPhoneNumbers(person) ?? [];
  const requiresContactNumberSelection = !personNumber && !!person && !(multipleContactNumbers.length === 1);

  const hasSingleLocationVoiceData = outboundNumbers?.length === 1;
  const requiresOutboundNumberSelection = !(
    hasSingleLocationVoiceData && outboundNumbers[0]?.phoneNumbers?.length === 1
  );
  const { t } = useTranslation('phone-call');
  const { selectedLocationIds } = useAppScopeStore();
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig', 'setPhoneConfig');

  const { modalProps, buttonProps } = usePhoneSyncControl();
  const { data: devices } = useQueryDevices(selectedLocationIds);

  const availableDevices = devices?.filter((device) => device.availableInContext) ?? [];
  const onSyncSuccess = usePhonePairSuccessHandler()(selectedLocationIds);

  const defaultVoiceData = useMemo(() => DialUtils.getDefaultVoiceData(outboundNumbers), [outboundNumbers]);

  return (
    <div>
      {isLoading ? (
        <Modal {...modal.modalProps} maxWidth={400}>
          <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SpinningLoader />
          </div>
        </Modal>
      ) : (
        <>
          {!phoneConfig && availableDevices && availableDevices.length > 0 && (
            <>
              <DeviceSelectModal
                variant='click-to-call'
                deviceSelectModal={modal}
                closeModal={modal.closeModal}
                locationIds={selectedLocationIds}
                pairDeviceButton={
                  <PhoneSyncButton {...buttonProps} variant='secondary' onClick={modal.modalProps.onClose} />
                }
              />
              <PhoneSyncModal
                {...modalProps}
                locationIds={selectedLocationIds}
                onSyncSuccess={onSyncSuccess}
                syncFn={PhoneSyncApi.pairDevice}
              />
            </>
          )}

          {!phoneConfig && availableDevices?.length === 0 && (
            <>
              <NoDevicePairedModal
                modalProps={modal.modalProps}
                pairDeviceButton={
                  <PhoneSyncButton {...buttonProps} btnLabel={t('Pair Phone')} onClick={modal.modalProps.onClose} />
                }
              />
              <PhoneSyncModal
                {...modalProps}
                locationIds={selectedLocationIds}
                onSyncSuccess={onSyncSuccess}
                syncFn={PhoneSyncApi.pairDevice}
              />
            </>
          )}
          {!useOutboundNumber &&
            !!phoneConfig &&
            requiresContactNumberSelection &&
            !requiresOutboundNumberSelection && (
              <ContactNumberSelection
                person={person}
                useOutboundNumber={useOutboundNumber}
                defaultOutboundNumber={defaultVoiceData}
                modalProps={modal.modalProps}
              />
            )}

          {!!phoneConfig && requiresOutboundNumberSelection && (
            <OutboundCallerSelectionModal
              key={phoneConfig?.sipProfileId}
              modalProps={modal.modalProps}
              outboundNumbers={outboundNumbers}
              useOutboundNumber={useOutboundNumber}
              dialNumber={personNumber ?? ''}
              person={person}
            />
          )}
        </>
      )}
    </div>
  );
};
