import dayjs from 'dayjs';
import { SMSAnalyticsApi, SMSAnalyticsTypes } from '@frontend/api-analytics';
import { useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';

export const useOutboundMessagePresent = () => {
  const { selectedLocationIds } = useAppScopeStore();
  return useQuery<SMSAnalyticsTypes.SMSReportResponse | undefined, unknown, boolean>({
    queryKey: ['dashboard', 'smsAnalytics', 'outboundMessage', ...selectedLocationIds],
    queryFn: () =>
      SMSAnalyticsApi.getSMSReport(
        {
          location_id: selectedLocationIds,
          sms_type: ['Outbound'],
          start_date: dayjs(0).toISOString(),
          end_date: dayjs().toISOString(),
        },
        1
      ),
    select: (response) => {
      return !!response?.data?.length;
    },
  });
};
