import { useEffect, useState } from 'react';
import {
  EventType,
  TaskEvent,
} from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { isToday } from 'date-fns';
import { useIsFetching } from 'react-query';
import { useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { OnboardingModulesApi, OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { CoreACLs, hasACL, getUser, getDecodedWeaveToken } from '@frontend/auth-helpers';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useMatchMedia, breakpoints } from '@frontend/responsiveness';
import { OnboardingModulesUiTypes } from '@frontend/shared';
import { sentry } from '@frontend/tracking';
import { getTourGuideInfo } from '..';
import { useTourGuideShallowStore, useTourGuideStore } from '../../hooks';
import { GuideNameEnum } from '../../types';

type AdvanceTourGuideOptions = {
  hasIncompleteTasks: boolean;
  setLandingPageTab: (data: OnboardingModulesUiTypes.LandingPageTab) => void;
};

export enum StepIndexNames {
  OpenDialog = 0,
  OnboardingToolTip = 1,
  TrainingToolTip = 2,
  DownloadSoftwareToolTip = 3,
  LogoToolTip = 4,
  MaxDismissalTooltip = 5,
}

const isStepIndex = (stepIndex: number | undefined, name: StepIndexNames) => {
  return stepIndex === name;
};

function handleStartFirstTimeUserExperienceGuide(hasBeenDismissedPreviously: boolean) {
  const guideInfo = getTourGuideInfo(GuideNameEnum.PortalWelcomeExperienceSetup);
  const steps = guideInfo?.getSteps() ?? [];

  if (steps.length) {
    useTourGuideStore.getState().startGuide({
      steps,
      guideName: GuideNameEnum.PortalWelcomeExperienceSetup,
      auditInfo: {
        moduleName: 'Stand Alone',
        guideVersion: guideInfo!.version,
        taskType: OnboardingModulesTypes.TaskType.User,
        taskId: OnboardingModulesUiTypes.UserTask.PortalWelcomeExperienceTask,
      },
      additionalGuideData: { hasBeenDismissedPreviously },
      skipLocalStorage: true,
    });
  }
}

export const useControlPortalWelcomeExperience = ({
  setLandingPageTab,
  hasIncompleteTasks,
}: AdvanceTourGuideOptions) => {
  const { stepIndex, isRunning } = useTourGuideShallowStore('stepIndex', 'isRunning');
  const { isShowIntakeForm } = useIntakeFormShallowStore('isShowIntakeForm');
  const user = getUser();
  const isFetching = useIsFetching();
  const isMobile = useMatchMedia({ maxWidth: breakpoints.xsmall.max });

  const { isParentLocation, locationId } = useLocationDataShallowStore('locationId', 'isParentLocation');
  const [guideHasRunOnce, setGuideHasRunOnce] = useState(false);
  const isAdmin = hasACL(locationId, CoreACLs.USER_WRITE);

  const fetchUserTaskStatus = async (userId: string) => {
    try {
      const tokenUserId = getDecodedWeaveToken()?.user_id ?? '';
      const mismatchUserId = user?.userID !== tokenUserId;
      if (mismatchUserId) {
        sentry.warn({
          error: 'UserId mismatch. LocalStorage UserId different than token user_id. ID: ONB-k73js8e3',
          topic: 'onboarding',
          addContext: {
            name: 'details',
            context: {
              userId: user?.userID,
              tokenUserId,
            },
          },
        });
      }

      const resp = await OnboardingModulesApi.getTasksByUserId(userId);
      return resp;
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const fetchUserEvents = async (userId: string) => {
    try {
      const resp = await OnboardingModulesApi.getTaskEventsByUserIDTaskId({
        userId,
        locationId,
        taskId: OnboardingModulesUiTypes.UserTask.PortalWelcomeExperienceTask,
      });
      return resp;
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const checkDismissedEvents = async (userId: string) => {
    const userTaskEvents = await fetchUserEvents(userId);

    const userDismissedEvents: TaskEvent[] = [];
    if (userTaskEvents) {
      for (let i = 0; i < userTaskEvents.length; i++) {
        if (userTaskEvents[i].eventType === EventType.EVENT_GUIDE_DISMISS) {
          userDismissedEvents.push(userTaskEvents[i]);
        }
      }
    }

    const hasBeenDismissedToday = userDismissedEvents.some((data) => isToday(new Date(data.createdAt)));

    const userTasks = await fetchUserTaskStatus(userId);
    const shouldShowUserExperienceModal = () => {
      const userHasCompletedTask = userTasks?.some(
        (taskStatus) =>
          !!taskStatus.completedAt && taskStatus.taskId === OnboardingModulesTypes.TaskIds.PortalWelcomeExperience
      );

      const showPortalWelcomeModal =
        !userHasCompletedTask && !isParentLocation && !hasBeenDismissedToday && !guideHasRunOnce && !isShowIntakeForm;

      return showPortalWelcomeModal;
    };

    if (!isAdmin) return;

    if (shouldShowUserExperienceModal()) {
      setGuideHasRunOnce(true);

      const hasBeenDismissedPreviously = userDismissedEvents.length >= 1 ? true : false;

      handleStartFirstTimeUserExperienceGuide(hasBeenDismissedPreviously);
    }
  };

  useEffect(() => {
    if (!user?.userID) return;

    if (isFetching > 0) return;

    checkDismissedEvents(user.userID);
  }, [user?.userID, isShowIntakeForm, isFetching]);

  useEffect(() => {
    if (isStepIndex(stepIndex, StepIndexNames.TrainingToolTip)) {
      setLandingPageTab(OnboardingModulesUiTypes.LandingPageTab.Training);
    } else if (isStepIndex(stepIndex, StepIndexNames.OnboardingToolTip)) {
      setLandingPageTab(OnboardingModulesUiTypes.LandingPageTab.Onboarding);
    } else if (isMobile && isStepIndex(stepIndex, StepIndexNames.DownloadSoftwareToolTip)) {
      setLandingPageTab(OnboardingModulesUiTypes.LandingPageTab.Onboarding);
    } else if (
      isStepIndex(stepIndex, StepIndexNames.MaxDismissalTooltip) ||
      isStepIndex(stepIndex, StepIndexNames.LogoToolTip)
    ) {
      setLandingPageTab(
        hasIncompleteTasks
          ? OnboardingModulesUiTypes.LandingPageTab.Onboarding
          : OnboardingModulesUiTypes.LandingPageTab.Training
      );
    }
  }, [stepIndex, isRunning]);
};
