export enum TaskStatus {
  InComplete = 'INCOMPLETE',
  Complete = 'COMPLETE',
}

export enum GuideType {
  GuidedWalkthrough,
  ExternalLink,
}

export enum ModuleName {
  Standalone = 'standalone',
  Payments = 'payments',
  Reviews = 'reviews',
}

export enum TaskType {
  Location = 'location',
  User = 'user',
}

export enum TaskCompletionType {
  Modules = 'Modules',
  Ordinary = 'Ordinary',
}

export enum TaskIds {
  EINSetup = 1,
  PaymentsStripeSetup = 2,
  ReviewsSetup = 3,
  UserInviteSetup = 4,
  PortalWelcomeExperience = 5,
  ScheduleCallWithOnboarder = 16,
}

export enum ModuleIds {
  Standalone = 1,
  Payments = 2,
  Reviews = 3,
  OnlineScheduling = 4,
  TextConnect = 5,
}

export type TaskTrayStepInfo = {
  id: string;
  title: string;
  description?: string;
  information?: string;
  imageUrl?: string;
};

export type TaskTrayTaskInfo = {
  id: number;
  title: string;
  description?: string;
  information?: string;
  imageUrl?: string;
  isCompleted: boolean;
  moduleId: number;
  isOptional?: boolean;
};

export type TaskTrayModuleInfo = {
  id: number;
  title: string;
  description?: string;
  information?: string;
  isCompleted: boolean;
  tasks: TaskTrayTaskInfo[];
  progressText: string;
  progressValue: number;
};

export type TaskTrayModuleListInfo = {
  modules: TaskTrayModuleInfo[];
  progressText: string;
  progressValue: number;
};
