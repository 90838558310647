import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const sectionStyle = css({ padding: theme.spacing(0, 1) });

export const containerStyle = css({ display: 'flex', alignItems: 'center', justifyContent: 'space-between' });

export const chevronContainerStyle = css({
  gap: theme.spacing(1),
  display: 'flex',
  height: '100%',
  alignItems: 'center',
});

export const titleStyle = css({ lineHeight: 1, color: theme.colors.neutral90 });

export const caretStyle = css({ transition: 'transform 300ms', transform: 'rotate(0deg)' });

export const caretTransition = css({ transform: 'rotate(180deg)' });
