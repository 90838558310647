import { css } from '@emotion/react';
import { Heading, PrimaryButton, SkeletonLoaders, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { FC } from 'react';
import { CheckedListItem } from '../sign-up-landing-page-subscription-cards';
import { useTranslation } from '@frontend/i18n';
import { MAX_CARD_WIDTH } from '../sign-up-landing-page-subscription-cards/constant';
import { FeatureSignUpContentType } from './types';

type SignUpContentType = FeatureSignUpContentType['subscriptionCardContent'];
type SignUpSubscriptionData = FeatureSignUpContentType['subscriptionData'];
interface FeatureSignUpCardProps extends SignUpContentType, SignUpSubscriptionData {
  canSelfSubscribe: boolean;
  disableAction: boolean;
  isLoading: boolean;
  isMulti: boolean;
  onActionClick: () => void;
  scheduleCallTrackingId?: string;
  subscribeTrackingId?: string;
}
export const FeatureSignUpCard: FC<FeatureSignUpCardProps> = ({
  canSelfSubscribe,
  disableAction,
  featurePoints = [],
  isLoading,
  isMulti,
  price,
  onActionClick,
  scheduleCallTrackingId,
  subscriptionTerm,
  subscribeTrackingId,
  title,
}) => {
  const { t } = useTranslation('subscription', { keyPrefix: 'feature-signup' });

  if (isLoading) {
    return <FeatureSignUpCardLoader />;
  }
  return (
    <div css={signUpCardStyles}>
      <Heading level={2}>{title}</Heading>
      {featurePoints.map((point, index) => (
        <CheckedListItem key={index} size='medium' value={point} />
      ))}
      {!!price && (
        <div css={pricingPanelStyle}>
          <Heading level={3}>${price}</Heading>{' '}
          <Text size='large'>
            {subscriptionTerm === 'monthly' ? t('/mo') : t('year')} {isMulti && t('per location')}
          </Text>
        </div>
      )}

      <PrimaryButton
        disabled={disableAction}
        trackingId={canSelfSubscribe ? subscribeTrackingId : scheduleCallTrackingId}
        onClick={onActionClick}
        css={{ marginTop: theme.spacing(2) }}
      >
        {canSelfSubscribe ? t('Subscribe') : t('Schedule Call')}
      </PrimaryButton>
    </div>
  );
};

const FeatureSignUpCardLoader = () => {
  return (
    <div css={[signUpCardStyles, { minHeight: theme.spacing(32) }]}>
      <SkeletonLoaders.Loader height={theme.spacing(8)} />
      {Array(4)
        .fill(0)
        .map((_, idx) => (
          <SkeletonLoaders.Loader key={idx} height={theme.spacing(2)} />
        ))}
      <div css={{ marginTop: theme.spacing(2) }}>
        <SkeletonLoaders.Loader height={theme.spacing(4)} />
      </div>
    </div>
  );
};

const signUpCardStyles = css({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.medium,
  border: `1px solid ${theme.colors.neutral20}`,
  maxWidth: MAX_CARD_WIDTH,
});

const pricingPanelStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  borderTop: `1px solid ${theme.colors.neutral20}`,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
});
