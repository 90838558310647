import { FC } from 'react';
import { css } from '@emotion/react';
import { Channel } from 'stream-chat';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useAlert, TextLink, ConfirmationModal, useModalControl } from '@frontend/design-system';
import { useTeamChatStore } from '../../../providers';

interface DeleteChannelProps {
  className?: string;
  channel: Channel;
}

export const DeleteChannel: FC<DeleteChannelProps> = ({ channel, className }) => {
  const { t } = useTranslation('team-chat');
  const { error } = useAlert();
  const { openModal, modalProps } = useModalControl();
  const { resetActiveConversation, removeConversation } = useTeamChatStore([
    'resetActiveConversation',
    'removeConversation',
  ]);

  const deleteChannel = async () => {
    try {
      await channel.delete();
      resetActiveConversation();
      if (channel.id) removeConversation(channel.id);
    } catch (err) {
      error(t('Failed to delete conversation'));
    }
  };

  return (
    <>
      <TextLink css={css({ padding: theme.spacing(2) })} onClick={openModal} className={className}>
        <Text color='error' weight='bold' textAlign='left'>
          {t('Delete Channel')}
        </Text>
      </TextLink>
      <ConfirmationModal onConfirm={deleteChannel} title={t('Delete Conversation')} destructive {...modalProps}>
        <Text>
          {t(
            'Are you sure you want to delete this conversation? You will not be able to recover this conversation after deleting.'
          )}
        </Text>
      </ConfirmationModal>
    </>
  );
};
