/**
 * Calculates the percentage of the numerator over the denominator.
 * Handles undefined inputs by treating them as 0.
 * @param {number | undefined} numerator - The part value of the calculation, or undefined.
 * @param {number | undefined} denominator - The whole value of the calculation, or undefined.
 * @returns {number} The calculated percentage, rounded to the nearest integer.
 */
export function calculatePercentage(numerator?: number, denominator?: number) {
  const safeNumerator = numerator ?? 0;
  const safeDenominator = denominator ?? 0;

  if (safeDenominator === 0) return 0;
  return Math.round((safeNumerator / safeDenominator) * 100);
}
