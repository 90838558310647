import { ReactElement } from 'react';
import {
  BlockPrice,
  Consumer,
  SalesforceAccountProductBundle,
} from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { SubscriptionAddOnEnums } from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { SchemaIO, SchemaPackageService } from '@frontend/schema';

export type { GetSalesforceBundleNameResponse } from '@weave/schema-gen-ts/dist/schemas/package-service/v1/package.pb';
export { AddOnLineItems } from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
export { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';

export type SubscriptionCard = {
  title: string;
  isNew: boolean;
  description: string;
  pricingText?: (isActive?: boolean) => ReactElement | string;
  image: string;
  featureSubscriptionRoute: string;
  featureAppRoute?: string;
  subscriptionAddonEnumValue?: SubscriptionAddOnEnums;
  featureEnumValue?: Feature;
  isFreeTrialActive?: boolean;
  subscriptionCardTrackingId?: string;
  freeTrialActivateApiFunc?: () => Promise<unknown>;
};

export type ExtendedSubscriptionCard = SubscriptionCard & {
  isFreeTrialAvailable: boolean;
  isActive: boolean;
  shouldShow: boolean;
};

export type SubscriptionFeatures = 'bulk-messaging' | 'digital-forms' | 'insurance-verification';
export enum SubscriptionFeaturesOptions {
  BULK_MESSAGING = 'bulk-messaging',
  DIGITAL_FORMS = 'digital-forms',
  INSURANCE_VERIFICATION = 'insurance-verification',
}
export type FormattedSubscriptionFeatures = 'Bulk Messaging' | 'Digital Forms' | 'Insurance Verification';

export enum TieredSubscriptionFeatures {
  BULK_MESSAGING = 'bulk-messaging',
}
export type SubscriptionData = {
  title: string;
  subheading: ReactElement;
  src: string;
  content: ReactElement;
  price?: number | string;
  sidebarHeading?: string;
  sidebarContent?: ReactElement;
  isActive: boolean;
  hasTier?: boolean;
  subscriptionId: SubscriptionFeatures | TieredSubscriptionFeatures;
  isSuperAdmin?: boolean;
};

export type TermsItem = { text: string | ReactElement; hide?: boolean };

export type TierData = {
  selectedTier: number | undefined;
  quota: number | undefined;
  disabled: boolean;
  blockPrice: BlockPrice[] | undefined;
};

export enum SalesforceBundleNameEnums {
  WEAVE_PLUS_ESSENTIALS = 'Weave Plus Essentials',
  WEAVE_PLUS_PRO = 'Weave Plus Pro',
  WEAVE_PLUS_ELITE = 'Weave Plus Elite',
  WEAVE_CORE_ESSENTIALS = 'Weave Core Essentials',
  WEAVE_CORE_PRO = 'Weave Core Pro',
  WEAVE_CORE_ELITE = 'Weave Core Elite',
  WEAVE_CORE_SOFTWARE_ONLY_ESSENTIALS = 'Weave Core Essentials Software Only',
  WEAVE_CORE_SOFTWARE_ONLY_PRO = 'Weave Core Pro Software Only',
  WEAVE_CORE_SOFTWARE_ONLY_ELITE = 'Weave Core Elite Software Only',
  WEAVE_PLUS_SOFTWARE_ONLY_ESSENTIALS = 'Weave Plus Essentials Software Only',
  WEAVE_PLUS_SOFTWARE_ONLY_PRO = 'Weave Plus Pro Software Only',
  WEAVE_PLUS_SOFTWARE_ONLY_ELITE = 'Weave Plus Elite Software Only',
  WEAVE_PLUS = 'Weave Plus',
  WEAVE_CORE = 'Weave Core',
  PRODUCT_BUNDLE_PATTERSON = 'Patterson Bundle',
  // Generic bundle names
  WEAVE_ESSENTIALS = 'Weave Essentials',
  WEAVE_PRO = 'Weave Pro',
  WEAVE_ELITE = 'Weave Elite',
}

export type SalesforceBundleNameType = SalesforceBundleNameEnums | string;
export type SalesforceProductBundleNameEnumType = SalesforceAccountProductBundle | '';

export type SalesforceAccountBySlugRequestQueryParams = { slug: string; enabled: boolean; consumer?: Consumer };

export type GetSalesforceBundleNameForMultiRequest = SchemaIO<
  (typeof SchemaPackageService)['GetSalesforceBundleNameForMulti']
>['input'];

export type GetSalesforceBundleNameForMultiResponse = SchemaIO<
  (typeof SchemaPackageService)['GetSalesforceBundleNameForMulti']
>['output'];
