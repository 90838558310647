import { PracticeAnalyticsTypes } from '../../practice';
import { generateNames, getRandomInt, requiredPatientInfoFields } from './mock-generators';

const classificationBreakpoints = [0, 12, 20, 32, 50];
const patientsCount = classificationBreakpoints[classificationBreakpoints.length - 1];

const procedures = Array.from({ length: patientsCount }, () => ({
  adaCode: `D${getRandomInt(1000, 9999)}`,
  procedureAmount: getRandomInt(100, 2000),
}));

const patients: PracticeAnalyticsTypes.PatientInfo[] = generateNames(patientsCount).map((name, index) => {
  const mockProcedures: PracticeAnalyticsTypes.Procedure[] = [procedures[index]];

  let classification = null;

  if (index < classificationBreakpoints[1]) {
    classification = PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.CANCELLED_PATIENTS;
  } else if (index < classificationBreakpoints[2]) {
    classification = PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.HYGIENE_FOLLOW_UP;
  } else if (index < classificationBreakpoints[3]) {
    classification = PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.HYGIENE_TREATMENT_PLAN;
  } else {
    classification = PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.RESTORATIVE_TREATMENT_PLAN;
  }

  return {
    ...name,
    ...requiredPatientInfoFields,
    classification,
    id: `demo-${index}`,
    MobilePhone: getRandomInt(1000000000, 9999999999).toString(),
    procedures:
      classification !== PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.CANCELLED_PATIENTS
        ? mockProcedures
        : [],
    productionAmount: getRandomInt(200, 500),
  };
});

const missedAppointments = {
  details: {
    patients: patients.slice(0, classificationBreakpoints[1]),
  },
};

const hygieneReappointment = {
  details: {
    patients: patients.slice(classificationBreakpoints[1], classificationBreakpoints[2]),
  },
};

const hygieneTreatmentPlan = {
  details: {
    patients: patients.slice(classificationBreakpoints[2], classificationBreakpoints[3]),
  },
};

const restorativeTreatmentPlan = {
  details: {
    patients: patients.slice(classificationBreakpoints[3], classificationBreakpoints[4]),
  },
};

export const opportunityList = (): PracticeAnalyticsTypes.OpportunityList => ({
  location: {
    missedAppointments,
    hygieneReappointment,
    hygieneTreatmentPlan,
    restorativeTreatmentPlan,
  },
});
