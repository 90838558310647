import { titleCase } from '@frontend/string';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { css } from '@emotion/react';
import {
  CheckboxField,
  DropdownField,
  EmailField,
  FormFieldActionTypes,
  FormRow,
  IconButton,
  InfoRoundIconSmall,
  Modal,
  PhoneField,
  PostalCodeField,
  PostalCodeLocale,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextField,
  TextLink,
  useForm,
  useTooltip,
  useModalControl,
} from '@frontend/design-system';
import { TFunction, Trans, useTranslation } from '@frontend/i18n';
import { stateOptions } from '@frontend/geography';
import { CountryCodes, countryOptions } from '@frontend/geography';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { BusinessInfoApi, BusinessInfoTypes } from '@frontend/api-business-information';
import { useEffect, useState } from 'react';
import { EinSuggestionModal } from './ein-suggestion-modal';
import urlRegex from 'url-regex';
import { breakpoints } from '@frontend/responsiveness';
import { SubscriptionsQueries } from '@frontend/api-subscription';
import { Consumer } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';

type BusinessInfoFormProps = {
  isDemoMode?: boolean;
  info: BusinessInfoTypes.TCRBrand;
  isVerified: boolean;
  previousValues?: Partial<BusinessInfoTypes.BusinessInfoFormValues>;
  familyLocationTcrBrands?: BusinessInfoTypes.LocationTCRBrand[];
  handleSubmit: (
    values: BusinessInfoTypes.BusinessInfoFormValues,
    changedValues: Partial<BusinessInfoTypes.BusinessInfoFormValues>
  ) => void;
  handleCancel: () => void;
  handleFamilyLocationEinMatch: (brand: BusinessInfoTypes.TCRBrand) => void;
  hasLocationWritePermission?: boolean;
};

const einFormatter = (ein: string | undefined, removeFormat = false) => {
  if (ein === undefined) return '';

  const numEin = ein.replaceAll('-', '');

  if (removeFormat || numEin.length < 3) return numEin;

  return numEin.slice(0, 2) + '-' + numEin.slice(2);
};

const websiteValidator = ({ value, t }: { value: string; t: TFunction<'business-info', undefined> }) => {
  const lowercaseValue = value.toLowerCase();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (lowercaseValue.includes('google.com')) {
    return t('Website cannot be a link to Google');
  }
  if (lowercaseValue.includes('yahoo.com')) {
    return t('Website cannot be a link to Yahoo');
  }
  if (lowercaseValue.includes('gmail.com')) {
    return t('Website cannot be a link to Gmail');
  }
  if (lowercaseValue.includes('getweave.com')) {
    return t('Website cannot be a link to Weave');
  }
  if (!urlRegex({ exact: true, strict: false }).test(lowercaseValue)) {
    return t('Please enter a valid website URL');
  }
  if (emailRegex.test(lowercaseValue)) {
    return t('Website cannot be an email address');
  }
  if (lowercaseValue.length > 100) {
    return t('Website cannot be longer than 100 characters');
  }
  return '';
};

const findFamilyLocationMatchingEinBrand = (ein: string, familyLocations?: BusinessInfoTypes.LocationTCRBrand[]) => {
  if (familyLocations === undefined || familyLocations.length === 0) return undefined;
  return familyLocations.find(
    (locationTcrBrand) => locationTcrBrand.tcrBrand?.ein && locationTcrBrand.tcrBrand.ein === ein
  )?.tcrBrand;
};

// Returns true if searchResults matches the current form values
const searchResultsHasExactMatch = (
  searchResults: BusinessInfoTypes.EINSearchRecord[],
  matchValues: Required<
    Pick<
      BusinessInfoTypes.BusinessInfoFormValues,
      'ein' | 'companyName' | 'state' | 'city' | 'postalCode' | 'street' | 'street2'
    >
  >
) => {
  const combinedStreetLine = `${matchValues.street} ${matchValues.street2}`.toUpperCase().trim();
  return searchResults
    ? searchResults.some((currentResult) => {
        const truncatedResultPostalCode =
          currentResult.zipCode && currentResult.zipCode.length > 5
            ? currentResult.zipCode.slice(0, 5)
            : currentResult.zipCode;
        const combinedAddressLine = `${currentResult.address ?? ''} ${currentResult.address2 ?? ''}`
          .toUpperCase()
          .trim();
        return (
          currentResult.ein === matchValues.ein &&
          combinedAddressLine === combinedStreetLine &&
          currentResult.companyName?.toUpperCase() === matchValues.companyName?.toUpperCase() &&
          currentResult.city === matchValues.city &&
          currentResult.state === matchValues.state &&
          truncatedResultPostalCode === matchValues.postalCode
        );
      })
    : false;
};

export const BusinessInfoForm = ({
  info,
  isDemoMode,
  isVerified,
  previousValues,
  familyLocationTcrBrands,
  handleSubmit,
  handleCancel,
  handleFamilyLocationEinMatch,
  hasLocationWritePermission,
}: BusinessInfoFormProps) => {
  const [businessType, _setBusinessType] = useState<BusinessInfoTypes.EntityType | undefined>(info.entityType);
  const { t } = useTranslation('business-info');
  const [einSearchIsActive, setEinSearchIsActive] = useState(false);
  const [einSearchIsReverse, setEinSearchIsReverse] = useState(false);
  const [searchResults, setSearchResults] = useState<BusinessInfoTypes.EINSearchRecord[]>([]);
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'right' });
  const { locationData, isMultiLocation } = useLocationDataShallowStore('locationData', 'isMultiLocation');

  const { data: salesforceAccountDetails } = SubscriptionsQueries.useGetSalesforceAccountBySlug({
    enabled: !isMultiLocation && !!locationData?.Slug,
    slug: locationData?.Slug ?? '',
    consumer: Consumer.COMMX,
  });

  const suggestedDisplayName = locationData?.Name ?? '';
  const suggestedWebsite = salesforceAccountDetails?.account?.website ?? '';

  const isNewBrand = !info.brandId;
  const initialValues = isNewBrand
    ? previousValues
    : ({
        soleProprietor: info.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR,
        companyName:
          info.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR
            ? info.displayName
            : info.companyName,
        displayName: info.displayName ?? '',
        ein: info.ein,
        street: info.street,
        city: info.city,
        postalCode: info.postalCode,
        country: info.country ?? BusinessInfoTypes.Country.COUNTRY_US,
        state: info.state,
        firstName: info.firstName,
        lastName: info.lastName,
        email: info.email,
        phone: info.phone?.replace('+1', ''),
        vertical: info.vertical,
        website: info.website,
      } as BusinessInfoTypes.BusinessInfoFormValues);
  const [isUS, setIsUS] = useState(
    initialValues?.country === BusinessInfoTypes.Country.COUNTRY_US || initialValues?.country === undefined
  );
  const isTrulyVerified =
    info.identityStatus === BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VERIFIED ||
    info.identityStatus === BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VETTED_VERIFIED;
  const industries = Object.values(BusinessInfoTypes.TCRVertical)
    .filter((vertical) => vertical !== BusinessInfoTypes.TCRVertical.TCR_VERTICAL_UNSPECIFIED)
    .map((vertical) => {
      const label =
        vertical !== BusinessInfoTypes.TCRVertical.TCR_VERTICAL_NGO
          ? titleCase(vertical.replace('TCR_VERTICAL_', '').replaceAll('_', ' '))
          : vertical.replace('TCR_VERTICAL_', '').replaceAll('_', ' ');
      return {
        value: vertical,
        label,
      };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const businessInfoModalControl = useModalControl();
  const einInfoModalControl = useModalControl();
  const bnInfoModalControl = useModalControl();
  const additionalInfoModalControl = useModalControl();

  const einValidator = ({ value }: { value: string }) => {
    const numValue = einFormatter(value, true);
    return numValue.length === 9 && /^\d+$/gm.test(numValue) ? '' : t('Must be 9 digits long');
  };
  const bnValidator = ({ value }: { value: string }) => (value.length < 9 ? t('Must be at least 9 characters') : '');
  const phoneValidator = ({ value }: { value: string }) =>
    value.charAt(0) === '1' || value.charAt(0) === '0' ? t('Invalid phone number') : '';

  const { formProps, getFieldProps, changedValues, isComplete, validate, values } = useForm({
    computeChangedValues: true,
    allowInvalidSubmission: isDemoMode,
    fields: {
      soleProprietor: {
        type: 'checkbox',
        value: initialValues?.soleProprietor,
        disabled: !isNewBrand && !initialValues?.soleProprietor,
        validateOnChange: true,
      },
      companyName: {
        type: 'text',
        required: true,
        value: initialValues?.companyName ?? '',
        validateOnChange: true,
        disabled: isTrulyVerified,
      },
      displayName: {
        type: 'text',
        required: !initialValues?.soleProprietor,
        value: initialValues?.displayName,
        hidden: initialValues?.soleProprietor,
        validateOnChange: true,
      },
      ein: {
        type: 'text',
        required: !initialValues?.soleProprietor,
        value: isUS ? einFormatter(initialValues?.ein) : initialValues?.ein,
        validator: isUS ? einValidator : bnValidator,
        validateOnChange: true,
        disabled: isTrulyVerified,
      },
      street: { type: 'text', required: true, value: initialValues?.street, validateOnChange: true },
      street2: { type: 'text', required: false, value: initialValues?.street2, validateOnChange: true },
      city: { type: 'text', required: true, value: initialValues?.city, validateOnChange: true },
      postalCode: {
        type: 'postalCode',
        required: true,
        value: initialValues?.postalCode,
        locale: isUS ? PostalCodeLocale.US : PostalCodeLocale.CA,
        validateOnChange: true,
      },
      country: {
        type: 'dropdown',
        required: true,
        value: initialValues?.country ?? BusinessInfoTypes.Country.COUNTRY_US,
        validateOnChange: true,
        disabled: isTrulyVerified,
      },
      state: { type: 'dropdown', required: true, value: initialValues?.state, validateOnChange: true },
      firstName: {
        type: 'text',
        required: initialValues?.soleProprietor,
        value: initialValues?.firstName,
        hidden: !initialValues?.soleProprietor,
        validateOnChange: true,
      },
      lastName: {
        type: 'text',
        required: initialValues?.soleProprietor,
        value: initialValues?.lastName,
        hidden: !initialValues?.soleProprietor,
        validateOnChange: true,
      },
      email: { type: 'email', required: true, value: initialValues?.email, validateOnChange: true },
      phone: {
        type: 'phone',
        required: true,
        value: initialValues?.phone,
        validateOnChange: true,
        validator: phoneValidator,
      },
      vertical: { type: 'text', required: true, value: initialValues?.vertical, validateOnChange: true },
      website: {
        type: 'text',
        required: true,
        value: initialValues?.website,
        validator: ({ value }) => websiteValidator({ value, t }),
        validateOnChange: true,
      },
      websiteValidation: {
        type: 'checkbox',
        value: false,
        required: true,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update) {
        if (action.payload.name === 'country') {
          if (state.country.value === BusinessInfoTypes.Country.COUNTRY_US) {
            setIsUS(true);
            return {
              ...state,
              ein: {
                ...state.ein,
                // typescript for some reason thinks that state.ein.value could be a boolean here, which it shouldn't
                value: typeof state.ein.value === 'string' ? einFormatter(state.ein.value) : '',
                validator: einValidator,
              },
              postalCode: {
                ...state.postalCode,
                value: '',
                error: t('This field is required'),
                locale: PostalCodeLocale.US,
              },
              state: {
                ...state.state,
                value: '',
                error: t('This field is required'),
              },
            };
          } else if (state.country.value === BusinessInfoTypes.Country.COUNTRY_CA) {
            setIsUS(false);
            return {
              ...state,
              ein: {
                ...state.ein,
                // typescript for some reason thinks that state.ein.value could be a boolean here, which it shouldn't
                value: typeof state.ein.value === 'string' ? einFormatter(state.ein.value, true) : '',
                validator: bnValidator,
              },
              postalCode: {
                ...state.postalCode,
                value: '',
                error: t('This field is required'),
                locale: PostalCodeLocale.CA,
              },
              state: {
                ...state.state,
                value: '',
                error: t('This field is required'),
              },
            };
          }
        }

        if (action.payload.name === 'soleProprietor') {
          if (action.payload.value) {
            _setBusinessType(BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR);
            return {
              ...state,
              ein: { ...state.ein, required: false, disabled: true, value: '', error: '' },
              displayName: { ...state.displayName, hidden: true, required: false },
              firstName: { ...state.firstName, required: true, hidden: false },
              lastName: { ...state.lastName, required: true, hidden: false },
              phone: {
                ...state.phone,
                value: '',
                error: t('This field is required'),
              },
            };
          } else {
            _setBusinessType(BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT);
            return {
              ...state,
              ein: {
                ...state.ein,
                required: true,
                disabled: false,
                value: '',
                error: '',
              },
              displayName: { ...state.displayName, hidden: false, required: true, value: '' },
              firstName: { ...state.firstName, required: false, hidden: true },
              lastName: { ...state.lastName, required: false, hidden: true },
              phone: {
                ...state.phone,
                value: '',
                error: t('This field is required'),
              },
            };
          }
        } else if (action.payload.name === 'ein' && isUS) {
          return {
            ...state,
            ein: {
              ...state.ein,
              value: einFormatter(action.payload.value),
            },
          };
        }
      }

      return null;
    },
    onSubmit: async (values, changedValues) => {
      if (isDemoMode) {
        handleSubmit(
          values as BusinessInfoTypes.BusinessInfoFormValues,
          changedValues as Partial<BusinessInfoTypes.BusinessInfoFormValues>
        );
        return;
      }
      // Remove formatting from ein if present
      if (values.ein) values.ein = values.ein.replaceAll('-', '');
      if (changedValues && changedValues.ein) changedValues.ein = changedValues?.ein.replaceAll('-', '');

      // If a family location has a brand using the same EIN, connect current location to existing brand
      if (familyLocationTcrBrands && familyLocationTcrBrands.length > 0 && values.ein && isNewBrand) {
        const matchingBrand = findFamilyLocationMatchingEinBrand(values.ein, familyLocationTcrBrands);
        if (matchingBrand !== undefined) {
          handleFamilyLocationEinMatch(matchingBrand);
          return;
        }
      }

      // Skip ein lookup if brand is Canadian, Sole Proprietor, or already verified
      if (values.country === BusinessInfoTypes.Country.COUNTRY_CA || values.soleProprietor || isTrulyVerified) {
        handleSubmit(
          values as BusinessInfoTypes.BusinessInfoFormValues,
          changedValues as Partial<BusinessInfoTypes.BusinessInfoFormValues>
        );
        return;
      }

      // Ein lookup/suggestion flow
      setEinSearchIsActive(true);
      if (values.ein && values.companyName && values.city && values.state && values.postalCode) {
        const matchValues = {
          ein: values.ein,
          street: values.street ?? '',
          street2: values.street2 ?? '',
          companyName: values.companyName,
          city: values.city,
          state: values.state,
          postalCode: values.postalCode,
        };

        // Forward ein lookup
        const forwardSearchRes = await BusinessInfoApi.getEinLookup({
          ein: changedValues?.ein || values.ein,
        }).catch(() => Promise.resolve({ searchResults: [] as BusinessInfoTypes.EINSearchRecord[] }));
        if (searchResultsHasExactMatch(forwardSearchRes.searchResults ?? [], matchValues)) {
          handleSubmit(
            values as BusinessInfoTypes.BusinessInfoFormValues,
            changedValues as Partial<BusinessInfoTypes.BusinessInfoFormValues>
          );
          setEinSearchIsActive(false);
          return;
        } else if (forwardSearchRes.searchResults && forwardSearchRes.searchResults.length > 0) {
          setSearchResults(forwardSearchRes.searchResults);
          return;
        }

        // If no results from forward lookup (or error response) use reverse ein lookup
        setEinSearchIsReverse(true);
        const reverseSearchRes = await BusinessInfoApi.getReverseEinLookup({
          company: matchValues.companyName,
          state: matchValues.state,
          city: matchValues.city,
          zipCode: matchValues.postalCode.slice(0, 5),
        }).catch(() => Promise.resolve({ searchResults: [] as BusinessInfoTypes.EINSearchRecord[] }));
        if (
          searchResultsHasExactMatch(reverseSearchRes.searchResults ?? [], matchValues) ||
          (reverseSearchRes.searchResults && reverseSearchRes.searchResults.length === 0) ||
          reverseSearchRes.searchResults === undefined
        ) {
          handleSubmit(
            values as BusinessInfoTypes.BusinessInfoFormValues,
            changedValues as Partial<BusinessInfoTypes.BusinessInfoFormValues>
          );
          setEinSearchIsActive(false);
          return;
        }

        setSearchResults(reverseSearchRes.searchResults);
      } else {
        validate();
      }
    },
  });

  const submitLabel = isNewBrand ? t('Submit') : isVerified ? t('Update') : t('Resubmit');
  const submitDisabled = isNewBrand ? !isComplete : !(changedValues && isComplete);
  const [hasBeenCompleted, setHasBeenCompleted] = useState(!isNewBrand);

  const handleEinSuggestionSelection = (einSuggestion: BusinessInfoTypes.EINSearchRecord) => {
    const resolvedValues = {
      ...values,
      ein: einSuggestion.ein ?? values.ein,
      companyName: einSuggestion.companyName ?? values.companyName,
      street: einSuggestion.address ?? values.street,
      street2: einSuggestion.address2 ?? values.street2,
      city: einSuggestion.city ?? values.city,
      state: einSuggestion.state ?? values.state,
      postalCode: einSuggestion.zipCode ?? values.postalCode,
    };

    const resolvedChangedValues: Partial<BusinessInfoTypes.BusinessInfoFormValues> = {
      ...(changedValues as Partial<BusinessInfoTypes.BusinessInfoFormValues>),
    };

    // Add ein suggestion values if they don't match initial values
    // and remove changed value if suggestion is equal to initial values
    if (einSuggestion.ein !== previousValues?.ein) {
      resolvedChangedValues.ein = einSuggestion.ein;
    } else if (resolvedChangedValues.ein) {
      delete resolvedChangedValues.ein;
    }
    if (einSuggestion.companyName !== previousValues?.companyName) {
      resolvedChangedValues.companyName = einSuggestion.companyName;
    } else if (resolvedChangedValues.companyName) {
      delete resolvedChangedValues.companyName;
    }
    if (
      `${einSuggestion.address} ${einSuggestion.address2}`.trim() !==
      `${previousValues?.street} ${previousValues?.street2}`.trim()
    ) {
      resolvedChangedValues.street = einSuggestion.address;
      resolvedChangedValues.street2 = einSuggestion.address2;
    } else if (resolvedChangedValues.street || resolvedChangedValues.street2) {
      if (resolvedChangedValues.street) delete resolvedChangedValues.street;
      if (resolvedChangedValues.street2) delete resolvedChangedValues.street2;
    }
    if (einSuggestion.city !== previousValues?.city) {
      resolvedChangedValues.city = einSuggestion.city;
    } else if (resolvedChangedValues.city) {
      delete resolvedChangedValues.city;
    }
    if (einSuggestion.state !== previousValues?.state) {
      resolvedChangedValues.state = einSuggestion.state;
    } else if (resolvedChangedValues.state) {
      delete resolvedChangedValues.state;
    }
    if (einSuggestion.zipCode !== previousValues?.postalCode) {
      resolvedChangedValues.postalCode = einSuggestion.zipCode;
    } else if (resolvedChangedValues.postalCode) {
      delete resolvedChangedValues.postalCode;
    }

    handleSubmit(resolvedValues as BusinessInfoTypes.BusinessInfoFormValues, resolvedChangedValues);
  };

  const resetEinLookupState = () => {
    setEinSearchIsActive(false);
    setEinSearchIsReverse(false);
    setSearchResults([]);
  };

  useEffect(() => {
    if (!submitDisabled && !hasBeenCompleted) setHasBeenCompleted(true);
  }, [submitDisabled]);

  useEffect(() => {
    // Display validation errors after form has been completed, since they don't always appear when values are programmatically changed
    if (hasBeenCompleted) {
      validate();
    }
  }, [values.country, values.soleProprietor]);

  const einNameString = isUS ? 'an EIN' : 'a BN';

  const disableSubmitButton =
    !isNewBrand &&
    (!changedValues ||
      Object.keys(changedValues).length === 0 ||
      (Object.keys(changedValues).length === 1 && changedValues.websiteValidation === true));

  const isValidBusinessWebsite = () => {
    if (!salesforceAccountDetails?.account) return false;
    const forbiddenDomains = ['google.com', 'yahoo.com', 'hotmail.com', 'gmail.com'];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const lowerCaseWebsite = salesforceAccountDetails?.account?.website
      ? salesforceAccountDetails?.account?.website?.toLowerCase()
      : '';

    return (
      !forbiddenDomains.some((domain) => lowerCaseWebsite.includes(domain)) &&
      !emailRegex.test(lowerCaseWebsite) &&
      urlRegex({ exact: true, strict: false }).test(lowerCaseWebsite)
    );
  };

  return (
    <>
      <form {...formProps} css={formStyles}>
        <section>
          <div css={sectionHeaderRowStyles}>
            <div css={sectionTitleStyles}>
              <Text weight='semibold'>{t('Legal Business Information')}</Text>
              <IconButton
                label={t('Open legal business info modal')}
                size='small'
                {...businessInfoModalControl.triggerProps}
              >
                <InfoRoundIconSmall color='light' />
              </IconButton>
            </div>
            {!isNewBrand && isVerified && (
              <SecondaryButton css={css({ width: 'auto' })} onClick={handleCancel}>
                {t('Cancel Update')}
              </SecondaryButton>
            )}
          </div>
          <FormRow>
            <TextField
              {...getFieldProps('companyName')}
              label={`${getFieldProps('companyName').required ? '*' : ''}${t('Legal Business Name')}`}
            />
          </FormRow>
          <FormRow>
            <TextField
              {...getFieldProps('street')}
              label={`${getFieldProps('street').required ? '*' : ''}${t('Street Address')}`}
            />
          </FormRow>
          <FormRow>
            <TextField
              {...getFieldProps('street2')}
              label={`${getFieldProps('street2').required ? '*' : ''}${t('Address Line 2')}`}
              helperText={t('Optional')}
            />
          </FormRow>
          <FormRow>
            <TextField {...getFieldProps('city')} label={`${getFieldProps('city').required ? '*' : ''}${t('City')}`} />
            <DropdownField
              {...getFieldProps('state')}
              label={`${getFieldProps('state').required ? '*' : ''}${isUS ? t('State') : t('Province')}`}
            >
              {stateOptions[
                getFieldProps('country').value === BusinessInfoTypes.Country.COUNTRY_US
                  ? CountryCodes.USA
                  : CountryCodes.Canada
              ]?.map(({ label, value }) => (
                <DropdownField.Option key={value} value={value} searchValue={label}>
                  {label}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
          <FormRow>
            <PostalCodeField
              {...getFieldProps('postalCode')}
              label={`${getFieldProps('postalCode').required ? '*' : ''}${isUS ? t('Zip Code') : t('Postal Code')}`}
            />
            <DropdownField
              {...getFieldProps('country')}
              label={`${getFieldProps('country').required ? '*' : ''}${t('Country')}`}
            >
              {countryOptions.map(({ label }) => (
                <DropdownField.Option
                  key={label}
                  value={label === 'USA' ? BusinessInfoTypes.Country.COUNTRY_US : BusinessInfoTypes.Country.COUNTRY_CA}
                >
                  {label}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
        </section>
        <section>
          <div css={sectionHeaderRowStyles}>
            <div css={sectionTitleStyles}>
              <Text weight='semibold'>
                {isUS ? t('Taxpayer Identification Number (EIN)') : t('Canadian Revenue Agency Business Number (BN)')}
              </Text>
              {isUS ? (
                <IconButton label={t('Open EIN info modal')} size='small' {...einInfoModalControl.triggerProps}>
                  <InfoRoundIconSmall color='light' />
                </IconButton>
              ) : (
                <IconButton label={t('Open BN info modal')} size='small' {...bnInfoModalControl.triggerProps}>
                  <InfoRoundIconSmall color='light' />
                </IconButton>
              )}
            </div>
          </div>
          <FormRow>
            <CheckboxField
              {...getFieldProps('soleProprietor')}
              trackingId={`${IntakePrefixes.BusinessInformation}-EIN-checkbox`}
              label={t(
                `Check this box if you operate your business as a sole proprietor and use an SSN to report taxes. By not registering your business with {{einNameString}}, you may experience slower or undelivered SMS traffic and/or higher fees from the mobile network operators.`,
                { einNameString }
              )}
            />
          </FormRow>
          <FormRow>
            <TextField
              {...getFieldProps('ein')}
              css={einTextFieldMediaQuery}
              label={`${getFieldProps('ein').required ? '*' : ''}${
                isUS ? t('Taxpayer Identification Number (EIN)') : t('Canadian Revenue Agency Business Number (BN)')
              }`}
              disabled={getFieldProps('soleProprietor').value || getFieldProps('ein').disabled}
              helperText={isUS ? t('EINs typically use this format: ##-#######') : undefined}
            />
          </FormRow>
        </section>
        <section>
          <div css={sectionHeaderRowStyles}>
            <div css={sectionTitleStyles}>
              <Text weight='semibold'>{t('Additional Information')}</Text>
              <IconButton
                label={t('Open additional info modal')}
                size='small'
                {...additionalInfoModalControl.triggerProps}
              >
                <InfoRoundIconSmall color='light' />
              </IconButton>
            </div>
          </div>
          <FormRow>
            <TextField
              {...getFieldProps('displayName')}
              label={`${getFieldProps('displayName').required ? '*' : ''}${t('Doing Business as Name')}`}
              helperText={t('Suggested name: {{suggestedDisplayName}}', { suggestedDisplayName })}
            />
          </FormRow>
          <FormRow>
            <TextField
              {...getFieldProps('firstName')}
              label={`${getFieldProps('firstName').required ? '*' : ''}${t('First Name')}`}
            />
          </FormRow>
          <FormRow>
            <TextField
              {...getFieldProps('lastName')}
              label={`${getFieldProps('lastName').required ? '*' : ''}${t('Last Name')}`}
            />
          </FormRow>
          <FormRow>
            <EmailField
              {...getFieldProps('email')}
              label={`${getFieldProps('email').required ? '*' : ''}${t('Primary Contact Email')}`}
              helperText={t('When possible, use the email that matches your website (e.g., name@yourofffice.com)')}
            />
          </FormRow>
          <FormRow>
            <PhoneField
              {...getFieldProps('phone')}
              label={`${getFieldProps('phone').required ? '*' : ''}${t(
                `Primary Contact ${
                  businessType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR ? 'Mobile ' : ''
                }Phone Number`
              )}`}
            />
          </FormRow>
          <FormRow>
            <DropdownField
              {...getFieldProps('vertical')}
              label={`${getFieldProps('vertical').required ? '*' : ''}${t('Industry')}`}
            >
              {industries.map((vertical) => (
                <DropdownField.Option key={vertical.value} value={vertical.value} searchValue={vertical.label}>
                  {vertical.label}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
          <FormRow>
            <TextField
              {...getFieldProps('website')}
              label={`${getFieldProps('website').required ? '*' : ''}${t('Website')}`}
              helperText={
                isValidBusinessWebsite() && isNewBrand && suggestedWebsite
                  ? t('Suggested website: {{suggestedWebsite}}', { suggestedWebsite })
                  : undefined
              }
            />
          </FormRow>
          <FormRow
            css={{
              marginBottom: 0,
            }}
          >
            <CheckboxField
              {...getFieldProps('websiteValidation')}
              trackingId={`${IntakePrefixes.BusinessInformation}-website-verify-checkbox`}
              label={t(`I verify that if the website entered above collects a phone number there is included:`)}
            />
          </FormRow>
          <Trans t={t} suggestedDisplayName={suggestedDisplayName}>
            <ul
              css={{
                marginBottom: theme.spacing(2),
              }}
            >
              <Text as='li'>
                <b>Opt-In Language</b> stating{' '}
                <i>
                  “I consent to receive SMS text messages from {{ suggestedDisplayName }}. Msg&data rates may apply.
                  Reply STOP to opt out.”
                </i>{' '}
                and a <b>Checkbox</b> next to any phone # collection field.
              </Text>
              <Text as='li'>
                A <b>Privacy Policy</b> that explicitly states{' '}
                <i>“Consumer information is not shared with third-parties for marketing purposes.”</i>
              </Text>
            </ul>
          </Trans>
        </section>
        <section {...triggerProps}>
          <PrimaryButton
            type='submit'
            disabled={disableSubmitButton}
            trackingId={`${IntakePrefixes.BusinessInformation}-EIN-submit-btn`}
          >
            {submitLabel}
          </PrimaryButton>
          {disableSubmitButton && (
            <Tooltip {...tooltipProps}>{t('Please change a value before resubmitting.')}</Tooltip>
          )}
        </section>
      </form>

      <Modal {...businessInfoModalControl.modalProps}>
        <Modal.Header>{t('Legal Business Information')}</Modal.Header>
        <Modal.Body>
          <Text>
            <Trans t={t}>
              This information must be<i> exactly</i> as it is filed with the IRS or CRA.
            </Trans>
          </Text>
          <Text weight='bold' css={modalStyles.section}>
            For US:
          </Text>
          <ul css={modalStyles.listSection}>
            <Text as='li'>
              {t(
                'Check any of your IRS-issued documentation, such as your IRS SS-4 confirmation letter or your Letter 147C (the record of how your entity is filed with the IRS).'
              )}
            </Text>
            <Text as='li'>
              <Trans t={t}>
                {'Ask the IRS for a copy of your Letter 147C by calling the Business & Specialty Tax Line at '}
                <TextLink to='tel:8008294933'>800-829-4933</TextLink>
                {'. You can find more information '}
                <TextLink to='https://www.irs.gov/businesses/small-businesses-self-employed/lost-or-misplaced-your-ein'>
                  here
                </TextLink>
                .
              </Trans>
            </Text>
          </ul>
          <Text weight='bold' css={modalStyles.section}>
            For Canada:
          </Text>
          <ul css={modalStyles.listSection}>
            <Text as='li'>
              <Trans t={t}>
                {'Search '}
                <TextLink to='https://beta.canadasbusinessregistries.ca/search'>Canada's Business Registries</TextLink>.
              </Trans>
            </Text>
          </ul>
        </Modal.Body>
        <Modal.Actions primaryLabel='Okay' onPrimaryClick={businessInfoModalControl.closeModal} />
      </Modal>

      <Modal {...einInfoModalControl.modalProps}>
        <Modal.Header>{t('Employer Identification Number')}</Modal.Header>
        <Modal.Body>
          <Text>
            {t(
              'If you are a US company or a foreign company with a US IRS Employer Identification Number (EIN), please enter that nine-digit number in the EIN field without dashes.'
            )}
          </Text>
          <Text weight='bold' css={modalStyles.section}>
            Where can I find my EIN?
          </Text>
          <ul css={modalStyles.listSection}>
            <Text as='li'>
              {t(
                'Check any of your IRS-issued documentation, such as your IRS SS-4 confirmation letter or your Letter 147C (the record of how your entity is filed with the IRS).'
              )}
            </Text>
            <Text as='li'>
              <Trans t={t}>
                {'Ask the IRS for a copy of your Letter 147C by calling the Business & Specialty Tax Line at '}
                <TextLink to='tel:8008294933'>800-829-4933</TextLink>
                {'. You can find more information '}
                <TextLink to='https://www.irs.gov/businesses/small-businesses-self-employed/lost-or-misplaced-your-ein'>
                  here
                </TextLink>
                .
              </Trans>
            </Text>
            <Text as='li'>
              {t(
                'If you used your EIN to open a bank account, or apply for any type of state or local license, you should contact the bank or agency to secure your EIN.'
              )}
            </Text>
            <Text as='li'>
              {t('Find a previously filed tax return for your business entity. It will include your EIN.')}
            </Text>
          </ul>
        </Modal.Body>
        <Modal.Actions primaryLabel='Okay' onPrimaryClick={einInfoModalControl.closeModal} />
      </Modal>

      <Modal {...bnInfoModalControl.modalProps}>
        <Modal.Header>{t('Canada Revenue Agency Business Number')}</Modal.Header>
        <Modal.Body>
          <Text>
            {t(
              'If your primary business registration is in Canada, please enter your Canadian Corporation Number, which may be federal or provincial. Please do NOT enter your business number or federal tax ID number, as that is not readily cross-referenced.'
            )}
          </Text>
          <ul css={modalStyles.section}>
            <Text as='li'>{t('Alberta: Corporate Access Numbers')}</Text>
            <Text as='li'>{t('British Columbia: Requires an alpha prefix (BC)')}</Text>
            <Text as='li'>{t('Quebec: Quebec Business Number')}</Text>
            <Text as='li'>{t('Ontario: Federal Corporation Business Number')}</Text>
            <Text as='li'>{t('Manitoba: Manitoba Corporation Number')}</Text>
          </ul>
          <Text weight='bold' css={modalStyles.section}>
            Where can I find my BN?
          </Text>
          <ul css={modalStyles.listSection}>
            <Text as='li'>
              <Trans t={t}>
                {'Search '}
                <TextLink to='https://beta.canadasbusinessregistries.ca/search'>Canada's Business Registries</TextLink>.
              </Trans>
            </Text>
            <Text as='li'>
              {t(
                'If you used your BN to open a bank account, or apply for any type of federal, provincial or local license or program (e.g., GST/HST, payroll deductions, corporate income tax), you should contact the bank or agency to secure your BN.'
              )}
            </Text>
            <Text as='li'>
              {t(
                'Find a previously filed tax return for your existing entity (if you have filed a return) for which you have your lost or misplaced BN. Your previously filed return should be notated with your BN.'
              )}
            </Text>
          </ul>
        </Modal.Body>
        <Modal.Actions primaryLabel='Okay' onPrimaryClick={bnInfoModalControl.closeModal} />
      </Modal>

      <Modal {...additionalInfoModalControl.modalProps}>
        <Modal.Header>{t('Additional Information')}</Modal.Header>
        <Modal.Body>
          {businessType === BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT && (
            <>
              <Text weight='semibold' css={modalStyles.section}>
                {t('Doing Business as Name:')}
              </Text>
              <Text>
                {t(
                  'The outward-facing, public brand name of your business, if it is different from the legal business name.'
                )}
              </Text>
            </>
          )}
          <Text weight='semibold'>
            {businessType === BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT
              ? t('Primary Contact Email and Phone Number:')
              : t('Primary Contact Information:')}
          </Text>
          <Text>
            {t(
              'This information may be used by The Campaign Registry to contact you in order to verify the legitimacy of your business.'
            )}
          </Text>
          {businessType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR && (
            <>
              <Text css={modalStyles.section} weight='semibold'>
                {t('Mobile Phone Number:')}
              </Text>
              <Text>
                {t(
                  'If you are registering as a Sole Proprietor, this phone number must be able to receive text messages for verification purposes.'
                )}
              </Text>
            </>
          )}
          <Text css={modalStyles.section} weight='semibold'>
            {t('Industry:')}
          </Text>
          <Text>
            {t(
              'Select the single industry that best describes your business, even if your business operates in multiple industries.'
            )}
          </Text>
          <Text css={modalStyles.section} weight='semibold'>
            {t('Website:')}
          </Text>
          <Text>
            {t(
              'If you do not have a website for your business, enter a link to your online presence (i.e. social media, Google Business, etc).'
            )}
          </Text>
        </Modal.Body>
        <Modal.Actions primaryLabel='Okay' onPrimaryClick={additionalInfoModalControl.closeModal} />
      </Modal>

      <EinSuggestionModal
        show={einSearchIsActive && searchResults.length > 0}
        searchResults={searchResults}
        isReverseSearch={einSearchIsReverse}
        onConfirm={handleEinSuggestionSelection}
        onContinue={() => {
          handleSubmit(
            values as BusinessInfoTypes.BusinessInfoFormValues,
            changedValues as Partial<BusinessInfoTypes.BusinessInfoFormValues>
          );
          resetEinLookupState();
        }}
        onClose={() => {
          resetEinLookupState();
        }}
        hasLocationWritePermission={hasLocationWritePermission}
        isNewBrand={isNewBrand}
      />
    </>
  );
};

const formStyles = css`
  max-width: 500px;
  margin-top: ${`-${theme.spacing(2)}`};

  hr {
    border: 0;
    border-bottom: solid 1px ${theme.colors.neutral20};
  }
`;

const sectionTitleStyles = css`
  display: flex;
  align-items: center;
  overflow: visible;
`;

const sectionHeaderRowStyles = css`
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  align-items: center;
  margin-bottom: ${theme.spacing(2)};
`;

const modalStyles = {
  section: css`
    margin-top: ${theme.spacing(2)};
  `,
  listSection: css`
    paddingleft: ${theme.spacing(2)};
  `,
};

const einTextFieldMediaQuery = css`
  height: 40px;
  @media only screen and (max-width: ${breakpoints.xsmall.max}) {
    height: 50px;
  }
`;
