import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotification } from './use-notification';

export const useScheduleRequestNotification = () => {
  useCommonActionSubscriptions('schedule-request-new');
  const { create, remove } = useNotification<'schedule-request-new'>();
  return {
    create,
    remove,
  };
};
