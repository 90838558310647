import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { FormsQueryKeys, FormsWritebacks } from '@frontend/api';
import { useAlert } from '@frontend/design-system';
import { useWritebackWidgetStore, useWritebackWidgetContext } from '../providers';

const ERROR_MESSAGE = 'Writeback retry failed! ';

type TriggerWritebackRetryFn = (retrySearch?: boolean) => Promise<FormsWritebacks.Types.RetryWritebackResponse>;

interface UseWritebackRetryResults {
  triggerWritebackRetry: TriggerWritebackRetryFn;
}

export const useWritebackRetry = (): UseWritebackRetryResults => {
  const queryClient = useQueryClient();
  const alert = useAlert();

  const {
    createPersonSetting,
    sourceTenantId,
    updatePersonSetting,
    uploadDocumentSetting,
    writebackMode,
    isSourceTenantIdFromSubmissionTheRootSourceId,
    sourceId,
    setIsRetryingWriteback,
  } = useWritebackWidgetStore([
    'createPersonSetting',
    'updatePersonSetting',
    'uploadDocumentSetting',
    'sourceTenantId',
    'writebackMode',
    'setIsRetryingWriteback',
    'isSourceTenantIdFromSubmissionTheRootSourceId',
    'sourceId',
  ]);

  const { submissionId, locationId } = useWritebackWidgetContext();

  const refetchSubmission = useCallback(
    (payload: FormsWritebacks.Types.RetryWritebackPayload) => {
      queryClient.invalidateQueries(FormsQueryKeys.formSubmissions);
      queryClient.invalidateQueries([FormsQueryKeys.formSubmission, payload.submissionId]);
    },
    [queryClient]
  );

  const { mutateAsync: retryWriteback } = useMutation(FormsWritebacks.API.retryWriteback, {
    onSuccess: (res, payload) => {
      refetchSubmission(payload);

      if (!res.success) {
        alert.error(getWBErrorMessage(res.data?.data?.message, '|'));
        return;
      }

      alert.success('Writeback was successful!');
    },
    onError: (_, payload) => {
      refetchSubmission(payload);
      alert.error(ERROR_MESSAGE);
    },
  });

  const triggerWritebackRetry = useCallback<TriggerWritebackRetryFn>(
    async (retrySearch = false) => {
      const settingIdsToRetry: string[] = [];

      if (createPersonSetting.value) {
        settingIdsToRetry.push(createPersonSetting.settingId);
      }

      if (updatePersonSetting.value) {
        settingIdsToRetry.push(updatePersonSetting.settingId);
      }

      if (uploadDocumentSetting.value) {
        settingIdsToRetry.push(uploadDocumentSetting.settingId);
      }

      const payload: FormsWritebacks.Types.RetryWritebackPayload = {
        settingIds: settingIdsToRetry,
        submissionId: submissionId,
        mode: writebackMode,
        sourceTenantId: isSourceTenantIdFromSubmissionTheRootSourceId ? sourceId : sourceTenantId,
        locationId,
      };

      if (retrySearch) {
        delete payload.sourceTenantId;
      }

      setIsRetryingWriteback(true);
      const response = await retryWriteback(payload);
      setIsRetryingWriteback(false);

      return response;
    },
    [
      submissionId,
      sourceTenantId,
      createPersonSetting,
      updatePersonSetting,
      uploadDocumentSetting,
      writebackMode,
      isSourceTenantIdFromSubmissionTheRootSourceId,
      sourceId,
      locationId,
      setIsRetryingWriteback,
      retryWriteback,
    ]
  );

  return {
    triggerWritebackRetry,
  };
};

function getWBErrorMessage(str: string, delimiter: string): string {
  const index = str.indexOf(delimiter);

  if (index !== -1) {
    return ERROR_MESSAGE + str.slice(0, index);
  }

  return ERROR_MESSAGE;
}
