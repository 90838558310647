import { useMemo, useState } from 'react';
import {
  Contact,
  ContactList,
  Direction,
  GetBatchesResponse,
  GetMessagesRequest_Category,
  Usage,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import { ListRecipientsRequest } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/bulk/v1/service.pb';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import {
  SchemaBulkMessage,
  SchemaBulkMetl,
  SchemaCommPhoneNumberService,
  SchemaIO,
  SchemaTenDLCCoordinator,
} from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { useBulkQueryKeys } from './hooks';
import { validateFilters } from './utils';
import { MessagesTypes } from '.';

// TODO: Fix all error handling. When the server throws a 400 error, things just keep going like there's no problems at all!

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

type SchemaGetBatches = SchemaIO<(typeof SchemaBulkMessage)['GetBatches']>;
export const useGetBatches = (
  year: number = new Date().getFullYear(),
  month?: number,
  direction = Direction.DESCENDING,
  options: UseQueryOptions<SchemaGetBatches['output']> = {}
) => {
  const queryKeys = useBulkQueryKeys();
  const request: SchemaGetBatches['input'] = {
    year,
    month,
    direction,
    limit: month !== undefined ? undefined : 5,
  };

  return useQuery({
    ...defaultOptions,
    queryKey: queryKeys.batches(direction, year, month),
    queryFn: () => SchemaBulkMessage.GetBatches(request),
    ...options,
  });
};

export const useGetBatchesForSelectedLocations = (
  year: number = new Date().getFullYear(),
  month?: number,
  direction = Direction.DESCENDING,
  options: UseQueryOptions<GetBatchesResponse> = {}
) => {
  const { selectedLocationIdsWithParents: locationIds } = useAppScopeStore();

  const request = {
    year,
    month,
    direction,
  };

  return useQueries(
    locationIds.map((locationId) => ({
      ...defaultOptions,
      ...options,
      queryFn: () => SchemaBulkMessage.GetBatches({ ...request, locationId }),
    }))
  ).map((result, index) => {
    // TODO: this seems brittle but it's better than relying on trying to dig into the results.batches array and grab the locationId from there if it even has any batches
    const locationId = locationIds[index]!;
    return { ...result?.data?.batches?.[0]?.usage, locationId } as Usage & { locationId: string };
  });
};

export const useExpandGetBatches = (options?: { onError: (err: unknown) => void }) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const queryKeys = useBulkQueryKeys();

  const expandBatch = async (year: number, month: number, direction: Direction) => {
    try {
      const expandedMonthExists =
        queryClient.getQueryData<GetBatchesResponse>(queryKeys.batches(direction, year, month)) !== undefined;
      if (expandedMonthExists) return;

      setIsLoading(true);
      const data = await SchemaBulkMessage.GetBatches({ year, month, direction, limit: 0 });
      const cache = queryClient.getQueryData<SchemaGetBatches['output']>(queryKeys.batches(direction, year));
      const updatedMonthlyBatch = data?.batches?.[0];
      if (!updatedMonthlyBatch || !cache?.batches) return;

      const updatedCache: GetBatchesResponse = {
        ...cache,
        batches: cache.batches.map((monthlyBatch) =>
          monthlyBatch.month === updatedMonthlyBatch.month ? updatedMonthlyBatch : monthlyBatch
        ),
      };

      queryClient.setQueryData(queryKeys.batches(direction, year), updatedCache);
      queryClient.setQueryData(queryKeys.batches(direction, year, month), data);
    } catch (err) {
      options?.onError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, expandBatch };
};

type SchemaGetBatch = SchemaIO<(typeof SchemaBulkMessage)['GetBatch']>;
export const useGetBatch = (batchId: string, options: UseQueryOptions<SchemaGetBatch['output']> = {}) => {
  const request: SchemaGetBatch['input'] = { batchId };
  const queryKeys = useBulkQueryKeys();

  return useQuery({
    ...defaultOptions,
    queryKey: queryKeys.batch(batchId),
    queryFn: () => SchemaBulkMessage.GetBatch(request),
    enabled: !!batchId,
    ...options,
  });
};

type SchemaGetPeople = SchemaIO<(typeof SchemaBulkMessage)['GetMessages']>;
export const useGetPeople = (
  batchId: string,
  category: GetMessagesRequest_Category,
  options: UseQueryOptions<SchemaGetPeople['output']> = {}
) => {
  // TODO: Handle pagination
  const request: SchemaGetPeople['input'] = { batchId, category };
  const queryKeys = useBulkQueryKeys();

  return useQuery({
    ...defaultOptions,
    queryKey: queryKeys.people(batchId, category),
    queryFn: () => SchemaBulkMessage.GetMessages(request),
    enabled: !!batchId,
    ...options,
  });
};

// TODO: Actually fix batch and cache invalidation
const useInvalidateBatches = () => {
  const queryClient = useQueryClient();
  const queryKeys = useBulkQueryKeys();

  return (direction: Direction, batchId?: string) => {
    const currentYear = new Date().getFullYear();
    queryClient.invalidateQueries(queryKeys.batches(direction, currentYear));

    if (batchId) {
      queryClient.invalidateQueries(queryKeys.batch(batchId));
    }
  };
};

export const useCancelBatch = () => {
  const invalidateBatches = useInvalidateBatches();
  return (batchId: string, weaveUserId: string, direction: Direction) =>
    SchemaBulkMessage.CancelBatch({ batchId, weaveUserId }).then(() => {
      invalidateBatches(direction, batchId);
    });
};

export const useCreateBatch = () => {
  const invalidateBatches = useInvalidateBatches();
  return (batch: SchemaIO<typeof SchemaBulkMessage.CreateBatch>['input'], direction: Direction) =>
    SchemaBulkMessage.CreateBatch(batch).then(() => {
      invalidateBatches(direction);
    });
};

export const useDeleteBatch = () => {
  const invalidateBatches = useInvalidateBatches();
  return (batchId: string, weaveUserId: string, direction: Direction) => {
    return SchemaBulkMessage.DeleteBatch({ batchId, weaveUserId }).then(() => {
      invalidateBatches(direction, batchId);
    });
  };
};

export const useUpdateBatch = () => {
  const invalidateBatches = useInvalidateBatches();
  return (batch: SchemaIO<typeof SchemaBulkMessage.UpdateBatch>['input'], direction: Direction) =>
    SchemaBulkMessage.UpdateBatch(batch).then(() => {
      invalidateBatches(direction, batch?.batchId);
    });
};

const createListRecipientsRequest: (filters: MessagesTypes.Filters) => ListRecipientsRequest = (filters) => ({
  primaryInsurance: filters.primaryInsurance,
  appointmentTypes: filters.appointmentTypes,
  appointmentStatuses: filters.appointmentStatuses,
  appointmentBeginDate: filters.appointmentDate?.[0],
  appointmentEndDate: filters.appointmentDate?.[1],
  minDaysSinceLastBulkMessage: filters?.minDaysSinceLastBulkMessage
    ? MessagesTypes.MinDaysSinceLastBulkMessageMap[filters.minDaysSinceLastBulkMessage]
    : undefined,
  minAge: filters.minAge ? Number(filters.minAge) : undefined,
  maxAge: filters.maxAge ? Number(filters.maxAge) : undefined,
  sourceTenantIds: filters.sourceTenantIds,
  recipientStatuses: filters.recipientStatuses,
  appointmentPractitioners: filters.appointmentPractitioners,
  recallTypes: filters.recallTypes,
  recallBeginDate: filters.recallDate?.[0],
  recallEndDate: filters.recallDate?.[1],
  appointmentQualifier: filters.appointmentQualifier
    ? MessagesTypes.AppointmentQualifierMap[filters.appointmentQualifier]
    : undefined,
});

export const useListRecipients = (filters: MessagesTypes.Filters, pageSize: number, searchToken?: string) => {
  const request = createListRecipientsRequest(filters);
  const queryKeys = useBulkQueryKeys();
  const sanitizedSearchToken = searchToken?.replace(/[\t\r\n]+/g, ' ').trim();

  return useInfiniteQuery({
    queryKey: queryKeys.recipients(filters, sanitizedSearchToken),
    queryFn: (queryFunctionContext) =>
      SchemaBulkMetl.ListRecipients(
        {
          pageSize,
          lastPersonSortName: queryFunctionContext.pageParam?.lastPersonSortName,
          lastRecipientId: queryFunctionContext.pageParam?.lastRecipientId,
          searchToken: sanitizedSearchToken,
          ...request,
        },
        { signal: queryFunctionContext.signal }
      ),
    getNextPageParam: (lastGroup) => {
      const isPageSize = lastGroup.recipients?.length && lastGroup.recipients?.length < pageSize;
      const hasRecipient = lastGroup.recipients && lastGroup.recipients?.[lastGroup.recipients.length - 1];
      const hasMoreThanOne = lastGroup.recipients?.length && lastGroup?.recipients?.length > 1;

      if (isPageSize) return undefined;

      if (hasRecipient) {
        const lastInstance = lastGroup.recipients?.[lastGroup.recipients.length - 1];
        const nextParam = {
          lastPersonSortName: lastInstance?.personSortName,
          lastRecordKey: lastInstance?.recipientId,
        };
        return hasMoreThanOne ? nextParam : undefined;
      }
      return undefined;
    },
    select: (data) => ({
      ...data,
      pages: data.pages.map((page) => ({ rows: page.recipients })), // formatting it for the pagination list component
    }),
  });
};

type SchemaCountRecipients = SchemaIO<(typeof SchemaBulkMetl)['CountRecipients']>;
export const useCountRecipients = (
  filters: MessagesTypes.Filters = {},
  options: UseQueryOptions<SchemaCountRecipients['output']> = {}
) => {
  const request = createListRecipientsRequest(filters);
  const queryKeys = useBulkQueryKeys();

  return useQuery({
    ...defaultOptions,
    queryKey: queryKeys.countRecipients(filters),
    queryFn: validateFilters(filters)
      ? (queryFunctionContext) => SchemaBulkMetl.CountRecipients(request, { signal: queryFunctionContext.signal })
      : async () => ({ count: undefined }),
    ...options,
  });
};

export const useRecipientFilterOptions = () => {
  const queryKeys = useBulkQueryKeys();

  return useQuery({
    ...defaultOptions,
    queryKey: queryKeys.filterOptions(),
    queryFn: () => SchemaBulkMetl.RecipientFilterOptions({}),
    select: (data) =>
      data.options?.map((option) => {
        if (!option.filterOptions) {
          return option;
        }

        const filterOptions = option.filterOptions;
        const sortedFilterOptions = Object.keys(filterOptions).sort(
          (a, b) => filterOptions[a]?.localeCompare(filterOptions[b] ?? '') ?? 0
        );
        const newFilterOptions: Record<string, string> = {};
        for (const key of sortedFilterOptions) {
          const newVal = filterOptions[key];
          if (newVal) newFilterOptions[key] = newVal;
        }
        return {
          ...option,
          filterOptions: newFilterOptions,
        };
      }),
  });
};

const useInvalidateContactLists = () => {
  const queryClient = useQueryClient();
  const queryKeys = useBulkQueryKeys();

  return () => {
    queryClient.invalidateQueries(queryKeys.contactLists());
  };
};

// TODO: Have the backend sort the contact lists by createdAt, so newest is first, and handle searching. Then we can implement pagination.
type SchemaGetContactLists = SchemaIO<(typeof SchemaBulkMessage)['GetContactLists']>;
export const useGetContactLists = (options: UseQueryOptions<SchemaGetContactLists['output']> = {}) => {
  const { singleLocationId } = useAppScopeStore();
  const queryKeys = useBulkQueryKeys();
  const request: SchemaGetContactLists['input'] = {
    locationId: singleLocationId,
    limit: 50,
  };

  return useQuery({
    ...defaultOptions,
    queryKey: queryKeys.contactLists(),
    queryFn: () => SchemaBulkMessage.GetContactLists(request),
    onSuccess: (data) => {
      const sortedData = data?.contactLists?.sort(
        (a, b) => new Date(b.createdAt ?? '').getTime() - new Date(a.createdAt ?? '').getTime()
      );
      return { contactLists: sortedData };
    },
    ...options,
  });
};

type SchemaGetContactList = SchemaIO<(typeof SchemaBulkMessage)['GetContactList']>;
export const useGetContactList = (
  lists: ContactList[],
  options: UseQueryOptions<SchemaGetContactList['output']> = {}
) => {
  const { singleLocationId } = useAppScopeStore();
  const queryKeys = useBulkQueryKeys();

  return useQueries(
    lists.map((list) => ({
      ...defaultOptions,
      queryKey: queryKeys.contactList(list.id ?? ''),
      queryFn: () => SchemaBulkMessage.GetContactList({ listId: list.id ?? '', locationId: singleLocationId }),
      ...options,
    }))
  );
};

export const useGetContactsFromLists = (
  lists: ContactList[],
  options: UseQueryOptions<SchemaGetContactList['output']> = {}
) => {
  const results = useGetContactList(lists, {
    enabled: false,
    refetchOnMount: false,
    ...options,
  });

  const isLoading = results.some((result) => result.isLoading);

  const refetch = () => {
    results.forEach((result) => result.refetch());
  };

  const data = useMemo(() => {
    if (isLoading) return [];

    return results.reduce((combinedUniqueContacts, contactListResult) => {
      const listContacts = contactListResult.data?.contacts ?? [];

      const uniqueContacts = listContacts.reduce((listUniqueContacts: Contact[], contact: Contact) => {
        const inThisList = listUniqueContacts.some((otherContact) => otherContact.smsNumber === contact.smsNumber);
        const inOtherLists = combinedUniqueContacts.some((item) => item.smsNumber === contact.smsNumber);
        if (!inThisList && !inOtherLists) {
          listUniqueContacts.push(contact);
        }
        return listUniqueContacts;
      }, []);

      return [...combinedUniqueContacts, ...uniqueContacts];
    }, [] as Contact[]);
  }, [results, isLoading]);

  return { refetch, isLoading, data };
};

export const useCreateContactList = (
  options: Partial<
    UseMutationOptions<unknown, unknown, { title: string; contacts: Contact[]; weaveUserId: string }>
  > = {}
) => {
  const { singleLocationId } = useAppScopeStore();
  const invalidateContactLists = useInvalidateContactLists();

  return useMutation({
    mutationFn: (request) => SchemaBulkMessage.CreateContactList({ ...request, locationId: singleLocationId }),
    onSuccess: invalidateContactLists,
    ...options,
  });
};

// TODO: Backend is expecting a contact list, but I shouldn't have to supply it. Fix this to accept a partial update.
// TODO: Invalidate contact lists for this contact list. The old ones are being cached and sticking around after the update.
export const useUpdateContactList = (
  options: Partial<
    UseMutationOptions<unknown, unknown, { listId: string; title?: string; contacts?: Contact[]; weaveUserId: string }>
  > = {}
) => {
  const { singleLocationId } = useAppScopeStore();
  const invalidateContactLists = useInvalidateContactLists();

  return useMutation({
    mutationFn: (request) => SchemaBulkMessage.UpdateContactList({ ...request, locationId: singleLocationId }),
    onSuccess: invalidateContactLists,
    ...options,
  });
};

export const useDeleteContactList = (options: Partial<UseMutationOptions<unknown, unknown, string>> = {}) => {
  const { singleLocationId } = useAppScopeStore();
  const invalidateContactLists = useInvalidateContactLists();

  return useMutation({
    mutationFn: (listId) => SchemaBulkMessage.DeleteContactList({ listId, locationId: singleLocationId }),
    onSuccess: invalidateContactLists,
    ...options,
  });
};

type SchemaTenDLCLocationRegistrationStatus = SchemaIO<
  (typeof SchemaTenDLCCoordinator)['GetLocationRegistrationStatus']
>;
export const useTenDLCLocationRegistrationStatus = ({
  locationId,
  enabled,
}: {
  locationId: string;
  enabled: boolean;
}) => {
  const request: SchemaTenDLCLocationRegistrationStatus['input'] = {
    locationId,
  };
  const queryKeys = useBulkQueryKeys();

  return useQuery({
    ...defaultOptions,
    refetchOnMount: false,
    queryKey: queryKeys.registrationStatus(),
    queryFn: () => SchemaTenDLCCoordinator.GetLocationRegistrationStatus(request),
    enabled,
  });
};

type SchemaLocationTCRBrand = SchemaIO<(typeof SchemaTenDLCCoordinator)['GetLocationTCRBrand']>;
export const useLocationTCRBrand = (locationId: string) => {
  const request: SchemaLocationTCRBrand['input'] = {
    locationId,
  };
  const queryKeys = useBulkQueryKeys();

  return useQuery({
    ...defaultOptions,
    refetchOnMount: false,
    retry: false,
    queryKey: queryKeys.locationTCRBrand(),
    queryFn: () => SchemaTenDLCCoordinator.GetLocationTCRBrand(request),
    enabled: !!locationId,
    select: (data) => data?.tcrBrand,
  });
};

export const useCurrentQuota = (direction: Direction, year: number, month: number, localStorageQuota?: number) => {
  const queryClient = useQueryClient();
  const queryKey = useBulkQueryKeys().batches(direction, year);
  let response = 0;
  if (!localStorageQuota) {
    const currentData: GetBatchesResponse | undefined = queryClient.getQueryData(queryKey);
    const currentBatches = currentData?.batches ?? [];
    response = currentBatches.find((batch) => batch.month === month)?.usage?.quota ?? 0;
  }

  return response;
};

type SchemaDefaultPhoneRecord = SchemaIO<(typeof SchemaCommPhoneNumberService)['GetDefaultPhoneRecord']>;
export const useDefaultPhoneRecord = (request: SchemaDefaultPhoneRecord['input']) =>
  useQuery({
    queryKey: useBulkQueryKeys().defaultPhoneRecord(),
    queryFn: () => SchemaCommPhoneNumberService.GetDefaultPhoneRecord(request),
    select: (data) => data?.phoneNumber,
  });
