import { FC } from 'react';
import { FormsAutoReminder } from '@frontend/api';
import { Modal, ModalControlModalProps, PrimaryButton, Text } from '@frontend/design-system';
import { LibraryListType } from '../../library.types';
import AutoReminderWarning from './auto-reminder-warning/auto-reminder-warning.component';
import { autoRemindersListContainerStyle, buttonContainerStyle } from './auto-reminder-warning-modal.styles';

export interface AutoReminderWarningModalProps {
  modalProps: ModalControlModalProps;
  entityType: LibraryListType;
  autoReminders: FormsAutoReminder.Types.AutoReminderCheckResult;
  onClose: () => void;
}

const ReminderType = FormsAutoReminder.Types.ReminderType;

const AutoReminderWarningModal: FC<AutoReminderWarningModalProps> = ({
  modalProps,
  entityType,
  autoReminders,
  onClose,
}) => {
  return (
    <Modal {...modalProps} minWidth={600}>
      <Modal.Header>Auto reminders linked to {entityType}</Modal.Header>
      <Modal.Body>
        <Text>
          Please note that this {entityType} is currently linked to auto reminders. Deleting it may affect the
          functionality of auto reminders. If you still wish to delete the {entityType}, delete the following
          auto-reminder(s) from your Desktop Client App &amp; try again.
        </Text>

        <div css={autoRemindersListContainerStyle}>
          <AutoReminderWarning
            reminderType={ReminderType.AppointmentReminder}
            activeReminders={autoReminders.appointmentReminders}
          />

          <AutoReminderWarning
            reminderType={ReminderType.AppointmentFollowup}
            activeReminders={autoReminders.appointmentFollowup}
          />

          <AutoReminderWarning reminderType={ReminderType.BirthdayReminder} activeReminders={autoReminders.birthdays} />

          <AutoReminderWarning reminderType={ReminderType.RecallReminder} activeReminders={autoReminders.recall} />

          <AutoReminderWarning reminderType={ReminderType.ReviewReminder} activeReminders={autoReminders.reviews} />

          <AutoReminderWarning
            reminderType={ReminderType.SaveTheDateReminder}
            activeReminders={autoReminders.saveTheDates}
          />

          <AutoReminderWarning reminderType={ReminderType.MissedCall} activeReminders={autoReminders.missedCalls} />

          <AutoReminderWarning reminderType={ReminderType.MissedCall} activeReminders={autoReminders.missedCalls} />
          <AutoReminderWarning reminderType={ReminderType.Unspecified} activeReminders={autoReminders.unspecified} />
        </div>
      </Modal.Body>

      <div css={buttonContainerStyle}>
        <PrimaryButton onClick={onClose} size='tiny'>
          Okay
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default AutoReminderWarningModal;
