import { useEffect } from 'react';
import { MessagesTypes } from '@frontend/api-messaging';
import { FormFieldActionTypes, MultiselectField, useForm } from '@frontend/design-system';
import { SwitchedFilter } from './switched-filter';
import { BulkPrefixes } from '@frontend/tracking-prefixes';

interface Props {
  options: string[] | string[][];
  label: string;
  filters: MessagesTypes.Filters;
  setFilters: (filters: MessagesTypes.Filters) => void;
  filterType: keyof MessagesTypes.Filters;
}

export const MultiselectFilter = ({ label, options, filters, setFilters, filterType }: Props) => {
  const updateFilter = (values: string[] | undefined) => {
    if (values) {
      setFilters({ ...filters, [filterType]: values });
    } else {
      const copy = { ...filters };
      delete copy[filterType];
      setFilters(copy);
    }
  };

  const trackingId = `${label.toLowerCase().split(' ').join('-')}-filter-input`;

  const { getFieldProps, seedValues } = useForm({
    fields: {
      filter: {
        type: 'multiselect',
      },
      enabled: {
        type: 'switch',
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update && action.payload.name === 'filter') {
        // enable toggle when selecting at least one
        const value = state.filter.value as string[];
        if (value.length && !state.enabled.value) {
          return {
            ...state,
            enabled: {
              ...state.enabled,
              value: true,
            },
          };
        }

        // disable toggle when unselecting
        if (!value.length && state.enabled.value) {
          return {
            ...state,
            enabled: {
              ...state.enabled,
              value: false,
            },
          };
        }
      }

      if (action.type === FormFieldActionTypes.Update && action.payload.name === 'enabled') {
        // removing selections when toggle is turned off
        if (!state.enabled.value) {
          return {
            ...state,
            enabled: {
              ...state.enabled,
              value: false,
            },
            filter: {
              ...state.filter,
              value: [],
            },
          };
        }
      }
      return state;
    },
  });

  useEffect(() => {
    seedValues({
      filter: (filters[filterType] ?? []) as string[],
      enabled: !!filters[filterType],
    });
  }, []);

  useEffect(() => {
    const value = getFieldProps('filter').value;
    const filterValue = value.length ? value : undefined;
    updateFilter(filterValue);
  }, [getFieldProps('filter').value]);

  useEffect(() => {
    if (!getFieldProps('enabled').value) {
      updateFilter(undefined);
    }
  }, [getFieldProps('enabled').value]);

  return (
    <SwitchedFilter getFieldProps={getFieldProps}>
      <MultiselectField
        {...getFieldProps('filter')}
        label={label}
        data-testid={trackingId}
        data-trackingid={`${BulkPrefixes.Recipients}-${trackingId}`}
      >
        {options?.map((option, index) => {
          if (Array.isArray(option)) {
            return (
              <MultiselectField.Option key={index} value={option[0]}>
                {option[1]}
              </MultiselectField.Option>
            );
          }
          return (
            <MultiselectField.Option key={index} value={option}>
              {option}
            </MultiselectField.Option>
          );
        })}
      </MultiselectField>
    </SwitchedFilter>
  );
};
