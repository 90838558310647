import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Ribbon } from '@frontend/ribbon';
import { useSoftphoneManager } from '@frontend/weave-softphone-manager';
import { theme } from '@frontend/theme';
import { TextLink, WarningBadgeIcon } from '@frontend/design-system';

export const SoftphoneInAnotherTabRibbon = () => {
  const { t } = useTranslation('softphone');
  const useThisTab = useSoftphoneManager((ctx) => ctx.useThisTab);
  const hasActiveCalls = useSoftphoneManager((ctx) => ctx.hasActiveCalls);
  const deferredToOtherTab = useSoftphoneManager((ctx) => ctx.deferredToOtherTab);
  const actionButton =
    hasActiveCalls && deferredToOtherTab ? (
      <></>
    ) : (
      <TextLink
        weight='bold'
        css={css`
          width: auto;
        `}
        onClick={useThisTab}
      >
        {t('Use Softphone in This Tab')}
      </TextLink>
    );

  const message =
    hasActiveCalls && deferredToOtherTab
      ? t(
          'It looks like you have Weave open on multiple tabs while on an active call. Please return to the tab with your active call and close all other tabs to prevent losing your active call or missing incoming calls.'
        )
      : t(
          'It looks like you have Weave open on multiple tabs while you’re connected to a Softphone. Your Softphone can only be used in one tab. You can return to the original tab or use the Softphone in this tab.'
        );

  return (
    <Ribbon
      type='warning'
      icon={
        <WarningBadgeIcon
          color='warn'
          css={css`
            border-radius: 100px;
            background: radial-gradient(${theme.colors.neutral90}, rgba(0, 0, 0, 0));
          `}
        />
      }
      header={t('Multiple Tabs Detected')}
      message={message}
      isCloseable={true}
      action={actionButton}
      trackingId='softphone-alerts-tabmanager'
    ></Ribbon>
  );
};
