import { memo } from 'react';
import { css } from '@emotion/react';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, ChipVariants, NakedButton, Text } from '@frontend/design-system';
import { formatters } from '../../utils';

export interface UnscheduledOpportunities {
  cancelledPatients: PracticeAnalyticsTypes.PatientInfo[];
  hygieneFollowUp: PracticeAnalyticsTypes.PatientInfo[];
  hygieneTreatment: PracticeAnalyticsTypes.PatientInfo[];
  restorativeTreatment: PracticeAnalyticsTypes.PatientInfo[];
}

interface OpportunityCardProps {
  iconConfig: {
    bgColor: string;
    borderColor: string;
    name?: IconName;
    variant: ChipVariants;
  };
  onClick: () => void;
  subtitle: string;
  title: string;
  value: string;

  isSelected?: boolean;
}

interface OpportunityCardsProps {
  onChange: (value: keyof UnscheduledOpportunities) => void;
  unscheduledOpportunities: UnscheduledOpportunities;

  selectedOpportunities?: (keyof UnscheduledOpportunities)[] | null;
}

const OpportunityCard = memo(({ iconConfig, isSelected, onClick, subtitle, title, value }: OpportunityCardProps) => {
  return (
    <NakedButton className={isSelected ? 'selected' : ''} css={styles.opportunityCard} onClick={onClick}>
      <div>
        <Text color='subdued' style={{ fontSize: theme.font.size.h3 }} weight='bold'>
          {title}
        </Text>
        <Text weight='bold' style={{ fontSize: theme.fontSize(20) }}>
          {value}
        </Text>
        <Text size='small'>{subtitle}</Text>
      </div>
      <Chip
        variant={iconConfig.variant}
        style={{
          backgroundColor: iconConfig.bgColor,
          height: theme.spacing(5),
          borderRadius: theme.borderRadius.medium,
          border: `1px solid ${iconConfig.borderColor}`,
        }}
      >
        <Icon name={iconConfig.name ?? 'user'} />
      </Chip>
    </NakedButton>
  );
});

export const OpportunityCards = memo(
  ({ onChange, selectedOpportunities, unscheduledOpportunities }: OpportunityCardsProps) => {
    const { t } = useTranslation('analytics');

    return (
      <div css={styles.mainWrapper}>
        <OpportunityCard
          iconConfig={{
            bgColor: theme.colors.secondary.seaweed5,
            borderColor: theme.colors.secondary.seaweed50,
            variant: 'seaweed',
          }}
          isSelected={selectedOpportunities?.includes('hygieneTreatment')}
          onClick={() => onChange('hygieneTreatment')}
          subtitle={t('Unscheduled')}
          title={t('Hygiene Treatment')}
          value={formatters.value.format(unscheduledOpportunities.hygieneTreatment.length)}
        />
        <OpportunityCard
          iconConfig={{
            bgColor: theme.colors.secondary.eggplant5,
            borderColor: theme.colors.secondary.eggplant50,
            variant: 'eggplant',
          }}
          isSelected={selectedOpportunities?.includes('restorativeTreatment')}
          onClick={() => onChange('restorativeTreatment')}
          subtitle={t('Unscheduled')}
          title={t('Restorative Treatment')}
          value={formatters.value.format(unscheduledOpportunities.restorativeTreatment.length)}
        />
        <OpportunityCard
          iconConfig={{
            bgColor: theme.colors.primary5,
            borderColor: theme.colors.primary50,
            variant: 'primary',
          }}
          isSelected={selectedOpportunities?.includes('hygieneFollowUp')}
          onClick={() => onChange('hygieneFollowUp')}
          subtitle={t('Unscheduled')}
          title={t('Hygiene Follow-Up')}
          value={formatters.value.format(unscheduledOpportunities.hygieneFollowUp.length)}
        />
        <OpportunityCard
          iconConfig={{
            bgColor: theme.colors.critical5,
            borderColor: theme.colors.critical50,
            name: 'phone-missed',
            variant: 'critical',
          }}
          isSelected={selectedOpportunities?.includes('cancelledPatients')}
          onClick={() => onChange('cancelledPatients')}
          subtitle={t('Cancelled')}
          title={t('Cancelled Patients')}
          value={formatters.value.format(unscheduledOpportunities.cancelledPatients.length)}
        />
      </div>
    );
  }
);

OpportunityCard.displayName = 'OpportunityCard';
OpportunityCards.displayName = 'OpportunityCards';

const styles = {
  mainWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
    margin: ${theme.spacing(3, 0)};
  `,

  opportunityCard: css`
    align-items: center;
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: space-between;
    min-width: 280px;
    overflow: hidden;
    padding: ${theme.spacing(3)};
    position: relative;
    text-align: start;

    &:hover {
      background-color: ${theme.colors.neutral5};
    }

    &.selected {
      background-color: ${theme.colors.primary5};
      border-color: ${theme.colors.primary50};
    }
  `,
};
