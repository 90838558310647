import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { useMatchMedia, breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Heading, NakedButton, Text } from '@frontend/design-system';
import { useGetScheduleActionsModal } from '../../../../hooks';
import { useAppointmentsInfoShallowStore } from '../../../../hooks/use-appointments-info-store';
import { useAppointmentFilterDefaultDataShallowStore } from '../../../../stores/use-appointments-filter-store';
import { useFilteredAppointmentListInfoShallowStore } from '../../../../stores/use-filtered-appointment-list-store';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../../../stores/use-schedule-actions-appointment-list-store';
import { HeaderBarTrackingIds } from './trackingIds';

export const SummaryBoxRow = () => {
  const { t } = useTranslation('schedule');
  const isMedium = useMatchMedia({ maxWidth: breakpoints.medium.max });

  const { appointments: unfilteredAppointments } = useAppointmentsInfoShallowStore('appointments');

  const { filteredAppointmentList } = useFilteredAppointmentListInfoShallowStore('filteredAppointmentList');

  const { hasActiveFilters } = useAppointmentFilterDefaultDataShallowStore('hasActiveFilters');

  const appointments = hasActiveFilters ? filteredAppointmentList : unfilteredAppointments;

  const { triggerProps, openModal, Modal } = useGetScheduleActionsModal();

  const { setStatusFilter } = useScheduleActionsAppointmentListInfoShallowStore('setStatusFilter');

  const getUnconfirmedAppointmentsCount = () => {
    return (
      (appointments &&
        appointments.filter((appointment) => {
          return appointment.statusOfficeView?.toLowerCase() === 'unconfirmed';
        }).length) ||
      0
    );
  };

  const openAppointmentList = (filter?: string) => {
    openModal();
    setStatusFilter && setStatusFilter(filter || '');
  };

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: 'fit-content',
        height: theme.spacing(8),
      }}
    >
      <SummaryBox
        title={isMedium ? t('Appts') : t('Appointments')}
        iconName='calendar'
        count={appointments?.length || 0}
        handleClick={openAppointmentList}
        trackingId={HeaderBarTrackingIds.allAppointmentsCountButton}
        {...triggerProps}
      />

      <SummaryBox
        title={t('Unconfirmed')}
        iconName='pending'
        count={getUnconfirmedAppointmentsCount()}
        handleClick={() => openAppointmentList('unconfirmed')}
        trackingId={HeaderBarTrackingIds.unconfirmedAppointmentsCount}
      />

      {/* //TODO - We can uncomment the following when we have the appropriate APIs to fetch insurance statuses */}
      {/* <SummaryBox
        title={t('Insurance Alerts')}
        iconName='insurance-error-small'
        count={999}
        trackingId={HeaderBarTrackingIds.insuranceAlertsCount}
      /> */}

      {Modal}
    </div>
  );
};

type SummaryBoxProps = {
  title: string;
  iconName: IconName;
  count: number;
  handleClick?: () => void;
  trackingId?: string;
};

export const SummaryBox = ({ title, iconName, count, handleClick, trackingId }: SummaryBoxProps) => {
  const isMedium = useMatchMedia({ maxWidth: breakpoints.medium.max });

  return (
    <>
      {handleClick ? (
        <NakedButton
          onClick={() => handleClick()}
          trackingId={trackingId ?? ''}
          css={{
            width: 'fit-content',
            backgroundColor: theme.colors.white,
            padding: theme.spacing(1, 2),
            marginRight: isMedium ? theme.spacing(1) : theme.spacing(3),
            border: `1px solid ${theme.colors.neutral20}`,
            borderRadius: theme.borderRadius.small,
            boxShadow: theme.shadows.light,
            position: 'relative',
            transition: 'border 0.3s ease-in-out',
            '::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: theme.spacing(8),
              borderRadius: theme.borderRadius.small,
              boxShadow: theme.shadows.floating,
              opacity: 0,
              transition: 'opacity 0.3s ease-in-out',
            },
            ':hover::after': {
              opacity: 1,
            },
            ':active': {
              border: `1px solid ${theme.colors.primary50}`,
            },
          }}
        >
          <Text size='small' color='subdued' textAlign='left'>
            {title}
          </Text>
          <div css={{ display: 'flex', alignItems: 'center', height: theme.spacing(3) }}>
            <Icon
              name={iconName}
              color='subdued'
              size={isMedium ? 16 : 24}
              css={{ marginRight: theme.spacing(1), minWidth: theme.spacing(2) }}
            />
            <Heading level={2}>{count}</Heading>
          </div>
        </NakedButton>
      ) : (
        <div
          css={{
            width: 'fit-content',
            backgroundColor: theme.colors.white,
            padding: theme.spacing(1, 2),
            marginRight: isMedium ? theme.spacing(1) : theme.spacing(3),
            border: `1px solid ${theme.colors.neutral20}`,
            borderRadius: theme.borderRadius.small,
            boxShadow: theme.shadows.light,
            position: 'relative',
            transition: 'border 0.3s ease-in-out',
          }}
        >
          <Text size='small' color='subdued'>
            {title}
          </Text>
          <div css={{ display: 'flex', alignItems: 'center', width: '100%', height: '24px' }}>
            <Icon name={iconName} color='subdued' size={isMedium ? 16 : 24} css={{ marginRight: theme.spacing(1) }} />
            <Heading level={2}>{count}</Heading>
          </div>
        </div>
      )}
    </>
  );
};
