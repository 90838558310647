import { memo, useCallback, useMemo } from 'react';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { CallIntelSubViewId, useCallIntelStore } from '../../../hooks';
import { callIntelligenceUtils, formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { OpportunityLegendContent } from '../opportunity-legend-content';

type Props = {
  data?: CallIntelligenceTypes.Overview | null;
  isDemoAccount?: boolean;
  isLoading?: boolean;
  sentimentCode?: CallIntelSubViewId;
};

export const OverviewSummaryOpportunitiesStack = memo(({ data, isDemoAccount, isLoading, sentimentCode }: Props) => {
  const { t } = useTranslation('analytics');
  const { dataLabels, filterHintText, subView } = useCallIntelStore();

  const chartTitle = useMemo(() => {
    return t('{{name}} Opportunities', {
      name:
        subView.type === 'sentiment'
          ? dataLabels.sentiments?.[subView.id || '']
          : subView.type === 'appointment-type'
          ? dataLabels.appointmentTypes?.[subView.id || '']
          : dataLabels.categories?.[subView.id || ''],
    });
  }, [subView]);

  const chartData = useMemo(() => {
    const scheduled = data?.summary.scheduledOpportunities || 0;
    const unscheduled = (data?.summary.totalOpportunities || 0) - (data?.summary.scheduledOpportunities || 0);
    const noOpportunity = data
      ? callIntelligenceUtils.getCallsCount(data, sentimentCode) - (data?.summary.totalOpportunities || 0)
      : 0;

    return {
      data: {
        groups: [
          {
            name: 'opportunities',
            values: {
              scheduled,
              unscheduled,
              noOpportunity,
            },
          },
        ],
      },
      maxValue: scheduled + unscheduled + noOpportunity,
      noOpportunity,
      scheduled,
      unscheduled,
    };
  }, [data]);

  const legendContentProps = useCallback((legendId?: string | null) => {
    const iconProps = {
      iconName: 'check-small',
      label: t('Scheduled'),
      variant: 'success',
    };

    if (legendId === 'unscheduled') {
      iconProps.iconName = 'x-small';
      iconProps.label = t('Unscheduled');
      iconProps.variant = 'warn';
    } else if (legendId === 'noOpportunity') {
      iconProps.iconName = 'feedback-bad-small';
      iconProps.label = t('No Opportunity');
      iconProps.variant = 'neutral';
    }

    return iconProps as {
      iconName: IconName;
      label: string;
      variant: 'success' | 'warn' | 'neutral';
    };
  }, []);

  return (
    <Chart
      colors={{
        noOpportunity: theme.colors.neutral50,
        scheduled: theme.colors.success40,
        unscheduled: theme.colors.warning50,
      }}
      commonTooltipLabel={t('Calls')}
      isLoading={isLoading}
      labels={{
        noOpportunity: t('No Opportunity'),
        scheduled: t('Scheduled'),
        unscheduled: t('Unscheduled'),
      }}
    >
      <Chart.Header leftElement={isDemoAccount ? <DemoChip /> : null} subtitle={filterHintText} title={chartTitle} />
      <Chart.BarChart
        appearance={{
          barSize: 40,
          customTooltipTitle: ({ hoveredSegment }) => {
            const iconProps = legendContentProps(hoveredSegment);
            return (
              <OpportunityLegendContent
                iconName={iconProps.iconName}
                label={iconProps.label}
                variant={iconProps.variant}
              />
            );
          },
          groupsGap: 0,
          layout: 'vertical',
          maxValue: chartData.maxValue,
          mode: 'stacked',
        }}
        data={chartData.data}
        formatValue={formatters.value.format}
      />
      {!!chartData.maxValue && (
        <Chart.Legends
          customData={{
            scheduled: {
              label: <OpportunityLegendContent iconName='check-small' label={t('Scheduled')} variant='success' />,
              value: formatters.value.format(chartData.scheduled),
            },
            unscheduled: {
              label: <OpportunityLegendContent iconName='x-small' label={t('Unscheduled')} variant='warn' />,
              value: formatters.value.format(chartData.unscheduled),
            },
            noOpportunity: {
              label: (
                <OpportunityLegendContent iconName='feedback-bad-small' label={t('No Opportunity')} variant='neutral' />
              ),
              value: formatters.value.format(chartData.noOpportunity),
            },
          }}
          style={{ marginTop: theme.spacing(2) }}
        />
      )}
    </Chart>
  );
});

OverviewSummaryOpportunitiesStack.displayName = 'OverviewSummaryOpportunitiesStack';
