import { useTranslation } from '@frontend/i18n';
import { useSoftphoneSettings } from '../../providers/softphone-settings-provider';
import { Text } from '@frontend/design-system';
import { DeviceInformationWrapper } from '../../components/device-information-wrap';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { DnDToggle } from '../../components/settings/do-not-disturb';
import { E911Selector } from '../../components/settings/e911-selector';
import { AudioSettings } from '../../components/settings/audio-settings';
import { SettingsFooter } from '../../components/settings/settings-footer';
import { useSoftphoneCallState } from '../../providers/softphone-call-state-provider';
import { useSoftphoneWidgetControl } from '../../providers/widget-control-provider';
import { CallWaitingIndicatorToggle } from '../../components/settings/call-waiting-indicator';
import { Page, backgroundColor } from '@frontend/generic-dialpad-accessories';
import { PageHeaderWraps } from '../../components/generic/page-header-wraps';

export const SettingsOverlay = () => {
  const { t } = useTranslation('softphone');
  const hideSettings = useSoftphoneSettings((ctx) => ctx.hideSettings);
  const primaryCall = useSoftphoneCallState((ctx) => ctx?.primaryCall);
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);

  return (
    <Page css={settingsOverlayStyles}>
      <PageHeaderWraps
        onExit={() => hideSettings()}
        onBack={() => hideSettings()}
        hasBackButton={!primaryCall || isMobile}
        inlineWithIcons={false}
        hasUnderline
      >
        <Text size='medium' color='white'>
          {t('Softphone Settings')}
        </Text>
        <DeviceInformationWrapper />
      </PageHeaderWraps>
      <Page.Body>
        <ul
          css={css`
            padding: ${theme.spacing(1)} 0;
            list-style: none;
            li {
              margin-bottom: ${theme.spacing(2)};
            }
            .settings-li-title {
              margin-bottom: ${theme.spacing(2)};
            }
            .settings-li-helper-text {
              margin-top: ${theme.spacing(1)};
              color: ${theme.colors.neutral30};
            }
          `}
        >
          <DnDToggle />
          <CallWaitingIndicatorToggle />
          <E911Selector />
          <AudioSettings />
          <SettingsFooter />
        </ul>
      </Page.Body>
    </Page>
  );
};

const settingsOverlayStyles = css`
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background: ${backgroundColor};
  justify-content: start;
`;
