import { MessageAction_index } from '@weave/schema-gen-ts/dist/schemas/websocket-director/v1/websocket.pb';
import { WebsocketEventPayload } from '../events';

export type HeartbeatState = {
  connectAttemptStartedAt: number | null;
  lastHeartbeatAt: number | null;
  lastPingSentAt: number | null;
  reconnectWaitInterval: number;
  inReconnectLoop: boolean;
  refreshTokenStartedAt: number | null;
};

export enum WebsocketReadyState {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
}

export type GetWebsocketProps = {
  url: string;
  onMessage: (payload: WebsocketEventPayload) => void;
  heartbeatRef: HeartbeatState;
};

export type ServerMessageActionIndex = {
  action: MessageAction_index;
  payload?: string;
};

export enum WebsocketState {
  WAITING_FOR_URL,
  CONNECTING,
  HEALTHY_CONNECTION,
  NEED_TO_SEND_PING,
  WAITING_FOR_PONG,
  NEED_TO_REFRESH_TOKEN,
  NEED_TO_RECONNECT,
  RECONNECTING,
}

export type ConnectProps = {
  url: string;
  onMessage: (payload: WebsocketEventPayload) => void;
  heartbeatState: HeartbeatState;
  selectedLocationIds: string[];
  reevaluateCurrentState: () => void;
};
