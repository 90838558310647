import { EntityType } from '@weave/schema-gen-ts/dist/schemas/tendlc/shared/v1/shared.pb';
import { BulkMessagesQueries, MessagesFeatureFlags, MessagesUtils } from '@frontend/api-messaging';
import { useAppScopeStore } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';

export const useHasBulkTexting = (): boolean => {
  const isBulkTextingFeatureFlagEnabled = useHasFeatureFlag(MessagesFeatureFlags.bulkMessages);

  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const locationData = getSelectedLocationData()[locationId];
  const isParentLocation = !!locationData?.children?.length;

  const { data: tcrBrandData } = BulkMessagesQueries.useLocationTCRBrand(locationId);
  const isSupportedCountry = MessagesUtils.isBulkTextingSupportedInCountry(tcrBrandData?.country);
  const isProduction = localStorage.getItem('backendEnv') === 'prod';
  const isSoleProprietor = tcrBrandData?.entityType === EntityType.ENTITY_TYPE_SOLE_PROPRIETOR;

  const hasBulkTexting =
    isBulkTextingFeatureFlagEnabled &&
    !isParentLocation &&
    isSupportedCountry &&
    (!isSoleProprietor || (isSoleProprietor && !isProduction));

  return hasBulkTexting;
};
