import { SerializedStyles, css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { emptyStateGraphics, Text } from '@frontend/design-system';
import { EmptyStateConfig } from '../types';

type EmptyDataProps = {
  emptyDataStyles?: SerializedStyles;
  emptyStateConfig?: EmptyStateConfig;
};

export const EmptyData = ({ emptyDataStyles, emptyStateConfig }: EmptyDataProps) => {
  const EmptyStateGraphic = emptyStateConfig?.type
    ? emptyStateGraphics[emptyStateConfig?.type]
    : emptyStateGraphics['generic'];

  return (
    <div className='no-data' css={[styles.wrapper, emptyDataStyles]}>
      <EmptyStateGraphic css={styles.graphic} />
      {emptyStateConfig?.label && (
        <Text color='subdued' textAlign='center'>
          {emptyStateConfig?.label}
        </Text>
      )}
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(0.5)};
    position: absolute;
    width: 100%;
  `,

  graphic: css`
    width: 240px;
    max-width: 100%;
  `,
};
