import { ScheduleRequestCountResponse } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { useQuery } from 'react-query';
import { ScheduleApi, ScheduleQueries } from '@frontend/api-schedule';
import { useAppScopeStore } from '@frontend/scope';
import { useFeatureFlagShallowStore } from '@frontend/shared';

type GetScheduleRequestAndAlertsCountType = {
  scheduleAlertsCount: number;
  isScheduleAlertsLoading: boolean;
  isAppointmentRequestCountLoading: boolean;
  scheduleRequestCount: ScheduleRequestCountResponse | undefined;
  refetchAlertCount: () => void;
  refetchAppointmentRequestCount: () => void;
};

export const useGetScheduleRequestAndAlertsCount = (): GetScheduleRequestAndAlertsCountType => {
  const { selectedLocationIds } = useAppScopeStore();
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const scheduleAlertsEnabled = flagValues['schedule-alerts-2.0'];
  const {
    data: scheduleAlertsCount,
    isLoading: isScheduleAlertsLoading,
    refetch: refetchAlertCount,
  } = useQuery({
    queryKey: ['scheduleAlertsCount', selectedLocationIds],
    queryFn: async () => await ScheduleApi.getScheduleAlertsCount(selectedLocationIds),
    enabled: scheduleAlertsEnabled,
  });

  const {
    data: scheduleRequestCount,
    isLoading: isAppointmentRequestCountLoading,
    refetch: refetchAppointmentRequestCount,
  } = ScheduleQueries.useGetScheduleRequestCount({
    locationIds: selectedLocationIds,
    statuses: 'Pending',
  });

  return {
    scheduleAlertsCount: scheduleAlertsCount || 0,
    isScheduleAlertsLoading,
    refetchAlertCount,
    scheduleRequestCount,
    isAppointmentRequestCountLoading,
    refetchAppointmentRequestCount,
  };
};
