import { useRef, useEffect } from 'react';
import { useMatch } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import { FormsMediaManager, FormsQueryKeys } from '@frontend/api';
import { saveImage, removeDownloadLink } from '../../../shared/helpers';
import { useScopeLocation } from '../../../shared/hooks';

export const useFormAttachment = (attachmentId: string | undefined, autoFetch = true) => {
  const downloadLinkRef = useRef<HTMLAnchorElement>();
  const { params } = useMatch();
  const routeSubmissionId = params.submissionid;
  const { locationId } = useScopeLocation();
  const { data, refetch, ...otherUseQueryProps } = useQuery(
    [FormsQueryKeys.formAttachment, attachmentId],
    () =>
      attachmentId
        ? FormsMediaManager.API.fetchMedia(attachmentId, locationId, routeSubmissionId)
        : Promise.resolve(undefined),
    {
      refetchOnMount: true,
      enabled: autoFetch,
    }
  );

  useEffect(() => {
    return () => {
      cleanUpDownloadLink();
    };
  }, []);

  async function downloadImageAs(fileName: string) {
    let base64Data = data?.data;

    if (!data) {
      const response = await refetch();
      base64Data = response.data?.data;
    }

    if (!base64Data) {
      return;
    }

    cleanUpDownloadLink();
    downloadLinkRef.current = saveImage({
      base64Data,
      fileName,
    });
  }

  function cleanUpDownloadLink() {
    if (downloadLinkRef.current) {
      removeDownloadLink(downloadLinkRef.current);
      downloadLinkRef.current = undefined;
    }
  }

  return {
    data,
    downloadImageAs,
    ...otherUseQueryProps,
  };
};
