import { useTranslation } from '@frontend/i18n';
import { css } from '@emotion/react';
import { Text } from '@frontend/design-system';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { useSoftphoneRouter } from '../providers/softphone-router-provider';
import { CallInformation } from '../components/call-information';
import { Button, Page } from '@frontend/generic-dialpad-accessories';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { isEstablishedCall, isOutgoingCall } from '../types';
import { theme } from '@frontend/theme';
import { CallDuration } from '../hooks/use-call-duration';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';

export const PageMergedHomeMobile = () => {
  const { t } = useTranslation('softphone');
  const mergedCallGroup = useSoftphoneCallState((ctx) => ctx.mergedCallGroup);
  const isMuted = useSoftphoneCallState((ctx) => ctx.isMuted);
  const toggleMute = useSoftphoneCallActions((ctx) => ctx.toggleMute);
  const endCall = useSoftphoneCallActions((ctx) => ctx.endCall);
  const route = useSoftphoneRouter((ctx) => ctx.setGroupCallRoute);
  const toggleHoldMergedCalls = useSoftphoneCallActions((ctx) => ctx.toggleHoldMergedCalls);
  const callOnHold = mergedCallGroup?.some((call) => !!call.onHoldSince);
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);

  const firstMergedCall = mergedCallGroup?.reduce((first, call) => {
    if (!first) {
      return call;
    }
    return isEstablishedCall(call) && isEstablishedCall(first) && call.establishedAt < first.establishedAt
      ? call
      : first;
  }, mergedCallGroup[0]);
  const callWithTimer = firstMergedCall ? firstMergedCall : primaryCall;
  const startTime = callWithTimer
    ? isOutgoingCall(callWithTimer)
      ? callWithTimer.placedAt
      : isEstablishedCall(callWithTimer)
      ? callWithTimer?.establishedAt
      : undefined
    : undefined;

  const endMergedCall = async () => {
    if (mergedCallGroup?.length) {
      const calls = mergedCallGroup.filter(isEstablishedCall);
      for (const call of calls) {
        await endCall(call);
      }
    }
  };

  const holdMergeCals = () => {
    if (mergedCallGroup?.length) {
      const isHolding = mergedCallGroup?.some((call) => !!call.onHoldSince);
      toggleHoldMergedCalls(!isHolding);
    }
  };

  return (
    <Page>
      <PageHeaderWraps hasBackButton={false}></PageHeaderWraps>
      <Page.Body>
        {mergedCallGroup?.map((call) => {
          return <CallInformation textAlign='center' {...call.remoteParty} hideSubtitle hideCallDuration />;
        })}
        <Text color='white' textAlign='center'>
          <CallDuration connectedAt={startTime} />
        </Text>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: ${theme.spacing(2)} 0;
          `}
        >
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: ${theme.spacing(2)} ${theme.spacing(3)};
              justify-content: center;

              div:last-child {
                margin: auto;
                grid-column: span 2; /* Span two columns (centered) */
              }
            `}
          >
            <Button.Round
              trackingId='softphone-multicall-managecall'
              onClick={() => route('calls-list')}
              iconName={'user'}
              label={t('Manage')}
            />
            <Button.Round onClick={() => route('keypad')} iconName={'dial'} label={t('Keypad')} />
            <Button.Round
              onClick={(e) => {
                e.stopPropagation();
                toggleMute(!isMuted);
              }}
              selected={isMuted}
              iconName={isMuted ? 'mute' : 'microphone'}
              label={t('Mute')}
            ></Button.Round>
            <Button.Round
              onClick={(e) => {
                e.stopPropagation();
                holdMergeCals();
              }}
              selected={!!callOnHold}
              iconName={'pause'}
              label={t('Hold')}
            />
            <Button.Round
              onClick={(e) => {
                e.stopPropagation();
                endMergedCall();
              }}
              color='red'
              iconName={'phone-hangup'}
            ></Button.Round>
          </div>
        </div>
      </Page.Body>
    </Page>
  );
};
