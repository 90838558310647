import { FreeTrialSubscriptionQueries, FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { checkAggregateACL } from '@frontend/auth-helpers';
import { useModalControl } from '@frontend/design-system';
import { useAppScopeStore } from '@frontend/scope';
import {
  SelfUpgradeModal,
  useFeatureSelfSubscribeModal,
  useFreeTrialModal,
  type SelfSubscribeEligibilityData,
} from '@frontend/self-upgrade';
import { theme } from '@frontend/theme';
import { ComponentProps } from '@frontend/types';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { SignUpPageFreeTrialSubscriptionCard } from '../sign-up-landing-page-subscription-cards';
import { FeatureLocationSubscriptionTable } from './FeatureLocationSubscriptionTable';
import { FeatureSignUpAlert } from './FeatureSignUpAlert';
import { FeatureSignUpCard } from './FeatureSignUpCard';
import { FeatureSignUpDetailCard } from './FeatureSignUpDetailCard';
import { FeatureSignUpContentType } from './types';

interface FeatureSignUpPageProps extends Pick<FeatureSignUpContentType, 'links' | 'permissions' | 'subscriptionData'> {
  featureEnum: Feature;
  featureName: string;
  onFreeTrialSuccess?: () => void;
  onUpgradeSuccess?: () => void;
  renderFeatureContent: (props: { isFeatureActive: boolean }) => ReactNode;
  renderAlert: (props: {
    canManageAddons: boolean;
    isFeatureActive: boolean;
    isFreeTrialActive: boolean;
    isNonDentalError: boolean;
  }) => ReactNode;
  renderLocationTable: (props: {
    selfUpgradeEligibilityData: FreeTrialSubscriptionTypes.IsMultiEligibleForSelfUpgradeOrTrialDetail[];
    isLoading: boolean;
    disableAction: boolean;
    handleTrialAction: (locationIds: string[]) => void;
    handleUpgradeAction: (locationIds: string[]) => void;
    handleScheduleCallAction: () => void;
  }) => ReactNode;
  renderFreeTrialCard: (props: {
    isLoading: boolean;
    disableAction: boolean;
    handleTrialAction: (locationIds?: string[]) => void;
  }) => ReactNode;
  renderSubscriptionCard: (props: {
    isFeatureActive: boolean;
    isLoading: boolean;
    isMulti: boolean;
    canSelfSubscribe: boolean;
    disableAction: boolean;
    handleUpgradeAction: (locationIds?: string[]) => void;
    handleScheduleCallAction: () => void;
  }) => ReactNode;
}

interface FeatureSignUpPageComponentProps extends FC<FeatureSignUpPageProps> {
  Content: FC<ComponentProps<typeof FeatureSignUpDetailCard>>;
  Alert: FC<ComponentProps<typeof FeatureSignUpAlert>>;
  LocationTable: FC<ComponentProps<typeof FeatureLocationSubscriptionTable>>;
  TrialCard: FC<ComponentProps<typeof SignUpPageFreeTrialSubscriptionCard>>;
  SubscriptionCard: FC<ComponentProps<typeof FeatureSignUpCard>>;
}

export const FeatureSignUpPage: FeatureSignUpPageComponentProps = ({
  permissions,
  links,
  subscriptionData,
  featureEnum,
  featureName,
  onFreeTrialSuccess,
  onUpgradeSuccess,
  renderAlert,
  renderFeatureContent,
  renderLocationTable,
  renderFreeTrialCard,
  renderSubscriptionCard,
}) => {
  const { selfSubscribeAllowed = true, trialAllowed = true, allowedForDentalOnly = false } = permissions;
  const { homePageLink, onboardingCallLink, scheduleCallLink, settingsPageLink } = links;
  const { price = 0, subscriptionTerm = 'monthly' } = subscriptionData;
  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const { aclValuesByLocationId: locationWiseManageAddonAccess, aggregateValue: canManageAddons } = useMemo(() => {
    return checkAggregateACL(selectedLocationIds, Permission.MANAGE_ADD_ON_SUBSCRIPTIONS, 'any');
  }, [selectedLocationIds]);

  const {
    data: selfUpgradeEligibilityReponse,
    isRefetching: isRefetchingSelfUpgradeEligibilityData,
    isLoading: isLoadingSelfUpgradeEligibilityData,
  } = FreeTrialSubscriptionQueries.useSelfUpgradeOrFreeTrialEligibility({
    locationIds: selectedLocationIds,
    featureEnum,
  });

  const [freeTrialEligibilityData, setFreeTrialEligibilityData] = useState<SelfSubscribeEligibilityData[]>([]);
  const [selfSubscribeEligibilityData, setSelfSubscribeEligibilityData] = useState<SelfSubscribeEligibilityData[]>([]);

  const { freeTrialModalControls, FreeTrialModal } = useFreeTrialModal();
  const { FeatureSelfSubscribeModal } = useFeatureSelfSubscribeModal({ featureEnum });
  const selfSubscribeModalControls = useModalControl();
  const queryClient = useQueryClient();

  const { SUBSCRIBE, SCHEDULE_CALL } = FreeTrialSubscriptionTypes.SubscriptionStatusForSelfUpgrade;
  const { TRIAL_ACTIVE, TRIAL_ELIGIBLE } = FreeTrialSubscriptionTypes.TrialStatus;

  const selfUpgradeEligibilityData = useMemo<SelfSubscribeEligibilityData[]>(() => {
    return (selfUpgradeEligibilityReponse?.eligibility || []).map((rowData) => ({
      ...rowData,
      // Change subscribe to schedule call if self subscribe is not allowed
      subscriptionStatus:
        rowData.subscriptionStatus === SUBSCRIBE && !selfSubscribeAllowed ? SCHEDULE_CALL : rowData.subscriptionStatus,
      canManageAddons: locationWiseManageAddonAccess[rowData.locationId],
    }));
  }, [selfUpgradeEligibilityReponse?.eligibility, locationWiseManageAddonAccess, selfSubscribeAllowed]);

  const isLoading = isLoadingSelfUpgradeEligibilityData || isRefetchingSelfUpgradeEligibilityData;

  const {
    canSelfSubscribe,
    isUpgradeEligible,
    isFeatureActive,
    isFreeTrialActive,
    isFreeTrialEligible,
    isNonDentalError,
  } = useMemo(
    () => ({
      canSelfSubscribe: selfUpgradeEligibilityData.some(({ subscriptionStatus }) => {
        return selfSubscribeAllowed && subscriptionStatus === SUBSCRIBE;
      }),
      isUpgradeEligible: selfUpgradeEligibilityData.some(({ subscriptionStatus, isStateActive }) => {
        return [SCHEDULE_CALL, SUBSCRIBE].includes(subscriptionStatus) && !isStateActive;
      }),
      isFreeTrialEligible:
        trialAllowed &&
        !!selfUpgradeEligibilityData.length &&
        selfUpgradeEligibilityData.some(
          ({ trialStatus, isStateActive }) => !isStateActive && trialStatus === TRIAL_ELIGIBLE
        ),
      isFreeTrialActive:
        !!selfUpgradeEligibilityData.length &&
        selfUpgradeEligibilityData.every(({ trialStatus }) => trialStatus === TRIAL_ACTIVE),
      isFeatureActive:
        !!selfUpgradeEligibilityData.length && selfUpgradeEligibilityData.every(({ isStateActive }) => isStateActive),
      isNonDentalError:
        allowedForDentalOnly &&
        !!selfUpgradeEligibilityData.length &&
        selfUpgradeEligibilityData.some(
          ({ locationId }) => accessibleLocationData[locationId]?.vertical !== Vertical.DENTAL
        ),
    }),
    [accessibleLocationData, allowedForDentalOnly, selfUpgradeEligibilityData, selfSubscribeAllowed, trialAllowed]
  );

  const handleTrialAction = useCallback(
    (locationIds?: string[]) => {
      setFreeTrialEligibilityData(
        Array.isArray(locationIds) && locationIds?.length
          ? selfUpgradeEligibilityData.filter(({ locationId }) => locationIds.includes(locationId))
          : selfUpgradeEligibilityData
      );
      freeTrialModalControls.openModal();
    },
    [selfUpgradeEligibilityData]
  );

  const handleUpgradeAction = useCallback(
    (locationIds?: string[]) => {
      setSelfSubscribeEligibilityData(
        Array.isArray(locationIds) && locationIds?.length
          ? selfUpgradeEligibilityData.filter(({ locationId }) => locationIds.includes(locationId))
          : selfUpgradeEligibilityData
      );
      selfSubscribeModalControls.openModal();
    },
    [selfUpgradeEligibilityData]
  );

  const handleScheduleCallAction = () => {
    window.open(scheduleCallLink, '_blank', 'noopener noreferrer');
  };

  const invalidateSubscriptionQueries = () => {
    queryClient.invalidateQueries({
      queryKey: FreeTrialSubscriptionQueries.freeTrialSubscriptionQueryKeys.subscriptionBaseQuery,
      exact: false,
    });
  };

  const handleFreeTrialSuccess = () => {
    freeTrialModalControls.closeModal();
    onFreeTrialSuccess?.();
    invalidateSubscriptionQueries();
  };

  const handleUpgradeSuccess = () => {
    selfSubscribeModalControls.closeModal();
    onUpgradeSuccess?.();
    invalidateSubscriptionQueries();
  };

  return (
    <>
      <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
        {!isLoading && renderAlert({ canManageAddons, isFeatureActive, isFreeTrialActive, isNonDentalError })}
        <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(6) }}>
          <div css={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: theme.spacing(3) }}>
            <section css={{ flex: 1 }}>{renderFeatureContent({ isFeatureActive })}</section>
            <aside
              css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3), minWidth: theme.spacing(45.5) }}
            >
              {(isFreeTrialEligible || isLoading) &&
                renderFreeTrialCard({
                  isLoading,
                  disableAction: isLoading || !canManageAddons,
                  handleTrialAction,
                })}
              {renderSubscriptionCard({
                isFeatureActive,
                canSelfSubscribe,
                disableAction: isLoading || !canManageAddons || !isUpgradeEligible || isNonDentalError,
                handleScheduleCallAction,
                handleUpgradeAction,
                isMulti: selfUpgradeEligibilityData.length > 1,
                isLoading,
              })}
            </aside>
          </div>
          {selfUpgradeEligibilityData?.length > 1 &&
            renderLocationTable({
              isLoading,
              selfUpgradeEligibilityData,
              handleTrialAction,
              handleUpgradeAction,
              handleScheduleCallAction,
              disableAction: isLoading || !canManageAddons || isNonDentalError,
            })}
        </div>
      </div>
      <FreeTrialModal
        modalControls={freeTrialModalControls}
        featureEnum={featureEnum}
        featureName={featureName}
        featureAccountSettingsUrl={settingsPageLink || ''}
        freeTrialEligibilityData={freeTrialEligibilityData}
        onActionSuccess={handleFreeTrialSuccess}
      />
      {!!FeatureSelfSubscribeModal ? (
        <FeatureSelfSubscribeModal
          {...selfSubscribeModalControls.modalProps}
          onUpgradeSuccess={handleUpgradeSuccess}
          selfSubscribeEligibilityData={selfSubscribeEligibilityData}
        />
      ) : (
        <SelfUpgradeModal
          featureEnum={featureEnum}
          featureName={featureName}
          selfSubscribeEligibilityData={selfSubscribeEligibilityData}
          price={price}
          subscriptionTerm={subscriptionTerm}
          featureRedirectUrl={homePageLink}
          onUpgradeSuccess={handleUpgradeSuccess}
          onboardingCallLink={onboardingCallLink || ''}
          {...selfSubscribeModalControls.modalProps}
        />
      )}
    </>
  );
};

FeatureSignUpPage.Content = FeatureSignUpDetailCard;
FeatureSignUpPage.Alert = FeatureSignUpAlert;
FeatureSignUpPage.LocationTable = FeatureLocationSubscriptionTable;
FeatureSignUpPage.TrialCard = SignUpPageFreeTrialSubscriptionCard;
FeatureSignUpPage.SubscriptionCard = FeatureSignUpCard;
