import { useEffect } from 'react';
import { useNavSize, NavSize } from '@frontend/responsiveness';
import { useInterRouter } from '@frontend/settings-routing';

export const useSettingsNavSize = () => {
  const { setIsSettingsMenuOpen, isSettingsMenuOpen, isSettingsOpen } = useInterRouter([
    'setIsSettingsMenuOpen',
    'isSettingsMenuOpen',
    'isSettingsOpen',
  ]);

  const navSize = useNavSize();
  const isDesktopAndMenuOpen = navSize === NavSize.Desktop && isSettingsMenuOpen;

  useEffect(() => {
    if (isDesktopAndMenuOpen || !isSettingsOpen) {
      setIsSettingsMenuOpen(false);
    }
  }, [isDesktopAndMenuOpen, isSettingsOpen, setIsSettingsMenuOpen]);

  const isSmallerThanDesktop = navSize !== NavSize.Desktop;
  const isMini = navSize === NavSize.Mini;

  return { navSize, isSmallerThanDesktop, isMini };
};
