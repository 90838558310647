import { FormsWritebacks } from '@frontend/api';
import { createProvider } from '@frontend/store';
import { MultiState } from '../../types';
import { WritebackSettingsStore } from './types';

export const { Provider: WritebackSettingsStoreProvider, useStore: useWritebackSettingsStore } =
  createProvider<WritebackSettingsStore>()((set) => ({
    isLoadingWritebackSettings: false,
    setIsLoadingWritebackSettings: (isLoading) => set(() => ({ isLoadingWritebackSettings: isLoading })),

    isFetchingWritebackSettings: false,
    setIsFetchingWritebackSettings: (isFetching) => set(() => ({ isFetchingWritebackSettings: isFetching })),

    isFetchedWritebackSettings: false,
    setIsFetchedWritebackSettings: (isFetched) => set(() => ({ isFetchedWritebackSettings: isFetched })),

    currentHash: '',
    setCurrentHash: (hash) => set(() => ({ currentHash: hash })),

    writebackSettings: {},
    setWritebackSettings: (writebackSettings) => set(() => ({ writebackSettings })),

    isBulkUpdatingWritebackSettings: false,
    setIsBulkUpdatingWritebackSettings: (status) => set(() => ({ isBulkUpdatingWritebackSettings: status })),

    updatingWritebackSettings: {},
    setUpdatingWritebackSettings: (locationId, status) => {
      set((state) => {
        return {
          updatingWritebackSettings: {
            ...state.updatingWritebackSettings,
            [locationId]: status,
          },
        };
      });
    },

    updateWritebackSetting: (locationId, settingId, settingValue) => {
      set((state) => {
        const writebackSettings = state.writebackSettings[locationId] ?? [];
        const updatedWritebackSettings = writebackSettings.map((setting) => {
          if (setting.settingId === settingId) {
            return {
              ...setting,
              settingValue,
            };
          }

          return setting;
        });

        return {
          writebackSettings: {
            ...state.writebackSettings,
            [locationId]: updatedWritebackSettings,
          },
        };
      });
    },

    bulkUpdateWritebackSettings: (payload) => {
      set((state) => {
        // Get the state to update the writeback settings
        const updatedWritebackSettings = payload.reduce<MultiState<FormsWritebacks.Types.ModifiedWritebackSetting[]>>(
          (acc, { locationId, settings: settingsUpdate }) => {
            const writebackSettings = state.writebackSettings[locationId] ?? [];

            // Update the writeback settings for the given location
            const updatedSettings = writebackSettings.map((originalSetting) => {
              const updatedSetting = settingsUpdate.find((s) => s.settingId === originalSetting.settingId);

              if (updatedSetting) {
                return {
                  ...originalSetting,
                  settingValue: updatedSetting.settingValue,
                };
              }

              return originalSetting;
            });

            return {
              ...acc,
              [locationId]: updatedSettings,
            };
          },
          {}
        );

        return {
          writebackSettings: {
            ...state.writebackSettings,
            ...updatedWritebackSettings,
          },
        };
      });
    },
  }));
