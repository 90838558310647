export const useBulkNavigation = ({
  isApp,
  feature = 'text',
  id,
  isDraft = false,
}: {
  isApp: boolean;
  feature?: 'text' | 'email';
  id?: string;
  isDraft?: boolean;
}) => {
  const draftBase = '/bulk/drafts';
  const allCampaignsBase = `/bulk/all-campaigns/${feature}`;
  const portalBase = '/portal/messages/bulk';
  const appSuffix = feature === 'email' ? '/editor' : '';

  if (isApp && isDraft) {
    return {
      base: draftBase,
      new: `${draftBase}/${feature}/new` + appSuffix,
      edit: `${draftBase}/${feature}/${id}` + appSuffix,
    };
  } else if (isApp) {
    return {
      base: allCampaignsBase,
      new: `${allCampaignsBase}/new` + appSuffix,
      edit: `${allCampaignsBase}/${id}` + appSuffix,
    };
  }
  return {
    base: portalBase,
    new: `${portalBase}/new`,
    edit: `${portalBase}/${id}`,
  };
};
