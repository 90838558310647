import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { StyleProps, ButtonStyleValues } from '../button.type';
import { baseButtonStyle, pressedStyle, hoverStyle } from './common.styles';

const secondaryButtonTheme: ButtonStyleValues = {
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.neutral30,
  color: theme.font.colors.default,
  focusRingColor: theme.colors.neutral50,
  hoverColor: theme.colors.neutral10,
  pressed: {
    borderColor: theme.colors.neutral90,
    backgroundColor: theme.colors.neutral10,
  },
  disabled: {
    backgroundColor: theme.colors.white,
    color: theme.colors.status.disabled,
    secondaryColor: theme.colors.neutral20,
    borderColor: theme.colors.neutral30,
  },
  loading: {
    backgroundColor: 'transparent',
    borderColor: theme.colors.neutral30,
  },
};

export const secondaryStyles = ({ size, isLoading, iconOnly }: StyleProps) => [
  baseButtonStyle({
    focusRingColor: secondaryButtonTheme.focusRingColor,
    size: size ?? 'small',
    iconOnly,
  }),
  css`
    background: ${secondaryButtonTheme.backgroundColor};
    border: 1px solid ${secondaryButtonTheme.borderColor};
    color: ${secondaryButtonTheme.color};
  `,
  isLoading
    ? css`
        border: 1px solid ${secondaryButtonTheme.loading.borderColor};
        background: ${secondaryButtonTheme.loading.backgroundColor};
        &[aria-disabled='true'] {
          box-shadow: none;
          color: ${secondaryButtonTheme.disabled.color};
        }
      `
    : css`
        :hover {
          ${hoverStyle({
            hoverColor: secondaryButtonTheme.hoverColor,
            borderColor: secondaryButtonTheme.borderColor,
          })};
        }
        &[aria-disabled='true'] {
          background: ${secondaryButtonTheme.disabled.backgroundColor};
          border-color: ${secondaryButtonTheme.disabled.borderColor};
          color: ${secondaryButtonTheme.disabled.color};
        }
        ${pressedStyle({ pressed: secondaryButtonTheme.pressed })}
      `,
];
