import { css } from '@emotion/react';
import {
  SubscriptionStatusForSelfUpgrade,
  TrialStatus,
} from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { FreeTrialsQueries } from '@frontend/api-free-trials';
import { VideoFrame } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';
import { ManageSubscriptionAccessWarning } from '../components';
import {
  PracticeAnalyticsFreeTrialSubscribeCard,
  PracticeAnalyticsSubscribeCard,
} from '../components/practice-self-signup';

export const PracticeAnalyticsSignUp = () => {
  const { t } = useTranslation('analytics');
  const { flags } = useCustomizationFlagShallowStore('flags');
  const isPAEnabled = flags.practiceanalytics?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
  const isFreeTrialEnabled = isPAEnabled && flags.practiceanalytics?.isFreeTrial;

  const { data: selfUpgradeEligibility } = FreeTrialsQueries.useGetIsEligibleForSelfUpgradeOrTrial(
    Feature.PRACTICE_ANALYTICS
  );

  const canViewFreeTrialsCard = selfUpgradeEligibility?.trialStatus === TrialStatus.TRIAL_ELIGIBLE;

  const hideSignUpCard =
    selfUpgradeEligibility?.subscriptionStatus === SubscriptionStatusForSelfUpgrade.SUBSCRIPTION_STATUS_UNSPECIFIED;

  return (
    <>
      <ManageSubscriptionAccessWarning isFreeTrialActive={isFreeTrialEnabled} isSubscriptionActive={isPAEnabled} />
      <div css={styles.container}>
        <section>
          <Heading level={3}>{t('Better understand your practice.')}</Heading>
          <Heading level={3} style={{ color: theme.colors.text.interactive }}>
            {t('Analyze data, schedule more patients, and increase revenue opportunities.')}
          </Heading>
          <VideoFrame css={styles.videoFrame} src='https://fast.wistia.com/embed/medias/ymxr76flgk' />
          <Text weight='bold'>
            {t(
              'Practice Analytics tackles challenges like limited visibility into abandoned treatment plans, inefficient appointment scheduling and lack of performance tracking.'
            )}
          </Text>
          <Text>
            {t(
              'It gives you real-time actionable data built into your communication platform to help drive practice growth, team performance, and daily production.'
            )}
          </Text>
        </section>
        <aside>
          {canViewFreeTrialsCard && <PracticeAnalyticsFreeTrialSubscribeCard />}

          {!hideSignUpCard && <PracticeAnalyticsSubscribeCard />}
        </aside>
      </div>
    </>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(6)};

    > section {
      flex: 1;
      max-width: 860px;
      min-width: 380px;
    }

    > aside {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: ${theme.spacing(4)};
    }
  `,

  videoFrame: css`
    margin: ${theme.spacing(4, 0)};
    width: 100%;
  `,
};
