export const featureFlags = {
  bulkMessages: 'commx-bulk-messaging',
  // TODO: once Matt has contacted these locations we can remove this flag
  // this is a temp flag for showing the missed call reminders for specific locations
  // but by default the when to send section will be hidden for missed calls types
  showMissedCallReminders: 'commx-missed-call-reminders',
};

export const MMS_MAX_SIZE = 1000000;
export const SENDING_STATUS_DETAILS = 'Sending...';
