import { CallIntelligenceTypes } from '../../call-intelligence';

// Randomly generate a value between given min and max
export const generateRandomValue = (min?: number, max?: number) =>
  Math.floor(Math.random() * ((max || 100) - (min || 1) + 1)) + (min || 1);

const getCallsCount = (sentiments?: CallIntelligenceTypes.Overview['sentiments']): number => {
  // Each call will have only 1 sentiment, hence total calls = total sentiments
  return Object.values(sentiments || {}).reduce((acc, count) => acc + count, 0);
};

export const getFilteredOverviewData = (
  overview: CallIntelligenceTypes.Overview,
  filters: CallIntelligenceTypes.Filters
): CallIntelligenceTypes.Overview => {
  if (!filters.contactTypes || !filters.contactTypes.length) {
    return overview;
  }

  const factor = filters.contactTypes.length / (Object.keys(CallIntelligenceTypes.ContactTypeEnum).length - 1);

  const filterData = (data: Partial<Record<string, number>> | null): Partial<Record<string, number>> | null => {
    if (!data) return data;

    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, Math.floor((value ?? 0) * factor)]));
  };

  const filteredOverview = {
    appointmentTypes: filterData(overview.appointmentTypes),
    categories: filterData(overview.categories),
    sentiments: filterData(overview.sentiments),
    summary: {
      scheduledOpportunities: Math.floor((overview?.summary?.scheduledOpportunities || 1) * factor),
      totalOpportunities: overview?.summary?.totalOpportunities,
    },
  };

  return filteredOverview;
};

export const getFilteredOverviewCategoriesDrillDown = (
  overview: CallIntelligenceTypes.Overview,
  filters: CallIntelligenceTypes.Filters
): CallIntelligenceTypes.Overview => {
  if (!filters.contactTypes || !filters.contactTypes.length) {
    return overview;
  }

  const factor = filters.contactTypes.length / (Object.keys(CallIntelligenceTypes.ContactTypeEnum).length - 1);

  const filterData = (data: Partial<Record<string, number>> | null): Partial<Record<string, number>> | null => {
    if (!data) return data;

    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, Math.floor((value ?? 0) * factor)]));
  };

  const filteredOverview = {
    appointmentTypes: filterData(overview.appointmentTypes),
    categories: overview.categories,
    sentiments: filterData(overview.sentiments),
    summary: {
      scheduledOpportunities: Math.floor((overview?.summary?.scheduledOpportunities || 1) * factor),
      totalOpportunities: overview?.summary?.totalOpportunities,
    },
  };

  return filteredOverview;
};

export const getFilteredOverviewAppointmentsDrillDown = (
  overview: CallIntelligenceTypes.Overview,
  filters: CallIntelligenceTypes.Filters
): CallIntelligenceTypes.Overview => {
  if (!filters.contactTypes || !filters.contactTypes.length) {
    return overview;
  }

  const factor = filters.contactTypes.length / (Object.keys(CallIntelligenceTypes.ContactTypeEnum).length - 1);

  const filterData = (data: Partial<Record<string, number>> | null): Partial<Record<string, number>> | null => {
    if (!data) return data;

    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, Math.floor((value ?? 0) * factor)]));
  };

  const filteredOverview = {
    appointmentTypes: overview.appointmentTypes,
    categories: filterData(overview.categories),
    sentiments: filterData(overview.sentiments),
    summary: {
      scheduledOpportunities: Math.floor((overview?.summary?.scheduledOpportunities || 1) * factor),
      totalOpportunities: overview?.summary?.totalOpportunities,
    },
  };

  return filteredOverview;
};

export const getFilteredOverviewSentimentsDrillDown = (
  overview: CallIntelligenceTypes.Overview,
  filters: CallIntelligenceTypes.Filters
): CallIntelligenceTypes.Overview => {
  if (!filters.contactTypes || !filters.contactTypes.length) {
    return overview;
  }

  const factor = filters.contactTypes.length / (Object.keys(CallIntelligenceTypes.ContactTypeEnum).length - 1);

  const filterData = (data: Partial<Record<string, number>> | null): Partial<Record<string, number>> | null => {
    if (!data) return data;

    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, Math.floor((value ?? 0) * factor)]));
  };

  const filteredOverview = {
    appointmentTypes: filterData(overview.appointmentTypes),
    categories: filterData(overview.categories),
    sentiments: filterData(overview.sentiments),
    summary: {
      scheduledOpportunities: Math.floor((overview?.summary?.scheduledOpportunities || 1) * factor),
      totalOpportunities: overview?.summary?.totalOpportunities,
    },
  };

  return filteredOverview;
};

const overviewSentiments = {
  [CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE]: generateRandomValue(10, 15),
  [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL]: generateRandomValue(15, 20),
  [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE]: generateRandomValue(10, 15),
};

const overviewDrillDownSentiments = {
  [CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE]: generateRandomValue(1, 8),
  [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL]: generateRandomValue(1, 15),
  [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE]: generateRandomValue(1, 10),
};

const overviewScheduledOpportunities = generateRandomValue(1, getCallsCount(overviewSentiments));

const overviewDrillDownScheduledOpportunities = generateRandomValue(1, getCallsCount(overviewDrillDownSentiments));

export const overview: CallIntelligenceTypes.Overview = {
  appointmentTypes: {
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: generateRandomValue(20, 50),
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: generateRandomValue(10, 20),
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: generateRandomValue(2, 40),
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: generateRandomValue(2, 10),
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: generateRandomValue(25, 40),
  },
  categories: {
    [CallIntelligenceTypes.CategoryEnum.CATEGORY_BILLING]: generateRandomValue(8, 30),
    [CallIntelligenceTypes.CategoryEnum.CATEGORY_COMPLAINT]: generateRandomValue(10, 12),
    [CallIntelligenceTypes.CategoryEnum.CATEGORY_EMERGENCY]: generateRandomValue(2, 5),
    [CallIntelligenceTypes.CategoryEnum.CATEGORY_SCHEDULING]: generateRandomValue(30, 60),
  },
  sentiments: overviewSentiments,
  summary: {
    scheduledOpportunities: generateRandomValue(1, overviewScheduledOpportunities),
    totalOpportunities: overviewScheduledOpportunities,
  },
};

export const overviewAppointmentsDrillDown: CallIntelligenceTypes.Overview = {
  appointmentTypes: {},
  categories: {
    [CallIntelligenceTypes.CategoryEnum.CATEGORY_BILLING]: generateRandomValue(2, 20),
    [CallIntelligenceTypes.CategoryEnum.CATEGORY_SCHEDULING]: generateRandomValue(1, 3),
    [CallIntelligenceTypes.CategoryEnum.CATEGORY_EMERGENCY]: generateRandomValue(10, 40),
  },
  sentiments: overviewDrillDownSentiments,
  summary: {
    scheduledOpportunities: generateRandomValue(1, overviewDrillDownScheduledOpportunities),
    totalOpportunities: overviewDrillDownScheduledOpportunities,
  },
};

export const overviewCategoriesDrillDown: CallIntelligenceTypes.Overview = {
  appointmentTypes: {
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: generateRandomValue(2, 30),
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: generateRandomValue(1, 30),
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: generateRandomValue(1, 5),
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: generateRandomValue(2, 25),
  },
  categories: {},
  sentiments: overviewDrillDownSentiments,
  summary: {
    scheduledOpportunities: generateRandomValue(1, overviewDrillDownScheduledOpportunities),
    totalOpportunities: overviewDrillDownScheduledOpportunities,
  },
};

export const overviewSentimentsDrillDown: CallIntelligenceTypes.Overview = {
  appointmentTypes: {
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: generateRandomValue(2, 30),
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: generateRandomValue(2, 15),
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: generateRandomValue(1, 30),
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: generateRandomValue(1, 5),
    [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: generateRandomValue(2, 25),
  },
  categories: {
    [CallIntelligenceTypes.CategoryEnum.CATEGORY_BILLING]: generateRandomValue(2, 20),
    [CallIntelligenceTypes.CategoryEnum.CATEGORY_COMPLAINT]: generateRandomValue(2, 6),
    [CallIntelligenceTypes.CategoryEnum.CATEGORY_EMERGENCY]: generateRandomValue(10, 40),
    [CallIntelligenceTypes.CategoryEnum.CATEGORY_SCHEDULING]: generateRandomValue(1, 3),
  },
  sentiments: overviewDrillDownSentiments,
  summary: {
    scheduledOpportunities: generateRandomValue(1, overviewDrillDownScheduledOpportunities),
    totalOpportunities: overviewDrillDownScheduledOpportunities,
  },
};
