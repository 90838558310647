import {
  FieldProps,
  IconButton,
  Modal,
  ModalControlModalProps,
  SwitchField,
  Text,
  TextLink,
  XIcon,
  useModalControl,
} from '@frontend/design-system';
import { formConfigBase } from '../../views/phone-tree';
import { SectionHeader, SettingSection } from '../call-queue/primitives';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';

interface IExtensionSettings {
  field: FieldProps<typeof formConfigBase, 'allowExtensionDialing'>;
}

export const ExtensionSettings = ({ field }: IExtensionSettings) => {
  const { modalProps, triggerProps } = useModalControl();
  const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });

  return (
    <SettingSection>
      <SectionHeader>
        <SwitchField
          labelPlacement='left'
          {...field}
          css={{ width: 'max-content' }}
          data-trackingid={
            field.value
              ? 'phone-portal-phoneTree-extensionDialing-switch-disabled'
              : 'phone-portal-phoneTree-extensionDialing-switch-enabled'
          }
          label={
            <Text
              as='span'
              weight='bold'
              css={css`
                font-size: 1.5rem;
              `}
            >
              {t('Dial By Extension')}
            </Text>
          }
        />
        <Text size='large' color='light'>
          {t('Activating Dial by Extension allows callers in this phone tree to dial office device extensions.')}{' '}
          <TextLink
            trackingId='phone-portal-phoneTree-extensionDialing-learnMore-infoModal'
            weight='bold'
            {...triggerProps}
          >
            {t('Learn More')}
          </TextLink>
        </Text>
      </SectionHeader>
      <ExtensionInfoModal {...modalProps} />
    </SettingSection>
  );
};

const ExtensionInfoModal = (modalProps: ModalControlModalProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });
  return (
    <Modal maxWidth={600} {...modalProps}>
      <Modal.Header
        css={css`
          display: flex;
          justify-content: space-between;
          font-size: ${theme.fontSize(36)};
        `}
        textAlign='left'
      >
        {t('Dial by Extension')}
        <IconButton label={t('Close')} onClick={modalProps.onClose}>
          <XIcon />
        </IconButton>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <Text>
          {t(
            "Dial by Extension allows callers who reach this phone tree to dial any of the office's device extensions."
          )}
        </Text>
        <Text>{t('Some common use cases for this feature are:')}</Text>

        <div>
          <Text weight='bold'>{t('Extension Directory')}</Text>
          <Text>
            {t(
              'Businesses that want to allow their callers to reach a specific person can inform the caller during the phone tree prompt. Example: “Thank you for calling our office. Press 1 for appointments. Press 2 for billing. If you know your party’s extension, please dial it now.“'
            )}
          </Text>
        </div>

        <div>
          <Text weight='bold'>{t('Pre-Informed Callers')}</Text>
          <Text>
            {t(
              'Businesses that would only like a select set of callers, such as business partners, to directly call an extension can privately inform those callers to dial by extension while leaving it out of the prompt.'
            )}
          </Text>
        </div>

        <Text>
          <Trans t={t}>
            Learn more about phone tree configurations through{' '}
            <TextLink
              trackingId='phone-portal-phoneTree-extensionDialing-weaveHelp-link'
              rel='noreferrer'
              target='_blank'
              href='https://www.weavehelp.com/hc/en-us/articles/360060796212'
            >
              Weave Help
            </TextLink>
            .
          </Trans>
        </Text>
      </Modal.Body>
    </Modal>
  );
};
