import { PhoneRecordsSchemaService, SchemaIO, VoicemailSchemaService } from '@frontend/schema';
import { http } from '@frontend/fetch';
import {
  VoicemailPayload,
  VoicemailResponse,
  OnReadParams,
  DeleteVoicemailParams,
  DownloadVoicemailResponse,
  ToggleReadResponse,
  GetVoicemailsTypes,
  SetVoicemailViewStatusTypes,
  DeleteVoicemailTypes,
  ListMultiVoicemails,
  ListVoicemailBox,
  ForwardVoicemailTypes,
} from './types';

const baseEndpoint = 'desktop/v1/voicemails';

export const getVoicemails = async (payload: VoicemailPayload, url?: string): Promise<VoicemailResponse> => {
  const { limit = 0, skip = 0, departmentIds = [], isGeneral, workstationSlug, departmentsEnabled } = payload;
  const departmentIdsParams = departmentIds.length ? `&departmentID=${departmentIds.join('&departmentID=')}` : '';
  const mailboxParams = !departmentsEnabled ? `&mailbox=${isGeneral ? 'general' : 'currentWorkstation'}` : '';
  const workstationSlugParams = `&workstationSlug=${!!workstationSlug ? workstationSlug : 'none'}`;

  const { data, meta } = await http.get<VoicemailResponse>(
    url ||
      `${baseEndpoint}?limit=${limit}&skip=${skip}&orderBy=createdAt${departmentIdsParams}${workstationSlugParams}${mailboxParams}`
  );
  return { data: skip === 0 ? data.slice(0, limit) : data, meta };
};

export const markVoicemailAsRead = async ({ mailboxId, messageId, markedRead }: OnReadParams) =>
  http.put<ToggleReadResponse>(`${baseEndpoint}/read/mailboxid/${mailboxId}/messageid/${messageId}`, { markedRead });

export const deleteVoicemail = async ({ mailboxId, messageId }: DeleteVoicemailParams) =>
  http.delete<Record<string, never>>(`${baseEndpoint}/mailboxid/${mailboxId}/messageid/${messageId}`);

export const downloadVoicemail = async (url: string) =>
  http.get<DownloadVoicemailResponse>(url, { responseType: 'blob' });

//------------schema endpoints here--------------

export const listVoicemails = (req: GetVoicemailsTypes['input']): Promise<GetVoicemailsTypes['output']> => {
  return VoicemailSchemaService.ListVoicemails(req);
};

export const setVoicemailViewStatus = (req: SetVoicemailViewStatusTypes['input']) => {
  return VoicemailSchemaService.SetVoicemailViewStatus(req);
};

export const deleteVoicemailMedia = (req: DeleteVoicemailTypes['input']) => {
  return VoicemailSchemaService.DeleteVoicemail(req);
};

export const forwardVoicemail = (req: ForwardVoicemailTypes['input']) => {
  return VoicemailSchemaService.ForwardVoicemailMessage(req);
};

export const listMultiVoicemails = (req: ListMultiVoicemails['input']) => {
  return PhoneRecordsSchemaService.ListVoicemails(req);
};

export const listVoicemailBox = (req: ListVoicemailBox['input']) => {
  return VoicemailSchemaService.ListVoicemailBox(req);
};

export const tagVoicemail = (req: SchemaIO<typeof PhoneRecordsSchemaService.ApplyVoicemailMessageTags>['input']) => {
  return PhoneRecordsSchemaService.ApplyVoicemailMessageTags(req);
};

export const untagVoicemail = (req: SchemaIO<typeof PhoneRecordsSchemaService.RemoveVoicemailMessageTags>['input']) => {
  return PhoneRecordsSchemaService.RemoveVoicemailMessageTags(req);
};
