import { FormFieldActionTypes, useForm } from '@frontend/design-system';
import { Entries } from '@frontend/types';
import { LabeledFormFields, FormSchema, InstructionFieldsProps } from '../types';
import { FormFields } from './form';

const populateConfig = (
  fields: LabeledFormFields<FormSchema['fields']>,
  customization: InstructionFieldsProps<Record<string, string | boolean | string[]>>['customization']
) => {
  (
    Object.entries(customization.optionGroups ?? {}) as Entries<
      Record<keyof FormSchema['fields'], { value: string; label: string }[]>
    >
  ).forEach(([key, options]) => {
    fields[key].options = options;
  });

  (Object.entries(customization.value ?? {}) as Entries<Record<keyof FormSchema['fields'], string | string[]>>).forEach(
    ([key, options]) => {
      fields[key].value = options;
    }
  );

  (Object.entries(customization.meta ?? {}) as Entries<Record<keyof FormSchema['fields'], any>>).forEach(
    ([key, options]) => {
      fields[key].meta = options;
    }
  );

  return fields;
};

export const InstructionFields = <T extends Record<string, any>>({
  onChange,
  schema,
  customization,
}: InstructionFieldsProps<T>) => {
  const { fields: schemaFields } = schema;
  const config = populateConfig(schemaFields, customization);

  const form = useForm({
    fields: config,
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update) {
        const payload = Object.fromEntries(
          (Object.entries(state) as Entries<typeof state>).map(([key, field]) => [key, field.value])
        );
        onChange(payload);
      }

      return state;
    },
  });

  return <FormFields form={form} config={config} />;
};
