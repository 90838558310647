import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { AnalyticsCommonTypes, SMSAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { TableFilters } from '@frontend/design-system';
import { FiltersTray } from '..';
import { DEFAULT_DAY } from '../../constants';
import { useLocations, useTimePeriodFilterLabels } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { appendLocalTimeStamp, defaultDateRangeMap, getMatchingDateRangeKey } from '../../utils';
import { filtersStyles } from '../../views/common-styles';
import { DatesRangeSelector, LocationsSelector, TimePeriodSelector } from '../filter-selectors';
import { AdditionalMessagingFilters } from './additional-messaging-filters';
import { useMessagingAnalyticsStore } from './hooks';

type Props = {
  isLoadingData?: boolean;
  isTableView?: boolean;
};

const defaultDates = defaultDateRangeMap[DEFAULT_DAY];

export const MessagingAnalyticsFilters = ({ isLoadingData, isTableView }: Props) => {
  const { t } = useTranslation('analytics');
  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const locationData = getSelectedLocationData()[locationId];

  const {
    filters,
    hasCustomFilters,
    locationId: messagingAnalyticsLocationId,
    setDefaultFilters,
    setFilterHintText,
    setFilters,
    setLocationId,
    resetStore,
  } = useMessagingAnalyticsStore();

  const [trayFilters, setTrayFilters] = useState<SMSAnalyticsTypes.Filters>({});

  const timeFilterLabels = useTimePeriodFilterLabels();
  const { isLoading: isLoadingLocations, isSalesforceMultiLocation, isWAMMultiLocation, locations } = useLocations();
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const defaultFilters: SMSAnalyticsTypes.Filters = useMemo(
    () => ({
      content_type: [],
      end_date: appendLocalTimeStamp(defaultDates.endDate),
      location_id: locations ? Object.keys(locations) : [],
      message_category: [],
      number: '',
      sms_type: [],
      source_type: [],
      start_date: appendLocalTimeStamp(defaultDates.startDate),
      status: [],
      time_zone: locationData?.timezone,
    }),
    [locations]
  );

  const handleUpdateTrayFilters = (newFilters: Partial<SMSAnalyticsTypes.Filters>) => {
    setTrayFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const applyDefaultFilters = () => {
    // Also used for resetting filters whenever required
    setDefaultFilters(defaultFilters);
    setErrors({});
    setFilters(defaultFilters);
  };

  const applyActiveFiltersToTrayState = () => {
    handleUpdateTrayFilters(filters);
  };

  const handleApplyTrayFilters = () => {
    // This method will be invoked when user clicks on apply button in the tray
    setFilters(trayFilters);
  };

  const handleUpdateErrors = (error: AnalyticsCommonTypes.filterSelectorError) => {
    setErrors((prevErrors) => ({ ...prevErrors, [error.name]: error.value }));
  };

  const hasErrors = useMemo(() => Object.values(errors).some((error) => error), [errors]);

  useEffect(() => {
    // Update local state when main filters has changed
    applyActiveFiltersToTrayState();

    const timeFilter = getMatchingDateRangeKey({
      datesMap: defaultDateRangeMap,
      endDate: filters.end_date,
      startDate: filters.start_date,
    });

    // This text is displayed in sub view pages and charts to indicate the currently applied filters
    setFilterHintText(
      timeFilterLabels[timeFilter || ''] ||
        `${dayjs(filters.start_date).format('DD MMM, YYYY')} - ${dayjs(filters.end_date).format('DD MMM, YYYY')}`
    );
  }, [filters]);

  useEffect(() => {
    // Avoid resetting when custom filters are already applied (user has navigated from sub page to the main page)
    if (!hasCustomFilters) {
      applyDefaultFilters();
    }
  }, [defaultFilters]);

  useEffect(() => {
    if (!messagingAnalyticsLocationId) {
      setLocationId(locationId);
    } else if (locationId !== messagingAnalyticsLocationId) {
      //  Reset when location is switched
      resetStore();
      setLocationId(locationId);
      applyDefaultFilters();
    }
  }, [locationId, messagingAnalyticsLocationId]);

  return (
    <FiltersTray
      disableApply={hasErrors}
      disableTray={isLoadingData}
      headerLabel={t('Filter Messaging Analytics')}
      onApplyFilters={handleApplyTrayFilters}
      onResetFilters={applyDefaultFilters}
      onRevertLocalChanges={applyActiveFiltersToTrayState}
      showFilteredBadge={hasCustomFilters}
      trackingId={trackingIds.messagingAnalytics.filtersOpen}
    >
      {(isSalesforceMultiLocation || isWAMMultiLocation) && (
        <TableFilters.Section sectionHeaderLabel={t('Location')}>
          <div css={filtersStyles.traySection}>
            <LocationsSelector
              disabled={isLoadingLocations}
              locations={locations}
              onChange={(value) => handleUpdateTrayFilters({ location_id: value })}
              onError={handleUpdateErrors}
              value={trayFilters.location_id}
            />
          </div>
        </TableFilters.Section>
      )}

      <TableFilters.Section sectionHeaderLabel={t('Time')}>
        <div css={filtersStyles.traySection}>
          <TimePeriodSelector
            defaultPeriod={DEFAULT_DAY}
            endDate={trayFilters.end_date}
            onChange={({ endDate, startDate }) => {
              handleUpdateTrayFilters({
                end_date: appendLocalTimeStamp(endDate),
                start_date: appendLocalTimeStamp(startDate),
              });
            }}
            startDate={trayFilters.start_date}
          />
          <DatesRangeSelector
            endDate={trayFilters.end_date}
            onChange={({ endDate, startDate }) =>
              handleUpdateTrayFilters({
                end_date: appendLocalTimeStamp(endDate),
                start_date: appendLocalTimeStamp(startDate),
              })
            }
            startDate={trayFilters.start_date}
          />
        </div>
      </TableFilters.Section>

      {isTableView && (
        <AdditionalMessagingFilters disabled={isLoadingData} filters={filters} onChange={handleUpdateTrayFilters} />
      )}
    </FiltersTray>
  );
};
