import { CSSProperties, ReactNode } from 'react';
import { css } from '@emotion/react';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Heading, IconButton, Text, TextLink, useTooltip } from '@frontend/design-system';

export type ChartHeaderAction = {
  disabled?: boolean;
  label: string;
  omit?: boolean;
  onClick: () => void;
  trackingId?: string;
} & (
  | {
      hoverText?: never;
      iconName?: IconName;
    }
  | {
      hoverText?: string;
      iconName?: never;
    }
);

type HeaderProps = {
  actions?: ChartHeaderAction[];
  bottomElement?: ReactNode | null;
  expandable?: boolean;
  infoTip?: ReactNode;
  leftElement?: ReactNode | null | false;
  style?: CSSProperties;
  subtitle?: string;
  title: string;
  trackingIdHelper?: string;
};

export const Header = ({
  actions = [],
  bottomElement,
  expandable,
  infoTip,
  leftElement,
  subtitle,
  title,
  ...rest
}: HeaderProps) => {
  const {
    Tooltip,
    tooltipProps,
    triggerProps: tooltipTriggerProps,
  } = useTooltip({ placement: 'top', trigger: 'hover' });

  return (
    <div {...rest}>
      <header css={styles.header} style={bottomElement ? { marginBottom: 0 } : {}}>
        <div className='title-wrapper'>
          {leftElement}
          <Heading className='heading' level={3}>
            {title}
            {infoTip}
          </Heading>
        </div>
        {(actions.length || expandable || subtitle) && (
          <div className='actions-wrapper'>
            {subtitle && (
              <Text color='light' weight='bold'>
                {subtitle}
              </Text>
            )}
            {actions.map(({ hoverText, iconName, omit, onClick, ...rest }) =>
              omit ? null : iconName ? (
                <IconButton
                  {...rest}
                  key={rest.label}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick();
                  }}
                  showLabelOnHover
                  size='normal'
                >
                  <Icon color='light' name={iconName} />
                </IconButton>
              ) : (
                <div key={rest.label} {...tooltipTriggerProps}>
                  <TextLink
                    {...rest}
                    as='button'
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick();
                    }}
                  >
                    {rest.label}
                  </TextLink>
                  {hoverText && <Tooltip {...tooltipProps}>{hoverText}</Tooltip>}
                </div>
              )
            )}
          </div>
        )}
      </header>
      {bottomElement}
    </div>
  );
};

const styles = {
  header: css`
    align-items: center;
    column-gap: ${theme.spacing(2)};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};

    .title-wrapper {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing(0.5)};
    }

    .heading {
      align-items: center;
      display: flex;
      gap: ${theme.spacing(0.5)};

      button {
        margin-top: ${theme.spacing(0.25)};
      }
    }

    .actions-wrapper {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing(1)};
    }
  `,
};
