import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { FormsSubmission, PhoneCallsQueries } from '@frontend/api';
import { BulkMessagingQueries } from '@frontend/api-bulk-messaging';
import { ClientSettingsApi } from '@frontend/api-client-settings';
import {
  CustomizationFlagQueries,
  CustomizationFlagTypes,
  useCustomizationFlagShallowStore,
} from '@frontend/api-customization-flags';
import { FaxQueries } from '@frontend/api-fax';
import { FaxDraftQueries } from '@frontend/api-fax-draft';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { InboxQueries } from '@frontend/api-messaging';
import { useListDraftsCountQuery } from '@frontend/api-sms-draft';
import {
  AnalyticsFeatureFlags,
  useShowAppointmentAnalyticsNav,
  useShowCallIntelNav,
  useShowPracticeAnalyticsNav,
} from '@frontend/analytics';
import { hasSchemaACL, getUser } from '@frontend/auth-helpers';
import { NavItem } from '@frontend/components';
import { pendoTags as formsPendoTags } from '@frontend/forms';
import { useTranslation } from '@frontend/i18n';
import { useTranslationTokens } from '@frontend/i18n-tokens';
import { Icon, IconName } from '@frontend/icons';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useBulkDraftShallowStore } from '@frontend/messages';
import { MultiSettingsHooks } from '@frontend/multi-settings';
import { useMerchant } from '@frontend/payments-hooks';
import { useHasPhoneSystemAccess } from '@frontend/phone-config';
import { useAppScopeStore } from '@frontend/scope';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { FaxPrefixes } from '@frontend/tracking-prefixes';
import { DeepReadonly } from '@frontend/types';

const NavIcon = ({ name, ...rest }: { name: IconName }) => <Icon name={name} size={20} {...rest} />;

export const primaryNavContent = () => {
  const { t } = useTranslation('primaryNav');
  const { locationId } = useLocationDataShallowStore('locationId');

  const hasAppointmentAnalyticsAccess = useShowAppointmentAnalyticsNav();
  const hasCallIntelAccess = useShowCallIntelNav();
  const hasPracticeAnalyticsAccess = useShowPracticeAnalyticsNav();
  const tokens = useTranslationTokens();

  const { selectedLocationIdsWithParents, selectedLocationIds, selectedOrgId } = useAppScopeStore();
  const user = getUser();

  // ACL(s)
  const analyticsAcl = hasSchemaACL(locationId, Permission.ANALYTICS_READ);
  const messagingAnalyticsAcl = hasSchemaACL(locationId, Permission.ANALYTICS_READ_MESSAGING);
  const phoneAnalyticsAcl = hasSchemaACL(locationId, Permission.ANALYTICS_READ_PHONE);
  const emailMarketingAcl = hasSchemaACL(locationId, Permission.EMAIL_MARKETING_MANAGE);
  const bulkTextingAcl = hasSchemaACL(locationId, Permission.BULK_TEXTING_MANAGE);

  // Customization Flags
  const { flags } = useCustomizationFlagShallowStore('flags');
  const isAutoRecallAnalyticsEnabled =
    flags['reports-recall']?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
  const hasPhoneSystemAccess = useHasPhoneSystemAccess();

  // Feature Flags
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const faxFeatureFlag = flagValues['fax-2-0'];
  const faxBlockFeatureFlag = flagValues['fax-2-0-block'];
  const faxArchiveFeatureFlag = flagValues['fax-2-0-archive'];
  const messagingAnalytics =
    flagValues[AnalyticsFeatureFlags.messagingAnalytics] ||
    flagValues[AnalyticsFeatureFlags.messagingAnalyticsDemoData];
  const phoneAnalytics =
    flagValues[AnalyticsFeatureFlags.phoneAnalytics] || flagValues[AnalyticsFeatureFlags.practiceAnalyticsDemo];
  const ourPartnersPage = flagValues['our-partners-page'];
  const bulkTextingFeatureFlag = flagValues['commx-bulk-messaging'];
  const bulkEmailFeatureFlag = flagValues['commx-email-marketing-v2'];
  const showAutoRecallDemoData = flagValues[AnalyticsFeatureFlags.autoRecallDemoData];

  const { aggregateValue: paymentsFeatureFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'payments:2.0',
    aggregationStrategy: FeatureFlagQueries.AggregationStrategy.ANY,
    locationIds: selectedLocationIds,
  });

  const { isFeatureHiddenInAllLocations: isOnlineSchedulingCustomizationFlagDisabledInAllLocations } =
    CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
      locationIds: selectedLocationIds,
      enabled: true,
      customizationFlag: Feature.ONLINE_SCHEDULING,
    });

  const { aggregateValue: autoMessageInboxVisibilityFF } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'automessage-inbox-visibility',
    aggregationStrategy: FeatureFlagQueries.AggregationStrategy.ANY,
    locationIds: selectedLocationIds,
  });
  const { aggregatedValue: autoMessageInboxVisibility } = MultiSettingsHooks.useBooleanClientSetting({
    set: ClientSettingsApi.querySetKeys.sets.autoMessageInboxVisibility,
    key: ClientSettingsApi.querySetKeys.keys.autoMessageInboxVisibility,
    defaultValue: 'true',
    locationIds: selectedLocationIds,
  });
  const avoidLastAutomated = autoMessageInboxVisibilityFF ? autoMessageInboxVisibility !== true : false;

  // Counts
  const inboxUnreadCount = InboxQueries.useUnreadCount({ avoidLastAutomated });
  const { data: faxUnreadCount } = FaxQueries.useCountFaxes(selectedLocationIds, {
    enabled: !!faxFeatureFlag && selectedLocationIds.length > 0,
  });
  const { data: faxDraftUnreadCount } = FaxDraftQueries.useGetDraftCount(
    selectedLocationIdsWithParents[0],
    selectedLocationIds,
    {
      enabled: !!faxFeatureFlag && selectedLocationIds.length > 0 && !!selectedLocationIdsWithParents[0],
    }
  );
  const voicemailsUnreadCount = PhoneCallsQueries.useVoicemailsUnreadCount();
  const { initialYear: initialBulkDraftYear } = useBulkDraftShallowStore('initialYear');
  const bulkDraftYear = initialBulkDraftYear ? Number(initialBulkDraftYear) : new Date().getFullYear();
  const hasBulkEmailAccess = emailMarketingAcl && bulkEmailFeatureFlag;
  const hasBulkTextAccess = bulkTextingAcl && bulkTextingFeatureFlag;
  const { count: bulkMessageDraftCount } = BulkMessagingQueries.useCombinedCampaignDrafts(
    {
      locationIds: selectedLocationIdsWithParents,
      orgId: selectedOrgId,
      year: bulkDraftYear,
    },
    {
      locationId: selectedLocationIdsWithParents[0],
      year: bulkDraftYear,
    },
    hasBulkEmailAccess,
    hasBulkTextAccess
  );
  const { data: smsDraftCount } = useListDraftsCountQuery({
    orgId: selectedOrgId,
    userId: user?.userID ?? '',
    locationId: selectedLocationIds[0],
    groupIds: selectedLocationIds,
  });

  const { hasAccountAlert: hasPaymentAccountAlert } = useMerchant();

  const formsSubmissionCount = FormsSubmission.useSubmissionsUnreadCount();

  return [
    {
      icon: (props) => <NavIcon {...props} name='home' />,
      label: t('Home'),
      root: '/home',
      subNavItems: [
        { label: t('My Dashboard'), relativePath: '/dashboard', trackingId: 'app-nav-item-dashboard' },
        { label: t('Portal'), path: '/portal', show: true, trackingId: 'app-nav-item-portal' },
        {
          label: t('Our Partners'),
          relativePath: '/partners',
          show: ourPartnersPage,
          trackingId: 'app-nav-item-our-partners',
        },
      ],
      show: true,
    },
    {
      hasCount: !!inboxUnreadCount,
      icon: (props) => <NavIcon {...props} name='inbox' />,
      label: t('Messages'),
      root: '/messages',
      subNavItems: [
        { count: inboxUnreadCount, label: t('Inbox'), relativePath: '/inbox' },
        { label: t('Scheduled'), relativePath: '/scheduled' },
        { count: smsDraftCount?.count, isPassiveCount: true, label: t('Drafts'), relativePath: '/drafts' },
        { label: t('Archived'), relativePath: '/archived' },
        { label: t('Blocked'), relativePath: '/blocked' },
      ],
      settings: {
        path: '/messaging',
        label: '',
      },
    },
    {
      hasCount: !!voicemailsUnreadCount,
      icon: (props) => <NavIcon {...props} name='phone' />,
      show: hasPhoneSystemAccess,
      root: '/calls',
      label: t('Calls'),
      subNavItems: [
        { label: t('Recent Calls'), relativePath: '/recent-calls' },
        { count: voicemailsUnreadCount, label: t('Voicemails'), relativePath: '/voicemails' },
        { label: t('Call Queue Data'), relativePath: '/call-queue-stats' },
      ],
      settings: {
        path: '/phone/main',
        label: '',
      },
    },
    {
      icon: (props) => <NavIcon {...props} name='calendar' />,
      label: t('Schedule'),
      root: '/schedule',
      subNavItems: [
        { label: t('Calendar'), relativePath: '/calendar' },
        {
          label: t('Request History'),
          relativePath: '/request-history',
          show: !isOnlineSchedulingCustomizationFlagDisabledInAllLocations,
        },
        { label: t('Quick Fill History'), relativePath: '/quickfill-history' },
      ],
      settings: {
        path: '/schedule',
        label: '',
      },
    },
    {
      hasCount: !!hasPaymentAccountAlert,
      icon: (props) => <NavIcon {...props} name='payments' />,
      label: t('Payments'),
      root: '/payments',
      show: paymentsFeatureFlag,
      subNavItems: [
        { label: t('Invoices'), relativePath: '/invoices' },
        { label: t('Refunds'), relativePath: '/refunds' },
        { label: t('Payouts'), relativePath: '/payouts' },
        { label: t('Buy Now, Pay-Over-Time'), relativePath: '/buy-now-pay-later' },
        { label: t('Online Bill Pay'), relativePath: '/online-bill-pay' },
        { label: t('Payment Plans'), relativePath: '/payment-plans' },
      ],
      settings: {
        path: '/payments',
        label: '',
      },
    },
    {
      hasCount: !!formsSubmissionCount.data?.count,
      icon: (props) => <NavIcon {...props} name='forms' />,
      label: t('Forms'),
      root: '/forms',
      subNavItems: [
        {
          count: formsSubmissionCount.data?.count,
          label: t('Submissions'),
          relativePath: '/submissions',
          trackingId: formsPendoTags.sideNav.submissions,
        },
        { label: t('My Forms'), relativePath: '/library', trackingId: formsPendoTags.sideNav.myForms },
        {
          label: t('Kiosk Mode'),
          relativePath: '/kiosk',
          trackingId: formsPendoTags.sideNav.kiosk,
        },
        { label: t('Templates'), relativePath: '/templates', trackingId: formsPendoTags.sideNav.templates },
        {
          label: t('Builder'),
          relativePath: '/builder/new',
          trackingId: formsPendoTags.sideNav.builder,
        },
      ],
      settings: {
        path: '/digital-forms',
        label: '',
        show: true,
      },
    },
    {
      icon: (props) => <NavIcon {...props} name='fax' />,
      label: t('Fax'),
      root: '/fax',
      show: faxFeatureFlag,
      hasCount: !!faxUnreadCount?.faxCount,
      subNavItems: [
        {
          count: faxUnreadCount?.faxCount,
          label: t('Fax Inbox'),
          relativePath: '/inbox',
          trackingId: `${FaxPrefixes.Nav}-inbox`,
        },
        {
          label: t('Drafts'),
          relativePath: '/drafts',
          count: faxDraftUnreadCount?.count,
          isPassiveCount: true,
          trackingId: `${FaxPrefixes.Nav}-drafts`,
        },

        ...(faxArchiveFeatureFlag
          ? [
              {
                label: t('Archived'),
                relativePath: '/archived',
                count: faxUnreadCount?.archivedFaxCount,
                isPassiveCount: true,
                trackingId: `${FaxPrefixes.Nav}-archived`,
              },
            ]
          : []),
        { label: t('Fax Contacts'), relativePath: '/contacts', trackingId: `${FaxPrefixes.Nav}-contacts` },
        ...(faxBlockFeatureFlag
          ? [
              {
                count: faxUnreadCount?.blockedFaxCount,
                isPassiveCount: true,
                label: t('Blocked'),
                relativePath: '/blocked',
                trackingId: `${FaxPrefixes.Nav}-blocked`,
              },
            ]
          : []),
        { label: t('Send New Fax'), relativePath: '/drafts/new', show: false },
        { label: t('Send From Template'), relativePath: '/drafts/new-from-template', show: false },
      ],
    },
    {
      icon: (props) => <NavIcon {...props} name='contacts' />,
      label: tokens.contacts,
      root: '/contacts',
      subNavItems: [
        { label: tokens.allContacts, relativePath: '/all-contacts', trackingId: 'contact-2.0-list-menu-all' },
        { label: t('Active'), relativePath: '/active', trackingId: 'contact-2.0-list-menu-active' },
        { label: t('Inactive'), relativePath: '/inactive', trackingId: 'contact-2.0-list-menu-inactive' },
        { label: t('Lists'), relativePath: '/lists', trackingId: 'contact-2.0-list-menu-lists' },
      ],
    },
    {
      icon: (props) => <NavIcon {...props} name='reviews-dash' />,
      label: t('Reviews'),
      root: '/reviews',
      subNavItems: [
        { label: t('All Reviews'), relativePath: '/all-reviews' },
        { label: t('Google'), relativePath: '/google' },
        { label: t('Facebook'), relativePath: '/facebook' },
        { label: t('Private'), relativePath: '/private' },
      ],
    },
    {
      icon: (props) => <NavIcon {...props} name='analytics' />,
      label: t('Analytics'),
      root: '/analytics',
      subNavItems: [
        { label: t('Overview'), relativePath: '/dashboard' },
        {
          label: t('Practice'),
          relativePath: '/practice',
          show: hasPracticeAnalyticsAccess,
        },
        {
          label: t('Call Intelligence'),
          relativePath: '/call-intelligence',
          show: hasCallIntelAccess,
        },
        {
          label: t('Auto Recall'),
          relativePath: '/auto-recall',
          show: isAutoRecallAnalyticsEnabled || showAutoRecallDemoData,
        },
        {
          label: t('Phone'),
          relativePath: '/phone',
          show: (analyticsAcl || phoneAnalyticsAcl) && phoneAnalytics,
        },
        {
          label: t('Messaging'),
          relativePath: '/messaging',
          show: (analyticsAcl || messagingAnalyticsAcl) && messagingAnalytics,
        },
        {
          label: t('Appointment'),
          relativePath: '/appointment',
          show: hasAppointmentAnalyticsAccess,
        },
      ],
    },
    {
      icon: (props) => <NavIcon {...props} name='bulk-messaging' />,
      label: t('Bulk Messaging'),
      root: '/bulk',
      show: hasBulkEmailAccess || hasBulkTextAccess,
      subNavItems: [
        { label: t('All Campaigns'), relativePath: '/all-campaigns/email' },
        { label: t('Drafts'), relativePath: '/drafts', count: bulkMessageDraftCount, isPassiveCount: true },
      ],
    },
  ] as const satisfies DeepReadonly<NavItem[]>;
};
