import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { Sort_Enum } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import { PMSPerson, PMSVertical, Pet } from '@weave/schema-gen-ts/dist/schemas/sms/writeback/v1/writeback_service.pb';
import { DepartmentsTypes } from '@frontend/api-departments';
import { SchemaSMSSharedModels } from '@frontend/api-messaging';
import { PersonTypes } from '@frontend/api-person';
import { SchemaIO, SchemaSMSWritebackService } from '@frontend/schema';
import { PickRequired } from '@frontend/types';

export enum BulkSelectionActionType {
  EXPORT_CONVERSATION = 'EXPORT_CONVERSATION',
  TEXT_WRITEBACK = 'TEXT_WRITEBACK',
}

export enum InboxStatus {
  UNREAD = 'new',
  UNREPLIED = 'unreplied',
  READ = 'read',
  REPLIED = 'replied',
  ERROR = 'error',
}
export type InboxFiltersV0 = {
  readStatus: 'read' | 'unread' | '';
  deliveryStatus: 'delivered' | 'undelivered' | '';
  repliedStatus: 'replied' | 'unreplied' | '';
  tags: string[];
  departments: string[];
};
export type InboxFilters = {
  statuses: InboxStatus[];
  tags: string[];
  departments: string[];
};
export type InboxFilter = keyof InboxFilters;

export type SelectedThreadInfo = {
  id?: string;
  departmentId?: string;
  person?: PersonTypes.Person;
  personId?: string;
  personPhone?: string;
};

export type PMSOption = {
  label: string;
  id: string;
  vertical: PMSVertical;
  personOptions: PMSPerson[];
  petOptions: Pet[];
};

export type WritebackPayloadMap = Record<string, WritebackPayload>;

export type WritebackPayload = Omit<
  SchemaIO<(typeof SchemaSMSWritebackService)['WritebackMessages']>['input'],
  'locationId' | 'weaveUserId' | 'smsIds'
>;

export enum InboxType {
  INBOX = 'inbox',
  ARCHIVED = 'archived',
  BLOCKED = 'blocked',
  SCHEDULED = 'scheduled',
  DRAFTS = 'drafts',
}

export enum SMSConversationStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  NEW = 'NEW',
}

export type ExtendedDepartment = DepartmentsTypes.SmsDepartmentModel & {
  threadStatus?: SMSConversationStatus;
  threadId?: string;
};

export enum LocalSendingStatus {
  SENDING = 'sending',
  SENT = 'sent',
  FAILED = 'failed',
}

/**
 * A wrapper for the Schema SMS type that makes all relevant properties for displaying an SMS required.
 * All other properties are optional.
 */
export type ThreadSMS = Omit<PickRequired<SchemaSMSSharedModels.SMS, 'id' | 'createdAt' | 'createdBy'>, 'media'> &
  Partial<{
    media: Partial<SchemaSMSSharedModels.Media>[];
  }>;

export type SentMessage = ThreadSMS & { localSendingStatus?: LocalSendingStatus };

export type ThreadCreationRoute = {
  filter: InboxType;
  personId?: string;
  personPhone?: string;
  departmentId?: string;
  groupId?: string;
  draftSort?: Sort_Enum;
};

export type InboxThreadRoute = {
  filter: InboxType;
  groupId: string;
  threadId: string;
} & InboxThreadSearch;

export type InboxThreadSearch = {
  personPhone?: string;
  personId?: string;
  isNew?: boolean;
  departmentId?: string;
  smsId?: string;
  smsCreatedAt?: string;
  click?: number;
  draftSort?: Sort_Enum;
  isArchived?: boolean;
};

export enum SearchContainer {
  Contacts = 'Contacts',
  Messages = 'Messages',
}

export type DraftsFilters = {
  [key: string]: unknown; // Once we have the filters, we can add the type here
};
export type DraftsStoreValueType = {
  sort: Sort_Enum;
  filters: DraftsFilters;
};

export type ScheduledFilters = {
  statuses: OutboundMessageStatus[];
  tags: string[];
  departments: string[];
};
export type ScheduledFilter = keyof ScheduledFilters;

export type AcceptableSortInboxTypes = InboxType.DRAFTS | InboxType.SCHEDULED;
