import { QueryKey } from 'react-query';

export const queryKeys = {
  base: ['contact-directories'] as const,
  getContactDirectories: (locationId: string): QueryKey => [locationId, ...queryKeys.base],
  getContacts: (locationId: string): QueryKey => [locationId, 'contacts', ...queryKeys.base],
  contactDirectoryById: (id: string, locationId: string) => [locationId, id, 'contact-directory', ...queryKeys.base],
  getContactsbyContactDirectoryId: (contactDirectoryId: string, locationId: string): QueryKey => [
    locationId,
    contactDirectoryId,
    'contacts',
    'by-contact-directory-id',
    ...queryKeys.base,
  ],
  getDevicesbyContactDirectoryId: (contactDirectoryId: string, locationId: string): QueryKey => [
    locationId,
    contactDirectoryId,
    'devices',
    'by-contact-directory-id',
    ...queryKeys.base,
  ],
  getContactDirectoriesByContactIds: (contactId: string[], locationId: string): QueryKey => [
    locationId,
    'contact-directories',
    'by-contact-id',
    contactId,
    ...queryKeys.base,
  ],
  getContactDirectoriesByDeviceIds: (deviceIds: string[], locationId: string): QueryKey => [
    locationId,
    'by-device-id',
    deviceIds,
    ...queryKeys.base,
  ],
};
