import { OpsType } from '@weave/schema-gen-ts/dist/shared/organization/ops_type.pb';
import { useTranslation } from '@frontend/i18n';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { BannerNotification } from '@frontend/design-system';

const MIN_WARN = 30;
const MAX_WARN = 57;
const MIN_ERROR = 58;
const MAX_ERROR = 60;

const getDaysUntilPauseMessage = (daysSinceLastActivity: number, payoutsEnabled: boolean) => {
  const { t } = useTranslation('payments');
  let message;
  if (daysSinceLastActivity <= MAX_ERROR && payoutsEnabled) {
    const daysUntilPause = MAX_ERROR - daysSinceLastActivity;
    let daysUntilPauseMessage;
    if (daysUntilPause < 1) {
      daysUntilPauseMessage = `today`;
    } else if (daysUntilPause === 1) {
      daysUntilPauseMessage = `by tomorrow`;
    } else {
      daysUntilPauseMessage = `in the next ${daysUntilPause} days`;
    }
    message = t(
      `You haven't processed a payment with Weave in the past ${daysSinceLastActivity} days. For security purposes, your Weave payments account for payouts will be automatically paused after 60 days of inactivity. Please process a payment with Weave ${daysUntilPauseMessage} to avoid interruption.`
    );
  } else if (daysSinceLastActivity >= MAX_ERROR && !payoutsEnabled) {
    message = t(
      `Your Weave payments account has been disabled for payouts due to inactivity. Call Weave at 888-579-5668 to reactivate and avoid further interruption.`
    );
  } else {
    message = t(
      `You haven't processed a payment with Weave in the past ${daysSinceLastActivity} days. If you recently re-enabled payouts, please process a payment to avoid further interruption.`
    );
  }
  return message;
};
const bannerSpacing = {
  marginBottom: theme.spacing(3),
};

export const MerchantAlertBanner = () => {
  const { merchant, daysSinceLastActivity, payoutsEnabled, locationId } = useMerchant();
  const { getLocationData } = useMultiQueryUtils();
  const { opsType } = getLocationData(locationId) ?? {};
  const isProdLocation = opsType === OpsType.REGULAR;

  const message = getDaysUntilPauseMessage(daysSinceLastActivity, payoutsEnabled);

  if (daysSinceLastActivity < MIN_WARN || !merchant || !isProdLocation) return;

  if (daysSinceLastActivity >= MIN_WARN && daysSinceLastActivity <= MAX_WARN)
    return <BannerNotification message={message} status='warn' css={bannerSpacing} />;

  if (daysSinceLastActivity > MAX_ERROR && payoutsEnabled)
    return <BannerNotification message={message} status='error' css={bannerSpacing} />;

  if (daysSinceLastActivity >= MIN_ERROR)
    return <BannerNotification message={message} status='error' css={bannerSpacing} />;

  return;
};
