import { createProvider } from '@frontend/store';
import { SectionTypes } from '../types';
import { genUID, generateFieldToAdd } from '../utils';
import { BuilderEngineState, BuilderEngineStore } from './types';

const DEFAULT_BUILDER_STATE: BuilderEngineState = {
  title: '',
  sectionOrder: [],
  sections: {},
  fields: {},
  conditions: [],
};

export const { Provider: BuilderEngineStoreProvider, useStore: useBuilderEngineStore } =
  createProvider<BuilderEngineStore>()((set, get) => ({
    ...DEFAULT_BUILDER_STATE,
    initialize: (initialState: BuilderEngineState) => set(() => initialState),

    reset: () => set(DEFAULT_BUILDER_STATE),

    // ============ Section Actions ============

    createSection: (title = '') =>
      set((state) => {
        const newSectionId = genUID();

        return {
          sectionOrder: [...state.sectionOrder, newSectionId],
          sections: {
            ...state.sections,
            [newSectionId]: {
              id: newSectionId,
              title,
              fields: [],
            },
          },
        };
      }),

    deleteSection: (sectionId, index) => {
      const removedPrimaryFieldKeys: string[] = [];
      let removedSectionTemplateKey = '';

      set((state) => {
        const newSectionOrder = index
          ? state.sectionOrder.splice(index, 1)
          : state.sectionOrder.filter((id) => id !== sectionId);

        const newSections = { ...state.sections };
        const newFields = { ...state.fields };
        const sectionToDelete = newSections[sectionId];

        if (sectionToDelete.sectionTemplateKey) {
          removedSectionTemplateKey = sectionToDelete.sectionTemplateKey;
        }

        sectionToDelete.fields.forEach((fieldId) => {
          const primaryFieldKey = state.fields[fieldId].meta.dataKey;

          if (primaryFieldKey) {
            // Collect primary field keys that were removed from the section.
            removedPrimaryFieldKeys.push(primaryFieldKey);
          }

          // Remove field from fields
          delete newFields[fieldId];
        });

        // Remove section
        delete newSections[sectionId];

        return {
          sectionOrder: newSectionOrder,
          sections: newSections,
          fields: newFields,
        };
      });

      return {
        removedPrimaryFieldKeys,
        removedSectionTemplateKey,
      };
    },

    updateSection: (section) =>
      set((state) => ({
        sections: {
          ...state.sections,
          [section.id]: section,
        },
      })),

    updateSectionTitle: (sectionId, title) =>
      set((state) => {
        const section = state.sections[sectionId];

        return {
          sections: {
            ...state.sections,
            [sectionId]: {
              ...section,
              title,
            },
          },
        };
      }),

    cloneSection: (sectionId: string) => {
      const newSectionId = genUID();

      set((state) => {
        const fieldIdsForNewSection: string[] = [];
        let newFields = { ...state.fields };
        const section = state.sections[sectionId];

        for (const fieldId of section.fields) {
          const field = state.fields[fieldId];

          if (field.meta.dataKey) {
            // We don't want to clone the primary fields.
            continue;
          }

          const newFieldId = genUID();
          fieldIdsForNewSection.push(newFieldId);

          newFields = {
            ...newFields,
            [newFieldId]: {
              ...field,
              id: newFieldId,
              condition_ids: [], // Conditions should not be copied.
            },
          };
        }

        const newSection: SectionTypes.Section = {
          id: newSectionId,
          fields: fieldIdsForNewSection,
          title: `${state.sections[sectionId].title} (copy)`,
        };
        const newSections = { ...state.sections, [newSectionId]: newSection };
        const newSectionOrder = [...state.sectionOrder, newSectionId];

        return {
          sectionOrder: newSectionOrder,
          sections: newSections,
        };
      });

      return newSectionId;
    },

    moveSection: (sectionId: string, direction: 'up' | 'down') =>
      set((state) => {
        const sectionOrder = [...state.sectionOrder];
        const currentIndex = sectionOrder.indexOf(sectionId);

        if (direction === 'up') {
          if (currentIndex === 0) {
            return state;
          }

          sectionOrder.splice(currentIndex, 1);
          sectionOrder.splice(currentIndex - 1, 0, sectionId);
        } else {
          if (currentIndex === sectionOrder.length - 1) {
            return state;
          }

          sectionOrder.splice(currentIndex, 1);
          sectionOrder.splice(currentIndex + 1, 0, sectionId);
        }

        return {
          sectionOrder,
        };
      }),

    isSectionCloneable: (sectionId: string) => {
      const section = get().sections[sectionId];
      return section.fields.length > 0;
    },

    addSectionTemplate: ({ fields, placementIndex, title, sectionTemplateKey }) => {
      const fieldsToAdd = fields.map(generateFieldToAdd);
      const newSectionId = genUID();
      const newSection: SectionTypes.Section = {
        id: newSectionId,
        fields: fieldsToAdd.map((field) => field.id),
        title,
        sectionTemplateKey,
      };

      set((state) => {
        const newSections = { ...state.sections, [newSectionId]: newSection };
        const newSectionOrder = [...state.sectionOrder];
        newSectionOrder.splice(placementIndex, 0, newSectionId);

        const newFields = fieldsToAdd.reduce(
          (acc, field) => {
            acc[field.id] = field;
            return acc;
          },
          { ...state.fields }
        );

        return {
          sectionOrder: newSectionOrder,
          sections: newSections,
          fields: newFields,
        };
      });

      return newSectionId;
    },

    // ============ Field Actions ============

    addField: ({ field, placementIndex, sectionId }) =>
      set((state) => {
        const section = state.sections[sectionId];
        const fieldIdsInSection = [...section.fields];
        const fieldToAdd = generateFieldToAdd(field);
        const newFieldId = fieldToAdd.id;
        fieldIdsInSection.splice(placementIndex, 0, newFieldId);

        return {
          sections: {
            ...state.sections,
            [sectionId]: {
              ...section,
              fields: fieldIdsInSection,
            },
          },
          fields: {
            ...state.fields,
            [newFieldId]: fieldToAdd,
          },
        };
      }),

    updateFieldTitle: (fieldId, title) =>
      set((state) => {
        const field = state.fields[fieldId];

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              label: title,
            },
          },
        };
      }),

    markFieldAsRequired: (fieldId, isRequired) =>
      set((state) => {
        const field = state.fields[fieldId];

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              required: isRequired,
            },
          },
        };
      }),
  }));
