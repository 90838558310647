import { FC, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import { MorningHuddleTypes, PracticeAnalyticsAggregations, PracticeAnalyticsApi } from '@frontend/api-analytics';
import { BarChartData, Chart, YAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { DemoChip } from '..';
import { usePracticeAnalyticsStore } from '../../hooks';
import { queryKeys } from '../../query-keys';
import { convertToDateId, formatters } from '../../utils';

type MonthProductionProps = {
  isDemoAccount?: boolean;
  reportDate: string;
};

const query = gql`
  query ($lmtdStart: Int!, $lmtdEnd: Int!, $mtdStart: Int!, $mtdEnd: Int!, $monthEnd: Int!) {
    location {
      id
      lmtdProduction: production {
        totals(start: $lmtdStart, end: $lmtdEnd, step: MONTHLY) {
          completedProduction
        }
      }
      mtdProduction: production {
        totals(start: $mtdStart, end: $mtdEnd, step: DAILY) {
          completedProduction
        }
        projections(start: $mtdEnd, end: $monthEnd, step: DAILY) {
          totalScheduled
        }
      }
    }
  }
`;

export const MonthProduction: FC<React.PropsWithChildren<MonthProductionProps>> = ({ isDemoAccount, reportDate }) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { demoData, filters } = usePracticeAnalyticsStore();

  const [productionData, setProductionData] = useState<
    { chartData: BarChartData; values: Record<string, number> } | undefined
  >();

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.morningHuddle(`monthProductionToDate-${isDemoAccount}-${reportDate}-${filters.locations}`),
    queryFn: () => {
      if (isDemoAccount || !filters.locations?.length) {
        return null;
      }

      const date = dayjs(reportDate);

      return PracticeAnalyticsApi.getPracticeAnalyticsRecords<MorningHuddleTypes.MonthProductionResponse>({
        locationIds: filters.locations,
        queries: [query],
        variables: {
          lmtdEnd: convertToDateId(date.subtract(1, 'months')),
          lmtdStart: convertToDateId(date.subtract(1, 'months').startOf('month')),
          monthEnd: convertToDateId(date.endOf('month')),
          mtdEnd: convertToDateId(date),
          mtdStart: convertToDateId(date.startOf('month')),
        },
      });
    },
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => (isDemoAccount ? demoData?.morningHuddleProductionMTD : data),
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    const aggregatedData = PracticeAnalyticsAggregations.morningHuddleMonthProduction(data);

    if (!aggregatedData) {
      return;
    }

    const { lmtdProduction, mtdProduction } = aggregatedData;
    const completed = mtdProduction.totals.completedProduction;
    const remaining = mtdProduction.projections.totalScheduled;
    const estimated = completed + remaining;
    const lastMonth = lmtdProduction.totals.completedProduction;

    setProductionData({
      chartData: {
        groups: [
          {
            name: 'completed',
            values: {
              completed: completed || 0,
            },
          },
          {
            name: 'remaining',
            values: {
              remaining: remaining || 0,
            },
          },
          {
            name: 'estimated',
            values: {
              estimated: estimated || 0,
            },
          },
        ],
        markers: [
          {
            dottedLine: true,
            formatter: formatters.currency.format,
            id: 'lastMonth',
            label: t('Last Month'),
            value: lastMonth,
          },
        ],
      },
      values: {
        completed,
        estimated,
        lastMonth,
        remaining,
      },
    });
  }, [data]);

  return (
    <Chart
      colors={{
        completed: theme.colors.primary40,
        estimated: theme.colors.neutral20,
        lastMonth: theme.colors.success30,
        remaining: theme.colors.primary20,
      }}
      css={styles.chartStyles}
      isLoading={isLoading}
      labels={{
        completed: t('MTD Completed'),
        estimated: t('Estimated Production'),
        remaining: t('Remaining Production'),
      }}
    >
      <Chart.Header
        expandable={false}
        leftElement={isDemoAccount ? <DemoChip /> : null}
        title={t('Production Month to Date')}
      />
      <Chart.BarChart
        appearance={{
          customYAxisTick: ({ labels, groupName, ...rest }) => (
            <YAxisLabelValueTick
              {...rest}
              doNotClip
              label={labels?.[groupName] || groupName}
              translateTo={70}
              value={formatters.currency.format(productionData?.values[groupName])}
            />
          ),
          groupsGap: 24,
          hideTooltip: true,
          layout: 'vertical',
          margin: { top: 20, right: 80, bottom: 0, left: 150 },
          showYAxis: true,
        }}
        data={productionData?.chartData}
      />
    </Chart>
  );
};

const styles = {
  chartStyles: css`
    flex-basis: calc(70% - ${theme.spacing(6)});
    flex-grow: 1;
    margin-bottom: 0;
    min-width: 400px;
  `,
};
