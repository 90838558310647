import { Heading, IconButton } from '@frontend/design-system';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import {
  DeviceMake_Enum,
  LineKey,
  ListDevice,
  ModelInfo,
} from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { EmptyStateLayout } from './empty-state';
import { LayoutKeyRender } from './layout-key-render';
import { LayoutNameRender } from './layout-name-render';
import { LineKeyPagination } from './pagination';
import { DEVICE_LAYOUT_WIDTH } from '../constants';
import { useTranslation } from '@frontend/i18n';
import { NavSize, useNavSize } from '@frontend/responsiveness';
import { Icon } from '@frontend/icons';

type Props = {
  device: ListDevice | undefined;
  previewOnly?: boolean;
  lineKeys: LineKey[] | undefined;
  model: ModelInfo | undefined;
  setIsMenuBarOpen?: (toggle: boolean) => void;
  setShowDeviceLayoutPreview?: Dispatch<SetStateAction<boolean>>;
};

export const DeviceLayout = ({
  device,
  lineKeys,
  model,
  previewOnly,
  setShowDeviceLayoutPreview,
  setIsMenuBarOpen,
}: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const paginationRef = useRef<HTMLDivElement>(null);
  const [pageNum, setPageNum] = useState(1);

  const extensionsCount = device?.extensions?.length ?? 1;

  const keysPerPage = (model?.lineKeys?.keysPerPageLeft ?? 0) + (model?.lineKeys?.keysPerPageRight ?? 0);
  const totalPages = ((lineKeys?.length ?? 0) / keysPerPage < 1 ? 1 : model?.lineKeys?.pages) ?? 1;

  const isPaginationEnabled = !!model?.lineKeys?.pages && model?.lineKeys?.pages > 1 && totalPages > 1;
  const navSize = useNavSize();
  const isMobile = navSize === NavSize.Mobile;

  useEffect(() => {
    if (!isPaginationEnabled) setPageNum(1);
  }, [isPaginationEnabled]);

  const { leftPageKeys, rightPageKeys } = useMemo(() => {
    /* We start at page 1 but when we determining the position of the line key index we start at index 0 */
    const currentPageIndex = pageNum - 1;
    const leftPageKeysCount = model?.lineKeys?.keysPerPageLeft ?? 0;
    const rightPageKeysCount = model?.lineKeys?.keysPerPageRight ?? 0;

    const currLeftStartingIndex = currentPageIndex * leftPageKeysCount * 2;
    const currRightStartingIndex = currentPageIndex * leftPageKeysCount * 2 + leftPageKeysCount;
    const currLeftEndingIndex = currLeftStartingIndex + leftPageKeysCount;
    const currRightEndingIndex = currRightStartingIndex + rightPageKeysCount;

    const leftKeys = new Array(currLeftEndingIndex - currLeftStartingIndex).fill(undefined);
    const rightKeys = new Array(currRightEndingIndex - currRightStartingIndex).fill(undefined);

    const leftPageKeys = leftKeys.map((_, i) => {
      const currentPosition = currLeftStartingIndex + i;
      const isAnExtension = extensionsCount >= currentPosition + 1;
      return isAnExtension ? device?.extensions?.[i].name : currentPosition - currentPageIndex;
    });

    const rightPageKeys = rightKeys.map((_, i) => {
      const currentPosition = currRightStartingIndex + i;
      const isAnExtension = extensionsCount >= currentPosition + 1;

      if (isPaginationEnabled && currentPosition === (model?.lineKeys?.paginationLineKey ?? 0) * pageNum - 1) {
        return `< Page ${pageNum} >`;
      }
      return isAnExtension ? device?.extensions?.[i].name : currentPosition - currentPageIndex;
    });
    return { leftPageKeys, rightPageKeys };
  }, [model, pageNum, extensionsCount, isPaginationEnabled]);

  return (
    <>
      {!model ? (
        <EmptyStateLayout />
      ) : (
        <section css={containerStyles({ previewOnly })}>
          {isMobile && (
            <IconButton onClick={() => setShowDeviceLayoutPreview?.(false)} showLabelAlways label={t('Back')}>
              <Icon name='back' />
            </IconButton>
          )}
          <Heading css={{ marginBottom: theme.spacing(3) }} level={2}>
            {previewOnly
              ? t('{{name}} Layout', { name: device?.name })
              : t('Preview {{name}} Layout', { name: device?.name })}
          </Heading>
          <article className={`${model?.make}-${model?.model}`} css={layoutStyles({ previewOnly, model, isMobile })}>
            <Heading as='div' className='device-model-layout-header' level={2} textAlign='center' color='white'>
              {model?.name}
            </Heading>
            <div
              css={css`
                display: flex;
              `}
            >
              {/* Left Side */}
              <ul className='line-key-left-side-keys'>
                <LayoutKeyRender
                  keys={leftPageKeys}
                  side='left'
                  preview={previewOnly}
                  lineKeys={lineKeys}
                  make={model.make}
                  setIsMenuBarOpen={setIsMenuBarOpen}
                />
              </ul>

              {/* Middle Section with names  */}
              <div className='line-key-middle-container'>
                <ul className='left-side-names'>
                  <LayoutNameRender keys={leftPageKeys} lineKeys={lineKeys} />
                </ul>
                <ul className='right-side-names'>
                  <LayoutNameRender keys={rightPageKeys} lineKeys={lineKeys} />
                </ul>
              </div>

              {/* Right Side  */}
              <ul className='line-key-right-side-keys'>
                <LayoutKeyRender
                  keys={rightPageKeys}
                  side='right'
                  preview={previewOnly}
                  lineKeys={lineKeys}
                  make={model.make}
                  setIsMenuBarOpen={setIsMenuBarOpen}
                />
              </ul>
            </div>
            <div className='device-model-layout-footer'></div>
          </article>
          <LineKeyPagination
            ref={paginationRef}
            setPageNum={setPageNum}
            totalPages={model.lineKeys?.pages}
            isPaginationEnabled={isPaginationEnabled}
            pageNum={pageNum}
          />
        </section>
      )}
    </>
  );
};

const containerStyles = ({ previewOnly }: { previewOnly?: boolean }) => css`
  position: relative;
  ${!previewOnly && `padding: ${theme.spacing(8, 3, 0)}`};
  flex: 1;
  width: 100%;
  overflow-x: auto;
`;

const layoutStyles = ({
  previewOnly,
  model,
  isMobile,
}: {
  previewOnly?: boolean;
  model?: ModelInfo;
  isMobile?: boolean;
}) => {
  const keyContainerWidth = theme.spacing(13.5);
  const lineKeyFontSize = theme.fontSize(20);
  const headerHeight = theme.spacing(11);
  const lineKeyHeight = theme.spacing(5);

  return css`
    background: ${theme.colors.neutral80};
    ${!previewOnly && `max-width: ${DEVICE_LAYOUT_WIDTH}`};
    height: max-content;
    border-radius: ${theme.spacing(2)};
    border: 2px solid ${theme.colors.neutral80};
    width: 100%;

    .device-model-layout-header {
      font-size: ${lineKeyFontSize};
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .device-model-layout-header,
    .device-model-layout-footer {
      height: ${headerHeight};
    }

    ul {
      padding: 0;
    }

    .line-key-left-side-keys,
    .line-key-right-side-keys,
    .line-key-middle-container {
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${lineKeyHeight};
        margin-bottom: ${theme.spacing(3)};

        span {
          font-weight: bold;
        }

        &:first-of-type {
          margin-top: ${theme.spacing(3)};
        }
      }
    }

    .line-key-left-side-keys {
      ${model?.make === DeviceMake_Enum.POLYCOM &&
      `li {
        margin-right: ${theme.spacing(2)};
        &:nth-child(odd) {
          margin-right: ${theme.spacing(4)};
        }
      }`}
    }

    .line-key-right-side-keys {
      ${model?.make === DeviceMake_Enum.POLYCOM &&
      `li {
          margin-left: ${theme.spacing(2)};
          &:nth-child(odd) {
            margin-left: ${theme.spacing(4)};
          }
        }`}
    }

    .line-key-left-side-keys,
    .line-key-right-side-keys {
      width: ${keyContainerWidth};
    }

    .line-key-middle-container {
      display: flex;
      width: 100%;
      background: ${theme.colors.white};
      border-radius: ${theme.spacing(2)};
      width: 100%;
      flex: 1;
      padding: ${theme.spacing(0, 2)};
      min-width: 300px;

      .left-side-names,
      .right-side-names {
        flex: 1;
      }

      li {
        span {
          font-size: ${lineKeyFontSize};
        }
        span.line-key-placeholder {
          color: ${theme.colors.neutral30};
        }
      }

      .right-side-names {
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;

        li {
          justify-content: end;
          text-align: right;
        }
      }

      .left-side-names {
        li {
          justify-content: start;
          text-align: left;
        }
      }
    }

    ${isMobile &&
    `
        width: 100%;
        min-width: 450px;

        .line-key-middle-container {
          min-width: 200px;
          padding: ${theme.spacing(0, 1)};
        }

        .line-key-left-side-keys,
        .line-key-right-side-keys {
          width: ${theme.spacing(9)};

          li {
            width: ${theme.spacing(6)};
          }
        }

      `}
  `;
};
