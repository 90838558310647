import { FC, useMemo } from 'react';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import {
  ActivePatientsUnscheduledTable,
  SummaryMetric,
  UnscheduledPatientsMetricMulti,
  UnscheduledPatientsMetricSingle,
} from '..';
import { InlineStats } from '../..';
import { usePracticeAnalyticsStore } from '../../../hooks';
import { queryKeys } from '../../../query-keys';
import { formatters } from '../../../utils';

const query = gql`
  query {
    location {
      activePatients {
        total
      }
      activePatientsScheduled {
        percentage
      }
      unscheduledHygienePatients: unscheduledPatients(type: HYGIENE, overdueOnly: false) {
        totals {
          patients
        }
        buckets {
          label
          total
          persons {
            id
            FirstName: firstName
            LastName: lastName
            PMID: pmid
            MobilePhone: mobilePhone
            HomePhone: homePhone
            WorkPhone: workPhone
            Email: email
            PreferredName: preferredName
            Birthdate: birthdate
            Gender: gender
            dueDate
            lastHygieneDate
            lastVisitDate
            isPerio
          }
        }
      }
      unscheduledNonhygienePatients: unscheduledPatients(type: NONHYGIENE, overdueOnly: false) {
        totals {
          patients
        }
        buckets {
          label
          total
          persons {
            id
            FirstName: firstName
            LastName: lastName
            PMID: pmid
            MobilePhone: mobilePhone
            HomePhone: homePhone
            WorkPhone: workPhone
            Email: email
            PreferredName: preferredName
            Birthdate: birthdate
            Gender: gender
            dueDate
            lastVisitDate
            isPerio
          }
        }
      }
    }
  }
`;

const hyginePatientsColors: Record<string, string> = {
  'not due': theme.colors.success20,
  '0-3': theme.colors.primary30,
  '3-6': theme.colors.secondary.eggplant50,
  '6-12': theme.colors.warning50,
  '12+': theme.colors.critical30,
};

const nonHyginePatientsColors: Record<string, string> = {
  '0-6': theme.colors.success20,
  '6-9': theme.colors.primary30,
  '9-12': theme.colors.secondary.eggplant50,
  '12-18': theme.colors.warning50,
  '18+': theme.colors.critical30,
};

export const ActivePatientsUnscheduledSubView: FC<
  React.PropsWithChildren<PracticeAnalyticsTypes.MetricSubViewProps>
> = (props) => {
  const { exportEnabled } = props;
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount } = usePracticeAnalyticsStore();

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

  const hyginePatientsLabels: Record<string, string> = useMemo(
    () => ({
      'not due': t('Not Due'),
      '0-3': t('0-3 Overdue'),
      '3-6': t('3-6 Overdue'),
      '6-12': t('6-12 Overdue'),
      '12+': t('12+ Overdue'),
    }),
    []
  );

  const nonHyginePatientsLabels: Record<string, string> = useMemo(
    () => ({
      '0-6': t('0-6 Overdue'),
      '6-9': t('6-9 Overdue'),
      '9-12': t('9-12 Overdue'),
      '12-18': t('12-18 Overdue'),
      '18+': t('18+ Overdue'),
    }),
    []
  );

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(
      `summaryMetricActivePatientsScheduledDemographics-${isDemoAccount}-${JSON.stringify(filters.locations)}`
    ),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.ActivePatientsDemographicsScheduledResponse>(
            {
              locationIds: filters.locations,
              queries: [query],
            }
          ),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    select: (data) => (isDemoAccount ? demoData?.activePatientsDemographicsScheduled : data),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const processedData = useMemo(() => {
    const updatedData = PracticeAnalyticsAggregations.activePatientsScheduledDetails(data, filters.locations?.length);
    const { activePatients, activePatientsScheduled } = updatedData.aggregatedData || {};

    return {
      data: updatedData,
      quickStats: [
        {
          label: t('Active Patients'),
          value: formatters.value.format(activePatients?.total),
        },
        {
          label: t('Scheduled Percentage'),
          value: formatters.percent.format(activePatientsScheduled?.percentage),
        },
      ],
    };
  }, [data?.data]);

  const Component = multipleLocationsSelected ? UnscheduledPatientsMetricMulti : UnscheduledPatientsMetricSingle;

  return (
    <>
      <InlineStats data={processedData.quickStats} isDemoAccount={isDemoAccount} />

      <SummaryMetric clickNoop hideActivePatientsMetric hideActivePatientsScheduledMetric isDrillDownPage />

      <Component
        {...props}
        colors={{
          hygienePatients: hyginePatientsColors,
          nonHygienePatients: nonHyginePatientsColors,
        }}
        data={processedData.data}
        isLoading={isLoading}
        labels={{
          hygienePatients: hyginePatientsLabels,
          nonHygienePatients: nonHyginePatientsLabels,
        }}
      />

      <ActivePatientsUnscheduledTable data={processedData.data} isExportEnabled={exportEnabled} isLoading={isLoading} />
    </>
  );
};
