import { useEffect, useMemo } from 'react';
import { useLocation } from '@tanstack/react-location';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart, FormatValue, YAxisLabelValueTick, commonChartsStyles } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text, useAlert } from '@frontend/design-system';
import { DemoChip, LocationChip } from '..';
import { useAnalyticsOrgLocations, usePaNavigate, usePracticeAnalyticsStore } from '../../hooks';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { formatters } from '../../utils';
import { CompareLocationsButton } from '../compare-locations-button';
import {
  calcAverageIndustryPercentChange,
  getIndustryAverageComparisonText,
  getPracticeAnalyticsQueryVariables,
} from './helpers';
import { monthlyTrendChartModal } from './monthly-trend-chart-modal';
import { PracticeAnalyticsInfoTips } from '.';

const query = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      hygieneTreatmentPlan: treatmentPlan(treatmentPlanType: HYGIENE) {
        benchmarks {
          label
          value
        }
        totals(start: $start, end: $end, step: $step) {
          accepted
          acceptedPercent
          diagnosed
          diagnosedPercent
          qualifiedVisits
          unscheduledTreatment
        }
      }
      industryAvg {
        totals(start: $start, end: $end, step: $step) {
          totalAcceptedHygiene
          totalDiagnosedHygiene
          totalUnscheduledTreatmentHygiene
        }
      }
    }
  }
`;

type IndustryAveragePercentChange = {
  patientsAccepted?: number;
  patientsDiaglosed?: number;
  unscheduledProduction?: number;
};

type ChartDataState = {
  avgAccepted: number;
  avgUnscheduledProduction: number;
  charts: {
    aggregated?: {
      patientsAccepted?: BarChartData;
      patientsDiaglosed?: BarChartData;
      unscheduledProduction?: BarChartData;
    };
    categorized?: {
      [groupName: string]: {
        [barId: string]: number;
      };
    };
  };
  industryAveragePercentChange: IndustryAveragePercentChange;
  patientsAccepted: number;
  patientsAcceptedPercent: number;
  patientsDiaglosed: number;
  patientsDiaglosedPercent: number;
  topAccepted: number;
  topDiagnosed: number;
  unscheduledProduction: number;
};

const colors = {
  activePractice: theme.colors.warning50,
  avgPractice: theme.colors.primary20,
  patientsAccepted: theme.colors.warning50,
  patientsDiagnosed: theme.colors.warning50,
  topPractice: theme.colors.secondary.seaweed30,
  unscheduledProduction: theme.colors.warning50,
};

export const HygieneTreatmentMetric = ({
  clickNoop,
  isDrillDownPage,
  onFetchStateChange,
}: PracticeAnalyticsTypes.MetricProps) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const navigate = usePaNavigate();
  const { current } = useLocation();
  const { demoData, filters, isDemoAccount } = usePracticeAnalyticsStore();
  const { locationNames } = useAnalyticsOrgLocations({ module: 'PA' });

  const monthlyTrendModal = monthlyTrendChartModal({
    defaultKey: 'diagnosedPercent',
    metric: 'hygieneTreatmentPlan',
    tabs: { diagnosedPercent: t('Diagnosed %'), acceptedPercent: t('Accepted %') },
    title: t('Trend: Hygiene Treatment Plan'),
    trackingId: trackingIds.practiceAnalytics.hygieneTreatmentPlanTrend,
  });

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;
  const showMultiComparison = isDrillDownPage && multipleLocationsSelected;

  const labels = useMemo(
    () => ({
      activePractice: multipleLocationsSelected ? t('Your Avg Practice') : t('Your Practice'),
      avgPractice: t('Average Practice'),
      patientsAccepted: t('Patients Accepted'),
      patientsDiagnosed: t('Patients Diagnosed'),
      topPractice: t('Top Practice'),
      unscheduledProduction: t('Unscheduled Revenue'),
    }),
    [multipleLocationsSelected]
  );

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(`hygieneTreatmentSummary-${isDemoAccount}-${JSON.stringify(filters)}`),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.HygieneTreatmentPlanResponse>({
            locationIds: filters.locations,
            queries: [query],
            variables: getPracticeAnalyticsQueryVariables(filters),
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    select: (data) => (isDemoAccount ? demoData?.hygieneTreatmentPlan : data),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const renderChart = (chartName: string, data?: BarChartData, formatValue?: FormatValue) => (
    <div css={commonChartsStyles.centerPosition}>
      <Chart.BarChart
        appearance={{
          barsGap: 24,
          collectiveTooltip: true,
          width: 200,
        }}
        customLegendsIds={['activePractice', 'avgPractice', 'topPractice']}
        data={data}
        formatValue={formatValue}
      />
      <Text color='subdued' size='medium' textAlign='center' style={{ marginTop: theme.spacing(1) }}>
        {chartName}
      </Text>
    </div>
  );

  const chartData: ChartDataState | null = useMemo(() => {
    const { aggregatedData, rawData } = PracticeAnalyticsAggregations.hygieneTreatmentPlanSummary(data);

    if (!aggregatedData) {
      return null;
    }

    const { hygieneTreatmentPlan, industryAvg } = aggregatedData;
    const averageWeaveAccepted = Math.round(
      ((industryAvg?.totals?.totalAcceptedHygiene || 0) / (industryAvg?.totals?.totalDiagnosedHygiene || 1)) * 100
    );

    const topAccepted =
      (hygieneTreatmentPlan.benchmarks?.find(({ label }) => label === 'Top Accepted')?.value || 0) * 100;
    const topDiagnosed =
      (hygieneTreatmentPlan.benchmarks?.find(({ label }) => label === 'Top Diagnosed')?.value || 0) * 100;

    const processedData: ChartDataState = {
      avgAccepted: averageWeaveAccepted,
      avgUnscheduledProduction: industryAvg?.totals?.totalUnscheduledTreatmentHygiene || 0,
      charts: {},
      industryAveragePercentChange: {
        patientsAccepted: Math.round((hygieneTreatmentPlan?.totals?.acceptedPercent || 0) * 100) - averageWeaveAccepted,
        patientsDiaglosed: calcAverageIndustryPercentChange({
          industryAverage: industryAvg?.totals.totalDiagnosedHygiene,
          practiceValue: hygieneTreatmentPlan?.totals?.diagnosed,
        }),
        unscheduledProduction: calcAverageIndustryPercentChange({
          industryAverage: industryAvg?.totals.totalUnscheduledTreatmentHygiene,
          practiceValue: hygieneTreatmentPlan?.totals?.unscheduledTreatment,
        }),
      },
      patientsAccepted: hygieneTreatmentPlan?.totals?.accepted || 0,
      patientsAcceptedPercent: hygieneTreatmentPlan?.totals?.acceptedPercent || 0,
      patientsDiaglosed: hygieneTreatmentPlan?.totals?.diagnosed || 0,
      patientsDiaglosedPercent: hygieneTreatmentPlan?.totals?.diagnosedPercent || 0,
      topAccepted,
      topDiagnosed,
      unscheduledProduction: hygieneTreatmentPlan?.totals?.unscheduledTreatment || 0,
    };

    if (showMultiComparison) {
      processedData.charts.categorized = Object.entries(rawData || {}).reduce((acc, [locationId, locationDetails]) => {
        acc![locationNames[locationId] || locationId] = {
          patientsDiagnosed: (locationDetails?.location.hygieneTreatmentPlan?.totals?.diagnosedPercent || 0) * 100,
          patientsAccepted: (locationDetails?.location.hygieneTreatmentPlan?.totals?.acceptedPercent || 0) * 100,
          unscheduledProduction: locationDetails?.location.hygieneTreatmentPlan?.totals?.unscheduledTreatment || 0,
        };
        return acc;
      }, {} as ChartDataState['charts']['categorized']);
    } else {
      processedData.charts.aggregated = {
        patientsDiaglosed: {
          groups: [
            {
              name: t('Patients Diagnosed'),
              values: {
                activePractice: (hygieneTreatmentPlan?.totals?.diagnosedPercent || 0) * 100,
                topPractice: topDiagnosed,
              },
            },
          ],
          markers: [
            {
              id: 'topPractice',
              value: topDiagnosed,
            },
          ],
        },
        patientsAccepted: {
          groups: [
            {
              name: t('Patients Accepted'),
              values: {
                activePractice: (hygieneTreatmentPlan?.totals?.acceptedPercent || 0) * 100,
                avgPractice: averageWeaveAccepted,
                topPractice: topAccepted,
              },
            },
          ],
          markers: [
            {
              id: 'topPractice',
              value: topAccepted,
            },
            {
              id: 'avgPractice',
              value: averageWeaveAccepted,
            },
          ],
        },
        unscheduledProduction: {
          groups: [
            {
              name: t('Unscheduled Revenue'),
              values: {
                activePractice: hygieneTreatmentPlan?.totals?.unscheduledTreatment || 0,
                avgPractice: industryAvg?.totals?.totalUnscheduledTreatmentHygiene || 0,
              },
            },
          ],
          markers: [
            {
              id: 'avgPractice',
              value: industryAvg?.totals?.totalUnscheduledTreatmentHygiene || 0,
            },
          ],
        },
      };
    }

    return processedData;
  }, [locationNames, data?.data]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <>
      <Chart
        colors={colors}
        isLoading={isLoading}
        labels={labels}
        onClick={
          clickNoop
            ? undefined
            : () => {
                navigate({
                  to: `${current.pathname}/hygiene-treatment`,
                });
              }
        }
        trackingId={trackingIds.practiceAnalytics.hygieneTreatmentPlanChart}
      >
        {isDrillDownPage ? (
          isDemoAccount && <DemoChip />
        ) : (
          <>
            <Chart.Header
              actions={[monthlyTrendModal.triggerProps]}
              bottomElement={
                multipleLocationsSelected ? <CompareLocationsButton style={{ marginBottom: theme.spacing(1) }} /> : null
              }
              infoTip={<PracticeAnalyticsInfoTips tip='hygieneTreatmentPlan' />}
              leftElement={isDemoAccount ? <DemoChip /> : null}
              title={t('Hygiene Treatment Plan')}
            />
            <Chart.SneakPeakBar
              data={[
                {
                  key: 'patientsDiaglosed',
                  name: t('Patients Diagnosed'),
                  value: formatters.percent.format(chartData?.patientsDiaglosedPercent || 0),
                },
                {
                  key: 'patientsAccepted',
                  name: t('Patients Accepted'),
                  omitSubtext: multipleLocationsSelected,
                  subtext: getIndustryAverageComparisonText(chartData?.industryAveragePercentChange?.patientsAccepted),
                  value: formatters.percent.format(chartData?.patientsAcceptedPercent || 0),
                },
                {
                  key: 'unscheduledProduction',
                  name: t('Unscheduled Treatment'),
                  omitSubtext: multipleLocationsSelected,
                  subtext: getIndustryAverageComparisonText(
                    chartData?.industryAveragePercentChange?.unscheduledProduction
                  ),
                  value: formatters.currency.format(chartData?.unscheduledProduction || 0),
                },
              ]}
            />
          </>
        )}

        {showMultiComparison ? (
          <>
            <Chart.Legends
              customData={{
                activePractice: {
                  label: t('Your Practice'),
                },
                avgPractice: {
                  label: t('Average Practice'),
                },
                topPractice: {
                  label: t('Top Practice'),
                },
              }}
              style={{ marginBottom: theme.spacing(2) }}
            />

            <Chart.CategoryBarChart
              appearance={{
                customTooltipTitle: ({ groupName }) => <LocationChip locationName={groupName} noTruncating />,
                customYAxisTick: ({ labels, groupName, ...rest }) => {
                  return <YAxisLabelValueTick {...rest} clipLength={25} label={labels?.[groupName] || groupName} />;
                },
                formatters: {
                  patientsAccepted: formatters.percent.appendPercent,
                  patientsDiagnosed: formatters.percent.appendPercent,
                  unscheduledProduction: formatters.currency.format,
                },
                maxValues: {
                  patientsAccepted: 100,
                  patientsDiagnosed: 100,
                },
                xAxisTickFormatters: {
                  patientsAccepted: formatters.percent.appendPercent,
                  patientsDiagnosed: formatters.percent.appendPercent,
                  unscheduledProduction: (value) => `$${formatters.value.shortenNumber(value)}`,
                },
              }}
              data={chartData?.charts.categorized || {}}
              markers={{
                patientsDiagnosed: [
                  {
                    color: theme.colors.primary40,
                    id: 'avgPractice',
                    value: chartData?.topDiagnosed || 0,
                  },
                ],
                patientsAccepted: [
                  {
                    id: 'topPractice',
                    value: chartData?.topAccepted || 0,
                  },
                  {
                    color: theme.colors.primary40,
                    id: 'avgPractice',
                    value: chartData?.avgAccepted || 0,
                  },
                ],
                unscheduledProduction: [
                  {
                    color: theme.colors.primary40,
                    id: 'avgPractice',
                    value: chartData?.avgUnscheduledProduction || 0,
                  },
                ],
              }}
            />
          </>
        ) : (
          <>
            {isDrillDownPage && <Chart.Legends style={{ marginTop: theme.spacing(1) }} />}

            <Chart.HorizontalContainer marginBottom={0}>
              {renderChart(
                t('Patients Diagnosed'),
                chartData?.charts.aggregated?.patientsDiaglosed,
                formatters.percent.appendPercent
              )}

              {renderChart(
                t('Patients Accepted'),
                chartData?.charts.aggregated?.patientsAccepted,
                formatters.percent.appendPercent
              )}

              {renderChart(
                t('Unscheduled Treatment'),
                chartData?.charts.aggregated?.unscheduledProduction,
                formatters.currency.format
              )}
            </Chart.HorizontalContainer>

            {!isDrillDownPage && <Chart.Legends style={{ marginTop: theme.spacing(1) }} />}
          </>
        )}
      </Chart>
      {monthlyTrendModal.modalRenderer()}
    </>
  );
};
