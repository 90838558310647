import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { ThreadListVirtuosoContext } from '../types';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { MessageBubbleRowWrapper, ScheduledMessageBubble } from '../message-bubble-row';

const HIDDEN_STATUSES: readonly OutboundMessageStatus[] = [
  OutboundMessageStatus.DELETED,
  OutboundMessageStatus.SENT,
  OutboundMessageStatus.DELIVERED,
];

type ScheduledSmsVirtuosoItemProps = {
  message: ScheduledSms;
  context: ThreadListVirtuosoContext;
};

export const ScheduledSmsVirtuosoItem = ({
  message,
  context: { optedOut, setScheduledMessageForEdit, mediaQueries },
}: ScheduledSmsVirtuosoItemProps) => {
  if (!!message.deletedAt || HIDDEN_STATUSES.includes(message.status)) return null;

  return (
    <MessageBubbleRowWrapper key={message.id} id={message.id} disableSelection optedOut={optedOut}>
      <ScheduledMessageBubble
        message={message}
        mediaQueries={mediaQueries}
        onEdit={setScheduledMessageForEdit ? () => setScheduledMessageForEdit(message) : undefined}
      />
    </MessageBubbleRowWrapper>
  );
};
