type AppointmentOpeningTimeParamsType = {
  apptTypeId: string;
  weekInView: string;
  locationId: string;
  providerId?: string;
};
export const scheduleBookingQueryKeys = {
  getAppointmentOpeningsKeys: ({
    apptTypeId,
    weekInView,
    locationId,
    providerId = 'any',
  }: AppointmentOpeningTimeParamsType) => ['appointmentOpeningsTime', providerId, apptTypeId, weekInView, locationId],
};
