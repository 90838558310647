import {
  ContactDirectoriesMutation,
  ContactDirectoriesQuery,
  ContactDirectoriesQueryKeys,
} from '@frontend/api-contact-directories';
import {
  ContentLoader,
  EditSimpleIcon,
  ModalControlResponse,
  PlusIcon,
  Table,
  TrashIcon,
  UserMinusIcon,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { formatPhoneNumber, removeNonDigits } from '@frontend/phone-numbers';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { CreateNewDirectory } from '../side-modals/create-new-directory-modal';
import { TrayContainer } from '../side-modals';
import type { SelectedContactsType } from '../types';
import { DeleteContactModal, EditContactModal, RemoveContactFromDirectory } from '../modals';

type Props = {
  newContactModalControls: ModalControlResponse;
  addExistingContactControls: ModalControlResponse;
  id: string;
  contactDirectoryName?: string;
};

export const ContactDirectoryTable = ({
  newContactModalControls,
  addExistingContactControls,
  id: contactDirectoryId,
  contactDirectoryName,
}: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const locationId = locationIds?.[0] ?? '';
  const [filteredGlobalData, setFilteredGlobalData] = useState<Contact[] | null>(null);
  const queryClient = useQueryClient();
  const alerts = useAlert();

  const [selectedContacts, setSelectedContacts] = useState<SelectedContactsType>({
    contacts: [],
    contactNumber: '',
  });

  const { modalProps: deleteContactModalProps, triggerProps: deleteContactTriggerProps } = useModalControl();
  const { modalProps: editContactModalProps, triggerProps: editContactTriggerProps } = useModalControl();
  const { modalProps: createNewDirectoryModalProps, triggerProps: createNewDirectoryTriggerProps } = useModalControl();
  const { modalProps: removeFromDirectoryModalProps, triggerProps: removeFromDirectoryTriggerProps } =
    useModalControl();

  const { useGetContactDirectoryContacts, useGetContactDirectoryDevices } =
    ContactDirectoriesQuery.useContactDirectoryQueries(locationId);

  const { data: { contacts, contactIds } = { contacts: [], contactIds: [] }, isRefetching: isContactsRefetching } =
    useGetContactDirectoryContacts({
      contactListId: contactDirectoryId,
    });

  const { data: devices, isLoading: isDevicesLoading } = useGetContactDirectoryDevices({
    contactListId: contactDirectoryId,
  });

  const { useReplaceContactDirectoryContactsMutation } =
    ContactDirectoriesMutation.useContactDirectoryMutations(locationId);

  const { mutateAsync: replaceContacts } = useReplaceContactDirectoryContactsMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(
        ContactDirectoriesQueryKeys.queryKeys.getContactsbyContactDirectoryId(contactDirectoryId, locationId)
      );
      removeFromDirectoryModalProps.onClose();
    },
  });

  const isLoading = isContactsRefetching || isDevicesLoading;

  return (
    <>
      {isLoading ? (
        <ContentLoader show />
      ) : (
        <Table
          data={filteredGlobalData ?? contacts ?? ([] as typeof contacts)}
          uniqueRowId={({ id }) => id}
          fullHeight
          fullHeightConfig={{
            offset: 25,
          }}
          isPaginated
          clientPaginationConfig={{
            defaultRowsPerPage: 25,
          }}
          colConfig={[
            {
              Header: t('Name'),
              id: 'contact-list-name',
              accessor: (cell) => cell.name,
            },
            {
              Header: t('Phone Number'),
              id: 'contact-list-phone-number',
              accessor: (cell) => cell.number,
              cellRenderer: (number) => formatPhoneNumber(number),
            },
          ]}
          emptyStateConfig={{
            type: 'contact_directories',
            header: t('No Contacts'),
            description: t('Add contacts to this list and assign this list to your devices'),
            action: {
              label: t('Add Contact'),
              actions: [
                {
                  label: t('Add New Contact'),
                  onClick: newContactModalControls.openModal,
                },
                {
                  label: t('Add Existing Contact'),
                  onClick: addExistingContactControls.openModal,
                },
              ],
            },
          }}
          hasGlobalSearch
          globalSearchConfig={{
            label: t('Search Name or Phone Number'),
            searchHandler: (term) => {
              const contactsFiltered = contacts?.filter(
                (data) =>
                  data.name?.toLocaleLowerCase().includes(term.toLocaleLowerCase()) ||
                  removeNonDigits(data.number).includes(term)
              );
              setFilteredGlobalData(contactsFiltered ?? null);
            },
          }}
          rowActions={{
            actions: [
              {
                label: t('Edit Contact'),
                Icon: EditSimpleIcon,
                onClick: (contact) => {
                  setSelectedContacts({
                    contacts: [contact],
                    contactNumber: contact.number,
                  });
                  editContactTriggerProps.onClick();
                },
              },
              {
                label: t('Remove from List'),
                Icon: UserMinusIcon,
                onClick: (contact) => {
                  if (!devices?.deviceIds?.length) {
                    const filteredContactIds = contactIds.filter((contactId) => contactId !== contact.id);
                    replaceContacts({ contactListId: contactDirectoryId, contactIds: filteredContactIds }).then(() => {
                      alerts.success(
                        t('Successfully removed {{count}} contacts from list', { count: filteredContactIds.length })
                      );
                    });
                  } else {
                    setSelectedContacts({
                      contacts: [contact],
                    });
                    removeFromDirectoryTriggerProps.onClick();
                  }
                },
              },
              {
                label: t('Delete Contact'),
                Icon: TrashIcon,
                onClick: (contact) => {
                  setSelectedContacts({
                    contacts: [contact],
                  });
                  deleteContactTriggerProps.onClick();
                },
                destructive: true,
              },
            ],
          }}
          isSelectable
          rowSelectionConfig={{
            onSelectionToggle: (_, contacts) => {
              setSelectedContacts({
                contacts,
              });
            },
          }}
          bulkActions={[
            {
              Icon: PlusIcon,
              onClick: (_, contacts) => {
                setSelectedContacts({
                  contacts,
                });
                createNewDirectoryTriggerProps.onClick();
              },
              label: t('Add to New List'),
            },
            {
              Icon: UserMinusIcon,
              onClick: (_, contacts) => {
                const idsSelected = contacts.map(({ id }) => id);
                if (!devices?.deviceIds?.length) {
                  const filteredContactIds: string[] = idsSelected.filter(
                    (contactId) => !idsSelected.includes(contactId)
                  );
                  return replaceContacts({ contactListId: contactDirectoryId, contactIds: filteredContactIds }).then(
                    () => {
                      alerts.success(
                        t('Successfully removed {{count}} contacts from list', { count: idsSelected.length })
                      );
                    }
                  );
                }
                setSelectedContacts({
                  contacts,
                });
                return removeFromDirectoryTriggerProps.onClick();
              },
              label: t('Remove from List'),
            },
            {
              Icon: TrashIcon,
              onClick: (_, contacts) => {
                setSelectedContacts({
                  contacts,
                });
                deleteContactTriggerProps.onClick();
              },
              label: t('Delete Contacts'),
              destructive: true,
            },
          ]}
        />
      )}
      <EditContactModal
        selectedContacts={selectedContacts}
        contactDirectoryId={contactDirectoryId}
        contacts={contacts}
        {...editContactModalProps}
      />
      <RemoveContactFromDirectory
        contactDirectoryName={contactDirectoryName ?? ''}
        devices={devices?.devices}
        contactDirectoryId={contactDirectoryId}
        contactIds={contactIds}
        selectedContacts={selectedContacts}
        onSave={replaceContacts}
        {...removeFromDirectoryModalProps}
      />
      {deleteContactModalProps.show && (
        <DeleteContactModal
          selectedContacts={selectedContacts}
          contactDirectoryId={contactDirectoryId}
          {...deleteContactModalProps}
        />
      )}
      <TrayContainer modalProps={createNewDirectoryModalProps}>
        <CreateNewDirectory {...createNewDirectoryModalProps} assignableContacts={contacts} />
      </TrayContainer>
    </>
  );
};
