import { InstructionFields } from './base';
import { InstructionFieldsProps, VoicemailBoxPayload } from '../types';
import { useQuery } from 'react-query';
import { VoicemailConfigApi } from '@frontend/api-voicemail-config';
import { queryKeys } from '../../../query-keys';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { SkeletonLoader } from '@frontend/design-system';
import { useAppScopeStore } from '@frontend/scope';

export const VoicemailBoxInstructionFields = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<VoicemailBoxPayload>['onChange'];
  option: VoicemailBoxPayload;
}) => {
  const { singleLocationId, accessibleLocationData } = useAppScopeStore();
  const location = accessibleLocationData[singleLocationId];

  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? location.phoneTenantId ?? '';

  const { data = [], isLoading } = useQuery({
    queryKey: queryKeys.listTenantVoicemailBoxes(tenantId),
    queryFn: () => VoicemailConfigApi.listVoicemailBoxes({ tenantId: tenantId }),
    select: (data) => {
      return data.voicemailBoxes
        .sort((a, b) => a.mailbox.name.localeCompare(b.mailbox.name))
        .map((voicemailBox) => ({
          value: voicemailBox.mailbox.id,
          label: voicemailBox.mailbox.name,
        }));
    },
  });

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<VoicemailBoxPayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          voicemailBoxId: data,
        },
        value: {
          voicemailBoxId: option.voicemailBoxId,
        },
      }}
    />
  );
};

const schema = {
  type: 'voicemailBox',
  fields: {
    voicemailBoxId: {
      label: 'Voicemail Box',
      name: 'voicemailBoxId',
      type: 'dropdown',
      helperText: 'This is some helper text for A',
    },
    greeting: {
      label: 'Greeting',
      name: 'phone-tree-2',
      type: 'dropdown',
      helperText: 'This is some helper text for B that is so very long and will take a few rows to display',
    },
    callerLabel: {
      label: 'Caller Label',
      name: 'phone-tree-3',
      type: 'text',
      helperText: 'This is some helper text for Caller Label',
    },
  },
} as const;
