import { LinkData, ManualTemplateTypesWithStaticTemplate } from '../types';
import { TEMPLATE_TYPE_LINK_MAP } from '../constants';
import { RelatedID } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { getManualTemplateIndexedValue } from './get-manual-template-indexed-value';

type GetRelatedIdsFromLinkDataArgs = {
  templateType?: ManualTemplateTypesWithStaticTemplate;
  bodyValue: string;
  linkData: LinkData[];
};

export const getRelatedIdsFromLinkData = ({
  templateType,
  bodyValue,
  linkData,
}: GetRelatedIdsFromLinkDataArgs): RelatedID[] => {
  const defaultRelatedType = templateType
    ? getManualTemplateIndexedValue({
        templateType,
        indexedValues: TEMPLATE_TYPE_LINK_MAP,
      })?.relatedType
    : undefined;
  const mappedLinkData = linkData.map(({ relatedType, ...link }) => ({
    relatedType: relatedType || defaultRelatedType,
    ...link,
  }));

  return mappedLinkData
    .filter(({ link, relatedType }) => bodyValue.includes(link) && !!relatedType)
    .map<RelatedID>(({ relatedId, relatedType }) => ({
      id: relatedId,
      type: relatedType!,
    }));
};
