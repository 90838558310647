import { InstructionFields } from './base';
import { CallGroupPayload, InstructionFieldsProps } from '../types';
import { useQuery } from 'react-query';

import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { CallGroupsApi } from '@frontend/api-call-groups';
import { useAppScopeStore } from '@frontend/scope';

export const CallGroupInstructionField = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<CallGroupPayload>['onChange'];
  option: CallGroupPayload;
}) => {
  const { singleLocationId, accessibleLocationData } = useAppScopeStore();
  const location = accessibleLocationData[singleLocationId];

  const { data = [], isLoading } = useQuery({
    queryKey: [queryKeys.callGroups(), location.id],
    queryFn: () => {
      return CallGroupsApi.listCallGroups(location.id);
    },
    select: (data) => {
      return data
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((cg) => ({
          value: cg.ID,
          label: cg.name,
        }));
    },
  });

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<CallGroupPayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          callGroupId: data ?? [],
        },
        value: {
          callGroupId: option.callGroupId,
          callerName: option.callerName,
        },
      }}
    />
  );
};

const schema = {
  type: 'callGroup',
  fields: {
    callGroupId: {
      label: 'Call Group',
      name: 'callGroupId',
      type: 'dropdown',
      helperText: 'This is some helper text for A',
    },
    callerName: {
      label: 'Caller Label',
      name: 'callerName',
      type: 'text',
    },
  },
} as const;
