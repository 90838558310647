import { UseMutationOptions, UseQueryOptions, useQueryClient } from 'react-query';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { SchemaPaymentsCommandsService } from '@frontend/schema';
import { deletePaymentMethod, getLocationPaymentMethods, getPersonPaymentMethods } from './api';
import {
  CreatePMRegistrationLinkRequest,
  CreatePMRegistrationLinkResponse,
  GetLocationPaymentMethodsRequest,
  GetPersonPaymentMethodsRequest,
  PaymentMethod,
  PersonPaymentMethodsResponse,
} from './types';

const MIN_IN_MS = 1000 * 60;
const HOURS_IN_MS = 60 * MIN_IN_MS;

export const paymentPlansQueryKeys = {
  baseQuery: ['payment-plans'],
  getPersonPaymentMethodsBase: () => [...paymentPlansQueryKeys.baseQuery, 'person-payment-methods'],
  getPersonPaymentMethods: (request: GetPersonPaymentMethodsRequest) => [
    ...paymentPlansQueryKeys.getPersonPaymentMethodsBase(),
    request.locationId,
    request.patientId,
  ],
  getSetupLinkBase: () => [...paymentPlansQueryKeys.baseQuery, 'payment-method-setup-link'],
  getSetupLink: (request: GetLocationPaymentMethodsRequest) => [
    ...paymentPlansQueryKeys.getSetupLinkBase(),
    request.locationId,
  ],
};

export const usePaymentPlansInvalidation = () => {
  const queryClient = useQueryClient();

  const invalidatePaymentMethodsByPerson = (patientId?: string, locationId?: string) => {
    queryClient.invalidateQueries({
      predicate: ({ queryKey }) => {
        return (
          paymentPlansQueryKeys.getPersonPaymentMethodsBase().every((part) => queryKey.includes(part)) &&
          (!patientId || queryKey.includes(patientId)) &&
          (!locationId || queryKey.includes(locationId))
        );
      },
    });
  };

  const invalidateSetupLink = (locationId?: string) => {
    queryClient.invalidateQueries({
      predicate: ({ queryKey }) => {
        return (
          paymentPlansQueryKeys.getSetupLinkBase().every((part) => queryKey.includes(part)) &&
          (!locationId || queryKey.includes(locationId))
        );
      },
    });
  };

  return {
    invalidatePaymentMethodsByPerson,
    invalidateSetupLink,
  };
};

export const useGetPaymentMethodsByPerson = (
  request: GetPersonPaymentMethodsRequest,
  options?: UseQueryOptions<PaymentMethod[], unknown, PaymentMethod[]>
) => {
  return useQuery({
    queryKey: paymentPlansQueryKeys.getPersonPaymentMethods(request),
    queryFn: () => getPersonPaymentMethods(request.paymentsUrl, request.patientId, request.locationId),
    cacheTime: 5 * MIN_IN_MS, // 5 Minutes
    staleTime: 5 * MIN_IN_MS, // 5 Minutes
    ...options,
    enabled: (options?.enabled ?? true) && !!request.patientId && !!request.paymentsUrl && !!request.locationId,
  });
};

export const useGetPaymentMethodSetupLink = (
  request: GetLocationPaymentMethodsRequest,
  options?: UseQueryOptions<PersonPaymentMethodsResponse, unknown, string | undefined>
) => {
  return useQuery({
    queryKey: paymentPlansQueryKeys.getSetupLink(request),
    queryFn: () => getLocationPaymentMethods(request.paymentsUrl, request.locationId),
    cacheTime: 12 * HOURS_IN_MS,
    staleTime: 12 * HOURS_IN_MS,
    ...options,
    enabled: (options?.enabled ?? true) && !!request.paymentsUrl && !!request.locationId,
    select: (response) => response?.links?.setups,
  });
};

export const useCreatePMRegistrationLink = (
  options?: UseMutationOptions<CreatePMRegistrationLinkResponse, unknown, CreatePMRegistrationLinkRequest>
) => {
  return useMutation({
    mutationFn: (request) =>
      SchemaPaymentsCommandsService.CreatePaymentMethodRegistrationLink(request, {
        headers: { 'Location-Id': request.locationId },
      }),
    ...options,
  });
};

export const useDeletePaymentMethod = (
  options?: UseMutationOptions<unknown, unknown, { paymentsUrl: string; paymentMethodId: string; locationId: string }>
) => {
  return useMutation({
    mutationFn: (request) => deletePaymentMethod(request),
    ...options,
  });
};
