import { StatusGroup } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/v1/scheduled.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { cloneDeep } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';

export const useFormatStatuses = (customStatusMaps?: Partial<Record<OutboundMessageStatus, OutboundMessageStatus>>) => {
  const { t } = useTranslation('messages');

  // map of statuses to group together - for end user
  const groupedStatusMap: Partial<Record<OutboundMessageStatus, OutboundMessageStatus>> = {
    [OutboundMessageStatus.DELIVERED]: OutboundMessageStatus.DELIVERED,
    [OutboundMessageStatus.SENT]: OutboundMessageStatus.DELIVERED,
    [OutboundMessageStatus.ERROR]: OutboundMessageStatus.ERROR,
    [OutboundMessageStatus.INVALID_DESTINATION]: OutboundMessageStatus.INVALID_DESTINATION,
    [OutboundMessageStatus.NOT_SENT]: OutboundMessageStatus.NOT_SENT,
    [OutboundMessageStatus.CANCELED]: OutboundMessageStatus.NOT_SENT,
    [OutboundMessageStatus.OPTED_OUT]: OutboundMessageStatus.OPTED_OUT,
    [OutboundMessageStatus.SCHEDULED]: OutboundMessageStatus.SCHEDULED,
    [OutboundMessageStatus.IN_PROCESS]: OutboundMessageStatus.SCHEDULED,
    [OutboundMessageStatus.SCHEDULED_RETRY]: OutboundMessageStatus.SCHEDULED,
    ...customStatusMaps,
  };

  const getGroupedStatuses = () => [...new Set(Object.values(groupedStatusMap))];
  const getGroupedStatus = (status: OutboundMessageStatus) => groupedStatusMap[status] ?? OutboundMessageStatus.ERROR;

  // map to reverse the status to all its options - for backend call
  const reversedGroupedStatusMap = Object.entries(groupedStatusMap ?? {}).reduce((acc, [key, value]) => {
    if (acc[value]) {
      acc[value].push(key as OutboundMessageStatus);
      return acc;
    }
    acc[value] = [key as OutboundMessageStatus];
    return acc;
  }, {} as Record<OutboundMessageStatus, OutboundMessageStatus[]>);

  const getReversedGroupedStatus = (status: OutboundMessageStatus): OutboundMessageStatus[] =>
    reversedGroupedStatusMap[status] ?? [OutboundMessageStatus.ERROR];

  // map of statuses to display to the end user
  const displayedStatusMap: Partial<Record<OutboundMessageStatus, string>> = {
    [OutboundMessageStatus.DELIVERED]: t('Delivered'),
    [OutboundMessageStatus.ERROR]: t('Error'),
    [OutboundMessageStatus.INVALID_DESTINATION]: t('Invalid Destination'),
    [OutboundMessageStatus.NOT_SENT]: t('Not Sent'),
    [OutboundMessageStatus.OPTED_OUT]: t('Opted Out'),
    [OutboundMessageStatus.SCHEDULED]: t('Scheduled'),
  };

  const formatDisplayedStatus = (status: OutboundMessageStatus): string => displayedStatusMap[status] ?? t('Error');

  // map to reverse the displayed status to the BE status
  const reversedDisplayedStatusMap = Object.entries(displayedStatusMap ?? {}).reduce<
    Record<string, OutboundMessageStatus>
  >((acc, [key, value]) => {
    acc[value] = key as OutboundMessageStatus;
    return acc;
  }, {});

  const formatReversedDisplayedStatus = (status: string): OutboundMessageStatus =>
    reversedDisplayedStatusMap[status] ?? reversedDisplayedStatusMap[t('Error')];

  const reduceStatuses = (statuses?: Record<string, StatusGroup>) => {
    const combinedStatuses = Object.entries(statuses ?? {}).reduce((acc, [key, { total, values }]) => {
      const status = groupedStatusMap[key as OutboundMessageStatus] ?? OutboundMessageStatus.ERROR;
      const currentValue = acc[status];
      if (currentValue) {
        currentValue.total = (currentValue?.total || 0) + (total || 0);
        currentValue.values = currentValue?.values?.map((item) => {
          const newItem = cloneDeep(item);
          const instance = values?.find((value) => value.date === item.date);
          if (instance) {
            newItem.count = (newItem?.count || 0) + (instance?.count || 0);
          }
          return newItem;
        });
      } else {
        acc[status] = {
          total,
          values,
        };
      }
      return acc;
    }, {} as Record<OutboundMessageStatus, StatusGroup>);

    return Object.entries(combinedStatuses ?? {}) as [OutboundMessageStatus, StatusGroup][];
  };

  return {
    formatDisplayedStatus,
    formatReversedDisplayedStatus,
    getGroupedStatuses,
    getGroupedStatus,
    getReversedGroupedStatus,
    groupedStatusMap,
    reduceStatuses,
  };
};
