import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { InstructionFieldsProps, PlayMessagePayload } from '../types';
import { InstructionFields } from './base';

export const PlayMessageInstructionFields = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<PlayMessagePayload>['onChange'];
  option: PlayMessagePayload;
}) => {
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');

  return (
    <InstructionFields<PlayMessagePayload>
      onChange={onChange}
      schema={schema}
      customization={{
        value: {
          greetingId: option.greetingId,
        },
        meta: {
          greetingId: {
            tenantId: settingsTenantLocation?.phoneTenantId ?? '',
            requestedTypes: { custom: true, standard: false, mailbox: false },
          },
        },
      }}
    />
  );
};

const schema = {
  type: 'playMessage',
  fields: {
    greetingId: {
      label: 'Play Message',
      name: 'greetingId',
      type: 'dropdown',
      kind: 'media',
      helperText: 'This is some helper text for A',
      meta: {
        tenantId: '',
        requestedTypes: { custom: true, standard: false, mailbox: false },
      },
    },
  },
} as const;
