import { ViewInvoiceDetails, ViewInvoiceHeader } from '@frontend/payment-request-details';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';

export const PreviewInvoice = () => {
  const { invoice } = useSelectedInvoice();
  return (
    <>
      <ViewInvoiceHeader invoice={invoice} />
      <ViewInvoiceDetails invoice={invoice} />
    </>
  );
};
