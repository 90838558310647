import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { FaxPrefixes } from '@frontend/tracking-prefixes';
import { ComponentProps } from '@frontend/types';
import { theme } from '@frontend/theme';
import {
  IconButton,
  ListRow,
  ListRowProps,
  MoreIcon,
  PopoverMenu,
  PopoverMenuItem,
  usePopoverMenu,
  Text,
  IconProps,
  FontColorType,
  FontWeight,
  UsePopoverMenuResponse,
} from '@frontend/design-system';
import { ListRowDownloadPopover } from './list-row-download-popover';

dayjs.extend(calendar);

export type StatusAlert = {
  text: string;
  color?: FontColorType;
  weight?: FontWeight;
  customColor?: string;
};

export type FaxListRowProps<C extends React.ElementType = 'li'> = ListRowProps<C> & {
  Lead: JSX.Element;
  Title: JSX.Element;
  Content?: JSX.Element;
  timestamp?: string;
  isExpanded?: boolean;
  ExpandedContent?: JSX.Element;
  trackingId?: string;
  actions?: {
    actionIcon: PopoverMenuItem['Icon'];
    label: string;
    action: () => void;
  }[];
  archiveButtonProps?: ArchiveButtonProps['archiveButtonProps'];
};

export const FaxListRow = <C extends React.ElementType = 'li'>({
  Lead,
  Title,
  Content,
  isExpanded,
  ExpandedContent,
  timestamp,
  actions,
  archiveButtonProps,
  trackingId,
  ...rest
}: FaxListRowProps<C>) => {
  const [isHovering, setIsHovering] = useState(false);

  const { getTriggerProps, getMenuProps, getItemProps, isOpen, close } = usePopoverMenu({
    placement: 'bottom-end',
    middlewareOptions: {
      offset: {
        alignmentAxis: 0,
        mainAxis: 0,
      },
    },
  });
  const formatTimestamp = useTimestampFormatter();

  return (
    <ListRow
      trackingId={trackingId}
      as='div'
      css={{ gridTemplateColumns: 'auto 1fr' }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        if (!isOpen) {
          setIsHovering(false);
        }
      }}
      {...rest}
    >
      {Lead}
      <ListRow.Content css={{ display: 'grid', gridTemplateRows: 'repeat(auto-fit, minmax(24px, auto))' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: 0 }}>
          {Title}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                position: 'absolute',
                right: theme.spacing(3),
                width: '60px',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                visibility: isHovering ? 'visible' : 'hidden',
                opacity: isHovering ? 1 : 0,
                transition: 'all 200ms ease-in-out',
              }}
            >
              {archiveButtonProps && <ArchiveIconButton archiveButtonProps={archiveButtonProps} />}
              {actions && (
                <ActionsPopoverMenu
                  actions={actions}
                  getMenuProps={getMenuProps}
                  getItemProps={getItemProps}
                  getTriggerProps={getTriggerProps}
                  close={close}
                />
              )}
            </div>
            <Text
              size='small'
              color={'light'}
              weight={'regular'}
              css={{
                flexShrink: 0,
                width: 'max-content',
                visibility: isHovering ? 'hidden' : 'visible',
                opacity: isHovering ? 0 : 1,
                transition: 'all 200ms ease-in-out',
              }}
            >
              {formatTimestamp(timestamp)}
            </Text>
          </div>
        </div>
        {Content}
        {isExpanded && ExpandedContent}
      </ListRow.Content>
    </ListRow>
  );
};

type ActionsPopoverMenuProps = {
  actions: {
    actionIcon: PopoverMenuItem['Icon'];
    label: string;
    action: () => void;
    trackingId?: string;
    disableCloseOnSelect?: boolean;
    popoverProps?: UsePopoverMenuResponse<HTMLButtonElement, false, HTMLMenuElement, HTMLButtonElement>;
    faxId?: string;
    locationId?: string;
  }[];
  getItemProps: ReturnType<typeof usePopoverMenu<HTMLElement>>['getItemProps'];
  getMenuProps: ReturnType<typeof usePopoverMenu<HTMLElement>>['getMenuProps'];
  getTriggerProps: ReturnType<typeof usePopoverMenu<HTMLElement>>['getTriggerProps'];
  close: () => void;
};

export const ActionsPopoverMenu = ({
  actions,
  getItemProps,
  getTriggerProps,
  getMenuProps,
  close,
}: ActionsPopoverMenuProps) => {
  const { t } = useTranslation('fax');
  const menuProps = getMenuProps();

  const triggerProps = getTriggerProps({
    onClick: (e) => {
      e.stopPropagation();
    },
  });

  return (
    <>
      <IconButton
        css={{ height: 24, ':hover:not(:disabled)': { background: 'none', color: theme.colors.primary50 } }}
        size='small'
        {...triggerProps}
        label={t('More actions')}
        trackingId={`${FaxPrefixes.List}-more-actions-button`}
      >
        <MoreIcon />
      </IconButton>

      <PopoverMenu {...menuProps}>
        {actions.map(
          (
            { actionIcon, label, action, trackingId, disableCloseOnSelect = false, popoverProps, faxId, locationId },
            index
          ) => {
            return (
              <>
                {disableCloseOnSelect ? (
                  <>
                    <PopoverMenuItem
                      Icon={actionIcon}
                      {...popoverProps?.getItemProps({
                        index: 2,
                        disableCloseOnSelect: true,
                      })}
                      {...popoverProps?.getTriggerProps()}
                      trackingId={trackingId}
                    >
                      <div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flex: 1,
                        }}
                      >
                        <Text>{t('Download')}</Text>
                        <Icon
                          name='alt-caret-right-tiny'
                          css={{
                            color: theme.colors.neutral50,
                          }}
                        />
                      </div>
                    </PopoverMenuItem>
                    <ListRowDownloadPopover
                      closeParentMenu={close}
                      downloadPopoverProps={popoverProps}
                      faxId={faxId ?? ''}
                      locationId={locationId ?? ''}
                    />
                  </>
                ) : (
                  <PopoverMenuItem
                    Icon={actionIcon}
                    {...getItemProps({
                      index,
                      onClick: (e) => {
                        e.stopPropagation();
                        action();
                      },
                    })}
                    key={label}
                    trackingId={trackingId}
                  >
                    {label}
                  </PopoverMenuItem>
                )}
              </>
            );
          }
        )}
      </PopoverMenu>
    </>
  );
};

type ArchiveButtonProps = {
  archiveButtonProps: {
    label: string;
    onClick: () => void;
    Icon: FC<React.PropsWithChildren<IconProps>>;
    trackingId?: string;
    showLabelOnHover?: boolean;
    size?: ComponentProps<typeof IconButton>['size'];
  };
};

const ArchiveIconButton = ({ archiveButtonProps }: ArchiveButtonProps) => {
  const { label, onClick, Icon, trackingId, showLabelOnHover, size } = archiveButtonProps;

  return (
    <IconButton
      css={{ height: 24, ':hover:not(:disabled)': { background: 'none', color: theme.colors.primary50 } }}
      size={size ?? 'small'}
      label={label}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      trackingId={trackingId}
      showLabelOnHover={showLabelOnHover}
    >
      <Icon />
    </IconButton>
  );
};
