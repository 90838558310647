import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { InboxFilter, InboxFilters } from '../../../types';
import { DepartmentsFilterPopoverSubmenuItem } from './departments-filters';
import { TagsFilterPopoverSubmenuItem } from './tags-filters';
import { GetItemPropsFn } from './types';

type InboxFilterPopoverMenuItemProps<T extends InboxFilter> = {
  getItemProps: GetItemPropsFn;
  startingIndex: number;
  filter: T;
  toggleItems: <V extends string[] = InboxFilters[T]>(values: V) => void;
  selectedItems: InboxFilters[T];
  groupIds?: string[];
  divider?: 'top' | 'bottom';
  closeMenu: () => void;
};

export const InboxFilterPopoverMenuItem = <T extends InboxFilter>({
  getItemProps,
  startingIndex,
  filter,
  toggleItems,
  selectedItems,
  groupIds,
  divider,
  closeMenu,
}: InboxFilterPopoverMenuItemProps<T>) => {
  if (filter === 'tags') {
    return (
      <TagsFilterPopoverSubmenuItem
        getItemProps={getItemProps}
        index={startingIndex}
        toggleItems={toggleItems}
        selectedTags={selectedItems}
        groupIds={groupIds}
        divider={divider}
        closeParentMenu={closeMenu}
        trackingId={`${InboxPrefixes.List}-filters-popover-tags`}
      />
    );
  }

  if (filter === 'departments') {
    return (
      <DepartmentsFilterPopoverSubmenuItem
        getItemProps={getItemProps}
        index={startingIndex}
        toggleItems={toggleItems}
        selectedDepartments={selectedItems}
        groupIds={groupIds}
        divider={divider}
        trackingId={`${InboxPrefixes.List}-filters-popover-departments`}
      />
    );
  }

  return null;
};
