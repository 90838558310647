import { useState } from 'react';
import { css } from '@emotion/react';
import { omit } from 'lodash-es';
import { MetlQueries } from '@frontend/api-messaging';
import { CoreACLs, getUser, hasACL } from '@frontend/auth-helpers';
import { Page } from '@frontend/components';
import { useOrgData } from '@frontend/get-org-data';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Alert, Heading, PrimaryButton, Tabs, Text, useAlert } from '@frontend/design-system';
import {
  AppointmentsListTable,
  ContactInfosListTable,
  OrdersListTable,
  PersonsListTable,
  RecallsListTable,
} from '../components/record-history';

const DEFAULT_VIEW = 'appointments-tab';
const NATE_DATA_SYNC_KEY = 'nate-data-sync';

export const DebugToolsPage = () => {
  const { t } = useTranslation('messages');
  const { locationId, locationData } = useOrgData();
  const canView = hasACL(locationId, CoreACLs.MESSAGE_HISTORY_VIEW);

  const alert = useAlert();
  const user = getUser();

  const getDataSyncKey = () => {
    const data = sessionStorage.getItem(NATE_DATA_SYNC_KEY) || '{}';
    return JSON.parse(data);
  };

  const checkExpiration = () => {
    const parsedData: Record<string, number> = getDataSyncKey();
    if (!parsedData[locationId]) return false;

    const fifteenMinutes = 15 * 60 * 1000;
    const hasExpired = parsedData[locationId] + fifteenMinutes < Date.now();

    if (hasExpired) {
      const newData = omit(parsedData, locationId);
      sessionStorage.setItem(NATE_DATA_SYNC_KEY, JSON.stringify(newData));
    }
    return !hasExpired;
  };
  const [isClicked, setIsClicked] = useState(checkExpiration());

  const [activeTab, setActiveTab] = useState<string>(DEFAULT_VIEW);

  if (!canView) {
    return (
      <Alert id='debug-tools-permission' type='warning'>
        <Text>{t("You don't have permission to view Record History.")}</Text>
      </Alert>
    );
  }

  return (
    // TODO: Once this isn't needed anymore, we will revert it back to this.
    // <Page title={t('Debug Tools')}>
    <Page
      title={
        <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Heading level={1}>{t('Debug Tools')}</Heading>
          <PrimaryButton
            onClick={() => {
              setIsClicked(true);
              MetlQueries.useFullSyncLocation();
              alert.success(
                t("A data sync has been triggered for '{{location}}' by {{user}}.", {
                  location: locationData?.name ?? '',
                  user: user?.firstName + ' ' + user?.lastName,
                })
              );
              sessionStorage.setItem(
                NATE_DATA_SYNC_KEY,
                JSON.stringify({ ...getDataSyncKey(), [locationId]: Date.now() })
              );
            }}
            size='tiny'
            disabled={isClicked}
          >
            {t("Nate's Data Sync")}
          </PrimaryButton>
        </div>
      }
    >
      <Tabs initialTab={activeTab} onChange={setActiveTab}>
        <Tabs.Bar fullWidth css={TabBarStyles}>
          <Tabs.Tab id='appointments-tab' controls='appointments-panel'>
            {t('Appointments')}
          </Tabs.Tab>
          <Tabs.Tab id='contact-infos-tab' controls='contact-infos-panel'>
            {t('Contact Infos')}
          </Tabs.Tab>
          <Tabs.Tab id='orders-tab' controls='orders-panel'>
            {t('Orders')}
          </Tabs.Tab>
          <Tabs.Tab id='persons-tab' controls='persons-panel'>
            {t('Persons')}
          </Tabs.Tab>
          <Tabs.Tab id='recalls-tab' controls='recalls-panel'>
            {t('Recalls')}
          </Tabs.Tab>
        </Tabs.Bar>
        <Tabs.Panel controller='appointments-tab' id='appointments-panel'>
          <AppointmentsListTable />
        </Tabs.Panel>
        <Tabs.Panel controller='contact-infos-tab' id='contact-infos-panel'>
          <ContactInfosListTable />
        </Tabs.Panel>
        <Tabs.Panel controller='orders-tab' id='orders-panel'>
          <OrdersListTable />
        </Tabs.Panel>
        <Tabs.Panel controller='persons-tab' id='persons-panel'>
          <PersonsListTable />
        </Tabs.Panel>
        <Tabs.Panel controller='recalls-tab' id='recalls-panel'>
          <RecallsListTable />
        </Tabs.Panel>
      </Tabs>
    </Page>
  );
};

const TabBarStyles = css`
  margin-bottom: ${theme.spacing(2)};
`;
