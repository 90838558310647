import { useCallback, useEffect, useRef } from 'react';
import { isFunction } from 'lodash-es';
import { PaymentMethod, PaymentMethodStatus, PaymentPlanQueries } from '@frontend/api-payment-plans';
import { HttpError } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useAlert } from '@frontend/design-system';

export const MIN_ACH_AMOUNT = 25000;

export type UseCardOnFileParams = {
  patientId?: string;
  paymentAmount: number | undefined;
  minACHAmount: number | undefined;
  setSelectedPM?: (pm: PaymentMethod | undefined) => void;
  alertOnError?: boolean;
  selectDefaultPaymentMethod?: boolean;
  paymentMethodId?: string;
  achDisabledByCadence?: boolean;
};

export const useCardOnFile = ({
  patientId = '',
  paymentAmount = 0,
  minACHAmount = MIN_ACH_AMOUNT,
  setSelectedPM,
  alertOnError = false,
  selectDefaultPaymentMethod = false,
  paymentMethodId,
  achDisabledByCadence = false,
}: UseCardOnFileParams) => {
  const { t } = useTranslation('payments');
  const alerts = useAlert();

  const { paymentsUrl } = useMerchant();
  const { locationId } = useMultiQueryUtils();

  const {
    data: paymentMethods,
    isLoading,
    isRefetching,
    isError,
  } = PaymentPlanQueries.useGetPaymentMethodsByPerson(
    {
      paymentsUrl,
      locationId,
      patientId,
    },
    {
      onError: (error) => {
        if (alertOnError && error && error instanceof HttpError) {
          alerts.error(error.message || t('Error in retrieving saved cards.'));
        }
      },
    }
  );

  const invalidAmount = paymentAmount < minACHAmount;
  const disableACHPaymentMethods = invalidAmount || achDisabledByCadence;

  const selectedByDefaultPM = useRef<PaymentMethod>();

  const getDefaultPaymentMethod = useCallback(() => {
    if (paymentMethodId && (!selectedByDefaultPM.current || paymentMethodId !== selectedByDefaultPM.current.id)) {
      const selectedPaymentMethod = paymentMethods?.find((pm) => pm.id === paymentMethodId);
      if (selectedPaymentMethod) return selectedPaymentMethod;
    }
    const filteredPaymentMethods = paymentMethods?.filter(
      (pm) =>
        !(
          pm.status &&
          [
            PaymentMethodStatus.PAYMENT_METHOD_STATUS_VERIFICATION_PENDING,
            PaymentMethodStatus.PAYMENT_METHOD_STATUS_EXPIRED,
          ].includes(pm.status)
        )
    );

    const firstCardMethod = filteredPaymentMethods?.find((pm) => pm.type === 'card');
    // Returns default card method if available, otherwise returns first card method
    return (
      filteredPaymentMethods?.find(
        (pm) => pm.is_default && (pm.type === 'us_bank_account' ? !disableACHPaymentMethods : true)
      ) || firstCardMethod
    );
  }, [paymentMethods, disableACHPaymentMethods, paymentMethodId]);

  const setDefaultPaymentMethod = useCallback(() => {
    if (!isError && !!paymentMethods && isFunction(setSelectedPM)) {
      selectedByDefaultPM.current = getDefaultPaymentMethod();
      setSelectedPM(selectedByDefaultPM.current);
    }
  }, [isError, paymentMethods, getDefaultPaymentMethod]);

  useEffect(() => {
    if (selectDefaultPaymentMethod) setDefaultPaymentMethod();
  }, [selectDefaultPaymentMethod, setDefaultPaymentMethod]);

  return {
    paymentAmount,
    patientId,
    isLoading: isLoading || isRefetching,
    paymentMethods,
    disableACHPaymentMethods,
    achDisabledByCadence,
    invalidAmount,
  };
};
