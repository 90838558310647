import { useEffect, useMemo } from 'react';
import { AnalyticsCommonTypes, CallIntelligenceApi } from '@frontend/api-analytics';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { queryKeys } from '../query-keys';

type Params = {
  demoLocations?: AnalyticsCommonTypes.StringRecord;
  onLoadLocations?: (locations: AnalyticsCommonTypes.StringRecord) => void;
};

type Response = {
  isLoading?: boolean;
  isMultiLocation?: boolean;
  locations: AnalyticsCommonTypes.StringRecord;
};

const adjustDemoLocations = (locations: AnalyticsCommonTypes.StringRecord, isWAMMultiLocation?: boolean) => {
  if (!isWAMMultiLocation) {
    const [firstKey, firstValue] = Object.entries(locations)?.[0] || {};
    return { [firstKey]: firstValue };
  }

  return locations || {};
};

export const useCallIntelLocations = (options?: Params): Response => {
  const { demoLocations, onLoadLocations } = options || {};
  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const locationData = getSelectedLocationData()[selectedLocationIdsWithParents[0]];
  const isWAMMultiLocation = !!locationData?.parentId || !!locationData?.children?.length;

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.callIntelLocations(locationData?.slug),
    queryFn: () => (demoLocations ? null : CallIntelligenceApi.getLocations()),
    onError: (error) => {
      console.error(error);
    },
    staleTime: 1000 * 60 * 5,
  });

  const locations: AnalyticsCommonTypes.StringRecord = useMemo(() => {
    const receivedLocations: AnalyticsCommonTypes.StringRecord = demoLocations
      ? adjustDemoLocations(demoLocations, isWAMMultiLocation)
      : data?.locations?.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {}) || {};

    const sortedLocationsByName = Object.entries(receivedLocations).sort(([, a], [, b]) => a.localeCompare(b));

    return Object.fromEntries(sortedLocationsByName);
  }, [data, demoLocations]);

  useEffect(() => {
    if (locations) {
      onLoadLocations?.(locations);
    }
  }, [locations]);

  return {
    isLoading,
    isMultiLocation: Object.keys(locations).length > 1,
    locations,
  };
};
