import { useQueryClient } from 'react-query';
import { queryKeys } from '../inbox-queries';
import { useCallback } from 'react';

export const useInvalidateUnreadCount = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    const queryKey = queryKeys.unreadCountBase;
    queryClient.invalidateQueries(queryKey);
  }, [queryClient]);
};
