import { memo, useMemo } from 'react';
import { useLocation } from '@tanstack/react-location';
import { capitalize } from 'lodash-es';
import {
  AnalyticsCommonTypes,
  PracticeAnalyticsAggregations,
  PracticeAnalyticsApi,
  PracticeAnalyticsTypes,
} from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { TableLoadingSkeleton, emptyStateGraphics } from '@frontend/design-system';
import { useAnalyticsOrgLocations, usePracticeAnalyticsStore, useShowBulkMessageButton } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { ExportUtils, formatters } from '../../utils';
import { commonStyles } from '../../views';
import { ColumnHeaderInfoTip } from '../column-header-info-tip';
import { InfoTipPopover } from '../info-tip-popover';
import { LocationChip } from '../location-chip';
import { Status } from '../status';
import { TabbedTable } from '../tabbed-table';
import { TableActions } from '../table-actions';
import { UserCard } from '../user-card';
import { BulkMessageButton } from './bulk-message-button';
import { PatientsHelpers, getLast24WeeksDates } from './helpers';
import { usePatientsLastContacted } from './hooks';

type Props = {
  data?: ReturnType<typeof PracticeAnalyticsAggregations.activePatientsDetails>;
  isExportEnabled?: boolean;
  isLoading?: boolean;
};

export const ActivePatientsTable = memo(({ data, isExportEnabled, isLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const { current } = useLocation();
  const isWeaveApp = !current.pathname.startsWith('/portal');
  const { filters, isDemoAccount } = usePracticeAnalyticsStore();
  const { locationNames } = useAnalyticsOrgLocations({ module: 'PA' });
  const isBulkMessagingEnabled = useShowBulkMessageButton();

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

  // Collect all patients from all buckets and separate them into all and unscheduled categories
  const patients = useMemo(() => {
    const unscheduled: PracticeAnalyticsTypes.PatientInfo[] = [];

    const allPatients = data?.aggregatedData.patientDemographics.buckets
      .map(({ subbuckets }) => {
        return subbuckets
          .map(({ persons }) => {
            persons?.forEach((person) => {
              if (!person.nextVisit) {
                unscheduled.push(person);
              }
            });
            return persons;
          })
          .flat();
      })
      .flat() as PracticeAnalyticsTypes.PatientInfo[];

    const insurances = data?.aggregatedData.patientDemographics.details.insuranceProviders.reduce(
      (acc, { label, persons }) => {
        persons?.forEach(({ id }) => {
          acc[id] = capitalize(label);
        });
        return acc;
      },
      {} as Record<string, string>
    );

    return {
      all: allPatients,
      unscheduled,
      insurances,
    };
  }, [data?.aggregatedData]);

  const lastContactedParams = useMemo(() => {
    const { startDate, endDate } = getLast24WeeksDates();

    return {
      endTime: endDate,
      patientIds: PatientsHelpers.collectPatientIds(patients.all),
      startTime: startDate,
    };
  }, [patients.all]);

  const { isLoading: isLoadingLastContacted, lastContactedDates } = usePatientsLastContacted(lastContactedParams);

  const colConfig = useMemo(() => {
    return [
      {
        Header: t('Patient Name'),
        headerLabel: t('Patient Name'),
        accessor: ({ FirstName, LastName, id }: PracticeAnalyticsTypes.PatientInfo) =>
          JSON.stringify({
            firstName: FirstName,
            lastName: LastName,
            userId: id,
          }),
        cellRenderer: (value: string) => {
          const props = JSON.parse(value);
          return <UserCard {...props} key={props.userId} openProfileOnClick={!isDemoAccount} showOnlyName />;
        },
        id: 'name',
        sticky: 'left' as AnalyticsCommonTypes.Alignment,
        width: 250,
      },
      {
        accessor: ({ locationId }: PracticeAnalyticsTypes.PatientInfo) => locationId,
        cellRenderer: (locationId: string) => {
          return locationId ? (
            <LocationChip locationName={locationNames[locationId] || locationId} maxWidth={180} />
          ) : (
            '-'
          );
        },
        disableSortBy: true,
        Header: t('Location'),
        id: 'locationName',
        omit: !multipleLocationsSelected,
        width: 220,
      },
      {
        Header: t('Phone Number'),
        headerLabel: t('Phone Number'),
        accessor: ({ HomePhone, MobilePhone, WorkPhone }: PracticeAnalyticsTypes.PatientInfo) =>
          MobilePhone || HomePhone || WorkPhone,
        cellRenderer: (value: string) => (value ? formatPhoneNumber(value) : '-'),
        id: 'phone-number',
        width: 180,
      },
      {
        Header: t('Gender'),
        headerLabel: t('Gender'),
        accessor: ({ Gender }: PracticeAnalyticsTypes.PatientInfo) => Gender || '-',
        id: 'gender',
        width: 130,
      },
      {
        Header: t('Age'),
        headerLabel: t('Age'),
        accessor: ({ age }: PracticeAnalyticsTypes.PatientInfo) => age || '-',
        id: 'age',
        width: 100,
      },
      {
        Header: t('Status'),
        headerLabel: t('Status'),
        accessor: ({ nextVisit }: PracticeAnalyticsTypes.PatientInfo) => nextVisit,
        cellRenderer: (value: string) => <Status status={value ? 'scheduled' : 'unscheduled'} />,
        id: 'status',
        width: 150,
      },
      {
        Header: (
          <ColumnHeaderInfoTip
            columnTitle={t('Last Contacted')}
            infoTip={
              <InfoTipPopover>
                {t('Date and Time of last outbound answered call to patient phone number')}
              </InfoTipPopover>
            }
          />
        ),
        headerLabel: t('Last Contacted'),
        accessor: ({ id }: PracticeAnalyticsTypes.PatientInfo) => lastContactedDates?.[id],
        cellRenderer: (lastContacted: string) =>
          isLoadingLastContacted ? (
            <TableLoadingSkeleton />
          ) : lastContacted ? (
            formatters.date.format(lastContacted)
          ) : (
            '-'
          ),
        id: 'lastContacted',
        width: 180,
      },
      {
        Header: t('Insurance'),
        headerLabel: t('Insurance'),
        accessor: ({ id }: PracticeAnalyticsTypes.PatientInfo) => patients.insurances?.[id] || '-',
        id: 'insurance',
        width: 150,
      },
      {
        disableSortBy: true,
        Header: t('Actions'),
        headerLabel: t('Actions'),
        headerAlign: 'center' as AnalyticsCommonTypes.Alignment | 'center',
        accessor: (patient: PracticeAnalyticsTypes.PatientInfo) => patient,
        cellRenderer: (patient: PracticeAnalyticsTypes.PatientInfo) => {
          const phoneNumber = patient.MobilePhone || patient.HomePhone;
          return (
            <TableActions
              isDemoAccount={isDemoAccount}
              personId={patient.id}
              personName={patient.FirstName}
              phoneNumber={phoneNumber}
              trackingIdBase='active-patient'
            />
          );
        },
        id: 'actions',
        omit: !isWeaveApp,
        sticky: 'right' as AnalyticsCommonTypes.Alignment,
        width: 128,
      },
    ];
  }, [patients, isLoadingLastContacted, lastContactedDates]);

  const tabsCommonConfig = {
    colConfig,
    emptyStateConfig: 'users' as keyof typeof emptyStateGraphics,
  };

  return (
    <TabbedTable
      actions={BulkMessageButton}
      autorizedToExport={PracticeAnalyticsApi.auditPracticeAnalyticsExport}
      isDemoAccount={isDemoAccount}
      exportFileName='Active Demographics Patients' // Translation not needed
      initialTab='all'
      isExportReady={!isLoading && !isLoadingLastContacted}
      isLoading={isLoading}
      isSelectable={isBulkMessagingEnabled}
      processExportableData={(data) =>
        // Translation is not needed as this is for export purpose
        ExportUtils.processExportableData({
          columns: [
            'Patient Name',
            ...(multipleLocationsSelected ? ['Location Name'] : []),
            'Phone Number',
            'Gender',
            'Age',
            'Status',
            'Last Contacted',
            'Insurance',
          ],
          data,
          deriveExportValue: (params) =>
            PatientsHelpers.deriveExportValue({
              ...params,
              insuranceNames: patients.insurances,
              lastContactedDates,
              locationNames,
            }),
        })
      }
      showExportIcon={isExportEnabled}
      tabs={{
        all: {
          ...tabsCommonConfig,
          data: patients?.all || [],
          label: t('All'),
          tableInstanceId: 'all-active-patients',
          trackingId: trackingIds.practiceAnalytics.allActivePatientsTab,
        },
        unscheduled: {
          ...tabsCommonConfig,
          data: patients?.unscheduled || [],
          label: t('Unscheduled'),
          tableInstanceId: 'active-unscheduled-patients',
          trackingId: trackingIds.practiceAnalytics.unscheduledActivePatientsTab,
        },
      }}
      trackingIdBase='active-patients'
      wrapperStyle={commonStyles.drillDownTableSize}
    />
  );
});

ActivePatientsTable.displayName = 'ActivePatientsTable';
