import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, SpinningLoader } from '@frontend/design-system';
import { PersonSelectionList, PersonSelectionListFilters } from '..';
import { useSelectRecipientList } from '../../hooks';

type SelectRecipientModalProps = ModalControlModalProps & {
  groupIds?: string[];
  bodyHeight?: number;
  onSelect: (person: Person) => void;
};

export const SelectRecipientModal = ({ groupIds, bodyHeight = 400, onSelect, ...rest }: SelectRecipientModalProps) => {
  const { t } = useTranslation('integrated-messaging');
  const { filtersProps, persons, isLoading } = useSelectRecipientList({ groupIds });

  return (
    <Modal maxWidth={800} minWidth={650} {...rest}>
      <Modal.Header css={{ marginBottom: theme.spacing(2) }} autoFocus>
        {t('Select Recipient')}
        <PersonSelectionListFilters
          {...filtersProps}
          css={{
            marginTop: theme.spacing(2),
          }}
        />
      </Modal.Header>

      {isLoading ? (
        <div
          css={{
            minHeight: bodyHeight,
            maxHeight: bodyHeight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SpinningLoader />
        </div>
      ) : (
        <Modal.Body
          css={[
            {
              minHeight: bodyHeight,
              maxHeight: bodyHeight,
            },
          ]}
        >
          <PersonSelectionList persons={persons} onSelect={onSelect} />
        </Modal.Body>
      )}
    </Modal>
  );
};
