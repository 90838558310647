import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { SalesforceBundleNameEnums } from './types';

export const SalesforceGoodBetterBestProductBundles = [
  SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_PRO,
  SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_ELITE,
  SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_ESSENTIALS,
  SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_PRO,
  SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_ELITE,
  SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_ESSENTIALS,
  SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ESSENTIALS,
  SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_PRO,
  SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ELITE,
  SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ESSENTIALS,
  SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_PRO,
  SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ELITE,
];

export const SalesForceBundleName = {
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE]: SalesforceBundleNameEnums.WEAVE_CORE,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS]: SalesforceBundleNameEnums.WEAVE_PLUS,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_PRO]: SalesforceBundleNameEnums.WEAVE_PLUS_PRO,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_ELITE]: SalesforceBundleNameEnums.WEAVE_PLUS_ELITE,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_ESSENTIALS]:
    SalesforceBundleNameEnums.WEAVE_PLUS_ESSENTIALS,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_PRO]: SalesforceBundleNameEnums.WEAVE_CORE_PRO,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_ELITE]: SalesforceBundleNameEnums.WEAVE_CORE_ELITE,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_ESSENTIALS]:
    SalesforceBundleNameEnums.WEAVE_CORE_ESSENTIALS,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ESSENTIALS]:
    SalesforceBundleNameEnums.WEAVE_CORE_SOFTWARE_ONLY_ESSENTIALS,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_PRO]:
    SalesforceBundleNameEnums.WEAVE_CORE_SOFTWARE_ONLY_PRO,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ELITE]:
    SalesforceBundleNameEnums.WEAVE_CORE_SOFTWARE_ONLY_ELITE,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ESSENTIALS]:
    SalesforceBundleNameEnums.WEAVE_PLUS_SOFTWARE_ONLY_ESSENTIALS,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_PRO]:
    SalesforceBundleNameEnums.WEAVE_PLUS_SOFTWARE_ONLY_PRO,
  [SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ELITE]:
    SalesforceBundleNameEnums.WEAVE_PLUS_SOFTWARE_ONLY_ELITE,
};
