import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { ScheduleTypes } from '@frontend/api-schedule';
import { stateOptions, CountryCodes, countryOptions } from '@frontend/geography';
import { AvailableLanguages, useCurrentLanguage, useTranslation } from '@frontend/i18n';
import { useMediaMatches } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import {
  useFormField,
  TextField,
  NumberField,
  DateField,
  CheckboxField,
  DropdownField,
  useForm,
  FormFieldActionTypes,
  FormRow,
  PhoneField,
  TextareaField,
} from '@frontend/design-system';
import { addressFormStyles, checkboxStyles } from './styles';

interface FieldProps<T = ScheduleTypes.SupportedTypes> {
  field?: ScheduleTypes.CustomField;
  value?: T;
  error?: boolean;
  translation?: string;
  multiline?: boolean;
  validator?: (value: T) => string;
  type?: 'text' | 'email' | 'phone';
  onChange?: (value: any, error?: Record<string, string>) => void;
  onError?: (error?: Record<string, string>) => void;
}

export function CustomCheckBox<T>({ field, onChange, value, translation, onError }: FieldProps<T>) {
  if (!field) {
    return null;
  }
  const props = useFormField({
    type: 'checkbox',
    value: value as any,
  });

  useEffect(() => {
    if (props.value !== value) {
      onChange?.({ [field.key]: props.value }, props.error ? { [field.key]: props.error } : undefined);
    }
  }, [props.value, field, props.error]);

  useEffect(() => {
    if (props.error) {
      onError?.({ [field.key]: props.error });
    }
  }, [field, props.error]);

  const label = useMemo(() => {
    const lbl = translation || field.label;
    return field.required ? `${lbl} *` : lbl;
  }, [translation, field]);

  return (
    <CheckboxField
      className='custom-field custom-field-check-box'
      css={checkboxStyles}
      {...props}
      name={field.key}
      label={label}
    />
  );
}

export function CustomAddress<T = ScheduleTypes.PatientAddress>({ field, onChange, value }: FieldProps<T>) {
  if (!field) {
    return null;
  }
  const { t } = useTranslation('schedule');
  const lang = useCurrentLanguage() as AvailableLanguages;
  const { matches } = useMediaMatches();

  const labels = {
    street: field?.subFields?.['street']?.translations?.[lang] || field?.subFields?.['street']?.label || t('Street'),
    unit: field?.subFields?.['unit']?.translations?.[lang] || field?.subFields?.['unit']?.label || t('Unit'),
    city: field?.subFields?.['city']?.translations?.[lang] || field?.subFields?.['city']?.label || t('City'),
    state: field?.subFields?.['state']?.translations?.[lang] || field?.subFields?.['state']?.label || t('State'),
    country:
      field?.subFields?.['country']?.translations?.[lang] || field?.subFields?.['country']?.label || t('Country'),
    postalCode:
      field?.subFields?.['postalCode']?.translations?.[lang] ||
      field?.subFields?.['postalCode']?.label ||
      t('Postal Code'),
  } as Record<keyof ScheduleTypes.PatientAddress, string>;

  const getSuffix = (subFieldName: string) => {
    const subField = field?.subFields?.[subFieldName];
    if (field?.required && subField?.required) {
      return ' *';
    }
    return '';
  };

  const { values, getFieldProps, getErrors } = useForm({
    fields: {
      street: {
        type: 'text',
        required: field.required && field.subFields?.street?.required,
      },
      unit: {
        required: field.required && field.subFields?.unit?.required,
        type: 'text',
      },
      city: {
        type: 'text',
        required: field.required && field.subFields?.city?.required,
      },
      state: {
        type: 'dropdown',
        required: field.required && field.subFields?.state?.required,
      },
      country: {
        type: 'dropdown',
        value: CountryCodes.USA,
        required: field.required && field.subFields?.country?.required,
      },
      postalCode: {
        type: 'text',
        required: field.required && field.subFields?.postalCode?.required,
      },
    },
    fieldStateReducer: (state, action) => {
      if (
        action.type === FormFieldActionTypes.Update &&
        action.payload.name === 'country' &&
        action.payload.value !== state[action.payload.name]
      ) {
        state[action.payload.name].value = action.payload.value;
        // If the country value is changed, clear the postal and state field values and
        // also change the postal locale to that of the country value (country code).
        return {
          ...state,
          postalCode: { ...state.postalCode, value: '', locale: action.payload.value },
          state: { ...state.state, value: '' },
        };
      }
      return state;
    },
  });

  useEffect(() => {
    if (!isEqual(value, values)) {
      onChange?.({ [field.key]: values }, getErrors());
    }
  }, [values, field, value]);

  if (matches.mediumMax()) {
    return (
      <div className='custom-field custom-field-address' css={addressFormStyles}>
        <FormRow>
          <div className='col-10'>
            <TextField {...getFieldProps('street')} label={`${labels.street}${getSuffix('street')}`} />
          </div>
          <div className='col-2'>
            <TextField {...getFieldProps('unit')} label={`${labels.unit}${getSuffix('unit')}`} />
          </div>
        </FormRow>
        <FormRow>
          <div className='col-12'>
            <TextField {...getFieldProps('city')} label={`${labels.city}${getSuffix('city')}`} />
          </div>
        </FormRow>
        <FormRow>
          <div className='col-4'>
            <DropdownField {...getFieldProps('state')} label={`${labels.state}${getSuffix('state')}`}>
              {stateOptions[values.country as CountryCodes].map(({ label, value }) => (
                <DropdownField.Option key={value} value={value}>
                  {label}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </div>
          <div className='col-4'>
            <TextField {...getFieldProps('postalCode')} label={`${labels.postalCode}${getSuffix('postalCode')}`} />
          </div>
          <div className='col-4'>
            <DropdownField {...getFieldProps('country')} label={`${labels.country}${getSuffix('country')}`}>
              {countryOptions.map(({ label, value }) => (
                <DropdownField.Option key={value} value={value}>
                  {label}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </div>
        </FormRow>
      </div>
    );
  }
  return (
    <form css={addressFormStyles} className='custom-field custom-field-address'>
      <FormRow>
        <TextField {...getFieldProps('street')} label={`${labels.street}${getSuffix('street')}`} />
      </FormRow>
      <FormRow>
        <TextField {...getFieldProps('unit')} label={`${labels.unit}${getSuffix('unit')}`} />
      </FormRow>
      <FormRow>
        <TextField {...getFieldProps('city')} label={`${labels.city}${getSuffix('city')}`} />
      </FormRow>
      <FormRow>
        <DropdownField {...getFieldProps('state')} label={`${labels.state}${getSuffix('state')}`}>
          {stateOptions[values.country as CountryCodes].map(({ label, value }) => (
            <DropdownField.Option key={value} value={value}>
              {label}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </FormRow>
      <FormRow>
        <TextField {...getFieldProps('postalCode')} label={`${labels.postalCode}${getSuffix('postalCode')}`} />
      </FormRow>
      <FormRow>
        <DropdownField {...getFieldProps('country')} label={`${labels.country}${getSuffix('country')}`}>
          {countryOptions.map(({ label, value }) => (
            <DropdownField.Option key={value} value={value}>
              {label}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </FormRow>
    </form>
  );
}

export function CustomPhoneInput<T = string>({ field, onChange, onError, value, translation }: FieldProps<T>) {
  if (!field) {
    return null;
  }
  const props = useFormField({
    type: 'phone',
    value: value as any,
    required: field.required,
  });

  useEffect(() => {
    if (props.value !== value) {
      onChange?.({ [field.key]: props.value }, props.error ? { [field.key]: props.error } : undefined);
    }
  }, [props.value, field, props.error]);

  useEffect(() => {
    if (props.error) {
      onError?.({ [field.key]: props.error });
    }
  }, [field, props.error]);

  const label = useMemo(() => {
    const lbl = translation || field.label;
    return field.required ? `${lbl} *` : lbl;
  }, [translation, field]);

  return <PhoneField className='custom-field custom-field-phone' {...props} name={field.key} label={label} />;
}

export function CustomTextBox<T = string>({
  field,
  onChange,
  onError,
  value,
  multiline,
  translation,
  type = 'text',
}: FieldProps<T>) {
  if (!field) {
    return null;
  }
  const props = useFormField({
    type,
    value: value as any,
    required: field.required,
  });

  useEffect(() => {
    if (props.value !== value) {
      onChange?.({ [field.key]: props.value }, props.error ? { [field.key]: props.error } : undefined);
    }
  }, [props.value, field, props.error]);

  useEffect(() => {
    if (props.error) {
      onError?.({ [field.key]: props.error });
    }
  }, [field, props.error]);

  const label = useMemo(() => {
    const lbl = translation || field.label;
    return field.required ? `${lbl} *` : lbl;
  }, [translation, field]);

  if (multiline) {
    return (
      <TextareaField className='custom-field custom-field-textarea-box' {...props} name={field.key} label={label} />
    );
  }
  return <TextField className='custom-field custom-field-text-box' {...props} name={field.key} label={label} />;
}

export function CustomDateBox<T = string>({ field, onChange, onError, value, translation, validator }: FieldProps<T>) {
  if (!field) {
    return null;
  }
  const props = useFormField({
    type: 'date',
    value: value as any,
    required: field.required,
    validator: ({ touched, value }) => {
      if (!touched) {
        return '';
      }
      return validator?.(value as T) || '';
    },
  });

  useEffect(() => {
    if (props.value !== value) {
      onChange?.({ [field.key]: props.value }, props.error ? { [field.key]: props.error } : undefined);
    }
  }, [props.value, field, props.error]);

  useEffect(() => {
    if (props.error) {
      onError?.({ [field.key]: props.error });
    }
  }, [field, props.error]);

  const label = useMemo(() => {
    const lbl = translation || field.label;
    return field.required ? `${lbl} *` : lbl;
  }, [translation, field]);

  return <DateField className='custom-field custom-field-date' {...props} name={field.key} label={label} />;
}

export function CustomNumberBox<T = number>({ field, onChange, onError, value, translation }: FieldProps<T>) {
  if (!field) {
    return null;
  }
  const props = useFormField({
    type: 'number',
    value: value as any,
    required: field.required,
  });

  useEffect(() => {
    if (+props.value !== value) {
      onChange?.({ [field.key]: props.value }, props.error ? { [field.key]: props.error } : undefined);
    }
  }, [props.value, field, props.error]);

  useEffect(() => {
    if (props.error) {
      onError?.({ [field.key]: props.error });
    }
  }, [field, props.error]);

  const label = useMemo(() => {
    const lbl = translation || field.label;
    return field.required ? `${lbl} *` : lbl;
  }, [translation, field]);

  return <NumberField className='custom-field custom-field-number' {...props} name={field.key} label={label} />;
}

export function CustomDropdown<T = string>({ field, onChange, onError, value, translation }: FieldProps<T>) {
  if (!field) {
    return null;
  }
  const lang = useCurrentLanguage() as AvailableLanguages;

  const props = useFormField({
    type: 'dropdown',
    value: value as any,
    required: field.required,
  });

  useEffect(() => {
    if (props.value !== value) {
      onChange?.({ [field.key]: props.value }, props.error ? { [field.key]: props.error } : undefined);
    }
  }, [props.value, field, props.error]);

  useEffect(() => {
    if (props.error) {
      onError?.({ [field.key]: props.error });
    }
  }, [field, props.error]);

  const label = useMemo(() => {
    const lbl = translation || field.label;
    return field.required ? `${lbl} *` : lbl;
  }, [translation, field]);
  return (
    <DropdownField className='custom-field custom-field-dropdown' {...props} name={field.key} label={label}>
      {field.options?.map(({ value, label, translations }) => (
        <DropdownField.Option key={value} value={value}>
          {translations?.[lang] || label}
        </DropdownField.Option>
      ))}
    </DropdownField>
  );
}

export function CustomField({ field, onChange, value, error, onError }: FieldProps) {
  const lang = useCurrentLanguage();
  const translation = field?.translations?.[lang as AvailableLanguages];
  const { t } = useTranslation('schedule');
  if (!field) {
    return null;
  }
  switch (field.type) {
    case 'checkbox':
      return (
        <CustomCheckBox
          error={error}
          field={field}
          translation={translation}
          onChange={onChange}
          onError={onError}
          value={value}
        />
      );
    case 'dropdown':
      return (
        <CustomDropdown
          error={error}
          field={field}
          translation={translation}
          onChange={onChange}
          onError={onError}
          value={value}
        />
      );
    case 'address':
      return (
        <CustomAddress
          error={error}
          field={field}
          translation={translation}
          onChange={onChange}
          onError={onError}
          value={value}
        />
      );
    case 'number':
      return (
        <CustomNumberBox
          error={error}
          field={field}
          translation={translation}
          onChange={onChange}
          onError={onError}
          value={value}
        />
      );
    case 'phone':
      return (
        <CustomPhoneInput
          error={error}
          field={field}
          translation={translation}
          onChange={onChange}
          onError={onError}
          value={value}
        />
      );
    case 'birthDate':
      return (
        <CustomDateBox
          error={error}
          validator={(date) => {
            if (dayjs().diff(date as string, 'y') > 110) {
              return t('Check your birth date, it seems out of range.');
            }
            if (dayjs().isBefore(date as string)) {
              return t('Birth date must not be in the future');
            }
            return '';
          }}
          field={field}
          translation={translation}
          onChange={onChange}
          onError={onError}
          value={value}
        />
      );
    case 'date':
      return (
        <CustomDateBox
          error={error}
          field={field}
          translation={translation}
          onChange={onChange}
          onError={onError}
          value={value}
        />
      );
    case 'email':
      return (
        <CustomTextBox
          error={error}
          type='email'
          field={field}
          translation={translation}
          onChange={onChange}
          onError={onError}
          value={value}
        />
      );
    case 'textarea':
      return (
        <CustomTextBox
          error={error}
          multiline={true}
          field={field}
          translation={translation}
          onChange={onChange}
          onError={onError}
          value={value}
        />
      );
    case 'text':
    default:
      return (
        <CustomTextBox
          error={error}
          field={field}
          translation={translation}
          onChange={onChange}
          onError={onError}
          value={value}
        />
      );
  }
}

interface CustomFieldsFormProps {
  fields?: ScheduleTypes.CustomField[];
  errors?: Record<string, string>;
  value?: Record<string, ScheduleTypes.SupportedTypes>;
  onChange: (value: any, error?: Record<string, string>) => void;
  onError?: (errors?: Record<string, string>) => void;
}

export default function CustomFieldsForm({ fields, onChange, onError, value, errors }: CustomFieldsFormProps) {
  return (
    <div css={css({ '> div': { marginBottom: theme.spacing(4) } })}>
      {fields
        ?.filter(({ show }) => show)
        .map((field) => (
          <CustomField
            key={field.key}
            error={!!errors?.[field.key]}
            field={field}
            value={value?.[field.key]}
            onChange={(fieldValue: Record<string, ScheduleTypes.SupportedTypes>, error) => {
              onChange(
                {
                  ...value,
                  ...fieldValue,
                },
                error
              );
            }}
            onError={onError}
          />
        ))}
    </div>
  );
}

type ShowFieldProps = {
  isNewUser?: boolean;
  checkNewUserOnly?: boolean;
  showIfNewUser?: boolean;
  field?: ScheduleTypes.CustomField;
  error?: boolean;
  value?: any;
  translation?: string;
  type?: 'text' | 'email';
  wrapInFormRow?: boolean;
  onChange?: (value: Partial<ScheduleTypes.PatientInfo>, errors?: Record<string, string>) => void;
  onError?: (errors?: Record<string, string>) => void;
};

export const shouldShow = ({ checkNewUserOnly, showIfNewUser, isNewUser, field }: ShowFieldProps) => {
  if (!field) {
    return false;
  }
  if (!field?.show) {
    return false;
  }

  if (checkNewUserOnly) {
    if (showIfNewUser) {
      if (field.newUserOnly && !isNewUser) {
        return false;
      }
      if (!field.newUserOnly) {
        return false;
      }
    } else {
      if (field.newUserOnly) {
        return false;
      }
    }
  }
  return true;
};
export function ShowField({
  checkNewUserOnly,
  showIfNewUser,
  isNewUser,
  field,
  wrapInFormRow = true,
  onChange,
  onError,
  error,
  value,
}: ShowFieldProps) {
  if (
    !field ||
    !shouldShow({
      checkNewUserOnly,
      showIfNewUser,
      isNewUser,
      field,
    })
  ) {
    return null;
  }

  if (wrapInFormRow) {
    return (
      <FormRow>
        <CustomField error={error} field={field} onChange={onChange} onError={onError} value={value} />
      </FormRow>
    );
  }
  return <CustomField error={error} field={field} onChange={onChange} onError={onError} value={value} />;
}
