import { css } from '@emotion/react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import { PersonHelpers } from '@frontend/api-person';
import { ActionsUI } from '@frontend/contact-actions';
import { formatDate } from '@frontend/date';
import { FirstWordBoldText, WidgetizedDashboardTrackingIds } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { IconButton, PopoverMenu, PopoverMenuItem, Text, usePopoverMenu } from '@frontend/design-system';
import { useGetAppointmentDetails } from '../../hooks';

interface Props {
  appointmentInfo: Appointment;
}

const appointmentsWidgetTrackingId =
  WidgetizedDashboardTrackingIds.widgetSpecificTrackingId('appointments-widget-action');

export const AppointmentCard = ({ appointmentInfo }: Props) => {
  const { t } = useTranslation('schedule');
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const popoverMenuProps = usePopoverMenu();

  const person = appointmentInfo.person ?? {};
  const personName = PersonHelpers.getFullName({
    FirstName: person.firstName,
    LastName: person.lastName,
    PreferredName: person.preferredName,
  });
  const fromTime = formatDate(appointmentInfo.start, 'h:mm A');
  const toTime = formatDate(dayjs(appointmentInfo.start).add(appointmentInfo.duration ?? 0, 'm'), 'h:mm A');
  const personPhoneNumber = person.mobilePhone || person.homePhone || person.workPhone || '';

  const { triggerProps: viewAppointmentTriggerProps, Modal: AppointmentDetailsModal } = useGetAppointmentDetails({
    appointmentId: appointmentInfo.id || '',
    locationId: appointmentInfo.locationId || '',
    appointments: [appointmentInfo],
  });

  const { Modal: PhoneCallModal, triggerProps: callTriggerProps } = ActionsUI.actions.usePhoneCallAction({
    context: {
      personId: person.personId ?? '',
      phoneNumber: personPhoneNumber,
    },
  });

  const {
    disabled: disableMessageAction,
    disabledDetails: disabledMessageDetails,
    Modal: MessageModal,
    triggerProps: messageTriggerProps,
  } = ActionsUI.actions.useMessageAction({
    context: {
      personId: person.personId ?? '',
      locationId: selectedLocationIdsWithParents[0] ?? '',
      phoneNumber: personPhoneNumber,
    },
  });

  const handleViewAppointmentClick = () => {
    popoverMenuProps.close();
    viewAppointmentTriggerProps.onClick();
  };

  return (
    <li css={[containerStyle, { borderColor: theme.colors.warning50 }]}>
      <div>
        <FirstWordBoldText size='medium'>{personName}</FirstWordBoldText>
        <Text size='small' color='light'>
          {fromTime} - {toTime}
        </Text>
      </div>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          {...callTriggerProps}
          label={t('Call {{name}}', {
            name: personName || personPhoneNumber,
          })}
          showLabelOnHover
          trackingId={appointmentsWidgetTrackingId('call')}
        >
          <Icon name='phone-small' />
        </IconButton>

        <IconButton
          {...messageTriggerProps}
          disabled={disableMessageAction}
          label={
            disableMessageAction
              ? disabledMessageDetails
              : t('Message {{name}}', {
                  name: personName || personPhoneNumber,
                })
          }
          showLabelOnHover
          trackingId={appointmentsWidgetTrackingId('message')}
        >
          <Icon name='messaging-small' />
        </IconButton>
        <IconButton
          {...popoverMenuProps.getTriggerProps()}
          label={t('More Menu')}
          size='small'
          trackingId={appointmentsWidgetTrackingId('more')}
        >
          <Icon name='more-small' />
        </IconButton>
        <PopoverMenu {...popoverMenuProps.getMenuProps()} title=''>
          <PopoverMenuItem
            {...popoverMenuProps.getItemProps({ index: 0 })}
            onClick={handleViewAppointmentClick}
            trackingId={appointmentsWidgetTrackingId('view-appointment')}
          >
            <Icon name='calendar-small' />
            {t('View Appointment')}
          </PopoverMenuItem>
        </PopoverMenu>
        {PhoneCallModal}
        {MessageModal}
        {AppointmentDetailsModal}
      </div>
    </li>
  );
};

const containerStyle = css({
  borderLeft: '4px solid',
  background: theme.colors.white,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(0.5, 2),
  marginBottom: theme.spacing(1),
  boxShadow: theme.shadows.light,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
