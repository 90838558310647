import dayjs from 'dayjs';
import { USERS_QUERY_LIMIT } from '../constants';
import { StreamInstance, StreamUserResponse, StreamWeavePresence } from '../types';
import { getTeamId } from '../utils';

const getUserPresence = (presence: StreamWeavePresence | undefined) => {
  const { online, expiresAt } = presence || {};

  if (!online || !expiresAt) {
    return false;
  }
  const now = new Date();

  if (dayjs(now).isAfter(dayjs(expiresAt))) {
    return false;
  }
  return online;
};

export const formatUser = (user: StreamUserResponse) => {
  // enforce the user object to have a name property
  const { name = ' ' } = user;
  const [firstName, lastName] = name.split(' ');
  return {
    firstName,
    lastName,
    userID: user.id,
    status: {
      online: getUserPresence(user.weavePresence),
      status: {
        duration: user.userStatus?.statusDuration,
        expiry: user.userStatus?.statusExpiration,
        text: user.userStatus?.statusText,
      },
    },
  };
};

export const fetchUsers = async (client: StreamInstance, orgId: string) => {
  let hasMoreUsers = true;
  const userList: StreamUserResponse[] = [];
  let offset = 0;
  while (hasMoreUsers) {
    const { users } = await client.queryUsers(
      { teams: { $contains: getTeamId(orgId) } },
      { name: 1 },
      { presence: true, limit: USERS_QUERY_LIMIT, offset }
    );
    userList.push(...users);
    offset += USERS_QUERY_LIMIT;
    hasMoreUsers = users.length === USERS_QUERY_LIMIT;
  }

  // during the migration, users which were not part of the location anymore were named as 'deleted user'
  // we are filtering out those users
  // This requires a permanent fix from the backend

  return userList.filter((user) => !(user.name === 'deleted user' || user.id === client.user?.id)).map(formatUser);
};
