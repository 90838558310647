import { useTranslation } from '@frontend/i18n';
import CallIntelMedia from '../../../assets/call-intel-promotion.png';
import { FeaturePromotionPageType } from '../../../types';
import { FeaturePromotionBasePage } from '../FeaturePromotionBasePage';

export const CallIntelPromotion: FeaturePromotionPageType = (props) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'call-intel-promotion' });
  return (
    <FeaturePromotionBasePage
      title={t('Real Time Phone Analytics')}
      description={t('Monitor your phone call conversions and appointment data for all of your locations.')}
      media={{ src: CallIntelMedia, alt: t('Call Intelligence Example') }}
      {...props}
    />
  );
};
