import { useEffect } from 'react';
import { FormPacketSelectorHooks } from '@frontend/form-packet-selector';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { useSendFormsContext, useSendFormsStore } from '../providers';

interface GenerateLinkResult {
  success: boolean;
}
interface UseGeneratedLinkResults {
  generateLink: (deliveryMethod: string) => Promise<GenerateLinkResult>;
}

export const useGeneratedLink = (): UseGeneratedLinkResults => {
  const { locationId } = useSendFormsContext();
  const { t } = useTranslation('forms');
  const { personId, acceptedFiles, appointmentId, formOrPacketToSend, setIsGeneratingSolictedLink, setSolicitedLink } =
    useSendFormsStore([
      'personId',
      'acceptedFiles',
      'appointmentId',
      'formOrPacketToSend',
      'setIsGeneratingSolictedLink',
      'setSolicitedLink',
    ]);
  const alert = useAlert();
  const { isGeneratingSolicitedLink, generateSolicitedLink } = FormPacketSelectorHooks.useSolicitedLink({
    locationId,
    personId,
  });

  useEffect(() => {
    setIsGeneratingSolictedLink(isGeneratingSolicitedLink);
  }, [isGeneratingSolicitedLink]);

  const generateLink = async (deliveryMethod: string): Promise<GenerateLinkResult> => {
    if (!formOrPacketToSend || !formOrPacketToSend.id) {
      setSolicitedLink('');
      setIsGeneratingSolictedLink(false);
      return { success: false };
    }

    try {
      const newLink = await generateSolicitedLink({
        attachments: acceptedFiles,
        appointmentId,
        formOrPacketToSend,
        deliveryMethod,
      });
      if (!newLink) {
        setSolicitedLink('');
        setIsGeneratingSolictedLink(false);
        return { success: false };
      }
      setSolicitedLink(newLink);
      setIsGeneratingSolictedLink(isGeneratingSolicitedLink);
      return { success: true };
    } catch (error) {
      alert.error(t('Error occurred while generating the form link. {{error}}', { error: error }));
      return { success: false };
    }
  };

  return { generateLink };
};
