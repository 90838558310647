import { useNavigate } from '@tanstack/react-location';
import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { useOutboundMessagePresent } from '../hooks/use-outbound-message-present';

/**
 * @dashboard-widget
 *
 * id: add-message
 * title: New Text
 * description: Create and send a new message with just one click.
 * icon: message-small
 */

export const AddMessageWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('messages');
  const { widgetTrackingId } = useDashboardWidget();
  const navigate = useNavigate();

  const { data: isOutboundMessagePresent, isLoading, isError } = useOutboundMessagePresent();

  return (
    <DashboardWidget.ActionButton
      iconName='kiosk'
      title={isOutboundMessagePresent || isLoading || isError ? t('Message') : t('Send your first message')}
      onClick={() => navigate({ to: '/messages/inbox/new' })}
      trackingId={widgetTrackingId('action')}
    />
  );
};

AddMessageWidget.config = {
  size: 'tiny-slim',
  feature: 'messaging',
};
