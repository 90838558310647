import { IconColorType } from '@frontend/icons';
import { Variants } from '../button.type';
import { primaryStyles } from './primary.styles';
import { secondaryStyles } from './secondary.styles';
import { tertiaryStyles } from './tertiary.styles';

export const getIconColor: (variant: Variants, disabled?: boolean) => IconColorType = (variant, disabled) => {
  if (disabled) {
    switch (variant) {
      case 'primary':
        return 'white';
      case 'secondary':
        return 'disabled';
      case 'tertiary':
        return 'disabled';
    }
  } else {
    switch (variant) {
      case 'primary':
        return 'white';
      case 'secondary':
        return 'default';
      case 'tertiary':
        return 'primary';
      default:
        return 'primary';
    }
  }
};

export const getVariantStyles = ({ variant, size, loading, iconOnly }) => {
  switch (variant) {
    case 'primary':
      return primaryStyles({ size, isLoading: loading, iconOnly });
    case 'secondary':
      return secondaryStyles({ size, isLoading: loading, iconOnly });
    case 'tertiary':
      return tertiaryStyles({ size, isLoading: loading, iconOnly });
    default:
      return primaryStyles({ size, isLoading: loading, iconOnly });
  }
};
