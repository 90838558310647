import { useLineKeyContext, useLineKeyState } from '../store/line-key-provider';
import { LineKeyType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { genUUIDV4 } from '@frontend/string';
import { useMemo } from 'react';
import { LineKeysApi } from '@frontend/api-line-keys';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { useQuery } from 'react-query';
import { ModalControlTriggerProps } from '@frontend/design-system';

type Props = {
  openUserExtensionsModal: ModalControlTriggerProps['onClick'];
  openContactsModal: ModalControlTriggerProps['onClick'];
};

export type ConfigItem = {
  title: string;
  subtitle: string;
  disabled?: boolean;
  onClick: () => void;
};

export const useLineKeyMenuConfig = ({ openUserExtensionsModal, openContactsModal }: Props) => {
  const { lineKeys } = useLineKeyContext();
  const { addNewKey, finalLineKeys } = useLineKeyState(['addNewKey', 'finalLineKeys']);
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const locationId = locationIds?.[0] ?? '';

  const existingParkSlotIndexes = useMemo(() => {
    return (
      Object.values(finalLineKeys ?? lineKeys ?? {})?.reduce(
        (acc, lineKey) => ({
          ...acc,
          [lineKey.parkSlotNumber > 0 ? lineKey.parkSlotNumber : 'empty']: true,
        }),
        {} as Record<number, boolean>
      ) ?? {}
    );
  }, [finalLineKeys]);

  const { data: lineKeyOptions } = useQuery({
    queryKey: ['line-keys', 'line-key-options', locationId],
    queryFn: () => LineKeysApi.GetLineKeyOptions(locationId),
  });

  const config: ConfigItem[] = [
    {
      title: 'Hold',
      subtitle: 'Universal hold slots, also known as park',
      disabled: false,
      onClick: () => {
        const sortedIndexs = Object.keys(existingParkSlotIndexes)
          .slice()
          .sort((a, b) => {
            return +a - +b;
          });
        let nextNumber = 1;
        for (const num of sortedIndexs) {
          if (+num === nextNumber) {
            nextNumber++;
          } else {
            break;
          }
        }
        addNewKey({
          [genUUIDV4()]: {
            lineKeyType: LineKeyType_Enum.PARK_SLOT,
            name: `Hold ${nextNumber}`,
            parkSlotNumber: nextNumber,
            contactId: '',
            extensionId: '',
            presenceEnabled: false,
            intercomEnabled: false,
            dataEndpointId: '',
          },
        });
      },
    },
    {
      title: 'User Extension',
      subtitle: 'Speed dial User Extensions with options to monitor activity or intercom',
      onClick: openUserExtensionsModal,
    },
    {
      title: 'Office Extension',
      subtitle: 'Speed dial office extensions',
      disabled: !lineKeyOptions?.officeExtensions.length,
      onClick: () => {
        addNewKey({
          [genUUIDV4()]: {
            lineKeyType: LineKeyType_Enum.OFFICE_EXTENSION,
            name: `${lineKeyOptions?.officeExtensions?.[0]?.name}`,
            extensionId: `${lineKeyOptions?.officeExtensions?.[0]?.extensionId}`,
            contactId: '',
            presenceEnabled: false,
            intercomEnabled: false,
            dataEndpointId: '',
            parkSlotNumber: 0,
          },
        });
      },
    },
    {
      title: 'Voicemail',
      subtitle: "Check your office's voicemail boxes",
      disabled: !lineKeyOptions?.voicemailExtensions.length,
      onClick: () => {
        addNewKey({
          [genUUIDV4()]: {
            lineKeyType: LineKeyType_Enum.VOICEMAIL_EXTENSION,
            name: `${lineKeyOptions?.voicemailExtensions?.[0].name} ${lineKeyOptions?.voicemailExtensions?.[0].extensionNumber}`,
            extensionId: `${lineKeyOptions?.voicemailExtensions?.[0].extensionId}`,
            contactId: '',
            parkSlotNumber: 0,
            presenceEnabled: false,
            intercomEnabled: false,
            dataEndpointId: '',
          },
        });
      },
    },
    {
      title: 'Contact',
      subtitle: 'Speed dial contacts in your local contact directory',
      onClick: openContactsModal,
    },
  ];
  return {
    config,
  };
};
