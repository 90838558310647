import { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { StreamChat } from 'stream-chat';
import { getUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { useAppScopeStore } from '@frontend/scope';
import { CHAT_TOKEN_URL, TEAM_CHAT_API_INVALIDATION_KEY } from '../constants';
import { useTeamChatStore } from '../providers';
import { formatUser, getStreamUserId } from '../utils';

interface ApiAndToken {
  apiKey: string;
  token: string;
}

interface UseTeamChatConnect {
  connect: (orgId: string) => Promise<{ client: StreamChat | null }>;
  isTokenReady: boolean;
}

export const useTeamChatConnect = (): UseTeamChatConnect => {
  const queryClient = useQueryClient();
  const { selectedOrgId } = useAppScopeStore();
  const {
    isTrayOpen,
    isConnected,
    streamClient,
    teamChatOrgId,
    isInitializing,
    reset,
    setTeamChatOrgId,
    initialize,
    setTotalUnreadCount,
    setIsConnected,
  } = useTeamChatStore([
    'isTrayOpen',
    'initialize',
    'isConnected',
    'reset',
    'setTeamChatOrgId',
    'streamClient',
    'teamChatOrgId',
    'isInitializing',
    'setTotalUnreadCount',
    'setIsConnected',
  ]);
  const isOrgSwitched = !!teamChatOrgId && teamChatOrgId !== selectedOrgId;
  const isInitialUnreadCountSet = useRef(false);

  const [apiAndToken, setApiAndToken] = useState<ApiAndToken | null>(null);

  const connect = useCallback(
    async (orgId: string) => {
      if (isConnected) {
        console.info('Already connected to the chat client');
        return { client: streamClient };
      }

      if (!apiAndToken) {
        console.error('API key and token not available');
        return { client: null };
      }

      const userId = getUser()?.userID;

      if (!userId) {
        console.error('User ID not available');
        return { client: null };
      }

      const client = StreamChat.getInstance(apiAndToken.apiKey);
      // Adding org id to the user id to make it compatible with the stream chat
      const streamUserId = getStreamUserId({ orgId, userId });

      // Initialize current user with userId
      await client.connectUser({ id: streamUserId }, apiAndToken.token);

      if (!client.user) {
        console.error('Failed to connect to the chat client');
        return { client: null };
      }

      initialize({
        currentUser: formatUser(client.user),
        isConnected: !!client.user,
        streamClient: client,
      });

      return { client };
    },
    [apiAndToken, initialize, isConnected, streamClient]
  );

  useEffect(() => {
    if (isOrgSwitched) {
      reset();
    }
    setTeamChatOrgId(selectedOrgId);
  }, [isOrgSwitched, selectedOrgId]);

  useEffect(() => {
    const fetchApiAndToken = async () => {
      // If it is not updated on org switch then stream throws error that userToken does not have a user_id or is not matching with user.id
      const response = await queryClient.fetchQuery(`${TEAM_CHAT_API_INVALIDATION_KEY}-${selectedOrgId}`, () =>
        http.get(CHAT_TOKEN_URL)
      );
      setApiAndToken(response as ApiAndToken);
    };

    fetchApiAndToken();
  }, [selectedOrgId, queryClient]);

  useEffect(() => {
    (async function () {
      if (!isTrayOpen && !isInitializing && !isInitialUnreadCountSet.current && streamClient?.user) {
        // get unread count on initial load and disconnect user
        const unreadCount = await streamClient.getUnreadCount();
        setTotalUnreadCount(unreadCount.total_unread_count);
        isInitialUnreadCountSet.current = true;
        streamClient.disconnectUser();
        setIsConnected(false);
      } else if (isTrayOpen && !isInitializing && streamClient && !streamClient.user) {
        // connect on tray open
        await connect(selectedOrgId);
        setIsConnected(true);
      } else if (!isTrayOpen && streamClient?.user && isInitialUnreadCountSet.current) {
        // disconnect on tray close
        streamClient.disconnectUser();
        setIsConnected(false);
      }
    })();
  }, [isTrayOpen, streamClient, isInitializing, selectedOrgId]);

  return {
    connect,
    isTokenReady: !!apiAndToken?.token,
  };
};
