import { Avatar, ChatItem, Text } from '@frontend/design-system';
import { useBulkListContext } from '@frontend/bulk-list-provider';

import { Trans, useTranslation } from '@frontend/i18n';
import { UsersTypes } from '@frontend/user-helpers';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { ReactNode, forwardRef } from 'react';
import { EXPORT_IMG_ID_PREFIX } from '../../constants';
import { SchemaSMSSharedEnums, SchemaSMSSharedModels } from '@frontend/api-messaging';

type ConversationExportProps = {
  conversation: SchemaSMSSharedModels.SMS[];
  locationName: string;
  locationUsers: UsersTypes.UserProfile[];
  downloadedMedia: Record<string, string>;
  timestamp?: Date;
  personName?: string;
};

type DividedConversation = (SchemaSMSSharedModels.SMS | number)[];

export const ConversationExport = forwardRef<HTMLDivElement, ConversationExportProps>(
  ({ conversation, locationName, locationUsers, downloadedMedia, timestamp = new Date(), personName }, ref) => {
    const { t } = useTranslation('messages');
    const { selectedItems, hasSelectedAll } = useBulkListContext();

    const dividedConversation = hasSelectedAll
      ? [...conversation]
      : conversation.reduce((prev, currentMessage, index, arr): DividedConversation => {
          const currentItemIsSelected = selectedItems.includes(currentMessage.id);
          if (prev.length === 0) {
            if (currentItemIsSelected) return [currentMessage];
            return [];
          }
          if (currentItemIsSelected) {
            return prev.concat(currentMessage);
          }
          const lastItem = prev.at(-1);
          if (typeof lastItem === 'number') {
            if (index === arr.length - 1) {
              return prev.slice(0, -1);
            }
            return [...prev.slice(0, -1), lastItem + 1];
          }
          return prev.concat(1);
        }, [] as DividedConversation);

    return (
      <div
        id='page'
        ref={ref}
        css={{
          width: '100%',
          '@page': {
            width: '100%',
            height: '100%',
            margin: theme.spacing(6),
          },
          '@media': {
            print: {
              height: '100%',
            },
          },
        }}
      >
        <div
          css={{
            columnCount: 2,
            columnGap: '10%',
            columnRule: `1px solid ${theme.colors.neutral30}`,
            columnFill: 'auto',
            padding: theme.spacing(2),
          }}
        >
          {dividedConversation.reverse().map((item, index) => {
            if (typeof item === 'number') {
              return <SkippedMessages key={`export-skip-${index}`} count={item} />;
            }

            const isOutbound = item.direction === SchemaSMSSharedEnums.Direction.DIRECTION_OUTBOUND;
            const sender = isOutbound ? locationUsers.find((user) => item.createdBy === user.UserID) : undefined;
            const isAutogenerated = !!item.autogeneratedBy;
            const senderName = isOutbound
              ? isAutogenerated
                ? t('Auto-Message')
                : sender
                ? `${sender.FirstName} ${sender.LastName}`.trim()
                : undefined
              : personName;
            const errorText =
              item.status === SchemaSMSSharedEnums.Status.STATUS_NOT_SENT ||
              item.status === SchemaSMSSharedEnums.Status.STATUS_ERROR
                ? t('Not Delivered')
                : undefined;

            return (
              <PrintableMessage key={item.id} isFirst={index === 0} isOutbound={isOutbound}>
                <ChatItem
                  direction={isOutbound ? 'outbound' : 'inbound'}
                  backgroundColor={!!item.autogeneratedBy ? theme.colors.success5 : undefined}
                  avatar={<Avatar size='small' name={senderName} isWeave={!!item.autogeneratedBy} />}
                  timestamp={new Date(item.createdAt).toLocaleString(undefined, {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  })}
                  error={errorText}
                  senderName={`${senderName ? senderName + ':' : ''} ${
                    isOutbound ? formatPhoneNumber(item.locationPhone) : formatPhoneNumber(item.personPhone)
                  }`.trim()}
                  css={{
                    '.timestamp, .sender-name': {
                      opacity: 1,
                    },
                  }}
                >
                  {!!item.numMedia &&
                    item.media.map((mediaItem) => (
                      <ChatItem.Image
                        id={`${EXPORT_IMG_ID_PREFIX}${mediaItem.mediaId}`}
                        key={mediaItem.mediaId}
                        src={downloadedMedia ? downloadedMedia[mediaItem.mediaId] : undefined}
                        maxWidth='60%'
                        maxHeight='40%'
                      />
                    ))}
                  {!!item.body && (
                    <ChatItem.Bubble
                      text={item.body}
                      backgroundColor={!!item.autogeneratedBy ? theme.colors.success5 : undefined}
                      maxWidth='90%'
                    />
                  )}
                </ChatItem>
              </PrintableMessage>
            );
          })}
        </div>
        <footer
          css={{
            borderTop: `solid 1px ${theme.colors.neutral40}`,
            width: '100%',
            backgroundColor: theme.colors.neutral5,
            padding: theme.spacing(0, 2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pageBreakInside: 'avoid',
            pageBreakBefore: 'always',
            marginTop: theme.spacing(2),
            position: 'fixed',
            bottom: 0,
          }}
        >
          <Trans t={t} locationName={locationName}>
            <Text size='large' css={{ margin: theme.spacing(1, 0) }}>
              Downloaded from Weave for {{ locationName }}
            </Text>
          </Trans>
          <Text>
            {timestamp.toLocaleString(undefined, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              timeZoneName: 'short',
              hourCycle: 'h12',
              hour: 'numeric',
              minute: '2-digit',
              second: '2-digit',
            })}
          </Text>
        </footer>
      </div>
    );
  }
);

type PrintableMessageProps = {
  isFirst: boolean;
  isOutbound: boolean;
  children: ReactNode;
};

const PrintableMessage = ({ isFirst, isOutbound, children }: PrintableMessageProps) => {
  return (
    <div
      css={[
        {
          width: '100%',
          display: 'flex',
          justifyContent: isOutbound ? 'end' : 'start',
          pageBreakInside: 'avoid',
          pageBreakBefore: 'auto',
          '@media': {
            print: {
              pageBreakInside: 'avoid',
            },
          },
        },
        paginationFixItemStyle(isFirst),
      ]}
    >
      <div
        css={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(0.25),
          alignItems: isOutbound ? 'end' : 'start',
        }}
      >
        {children}
      </div>
    </div>
  );
};

type SkippedMessagesProps = {
  count: number;
};

const SkippedMessages = ({ count }: SkippedMessagesProps) => {
  const { t } = useTranslation('messages');

  const itemsString = count > 1 ? t('items not downloaded') : t('item not downloaded');

  return (
    <div
      css={[
        {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          height: '65px',
        },
        paginationFixItemStyle(),
      ]}
    >
      <div css={{ height: '1px', backgroundColor: theme.colors.neutral40, flexGrow: 1 }} />
      <Trans t={t} count={count} itemsString={itemsString}>
        <Text css={{ padding: theme.spacing(0, 2), whiteSpace: 'nowrap' }}>
          {{ count }} {{ itemsString }}
        </Text>
      </Trans>
      <div css={{ height: '1px', backgroundColor: theme.colors.neutral40, flexGrow: 1 }} />
    </div>
  );
};

// Yes, this padding and margin don't make sense, but they trick the pagination logic of the print workflow
// into leaving enough space for the footer at the bottom of each page
const paginationFixItemStyle = (isFirst = false) =>
  css({ paddingBottom: '50px', marginTop: isFirst ? undefined : '-35px' });
