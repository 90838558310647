import { FC, useEffect } from 'react';
import { isWeaveUser } from '@frontend/auth-helpers';
import { PaddedContent } from '@frontend/chat';
import { ContentLoader, ModalControlModalProps } from '@frontend/design-system';
import { useTeamChatStore } from '../providers';
import { ChatListView } from './chat-list/chat-list-view/chat-list-view';
import { chatContentStyle, containerStyle, paddedStyle } from './chat-tray.styles';
import { Conversation } from './conversation';
import { TrayTopBar } from './tray-top-bar/tray-top-bar';

interface ChatTrayProps {
  modalProps: ModalControlModalProps;
}

export const ChatTray: FC<ChatTrayProps> = ({ modalProps }) => {
  const { isInitializing, setTrayOpen } = useTeamChatStore(['setTrayOpen', 'isInitializing']);

  useEffect(() => {
    setTrayOpen(true);

    return () => {
      setTrayOpen(false);
    };
  }, []);

  if (isWeaveUser()) {
    return <PaddedContent css={paddedStyle}>Weave users do not have access to Team Chat</PaddedContent>;
  }

  return (
    <div css={containerStyle}>
      <TrayTopBar modalProps={modalProps} />
      <div css={chatContentStyle}>
        <ChatListView />
        <Conversation />
      </div>
      <ContentLoader show={isInitializing} />
    </div>
  );
};
