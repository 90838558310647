import { useRef, useState } from 'react';
import { css } from '@emotion/react';
import { CallGroup, ListDevice } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { CallGroupsTypes } from '@frontend/api-call-groups';
import { theme } from '@frontend/theme';
import { ContentLoader, Modal, ModalControlModalProps, Stepper } from '@frontend/design-system';
import { useCreateSoftphone } from '../../../store/create-softphone-provider';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { ConfirmSoftphoneModalExit } from './confirm-exit';
import { useCreateSoftphoneMutation } from './hooks/use-create-softphone';
import { useCreateSoftphoneForm } from './hooks/use-create-softphone-form';
import { FirstStep } from './steps/first-step';
import { FourthStep } from './steps/fourth-step';
import { SecondStep } from './steps/second-step';
import { SuccessPage } from './steps/success-page';
import { ThirdStep } from './steps/third-step';

const stepTitleText = {
  1: 'User & Extension',
  2: 'Device Name',
  3: 'E911',
  4: 'Call Groups',
};

export type CreateSoftphonePropTypes = {
  getFieldProps: ReturnType<typeof useCreateSoftphoneForm>['getFieldProps'];
  values?: ReturnType<typeof useCreateSoftphoneForm>['values'];
  locationIds: string[];
  isMultiLocation?: boolean;
  tenantId?: string;
};

export type CallGroupDevicesListType = CallGroupsTypes.CallGroup & {
  devices?: ListDevice[];
  locationId: string;
};

export const CreateSoftphoneStepper = ({ modalProps }: { modalProps: ModalControlModalProps }) => {
  const stepperComponents = [FirstStep, SecondStep, ThirdStep, FourthStep];
  const { showConfirmedExitModal } = useCreateSoftphone(['showConfirmedExitModal']);
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');

  const locations =
    settingsTenantLocation?.children && settingsTenantLocation.children.length > 0
      ? settingsTenantLocation?.children
      : [settingsTenantLocation].filter(Boolean);

  const locationIds = locations.map((loc: { locationID: string }) => loc.locationID);
  const isMultiLocation = settingsTenantLocation?.locationType === 'unify';
  const initialCallGroupSelections = useRef<Record<string, boolean>>({});
  const [selectedCallGroupIds, setSelectedCallGroupIds] = useState<CallGroupDevicesListType[]>([]);

  const { formProps, getFieldProps, values } = useCreateSoftphoneForm({});

  const ids: CallGroup[] = selectedCallGroupIds.map((group) => ({ id: group.ID, name: group.name }));
  const { onSubmit, isLoading, isFormSubmitted } = useCreateSoftphoneMutation(locationIds[0]);

  const props = {
    getFieldProps,
    values,
    locationIds,
    isMultiLocation,
    initialCallGroupSelections,
    setSelectedCallGroupIds,
    tenantId: settingsTenantLocation?.phoneTenantId,
  };

  return (
    <>
      <Modal
        disableCloseOnOverlayClick
        disableCloseOnEscape
        css={css`
          height: auto;
          padding: 0;
          .wv-modal-flex-content,
          .wv-modal-header {
            padding: 0;
            margin: 0;
          }
          div[class*='ModalActions'] {
            padding: 0;
          }
        `}
        maxWidth={showConfirmedExitModal ? 600 : 800}
        {...modalProps}
      >
        <form
          {...formProps}
          css={css`
            overflow: hidden;
          `}
        >
          <ContentLoader show={isLoading} />
          <Stepper
            maxWidth={800}
            isCompleted={isFormSubmitted}
            stepTitleText={stepTitleText}
            isVerticalSingleView
            css={css`
              .step-container .step-card {
                ${!showConfirmedExitModal && `border-left: 1px solid ${theme.colors.neutral20}`};
              }
              .stepper-vertical {
                padding: ${theme.spacing(0.5)} ${theme.spacing(3)};
                ${showConfirmedExitModal && `display: none;`};
              }
            `}
          >
            {stepperComponents?.map((Component) => {
              return (
                <Stepper.Card>
                  {showConfirmedExitModal ? (
                    <ConfirmSoftphoneModalExit />
                  ) : (
                    <Component onSubmit={() => onSubmit(values, ids)} {...props} />
                  )}
                </Stepper.Card>
              );
            })}
            <Stepper.Success>
              <SuccessPage {...props} />
            </Stepper.Success>
          </Stepper>
        </form>
      </Modal>
    </>
  );
};

export const spacingStyles = (spacing: number) => css`
  margin-bottom: ${theme.spacing(spacing)};
`;

export const inputFieldStyles = css`
  width: 325px;
`;

export const stepperButtonBarStyle = css`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing(3)};

  button {
    height: ${theme.spacing(5)};
  }

  & > button:first-child {
    margin-left: 0;
    width: fit-content;
    border: 0;
    color: ${theme.colors.primary50};
  }
`;
