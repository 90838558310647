import { FC } from 'react';
import { Avatar, AvatarGroup, Text } from '@frontend/design-system';
import { StreamUser } from '../../../types';
import { memberCountStyle } from './chat-avatar.styles';

interface ChatAvatarProps {
  members: StreamUser[];
  shouldShowOnlineStatus?: boolean;
  showGroupAvatars?: boolean;
  size?: string;
}

/* 
  Adding a prop called shouldShowOnlineStatus to show the online status of the user, since this component can be reused
  in different places where we may or may not want to show the online status.
  Making it default so that, on some places where we don't want to show online, we can hide it.
 */
export const ChatAvatar: FC<ChatAvatarProps> = ({ members, size, shouldShowOnlineStatus = true, showGroupAvatars }) => {
  // Ideally this should not happen, but in case if there are no members in a chat then we should display an empty avatar
  if (members.length === 0) {
    // @ts-expect-error size prop is of type SizeProps, which is not exported. Hence passing size as string and ignoring the error
    return <Avatar size={size || 'small'} isUser={isUser} />;
  }

  const name = `${members[0].firstName} ${members[0].lastName}`;

  if (members.length > 1 && !showGroupAvatars) {
    return (
      <Text weight='bold' size='small' css={memberCountStyle}>
        {members.length}
      </Text>
    );
  } else if (members.length > 1 && showGroupAvatars) {
    return (
      // @ts-expect-error size prop is of type SizeProps, which is not exported. Hence passing size as string and ignoring the error
      <AvatarGroup fallbackName={''} size={size || 'small'}>
        {members.map((member) => (
          <Avatar firstName={member.firstName} lastName={member.lastName} key={member.userID} isUser />
        ))}
      </AvatarGroup>
    );
  }

  return (
    // @ts-expect-error size prop is of type SizeProps, which is not exported. Hence passing size as string and ignoring the error
    <Avatar size={size || 'xs'} name={name} isUser={isUser}>
      {members.length == 1 && shouldShowOnlineStatus && members[0].status.online && <Avatar.Dot variant='success' />}
    </Avatar>
  );
};
