import { theme } from '@frontend/theme';
import { useTranslation, Trans } from '@frontend/i18n';
import { Modal, Text, TextLink, useModalControl } from '@frontend/design-system';
import { css } from '@emotion/react';

const MODAL_MAX_WIDTH = 550;

interface SubscribeTermsModalProps {
  productServiceName: string;
  trackingIdPrefix?: string;
  additionalTerms?: string[];
  onCloseModal: () => void;
}

export const SubscribeTermsModal = ({
  productServiceName,
  additionalTerms,
  onCloseModal,
}: SubscribeTermsModalProps) => {
  const { t } = useTranslation('subscribe', { keyPrefix: 'TermsModal' });
  const subscribeTermsModalProps = useModalControl();

  return (
    <Modal {...subscribeTermsModalProps.modalProps} maxWidth={MODAL_MAX_WIDTH} show={true} onClose={onCloseModal}>
      <Modal.Header>Terms and Conditions</Modal.Header>

      <Modal.Body>
        <Text css={termsParagraphStyles}>{t('You are at least 18 years of age.')}</Text>

        <Trans t={t}>
          <Text css={termsParagraphStyles}>
            {{ productServiceName }} is provided subject to{' '}
            <TextLink
              // trackingId={`${trackingIdPrefix}${ids.weaveTermsPageLink1}`}
              rel='noreferrer'
              target='_blank'
              href='https://www.getweave.com/legal/terms/'
            >
              Weave's Terms of Service
            </TextLink>{' '}
            which are incorporated herein by reference and govern your use of the Weave {{ productServiceName }}.
          </Text>
        </Trans>

        <Text css={termsParagraphStyles}>
          {t(
            "By accessing or otherwise using {{productServiceName}} you expressly acknowledge agree to Weave's Terms of Service.",
            { productServiceName }
          )}
        </Text>

        <Text css={termsParagraphStyles}>
          {t(
            'You authorize Weave to initiate an automatic payment. The additional cost for {{productServiceName}} will reflect on your next monthly billing cycle.',
            { productServiceName }
          )}
        </Text>

        <Text css={termsParagraphStyles}>
          {t(
            'This authority will remain in effect until you withdraw your consent and allow us a reasonable time to respond.  You can stop payment on an entry by notifying the sender 3 business days in advance.'
          )}
        </Text>

        <Trans t={t}>
          <Text css={termsParagraphStyles}>
            If you decide to cancel your {{ productServiceName }}, you must contact your Customer Support team using the
            contact methods listed{' '}
            <TextLink
              // trackingId={`${trackingIdPrefix}${ids.contactMethodLink}`}
              rel='noreferrer'
              target='_blank'
              href='https://www.weavehelp.com/hc/en-us/p/contact-us'
            >
              here
            </TextLink>{' '}
            . There are no refunds for Weave {{ productServiceName }} cancellations. You must cancel your subscription
            within at least seven (7) business days prior to the first day of your next monthly billing cycle to avoid
            being charged the renewal fee for that month.
          </Text>
        </Trans>

        <Trans t={t}>
          <Text css={termsParagraphStyles}>
            You represent and warrant that in conjunction with any and all of Weave's products and services, you have
            reviewed the applicable terms found{' '}
            <TextLink
              // trackingId={`${trackingIdPrefix}${ids.weaveTermsPageLink2}`}
              rel='noreferrer'
              target='_blank'
              href='https://www.getweave.com/legal/terms/'
            >
              here
            </TextLink>{' '}
            and you agree to comply with the applicable laws and regulations. Weave reserves the right to suspend your
            account if abuse is reported or if you use the service in a way that otherwise violates Weave's Terms of
            Service.
          </Text>
        </Trans>

        {additionalTerms?.map((term, index) => (
          <Text css={termsParagraphStyles} key={index}>
            {term}
          </Text>
        ))}

        <Text css={termsParagraphStyles}>
          {t(
            'You acknowledge and consent to the proration of charges for this add-on feature, ensuring it aligns with your upcoming billing date. Additionally, please note that its billing frequency will be synchronized with the cadence of your main bundle service charges.'
          )}
        </Text>
      </Modal.Body>
    </Modal>
  );
};

const termsParagraphStyles = css({ margin: theme.spacing(3, 0) });
