import { useMemo, useState } from 'react';
import {
  Batch,
  BatchStatus,
  GetBatchesRequest,
  GetBatchesResponse,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import {
  EmailCampaign,
  GetAudienceRequest,
  GetSegmentsRequest,
  ListEmailCampaignsRequest,
  ListEmailCampaignsResponse,
  ListEmailDraftsRequest,
  ListEmailDraftsResponse,
  ListEmailMessagesRequest,
  ListUsageAndAllotmentRequest,
  ListUsageAndAllotmentResponse,
  LocationUsageAndAllotment,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-campaign/v1/emailcampaign.pb';
import { omit } from 'lodash-es';
import { UseQueryOptions, useQueries, useQuery, useQueryClient } from 'react-query';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { SchemaBulkMessage, SchemaEmailCampaignService } from './service';
import { BulkUsageInfo, QueryKeysType } from './types';
import { sortCampaigns } from './utils';

// TODO: Replace SchemaQueryOptions with helper types from the query helper library
type SchemaQueryOptions<T> = UseQueryOptions<T, unknown, T>;

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const keys = {
  base: ['bulk-messaging'],
  getActiveYears: (locationIds: string[]) => [...keys.base, 'get-active-years', locationIds],
  getAudience: (req: GetAudienceRequest) => [...keys.base, 'get-audience', req],
  getEmailCampaign: (campaignId: string) => [...keys.base, 'get-email-campaign', campaignId],
  getSegments: (req: GetSegmentsRequest) => [...keys.base, 'get-segments', req],
  listEmailCampaigns: (req: ListEmailCampaignsRequest) => [...keys.base, 'list-email-campaigns', req],
  listEmailDrafts: (req: ListEmailDraftsRequest) => [...keys.base, 'list-email-drafts', req],
  listEmailMessages: (req: ListEmailMessagesRequest) => [...keys.base, 'list-email-messages', req],
  listUsageAndAllotment: (request: ListUsageAndAllotmentRequest) => [...keys.base, 'list-usage-and-allotment', request],
};

export const useGetActiveYears = (locationIds: string[]) =>
  useQuery({
    ...defaultOptions,
    queryKey: keys.getActiveYears(locationIds),
    queryFn: () => SchemaBulkMessage.GetActiveYears({}),
    enabled: !!locationIds.length,
    select: (data) => data.years,
  });

export const useGetAudience = (req: GetAudienceRequest) =>
  useQuery({
    ...defaultOptions,
    queryKey: keys.getAudience(req),
    queryFn: () => SchemaEmailCampaignService.GetAudience(req),
    enabled: !!req.campaignId,
  });

export const useGetEmailCampaign = (
  campaignId: string,
  options: SchemaQueryOptions<EmailCampaign | undefined> = {}
) => {
  return useQuery({
    queryKey: keys.getEmailCampaign(campaignId),
    queryFn: async () => {
      const data = await SchemaEmailCampaignService.GetEmailCampaign({ campaignId });
      return data.campaign;
    },
    ...defaultOptions,
    ...options,
  });
};

export const useListEmailCampaigns = (
  req: ListEmailCampaignsRequest,
  options: UseQueryOptions<EmailCampaign[], unknown, EmailCampaign[]> = {}
) =>
  useQuery({
    queryKey: keys.listEmailCampaigns(req),
    queryFn: async () => {
      const data = await SchemaEmailCampaignService.ListEmailCampaigns(req);
      return data.campaigns ?? [];
    },
    ...defaultOptions,
    ...options,
  });

export const useListUsageAndAllotment = (
  request: ListUsageAndAllotmentRequest,
  options: UseQueryOptions<ListUsageAndAllotmentResponse, unknown, LocationUsageAndAllotment[] | undefined>,
  month?: number
) => {
  const queryKey = keys.listUsageAndAllotment(request);
  const queryData = useQuery({
    queryKey,
    queryFn: async () => await SchemaEmailCampaignService.ListUsageAndAllotment(request),
    select: (data) => (month ? data.usageAndAllotment?.filter((item) => item.month === month) : data.usageAndAllotment),
    ...defaultOptions,
    ...options,
  });
  return { ...queryData, queryKey };
};

export const useListEmailDrafts = (request: ListEmailDraftsRequest) =>
  useQuery({
    queryKey: keys.listEmailDrafts(request),
    queryFn: () => SchemaEmailCampaignService.ListEmailDrafts(request),
    select: (data) => data.draftCampaigns,
    enabled: !!request.locationIds?.length,
  });

type Draft = (Batch | EmailCampaign) & { type: 'email' | 'text' };
export const useCombinedCampaignDrafts = (
  bulkEmailRequest: ListEmailDraftsRequest,
  bulkTextRequest: GetBatchesRequest,
  emailEnabled: boolean,
  textEnabled: boolean
) => {
  const listEmailDraftsQueryKey = keys.listEmailDrafts(bulkEmailRequest) as QueryKeysType;
  // Just hard coded in the queryKey from the `useBulkQueryKeys` file in the messaging service. One less service to import especially that this query is used in the navigation. And no circular dependency.
  const listTextDraftsQueryKey = [
    bulkTextRequest.locationId,
    'bulk-messages',
    'batches',
    bulkTextRequest.direction,
    bulkTextRequest.year,
    bulkTextRequest.month,
  ] as QueryKeysType;
  const [{ data: emailDrafts, isFetched: emailDraftsIsFetched }, { data: textDrafts, isFetched: textDraftsIsFetched }] =
    useQueries([
      {
        queryKey: listEmailDraftsQueryKey,
        queryFn: () => SchemaEmailCampaignService.ListEmailDrafts(bulkEmailRequest),
        select: (data: ListEmailDraftsResponse) =>
          data.draftCampaigns?.map((campaign) => ({ ...campaign, type: 'email' })),
        enabled: !!bulkEmailRequest?.locationIds?.length && emailEnabled,
      },
      {
        queryKey: listTextDraftsQueryKey,
        queryFn: () => SchemaBulkMessage.GetBatches(bulkTextRequest),
        select: (data: GetBatchesResponse) =>
          data.batches
            ?.flatMap((monthlyBatches) => monthlyBatches.batches ?? [])
            .filter((batch) => batch.status === BatchStatus.DRAFT)
            .map((batch) => ({ ...batch, type: 'text' })),
        enabled: !!bulkTextRequest?.locationId && textEnabled,
      },
    ]);
  const drafts = [...(emailDrafts || []), ...(textDrafts || [])] as Draft[];
  return {
    count: drafts.length,
    drafts,
    isFetched: emailDraftsIsFetched && textDraftsIsFetched,
    listEmailDraftsQueryKey,
    listTextDraftsQueryKey,
  };
};

export const useListEmailMessages = (req: ListEmailMessagesRequest) =>
  useQuery({
    queryKey: keys.listEmailMessages(req),
    queryFn: () => SchemaEmailCampaignService.ListEmailMessages(req),
    enabled: !!req.campaignId,
  });

export const useExpandListEmailCampaigns = (options: UseQueryOptions<EmailCampaign[], unknown, EmailCampaign[]>) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const expandCampaign = async (req: ListEmailCampaignsRequest) => {
    const selectedMonthCampaignsQueryKey = keys.listEmailCampaigns(req);
    const yearCampaignsQueryKey = keys.listEmailCampaigns(omit(req, 'month'));

    try {
      // check if the selected month's email campaigns are already cached
      const cachedMonthCampaign = queryClient.getQueryData<ListEmailCampaignsResponse>(selectedMonthCampaignsQueryKey);
      if (cachedMonthCampaign) return;

      setIsLoading(true);

      // fetched month's email campaigns
      const data = await SchemaEmailCampaignService.ListEmailCampaigns(req);
      const selectedMonthCampaigns = data.campaigns ?? [];

      // get the years email campaigns from cache
      const yearCampaigns = queryClient.getQueryData<EmailCampaign[]>(yearCampaignsQueryKey) ?? [];

      // combine the selected months email campaigns with the years email campaigns and remove the duplicates
      const mergedCampaigns = sortCampaigns([...yearCampaigns, ...selectedMonthCampaigns]).filter(
        (campaign, index, arr) => arr.findIndex((c) => c.id === campaign.id) === index
      );

      // update the cache with the merged campaigns and selected month's campaigns
      queryClient.setQueryData(yearCampaignsQueryKey, mergedCampaigns);
      queryClient.setQueryData(selectedMonthCampaignsQueryKey, selectedMonthCampaigns);
    } catch (err) {
      // handle error
      options.onError?.(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { expandCampaign, isLoading };
};

export const useGetMultiBulkMessageUsageInfo = (locationIds: string[], year: number, month?: number) => {
  const queryResults = useQueries(
    locationIds.map((locationId) => ({
      queryKey: [locationId, 'bulk-message-usage', year, month ?? 'all'],
      queryFn: async (): Promise<[string, BulkUsageInfo]> => {
        const res = await SchemaBulkMessage.GetBatches({
          locationId,
          year,
          month,
        });

        const usageInfo = (res?.batches ?? []).reduce<BulkUsageInfo>(
          (acc, batch) => ({
            allotment: acc.allotment || (batch.usage?.quota ?? 0),
            sentAndFailed: acc.sentAndFailed + (batch.usage?.sent ?? 0) + (batch.usage?.failed ?? 0),
            scheduled: acc.scheduled + (batch.usage?.pending ?? 0),
          }),
          { allotment: 0, sentAndFailed: 0, scheduled: 0 }
        );

        return [locationId, usageInfo];
      },
      enabled: !!locationId && !!year,
    }))
  );

  const messageUsageInfoMap = useMemo<Map<string, BulkUsageInfo>>(
    () =>
      queryResults.reduce<Map<string, BulkUsageInfo>>((usageMap, queryResult) => {
        if (!queryResult?.data) return usageMap;

        const [locationId, usageInfo] = queryResult.data;
        if (!locationId || (!usageInfo.allotment && !usageInfo.scheduled && !usageInfo.sentAndFailed)) return usageMap;

        usageMap.set(locationId, usageInfo);
        return usageMap;
      }, new Map<string, BulkUsageInfo>()),
    [queryResults]
  );

  const isLoading = queryResults.some((result) => result.isLoading);
  return { messageUsageInfoMap, isLoading };
};

interface UseGetMultiBulkEmailUsageInfoParams {
  orgId: string;
  locationIds: string[];
  year: number;
  month?: number;
}

export const useGetMultiBulkEmailUsageInfo = ({
  orgId,
  locationIds,
  year,
  month,
}: UseGetMultiBulkEmailUsageInfoParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ['yearly-bulk-text-batches', orgId, ...locationIds],
    queryFn: () => SchemaEmailCampaignService.ListUsageAndAllotment({ locationIds, orgId, year }),
    enabled: !!locationIds.length && !!orgId && !!year,
  });

  const emailUsageInfoMap = useMemo(() => {
    const batches = data?.usageAndAllotment ?? [];
    const emailUsageInfoMap = new Map<string, BulkUsageInfo>();
    batches.forEach((batch) => {
      if (!batch.locationId || (!!month && batch.month !== month)) return;

      const location = emailUsageInfoMap.get(batch.locationId);
      emailUsageInfoMap.set(batch.locationId, {
        allotment: (location?.allotment ?? 0) || (batch.allotment ?? 0),
        sentAndFailed: (location ? location.sentAndFailed : 0) + (batch.sent ?? 0) + (batch.errorAgainstUsage ?? 0),
        scheduled: (location ? location.scheduled : 0) + (batch.scheduled ?? 0),
      });
    });

    return emailUsageInfoMap;
  }, [data?.usageAndAllotment]);

  return { emailUsageInfoMap, isLoading };
};

export const useGetSegments = (req: GetSegmentsRequest) =>
  useQuery({
    queryKey: keys.getSegments(req),
    queryFn: () => SchemaEmailCampaignService.GetSegments(req),
    enabled: !!req.campaignId,
    select: (data) => data.segments,
  });
