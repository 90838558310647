import React from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { ScheduleQueries } from '@frontend/api-schedule';
import { ActionsUI } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Avatar,
  ContentLoader,
  IconButton,
  PopoverMenu,
  PopoverMenuItem,
  Text,
  useModalControl,
  usePopoverMenu,
  useTooltip,
  useAlert,
} from '@frontend/design-system';
import { useScheduleActionsContext } from '../../ScheduleActionsContext';
import { useScheduleRequestContext } from '../Context/ScheduleRequestContext';
import { ScheduleRequestsTrackingIds } from '../trackingIds';
import { ScheduleRequestDeleteConfirmationModal } from './components/ScheduleRequestDeleteConfirmationModal';

interface Props {
  name: string;
  appointmentTime: string;
  personPhone: string;
  locationId: string;
  updateSelectedScheduleRequest: () => void;
  scheduleRequestId: string;
}

export const ScheduleRequestListItemHeader = React.memo(
  ({ name, appointmentTime, personPhone, locationId, scheduleRequestId, updateSelectedScheduleRequest }: Props) => {
    const alert = useAlert();
    const { t } = useTranslation('scheduleCalendarActions');

    const { refetchScheduleRequestList } = useScheduleRequestContext();
    const { closeModal, refetchAppointmentRequestCount } = useScheduleActionsContext();

    const { Tooltip, tooltipProps, triggerProps: tooltipTriggerProps } = useTooltip({ placement: 'right' });
    const deleteScheduleRequestModalProps = useModalControl();

    const formattedDate = appointmentTime ? dayjs(appointmentTime).format('DD MMM YYYY, h:mm A') : '';

    const { mutateAsync: deleteScheduleRequest, isLoading: isLoadingDeleteScheduleRequest } =
      ScheduleQueries.useDeleteScheduleRequest();

    const {
      Modal: MessageModal,
      triggerProps: messageTriggerProps,
      disabledDetails,
    } = ActionsUI.actions.useMessageAction({
      context: {
        personId: '',
        locationId: locationId ?? '',
        phoneNumber: personPhone,
      },
      onClose: () => closeModalAndPopover(),
    });

    const {
      getMenuProps,
      getTriggerProps,
      close: closePopOverMenu,
    } = usePopoverMenu({
      placement: 'bottom-start',
    });

    const deleteScheduleRequestHandler = async (id: string) => {
      try {
        await deleteScheduleRequest({ id, locationId });
        refetchAppointmentRequestCount();
        refetchScheduleRequestList();
        deleteScheduleRequestModalProps.closeModal();
        alert.success(t('Schedule request deleted successfully'));
      } catch (error) {
        alert.error(t('Failed to delete schedule request'));
      }
    };

    const closeModalAndPopover = () => {
      closePopOverMenu();
      closeModal();
    };

    const handleMessageButtonClick = () => {
      messageTriggerProps?.onClick();
    };

    return (
      <>
        <ContentLoader show={isLoadingDeleteScheduleRequest} />
        <div css={containerStyles}>
          <Avatar name={name} />
          <div css={{ marginLeft: theme.spacing(2) }}>
            <Text size='medium' textAlign='left' weight='bold'>
              {name}
            </Text>
            <Text textAlign='left' size='small'>
              {formattedDate ? formattedDate : '-'}
            </Text>
          </div>

          <IconButton
            trackingId={ScheduleRequestsTrackingIds.requestListItemMoreBtn}
            css={{ marginLeft: 'auto' }}
            {...getTriggerProps()}
            label={t('More Icon')}
          >
            <Icon name='more' />
          </IconButton>
          <PopoverMenu {...getMenuProps()}>
            <PopoverMenuItem
              trackingId={ScheduleRequestsTrackingIds.requestListItemReviewRequestBtn}
              Icon={() => <Icon name='lists-small' />}
              onClick={() => {
                updateSelectedScheduleRequest();
                closePopOverMenu();
              }}
            >
              {t('Review Request')}
            </PopoverMenuItem>
            <div {...tooltipTriggerProps}>
              {personPhone && (
                <PopoverMenuItem
                  trackingId={ScheduleRequestsTrackingIds.requestListItemMessageBtn}
                  Icon={() => <Icon name='message-small' />}
                  disabled={!!disabledDetails}
                  css={{ width: '100%' }}
                  onClick={handleMessageButtonClick}
                >
                  {t('Message')}
                  {!!disabledDetails && <Icon name='alert-invert' color='error' />}
                </PopoverMenuItem>
              )}
              {!!disabledDetails && <Tooltip {...tooltipProps}>{disabledDetails}</Tooltip>}
            </div>
            <PopoverMenuItem
              trackingId={ScheduleRequestsTrackingIds.requestListItemDeleteBtn}
              Icon={() => <Icon name='trash-small' />}
              onClick={() => {
                deleteScheduleRequestModalProps.openModal();
              }}
            >
              {t('Delete')}
            </PopoverMenuItem>
          </PopoverMenu>
        </div>
        <ScheduleRequestDeleteConfirmationModal
          onConfirm={() => deleteScheduleRequestHandler(scheduleRequestId)}
          modalProps={deleteScheduleRequestModalProps.modalProps}
        />
        {MessageModal}
      </>
    );
  }
);

ScheduleRequestListItemHeader.displayName = 'ScheduleRequestListItemHeader';

const containerStyles = css({ display: 'flex', alignItems: 'center', width: '100%' });
