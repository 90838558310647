import { useMemo } from 'react';
import { ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { Provider, Workstation } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { AppointmentTypesApi, AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { ScheduleApi } from '@frontend/api-schedule';
import { ServiceProvidersApi, ServiceProvidersConstants } from '@frontend/api-service-providers';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useQuery } from '@frontend/react-query-helpers';
import { getWorkstationId } from '../utils';

const { ONE_MINUTE_IN_MILLISECONDS } = ServiceProvidersConstants;
export type ReviewRequestReturnType = {
  lists: {
    appointmentTypes: AppointmentTypesTypes.AppointmentType[];
    providers: Provider[];
    workstations: Workstation[];
  };
  selected: {
    appointmentId: string;
    providerId: string;
    workstationId: string;
  };
  isLoading: boolean;
};

export const useReviewRequest = (reviewDetails: ScheduleRequest): ReviewRequestReturnType => {
  const workstationAssets = reviewDetails.requestedOpenings?.[0].assets?.operatories ?? [];
  const providerAssets = reviewDetails.requestedOpenings?.[0].assets?.providers ?? [];
  const locationId = reviewDetails.locationId ?? '';

  const { data: appointmentTypesData, isLoading: isAppointmentTypesLoading } = useQuery({
    queryKey: ['appointmentTypes', locationId],
    queryFn: () => {
      return AppointmentTypesApi.getAppointmentTypes(locationId);
    },
    cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS,
    staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS,
    enabled: !!locationId,
  });

  const { data: providersData, isLoading: isProviderLoading } = useLocalizedQuery({
    queryKey: ['scheduleProvidersList', locationId],
    queryFn: () => ScheduleApi.getProvidersListForMultipleLocationIds({ locationIds: [locationId] }),
    retry: 1,
    cacheTime: 60 * ONE_MINUTE_IN_MILLISECONDS,
    staleTime: 60 * ONE_MINUTE_IN_MILLISECONDS,
    enabled: !!locationId,
  });

  const { data: workstations, isLoading: isWorkstationsLoading } = useQuery({
    queryKey: ['workstations', locationId],
    queryFn: async () => await ServiceProvidersApi.getWorkstations({ locationId: locationId }),
    enabled: !!locationId,
    staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 minutes
    cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 minutes
  });

  const appointmentId = useMemo(
    () => appointmentTypesData?.find((apptType) => apptType.name === reviewDetails?.appointmentType)?.id ?? '',
    [appointmentTypesData, reviewDetails?.appointmentType]
  );

  const providerId = useMemo(() => {
    const providerName = providerAssets?.toString();
    return (
      providersData?.providers?.find(
        (provider) =>
          providerName === `${provider.publicDisplayName}` ||
          providerName === `${provider.firstName} ${provider.lastName}`
      )?.id ?? ''
    );
  }, [providerAssets?.toString(), providersData?.providers]);

  const workstationId = useMemo(
    () => getWorkstationId(workstationAssets, workstations?.workstations ?? []),
    [workstationAssets?.toString(), workstations?.workstations?.length]
  );

  return {
    lists: {
      appointmentTypes: appointmentTypesData ?? [],
      providers: providersData?.providers ?? [],
      workstations: workstations?.workstations ?? [],
    },
    selected: {
      appointmentId,
      providerId,
      workstationId,
    },
    isLoading: isAppointmentTypesLoading || isProviderLoading || isWorkstationsLoading,
  };
};
