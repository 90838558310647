import { PracticeAnalyticsTypes } from '../../practice';
import { getRandomDate, getRandomInt } from './mock-generators';

const procedures = [
  {
    adaCode: 'D1234',
    date: getRandomDate(1, 5),
    procedureAmount: 462,
  },
  {
    adaCode: 'D8753',
    date: getRandomDate(3, 10),
    procedureAmount: 122,
  },
  {
    adaCode: 'D4342',
    date: getRandomDate(1, 20),
    procedureAmount: 753,
  },
  {
    adaCode: 'D7472',
    date: getRandomDate(5, 19),
    procedureAmount: 246,
  },
  {
    adaCode: 'D1768',
    date: getRandomDate(1, 20),
    procedureAmount: 422,
  },
  {
    adaCode: 'D9624',
    date: getRandomDate(1, 30),
    procedureAmount: 67,
  },
  {
    adaCode: 'D4437',
    date: getRandomDate(1, 25),
    procedureAmount: 287,
  },
  {
    adaCode: 'D0983',
    date: getRandomDate(1, 17),
    procedureAmount: 844,
  },
  {
    adaCode: 'D1972',
    date: getRandomDate(17, 30),
    procedureAmount: 645,
  },
  {
    adaCode: 'D2221',
    date: getRandomDate(20, 30),
    procedureAmount: 129,
  },
];

export const getMockProcedures = (): PracticeAnalyticsTypes.Procedure[] => {
  const randomProcedures = Array.from({ length: getRandomInt(1, 5) }, () => getRandomInt(0, procedures.length - 1));
  const mockProcedures = randomProcedures.map((idx) => procedures[idx]);

  return mockProcedures;
};
