import { Link } from '@tanstack/react-location';
import { useTranslation, Trans } from '@frontend/i18n';
import { FailureGraphic } from '@frontend/assets';
import { Text, TextLink } from '@frontend/design-system';

import { SubscribeModal } from './SubscribeModal';
import { subscribeTrackingIds as ids } from './trackingIds';

const WEAVE_HELP_BILLING_LINK =
  'https://www.weavehelp.com/hc/en-us/articles/4409011007643-Update-Your-Weave-Billing-Information-';

interface SubscribeFailureModalProps {
  onOkayButtonClick: () => void;
  hasOverdueBalance?: boolean;
  productServiceName: string;
  trackingIdPrefix?: string;
}

export const SubscribeFailureModal = ({
  onOkayButtonClick,
  hasOverdueBalance,
  productServiceName,
  trackingIdPrefix = '',
}: SubscribeFailureModalProps) => {
  const { t } = useTranslation('subscribe', { keyPrefix: 'FailureModal' });

  const updatedTrackingPrefix = hasOverdueBalance
    ? `${trackingIdPrefix}${ids.invoiceBalanceFailure}`
    : `${trackingIdPrefix}${ids.failure}`;

  return (
    <SubscribeModal
      title={t('Uh oh!')}
      primaryButtonLabel={t('Okay')}
      image={<FailureGraphic css={{ width: 300, height: 150 }} />}
      onPrimaryBtnClick={onOkayButtonClick}
      primaryButtonTrackingId={`${updatedTrackingPrefix}${ids.okayButton}`}
      renderContent={
        hasOverdueBalance ? (
          <InvoiceBalanceFailureContent
            productServiceName={productServiceName}
            trackingIdPrefix={updatedTrackingPrefix}
          />
        ) : (
          <SubscribeFailureContent productServiceName={productServiceName} />
        )
      }
    />
  );
};

const SubscribeFailureContent = ({ productServiceName }: { productServiceName: string }) => {
  const { t } = useTranslation('subscribe', { keyPrefix: 'FailureModal' });
  return (
    <>
      <Text>
        {t('We were unable to complete your subscription for {{productServiceName}}.', { productServiceName })}
      </Text>
      <Text>
        {t(
          'Please refresh the page and try again. If this problem persists, please contact support at +1 (888) 579-5668'
        )}
      </Text>
    </>
  );
};

const InvoiceBalanceFailureContent = ({
  productServiceName,
  trackingIdPrefix,
}: {
  productServiceName: string;
  trackingIdPrefix: string;
}) => {
  const { t } = useTranslation('subscribe', { keyPrefix: 'FailureModal' });
  return (
    <Trans t={t}>
      <Text>
        You do not have valid billing information on file. To proceed with Weave {{ productServiceName }} sign up,
        please update your billing information{' '}
        <TextLink as={Link} to={'/portal/account/billing'} trackingId={`${trackingIdPrefix}${ids.billingPageLink}`}>
          here
        </TextLink>
        .
      </Text>

      <Text>
        You can find more instructions on{' '}
        <TextLink
          rel='noreferrer'
          target='_blank'
          href={WEAVE_HELP_BILLING_LINK}
          trackingId={`${trackingIdPrefix}${ids.weaveHelpLink}`}
        >
          WeaveHelp
        </TextLink>
        .
      </Text>
    </Trans>
  );
};
