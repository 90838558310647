import { FreeTrialSubscriptionQueries, FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { checkAggregateACL } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { SubscriptionStatusForSelfUpgrade } from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { useMemo } from 'react';
import { FeatureSubscriptionContextProps } from '../providers/FeatureSubscriptionProvider';

interface FeatureSubscriptionConfig extends FeatureSubscriptionContextProps {
  isFeatureActiveInAllLocations: boolean;
  isFeatureHiddenInAllLocations: boolean;
  isLoading: boolean;
}

export const useFeatureSubscriptionConfig = ({
  feature,
  locationIds,
}: {
  feature: Feature;
  locationIds?: string[];
}): FeatureSubscriptionConfig => {
  const { selectedLocationIds: appScopeLocationIds } = useAppScopeStore();

  const selectedLocationIds = locationIds || appScopeLocationIds;

  const { aclValuesByLocationId: locationWiseAdminAccess, aggregateValue: hasAdminAccessInAtleastOne } = useMemo(() => {
    return checkAggregateACL(selectedLocationIds, Permission.MANAGE_ADD_ON_SUBSCRIPTIONS, 'any');
  }, [selectedLocationIds]);

  const {
    data: selfUpgradeFreeTrialEligibilityData,
    isFetching: isLoadingSelfUpgradeData,
    refetch: refetchSelfUpgradeFreeTrialEligibilityData,
  } = FreeTrialSubscriptionQueries.useSelfUpgradeOrFreeTrialEligibility({
    featureEnum: feature,
    locationIds: selectedLocationIds,
  });

  const {
    selfUpgradeData,
    canSelfSubscribe,
    isUpgradeEligible,
    isFreeTrialEligible,
    isFeatureHiddenInAllLocations,
    isFeatureActiveInAllLocations,
  } = useMemo(() => {
    const selfUpgradeData = selfUpgradeFreeTrialEligibilityData?.eligibility || [];

    const isUpgradeEligible = selfUpgradeData.some(({ subscriptionStatus }) => {
      return subscriptionStatus !== SubscriptionStatusForSelfUpgrade.SUBSCRIPTION_STATUS_UNSPECIFIED;
    });

    const canSelfSubscribe = selfUpgradeData.some(({ subscriptionStatus }) => {
      return subscriptionStatus === SubscriptionStatusForSelfUpgrade.SUBSCRIBE;
    });

    const isFreeTrialEligible = selfUpgradeData.some(({ trialStatus }) => {
      return trialStatus === FreeTrialSubscriptionTypes.TrialStatus.TRIAL_ELIGIBLE;
    });

    const isFeatureActiveInAllLocations = selfUpgradeData.every(({ isStateActive, trialStatus }) => {
      return isStateActive || trialStatus === FreeTrialSubscriptionTypes.TrialStatus.TRIAL_ACTIVE;
    });
    const isFeatureHiddenInAllLocations = selfUpgradeData.every(({ isStateActive, trialStatus }) => {
      return !isStateActive && trialStatus !== FreeTrialSubscriptionTypes.TrialStatus.TRIAL_ACTIVE;
    });

    return {
      selfUpgradeData,
      isUpgradeEligible,
      isFeatureHiddenInAllLocations,
      isFeatureActiveInAllLocations,
      isFreeTrialEligible,
      canSelfSubscribe,
    };
  }, [selfUpgradeFreeTrialEligibilityData?.eligibility]);

  const refetchSubscriptionConfig = () => {
    refetchSelfUpgradeFreeTrialEligibilityData();
  };

  return {
    selectedLocationIds,
    feature,
    isFreeTrialEligible,
    isUpgradeEligible,
    canSelfSubscribe,
    isFeatureHiddenInAllLocations,
    isFeatureActiveInAllLocations,
    isLoading: isLoadingSelfUpgradeData,
    refetchSubscriptionConfig,
    selfUpgradeData,
    locationWiseAdminAccess,
    hasAdminAccessInAtleastOne,
  };
};
