import { memo, PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import { LoadingSkeleton } from '@frontend/assets';
import { generateAreaGradientDefs } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { DemoChip } from '../../demo-chip';
import { usePhoneAnalyticsStore } from '../hooks';

interface SummaryChartProps {
  isLoading?: boolean;
}

interface SummaryChartHeaderProps {
  title: string;
  value: string;
}

interface SummaryChartStatsProps {
  values: { label: string; value: number | string }[];
}

export type SummaryTrend = {
  value: number;
};

interface SummaryChartGraphProps {
  color?: string;
  uniqueGradientKey: string;
  values?: SummaryTrend[];
}

export const SummaryChart = ({ children, isLoading }: PropsWithChildren<SummaryChartProps>) => {
  return (
    <section css={styles.chart}>
      {children}
      {isLoading && <LoadingSkeleton css={styles.loading} />}
    </section>
  );
};

const SummaryChartHeader = memo(({ title, value }: SummaryChartHeaderProps) => {
  const { isDemoAccount } = usePhoneAnalyticsStore();

  return (
    <>
      {isDemoAccount && <DemoChip />}

      <div css={styles.flexHeader}>
        <div className='title'>
          <Icon name='phone-small' />
          <Text as='h3' size='medium' weight='bold'>
            {title}
          </Text>
        </div>

        <Text as='h3' css={styles.value} textAlign='right' weight='bold'>
          {value}
        </Text>
      </div>
    </>
  );
});

const SummaryChartStats = memo(({ values }: SummaryChartStatsProps) => {
  const dataLength = values.length;

  return (
    <div css={styles.statsWrapper}>
      {values.map(({ label, value }, index) => {
        let className = 'stat ';

        if (dataLength > 1) {
          if (index === 0) {
            className += 'first';
          } else if (index === dataLength - 1) {
            className += 'last';
          } else {
            className += 'middle';
          }
        }

        return (
          <div className={className.trim()} key={label}>
            <Text color='subdued' size='small'>
              {label}
            </Text>
            <Text color='subdued' weight='bold' style={{ fontSize: theme.font.size.h3 }}>
              {value}
            </Text>
          </div>
        );
      })}
    </div>
  );
});

const SummaryChartGraph = memo(
  ({ color = theme.colors.neutral50, uniqueGradientKey, values }: SummaryChartGraphProps) => {
    const { t } = useTranslation('analytics');

    return values?.length ? (
      <ResponsiveContainer height={60}>
        <AreaChart data={values} margin={{ left: 0, right: 0, top: 0, bottom: 0 }}>
          {generateAreaGradientDefs(uniqueGradientKey, color)}
          <Area dataKey='value' fill={`url(#${uniqueGradientKey})`} stroke={color} strokeWidth={3} type='monotone' />
        </AreaChart>
      </ResponsiveContainer>
    ) : (
      <Text color='disabled' style={{ marginTop: theme.spacing(2) }} textAlign='center'>
        {t('Not enough data to draw chart')}
      </Text>
    );
  }
);

SummaryChartHeader.displayName = 'SummaryChartHeader';
SummaryChartStats.displayName = 'SummaryChartStats';
SummaryChartGraph.displayName = 'SummaryChartGraph';

SummaryChart.Header = SummaryChartHeader;
SummaryChart.Stats = SummaryChartStats;
SummaryChart.Graph = SummaryChartGraph;

const styles = {
  chart: css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    flex: 1;
    justify-content: flex-start;
    min-width: 240px;
    overflow: hidden;
    padding: ${theme.spacing(3)};
    position: relative;
  `,

  flexHeader: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .title {
      align-items: center;
      display: flex;
      gap: ${theme.spacing(0.5)};
    }
  `,

  value: css`
    font-size: ${theme.fontSize(24)};
  `,

  statsWrapper: css`
    display: flex;
    justify-content: space-between;
    margin: ${theme.spacing(1, 0)};

    .first {
      text-align: start;
    }

    .middle {
      text-align: center;
    }

    .last {
      text-align: end;
    }
  `,

  loading: css`
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  `,
};
