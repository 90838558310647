import { getTabSessionId } from '@frontend/tab-management';
import { useCallback, useEffect, useRef, useState } from 'react';

const prefix = `softphone.tab-manager`;

type SoftphoneTabLocalStorageType = {
  id: string;
  hasActiveCalls: boolean;
};

const removeTabFromRegistry = () => {
  const id = getTabSessionId();
  Object.entries(localStorage)
    .filter(([keyName, value]) => {
      const parsedValue = keyName.startsWith(prefix) && value && (JSON.parse(value) as SoftphoneTabLocalStorageType);
      return keyName.startsWith(prefix) && parsedValue.id && parsedValue.id === id;
    })
    .forEach(([keyName]) => {
      localStorage.removeItem(keyName);
    });
};

const removeOthertabsFromRegistry = () => {
  const id = getTabSessionId();
  Object.entries(localStorage)
    .filter(([keyName, value]) => {
      const parsedValue = keyName.startsWith(prefix) && value && (JSON.parse(value) as SoftphoneTabLocalStorageType);
      return keyName.startsWith(prefix) && parsedValue.id && parsedValue.id !== id;
    })
    .forEach(([keyName]) => {
      localStorage.removeItem(keyName);
    });
};

const registerTab = (sipProfileId: string, hasActiveCalls: boolean) => {
  const id = getTabSessionId();
  if (id) {
    localStorage.setItem(
      `${prefix}:${sipProfileId}`,
      JSON.stringify({ id, hasActiveCalls } satisfies SoftphoneTabLocalStorageType)
    );
  }
};

type Props = {
  onTabRegister: (sipProfileId: string) => void;
  onTabUnregister: () => void;
  sipProfileId: string | undefined;
  isReady: boolean;
  hasActiveCalls: boolean;
  setHasActiveCalls: (hasActiveCalls: boolean) => void;
};
export const useSoftphoneTabManager = ({
  isReady,
  sipProfileId,
  onTabUnregister,
  onTabRegister,
  hasActiveCalls,
  setHasActiveCalls,
}: Props) => {
  const [_focused, setFocused] = useState(document.hasFocus());
  const isUnloaded = useRef(false);
  const tabId = getTabSessionId();
  const key = `${prefix}:${sipProfileId ?? 'unknown'}`;
  const currentRegisteredTab = localStorage.getItem(key);
  const runningInOtherTab =
    currentRegisteredTab && (JSON.parse(currentRegisteredTab) as SoftphoneTabLocalStorageType).id !== tabId;

  useEffect(() => {
    if (!!runningInOtherTab) return;
    if (hasActiveCalls && sipProfileId) {
      registerTab(sipProfileId, true);
    } else if (!hasActiveCalls && sipProfileId) {
      registerTab(sipProfileId, false);
    }
  }, [hasActiveCalls]);

  useEffect(() => {
    if (!isReady) {
      removeTabFromRegistry();
      return;
    }

    if (runningInOtherTab) {
      setHasActiveCalls((JSON.parse(currentRegisteredTab) as SoftphoneTabLocalStorageType).hasActiveCalls);
    }

    if (isReady && tabId && sipProfileId && (!currentRegisteredTab || !runningInOtherTab) && !isUnloaded.current) {
      registerTab(sipProfileId, hasActiveCalls);
      onTabRegister(sipProfileId);
    }

    const storageFn = (e: StorageEvent) => {
      if (e.key === key && e.newValue === null) {
        onTabUnregister();
      }
      if (e.key === key && e.newValue) {
        const value: SoftphoneTabLocalStorageType = JSON.parse(e.newValue) as SoftphoneTabLocalStorageType;
        setHasActiveCalls(value.hasActiveCalls);
      }
    };

    const beforeUnloadFn = (e: BeforeUnloadEvent) => {
      if (hasActiveCalls) {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    const unloadFn = () => {
      isUnloaded.current = true;
      removeTabFromRegistry();
      setHasActiveCalls(false);
    };

    const focusFn = () => setFocused(true);
    const unfocusFn = () => setFocused(false);

    window.addEventListener('storage', storageFn);
    window.addEventListener('beforeunload', beforeUnloadFn);
    window.addEventListener('unload', unloadFn);
    window.addEventListener('focus', focusFn);
    window.addEventListener('blur', unfocusFn);
    return () => {
      window.removeEventListener('storage', storageFn);
      window.removeEventListener('beforeunload', beforeUnloadFn);
      window.removeEventListener('unload', unloadFn);
      window.removeEventListener('focus', focusFn);
      window.removeEventListener('blur', unfocusFn);
    };
  }, [key, sipProfileId, tabId, isReady, hasActiveCalls, runningInOtherTab]);

  const useThisTab = useCallback(() => {
    removeOthertabsFromRegistry();
    onTabUnregister();
  }, [key, onTabUnregister]);

  return { useThisTab, runningInOtherTab, tabId };
};
