import { FC, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { PhoneAnalyticsApi, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Tabs, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { formatDateByTimezone, subtractArrayIndexes, sumArrayIndexes } from '../../utils';
import { pageStyles } from '../../views/common-styles';
import { usePhoneAnalyticsStore } from './hooks';
import { CallAnalyticsPanel, CallReportSummary, DurationAnalyticsPanel } from '.';

interface Props {
  onFetchStateChange: (isFetching: boolean) => void;
}

const DEFAULT_TAB_VIEW = 'call-analytics-view';

const defaultGeneralData: PhoneAnalyticsTypes.ChartsData = {
  incomingCallsDuration: {},
  longDurationCalls: {},
  totalCalls: {},
  totalCallsDuration: {},
  totalIncomingCalls: {},
  totalUnknownVsPatientCalls: {},
  totalUnknownVsPatientCallsDuration: {},
  weaveAverages: {
    callAnswerRate: {},
  },
};

const colors: Record<string, string> = {
  abandoned: theme.colors.warning50,
  answered: theme.colors.secondary.seaweed30,
  incoming: theme.colors.secondary.seaweed30,
  missed: theme.colors.critical30,
  outgoing: theme.colors.warning50,
  patient: theme.colors.primary20,
  total: theme.colors.primary20,
  unknown: theme.colors.secondary.eggplant30,
  weaveAverage: theme.colors.primary20,
};

const defaultTimeBoundData: PhoneAnalyticsTypes.TimeBoundInsights = {
  calls: { incoming: {}, outgoing: {}, total: {} },
};

const combineValues = (...values: any[]) =>
  Array.isArray(values[0]) ? sumArrayIndexes({ arrays: values }) : values.reduce((sum, value) => sum + (value || 0), 0);

export const PhoneReportCharts: FC<React.PropsWithChildren<Props>> = ({ onFetchStateChange }) => {
  const { demoData, filters, isDemoAccount } = usePhoneAnalyticsStore();
  // Extract unwanted filters for the charts view
  const { CallType, NumberSearch, Result, SourceType, ...restFilters } = filters;
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const queryString = useMemo(() => `${JSON.stringify({ ...restFilters })}`, [restFilters]);
  const daysDiffInHours = dayjs(filters.EndDate).diff(dayjs(filters.StartDate), 'hours');
  const isHourlyInsights = !!(filters.LocationID?.length === 1 && daysDiffInHours < 24);

  const locationData = getSelectedLocationData()[selectedLocationIdsWithParents[0]];

  // This general data can also have hourly insights when just one location and one date is selected
  const { data: generalData, isFetching: isLoadingGeneralData } = useScopedQuery({
    queryKey: queryKeys.phoneAnalyticsCharts(`${queryString}-general-data-${isDemoAccount}`),
    queryFn: () => (isDemoAccount ? null : PhoneAnalyticsApi.getPhoneReportingCharts(restFilters, isHourlyInsights)),
    onError: () => {
      alert.error(t("Couldn't load the phone reports charts. Please try again."));
    },
    refetchOnWindowFocus: false,
    retry: false,
    select: (data) => {
      return isDemoAccount ? (isHourlyInsights ? demoData?.hourlyData : demoData?.dailyData) : data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  // This hourly data will be used to generated hourly data charts along with general data charts
  const { data: hourlyData, isFetching: isLoadingHourlyData } = useScopedQuery({
    queryKey: queryKeys.phoneAnalyticsCharts(`${queryString}-hourly-data-${isDemoAccount}`),
    queryFn: () => (isDemoAccount ? null : PhoneAnalyticsApi.getPhoneReportingCharts(restFilters, true)),
    onError: () => {
      alert.error(t("Couldn't load the phone reports charts. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return isDemoAccount ? demoData?.hourlyData : data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const getChartLabelText = (locationName: string, date: string) =>
    restFilters.LocationID?.length === 1
      ? formatDateByTimezone(date, filters.StartDate, locationData?.timezone)
      : locationName;

  const generalChartsData: PhoneAnalyticsTypes.ChartsData = useMemo(
    () =>
      generalData
        ? generalData.reduce(
            (
              final,
              {
                InboundCallDurationMoreThan10Min,
                LocationName,
                OutboundCallDurationMoreThan10Min,
                StartDate,
                TotalInboundAbandonedCalls,
                TotalInboundAbandonedCallsDuration,
                TotalInboundAnsweredCalls,
                TotalInboundAnsweredCallsDuration,
                TotalInboundAnsweredCallsDurationPatients,
                TotalInboundCallDuration,
                TotalInboundCallDurationPatient,
                TotalInboundCallDurationUnknown,
                TotalInboundCalls,
                TotalInboundCallsPatient,
                TotalInboundCallsUnknown,
                TotalInboundMissedCalls,
                TotalInboundMissedCallsDuration,
                TotalInboundMissedCallsDurationUnknown,
                TotalInboundMissedOOOCalls,
                TotalOutboundCallDuration,
                TotalOutboundCallDurationPatient,
                TotalOutboundCallDurationUnknown,
                TotalOutboundCalls,
                TotalOutboundCallsPatient,
                TotalOutboundCallsUnknown,
                WeaveCallAnswerRate,
              }
            ) => {
              const label = getChartLabelText(LocationName, StartDate);
              return {
                ...final,
                incomingCallsDuration: {
                  ...final.incomingCallsDuration,
                  [label]: {
                    abandoned: combineValues(
                      TotalInboundAbandonedCallsDuration,
                      final.incomingCallsDuration[label]?.abandoned
                    ),
                    answered: combineValues(
                      TotalInboundAnsweredCallsDuration,
                      final.incomingCallsDuration[label]?.answered
                    ),
                    missed: combineValues(TotalInboundMissedCallsDuration, final.incomingCallsDuration[label]?.missed),
                    patient: combineValues(
                      TotalInboundAnsweredCallsDurationPatients,
                      final.incomingCallsDuration[label]?.patient
                    ),
                    unknown: combineValues(
                      TotalInboundMissedCallsDurationUnknown,
                      final.incomingCallsDuration[label]?.unknown
                    ),
                  },
                },
                longDurationCalls: {
                  ...final.longDurationCalls,
                  [label]: {
                    incoming: combineValues(InboundCallDurationMoreThan10Min, final.longDurationCalls[label]?.incoming),
                    outgoing: combineValues(
                      OutboundCallDurationMoreThan10Min,
                      final.longDurationCalls[label]?.outgoing
                    ),
                    total: combineValues(
                      InboundCallDurationMoreThan10Min,
                      OutboundCallDurationMoreThan10Min,
                      final.longDurationCalls[label]?.incoming,
                      final.longDurationCalls[label]?.outgoing
                    ),
                  },
                },
                totalCalls: {
                  ...final.totalCalls,
                  [label]: {
                    incoming: combineValues(TotalInboundCalls, final.totalCalls[label]?.incoming),
                    outgoing: combineValues(TotalOutboundCalls, final.totalCalls[label]?.outgoing),
                    total: combineValues(
                      TotalInboundCalls,
                      TotalOutboundCalls,
                      final.totalCalls[label]?.incoming,
                      final.totalCalls[label]?.outgoing
                    ),
                  },
                },
                totalCallsDuration: {
                  ...final.totalCallsDuration,
                  [label]: {
                    incoming: combineValues(TotalInboundCallDuration, final.totalCallsDuration[label]?.incoming),
                    outgoing: combineValues(TotalOutboundCallDuration, final.totalCallsDuration[label]?.outgoing),
                    total: combineValues(
                      TotalInboundCallDuration,
                      TotalOutboundCallDuration,
                      final.totalCallsDuration[label]?.incoming,
                      final.totalCallsDuration[label]?.outgoing
                    ),
                  },
                },
                totalIncomingCalls: {
                  ...final.totalIncomingCalls,
                  [label]: {
                    abandoned: combineValues(TotalInboundAbandonedCalls, final.totalIncomingCalls[label]?.abandoned),
                    answered: combineValues(TotalInboundAnsweredCalls, final.totalIncomingCalls[label]?.answered),
                    missed: combineValues(TotalInboundMissedCalls, final.totalIncomingCalls[label]?.missed),
                    missedOOO: combineValues(TotalInboundMissedOOOCalls, final.totalIncomingCalls[label]?.missedOOO),
                    patient: combineValues(TotalInboundCallsPatient, final.totalIncomingCalls[label]?.patient),
                    unknown: combineValues(TotalInboundCallsUnknown, final.totalIncomingCalls[label]?.unknown),
                  },
                },
                totalUnknownVsPatientCalls: {
                  ...final.totalUnknownVsPatientCalls,
                  [label]: {
                    patient: combineValues(
                      TotalInboundCallsPatient,
                      TotalOutboundCallsPatient,
                      final.totalUnknownVsPatientCalls[label]?.patient
                    ),
                    unknown: combineValues(
                      TotalInboundCallsUnknown,
                      TotalOutboundCallsUnknown,
                      final.totalUnknownVsPatientCalls[label]?.unknown
                    ),
                  },
                },
                totalUnknownVsPatientCallsDuration: {
                  ...final.totalUnknownVsPatientCallsDuration,
                  [label]: {
                    patient: combineValues(
                      TotalInboundCallDurationPatient,
                      TotalOutboundCallDurationPatient,
                      final.totalUnknownVsPatientCallsDuration[label]?.patient
                    ),
                    unknown: combineValues(
                      TotalInboundCallDurationUnknown,
                      TotalOutboundCallDurationUnknown,
                      final.totalUnknownVsPatientCallsDuration[label]?.unknown
                    ),
                  },
                },
                weaveAverages: {
                  ...final.weaveAverages,
                  callAnswerRate: {
                    ...final.weaveAverages.callAnswerRate,
                    // Store averages only date wise
                    [formatDateByTimezone(StartDate, filters.StartDate, locationData?.timezone)]:
                      WeaveCallAnswerRate || 0,
                  },
                },
              };
            },
            defaultGeneralData
          )
        : defaultGeneralData,
    [generalData]
  );

  const hourlyChartsData: PhoneAnalyticsTypes.TimeBoundInsights = useMemo(
    () =>
      hourlyData
        ? hourlyData.reduce(
            (
              final,
              {
                LocationName,
                TotalInboundAbandonedCalls,
                TotalInboundAnsweredCalls,
                TotalInboundCalls,
                TotalInboundMissedCalls,
                TotalInboundMissedOOOCalls,
                TotalOutboundAbandonedCalls,
                TotalOutboundAnsweredCalls,
                TotalOutboundCalls,
              }
            ) => {
              return {
                ...final,
                calls: {
                  ...final.calls,
                  incoming: {
                    ...final.calls.incoming,
                    [LocationName]: {
                      abandoned: combineValues(
                        TotalInboundAbandonedCalls,
                        final.calls.incoming?.[LocationName]?.abandoned
                      ),
                      answered: combineValues(
                        TotalInboundAnsweredCalls,
                        final.calls.incoming?.[LocationName]?.answered
                      ),
                      missed: {
                        ...final.calls.incoming?.[LocationName]?.missed,
                        closedOffice: combineValues(
                          TotalInboundMissedOOOCalls,
                          final.calls.incoming?.[LocationName]?.missed?.closedOffice
                        ),
                        openOffice: combineValues(
                          subtractArrayIndexes(
                            TotalInboundMissedCalls as number[],
                            TotalInboundMissedOOOCalls as number[]
                          ),
                          final.calls.incoming?.[LocationName]?.missed?.openOffice
                        ),
                        total: combineValues(
                          TotalInboundMissedCalls,
                          final.calls.incoming?.[LocationName]?.missed?.total
                        ),
                      },
                    },
                  },
                  outgoing: {
                    ...final.calls.outgoing,
                    [LocationName]: {
                      abandoned: combineValues(
                        TotalOutboundAbandonedCalls,
                        final.calls.outgoing?.[LocationName]?.abandoned
                      ),
                      answered: combineValues(
                        TotalOutboundAnsweredCalls,
                        final.calls.outgoing?.[LocationName]?.answered
                      ),
                    },
                  },
                  total: {
                    ...final.calls.total,
                    [LocationName]: {
                      incoming: combineValues(TotalInboundCalls, final.calls.total?.[LocationName]?.incoming),
                      outgoing: combineValues(TotalOutboundCalls, final.calls.total?.[LocationName]?.outgoing),
                      total: combineValues(
                        TotalInboundCalls,
                        TotalOutboundCalls,
                        final.calls.total?.[LocationName]?.total
                      ),
                    },
                  },
                },
              };
            },
            defaultTimeBoundData
          )
        : defaultTimeBoundData,
    [hourlyData]
  );

  useEffect(() => {
    onFetchStateChange(isLoadingGeneralData || isLoadingHourlyData);
  }, [isLoadingGeneralData, isLoadingHourlyData]);

  return (
    <div css={styles.wrapper}>
      <CallReportSummary
        filters={filters}
        generalChartsData={generalChartsData}
        isHourlyInsights={isHourlyInsights}
        isLoading={isLoadingGeneralData || isLoadingHourlyData}
        isOpenHoursOnly={filters.OpenOffice}
      />

      <Tabs initialTab={DEFAULT_TAB_VIEW}>
        <div css={styles.tabsWrapper}>
          <Tabs.Bar css={pageStyles.customTabSelect}>
            <Tabs.Tab
              id='call-analytics-view'
              controls='call-analytics-view-panel'
              trackingId={trackingIds.phoneAnalytics.callAnalyticsTab}
            >
              {t('Call Analytics')}
            </Tabs.Tab>
            <Tabs.Tab
              id='duration-analytics-view'
              controls='duration-analytics-view-panel'
              trackingId={trackingIds.phoneAnalytics.durationAnalyticsTab}
            >
              {t('Duration Analytics')}
            </Tabs.Tab>
          </Tabs.Bar>
        </div>

        <Tabs.Panel css={styles.tabPanel} controller='call-analytics-view' id='call-analytics-view-panel'>
          <CallAnalyticsPanel
            colors={colors}
            generalChartsData={generalChartsData}
            hourlyChartsData={hourlyChartsData}
            isHourlyInsights={isHourlyInsights}
            isLoading={isLoadingGeneralData || isLoadingHourlyData}
            isMultiLocations={(filters.LocationID || []).length > 1}
            isOpenHoursOnly={filters.OpenOffice}
          />
        </Tabs.Panel>
        <Tabs.Panel css={styles.tabPanel} controller='duration-analytics-view' id='duration-analytics-view-panel'>
          <DurationAnalyticsPanel
            colors={colors}
            data={generalChartsData}
            isHourlyInsights={isHourlyInsights}
            isLoading={isLoadingGeneralData || isLoadingHourlyData}
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

const styles = {
  wrapper: css`
    overflow: auto;
  `,

  tabPanel: css`
    height: 100%;
    overflow: auto;
  `,

  tabsWrapper: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(2)};
    top: ${theme.spacing(-2)};
    z-index: 1;
  `,
};
