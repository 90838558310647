import { useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { Button, DialerInput, Divider, KeypadButtons, Page } from '@frontend/generic-dialpad-accessories';
import { useDialer } from '../hooks/use-dialer';
import { isEstablishedCall, isOutgoingCall } from '../types';
import { useCallDuration } from '../hooks/use-call-duration';
import { useCallerInfo } from '../hooks/use-caller-info';
import { SettingsRow } from '../components/settings-row';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';
import { useSoftphoneDialer } from '../providers/softphone-dialer-provider';
import { useSoftphoneDirectory } from '../providers/softphone-directory-provider';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';
import { AutocompleteWraps } from '../components/autocomplete/auto-complete-wrap';
import { useDialerAutocomplete } from '../hooks/use-dialer-autocomplete';

export const PageCallTransfer = () => {
  const { t } = useTranslation();
  const blindTransfer = useSoftphoneCallActions((ctx) => ctx.blindTransfer);
  const startWarmTransfer = useSoftphoneCallActions((ctx) => ctx.startWarmTransfer);
  const [ready, setReady] = useState(false);
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);
  const currentDial = useSoftphoneDialer((ctx) => ctx.currentDial);
  const setCurrentDial = useSoftphoneDialer((ctx) => ctx.setCurrentDial);
  const isLoading = useSoftphoneDirectory((ctx) => ctx.isLoading);

  const callInfo = useCallerInfo({
    uri: primaryCall?.remoteParty.uri ?? '',
    displayName: primaryCall?.remoteParty.displayName,
  });
  const dialer = useDialer({
    onSubmit: () => {
      setReady(true);
      return Promise.resolve(true);
    },
  });
  const dialerInputProps = {
    currentDial,
    setCurrentDial,
    isDirectoryLoading: isLoading,
    ...dialer,
  };

  const autocompleteProps = useDialerAutocomplete({
    currentDial,
    dispatch: dialer.dispatch,
  });

  const autocompleteInputProps = {
    currentDial,
    inputState: dialer.inputState,
    isDirectoryLoading: isLoading,
    ...autocompleteProps,
  };

  const submitBlindTransfer = () => {
    if (!primaryCall || !isEstablishedCall(primaryCall)) {
      return;
    }
    return blindTransfer(primaryCall, callInfo.title).then(() => {
      dialer.dispatch({ type: 'reset' });
    });
  };

  const submitWarmTransfer = () => {
    if (!primaryCall || !isEstablishedCall(primaryCall)) {
      return;
    }
    return startWarmTransfer(primaryCall).then(() => {
      dialer.dispatch({ type: 'reset' });
    });
  };

  const startedAt =
    primaryCall && isEstablishedCall(primaryCall)
      ? primaryCall.establishedAt
      : primaryCall && isOutgoingCall(primaryCall)
      ? primaryCall.placedAt
      : undefined;

  if (!primaryCall) {
    return null;
  }

  return (
    <Page>
      <PageHeaderWraps hasUnderline hasBackButton={!!isMobile}>
        <Text color='white'>{t('Transfer Call')}</Text>
        <div
          css={css`
            display: flex;
            gap: ${theme.spacing(1)};
          `}
        >
          <Text color='white' weight='bold' as='div'>
            {callInfo.title}
          </Text>
          {startedAt && (
            <Text color='white' as='div'>
              <Duration startedAt={startedAt} />
            </Text>
          )}
        </div>
      </PageHeaderWraps>
      <Page.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <DialerInput placeholderLabel={t('Dial')} focusOnMount={false} {...dialerInputProps}>
            <AutocompleteWraps
              hideAutocompleteOnBlur={true}
              hideAutocompleteOnEmpty={false}
              {...autocompleteInputProps}
            />
          </DialerInput>
          <KeypadButtons />
          <footer
            css={css`
              display: flex;
              justify-content: center;
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            <Button.Rect
              disabled={!ready}
              onClick={() => submitBlindTransfer()}
              css={css`
                margin: ${theme.spacing(1)};
              `}
              trackingId='softphone-transfer-blind'
            >
              {t('Blind')}
            </Button.Rect>
            <Button.Rect
              disabled={!ready}
              onClick={() => submitWarmTransfer()}
              css={css`
                margin: ${theme.spacing(1)};
              `}
              trackingId='softphone-transfer-warm'
            >
              {t('Warm')}
            </Button.Rect>
          </footer>
        </form>
      </Page.Body>
      <Page.Footer>
        <Divider />
        <SettingsRow />
      </Page.Footer>
    </Page>
  );
};

//TODO: probably upgrade this for use in other components
const Duration = ({ startedAt }: { startedAt: Date }) => {
  const duration = useCallDuration(startedAt);
  return <>{duration}</>;
};
