import { SchemaDevices } from '@frontend/schema';
import {
  GetLineKeyOptionsType,
  GetLineKeysType,
  ListDevices,
  ReplaceLineKeysType,
  GetDeviceModelInfoType,
  DuplicateLineKeysType,
} from './types';
import { DeviceType_Enum, LineKey } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';

export const GetDevices = (req?: ListDevices['input']): Promise<ListDevices['output']> => {
  return SchemaDevices.List({
    deviceType: DeviceType_Enum.DESK_PHONE,
    locationIds: req?.locationIds,
  });
};

export const GetLineKeys = (deviceId: string, locationId?: string): Promise<GetLineKeysType['output']> => {
  return SchemaDevices.GetLineKeys(
    {
      deviceId,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const ReplaceLineKeys = ({
  deviceId,
  lineKeys,
  locationId,
}: {
  deviceId: string;
  lineKeys: LineKey[];
  locationId?: string;
}): Promise<ReplaceLineKeysType['output']> => {
  return SchemaDevices.ReplaceLineKeys(
    {
      deviceId,
      lineKeys,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const GetLineKeyOptions = (locationId?: string): Promise<GetLineKeyOptionsType['output']> => {
  return SchemaDevices.GetLineKeyOptions({}, locationId ? { headers: { 'Location-Id': locationId } } : undefined);
};

export const GetDeviceModelInfo = (locationId?: string): Promise<GetDeviceModelInfoType['output']> => {
  return SchemaDevices.GetModels({}, locationId ? { headers: { 'Location-Id': locationId } } : undefined);
};

export const DuplicateLineKeys = (
  sourceDeviceId: string,
  destinationDeviceIds: string[]
): Promise<DuplicateLineKeysType['output']> => {
  return SchemaDevices.DuplicateLineKeys({ sourceDeviceId, destinationDeviceIds });
};
