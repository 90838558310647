import { useMemo } from 'react';
import { useAppointmentAnalyticsStore } from '../../../hooks';
import { appointmentsDemoData } from '../demo-data';
import { useIsAppointmentDemoAccount } from './use-is-appointment-demo-account';

type UseAppointmentDemoData = {
  isDemoAccount?: boolean;
  appointmentsDemoData?: typeof appointmentsDemoData;
};

type SortKeys = 'AppointmentDate' | 'AppointmentCategory' | 'TypePM' | 'EntryDate';

const sortByKey = (records: (typeof appointmentsDemoData)['tableData'], key: SortKeys, orderByAsc?: boolean) => {
  return records.data.Data.slice().sort((a, b) => {
    let aValue, bValue;

    if (key.includes('Date')) {
      aValue = new Date(a[key]);
      bValue = new Date(b[key]);

      return orderByAsc ? aValue.getTime() - bValue.getTime() : bValue.getTime() - aValue.getTime();
    } else {
      aValue = a[key];
      bValue = b[key];

      return orderByAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
  });
};

export const useAppointmentDemoData = (): UseAppointmentDemoData => {
  const { isDemoAccount } = useIsAppointmentDemoAccount();
  const { filters } = useAppointmentAnalyticsStore();

  const sortedTableData = useMemo(() => {
    if (!filters.OrderByField) {
      return appointmentsDemoData.tableData;
    }

    return {
      ...appointmentsDemoData.tableData,
      data: {
        Data: sortByKey(appointmentsDemoData.tableData, filters.OrderByField as SortKeys, filters.OrderByAsc),
      },
    };
  }, [filters.OrderByAsc, filters.OrderByField]);

  return {
    isDemoAccount,
    appointmentsDemoData: isDemoAccount
      ? {
          ...appointmentsDemoData,
          tableData: sortedTableData,
        }
      : undefined,
  };
};
