import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { AnalyticsCommonTypes, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { TableFilters } from '@frontend/design-system';
import { FiltersTray } from '..';
import { DEFAULT_DAY } from '../../constants';
import { useLocations, useTimePeriodFilterLabels } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { appendLocalTimeStamp, defaultDateRangeMap, getMatchingDateRangeKey } from '../../utils';
import { filtersStyles } from '../../views/common-styles';
import { DatesRangeSelector, HoursSelector, LocationsSelector, TimePeriodSelector } from '../filter-selectors';
import { AdditionalPhoneFilters } from './additional-phone-filters';
import { usePhoneAnalyticsStore } from './hooks';

type Props = {
  isLoadingData?: boolean;
  isTableView?: boolean;
};

const defaultDates = defaultDateRangeMap[DEFAULT_DAY];

export const PhoneAnalyticsFilters = ({ isLoadingData, isTableView }: Props) => {
  const { t } = useTranslation('analytics');
  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const locationData = getSelectedLocationData()[locationId];

  const {
    filters,
    hasCustomFilters,
    locationId: phoneAnalyticsLocationId,
    setDefaultFilters,
    setFilterHintText,
    setFilters,
    setLocationId,
    resetStore,
  } = usePhoneAnalyticsStore();

  const [trayFilters, setTrayFilters] = useState<PhoneAnalyticsTypes.ReportFilterPayload>({});

  const timeFilterLabels = useTimePeriodFilterLabels();
  const { isLoading: isLoadingLocations, isSalesforceMultiLocation, isWAMMultiLocation, locations } = useLocations();
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const defaultFilters: PhoneAnalyticsTypes.ReportFilterPayload = useMemo(
    () => ({
      CallType: [],
      EndDate: appendLocalTimeStamp(defaultDates.endDate),
      LocationID: locations ? Object.keys(locations) : [],
      NumberSearch: '',
      OpenOffice: false,
      PhoneNumber: [],
      Result: [],
      SourceType: [],
      StartDate: appendLocalTimeStamp(defaultDates.startDate),
      TimeZone: locationData?.timezone,
    }),
    [locations]
  );

  const handleUpdateTrayFilters = (newFilters: Partial<PhoneAnalyticsTypes.ReportFilterPayload>) => {
    setTrayFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const applyDefaultFilters = () => {
    // Also used for resetting filters whenever required
    setDefaultFilters(defaultFilters);
    setErrors({});
    setFilters(defaultFilters);
  };

  const applyActiveFiltersToTrayState = () => {
    handleUpdateTrayFilters(filters);
  };

  const handleApplyTrayFilters = () => {
    // This method will be invoked when user clicks on apply button in the tray
    setFilters(trayFilters);
  };

  const handleUpdateErrors = (error: AnalyticsCommonTypes.filterSelectorError) => {
    setErrors((prevErrors) => ({ ...prevErrors, [error.name]: error.value }));
  };

  const hasErrors = useMemo(() => Object.values(errors).some((error) => error), [errors]);

  useEffect(() => {
    // Update local state when main filters has changed
    applyActiveFiltersToTrayState();

    const timeFilter = getMatchingDateRangeKey({
      datesMap: defaultDateRangeMap,
      endDate: filters.EndDate,
      startDate: filters.StartDate,
    });

    // This text is displayed in sub view pages and charts to indicate the currently applied filters
    setFilterHintText(
      timeFilterLabels[timeFilter || ''] ||
        `${dayjs(filters.StartDate).format('DD MMM, YYYY')} - ${dayjs(filters.EndDate).format('DD MMM, YYYY')}`
    );
  }, [filters]);

  useEffect(() => {
    // Avoid resetting when custom filters are already applied (user has navigated from sub page to the main page)
    if (!hasCustomFilters) {
      applyDefaultFilters();
    }
  }, [defaultFilters]);

  useEffect(() => {
    if (!phoneAnalyticsLocationId) {
      setLocationId(locationId);
    } else if (locationId !== phoneAnalyticsLocationId) {
      //  Reset when location is switched
      resetStore();
      setLocationId(locationId);
      applyDefaultFilters();
    }
  }, [locationId, phoneAnalyticsLocationId]);

  return (
    <FiltersTray
      disableApply={hasErrors}
      disableTray={isLoadingData}
      headerLabel={t('Filter Phone Analytics')}
      onApplyFilters={handleApplyTrayFilters}
      onResetFilters={applyDefaultFilters}
      onRevertLocalChanges={applyActiveFiltersToTrayState}
      showFilteredBadge={hasCustomFilters}
      trackingId={trackingIds.phoneAnalytics.filtersOpen}
    >
      {(isSalesforceMultiLocation || isWAMMultiLocation) && (
        <TableFilters.Section sectionHeaderLabel={t('Location')}>
          <div css={filtersStyles.traySection}>
            <LocationsSelector
              disabled={isLoadingData || isLoadingLocations}
              locations={locations}
              onChange={(value) => handleUpdateTrayFilters({ LocationID: value })}
              onError={handleUpdateErrors}
              value={trayFilters.LocationID}
            />
          </div>
        </TableFilters.Section>
      )}

      <TableFilters.Section sectionHeaderLabel={t('Time')}>
        <div css={filtersStyles.traySection}>
          <TimePeriodSelector
            defaultPeriod={DEFAULT_DAY}
            disabled={isLoadingData}
            endDate={trayFilters.EndDate}
            onChange={({ endDate, startDate }) => {
              handleUpdateTrayFilters({
                EndDate: appendLocalTimeStamp(endDate),
                StartDate: appendLocalTimeStamp(startDate),
              });
            }}
            startDate={trayFilters.StartDate}
          />
          <DatesRangeSelector
            endDate={trayFilters.EndDate}
            onChange={({ endDate, startDate }) =>
              handleUpdateTrayFilters({
                EndDate: appendLocalTimeStamp(endDate),
                StartDate: appendLocalTimeStamp(startDate),
              })
            }
            startDate={trayFilters.StartDate}
          />
        </div>
      </TableFilters.Section>

      {isTableView ? (
        <AdditionalPhoneFilters disabled={isLoadingData} filters={filters} onChange={handleUpdateTrayFilters} />
      ) : (
        <TableFilters.Section sectionHeaderLabel={t('View')}>
          <HoursSelector
            disabled={isLoadingData}
            onChange={(value) => handleUpdateTrayFilters({ OpenOffice: value === 'open-hours' })}
            value={trayFilters.OpenOffice ? 'open-hours' : 'all-hours'}
          />
        </TableFilters.Section>
      )}
    </FiltersTray>
  );
};
