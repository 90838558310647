import { MediaQueries } from '@frontend/api-media';
import { useBulkListContext } from '@frontend/bulk-list-provider';
import { Avatar, ChatItem } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { useMessagePersonNames, useScheduledSmsBubbleActions } from '../../../../../hooks';
import { Direction } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';

type ScheduledMessageBubbleProps = {
  message: ScheduledSms;
  mediaQueries: Record<string, MediaQueries.UseMmsMediaItem>;
  onEdit?: () => void;
};

export const ScheduledMessageBubble = ({ message, mediaQueries, onEdit }: ScheduledMessageBubbleProps) => {
  const { t } = useTranslation('inbox');
  const { senderName } = useMessagePersonNames({
    direction: Direction.DIRECTION_OUTBOUND,
    autogeneratedBy: '',
    createdBy: message.scheduledBy,
    deletedAt: '',
    deletedBy: '',
  });
  const { isActive: selectionIsActive } = useBulkListContext();
  const getBubbleActions = useScheduledSmsBubbleActions({ scheduledSms: message, onEdit });
  const isPaused = message.status === OutboundMessageStatus.PAUSED;
  const timestampFormatter = useTimestampFormatter();

  return (
    <ChatItem
      direction='outbound'
      avatar={<Avatar size='small' title={senderName || t('Unknown User')} name={senderName} />}
      timestamp={isPaused ? t('Scheduled send paused') : timestampFormatter(message.sendAt)}
      isFuture
      isPaused={isPaused}
      moreActions={getBubbleActions()}
      data-messagetype='scheduled'
      maxWidth='min(80%, 600px)'
    >
      {message.mediaIds.map((mediaId) => {
        const mediaQuery = mediaQueries[mediaId];
        const isLoading = !!mediaQuery?.isLoading || !mediaQuery?.data?.src;
        return (
          <ChatItem.Image
            key={mediaId}
            loadingText={mediaQuery?.isLoading ? t('Loading image...') : undefined}
            src={mediaQuery?.data?.src}
            suppressPreview={selectionIsActive}
            contextActions={getBubbleActions({ mediaId })}
            maxWidth={isLoading ? '320px' : 'min(40%, 320px)'}
            maxHeight={isLoading ? '320px' : 'min(40vh, 480px)'}
          />
        );
      })}
      {!!message.body && <ChatItem.Bubble contextActions={getBubbleActions()} text={message.body} />}
    </ChatItem>
  );
};
