import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { isEmpty, isEqual } from 'lodash-es';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneFaxNumber } from '@frontend/phone-numbers';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { FormRow, PhoneField, Text, TextField, useForm, useModalControl } from '@frontend/design-system';
import { useAddressForm, useStepControl } from '../../../hooks';
import { convertToIntakeFormAddress, defaultAddress } from '../../../utils/address';
import { AddressCard, Step } from '../../common';
import { AddressContainer } from './address-container';
import { AddressesNotVerifiedConfirmModal } from './addresses-not-verified-confirm-modal';
import { E911InfoModal } from './e911-info-modal';
import { E911TermsConfirmModal } from './e911-terms-confirm-modal';
import { ShippingInfoModal } from './shipping-info-modal';

export const AddressesStep = () => {
  const { t } = useTranslation('onboarding');
  const { debug, intakeForm, stepNavigation, updateIntakeForm } = useStepControl();
  const termsAndConditionsModalControl = useModalControl();
  const e911ModalControl = useModalControl();
  const addressesNotVerifiedConfirmModalControl = useModalControl();
  const shippingInfoModalControl = useModalControl();

  const [isShippingSameAsOtherAddress, setIsShippingSameAsOtherAddress] = useState(
    intakeForm?.shippingSameAsOtherAddress ?? false
  );
  const [isE911SameAsOtherAddress, setIsE911SameAsOtherAddress] = useState(intakeForm?.e911SameAsOtherAddress ?? false);
  const {
    getFieldProps: getBusinessFieldProps,
    isComplete: businessFieldsAreSet,
    values: businessFieldValues,
  } = useForm({
    fields: {
      businessName: { type: 'text', required: !debug, value: intakeForm?.businessName },
      businessPhone: { type: 'phone', required: !debug, value: intakeForm?.businessPhone },
    },
  });

  const handleBillingAddressSelected = (updates: IntakeFormTypes.IntakeFormAddress) => {
    const isAddressVerified = updates.verified ?? false;
    const address = convertToIntakeFormAddress(updates, isAddressVerified);
    updateIntakeForm(
      {
        billingAddress: address,
        businessName: businessFieldValues.businessName,
        businessPhone: businessFieldValues.businessPhone,
        ...(isShippingSameAsOtherAddress && { shippingAddress: address }),
        ...(isE911SameAsOtherAddress && { e911Address: address }),
      },
      true
    );

    if (isShippingSameAsOtherAddress) {
      setShippingAddress(address, isAddressVerified);
    }
    if (isE911SameAsOtherAddress) {
      e911SetAddress(address, isAddressVerified);
    }
  };

  const handleShippingAddressSelected = (updates: IntakeFormTypes.IntakeFormAddress) => {
    const address = convertToIntakeFormAddress(updates, updates.verified ?? false);
    updateIntakeForm({ shippingAddress: address }, true);
  };

  const handleE911AddressSelected = (updates: IntakeFormTypes.IntakeFormAddress) => {
    const address = convertToIntakeFormAddress(updates, updates.verified ?? false);
    updateIntakeForm({ e911Address: address }, true);
  };

  const {
    addressFormProps: shippingAddressFormProps,
    setIsEditMode: shippingAddressSetIsEditMode,
    isValid: shippingAddressIsValid,
    isVerified: shippingAddressIsVerified,
    setAddress: setShippingAddress,
    values: shippingAddressValues,
  } = useAddressForm({
    initialAddress: intakeForm?.shippingAddress,
    initialIsVerified: intakeForm?.shippingAddress?.verified,
    onAddressSelect: handleShippingAddressSelected,
    debug,
  });

  const {
    addressFormProps: billingAddressFormProps,
    isValid: billingAddressIsValid,
    isVerified: billingAddressIsVerified,
    values: billingAddressValues,
  } = useAddressForm({
    initialAddress: intakeForm?.billingAddress,
    initialIsVerified: intakeForm?.billingAddress?.verified,
    onAddressSelect: handleBillingAddressSelected,
    debug,
  });

  const {
    addressFormProps: e911AddressFormProps,
    isValid: e911AddressIsValid,
    setIsEditMode: e911AddressSetIsEditMode,
    isVerified: e911AddressIsVerified,
    setAddress: e911SetAddress,
    values: e911AddressValues,
  } = useAddressForm({
    initialAddress: intakeForm?.e911Address,
    initialIsVerified: intakeForm?.e911Address?.verified,
    onAddressSelect: handleE911AddressSelected,
    debug,
  });

  useEffect(() => {
    const address = intakeForm?.billingAddress ?? defaultAddress;
    const addressIsVerified = intakeForm?.billingAddress?.verified ?? false;

    if (isEmpty(intakeForm?.e911Address)) {
      e911SetAddress(address, addressIsVerified);
      setIsE911SameAsOtherAddress(true);
    } else if (intakeForm?.e911SameAsOtherAddress === null) {
      setIsE911SameAsOtherAddress(isEqual(intakeForm?.billingAddress, intakeForm.e911Address));
    }

    if (isEmpty(intakeForm?.shippingAddress)) {
      setShippingAddress(address, addressIsVerified);
      setIsShippingSameAsOtherAddress(true);
    } else if (intakeForm?.shippingSameAsOtherAddress === null) {
      setIsShippingSameAsOtherAddress(isEqual(intakeForm?.billingAddress, intakeForm.shippingAddress));
    }
  }, []);

  const handleE911TermsConfirmClick = () => {
    stepNavigation.onNextClick({
      businessName: businessFieldValues.businessName,
      businessPhone: businessFieldValues.businessPhone,
      shippingAddress: convertToIntakeFormAddress(shippingAddressValues, shippingAddressIsVerified),
      billingAddress: convertToIntakeFormAddress(billingAddressValues, billingAddressIsVerified),
      e911Address: convertToIntakeFormAddress(e911AddressValues, e911AddressIsVerified),
      shippingSameAsOtherAddress: isShippingSameAsOtherAddress,
      e911SameAsOtherAddress: isE911SameAsOtherAddress,
    });
  };

  const handleShippingSameAsOtherCheckboxChange = (checkboxValue: boolean) => {
    setIsShippingSameAsOtherAddress(checkboxValue);

    if (checkboxValue) {
      shippingAddressSetIsEditMode(false);
      setShippingAddress(
        convertToIntakeFormAddress(billingAddressValues, billingAddressIsVerified),
        billingAddressIsVerified
      );
    }
  };

  const handleE911SameAsOtherCheckboxChange = (checkboxValue: boolean) => {
    setIsE911SameAsOtherAddress(checkboxValue);

    if (checkboxValue) {
      e911AddressSetIsEditMode(false);
      e911SetAddress(
        convertToIntakeFormAddress(billingAddressValues, billingAddressIsVerified),
        billingAddressIsVerified
      );
    }
  };

  const handleNextClick = () => {
    if (!e911AddressIsVerified || !shippingAddressIsVerified) {
      addressesNotVerifiedConfirmModalControl.openModal();
    } else {
      termsAndConditionsModalControl.openModal();
    }
  };

  const areAddressesValid = shippingAddressIsValid && billingAddressIsValid && e911AddressIsValid;
  const shouldDisableNextButton =
    !businessFieldsAreSet ||
    !areAddressesValid ||
    shippingAddressFormProps.isEditMode ||
    billingAddressFormProps.isEditMode ||
    e911AddressFormProps.isEditMode;

  return (
    <>
      <Step css={{ maxWidth: 600 }}>
        <Step.Header title={t("Let's verify your business information")} />
        <Step.Body css={{ alignItems: 'flex-center' }}>
          <Text css={{ marginBottom: theme.spacing(5) }}>
            {t(
              'Your phone order will ship as soon as you submit this form. Please ensure your information below is correct.'
            )}
          </Text>
          <div css={cardContainer} data-testid='addressesStepCard'>
            <AddressCard
              address={convertToIntakeFormAddress(billingAddressValues, billingAddressIsVerified)}
              addressFormProps={billingAddressFormProps}
              disabled={shippingAddressFormProps.isEditMode || e911AddressFormProps.isEditMode}
              cardTitle={t('Billing Address')}
              extraFieldsComplete={businessFieldsAreSet}
              displayModeTopChildren={<Text>{businessFieldValues.businessName}</Text>}
              displayModeBottomChildren={
                <Text css={{ marginTop: theme.spacing(2) }}>
                  {formatPhoneFaxNumber(businessFieldValues.businessPhone ?? '')}
                </Text>
              }
              extraFields={
                <>
                  <FormRow>
                    <TextField
                      {...getBusinessFieldProps('businessName')}
                      label={t('Business Name')}
                      data-testid='businessName'
                    />
                  </FormRow>
                  <FormRow>
                    <PhoneField
                      {...getBusinessFieldProps('businessPhone')}
                      label={t('Main Phone Number')}
                      data-testId='mainPhoneNumber'
                    />
                  </FormRow>
                </>
              }
            />
          </div>

          <div css={cardContainer} data-testid='addressesStepCard'>
            <AddressContainer
              address={convertToIntakeFormAddress(shippingAddressValues, shippingAddressIsVerified)}
              addressFormProps={shippingAddressFormProps}
              disabled={billingAddressFormProps.isEditMode || e911AddressFormProps.isEditMode}
              addressName={t('Shipping')}
              onInfoIconClicked={shippingInfoModalControl.openModal}
              checkboxState={isShippingSameAsOtherAddress}
              onCheckboxChange={handleShippingSameAsOtherCheckboxChange}
            />
          </div>
          <div css={cardContainer} data-testid='addressesStepCard'>
            <AddressContainer
              address={convertToIntakeFormAddress(e911AddressValues, e911AddressIsVerified)}
              addressFormProps={e911AddressFormProps}
              disabled={billingAddressFormProps.isEditMode || shippingAddressFormProps.isEditMode}
              addressName={t('E911')}
              onInfoIconClicked={e911ModalControl.openModal}
              checkboxState={isE911SameAsOtherAddress}
              onCheckboxChange={handleE911SameAsOtherCheckboxChange}
            />
          </div>
        </Step.Body>
        <Step.Navigation
          nextButtonLabel={t('Next')}
          nextButtonTrackingId={`${IntakePrefixes.BusinessInformation}-next-btn`}
          onNextClick={handleNextClick}
          disableNext={shouldDisableNextButton}
        />
      </Step>

      <E911InfoModal modalProps={e911ModalControl.modalProps} />
      <ShippingInfoModal modalProps={shippingInfoModalControl.modalProps} />
      <E911TermsConfirmModal
        modalProps={termsAndConditionsModalControl.modalProps}
        onConfirmClick={handleE911TermsConfirmClick}
      />
      <AddressesNotVerifiedConfirmModal
        modalProps={addressesNotVerifiedConfirmModalControl.modalProps}
        onConfirmClick={termsAndConditionsModalControl.openModal}
        isE911AddressVerified={e911AddressIsVerified}
        isShippingAddressVerified={shippingAddressIsVerified}
      />
    </>
  );
};

const cardContainer = css`
  width: 100%;
  padding: ${theme.spacing(3, 0)};
  border: solid ${theme.colors.neutral20};
  border-width: 1px 0 0;
  &:last-child {
    border-bottom-width: 1px;
  }
`;
