import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const currentRecordStyles = css`
  background-color: ${theme.colors.success10};
`;

export const compareRecordStyles = css`
  background-color: ${theme.colors.primary10};
`;
