import {
  MessageIcon,
  NakedButton,
  PhoneIncomingIcon,
  PhoneMissedIcon,
  PhoneOutgoingIcon,
  Text,
  styles,
} from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { Entry, Person } from '@weave/schema-gen-ts/dist/schemas/comm/history/v1/history_service.pb';
import { Direction } from '@weave/schema-gen-ts/dist/schemas/comm/shared/v1/enums.pb';
import dayjs from 'dayjs';
import { PersonHelpers } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { ActionsUI } from '@frontend/contact-actions';
import { HintedString } from '@frontend/types';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { sentry } from '@frontend/tracking';

type CallStatus = HintedString<'missed' | 'abandoned' | 'answered'>;

const getPersonName = (person?: Person) => {
  const { t } = useTranslation('contacts');

  if (person?.lastName && person?.firstName) {
    return PersonHelpers.getFullName({
      FirstName: person?.firstName,
      LastName: person?.lastName,
      PreferredName: person?.preferredName,
    });
  } else {
    return t('unknown employee');
  }
};

const getSmsIcon = () => {
  return <MessageIcon css={{ paddingRight: theme.spacing(1) }} />;
};

const getPhoneIcon = (direction?: Direction, status?: CallStatus) => {
  const isInbound = direction === Direction.DIRECTION_INBOUND;
  const isOutbound = direction === Direction.DIRECTION_OUTBOUND;

  if (isInbound && status !== 'answered') {
    return <PhoneMissedIcon css={{ paddingRight: theme.spacing(1) }} color='error' />;
  }
  if (isInbound) {
    return <PhoneIncomingIcon css={{ paddingRight: theme.spacing(1) }} />;
  }
  if (isOutbound) {
    return <PhoneOutgoingIcon css={{ paddingRight: theme.spacing(1) }} />;
  }
  return null;
};

const getPhoneText = ({
  direction,
  status,
  locationUser,
}: {
  direction?: Direction;
  status?: CallStatus;
  locationUser?: Entry['locationUser'];
}) => {
  const { t } = useTranslation('contacts');
  const isInbound = direction === Direction.DIRECTION_INBOUND;
  const isOutbound = direction === Direction.DIRECTION_OUTBOUND;

  if (isInbound && status !== 'answered') {
    return t('Missed call');
  }
  if (isInbound) {
    return t('Call answered by {{person}}', { person: getPersonName(locationUser) });
  }
  if (isOutbound) {
    return t('Call placed by {{person}}', {
      person: getPersonName(locationUser),
    });
  }
  return 'Unknown';
};

const historyEntryContainerStyles = {
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  display: 'flex',
  height: 105,
  padding: theme.spacing(2),
  width: '100%',
};

const getBody = ({ timestamp, body }: { timestamp?: string; body?: string }) => (
  <div>
    <Text textAlign='left' weight='bold'>
      {dayjs(timestamp).calendar()}
    </Text>
    <Text textAlign='left' color='light' css={styles.multilineTruncate(2)}>
      {body}
    </Text>
  </div>
);

export const PhoneHistoryEntry = ({
  direction,
  recordDetails,
  timestamp,
  locationUser,
}: Pick<Entry, 'direction' | 'recordDetails' | 'timestamp' | 'locationUser'>) => (
  <div css={historyEntryContainerStyles} data-trackingid='contact-2.0-tray-tab-history-call'>
    {getPhoneIcon(direction, recordDetails?.status)}
    {getBody({ timestamp, body: getPhoneText({ direction, locationUser, status: recordDetails?.status }) })}
  </div>
);

export const SmsHistoryEntry = ({
  person,
  personPhone,
  recordDetails,
  timestamp,
}: Pick<Entry, 'person' | 'personPhone' | 'recordDetails' | 'timestamp'>) => {
  const { setShow } = useSlidePanelShallowStore('setShow');
  const context = {
    personId: person?.personId || '',
    locationId: recordDetails?.locationId || '',
    phoneNumber: personPhone || '',
    departmentId: recordDetails?.departmentId || '',
  };
  const { Modal, triggerProps, disabled } = ActionsUI.actions.useMessageAction({
    context: context,
    targetSmsData:
      recordDetails?.messageId && timestamp
        ? {
            id: recordDetails?.messageId,
            createdAt: timestamp,
          }
        : undefined,
  });

  return (
    <>
      {Modal}
      <NakedButton
        css={historyEntryContainerStyles}
        onClick={() => {
          if (disabled) {
            sentry.error({
              topic: 'messages',
              error: 'Message action disabled',
              addContext: { name: 'Send sms from history tab', context: context },
            });
            return;
          } else {
            triggerProps?.onClick();
            setShow(false);
          }
        }}
        trackingId='contact-2.0-tray-tab-history-message'
      >
        {getSmsIcon()}
        {getBody({ timestamp, body: recordDetails?.body })}
      </NakedButton>
    </>
  );
};
