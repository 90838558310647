import { AccountManagerQueries } from '@frontend/api';
import { OnboardingModulesQueries, OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { useAppScopeStore } from '@frontend/scope';

export const useAccountOwnerPopup = () => {
  const { selectedParentsIds } = useAppScopeStore();
  const isMultiLocation = selectedParentsIds.length > 0;

  const {
    data: userTasksData,
    isSuccess: isSuccessUserTasks,
    isFetched: isFetchedUserTasks,
  } = OnboardingModulesQueries.useFetchUserTasksQuery();

  const hasCompletedFirstTimeUserWelcomeTask = userTasksData?.some(
    (taskStatus) =>
      !!taskStatus.completedAt && taskStatus.taskId === OnboardingModulesTypes.TaskIds.PortalWelcomeExperience
  );
  // The account owner popup will interfere with the first time user experience (FTUE) guide so we will not display it if
  // that guide has not been completed (we can determine if the guide has been completed by fetching the user tasks data).
  // That FTUE guide is not presented on parent locations so we don't have to worry about the task being completed for
  // parent locations.
  // We don't care to suppress the popup if we failed to fetch the user tasks data since this most likely means that the
  // the FTUE guide will not show since it is also fetched from the same endpoint.
  // We need to suppress fetch the popup status due to the mutation side effect on the BE (see comment below).
  const suppressForUserTask =
    !isFetchedUserTasks || (isSuccessUserTasks && !hasCompletedFirstTimeUserWelcomeTask && !isMultiLocation);

  const {
    data: accountManagerData,
    isLoading: isLoadingAccountManagerData,
    isError: isErrorAccountManagerData,
    isFetched: isFetchedAccountManagerData,
    isSuccess: isSuccessAccountManagerData,
  } = AccountManagerQueries.useAccountManagerDataQuery();

  const suppressForAccountManagerData = !isSuccessAccountManagerData || !accountManagerData?.userId;

  const {
    data: accountOwnerPopupStatus,
    isLoading: isLoadingAccountOwnerPopupStatus,
    isError: isErrorAccountOwnerPopupStatus,
    isFetched: isFetchedAccountOwnerPopupStatus,
  } = AccountManagerQueries.useCSMContactPopupStatusByUserIDAndLocationID({
    // Fetching the account owner popup status causes a mutation side effect on the BE (it sets the isModalShown flag to
    // true in the DB) so we can only fetch this once per user and so must ensure that we only fetch the status if we
    // have the necessary data to display the popup.
    enabled: !suppressForUserTask && !suppressForAccountManagerData,
  });

  const isLoading = isLoadingAccountOwnerPopupStatus || isLoadingAccountManagerData;
  const isFetched = isFetchedAccountOwnerPopupStatus && isFetchedAccountManagerData;
  const isError = isErrorAccountOwnerPopupStatus || isErrorAccountManagerData;

  const hasPopupBeenShownBefore = accountOwnerPopupStatus?.isModalShown;
  const shouldShowAccountOwnerPopup =
    !isLoading && !isError && isFetched && !hasPopupBeenShownBefore && !!accountManagerData?.userId;

  return {
    shouldShowAccountOwnerPopup,
    accountManagerData,
  };
};
