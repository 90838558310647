import { css } from '@emotion/react';
import { FormRow, Heading, Modal, Stepper, Text, TextField } from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FC } from 'react';
import { CreateSoftphonePropTypes, inputFieldStyles, stepperButtonBarStyle } from '..';
import { Icon } from '@frontend/icons';
import { useCreateSoftphone } from '../../../../store/create-softphone-provider';

type Props = CreateSoftphonePropTypes;

export const SecondStep: FC<React.PropsWithChildren<Props>> = ({ getFieldProps, values }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'create-new-softphone' });
  const { setShowConfirmedExitModal } = useCreateSoftphone(['setShowConfirmedExitModal']);

  return (
    <>
      <Modal.Header onClose={() => setShowConfirmedExitModal(true)}>{t('Set the Device Name')}</Modal.Header>
      <Modal.Body>
        <Stepper.Content>
          <Text css={spacingStyles(3)}>
            <Trans t={t}>
              Set the device name for <b>{values?.firstName}'s</b> softphone. You can change the device name at any time
              in the Admin Portal.
            </Trans>
          </Text>

          <Heading level={3}>{t('Device Extension Number')}</Heading>
          <FormRow css={spacingStyles(2)}>
            <Text>{getFieldProps('extension').value}</Text>
          </FormRow>

          <Heading css={spacingStyles(2)} level={3}>
            {t('Device Name')}
          </Heading>

          <FormRow css={inputFieldStyles}>
            <TextField
              containerCss={spacingStyles(3)}
              helperText={t('This name is used to identify the device with configurations.')}
              {...getFieldProps('deviceName')}
              label={t('Device Name')}
            />
          </FormRow>

          <FormRow css={inputFieldStyles}>
            <TextField
              containerCss={spacingStyles(4.5)}
              helperText={t('This name will appear on the devices you call.')}
              {...getFieldProps('internalIDName')}
              label={t('Internal ID Name')}
            />
          </FormRow>
        </Stepper.Content>
        <Stepper.ButtonBar css={stepperButtonBarStyle}>
          <Stepper.PreviousButton>
            <Icon name='back' />
            <Text
              css={css`
                margin-left: ${theme.spacing(1)};
              `}
              color='primary'
              weight='bold'
            >
              {t('Back')}
            </Text>
          </Stepper.PreviousButton>
          <Stepper.NextButton isValid={!!values?.deviceName && !!values.internalIDName}>
            {t('Continue')}
          </Stepper.NextButton>
        </Stepper.ButtonBar>
      </Modal.Body>
    </>
  );
};

const spacingStyles = (spacing: number) => css`
  margin-bottom: ${theme.spacing(spacing)};
`;
