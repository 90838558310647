import React, { ButtonHTMLAttributes } from 'react';
import { Button } from './button';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick?: () => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  disabled?: boolean;
  active?: boolean;
  className?: string;
  iconNode?: React.ReactNode;
  color?: 'green' | 'grey';
  label?: string;
  trackingId?: string;
};

const style = () => css`
  margin: ${theme.spacing(1, 1.5)};
  padding: ${theme.spacing(1.5)};
  height: ${theme.spacing(8)};
  width: ${theme.spacing(8)};
  cursor: pointer;
  font-size: ${theme.fontSize(14)};
  * {
    cursor: pointer;
  }
`;

export const IconButton: React.FC<React.PropsWithChildren<IconButtonProps>> = ({
  children,
  label,
  disabled = false,
  active = false,
  className,
  type,
  color = 'grey',
  onClick,
  onMouseUp,
  onMouseDown,
  iconNode,
  trackingId,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      type={type}
      disabled={disabled}
      onClick={onClick}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      className={`${className || ''}
          ${color}
          ${active ? 'active' : ''}
          ${disabled ? 'disabled' : ''}`}
      css={style}
      data-trackingid={trackingId}
    >
      {!iconNode && (
        <>
          {children !== null && children !== undefined && <label className='main-label'>{children}</label>}
          {label && <label className='alt-label'>{label}</label>}
        </>
      )}
      {!!iconNode && <label className='only-label'>{iconNode}</label>}
    </Button>
  );
};
