import { useCallback, useEffect, useMemo, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { SoftphoneCallActionsProvider } from './softphone-call-actions.provider';
import {
  AnyCall,
  EstablishedCall,
  IncomingCall,
  MergedCallGroup,
  OutgoingCall,
  TerminatedCall,
  Transfer,
  TransferIds,
  isEstablishedCall,
  isIncomingCall,
  isOutgoingCall,
  isTerminatedCall,
} from '../../types';
import { useSoftphoneEventsEmitter } from '../softphone-events-provider';

export type SoftphoneCallStateContextValue = {
  getCallById: (id: string) => AnyCall | undefined;
  addCall: (call: AnyCall) => void;
  establishCall: (call: IncomingCall | OutgoingCall) => EstablishedCall;
  terminateCall: (call: Exclude<AnyCall, TerminatedCall>, reason: string, disposeTimeout?: number) => TerminatedCall;
  updateCall: <T extends AnyCall>(call: T, updates: Partial<T>) => T;
  mergedCallGroup: MergedCallGroup | undefined;
  setMergedCallIds: (ids: string[]) => void;
  primaryCall: OutgoingCall | EstablishedCall | undefined;
  setPrimaryCall: (id: string) => void;
  calls: AnyCall[];
  incomingCalls: IncomingCall[];
  outgoingCalls: OutgoingCall[];
  establishedCalls: EstablishedCall[];
  terminatedCalls: TerminatedCall[];
  currentTransfer: Transfer | undefined;
  setCurrentTransfer: (transfer: TransferIds | undefined) => void;
  setIsPlaceCallLoading: (value: boolean) => void;
  isPlaceCallLoading: boolean;
  isMuted: boolean;
  setIsMuted: (muted: boolean) => void;
};

const SoftphoneCallStateContext = createContext(undefined as unknown as SoftphoneCallStateContextValue);

type Props = {
  children: React.ReactNode;
};
export const SoftphoneCallStateProvider = ({ children }: Props) => {
  const [calls, setCalls] = useState<AnyCall[]>([]);
  const { emit } = useSoftphoneEventsEmitter();
  const [primaryCallId, setPrimaryCallId] = useState<string>();
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaceCallLoading, setIsPlaceCallLoading] = useState(false);

  const { incomingCalls, outgoingCalls, establishedCalls, terminatedCalls, primaryCall } = useMemo(() => {
    return {
      incomingCalls: calls.filter(isIncomingCall),
      outgoingCalls: calls.filter(isOutgoingCall),
      establishedCalls: calls.filter(isEstablishedCall),
      terminatedCalls: calls.filter(isTerminatedCall),
      primaryCall: primaryCallId
        ? (calls.find((call) => call.id === primaryCallId) as OutgoingCall | EstablishedCall)
        : undefined,
    };
  }, [calls, primaryCallId]);

  const [mergedCallIds, setMergedCallIds] = useState<string[]>();
  const [currentTransferIds, setCurrentTransfer] = useState<TransferIds>();

  const setPrimaryCall = (id: string | undefined) => {
    setPrimaryCallId(id);
  };

  const getCallById = useCallback(
    (id: string) => {
      return calls.find((call) => call.id === id);
    },
    [calls]
  );

  const addCall = (call: AnyCall) => {
    setCalls((prev) => [...prev, call]);
  };

  const updateCall: SoftphoneCallStateContextValue['updateCall'] = (call, updates) => {
    setCalls((prev) => {
      return prev.map((c) => (call.id === c.id ? { ...c, ...updates } : c));
    });
    return call;
  };

  const terminateCall: SoftphoneCallStateContextValue['terminateCall'] = (call, reason, disposeTimeout = 1000) => {
    const terminatedCall = {
      ...call,
      terminatedAt: new Date(),
      terminationReason: reason,
    } satisfies TerminatedCall;
    setCalls((prev) => {
      return prev.map<AnyCall>((c) => (c.id === call.id ? terminatedCall : c));
    });
    setTimeout(() => {
      setCalls((prev) => prev.filter((c) => c.id !== terminatedCall.id));
      setMergedCallIds((prev) => {
        const next = prev?.filter((id) => id !== terminatedCall.id);
        //if the merged call group is down to one call, it's no longer a group, so we empty the group
        return next?.length === 1 ? [] : next;
      });
      if (primaryCall?.id === call.id) {
        setPrimaryCallId(undefined);
      }
    }, disposeTimeout);
    return terminatedCall;
  };

  const establishCall: SoftphoneCallStateContextValue['establishCall'] = (call) => {
    const establishedCall = {
      ...call,
      establishedAt: new Date(),
      session: isIncomingCall(call) ? call.invitation : call.inviter,
    } satisfies EstablishedCall;
    setCalls((prev) => {
      return prev.map((c) => (call === c ? establishedCall : c));
    });
    return establishedCall;
  };

  const currentTransfer = useMemo<Transfer | undefined>(() => {
    if (!currentTransferIds) {
      return undefined;
    }
    const initialCall = getCallById(currentTransferIds.initialCallId);
    const transferTarget = getCallById(currentTransferIds.transferTargetId);
    if (
      !initialCall ||
      !transferTarget ||
      !isEstablishedCall(initialCall) ||
      (!isEstablishedCall(transferTarget) && !isOutgoingCall(transferTarget))
    ) {
      return undefined;
    }
    return {
      initialCall,
      transferTarget,
    } satisfies Transfer;
  }, [currentTransferIds, getCallById]);

  const mergedCallGroup = useMemo(() => {
    return mergedCallIds?.map((id) => getCallById(id)).filter((call) => !!call) as MergedCallGroup;
  }, [calls, mergedCallIds]);

  //makes sure there's always a primary call if there are any non-incoming calls
  useEffect(() => {
    const nonIncomingCalls = calls.filter((call) => !isIncomingCall(call));
    if (nonIncomingCalls.length && !primaryCallId) {
      setPrimaryCallId(nonIncomingCalls?.[0]?.id ?? undefined);
    } else if (primaryCallId && nonIncomingCalls.length) {
      const matchingCall = nonIncomingCalls.find((call) => call.id === primaryCallId);
      //if the primary call id is pointing at a call that no longer exists, just switch to the next call in the list
      if (!matchingCall) {
        setPrimaryCall(nonIncomingCalls.find((call) => call.id !== primaryCallId)?.id ?? undefined);
      }
    } else if (primaryCallId && !nonIncomingCalls.length) {
      setPrimaryCall(undefined);
    }
  }, [calls, currentTransfer, primaryCallId]);

  //make sure the transfer state is reset if the calls involved in the transfer are disposed
  useEffect(() => {
    if (currentTransfer) {
      const bothCallsExist =
        !!calls.find((call) => call.id === currentTransfer?.initialCall?.id) &&
        !!calls.find((call) => call.id === currentTransfer?.transferTarget?.id);

      if (!bothCallsExist) {
        setCurrentTransfer(undefined);
      }
    }
  }, [calls, currentTransfer]);

  const value = {
    addCall,
    updateCall,
    establishCall,
    terminateCall,
    primaryCall,
    getCallById,
    setPrimaryCall,
    mergedCallGroup,
    setMergedCallIds,
    calls,
    incomingCalls,
    outgoingCalls,
    establishedCalls,
    terminatedCalls,
    currentTransfer,
    setCurrentTransfer,
    isMuted,
    setIsMuted,
    isPlaceCallLoading,
    setIsPlaceCallLoading,
  } satisfies SoftphoneCallStateContextValue;

  /**
   * Emit the call state changed event whenever the call state changes
   * Most effects should subscribe to individual events, rather than the full call state,
   * but sometimes the full call state is needed
   */
  useEffect(() => {
    const callState = {
      calls,
      mergedCallGroup,
      incomingCalls,
      outgoingCalls,
      establishedCalls,
      terminatedCalls,
      primaryCall,
    };

    emit('call-state.changed', callState);
  }, [calls, mergedCallGroup, incomingCalls, outgoingCalls, establishedCalls, terminatedCalls, primaryCall]);

  return (
    <SoftphoneCallStateContext.Provider value={value}>
      <SoftphoneCallActionsProvider>{children}</SoftphoneCallActionsProvider>
    </SoftphoneCallStateContext.Provider>
  );
};

export const useSoftphoneCallState = <T extends any>(selector: (value: SoftphoneCallStateContextValue) => T) => {
  return useContextSelector(SoftphoneCallStateContext, selector);
};
