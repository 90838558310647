import { useTranslation } from '@frontend/i18n';
import { HistoryQueries } from '@frontend/api-messaging';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Table, Text } from '@frontend/design-system';
import { ListRecordHistoriesRequest } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/history/v1/service.pb';
import { RecordTableControlsParams, useRecordTableControls } from '../../hooks/use-record-table-controls';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

type Props = {
  personId?: string;
};

export const RecordHistoryTable = ({ personId = '' }: Props) => {
  const { t } = useTranslation('messages');

  const [requestParams, setRequestParams] = useState<ListRecordHistoriesRequest>({
    pageSize: 10,
    personIds: [personId],
  });
  const [page, setPage] = useState<number>(1);
  const [pageParams, setPageParams] = useState<RecordTableControlsParams>({});

  const { data: recordsData = [], isLoading, isFetching } = HistoryQueries.useListRecordHistories(requestParams);

  const hasMore = requestParams?.pageSize ? !!recordsData?.[requestParams.pageSize - 1] : false;

  useEffect(() => {
    if (recordsData?.length) {
      const firstRecordId = recordsData[0]?.recordId;
      const firstRecordDate = recordsData[0]?.modifiedAt;
      const lastRecordId = recordsData[recordsData.length - 1]?.recordId;
      const lastRecordDate = recordsData[recordsData.length - 1]?.modifiedAt;
      setPageParams({
        firstRecordId,
        firstRecordDate,
        lastRecordId,
        lastRecordDate,
      });
    }
  }, [recordsData, requestParams]);

  const { handlePageForward, handlePageBackward } = useRecordTableControls({
    pageParams,
    page,
    requestParams,
    setPage,
    setRequestParams,
  });

  return (
    <>
      <Text as='h3' weight='bold' css={headingStyles}>
        {t('Activity Log')}
      </Text>
      <Table
        colConfig={[
          {
            Header: t('Record Id'),
            id: 'recordId',
            accessor: (data) => data.recordId,
            width: 100,
            disableSortBy: true,
          },
          {
            Header: t('Record Type'),
            id: 'recordType',
            accessor: (data) => data.recordType,
            width: 100,
            disableSortBy: true,
          },
          {
            Header: t('Modified At'),
            id: 'modifiedAt',
            accessor: (data) => dayjs(data?.modifiedAt).format('MMM D, YYYY h:mm A'),
            disableSortBy: true,
          },
          {
            Header: t('Created At'),
            id: 'createdAt',
            accessor: (data) => dayjs(data?.createdAt).format('MMM D, YYYY h:mm A'),
            disableSortBy: true,
          },
        ]}
        isPaginated
        data={recordsData}
        isLoading={isLoading || isFetching}
        manualPaginationConfig={{
          page,
          hasNext: hasMore,
          hasPrevious: hasMore,
          defaultRowsPerPage: requestParams.pageSize,
          onNumRowsChange: (numRows) => {
            setRequestParams({ ...requestParams, pageSize: numRows });
            setPage(1);
          },
          rowsPerPageOptions: [10, 25, 50, 100],
          handleChange: (prevOrNext: 'prev' | 'next') => {
            if (prevOrNext === 'prev') handlePageBackward();
            if (prevOrNext === 'next') handlePageForward();
          },
        }}
      />
    </>
  );
};

const headingStyles = css`
  font-size: ${theme.fontSize(24)};
  margin: ${theme.spacing(1.5)} 0;
`;
