import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { CustomizationFlagQueries, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { FreeTrialsAPI, FreeTrialsHooks, FreeTrialsTypes } from '@frontend/api-free-trials';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { SignUpPageFreeTrialSubscriptionCard } from '@frontend/product-subscription-sign-up-page';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text, useModalControl, useAlert } from '@frontend/design-system';
import { useShouldShowSubscribeCard } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { PracticeAnalyticsFreeTrialSuccessModal } from '.';

export const PracticeAnalyticsFreeTrialSubscribeCard = () => {
  const alert = useAlert();
  const freeTrialActivatedModalProps = useModalControl();
  const { t } = useTranslation('analytics');
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const canManageAddOns = hasSchemaACL(selectedLocationIdsWithParents[0], Permission.MANAGE_ADD_ON_SUBSCRIPTIONS);
  const { setFlags: setCustomizationFlags, flags: customizationFlags } = useCustomizationFlagShallowStore(
    'setFlags',
    'flags'
  );
  const { resetQueries } = FreeTrialsHooks.useResetIsFreeTrialEligibleQuery();

  const shouldShowFreeTrialSubscriptionCard = useShouldShowSubscribeCard({
    flagDetails: customizationFlags?.practiceanalytics,
    verify: 'showFreeTrialSubscriptionCard',
  });

  const { mutateAsync: signUpForFreeTrial, isLoading } = FreeTrialsAPI.useFreeTrialSignUp();

  const { isLoading: isCustomizationFlagLoading, refetch: refetchCustomizationFlags } =
    CustomizationFlagQueries.useCustomizationFlagsQuery({
      onSuccess: setCustomizationFlags,
      enabled: false,
    });

  const { ONLINE_SCHEDULING_FREE_TRIALS_FEATURE_FLAG, PRACTICE_ANALYTICS_FREE_TRIALS_FEATURE_FLAG } =
    FreeTrialsTypes.FreeTrialFeatureFlagsEnum;

  const { refetch: refetchGetTrialStageBanner, isFetching: isGetTrialStageBannerFetching } =
    FreeTrialsAPI.useGetTrialStageBanner({
      enabled: false,
      enabledFeatureFlags: [ONLINE_SCHEDULING_FREE_TRIALS_FEATURE_FLAG, PRACTICE_ANALYTICS_FREE_TRIALS_FEATURE_FLAG],
    });

  const refetchCustomizationFlagAndBannerDetails = () => {
    Promise.all([refetchGetTrialStageBanner(), refetchCustomizationFlags()])
      .then(() => {
        alert.success(t('Your Free Trial is activated'));
        freeTrialActivatedModalProps.openModal();
        resetQueries([FreeTrialsTypes.Feature.ONLINE_SCHEDULING, FreeTrialsTypes.Feature.PRACTICE_ANALYTICS]);
      })
      .catch((err) => {
        alert.error(t('Something went wrong while setting up the free trial'));
        console.error(err);
      });
  };

  const handleActivateTrial = () => {
    signUpForFreeTrial({
      featureEnum: FreeTrialsTypes.Feature.PRACTICE_ANALYTICS,
      locationId: selectedLocationIdsWithParents[0],
    })
      .then((res) => {
        if (res) refetchCustomizationFlagAndBannerDetails();
      })
      .catch((err) => {
        alert.error(t('Something went wrong while activating free trial'));
        console.error(err);
      });
  };

  return (
    <>
      {shouldShowFreeTrialSubscriptionCard && (
        <SignUpPageFreeTrialSubscriptionCard
          activateFreeTrialButtonTrackingId={trackingIds.subscriptions.freeTrialPA}
          activateTrialButtonLabel={t('Activate Free Trial')}
          css={{ marginTop: theme.spacing(3) }}
          isActivateTrialBtnDisabled={!canManageAddOns}
          isLoading={isLoading || isCustomizationFlagLoading || isGetTrialStageBannerFetching}
          onActivateTrialClick={handleActivateTrial}
          renderFreeTrialCardContent={
            <Text>{t('Activate your 30-day Free Trial today. No commitment. No credit card required.')}</Text>
          }
          title={t('Start Your Free Trial')}
        />
      )}
      <PracticeAnalyticsFreeTrialSuccessModal {...freeTrialActivatedModalProps} />
    </>
  );
};
