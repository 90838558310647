import { FC } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { ModalControlResponse, useModalControl } from '@frontend/design-system';
import { CallIntelSelfSubscribeModal } from '../components/feature-upgrade-details-modal/call-intel/CallIntelSelfSubscribeModal';
import { FormsSelfSubscribeModal } from '../components/feature-upgrade-details-modal/forms/FormsSelfSubscribeModal';
import { InsuranceVerificationSelfSubscribeModal } from '../components/feature-upgrade-details-modal/insurance-verification/InsuranceVerificationSelfSubscribeModal';
import { FeatureSelfSubscribeModalProps } from '../types';

interface UseFeatureSelfSubscribeModalResults {
  FeatureSelfSubscribeModal?: FC<FeatureSelfSubscribeModalProps>;
  featureSelfSubscribeModalControls: ModalControlResponse;
}

const FeatureSelfSubscribeModalMapping: Partial<Record<Feature, FC<FeatureSelfSubscribeModalProps>>> = {
  [Feature.FORMS]: FormsSelfSubscribeModal,
  [Feature.WEAVE_VERIFY]: InsuranceVerificationSelfSubscribeModal,
  [Feature.CALL_INTELLIGENCE]: CallIntelSelfSubscribeModal,
};

export const useFeatureSelfSubscribeModal = ({
  featureEnum,
}: {
  featureEnum: Feature;
}): UseFeatureSelfSubscribeModalResults => {
  const modalControls = useModalControl();
  return {
    FeatureSelfSubscribeModal: FeatureSelfSubscribeModalMapping[featureEnum],
    featureSelfSubscribeModalControls: modalControls,
  };
};
