import { useEffect } from 'react';
import { ScheduleTypes } from '@frontend/api-schedule';
import { ActionsUI } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { PopoverMenuItem, Text } from '@frontend/design-system';
import { useScheduleAlertsContext } from '../../../../context/ScheduleAlertsContext';
import { scheduleAlertsTrackingIds } from './schedule-actions-tracking-ids';
import { useScheduleActionsContext } from './ScheduleActionsContext';

interface AlertMenuProps {
  alert: ScheduleTypes.ScheduleAlertType;
  locationId: string;
  closeAlertPopoverMenu: () => void;
}
export const ScheduleAlertActionMenu = ({ alert, locationId, closeAlertPopoverMenu }: AlertMenuProps) => {
  const { t } = useTranslation('scheduleCalendarActions');
  const { clearAlert, setSelectedLocationId } = useScheduleAlertsContext();
  const { closeScheduleActionsModal } = useScheduleActionsContext();

  useEffect(() => {
    setSelectedLocationId(locationId);
  }, [locationId]);

  const {
    Modal: PhoneCallModal,
    triggerProps: callTriggerProps,
    disabled: disableCallAction,
  } = ActionsUI.actions.usePhoneCallAction({
    context: {
      personId: alert?.patient.person_id,
    },
  });

  const {
    disabled: disableMessageAction,
    Modal: MessageModal,
    triggerProps: messageTriggerProps,
  } = ActionsUI.actions.useMessageAction({
    context: {
      personId: alert?.patient.person_id ?? '',
      locationId: locationId ?? '',
    },
  });

  const handleClearAlert = (alertId: string) => {
    clearAlert(alertId, locationId);
    closeAlertPopoverMenu();
  };

  const handleOpenMessageModal = () => {
    closeScheduleActionsModal();
    messageTriggerProps?.onClick();
  };

  const fullName = `${alert?.patient.preferred_name ? alert?.patient.preferred_name : alert?.patient.first_name} ${
    alert?.patient.last_name
  }`;

  return (
    <>
      <PopoverMenuItem
        onClick={() => handleOpenMessageModal()}
        disabled={disableMessageAction}
        trackingId={scheduleAlertsTrackingIds.sendMessage}
      >
        <Icon name='message' />
        <Text>{t('Send Message')}</Text>
      </PopoverMenuItem>
      <PopoverMenuItem
        {...callTriggerProps}
        disabled={disableCallAction}
        trackingId={scheduleAlertsTrackingIds.callPerson}
      >
        <Icon name='phone' />
        <Text>{t('Call {{fullName}}', { fullName })}</Text>
      </PopoverMenuItem>
      <PopoverMenuItem disabled trackingId={scheduleAlertsTrackingIds.viewAppointment}>
        <Icon name='view' />
        <Text>{t('View Appointment')}</Text>
      </PopoverMenuItem>
      <PopoverMenuItem disabled trackingId={scheduleAlertsTrackingIds.confirmAppointment}>
        <Icon name='calendar-confirmed' />
        <Text>{t('Confirm Appointment')}</Text>
      </PopoverMenuItem>
      <PopoverMenuItem onClick={() => handleClearAlert(alert.id)} trackingId={scheduleAlertsTrackingIds.clearAlert}>
        <Icon name='trash' color='error' />
        <Text color='error' weight='bold'>
          {t('Clear Alert')}
        </Text>
      </PopoverMenuItem>
      {PhoneCallModal}
      {MessageModal}
    </>
  );
};
