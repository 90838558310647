import { CalendarProvider } from '../../provider';
import type { UseCalendarReturn } from '../../provider';
import { SingleMonthDisplay } from './single-month-display';
import { css } from '@emotion/react';

type Props = {
  btnDiameter?: number;
};

export const SingleMonthCalendarLayout = ({ btnDiameter, ...rest }: Omit<UseCalendarReturn, 'children'> & Props) => (
  <CalendarProvider {...rest} css={css({ outline: 'none' })}>
    <SingleMonthDisplay btnDiameter={btnDiameter} />
  </CalendarProvider>
);
