import { BackIcon, IconButton, Modal } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { ReactNode } from 'react';
import { DeviceLayout } from './layout';
import { LineKey, ListDevice, ModelInfo } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';

interface Props {
  onClose: () => void;
  device?: ListDevice;
  duplicate?: boolean;
  children?: ReactNode;
  lineKeys: LineKey[] | undefined;
  model: ModelInfo | undefined;
  onBack: () => void;
}
export const PreviewLayoutModal = ({ duplicate, model, lineKeys, device, onClose, onBack, children }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });

  return (
    <>
      <Modal.Header onClose={onClose}>
        <IconButton
          onClick={onBack}
          label={duplicate ? t('Back to Device Selection') : t('Back to Devices')}
          showLabelAlways
        >
          <BackIcon />
        </IconButton>
      </Modal.Header>
      <Modal.Body>
        <DeviceLayout model={model} lineKeys={lineKeys} previewOnly device={device} />
      </Modal.Body>
      {children}
    </>
  );
};
