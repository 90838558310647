import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Tabs, NotificationBadge, SpinningLoader, styles } from '@frontend/design-system';
import {
  OnlineSchedulingMultiLocationCustomizationFlagContext,
  OnlineSchedulingMultiLocationCustomizationFlagContextType,
} from '../../../../context/OnlineSchedulingMultiLocationCustomizationFlagContext';
import { useScheduleActionsContext } from './ScheduleActionsContext';
import { ScheduleAlerts } from './ScheduleAlerts';
import { ScheduleMassMessages } from './ScheduleMassMessages/ScheduleMassMessages';
import { ScheduleRequestTabContent } from './ScheduleRequests/ScheduleRequestTabContent';
import { ScheduleRequestTabHeader } from './ScheduleRequests/ScheduleRequestTabHeader';

export const ScheduleActionsTabMap = {
  SCHEDULE_APPOINTMENT_REQUESTS: 'schedule-appointment-requests-tab',
  SCHEDULE_ALERTS: 'schedule-alerts-tab',
  SCHEDULE_APPOINTMENT_LIST: 'schedule-appointments-list-tab',
};

export const ScheduleActionsTabContent = () => {
  const { scheduleAlertsTotalCount } = useScheduleActionsContext();

  const { selectedLocationIds } = useAppScopeStore();
  const { t } = useTranslation('scheduleCalendarActions');
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const scheduleAlertsEnabled = flagValues['schedule-alerts-2.0'];
  const scheduleMassMessageEnabled = flagValues['schedule-mass-message-2.0'];

  const {
    isLoading: isLoadingCustomizationFlags,
    isFeatureHiddenInAllLocations,
    isFeatureActiveInAllLocations,
    locationIdWiseCustomizationFlagDetails,
  } = CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
    locationIds: selectedLocationIds,
    enabled: true,
    customizationFlag: Feature.ONLINE_SCHEDULING,
  });

  const providerValue = useMemo<OnlineSchedulingMultiLocationCustomizationFlagContextType>(
    () => ({
      isFeatureHiddenInAllLocations,
      isFeatureActiveInAllLocations,
      locationIdWiseCustomizationFlagDetails,
    }),
    [isFeatureHiddenInAllLocations, isFeatureActiveInAllLocations, locationIdWiseCustomizationFlagDetails]
  );

  if (isLoadingCustomizationFlags)
    return (
      <div css={styles.flexCenter}>
        <SpinningLoader />
      </div>
    );

  return (
    <OnlineSchedulingMultiLocationCustomizationFlagContext.Provider value={providerValue}>
      <Tabs initialTab={ScheduleActionsTabMap.SCHEDULE_APPOINTMENT_LIST}>
        <Tabs.Bar css={{ padding: theme.spacing(0, 3) }}>
          <Tabs.Tab
            trackingId={`${ScheduleActionsTabMap.SCHEDULE_APPOINTMENT_LIST}-panel`}
            id={ScheduleActionsTabMap.SCHEDULE_APPOINTMENT_LIST}
            controls={`${ScheduleActionsTabMap.SCHEDULE_APPOINTMENT_LIST}-panel`}
          >
            {t('Appointment List')}
          </Tabs.Tab>
          {!isFeatureHiddenInAllLocations && (
            <Tabs.Tab
              trackingId={`${ScheduleActionsTabMap.SCHEDULE_APPOINTMENT_REQUESTS}-panel`}
              id={ScheduleActionsTabMap.SCHEDULE_APPOINTMENT_REQUESTS}
              controls={`${ScheduleActionsTabMap.SCHEDULE_APPOINTMENT_REQUESTS}-panel`}
            >
              <ScheduleRequestTabHeader />
            </Tabs.Tab>
          )}
          <Tabs.Tab
            disabled={!scheduleAlertsEnabled}
            trackingId={`${ScheduleActionsTabMap.SCHEDULE_ALERTS}-panel`}
            id={ScheduleActionsTabMap.SCHEDULE_ALERTS}
            controls={`${ScheduleActionsTabMap.SCHEDULE_ALERTS}-panel`}
          >
            <span
              css={[
                tabStyles,
                css`
                  gap: ${theme.spacing(1)};
                `,
              ]}
            >
              {t('Alerts')}
              {!!scheduleAlertsTotalCount && <NotificationBadge>{scheduleAlertsTotalCount}</NotificationBadge>}
            </span>
          </Tabs.Tab>
        </Tabs.Bar>
        <Tabs.Panel
          css={tabListPanelContainerStyles}
          id={`${ScheduleActionsTabMap.SCHEDULE_APPOINTMENT_LIST}-panel`}
          controller={ScheduleActionsTabMap.SCHEDULE_APPOINTMENT_LIST}
        >
          {scheduleMassMessageEnabled ? <ScheduleMassMessages /> : t('Coming Soon...')}
        </Tabs.Panel>
        <Tabs.Panel
          css={tabListPanelContainerStyles}
          id={`${ScheduleActionsTabMap.SCHEDULE_APPOINTMENT_REQUESTS}-panel`}
          controller={ScheduleActionsTabMap.SCHEDULE_APPOINTMENT_REQUESTS}
        >
          <ScheduleRequestTabContent />
        </Tabs.Panel>
        {scheduleAlertsEnabled && (
          <Tabs.Panel
            css={tabListPanelContainerStyles}
            id={`${ScheduleActionsTabMap.SCHEDULE_ALERTS}-panel`}
            controller={ScheduleActionsTabMap.SCHEDULE_ALERTS}
          >
            {scheduleAlertsEnabled ? <ScheduleAlerts /> : t('Coming Soon...')}
          </Tabs.Panel>
        )}
      </Tabs>
    </OnlineSchedulingMultiLocationCustomizationFlagContext.Provider>
  );
};

const tabStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const tabListPanelContainerStyles = css({
  padding: theme.spacing(3),
  backgroundColor: theme.colors.neutral5,
  height: '100%',
  overflowY: 'auto',
});
