import { Dispatch, SetStateAction } from 'react';
import { DataSourcesTypes } from '@frontend/api-data-sources';
import { PersonTypes } from '@frontend/api-person';
import { UsePopoverDialogResponse } from '@frontend/design-system';

export enum PatientSelectorScreenEnum {
  PERSON_SEARCH = 'PERSON_SEARCH',
  PERSON_NOTES = 'PERSON_NOTES',
  CUSTOM_CONTACT = 'CUSTOM_CONTACT',
}

export type PersonLastNameLetterMap = {
  [letter: string]: string[];
};

export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

export type FieldName =
  | 'firstName'
  | 'lastName'
  | 'dateOfBirth'
  | 'gender'
  | 'email'
  | 'source'
  | 'phoneNumber'
  | 'notes'
  | 'clientLocationId';

type Fields<T extends string> = AtLeastOne<{
  [key in T]: {
    value: string;
    required?: boolean;
  };
}>;

export type FormFields = Fields<FieldName>;

export type CustomContactFormFieldName =
  | 'firstName'
  | 'lastName'
  | 'dateOfBirth'
  | 'gender'
  | 'email'
  | 'phoneNumber'
  | 'notes';

export type CustomContactFormFields = Fields<CustomContactFormFieldName>;

export type PersonSelectorFormValues = AtLeastOne<{
  [key in FieldName]: string;
}> & { personId?: string };

export type AddToListHandlerParamsType = Pick<PersonSelectorFormValues, 'personId' | 'notes'>;

export type PersonSelectorPropsType = {
  setSelectedPerson: Dispatch<SetStateAction<PersonTypes.Person | null>>;
  setNotes: Dispatch<SetStateAction<string>>;
  formFields: FormFields;
  dataSources: DataSourcesTypes.DataSource[] | undefined;
  personSelectorScreen: PatientSelectorScreenEnum;
  setPersonSelectorScreen: Dispatch<SetStateAction<PatientSelectorScreenEnum>>;
  clientLocationIds?: DataSourcesTypes.AccessDataResponse['ClientLocations'];
};

export type UsePersonSelectorReturnType = {
  selectedPerson: PersonTypes.Person | null;
  notes: string;
  personSelectorDialogProps: UsePopoverDialogResponse;
  personSelectorProps: PersonSelectorPropsType;
};
