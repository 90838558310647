import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, NakedButton, PopoverMenu, PrimaryButton, usePopoverMenu } from '@frontend/design-system';
import { defaultDateRangeMap, getMatchingDateRangeKey } from '../../utils';
import { DatesRangeSelector } from './dates-range-selector';
import { DatesMap, TimePeriodChange } from './time-period-selector';

type CommonProps<T> = {
  defaultPeriod?: string;
  disabled?: boolean;
  endDate?: string;
  onChange: TimePeriodChange<T>;
  showDateRangeInput?: boolean;
  startDate?: string;
  trackingId?: string;
};

type Props<T> = CommonProps<T> &
  (
    | {
        customDatesMap: DatesMap;
        customTimePeriods: Record<string, string>;
        limitDefaultTimePeriods?: never;
      }
    | {
        customDatesMap?: never;
        customTimePeriods?: never;
        limitDefaultTimePeriods?: (keyof typeof defaultDateRangeMap)[];
      }
  );

type Any = any; // Just getting the linter off my back

export const TimePeriodPopoverSelector = <T extends Any>({
  customDatesMap: datesMap = defaultDateRangeMap,
  customTimePeriods,
  defaultPeriod = 'last-14-days',
  disabled,
  endDate,
  limitDefaultTimePeriods,
  onChange,
  showDateRangeInput = true,
  startDate,
  trackingId,
}: Props<T>) => {
  const { t } = useTranslation('analytics');
  const [customDateRange, setCustomDateRange] = useState<{ startDate?: string; endDate?: string }>({
    startDate,
    endDate,
  });
  const { close, getMenuProps, getTriggerProps, open } = usePopoverMenu({
    placement: 'bottom',
  });
  const { ref: filterTriggerRef, ...triggerProps } = getTriggerProps();
  const activeKey = getMatchingDateRangeKey({ datesMap, endDate, startDate });

  const defaultTimePeriods = {
    today: t('Today'),
    yesterday: t('Yesterday'),
    'last-7-days': t('Last 7 Days'),
    'last-14-days': t('Last 14 Days'),
    'last-30-days': t('Last 30 Days'),
    'this-week': t('This Week'),
    'this-month': t('This Month'),
    'this-quarter': t('This Quarter'),
    'last-week': t('Last Week'),
    'last-month': t('Last Month'),
    'last-quarter': t('Last Quarter'),
  };

  const handleChange = (selectedPeriod: string) => {
    if (selectedPeriod && datesMap[selectedPeriod]) {
      const { startDate, endDate } = datesMap[selectedPeriod];
      onChange?.({
        endDate,
        startDate,
      });
    }
    close();
  };

  const handleApply = () => {
    const { startDate = '', endDate = '' } = customDateRange;
    onChange?.({
      endDate,
      startDate,
    });
    close();
  };

  return (
    <div ref={filterTriggerRef}>
      <Chip.DropdownFilter
        {...triggerProps}
        css={{ gap: '10px', maxWidth: 150, width: '100%' }}
        filterIsActive={activeKey !== defaultPeriod}
        leftElement={<Icon name='clock-small' color={activeKey !== defaultPeriod ? 'white' : 'default'} />}
        onClick={open}
        trackingId={trackingId}
      >
        {t('Time Period')}
      </Chip.DropdownFilter>

      <PopoverMenu {...getMenuProps()}>
        {Object.entries(customTimePeriods || defaultTimePeriods).map(([key, value]) => {
          if (
            limitDefaultTimePeriods?.length &&
            !limitDefaultTimePeriods.includes(key as keyof typeof defaultDateRangeMap)
          ) {
            return null;
          }

          return (
            <NakedButton
              disabled={disabled}
              className={key === activeKey ? 'active' : ''}
              css={styles.listItem}
              key={key}
              onClick={() => handleChange(key)}
              trackingId={`${trackingId}-${key}`}
            >
              {key === activeKey && (
                <span style={{ color: theme.colors.text.interactive }}>
                  <Icon name='check' />
                </span>
              )}
              {value}
            </NakedButton>
          );
        })}
        {showDateRangeInput && (
          <>
            <hr css={styles.hr} />
            <div css={styles.dateRangeWrapper}>
              <DatesRangeSelector
                disabled={disabled}
                endDate={endDate}
                onChange={setCustomDateRange}
                startDate={startDate}
              />
              <PrimaryButton onClick={handleApply} trackingId={`${trackingId}-apply-button`}>
                {t('Apply')}
              </PrimaryButton>
            </div>
          </>
        )}
      </PopoverMenu>
    </div>
  );
};

const styles = {
  listItem: css`
    cursor: pointer;
    height: ${theme.spacing(5)};
    padding: ${theme.spacing(0, 3, 0, 6)};
    text-align: left;
    transition: background-color 0.3s ease;

    > span {
      left: ${theme.spacing(2)};
      position: absolute;
    }

    &.active,
    &:hover {
      background-color: ${theme.colors.neutral5};
    }
  `,

  hr: css`
    border: none;
    border-top: 1px solid ${theme.colors.neutral10};
    margin: ${theme.spacing(1, 0, 0)};
  `,

  dateRangeWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    max-width: 352px;
    padding: ${theme.spacing(2)};
  `,
};
