import dayjs, { Dayjs } from 'dayjs';
import { genUUIDV4 } from '@frontend/string';

type Name = {
  firstName: string;
  lastName: string;
};

const firstNames = [
  'John',
  'Jane',
  'Mary',
  'James',
  'Emma',
  'Robert',
  'Linda',
  'Michael',
  'Patricia',
  'David',
  'Alice',
  'Bob',
  'Charlie',
  'Diana',
  'Eve',
  'Frank',
  'Grace',
  'Helen',
  'Ivan',
  'Julia',
];

const lastNames = [
  'Smith',
  'Johnson',
  'Williams',
  'Brown',
  'Jones',
  'Miller',
  'Davis',
  'Garcia',
  'Rodriguez',
  'Wilson',
  'Anderson',
  'Thomas',
  'Taylor',
  'Moore',
  'Jackson',
  'Martin',
  'Lee',
  'Perez',
  'Thompson',
  'White',
];

export const demoDataUtils = {
  dummyLocationId: '00000000-0000-0000-0000-000000000000',

  generateConsecutiveDates: (startDate: Date, count: number): Dayjs[] => {
    const dates = [];

    for (let i = 0; i < count; i++) {
      dates.push(dayjs(startDate).add(i, 'day'));
    }

    return dates;
  },

  generateRandomDate: (isFutureDate?: boolean): string => {
    const date = dayjs().add(Math.floor(Math.random() * 365), 'day');
    return isFutureDate ? date.format('YYYY-MM-DD') : date.subtract(365, 'day').format('YYYY-MM-DD');
  },

  generateRandomUserNames: (count: number): Name[] => {
    const names: Name[] = [];

    for (let i = 0; i < count; i++) {
      const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
      const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
      names.push({ firstName, lastName });
    }

    return names;
  },

  generateRandomUUID: (): string => genUUIDV4(),

  generateRandomNumberString: (length: number): string => {
    let result = '';

    for (let i = 0; i < length; i++) {
      result += Math.floor(Math.random() * 10);
    }

    return result;
  },
};
