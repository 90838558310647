import { FC } from 'react';
import { tokenStyles } from './token.styles';

interface TokenProps {
  name: string;
  active?: boolean;
  after?: string;
  disabled?: boolean;
  trackingId?: string;
  onClick?: () => void;
}

export const Token: FC<TokenProps> = ({ name, active, after, disabled, trackingId, onClick }) => {
  return (
    <button
      type='button'
      disabled={!!disabled}
      css={tokenStyles({ active, after })}
      onClick={onClick}
      {...(trackingId && { 'data-trackingid': trackingId })}
    >
      {name}
    </button>
  );
};
