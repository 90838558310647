import { useMemo } from 'react';
import { css } from '@emotion/react';
import {
  ClockIcon,
  PrimaryButton,
  SearchableListbox,
  SecondaryButton,
  Text,
  Heading,
  to12HrString,
  useListboxState,
} from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';
import { ScheduleTypes } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';

import { DepartmentsUtils } from '@frontend/api-departments';
import { InstructionsTypes } from '@frontend/api-phone-tree';

type IBaseEditBreakSchedules = {
  schedules: ScheduleTypes.ScheduleWithRouting[] | undefined;
};

type TimePickerHours =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;

const DayMap = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] as const;
const lightGray = originalTheme.colors.gray500;

const getRuleDisplay = ({ dayOfWeek, startTime, endTime }: ScheduleTypes.Rule) => {
  const scheduleName = DayMap[dayOfWeek];
  const start = {
    hours: Number(startTime.slice(0, 2)) as TimePickerHours,
    minutes: Number(startTime.slice(3, 5)),
  };
  const end = {
    hours: Number(endTime.slice(0, 2)) as TimePickerHours,
    minutes: Number(endTime.slice(3, 5)),
  };

  return {
    name: scheduleName,
    display: `${scheduleName} ${to12HrString(start)} - ${to12HrString(end)}`,
  };
};

const getInstructionDisplay = (instructions: InstructionsTypes.AnyInstruction[]) => {
  return instructions.map((inst, index) => {
    const type = inst.type as InstructionsTypes.Instruction;
    return index !== instructions.length - 1
      ? DepartmentsUtils.InstructionTypeMap[type] + ' > '
      : DepartmentsUtils.InstructionTypeMap[type];
  });
};

const useBreakSchedules = ({ schedules }: { schedules: ScheduleTypes.ScheduleWithRouting[] | undefined }) => {
  return useMemo(() => {
    return (schedules || [])?.filter((s: { type: string }) => s.type === ScheduleTypes.ScheduleType.Break);
  }, [schedules]);
};

/// ~~~~~~~~~~~~~~~~~~~~ PORTAL/DEPARTMENTS ~~~~~~~~~~~~~~~~~~~~
type IEditBreakSchedulesModal = {
  listBoxProps: ReturnType<typeof useListboxState>;
  handleNext: () => void;
  handleCancel: () => void;
} & IBaseEditBreakSchedules;

export const OfficeHoursViewBreaks = ({
  listBoxProps,
  schedules,
  handleNext,
  handleCancel,
}: IEditBreakSchedulesModal) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  const breakSchedules = useBreakSchedules({ schedules: schedules });

  return (
    <div>
      <SearchableListbox
        {...listBoxProps}
        css={css`
          margin-top: ${theme.spacing(2.5)};
        `}
      >
        {(breakSchedules ?? [])
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item, index) => {
            return (
              <SearchableListbox.Option
                key={index}
                value={item.name}
                css={css`
                  border-bottom: 0.5px solid ${theme.colors.neutral20};
                  padding: ${theme.spacing(2)};
                  cursor: pointer;
                `}
              >
                {item.name}
                <Text
                  css={css`
                    margin: 0;
                    font-size: 14px;
                    color: ${lightGray};
                  `}
                >
                  {getInstructionDisplay(item.callRoute?.instructions)}
                </Text>
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <ClockIcon
                    size={16}
                    css={css`
                      margin-right: ${theme.spacing(0.5)};
                      color: ${lightGray};
                    `}
                  />
                  <div>
                    {item.rules.length ? (
                      item.rules
                        .sort((a, b) => a.dayOfWeek - b.dayOfWeek)
                        .map((schedule) => {
                          const { display, name } = getRuleDisplay(schedule);
                          return (
                            <Text
                              key={name}
                              css={css`
                                font-size: ${theme.fontSize(14)};
                                color: ${lightGray};
                                line-height: ${theme.spacing(2)};
                              `}
                            >
                              {display}
                            </Text>
                          );
                        })
                    ) : (
                      <Text
                        css={css`
                          line-height: ${theme.spacing(2)};
                          font-size: ${theme.fontSize(14)};
                          color: ${lightGray};
                          font-style: italic;
                        `}
                      >
                        No times
                      </Text>
                    )}
                  </div>
                </div>
              </SearchableListbox.Option>
            );
          })}
      </SearchableListbox>
      <div style={{ width: '100px', margin: theme.spacing(3, 0, 0, 46), display: 'flex', gap: theme.spacing(1) }}>
        <SecondaryButton onClick={handleCancel}>{t('Cancel')}</SecondaryButton>
        <PrimaryButton
          disabled={!listBoxProps.value}
          onClick={handleNext as () => void}
          trackingId='phone-portal-department-editBreaks-next-btn'
        >
          {t('Next')}
        </PrimaryButton>
      </div>
    </div>
  );
};

/// ~~~~~~~~~~~~~~~~~~~~ APP/SETTINGS/PHONE ~~~~~~~~~~~~~~~~~~~~
type IOfficeHoursSettingsViewBreaks = {
  selectSchedule: (scheduleIdOrName: string) => void;
  onCancelClick: () => void;
} & IBaseEditBreakSchedules;

export const OfficeHoursSettingsViewBreaks = ({
  schedules,
  selectSchedule,
  onCancelClick,
}: IOfficeHoursSettingsViewBreaks) => {
  const { t } = useTranslation('phone');
  const breakSchedules = useBreakSchedules({ schedules });

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      `}
    >
      <div>
        {breakSchedules.map((schedule) => {
          const sortedRules = schedule.rules.sort((a, b) => a.dayOfWeek - b.dayOfWeek);

          return (
            <div
              css={css`
                background-color: ${theme.colors.white};
                padding: ${theme.spacing(3)};
                border: 1px solid ${theme.colors.neutral10};

                &:first-of-type {
                  border-top-left-radius: ${theme.borderRadius.medium};
                  border-top-right-radius: ${theme.borderRadius.medium};
                }
                &:last-of-type {
                  border-bottom-left-radius: ${theme.borderRadius.medium};
                  border-bottom-right-radius: ${theme.borderRadius.medium};
                }
                &:not(:first-of-type) {
                  border-top: none;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  margin-top: ${theme.spacing(0.5)};
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <Heading
                  level={3}
                  css={css`
                    white-space: nowrap;
                  `}
                >
                  {schedule.name}
                </Heading>
                <SecondaryButton
                  css={css`
                    max-width: max-content;
                    font-weight: 700;
                  `}
                  onClick={() => {
                    selectSchedule(schedule.scheduleId);
                  }}
                  data-testid={`edit-schedule-${schedule.name}`}
                >
                  {t('Edit')}
                </SecondaryButton>
              </div>
              <div
                css={css`
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  margin-top: ${theme.spacing(3)};
                  grid-gap: ${theme.spacing(2)};
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <Text size='small' color='light'>
                    {t('Break Hours')}
                  </Text>
                  {sortedRules.length ? (
                    sortedRules.map((rule) => {
                      const { name, display } = getRuleDisplay(rule);
                      return <Text key={name}>{t('{{scheduleRule}}', { scheduleRule: display })}</Text>;
                    })
                  ) : (
                    <Text>{t('No times')}</Text>
                  )}
                </div>
                {schedule.callRoute.instructions?.length ? (
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    <Text size='small' color='light'>
                      {t('Routing')}
                    </Text>
                    <Text>
                      {t('{{callRouteInstructions}}', {
                        callRouteInstructions: getInstructionDisplay(schedule.callRoute.instructions),
                      })}
                    </Text>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      <div
        css={css`
          align-self: flex-end;
        `}
      >
        <SecondaryButton size='large' onClick={onCancelClick}>
          Cancel
        </SecondaryButton>
      </div>
    </div>
  );
};
