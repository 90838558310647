import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconColorType } from '@frontend/icons';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Dot, IconButton, IconButtonProps, usePopoverMenu } from '@frontend/design-system';
import { useInboxFiltersShallowStore } from '../../stores';
import { InboxFilter } from '../../types';
import { InboxFiltersPopover } from './filters-popover';

type InboxFiltersButtonProps = {
  iconButtonProps?: Partial<IconButtonProps>;
  iconSize?: number;
  iconColor?: IconColorType;
  hideFilters?: InboxFilter[];
  groupIds?: string[];
};

export const InboxFiltersButton = ({
  iconButtonProps,
  iconSize,
  iconColor,
  hideFilters = [],
  groupIds,
}: InboxFiltersButtonProps) => {
  const { t } = useTranslation('inbox');
  const { inboxFilters } = useInboxFiltersShallowStore('inboxFilters');
  const { getTriggerProps, getMenuProps, getItemProps, activeIndex, close } = usePopoverMenu<
    HTMLButtonElement | HTMLAnchorElement
  >({ placement: 'bottom-end' });

  const hasFilters = useMemo(() => {
    const filterKeys = Object.keys(inboxFilters) as (keyof typeof inboxFilters)[];
    const filteredKeys = filterKeys.filter((key) => !hideFilters.includes(key) && key !== 'statuses');
    return filteredKeys.some((key) => {
      const value = inboxFilters[key];
      return value.length > 0;
    });
  }, [inboxFilters.tags.length, inboxFilters.departments.length, JSON.stringify(hideFilters)]);

  return (
    <>
      <IconButton
        showLabelOnHover
        label={t('Filter messages')}
        trackingId={`${InboxPrefixes.List}-filter-button`}
        css={{
          position: 'relative',
          padding: theme.spacing(0.75),
        }}
        {...iconButtonProps}
        {...getTriggerProps()}
      >
        <Icon name='filter-alt' color={iconColor} size={iconSize} />
        {hasFilters && (
          <Dot
            color='primary'
            css={{
              width: theme.spacing(1.5),
              height: theme.spacing(1.5),
              top: 2,
              right: 0,
              border: `1.5px solid ${theme.colors.white}`,
            }}
          />
        )}
      </IconButton>
      <InboxFiltersPopover
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        activeIndex={activeIndex}
        close={close}
        groupIds={groupIds}
        hideFilters={hideFilters}
      />
    </>
  );
};
