import { ModalControlModalProps } from '@frontend/design-system';

export type HistoryRow = {
  amount: number;
  date: string;
  refundId?: string;
  type: 'Payment' | 'Failed Payment' | InvoiceRefundStatus.PartialRefund | InvoiceRefundStatus.Refunded | 'Requested';
  time: string;
  user: string;
};

// This status is computed on the front end
export enum InvoiceRefundStatus {
  Refunded = 'Refunded',
  PartialRefund = 'Partial Refund',
}

export enum InvoiceDetailsFromPage {
  Invoices = 'invoices',
  Refunds = 'refunds',
}

export type InvoiceDetailsBackPageRoutes = {
  [key in InvoiceDetailsFromPage]: {
    route: string;
    label: string;
  };
};

export type InvoiceFilterMenuProps = {
  modalProps: ModalControlModalProps;
  setShowNotification: (value: boolean) => void;
};

export type DateRangePeriod = 'day' | 'week' | 'month' | 'year' | 'custom' | 'all';

export type QuickFillConfigOption = {
  label: string;
  period: DateRangePeriod;
  startShift?: number;
  endShift?: number;
  value?: string;
};

export type OptionsConfig = QuickFillConfigOption | 'custom';
export type RangeConfigType = Array<{
  label: string;
  options: OptionsConfig[];
}>;
