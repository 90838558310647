import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useInvalidateUnreadCount } from './use-invalidate-unread-count';
import { queryKeys } from '../inbox-queries';

export const useInvalidateInboxList = () => {
  const queryClient = useQueryClient();
  const invalidateUnreadCount = useInvalidateUnreadCount();
  return useCallback(() => {
    const keys = queryKeys.inboxListBase;
    queryClient.invalidateQueries({ queryKey: keys, exact: false });
    invalidateUnreadCount();
  }, [invalidateUnreadCount]);
};
