import { css } from '@emotion/react';
import { useMutation } from 'react-query';
import { createStripeAccount, getStripeOnboardingLink } from '@frontend/api-payments';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  PrimaryButton,
  Text,
  TextLink,
  useFormField,
  useModalControl,
  GiftIcon,
  GraduationCapIcon,
  Modal,
  BlockIcon,
  RadioField,
  useAlert,
} from '@frontend/design-system';
import { PageTitleWithLocationChip } from '../Shared';
import { CreationCheckModal } from './creation-check-modal';
import { TermsAndConditionsModal } from './legal-modal';

const UPSELL_LINK = 'https://www.weavehelp.com/hc/en-us/categories/360006216791-Payments';
const FREE_TERMINAL_LINK = 'https://try.getweave.com/payments/';

const styles = {
  promoCard: css`
    display: flex;
    flex-flow: row;
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
  `,
  blockIcon: css`
    display: inline;
  `,
  roleRequiredModalHeader: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
};

interface PaymentsSetupProps {
  isPaymentsBillingManager: boolean;
  locationId: string;
  paymentsUrl: string;
  stripeCreatedNotOnboarded: boolean;
  stripeId: string | undefined;
}

export const PaymentsSetup = ({
  isPaymentsBillingManager = false,
  locationId,
  paymentsUrl,
  stripeCreatedNotOnboarded = false,
  stripeId,
}: PaymentsSetupProps) => {
  const { t } = useTranslation('payments');
  const alert = useAlert();

  const radioFieldProps = useFormField({ type: 'radio', required: true });
  const {
    modalProps: legalTermsModalProps,
    triggerProps: legalTermsTriggerProps,
    closeModal: legalTermsCloseModal,
  } = useModalControl({
    disableReturnFocus: true,
  });
  const { onClick: openLegalModal } = legalTermsTriggerProps;
  const { modalProps: creationCheckModalProps, triggerProps: creationCheckTriggerProps } = useModalControl();
  const { modalProps: roleRequiredModalProps, triggerProps: roleRequiredTriggerProps } = useModalControl({
    disableReturnFocus: true,
  });

  const redirectToStripe = ({
    onboardingUrl,
    setLoading,
  }: {
    onboardingUrl: string;
    setLoading: (loading: boolean) => void;
  }) => {
    window.open(onboardingUrl, '_blank');
    setLoading(false);
    legalTermsCloseModal();
  };

  // TODO: (m-cain) previously, we had specified separate return and refresh urls.
  // (See:  https://docs.stripe.com/connect/hosted-onboarding#handle-the-connected-account-returning-to-your-platform)
  // `/payments/settings` was specified as the return url, however, the Stripe connect flow shows a link
  // to the return url _before_ onboarding has completed, at a point where the settings page is unreachable.
  // We need to add a dedicated route for this so we can check the state of the merchant and either redirect to the
  // route to continue onboarding, or to the settings page, accordingly.
  const stripeRedirectUrl = window.location.href;

  const { mutate: createStripeAccountMutation } = useMutation(
    ({ paymentsUrl }: { paymentsUrl: string }) =>
      createStripeAccount(paymentsUrl, radioFieldProps.value, false, locationId)
        .then((res) =>
          getStripeOnboardingLink(paymentsUrl, res.stripeId, stripeRedirectUrl, stripeRedirectUrl, locationId)
        )
        .then((response) => response),
    { retry: false }
  );

  const { mutate: continueOnboardingMutation } = useMutation(
    ({ paymentsUrl, stripeId }: { paymentsUrl: string; stripeId: string | undefined }) =>
      getStripeOnboardingLink(paymentsUrl, stripeId, stripeRedirectUrl, stripeRedirectUrl, locationId).then(
        (response) => response
      ),
    { retry: false }
  );

  const handleCreationCheckModal = () => {
    creationCheckModalProps.onClose();
    openLegalModal();
  };

  const handleCreateAccount = ({ setLoading }: { setLoading: (loading: boolean) => void }) => {
    if (isPaymentsBillingManager) {
      setLoading(true);
      const onSuccess = ({ next: onboardingUrl }: { next: string }) => redirectToStripe({ onboardingUrl, setLoading });
      const onError = (e: unknown) => {
        console.error(e);
        alert.error(t('Failed to redirect to Stripe. Please try again.'));
        legalTermsCloseModal();
      };

      if (!stripeId) {
        createStripeAccountMutation({ paymentsUrl }, { onSuccess, onError });
      } else {
        continueOnboardingMutation({ paymentsUrl, stripeId }, { onSuccess, onError });
      }
    } else {
      roleRequiredTriggerProps.onClick();
    }
  };

  return (
    <Page
      title={<PageTitleWithLocationChip title={t('Welcome to Weave Payments')} hasMargin={true} />}
      subtitle={t('Create a new Stripe account to get started.')}
      maxWidth={600}
    >
      {!stripeCreatedNotOnboarded && (
        <RadioField
          name='business_type'
          label={t('Type of Business')}
          {...radioFieldProps}
          css={css`
            min-width: min-content;
            max-width: 300px;
          `}
          data-trackingid={'portal-payments_setup-stripe_type-of-business_radio'}
        >
          <RadioField.Radio value='COMPANY'>{t('Business')}</RadioField.Radio>
          <RadioField.Radio value='NON_PROFIT'>{t('Nonprofit Organization')}</RadioField.Radio>
          <RadioField.Radio value='INDIVIDUAL'>{t('Individual')}</RadioField.Radio>
        </RadioField>
      )}
      <PrimaryButton
        css={css`
          margin-bottom: ${theme.spacing(3)};
          margin-top: ${theme.spacing(3)};
          width: max-content;
        `}
        disabled={!radioFieldProps.value && !stripeCreatedNotOnboarded}
        size='large'
        {...creationCheckTriggerProps}
        trackingId='portal-payments_setup-stripe_create-account_button'
      >
        {stripeCreatedNotOnboarded ? t('Continue Onboarding') : t('Setup Account')}
      </PrimaryButton>
      <hr
        color={theme.colors.neutral20}
        css={css`
          max-width: 500px;
          margin-bottom: ${theme.spacing(3)};
        `}
      />
      <div css={styles.promoCard}>
        <GiftIcon />
        <div
          css={css`
            margin-left: ${theme.spacing(1)};
          `}
        >
          <Text
            css={css`
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            {t('New Weave Payments customers can get a free credit card terminal!')}
          </Text>
          <TextLink to={FREE_TERMINAL_LINK} target='_blank'>
            {t('Request Terminal')}
          </TextLink>
          <Text
            size='small'
            color='light'
            css={css`
              margin-top: ${theme.spacing(1)};
            `}
          >
            {t('Weave will contact you in 1 - 2 business days.')}
          </Text>
        </div>
      </div>
      <div css={styles.promoCard}>
        <GraduationCapIcon />
        <TextLink
          size='large'
          to={UPSELL_LINK}
          target='_blank'
          rel='noreferrer'
          css={css`
            margin-left: ${theme.spacing(1)};
          `}
        >
          {t('Learn more about Weave Payments')}
        </TextLink>
      </div>
      <CreationCheckModal onAccept={handleCreationCheckModal} modalProps={creationCheckModalProps} />
      <TermsAndConditionsModal onAccept={handleCreateAccount} modalProps={legalTermsModalProps} />
      <Modal {...roleRequiredModalProps}>
        <Modal.Header>
          <span css={styles.roleRequiredModalHeader}>
            <BlockIcon css={styles.blockIcon} color='error' />
            {t('Missing authorized roles')}
          </span>
        </Modal.Header>
        <Modal.Body>
          <Text>{t('Super Admin or Payments/Billing Manager role is required to create a Stripe account.')}</Text>
        </Modal.Body>
      </Modal>
    </Page>
  );
};

export const NoInvoices = (props: PaymentsSetupProps) => {
  return <PaymentsSetup {...props} />;
};
