import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const textStyle = css`
  padding: ${theme.spacing(0, 3)};
`;

export const modalStyle = css`
  gap: ${theme.spacing(2)};
`;

export const locationPickerStyle = css`
  padding-top: ${theme.spacing(1)};
`;
