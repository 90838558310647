import {
  EditSimpleIcon,
  Heading,
  IconButton,
  PhoneIncomingIcon,
  PopoverMenu,
  PopoverMenuItem,
  TrashIcon,
  useModalControl,
  usePopoverMenu,
  Text,
  TextLink,
  ModalControlResponse,
  useFormField,
  useAlert,
} from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  ContactDirectoriesAPI,
  ContactDirectoriesQuery,
  ContactDirectoriesQueryKeys,
} from '@frontend/api-contact-directories';
import { css } from '@emotion/react';
import { ContactDirectoryTable } from '../../../components/devices/contact-directories';
import { AssignDevicesToDirectoryModal } from '../../../components/devices/contact-directories/side-modals/assign-devices-to-directoy-modal';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { TrayContainer } from '../../../components/devices/contact-directories/side-modals';
import { useState } from 'react';
import { EditableText } from '@frontend/editable-text';
import { useMutation } from '@frontend/react-query-helpers';
import { useQueryClient } from 'react-query';
import { PreviewDevices } from '../../../components/devices/contact-directories/side-modals/preview-devices-modal';
import { DeleteContactDirectory } from '../../../components/devices/contact-directories/modals';

export const ContactDirectory = ({
  newContactModalControls,
  addExistingContactControls,
  id,
  contactDirectoryName,
}: {
  newContactModalControls: ModalControlResponse;
  addExistingContactControls: ModalControlResponse;
  id: string;
  contactDirectoryName?: string;
}) => {
  return (
    <ContactDirectoryTable
      id={id}
      contactDirectoryName={contactDirectoryName}
      addExistingContactControls={addExistingContactControls}
      newContactModalControls={newContactModalControls}
    />
  );
};

type ContactDirectoryTitleType = {
  id: string;
  name?: string;
};

export const ContactDirectoryTitle = ({ name, id }: ContactDirectoryTitleType) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu({ placement: 'right-start' });
  const { modalProps: deleteDirectoryModalProps, triggerProps: deleteDirectoryTriggerProps } = useModalControl();
  const { modalProps: assignDevicesToDirectoryModalProps, triggerProps: assignDevicesToDirectoryTriggerProps } =
    useModalControl();
  const [enableNameEditor, setEnableNameEditor] = useState(false);
  const queryClient = useQueryClient();
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const locationId = locationIds?.[0] ?? '';
  const alerts = useAlert();

  const contactListNameProps = useFormField(
    {
      required: true,
      type: 'text',
      value: name,
      placeholder: t('New List Name'),
    },
    [enableNameEditor]
  );

  const { mutate: updateContactListName } = useMutation(ContactDirectoriesAPI.updateContactList, {
    onSuccess: () => {
      queryClient.invalidateQueries(ContactDirectoriesQueryKeys.queryKeys.getContactDirectories(locationId));
      queryClient.invalidateQueries(ContactDirectoriesQueryKeys.queryKeys.contactDirectoryById(id, locationId));
      alerts.success(t('Successfully renamed contact list'));
    },
    onSettled: () => {
      setEnableNameEditor(false);
    },
  });

  return (
    <>
      <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
        {enableNameEditor ? (
          <EditableText
            css={inputFieldStyles}
            isTitle
            isToggledOpen={enableNameEditor}
            setIsToggleOpen={setEnableNameEditor}
            hideEditIcon
            fieldProps={contactListNameProps}
            actionText={t('Save')}
            onActionClick={() => {
              updateContactListName({
                id,
                name: contactListNameProps.value,
                locationId,
              });
            }}
          />
        ) : (
          <>
            <Heading level={1}>{t('{{name}}', { name })}</Heading>
            <IconButton label='' css={{ width: 'auto' }} {...getTriggerProps()}>
              <Icon css={{ color: theme.colors.neutral60 }} name='more' />
            </IconButton>
          </>
        )}
      </div>
      <PopoverMenu {...getMenuProps()}>
        <PopoverMenuItem
          Icon={PhoneIncomingIcon}
          trackingId='assign-contact-list'
          {...getItemProps({
            index: 0,
            onClick: () => {
              assignDevicesToDirectoryTriggerProps.onClick();
            },
          })}
        >
          {t('Assign List to Devices')}
        </PopoverMenuItem>
        <PopoverMenuItem
          Icon={EditSimpleIcon}
          {...getItemProps({
            index: 1,
            onClick: () => {
              setEnableNameEditor(true);
            },
          })}
        >
          {t('Rename Contact List')}
        </PopoverMenuItem>
        <PopoverMenuItem
          Icon={TrashIcon}
          {...getItemProps({
            index: 2,
            onClick: () => {
              deleteDirectoryTriggerProps.onClick();
            },
          })}
          destructive
          trackingId='NWX-RPB-DeleteList'
        >
          {t('Delete Contact List')}
        </PopoverMenuItem>
      </PopoverMenu>
      <DeleteContactDirectory name={name} contactDirectoryId={id} {...deleteDirectoryModalProps} />
      <TrayContainer modalProps={assignDevicesToDirectoryModalProps}>
        <AssignDevicesToDirectoryModal
          contactDirectoryId={id}
          name={name ?? ''}
          {...assignDevicesToDirectoryModalProps}
        />
      </TrayContainer>
    </>
  );
};

const inputFieldStyles = css`
  width: 300px;
  margin-bottom: ${theme.spacing(1.5)};

  & input {
    padding-left: ${theme.spacing(0.5)};
    background: transparent;
    font-weight: ${theme.font.weight.bold};
  }
`;

export const ContactDirectorySubtitle = ({ id, name }: { id: string; name: string }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const locationId = locationIds?.[0] ?? '';
  const { modalProps: viewDevicesModalProps, triggerProps: viewDevicesTriggerProps } = useModalControl();
  const { modalProps: assignDevicesModalProps, triggerProps: assignDevicesTriggerProps } = useModalControl();

  const { useGetContactDirectoryDevices } = ContactDirectoriesQuery.useContactDirectoryQueries(locationId);
  const { data: devices } = useGetContactDirectoryDevices({
    contactListId: id,
  });

  const handleOpenAssignDevices = () => {
    viewDevicesModalProps.onClose();
    assignDevicesTriggerProps.onClick();
  };

  return (
    <div
      css={css`
        display: flex;
        margin-top: ${theme.spacing(1)};
      `}
    >
      {!!devices?.deviceIds?.length ? (
        <>
          <Icon name='confirm-badge' color='success' />
          <Text
            css={css`
              margin-left: ${theme.spacing(1)};
            `}
          >
            <Trans t={t} count={devices?.deviceIds.length}>
              Assigned to
              <TextLink
                css={css`
                  pointer: cursor;
                  margin-left: ${theme.spacing(0.75)};
                `}
                onClick={viewDevicesTriggerProps.onClick}
              >
                <span>{{ count: devices?.deviceIds.length }} Devices </span>
              </TextLink>
            </Trans>
          </Text>
        </>
      ) : (
        <>
          <Icon name='info-badge' color='primary' />
          <Text
            css={css`
              margin-left: ${theme.spacing(1)};
            `}
          >
            <Trans t={t}>
              <TextLink
                css={css`
                  pointer: cursor;
                  margin-right: ${theme.spacing(0.5)};
                `}
                onClick={assignDevicesTriggerProps.onClick}
              >
                Assign this list
              </TextLink>
              to your devices to use these contacts in your directories.
            </Trans>
          </Text>
        </>
      )}
      <TrayContainer modalProps={viewDevicesModalProps}>
        <PreviewDevices
          openAssignDevicesModal={handleOpenAssignDevices}
          name={name}
          devices={devices?.devices ?? []}
          {...viewDevicesModalProps}
        />
      </TrayContainer>
      <TrayContainer modalProps={assignDevicesModalProps}>
        <AssignDevicesToDirectoryModal name={name} contactDirectoryId={id} {...assignDevicesModalProps} />
      </TrayContainer>
    </div>
  );
};
