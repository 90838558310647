import { GlobalSearch } from '@frontend/global-search';
import { MessagesGlobalSearchModule, ContactsGlobalSearchModule, MessagesFilters } from '@frontend/inbox';
import { FeatureGlobalSearchModule } from '../feature-global-search-module';
import { SettingsGlobalSearchModule } from '../settings-global-search-module';

/**
 * This is where all GlobalSearch modules should be placed. The `id` prop which is passed to the `GlobalSearch.Section` component is used to 
 * determine which module to display based on the current search context. The `GlobalSearch.Section` component will only display the module
 * if the `id` prop matches the current search context.
 * 
 * Same goes for the `GlobalSearch.Filters.Section` component. The `id` prop is used to determine which filter to display based on the current
 * search context. The recommended way to handle filters is to use `createProvider` from `@frontend/store`, and then wrap it around both 
 * GlobalSearch.Filters and GlobalSearch.Content components. This way, the filters will be able to update the search context and the content
 * will be able to display the correct module based on the search context.
 * 
 * Example:
 * ```ts
 * export const GlobalSearchView = () => {
  return (
    <GlobalSearch>
     <MessagesFilterProvider>
      <GlobalSearch.Filters>
       ....
      </GlobalSearch.Filters>
      <GlobalSearch.Content>
       ....
      </GlobalSearch.Content>
     </MessagesFilterProvider>
    </GlobalSearch>
  );
};
 */

export const GlobalSearchView = () => {
  return (
    <GlobalSearch>
      <GlobalSearch.Filters>
        <GlobalSearch.Filters.Section id='messaging'>
          <MessagesFilters />
        </GlobalSearch.Filters.Section>
      </GlobalSearch.Filters>
      <GlobalSearch.Content>
        <GlobalSearch.Section id='messaging'>
          <MessagesGlobalSearchModule />
        </GlobalSearch.Section>
        <GlobalSearch.Section id='contacts'>
          <ContactsGlobalSearchModule />
        </GlobalSearch.Section>
        <GlobalSearch.Section id='settings'>
          <SettingsGlobalSearchModule />
        </GlobalSearch.Section>
        <GlobalSearch.Section id='features'>
          <FeatureGlobalSearchModule />
        </GlobalSearch.Section>
      </GlobalSearch.Content>
    </GlobalSearch>
  );
};
