import { useQueryClient } from 'react-query';
import { ContactsQueries } from '@frontend/api';
import { useTranslation } from '@frontend/i18n';
import { useTranslationTokens } from '@frontend/i18n-tokens';
import { useContactPanelShallowStore } from '@frontend/shared';
import { ConfirmationModal, useModalControl } from '@frontend/design-system';
import { useAlert } from '@frontend/design-system';

export const useDeleteContactAction = (context: { personId: string; locationId: string }) => {
  const { t } = useTranslation();
  const alerts = useAlert();
  const tokens = useTranslationTokens();
  const queryClient = useQueryClient();
  const { personId, setPersonId } = useContactPanelShallowStore('personId', 'setPersonId');
  const deleteMutation = ContactsQueries.useDeleteContactMutation({
    onSuccess: (response) => {
      if (personId === response.data.person_id) {
        setPersonId('', false);
      }
      alerts.success(t('{{contactLabel}} deleted successfully', { contactLabel: tokens.individualContact }));
      setTimeout(() => {
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes('contacts-list'),
        });
      }, 1000);
    },
    onError: () => {
      alerts.error(t('{{contactLabel}} deletion error', { contactLabel: tokens.individualContact }));
    },
  });
  const destructiveModalProps = useModalControl();

  return {
    triggerProps: destructiveModalProps.triggerProps,
    DeleteConfirmationModal: (
      <ConfirmationModal
        title={tokens.deleteContact}
        message={t('Are you sure you want to delete this {{contactLabel}}? This action cannot be undone.', {
          contactLabel: tokens.individualContact,
        })}
        {...destructiveModalProps.modalProps}
        destructive
        cancelTrackingId='contact-panel-contact-modal-cancel-btn'
        confirmTrackingId='contact-panel-contact-modal-confirm-btn'
        onConfirm={() => deleteMutation.mutate({ personId: context.personId, locationId: context.locationId })}
      />
    ),
  };
};
