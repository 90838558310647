import { CoreACLs, getWeaveToken, hasACL } from '@frontend/auth-helpers';
import { CallRecordingApi } from '@frontend/api';
import { CallLogsApi, CallLogTypes } from '@frontend/api-call-logs';
import { useLocalizedInfiniteQuery } from '@frontend/location-helpers';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { queryKeys } from '../../query-keys';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import {
  Table,
  useModalControl,
  ChipVariants,
  DownloadIcon,
  TrashIcon,
  ConfirmationModal,
  useAlert,
} from '@frontend/design-system';
import dayjs from 'dayjs';
import appConfig from '@frontend/env';
import { CallRecordsFilters, filterDigits } from './call-records-filters';
import { CallRecordsExportModal } from './call-records-export-modal';
import { interceptDates } from '../../utils/phone-utils';
import { http } from '@frontend/fetch';
import { useAppScopeStore } from '@frontend/scope';

const DEFAULT_PAGE_SIZE = 25;

const getDirectionChipColor = (direction: CallLogTypes.CallLog['Direction']): ChipVariants => {
  switch (direction) {
    case 'inbound':
      return 'primary';
    case 'outbound':
      return 'warn';
  }
};

const getStatusChipColor = (status: CallLogTypes.CallLog['Status']): ChipVariants => {
  switch (status) {
    case 'abandoned':
      return 'eggplant';
    case 'missed':
      return 'critical';
    case 'answered':
      return 'primary';
    case 'forwarded':
      return 'warn';
  }
};

const start = dayjs(new Date()).add(-7, 'days').format('MM/DD/YYYY');
const end = dayjs(new Date()).add(1, 'days').format('MM/DD/YYYY');
const defaultFilters = { start, end, number: '' };

export const CallRecordsTable = () => {
  const { t } = useTranslation('phone', { keyPrefix: 'call-records' });
  const alert = useAlert();
  const exportModalControls = useModalControl();
  const deleteModalControls = useModalControl();
  const weaveToken = getWeaveToken();
  const { singleLocationId: locationId } = useAppScopeStore();

  const [pageConfig, setPageConfig] = useState<CallLogTypes.PageConfig>({
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [deleteMediaData, setDeleteMediaData] = useState<CallLogTypes.DeletePayload>({
    ChannelID: '',
    CallerNumber: '',
  });
  const [filters, setFilters] = useState<CallLogTypes.CallLogFiltersPayload>(defaultFilters);
  const [errorChannelIds, setErrorChannelIds] = useState<Record<string, boolean>>({});
  const hasAccessToCallRecordings = locationId && hasACL(locationId, CoreACLs.CALL_RECORDING_READ);

  const queryString = useMemo(
    () => `${JSON.stringify({ ...filters })}-${pageConfig.pageSize}`,
    [filters, pageConfig.pageSize]
  );

  const { data, fetchNextPage, hasNextPage, hasPreviousPage, isFetching, refetch } = useLocalizedInfiniteQuery({
    queryKey: queryKeys.callRecords(queryString),
    queryFn: ({ pageParam }) => {
      const today = dayjs(new Date()).format('MM/DD/YYYY');
      const filtersPayload = {
        ...filters,
        start: interceptDates(filters.start || today, false),
        end: interceptDates(filters.end || today, true),
        number: filterDigits(filters.number || ''),
      };
      return CallLogsApi.getCallRecords(filtersPayload, pageConfig.pageSize, pageParam);
    },
    getNextPageParam: (lastPage: CallLogTypes.CallLogsResponse | undefined) =>
      lastPage?.meta.links.next?.replace('/', ''),
    getPreviousPageParam: (lastPage: CallLogTypes.CallLogsResponse | undefined) =>
      lastPage?.meta.links.previous?.replace('/', ''),
    onError: () => {
      alert.error(t("Couldn't load the call records. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const getHasNext = () => {
    const pageData = data?.pages[pageConfig.pageNumber - 1]?.data || [];
    return hasNextPage && !!pageData.length && pageData.length == pageConfig.pageSize;
  };

  const getFullName = (firstName?: string, lastName?: string) => {
    if (firstName || lastName) {
      return `${firstName || ''} ${lastName || ''}`.trim();
    }
    return t('Unknown');
  };

  useEffect(() => {
    setPageConfig({ ...pageConfig, pageNumber: 1 });
  }, [filters]);

  useEffect(() => {
    if (!data?.pages[pageConfig.pageNumber - 1]) {
      fetchNextPage();
    }
  }, [pageConfig.pageNumber]);

  const downloadMedia = async (record: CallLogTypes.CallLog) => {
    const mediaUrl = `${appConfig.BACKEND_API}/portal/v1/phone/callRecordings/${record.ChannelID}?location_id=${locationId}&token=${weaveToken}`;
    try {
      const blob: any = await CallRecordingApi.download(mediaUrl);
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${url.split('/')[3]}.mp3`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (e) {
      alert.error(t('unable to download the media'));
    }
  };

  //delete the media file --soft delete
  const handleMediaDeletion = async (mediaData: CallLogTypes.DeletePayload) => {
    try {
      await CallRecordingApi.deleteMedia(mediaData.ChannelID, mediaData.CallerNumber);
      refetch();
    } catch (err) {
      if (http.isHttpError(err) && err.status === 403) {
        const httpErr = err?.data as Record<string, string>;
        const displayMessage = httpErr.error
          ? httpErr.error.charAt(0).toUpperCase() + httpErr.error.slice(1).toLowerCase()
          : t('unable to delete the media');
        alert.error(displayMessage);
      } else {
        alert.error(t('unable to delete the media'));
      }
    }
  };

  return (
    <div css={styles.wrapper}>
      <CallRecordsFilters defaultFilters={defaultFilters} filters={filters} onChange={setFilters} />
      <Table
        tableInstanceId='call-records'
        hasFilterColumns
        hasResizeColumns
        emptyStateConfig={{
          type: 'sync_your_phone',
          header: t('No Call Records'),
          description: t("You don't have any call records yet."),
        }}
        colConfig={[
          {
            Header: t('Calling Number'),
            accessor: ({ CallerNumber }: CallLogTypes.CallLog) => formatPhoneNumber(CallerNumber),
            disableSortBy: true,
            id: 'callingNumber',
            width: 160,
          },
          {
            Header: t('Called Number'),
            accessor: ({ DialedNumber }: CallLogTypes.CallLog) => formatPhoneNumber(DialedNumber),
            disableSortBy: true,
            id: 'calledNumber',
            width: 160,
          },
          {
            Header: t('Type'),
            accessor: ({ Direction }: CallLogTypes.CallLog) => Direction,
            cellConfig: {
              element: 'Chip',
              // Added this 'any' type due to erroneous DS implicit 'any' type error
              variant: (cellValue: any) => getDirectionChipColor(cellValue),
            },
            disableSortBy: true,
            id: 'type',
            width: 140,
          },
          {
            Header: t('Media'),
            accessor: ({ ChannelID, HasCallRecording }: CallLogTypes.CallLog) => ({ ChannelID, HasCallRecording }),
            cellRenderer: ({ ChannelID, HasCallRecording }) => (
              <>
                {ChannelID && HasCallRecording && !errorChannelIds[ChannelID] ? (
                  <audio
                    controls
                    controlsList='nodownload'
                    css={[
                      styles.audio,
                      css`
                        max-width: 100%;
                      `,
                    ]}
                    onError={() => setErrorChannelIds({ ...errorChannelIds, [ChannelID]: true })}
                    preload='metadata'
                    src={`${appConfig.BACKEND_API}/portal/v1/phone/callRecordings/${ChannelID}?location_id=${locationId}&token=${weaveToken}`}
                  >
                    {t('Your browser does not support the audio tag.')}
                  </audio>
                ) : (
                  <span css={styles.disabledText}>{t('Recording Not Available')}</span>
                )}
              </>
            ),
            disableSortBy: true,
            id: 'media',
            omit: !hasAccessToCallRecordings,
            minWidth: 350,
          },
          {
            Header: t('Date / Time'),
            accessor: ({ StartedAt }: CallLogTypes.CallLog) => dayjs(StartedAt).format('MMM DD YYYY, hh:mm A'),
            disableSortBy: true,
            id: 'dateTime',
            width: 200,
          },
          {
            Header: t('Result'),
            accessor: ({ Status }: CallLogTypes.CallLog) => Status,
            cellConfig: {
              element: 'Chip',
              // Added this 'any' type due to erroneous DS implicit 'any' type error
              variant: (cellValue: any) => getStatusChipColor(cellValue),
            },
            disableSortBy: true,
            id: 'result',
            width: 150,
          },
          {
            Header: t('Duration'),
            accessor: ({ Duration }: CallLogTypes.CallLog) =>
              (dayjs as any).duration(Duration, 'milliseconds').format('HH:mm:ss'),
            disableSortBy: true,
            id: 'duration',
            width: 150,
          },
          {
            Header: t('Caller Name'),
            accessor: ({ Person }: CallLogTypes.CallLog) => getFullName(Person?.FirstName, Person?.LastName),
            disableSortBy: true,
            id: 'callerName',
            width: 180,
          },
          {
            Header: t('Office User'),
            accessor: ({ User }: CallLogTypes.CallLog) => getFullName(User?.FirstName, User?.LastName),
            disableSortBy: true,
            id: 'officeUser',
            width: 180,
          },
          {
            Header: t('Office Number'),
            accessor: ({ CallerNumber, DialedNumber, Direction }: CallLogTypes.CallLog) =>
              formatPhoneNumber(Direction === 'inbound' ? DialedNumber : CallerNumber),
            disableSortBy: true,
            id: 'officeNumber',
            width: 150,
          },
        ]}
        data={data?.pages[pageConfig.pageNumber - 1]?.data || []}
        hasResponsiveColWidths
        isLoading={isFetching}
        isPaginated
        manualFilters
        manualFiltersRender={(modalProps, setShowNotification) => (
          <CallRecordsFilters
            defaultFilters={defaultFilters}
            filters={filters}
            isSideBar
            modalProps={modalProps}
            onChange={setFilters}
            setShowNotificationBadge={setShowNotification}
          />
        )}
        manualPaginationConfig={{
          handleChange: (action: 'next' | 'prev') => {
            if (action === 'next') {
              setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber + 1 });
            } else {
              setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber - 1 });
            }
          },
          hasNext: getHasNext(),
          hasPrevious: hasPreviousPage,
          page: pageConfig.pageNumber,
          defaultRowsPerPage: pageConfig.pageSize,
          onNumRowsChange: (num) => {
            setPageConfig({ pageNumber: 1, pageSize: num });
          },
          rowsPerPageOptions: [10, 25, 50, 75],
        }}
        rowActions={
          hasAccessToCallRecordings
            ? {
                rowActionMenuLabel: '',
                actions: [
                  {
                    label: t('Download Media'),
                    Icon: DownloadIcon,
                    hide: (row) => !row.HasCallRecording,
                    onClick: (record: CallLogTypes.CallLog) => {
                      downloadMedia(record);
                    },
                  },
                  {
                    label: t('Delete Media'),
                    Icon: TrashIcon,
                    hide: (row) => !row.HasCallRecording,
                    onClick: (record: CallLogTypes.CallLog) => {
                      deleteModalControls.triggerProps.onClick();
                      setDeleteMediaData({ ChannelID: record.ChannelID, CallerNumber: record.CallerNumber });
                    },
                  },
                ],
              }
            : {}
        }
        tableActions={[
          {
            label: t('Export'),
            onClick: exportModalControls.openModal,
            type: 'button',
            variant: 'primary',
          },
        ]}
        wrapperStyle={css`
          height: 100%;
        `}
      />
      <CallRecordsExportModal filters={filters} modalProps={exportModalControls.modalProps} />
      {/* confirmation whether the user wants to delete the media or not */}
      <ConfirmationModal
        {...deleteModalControls.modalProps}
        title={t('Delete Confirmation')}
        message={t('Are you sure you want to delete this call recording?')}
        destructive
        onConfirm={() => {
          handleMediaDeletion(deleteMediaData);
        }}
      />
    </div>
  );
};

const styles = {
  wrapper: css`
    flex: 1;
    height: 100%;
    overflow: hidden;
    padding-top: ${theme.spacing(1)};
    position: relative;
  `,

  flexCells: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
  `,

  audio: css`
    height: ${theme.spacing(4)};
  `,

  disabledText: css`
    color: ${theme.colors.neutral30};
    display: inline-block;
    text-align: left;
    width: 100%;
  `,
};
