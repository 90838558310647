import { useTranslation } from '@frontend/i18n';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { useSoftphoneRouter } from '../providers/softphone-router-provider';
import { CallInformation } from '../components/call-information';
import { Button, Page } from '@frontend/generic-dialpad-accessories';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';

export const PageMergedHome = () => {
  const { t } = useTranslation('softphone');
  const mergedCallGroup = useSoftphoneCallState((ctx) => ctx.mergedCallGroup);

  const route = useSoftphoneRouter((ctx) => ctx.setGroupCallRoute);

  return (
    <Page>
      <PageHeaderWraps
        css={css`
          display: block;
        `}
      >
        {mergedCallGroup?.map((call) => {
          return <CallInformation {...call.remoteParty} hideSubtitle hideCallDuration />;
        })}
      </PageHeaderWraps>
      <Page.Body>
        <ul
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            margin: ${theme.spacing(3, 0)};
            gap: ${theme.spacing(3)};
            padding: 0;
            list-style-type: none;
          `}
        >
          <li>
            <Button.Round
              trackingId='softphone-multicall-managecall'
              onClick={() => route('calls-list')}
              size='medium'
              iconName={'user'}
              label={t('Manage')}
            />
          </li>
          <li>
            <Button.Round onClick={() => route('keypad')} size='medium' iconName={'dial'} label={t('Keypad')} />
          </li>
        </ul>
      </Page.Body>
    </Page>
  );
};
