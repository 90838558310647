import { SchemaSMSSharedEnums } from '@frontend/api-messaging';

export const convertStringToStatus = (
  status: string,
  defaultVal: SchemaSMSSharedEnums.Status = SchemaSMSSharedEnums.Status.STATUS_NEW
): SchemaSMSSharedEnums.Status => {
  switch (status) {
    case 'new':
      return SchemaSMSSharedEnums.Status.STATUS_NEW;
    case 'read':
      return SchemaSMSSharedEnums.Status.STATUS_READ;
    case 'error':
      return SchemaSMSSharedEnums.Status.STATUS_ERROR;
    case 'received':
      return SchemaSMSSharedEnums.Status.STATUS_RECEIVED;
    default:
      return defaultVal;
  }
};
