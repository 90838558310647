import { Fragment } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { INITIAL_MESSAGE_ITEM_INDEX } from '../../../constants';
import { Message } from '../../../types';
import { MessageItem } from './message-item';
import { NewMessageDivider } from './new-messages-divider';

export const VirtuosoItem = ({
  index,
  message,
  unreadCount,
  messagesLength,
}: {
  index: number;
  message: Message;
  unreadCount: number;
  messagesLength: number;
}) => {
  /* 
    Since the Virtuoso Item works on initially passed index there not a better way to the real message index position
    in the array, so we are making a equation to get the real index so that we could display the new message line.
  */
  const actualIndex = Math.abs(INITIAL_MESSAGE_ITEM_INDEX - messagesLength - index);
  const showNewMessageDivider = unreadCount === messagesLength - actualIndex;

  if (message.type === 'system') {
    return <div css={systemMessageStyles}>{message.text}</div>;
  }

  return (
    <Fragment key={message.id}>
      {/* TODO :: Check if we can have the divider via CSS only */}
      {showNewMessageDivider && <NewMessageDivider />}
      <MessageItem message={message} />
    </Fragment>
  );
};

const systemMessageStyles = css({
  padding: theme.spacing(1, 0),
  textAlign: 'center',
  color: theme.colors.neutral50,
  fontSize: theme.spacing(1.5),
  fontWeight: theme.font.weight.regular,
});
