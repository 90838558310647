import { SchemaIO } from '@frontend/schema';
import { useSMSDataV3QueryUpdaters } from '../../query-updaters';
import { KnownThreadStatus, ServiceMutations } from '../../types';
import { UseSMSDataV3MutationEndpointArgs, useSMSDataV3Mutation } from '../use-sms-data-v3-mutation';

/**
 * A hook that returns a mutation for the `SetThreadsStatusNew` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useSetThreadsStatusNewMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations['SetThreadsStatusNew']>['input'] = SchemaIO<
    ServiceMutations['SetThreadsStatusNew']
  >['input']
>({
  options,
  ...args
}: UseSMSDataV3MutationEndpointArgs<'SetThreadsStatusNew', E, C | undefined, OtherOptions, RequestOverride> = {}) => {
  const { invalidateQueries, updateQuery, getQueryKey } = useSMSDataV3QueryUpdaters();

  return useSMSDataV3Mutation<'SetThreadsStatusNew', E, C, OtherOptions, RequestOverride>({
    endpointName: 'SetThreadsStatusNew',
    ...args,
    options: {
      ...options,
      onSuccess: (response, request, context) => {
        response.succeeded.forEach((threadId) => {
          updateQuery<'GetThread', true>({
            endpointName: 'GetThread',
            queryFilters: {
              queryKey: getQueryKey({
                endpointName: 'GetThread',
                request: {
                  threadId,
                },
              }),
              exact: false,
            },
            updater: (oldData) => {
              return {
                ...oldData,
                pages: oldData.pages.map((page) => ({
                  ...page,
                  thread: {
                    ...page.thread,
                    status: KnownThreadStatus.NEW,
                  },
                })),
              };
            },
          });
        });

        invalidateQueries<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: {
            predicate: ({ queryKey }) => {
              const queryRequest = queryKey[2];
              const matchesGroupIds =
                queryRequest.groupIds?.some((groupId) => request.groupIds?.includes(groupId) ?? false) ?? true;
              const matchesLocationId = queryRequest.locationId === request.locationId;
              if (matchesGroupIds || matchesLocationId) return true;

              return false;
            },
          },
        });

        return options?.onSuccess?.(response, request, context);
      },
    },
  });
};
