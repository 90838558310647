import { useEffect } from 'react';
import { SEND_FORM_STEPS } from '../../constants';
import { SendFormsOrigin } from '../../types';
import { useSendFormsStore } from '../store';

interface UseStepsConfigProps {
  origin: SendFormsOrigin;
}

const useStepsConfig = ({ origin }: UseStepsConfigProps) => {
  const { setCurrentStep, setSteps } = useSendFormsStore(['setCurrentStep', 'setSteps']);

  useEffect(() => {
    switch (origin) {
      case SendFormsOrigin.FORMS: {
        const steps = SEND_FORM_STEPS.formsOrigin;
        setSteps(steps);
        setCurrentStep(steps[0]);
        break;
      }

      case SendFormsOrigin.CONTACTS: {
        const steps = SEND_FORM_STEPS.contactsOrigin;
        setSteps(steps);
        setCurrentStep(steps[0]);
        break;
      }
    }
  }, [origin]);
};

export default useStepsConfig;
