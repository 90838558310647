import { useState, useEffect } from 'react';
import {
  FeedbackType,
  GenerateReplyRequest,
  SubmitFeedbackRequest,
} from '@weave/schema-gen-ts/dist/schemas/sms/reply-assistant/v1/reply_assistant_service.pb';
import { StateRefHelpers } from '@frontend/state-ref-helpers';
import { useGenerateReplyMutation, useSubmitFeedbackMutation } from '../mutations';

type useAiResponseGenerationProps = {
  isAiAssistantAvailable: boolean;
  textForRefinement?: string;
  aiResponseActionCallback: (response: string | undefined) => void;
  onAiResponseGenerationError?: () => void;
  onFeedbackSubmitSuccess?: () => void;
  onFeedbackSubmitError?: () => void;
};

export type generateAiResponseType = (reqBody: GenerateReplyRequest) => void;

/**
 * Hook to generate/regenerate, undo/redo and refine ai assisted replies.
 *
 * @param isAiAssistantAvailable: boolean - consumers logic visibility control for ai-reply-assistant.
 * @param onAiResponseGenerated: cb function - invoked after ai response generation success.
 * @param textForRefinement[optional]:  string - text for refinement.
 * @param onAiResponseGenerationError: cb function - invoked after ai response generation error.
 * @param onFeedbackSubmitSuccess: cb function - invoked after submit feedback success.
 * @param onFeedbackSubmitError: cb function - invoked after submit feedback error.
 *
 * @returns canShowAiAssistant: boolean - consumers logic + ai-response-actions logic visibility control for ai-reply-assistant.
 * @returns canShowAiResponseActions: boolean - visibility control for ai-response-action.
 * @returns canShowResponseFeedback: boolean - visibility control for response feedback UI.
 * @returns isGenerateAiResponseLoading: boolean - response generation loader logic control.
 * @returns generateAiResponse: Function - function to generate ai response.
 * @returns generateAiResponseError: Error - error thrown during response generation.
 * @returns undoResponse: Function - function to revert back to previous response
 * @returns redoResponse: Function - function to go to next response.
 * @returns canUndo: boolean - disable control for undo UI.
 * @returns canRedo: boolean - disable control for redo UI.
 * @returns submitFeedback: Function - function to submit feedback for generated response.
 * @returns isSubmitResponseFeedbackSuccess: boolean - feedback success boolean state.
 * @returns submitResponseFeedbackError: Error - error thrown during submit feedback.
 * @returns shouldOpenInRefinementMode: boolean - control to open ai-response-action in refinement mode.
 * @returns closeAiResponseActions: Function - function to close ai-response-actions.
 */
export const useReplyAssistant = ({
  isAiAssistantAvailable,
  textForRefinement,
  aiResponseActionCallback,
  onAiResponseGenerationError,
  onFeedbackSubmitSuccess,
  onFeedbackSubmitError,
}: useAiResponseGenerationProps) => {
  const [canShowAiResponseActions, setCanShowAiResponseActions] = useState<boolean>(false);
  const [canShowResponseFeedback, setCanShowResponseFeedback] = useState<boolean>(true);
  const [canSyncWithHistory, setCanSyncWithHistory] = useState<boolean>(true);
  const [shouldOpenInRefinementMode, setShouldOpenInRefinementMode] = useState<boolean>(true);

  const { addToHistory, removeCurrentItem, undo, redo, currentItem, clearHistory, canUndo, canRedo } =
    StateRefHelpers.useUndoRedo<string>();

  const {
    error: generateAiResponseError,
    isLoading: isGenerateAiResponseLoading,
    mutate: generatedReply,
  } = useGenerateReplyMutation({
    options: {
      onSuccess: ({ reply }) => {
        if (reply) {
          setCanShowAiResponseActions(true);
          addToHistory(reply);
          setShouldOpenInRefinementMode(false);
        }
      },
      onError: () => {
        onAiResponseGenerationError?.();
      },
    },
  });

  const {
    isSuccess: isSubmitResponseFeedbackSuccess,
    error: submitResponseFeedbackError,
    mutate: submitResponseFeedback,
  } = useSubmitFeedbackMutation({
    options: {
      onSuccess: (_, reqBody) => {
        // Clear generated response on negative feedback and remove current item without affecting response sent to consumer
        if (reqBody.feedbackType === FeedbackType.FEEDBACK_TYPE_BAD) {
          removeCurrentItem();
          setCanSyncWithHistory(false);
          aiResponseActionCallback('');
        }
        setCanShowResponseFeedback(false);
        onFeedbackSubmitSuccess?.();
      },
      onError: () => {
        onFeedbackSubmitError?.();
      },
    },
  });

  // If there is a text ready for refinement already, open in refinement mode.
  const generateAiResponse = (reqBody: GenerateReplyRequest) => {
    // add modified drafts to history, if any. addToHistory takes care of isModified check.
    const { previousDraft } = reqBody;
    previousDraft && addToHistory(previousDraft);
    if (!(textForRefinement && shouldOpenInRefinementMode) || canShowAiResponseActions) {
      generatedReply(reqBody);
    } else {
      setCanShowAiResponseActions(true); // RefinementMode only opens the ai-response-actions initially.
    }
  };

  const submitFeedback = (partialReqBody: Omit<SubmitFeedbackRequest, 'draft'>) => {
    submitResponseFeedback({ ...partialReqBody, draft: currentItem || '' });
  };

  const closeAiResponseActions = () => {
    setCanShowAiResponseActions(false);
    setCanShowResponseFeedback(true);
    setShouldOpenInRefinementMode(true);
    setCanSyncWithHistory(true);
    clearHistory();
  };

  // To handle reset logic for consumer which toggles isAiAssistantAvailable boolean without remounting.
  useEffect(() => {
    if (!isAiAssistantAvailable) {
      closeAiResponseActions();
    }
  }, [isAiAssistantAvailable]);

  // Sync with history and send recent response generated back to consumer.
  useEffect(() => {
    if (canSyncWithHistory) {
      currentItem && aiResponseActionCallback(currentItem);
    } else {
      setCanSyncWithHistory(true);
    }
  }, [currentItem]);

  return {
    canShowAiAssistant: isAiAssistantAvailable && !isGenerateAiResponseLoading && !canShowAiResponseActions,
    canShowAiResponseActions: canShowAiResponseActions && !isGenerateAiResponseLoading,
    canShowResponseFeedback:
      textForRefinement === currentItem && canShowResponseFeedback && !shouldOpenInRefinementMode,
    isGenerateAiResponseLoading,
    generateAiResponse,
    generateAiResponseError,
    undoResponse: undo,
    redoResponse: redo,
    canUndo,
    canRedo,
    submitFeedback,
    isSubmitResponseFeedbackSuccess,
    submitResponseFeedbackError,
    shouldOpenInRefinementMode,
    closeAiResponseActions,
  };
};
