import { memo, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { PhoneAnalyticsApi, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { AreaChartData, Chart, sequentialChartColors } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  DropdownFilterChipField,
  SwitchChipGroup,
  useFormField,
  useSwitchChipGroup,
  styles as dsStyles,
  useAlert,
  Text,
} from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { formatHourlyInsights, hourlyInsightsLimiter, sumArrayItems } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { useIsPhoneAnalyticsDemoAccount, usePhoneAnalyticsStore } from '../hooks';

type CallType = 'answered' | 'placed';

interface PhoneExtensionChartFiltersProps {
  callType: CallType;
  extensionsList: string[];
  selectedExtensions: string[];
  setCallType: (value: CallType) => void;
  setSelectedExtensions: (value: string[]) => void;

  disabled?: boolean;
}

interface ExtensionsData {
  [date: string]: {
    [extensionName: string]: {
      answered: number[];
      placed: number[];
    };
  };
}

interface FormatExtensionsData {
  existingData: ExtensionsData;
  newData: PhoneAnalyticsTypes.ExtensionAggregates[];
  type: CallType;

  isHourlyInsights?: boolean;
}

const zeroArray = (length: number) => Array.from({ length }, () => 0);

const formatExtensionsData = ({ existingData, isHourlyInsights, newData, type }: FormatExtensionsData) => {
  const clonedData = { ...existingData };

  newData.forEach(({ Aggregates, Extention }) => {
    Aggregates.forEach(({ Date, Values }) => {
      if (!clonedData[Date]) {
        clonedData[Date] = {};
      }

      const extensionName = Extention.trim() || 'unknown';

      // Lets make sure to add an extension object for each date
      // So we will always have an object to form the chart data properly
      if (!clonedData[Date][extensionName]) {
        const prefill = zeroArray(isHourlyInsights ? 13 : 24);
        clonedData[Date][extensionName] = {
          answered: prefill,
          placed: prefill,
        };
      }

      clonedData[Date][extensionName][type] = isHourlyInsights ? hourlyInsightsLimiter(Values) : Values;
    });
  });

  return clonedData;
};

const PhoneExtensionChartFilters = memo(
  ({
    callType,
    extensionsList = [],
    disabled,
    selectedExtensions,
    setCallType,
    setSelectedExtensions,
  }: PhoneExtensionChartFiltersProps) => {
    const { t } = useTranslation('analytics');

    const switchChipProps = useSwitchChipGroup({
      defaultValue: [callType],
      onChange: (val) => {
        setCallType(val[0] as CallType);
      },
    });

    const extensionsFieldProps = useFormField(
      {
        type: 'checklist',
        value: selectedExtensions,
      },
      [selectedExtensions]
    );

    return (
      <div css={styles.filtersWrapper}>
        <SwitchChipGroup {...switchChipProps} singleSelect>
          <SwitchChipGroup.Option disabled={disabled} value='answered'>
            {t('Call Answered')}
          </SwitchChipGroup.Option>
          <SwitchChipGroup.Option disabled={disabled} value='placed'>
            {t('Call Placed')}
          </SwitchChipGroup.Option>
        </SwitchChipGroup>

        <DropdownFilterChipField
          {...extensionsFieldProps}
          icon='phone'
          label={t('Extensions')}
          name='extension-filter'
          onApply={({ value, close }) => {
            setSelectedExtensions(value);
            close();
          }}
          showClearAll={false}
        >
          {extensionsList.map((extension) => (
            <DropdownFilterChipField.Option key={extension} value={extension}>
              <Text css={[dsStyles.truncate, styles.filterOption]}>{extension}</Text>
            </DropdownFilterChipField.Option>
          ))}
        </DropdownFilterChipField>
      </div>
    );
  }
);

export const PhoneExtensionChart = memo(() => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const isDemoAccount = useIsPhoneAnalyticsDemoAccount();
  const { demoData, filterHintText, filters } = usePhoneAnalyticsStore();
  const [extensions, setExtensions] = useState<string[]>([]);
  const [callType, setCallType] = useState<CallType>('answered');

  // Show hourly insights if start and end dates are same
  const isHourlyInsights = dayjs(filters.StartDate).isSame(filters.EndDate, 'day');

  const queryString = useMemo(() => {
    const { StartDate, EndDate, LocationID } = filters;
    return `ana-phone-extensions-data-${StartDate}-${EndDate}-${LocationID?.sort().join()}-${isDemoAccount}`;
  }, [filters, isDemoAccount]);

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.phoneAnalyticsCharts(queryString),
    queryFn: () => (isDemoAccount ? null : PhoneAnalyticsApi.getPhoneExtensionsRecords(filters)),
    onError: () => {
      alert.error(t("Couldn't load the extensions data. Please try again."));
    },
    refetchOnWindowFocus: false,
    retry: false,
    select: (data) => {
      return isDemoAccount ? demoData?.extensionsData : data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const extensionsRecords = useMemo(() => {
    // Process all locations data to extract extensions details
    return data?.LocationIds.reduce<ExtensionsData>((acc, { CallAnswered, CallPlaced }) => {
      const formattedAnswered = formatExtensionsData({
        existingData: acc,
        isHourlyInsights,
        newData: CallAnswered,
        type: 'answered',
      });

      acc = formatExtensionsData({
        existingData: formattedAnswered,
        isHourlyInsights,
        newData: CallPlaced,
        type: 'placed',
      });

      return acc;
    }, {});
  }, [data, isHourlyInsights]);

  const extensionsList = useMemo(() => {
    if (!extensionsRecords) {
      return [];
    }

    // All extensions record sets will have the same list of extension names
    // Hence picking up the list from the first record set
    return Object.keys(Object.values(extensionsRecords || {})[0] || {});
  }, [extensionsRecords]);

  const chartData: AreaChartData = useMemo(() => {
    if (!extensions.length || !extensionsRecords) {
      return {
        groups: [],
      };
    }

    let groups: AreaChartData['groups'] = [];

    if (isHourlyInsights) {
      // isHourlyInsights will be true only when there is just one date record
      // Hence we can directly pick the first record set from the extensionsRecords
      const record = Object.values(extensionsRecords)[0];
      groups = Object.entries(record).reduce<AreaChartData['groups']>((acc, [extension, values]) => {
        if (!extensions.includes(extension)) {
          return acc;
        }
        return formatHourlyInsights(extension, values[callType], { groups: acc });
      }, []);
    } else {
      groups = Object.entries(extensionsRecords).map(([date, data]) => {
        return {
          name: dayjs(date).format('MMM D'),
          values: extensions.reduce<Record<string, number>>((acc, extension) => {
            acc[extension] = sumArrayItems(data[extension][callType]);
            return acc;
          }, {}),
        };
      });
    }

    return {
      groups,
    };
  }, [callType, extensions, extensionsRecords, isHourlyInsights]);

  const chartColors = useMemo(() => {
    return extensionsList.reduce<Record<string, string>>((acc, extension, index) => {
      acc[extension] = sequentialChartColors[index];
      return acc;
    }, {});
  }, [extensionsList]);

  useEffect(() => {
    setExtensions(extensionsList);
  }, [extensionsList]);

  return (
    <Chart
      colors={chartColors}
      emptyStateConfig={{
        type: 'phone_extensions',
        label: t('Sorry! Phone extension report is not available'),
      }}
      isLoading={isLoading}
      labels={{ unknown: t('Unknown') }}
    >
      <Chart.Header
        bottomElement={
          <PhoneExtensionChartFilters
            callType={callType}
            extensionsList={extensionsList}
            selectedExtensions={extensions}
            setCallType={setCallType}
            setSelectedExtensions={setExtensions}
          />
        }
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Device Extension')}
      />
      <Chart.AreaChart
        appearance={{
          margin: { left: 20 },
          showXAxis: true,
          showYAxis: true,
        }}
        data={chartData}
      />
      <Chart.Legends />
    </Chart>
  );
});

PhoneExtensionChartFilters.displayName = 'PhoneExtensionChartFilters';
PhoneExtensionChart.displayName = 'PhoneExtensionChart';

const styles = {
  filtersWrapper: css`
    margin: ${theme.spacing(2, 0)};
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
    justify-content: space-between;
  `,

  filterOption: css`
    max-width: 200px;
    text-transform: capitalize;
  `,
};
