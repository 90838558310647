import { CallQueueTypes } from '@frontend/api';
import { DevicesTypes } from '@frontend/api-devices';

export const deviceSelectionLimit = 20;

export type CallQueueDevicesContext = {
  allDevices: DevicesTypes.DeviceWithAddressModel[];
  assignedDevices: CallQueueTypes.Device[];
  callQueue: CallQueueTypes.CallQueue | undefined;
  shouldUpdate: boolean;
};

export type TransformedDevicesType = (DevicesTypes.DeviceModel & {
  isAssigned: boolean;
  isTentativelyAssigned: boolean;
})[];

export const transformDevices = (data: DevicesTypes.DeviceWithAddressModel[], assignedDevices: string[]) => {
  return data.map((d) => {
    // add props to distinguish between assigned and newly assigned devices
    const isAssigned = d.sipDevice.sipProfile?.ID ? assignedDevices.includes(d.sipDevice.sipProfile?.ID) : false;

    return { ...d.sipDevice, isAssigned, isTentativelyAssigned: false };
  });
};

export const createPostPayload = (callQueue: CallQueueTypes.CallQueue, devices: string[]): CallQueueTypes.CallQueue => {
  return {
    ...callQueue,
    Devices: devices?.map((id: any) => ({ SipProfileID: id })) ?? callQueue.Devices,
  };
};

export const getWorkingState = (devices: TransformedDevicesType) =>
  devices.filter((device) => device.type === 'desk_phone' || device.type === 'softphone');
