import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { NoTranscription } from '@frontend/assets';
import { Trans, useTranslation } from '@frontend/i18n';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Accordion, ContentLoader, NakedUl, Text, TextLink } from '@frontend/design-system';
import { transcribingCallLottie } from '../../assets';
import { featureFlags } from '../../feature-flags';
import { useCallIntelStore } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { callIntelligenceUtils, parseTranscript, parseTranscriptWithTokens } from '../../utils';
import { EmptyData } from '../empty-data';
import { AnalyzingCallLoader } from './analyzing-call-loader';
import { CallAnalyzeStatus } from './call-analyze-status';
import { OfficeUserName, TranscriptCard } from '.';

const SPACE_MARGIN = 130;

type Props = {
  call: CallIntelligenceTypes.Call;
  callMetadata?: CallIntelligenceTypes.CallMetadata | null;
  highlightedCitation?: CallIntelligenceTypes.HighlightedCitation;
  isDemoAccount?: boolean;
  isFailedCall?: boolean;
  isLoading?: boolean;
  isPoorAudio?: boolean;
  isSkippedCall?: boolean;
  maxHeight?: number;
};

export const CallSummary = ({
  call: { officeUser, person, startTime, status },
  callMetadata,
  highlightedCitation,
  isDemoAccount,
  isFailedCall,
  isLoading,
  isPoorAudio,
  isSkippedCall,
  maxHeight = 0,
}: Props) => {
  const { t } = useTranslation('analytics');
  const isFollowUpsEnabled = useHasFeatureFlag(featureFlags.enableCallIntelFollowUps);
  const [showFullTranscript, setShowFullTranscript] = useState<boolean>(false);
  const isCallInProcessing = callIntelligenceUtils.isCallInProcessing(status);
  const isUserInactive = callIntelligenceUtils.isUserInactive(officeUser);

  const { chipVariants } = useCallIntelStore();

  const callParsedTranscripts = useMemo(
    () =>
      !isDemoAccount
        ? parseTranscript(callMetadata?.transcript as string)
        : parseTranscript(callMetadata?.transcript as string, {
            PERSON_NAME: person?.firstName ?? '',
          }),
    [callMetadata?.transcript, isDemoAccount, person]
  );

  const callSummary = useMemo(() => {
    return !isDemoAccount
      ? callMetadata?.summary
      : parseTranscriptWithTokens(callMetadata?.summary as string, {
          PERSON_NAME: person?.firstName ?? '',
        });
  }, [callMetadata?.summary, isDemoAccount, person]);

  const citations = useMemo(() => {
    if (!isFollowUpsEnabled) return [];

    return (callMetadata?.tasks || []).map((task) => ({
      time: task.citationTime ?? '',
      text: task.citation ?? '',
      variant: chipVariants[task.type] || 'neutral',
    }));
  }, [callMetadata, chipVariants, isFollowUpsEnabled]);

  const renderEmptyData = (isSummary?: boolean) => (
    <EmptyData
      mainText={isSummary ? t('No Summary Available') : t('No Transcript Available')}
      style={{
        overflow: 'auto',
      }}
      svg={NoTranscription}
      subText={t('Please review the call recording.')}
    />
  );

  useEffect(() => {
    const shouldShowFullTranscript =
      (!!highlightedCitation?.time && !!callMetadata?.transcript) ||
      (!callMetadata?.summary && !!callMetadata?.transcript);

    setShowFullTranscript(shouldShowFullTranscript);
  }, [callMetadata, highlightedCitation?.time]);

  return (
    <>
      <Accordion.Header title={showFullTranscript ? t('Call Transcript') : t('Summary of Call')} />
      <Accordion.Body>
        {!isCallInProcessing && (
          <CallAnalyzeStatus
            isFailedCall={isFailedCall}
            isPoorAudio={isPoorAudio}
            isSkippedCall={isSkippedCall}
            type='summary'
          />
        )}
        {isFailedCall || isCallInProcessing || isLoading ? (
          <>
            {isFailedCall ? (
              renderEmptyData()
            ) : (
              <AnalyzingCallLoader
                lottie={transcribingCallLottie}
                message={t('Transcribing call recording...')}
                messageCss={styles.loadingMessage}
              />
            )}
          </>
        ) : (
          <div css={styles.wrapper}>
            {showFullTranscript ? (
              <>
                {callMetadata?.transcript ? (
                  <NakedUl
                    css={styles.overflow}
                    style={{
                      marginBottom: callMetadata.summary ? 0 : theme.spacing(1),
                      maxHeight: maxHeight - SPACE_MARGIN,
                    }}
                  >
                    {callParsedTranscripts.map((transcript) => (
                      <TranscriptCard
                        {...transcript}
                        caller={person}
                        citations={citations}
                        highlightedCitation={highlightedCitation}
                        isDemoAccount={isDemoAccount}
                        key={transcript.time.start}
                        officeUser={officeUser}
                        time={transcript.time.start}
                      />
                    ))}
                  </NakedUl>
                ) : (
                  !isLoading && renderEmptyData()
                )}
                {callMetadata?.summary && (
                  <TextLink
                    onClick={() => setShowFullTranscript(false)}
                    trackingId={trackingIds.callIntel.showTranscriptSummary}
                    weight='bold'
                  >
                    {t('Show Summary')}
                  </TextLink>
                )}
              </>
            ) : callMetadata?.summary ? (
              <>
                <Text css={styles.officeUserName}>
                  <Trans t={t}>
                    Office User:{' '}
                    <strong style={isUserInactive ? { color: theme.colors.neutral50 } : {}}>
                      <OfficeUserName
                        {...officeUser}
                        isDataUnavailable={callIntelligenceUtils.isOfficeUserDataUnavailable(startTime)}
                        isInactive={isUserInactive}
                      />
                    </strong>
                  </Trans>
                </Text>
                <Text
                  color={callMetadata?.summary ? 'default' : 'light'}
                  css={styles.overflow}
                  style={{
                    maxHeight: maxHeight - SPACE_MARGIN,
                  }}
                >
                  {callSummary}
                </Text>
                {callMetadata.transcript && (
                  <TextLink
                    onClick={() => setShowFullTranscript(true)}
                    trackingId={trackingIds.callIntel.showFullTranscript}
                    weight='bold'
                  >
                    {t('Show Full Transcript')}
                  </TextLink>
                )}
              </>
            ) : (
              !isLoading && renderEmptyData(true)
            )}
            <ContentLoader show={isLoading} size='small' />
          </div>
        )}
      </Accordion.Body>
    </>
  );
};

const styles = {
  wrapper: css`
    align-items: baseline;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    position: relative;
  `,

  overflow: css`
    overflow: auto;
    padding-right: ${theme.spacing(2)};

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: ${theme.spacing(1)};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.neutral30};
      border-radius: ${theme.borderRadius.small};
    }
  `,

  loadingMessage: css`
    margin-top: ${theme.spacing(-3)};
  `,

  officeUserName: css`
    display: flex;
    gap: ${theme.spacing(0.5)};
  `,
};
