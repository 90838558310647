import { useMemo } from 'react';
import {
  SubscriptionStatusForSelfUpgrade,
  TrialStatus,
} from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { DataSourcesApi } from '@frontend/api-data-sources';
import { FreeTrialsQueries } from '@frontend/api-free-trials';
import { useScopedQuery } from '@frontend/scope';

type UseIsPAEligibleAccount = {
  isFreeTrialEligible?: boolean;
  isLoading?: boolean;
  isPAEligibleAccount?: boolean;
};

// This is used in the Analytics app to determine if the location is eligible for the Practice Analytics feature.
export const useIsPAEligibleAccount = (): UseIsPAEligibleAccount => {
  const { flags } = useCustomizationFlagShallowStore('flags');
  const isCFActive =
    flags.practiceanalytics?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE ||
    flags.practiceanalytics?.state === CustomizationFlagTypes.CustomizationFlagState.PROMOTE;

  const { data: selfUpgradeEligibility, isLoading: isLoadingSelfUpgradeEligibility } =
    FreeTrialsQueries.useGetIsEligibleForSelfUpgradeOrTrial(Feature.PRACTICE_ANALYTICS);

  const { data: isSupportedPMS } = useScopedQuery({
    queryKey: 'pa-support-for-pms',
    queryFn: () => DataSourcesApi.getAccessibleIntegrations(),
    select: ({ SyncApps }) => {
      const supportedPMSes = ['dentrix', 'eaglesoft', 'opendental'];
      const nonSupportedPMSes = ['dentrixascend', 'dentrixenterprise'];
      return SyncApps?.some(
        ({ PracticeManagementSystem }) =>
          supportedPMSes.some((pms) => PracticeManagementSystem.toLowerCase().includes(pms.toLowerCase())) &&
          !nonSupportedPMSes.some((pms) => PracticeManagementSystem.toLowerCase().includes(pms.toLowerCase()))
      );
    },
  });

  const isPAEligibleAccount = useMemo(() => {
    if (isCFActive) {
      // For non self upgraded locations (sale done by the sales team), use CF status
      return true;
    }

    if (
      isLoadingSelfUpgradeEligibility ||
      (selfUpgradeEligibility?.trialStatus === TrialStatus.TRIAL_STATUS_UNSPECIFIED &&
        selfUpgradeEligibility?.subscriptionStatus === SubscriptionStatusForSelfUpgrade.SUBSCRIPTION_STATUS_UNSPECIFIED)
    ) {
      return false;
    }

    return isSupportedPMS;
  }, [isCFActive, isLoadingSelfUpgradeEligibility, selfUpgradeEligibility, isSupportedPMS]);

  return {
    isPAEligibleAccount,
    isLoading: isLoadingSelfUpgradeEligibility,
    isFreeTrialEligible: selfUpgradeEligibility?.trialStatus === TrialStatus.TRIAL_ELIGIBLE,
  };
};
