import { Dispatch, SetStateAction, useState } from 'react';
import { useFormField } from '@frontend/design-system';
import { QuickFillConfigOption } from '../../types';
import { calculateRange } from '../../utils';

export type QuickFillButtonFilter = { dateRange?: { start?: string; end?: string } };
export type OnDateFilterSelect = (range: string[]) => void;
export type SetCustomDown = Dispatch<SetStateAction<boolean>>;
export type DateRangeFieldProps = ReturnType<typeof useFormField<'dateRange'>>;
export type DropdownFieldProps = ReturnType<typeof useFormField<'dropdown'>>;

export interface UseDateFiltersOptions {
  filter: QuickFillButtonFilter;
  onSelect: OnDateFilterSelect;
  dateRangeProps: DateRangeFieldProps;
}

export const useDateFilters = ({ filter, onSelect, dateRangeProps }: UseDateFiltersOptions) => {
  const { dateRange } = filter;
  const [customDown, setCustomDown] = useState(false);

  const isSelected = (option: QuickFillConfigOption) => {
    const [optionStart, optionEnd] = calculateRange(option);
    const [selStart, selEnd] = dateRangeProps.value;
    if (!dateRange || !dateRange.start) {
      return option.period === 'all';
    } else if (option.period === 'custom') {
      return selStart === dateRange?.start && selEnd === dateRange?.end ? 'selected' : '';
    } else {
      return optionStart === dateRange?.start && optionEnd === dateRange?.end;
    }
  };

  const handleClick = (option: QuickFillConfigOption) => {
    const [optionStart, optionEnd] = calculateRange(option);
    if (option.period === 'custom') {
      setCustomDown(!customDown);
      return;
    }
    if (dateRange?.start === optionStart && dateRange?.end === optionEnd) {
      onSelect([]);
      return;
    }
    onSelect([optionStart, optionEnd]);
  };

  const getTitle = (option: QuickFillConfigOption) => {
    const [optionStart, optionEnd] = calculateRange(option);
    if (optionStart === optionEnd) {
      return optionStart;
    }
    return `${optionStart} - ${optionEnd}`;
  };

  return { handleClick, isSelected, getTitle, customDown, setCustomDown };
};
