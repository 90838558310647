import { useCallback } from 'react';
import { PersonHelpers } from '@frontend/api-person';
import { useIncomingCallNotification } from '@frontend/notifications';
import { CallPopInterface, useCallPopStateSync } from '@frontend/pop';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { GetWebsocketEventHandler, useWebsocketEventSubscription } from '@frontend/websocket';
import { useCallQueueMetricsStore } from './components/call-queue-stats/use-metrics-store';

type Props = { enabled: boolean };
export const useWebsocketDeskPhoneEventSubscription = ({ enabled }: Props) => {
  const { create } = useIncomingCallNotification();
  const { notifications, addNotification } = useCallPopStateSync();

  const handlerV2 = useCallback<GetWebsocketEventHandler<'PhoneSystemEventsV2'>>(
    (payload) => {
      if (!enabled) {
        return;
      }
      const event = payload.params.event;
      switch (event) {
        case 'inbound_call': {
          addNotification({
            id: payload.params.channel_id,
            timestamp: Date.now(),
            payload: {
              type: 'default',
              callerContext: payload.params.caller_context,
              recipientLocationName: payload.params.recipient_location_name,
              headOfHousehold: payload.params.call_pop_head_of_household,
              contacts: payload.params.contact_matches
                ? payload.params.contact_matches.map((contact) => ({
                    personId: contact.person_id,
                    matchedLocationId: contact.weave_locations_matched[0].location_id,
                    callerName: PersonHelpers.getFullName({
                      FirstName: contact.first_name,
                      LastName: contact.last_name,
                    }),
                    callerNumber: payload.params.caller_id_number,
                    recipientLocationName: payload.params.recipient_location_name,
                    gender: contact.gender,
                    birthdate: contact.birthdate.seconds,
                    source: contact.data_source_name,
                    patientId: contact.patient_id,
                    householdId: contact.household_id,
                  }))
                : [
                    {
                      personId: '',
                      patientId: '',
                      householdId: '',
                      // An empty caller name will show "Unknown Caller" in the call pop
                      callerName: '',
                      callerNumber: payload.params.caller_id_number,
                      recipientLocationName: payload.params.recipient_location_name,
                      gender: '',
                      birthdate: 0,
                      matchedLocationId: payload.params.recipient_location_id,
                      source: '',
                    },
                  ],
            },
          });
          return;
        }
        case 'outbound_call':
        case 'answer_call':
        case 'hangup_call':
          // Remove the call pop for the call that just ended
          CallPopInterface.dismiss(payload.params.channel_id);

          create({
            id: payload.id,
            location: payload.params.recipient_location_name,
            timestamp: new Date().toDateString(),
            type: 'missed-call',
            payload: {
              callerName: payload.params.caller_id_name,
              callerNumber: payload.params.caller_id_number,
              recipientLocationName: payload.params.recipient_location_name,
            },
            state: {
              paused: true, //initially paused. timer will begin when call ends
              timeout: 0,
              status: 'unread',
            },
          } satisfies GetWeavePopNotificationByType<'missed-call'>);
          return;
        default: {
          const _exhaustive: never = event;
          return _exhaustive;
        }
      }
    },
    [notifications, addNotification, enabled]
  );

  useWebsocketEventSubscription('PhoneSystemEventsV2', handlerV2);
};

export const useWebsocketCallQueueEventSubscription = () => {
  const { metrics, setMetrics } = useCallQueueMetricsStore();
  useWebsocketEventSubscription('PhoneCallQueueEvents', (payload) => {
    const event = payload.params;

    setMetrics({
      [event.queue_id]: {
        callCount: event.call_count,
        averageWaitTimeInSeconds: event.average_wait_time_in_seconds,
        waitTimesInSeconds: event.wait_times_in_seconds,
        name: metrics?.[event.queue_id]?.name ?? '',
        locationIds: metrics?.[event.queue_id]?.locationIds ?? [],
      },
    });
  });
};
