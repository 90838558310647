import { FC } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { ModalControlModalProps, ModalControlResponse, useModalControl } from '@frontend/design-system';
import { CallIntelUpgradeDetailsModal } from '../components/feature-upgrade-details-modal/call-intel/CallIntelUpgradeDetailsModal';
import { FormsUpgradeDetailsModal } from '../components/feature-upgrade-details-modal/forms/FormsUpgradeDetailsModal';
import { WivUpgradeDetailsModal } from '../components/feature-upgrade-details-modal/insurance-verification/WivUpgradeDetailsModal';
import { PracticeAnalyticsUpgradeDetailsModal } from '../components/feature-upgrade-details-modal/practice-analytics/PracticeAnalyticsUpgradeDetailsModal';
import { FeatureUpgradeModalComponentProps } from '../types';

interface UseFeatureUpgradeDetailsModalResults {
  FeatureUpgradeDetailsModal?: FC<FeatureUpgradeModalComponentProps>;
  featureUpgradeDetailsModalControls: ModalControlResponse;
}

const FeatureUpgradeModalMapping: Partial<Record<Feature, FC<FeatureUpgradeModalComponentProps>>> = {
  [Feature.FORMS]: FormsUpgradeDetailsModal,
  [Feature.WEAVE_VERIFY]: WivUpgradeDetailsModal,
  [Feature.CALL_INTELLIGENCE]: CallIntelUpgradeDetailsModal,
  [Feature.PRACTICE_ANALYTICS]: PracticeAnalyticsUpgradeDetailsModal,
};

interface UseFeatureUpgradeDetailsModalProps {
  feature: Feature;
  onClose?: ModalControlModalProps['onClose'];
}
export const useFeatureUpgradeDetailsModal = ({
  feature,
  onClose,
}: UseFeatureUpgradeDetailsModalProps): UseFeatureUpgradeDetailsModalResults => {
  const modalControls = useModalControl({ onClose });
  const FeatureUpgradeDetailsModal = FeatureUpgradeModalMapping[feature];
  return {
    FeatureUpgradeDetailsModal,
    featureUpgradeDetailsModalControls: modalControls,
  };
};
