import { http } from '@frontend/fetch';
import { baseUrl } from '../baseurl';
import {
  NormalizedSubmissionDetailResponse,
  PDFRequestParams,
  PDFResponse,
  PendingFormDetails,
  SubmissionDetailsResponse,
  UpdateArchiveStatusParams,
  UpdateIsReadFlagRequest,
  UpdateIsReadFlagResponse,
} from './types';

export async function fetchSubmission(
  submissionId: string,
  company_id: string
): Promise<NormalizedSubmissionDetailResponse> {
  const response = await http.get<SubmissionDetailsResponse>(`${baseUrl}/submissions/${submissionId}`, {
    params: { company_id },
  });

  const submissionData = response;
  const normalizedSubmissionData: NormalizedSubmissionDetailResponse = {
    ...submissionData,
    data: [],
  };

  if (submissionData.data) {
    submissionData.data.forEach((formDetail) => {
      normalizedSubmissionData.data.push({
        ...formDetail,
        submissionData: formDetail.formSubmittedAt
          ? formDetail.submissionData
          : JSON.parse((formDetail as PendingFormDetails).submissionData.form.jsonData),
      });
    });
  }

  return normalizedSubmissionData;
}

export async function fetchSubmissionPDF({ submissionId, formId, companyId }: PDFRequestParams) {
  const params: Record<string, string> = {
    submission_id: submissionId,
    company_id: companyId,
  };

  if (formId) {
    params['form_id'] = formId;
  }

  const response = await http.get<PDFResponse>(`${baseUrl}/pdf-auth`, { params });
  return response.file;
}

export async function updateArchiveStatus({ submissionIds, archive }: UpdateArchiveStatusParams) {
  return await http.patch<Record<string, never>>(`${baseUrl}/submissions-archive-status`, {
    submissionIds,
    isArchive: archive,
  });
}

/**
 *
 * @deprecated Use `useUpdateSubmissionIsRead` instead
 */
export const updateIsReadFlag = (request: UpdateIsReadFlagRequest, locationId: string) =>
  http.patch<UpdateIsReadFlagResponse>(`${baseUrl}/submissions/update-is-read`, request, {
    headers: { 'Location-Id': locationId },
  });
