import { FC, useState, useCallback } from 'react';
import { FormsWritebacks } from '@frontend/api';
import {
  useDigitalFormsLocationsContext,
  useDigitalFormsWritebackSettingsContext,
} from '@frontend/digital-forms-scope';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useBreakpoint } from '@frontend/responsiveness';
import {
  ButtonBar,
  Chip,
  ConfirmationModal,
  ContentLoader,
  Heading,
  IconButton,
  Modal,
  ModalControlModalProps,
  PrimaryButton,
  SecondaryButton,
  SpinningLoader,
  Text,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { Token } from '../../../../shared/components';
import {
  manageAllButtonBarStyle,
  manageAllHeaderStyle,
  settingsSectionsStyle,
  spinnerStyle,
  writebackCardSectionStyle,
} from './writeback.style';

interface ManageAllProps {
  modalProps: ModalControlModalProps;
  closeModal: () => void;
  openModal: () => void;
}

const { WRITEBACK_SETTINGS } = FormsWritebacks.Constants;

const defaultSetting = FormsWritebacks.Types.SettingsValue.Auto;

enum SettingsNameMapper {
  'Create Person' = 'createPerson',
  'Update Person' = 'updatePerson',
  'Upload Document' = 'uploadDocument',
}

const settingsOrder: FormsWritebacks.Types.SettingsType[] = ['createPerson', 'updatePerson', 'uploadDocument'];

export const ManageAllWirtebackPreferences: FC<ManageAllProps> = ({ modalProps, closeModal, openModal }) => {
  const alert = useAlert();
  const { t } = useTranslation('forms', { keyPrefix: 'WRITEBACK_SETTINGS' });
  const { validFormsLocations } = useDigitalFormsLocationsContext();
  const breakpoints = useBreakpoint();

  const {
    isLoadingWritebackSettings,
    isBulkUpdatingWritebackSettings,
    updateAllWritebackSettings,
    getWritebackSettings,
  } = useDigitalFormsWritebackSettingsContext();

  const [settingsValue, setSettingsValue] = useState<
    Record<FormsWritebacks.Types.SettingsType, FormsWritebacks.Types.WritebackSettingValue>
  >({
    createPerson: defaultSetting,
    updatePerson: defaultSetting,
    uploadDocument: defaultSetting,
  });

  const {
    modalProps: confirmationModalProps,
    openModal: openConfirmationModal,
    closeModal: closeConfirmationModal,
  } = useModalControl();

  const translations: Record<string, string> = {
    CUSTOM_NAME_FOR_CREATE: t('CUSTOM_NAME_FOR_CREATE'),
    CUSTOM_DESCRIPTION_FOR_CREATE: t('CUSTOM_DESCRIPTION_FOR_CREATE'),
    CUSTOM_NAME_FOR_UPDATE: t('CUSTOM_NAME_FOR_UPDATE'),
    CUSTOM_DESCRIPTION_FOR_UPDATE: t('CUSTOM_DESCRIPTION_FOR_UPDATE'),
    CUSTOM_NOTE_FOR_UPDATE: t('CUSTOM_NOTE_FOR_UPDATE'),
    CUSTOM_NAME_FOR_UPLOAD: t('CUSTOM_NAME_FOR_UPLOAD'),
    CUSTOM_DESCRIPTION_FOR_UPLOAD: t('CUSTOM_DESCRIPTION_FOR_UPLOAD'),
  };

  const settingChangeHandler = useCallback(
    (setting: FormsWritebacks.Types.SettingsType, value: FormsWritebacks.Types.WritebackSettingValue) => {
      setSettingsValue((prev) => ({ ...prev, [setting]: value }));
    },
    []
  );

  const saveHandler = useCallback(() => {
    closeModal();
    openConfirmationModal();
  }, []);

  const cancelConfirmationHandler = useCallback(() => {
    closeConfirmationModal();
    openModal();
  }, []);

  const confirmUpdateSettingsHandler = useCallback(async () => {
    const updatePayload = validFormsLocations.map((locationId) => {
      const writebackSettings = getWritebackSettings(locationId);

      return {
        company_id: locationId,
        settings: writebackSettings.map((setting) => {
          return {
            setting_name: setting.settingName,
            setting_id: setting.settingId,
            setting_value: settingsValue[SettingsNameMapper[setting.settingName]],
          };
        }),
      };
    });

    try {
      await updateAllWritebackSettings(updatePayload);
      alert.success(t('BULK_UPDATE_SUCCESS'));
    } catch {
      alert.error(t('BULK_UPDATE_ERROR'));
    }
    closeConfirmationModal();
  }, [...validFormsLocations, settingsValue]);

  function getTranslation(key: string) {
    return translations[key] ?? '';
  }

  if (isLoadingWritebackSettings) {
    return (
      <Modal
        {...modalProps}
        minWidth={breakpoints === 'xsmall' ? window.innerWidth - 40 : 400}
        maxWidth={breakpoints === 'xsmall' ? window.innerWidth - 40 : 800}
        disableCloseOnEscape
        disableCloseOnOverlayClick
      >
        <div css={spinnerStyle}>
          <SpinningLoader />
        </div>
      </Modal>
    );
  }

  return (
    <>
      <Modal
        {...modalProps}
        minWidth={breakpoints === 'xsmall' ? window.innerWidth - 40 : 400}
        maxWidth={breakpoints === 'xsmall' ? window.innerWidth - 40 : 800}
        disableCloseOnEscape
        disableCloseOnOverlayClick
      >
        <section css={settingsSectionsStyle(false, true)}>
          <header>
            <section css={manageAllHeaderStyle}>
              <Heading level={breakpoints === 'xsmall' ? 2 : 1}>{t('TITLE')} </Heading>
              {breakpoints !== 'xsmall' && (
                <Chip.MultiChip>
                  {validFormsLocations.length} {t('LOCATIONS')}
                </Chip.MultiChip>
              )}

              <IconButton label='' className='close-btn' onClick={closeModal}>
                <Icon name='x' />
              </IconButton>
            </section>
            {breakpoints === 'xsmall' && (
              <Chip.MultiChip>
                {validFormsLocations.length} {t('LOCATIONS')}
              </Chip.MultiChip>
            )}
            <Text>{t('BULK_SETTINGS_UPDATE_MESSAGE')}</Text>
          </header>

          {settingsOrder.map((setting) => {
            const { customDescription, customName } = WRITEBACK_SETTINGS[setting];

            return (
              <section key={customName} css={writebackCardSectionStyle}>
                <div className='main-content'>
                  <div>
                    <Heading level={breakpoints === 'xsmall' ? 3 : 2}>{getTranslation(customName)} </Heading>
                    <Text color='subdued' size='medium'>
                      {getTranslation(customDescription)}
                    </Text>
                  </div>
                  <div className='writeback-controls'>
                    <Token
                      name={t('SETTING_VALUE_OFF')}
                      active={settingsValue[setting] === FormsWritebacks.Types.SettingsValue.Off}
                      onClick={() => settingChangeHandler(setting, FormsWritebacks.Types.SettingsValue.Off)}
                    />
                    <Token
                      name={t('SETTING_VALUE_MANUAL')}
                      active={settingsValue[setting] === FormsWritebacks.Types.SettingsValue.Manual}
                      onClick={() => settingChangeHandler(setting, FormsWritebacks.Types.SettingsValue.Manual)}
                    />
                    <Token
                      name={t('SETTING_VALUE_AUTO')}
                      active={settingsValue[setting] === FormsWritebacks.Types.SettingsValue.Auto}
                      onClick={() => settingChangeHandler(setting, FormsWritebacks.Types.SettingsValue.Auto)}
                    />
                  </div>
                </div>
              </section>
            );
          })}
          <ButtonBar css={manageAllButtonBarStyle}>
            <SecondaryButton size='tiny'>{t('CANCEL_CHANGE')}</SecondaryButton>
            <PrimaryButton size='tiny' onClick={saveHandler}>
              {t('BULK_UPDATE_CONFIRM')}
            </PrimaryButton>
          </ButtonBar>

          <ContentLoader show={isBulkUpdatingWritebackSettings} message={`${t('UPDATING')} . . .`} />
        </section>
      </Modal>

      <ConfirmationModal
        {...confirmationModalProps}
        destructive
        title={t('BULK_UPDATE_CONFIRMATION_TITLE')}
        message={t('BULK_UPDATE_CONFIRMATION_MESSAGE')}
        confirmLabel={t('CONFIRM_CHANGE')}
        onConfirm={confirmUpdateSettingsHandler}
        onCancel={cancelConfirmationHandler}
      />
    </>
  );
};
