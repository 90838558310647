import { css } from '@emotion/react';
import {
  DepartmentPhonesUtils,
  DeptPhoneNumberApi,
  DeptPhoneNumberTypes,
} from '@frontend/api-department-phone-numbers';
import { DepartmentsTypes } from '@frontend/api-departments';
import {
  ButtonBar,
  CheckboxField,
  DropdownField,
  FormRow,
  Modal,
  phone,
  PrimaryButton,
  SecondaryButton,
  useForm,
  useModalLoadingState,
  useAlert,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { queryKeys } from '../../../query-keys';
import { useAppScopeStore } from '@frontend/scope';

type Props = {
  availablePhoneNumberIds: string[];
  department: DepartmentsTypes.DepartmentModel;
  hasExistingDepartmentNumbers: boolean;
  isMainline: boolean | undefined;
  onClose: () => void;
  phoneNumbers: DeptPhoneNumberTypes.DepartmentPhoneNumberType[];
};

/**
 * Assign a new number to the department
 * In case of mainline, the first assigned number will always be made default Voice/SMS number of the department.
 * In case of non-mainline -
 *  1. if you choose voice-enabled number, it will always be made default Voice number of the department.
 *  2. No compulsion on making any number default SMS number of the department.
 */
export const AssignNumberModal = ({
  availablePhoneNumberIds,
  department,
  hasExistingDepartmentNumbers,
  isMainline = false,
  onClose,
  phoneNumbers,
}: Props) => {
  const alert = useAlert();
  const { singleLocationId: locationId } = useAppScopeStore();
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  const { setLoading: setUpdatingDepartment } = useModalLoadingState();
  const queryClient = useQueryClient();
  const departmentId = department.id ?? '';

  const { mutate: addNewPhoneNumber } = useMutation(
    (phoneData: DeptPhoneNumberTypes.AssignPhoneNumberTypes['input']) => DeptPhoneNumberApi.assignNumber(phoneData),
    {
      onSuccess: () => {
        setUpdatingDepartment(false);
        queryClient.invalidateQueries([locationId, ...queryKeys.listDepartmentPhoneNumbers(departmentId)], {
          exact: true,
        });
        onClose();
      },
      onError: () => {
        alert.error(t('Could not assign phone number to the department'));
      },
    }
  );

  const { availableSMSOnlyNumbers, availableVoiceOnlyNumbers, availableVoiceSMSNumbers } = useMemo(() => {
    const availableNumbers = phoneNumbers.filter((number) => !number.isAssigned);
    return DepartmentPhonesUtils.splitNumbersByCapability(availableNumbers);
  }, [phoneNumbers]);

  const hasExistingSMSDefaultNumber = useMemo(() => {
    return phoneNumbers.some(
      (item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => item.isAssigned && item.smsConfig.isDefaultSms
    );
  }, [phoneNumbers]);

  const hasExistingVoiceDefaultNumber = useMemo(() => {
    return phoneNumbers.some(
      (item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => item.isAssigned && item.voiceConfig.isDefaultVoice
    );
  }, [phoneNumbers]);

  const { formProps, values, getFieldProps } = useForm({
    fields: {
      ID: { type: 'dropdown', required: true },
      defaultOutboundVoice: {
        type: 'checkbox',
        required: false,
        value: !hasExistingVoiceDefaultNumber,
      },
      defaultOutboundSMS: {
        type: 'checkbox',
        required: false,
        value: !hasExistingSMSDefaultNumber,
      },
    },
    onSubmit: async (values) => {
      const phoneNumber = phoneNumbers.find((num) => num.id === values.ID);
      if (!phoneNumber) {
        return;
      }
      setUpdatingDepartment(true);
      addNewPhoneNumber({
        departmentId: department.id,
        phoneNumbers: [
          {
            ...phoneNumber,
            voiceConfig: { ...phoneNumber.voiceConfig, isDefaultVoice: values.defaultOutboundVoice || false },
            smsConfig: { ...phoneNumber.smsConfig, isDefaultSms: values.defaultOutboundSMS || false },
          },
        ],
      });
    },
  });

  const phoneProps = getFieldProps('ID');
  const smsCheckfieldProps = getFieldProps('defaultOutboundSMS');
  const voiceCheckfieldProps = getFieldProps('defaultOutboundVoice');

  const canModifySMSCheckfield = () => {
    if (!isMainline) {
      return (
        availableVoiceSMSNumbers.some((item) => item.id === values?.ID) ||
        availableSMSOnlyNumbers.some((item) => item.id === values?.ID)
      );
    }
    return (
      hasExistingSMSDefaultNumber &&
      (availableVoiceSMSNumbers.some((item) => item.id === values?.ID) ||
        availableSMSOnlyNumbers.some((item) => item.id === values?.ID))
    );
  };

  const canModifyVoiceCheckfield = () =>
    hasExistingVoiceDefaultNumber &&
    [...availableVoiceSMSNumbers, ...availableVoiceOnlyNumbers].some((item) => item.id === values?.ID);

  const canShowVoiceOnlyOption = (phoneNumberID: string) => {
    if (isMainline) {
      return hasExistingDepartmentNumbers && availablePhoneNumberIds.includes(phoneNumberID);
    }
    return availablePhoneNumberIds.includes(phoneNumberID);
  };

  const canShowSMSOnlyOption = (phoneNumberID: string) => {
    if (isMainline) {
      return hasExistingDepartmentNumbers && availablePhoneNumberIds.includes(phoneNumberID);
    }
    return availablePhoneNumberIds.includes(phoneNumberID);
  };

  const handlePhoneNumberChange = (numberObj: any) => {
    phoneProps.onChange(numberObj);
    if (!hasExistingVoiceDefaultNumber) {
      voiceCheckfieldProps.onChange({
        name: 'defaultOutboundVoice',
        value: [...availableVoiceSMSNumbers, ...availableVoiceOnlyNumbers].some((item) => item.id === numberObj.value),
      });
    }
    if (!hasExistingSMSDefaultNumber) {
      smsCheckfieldProps.onChange({
        name: 'defaultOutboundSMS',
        value: [...availableVoiceSMSNumbers, ...availableSMSOnlyNumbers].some((item) => item.id === numberObj.value),
      });
    }
  };

  const canAssignNumber = () => {
    if (isMainline) {
      return hasExistingDepartmentNumbers || (!!values.defaultOutboundVoice && !!values.defaultOutboundSMS);
    }
    return true;
  };

  return (
    <>
      <Modal.Header>{`Assign Number to ${department.name}`}</Modal.Header>
      <Modal.Body
        css={css`
          margin-top: ${theme.spacing(2)};
        `}
      >
        <form {...formProps}>
          <FormRow
            css={css`
              padding-top: ${theme.spacing(1)};
            `}
          >
            <DropdownField label={t('Phone Number')} {...getFieldProps('ID')} onChange={handlePhoneNumberChange}>
              {availableVoiceSMSNumbers.map((phoneNumber) => (
                <DropdownField.Option
                  key={phoneNumber.id}
                  value={phoneNumber.id}
                  searchValue={`${phoneNumber.name} ${phoneNumber.number}`}
                >
                  {phone(phoneNumber.number)}
                </DropdownField.Option>
              ))}
              {availableVoiceOnlyNumbers.map((phoneNumber) => (
                <DropdownField.Option
                  key={phoneNumber.id}
                  value={phoneNumber.id}
                  searchValue={`${phoneNumber.name} ${phoneNumber.number}`}
                  disabled={!canShowVoiceOnlyOption(phoneNumber.id)}
                >
                  {phone(phoneNumber.number)} ({t('Voice only')})
                </DropdownField.Option>
              ))}
              {availableSMSOnlyNumbers.map((phoneNumber) => (
                <DropdownField.Option
                  key={phoneNumber.id}
                  value={phoneNumber.id}
                  searchValue={`${phoneNumber.name} ${phoneNumber.number}`}
                  disabled={!canShowSMSOnlyOption(phoneNumber.id)}
                >
                  {phone(phoneNumber.number)} ({t('SMS only')})
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
          <FormRow
            css={css`
              margin-bottom: ${theme.spacing(2)};
              label {
                opacity: ${!canModifyVoiceCheckfield() ? '0.3' : '1'};
              }
            `}
            data-test-id='voice-form'
          >
            <CheckboxField
              {...getFieldProps('defaultOutboundVoice')}
              label={t('Make this number the default voice outbound number')}
              disabled={!canModifyVoiceCheckfield()}
            />
          </FormRow>
          <FormRow
            css={css`
              margin-bottom: ${theme.spacing(4)};
              label {
                opacity: ${!canModifySMSCheckfield() ? '0.3' : '1'};
              }
            `}
            data-test-id='sms-form'
          >
            <CheckboxField
              {...getFieldProps('defaultOutboundSMS')}
              label={t('Make this number the default SMS outbound number')}
              disabled={!canModifySMSCheckfield()}
            />
          </FormRow>
          <ButtonBar
            css={css`
              padding: 0;
            `}
          >
            <SecondaryButton type='button' onClick={() => onClose()}>
              {t('Cancel')}
            </SecondaryButton>
            <PrimaryButton
              type='submit'
              disabled={!canAssignNumber()}
              trackingId='phone-portal-mainLine-assignNumber-btn'
            >
              {t('Assign Number')}
            </PrimaryButton>
          </ButtonBar>
        </form>
      </Modal.Body>
    </>
  );
};
