import { forwardRef } from 'react';
import { Direction_Enum, Status_Enum } from '@weave/schema-gen-ts/dist/schemas/fax/shared/v1/enums.pb';
import { FaxHooks } from '@frontend/api-fax';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { SchemaFaxService, SchemaIO } from '@frontend/schema';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { FaxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  ActionButton,
  PopoverMenu,
  PopoverMenuItem,
  PopoverMenuItemProps,
  contextFactory,
  Text,
  usePopoverMenu,
} from '@frontend/design-system';
import { useFaxActions, useFaxNavigation } from '../../hooks';
import { useSelectedFaxShallowStore } from '../../stores';

type ArchiveAction = {
  type: 'Archive';
  params: SchemaIO<(typeof SchemaFaxService)['UpdateFaxArchiveStatus']>['input'];
};

type TagAction = {
  type: 'Tag';
  params: {
    open: () => void;
  };
};

type BlockSenderAction = {
  type: 'Block';
  params: SchemaIO<(typeof SchemaFaxService)['BlockContact']>['input'];
};

type UnblockSenderAction = {
  type: 'Unblock';
  params: SchemaIO<(typeof SchemaFaxService)['UnblockContact']>['input'];
};

type PreviewFaxAction = {
  type: 'Preview';
  params: { faxId: string; locationId: string };
};

type ForwardAction = {
  type: 'Forward';
  params: {
    open: () => void;
  };
};

type MarkUnreadAction = {
  type: 'MarkUnread';
  params: SchemaIO<(typeof SchemaFaxService)['UpdateFaxes']>['input'];
};

type DownloadFaxAction = {
  type: 'Download';
  params: { faxId: string; locationId: string; direction: Direction_Enum; status: Status_Enum };
};

type DefinedContactActions =
  | BlockSenderAction
  | UnblockSenderAction
  | PreviewFaxAction
  | ForwardAction
  | MarkUnreadAction
  | DownloadFaxAction
  | ArchiveAction
  | TagAction;

const [MenuContext, useMenuContext] = contextFactory<{ close: () => void }>();

type GetThreadHeaderActionsProps = {
  faxId: string;
  tagSelectPopoverOpen: () => void;
  openForwardModal: () => void;
};

export const getThreadHeaderActions = ({
  faxId,
  tagSelectPopoverOpen,
  openForwardModal,
}: GetThreadHeaderActionsProps) => {
  const { selectedFax } = useSelectedFaxShallowStore('selectedFax');
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const faxUpdatedFeaturesFlag = flagValues['fax-2-0-features'];
  const faxArchiveFeatureFlag = flagValues['fax-2-0-archive'];
  const faxBlockFeatureFlag = flagValues['fax-2-0-block'];

  const outer: DefinedContactActions[] = [
    ...(faxArchiveFeatureFlag
      ? [
          selectedFax.direction === Direction_Enum.INBOUND
            ? ({
                type: 'Archive',
                params: {
                  faxIds: [faxId],
                  locationId: selectedFax.locationId,
                  isArchived: !selectedFax.isArchived,
                },
              } satisfies ArchiveAction)
            : undefined,
        ]
      : []),
    ...(faxUpdatedFeaturesFlag
      ? [
          {
            type: 'Tag',
            params: {
              open: tagSelectPopoverOpen,
            },
          },
        ]
      : []),
  ].filter(Boolean) as DefinedContactActions[];

  const inner: DefinedContactActions[] = [
    {
      type: 'Preview',
      params: {
        faxId,
        locationId: selectedFax.locationId,
      },
    },
    {
      type: 'Forward',
      params: {
        open: openForwardModal,
      },
    },
    selectedFax.direction === Direction_Enum.INBOUND
      ? ({
          type: 'MarkUnread',
          params: {
            hasBeenRead: false,
            locationId: selectedFax.locationId,
            faxIds: [faxId],
          },
        } satisfies MarkUnreadAction)
      : undefined,
    {
      type: 'Download',
      params: {
        faxId,
        locationId: selectedFax.locationId,
        direction: selectedFax.direction,
        status: selectedFax.status,
      },
    },
    ...(faxBlockFeatureFlag
      ? [
          ...(selectedFax.isBlocked
            ? [
                {
                  type: 'Unblock',
                  params: {
                    locationId: selectedFax.locationId,
                    faxNumber: selectedFax.contactPhone,
                  },
                } satisfies UnblockSenderAction,
              ]
            : [
                {
                  type: 'Block',
                  params: {
                    locationId: selectedFax.locationId,
                    faxNumber: selectedFax.contactPhone,
                  },
                } satisfies BlockSenderAction,
              ]),
        ]
      : []),
  ].filter(Boolean) as DefinedContactActions[];

  return { inner, outer };
};

const ArchiveActionButton = (props: ArchiveAction['params']) => {
  const { t } = useTranslation('fax');
  const invalidateInboxList = FaxHooks.useInvalidateFaxInboxList();
  const invalidateFaxCount = FaxHooks.useInvalidateFaxCount();
  const { closeThread } = useFaxNavigation();
  const { archiveFax } = useFaxActions();

  return (
    <ActionButton
      onClick={async () => {
        archiveFax(props.faxIds, props.locationId!, props.isArchived);
        invalidateInboxList();
        invalidateFaxCount();
        closeThread();
      }}
      trackingId={`${FaxPrefixes.Thread}-archive-action-button`}
      label={props.isArchived ? t('Archive') : t('Unarchive')}
    >
      {props.isArchived ? (
        <Icon name='archive' size={20} css={{ color: theme.colors.neutral70 }} />
      ) : (
        <Icon name='unarchive' size={20} css={{ color: theme.colors.neutral70 }} />
      )}
    </ActionButton>
  );
};

const TagActionButton = (props: TagAction['params']) => {
  return (
    <ActionButton onClick={props.open} trackingId={`${FaxPrefixes.Thread}-tag-action-button`} label={'Tag'}>
      <Icon name='label' size={20} css={{ color: theme.colors.neutral70 }} />
    </ActionButton>
  );
};

const BlockSenderMenuItem = forwardRef<
  HTMLButtonElement,
  { params: BlockSenderAction['params'] } & PopoverMenuItemProps<'button'>
>(({ params, ...rest }, ref) => {
  const { t } = useTranslation('fax');
  const { close } = useMenuContext();
  const invalidateInboxList = FaxHooks.useInvalidateFaxInboxList();
  const invalidateFaxCount = FaxHooks.useInvalidateFaxCount();
  const { closeThread } = useFaxNavigation();
  const { blockFax } = useFaxActions();

  return (
    <PopoverMenuItem
      {...rest}
      ref={ref}
      Icon={() => <Icon name='block' size={16} css={{ color: theme.colors.neutral70 }} />}
      onClick={async () => {
        await blockFax(params.locationId!, params.faxNumber);
        invalidateInboxList();
        invalidateFaxCount();
        closeThread();
        close();
      }}
      trackingId={`${FaxPrefixes.Thread}-block-sender-button`}
    >
      {t('Block Sender')}
    </PopoverMenuItem>
  );
});

const UnblockSenderMenuItem = forwardRef<
  HTMLButtonElement,
  { params: BlockSenderAction['params'] } & PopoverMenuItemProps<'button'>
>(({ params, ...rest }, ref) => {
  const { t } = useTranslation('fax');
  const { close } = useMenuContext();
  const invalidateInboxList = FaxHooks.useInvalidateFaxInboxList();
  const invalidateFaxCount = FaxHooks.useInvalidateFaxCount();
  const { closeThread } = useFaxNavigation();
  const { unblockFax } = useFaxActions();

  return (
    <PopoverMenuItem
      {...rest}
      ref={ref}
      Icon={() => <Icon name='block' size={16} css={{ color: theme.colors.neutral70 }} />}
      onClick={async () => {
        await unblockFax(params.locationId!, params.faxNumber);
        invalidateInboxList();
        invalidateFaxCount();
        closeThread();
        close();
      }}
      trackingId={`${FaxPrefixes.Thread}-unblock-sender-button`}
    >
      {t('Unblock Sender')}
    </PopoverMenuItem>
  );
});

const PreviewFaxMenuItem = forwardRef<
  HTMLButtonElement,
  { params: PreviewFaxAction['params'] } & PopoverMenuItemProps<'button'>
>(({ params, ...rest }, ref) => {
  const { t } = useTranslation('fax');
  const { close } = useMenuContext();
  const { previewFax } = useFaxActions();

  return (
    <PopoverMenuItem
      {...rest}
      ref={ref}
      Icon={() => <Icon name='preview' size={16} css={{ color: theme.colors.neutral70 }} />}
      onClick={() => {
        previewFax(params.faxId, 'fax-pdf', params.locationId, true);
        close();
      }}
      trackingId={`${FaxPrefixes.Thread}-preview-fax-button`}
    >
      {t('Preview / Print')}
    </PopoverMenuItem>
  );
});

const ForwardFaxMenuItem = forwardRef<
  HTMLButtonElement,
  { params: ForwardAction['params'] } & PopoverMenuItemProps<'button'>
>(({ params, ...rest }, ref) => {
  const { t } = useTranslation('fax');

  return (
    <PopoverMenuItem
      {...rest}
      ref={ref}
      Icon={() => <Icon name='forward' size={16} css={{ color: theme.colors.neutral70 }} />}
      onClick={params.open}
      trackingId={`${FaxPrefixes.Thread}-forward-fax-button`}
    >
      {t('Forward')}
    </PopoverMenuItem>
  );
});

const DownloadFaxMenuItem = forwardRef<
  HTMLButtonElement,
  { params: DownloadFaxAction['params'] } & PopoverMenuItemProps<'button'>
>(({ params, ...rest }, ref) => {
  const { t } = useTranslation('fax');
  const { close } = useMenuContext();
  const { downloadFax, downloadFaxDeliveryReport, downloadFaxAndDeliveryReport } = useFaxActions();
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const enableFaxDeliveryReport = flagValues['fax-delivery-report'];

  const downloadPopover = usePopoverMenu<HTMLButtonElement>({
    placement: 'right-start',
  });

  return (
    <>
      {enableFaxDeliveryReport &&
      params.direction === Direction_Enum.OUTBOUND &&
      params.status === Status_Enum.DELIVERED ? (
        <>
          <PopoverMenuItem
            Icon={() => <Icon name='download' size={16} css={{ color: theme.colors.neutral70 }} />}
            {...downloadPopover.getItemProps({
              index: 2,
              disableCloseOnSelect: true,
            })}
            {...downloadPopover.getTriggerProps()}
            trackingId={`${FaxPrefixes.Thread}-download-fax-button`}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              <Text>{t('Download')}</Text>
              <Icon
                name='alt-caret-right-tiny'
                css={{
                  color: theme.colors.neutral50,
                }}
              />
            </div>
          </PopoverMenuItem>
          <PopoverMenu {...downloadPopover.getMenuProps()}>
            <PopoverMenuItem
              onClick={() => {
                downloadFax(params.faxId, `fax-${params.faxId}-pdf`, params.locationId, true);
                close();
              }}
              trackingId={`${FaxPrefixes.Thread}-download-fax-message-button`}
            >
              {t('Fax Message')}
            </PopoverMenuItem>
            <PopoverMenuItem
              onClick={() => {
                downloadFaxDeliveryReport(params.faxId, params.locationId);
                close();
              }}
              trackingId={`${FaxPrefixes.Thread}-download-fax-report-button`}
            >
              {t('Fax Confirmation')}
            </PopoverMenuItem>
            <PopoverMenuItem
              onClick={() => {
                downloadFaxAndDeliveryReport(params.faxId, `fax-${params.faxId}-pdf`, params.locationId, true);
                close();
              }}
              trackingId={`${FaxPrefixes.Thread}-download-fax-message-and-report-button`}
            >
              {t('Fax Message & Confirmation')}
            </PopoverMenuItem>
          </PopoverMenu>
        </>
      ) : (
        <PopoverMenuItem
          {...rest}
          ref={ref}
          Icon={() => <Icon name='download' size={16} css={{ color: theme.colors.neutral70 }} />}
          onClick={() => {
            downloadFax(params.faxId, `fax-${params.faxId}-pdf`, params.locationId, true);
            close();
          }}
          trackingId={`${FaxPrefixes.Thread}-download-fax-button`}
        >
          {t('Download')}
        </PopoverMenuItem>
      )}
    </>
  );
});

const MarkUnreadMenuItem = forwardRef<
  HTMLButtonElement,
  { params: MarkUnreadAction['params'] } & PopoverMenuItemProps<'button'>
>(({ params, ...rest }, ref) => {
  const { t } = useTranslation('fax');
  const { close } = useMenuContext();
  const { closeThread } = useFaxNavigation();
  const { markReadFax } = useFaxActions();
  const invalidateInboxList = FaxHooks.useInvalidateFaxInboxList();
  const invalidateFaxCount = FaxHooks.useInvalidateFaxCount();

  return (
    <PopoverMenuItem
      {...rest}
      ref={ref}
      Icon={() => <Icon name='message-unread' size={16} css={{ color: theme.colors.neutral70 }} />}
      onClick={async () => {
        await markReadFax(!params.hasBeenRead, params.locationId!, params.faxIds);
        invalidateInboxList();
        invalidateFaxCount();
        closeThread();
        close();
      }}
      trackingId={`${FaxPrefixes.Thread}-mark-unread-button`}
    >
      {t('Mark Unread')}
    </PopoverMenuItem>
  );
});

type ContactHeaderActionsProps = {
  actions: { outer: DefinedContactActions[]; inner: DefinedContactActions[] };
};

const generateActionButtons = (actions: DefinedContactActions[]) => {
  return actions.map((action) => {
    switch (action.type) {
      case 'Archive':
        return <ArchiveActionButton key={action.type} {...action.params} />;
      case 'Tag':
        return <TagActionButton key={action.type} {...action.params} />;
      default:
        return null;
    }
  });
};

const generateActionMenuItems = (
  actions: DefinedContactActions[],
  getItemProps: ReturnType<typeof usePopoverMenu>['getItemProps']
) => {
  return actions.map((action, index) => {
    const itemProps = getItemProps({ index });
    switch (action.type) {
      case 'Preview':
        return <PreviewFaxMenuItem key={action.type} params={action.params} {...itemProps} />;
      case 'Forward':
        return <ForwardFaxMenuItem key={action.type} params={action.params} {...itemProps} />;
      case 'MarkUnread':
        return <MarkUnreadMenuItem key={action.type} params={action.params} {...itemProps} />;
      case 'Download':
        return <DownloadFaxMenuItem key={action.type} params={action.params} {...itemProps} />;
      case 'Block':
        return <BlockSenderMenuItem key={action.type} params={action.params} {...itemProps} />;
      case 'Unblock':
        return <UnblockSenderMenuItem key={action.type} params={action.params} {...itemProps} />;
      default:
        return null;
    }
  });
};

export const ThreadHeaderActions = ({ actions }: ContactHeaderActionsProps) => {
  const { getTriggerProps, getMenuProps, getItemProps, close } = usePopoverMenu({ placement: 'bottom' });

  const triggerProps = getTriggerProps();
  const menuProps = getMenuProps();

  return (
    <>
      <div style={{ display: 'flex', gap: theme.spacing(1) }}>
        {generateActionButtons(actions.outer)}
        <ActionButton {...triggerProps} trackingId={`${FaxPrefixes.Thread}-more-action-button`}>
          <Icon name='more' size={20} css={{ color: theme.colors.neutral70 }} />
        </ActionButton>
      </div>
      <MenuContext.Provider value={{ close }}>
        <PopoverMenu {...menuProps}>{generateActionMenuItems(actions.inner, getItemProps)}</PopoverMenu>
      </MenuContext.Provider>
    </>
  );
};
