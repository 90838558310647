import { ReactNode, useState } from 'react';
import { css } from '@emotion/react';
import { CallIntelligenceApi, CallIntelligenceTypes } from '@frontend/api-analytics';
import { getUser, isWeaveUser } from '@frontend/auth-helpers';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  AIIcon,
  EditIcon,
  FeedbackBadIcon,
  FeedbackIcon,
  IconButton,
  Text,
  TextLink,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { trackingIds } from '../../tracking-ids';
import { Feedback, FeedbackModal } from '../feedback-modal';

type Props = {
  call?: CallIntelligenceTypes.Call | null;
  callMetadata: CallIntelligenceTypes.CallMetadata;
  refetchCallMetadata: () => void;
  isDemoAccount?: boolean;
};

type EditRatingProps = {
  isLoading?: boolean;
  onEdit: () => void;
};

type UpdateRatingProps = {
  isLoading?: boolean;
  onNegative: () => void;
  onPositive: () => void;
};

type ChangeRatingProps = {
  isLoading?: boolean;
  children: ReactNode;
  onCancel: () => void;
};

const EditRatingButton = ({ isLoading, onEdit }: EditRatingProps) => {
  const { t } = useTranslation('analytics');

  return (
    <IconButton
      className='icon-button'
      disabled={isLoading}
      label={t('Change your rating')}
      onClick={onEdit}
      showLabelOnHover
      size='xsmall'
      trackingId={trackingIds.callIntel.changeCallAnalysisRating}
    >
      <EditIcon size={16} />
    </IconButton>
  );
};

const UpdateRatingButtons = ({ isLoading, onNegative, onPositive }: UpdateRatingProps) => {
  const { t } = useTranslation('analytics');

  return (
    <div className='feedback-buttons-wrapper'>
      <IconButton
        className='icon-button'
        disabled={isLoading}
        label={t('Yes, accurate')}
        onClick={onPositive}
        size='xsmall'
        trackingId={trackingIds.callIntel.accurateCallAnalysisFeedback}
      >
        <FeedbackIcon size={16} />
      </IconButton>
      <IconButton
        className='icon-button'
        disabled={isLoading}
        label={t('No, not accurate')}
        onClick={onNegative}
        size='xsmall'
        trackingId={trackingIds.callIntel.inaccurateCallAnalysisFeedback}
      >
        <FeedbackBadIcon size={16} />
      </IconButton>
    </div>
  );
};

const PositiveRating = () => {
  const { t } = useTranslation('analytics');

  return (
    <>
      <FeedbackIcon color='success' />
      <Text>
        <Trans t={t}>
          These takeaways have been rated as <strong style={{ color: theme.colors.status.success }}>accurate</strong>
        </Trans>
      </Text>
    </>
  );
};

const NegativeRating = () => {
  const { t } = useTranslation('analytics');

  return (
    <>
      <FeedbackBadIcon color='error' />
      <Text>
        <Trans t={t}>
          These takeaways have been rated as <strong style={{ color: theme.colors.status.critical }}>inaccurate</strong>
        </Trans>
      </Text>
    </>
  );
};

const ChangeRating = ({ children, isLoading, onCancel }: ChangeRatingProps) => {
  const { t } = useTranslation('analytics');

  return (
    <>
      <span style={{ color: theme.colors.secondary.eggplant50 }}>
        <AIIcon />
      </span>
      <Text>{t('Change your rating?')}</Text>
      {children}
      <TextLink disabled={isLoading} onClick={onCancel} trackingId={trackingIds.callIntel.cancelEditingAIRating}>
        {t('Cancel')}
      </TextLink>
    </>
  );
};

const NotRated = () => {
  const { t } = useTranslation('analytics');

  return (
    <>
      <span style={{ color: theme.colors.secondary.eggplant50 }}>
        <AIIcon />
      </span>
      <Text>{t('Are the takeaways of this call accurate?')}</Text>
    </>
  );
};

export const CallTakeawayFeedbackBanner = ({ call, callMetadata, refetchCallMetadata, isDemoAccount }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { modalProps: feedbackModalProps, triggerProps: feedbackModalTriggerProps } = useModalControl();
  const [isEditingRating, setIsEditingRating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [existingRating, setExistingRating] = useState<keyof typeof CallIntelligenceTypes.RatingEnum | undefined>(
    callMetadata?.rating
  );

  if (!call) {
    return null;
  }

  const submitRating = (
    rating: CallIntelligenceTypes.RatingEnum,
    additionalInfo?: Partial<CallIntelligenceTypes.FeedbackRequest>
  ) => {
    if (isWeaveUser()) {
      alert.warning(
        t(
          'This feature is intended solely for Weave customers to provide feedback on AI outputs perceived as wrongly classified. If you are not a Weave customer, we advise you to skip this module.'
        )
      );
      return;
    }

    const userID = getUser()?.userID;

    if (!call || !call.id || !userID) {
      return;
    }

    if (isDemoAccount) {
      handleRatingSubmitSuccess(rating, additionalInfo);
      return;
    }

    setIsLoading(true);

    CallIntelligenceApi.submitFeedback({
      ...(additionalInfo || {}),
      call_id: call.id,
      rating,
      user_id: userID,
    })
      .then(() => handleRatingSubmitSuccess(rating, additionalInfo))
      .catch((e) => {
        alert.error(t('Failed to submit rating. Please try again.'));
        console.error(e);
        setIsLoading(false);
      });
  };

  const handleRatingSubmitSuccess = (
    rating: CallIntelligenceTypes.RatingEnum,
    additionalInfo?: Partial<CallIntelligenceTypes.FeedbackRequest>
  ) => {
    alert.success(t('Rating submitted. Thank you!'));

    isDemoAccount === false && setIsLoading(false);

    setExistingRating(
      rating === CallIntelligenceTypes.RatingEnum.RATING_POSITIVE ? 'RATING_POSITIVE' : 'RATING_NEGATIVE'
    );
    setIsEditingRating(false);

    isDemoAccount === false && refetchCallMetadata();

    if (additionalInfo) {
      feedbackModalProps.onClose();
    } else {
      feedbackModalTriggerProps.onClick();
    }
  };

  const handleEditRating = () => {
    if (isWeaveUser()) {
      alert.warning(
        t(
          'This feature is intended solely for Weave customers to provide feedback on AI outputs perceived as wrongly classified. If you are not a Weave customer, we advise you to skip this module.'
        )
      );
      return;
    }
    setIsEditingRating(true);
  };

  const handlePositiveRating = () => {
    submitRating(CallIntelligenceTypes.RatingEnum.RATING_POSITIVE);
  };

  const handleNegativeRating = () => {
    submitRating(CallIntelligenceTypes.RatingEnum.RATING_NEGATIVE);
  };

  const handleFeedbackSubmit = (feedback: Feedback) => {
    if (!existingRating) {
      return;
    }

    submitRating(CallIntelligenceTypes.RatingEnum[existingRating], {
      comment: feedback.message,
      allow_weave_to_contact: feedback.allowWeaveToContact,
    });
  };

  return (
    <div css={styles}>
      {existingRating && existingRating !== 'RATING_UNKNOWN' ? (
        <>
          {isEditingRating ? (
            <>
              <ChangeRating isLoading={isLoading} onCancel={() => setIsEditingRating(false)}>
                <UpdateRatingButtons
                  isLoading={isLoading}
                  onNegative={handleNegativeRating}
                  onPositive={handlePositiveRating}
                />
              </ChangeRating>
            </>
          ) : (
            <>
              {existingRating === 'RATING_POSITIVE' ? <PositiveRating /> : <NegativeRating />}
              <EditRatingButton isLoading={isLoading} onEdit={handleEditRating} />
            </>
          )}
        </>
      ) : (
        <>
          <NotRated />
          <UpdateRatingButtons
            isLoading={isLoading}
            onNegative={handleNegativeRating}
            onPositive={handlePositiveRating}
          />
        </>
      )}
      <FeedbackModal
        disableCloseOnOverlayClick
        isLoading={isLoading}
        isMessageRequired
        modalProps={feedbackModalProps}
        onSubmit={handleFeedbackSubmit}
        productName='ci-call-analysis-feedback'
        showRatings={false}
        subtitle={
          existingRating === 'RATING_POSITIVE'
            ? t('Please share details about what you found positive or beneficial in the takeaways of this call.')
            : t(
                'Please specify what was inaccurate in the takeaways of this call and provide suggestions for the correct values.'
              )
        }
        title={t('Provide Additional Feedback')}
        trackingIdBase={trackingIds.callIntel.aiAdditionalFeedback}
      />
    </div>
  );
};

const styles = css`
  align-items: center;
  background-color: ${theme.colors.neutral5};
  border-radius: ${theme.borderRadius.medium};
  display: flex;
  gap: ${theme.spacing(1)};
  justify-content: center;
  padding: ${theme.spacing(1)};

  .icon-button {
    &:hover {
      background-color: ${theme.colors.neutral10} !important;
    }
  }

  > p {
    line-height: 1rem;
  }

  .feedback-buttons-wrapper {
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
  }
`;
