import { useCallback } from 'react';
import { DefaultSms } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/phone_number.pb';
import { Direction, Encoding, Status } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { UseMutationOptions, useMutation } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { DepartmentsQueries } from '@frontend/api-departments';
import { SMSSignatureV1 } from '@frontend/api-sms-signature';
import { getUser } from '@frontend/auth-helpers';
import { sanitizePhoneNumber } from '@frontend/phone-numbers';
import { SchemaIO, SchemaSMSSendService } from '@frontend/schema';
import { genUUIDV4 } from '@frontend/string';
import { sentry } from '@frontend/tracking';
import { sendMessageWithId } from '../api';
import { SENDING_STATUS_DETAILS } from '../constants';
import { KnownProgramSlugIds } from '../types';
import { addSignatureToMessage, formatPhoneWithCountryCode } from '../utils';
import { useInvalidateInboxList } from './use-invalidate-inbox-list';
import { useUpdateThread } from './use-update-thread';

type SendIO = SchemaIO<(typeof SchemaSMSSendService)['Send']>;
type SendMessageRequest = Compulsory<SendIO['input'], 'personPhone'> & {
  excludeSignature?: boolean;
};
type SendMessageArgs = {
  user: ReturnType<typeof getUser>;
  departments: DefaultSms[] | undefined;
  signature: string | undefined;
  threadId: string;
  targetSmsId?: string;
} & SendMessageRequest;

const sendMessage = ({
  excludeSignature,
  signature,
  user,
  departments,
  targetSmsId,
  threadId,
  id,
  ...req
}: SendMessageArgs) => {
  if (!user) {
    throw new Error('no user');
  }
  if (!req.personPhone) {
    throw new Error('no person phone');
  }
  if (departments && departments.length > 1 && !req.departmentId) {
    throw new Error('no department id provided');
  }

  const resolvedDepartment =
    departments?.find((dept) => dept.id === req.departmentId) ?? departments?.length === 1 ? departments[0] : undefined;
  const resolvedDepartmentId = resolvedDepartment?.id;
  const locationPhone = resolvedDepartment?.smsNumber?.number
    ? sanitizePhoneNumber(resolvedDepartment.smsNumber.number)
    : undefined;
  const requestObject = {
    createdBy: user?.userID,
    shortenUrls: true,
    id: id || genUUIDV4(),
    departmentId: resolvedDepartmentId,
    locationPhone,
    ...req,
    body: excludeSignature || !signature ? req.body : addSignatureToMessage(req.body ?? '', signature),
  };
  if (locationPhone && sanitizePhoneNumber(locationPhone) === sanitizePhoneNumber(req.personPhone)) {
    sentry.error({
      error: {
        message: 'Attempted to send a message to the same number as the location number',
      },
      topic: 'messages',
      addContext: {
        name: 'Request',
        context: { requestObject, departments },
      },
    });
  }
  return sendMessageWithId(requestObject);
};

type UseSendMessageProps = {
  locationId: string;
};
const useSendMessage = ({ locationId }: UseSendMessageProps) => {
  const user = getUser();
  const { data: departments } = DepartmentsQueries.useListDefaultSMSQuery({ locationId });
  const { data: signature = '' } = SMSSignatureV1.Queries.useGetSignatureQuery({
    request: {
      groupId: locationId,
      userId: user?.userID ?? '',
    },
    options: {
      enabled: !!user?.userID,
      select: (data) => {
        return data.signature.signature;
      },
    },
  });
  const { upsert, shallowUpdate, remove } = useUpdateThread();
  const invalidateInboxList = useInvalidateInboxList();

  return useCallback(
    (options: Omit<Parameters<typeof sendMessage>[0], 'locationId' | 'user' | 'departments' | 'signature'>) => {
      const resolvedDepartment =
        departments?.smsNumbers?.find((dept) => dept.id === options.departmentId) ??
        departments?.smsNumbers?.length === 1
          ? departments?.smsNumbers[0]
          : undefined;
      const resolvedDepartmentId = resolvedDepartment?.id;
      const defaultLocationPhone = resolvedDepartment?.smsNumber?.number
        ? formatPhoneWithCountryCode(sanitizePhoneNumber(resolvedDepartment?.smsNumber?.number))
        : '';
      const messageId = genUUIDV4();
      upsert({
        taggedSmsId: options.targetSmsId,
        sms: {
          id: messageId,
          locationId,
          providerMsgId: '',
          locationPhone: defaultLocationPhone,
          direction: Direction.DIRECTION_OUTBOUND,
          body: '',
          status: Status.STATUS_NOT_SENT,
          statusDetails: SENDING_STATUS_DETAILS,
          numMedia: options.media?.length ?? 0,
          media: [],
          encoding: Encoding.ENCODING_UNSPECIFIED,
          segments: 1,
          relatedIds: [],
          labels: {},
          readBy: '',
          readAt: '',
          deletedBy: '',
          deletedAt: '',
          createdBy: user?.userID ?? '',
          createdAt: new Date().toISOString(),
          updatedBy: '',
          updatedAt: '',
          actionable: false,
          tags: [],
          personId: '',
          deadline: '',
          autogeneratedBy: '',
          departmentId: resolvedDepartmentId ?? '',
          ...options,
        },
      });
      return sendMessage({
        id: messageId,
        user: user,
        departments: departments?.smsNumbers ?? [],
        signature: signature,
        threadId: options.threadId,
        targetSmsId: undefined,
        body: options.body,
        media: options.media,
        createdBy: user?.userID ?? '',
        departmentId: options.departmentId,
        locationId: locationId,
        personPhone: options.personPhone,
        programSlugId: KnownProgramSlugIds.MANUAL_MESSAGES,
        personId: options.personId,
        excludeSignature: options.excludeSignature,
        relatedIds: options.relatedIds,
      }).then((res) => {
        shallowUpdate({
          threadId: res.threadId,
          locationId: res.locationId,
          taggedSmsId: options.targetSmsId,
          vals: {
            id: res.smsId,
            locationId: res.locationId,
            status: Status.STATUS_SENT,
            statusDetails: '',
            locationPhone: res.locationPhone,
            personPhone: res.personPhone,
            body: res.body,
          },
        });
        invalidateInboxList();
        if (res.threadId !== options.threadId) {
          remove({
            threadId: options.threadId,
            locationId,
            taggedSmsId: options.targetSmsId,
            smsId: res.smsId,
            userId: user?.userID ?? '',
            permanent: true,
          });
        }
        return res;
      });
    },
    [user, departments, signature, upsert, shallowUpdate, remove, invalidateInboxList, locationId]
  );
};

type MutationArgs = Parameters<typeof useSendMessage>[0];
type MutationRequest = Parameters<ReturnType<typeof useSendMessage>>[0] & MutationArgs;
type SendMessageResponse = Awaited<ReturnType<ReturnType<typeof useSendMessage>>>;
type MutationOptions = UseMutationOptions<SendMessageResponse, unknown, MutationRequest>;
export const useSendMessageMutation = (args: MutationArgs, opts: MutationOptions = {}) => {
  const sendMessage = useSendMessage(args);
  return useMutation({
    mutationKey: 'send-message-mutation',
    mutationFn: (req) => sendMessage(req),
    ...opts,
  });
};
