import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { NWXNavigationModal } from '../components';
import { TimePeriodSelector } from '../components/filter-selectors';
import {
  AutoRecallOnDashboard,
  MessagingCategoriesOnDashboard,
  PhoneIncomingCategoriesOnDashboard,
  PracticeAnalyticsOnDashboard,
} from '../components/homepage-analytics';
import { useLocations } from '../hooks';
import { defaultDateRangeMap } from '../utils';

const DEFAULT_DAY_FILTER = 'last-14-days';
const defaultDates = defaultDateRangeMap[DEFAULT_DAY_FILTER];

export const AnalyticsDashboard = () => {
  const { t } = useTranslation('analytics');
  const { locations } = useLocations();
  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<AnalyticsCommonTypes.AnalyticsSummaryFilters>({
    endDate: dayjs(defaultDates.endDate),
    locationIds: Object.keys(locations),
    startDate: dayjs(defaultDates.startDate),
    timeZone: getSelectedLocationData()[selectedLocationIdsWithParents[0]]?.timezone,
  });

  const handleUpdateFilters = (filters: Partial<AnalyticsCommonTypes.AnalyticsSummaryFilters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filters,
    }));
  };

  useEffect(() => {
    setFilters({
      ...filters,
      locationIds: Object.keys(locations),
    });
  }, [locations]);

  const commonProps = {
    filters,
    onLoading: setIsLoading,
  };

  return (
    <Page
      action={
        <TimePeriodSelector
          defaultPeriod={DEFAULT_DAY_FILTER}
          disabled={isLoading}
          endDate={filters.endDate.format('YYYY-MM-DD')}
          onChange={({ endDate, startDate }) => {
            handleUpdateFilters({
              endDate: dayjs(dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss')),
              startDate: dayjs(dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss')),
            });
          }}
          startDate={filters.startDate.format('YYYY-MM-DD')}
        />
      }
      style={{ maxWidth: '100%' }}
      title={t('Analytics')}
    >
      <NWXNavigationModal />
      <div css={styles.chartsWrapper}>
        <PhoneIncomingCategoriesOnDashboard {...commonProps} />
        <MessagingCategoriesOnDashboard {...commonProps} />
        <AutoRecallOnDashboard {...commonProps} />
        <PracticeAnalyticsOnDashboard />
      </div>
    </Page>
  );
};

const styles = {
  chartsWrapper: css`
    column-gap: ${theme.spacing(3)};
    display: flex;
    flex-wrap: wrap;

    > section {
      flex-basis: calc(50% - ${theme.spacing(1.5)});
      flex-grow: 1;

      @media screen and (min-width: ${breakpoints.small.min}px) {
        min-width: 420px;
      }
    }
  `,
};
