import { ComponentProps } from 'react';
import { useTranslation } from '@frontend/i18n';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { BaseNotificationComponentProps, BaseNotificationInner, BaseNotificationOuter } from './base';
import { DefaultNotificationBody } from './defaults/default-notification';

type Props = BaseNotificationComponentProps<GetWeavePopNotificationByType<'schedule-request-new'>>;

export const ScheduleRequestNotificationComponent = ({ isHistorical, notification, emit, ...rest }: Props) => {
  const { t } = useTranslation();
  const actions: ComponentProps<typeof BaseNotificationInner>['actions'] = [
    // this will eventually be a link to the schedule page, which the schedule team is currently developing
    // {
    //   Icon: undefined,
    //   label: t('View Schedule Request'),
    //   action: () => {
    //     emit({ action: 'view', payload: undefined }, notification);
    //   },
    // },
    ...(!isHistorical
      ? [
          {
            Icon: undefined,
            label: t('Ignore'),
            action: () => {
              emit({ action: 'ignore', payload: undefined }, notification);
            },
          },
        ]
      : []),
  ];
  function getName(firstName?: string, lastName?: string) {
    if (!firstName || !lastName) {
      return '';
    } else {
      return firstName + ' ' + lastName;
    }
  }
  return (
    <BaseNotificationOuter notification={notification} emit={emit} isHistorical={isHistorical} {...rest}>
      <BaseNotificationInner
        onClose={() => emit({ action: 'ignore', payload: undefined }, notification)}
        actions={actions}
        title={t('Schedule Request')}
        body={
          <DefaultNotificationBody
            body={notification.payload.appointmentType}
            name={getName(notification.payload.schedulee?.firstName, notification.payload.schedulee?.lastName)}
          />
        }
        id={notification.id}
        timestamp={notification.timestamp}
        notificationType={notification.type}
        {...rest}
      />
    </BaseNotificationOuter>
  );
};
