import { useCallback } from 'react';
import { Invitation } from 'sip.js';
import { useSoftphoneCallState } from '..';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';
import { IncomingCall } from '../../../types';
import { useSoftphoneClient } from '../../softphone-client-provider';

export const useHandleIncomingCall = () => {
  const { emit } = useSoftphoneEventsEmitter();
  const addCall = useSoftphoneCallState((ctx) => ctx.addCall);
  const calls = useSoftphoneCallState((ctx) => ctx.calls);
  const client = useSoftphoneClient((ctx) => ctx.client);

  return useCallback(
    async (invitation: Invitation) => {
      const call = {
        id: invitation.id,
        invitation: invitation,
        receivedAt: new Date(),
        onHoldSince: undefined,
        remoteParty: {
          displayName: invitation.remoteIdentity.displayName || invitation.remoteIdentity.displayName || undefined,
          uri: invitation.remoteIdentity.uri.user ?? 'unknown',
        },
      } satisfies IncomingCall;
      addCall(call);
      //Note: since the call pop is the only way to answer an incoming call, we're going to defer the audio to the call pop.
      //This will ensure that the audio can only be played if the call pop is being displayed.
      // play.incomingCall();
      emit('incoming-call.received', call);
      return call;
    },
    [calls, client, addCall]
  );
};
