import { useTranslation } from '@frontend/i18n';

export const useTimePeriodFilterLabels = () => {
  const { t } = useTranslation('analytics');

  const labels: Record<string, string> = {
    'last-14-days': t('Last 14 Days'),
    'last-30-days': t('Last 30 Days'),
    'last-7-days': t('Last 7 Days'),
    'last-month': t('Last Month'),
    'last-quarter': t('Last Quarter'),
    'last-week': t('Last Week'),
    'this-month': t('This Month'),
    'this-quarter': t('This Quarter'),
    'this-week': t('This Week'),
    today: t('Today'),
    yesterday: t('Yesterday'),
  };

  return labels;
};
