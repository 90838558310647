import { useTranslation } from '@frontend/i18n';
import { HistoryQueries } from '@frontend/api-messaging';
import { useState, useEffect, useMemo } from 'react';
import {
  DatePickerField,
  Heading,
  ModalControlModalProps,
  MultiselectField,
  TableFilters,
  useForm,
} from '@frontend/design-system';
import { useNavigate } from '@tanstack/react-location';
import dayjs from 'dayjs';
import {
  ListPersonsRequest,
  Person,
  PersonFilterOption_FilterType,
} from '@weave/schema-gen-ts/dist/schemas/messaging/etl/history/v1/service.pb';
import { renderMultiSelectOptions } from './render-multiselect';
import { RecordTable } from './record-table';

type TableFiltersProps = {
  modalProps: ModalControlModalProps;
  setShowNotificationBadge: (showNotification: boolean) => void;
  handleFiltersUpdate: (filters: Partial<ListPersonsRequest>) => void;
  handlePageUpdate: (page: number) => void;
  currentFilters: ListPersonsRequest;
};

export const PersonsListTable = () => {
  const { t } = useTranslation('messages');
  const navigate = useNavigate();

  const [requestParams, setRequestParams] = useState<ListPersonsRequest>({
    pageSize: 100,
  });
  const [page, setPage] = useState<number>(1);

  const handleFiltersUpdate = (newFilters: Partial<ListPersonsRequest>) => {
    setRequestParams({
      pageBoundaryAt: '',
      pageBoundaryId: '',
      pageDirection: undefined,
      ...requestParams,
      ...newFilters,
    });
    setPage(1);
  };

  const { data: personsData = [], isLoading, isFetching } = HistoryQueries.useListPersons(requestParams);

  return (
    <>
      <Heading level={2}>{t('Persons')}</Heading>
      <RecordTable<Person, ListPersonsRequest>
        colConfig={[
          {
            Header: t('Person Id'),
            id: 'personId',
            accessor: (data) => data.personId,
            disableSortBy: true,
            cellConfig: {
              element: 'TextLink',
              onClick: (_: unknown, record) => {
                const recordId = record.personId;
                navigate({ to: `/portal/messages/record-history/person/${recordId}` });
              },
            },
          },
          {
            Header: t('Patient Id'),
            id: 'patientId',
            width: 80,
            accessor: (data) => data.externalId,
            disableSortBy: true,
          },
          {
            Header: t('Modified At'),
            id: 'modifiedAt',
            width: 80,
            accessor: (data) => (data.modifiedAt ? dayjs(data.modifiedAt).format('YYYY/MM/DD, h:mm A') : ''),
            disableSortBy: true,
          },
          {
            Header: t('First Name'),
            id: 'nameFirst',
            accessor: (data) => data.nameFirst,
            width: 80,
            disableSortBy: true,
          },
          {
            Header: t('Last Name'),
            id: 'nameLast',
            accessor: (data) => data.nameLast,
            width: 80,
            disableSortBy: true,
          },
          {
            Header: t('Household ID'),
            id: 'householdId',
            accessor: (data) => data.householdId,
            disableSortBy: true,
          },
          {
            Header: t('Status'),
            id: 'status',
            width: 80,
            accessor: (data) => data.status,
            disableSortBy: true,
          },
        ]}
        data={personsData}
        isLoading={isLoading || isFetching}
        manualFiltersRender={(modalProps, setShowNotification) => (
          <PersonsListTableFilters
            modalProps={modalProps}
            setShowNotificationBadge={setShowNotification}
            currentFilters={requestParams}
            handleFiltersUpdate={handleFiltersUpdate}
            handlePageUpdate={setPage}
          />
        )}
        page={page}
        setPage={setPage}
        requestParams={requestParams}
        setRequestParams={setRequestParams}
      />
    </>
  );
};

const PersonsListTableFilters = ({
  modalProps,
  setShowNotificationBadge,
  currentFilters,
  handleFiltersUpdate,
  handlePageUpdate,
}: TableFiltersProps) => {
  type PersonsListFilterFields = ReturnType<typeof getFieldProps>['name'];

  const { t } = useTranslation('messages');

  const {
    data: filterOptions = [],
    isLoading: filterOptionsIsLoading,
    isFetching: filterOptionsIsFetching,
  } = HistoryQueries.usePersonFilterOptions();

  const { getFieldProps, seedValues, formProps } = useForm({
    fields: {
      pageBoundaryAt: {
        type: 'datePicker',
      },
      statuses: {
        type: 'multiselect',
      },
      sourceTenantIds: {
        type: 'multiselect',
      },
    },
    onSubmit: (data) => {
      if (data.pageBoundaryAt) {
        data.pageBoundaryAt = dayjs(data.pageBoundaryAt).toISOString();
      }
      handleFiltersUpdate(data);
      handlePageUpdate(1);
    },
  });

  const resetIndividualFilter = (id: PersonsListFilterFields) => {
    seedValues({
      [id]: [],
    });
    handleFiltersUpdate({ [id]: [] });
  };

  const resetPageBoundaryAt = () => {
    seedValues({
      pageBoundaryAt: '',
    });
    handleFiltersUpdate({ pageBoundaryAt: '' });
  };

  useEffect(() => {
    const { pageSize, ...restOfFilters } = currentFilters;
    const hasFiltersActive = Object.values(restOfFilters).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });
    setShowNotificationBadge(!hasFiltersActive);
  }, [currentFilters]);

  const fieldValues = useMemo(() => {
    const filterOptionsByStatus = filterOptions?.find(
      ({ filterType }) => filterType === PersonFilterOption_FilterType.STATUS
    );
    const filterOptionsBySourceTenantId = filterOptions?.find(
      ({ filterType }) => filterType === PersonFilterOption_FilterType.SOURCE_TENANT
    );

    return {
      statuses: Object.values(filterOptionsByStatus?.filterOptions ?? {}).filter(Boolean),
      sourceTenantNames: Object.values(filterOptionsBySourceTenantId?.filterOptions ?? {}).filter(Boolean),
      sourceTenantIds: Object.keys(filterOptionsBySourceTenantId?.filterOptions ?? {}).filter(Boolean),
    };
  }, [filterOptions]);

  if (filterOptionsIsLoading || filterOptionsIsFetching) return null;

  return (
    <TableFilters
      {...modalProps}
      formProps={formProps}
      onApplyClick={() => {
        setShowNotificationBadge(true);
      }}
    >
      <TableFilters.Section
        disableClear={!currentFilters['pageBoundaryAt']}
        sectionHeaderLabel={t('Date')}
        onClearClick={() => resetPageBoundaryAt()}
      >
        <DatePickerField label={t('Date')} {...getFieldProps('pageBoundaryAt')} />
      </TableFilters.Section>
      <TableFilters.Section
        disableClear={!currentFilters['statuses'] || currentFilters['statuses'].length === 0}
        sectionHeaderLabel={t('Status')}
        onClearClick={() => resetIndividualFilter('statuses')}
      >
        <MultiselectField {...getFieldProps('statuses')} label={t('Status')} placeholder={t('Status')}>
          {renderMultiSelectOptions(fieldValues.statuses)}
        </MultiselectField>
      </TableFilters.Section>
      <TableFilters.Section
        disableClear={!currentFilters['sourceTenantIds'] || currentFilters['sourceTenantIds'].length === 0}
        sectionHeaderLabel={t('Tenant Name')}
        onClearClick={() => resetIndividualFilter('sourceTenantIds')}
      >
        <MultiselectField {...getFieldProps('sourceTenantIds')} label={t('Tenant Name')} placeholder={t('Tenant Name')}>
          {renderMultiSelectOptions(fieldValues.sourceTenantNames, fieldValues.sourceTenantIds)}
        </MultiselectField>
      </TableFilters.Section>
    </TableFilters>
  );
};
