import React, { ComponentProps, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { createContext, useContextSelector } from 'use-context-selector';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { DevicesApi } from '@frontend/api-devices';
import { DialQueries, DialUtils } from '@frontend/api-dialpad';
import { E911Api, E911QueryKeys } from '@frontend/api-e911-addresses';
import { PersonAPI } from '@frontend/api-person';
import { registerPhone } from '@frontend/api-phone-sync';
import { SoftphoneQueries, SoftphoneTypes } from '@frontend/api-softphone';
import { useAppSettingsShallowStore } from '@frontend/app-settings-store';
import { getUser, getWeaveToken } from '@frontend/auth-helpers';
import appConfig from '@frontend/env';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useHasPhoneSystemAccess, usePhoneConfigStore } from '@frontend/phone-config';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { SchemaPhoneUserService } from '@frontend/schema';
import {
  OutboundSelectedPhoneNumberProps,
  SoftphoneAudioProvider,
  SoftphoneCallStateProvider,
  SoftphoneClientProvider,
  SoftphoneDialerProvider,
  SoftphoneDirectoryProvider,
  SoftphoneE911Provider,
  SoftphoneEventsProvider,
  SoftphoneHistoryProvider,
  SoftphoneMediaDevicesProvider,
  SoftphoneMetricsProvider,
  SoftphoneParkSlotsProvider,
  SoftphoneRouterProvider,
  SoftphoneSettingsProvider,
  SoftphoneSubscriptionsProvider,
  SoftphoneToastProvider,
  SoftphoneUsersProvider,
  SoftphoneWidgetControlProvider,
} from '@frontend/softphone2';
import { useAlert, useModalControl } from '@frontend/design-system';
import { SoftphoneCallPopManager } from './softphone-call-pop-manager';
import { SoftphoneDisconnectModal } from './softphone-disconnect-modal';
import { useSoftphoneTabManager } from './softphone-tab-manager';

export const getPersonApis = ({
  dataSourceIds,
}: {
  dataSourceIds?: string[];
}): ComponentProps<typeof SoftphoneDirectoryProvider>['personApi'] => ({
  getPersonsByName: (name: string) => PersonAPI.getPersonsByName(name).then((res) => res.rows),
  getPersons: (skip, limit) =>
    PersonAPI.getPersonsV2({ order: ['last_name', 'first_name'], skip, limit, status: 'Active', dataSourceIds }).then(
      (res) => res.rows
    ),
  getPersonByPhone: PersonAPI.getPersonByPhone,
  getPersonsByPhone: (phone: string) =>
    PersonAPI.getPersonsV2({
      order: ['last_name', 'first_name'],
      skip: 0,
      limit: 25,
      status: 'Active',
      query: phone,
      dataSourceIds,
    }).then((res) => res.rows),
  getPersonById: PersonAPI.getPersonExtended,
  getPersonImage: PersonAPI.getPersonImage,
});

const defaultUsers: any[] = [];
const defaultParkSlots: any[] = [];
const defaultAddresses: any[] = [];

type SoftphoneManagerContextValue = {
  error: Error | undefined;
  restart: () => void;
  isLoading: boolean;
  isMounted: boolean;
  isConnected: boolean;
  deferredToOtherTab: boolean;
  useThisTab: () => void;
  sipProfile: SoftphoneTypes.SipProfile | undefined;
  hasActiveCalls: boolean;
  isCallWaitingIndicatorBeepEnabled: boolean;
};
const SoftphoneManagerContext = createContext({} as SoftphoneManagerContextValue);

const mobileBreakpoint = 850;

type SoftphoneManagerProps = {
  children: React.ReactNode;
  onCallIsActive?: () => void;
  onAllCallsEnded?: () => void;
};

export const SoftphoneManager = ({ onCallIsActive, onAllCallsEnded, children }: SoftphoneManagerProps) => {
  const { t } = useTranslation('softphone');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const token = getWeaveToken();
  const user = getUser();
  const [currE911AddressId, setCurrE911AddressId] = useState<string>();
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState<Error>();
  const phoneConfigTenantId = usePhoneConfigStore((store) => store.phoneConfig?.tenantId);
  const phoneConfigUserId = usePhoneConfigStore((store) => store.phoneConfig?.activeUserId);
  const phoneConfigSipProfileId = usePhoneConfigStore((store) => store.phoneConfig?.sipProfileId);
  const setPhoneConfig = usePhoneConfigStore((store) => store.setPhoneConfig);
  const disconnectKey = 'softphone.show-disconnect-warning';
  const alerts = useAlert();
  const disconnectDeviceModalProps = useModalControl();
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');
  const [hasActiveCalls, setHasActiveCalls] = useState(false);
  const { setHasActiveSoftphoneCalls } = useAppSettingsShallowStore('setHasActiveSoftphoneCalls');
  const hasPhoneSystemAccess = useHasPhoneSystemAccess();

  const hasActiveSoftphoneCall = useSoftphoneManager((ctx) => ctx.hasActiveCalls);
  const [isCallWaitingIndicatorBeepEnabled, setIsCallWaitingIndicatorBeepEnabled] = useState(true);

  useEffect(() => {
    setHasActiveSoftphoneCalls(hasActiveSoftphoneCall);
  }, [hasActiveSoftphoneCall]);

  /**
   * TODO: this setter is in preparation of allowing users to select their outbound id.
   * It isn't supporte yet in the backend, so once it is, we will enable the UI for it.
   */

  const { data: phoneNumbers } = DialQueries.useVoiceNumbersQuery(phoneConfigTenantId);
  const numbersToMap = useMemo(() => DialUtils.useNumbersToLocationMap(phoneNumbers ?? []), [phoneNumbers]);
  const numbers =
    numbersToMap &&
    Object.entries(numbersToMap).map(([number, locationName]) => ({
      number,
      locationName,
    }));

  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<OutboundSelectedPhoneNumberProps | undefined>();

  useEffect(() => {
    if (numbers.length > 0 && !selectedPhoneNumber) {
      setSelectedPhoneNumber({
        locationName: numbers[0].locationName,
        number: numbers[0].number,
      });
    }
  }, [numbers, selectedPhoneNumber]);

  const { mutate: disconnectDevice } = useMutation(
    () => {
      return SchemaPhoneUserService.ListSavedDevices({});
    },
    {
      onSuccess: () => {
        setPhoneConfig(undefined);
        alerts.success(t('Device disconnected successfully'));
      },
      onError: () => {
        alerts.error(t('Device disconnection failed'));
      },
    }
  );

  const {
    data: softphoneSettingsData,
    isLoading: isLoadingSoftphoneData,
    refetch,
  } = SoftphoneQueries.useGetCurrentUserSoftphoneQuery({
    enabled: !!user?.userID,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const softphoneData = useMemo(() => {
    return softphoneSettingsData?.softphones.find((softphone) => {
      return softphone.sipProfiles.some((sipProfile) => sipProfile.id === phoneConfigSipProfileId);
    });
  }, [softphoneSettingsData, phoneConfigSipProfileId]);

  const softphoneSipProfile = useMemo(() => {
    return softphoneData?.sipProfiles.find((sipProfile) => sipProfile.id === phoneConfigSipProfileId);
  }, [softphoneData]);

  const users = useMemo(() => {
    return softphoneData?.extensions.filter((user) => !!user.presenceUri);
  }, [softphoneData?.extensions]);

  const isConnectedToUserSoftphone = !!softphoneData && user?.userID === phoneConfigUserId;

  const { data: { addresses } = { addresses: [] }, isRefetching: isLoadingAddresses } = useQuery({
    queryKey: [phoneConfigTenantId, ...E911QueryKeys.queryKeys.listAddresses()],
    queryFn: () => E911Api.list({ tenantId: phoneConfigTenantId ?? '' }),
    enabled: !!user?.userID,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const version = appConfig.VERSION;
  const isLoading = isLoadingAddresses || isLoadingSoftphoneData || isRefreshing;

  useEffect(() => {
    if (softphoneSipProfile?.e911AddressId) {
      setCurrE911AddressId(softphoneSipProfile.e911AddressId);
    }
  }, [softphoneSipProfile?.e911AddressId]);

  const postEvent = useCallback(
    (event: string) => {
      if (!softphoneData?.metricsUrl) {
        return;
      }
      return http.post<unknown>(softphoneData?.metricsUrl, {
        event,
        userAgent: `Weave1.1/${appConfig.VERSION ?? 'unknown'}`,
      });
    },
    [softphoneData?.metricsUrl]
  );

  const onSelectAddress = useCallback((addressId: string) => {
    if (!softphoneSipProfile?.id) {
      console.warn('E911 address chosen, but no sip profile id provided');
      return;
    }
    const prev = currE911AddressId;
    setCurrE911AddressId(addressId);
    return DevicesApi.UpdateDeviceE911Address({
      deviceId: softphoneData?.id ?? '',
      e911Address: {
        id: addressId,
        name: '',
      },
    })
      .then((_res) => {
        refetch();
      })
      .catch(() => {
        setCurrE911AddressId(prev);
      });
  }, []);

  const restart = useCallback(() => {
    setIsRefreshing(true);
    setTimeout(() => {
      setIsRefreshing(false);
      refetch();
    }, 700);
  }, []);

  const hasAllTheData = !!(
    softphoneData &&
    softphoneSettingsData &&
    softphoneSipProfile?.id &&
    user &&
    token &&
    !isLoading
  );
  const { useThisTab, runningInOtherTab } = useSoftphoneTabManager({
    onTabRegister: registerPhone,
    onTabUnregister: restart,
    sipProfileId: softphoneSipProfile?.id,
    isReady: hasAllTheData && isConnectedToUserSoftphone,
    hasActiveCalls,
    setHasActiveCalls,
  });

  const isEnabled = isConnectedToUserSoftphone && !runningInOtherTab;
  const isMounted = hasAllTheData && isEnabled && !!hasPhoneSystemAccess;
  const isReallyConnected = isMounted && isConnected;

  const onDisconnect = useCallback(() => {
    setSelectedPhoneNumber(undefined);
    setIsConnected(false);
  }, []);

  const onConnect = useCallback(() => {
    setIsConnected(true);
  }, []);

  const onManualDisconnect = () => {
    if (localStorage.getItem(disconnectKey) === 'true') {
      disconnectDevice();
    } else {
      disconnectDeviceModalProps.openModal();
    }
  };

  const value = {
    error,
    restart,
    isLoading,
    isMounted,
    isConnected: isReallyConnected,
    deferredToOtherTab: !!(hasAllTheData && runningInOtherTab),
    useThisTab,
    sipProfile: softphoneSipProfile,
    hasActiveCalls,
    isCallWaitingIndicatorBeepEnabled,
  } satisfies SoftphoneManagerContextValue;

  const eventHandlers = useMemo<ComponentProps<typeof SoftphoneEventsProvider>['handlers']>(
    () => ({
      'call-state.changed': (state) => {
        if (state.establishedCalls?.length > 0) {
          setHasActiveCalls(true);
          return onCallIsActive?.();
        } else {
          setHasActiveCalls(false);
          return onAllCallsEnded?.();
        }
      },
    }),
    [onCallIsActive, onAllCallsEnded]
  );

  if (isMounted) {
    return (
      <SoftphoneManagerContext.Provider value={value} key={softphoneSipProfile?.username}>
        <ErrorBoundary
          fallback={({ error }) => (
            <SoftphoneProvidersErrorBoundary
              onError={() => {
                error instanceof Error ? setError(error as Error) : setError(new Error('unknown error occurred!'));
              }}
            >
              {children}
            </SoftphoneProvidersErrorBoundary>
          )}
        >
          <SoftphoneMetricsProvider postEvent={postEvent}>
            <SoftphoneToastProvider defaultTimeout={2000}>
              <SoftphoneEventsProvider handlers={eventHandlers}>
                <SoftphoneSettingsProvider
                  restart={restart}
                  onTurnOffSoftphone={() => onManualDisconnect()}
                  selectedOutboundPhoneNumber={selectedPhoneNumber}
                  currentPhoneNumber={selectedPhoneNumber?.number}
                  setCurrentPhoneNumber={setSelectedPhoneNumber}
                  availablePhoneNumbers={numbers}
                  setIsCallWaitingIndicatorBeepEnabled={setIsCallWaitingIndicatorBeepEnabled}
                  isCallWaitingIndicatorBeepEnabled={isCallWaitingIndicatorBeepEnabled}
                >
                  <SoftphoneClientProvider
                    onConnect={onConnect}
                    onDisconnect={onDisconnect}
                    deviceName={softphoneSipProfile?.name}
                    extensionNumber={softphoneSipProfile.extensionNumber}
                    proxy={softphoneSettingsData.proxy}
                    username={softphoneSipProfile.username}
                    domain={softphoneSipProfile.domain}
                    password={softphoneSipProfile.password}
                    useragent={`Weave2.0/${version ?? 'unknown'}`}
                    extensions={softphoneData?.extensions}
                  >
                    <SoftphoneDialerProvider>
                      <SoftphoneMediaDevicesProvider>
                        <SoftphoneAudioProvider>
                          <SoftphoneWidgetControlProvider mobileBreakpoint={mobileBreakpoint}>
                            <SoftphoneCallStateProvider>
                              <SoftphoneDirectoryProvider personApi={getPersonApis({ dataSourceIds: demoSourceIds })}>
                                <SoftphoneUsersProvider users={users ?? defaultUsers}>
                                  <SoftphoneParkSlotsProvider parkSlots={softphoneData.parkSlots ?? defaultParkSlots}>
                                    <SoftphoneSubscriptionsProvider>
                                      <SoftphoneE911Provider
                                        e911Addresses={addresses ?? defaultAddresses}
                                        currentE911AddressId={currE911AddressId}
                                        onSelectE911Address={onSelectAddress}
                                      >
                                        <SoftphoneHistoryProvider>
                                          <SoftphoneRouterProvider>
                                            <SoftphoneCallPopManager
                                              isCallWaitingIndicatorBeepEnabled={isCallWaitingIndicatorBeepEnabled}
                                              hasActiveCalls={hasActiveCalls}
                                              softphoneData={softphoneData}
                                            />
                                            <SoftphoneDisconnectModal
                                              disconnectDevice={disconnectDevice}
                                              {...disconnectDeviceModalProps}
                                            />

                                            {children}
                                          </SoftphoneRouterProvider>
                                        </SoftphoneHistoryProvider>
                                      </SoftphoneE911Provider>
                                    </SoftphoneSubscriptionsProvider>
                                  </SoftphoneParkSlotsProvider>
                                </SoftphoneUsersProvider>
                              </SoftphoneDirectoryProvider>
                            </SoftphoneCallStateProvider>
                          </SoftphoneWidgetControlProvider>
                        </SoftphoneAudioProvider>
                      </SoftphoneMediaDevicesProvider>
                    </SoftphoneDialerProvider>
                  </SoftphoneClientProvider>
                </SoftphoneSettingsProvider>
              </SoftphoneEventsProvider>
            </SoftphoneToastProvider>
          </SoftphoneMetricsProvider>
        </ErrorBoundary>
      </SoftphoneManagerContext.Provider>
    );
  }
  return <SoftphoneManagerContext.Provider value={value}>{children}</SoftphoneManagerContext.Provider>;
};

export const useSoftphoneManager = <T extends any>(selector: (val: SoftphoneManagerContextValue) => T) => {
  return useContextSelector(SoftphoneManagerContext, selector);
};

type Props = { children: ReactNode; onError: () => void };
const SoftphoneProvidersErrorBoundary = ({ children, onError }: Props) => {
  useEffect(() => {
    onError();
  }, []);
  return <>{children}</>;
};
