import { http, APIResponse } from '@frontend/fetch';
import { SoftphoneSchemaService, SchemaDevices, SchemaExtensions } from '@frontend/schema';
import type { DestoryLineType, ValidateDestroLineType } from './types';
import { DevicesTypes } from '.';

const baseUrl = 'portal/v1';
const baseUrlV2 = 'portal/v2';

export const getDeviceById = (deviceId: string, queryString?: string) =>
  http.getData<DevicesTypes.DeviceWithAddressModel>(`${baseUrlV2}/phone/devices/${deviceId}${queryString || ''}`);

export const listDevices = () =>
  http.getData<DevicesTypes.DeviceWithAddressModel[]>(`${baseUrl}/phone/devices?show=all`);

export const listDevicesWithHeaders = (locationId: string) => {
  return http.getData<DevicesTypes.DeviceWithAddressModel[]>(
    `${baseUrl}/phone/devices?show=all`,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const createAddress = (address: DevicesTypes.AddressModel, locationId?: string) =>
  http.post<{ data: DevicesTypes.AddressModel }, Partial<DevicesTypes.AddressModel>>(
    `${baseUrl}/addresses`,
    address,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );

export const updateAddress = (address: DevicesTypes.AddressModel) =>
  http.put(`${baseUrl}/addresses/${address.ID}`, address);

export const listDevicesWithAddressId = () =>
  http.getData<DevicesTypes.DeviceWithAddressModel[]>(`${baseUrl}/phone/devices?hasAddressID=true`);

export const update = (device: DevicesTypes.DeviceModel) => http.put(`${baseUrlV2}/phone/devices/${device.ID}`, device);

export const reboot = (deviceIds: string[]) => SchemaDevices.Sync({ deviceIds });

export const updateExtension = (extension: DevicesTypes.DeviceExtensionModel) =>
  http.post(`${baseUrl}/phone/extensions/${extension.id}/renumber`, { requested_extension_number: extension.number });

export const debounceExtension = (extension: DevicesTypes.DeviceExtensionModel) =>
  http
    .post<APIResponse<DevicesTypes.ExtensionResponse>>(`${baseUrl}/phone/extensions/${extension.id}/renumber/check`, {
      requested_extension_number: extension.number,
    })
    .then((res) => res.data);

export const checkExtensionAvailability = (extensionNumber: string, locationId?: string) =>
  http.get<DevicesTypes.NewDeviceExtensionResponse>(
    `phone-devices/lines/v1/extension-number/${extensionNumber}/availability`,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );

/** Schema Services */

export const ValidateDestroyLine = (
  req: ValidateDestroLineType['input']
): Promise<ValidateDestroLineType['output']> => {
  return SoftphoneSchemaService.ValidateDestroyByDeviceId(req);
};

export const DestroyLine = (req: DestoryLineType['input']) => SoftphoneSchemaService.DestroyByDeviceID(req);

export const ListDevices = SchemaDevices.List;

export const GetDevice = SchemaDevices.GetDevice;

export const GetDeviceExtensions = SchemaDevices.GetDeviceExtensions;

export const GetDeviceE911Address = SchemaDevices.GetDeviceE911Address;

export const UpdateDeviceE911Address = SchemaDevices.UpdateDeviceE911Address;

export const UpdateDevice = SchemaDevices.UpdateDevice;

export const UpdateDeviceExtension = SchemaDevices.UpdateDeviceExtension;

export const GetDeviceExtensionCallGroups = SchemaDevices.GetDeviceExtensionCallGroups;

export const UpdateDeviceExtensionCallGroups = SchemaDevices.ReplaceDeviceExtensionCallGroups;

export const RenumberExtension = SchemaExtensions.Renumber;
