import { PopoverMenu, PopoverMenuItem, Text } from '@frontend/design-system';
import { useSoftphoneClient } from '../providers/softphone-client-provider';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';
import { useSoftphoneSettings } from '../providers/softphone-settings-provider';
import { formatPhoneNumber } from '../utils/formatting-utils';
import { useMemo, useState } from 'react';
import { DeviceInformation, SelectOutboundIdMenuProps } from '@frontend/generic-dialpad-accessories';

interface WrapperProps {
  hideExtension?: boolean;
  hideOutboundCallerInfo?: boolean;
}

export const DeviceInformationWrapper = ({ hideExtension, hideOutboundCallerInfo }: WrapperProps) => {
  const { t } = useTranslation('softphone');
  const deviceName = useSoftphoneClient((ctx) => ctx.deviceName);
  const extensionNumber = useSoftphoneClient((ctx) => ctx.extensionNumber);
  const currentPhoneNumber = useSoftphoneSettings((ctx) => ctx.selectedOutboundPhoneNumber?.number);
  const availablePhoneNumbers = useSoftphoneSettings((ctx) => ctx.availablePhoneNumbers);
  const { navigate } = useSettingsNavigate();
  const close = useSoftphoneWidgetControl((ctx) => ctx.close);

  const deviceInfoProps = {
    deviceName: t('{{deviceName}}', { deviceName }),
    onChangeDeviceClick: () => {
      navigate({ to: '/personal/phone/device-connection' });
      close();
    },
    changeDeviceText: t('Change Device'),
    showExtension: !hideExtension,
    extensionNumberText: t('Extension {{extensionNumber}}', { extensionNumber }),
    currentPhoneNumberText: currentPhoneNumber
      ? t('Outbound ID: {{currentPhoneNumber}}', { currentPhoneNumber: formatPhoneNumber(currentPhoneNumber) })
      : null,
    availablePhoneNumbers: availablePhoneNumbers,
    SelectOutboundIdMenuWrapper,
    showOutboundCallerInfo: !hideOutboundCallerInfo,
    trackingId: 'softphone-modal-changedevice',
  };

  return <DeviceInformation {...deviceInfoProps} />;
};

type OutboundSelectedPhoneNumberProps = {
  availablePhoneNumbers: { number: string; locationName: string }[];
  currentPhoneNumber: string | undefined;
  setCurrentPhoneNumber: (data: { number: string; locationName: string }) => void;
};

type ActionItemProps = {
  locationName: string;
  phoneNumber: string | undefined;
  onClick: () => void;
};

export const SelectOutboundIdMenu = ({
  getMenuProps,
  getItemProps,
  close: closePopover,
  availablePhoneNumbers,
  currentPhoneNumber,
  setCurrentPhoneNumber,
  themeStyle = 'dark',
}: SelectOutboundIdMenuProps & OutboundSelectedPhoneNumberProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const actions: ActionItemProps[] = useMemo(
    () =>
      availablePhoneNumbers.map(({ number, locationName }, i) => {
        if (currentPhoneNumber === number) {
          setSelectedIndex(i);
        }
        return {
          locationName,
          phoneNumber: number,
          onClick: () => {
            closePopover?.();
            setSelectedIndex(i);
            setCurrentPhoneNumber({
              number,
              locationName,
            });
          },
        };
      }),
    [availablePhoneNumbers]
  );

  const backgroundColor = themeStyle === 'dark' ? theme.colors.neutral80 : theme.colors.white;
  const textColor = themeStyle === 'dark' ? 'white' : 'default';
  const hoverFocusColor = themeStyle === 'dark' ? theme.colors.neutral70 : theme.colors.neutral10;

  return (
    <PopoverMenu
      {...getMenuProps()}
      initialFocus={selectedIndex}
      css={css`
        background-color: ${backgroundColor};
        button span {
          color: ${theme.colors.white};
        }
        button:hover {
          background-color: ${hoverFocusColor};
        }
        button:focus {
          background-color: ${hoverFocusColor};
        }
      `}
    >
      {actions.map(({ locationName, onClick, phoneNumber }: ActionItemProps, index) => (
        <PopoverMenuItem
          css={css`
            height: auto;
            display: block;
            text-align: left;
            padding-top: ${theme.spacing(1)};
            padding-bottom: ${theme.spacing(1)};
          `}
          key={locationName}
          {...getItemProps({
            index,
            onClick,
          })}
        >
          <Text as='div' color={textColor} weight='bold'>
            {locationName}
          </Text>
          <Text as='div' color={textColor}>
            {formatPhoneNumber(phoneNumber ?? '')}
          </Text>
        </PopoverMenuItem>
      ))}
    </PopoverMenu>
  );
};

const SelectOutboundIdMenuWrapper = (popoverMenuProps: SelectOutboundIdMenuProps) => {
  const availablePhoneNumbers = useSoftphoneSettings((ctx) => ctx.availablePhoneNumbers);
  const setCurrentPhoneNumber = useSoftphoneSettings((ctx) => ctx.setCurrentPhoneNumber);
  const currentPhoneNumber = useSoftphoneSettings((ctx) => ctx.selectedOutboundPhoneNumber?.number);
  return (
    <SelectOutboundIdMenu
      availablePhoneNumbers={availablePhoneNumbers}
      setCurrentPhoneNumber={setCurrentPhoneNumber}
      currentPhoneNumber={currentPhoneNumber}
      {...popoverMenuProps}
    />
  );
};
