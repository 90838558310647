import React, { useCallback } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useAppScopeStore } from '@frontend/scope';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Modal, useModalControl, SpinningLoader, styles, ModalControlModalProps } from '@frontend/design-system';
import { AppointmentEventCard } from '../components/schedule-calendar-components/appointment-event-card';
import { useAppointmentsInfoShallowStore } from './use-appointments-info-store';

type UseGetAppointmentDetailsProps = {
  appointmentId: string;
  locationId: string;
  appointments?: Appointment[];
  eventColor?: string;
  onModalClose?: () => void;
};

type UseGetAppointmentCardDetailsProps = {
  appointmentId: string;
  locationId: string;
  isEnabled?: boolean;
};

const useGetAppointmentCardDetails = () => {
  const getAppointmentCardData = useCallback(
    ({ appointmentId, locationId, isEnabled = false }: UseGetAppointmentCardDetailsProps) => {
      const {
        data: appointment,
        refetch: refetchAppointment,
        isLoading: isAppointmentDataLoading,
        isRefetching: isAppointmentDataRefetching,
      } = ScheduleQueries.useGetAppointment({
        appointmentId,
        locationId,
        isEnabled,
      });
      return { ...appointment, refetchAppointment, isAppointmentDataLoading, isAppointmentDataRefetching };
    },
    []
  );
  return { getAppointmentCardData };
};

export const useGetAppointmentDetails = ({
  appointmentId,
  locationId,
  onModalClose,
  eventColor = theme.colors.warning50,
  appointments,
}: UseGetAppointmentDetailsProps) => {
  const { getAppointmentCardData } = useGetAppointmentCardDetails();

  const modal = useModalControl();

  const handleOnCloseModal = () => {
    onModalClose?.();
    modal?.closeModal();
  };

  return {
    Modal: (
      <AppointmentDetailsModal
        onCloseModal={handleOnCloseModal}
        modalProps={modal.modalProps}
        appointmentId={appointmentId}
        locationId={locationId}
        eventColor={eventColor}
        appointments={appointments}
      />
    ),
    triggerProps: { ...modal.triggerProps },
    closeModal: handleOnCloseModal,
    getAppointmentCardData,
  };
};

type AppointmentDetailsModalProps = {
  modalProps: ModalControlModalProps;
  appointmentId: string;
  locationId: string;
  onCloseModal: () => void;
  appointments?: Appointment[];
  eventColor?: string;
};

const AppointmentDetailsModal = React.memo(
  ({ modalProps, locationId, appointmentId, eventColor, appointments, onCloseModal }: AppointmentDetailsModalProps) => {
    const { flagValues } = useFeatureFlagShallowStore('flagValues');
    const isCalendarEventsReleased = flagValues['calendarEventsReleased'];
    const { getLocationName } = useAppScopeStore();
    const { getAppointmentCardData } = useGetAppointmentCardDetails();

    const { appointments: calendarViewAppointments } = useAppointmentsInfoShallowStore('appointments');

    const appointmentsToFindExistingAppointment = appointments ? appointments : calendarViewAppointments;

    const existsInFetchedAppointments = appointmentsToFindExistingAppointment?.find(
      (appointment: Appointment) => appointment.id === appointmentId
    );

    const { onClose, ...restModalProps } = modalProps;

    const onModalClose = () => {
      onClose();
      onCloseModal();
    };

    const { appointment, isAppointmentDataLoading } = getAppointmentCardData({
      appointmentId,
      locationId,
      isEnabled: !existsInFetchedAppointments && modalProps.show,
    });

    const appointmentDetails = !!existsInFetchedAppointments ? existsInFetchedAppointments : appointment;

    if (!appointmentDetails) return null;

    return (
      <Modal
        disableCloseOnEscape={true}
        onClose={onModalClose}
        {...restModalProps}
        css={{
          maxWidth: '600px',
          padding: theme.spacing(3),
          paddingLeft: eventColor ? theme.spacing(4) : theme.spacing(3),
        }}
      >
        {isAppointmentDataLoading ? (
          <AppointmentDetailsModalSpinner />
        ) : (
          <>
            {eventColor && (
              <div
                css={{
                  backgroundColor: eventColor ?? '',
                  width: theme.spacing(1),
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  borderTopLeftRadius: theme.borderRadius.medium,
                  borderBottomLeftRadius: theme.borderRadius.medium,
                }}
              />
            )}
            <AppointmentEventCard
              appointment={appointmentDetails ?? {}}
              closeAppointmentDetailsModal={onModalClose}
              eventId={appointmentDetails?.id ?? ''}
              locationName={getLocationName(locationId) ?? '-'}
              providerId=''
              providerName={appointmentDetails?.practitionerName ?? '-'}
              shouldShowActionsMenu={isCalendarEventsReleased}
            />
          </>
        )}
      </Modal>
    );
  }
);

AppointmentDetailsModal.displayName = 'AppointmentDetailsModal';

const AppointmentDetailsModalSpinner = () => (
  <div css={[styles.flexCenter, { width: '500px', height: '300px' }]}>
    <SpinningLoader />
  </div>
);
