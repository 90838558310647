import { ReactNode, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { useInfiniteQuery } from 'react-query';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { PersonAPI, PersonTypes } from '@frontend/api-person';
import { InfinitePaginatedList } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  PrimaryButton,
  SearchField,
  SecondaryButton,
  Text,
  useDebouncedValue,
  useFormField,
} from '@frontend/design-system';
import { useManagePersonListContext } from './PersonSelectorContext';
import { PatientSelectorScreenEnum, PersonLastNameLetterMap } from './types';

const API_RESPONSE_LIMIT = 50;

export const PersonSearch = () => {
  const { t } = useTranslation('personSelector');
  const {
    locationId,
    defaultSearchValue,
    shouldRenderNotes,
    setPersonSelectorScreen,
    closePopoverDialog,
    setSelectedPerson,
    dataSources,
    showDataSources,
    addNewButtonTrackingId,
  } = useManagePersonListContext();
  const searchFieldProps = useFormField({ type: 'text', value: defaultSearchValue });

  const debouncedSearchTerm = useDebouncedValue(searchFieldProps.value, 500);

  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');

  const infiniteQueryProps = useInfiniteQuery({
    queryKey: ['person-contacts', debouncedSearchTerm, locationId, ...demoSourceIds],
    queryFn: async (queryFunctionContext) => {
      const personListData = await PersonAPI.getPersons(
        API_RESPONSE_LIMIT,
        queryFunctionContext.pageParam,
        debouncedSearchTerm,
        'Active',
        locationId,
        demoSourceIds
      );
      const sortedPersonListData = personListData.rows.sort((a, b) => a?.LastName?.localeCompare(b?.LastName));
      return {
        rows: sortedPersonListData,
        nextOffset: personListData.nextOffset,
      };
    },

    getNextPageParam: (_lastGroup, persons) => {
      if (_lastGroup.rows.length < API_RESPONSE_LIMIT) return undefined;
      return persons.length * API_RESPONSE_LIMIT;
    },
  });

  const addNewHandler = () => {
    setPersonSelectorScreen(PatientSelectorScreenEnum.CUSTOM_CONTACT);
  };

  const personSelectorHandler = (person: PersonTypes.Person) => {
    setSelectedPerson(person);
    if (shouldRenderNotes) {
      setPersonSelectorScreen(PatientSelectorScreenEnum.PERSON_NOTES);
    } else {
      closePopoverDialog();
    }
  };

  const lastNameLettersMap = useMemo(() => {
    return (infiniteQueryProps.data?.pages || [])
      .flatMap((page) => page.rows)
      .reduce((acc, person) => {
        const firstLetter = person.LastName ? person.LastName[0].toUpperCase() : '';
        if (!acc[firstLetter]) acc[firstLetter] = [person.PersonID];
        else acc[firstLetter].push(person.PersonID);

        return acc;
      }, {} as PersonLastNameLetterMap);
  }, [infiniteQueryProps.data?.pages]);

  const getDataSourceName = useCallback(
    (sourceId: string) => {
      const dataSource = dataSources?.find((source) => source.SourceID === sourceId);
      if (dataSource) {
        return dataSource.PracticeManagementSystem;
      }
      return '';
    },
    [dataSources]
  );

  return (
    <>
      <div css={{ padding: theme.spacing(2) }}>
        <SearchField {...searchFieldProps} name='person-search' />
      </div>

      <InfinitePaginatedList
        height={250}
        infiniteQueryProps={infiniteQueryProps}
        renderListItem={({ listItem }): ReactNode => {
          const lastNameFirstLetter = listItem.LastName?.[0]?.toUpperCase();
          const isFirstPerson = lastNameLettersMap[lastNameFirstLetter]?.[0] === listItem.PersonID;
          const isLastPerson = lastNameLettersMap[lastNameFirstLetter]?.slice(-1)[0] === listItem.PersonID;

          const integratedSourceName = getDataSourceName(listItem.SourceID);

          return (
            <>
              {isFirstPerson && (
                <Text size='medium' weight='bold' css={{ padding: theme.spacing(1, 2) }}>
                  {lastNameFirstLetter}
                </Text>
              )}
              <div css={personListItemStyles} onClick={() => personSelectorHandler(listItem)}>
                <Text size='medium' css={{ marginRight: theme.spacing(0.5) }}>
                  {listItem.FirstName}
                </Text>
                <Text size='medium' weight='bold'>
                  {listItem.LastName}
                </Text>
                {!!showDataSources && !!dataSources?.length && (
                  <Text css={{ marginLeft: theme.spacing(1) }} size='small' color='light'>
                    {integratedSourceName}
                  </Text>
                )}
              </div>
              {isLastPerson && <div css={personListSectionBorderStyles} />}
            </>
          );
        }}
      />

      <ButtonBar css={{ padding: theme.spacing(2) }}>
        <SecondaryButton trackingId='person-selector-person-search-cancel-btn' onClick={closePopoverDialog}>
          {t('Cancel')}
        </SecondaryButton>
        <PrimaryButton trackingId={addNewButtonTrackingId} onClick={addNewHandler}>
          <Icon name='plus-small' color='white' css={{ marginRight: theme.spacing(1) }} />
          {t('Add New')}
        </PrimaryButton>
      </ButtonBar>
    </>
  );
};

const personListItemStyles = css({
  display: 'flex',
  padding: theme.spacing(0.5, 2),
  cursor: 'pointer',
  alignItems: 'center',
  ':hover': {
    backgroundColor: theme.colors.neutral10,
  },
});

const personListSectionBorderStyles = css({
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  padding: theme.spacing(0.5, 0),
});
