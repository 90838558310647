import React, {
  useState,
  useReducer,
  useLayoutEffect,
  useRef,
  ElementRef,
  PropsWithChildren,
  useMemo,
  useCallback,
} from 'react';
import { css } from '@emotion/react';
import {
  useClientPoint,
  useInteractions,
  useFloating,
  offset,
  flip,
  shift,
  useHover,
  autoUpdate,
} from '@floating-ui/react';
import composeRefs from '@seznam/compose-react-refs';
import dayjs from 'dayjs';
import { motion, AnimatePresence } from 'framer-motion';
import { transparentize } from '@frontend/colors';
import { useEventListener } from '@frontend/event';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useMediaMatches } from '@frontend/responsiveness';
import { useInterval } from '@frontend/timer';
import { WeaveThemeColors, theme } from '@frontend/theme';
import {
  Avatar,
  useTooltip,
  AvatarProps,
  Text,
  styles,
  NakedButton,
  useScrollShadow,
  usePortal,
  useAlert,
} from '@frontend/design-system';
import { isUUID } from '../../views/Calendar/utils';
import { AppointmentEventsCarousel } from './appointments-carousel';
import { EventCard } from './event-card';
import { useCalendarView, useCalendarViewProps } from './providers';
import { timelineReducer, initialTimelineState } from './reducers';
import { CalendarViewOffices, EventData } from './types';
import {
  generateHourLabels,
  calculateTimeProgress,
  scrollContainerToPercentage,
  getTimestamp,
  calculatePercentage,
  checkIntersection,
  zIndex,
  EVENT_CARD_DEFAULT_MEASUREMENTS,
  groupOverlappingEvents,
  getEventWidthRatio,
  createGridColumnsFromStartAndEndTimestamp,
  adjustedOneHourInMinutes,
  adjustedOneMinuteInMilliseconds,
  ADJUSTED_ONE_HOUR_IN_MINUTES,
} from './utils';

export const CalendarViewMain = () => {
  const { startHour, endHour, data, id, NoProviderComponent } = useCalendarViewProps();
  const calendarViewWrapperRef = useRef<ElementRef<'article'>>(null);
  const hourLabels = generateHourLabels(startHour, endHour);
  const numberOfHours = hourLabels.length;
  const { isVerticalView } = useCalendarView(['isVerticalView', 'setZoomedIn', 'isZoomedIn']);

  useLayoutEffect(() => {
    if (calendarViewWrapperRef.current) {
      const calendarViewWrapper = calendarViewWrapperRef.current;
      const { scrollWidth, clientWidth, clientHeight, scrollHeight } = calendarViewWrapper;

      if (clientWidth < scrollWidth || clientHeight < scrollHeight) {
        scrollContainerToPercentage(
          calculateTimeProgress(numberOfHours).progressPercentage,
          calendarViewWrapper,
          isVerticalView
        );
      }
    }
  }, [isVerticalView]);

  const hasProviders = data?.filter((office) => office?.providers?.length > 0)?.length > 0;

  return (
    <>
      <CalendarViewMainWrapper containerRef={calendarViewWrapperRef} id={id}>
        {!hasProviders && NoProviderComponent && <NoProviderComponent />}
        <MultiOfficeProvidersContainer>
          <MultiOfficeProvidersScrollableWrapper containerRef={calendarViewWrapperRef}>
            <MultiOfficeProviders multiOfficeProviders={data} />
          </MultiOfficeProvidersScrollableWrapper>
        </MultiOfficeProvidersContainer>
        <CalendarViewContainer numberOfHours={numberOfHours} hasProviders={hasProviders}>
          <CalendarScrollableWrapper containerRef={calendarViewWrapperRef}>
            <BackgroundOverlay />
            <HoursOfTheDay hourLabels={hourLabels} />
            <article
              css={[
                css`
                  grid-column: 1/-1;
                `,
                !isVerticalView &&
                  css`
                    display: grid;
                    grid-row: 2;
                    grid-auto-rows: auto;
                  `,
                isVerticalView &&
                  css`
                    grid-row: 1 / -1;
                    padding-left: ${theme.spacing(10)};
                    display: flex;
                    gap: 19px;
                  `,
              ]}
            >
              {data &&
                data?.map((office) => {
                  return (
                    <CalendarEventGridSection key={office.officeName}>
                      <EventTimelineWrapper
                        numberOfHours={numberOfHours}
                        locationId={office.locationId}
                        calendarDateValue={office.calendarDateValue}
                      >
                        {office.officeBreaks?.map((breakEvent) => {
                          return (
                            <EventCard
                              key={breakEvent.eventId}
                              ref={calendarViewWrapperRef}
                              id={breakEvent.eventId}
                              title={breakEvent.name}
                              locationName={office.officeName}
                              type={breakEvent.id ? 'outOfOffice' : 'break'}
                              startHour={breakEvent.startHour}
                              endHour={breakEvent.endHour}
                              locationId={office.locationId}
                            />
                          );
                        })}
                      </EventTimelineWrapper>
                      {office &&
                        office.providers &&
                        office.providers.map((provider) => {
                          return (
                            <EventTimelineWrapper
                              key={provider.providerId}
                              numberOfHours={numberOfHours}
                              providerName={provider.name}
                              providerId={provider.providerId}
                              locationId={office.locationId}
                              calendarDateValue={office.calendarDateValue}
                            >
                              <EventCards
                                events={provider.events}
                                providerId={provider.providerId}
                                providerName={provider.name}
                                locationName={office.officeName}
                                calendarViewWrapperRef={calendarViewWrapperRef}
                              />
                            </EventTimelineWrapper>
                          );
                        })}
                    </CalendarEventGridSection>
                  );
                })}
            </article>
            <CalendarEventHourSections numberOfHours={numberOfHours}>
              <HoveredTimeProgressLine numberOfHours={numberOfHours} />
              <TimeProgress numberOfHours={numberOfHours} />
              {hourLabels.map((hourLabels, idx) => (
                <motion.div
                  layout='position'
                  key={hourLabels + idx}
                  css={[
                    css`
                      background: ${theme.colors.neutral20};
                    `,
                    !isVerticalView &&
                      css`
                        width: 1px;
                      `,
                    isVerticalView &&
                      css`
                        height: 1px;
                        z-index: 0;
                      `,
                  ]}
                />
              ))}
            </CalendarEventHourSections>
          </CalendarScrollableWrapper>
        </CalendarViewContainer>
      </CalendarViewMainWrapper>
      <AppointmentEventsCarousel />
      <ZoomButton calendarViewWrapperRef={calendarViewWrapperRef} />
    </>
  );
};

interface EventCardsProps {
  events: EventData[];
  providerId: string;
  providerName: string;
  locationName: string;
  calendarViewWrapperRef: React.RefObject<ElementRef<'article'>>;
}

const MAX_OVERLAPPING_EVENTS_VERTICAL = 4;
const MAX_OVERLAPPING_EVENTS_HORIZONTAL = 2;

const EventCards: React.FC<EventCardsProps> = ({
  events,
  providerId,
  providerName,
  locationName,
  calendarViewWrapperRef,
}) => {
  // This should be done on the backend, as it's not a concern of the frontend
  const { isVerticalView } = useCalendarView(['isVerticalView']);
  const MAX_OVERLAPPING_EVENTS = isVerticalView ? MAX_OVERLAPPING_EVENTS_VERTICAL : MAX_OVERLAPPING_EVENTS_HORIZONTAL;

  const sortedEvents = useMemo(
    () => events.sort((a, b) => dayjs(a.startHour, 'h:mm A').diff(dayjs(b.startHour, 'h:mm A'))),
    [events]
  );
  const groupEvents = useMemo(() => groupOverlappingEvents(sortedEvents, MAX_OVERLAPPING_EVENTS), [sortedEvents]);

  return (
    <>
      {groupEvents.map((events) => {
        return events.length <= MAX_OVERLAPPING_EVENTS ? (
          events.map((event, idx) => {
            const isOutOfOfficeEvent = event.type === 'unavailable' && event.id;
            const eventType = isOutOfOfficeEvent ? 'outOfOffice' : event.id ? 'appointment' : 'break';
            const widthFactor = getEventWidthRatio(idx);
            const eventOverlaps = events.length > 1;

            return (
              <EventCard
                index={idx}
                key={event.eventId}
                ref={calendarViewWrapperRef}
                id={event.eventId}
                title={event.name}
                startHour={event.startHour}
                endHour={event.endHour}
                type={eventType}
                eventOverlaps={eventOverlaps}
                widthFactor={widthFactor}
                providerId={providerId}
                locationName={locationName}
                providerName={providerName}
                locationId={event.locationId}
              />
            );
          })
        ) : (
          <OverlappingEvents
            key={events[0].eventId}
            overlappingEvents={events}
            containerRef={calendarViewWrapperRef}
            eventLength={events.length}
            providerName={providerName}
            locationName={locationName}
          />
        );
      })}
    </>
  );
};

type ScrollableWrapperProps = {
  children: React.ReactNode;
  containerRef: React.RefObject<ElementRef<'article'>>;
};

const calendarScrollButtonStyles = (isVerticalView: boolean) =>
  css({
    position: 'fixed',
    opacity: 0.8,
    zIndex: 9999,
    width: 44,
    height: 44,
    borderRadius: theme.borderRadius.full,
    background: theme.colors.neutral10,
    border: `1px solid ${theme.colors.neutral20}`,
    top: isVerticalView ? '60%' : '56%',
    ': hover': {
      border: `1px solid ${theme.colors.primary50}`,
    },
  });

const CalendarScrollableWrapper = ({ children, containerRef }: ScrollableWrapperProps) => {
  const { matches } = useMediaMatches();
  const isMobileScreen = !matches.mediumMin();
  const {
    isVerticalView,
    containerState: { isLeft, isRight },
  } = useCalendarView(['isVerticalView', 'containerState']);

  return (
    <>
      {!isLeft && (
        <NakedButton
          css={[
            calendarScrollButtonStyles(isVerticalView),
            {
              marginLeft: isVerticalView ? 16 : -16,
            },
          ]}
        >
          <ScrollableButton
            isCircularButton
            backgroundColor='neutral5'
            disabled={isLeft}
            direction='left'
            containerRef={containerRef}
          />
        </NakedButton>
      )}

      {children}
      {!isRight && (
        <NakedButton
          css={[
            calendarScrollButtonStyles(isVerticalView),
            {
              right: isMobileScreen ? 16 : 72,
            },
          ]}
        >
          <ScrollableButton
            isCircularButton
            backgroundColor='neutral5'
            disabled={isRight}
            direction='right'
            containerRef={containerRef}
          />
        </NakedButton>
      )}
    </>
  );
};

const MultiOfficeProvidersScrollableWrapper = ({ children, containerRef }: ScrollableWrapperProps) => {
  const {
    isVerticalView,
    containerState: { isLeft, isRight, isScrollable },
  } = useCalendarView(['isVerticalView', 'containerState']);

  return (
    <>
      <TopCover>
        {isVerticalView && isScrollable && (
          <ScrollableButton disabled={isLeft} direction='left' containerRef={containerRef} />
        )}
      </TopCover>
      {children}
      {isVerticalView && (
        <div
          css={css`
            position: sticky;
            top: 0;
            right: 0;
            width: 40px;
            height: 100%;
            z-index: 100;
            background: ${theme.colors.white};
            display: flex;
            justify-content: center;
          `}
        >
          {isScrollable && <ScrollableButton disabled={isRight} direction='right' containerRef={containerRef} />}
        </div>
      )}
    </>
  );
};

type OverlappingEventsProps = {
  overlappingEvents: EventData[];
  eventLength: number;
  containerRef: React.RefObject<ElementRef<'article'>>;
  locationName: string;
  providerName: string;
};

const OverlappingEvents = ({ overlappingEvents, providerName, locationName, eventLength }: OverlappingEventsProps) => {
  const { isVerticalView, setEventCarouselData, isZoomedIn } = useCalendarView([
    'isVerticalView',
    'setEventCarouselData',
    'isZoomedIn',
  ]);
  const { startHour: calendarStartHour, endHour: calendarEndHour } = useCalendarViewProps();

  const { startHour, endHour } = useMemo(
    () => ({ startHour: overlappingEvents?.[0]?.startHour, endHour: overlappingEvents?.[0]?.endHour }),
    [overlappingEvents]
  );

  if (startHour === 'undefined') return null;

  const { startGridColumn } = createGridColumnsFromStartAndEndTimestamp(
    calendarStartHour,
    calendarEndHour,
    !isVerticalView && isZoomedIn
  )({
    startHourTimestamp: startHour,
    endHourTimeStamp: endHour,
  });

  return (
    <>
      <NakedButton
        onClick={(e) => {
          e.stopPropagation();
          setEventCarouselData({ events: overlappingEvents, locationName, providerName, isOpen: true });
        }}
        onMouseDown={(e) => e.stopPropagation()}
        css={[
          css`
            background-color: white;
            width: 40px;
            height: 40px;
            box-shadow: ${theme.shadows.light};
            border-radius: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: ${theme.font.size.large};
            font-weight: bold;
            z-index: ${zIndex.eventCardDefault};
            user-select: none;
            cursor: pointer;
            transition: scale 0.3s ease;
            :hover {
              scale: 1.2;
            }
          `,
          isVerticalView &&
            css`
              grid-column: 1;
              grid-row: ${startGridColumn};
            `,
          !isVerticalView &&
            css`
              grid-column: ${startGridColumn};
              grid-row: 1;
            `,
        ]}
      >
        {eventLength}
      </NakedButton>
    </>
  );
};

type CalendarViewMainWrapperProps = {
  children: React.ReactNode;
  containerRef: React.RefObject<ElementRef<'article'>>;
  id?: string;
};

const CalendarViewMainWrapper = ({ children, containerRef, id }: CalendarViewMainWrapperProps) => {
  const { clientWidth, isLeft, isRight, isScrollable, scrollRef } = useScrollShadow(true);
  const { setClientWidth, setContainerState, isVerticalView } = useCalendarView([
    'setClientWidth',
    'setContainerState',
    'isVerticalView',
  ]);

  useLayoutEffect(() => {
    setContainerState({ isLeft, isRight, isScrollable });
  }, [isLeft, isRight, isScrollable]);

  useLayoutEffect(() => {
    setClientWidth(clientWidth);
  }, [clientWidth]);

  return (
    <motion.article
      id={id}
      layoutScroll
      ref={composeRefs(containerRef, scrollRef)}
      css={[
        css`
          overflow: auto;
          isolation: isolate;
          height: 100%;
          width: 100%;
          display: grid;
          grid-template-columns: auto 1fr;
          background-color: ${theme.colors.neutral5};
          position: relative;
          overscroll-behavior: contain;
        `,
        isVerticalView &&
          css`
            grid-template-rows: auto 1fr;
            grid-template-columns: auto;
            flex: 1;
          `,
      ]}
    >
      {children}
    </motion.article>
  );
};

const BackgroundOverlay = () => {
  const { backgroundView, isVerticalView } = useCalendarView(['backgroundView', 'isVerticalView']);

  return (
    <AnimatePresence>
      {backgroundView ? (
        <motion.article
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          css={[
            css`
              background-color: rgba(0, 33, 82, 0.1);
              z-index: ${zIndex.backgroundOverlay};
              width: calc(100% + ${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px);
              height: 100%;
              position: absolute;
              left: 0;
              margin-left: -${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px;
              top: 0;
            `,
            isVerticalView &&
              css`
                height: calc(100% + ${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px);
                width: calc(100% + ${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px);
                margin-top: -${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px;
              `,
          ]}
        />
      ) : null}
    </AnimatePresence>
  );
};

type TopCoverProps = {
  children: React.ReactNode;
};

const TopCover = ({ children }: TopCoverProps) => {
  const { isVerticalView, setIsVerticalView } = useCalendarView(['isVerticalView', 'setIsVerticalView']);

  return (
    <div
      css={[
        css`
          position: sticky;
          top: 0;
          background: ${theme.colors.white};
          z-index: 100;
          display: flex;
        `,
        !isVerticalView &&
          css`
            height: 54px;
            width: 100%;
            justify-content: center;
            align-items: center;
          `,
        isVerticalView &&
          css`
            width: 100px;
            margin-right: 27px;
            left: 0;
            height: 100%;
            z-index: 100;
            padding-left: ${theme.spacing(2)};
            justify-content: space-between;
          `,
      ]}
    >
      <span
        css={css`
          height: 100%;
          display: flex;
          align-items: center;
        `}
      >
        <NakedButton
          css={css`
            border: 1px solid ${theme.colors.neutral30};
            width: 32px;
            height: 32px;
            border-radius: ${theme.borderRadius.small};
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          onClick={() => setIsVerticalView(!isVerticalView)}
        >
          <Icon
            css={css`
              color: ${theme.colors.neutral70};
            `}
            name={isVerticalView ? 'calendar-view-vertical-small' : 'calendar-view-horizontal-small'}
          />
        </NakedButton>
      </span>
      {children}
    </div>
  );
};

type MultiOfficeProvidersContainerProps = {
  children: React.ReactNode;
};

const MultiOfficeProvidersContainer = ({ children }: MultiOfficeProvidersContainerProps) => {
  const { isVerticalView } = useCalendarView(['isVerticalView']);

  return (
    <motion.section
      css={[
        css`
          box-shadow: ${theme.shadows.floating};
          border-right: 1px solid ${theme.colors.neutral20};
          position: sticky;
          background: ${theme.colors.white};
          z-index: ${zIndex.providerSection};
        `,
        !isVerticalView &&
          css`
            left: 0;
            grid-row: 1 / -1;
            width: 72px;
          `,
        isVerticalView &&
          css`
            width: 100%;
            border-right: none;
            transition: height 0.3s ease;
            height: ${theme.spacing(19)};
            top: 0;
            grid-column: 1 / -1;
            display: flex;
            gap: 19px;
          `,
      ]}
    >
      {children}
    </motion.section>
  );
};

type ScrollableButtonProps = {
  containerRef: React.RefObject<ElementRef<'article'>>;
  disabled?: boolean;
  direction: 'left' | 'right';
  backgroundColor?: WeaveThemeColors;
  isCircularButton?: boolean;
};

const ScrollableButton = ({
  containerRef,
  disabled,
  direction,
  backgroundColor = 'white',
  isCircularButton = false,
}: ScrollableButtonProps) => {
  const isLeft = direction === 'left';

  const handleScroll = () => {
    if (containerRef.current === null) return;
    const scrollAmount = isLeft ? -containerRef.current.clientWidth : containerRef.current.clientWidth;
    containerRef.current.scrollTo({
      left: containerRef.current.scrollLeft + scrollAmount,
      behavior: 'smooth',
    });
  };

  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        top: 0;
        width: 40px;
        align-items: center;
        justify-content: center;
        background: ${backgroundColor};
        z-index: 100;
        ${isCircularButton ? `border-radius: ${theme.borderRadius.full}` : ''}
      `}
    >
      <NakedButton
        css={css`
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background 0.3s ease;
          ${isCircularButton ? `border-radius: ${theme.borderRadius.full}` : ''}
          :hover:not(:disabled) {
            background: ${theme.colors.neutral5};
          }

          :disabled {
            cursor: not-allowed;
          }
        `}
        disabled={disabled}
        onClick={handleScroll}
      >
        <Icon name={isLeft ? 'caret-left' : 'caret-right'} color={disabled ? 'disabled' : 'subdued'} />
      </NakedButton>
    </div>
  );
};

type CalendarViewContainerProps = PropsWithChildren<{
  numberOfHours: number;
  hasProviders?: boolean;
}>;
const CalendarViewContainer = ({ children, numberOfHours, hasProviders = true }: CalendarViewContainerProps) => {
  const hoveredTimestamp = useRef('0');
  const [isOpen, setIsOpen] = useState(false);

  const { startHour, endHour } = useCalendarViewProps();
  const { backgroundView, setHoveredTimeProgressPosition, isVerticalView, isZoomedIn, floatingTooltipData } =
    useCalendarView([
      'backgroundView',
      'setHoveredTimeProgressPosition',
      'isVerticalView',
      'isZoomedIn',
      'floatingTooltipData',
    ]);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen && !backgroundView,
    placement: 'top',
    middleware: [offset(20), flip(), shift()],
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  });

  const clientPoint = useClientPoint(context);
  const hover = useHover(context, { restMs: 1000 });
  const { getReferenceProps, getFloatingProps } = useInteractions([clientPoint, hover]);

  return (
    <>
      <section
        ref={refs.setReference}
        {...getReferenceProps({
          onMouseDown: () => setIsOpen(false),
          onMouseMove: (event) => {
            const timestamp = getTimestamp({
              event,
              element: refs.domReference.current,
              startHour,
              endHour,
              isVerticalView,
              isZoomedIn: !isVerticalView && isZoomedIn,
            });

            if (!timestamp) return;

            const { formattedTime, totalMinutes } = timestamp;

            setHoveredTimeProgressPosition(totalMinutes);
            hoveredTimestamp.current = formattedTime;
          },
        })}
        css={[
          css`
            display: grid;
            position: relative;
            background-color: ${theme.colors.neutral5};
          `,
          !isVerticalView &&
            css`
              margin-left: ${theme.spacing(5)};
              grid-template-columns: repeat(${numberOfHours}, minmax(${adjustedOneHourInMinutes(isZoomedIn)}px, 1fr));
              grid-template-rows: auto auto 1fr;
              height: 100%;
            `,
          isVerticalView &&
            css`
              margin-top: ${theme.spacing(5)};
              grid-template-rows: repeat(${numberOfHours}, 120px);
              grid-template-columns: auto auto 1fr;
              width: 100%;
            `,
          !hasProviders && { overflow: 'hidden' },
        ]}
      >
        {children}
      </section>
      <AnimatePresence>
        {isOpen && !backgroundView ? (
          <motion.div
            ref={refs.setFloating}
            layout={false}
            style={floatingStyles}
            {...getFloatingProps()}
            css={css`
              pointer-events: none;
              z-index: ${theme.zIndex.hints};
            `}
          >
            <TimeTooltip>
              {floatingTooltipData ? (
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <span
                    css={css`
                      font-weight: ${theme.font.weight.bold};
                    `}
                  >
                    {floatingTooltipData.name}
                  </span>
                  <span
                    css={css`
                      font-size: ${theme.font.size.small};
                    `}
                  >
                    {floatingTooltipData.value}
                  </span>
                </div>
              ) : (
                hoveredTimestamp.current
              )}
            </TimeTooltip>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
};

type CalendarEventHourSectionsProps = {
  children: React.ReactNode;
  numberOfHours: number;
};
const CalendarEventHourSections = ({ children, numberOfHours }: CalendarEventHourSectionsProps) => {
  const { isVerticalView, isZoomedIn } = useCalendarView(['isVerticalView', 'isZoomedIn']);

  return (
    <div
      css={[
        css`
          display: grid;
          grid-row: 1/-1;
          grid-column: 1/-1;
          position: relative;
          background-color: ${theme.colors.neutral5};
        `,
        !isVerticalView &&
          css`
            grid-template-columns: repeat(${numberOfHours}, minmax(${adjustedOneHourInMinutes(isZoomedIn)}px, 1fr));
          `,
        isVerticalView &&
          css`
            grid-template-rows: repeat(${numberOfHours}, minmax(${ADJUSTED_ONE_HOUR_IN_MINUTES}px, 1fr));
            z-index: 0;
          `,
      ]}
    >
      {children}
    </div>
  );
};

type CalendarEventGridSectionProps = {
  children: React.ReactNode;
};

const CalendarEventGridSection = ({ children }: CalendarEventGridSectionProps) => {
  const { isVerticalView } = useCalendarView(['isVerticalView']);

  return (
    <section
      css={[
        isVerticalView &&
          css`
            margin-left: 66px;
            height: 100%;
            display: flex;
            column-gap: 19px;

            :not(:first-of-type) {
              margin-left: 0;
            }
          `,
        !isVerticalView &&
          css`
            margin-top: ${theme.spacing(2.5)};
            width: 100%;
          `,
      ]}
    >
      {children}
    </section>
  );
};

type EventTimelineWrapper = {
  children?: React.ReactNode;
  numberOfHours: number;
  providerName?: string;
  providerId?: string;
  locationId?: string;
  calendarDateValue?: string;
};

const EventTimelineWrapper = ({
  children,
  numberOfHours,
  providerName,
  providerId,
  locationId,
  calendarDateValue,
}: EventTimelineWrapper) => {
  const { startHour, endHour, onEventCreate, shouldAllowEventCreation } = useCalendarViewProps();
  const { eventTransitionComplete, isVerticalView, eventCarouselData, isZoomedIn } = useCalendarView([
    'eventTransitionComplete',
    'isVerticalView',
    'eventCarouselData',
    'isZoomedIn',
  ]);
  const [{ isActivated: isEventActivated, eventStartX, eventStartTime, eventEndX, eventEndTime }, dispatch] =
    useReducer(timelineReducer, initialTimelineState);

  const isActivated = isEventActivated && !eventCarouselData.isOpen;
  const [isIntersecting, setIntersecting] = useState(false);
  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarViewMain');

  const timelineRef = useRef<ElementRef<'section'>>(null);
  const eventBoxRef = useRef<ElementRef<'div'>>(null);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (eventTransitionComplete || !shouldAllowEventCreation) return;
    const shouldRound = !e.shiftKey;
    const timestamp = getTimestamp({
      event: e,
      element: timelineRef.current,
      nearestQuarter: shouldRound,
      startHour,
      endHour,
      isVerticalView,
      isZoomedIn: !isVerticalView && isZoomedIn,
    });

    if (!timestamp) return;

    const { formattedTime, totalMinutes } = timestamp;

    dispatch({
      type: 'ACTIVATE',
      payload: {
        eventStartX: totalMinutes,
        eventEndX: totalMinutes,
        eventStartTime: formattedTime,
        eventEndTime: formattedTime,
      },
    });
  };

  function handleMouseMove(e: MouseEvent) {
    if (!eventBoxRef.current || !timelineRef.current || !shouldAllowEventCreation) return;

    setIntersecting(
      checkIntersection({
        targetElement: eventBoxRef.current,
        containerElement: timelineRef.current,
      })
    );

    const shouldRound = !e.shiftKey;

    const timestamp = getTimestamp({
      event: e,
      element: timelineRef.current,
      nearestQuarter: shouldRound,
      startHour,
      endHour,
      isVerticalView,
      isZoomedIn: !isVerticalView && isZoomedIn,
    });

    if (!timestamp) return;

    const { formattedTime, totalMinutes } = timestamp;

    dispatch({
      type: 'UPDATE_POSITION',
      payload: {
        eventEndX: totalMinutes,
        eventEndTime: formattedTime,
      },
    });
  }

  const handleMouseUp = () => {
    if (!!providerId && !isUUID(providerId ?? '') && shouldAllowEventCreation) {
      !isIntersecting && alert.error(t('Event cannot be created. This provider does not have a provider ID'));
    } else {
      onEventCreate?.(
        eventStartX > eventEndX ? eventEndTime : eventStartTime,
        eventStartX > eventEndX ? eventStartTime : eventEndTime,
        providerName,
        providerId,
        !isIntersecting,
        locationId ?? '',
        calendarDateValue ?? ''
      );
    }

    dispatch({
      type: 'DEACTIVATE',
    });
  };

  useEventListener('mousemove', handleMouseMove, isActivated);
  useEventListener('mouseup', handleMouseUp, isActivated);

  const disableEventCreate = isIntersecting || (!!providerId && !isUUID(providerId ?? ''));

  return (
    <section
      ref={timelineRef}
      onMouseDown={handleMouseDown}
      css={[
        css`
          display: grid;
          width: 100%;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            height: 100%;
            transition: 0.2s ease;
            opacity: 0;
            background-color: ${transparentize(0.5, theme.colors.primary10)};
          }
        `,
        !isVerticalView &&
          css`
            grid-template-columns: repeat(${numberOfHours * adjustedOneHourInMinutes(isZoomedIn)}, minmax(1px, 1fr));
            grid-template-rows: ${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px;
            padding: ${theme.spacing(1, 0)};
            &::after {
              border-top: 1px dashed ${theme.colors.primary20};
              border-bottom: 1px dashed ${theme.colors.primary20};
              margin-left: -40px;
              width: calc(100% + 40px);
            }
          `,
        isVerticalView &&
          css`
            grid-template-rows: repeat(${numberOfHours * ADJUSTED_ONE_HOUR_IN_MINUTES}, 1px);
            grid-template-columns: 172px;
            max-width: 172px;
            &::after {
              border-left: 1px dashed ${theme.colors.primary20};
              border-right: 1px dashed ${theme.colors.primary20};
              height: calc(100% + 40px);
              margin-top: -40px;

              width: 100%;
            }
          `,
        eventTransitionComplete &&
          css`
            &::after {
              transition: none;
            }
          `,
        !eventTransitionComplete &&
          css`
            &:hover::after {
              opacity: 1;
            }
            z-index: ${zIndex.eventTimelineWrapper};
          `,
      ]}
    >
      {children}
      <AnimatePresence>
        {isActivated && (
          <motion.div
            ref={eventBoxRef}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              background: `${
                disableEventCreate ? transparentize(0.2, theme.colors.critical20) : theme.colors.primary20
              }`,
              border: `1px dashed ${
                disableEventCreate ? transparentize(0.2, theme.colors.critical50) : theme.colors.primary40
              }`,
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            css={[
              css`
                position: relative;
                z-index: ${zIndex.eventCreatorCard};
                border-radius: 4px;
                user-select: none;
                background: ${isIntersecting ? theme.colors.critical20 : theme.colors.primary20};
                border: 1px solid ${isIntersecting ? theme.colors.critical50 : theme.colors.primary40};
              `,
              !isVerticalView &&
                css`
                  height: ${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px;
                  grid-row: 1;
                  grid-column: ${eventStartX + 1} / ${eventEndX + 1};
                `,
              isVerticalView &&
                css`
                  grid-column: 1;
                  grid-row: ${eventStartX + 1} / ${eventEndX + 1};
                `,
            ]}
          >
            <motion.div
              css={css`
                pointer-events: none;
                z-index: ${theme.zIndex.hints};
                position: absolute;
                left: 50%;
                top: -60px;
                transform: translateX(-50%);
                width: auto;
              `}
            >
              <TimeTooltip>
                {eventEndX - eventStartX < 0
                  ? `${eventEndTime} - ${eventStartTime}`
                  : `${eventStartTime} - ${eventEndTime}`}
              </TimeTooltip>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

type HoursOfTheDayProps = {
  hourLabels: string[];
};
const HoursOfTheDay = ({ hourLabels }: HoursOfTheDayProps) => {
  const numberOfHours = hourLabels.length;
  const { isVerticalView, isZoomedIn } = useCalendarView(['isVerticalView', 'isZoomedIn']);

  return (
    <motion.section
      layout='position'
      css={[
        css`
          position: sticky;
          z-index: ${zIndex.hoursOfTheDay};
          display: grid;
          grid-column: 1/-1;
          background-color: ${theme.colors.neutral5};
        `,
        !isVerticalView &&
          css`
            padding-top: ${theme.spacing(3)};
            height: 54px;
            grid-template-columns: repeat(${numberOfHours}, minmax(${adjustedOneHourInMinutes(isZoomedIn)}px, 1fr));
            grid-row: 1 / auto;
            top: 0;
          `,
        isVerticalView &&
          css`
            grid-template-rows: repeat(${numberOfHours}, ${ADJUSTED_ONE_HOUR_IN_MINUTES}px);
            grid-row: 1 / -1;
            height: calc(100% + 40px);
            margin-top: -40px;
            padding-top: 40px;
            width: 76px;
            left: 0;
          `,
      ]}
    >
      {hourLabels.map((hour, index) => (
        <motion.div
          layout='position'
          key={hour + index}
          css={[
            css`
              display: flex;
            `,
            !isVerticalView &&
              css`
                justify-content: center;
              `,
            isVerticalView &&
              css`
                justify-content: flex-end;
                padding-right: ${theme.spacing(2)};
              `,
          ]}
        >
          <Text
            css={[
              css`
                margin: 0;
                user-select: none;
              `,
              !isVerticalView &&
                css`
                  margin-left: -100%;
                `,
              isVerticalView &&
                css`
                  margin-top: -9px;
                `,
            ]}
            size='small'
            color='light'
          >
            {hour}
          </Text>
        </motion.div>
      ))}
    </motion.section>
  );
};

type MultiOfficeProviders = {
  multiOfficeProviders: CalendarViewOffices;
};

const MultiOfficeProviders = ({ multiOfficeProviders }: MultiOfficeProviders) => {
  const { isVerticalView } = useCalendarView(['isVerticalView']);

  return (
    <>
      {multiOfficeProviders &&
        multiOfficeProviders?.map((provider, index) => (
          <section
            key={provider.officeName}
            css={[
              isVerticalView &&
                css`
                  display: flex;
                  height: 150px;
                  position: relative;
                  transition: height 0.3s ease;
                  ${index !== 0 &&
                  `::after {
                    content: '';
                    position: absolute;
                    width: 2px;
                    height: 60px;
                    background: ${theme.colors.neutral5};
                    top: ${theme.spacing(1)};
                    left: -10px;
                  }`};
                `,
              !isVerticalView &&
                css`
                  margin-top: ${theme.spacing(2.5)};
                `,
            ]}
          >
            <MultiOfficeSectionWrapper officeName={provider.officeName}>
              {isVerticalView && (
                <>
                  <section
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 172px;
                      max-width: 172px;
                      overflow: hidden;
                      height: 100%;
                      transition: height 0.3s ease;
                      padding: ${theme.spacing(1)};
                      padding-top: ${theme.spacing(2)};
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        width: 100%;
                      `}
                    >
                      <span
                        css={css`
                          display: block;
                          height: 32px;
                          width: 32px;
                          border-radius: 10px; //We don't have a theme value for this
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          background-color: ${theme.colors.neutral5};
                          margin-bottom: ${theme.spacing(1)};
                          overflow: hidden;
                        `}
                      >
                        <Icon name='location' color='subdued' />
                      </span>
                      <Text
                        css={[
                          styles.truncate,
                          css`
                            width: 100%;
                            flex-grow: 0;
                          `,
                        ]}
                        textAlign='center'
                        weight='bold'
                      >
                        {provider?.officeName}
                      </Text>
                    </div>
                  </section>
                </>
              )}
              <OfficeProviders
                officeName={provider?.officeName}
                providersData={provider?.providers?.map((provider) => {
                  return { name: provider.name, src: provider.src };
                })}
              />
            </MultiOfficeSectionWrapper>
          </section>
        ))}
    </>
  );
};

type MultiOfficeSectionWrapperProps = {
  children: React.ReactNode;
  officeName: string;
};

const MultiOfficeSectionWrapper = ({ children, officeName }: MultiOfficeSectionWrapperProps) => {
  const { isVerticalView, clientWidth } = useCalendarView(['isVerticalView', 'clientWidth']);

  return isVerticalView ? (
    <div>
      <div
        css={css`
          height: 36px;
          width: 100%;
          margin-top: ${theme.spacing(1)};
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            max-width: ${clientWidth - 140}px;
            position: sticky;
            background: ${theme.colors.neutral5};
            margin-top: ${theme.spacing(0.5)};
            border-radius: ${theme.borderRadius.medium};
            left: 100px;
            height: 100%;
            padding: ${theme.spacing(0, 2)};
          `}
        >
          <SlidingOfficeName officeName={officeName} />
        </div>
      </div>
      <section
        css={css`
          display: flex;
          gap: 19px;
        `}
      >
        {children}
      </section>
    </div>
  ) : (
    <>{children}</>
  );
};

type SlidingOfficeName = {
  officeName: string;
};

const SlidingOfficeName = ({ officeName }: SlidingOfficeName) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing(0.5)};
      position: sticky;
      left: 116px;
      right: 56px;
    `}
  >
    <Icon name='location' color='subdued' />
    <Text
      css={[
        styles.truncate,
        css`
          max-width: 120px;
        `,
      ]}
      textAlign='center'
      size='medium'
      weight='bold'
      color='subdued'
    >
      {officeName}
    </Text>
  </div>
);

type OfficeProviders = {
  providersData: { name: string; src: string }[];
  officeName: string;
};
const OfficeProviders = ({ providersData, officeName }: OfficeProviders) => {
  const { isVerticalView } = useCalendarView(['isVerticalView']);

  return (
    <>
      {!isVerticalView && (
        <section
          css={css`
            display: flex;
            justify-content: center;
            height: 56px;
            align-items: center;
            padding: ${theme.spacing(1)};
          `}
        >
          <OfficeName>{officeName}</OfficeName>
        </section>
      )}
      {providersData &&
        providersData.map((providerData, idx) => (
          <section
            key={providerData.name + idx}
            css={[
              css`
                display: flex;
              `,
              !isVerticalView &&
                css`
                  justify-content: center;
                  padding: ${theme.spacing(1)};
                `,
              isVerticalView &&
                css`
                  width: 172px;
                  max-width: 172px;
                  overflow: hidden;
                  align-items: center;
                  flex-direction: column;
                  padding: ${theme.spacing(1.5)};
                `,
            ]}
          >
            <ProviderAvatar {...providerData} />
            {isVerticalView && (
              <div
                css={css`
                  margin-top: 10px;
                  overflow: hidden;
                  width: 100%;
                `}
              >
                <Text
                  textAlign='center'
                  size='medium'
                  weight='bold'
                  css={[
                    css`
                      margin-bottom: ${theme.spacing(1)};
                      min-width: 0;
                      flex: 0;
                    `,
                    styles.truncate,
                  ]}
                >
                  {providerData.name}
                </Text>
              </div>
            )}
          </section>
        ))}
    </>
  );
};

type OfficeNameProps = {
  children: React.ReactNode;
};
const OfficeName = ({ children }: OfficeNameProps) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'bottom', hoverDelay: 300 });

  return (
    <>
      <Text
        size='small'
        css={[
          css`
            margin: 0;
            border-radius: ${theme.borderRadius.small};
            padding: ${theme.spacing(0.25, 1)};
            background: ${theme.colors.neutral5};
          `,
          styles.truncate,
        ]}
        {...triggerProps}
      >
        {children}
      </Text>
      <Tooltip {...tooltipProps}>{children}</Tooltip>
    </>
  );
};

type ProviderAvatarProps = Omit<AvatarProps, 'size'>;
const ProviderAvatar = (props: ProviderAvatarProps) => {
  return (
    <Avatar
      {...props}
      isUser
      css={css`
        place-self: center;
        justify-content: center;
      `}
    />
  );
};

type TimeProgressProps = {
  numberOfHours: number;
};

const TimeProgress = ({ numberOfHours }: TimeProgressProps) => {
  const { isVerticalView, isZoomedIn } = useCalendarView(['isVerticalView', 'isZoomedIn']);

  const [timeProgressPercentage, setTimeProgressPercentage] = useState(
    calculateTimeProgress(numberOfHours).progressPercentage
  );
  const [hasCrossedTotalDuration, setHasCrossedTotalDuration] = useState(
    calculateTimeProgress(numberOfHours).hasCrossedTotalDuration
  );

  useInterval(
    () => {
      const { hasCrossedTotalDuration, progressPercentage } = calculateTimeProgress(numberOfHours);
      setTimeProgressPercentage(progressPercentage);
      setHasCrossedTotalDuration(hasCrossedTotalDuration);
    },
    adjustedOneMinuteInMilliseconds(isZoomedIn && !isVerticalView),
    hasCrossedTotalDuration
  );

  return (
    <ProgressLine show={!hasCrossedTotalDuration} percentage={timeProgressPercentage} color={theme.colors.primary50} />
  );
};

const HoveredTimeProgressLine = ({ numberOfHours }: { numberOfHours: number }) => {
  const { hoveredTimeProgressPosition, backgroundView, isZoomedIn, isVerticalView } = useCalendarView([
    'hoveredTimeProgressPosition',
    'backgroundView',
    'isZoomedIn',
    'isVerticalView',
  ]);
  const progressLinePosition = calculatePercentage(
    hoveredTimeProgressPosition,
    numberOfHours * adjustedOneHourInMinutes(isZoomedIn && !isVerticalView)
  );

  return (
    <ProgressLine
      show={!backgroundView && progressLinePosition < 99.5}
      percentage={progressLinePosition}
      color={theme.colors.critical40}
    />
  );
};

type ProgressLineProps = {
  percentage: number;
  show?: boolean;
  color: string;
};

const ProgressLine = ({ percentage, show = true, color }: ProgressLineProps) => {
  const { isVerticalView } = useCalendarView(['isVerticalView']);

  return show ? (
    <div
      css={[
        css`
          position: absolute;
          z-index: ${zIndex.progressLine};
        `,
        !isVerticalView &&
          css`
            height: 100%;
            width: 1.25px;
            left: ${percentage}%;
            height: 100%;
            background: ${color};
          `,
        isVerticalView &&
          css`
            height: 1.25px;
            top: ${percentage}%;
            width: 100%;
            left: 0;
          `,
      ]}
    >
      <div
        css={[
          css`
            position: absolute;
          `,
          !isVerticalView &&
            css`
              top: -5px;
              left: 50%;
              height: 100%;
              transform: translateX(-50%);
            `,
          isVerticalView &&
            css`
              left: 50px;
              right: 0;
              top: 50%;
              height: 1px;
              transform: translateY(-50%);
              background: ${color};
            `,
        ]}
      >
        <div
          css={[
            css`
              position: sticky;
              width: 11px;
              height: 11px;
              background: ${color};
              border-radius: 50px;
            `,
            !isVerticalView &&
              css`
                top: 54px;
              `,
            isVerticalView &&
              css`
                left: 76px;
                top: 50%;
                transform: translateY(-50%);
              `,
          ]}
        />
      </div>
    </div>
  ) : null;
};

type TimeTooltip = {
  children: React.ReactNode;
};
const TimeTooltip = ({ children }: TimeTooltip) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0 }}
      css={css`
        pointer-events: none;
        user-select: none;
        border-radius: ${theme.borderRadius.medium};
        background: ${theme.colors.black};
        color: ${theme.colors.white};
        padding: ${theme.spacing(1.5)};
        white-space: nowrap;
      `}
    >
      {children}
    </motion.div>
  );
};

type ZoomButtonProps = {
  calendarViewWrapperRef: React.RefObject<ElementRef<'article'>>;
};

const ZoomButton = ({ calendarViewWrapperRef }: ZoomButtonProps) => {
  const { Portal } = usePortal({
    mountTarget: '#calendar-view-wrapper',
    attributes: {
      style: `position: absolute; bottom: 25px; right: 21px; z-index: ${theme.zIndex.highest};`,
    },
  });
  const { isZoomedIn, setZoomedIn, isVerticalView } = useCalendarView(['isZoomedIn', 'setZoomedIn', 'isVerticalView']);

  const handleZoom = useCallback(() => {
    setZoomedIn(!isZoomedIn);
    if (!calendarViewWrapperRef.current) return;
    calendarViewWrapperRef.current.scrollLeft = isZoomedIn
      ? calendarViewWrapperRef.current.scrollLeft / 4
      : calendarViewWrapperRef.current.scrollLeft * 4;
  }, [isZoomedIn, setZoomedIn]);

  if (isVerticalView) return null;

  return (
    <Portal>
      <NakedButton
        onClick={handleZoom}
        css={css`
          width: 40px;
          height: 40px;
          background: ${theme.colors.white};
          border-radius: ${theme.borderRadius.full};
          box-shadow: ${theme.shadows.floating};
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background 0.3s ease;
          :hover,
          :focus {
            background: ${theme.colors.primary10};
          }
        `}
      >
        <Icon name={isZoomedIn ? 'zoom-out' : 'zoom-in'} />
      </NakedButton>
    </Portal>
  );
};
