import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  position: absolute;
  display: inline-flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  top: -18px;
  right: ${theme.spacing(2)};
`;

export const buttonStyle = css`
  background-color: ${theme.colors.white};
  padding: ${theme.spacing(1)};
  box-shadow: ${theme.shadows.light};
  border-radius: ${theme.borderRadius.small};
`;
