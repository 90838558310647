import { i18next } from '@frontend/i18n';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const isNumberSearch = (phone: string | undefined) => {
  return !!phone && /^[\d() \-+*]*$/g.test(phone);
};

export const getFullName = (firstName?: string, lastName?: string, defaultValue = '') => {
  if (firstName || lastName) {
    return `${firstName || ''} ${lastName || ''}`.trim();
  }
  return defaultValue || i18next.t('Unknown', { ns: 'calls' });
};

export const isStringNumeric = (callerName: string) => {
  return /^[+-]?\d+(\.\d+)?$/.test(callerName);
};

// Sometimes caller name is a number, in that case, we show 'Unknown' caller name
export const getCallerName = (callerName: string) => {
  return !!callerName && !isStringNumeric(callerName) ? callerName : 'Unknown';
};

// get current epoch time in seconds
export const getCurrentEpochTime = () => {
  const currentDate = new Date();
  const epochTime = currentDate.getTime();
  const epochTimeInSeconds = Math.floor(epochTime / 1000);
  return epochTimeInSeconds;
};

/**
 *
 * @param seconds
 * @returns time in {m}m {s}s format
 */
export const secToMMSS = (avgSeconds: number): string => {
  const duration = dayjs.duration(avgSeconds, 'seconds');
  const minutes = duration.minutes();
  const remainingSeconds = duration.seconds();

  // Format the time as {m}m {s}s
  const formattedTime = `${minutes}m ${remainingSeconds}s`;
  return formattedTime;
};
