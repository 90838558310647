import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Modal, Heading } from '@frontend/design-system';

interface IProps {
  title: string;
  image?: React.ReactNode;
  renderContent: React.ReactNode;
  primaryButtonLabel: string;
  onPrimaryBtnClick: () => void;
  primaryButtonTrackingId?: string;
  secondaryButtonLabel?: string;
  onSecondaryBtnClick?: () => void;
  secondaryButtonTrackingId?: string;
}

export const SubscribeModal = ({
  title,
  renderContent,
  image,
  primaryButtonLabel,
  onPrimaryBtnClick,
  primaryButtonTrackingId = '',
  secondaryButtonLabel = '',
  secondaryButtonTrackingId = '',
  onSecondaryBtnClick,
}: IProps) => {
  return (
    <>
      <Modal.Body>
        {image && <div css={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(1) }}>{image}</div>}

        <div css={{ marginTop: theme.spacing(2), textAlign: 'center' }}>
          <Heading textAlign='center' level={2}>
            {title}
          </Heading>
          <div css={{ marginTop: theme.spacing(3) }}>{renderContent}</div>
        </div>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={primaryButtonLabel}
        onPrimaryClick={onPrimaryBtnClick}
        primaryTrackingId={primaryButtonTrackingId}
        secondaryLabel={secondaryButtonLabel}
        onSecondaryClick={onSecondaryBtnClick}
        secondaryTrackingId={secondaryButtonTrackingId}
        css={buttonContainerStyles}
      />
    </>
  );
};

const buttonContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  ['> button']: {
    width: 'max-content',
  },
});
