import { useContext, createContext, FC, useEffect } from 'react';
import { FormsSolicitedLink } from '@frontend/api';
import { PersonTypes } from '@frontend/api-person';
import { ModalControlResponse } from '@frontend/design-system';
import { SendFormsOrigin } from '../send-forms.type';
import useSendForms, { UseSendFormsResults } from './useSendForms';

type SencFormsProviderContext = UseSendFormsResults & {
  sendFormsModalControls: ModalControlResponse;
};

const SendFormsContext = createContext<SencFormsProviderContext | undefined>(undefined);

interface CommonProps {
  children: React.ReactNode;
  sendFormsModalControls: ModalControlResponse;
  locationId: string;
}

export interface DocumentDetails {
  documentId: string;
  documentType: FormsSolicitedLink.Types.DocumentType;
  reviewRequired: boolean;
  documentName: string;
  formIdsInDocument: string[];
  isNonEditableDocument: boolean;
}

export interface FromFormsContextProps {
  origin: SendFormsOrigin.FORMS;
  documentDetails: DocumentDetails;
}

export interface FromContactsContextProps {
  origin: SendFormsOrigin.CONTACTS;
  person: PersonTypes.Person;
}

export interface FromInvalidContextProps {
  origin: SendFormsOrigin.NONE;
}

export type SpecialProps = FromFormsContextProps | FromContactsContextProps | FromInvalidContextProps;
type SendFormsProviderProps = CommonProps & SpecialProps;

export const SendFormsProvider: FC<SendFormsProviderProps> = ({
  children,
  sendFormsModalControls,
  locationId,
  ...props
}) => {
  const sendFormsProps = useSendForms({ sendFormsModalControls, locationId, origin: props.origin });

  useEffect(() => {
    if (props.origin === SendFormsOrigin.CONTACTS) {
      sendFormsProps.selectContact(props.person);
    }

    if (props.origin === SendFormsOrigin.FORMS) {
      const { documentId, documentType, reviewRequired, documentName, formIdsInDocument, isNonEditableDocument } =
        props.documentDetails;

      switch (documentType) {
        case FormsSolicitedLink.Types.DocumentType.PACKET:
          sendFormsProps.selectDocumentToSend({
            id: documentId,
            type: documentType,
            reviewRequired,
            isNonEditable: isNonEditableDocument,
            name: documentName,
            formIds: formIdsInDocument,
          });
          break;

        case FormsSolicitedLink.Types.DocumentType.FORM:
          sendFormsProps.selectDocumentToSend({
            id: documentId,
            type: documentType,
            reviewRequired,
            isNonEditable: isNonEditableDocument,
            name: documentName,
          });
          break;

        default:
          break;
      }
    }
  }, [props.origin]);

  const contextValue = {
    ...sendFormsProps,
    sendFormsModalControls,
  };

  return <SendFormsContext.Provider value={contextValue}>{children}</SendFormsContext.Provider>;
};

export const useSendFormsContext = () => {
  const context = useContext(SendFormsContext);

  if (!context) {
    throw new Error('useSendFormsContext must be used within a SendFormsProvider');
  }

  return context;
};
