import { FC, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { capitalize } from 'lodash-es';
import { AnalyticsCommonTypes, SMSAnalyticsApi, SMSAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedInfiniteQuery } from '@frontend/location-helpers';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import {
  Table,
  TableColumnConfig,
  SortingValue,
  useModalControl,
  DownloadIcon,
  useAlert,
} from '@frontend/design-system';
import { ColumnHeaderInfoTip, ExportDataModal, InfoTipPopover, Status } from '..';
import { useLocations } from '../../hooks';
import { largeDownloadsKeys } from '../../large-downloads-keys';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { getTimeZoneDateTime } from '../../utils';
import { pageStyles, tableStyles } from '../../views/common-styles';
import { PhoneNumberSelector } from '../filter-selectors';
import { largeDownloadsRef } from '../large-downloads';
import { useMessagingAnalyticsStore } from './hooks';

interface Props {
  onFetchStateChange: (isFetching: boolean) => void;
}

const DEFAULT_PAGE_SIZE = 25;

export const SMSReportTable: FC<React.PropsWithChildren<Props>> = ({ onFetchStateChange }) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { locations } = useLocations();
  const exportModalControl = useModalControl();
  const { demoData, filters, isDemoAccount, setFilters } = useMessagingAnalyticsStore();

  const [pageConfig, setPageConfig] = useState<AnalyticsCommonTypes.PageConfig>({
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const queryString = useMemo(
    () => `${JSON.stringify(filters)}-${pageConfig.pageSize}`,
    [filters, pageConfig.pageSize]
  );

  const formatMessageCategories = (value: string) => {
    switch (value) {
      case 'na':
        return t('Incoming');

      case 'manual-messages':
        return t('Manual-Outgoing');

      default:
        return capitalize(value.replaceAll('-', ' '));
    }
  };

  const { data, fetchNextPage, hasNextPage, hasPreviousPage, isFetching } = useLocalizedInfiniteQuery({
    queryKey: queryKeys.smsAnalyticsReport(queryString),
    queryFn: ({ pageParam }) =>
      isDemoAccount
        ? { data: [], meta: { links: {} } }
        : SMSAnalyticsApi.getSMSReport(filters, pageConfig.pageSize, pageParam),
    getNextPageParam: (lastPage: SMSAnalyticsTypes.SMSReportResponse | undefined) => {
      const nextLink = lastPage?.meta.links.next;
      return nextLink?.substring(nextLink.indexOf('/') + 1);
    },
    getPreviousPageParam: (lastPage: SMSAnalyticsTypes.SMSReportResponse | undefined) => {
      const prevLink = lastPage?.meta.links.previous;
      return prevLink?.substring(prevLink.indexOf('/') + 1);
    },
    onError: () => {
      alert.error(t("Couldn't load the SMS reports. Please try again."));
    },
    refetchOnWindowFocus: false,
    retry: false,
    select: (data) => {
      return isDemoAccount
        ? {
            pageParams: [],
            pages: [demoData?.tableData],
          }
        : data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const getHasNext = () => {
    const pageData = data?.pages[pageConfig.pageNumber - 1]?.data || [];
    return hasNextPage && !!pageData.length && pageData.length === pageConfig.pageSize;
  };

  const handleSorting = (sortValues: SortingValue<string>[]) => {
    const { id, value } = sortValues[0] || {};
    if (id && value) {
      setFilters({
        order_by_field: id,
        order_by_asc: value === 'asc',
      });
    }
  };

  const colConfig: TableColumnConfig<SMSAnalyticsTypes.SMS>[] = [
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Message Details')}
          infoTip={<InfoTipPopover>{t('Content of the message')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Message Details'),
      accessor: ({ sms_body }: SMSAnalyticsTypes.SMS) => sms_body || '-',
      disableSortBy: true,
      id: 'sms_body',
      width: 400,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Location')}
          infoTip={<InfoTipPopover>{t('Name of the location')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Location'),
      accessor: ({ location_name }: SMSAnalyticsTypes.SMS) => location_name || '-',
      disableSortBy: true,
      id: 'location_name',
      omit: filters.location_id?.length === 1,
      width: 250,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Sender Number')}
          infoTip={<InfoTipPopover>{t('Number of the sender. It can be both office or contact.')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Sender Number'),
      accessor: ({ sender_number }: SMSAnalyticsTypes.SMS) => formatPhoneNumber(sender_number) || '-',
      id: 'sender_number',
      width: 190,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Receiver Number')}
          infoTip={<InfoTipPopover>{t('Name of the receiver. It can be both office or contact')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Receiver Number'),
      accessor: ({ receiver_number }: SMSAnalyticsTypes.SMS) => formatPhoneNumber(receiver_number) || '-',
      id: 'receiver_number',
      width: 200,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('SMS Type')}
          infoTip={<InfoTipPopover>{t('Direction of the message. Incoming vs Outgoing')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('SMS Type'),
      accessor: ({ direction }: SMSAnalyticsTypes.SMS) =>
        direction ? (direction.toLowerCase() === 'inbound' ? t(`Incoming`) : t(`Outgoing`)) : '-',
      id: 'direction',
      width: 150,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Message Category')}
          infoTip={
            <InfoTipPopover>
              {t('Category of the message sent or received. Incoming messages have category as NA')}
            </InfoTipPopover>
          }
        />
      ),
      headerLabel: t('Message Category'),
      accessor: ({ message_category }: SMSAnalyticsTypes.SMS) =>
        message_category ? formatMessageCategories(message_category) : '-',
      id: 'message_category',
      width: 250,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Date / Time')}
          infoTip={
            <InfoTipPopover>{t("When was the message created. It is in Location's local time zone")}</InfoTipPopover>
          }
        />
      ),
      headerLabel: t('Date / Time'),
      accessor: ({ message_start_time, time_zone }: SMSAnalyticsTypes.SMS) =>
        message_start_time ? getTimeZoneDateTime(time_zone, message_start_time) : '-',
      id: 'created_at',
      width: 370,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Source Type')}
          infoTip={
            <InfoTipPopover>
              {t('If phone number is present in PMS/EHR then it marked as Current Patient else Unknown')}
            </InfoTipPopover>
          }
        />
      ),
      headerLabel: t('Source Type'),
      accessor: ({ source_type }: SMSAnalyticsTypes.SMS) =>
        source_type ? capitalize(source_type.replaceAll('_', ' ')) : '-',
      id: 'source_type',
      width: 170,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Sender Name')}
          infoTip={<InfoTipPopover>{t('Name of the sender. It can be both office or contact')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Sender Name'),
      accessor: ({ sender_name }: SMSAnalyticsTypes.SMS) => sender_name || '-',
      disableSortBy: true,
      id: 'sender_name',
      width: 250,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Receiver Name')}
          infoTip={<InfoTipPopover>{t('Name of the receiver. It can be both office or contact')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Receiver Name'),
      accessor: ({ receiver_name }: SMSAnalyticsTypes.SMS) => receiver_name || '-',
      id: 'receiver_name',
      width: 250,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Status')}
          infoTip={
            <InfoTipPopover>
              {t('Current status of the message. It may change with time as status gets updated')}
            </InfoTipPopover>
          }
        />
      ),
      headerLabel: t('Status'),
      accessor: ({ status }: SMSAnalyticsTypes.SMS) => status?.replace('STATUS_', '').toLowerCase() || '-',
      cellRenderer: (value) => <Status status={value} />,
      id: 'status',
      width: 160,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Status Details')}
          infoTip={<InfoTipPopover>{t('Error status or reason when message fails')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Status Details'),
      accessor: ({ status_details }: SMSAnalyticsTypes.SMS) => status_details || '-',
      disableSortBy: true,
      id: 'status_details',
      width: 350,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Content Type')}
          infoTip={<InfoTipPopover>{t('Tells whether message is SMS or MMS')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Content Type'),
      accessor: ({ content_type }: SMSAnalyticsTypes.SMS) => content_type?.toUpperCase() || '-',
      id: 'content_type',
      width: 170,
    },
  ];

  useEffect(() => {
    setPageConfig({ ...pageConfig, pageNumber: 1 });
  }, [filters]);

  useEffect(() => {
    if (!data?.pages[pageConfig.pageNumber - 1]) {
      fetchNextPage();
    }
  }, [pageConfig.pageNumber]);

  useEffect(() => {
    onFetchStateChange(isFetching);
  }, [isFetching]);

  return (
    <div css={styles.wrapper}>
      <Table
        colConfig={colConfig}
        customToolbarRender={(_tableInstance, isMobile) => (
          <div css={pageStyles.numberSearch(isMobile)}>
            <PhoneNumberSelector
              css={isMobile ? styles.fullWidth : undefined}
              disabled={isFetching}
              onChange={(value) => setFilters({ number: value })}
              value={filters.number}
            />
          </div>
        )}
        data={data?.pages[pageConfig.pageNumber - 1]?.data || []}
        emptyStateConfig={{
          type: 'messages',
        }}
        hasFilterColumns
        hasResizeColumns
        hasResponsiveColWidths
        isLoading={isFetching}
        disableMultiSort
        manualSortBy
        onSortChange={handleSorting}
        isPaginated
        manualPaginationConfig={{
          handleChange: (action: 'next' | 'prev') => {
            if (action === 'next') {
              setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber + 1 });
            } else {
              setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber - 1 });
            }
          },
          hasNext: getHasNext(),
          hasPrevious: hasPreviousPage,
          page: pageConfig.pageNumber,
          defaultRowsPerPage: pageConfig.pageSize,
          onNumRowsChange: (num) => {
            setPageConfig({ pageNumber: 1, pageSize: num });
          },
          rowsPerPageOptions: [25, 50, 75, 100],
        }}
        tableActions={[
          {
            disabled:
              isFetching || largeDownloadsRef.current?.downloadsWaitState[largeDownloadsKeys.smsAnalytics]?.isWaiting,
            Icon: DownloadIcon,
            label: t('Download Data'),
            onClick: exportModalControl.openModal,
            trackingId: trackingIds.messagingAnalytics.exportTableData,
            type: 'icon',
          },
        ]}
        tableInstanceId='sms-report'
        wrapperStyle={tableStyles}
      />
      <ExportDataModal
        apiCall={SMSAnalyticsApi.getExportableDetails}
        downloadDataUrl='downloadreports/sms_report'
        downloadKey={largeDownloadsKeys.smsAnalytics}
        endDate={filters.end_date}
        fileName='Weave_Message_Report'
        generatePayload={({ currentLocationId, parentLocationId }) => ({
          ...filters,
          current_location_id: currentLocationId,
          parent_location_id: parentLocationId,
          report_type: 'sms_report',
        })}
        locationIds={filters.location_id}
        locationNames={locations}
        modalProps={exportModalControl.modalProps}
        primaryButtonTrackingId={trackingIds.messagingAnalytics.exportTableDataConfirm}
        referenceCheckUrl='downloadreports/getmediaurl'
        secondaryButtonTrackingId={trackingIds.messagingAnalytics.exportTableDataCancel}
        startDate={filters.start_date}
      />
    </div>
  );
};

const styles = {
  wrapper: css`
    position: relative;
    height: 100%;

    .table.sticky {
      min-height: auto !important;
    }
  `,

  fullWidth: css`
    max-width: 100%;
  `,
};
