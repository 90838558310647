import { useCallback, useEffect, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

type SoftphoneMediaDevicesContextValue = {
  inputSources: MediaDeviceInfo[];
  currInputSource: MediaDeviceInfo | undefined;
  setCurrInputSource: ReturnType<typeof useState<MediaDeviceInfo>>[1];
  outputSources: MediaDeviceInfo[];
  currOutputSource: MediaDeviceInfo | undefined;
  setCurrOutputSource: ReturnType<typeof useState<MediaDeviceInfo>>[1];
  refreshSources: () => Promise<MediaDeviceInfo[]>;
  getInputStream: () => Promise<MediaStream>;
  isMediaSourcesLoading: boolean;
};
const SoftphoneMediaDevicesContext = createContext<SoftphoneMediaDevicesContextValue>(
  {} as SoftphoneMediaDevicesContextValue
);

type Props = {
  children: React.ReactNode;
};
export const SoftphoneMediaDevicesProvider = ({ children }: Props) => {
  const [inputSources, setInputSources] = useState<MediaDeviceInfo[]>([]);
  const [outputSources, setOutputSources] = useState<MediaDeviceInfo[]>([]);
  const [currInputSource, setCurrInputSource] = useState<MediaDeviceInfo>();
  const [currOutputSource, setCurrOutputSource] = useState<MediaDeviceInfo>();
  const [isMediaSourcesLoading, setIsMediaSourcesLoading] = useState(false);
  const cachedInputSource = localStorage.getItem('softphone.audio-input-source');
  const cachedOutputSource = localStorage.getItem('softphone.audio-output-source');

  useEffect(() => {
    if (currInputSource) {
      localStorage.setItem('softphone.audio-input-source', `${currInputSource.deviceId}`);
    }
    if (currOutputSource) {
      localStorage.setItem('softphone.audio-output-source', `${currOutputSource.deviceId}`);
    }
  }, [currInputSource, currOutputSource]);

  const refreshSources = useCallback(async () => {
    setIsMediaSourcesLoading(true);
    await navigator.mediaDevices.getUserMedia({ audio: true });
    return navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        if (!devices) {
          throw new Error('No Device Access');
        }
        if (devices.every((device) => !device.deviceId)) {
          throw new Error('No Device Access');
        }
        setInputSources(devices.filter((device) => device.kind === 'audioinput'));
        setOutputSources(devices.filter((device) => device.kind === 'audiooutput'));
        return devices;
      })
      .finally(() => setIsMediaSourcesLoading(false));
  }, []);

  useEffect(() => {
    const callback = (_event: Event) => refreshSources();
    refreshSources().then((devices) => {
      navigator.mediaDevices.addEventListener('devicechange', callback);
      if (!!cachedInputSource) {
        const inputSource = devices.find((src) => src.kind === 'audioinput' && src.deviceId === `${cachedInputSource}`);
        setCurrInputSource(inputSource);
      } else {
        const defaultInputSource = devices.find((src) => src.kind === 'audioinput' && src.deviceId === 'default');
        setCurrInputSource(defaultInputSource);
        localStorage.setItem('softphone.audio-input-source', `${defaultInputSource?.deviceId}`);
      }
      if (!!cachedOutputSource) {
        const outputSource = devices.find(
          (src) => src.kind === 'audiooutput' && src.deviceId === `${cachedOutputSource}`
        );
        setCurrOutputSource(outputSource);
      } else {
        const defaultOutputSource = devices.find((src) => src.kind === 'audiooutput' && src.deviceId === 'default');
        setCurrOutputSource(devices.find((src) => src.kind === 'audiooutput' && src.deviceId === 'default'));
        localStorage.setItem('softphone.audio-output-source', `${defaultOutputSource?.deviceId}`);
      }
    });
    return () => navigator.mediaDevices.removeEventListener('devicechange', callback);
  }, []);

  const getInputStream = useCallback(() => {
    return navigator.mediaDevices.getUserMedia({
      audio: { deviceId: currInputSource ? { exact: currInputSource.deviceId } : undefined },
    });
  }, [currInputSource]);

  const value: SoftphoneMediaDevicesContextValue = {
    inputSources,
    currInputSource,
    setCurrInputSource,
    outputSources,
    currOutputSource,
    setCurrOutputSource,
    getInputStream,
    refreshSources,
    isMediaSourcesLoading,
  };
  return <SoftphoneMediaDevicesContext.Provider value={value}>{children}</SoftphoneMediaDevicesContext.Provider>;
};

export const useSoftphoneMediaDevices = <T extends any>(
  selector: (val: SoftphoneMediaDevicesContextValue | undefined) => T
) => {
  return useContextSelector(SoftphoneMediaDevicesContext, selector);
};
