import { SendFormStep } from '../types';

export const SEND_FORM_STEPS = {
  formsOrigin: [
    SendFormStep.SELECT_CONTACT,
    SendFormStep.SELECT_APPOINTMENT,
    SendFormStep.SELECT_DELIVERY_METHOD,
    SendFormStep.SELECTED_MESSAGE_MODE,
  ],
  contactsOrigin: [
    SendFormStep.SELECT_DOCUMENT,
    SendFormStep.SELECT_APPOINTMENT,
    SendFormStep.SELECT_DELIVERY_METHOD,
    SendFormStep.SELECTED_MESSAGE_MODE,
  ],
};
