import { ReactNode, SyntheticEvent, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from '@frontend/i18n';
import { DialApi, DialUtils, DialpadTypes } from '@frontend/api-dialpad';
import { removeNonDigits, sanitizePhoneNumber } from '@frontend/phone-numbers';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { ModalControlTriggerProps, useModalControl, useAlert } from '@frontend/design-system';
import { PersonAPI, PersonHelpers } from '@frontend/api-person';
import { useSoftphoneManager } from '@frontend/weave-softphone-manager';
import { ClickToCallSelection } from './selection-modal/click-to-call-selection';
import { CallSelectionContext } from './types';

const queryKeys = {
  base: ['calls'],
  outboundNumbers: (tenantId: string) => [...queryKeys.base, 'outboundVoiceNumbers', tenantId],
};

type UsePhoneCallActionArgs = {
  context: CallSelectionContext;
};
// Case 1: if personNumber is passed in the context, that means we don't need to give options to select from contact numbers
// Case 2: if person data is passed from person profile or contact page, then provide options to select from various contact numbers
// Case 3: if person Id is passed, then fetch the personData and provide options to select from various contact numbers
// Also, if outbound numbers are multiple then show the modal where we provide options to select the outbound numbers

export const usePhoneCallAction = ({
  context,
}: UsePhoneCallActionArgs): {
  triggerProps?: ModalControlTriggerProps;
  closeModal: () => void;
  Modal: ReactNode;
  disabled: boolean;
  disabledDetails: string;
} => {
  const { t } = useTranslation('phone-call');
  const alerts = useAlert();
  const modal = useModalControl();
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');

  let disabledDetails = '';
  const softphoneIsConnected = useSoftphoneManager((ctx) => ctx.isConnected);

  const { data: personData, isLoading: isPersonDataLoading } = PersonAPI.usePersonExtended(
    {
      personId: context?.personId ?? '',
      locationId: context?.locationId ?? '',
    },
    {
      enabled: !context?.person && !!context?.personId,
    }
  );

  const person = context.person || personData;

  const { data: { response: voiceNumbersList = [] } = {} } = useLocalizedQuery({
    queryKey: queryKeys.outboundNumbers(phoneConfig?.tenantId ?? ''),
    queryFn: () => DialApi.fetchVoicePhoneNumber({ tenantId: phoneConfig?.tenantId ?? '' }),
    retry: false,
    enabled: !!phoneConfig?.tenantId,
  });

  const { mutate: dialUser } = useMutation({
    mutationFn: (req: DialpadTypes.Dial['input']) => DialApi.dialUser(req),
    onSuccess: () => {
      if (!softphoneIsConnected) {
        alerts.success(t(`Call sent to desk phone.`));
      }
    },
  });

  const multipleContactNumbers = PersonHelpers.getAllPhoneNumbers(context?.person) ?? [];
  const requiresContactNumberSelection = !context.phoneNumber && !!person && !(multipleContactNumbers.length === 1);

  const isSingleLocationVoiceData = voiceNumbersList?.length === 1;

  const numberToLocationMap = DialUtils.useNumbersToLocationMap(voiceNumbersList);

  const useOutboundNumber =
    context?.useOutboundNumber &&
    context?.outboundNumber &&
    sanitizePhoneNumber(context.outboundNumber.replace('+1', '')) in numberToLocationMap;

  const requiresOutboundNumberSelection = !(
    (isSingleLocationVoiceData && voiceNumbersList[0]?.phoneNumbers?.length === 1) ||
    useOutboundNumber
  );

  const defaultVoiceData = useMemo(() => DialUtils.getDefaultVoiceData(voiceNumbersList), [voiceNumbersList]);

  const handleClick = (e: SyntheticEvent | undefined) => {
    e?.stopPropagation();
    if (!!phoneConfig && !requiresContactNumberSelection && !requiresOutboundNumberSelection) {
      dialUser({
        fromName: defaultVoiceData.locationName,
        fromNumber: useOutboundNumber ? context.outboundNumber : defaultVoiceData.defaultNumber,
        toNumber: removeNonDigits(context.phoneNumber || multipleContactNumbers[0].number),
        sipProfileId: phoneConfig?.sipProfileId ?? '',
      });
      modal.closeModal();
    } else {
      modal.triggerProps.onClick();
    }
  };

  if (!context?.personId && !context?.phoneNumber && (!context.person || multipleContactNumbers.length === 0)) {
    disabledDetails = t('This contact has no associated phone numbers.');
  }

  return {
    triggerProps: !!disabledDetails
      ? undefined
      : {
          ...modal.triggerProps,
          onClick: handleClick,
        },
    closeModal: modal.closeModal,
    Modal: (
      <ClickToCallSelection
        person={person}
        personNumber={context?.phoneNumber ?? ''}
        useOutboundNumber={!!context?.useOutboundNumber}
        modal={modal}
        outboundNumbers={voiceNumbersList}
        isLoading={isPersonDataLoading}
      />
    ),
    disabled: !!disabledDetails,
    disabledDetails: disabledDetails,
  };
};
