import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import {
  FeatureUpgradeIcon,
  FeatureUpgradeLabel,
  FeatureUpgradeModalWrapper,
  UpgradePromotionIconTrackingIdsMap,
  useFeatureUpgradeDetailsModal,
} from '@frontend/self-upgrade';
import { theme } from '@frontend/theme';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { ComponentProps } from 'react';
import { ExtraAction } from '../types';

interface FormsPromotionFlowResults {
  extraAction: [ExtraAction] | [];
  modalProps: ComponentProps<typeof FeatureUpgradeModalWrapper>;
}

interface UseFormsPromotionFlowProps {
  onUpgradeSuccess: () => void;
}
export const useFormsPromotionFlow = ({ onUpgradeSuccess }: UseFormsPromotionFlowProps): FormsPromotionFlowResults => {
  const { t } = useTranslation('inbox-templates');

  const { featureUpgradeDetailsModalControls } = useFeatureUpgradeDetailsModal({ feature: Feature.FORMS });

  return {
    extraAction: [
      {
        icon: (
          <>
            <Icon name='forms' color='disabled' />
            <FeatureUpgradeIcon css={featureUpgradeIconStyles} />
          </>
        ),
        css: iconButtonStyles,
        trackingId: UpgradePromotionIconTrackingIdsMap[Feature.FORMS],
        label: <FeatureUpgradeLabel label={t('Digital Forms is an elite feature. Click to upgrade.')} />,
        onClick: featureUpgradeDetailsModalControls.openModal,
      },
    ],
    modalProps: {
      modalControls: featureUpgradeDetailsModalControls,
      feature: Feature.FORMS,
      onUpgradeSuccess,
    },
  };
};

const featureUpgradeIconStyles = css({
  position: 'absolute',
  top: theme.spacing(0.75),
  right: theme.spacing(0.75),
  backgroundColor: theme.colors.white,
  borderRadius: theme.spacing(0.25),
  pointerEvents: 'none',
  transition: `opacity 0.25s ease-in-out`,
});

const iconButtonStyles = css`
  position: relative;
  img:last-of-type {
    opacity: 0;
  }
  :hover {
    img:last-of-type {
      opacity: 1;
    }
  }
`;
