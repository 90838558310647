import { PhoneNumberSchemaService, SchemaIO } from '@frontend/schema';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { UseQueryOptions } from 'react-query';

export const queryKeys = {
  base: ['calls'] as const,
  outboundNumbers: (tenantId: string) => [...queryKeys.base, 'outbound-voice-numbers', tenantId],
};

type Req = SchemaIO<typeof PhoneNumberSchemaService.ListVoicePhoneNumbers>['input'];
type Res = SchemaIO<typeof PhoneNumberSchemaService.ListVoicePhoneNumbers>['output']['response'];
export const useVoiceNumbersQuery = (tenantId?: string, options: UseQueryOptions<Res, Req> = {}) => {
  return useLocalizedQuery({
    queryKey: queryKeys.outboundNumbers(tenantId ?? ''),
    queryFn: () => PhoneNumberSchemaService.ListVoicePhoneNumbers({ tenantId }).then((res) => res.response),
    retry: false,
    enabled: !!tenantId,
    ...options,
  });
};
