import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';

export type ContactPanelTabsType = 'overview-tab' | 'notes-tab' | 'tasks-tab' | 'history-tab';

export interface LastSelectedContactPanelTabStore {
  lastSelectedContactPanelTab: ContactPanelTabsType;
  setLastSelectedContactPanelTab: (LastSelectedContactPanelTab: ContactPanelTabsType) => void;
}

const useLastSelectedContactPanelTabStore = createStoreWithPersistAndSubscribe<LastSelectedContactPanelTabStore>(
  (set) => ({
    lastSelectedContactPanelTab: 'overview-tab',
    setLastSelectedContactPanelTab: (lastSelectedContactPanelTab) =>
      set(
        (state) => {
          state.lastSelectedContactPanelTab = lastSelectedContactPanelTab;
        },
        false,
        'SET_LAST_SELECTED_CONTACT_PANEL_TAB'
      ),
  }),
  { name: 'LastSelectedContactPanelTab' },
  { name: 'LastSelectedContactPanelTabStore', trace: true }
);

export const useLastSelectedContactPanelTabShallowStore = createShallowStore(useLastSelectedContactPanelTabStore);
