import { css } from '@emotion/react';
import { Photos } from '@frontend/photos';
import { useContactPanelShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Avatar, PhoneOutgoingIcon, Text } from '@frontend/design-system';

interface ProfileCardProps {
  personName: string;
  personID?: string | undefined;
  locationId?: string;
  showIcon?: boolean;
  markedUnread?: boolean;
  trackingId?: string;
}

export const ProfileCard = ({
  personName,
  personID,
  showIcon,
  locationId,
  markedUnread = false,
  trackingId,
}: ProfileCardProps) => {
  const { setPersonId } = useContactPanelShallowStore('setPersonId', 'personId');
  const { setShow } = useSlidePanelShallowStore('setShow');

  return (
    <div
      data-test-id={`profile-card`}
      css={styles.wrapper(personID!)}
      data-trackingid={trackingId}
      onClick={() => {
        if (!!personID) {
          setPersonId(personID, undefined, locationId);
          setShow(true, 'contact');
        }
      }}
    >
      <Photos.ContactProfilePhoto
        locationId={locationId}
        personId={personID || ''}
        name={personName}
        disableClick={!personID}
      >
        {!!markedUnread && <Avatar.Dot variant='primary' />}
      </Photos.ContactProfilePhoto>
      <Text size='medium'>{personName}</Text>

      {showIcon && <PhoneOutgoingIcon size={16} />}
    </div>
  );
};

const styles = {
  wrapper: (personID: string) => css`
    display: flex;
    align-items: center;
    cursor: ${!!personID ? 'pointer' : 'auto'};
    > p {
      padding: ${theme.spacing(0, 0.5, 0, 1)};
    }
  `,
};
