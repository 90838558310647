import { PopoverMenuItem, PopoverMenuItemProps, Text } from '@frontend/design-system';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { forwardRef } from 'react';

type TagsFilterPopoverTextButtonProps = Omit<PopoverMenuItemProps<'button'>, 'ref' | 'children'> & {
  iconName: IconName;
  label: string;
};

export const TagsFilterPopoverTextButton = forwardRef<HTMLButtonElement, TagsFilterPopoverTextButtonProps>(
  ({ iconName, label, ...rest }, ref) => {
    return (
      <PopoverMenuItem
        {...rest}
        ref={ref}
        css={{
          borderTop: `1px solid ${theme.colors.neutral20}`,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Icon name={iconName} color='primary' />
        <Text color='primary' weight='semibold' size='medium'>
          {label}
        </Text>
      </PopoverMenuItem>
    );
  }
);
