import { useCallback, useEffect, useRef, memo } from 'react';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { SoftphoneTypes } from '@frontend/api-softphone';
import { useTranslation } from '@frontend/i18n';
import { CallPopActionHandler, useCallPopStateSync, useSubscribeToPopAction } from '@frontend/pop';
import {
  isAutoAnswerCall,
  isIntercomCall,
  isOccupiedParkSlot,
  isTerminatedCall,
  useSoftphoneCallActions,
  useSoftphoneCallState,
  useSoftphoneEventSubscription,
  useSoftphoneParkSlots,
  useSoftphoneSettings,
} from '@frontend/softphone2';
import {
  ContactMatch,
  PhoneEventPayload,
  PhoneEventV2Payload,
  isPhoneEventLegacyPayload,
  isPhoneEventV2Payload,
  useWebsocketEventSubscription,
  getHeadofHousehold,
} from '@frontend/websocket';
import { getPersonApis } from './softphone-manager.provider';

type CallPopManagerProps = {
  softphoneData: SoftphoneTypes.SoftphoneData | undefined;
  isCallWaitingIndicatorBeepEnabled: boolean;
  hasActiveCalls: boolean;
};
export const SoftphoneCallPopManager = memo(function SoftphoneCallPopManager({
  isCallWaitingIndicatorBeepEnabled,
  hasActiveCalls,
  softphoneData,
}: CallPopManagerProps) {
  const { t } = useTranslation('softphone');
  const calls = useSoftphoneCallState((ctx) => ctx.calls);
  const isDoNotDisturb = useSoftphoneSettings((ctx) => ctx.isDoNotDisturb);
  const answer = useSoftphoneCallActions((ctx) => ctx.answerIncomingCall);
  const reject = useSoftphoneCallActions((ctx) => ctx.endCall);
  const parkSlots = useSoftphoneParkSlots((ctx) => ctx.parkSlotsWithPresence);
  const { addNotification, removeNotification } = useCallPopStateSync();
  const inboundCallWebsocketMessages = useRef<(PhoneEventPayload | PhoneEventV2Payload)[]>([]);
  const nonTerminatedCalls = calls.filter((call) => !isTerminatedCall(call));
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');

  useSoftphonePopActions();

  useWebsocketEventSubscription('PhoneSystemEventsV2', (payload) => {
    const event = payload.params.event;
    if (event === 'inbound_call') {
      inboundCallWebsocketMessages.current.push(payload);
    }
  });

  type UsefulWebsocketData = {
    caller_context: string;
    caller_id_name: string;
    caller_id_number: string;
    phone_number: string;
    contacts: ContactMatch[] | undefined;
    recipient_location_id: string;
    location: string | undefined;
    personId: string | undefined;
    patientId: string | undefined;
    householdId: string | undefined;
    name: string | undefined;
    number: string;
    birthday: number | undefined;
    gender: string | undefined;
    source: string | undefined;
    call_pop_head_of_household:
      | {
          head_of_household_id: string;
          head_of_household_person_id: string;
        }
      | undefined;
  };
  const getWebsocketData = (phone: string) =>
    new Promise<UsefulWebsocketData>((resolve, reject) => {
      const interval = setInterval(() => {
        const payload = inboundCallWebsocketMessages.current.find(
          (payload) => payload.params.caller_id_number === phone
        );
        if (payload) {
          const guarantor = isPhoneEventV2Payload(payload)
            ? getHeadofHousehold(payload?.params.contact_matches)
            : undefined;

          resolve({
            caller_context: isPhoneEventV2Payload(payload) ? payload.params.caller_context ?? '' : '',
            caller_id_name: payload.params.caller_id_name,
            caller_id_number: payload.params.caller_id_number,
            phone_number: payload.params.phone_number,
            contacts: isPhoneEventV2Payload(payload) ? payload.params.contact_matches : undefined,
            recipient_location_id: payload.params.recipient_location_id,
            location: isPhoneEventLegacyPayload(payload)
              ? payload.params.client_location_name
              : guarantor?.client_location_name ?? payload.params.recipient_location_name,
            call_pop_head_of_household: isPhoneEventV2Payload(payload)
              ? payload.params.call_pop_head_of_household
              : undefined,
            name:
              isPhoneEventLegacyPayload(payload) && payload.params.first_name
                ? `${payload.params.first_name} ${payload.params.last_name}`
                : isPhoneEventV2Payload(payload) && guarantor?.first_name
                ? `${guarantor?.first_name} ${guarantor?.last_name}`
                : payload.params.caller_id_name !== payload.params.caller_id_number
                ? payload.params.caller_id_name
                : undefined,
            number: payload.params.caller_id_number ?? '',
            personId: isPhoneEventLegacyPayload(payload) ? payload.params.PersonID : guarantor?.person_id,
            patientId: isPhoneEventV2Payload(payload) ? guarantor?.patient_id : undefined,
            householdId: isPhoneEventLegacyPayload(payload) ? payload.params.household_id : guarantor?.household_id,
            birthday: isPhoneEventLegacyPayload(payload) ? payload.params.birthdate : guarantor?.birthdate?.seconds,
            gender: isPhoneEventLegacyPayload(payload) ? payload.params.gender : guarantor?.gender,
            source: isPhoneEventLegacyPayload(payload) ? payload.params.data_source_name : guarantor?.data_source_name,
          });
        }
      }, 200);
      setTimeout(() => {
        clearInterval(interval);
        reject(new Error('No payload found'));
      }, 2000);
    }).catch(() => undefined);

  useSoftphoneEventSubscription(
    'incoming-call.received',
    async (call) => {
      if (!softphoneData) {
        return;
      }

      const otherActiveCalls = nonTerminatedCalls.filter((c) => c.id !== call.id);

      const isParkRingback = () => {
        const occupiedSlots = parkSlots.filter(isOccupiedParkSlot);
        return occupiedSlots.some(
          (slot) =>
            slot.remoteParty.displayName === call.remoteParty.displayName ||
            slot.remoteParty.uri.split('@')[0]?.replace('sip:', '') === call.remoteParty.uri
        );
      };

      const shouldAutoReject =
        (isIntercomCall(call.invitation) || isAutoAnswerCall(call.invitation)) && !!otherActiveCalls.length;
      if (shouldAutoReject) {
        reject(call);
        return;
      }

      const shouldAutoAnswer = isIntercomCall(call.invitation) || isAutoAnswerCall(call.invitation);
      if (shouldAutoAnswer) {
        answer(call);
        return;
      }

      const shouldIgnore = isParkRingback() || isDoNotDisturb;

      if (shouldIgnore) {
        console.info('Not showing callpop because it is a park ringback or doNotDisturb is turned on');
        return;
      }

      const isProbablyExtension = call.remoteParty.uri.length < 5;
      const extension = isProbablyExtension
        ? softphoneData?.extensions.find(
            (ext) => ext.presenceUri === call.remoteParty.uri || `${ext.number}` === call.remoteParty.uri
          )
        : undefined;

      const shouldWaitForWebsocket = !extension;
      const websocketData = await (shouldWaitForWebsocket ? getWebsocketData(call.remoteParty.uri) : undefined);

      const person =
        !isProbablyExtension && !websocketData?.name
          ? await getPersonApis({ dataSourceIds: demoSourceIds })
              .getPersonByPhone(call.remoteParty.uri)
              .catch(() => undefined)
          : undefined;

      const personId = websocketData?.personId ?? person?.PersonID ?? undefined;

      const callerName = extension
        ? t(`Extension {{name}}`, { name: extension.name })
        : websocketData?.name ??
          (person ? `${person.FirstName} ${person.LastName}` : undefined) ??
          (call.remoteParty.displayName !== call.remoteParty.uri ? call.remoteParty.displayName : undefined) ??
          t('Unknown');

      const callerNumber = extension
        ? ''
        : websocketData?.number ?? call.remoteParty.uri ?? person?.MobilePhone ?? undefined;

      const notification = {
        id: call.id,
        timestamp: new Date().toDateString(),
        payload: {
          type: 'softphone',
          recipientLocationName: '',
          callerContext: websocketData?.caller_context ?? '',
          isCallWaitingIndicatorBeepEnabled,
          hasActiveCalls,
          headOfHousehold: websocketData?.call_pop_head_of_household ?? {
            head_of_household_id: '',
            head_of_household_person_id: '',
          },
          contacts: websocketData?.contacts?.map((item) => {
            return {
              personId: item.person_id,
              patientId: item.patient_id,
              householdId: item.household_id,
              callerName: `${item.first_name} ${item.last_name}`,
              callerNumber: websocketData.caller_id_number,
              recipientLocationName: item.client_location_name,
              gender: item.gender,
              birthdate: item.birthdate.seconds,
              source: item.data_source_name,
              matchedLocationId: item.weave_locations_matched[0].location_id,
            };
          }) ?? [
            {
              personId: personId ?? '',
              patientId: websocketData?.patientId ?? '',
              householdId: websocketData?.householdId ?? '',
              callerName: callerName ?? '',
              callerNumber: `${callerNumber}`,
              recipientLocationName: websocketData?.location ?? '',
              gender: websocketData?.gender ?? person?.Gender ?? '',
              birthdate:
                websocketData?.birthday ?? (person?.Birthdate ? new Date(person.Birthdate).valueOf() : undefined) ?? 0,
              source: websocketData?.source ?? '',
              matchedLocationId: websocketData?.contacts?.[0].weave_locations_matched[0].location_id || '',
            },
          ],
          //TODO: move these to a subscription
          // onAccept: () => answer(call),
          // onReject: () => reject(call),
        },
      } satisfies Parameters<typeof addNotification>[0];
      addNotification(notification);

      //we don't need to hang on to these any longer than a few seconds
      inboundCallWebsocketMessages.current = [];
    },
    [softphoneData, parkSlots, nonTerminatedCalls]
  );

  useSoftphoneEventSubscription(
    ['incoming-call.answered', 'incoming-call.completed-elsewhere', 'incoming-call.missed', 'incoming-call.rejected'],
    (e) => {
      removeNotification(e.id);
    }
  );

  return null;
});

const useSoftphonePopActions = () => {
  const { notifications } = useCallPopStateSync();
  const incomingCalls = useSoftphoneCallState((ctx) => ctx.incomingCalls);
  const answer = useSoftphoneCallActions((ctx) => ctx.answerIncomingCall);
  const reject = useSoftphoneCallActions((ctx) => ctx.endCall);

  //storing this stuff in refs so I don't have to keep re-subscribing to events whenever these change
  const notificationsRef = useRef(notifications);
  const incomingCallsRef = useRef(incomingCalls);
  const answerRef = useRef(answer);
  const rejectRef = useRef(reject);

  useEffect(() => {
    notificationsRef.current = notifications;
    incomingCallsRef.current = incomingCalls;
    answerRef.current = answer;
    rejectRef.current = reject;
  }, [notifications, incomingCalls, answer, reject]);

  const answerHandler: CallPopActionHandler<'answer'> = useCallback((data) => {
    const notification = notificationsRef.current.find((notification) => notification.id === data.id);
    const call = incomingCallsRef.current.find((call) => call.id === notification?.id);
    if (!notification || !call) {
      return;
    }
    answerRef.current(call);
  }, []);

  const hangupHandler: CallPopActionHandler<'hangup'> = useCallback((data) => {
    const notification = notificationsRef.current.find((notification) => notification.id === data.id);
    const call = incomingCallsRef.current.find((call) => call.id === notification?.id);
    if (!notification || !call) {
      return;
    }
    rejectRef.current(call);
  }, []);
  useSubscribeToPopAction('answer', answerHandler);
  useSubscribeToPopAction('hangup', hangupHandler);
};
