import { ReactNode } from 'react';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { Text, useModalControl } from '@frontend/design-system';
import { InfoTooltipWithModal } from '..';
import { useCallIntelStore } from '../../hooks';

export type CallIntelInfoTipId =
  | 'callsAnalyzed'
  | 'callsByAppointmentType'
  | 'callsByCategory'
  | 'contactType'
  | 'conversionRate'
  | 'schedulingOpportunities'
  | 'schedulingOutcome'
  | 'sentiments'
  | 'tasks'
  | 'followUpReason'
  | 'unscheduledOpportunitiesByLocation';

type Data = {
  modalContent?: ReactNode;
  noTipContentInModal?: boolean;
  tipContent: string;
  title: string;
};

type Props = {
  customTipTitle?: string;
  noCta?: boolean;
  tip: CallIntelInfoTipId;
};

export const CallIntelInfoTips = ({ customTipTitle, noCta, tip }: Props) => {
  const { t } = useTranslation('analytics');
  const { modalProps, triggerProps } = useModalControl();
  const { dataLabels } = useCallIntelStore();

  const data: Record<CallIntelInfoTipId, Data> = {
    callsAnalyzed: {
      title: t('Calls Analyzed'),
      tipContent: t(
        'This is the total number of successfully analyzed calls for the selected location(s) and time period. We might be unable to analyze some calls due to poor audio quality, very short call duration, or inability to meet our safety guidelines.'
      ),
      modalContent: (
        <>
          <Text>{t('Each successfully analyzed call will have a:')}</Text>
          <ul>
            <li>{t('Transcript')}</li>
            <li>{t('Summary')}</li>
            <li>{t('Scheduling Opportunity')}</li>
            <li>{t('Scheduling Outcome (if applicable)')}</li>
            <li>{t('Customer Sentiment')}</li>
            <li>{t('Appointment Type (if applicable)')}</li>
            <li>{t('Category')}</li>
          </ul>
        </>
      ),
    },

    callsByAppointmentType: {
      title: t('Calls by Appointment Type'),
      tipContent: t(
        'Appointment Types are created by Weave for different services, such as treatments or prescriptions, mentioned during a call. These can be brought up by either the patient or the staff member. A call may include one, several, or no Appointment Types.'
      ),
      modalContent: (
        <Text>
          {t(
            'Calls by Appointment Type tells you how many calls each Appointment Type is mentioned in for the given location(s) and time period.'
          )}
        </Text>
      ),
    },

    callsByCategory: {
      title: t('Calls by Category'),
      tipContent: t(
        'Categories are created by Weave to classify the nature of conversation, or issues mentioned during a call. A call may have one or several Categories.'
      ),
      modalContent: (
        <Text>
          {t(
            'Calls by Category tells you how many calls are in each Category for the given location(s) and time period.'
          )}
        </Text>
      ),
    },

    contactType: {
      title: t('Contact Type'),
      tipContent: t(
        'Contact Type is determined by appointment completion. New Patients are individuals who have no completed appointments at the time of the call. Existing Patients have had at least one completed appointment. Non-patients are determined by the Vendor Category.'
      ),
    },

    conversionRate: {
      title: t('Conversion Rate'),
      tipContent: t(
        'Conversion rates are an indicator of the overall performance of your location(s). They tell you how many Scheduling Opportunities are being converted to scheduled appointments.'
      ),
      modalContent: (
        <Text className='with-margin'>
          {t(
            'A call is identified as a Scheduling Opportunity if there was potential to schedule an appointment during the call. Not all analyzed calls may be opportunities. If there was an opportunity and an appointment was scheduled during the call, it will be marked as Scheduled.'
          )}
        </Text>
      ),
    },

    followUpReason: {
      title: t('Follow-up Reason'),
      tipContent: t(
        'A Follow-up Reason categorizes the type of Task generated from a call. A call may have no Tasks and no Follow-up Reasons if no open action items were identified in the transcript of the call.'
      ),
    },

    schedulingOpportunities: {
      title: t('Scheduling Opportunities'),
      tipContent: t(
        'For a Scheduling Opportunity, if an appointment was not scheduled during the call, it will be marked as Unscheduled. '
      ),
    },

    schedulingOutcome: {
      title: t('Scheduling Outcome'),
      tipContent: t(
        'For a Scheduling Opportunity, if an appointment was scheduled during the call, the opportunity will be marked as Scheduled. If not, it will be marked as Unscheduled. Calls with no Scheduling Opportunity will not have a Scheduling Outcome.'
      ),
    },

    sentiments: {
      title: t('Customer Sentiment'),
      tipContent: t(
        'Customer Sentiment types are defined by Weave to show the demeanor of participants during the call. They are determined by factors such as the choice of words and the context of the conversation.'
      ),
      modalContent: (
        <>
          <Text>{t('There are 4 sentiment types:')}</Text>
          <ul>
            <li>{dataLabels.sentimentsWithEmoji?.[CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE]}</li>
            <li>{dataLabels.sentimentsWithEmoji?.[CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL]}</li>
            <li>{dataLabels.sentimentsWithEmoji?.[CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE]}</li>
            <li>
              {t('{{value}} - for any call sentiment the AI model is unable to confidently detect', {
                value: dataLabels.sentimentsWithEmoji?.[CallIntelligenceTypes.SentimentEnum.SENTIMENT_UNKNOWN],
              })}
            </li>
          </ul>
          <Text className='with-margin'>
            {t(
              'Customer Sentiment breaks down all analyzed calls by the different sentiment types for the given location(s) and time period.'
            )}
          </Text>
        </>
      ),
    },

    tasks: {
      title: t('Tasks'),
      tipContent: t(
        'A Task is generated by Call Intelligence AI based on the contents of a conversation. It represents an action item that needs to be addressed by an office staff member after the call ends. Manually update the status of a Task to indicate when it is in progress or completed.'
      ),
    },

    unscheduledOpportunitiesByLocation: {
      title: t('Unscheduled Opportunities by Location'),
      tipContent: t(
        'Unscheduled Opportunities by Location tells you how many appointments were not scheduled on a call for the given locations and time period.'
      ),
      modalContent: (
        <>
          <Text>
            {t(
              'A call is identified as a Scheduling Opportunity if there was potential to schedule an appointment during the call. Not all analyzed calls may be Scheduling Opportunities.'
            )}
          </Text>
          <Text className='with-margin'>
            {t(
              'For a Scheduling Opportunity, if an appointment was not scheduled during the call, the opportunity will be marked as Unscheduled.'
            )}
          </Text>
          <Text className='with-margin'>
            {t(
              'Unscheduled Opportunities by Location tells you how many appointments were not scheduled on a call for the given locations and time period.'
            )}
          </Text>
        </>
      ),
      noTipContentInModal: true,
    },
  };

  return (
    <InfoTooltipWithModal
      ctaAction={noCta || !data[tip]?.modalContent ? undefined : triggerProps.onClick}
      modalContent={data[tip]?.modalContent}
      modalProps={modalProps}
      noTipContentInModal={data[tip]?.noTipContentInModal}
      tipContent={data[tip]?.tipContent}
      title={customTipTitle || data[tip]?.title}
      trackingId={tip}
    />
  );
};
