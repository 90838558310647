import { WeavePopNotification } from '@frontend/types';

export const mockIncomingCallNotification = () =>
  ({
    id: Math.random().toString(),
    type: 'incoming-call',
    timestamp: new Date().toLocaleString(),
    location: 'Testville',
    payload: {
      callerName: 'Jon Hopkins',
      callerNumber: '1234567890',
      recipientLocationName: 'Happy Smiles Lehi',
    },
    state: {
      status: 'unread',
      paused: true,
      timeout: 0,
    },
  } satisfies WeavePopNotification);

export const mockNewMessageNotification = () =>
  ({
    id: Math.random().toString(),
    type: 'sms-message-new',
    // timestamp doesn't exist here on the real websocket event, so we need to add it
    timestamp: new Date().toLocaleString(),
    location: 'Testville',
    payload: {
      body: 'You have a new message from somebody from across the world and they are hoping to hear from you because they are a prince from the congo and they have a lot of money to give you',
      number: '1234567890',
      personId: '1234567890',
      threadId: '1234567890',
      departmentId: '1234567890',
      locationId: '1234567890',
      firstName: 'James',
      lastName: 'Bond',
    },
    state: {
      status: 'unread',
      paused: false,
      timeout: 8000,
    },
    flags: {
      shouldAllowReply: false,
    },
  } satisfies WeavePopNotification);

//TODO: Voicemail is not yet added to the payload types
// const newVoicemail = {
//   id: Math.random().toString(),
//   type: 'new-voicemail',
//   status: 'unread',
//   timestamp: new Date().toLocaleString(),
//   location: 'Testville',
//   payload: {
//     transcription: 'Hello, this is Sara Johannesburg, calling to let you know I need to reschedule my appointment',
//     number: '1234567890',
//   },
// } satisfies Notification;

export const mockNewFaxNotification = () =>
  ({
    id: Math.random().toString(),
    type: 'fax-new',
    timestamp: new Date().toLocaleString(),
    location: 'Testville',
    payload: {
      faxId: '00000000-0000-0000-0000-000000000000',
      action: 'inbound',
      from: '1234567890',
      to: '1234567890',
    },
    state: {
      status: 'unread',
      paused: false,
      timeout: 8000,
    },
  } satisfies WeavePopNotification);

export const mockNewScheduleRequestNotification = () =>
  ({
    id: Math.random().toString(),
    type: 'schedule-request-new',
    timestamp: new Date().toLocaleString(),
    location: 'Testville',
    payload: {
      appointmentType: 'test',
      dateTime: new Date().toLocaleString(),
      id: Math.random().toString(),
      durationMinutes: 20,
      note: 'test',
      notificationType: 'ScheduleRequest',
      // schedulee: { firstName: 'Elaine', lastName: 'Bennis' },
    },
    state: {
      status: 'unread',
      paused: false,
      timeout: 8000,
    },
  } satisfies WeavePopNotification);

export const mockNewFollowupNotification = () =>
  ({
    id: Math.random().toString(),
    type: 'message-followup',
    timestamp: new Date().toLocaleString(),
    location: 'Testville',
    payload: {
      method: 'call',
      patient: {
        id: 'John Hopkins',
        email: 'jon@hopkins.com',
        phone_number: '1234567890',
        first_name: 'Jon',
        last_name: 'Hopkins',
      },
      text: 'Test`        ',
    },
    state: {
      status: 'unread',
      paused: false,
      timeout: 8000,
    },
  } satisfies WeavePopNotification);

export const mockNewReviewNotification = () =>
  ({
    id: Math.random().toString(),
    type: 'review-new',
    timestamp: new Date().toLocaleString(),
    location: 'Testville',
    payload: {
      title: 'New review title',
      body: 'New review body',
      siteName: 'private',
      author: 'Jerry Seinfeld',
      reviewId: 'ZWI2OWM0YmYtYWUyNi00N2U1LTg3YzgtZjc5N2YzY2IxYzI5',
    },
    state: {
      status: 'unread',
      paused: false,
      timeout: 8000,
    },
  } satisfies WeavePopNotification);

export const mockIncomingChatNotification = () =>
  ({
    id: Math.random().toString(),
    type: 'chat-message-new',
    timestamp: new Date().toLocaleString(),
    location: 'Testville',
    payload: {
      authorName: 'Cosmo Kramer',
      message: 'Test message',
      provider: 'twilio',
      sid: '123',
    },
    state: {
      status: 'unread',
      paused: false,
      timeout: 8000,
    },
  } satisfies WeavePopNotification);

export const mockFormSubmissionNewNotification = () =>
  ({
    id: Math.random().toString(),
    type: 'form-submission-new',
    timestamp: new Date().toLocaleString(),
    location: 'New york city dental',
    payload: {
      firstName: 'Jerry',
      lastName: 'Seinfeld',
      submissionId: '123',
      documentName: 'on',
      submittedAt: new Date().toLocaleDateString(),
    },
    state: {
      status: 'unread',
      paused: false,
      timeout: 8000,
    },
  } satisfies WeavePopNotification);

export const mockSmsTagNewNotification = () =>
  ({
    id: Math.random().toString(),
    type: 'sms-tag',
    timestamp: new Date().toLocaleString(),
    location: 'Testville',
    payload: {
      eventType: 'added',
    },
    state: {
      status: 'unread',
      paused: false,
      timeout: 8000,
    },
  } satisfies WeavePopNotification);

export const mockPaymentsNotification = () =>
  ({
    id: Math.random().toString(),
    type: 'payments',
    timestamp: new Date().toLocaleString(),
    location: 'Payments-Express',
    payload: {
      invoiceId: '3d0a2fd5-c659-45e7-95cd-07b3f618b93b',
      origin: 'Manual Card Entry',
      patientName: 'Jerry Seinfeld',
    },
    state: {
      status: 'unread',
      paused: false,
      timeout: 8000,
    },
  } satisfies WeavePopNotification);

export const notificationMocks = [
  mockIncomingCallNotification(),
  mockNewMessageNotification(),
  mockNewFaxNotification(),
  mockNewScheduleRequestNotification(),
  mockNewFollowupNotification(),
  mockSmsTagNewNotification(),
  mockIncomingChatNotification(),
  mockFormSubmissionNewNotification(),
  mockNewReviewNotification(),
  mockPaymentsNotification(),
];
