import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { CheckIcon, Text, TextSize } from '@frontend/design-system';

type CheckedListItemProps = { value: string; size?: TextSize };
const checkListItemStyle = css({ display: 'flex', alignItems: 'center', marginTop: theme.spacing(1) });

export const CheckedListItem = ({ value, size = 'medium' }: CheckedListItemProps) => (
  <div css={checkListItemStyle}>
    <CheckIcon />
    <Text size={size} css={{ marginLeft: theme.spacing(1) }}>
      {value}
    </Text>
  </div>
);
