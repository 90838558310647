import { BaseNotificationComponentProps, BaseNotificationInner, BaseNotificationOuter } from './base';
import { ComponentProps } from 'react';
import { useTranslation } from '@frontend/i18n';
import { GetWeavePopNotificationByType } from '@frontend/types';

type Props = BaseNotificationComponentProps<GetWeavePopNotificationByType<'sms-tag'>>;

export const SmsTagNotificationComponent = ({ isHistorical, notification, emit, ...rest }: Props) => {
  const { t } = useTranslation();
  const actions: ComponentProps<typeof BaseNotificationInner>['actions'] = [
    {
      Icon: undefined,
      label: t('View'),
      action: () => {
        emit({ action: 'view', payload: undefined }, notification);
      },
    },
  ];

  return (
    <BaseNotificationOuter notification={notification} emit={emit} isHistorical={isHistorical} {...rest}>
      <BaseNotificationInner
        onClose={() => emit({ action: 'ignore', payload: undefined }, notification)}
        actions={actions}
        title={t('New SMS Tag')}
        body={`Tag ${notification.payload.eventType}`}
        location={notification.location}
        timestamp={notification.timestamp}
        id={notification.id}
        notificationType={notification.type}
        {...rest}
      />
    </BaseNotificationOuter>
  );
};
