import { createContext, useContextSelector } from 'use-context-selector';
import { useSoftphoneEventSubscription } from './softphone-events-provider';

//https://linear.app/getweave/issue/PD_947/send_softphone_events_to_phone_device_events
type MetricsEvents =
  | 'registration_not_renewed'
  | 'setup_autoprovision_finish'
  | 'registered'
  | 'unregistered'
  | 'register_failed'
  | 'incoming_call'
  | 'missed_call'
  | 'reject_incoming_call'
  | 'call_completed_elsewhere'
  | 'answer_new_incoming_call'
  | 'outgoing_call'
  | 'cancel_call_out'
  | 'remote_busy'
  | 'call_remote_canceled'
  | 'call_established'
  | 'call_terminated'
  | 'transfer_call'
  | 'blind_transfer_call'
  | 'attended_transfer_call'
  | 'transfer_finished'
  | 'transfer_failed'
  | 'dnd_on'
  | 'dnd_off'
  | 'hold'
  | 'unhold'
  | 'mute'
  | 'unmute';

type SoftphoneMetricsContextValue = {
  postEvent: (event: MetricsEvents) => void;
};
const SoftphoneMetricsContext = createContext({} as SoftphoneMetricsContextValue);

type Props = {
  postEvent: (event: string) => void;
  children: React.ReactNode;
};
export const SoftphoneMetricsProvider = ({ postEvent, children }: Props) => {
  const postMetricsEvent = (e: MetricsEvents) => postEvent(e);

  useSoftphoneEventSubscription('registration.registered', () => {
    postMetricsEvent('registered');
  });
  useSoftphoneEventSubscription('registration.unregistered', () => {
    postMetricsEvent('unregistered');
  });
  useSoftphoneEventSubscription('registration.failure', () => {
    postMetricsEvent('register_failed');
  });
  useSoftphoneEventSubscription('do-not-disturb.enabled', () => {
    postMetricsEvent('dnd_on');
  });
  useSoftphoneEventSubscription('do-not-disturb.disabled', () => {
    postMetricsEvent('dnd_off');
  });
  useSoftphoneEventSubscription('blind-transfer.sent', () => {
    postMetricsEvent('blind_transfer_call');
  });
  useSoftphoneEventSubscription('blind-transfer.failed', () => {
    postMetricsEvent('transfer_failed');
  });
  useSoftphoneEventSubscription('attended-transfer.third-party.invitation.failed', () => {
    postMetricsEvent('transfer_failed');
  });
  useSoftphoneEventSubscription('incoming-call.answered', () => {
    postMetricsEvent('answer_new_incoming_call');
  });
  useSoftphoneEventSubscription('incoming-call.rejected', () => {
    postMetricsEvent('reject_incoming_call');
  });
  useSoftphoneEventSubscription('incoming-call.missed', () => {
    postMetricsEvent('missed_call');
  });
  useSoftphoneEventSubscription('outgoing-call.sent', () => {
    postMetricsEvent('outgoing_call');
  });
  useSoftphoneEventSubscription('outgoing-call.canceled', () => {
    postMetricsEvent('cancel_call_out');
  });
  useSoftphoneEventSubscription(['incoming-call.answered', 'outgoing-call.answered'], () => {
    postMetricsEvent('call_established');
  });
  useSoftphoneEventSubscription(['active-call.terminated'], () => {
    postMetricsEvent('call_terminated');
  });
  useSoftphoneEventSubscription('settings.mute', () => {
    postMetricsEvent('mute');
  });
  useSoftphoneEventSubscription('settings.unmute', () => {
    postMetricsEvent('unmute');
  });
  useSoftphoneEventSubscription('registration.not_renewed', () => {
    postMetricsEvent('registration_not_renewed');
  });
  //TODO: add more as needed

  const value = {
    postEvent: (event: MetricsEvents) => postEvent(event),
  } satisfies SoftphoneMetricsContextValue;

  return <SoftphoneMetricsContext.Provider value={value}>{children}</SoftphoneMetricsContext.Provider>;
};

export const useSoftphoneMetrics = <T extends any>(selector: (value: SoftphoneMetricsContextValue) => T) => {
  return useContextSelector(SoftphoneMetricsContext, selector);
};
