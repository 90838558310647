import { useQuery } from 'react-query';
import { FormsQueryKeys, ProviderReview } from '@frontend/api';
import { useScopeLocation } from '../../../shared/hooks';

export const useWeaveProviderUsers = () => {
  const { locationId } = useScopeLocation();
  const query = useQuery(
    FormsQueryKeys.providerMapping.weaveProviderUser,
    () => ProviderReview.API.fetchWeaveProviderUsers(locationId),
    {}
  );

  const pracitionerMapping: Record<string, number> = {};

  const pracitioners: ProviderReview.Types.WeaveProviderUser[] = [];

  if (!query.isLoading && query.isSuccess) {
    query.data?.providerMappings?.forEach?.((practitioner) => {
      if (practitioner.email) {
        pracitioners.push(practitioner);
        pracitionerMapping[practitioner.name] = pracitioners.length - 1;
      }
    });
  }

  return {
    isLoading: query.isLoading,
    isError: query.isError,
    pracitionerMapping,
    pracitioners,
  };
};
