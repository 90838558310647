import { useCallback } from 'react';
import { css } from '@emotion/react';
import { EmojiClickData } from 'emoji-picker-react';
import { theme } from '@frontend/theme';
import { EmojiButton } from '@frontend/design-system';
import { ReactionParams, useReactions } from '../../../hooks';
import { Message } from '../../../types';
import { ReactionButton } from './reaction-button';

interface Props {
  message: Message;
}

export const Reactions = ({ message }: Props) => {
  const { addReaction, removeReaction } = useReactions();

  const handleClickEmoji = useCallback(
    (emoji: EmojiClickData, hasOwnReaction?: boolean) => {
      const params: ReactionParams = {
        conversationId: message.channelId,
        messageId: message.id,
        reaction: emoji.unified,
      };

      if (hasOwnReaction) {
        removeReaction(params);
      } else {
        addReaction(params);
      }
    },
    [message]
  );

  return (
    <div css={styles.wrapper}>
      {message.reactions?.map((reaction) => (
        <ReactionButton
          key={reaction.name}
          onClickReaction={(emoji: EmojiClickData) => handleClickEmoji(emoji, reaction.hasOwnReaction)}
          reaction={reaction}
          wrapperStyle={styles.reactionButton}
        />
      ))}
      <EmojiButton
        css={[styles.reactionButton, styles.defaultEmoji]}
        onSelect={handleClickEmoji}
        trackingId='chat-2.0-emoji-icon-add'
      />
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
    margin-top: ${theme.spacing(1)};
  `,

  reactionButton: css`
    background-color: ${theme.colors.neutral5};
    border-radius: ${theme.spacing(2)};
    border: 1px solid ${theme.colors.neutral10};
    height: ${theme.spacing(3)};
    min-width: ${theme.spacing(2)};
    padding: ${theme.spacing(0, 0.5)};
    width: fit-content;
  `,

  defaultEmoji: css`
    svg {
      fill: ${theme.colors.neutral50};
      height: ${theme.spacing(2)};
      width: ${theme.spacing(2)};
    }
  `,
};
