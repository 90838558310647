import { css } from '@emotion/react';
import { Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { MessagesTypes } from '@frontend/api-messaging';
import { compareRecordStyles, currentRecordStyles } from './record-history-styles';

interface Props<T extends MessagesTypes.SmsRecord> {
  currentObj: T;
  compareObj: T;
  currentDate: string;
  compareDate: string;
}

export const RecordDiff = <T extends MessagesTypes.SmsRecord>({
  currentObj,
  compareObj,
  currentDate,
  compareDate,
}: Props<T>) => {
  const { t } = useTranslation('messages');
  const compare = [];
  const current = [];
  let hasDifferences = false;

  for (const key in currentObj) {
    const currentRecord = currentObj[key];
    const compareRecord = compareObj[key];

    const currentValue = Array.isArray(currentRecord) ? currentRecord.join(', ') : (currentRecord as string);
    const compareValue = Array.isArray(compareRecord) ? compareRecord.join(', ') : (compareRecord as string);

    if (currentValue !== compareValue && currentValue !== undefined && compareValue !== undefined) {
      current.push(
        <li key={key} css={[listItemStyles, hasDiffStyles]}>
          <span css={labelStyles}>{key}: </span>
          <span>{currentValue}</span>
        </li>
      );
      compare.push(
        <li key={key} css={[listItemStyles, hasDiffStyles]}>
          <span css={labelStyles}>{key}: </span>
          <span>{compareValue}</span>
        </li>
      );
      hasDifferences = true;
    } else if (currentValue !== undefined) {
      current.push(
        <li key={key} css={listItemStyles}>
          <span css={labelStyles}>{key}: </span>
          <span>{currentValue}</span>
        </li>
      );
      compare.push(
        <li key={key} css={listItemStyles}>
          <span css={labelStyles}>{key}: </span>
          <span>{compareValue}</span>
        </li>
      );
    }
  }

  return (
    <>
      <Text css={textStyles}>{t('Record Diff')}</Text>
      <div css={flexContainerStyles}>
        <ul css={[listStyles, currentRecordStyles]}>
          <Text css={recordTitleStyles}>
            {t('Current Record:')} {currentDate}
          </Text>
          {current}
        </ul>
        {hasDifferences && (
          <ul css={[listStyles, compareRecordStyles]}>
            <Text css={recordTitleStyles}>
              {t('Compare Record:')} {compareDate}
            </Text>
            {compare}
          </ul>
        )}
      </div>
    </>
  );
};

const flexContainerStyles = css`
  display: flex;
  justify-content: space-between;
`;

const labelStyles = css`
  font-weight: bold;
`;

const listStyles = css`
  padding: ${theme.spacing(2)};
  list-style: none;
  width: 45%;
`;

const textStyles = css`
  font-size: ${theme.fontSize(20)};
  margin: ${theme.spacing(2)} 0;
`;

const listItemStyles = css`
  font-size: ${theme.fontSize(16)};
  padding-bottom: ${theme.spacing(1)};
`;

const recordTitleStyles = css`
  font-size: ${theme.fontSize(18)};
  margin-bottom: ${theme.spacing(2)};
  font-weight: bold;
`;

const hasDiffStyles = css`
  background-color: ${theme.colors.warning5};
`;
