import { FreeTrialSubscriptionQueries, FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { getUser } from '@frontend/auth-helpers';
import { Text, useAlert, useModalControl } from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useNavigate } from '@tanstack/react-location';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SelfUpgradeModalTrackingIds } from '../../../constants/tracking-ids';
import { checkCanSelfSubscribe } from '../../../self-upgrade.util';
import { FeatureSelfSubscribeModalProps, SelfSubscribeEligibilityData } from '../../../types';
import { SubscribeTermsModal } from '../../subscribe-terms-modal/SubscribeTermsModal';
import { SelfUpgradeBaseModal } from './SelfUpgradeBaseModal';
import { SelfUpgradeFailModal } from './SelfUpgradeFailModal';
import { SelfUpgradeLocationSelectionTable } from './SelfUpgradeLocationSelectionTable';
import { SelfUpgradeOverdueBalance } from './SelfUpgradeOverdueBalanceModal';
import { SelfUpgradeSuccessModal } from './SelfUpgradeSuccessModal';
import { FinanceQueries } from '@frontend/api-finance';

export interface SelfUpgradeModalProps extends FeatureSelfSubscribeModalProps {
  featureEnum: Feature;
  featureName: string;
  featureRedirectUrl?: string;
  price: number;
  subscriptionTerm: 'monthly' | 'annually';
  selfSubscribeEligibilityData: SelfSubscribeEligibilityData[];
  onboardingCallLink: string;
  isLoading?: boolean;
  onTermsClick?: () => void;
  onUpgradeClick?: (locationIds: string[]) => void;
}

export const SelfUpgradeModal: FC<SelfUpgradeModalProps> = (props) => {
  const {
    featureEnum,
    featureName,
    featureRedirectUrl,
    isLoading,
    onboardingCallLink,
    onUpgradeClick,
    onUpgradeSuccess,
    onTermsClick,
    price,
    subscriptionTerm,
    selfSubscribeEligibilityData = [],
    ...modalProps
  } = props;
  const { accessibleLocationData } = useAppScopeStore();
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'self-upgrade-modal' });

  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);
  const [partialFailedLocationIds, setPartialFailedLocationIds] = useState<string[]>([]);

  const selfUpgradeSuccessModalControls = useModalControl();
  const selfUpgradeFailModalControls = useModalControl();
  const overdueBalanceModalControls = useModalControl();
  const subscribeTermsModalControls = useModalControl();

  const alerts = useAlert();
  const navigate = useNavigate();

  const subscriptionTermText = subscriptionTerm === 'monthly' ? t('mo') : t('year');

  const showFailedAlert = (partialFailedLocations: string[] = []) => {
    alerts.error({
      message: partialFailedLocations.length
        ? t('Upgrade failed for {{count}} location', { count: partialFailedLocations.length })
        : t('Upgrade failed'),
      action: {
        label: partialFailedLocations.length ? t('See Details') : t('Contact Support'),
        onClick: () => {
          setPartialFailedLocationIds(partialFailedLocations);
          selfUpgradeFailModalControls.openModal();
        },
      },
    });
  };

  const { mutateAsync: subscribe, isLoading: isSubscriptionInProgress } =
    FreeTrialSubscriptionQueries.useFeatureMultiSubscribe({
      onSettled: modalProps.onClose,
      onSuccess: (response) => {
        const failedToUpgradeLocationIds: string[] =
          response.subscriptionResponse?.filter(({ error }) => !!error).map(({ locationId }) => locationId || '') || [];
        if (!failedToUpgradeLocationIds.length) {
          alerts.success({
            message: t('Success! You now have access to {{featureName}}', { featureName }),
            action: featureRedirectUrl
              ? {
                  label: t('Go to'),
                  onClick: () => {
                    onUpgradeSuccess();
                    navigate({
                      to: featureRedirectUrl,
                    });
                  },
                }
              : undefined,
          });
          selfUpgradeSuccessModalControls.openModal();
          return;
        } else if (failedToUpgradeLocationIds.length === selectedLocationIds.length) {
          showFailedAlert();
        } else {
          showFailedAlert(failedToUpgradeLocationIds);
        }
      },
      onError: () => {
        showFailedAlert();
      },
    });

  const { data: multiInvoiceBalanceData, isLoading: isMultiInvoiceBalanceDataLoading } =
    FinanceQueries.useMultiInvoiceBalanceDetails(
      { locationIds: selfSubscribeEligibilityData.map(({ locationId }) => locationId) },
      { enabled: modalProps.show && selfSubscribeEligibilityData.length > 0 }
    );

  const locationWiseOverdueBalance = useMemo<Record<string, boolean>>(() => {
    if (multiInvoiceBalanceData?.response?.length) {
      return multiInvoiceBalanceData.response.reduce<Record<string, boolean>>((acc, current) => {
        if (current.locationId && !current.error) {
          acc[current.locationId] = Number(current.balanceStatus?.current) > 0;
        }
        return acc;
      }, {});
    }
    return {};
  }, [multiInvoiceBalanceData]);

  const modifiedSelfUpgradeEligibilityData = useMemo(() => {
    return selfSubscribeEligibilityData.map((data) => ({
      ...data,
      hasOverdueBalance: !!locationWiseOverdueBalance[data.locationId],
    }));
  }, [selfSubscribeEligibilityData, locationWiseOverdueBalance]);

  const isMultiLocation = modifiedSelfUpgradeEligibilityData.length > 1;

  const renderActionPrefix = useMemo(() => {
    if (isMultiLocation && selectedLocationIds.length > 0) {
      return (
        <Text weight='bold' size='medium'>
          ${price * selectedLocationIds.length}/{t('mo')}
        </Text>
      );
    } else if (selectedLocationIds.length === 0) {
      return (
        <Text color='light' size='medium'>
          {t('You must select atleast one location to continue')}
        </Text>
      );
    }
    return null;
  }, [selectedLocationIds, isMultiLocation, price]);

  const handleUpgrade = useCallback(() => {
    const user = getUser();
    const requestData: FreeTrialSubscriptionTypes.MultiSubscribeRequest = {
      coreFeatureEnum: featureEnum,
      userDetails: {
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        email: user?.username ?? '',
        phone: user?.mobileNumber ?? '',
      },
      locations: selectedLocationIds.map((locationId) => ({
        slug: accessibleLocationData[locationId]?.slug || '',
        additionalLines: [],
      })),
    };
    subscribe(requestData);
  }, [selectedLocationIds, isMultiLocation, accessibleLocationData, featureEnum]);

  useEffect(() => {
    if (modifiedSelfUpgradeEligibilityData.length === 1) {
      setSelectedLocationIds(
        modifiedSelfUpgradeEligibilityData.filter(checkCanSelfSubscribe).map(({ locationId }) => locationId)
      );
    }
  }, [modifiedSelfUpgradeEligibilityData]);

  useEffect(() => {
    if (modalProps.show && modifiedSelfUpgradeEligibilityData.length === 1) {
      if (modifiedSelfUpgradeEligibilityData[0].hasOverdueBalance) {
        overdueBalanceModalControls.openModal();
        modalProps.onClose();
      } else {
        setSelectedLocationIds(
          modifiedSelfUpgradeEligibilityData.filter(checkCanSelfSubscribe).map(({ locationId }) => locationId)
        );
      }
    }
  }, [modifiedSelfUpgradeEligibilityData, modalProps.show]);

  return (
    <>
      <SelfUpgradeBaseModal
        maxWidth={isMultiLocation ? 800 : 600}
        onTermsClick={onTermsClick ?? subscribeTermsModalControls.openModal}
        primaryActionLabel={t('Upgrade')}
        onPrimaryAction={() => (onUpgradeClick ? onUpgradeClick(selectedLocationIds) : handleUpgrade())}
        primaryActionTrackingId={SelfUpgradeModalTrackingIds.upgradeAction}
        isPrimaryActionDisabled={isMultiLocation && selectedLocationIds.length === 0}
        actionPrefixText={renderActionPrefix}
        isLoading={isLoading || isSubscriptionInProgress || isMultiInvoiceBalanceDataLoading}
        {...modalProps}
      >
        {isMultiLocation ? (
          <>
            <Text>
              {t(
                'You are adding {{featureName}} to these locations. All users on your account will have access to this feature.',
                { featureName }
              )}
            </Text>
            {modalProps.show && (
              <SelfUpgradeLocationSelectionTable
                initialSelectedData={modifiedSelfUpgradeEligibilityData}
                data={modifiedSelfUpgradeEligibilityData}
                onChangeSelection={setSelectedLocationIds}
                price={price}
                subscriptionTerm={subscriptionTerm}
              />
            )}
          </>
        ) : (
          <>
            <Trans t={t}>
              <Text size='large'>
                You are adding <strong>{{ featureName }}</strong> to your subscriptions for an additional ${{ price }}/
                {{ subscriptionTermText }}.
              </Text>
            </Trans>
            <Text size='large'>{t('Please read and agree to the terms and conditions below.')}</Text>
          </>
        )}
      </SelfUpgradeBaseModal>

      <SubscribeTermsModal productServiceName={featureName} {...subscribeTermsModalControls.modalProps} />

      <SelfUpgradeSuccessModal
        featureName={featureName}
        onboardingCallUrl={onboardingCallLink}
        {...selfUpgradeSuccessModalControls.modalProps}
        onClose={() => {
          selfUpgradeSuccessModalControls.closeModal();
          onUpgradeSuccess();
        }}
      />

      <SelfUpgradeFailModal
        partialFailedLocationIds={partialFailedLocationIds}
        {...selfUpgradeFailModalControls.modalProps}
        onClose={() => {
          partialFailedLocationIds.length > 0 && onUpgradeSuccess();
          selfUpgradeFailModalControls.closeModal();
        }}
      />
      <SelfUpgradeOverdueBalance featureName={featureName} {...overdueBalanceModalControls.modalProps} />
    </>
  );
};
