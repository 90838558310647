import { useLocalizedQuery } from '@frontend/location-helpers';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { SchemaHistoryMetl, SchemaIO } from '@frontend/schema';
import { useLocationDataShallowStore } from '@frontend/location-helpers';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

const queryKeys = {
  records: (params: SchemaListRecordHistories['input']) => ['records', params],
  appointmentHistory: (id: string) => ['appointmentHistory', id],
  appointments: (params: SchemaListAppointments['input']) => ['appointments', params],
  appointmentsFilterOptions: () => ['appointmentsFilterOptions'],
  contactInfoHistory: (id: string) => ['contactInfoHistory', id],
  contactInfos: (params: SchemaListContactInfos['input']) => ['contactInfos', params],
  contactInfosFilterOptions: () => ['contactInfosFilterOptions'],
  orderHistory: (id: string) => ['orderHistory', id],
  orders: (params: SchemaListOrders['input']) => ['orders', params],
  ordersFilterOptions: () => ['ordersFilterOptions'],
  personHistory: (id: string) => ['personHistory', id],
  persons: (params: SchemaListPersons['input']) => ['persons', params],
  personsFilterOptions: () => ['personsFilterOptions'],
  recallHistory: (id: string) => ['recallHistory', id],
  recalls: (params: SchemaListRecalls['input']) => ['recalls', params],
  recallsFilterOptions: () => ['recallsFilterOptions'],
};

type SchemaListRecordHistories = SchemaIO<(typeof SchemaHistoryMetl)['ListRecordHistories']>;
export const useListRecordHistories = (params: SchemaListRecordHistories['input']) => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaListRecordHistories['input'] = {
    locationId,
    ...params,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.records(params),
    queryFn: () => SchemaHistoryMetl.ListRecordHistories(request),
    select: (response) => response.entries,
  });
};

type SchemaListAppointments = SchemaIO<(typeof SchemaHistoryMetl)['ListAppointments']>;
export const useListAppointments = (params: SchemaListAppointments['input']) => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaListAppointments['input'] = {
    locationId,
    ...params,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.appointments(params),
    queryFn: () => SchemaHistoryMetl.ListAppointments(request),
    select: (response) => response.appointments,
  });
};

type SchemaListContactInfos = SchemaIO<(typeof SchemaHistoryMetl)['ListContactInfos']>;
export const useListContactInfos = (params: SchemaListContactInfos['input']) => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaListContactInfos['input'] = {
    locationId,
    ...params,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.contactInfos(params),
    queryFn: () => SchemaHistoryMetl.ListContactInfos(request),
    select: (response) => response.contactInfos,
  });
};

type SchemaListOrders = SchemaIO<(typeof SchemaHistoryMetl)['ListOrders']>;
export const useListOrders = (params: SchemaListOrders['input']) => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaListOrders['input'] = {
    locationId,
    ...params,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.orders(params),
    queryFn: () => SchemaHistoryMetl.ListOrders(request),
    select: (response) => response.orders,
  });
};

type SchemaListPersons = SchemaIO<(typeof SchemaHistoryMetl)['ListPersons']>;
export const useListPersons = (params: SchemaListPersons['input']) => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaListPersons['input'] = {
    locationId,
    ...params,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.persons(params),
    queryFn: () => SchemaHistoryMetl.ListPersons(request),
    select: (response) => response.persons,
  });
};

type SchemaListRecalls = SchemaIO<(typeof SchemaHistoryMetl)['ListRecalls']>;
export const useListRecalls = (params: SchemaListRecalls['input']) => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaListRecalls['input'] = {
    locationId,
    ...params,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.recalls(params),
    queryFn: () => SchemaHistoryMetl.ListRecalls(request),
    select: (response) => response.recalls,
  });
};

type SchemaAppointmentFilterOptions = SchemaIO<(typeof SchemaHistoryMetl)['AppointmentFilterOptions']>;
export const useAppointmentFilterOptions = () => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaAppointmentFilterOptions['input'] = {
    locationId,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.appointmentsFilterOptions(),
    queryFn: () => SchemaHistoryMetl.AppointmentFilterOptions(request),
    select: (response) => response.options,
  });
};

type SchemaContactInfoFilterOptions = SchemaIO<(typeof SchemaHistoryMetl)['ContactInfoFilterOptions']>;
export const useContactInfoFilterOptions = () => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaContactInfoFilterOptions['input'] = {
    locationId,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.contactInfosFilterOptions(),
    queryFn: () => SchemaHistoryMetl.ContactInfoFilterOptions(request),
    select: (response) => response.options,
  });
};

type SchemaOrderFilterOptions = SchemaIO<(typeof SchemaHistoryMetl)['OrderFilterOptions']>;
export const useOrderFilterOptions = () => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaOrderFilterOptions['input'] = {
    locationId,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.ordersFilterOptions(),
    queryFn: () => SchemaHistoryMetl.OrderFilterOptions(request),
    select: (response) => response.options,
  });
};

type SchemaPersonFilterOptions = SchemaIO<(typeof SchemaHistoryMetl)['PersonFilterOptions']>;
export const usePersonFilterOptions = () => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaPersonFilterOptions['input'] = {
    locationId,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.personsFilterOptions(),
    queryFn: () => SchemaHistoryMetl.PersonFilterOptions(request),
    select: (response) => response.options,
  });
};

type SchemaRecallFilterOptions = SchemaIO<(typeof SchemaHistoryMetl)['RecallFilterOptions']>;
export const useRecallFilterOptions = () => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaRecallFilterOptions['input'] = {
    locationId,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.recallsFilterOptions(),
    queryFn: () => SchemaHistoryMetl.RecallFilterOptions(request),
    select: (response) => response.options,
  });
};

type SchemaAppointmentHistory = SchemaIO<(typeof SchemaHistoryMetl)['AppointmentHistory']>;
export const useAppointmentHistory = (appointmentId: string) => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaAppointmentHistory['input'] = {
    locationId,
    appointmentId,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.appointmentHistory(appointmentId),
    queryFn: () => SchemaHistoryMetl.AppointmentHistory(request),
    select: (response) => response.entries,
  });
};

type SchemaContactInfoHistory = SchemaIO<(typeof SchemaHistoryMetl)['ContactInfoHistory']>;
export const useContactInfoHistory = (contactInfoId: string) => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaContactInfoHistory['input'] = {
    locationId,
    contactInfoId,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.contactInfoHistory(contactInfoId),
    queryFn: () => SchemaHistoryMetl.ContactInfoHistory(request),
    select: (response) => response.entries,
  });
};

type SchemaOrderHistory = SchemaIO<(typeof SchemaHistoryMetl)['OrderHistory']>;
export const useOrderHistory = (eventId: string) => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaOrderHistory['input'] = {
    locationId,
    eventId,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.orderHistory(eventId),
    queryFn: () => SchemaHistoryMetl.OrderHistory(request),
    select: (response) => response.entries,
  });
};

type SchemaPersonHistory = SchemaIO<(typeof SchemaHistoryMetl)['PersonHistory']>;
export const usePersonHistory = (personId: string) => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaPersonHistory['input'] = {
    locationId,
    personId,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.personHistory(personId),
    queryFn: () => SchemaHistoryMetl.PersonHistory(request),
    select: (response) => response.entries,
  });
};

type SchemaRecallHistory = SchemaIO<(typeof SchemaHistoryMetl)['RecallHistory']>;
export const useRecallHistory = (eventId: string) => {
  const { locationId } = useLocationDataShallowStore('locationId');

  const request: SchemaRecallHistory['input'] = {
    locationId,
    eventId,
  };

  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.recallHistory(eventId),
    queryFn: () => SchemaHistoryMetl.RecallHistory(request),
    select: (response) => response.entries,
  });
};
