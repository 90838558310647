import { FC, useState, useMemo, Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  PrimaryButton,
  Text,
  useFormField,
  ContentLoader,
  SecondaryButton,
  ButtonBar,
  ModalControlModalProps,
  useAlert,
} from '@frontend/design-system';
import { Steps } from '../../../constants';
import { useTeamChatStore } from '../../../providers';
import { StreamUser } from '../../../types';
import { createChannel } from '../../../utils';
import { CreateChannel } from './create-channel';
import { SelectMembers } from './select-members';

interface NewChannelProps {
  modalProps: ModalControlModalProps;
  step: Steps;
  setStep: Dispatch<SetStateAction<Steps>>;
}

export const NewChannelModal: FC<NewChannelProps> = ({ modalProps, step, setStep }) => {
  const [memberIDs, setMemberIDs] = useState<StreamUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { error } = useAlert();
  const { selectedOrgId } = useAppScopeStore();
  const { setActiveConversation, users, conversations, streamClient } = useTeamChatStore([
    'users',
    'conversations',
    'streamClient',
    'setActiveConversation',
  ]);
  // Keeping it in useMemo to avoid calling map function while re-rendering as it is less likely to change.
  const conversationNames: Set<string> = useMemo(
    () => new Set(conversations.groups.map((conversation) => conversation.name)),
    [conversations.groups]
  );
  const { t } = useTranslation('team-chat');
  const fieldProps = useFormField({
    type: 'text',
    value: '',
    validateOnChange: true,
    validator: (filed) => (conversationNames.has(filed.value) ? t('Channel name already exists') : ''),
  });

  const setupConversation = async () => {
    try {
      setIsLoading(true);
      if (!streamClient) throw new Error('Stream client not found');
      const conversation = await createChannel({
        streamClient,
        isDm: false,
        name: fieldProps.value.trim(),
        members: memberIDs,
        orgId: selectedOrgId,
      });
      setActiveConversation(conversation);
      modalProps.onClose();
      fieldProps.value = '';
      setStep(Steps.One);
      setIsLoading(false);
    } catch (err) {
      error(t('Failed to create a new conversation'));
      setIsLoading(false);
    }
  };
  if (!modalProps.show || !streamClient) return null;

  return (
    <div>
      {step == Steps.Two && (
        <div css={channelInfoStyle}>
          <Text css={channelNameStyle} size='medium'>
            # {fieldProps.value}
          </Text>
          {memberIDs.length > 0 && (
            <Text size='small' css={membersTextStyle} color='subdued'>
              {memberIDs.length}
            </Text>
          )}
        </div>
      )}
      <div css={contentContainerStyle}>
        {step === Steps.One ? (
          <CreateChannel fieldProps={fieldProps} />
        ) : (
          <SelectMembers users={users} members={memberIDs} updateMembers={setMemberIDs} />
        )}
      </div>
      <div css={bottomContainerStyle}>
        <Text css={stepTextStyle}>{t('Step {{step}} of 2', { step })}</Text>
        {step === Steps.One ? (
          <PrimaryButton
            disabled={!fieldProps.value.trim() || conversationNames.has(fieldProps.value)}
            onClick={() => setStep(Steps.Two)}
            css={[marginRight, buttonWidth]}
          >
            {t('Next')}
          </PrimaryButton>
        ) : (
          <ButtonBar css={[buttonWidth, buttonBarStyle, marginRight]}>
            <SecondaryButton css={buttonWidth} onClick={() => setStep(Steps.One)}>
              <Text>{t('Back')}</Text>
            </SecondaryButton>
            <PrimaryButton css={buttonWidth} disabled={!memberIDs.length} onClick={setupConversation}>
              <Text color='white'>{t('Create')}</Text>
            </PrimaryButton>
          </ButtonBar>
        )}
      </div>
      <ContentLoader show={isLoading} message={t('Creating a new conversation')} />
    </div>
  );
};

const channelInfoStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingLeft: theme.spacing(3),
  marginBottom: theme.spacing(2),
});
const channelNameStyle = css({ color: theme.colors.black });
const membersTextStyle = css({
  display: 'flex',
  width: '16px',
  height: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.neutral10,
  borderRadius: theme.borderRadius.small,
});
const contentContainerStyle = css({ padding: theme.spacing(0, 3) });
const bottomContainerStyle = css({
  marginTop: theme.spacing(3),
  paddingTop: theme.spacing(1.5),
  borderTop: `1px solid ${theme.colors.neutral10}`,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});
const stepTextStyle = css({ paddingLeft: theme.spacing(3) });
const buttonBarStyle = css({ padding: 0 });
const buttonWidth = css({ width: 'fit-content' });
const marginRight = css({ marginRight: theme.spacing(3) });
