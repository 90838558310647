import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const pageWrapperStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .token-wrapper {
    padding: ${theme.spacing(0, 0.5)};
  }
`;

export const builderTopBarStyle = css`
  display: flex;
  align-items: center;
  padding: ${theme.spacing(0.5, 1)};
  gap: ${theme.spacing(1)};
`;

export const builderLayoutStyle = css`
  display: flex;
  border-top: 1px solid ${theme.colors.neutral20};
  height: 100%;
  overflow: hidden;
`;
