import { MessageAction_index } from '@weave/schema-gen-ts/dist/schemas/websocket-director/v1/websocket.pb';
import { Permission_index } from '@weave/schema-gen-ts/dist/shared/waccess';
import { DecodedToken, CoreACLs } from '@frontend/auth-helpers';
import { ConnectProps, WebsocketState, HeartbeatState, ServerMessageActionIndex } from './types';
import {
  sendPing,
  sendPong,
  subscribeLocations,
  INITIAL_RECONNECT_WAIT,
  HEALTHY_HEARTBEAT_THRESHOLD,
  WAIT_FOR_PONG_THRESHOLD,
} from './utils';

const THIRTY_SECONDS = 1000 * 30;

export const connect = async ({
  url,
  onMessage,
  heartbeatState,
  reevaluateCurrentState,
  selectedLocationIds,
}: ConnectProps) => {
  const onOpen = (ws: WebSocket, resolve: (val: WebSocket) => void) => () => {
    resolve(ws);
    console.log('[open] WS 2.0 connection open!');
    sendPing(ws);
    if (selectedLocationIds?.length) {
      subscribeLocations({ locationIds: selectedLocationIds, ws });
    }
    setLastHeartbeat(heartbeatState);
  };

  const _onMessage = (ws: WebSocket) => (event: MessageEvent<string>) => {
    if (!event.data) {
      return console.warn('Received websocket event with no data');
    }
    let message;
    try {
      message = JSON.parse(event.data) as ServerMessageActionIndex;
    } catch (e) {
      console.error('Error parsing message from websocket', event.data);
      return;
    }
    if (message.action === MessageAction_index.MESSAGE_ACTION_CLOSE) {
      if (message.payload === 'TOKEN_NO_LONGER_VALID') {
        console.info(
          'Received close message with payload TOKEN_NO_LONGER_VALID, connection should automatically close and then reconnect.'
        );
      }
    }
    // check if there is a ping event in the payload's action.
    // if there is, send a pong and return.
    if (message?.action === MessageAction_index.MESSAGE_ACTION_PING) {
      sendPong(ws);
      setLastHeartbeat(heartbeatState);
      return;
    }
    if (message?.action === MessageAction_index.MESSAGE_ACTION_PONG) {
      setLastHeartbeat(heartbeatState);
      return;
    }
    // Otherwise we'll assume it's a notification type that should be forwarded on to any subscribers
    if (!!message.payload && !!onMessage) {
      try {
        const parsedPayload = JSON.parse(message.payload);
        onMessage(parsedPayload);
      } catch (e) {
        console.error('Error parsing payload from websocket message', message);
      }
    }
  };

  const onClose = (event: CloseEvent) => {
    if (event.wasClean) {
      console.log(`[close] 2.0 connection closed cleanly, code=${event.code} reason=${event.reason}`);
    } else {
      // Some error occurred
      // event.code is usually 1006 in this case
      console.log('[close] 2.0 connection died');
    }
    heartbeatState.lastHeartbeatAt = null;
    // With the update to lastHeartbeatAt we should trigger an immediate reevaluation of the connection state
    reevaluateCurrentState();
  };

  // Clear any heartbeat state from the previous connection
  heartbeatState.connectAttemptStartedAt = Date.now();
  heartbeatState.lastHeartbeatAt = null;
  heartbeatState.lastPingSentAt = null;

  /**
   * can't await `new WebSocket(url)`, so we need to wrap it in a promise
   * this allows `connect` to return an error if the connection fails
   */
  return new Promise<WebSocket>((resolve, reject) => {
    const ws = new WebSocket(url);
    ws.onmessage = _onMessage(ws);
    ws.onclose = onClose;
    ws.onopen = onOpen(ws, resolve);
    ws.onerror = function (error) {
      reject(error);
    };
  });
};

/**
 * This should be called any time a message from the server is received. We will treat any message
 *   received from the server as a heartbeat that indicates the connection is still alive. Because
 *   the connection is healthy, we can reset other pieces of state that track potential failure metrics.
 * @param ref The heartbeat state to update
 */
function setLastHeartbeat(state: HeartbeatState) {
  state.lastHeartbeatAt = Date.now();
  state.lastPingSentAt = null;
  state.connectAttemptStartedAt = null;
  state.reconnectWaitInterval = INITIAL_RECONNECT_WAIT;
  state.inReconnectLoop = false;
}

/**
 * This takes health metrics of the websocket to determine the current state and possible actions or
 *   next steps that need to be taken for the websocket connection.
 * @param url Current url used to connect to the websocket
 * @param decodedWeaveToken The decoded token used to authenticate the websocket connection
 * @param heartbeatState The pieces of state that track the health of the connection
 * @returns A single value indicating the current state of the websocket connection
 */
export function getWebsocketState(
  url: string,
  decodedWeaveToken: DecodedToken<CoreACLs | Permission_index> | undefined,
  heartbeatState: HeartbeatState
): WebsocketState {
  if (!url) {
    return WebsocketState.WAITING_FOR_URL;
  }
  const nowInMs = Date.now();
  const decodedWeaveTokenExpInMs = (decodedWeaveToken?.exp || 0) * 1000; // token exp is in seconds
  const {
    connectAttemptStartedAt,
    lastHeartbeatAt,
    lastPingSentAt,
    reconnectWaitInterval,
    inReconnectLoop,
    refreshTokenStartedAt,
  } = heartbeatState;

  // If the current token is going to expire in the next 30 seconds, we need to refresh it
  if (!!decodedWeaveToken && decodedWeaveTokenExpInMs - nowInMs < THIRTY_SECONDS) {
    if (refreshTokenStartedAt === null) {
      return WebsocketState.NEED_TO_REFRESH_TOKEN;
    } else {
      return WebsocketState.WAITING_FOR_URL;
    }
  }

  if (inReconnectLoop) {
    if (connectAttemptStartedAt === null) {
      // We are in a reconnect loop, but we haven't started a new connection attempt yet
      return WebsocketState.NEED_TO_RECONNECT;
    } else if (nowInMs - connectAttemptStartedAt < reconnectWaitInterval) {
      // We are in a reconnect loop and we are still WAITING to reconnect (not TOO much time has passed)
      return WebsocketState.RECONNECTING;
    } else {
      // The last reconnect attempt was TOO LONG ago, we need to try another reconnect
      return WebsocketState.NEED_TO_RECONNECT;
    }
  }

  if (lastHeartbeatAt === null) {
    if (connectAttemptStartedAt === null) {
      // If we have a url but no heartbeat have started a connection attempt, we need to connect
      return WebsocketState.NEED_TO_RECONNECT;
    }
    // No heartbeat received yet, check how long we've been waiting to connect
    if (nowInMs - connectAttemptStartedAt < HEALTHY_HEARTBEAT_THRESHOLD) {
      return WebsocketState.CONNECTING;
    } else {
      return WebsocketState.NEED_TO_RECONNECT;
    }
  }

  // We have a heartbeat, check how long it's been since the last one
  if (nowInMs - lastHeartbeatAt < HEALTHY_HEARTBEAT_THRESHOLD) {
    return WebsocketState.HEALTHY_CONNECTION;
  }

  // We have a heartbeat, but it's been TOO LONG since the last one
  if (lastPingSentAt === null) {
    return WebsocketState.NEED_TO_SEND_PING;
  } else if (nowInMs - lastPingSentAt < WAIT_FOR_PONG_THRESHOLD) {
    // We sent a ping, now we just need to wait for a pong
    return WebsocketState.WAITING_FOR_PONG;
  }
  // We are in an unknown state, we need to reconnect
  return WebsocketState.NEED_TO_RECONNECT;
}
