import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import {
  Text,
  useFormField,
  TextareaField,
  TextLink,
  useModalControl,
  ConfirmationModal,
} from '@frontend/design-system';
import { MIN_MODAL_WIDTH } from '../../../constants';
import { editingAreaContainerStyle, modalTextAreaStyle, commentTextStyle } from './info-with-editor.styles';

interface InfoEditorProps {
  title: string;
  placeholder?: string;
  value?: string;
  canEdit?: boolean;
  onClickSave?: (text: string) => Promise<void>;
  className?: string;
  comment?: string;
}

export const InfoWithEditor: FC<InfoEditorProps> = ({
  title,
  placeholder,
  value,
  canEdit = false,
  onClickSave,
  className,
  comment,
}) => {
  const editorProps = useFormField({ type: 'text', value });
  const { t } = useTranslation('team-chat');
  const { modalProps, openModal } = useModalControl();

  const onSave = async () => {
    //   We don't want to make unnecessary API calls if the value hasn't changed
    if (onClickSave && value?.trim() !== editorProps.value.trim()) {
      await onClickSave(editorProps.value);
    }
  };

  return (
    <div className={className}>
      <Text size='large' weight='bold'>
        {title}
      </Text>
      <div css={editingAreaContainerStyle}>
        <Text>{value || placeholder}</Text>
        {canEdit && (
          <TextLink onClick={openModal}>
            <Text color='primary' weight='bold'>
              {t('Edit')}
            </Text>
          </TextLink>
        )}
      </div>
      <ConfirmationModal
        maxWidth={MIN_MODAL_WIDTH}
        onConfirm={onSave}
        {...modalProps}
        title={title}
        confirmLabel={t('Save')}
      >
        <TextareaField
          label={''}
          placeholder={placeholder}
          name={value ?? ''}
          {...editorProps}
          css={modalTextAreaStyle}
          style={{ width: '400px' }}
        />
        {comment && <Text css={commentTextStyle}>{comment}</Text>}
      </ConfirmationModal>
    </div>
  );
};
