export const featureFlags = {
  // Appointment
  appointmentAnalyticsDemoData: 'appointment-analytics-demo-data',
  appointmentAnalytics: 'appointment-reporting-table',
  appointmentAnalyticsDashboard: 'appointment-reporting-dashboard',

  // Auto Recall
  autoRecallDemoData: 'auto-recall-analytics-demo-data',

  // Call Intel
  enableCallIntelSearch: 'enable-call-intel-search',
  enableCallIntelEmailSettings: 'enable-call-intel-email-settings',
  enableCallIntelFollowUps: 'enable-call-intel-follow-ups',

  // Messaging
  messagingAnalytics: 'sms-reporting-enabled',
  messagingAnalyticsDemoData: 'messaging-analytics-demo-data',

  // Phone
  phoneAnalytics: 'phone-reporting-enabled',
  phoneAnalyticsDemoData: 'phone-analytics-demo-data',
  phoneAnalyticsExtension: 'phone-analytics-extensions',

  // Practice
  paBulkMessaging: 'practice-analytics-bulk-messaging',
  practiceAnalyticsDemo: 'analytics-demo-data',
  practiceAnalyticsFreeTrial: 'practice-analytics-free-trial-enabled',
};
