import { FC, useEffect, useMemo, useState } from 'react';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { emptyStateGraphics, useAlert } from '@frontend/design-system';
import { BulkMessageButton, NewPatientsMetric } from '..';
import { InlineStats, TabbedTable } from '../..';
import { useAnalyticsOrgLocations, usePracticeAnalyticsStore, useShowBulkMessageButton } from '../../../hooks';
import { queryKeys } from '../../../query-keys';
import { trackingIds } from '../../../tracking-ids';
import { ExportUtils, formatters } from '../../../utils';
import { commonStyles } from '../../../views';
import { PatientsHelpers, getPracticeAnalyticsQueryVariables, subViewTableColConfig } from '../helpers';
import { usePatientsLastContacted } from '../hooks';

const query = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      newPatients {
        details(start: $start, end: $end, step: $step) {
          patients {
            id
            productionAmount
            firstAppointmentDate
            firstAppointmentType
            nextAppointmentDate
            nextHygieneAppointment
            FirstName: firstName
            LastName: lastName
            PMID: pmid
            MobilePhone: mobilePhone
            HomePhone: homePhone
            WorkPhone: workPhone
            Email: email
            PreferredName: preferredName
            Birthdate: birthdate
            Gender: gender
          }
        }
        totals(start: $start, end: $end, step: $step) {
          patients
          production
          unscheduledProductionPerDay {
            date
            amount
          }
        }
      }
    }
  }
`;

type CategorizedPatients = {
  all: PracticeAnalyticsTypes.PatientInfo[];
  hygiene: PracticeAnalyticsTypes.PatientInfo[];
  restorative: PracticeAnalyticsTypes.PatientInfo[];
};

export const NewPatientsSubView: FC<React.PropsWithChildren<PracticeAnalyticsTypes.MetricSubViewProps>> = ({
  exportEnabled,
  onPatientsLoad,
}) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount } = usePracticeAnalyticsStore();
  const { locationNames } = useAnalyticsOrgLocations({ module: 'PA' });
  const isBulkMessagingEnabled = useShowBulkMessageButton();

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

  const [categorizedData, setCategorizedData] = useState<CategorizedPatients>({
    all: [],
    hygiene: [],
    restorative: [],
  });

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(`newPatientsSubView-${isDemoAccount}-${JSON.stringify(filters)}`),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.NewPatientsTrend>({
            locationIds: filters.locations,
            queries: [query],
            variables: getPracticeAnalyticsQueryVariables(filters),
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    select: (data) => (isDemoAccount ? demoData?.newPatients : data),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const processedData = useMemo(() => {
    return {
      aggregatedData: PracticeAnalyticsAggregations.newPatientsDetails(data),
      patientIds: Object.values(data?.data || {}).reduce((acc, locationDetails) => {
        return PatientsHelpers.collectPatientIds(locationDetails?.location?.newPatients.details?.patients, acc);
      }, [] as string[]),
    };
  }, [data?.data]);

  const patientsLastContacted = usePatientsLastContacted({
    patientIds: processedData.patientIds,
  });

  const middleColumns = [
    {
      Header: t('First Appt'),
      headerLabel: t('First Appt'),
      accessor: ({ firstAppointmentDate }: PracticeAnalyticsTypes.PatientInfo) => firstAppointmentDate || '',
      cellRenderer: (date: string) => (date ? formatters.date.format(date) : '-'),
      id: 'firstAppointmentDate',
      width: 200,
    },
  ];

  const tabsCommonConfig = {
    colConfig: subViewTableColConfig({
      actionsTrackingIdBase: 'new-patient',
      middleColumns,
      patientsLastContacted,
    }),
    emptyStateConfig: 'users' as keyof typeof emptyStateGraphics,
    tableInstanceId: 'newPatientsPracticeSubView',
  };

  useEffect(() => {
    const patients = processedData.aggregatedData.newPatients.details.patients || [];

    setCategorizedData(
      patients.reduce(
        (final, patient) => {
          let value = { ...final, all: [...final.all, patient] };
          if (patient.firstAppointmentType === 'hygiene') {
            value = { ...value, hygiene: [...value.hygiene, patient] };
          }
          if (patient.firstAppointmentType === 'restorative') {
            value = { ...value, restorative: [...value.restorative, patient] };
          }
          return value;
        },
        { all: [], hygiene: [], restorative: [] } as CategorizedPatients
      )
    );
    onPatientsLoad?.(patients);
  }, [processedData]);

  return (
    <>
      <InlineStats
        data={[
          {
            label: t('New Patients'),
            value: formatters.value.format(processedData.aggregatedData.newPatients.totals.patients || 0),
          },
          {
            label: t('Completed Production'),
            value: formatters.currency.format(processedData.aggregatedData.newPatients.totals.production || 0),
          },
          {
            label: t('Uncaptured Production'),
            value: formatters.currency.format(
              processedData.aggregatedData.newPatients.totals.unscheduledProductionPerDay.reduce(
                (sum, { amount }) => sum + amount,
                0
              ) || 0
            ),
          },
        ]}
        isDemoAccount={isDemoAccount}
        isLoading={isLoading}
      />
      <NewPatientsMetric clickNoop isDrillDownPage />
      <TabbedTable
        actions={BulkMessageButton}
        autorizedToExport={PracticeAnalyticsApi.auditPracticeAnalyticsExport}
        exportFileName='New Patients' // Translation not needed
        initialTab='all'
        isExportReady={!isLoading && !patientsLastContacted.isLoading}
        isLoading={isLoading}
        isSelectable={isBulkMessagingEnabled}
        processExportableData={(data) =>
          // Translation is not needed as this is for export purpose
          ExportUtils.processExportableData({
            columns: [
              'Patient Name',
              ...(multipleLocationsSelected ? ['Location Name'] : []),
              'Phone Number',
              'First Appt',
              'Last Contacted',
              '$ Value',
            ],
            data,
            deriveExportValue: (params) =>
              PatientsHelpers.deriveExportValue({
                ...params,
                lastContactedDates: patientsLastContacted.lastContactedDates,
                locationNames,
              }),
            sortColumn: 'productionAmount',
          })
        }
        showExportIcon={exportEnabled}
        tabs={{
          all: {
            ...tabsCommonConfig,
            data: categorizedData.all,
            label: t('All'),
            trackingId: trackingIds.practiceAnalytics.newPatientsAllTab,
          },
          restorative: {
            ...tabsCommonConfig,
            data: categorizedData.restorative,
            label: t('Restorative'),
            trackingId: trackingIds.practiceAnalytics.newPatientsRestorativeTab,
          },
          hygiene: {
            ...tabsCommonConfig,
            data: categorizedData.hygiene,
            label: t('Hygiene'),
            trackingId: trackingIds.practiceAnalytics.newPatientsHygieneTab,
          },
        }}
        trackingIdBase='new-patients'
        wrapperStyle={commonStyles.drillDownTableSize}
      />
    </>
  );
};
