import { HistoryQueries } from '@frontend/api-messaging';
import { useEffect, useState } from 'react';
import { RecordDiff } from './record-diff';
import { ListsIcon, PreviewIcon, SpinningLoader, Table } from '@frontend/design-system';
import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { Recall } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/history/v1/service.pb';
import { compareRecordStyles, currentRecordStyles } from './record-history-styles';

type Props = {
  recordId: string;
};

export const RecallDetails = ({ recordId }: Props) => {
  const { t } = useTranslation('messages');

  const [currentRecord, setCurrentRecord] = useState<Recall>({});
  const [compareRecord, setCompareRecord] = useState<Recall>({});

  const { data: recallData = [], isLoading, isFetching } = HistoryQueries.useRecallHistory(recordId);

  useEffect(() => {
    if (recallData.length) {
      setCurrentRecord(recallData?.filter((item) => item.isCurrent)[0].recall || {});
    }
  }, [recallData]);

  if (isLoading || isFetching) {
    return <SpinningLoader />;
  }

  return (
    <>
      <Table
        colConfig={[
          {
            Header: t('Action'),
            id: 'action',
            width: 30,
            accessor: (data) => data?.action,
            disableSortBy: true,
          },
          {
            Header: t('Event At'),
            id: 'eventAt',
            width: 80,
            accessor: (data) =>
              data?.recall?.eventAt ? dayjs(data?.recall?.eventAt).format('YYYY/MM/DD, h:mm A') : '',
            disableSortBy: true,
          },
          {
            Header: t('Modified At'),
            id: 'modifiedAt',
            width: 80,
            accessor: (data) =>
              data?.recall?.modifiedAt ? dayjs(data?.recall?.modifiedAt).format('YYYY/MM/DD, h:mm A') : '',
            disableSortBy: true,
          },
          {
            Header: t('Type'),
            id: 'type',
            width: 60,
            accessor: (data) =>
              data?.recall?.type && Array.isArray(data?.recall?.type)
                ? data?.recall?.type.join(' ')
                : data?.recall?.type,
            disableSortBy: true,
          },
          {
            Header: t('Person Id'),
            id: 'personId',
            width: 80,
            accessor: (data) => data?.recall?.personId,
            disableSortBy: true,
          },
        ]}
        data={recallData}
        isLoading={isLoading || isFetching}
        collapsedTableConfig={
          (recallData?.length ?? 0) > 4
            ? {
                rowsToShow: 4,
                collapseLabel: t('Show Less...'),
                expandLabel: t('Show More...'),
                startingValue: false,
              }
            : undefined
        }
        rowActions={{
          rowActionMenuLabel: '',
          actions: [
            {
              label: t('Show Details'),
              Icon: ListsIcon,
              onClick: (item) => {
                setCurrentRecord(item.recall || {});
              },
            },
            {
              label: t('Compare'),
              Icon: PreviewIcon,
              onClick: (item) => {
                setCompareRecord(item.recall || {});
              },
            },
          ],
        }}
        styleConfig={{
          rows: (rowData) => {
            if (rowData.recall?.modifiedAt === currentRecord.modifiedAt) {
              return currentRecordStyles;
            }
            if (rowData.recall?.modifiedAt === compareRecord.modifiedAt) {
              return compareRecordStyles;
            }
            return;
          },
        }}
      />
      <RecordDiff
        currentObj={currentRecord}
        compareObj={compareRecord}
        currentDate={dayjs(currentRecord.modifiedAt).format('YYYY/MM/DD, h:mm A')}
        compareDate={dayjs(compareRecord.modifiedAt).format('YYYY/MM/DD, h:mm A')}
      />
    </>
  );
};
