import { useMutation, UseMutationOptions, useQueryClient, UseQueryOptions } from 'react-query';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { AggregatedReviews, Mappings, ReviewCommentPayload, Settings } from './reviews-types';
import {
  getMappings,
  getSettings,
  updateSettings,
  createReviewComment,
  getAggregatedReviews,
  updateComment,
  deleteComment,
  updateReviewReadStatus,
} from './reviews-api';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

const baseKey = 'reviews';

export const queryKeys = {
  mappings: [baseKey, 'mappings'],
  settings: [baseKey, 'settings'],
  aggregatedReviews: [baseKey, 'aggregated'],
  aggregatorReviews: [baseKey, 'aggregator'],
  reviewDetails: (site: string, id: string) => [baseKey, site, id],
} as const;

export const useAggregatedReviews = (options?: UseQueryOptions<AggregatedReviews>) => {
  return useScopedQuery({
    ...defaultOptions,
    queryKey: queryKeys.aggregatedReviews,
    queryFn: getAggregatedReviews,
    refetchOnWindowFocus: true,
    ...options,
  });
};

export const useGetMappings = (options: UseQueryOptions<Mappings> = {}) =>
  useScopedQuery({
    ...defaultOptions,
    queryKey: queryKeys.mappings,
    queryFn: getMappings,
    ...options,
  });

export const useGetSettings = (locationId?: string, options: UseQueryOptions<Settings> = {}) =>
  useScopedQuery({
    ...defaultOptions,
    queryKey: queryKeys.settings,
    queryFn: () => getSettings(locationId),
    ...options,
  });

export const useUpdateSettingsMutation = (
  options: UseMutationOptions<
    Settings,
    Error,
    Settings,
    {
      previousSettings: Settings | undefined;
    }
  > = {}
) => {
  const queryClient = useQueryClient();
  const { selectedLocationIds } = useAppScopeStore();
  const settingsKey = [selectedLocationIds, ...queryKeys.settings];

  return useMutation(updateSettings, {
    ...defaultOptions,
    mutationKey: settingsKey,
    onError: (error: Error, updatedSettings, context) => {
      if (context) {
        queryClient.setQueriesData(settingsKey, context.previousSettings);
      }
      if (options.onError) {
        options.onError(error, updatedSettings, context);
      }
    },
    ...options,
    onSuccess: async (res, req, ...rest) => {
      await queryClient.cancelQueries(settingsKey);
      queryClient.setQueryData(settingsKey, req);
      options.onSuccess?.(res, req, ...rest);
    },
  });
};

export const useCreateReviewComment = (
  options?: UseMutationOptions<AggregatedReviews, unknown, ReviewCommentPayload>
) => {
  return useMutation(createReviewComment, {
    ...options,
  });
};

export const useUpdateReviewReadStatus = (
  options?: UseMutationOptions<unknown, unknown, Omit<ReviewCommentPayload, 'comment'>>
) => {
  return useMutation(updateReviewReadStatus, {
    ...options,
  });
};

export const useUpdateReviewComment = (options?: UseMutationOptions<unknown, unknown, ReviewCommentPayload>) => {
  return useMutation(updateComment, {
    ...options,
  });
};

export const useDeleteReviewComment = (options?: UseMutationOptions<unknown, unknown, ReviewCommentPayload>) => {
  return useMutation(deleteComment, {
    ...options,
  });
};
