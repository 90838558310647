import { UseQueryOptions } from 'react-query';
import { useQuery } from '@frontend/react-query-helpers';
import {
  GetDevice,
  GetDeviceE911Address,
  GetDeviceExtensionCallGroups,
  GetDeviceExtensions,
  ListDevices,
  UpdateDevice,
  UpdateDeviceExtension,
  ValidateDestroyLine,
} from './api';
import { queryKeys } from './query-keys';
import type {
  ValidateDestroLineType,
  GetDeviceExtensionsType,
  GetDeviceLineType,
  GetDeviceExtensionCallGroupsType,
  GetDevicesListType,
  GetDeviceE911AddressType,
  UpdateDeviceType,
  UpdateDeviceExtensionType,
} from './types';

export const useValidateDestroyQuery = (req: ValidateDestroLineType['input'], opts: { locationId: string }) => {
  return useQuery({
    queryKey: [opts.locationId, ...queryKeys.validateDestroyLine(req.id ?? '')],
    queryFn: () => ValidateDestroyLine(req),
    refetchOnMount: 'always',
  });
};

export const useGetDeviceQuery = (req: GetDeviceLineType['input'], opts: { locationId: string }) => {
  return useQuery({
    queryKey: [opts.locationId, ...queryKeys.deviceById(req.deviceId ?? '')],
    queryFn: () =>
      GetDevice(
        {
          deviceId: req.deviceId,
        },
        { headers: { 'Location-Id': opts.locationId } }
      ),
  });
};

export const useGetDeviceE911Address = (req: GetDeviceE911AddressType['input'], opts: { locationId: string }) => {
  return useQuery({
    queryKey: [opts.locationId, ...queryKeys.deviceAddressById(req.deviceId)],
    queryFn: () =>
      GetDeviceE911Address(
        {
          deviceId: req.deviceId,
        },
        { headers: { 'Location-Id': opts.locationId } }
      ),
  });
};

export const useGetDeviceExtension = (req: GetDeviceExtensionsType['input'], opts: { locationId: string }) => {
  return useQuery({
    queryKey: [opts.locationId, ...queryKeys.deviceByExtensionId(req.deviceId ?? '')],
    queryFn: () =>
      GetDeviceExtensions(
        {
          deviceId: req.deviceId,
        },
        { headers: { 'Location-Id': opts.locationId } }
      ),
  });
};

export const useGetDeviceExtensionCallGroups = (
  req: GetDeviceExtensionCallGroupsType['input'],
  opts: { locationId: string }
) => {
  return useQuery({
    queryKey: [opts.locationId, ...queryKeys.listDeviceExtensionCallGroups(req.deviceId ?? '')],
    queryFn: () => GetDeviceExtensionCallGroups(req, { headers: { 'Location-Id': opts.locationId } }),
  });
};

export const useGetDevicesList = <T = GetDevicesListType['output']>(
  req: GetDevicesListType['input'],
  opts: UseQueryOptions<GetDevicesListType['output'], unknown, T> & { locationId?: string }
) => {
  return useQuery({
    queryKey: [req.locationIds ?? opts.locationId, ...queryKeys.listDevices(req.include)],
    queryFn: () => ListDevices(req, opts.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined),
    ...opts,
  });
};

export const useUpdateDevice = (req: UpdateDeviceType['input'], opts: { locationId: string }) => {
  return useQuery({
    queryKey: '',
    queryFn: () =>
      UpdateDevice(
        {
          device: req.device,
        },
        { headers: { 'Location-Id': opts.locationId } }
      ),
  });
};

export const useUpdateDeviceExtension = (req: UpdateDeviceExtensionType['input'], opts: { locationId: string }) => {
  return useQuery({
    queryKey: '',
    queryFn: () =>
      UpdateDeviceExtension(
        {
          deviceId: req.deviceId,
          extensionId: req.extensionId,
          extension: req.extension,
        },
        { headers: { 'Location-Id': opts.locationId } }
      ),
  });
};
