import { useState } from 'react';
import { Placement } from '@floating-ui/react';
import { DataSourcesTypes } from '@frontend/api-data-sources';
import { PersonTypes } from '@frontend/api-person';
import { usePopoverDialog } from '@frontend/design-system';
import { FormFields, PatientSelectorScreenEnum, PersonSelectorPropsType, UsePersonSelectorReturnType } from './types';

export { PatientSelectorScreenEnum } from './types';

interface UsePersonSelectorProps {
  placement?: Placement;
  initialOffset?: { x: number; y: number };
  formFields: FormFields;
  dataSources?: DataSourcesTypes.DataSource[];
  clientLocationIds?: DataSourcesTypes.AccessDataResponse['ClientLocations'];
}

const defaultFormFields: FormFields = {
  firstName: { value: '', required: true },
  lastName: { value: '', required: true },
  phoneNumber: { value: '', required: true },
  notes: { value: '' },
};

export const usePersonSelector = ({
  formFields,
  placement,
  initialOffset,
  dataSources,
  clientLocationIds = [],
}: UsePersonSelectorProps): UsePersonSelectorReturnType => {
  const [personSelectorScreen, setPersonSelectorScreen] = useState(PatientSelectorScreenEnum.PERSON_SEARCH);
  const [selectedPerson, setSelectedPerson] = useState<PersonTypes.Person | null>(null);
  const [notes, setNotes] = useState<string>('');
  const personSelectorDialogProps = usePopoverDialog({
    placement: placement ?? 'bottom-start',
    initialOffset: initialOffset,
  });

  const personSelectorProps: PersonSelectorPropsType = {
    setSelectedPerson,
    setNotes,
    formFields: formFields ?? defaultFormFields,
    dataSources,
    personSelectorScreen,
    clientLocationIds,
    setPersonSelectorScreen,
  };

  return {
    selectedPerson,
    notes,
    personSelectorDialogProps,
    personSelectorProps: personSelectorProps,
  };
};
