import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { getUser } from '@frontend/auth-helpers';
import { GradientPage } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useHasFeatureFlag } from '@frontend/shared';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { Avatar, IconButton, SpinningLoader, styles } from '@frontend/design-system';
import { DebugModeRibbon } from '../components';
import { BusinessInfoFormStep, TemporaryNumberInfoStep } from '../components/new-onboard-form-steps';
import {
  INTAKE_FORM_PATHS,
  INTAKE_FORM_V2_FEATURE_FLAG,
  NWX_DASHBOARD_PATH,
  PORTAL_DASHBOARD_PATH,
  trackingIds,
} from '../constants';
import { IntakeFormProvider } from '../providers/intake-form.provider';

enum IntakeFormStepsV2 {
  Loader,
  BusinessInfo,
  NewNumberInfo,
}

interface Props {
  onSignOut: () => void;
}

// This is the new intake form which supports only single software-only location.
export const IntakeFormV2 = ({ onSignOut }: Props) => {
  const user = getUser();
  const { t } = useTranslation('onboarding');
  const navigate = useNavigate();
  const hasIntakeFormV2FF = useHasFeatureFlag(INTAKE_FORM_V2_FEATURE_FLAG);
  const [step, setStep] = useState<IntakeFormStepsV2>(IntakeFormStepsV2.Loader);
  const {
    isShowIntakeForm,
    isSingleSoftwareOnlyLocation,
    intakeFormLocations,
    ownIntakeFormLocations,
    selectedIntakeFormLocationId,
    setSelectedIntakeFormLocationId,
  } = useIntakeFormShallowStore(
    'isShowIntakeForm',
    'isSingleSoftwareOnlyLocation',
    'intakeFormLocations',
    'ownIntakeFormLocations',
    'selectedIntakeFormLocationId',
    'setSelectedIntakeFormLocationId'
  );

  useEffect(() => {
    if (isShowIntakeForm && hasIntakeFormV2FF && isSingleSoftwareOnlyLocation) {
      const hasOwnIncompleteIntakeForm = ownIntakeFormLocations.some((form) => !form.formCompletedAt);
      if (!hasOwnIncompleteIntakeForm) {
        sentry.warn({
          error: 'Intake-form-v2 opened from non-onboarding-contact user',
          topic: 'onboarding',
          addContext: {
            name: 'additional-info',
            context: {
              user: user?.username ?? '',
              intakeFormLocationId: selectedIntakeFormLocationId,
            },
          },
        });
      }
      setSelectedIntakeFormLocationId(intakeFormLocations.find((form) => !form.formCompletedAt)?.locationId);
      setStep(IntakeFormStepsV2.BusinessInfo);
    } else if (!isShowIntakeForm) {
      navigate({
        to: hasIntakeFormV2FF ? NWX_DASHBOARD_PATH : PORTAL_DASHBOARD_PATH,
        replace: true,
      });
    } else if (!hasIntakeFormV2FF || !isSingleSoftwareOnlyLocation) {
      navigate({ to: INTAKE_FORM_PATHS.root, replace: true });
    }
  }, [isShowIntakeForm, hasIntakeFormV2FF, isSingleSoftwareOnlyLocation]);

  const handleBusinessInfoStepSuccess = async () => {
    setStep(IntakeFormStepsV2.NewNumberInfo);
  };

  const handleTemporaryPhoneNumberStepSaveSuccess = () => {
    navigate({ to: NWX_DASHBOARD_PATH });
  };

  const handleTemporaryPhoneNumberStepBackClick = () => {
    setStep(IntakeFormStepsV2.BusinessInfo);
  };

  const currentStepComponent = useMemo(() => {
    switch (step) {
      case IntakeFormStepsV2.NewNumberInfo:
        return (
          <TemporaryNumberInfoStep
            onBackClick={handleTemporaryPhoneNumberStepBackClick}
            onSaveSuccess={handleTemporaryPhoneNumberStepSaveSuccess}
          />
        );
      case IntakeFormStepsV2.BusinessInfo:
        return <BusinessInfoFormStep onSaveSuccess={handleBusinessInfoStepSuccess} />;
      case IntakeFormStepsV2.Loader:
      default:
        // SP-TODO: replace loader with lottie files
        return (
          <div css={styles.flexCenter}>
            <SpinningLoader />
          </div>
        );
    }
  }, [step]);

  const userFullName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim();
  return (
    <GradientPage pageStyles={[styles.flexCenter, { height: '100vh' }]} showWeaveIconAtBottom>
      <IconButton
        label={t('Sign Out')}
        showLabelAlways
        css={logoutButtonStyle}
        onClick={onSignOut}
        trackingId={trackingIds.onboardingV2.signOutButtonClick}
      >
        <Avatar name={userFullName} size='small' />
      </IconButton>
      <IntakeFormProvider>
        <DebugModeRibbon shouldShowToggle css={ribbonStyle} />
        {currentStepComponent}
      </IntakeFormProvider>
    </GradientPage>
  );
};

const ribbonStyle = css({
  position: 'absolute',
  top: 0,
  width: '100%',
  opacity: 0.5,
});

const logoutButtonStyle = css({
  position: 'absolute',
  top: 50,
  right: 50,
  ':focus-visible': {
    background: 'transparent',
    outline: 'none',
    boxShadow: `0 0 0 2px ${theme.colors.neutral50}`,
  },
});
