import { useEffect } from 'react';
import { useModalControl } from '@frontend/design-system';
import { ScheduleRequestModalTypeEnum } from '../types';
import { ScheduleRequestErrorModal } from './ScheduleRequestErrorModal';
import { ScheduleRequestMessageErrorModal } from './ScheduleRequestMessageErrorModal';
import { ScheduleRequestMessageSentModal } from './ScheduleRequestMessageSentModal';
import { ScheduleRequestWritebackErrorModal } from './ScheduleRequestWritebackErrorModal';

interface ScheduleRequestModalProps {
  modalType: ScheduleRequestModalTypeEnum | '';
  onConfirm: () => void;
  fullName: string;
  onClose: () => void;
  setModalType: (modalType: ScheduleRequestModalTypeEnum | '') => void;
}

export const ScheduleRequestModalManager = ({
  modalType,
  fullName,
  onClose,
  onConfirm,
  setModalType,
}: ScheduleRequestModalProps) => {
  const scheduleRequestMessageSentModalProps = useModalControl();

  useEffect(() => {
    if (modalType) {
      scheduleRequestMessageSentModalProps.openModal();
    }
  }, [modalType]);

  useEffect(() => {
    return () => setModalType('');
  }, []);

  if (!modalType) return null;

  if (modalType === ScheduleRequestModalTypeEnum.MESSAGE_SENT) {
    return (
      <ScheduleRequestMessageSentModal
        modalProps={scheduleRequestMessageSentModalProps.modalProps}
        fullName={fullName}
        onClose={onClose}
      />
    );
  }

  if (modalType === ScheduleRequestModalTypeEnum.WRITEBACK_ERROR) {
    return (
      <ScheduleRequestWritebackErrorModal
        modalProps={scheduleRequestMessageSentModalProps.modalProps}
        onConfirm={onConfirm}
      />
    );
  }

  if (modalType === ScheduleRequestModalTypeEnum.ERROR) {
    return (
      <ScheduleRequestErrorModal modalProps={scheduleRequestMessageSentModalProps.modalProps} onConfirm={onConfirm} />
    );
  }

  if (modalType === ScheduleRequestModalTypeEnum.MESSAGE_ERROR) {
    return (
      <ScheduleRequestMessageErrorModal
        modalProps={scheduleRequestMessageSentModalProps.modalProps}
        onClose={onClose}
      />
    );
  }

  return null;
};
