import { MessagesTypes } from '@frontend/api-messaging';
import { CoreACLs, hasACL } from '@frontend/auth-helpers';
import { Page } from '@frontend/components';
import { useOrgData } from '@frontend/get-org-data';
import { useTranslation } from '@frontend/i18n';
import { Alert, Text } from '@frontend/design-system';
import { AppointmentDetails } from '../components/record-history/appointment-details';
import { ContactInfoDetail } from '../components/record-history/contact-info-details';
import { OrderDetails } from '../components/record-history/order-details';
import { PersonDetails } from '../components/record-history/person-details';
import { RecallDetails } from '../components/record-history/recall-details';

type Props = {
  recordType: string;
  recordId: string;
};

const titleMap = {
  [MessagesTypes.RecordType.APPOINTMENT]: 'Appointment Details',
  [MessagesTypes.RecordType.CONTACTINFO]: 'Contact Info Details',
  [MessagesTypes.RecordType.ORDER]: 'Order Details',
  [MessagesTypes.RecordType.PERSON]: 'Person Details',
  [MessagesTypes.RecordType.RECALL]: 'Recall Details',
};

export const RecordDetailsPage = ({ recordType, recordId }: Props) => {
  const { t } = useTranslation('messages');
  const { locationId } = useOrgData();
  const canView = hasACL(locationId, CoreACLs.MESSAGE_HISTORY_VIEW);

  if (!canView) {
    return (
      <Alert id='record-details-permission' type='warning'>
        <Text>{t("You don't have permission to view Record History.")}</Text>
      </Alert>
    );
  }

  const recordTitle = titleMap[recordType as keyof typeof titleMap];

  return (
    <Page title={t(`{{recordTitle}}`, { recordTitle })}>
      {recordType === MessagesTypes.RecordType.APPOINTMENT && <AppointmentDetails recordId={recordId} />}
      {recordType === MessagesTypes.RecordType.CONTACTINFO && <ContactInfoDetail recordId={recordId} />}
      {recordType === MessagesTypes.RecordType.ORDER && <OrderDetails recordId={recordId} />}
      {recordType === MessagesTypes.RecordType.PERSON && <PersonDetails recordId={recordId} />}
      {recordType === MessagesTypes.RecordType.RECALL && <RecallDetails recordId={recordId} />}
    </Page>
  );
};
