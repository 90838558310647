import { ModalControlResponse } from '@frontend/design-system';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FC, PropsWithChildren } from 'react';
import { useFeatureSubscriptionConfig } from '../../hooks/useFeatureSubscriptionConfig';
import { useFeatureUpgradeDetailsModal } from '../../hooks/useFeatureUpgradeDetailsModal';
import { FeatureSubscriptionContext } from '../../providers/FeatureSubscriptionProvider';

interface FeatureUpgradeModalWrapperProps {
  modalControls: ModalControlResponse;
  feature: Feature;
  onUpgradeSuccess: () => void;
}

export const FeatureUpgradeModalWrapper: FC<PropsWithChildren<FeatureUpgradeModalWrapperProps>> = ({
  children,
  feature,
  modalControls,
  onUpgradeSuccess,
}) => {
  const featureSubscriptionConfig = useFeatureSubscriptionConfig({ feature });
  const { FeatureUpgradeDetailsModal } = useFeatureUpgradeDetailsModal({
    feature,
  });

  return (
    <FeatureSubscriptionContext.Provider value={featureSubscriptionConfig}>
      {children}
      {!!FeatureUpgradeDetailsModal && (
        <FeatureUpgradeDetailsModal modalControls={modalControls} onActionSuccess={onUpgradeSuccess} />
      )}
    </FeatureSubscriptionContext.Provider>
  );
};
