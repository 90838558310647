import React, { CSSProperties, ElementType, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { flip, offset, shift, useFloating } from '@floating-ui/react';
import { useMatches } from '@tanstack/react-location';
import { ReactLocationDevtools } from '@tanstack/react-location-devtools';
import dayjs from 'dayjs';
import { useHotkeys } from 'react-hotkeys-hook';
import { useMutation, useQueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { showTranslations } from 'translation-check';
import { transparentize } from '@frontend/colors';
import { useDevToolsStore } from '@frontend/devtools';
import appConfig from '@frontend/env';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useNotificationQueue, useNotificationSettingsShallowStore } from '@frontend/notifications';
import { useCallPopStateSync } from '@frontend/pop';
import { useAppScopeStore } from '@frontend/scope';
import { useShell } from '@frontend/shell-utils';
import { useWebsocketEventPublisher } from '@frontend/websocket';
import { theme } from '@frontend/theme';
import {
  Accordion,
  AnalyticsIcon,
  AnalyticsInfoIcon,
  BaseFieldProps,
  CaretDownIconSmall,
  IconButton,
  IconProps,
  InfoIcon,
  InfoRoundIconSmall,
  KeyNames,
  LocationIcon,
  Modal,
  NakedButton,
  NetworkIcon,
  NotificationIcon,
  PhoneIcon,
  PrimaryButton,
  SecondaryButton,
  SettingsIcon,
  SwitchField,
  Text,
  TextButton,
  TextField,
  WeaveLogoIcon,
  styles,
  useFormField,
  useModalControl,
  useOnClickOutside,
  usePortal,
  useTooltip,
  AlertManager,
  useAlert,
} from '@frontend/design-system';
import { useApp } from '../providers/app.provider';
import './devtools.css';

async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

const isCopy = ({
  key,
  ctrlKey,
  metaKey,
}: {
  key: KeyboardEvent['key'];
  ctrlKey: KeyboardEvent['ctrlKey'];
  metaKey: KeyboardEvent['metaKey'];
}) => {
  return (ctrlKey || metaKey) && key === 'c';
};

const DataTrackingIdModule = () => {
  const { Portal } = usePortal({ mountTarget: 'html' });
  const [trackingId, setTrackingId] = useState('');
  const overlays = useRef<{ element: HTMLElement; overlay: HTMLElement }[]>([]);
  const [copySuccess, setCopySuccess] = useState(false);

  const { refs, floatingStyles } = useFloating({
    open: !!trackingId,
    placement: 'bottom-start',
    middleware: [offset(20), shift(), flip()],
  });

  const closestTrackingId = (el: HTMLElement | null) => {
    while (el) {
      const id = el.getAttribute('data-trackingid');
      if (id) return el;
      el = el.parentElement;
    }
    return null;
  };

  const getElementsWithDataTrackingId = (elements: HTMLElement[]) => {
    const overlayClass = 'overlay-class';
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
    .${overlayClass} {
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background-color: #B0D9B1;
      opacity: 0.5;
      border-radius: 3px;
      pointer-events: none;
      border: 1px solid #005B41;
    }
    .position-relative {
      position: relative;
    }
  `;
    document.head.appendChild(styleElement);

    elements.map((element) => {
      const {
        tagName,
        dataset: { trackingid },
      } = element;

      const overlay = document.createElement('div');
      overlay.className = overlayClass;

      const positionStyle = window.getComputedStyle(element).position;
      if (positionStyle === 'static' || !positionStyle) {
        element.classList.add('position-relative');
      }

      element.appendChild(overlay);
      element.setAttribute('data-processed', 'true');
      overlays.current.push({ element, overlay });

      return {
        type: tagName,
        node: element,
        dataTrackingId: trackingid,
      };
    });
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const { clientX: x, clientY: y } = e;
      const el = document.elementFromPoint(x, y);
      const element = closestTrackingId(el as HTMLElement);
      const id = element?.getAttribute('data-trackingid');

      refs.setPositionReference({
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: e.clientX,
            y: e.clientY,
            top: e.clientY,
            right: e.clientX,
            bottom: e.clientY,
            left: e.clientX,
          };
        },
      });

      if (id) {
        setTrackingId(id);
      } else {
        setTrackingId('');
      }
    };

    const handleMutations: MutationCallback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          const elements = document.querySelectorAll('[data-trackingid]:not([data-processed])');
          getElementsWithDataTrackingId(Array.from(elements) as HTMLElement[]);
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { attributes: true, childList: true, subtree: true });

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      observer.disconnect();

      overlays.current.forEach(({ element, overlay }) => {
        element.removeChild(overlay);
        element.removeAttribute('data-processed');
      });
      overlays.current = [];
    };
  }, []);

  useLayoutEffect(() => {
    if (trackingId && refs.floating.current) {
      refs.floating.current.focus();
    }
  }, [trackingId]);

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 500);
    }
  }, [copySuccess]);

  return (
    <Portal>
      <div
        onKeyDown={(e) => {
          if (isCopy(e)) {
            if (!copySuccess && trackingId) {
              copyTextToClipboard(trackingId);
              setCopySuccess(true);
            }
          }
        }}
        tabIndex={1}
        ref={refs.setFloating}
        style={floatingStyles}
        css={css`
          opacity: ${trackingId ? 1 : 0};
          box-shadow: ${theme.shadows.floating};
          white-space: nowrap;
          background: ${theme.colors.white};
          border-radius: ${theme.borderRadius.medium};
          z-index: 1000000;
          overflow: auto;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <span
            css={css`
              background: ${theme.colors.neutral90};
              color: white;
              padding: ${theme.spacing(1)};
            `}
          >
            ID
          </span>
          <span
            css={css`
              padding: ${theme.spacing(1)};
              position: relative;
            `}
          >
            {copySuccess && (
              <div
                css={css`
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: 100%;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: ${theme.spacing(0, 1)};
                  background: ${transparentize(0.2, theme.colors.primary50)};
                `}
              >
                <Text
                  color='white'
                  weight='semibold'
                  css={[
                    css`
                      text-align: center;
                    `,
                    styles.truncate,
                  ]}
                >
                  Copied to clipboard
                </Text>
              </div>
            )}
            {trackingId}
          </span>
        </div>
      </div>
    </Portal>
  );
};

const CopyableText: React.FC<React.PropsWithChildren<{ children?: string; as: ElementType; className?: string }>> = ({
  children,
  className,
  as,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
    }
  }, [copySuccess]);

  const Element = as ?? 'span';

  return (
    <Element
      css={css`
        cursor: pointer;
        background-color: ${theme.colors.white};
        padding: ${theme.spacing(1)};
        border-radius: ${theme.borderRadius.small};
        font-size: ${theme.fontSize(14)};
        color: ${theme.colors.neutral80};
        border: 1px solid ${theme.colors.neutral20};
        transition: 0.2s ease;
        :hover {
          background-color: ${theme.colors.neutral10};
        }
      `}
      onClick={({ currentTarget }: { currentTarget: Element }) => {
        const text = currentTarget.textContent;
        if (!copySuccess && text) {
          navigator.clipboard.writeText(text);
          setCopySuccess(true);
        }
      }}
      className={className}
    >
      {copySuccess ? (
        <span
          css={css`
            color: ${theme.colors.secondary.seaweed50};
          `}
        >
          Copied to clipboard!
        </span>
      ) : (
        children
      )}
    </Element>
  );
};

const GeneralSettings = () => {
  const { setOptions } = useDevToolsStore();

  const visibleButtonSwitch = useFormField({ type: 'switch' });

  return (
    <DevtoolsSection title='Settings' Icon={SettingsIcon}>
      <div
        css={css`
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(1)};
        `}
      >
        <DebugOptionSwitch
          switchProps={{
            ...visibleButtonSwitch,
            onChange: (e) => {
              // @ts-ignore - ds event type needs work
              setOptions({ isDevToolsButtonVisible: e.value });
              visibleButtonSwitch.onChange(e);
            },
          }}
          name='debug-react-location'
          label={<span>Show Devtools button</span>}
        />
      </div>
    </DevtoolsSection>
  );
};

const RouteTools = () => {
  const client = useQueryClient();

  const { refresh } = useApp();

  const refreshAndClearCache = () => {
    client.clear();
    refresh();
  };
  return (
    <DevtoolsSection title='Route Tools' Icon={NetworkIcon}>
      <div
        css={css`
          padding: ${theme.spacing(1)};
        `}
        style={{ display: 'flex', gap: theme.spacing(1) }}
      >
        <SecondaryButton onClick={() => refresh()} css={{ width: 'auto' }}>
          Refresh Route
        </SecondaryButton>
        <SecondaryButton onClick={() => refreshAndClearCache()} css={{ width: 'auto' }}>
          Refresh Route and Clear Caches
        </SecondaryButton>
      </div>
    </DevtoolsSection>
  );
};

const LocationInfo = () => {
  const { selectedLocationIds, selectedOrgId, getLocationName } = useAppScopeStore();
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();

  return (
    <DevtoolsSection title='Scope Data' Icon={LocationIcon}>
      <dl
        css={css`
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(1)};
        `}
      >
        <Text as='span' size='medium' weight='bold'>
          Organization
        </Text>
        <div
          style={{
            background: theme.colors.secondary.seaweed5,
            borderRadius: theme.borderRadius.small,
            padding: theme.spacing(1),
          }}
        >
          <Text as='dt' size='medium' weight='bold'>
            ID
          </Text>
          <CopyableText as={'dd'}>{selectedOrgId}</CopyableText>
          <Text as='dt' size='medium' weight='bold'>
            Name
          </Text>
          <CopyableText as={'dd'}>{getLocationName(selectedOrgId)}</CopyableText>
        </div>
        <Text
          as='span'
          size='medium'
          weight='bold'
          css={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
        >
          Selected Locations{' '}
          <IconButton size={'xsmall'} label='info' {...triggerProps}>
            <InfoIcon size={16} />
            <Tooltip {...tooltipProps}>
              <Text size='small' color='white'>
                This list reflects what is selected in the org picker. It may not accurately reflect the active location
                in the portal.
              </Text>
            </Tooltip>
          </IconButton>
        </Text>
        {selectedLocationIds.map((id) => (
          <div
            key={id}
            style={{
              background: theme.colors.secondary.seaweed5,
              borderRadius: theme.borderRadius.small,
              padding: theme.spacing(1),
            }}
          >
            <Text as='dt' size='medium' weight='bold'>
              ID
            </Text>
            <CopyableText as={'dd'}>{id}</CopyableText>
            <Text as='dt' size='medium' weight='bold'>
              Name
            </Text>
            <CopyableText as={'dd'}>{getLocationName(id)}</CopyableText>
          </div>
        ))}
      </dl>
    </DevtoolsSection>
  );
};

const debugOptionSwitchStyle = css`
  align-items: center;
  code {
    font-size: 0.8rem;
  }
`;

interface IDebugOptionSwitch {
  switchProps: BaseFieldProps<boolean, ''>;
  name: string;
  label: ReactNode;
}

const DebugOptionSwitch: React.FC<React.PropsWithChildren<IDebugOptionSwitch>> = ({ switchProps, name, label }) => {
  return <SwitchField {...switchProps} css={debugOptionSwitchStyle} name={name} label={label} />;
};

const DebugOptionButton = ({
  children,
  style,
  ...props
}: {
  children: ReactNode;
  onClick: () => void;
  style?: CSSProperties;
}) => {
  return (
    <TextButton
      style={{
        borderRadius: 8,
        padding: 8,
        background: theme.colors.neutral5,
        border: `1px solid ${theme.colors.neutral20}`,
        margin: theme.spacing(1, 0),
        ...style,
      }}
      {...props}
    >
      {children}
    </TextButton>
  );
};

const DebugOptions = () => {
  const shell = useShell();
  const { setOptions } = useDevToolsStore();

  const debugQueryProps = useFormField({ type: 'switch' });
  const debugRLProps = useFormField({ type: 'switch' });
  const debugRQProps = useFormField({ type: 'switch' });
  const debugDataTracking = useFormField({ type: 'switch' });
  const debugNotifications = useFormField({ type: 'switch' });

  useEffect(() => {
    setOptions({ debugUseLocalizedQuery: debugQueryProps.value });
  }, [debugQueryProps.value]);

  useEffect(() => {
    setOptions({ showRLDevtools: debugRLProps.value });
  }, [debugRLProps.value]);

  useEffect(() => {
    setOptions({ showRQDevtools: debugRQProps.value });
  }, [debugRQProps.value]);

  useEffect(() => {
    setOptions({ showDebugDataTracking: debugDataTracking.value });
  }, [debugDataTracking.value]);

  useEffect(() => {
    if (debugNotifications.value) {
      shell.emit?.('debug:enable-debug-mode', undefined);
    } else {
      shell.emit?.('debug:disable-debug-mode', undefined);
    }
  }, [debugNotifications.value]);

  return (
    <DevtoolsSection title='Debug Options' Icon={AnalyticsInfoIcon}>
      <div
        css={css`
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(1)};
        `}
      >
        <DebugOptionSwitch
          switchProps={debugQueryProps}
          name='debug-query'
          label={
            <span>
              Debug <code>useLocalizedQuery</code>
            </span>
          }
        />
        <DebugOptionSwitch
          switchProps={debugRLProps}
          name='debug-react-location'
          label={<span>Show ReactLocation devtools</span>}
        />
        <DebugOptionSwitch
          switchProps={debugRQProps}
          name='debug-react-query'
          label={<span>Show ReactQuery devtools</span>}
        />
        <DebugOptionSwitch
          switchProps={debugDataTracking}
          name='debug-data-tracking'
          label={<span>Highlight Elements with data-trackingid</span>}
        />
        {shell.isShell && (
          <DebugOptionSwitch
            switchProps={debugNotifications}
            name='debug-data-tracking'
            label={<span>Enable Debug Mode in Notifications Window</span>}
          />
        )}
      </div>
    </DevtoolsSection>
  );
};

const IncomingCallOptionsSection = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <div
      css={css`
        :not(:last-child) {
          margin-bottom: ${theme.spacing(1.5)};
        }
      `}
    >
      <Text
        size='medium'
        weight='semibold'
        css={css`
          padding: ${theme.spacing(0.5, 1)};
          border-radius: ${theme.borderRadius.small} ${theme.borderRadius.medium} 0 0;
          display: inline-block;
          color: white;
          background-color: ${theme.colors.neutral90};
        `}
      >
        {title}
      </Text>
      <div
        css={css`
          padding: ${theme.spacing(1)};
          border-radius: 0 ${theme.borderRadius.medium} ${theme.borderRadius.medium} ${theme.borderRadius.medium};
          display: flex;
          gap: ${theme.spacing(1)};
          border: 1px solid ${theme.colors.neutral20};
          background-color: ${theme.colors.neutral5};
        `}
      >
        {children}
      </div>
    </div>
  );
};

const IncomingCallOptions = () => {
  const { setNotifications, setOutlet, addNotification } = useCallPopStateSync();
  const { add } = useNotificationQueue();
  const { notificationSettings } = useNotificationSettingsShallowStore('notificationSettings');

  // This is a specific example that works fully in devmobile only - it won't cause issues in other locations
  const addNotifications = () => {
    setNotifications([
      {
        id: 'not-1',
        timestamp: new Date().toLocaleString(),
        payload: {
          type: 'default',
          callerContext: 'testing',
          contacts: [
            {
              callerName: 'Jennifer Anderson',
              callerNumber: '2223339999',
              recipientLocationName: 'Testville',
              personId: 'd932f534-9067-5a85-ab49-4709c483b829',
              birthdate: new Date('1993-08-25').getSeconds(),
              gender: 'Female',
              source: 'Dentrix G7',
              patientId: '1_2xx1',
              householdId: '1_2xx1',
              matchedLocationId: '38fd93c3-78d0-4ef4-9466-99421eccf600',
            },
          ],
          recipientLocationName: 'Testville',
          headOfHousehold: {
            head_of_household_id: '1_2xx1',
            head_of_household_person_id: 'd932f534-9067-5a85-ab49-4709c483b829',
          },
        },
      },
      {
        id: 'not-2',
        timestamp: new Date().toLocaleString(),
        payload: {
          type: 'default',
          callerContext: 'testing',
          contacts: [
            {
              callerName: 'Braden Adams',
              callerNumber: '8884447777',
              recipientLocationName: 'Smallville',
              personId: 'fa64a452-2303-5174-ae33-9f7f801b4bb1',
              birthdate: new Date('1998-01-02').getSeconds(),
              gender: 'Male',
              source: 'Eaglesoft 21',
              patientId: '1_2xx2',
              householdId: '1_2xx2',
              matchedLocationId: '38fd93c3-78d0-4ef4-9466-99421eccf600',
            },
          ],
          recipientLocationName: 'Smallville',
          headOfHousehold: {
            head_of_household_id: '1_2xx2',
            head_of_household_person_id: 'fa64a452-2303-5174-ae33-9f7f801b4bb1',
          },
        },
      },
      {
        id: 'not-3',
        timestamp: new Date().toLocaleString(),
        payload: {
          type: 'default',
          callerContext: 'testing',
          contacts: [
            {
              callerName: 'Jennifer Anderson',
              callerNumber: '2223339999',
              recipientLocationName: 'Testville',
              personId: 'd932f534-9067-5a85-ab49-4709c483b829',
              birthdate: new Date('1993-08-25').getTime(),
              gender: 'Female',
              source: 'Dentrix G7',
              patientId: '1_2xx1',
              householdId: '1_2xx1',
              matchedLocationId: '38fd93c3-78d0-4ef4-9466-99421eccf600',
            },
            {
              callerName: 'Braden Adams',
              callerNumber: '8884447777',
              recipientLocationName: 'Smallville',
              personId: 'fa64a452-2303-5174-ae33-9f7f801b4bb1',
              birthdate: new Date('1998-01-02').getTime(),
              gender: 'Male',
              source: 'Eaglesoft 21',
              patientId: '1_2xx2',
              householdId: '1_2xx2',
              matchedLocationId: '38fd93c3-78d0-4ef4-9466-99421eccf600',
            },
          ],
          recipientLocationName: 'Smallville',
          headOfHousehold: {
            head_of_household_id: '',
            head_of_household_person_id: '',
          },
        },
      },
    ]);
  };

  // This is a specific example that works fully in devmobile only - it won't cause issues in other locations
  const addMultiContactNotification = () => {
    addNotification({
      id: Math.random().toString(),
      timestamp: new Date().toLocaleString(),
      payload: {
        type: 'default',
        callerContext: 'testing',
        contacts: [
          {
            callerName: 'Jennifer Anderson',
            callerNumber: '2223339999',
            recipientLocationName: 'Testville',
            personId: 'd932f534-9067-5a85-ab49-4709c483b829',
            birthdate: new Date('1993-08-25').getTime(),
            gender: 'Female',
            source: 'Dentrix G7',
            patientId: '1_2xx1',
            householdId: '1_2xx1',
            matchedLocationId: '38fd93c3-78d0-4ef4-9466-99421eccf600',
          },
          {
            callerName: 'Braden Adams',
            callerNumber: '8884447777',
            recipientLocationName: 'Smallville',
            personId: 'fa64a452-2303-5174-ae33-9f7f801b4bb1',
            birthdate: new Date('1998-01-02').getTime(),
            gender: 'Male',
            source: 'Eaglesoft 21',
            patientId: '1_2xx2',
            householdId: '1_2xx2',
            matchedLocationId: '38fd93c3-78d0-4ef4-9466-99421eccf600',
          },
        ],
        recipientLocationName: 'Smallville',
        headOfHousehold: {
          head_of_household_id: '',
          head_of_household_person_id: '',
        },
      },
    });
  };

  const publisher = useWebsocketEventPublisher();

  return (
    <DevtoolsSection title='Notification and Call Pop Test' Icon={NotificationIcon}>
      <div
        css={css`
          padding: 16px;
          display: flex;
          flex-direction: column;
        `}
      >
        <IncomingCallOptionsSection title='Notifications'>
          <PrimaryButton
            css={{ width: 'auto' }}
            onClick={() => {
              AlertManager.addAlert({
                type: 'info',
                message: 'HEEY!',
                action: {
                  label: 'Test',
                  onClick: () => {
                    alert('Test');
                  },
                },
              });
            }}
          >
            Alert
          </PrimaryButton>
          <PrimaryButton
            css={{ width: 'auto' }}
            onClick={() => {
              add({
                id: Math.random().toString(),
                type: 'sms-message-new',
                payload: {
                  body: 'Test body',
                  number: '1231231234',
                  personId: '1234567890',
                  threadId: '1234567890',
                  departmentId: '1234567890',
                  locationId: '1234567890',
                  firstName: 'Dan',
                  lastName: 'Allen',
                },
                state: {
                  status: 'unread',
                  paused: false,
                  timeout: notificationSettings.durationMs,
                },
                timestamp: new Date().toLocaleString(),
                flags: {
                  shouldAllowReply: false,
                },
              });
            }}
          >
            SMS
          </PrimaryButton>
          <PrimaryButton
            css={{ width: 'auto' }}
            onClick={() =>
              add({
                type: 'fax-new',
                id: Math.random.toString(),
                payload: {
                  faxId: '00000000-0000-0000-0000-000000000000',
                  action: 'inbound',
                  from: 'Just Dan Allen',
                  to: 'Daniel L. Allen',
                },
                state: { status: 'unread', paused: false, timeout: notificationSettings.durationMs },
                timestamp: new Date().toLocaleString(),
              })
            }
          >
            Fax
          </PrimaryButton>
        </IncomingCallOptionsSection>
        <IncomingCallOptionsSection title='Call Pop'>
          <PrimaryButton css={{ width: 'auto' }} onClick={addNotifications}>
            Example
          </PrimaryButton>
          <PrimaryButton css={{ width: 'auto' }} onClick={() => setNotifications([])}>
            Clear
          </PrimaryButton>
          <PrimaryButton
            css={{ width: 'auto' }}
            onClick={() =>
              publisher.emit('PhoneSystemEventsLegacy', {
                id: 'test1',
                method: 'PhoneSystemEventsLegacy',
                // @ts-ignore - this is just a demo
                params: {
                  event: 'inbound_call',
                  uuid: 'test',
                  caller_id_name: 'Test Person',
                  caller_id_number: '1234567890',
                  recipient_location_name: 'Testville',
                  PersonID: 'test-id',
                },
              })
            }
          >
            Add
          </PrimaryButton>
          <PrimaryButton
            css={{ width: 'auto' }}
            onClick={() => {
              addMultiContactNotification();
            }}
          >
            Multi
          </PrimaryButton>
        </IncomingCallOptionsSection>
        <IncomingCallOptionsSection title='Call Pop Outlet'>
          <PrimaryButton
            css={{ width: 'auto' }}
            onClick={() => {
              setOutlet('profile');
            }}
          >
            Profile
          </PrimaryButton>
          <PrimaryButton
            css={{ width: 'auto' }}
            onClick={() => {
              setOutlet('queue');
            }}
          >
            Queue
          </PrimaryButton>
          <PrimaryButton
            css={{ width: 'auto' }}
            onClick={() => {
              setOutlet('softphone');
            }}
          >
            Softphone
          </PrimaryButton>
        </IncomingCallOptionsSection>
      </div>
    </DevtoolsSection>
  );
};

const activeStyles = { color: theme.colors.white, background: theme.colors.primary50 };

const DevtoolsSection = ({
  children,
  Icon,
  title,
}: {
  children: ReactNode;
  title: string;
  Icon: React.FC<React.PropsWithChildren<IconProps>>;
}) => {
  return (
    <Accordion.Item value={title}>
      <Accordion.Header
        css={css`
          background: white;
          border-bottom: 1px solid ${theme.colors.neutral10};
          padding-left: 4px;
          svg {
            fill: ${theme.colors.neutral50};
          }
        `}
      >
        <Text
          weight='bold'
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <Icon
            size={16}
            css={css`
              fill: ${theme.colors.secondary.seaweed30} !important;
            `}
          />
          <span>{title}</span>
        </Text>
      </Accordion.Header>
      <Accordion.Body
        css={css`
          padding: 0;
        `}
      >
        {children}
      </Accordion.Body>
    </Accordion.Item>
  );
};

const InternationalizationOptions = () => {
  const { i18n } = useTranslation();

  return (
    <DevtoolsSection title='I18n' Icon={NetworkIcon}>
      <div
        css={css`
          padding: 12px;
        `}
        style={{ display: 'flex', gap: theme.spacing(1) }}
      >
        <DebugOptionButton
          onClick={() => {
            showTranslations(i18n);
          }}
        >
          Show translations
        </DebugOptionButton>
        <DebugOptionButton
          style={i18n.language === 'en' ? activeStyles : {}}
          onClick={() => {
            i18n.changeLanguage('en');
            dayjs.locale('en');
          }}
        >
          English
        </DebugOptionButton>
        <DebugOptionButton
          style={i18n.language === 'fr' ? activeStyles : {}}
          onClick={() => {
            i18n.changeLanguage('fr');
            dayjs.locale('fr');
          }}
        >
          French
        </DebugOptionButton>
      </div>
    </DevtoolsSection>
  );
};

/**
 *
 * @returns curl --location --request POST 'https://app.pendo.io/api/v1/guide/exampleGuideId123/visitor/exampleGuide/reset' \
--header 'Content-Type: application/json' \
--header 'x-pendo-integration-key: <PENDO_INTEGRATION_KEY>'
 */

const PendoSettings = () => {
  const field = useFormField({ type: 'text' });
  const searchField = useFormField({ type: 'text' });
  const [filteredGuides, setFilteredGuides] = useState<{ id: string; name: string }[]>([]);
  const { triggerProps, modalProps } = useModalControl();
  const alerts = useAlert();
  const { mutate } = useMutation({
    mutationFn: ({ guideId, visitorId }: { guideId: string; visitorId: string }) => {
      return http.post(`https://app.pendo.io/api/v1/guide/${guideId}/visitor/${visitorId}/reset`);
    },
    onSuccess: () => {
      alerts.success('Successfully reset guide');
    },
  });

  // @ts-ignore - pendo types are not up to date
  const metadata = pendo.getSerializedMetadata();

  return (
    <DevtoolsSection title='Pendo' Icon={AnalyticsIcon}>
      <div
        css={css`
          padding: 12px;
        `}
        style={{ display: 'grid', gap: theme.spacing(1), gridTemplateColumns: 'auto 1fr', alignItems: 'center' }}
      >
        {Object.entries(metadata.visitor).map(([key, value]) => (
          <>
            <Text as='dt' size='medium' weight='bold'>
              {key}
            </Text>
            <CopyableText css={styles.truncate} as={'dd'}>
              {value as string}
            </CopyableText>
          </>
        ))}
      </div>

      <div
        css={css`
          padding: 12px;
        `}
        style={{ display: 'flex', gap: theme.spacing(1), flexDirection: 'column' }}
      >
        <Text as='dt' size='medium' weight='bold'>
          Search guide by name
        </Text>
        <div style={{ display: 'flex', gap: theme.spacing(1) }}>
          <TextField label='Guide Name' name='guide-id' {...searchField} />
          <PrimaryButton
            size='large'
            css={{ width: 'max-content' }}
            onClick={() => {
              // @ts-ignore - this is not a public pendo API
              const filteredGuides = pendo.guides.filter((guide) =>
                guide.name.toLowerCase().includes(searchField.value.toLowerCase().trim())
              );
              setFilteredGuides(filteredGuides);
              triggerProps.onClick();
            }}
          >
            Search
          </PrimaryButton>
        </div>
      </div>
      <div
        css={css`
          padding: 12px;
        `}
        style={{ display: 'flex', gap: theme.spacing(1), flexDirection: 'column' }}
      >
        <Text as='dt' size='medium' weight='bold'>
          Reset Guide
        </Text>
        <div style={{ display: 'flex', gap: theme.spacing(1) }}>
          <TextField label='Guide ID' name='guide-id' {...field} />
          <PrimaryButton
            onClick={() => {
              mutate({ visitorId: metadata.visitor.id, guideId: field.value });
            }}
            size='large'
            css={{ width: 'max-content' }}
          >
            Reset
          </PrimaryButton>
        </div>
      </div>
      <Modal {...modalProps} id='pendo-guide-search'>
        <Modal.Header onClose={() => modalProps.onClose()}>Guides</Modal.Header>
        <Modal.Body css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), maxHeight: 500 }}>
          <Text as='span'>
            {searchField.value ? (
              <>
                Filtering by{' '}
                <Text as='span' color='primary' weight='bold'>
                  {searchField.value}
                </Text>
              </>
            ) : (
              <>Showing all guides</>
            )}
          </Text>
          <div
            style={{
              overflow: 'auto',
              padding: theme.spacing(0, 2, 0, 0),
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            }}
          >
            {filteredGuides.map((guide) => (
              <div
                key={guide.id}
                style={{
                  background: theme.colors.secondary.seaweed5,
                  borderRadius: theme.borderRadius.small,
                  padding: theme.spacing(1),
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  gap: theme.spacing(1),
                  alignItems: 'center',
                }}
              >
                <Text as='dt' size='medium' weight='bold'>
                  ID
                </Text>
                <CopyableText css={styles.truncate} as={'dd'}>
                  {guide.id}
                </CopyableText>
                <Text as='dt' size='medium' weight='bold'>
                  Name
                </Text>
                <CopyableText css={styles.truncate} as={'dd'}>
                  {guide.name}
                </CopyableText>
                <SecondaryButton
                  css={{
                    color: theme.colors.secondary.seaweed50,
                    background: theme.colors.white,
                    borderColor: theme.colors.secondary.seaweed50,
                    justifySelf: 'end',
                    ':hover': {
                      background: theme.colors.secondary.seaweed50,
                      color: theme.colors.white,
                    },
                    width: 'max-content',
                    gridColumn: '2',
                  }}
                  onClick={() => {
                    pendo.showGuideById(guide.id);
                  }}
                >
                  Show
                </SecondaryButton>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </DevtoolsSection>
  );
};

const PhoneSettings = () => {
  const { setOptions } = useDevToolsStore();

  const voicemailSwitch = useFormField({ type: 'switch' });
  const tenantTypeSwitch = useFormField({ type: 'switch' });

  return (
    <DevtoolsSection title='Phones' Icon={PhoneIcon}>
      <div
        css={css`
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(1)};
        `}
      >
        <DebugOptionSwitch
          switchProps={{
            ...voicemailSwitch,
            onChange: (e) => {
              // @ts-ignore - ds event type needs work
              setOptions({ showVoicemailDetails: e.value });
              voicemailSwitch.onChange(e);
            },
          }}
          name='show-voicemail-details'
          label={<span>Show Voicemail Details</span>}
        />
        <DebugOptionSwitch
          switchProps={{
            ...tenantTypeSwitch,
            onChange: (e) => {
              // @ts-ignore - ds event type needs work
              setOptions({ showTenantType: e.value });
              tenantTypeSwitch.onChange(e);
            },
          }}
          name='show-tenant-type'
          label={<span>Show Tenant Type</span>}
        />
      </div>
    </DevtoolsSection>
  );
};

const DevToolsContents = () => {
  const matches = useMatches();

  const showPhoneOptions = matches.some((e) => e.pathname === '/calls/all-calls');
  return (
    <div className='devtools-content'>
      <header
        css={css`
          background-color: ${theme.colors.neutral90};
          color: white;
          padding: 12px;
          display: flex;
          gap: 6px;
          border-radius: 12px 12px 0 0;
        `}
      >
        <WeaveLogoIcon
          size={32}
          css={css`
            fill: ${theme.colors.secondary.seaweed10};
          `}
        />
        <Text
          css={css`
            font-size: ${theme.fontSize(21)};
          `}
          color='white'
          size='large'
          weight='bold'
        >
          Devtools
        </Text>
      </header>
      <section
        css={css`
          padding: ${theme.spacing(2, 1.5, 1.5, 1.5)};
          flex: 1;
          border: 1px solid ${theme.colors.neutral20};
          border-bottom: none;
          overflow: auto;
          scrollbar-gutter: stable both-edges;
          ::-webkit-scrollbar {
            width: 6px;
            height: 4px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: ${theme.colors.secondary.seaweed30};
            border-radius: ${theme.borderRadius.small};
          }
        `}
      >
        <GeneralSettings />
        <LocationInfo />
        <DebugOptions />
        <InternationalizationOptions />
        <RouteTools />
        <IncomingCallOptions />
        {showPhoneOptions && <PhoneSettings />}
        {window.pendo && pendo.isReady?.() && <PendoSettings />}
      </section>
      <footer
        css={css`
          padding: ${theme.spacing(1, 2)};
          background-color: ${theme.colors.white};
          box-shadow: ${theme.shadows.floating};
          border-radius: 0 0 12px 12px;
          border-top: 1px solid ${theme.colors.neutral10};
          display: flex;
          align-items: center;
          gap: ${theme.spacing(1)};
        `}
      >
        <InfoRoundIconSmall color='light' />
        <Text color='light'>Version: {appConfig.VERSION}</Text>
      </footer>
    </div>
  );
};

export const DevTools = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { options } = useDevToolsStore();
  const insideRef = useRef<HTMLDivElement>(null);
  const closeDevTools = () => setIsOpen(false);

  const { Portal } = usePortal({ mountTarget: '#root' });

  useHotkeys('ctrl+shift+d', () => {
    console.log('Opening Dev Tools');
    setIsOpen(true);
  });

  useOnClickOutside({
    ref: insideRef,
    handler: closeDevTools,
    ignoreConditions: [() => document.getElementById('pendo-guide-search') !== null],
  });

  return (
    <Portal>
      {options.showRLDevtools && <ReactLocationDevtools initialIsOpen={false} />}
      {options.showRQDevtools && <ReactQueryDevtools initialIsOpen={false} />}
      {options.showDebugDataTracking && <DataTrackingIdModule />}

      <div
        className='devtools'
        style={{
          transform: isOpen ? 'translateY(0)' : 'translateY(630px)',
          background: theme.colors.white,
          zIndex: theme.zIndex.popover,
        }}
        id='devtools'
        ref={insideRef}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === KeyNames.Escape) {
            closeDevTools();
          }
        }}
      >
        <NakedButton
          css={css`
            position: absolute;
            right: -2px;
            background: ${theme.colors.neutral90};
            border-radius: 12px;
            padding: 14px;
            box-shadow: var(--shadow-floating);
            visibility: ${options.isDevToolsButtonVisible ? 'visible' : 'hidden'};
          `}
          className='devtools-icon-container'
          onClick={() => setIsOpen((previousState) => !previousState)}
          style={{ top: isOpen ? -60 : -70 }}
        >
          {isOpen ? (
            <CaretDownIconSmall color='white' size={21} />
          ) : (
            <SettingsIcon
              color='white'
              size={21}
              css={css`
                transform: rotate(0deg);
                transition: transform 500ms ease-in-out;
                :hover {
                  transform: rotate(180deg);
                  transition: transform 1s ease-in-out;
                }
              `}
            />
          )}
        </NakedButton>
        <Accordion
          startingValue={['Debug Options', 'Location Data']}
          isMulti
          showBoxShadow
          padding={1.2}
          variant='location'
        >
          <DevToolsContents />
        </Accordion>
      </div>
    </Portal>
  );
};
