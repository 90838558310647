import { GlobalSearchProvider } from './provider';
import { GlobalSearchMain } from './global-search-main';
import { GlobalSearchSection, GlobalSearchContent, GlobalSearchFilters } from './molecules';

type GlobalSearchProps = {
  children: React.ReactNode;
};

export const GlobalSearch = ({ children }: GlobalSearchProps) => {
  return (
    <GlobalSearchProvider>
      <GlobalSearchMain>{children}</GlobalSearchMain>
    </GlobalSearchProvider>
  );
};

GlobalSearch.Filters = GlobalSearchFilters;
GlobalSearch.Content = GlobalSearchContent;
GlobalSearch.Section = GlobalSearchSection;
