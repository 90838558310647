import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { CallInformation } from '../components/call-information';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { Button, Page, Divider } from '@frontend/generic-dialpad-accessories';
import { useSoftphoneRouter } from '../providers/softphone-router-provider';
import { isEstablishedCall, isOutgoingCall, isTerminatedCall } from '../types';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { SettingsRow } from '../components/settings-row';
import { HeldCall } from '../components/hold/held-call';
import { MultipleHeldCalls } from '../components/hold/multiple-held-calls';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';

export const PageCallHomeMobile = () => {
  const { t } = useTranslation('softphone');
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const establishedCalls = useSoftphoneCallState((ctx) => ctx.establishedCalls);
  const setCurrentRoute = useSoftphoneRouter((ctx) => ctx.setSingleCallRoute);
  const disabled = !primaryCall || !isEstablishedCall(primaryCall) || isTerminatedCall(primaryCall);
  const mergeCalls = useSoftphoneCallActions((ctx) => ctx.mergeCalls);
  const canMerge = establishedCalls.length > 1;
  const otherHeldCalls = establishedCalls.filter((call) => !!call.onHoldSince && primaryCall?.id !== call.id);

  const primaryCallStartTime =
    primaryCall && isOutgoingCall(primaryCall) ? primaryCall.placedAt : primaryCall?.establishedAt;

  const toggleMute = useSoftphoneCallActions((ctx) => ctx.toggleMute);
  const isMuted = useSoftphoneCallState((ctx) => ctx.isMuted);
  const connected = primaryCall && isEstablishedCall(primaryCall);
  const toggleHold = useSoftphoneCallActions((ctx) => ctx.toggleHold);
  const endCall = useSoftphoneCallActions((ctx) => ctx.endCall);
  if (!primaryCall) return null;

  return (
    <Page>
      <PageHeaderWraps hasBackButton={false}></PageHeaderWraps>
      <Page.Body
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin: ${theme.spacing(3, 0)};
          gap: ${theme.spacing(7)};
          flex: 1;
        `}
      >
        <CallInformation
          textAlign='center'
          connectedAt={primaryCallStartTime}
          displayName={primaryCall?.remoteParty.displayName}
          uri={primaryCall?.remoteParty.uri ?? ''}
          hideCallDuration={!connected}
        />
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(auto-fill, minmax(90px, auto));
            gap: ${theme.spacing(2)};
            justify-content: center;
          `}
        >
          <div css={{ display: 'flex', gap: theme.spacing(3) }}>
            <Button.Round
              disabled={disabled}
              onClick={() => setCurrentRoute('park')}
              iconName={'phone-parked-calls'}
              label={t('Park')}
            ></Button.Round>
            <Button.Round
              trackingId='softphone-active-transfer'
              disabled={disabled}
              onClick={() => setCurrentRoute('transfer')}
              iconName={'phone-outgoing'}
              label={t('Transfer')}
            ></Button.Round>
            {canMerge && (
              <Button.Round
                disabled={disabled}
                onClick={() => {
                  mergeCalls(establishedCalls);
                }}
                iconName={'merge'}
                label={t('Merge')}
              ></Button.Round>
            )}
            <Button.Round
              trackingId='softphone-active-keypad'
              disabled={disabled}
              onClick={() => setCurrentRoute('keypad')}
              iconName={'dial'}
              label={t('Keypad')}
            ></Button.Round>
          </div>
          <div css={{ display: 'flex', gap: theme.spacing(3), margin: 'auto' }}>
            <Button.Round
              trackingId='softphone-active-mute'
              onClick={(e) => {
                e.stopPropagation();
                toggleMute(!isMuted);
              }}
              selected={isMuted}
              disabled={!connected}
              iconName={isMuted ? 'mute' : 'microphone'}
              label={t('Mute')}
            ></Button.Round>

            <Button.Round
              trackingId='softphone-active-hold'
              disabled={!connected}
              selected={connected && !!primaryCall.onHoldSince}
              onClick={(e) => {
                e.stopPropagation();
                if (connected) {
                  toggleHold(primaryCall, !primaryCall.onHoldSince);
                }
              }}
              label={t('Hold')}
              iconName={'pause'}
            ></Button.Round>

            <Button.Round
              trackingId='softphone-active-addcall'
              disabled={disabled}
              onClick={() => {
                setCurrentRoute('add-call');
              }}
              iconName={'plus'}
              label={t('Add Call')}
            ></Button.Round>
          </div>
          <Button.Round
            css={{ margin: '8px auto' }}
            trackingId='softphone-active-endcall'
            disabled={!(isEstablishedCall(primaryCall) || isOutgoingCall(primaryCall))}
            onClick={(e) => {
              e.stopPropagation();
              endCall(primaryCall);
            }}
            color='red'
            iconName={'phone-hangup'}
          ></Button.Round>
        </div>
      </Page.Body>
      <Page.Footer>
        {!!otherHeldCalls.length && (
          <>
            {otherHeldCalls.length === 1 && (
              <>
                <Divider />
                <Text
                  color='white'
                  css={css`
                    margin-bottom: ${theme.spacing(1)};
                  `}
                >
                  {t('On Local Hold')}
                </Text>
                <HeldCall call={otherHeldCalls[0]} />
              </>
            )}
            {otherHeldCalls.length > 1 && (
              <MultipleHeldCalls
                numOfHeldCalls={otherHeldCalls.length}
                onClick={() => setCurrentRoute('allCallsOnHold')}
              />
            )}
          </>
        )}
        <Divider />
        <SettingsRow />
      </Page.Footer>
    </Page>
  );
};
