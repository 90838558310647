import { StreamUser } from '../types';

interface GenerateStreamUserParams {
  orgId: string;
  userId: string;
}

export const getTeamId = (orgId: string) => `ORG_ID_${orgId}`;

export const getStreamUserId = ({ orgId, userId }: GenerateStreamUserParams) => `ORG_ID_${orgId}_${userId}`;

export const getFullName = (user: StreamUser) => `${user.firstName} ${user.lastName}`;
