import { InstructionFields } from './base';
import { DepartmentPayload, InstructionFieldsProps } from '../types';
import { useQuery } from 'react-query';

import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { DepartmentsApi } from '@frontend/api-departments';
import { useAppScopeStore } from '@frontend/scope';

export const DepartmentInstructionField = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<DepartmentPayload>['onChange'];
  option: DepartmentPayload;
}) => {
  const { singleLocationId } = useAppScopeStore();

  const { data = [], isLoading } = useQuery({
    queryKey: [queryKeys.listDepartments()],
    queryFn: () => {
      return DepartmentsApi.listDept({ locationId: singleLocationId });
    },
    select: (data) => {
      return (
        data.departments
          ?.sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
          .map((department) => ({
            value: department.id ?? '',
            label: department.name ?? '',
          })) ?? []
      );
    },
  });

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<DepartmentPayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          departmentId: data ?? [],
        },
        value: {
          departmentId: option.departmentId,
        },
      }}
    />
  );
};

const schema = {
  type: 'department',
  fields: {
    departmentId: {
      label: 'Department',
      name: 'departmentId',
      type: 'dropdown',
      helperText: 'This is some helper text for A',
    },
  },
} as const;
