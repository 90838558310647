import { Reader } from '@stripe/terminal-js';
import { isUUID } from 'validator';
import { StoredReader, TerminalReader } from './terminal-strategy';

export const createStoredReader = (locationId: string, paymentsUrl: string, reader: TerminalReader): StoredReader => {
  return {
    locationId,
    paymentsUrl,
    stripeLocationId: reader.location,
    readerId: reader.id,
    readerIp: reader.ipAddress,
    deviceType: reader.deviceType,
    label: reader.label,
  };
};

export const isValidStoredReader = (data: unknown): data is StoredReader => {
  if (typeof data !== 'object' || data === null) return false;

  const exhaustiveKeyCheck: StoredReader = {
    locationId: '',
    paymentsUrl: '',
    stripeLocationId: 'tml_',
    readerId: 'tmr_',
    readerIp: '',
    label: '',
    deviceType: 'bbpos_wisepos_e',
  };

  return Object.keys(exhaustiveKeyCheck).reduce((acc, key) => {
    if (key === 'stripeLocationId') {
      return acc && key in data && typeof data[key] === 'string' && (data[key] as string).startsWith('tml_');
    }
    if (key === 'readerId') {
      return acc && key in data && typeof data[key] === 'string' && (data[key] as string).startsWith('tmr_');
    }
    if (key === 'locationId') {
      return acc && key in data && typeof data[key] === 'string' && isUUID(data[key] as string);
    }
    if (key === 'paymentsUrl') {
      return (
        acc &&
        key in data &&
        typeof data[key] === 'string' &&
        ((data[key] as string).includes('https://api.weavedev.net/payments') ||
          (data[key] as string).includes('https://api.weaveconnect.com/payments'))
      );
    }

    //@ts-ignore - TS complains the data object could be empty and not be
    //  indexable, this check overs this use case and won't cause errors since
    //  we would return false in that case it is an empty object.
    return acc && key in data && typeof data[key] === 'string';
  }, true);
};

export const formatReaderToTerminalReader = (reader: Reader): TerminalReader => {
  return {
    id: reader.id as `tmr_${string}`,
    object: 'reader',
    deviceType: reader.device_type,
    ipAddress: reader.ip_address,
    label: reader.label,
    liveMode: reader.livemode,
    location:
      typeof reader.location === 'string'
        ? (reader.location as `tml_${string}`)
        : (reader.location?.id as `tml_${string}`) ?? null,
    serialNumber: reader.serial_number,
    status: reader.status,
  };
};

export const formatTerminalReaderToReader = (reader: TerminalReader): Reader => {
  return {
    id: reader.id,
    device_type: reader.deviceType,
    ip_address: reader.ipAddress,
    label: reader.label,
    livemode: reader.liveMode ?? false,
    location: reader.location,
    serial_number: reader.serialNumber,
    status: reader.status,
    object: 'terminal.reader',
    device_sw_version: null,
    metadata: {},
  };
};

const MINUTES = 6e4; //ms
const SECONDS = 1e3; //ms

/**
 * Attempts to execute an action with retries
 * @param action - The action to execute, return true to escape the retry loop. A falsy value will continue the operation.
 * @example
 * ```typescript
 * // simple
 * doActionWithRetry(async () => { .... })
 *
 * // return result
 * const resilientAction = () => new Promise (async (resolve, reject) => {
 *  try {
 *    await doActionWithRetry(async () => {
 *      try {
 *        const result = await someAsyncAction();
 *        resolve(result);
 *        return true; // returning true escapes the retry loop
 *      } catch (error) {
 *        return false; // returning undefined or false will continue the retry loop
 *      }
 *    });
 *  } catch(error) {
 *    if(error instanceof RetryLimitError) {
 *      reject(new Error('Add custom error message here'));
 *    } else {
 *      reject(error);
 *    }
 *  }
 *
 * ```
 */
export const doActionWithRetry = async (
  action: () => Promise<boolean | undefined | void>,
  options?: {
    timeBetweenRetries?: number;
    maxRetryDuration?: number;
    exponentialBackoff?: boolean;
    maxAttempts?: number;
    maxTimeBetweenRetries?: number;
  }
): Promise<void> => {
  const {
    timeBetweenRetries = 1 * SECONDS,
    maxRetryDuration = 5 * MINUTES,
    exponentialBackoff = true,
    maxAttempts = 3,
    maxTimeBetweenRetries = 15 * MINUTES,
  } = options ?? {};

  const startTime = Date.now();
  let numAttempts = 0;

  const execute = async (): Promise<void> => {
    if (numAttempts >= maxAttempts) {
      throw new RetryLimitError('max_attempts');
    }

    const elapsed = Date.now() - startTime;
    if (elapsed >= maxRetryDuration) {
      throw new RetryLimitError('max_duration');
    }

    const shouldEscape = await action();
    if (shouldEscape) {
      return Promise.resolve();
    }

    const backoff = exponentialBackoff ? timeBetweenRetries * 2 ** numAttempts : timeBetweenRetries;
    const delayMs = maxTimeBetweenRetries ? Math.min(maxTimeBetweenRetries, backoff) : backoff;
    await new Promise((resolve) => setTimeout(resolve, delayMs));

    numAttempts++;
    return execute();
  };

  return execute();
};

type RetryErrorType = 'max_attempts' | 'max_duration';
export class RetryLimitError extends Error {
  type: RetryErrorType;

  constructor(type: RetryErrorType) {
    let message = '';

    switch (type) {
      case 'max_attempts':
        message = 'Max attempts reached';
        break;
      case 'max_duration':
        message = 'Max retry duration exceeded';
        break;
      default: {
        const _exhaustiveCheck: never = type;
        throw new Error(`Unhandled retry error type: ${_exhaustiveCheck}`);
      }
    }
    super(message);
    this.name = 'RetryLimitError';
    this.type = type;
  }

  public toString() {
    return `${this.name}: ${this.message}`;
  }
}

type ReaderStorageMap = Record<string, StoredReader>;

const localStorageKeys = {
  lastConnectedTerminal: 'wvc.last_connected_terminal',
};

const getStoredReadersMap = () => {
  try {
    return JSON.parse(localStorage.getItem(localStorageKeys.lastConnectedTerminal) ?? '{}') as ReaderStorageMap;
  } catch (err) {
    return {};
  }
};

const cleanReaders = (locationId: string, paymentUrl: string, reader: TerminalReader | StoredReader) => {
  const formattedReader = isValidStoredReader(reader) ? reader : createStoredReader(locationId, paymentUrl, reader);

  const storedReaders = getStoredReadersMap();
  const cleanedReaders: ReaderStorageMap = Object.entries(storedReaders).reduce((acc, [locationId, storedReader]) => {
    if (locationId.startsWith('tml_') || !isValidStoredReader(storedReader)) {
      return acc;
    }
    return {
      ...acc,
      [locationId]: storedReader,
    };
  }, {} as ReaderStorageMap);

  return { formattedReader, cleanedReaders };
};

const cleanAndModifyStorageData = (
  locationId: string,
  paymentUrl: string,
  reader: TerminalReader | StoredReader,
  modifyData: (data: ReaderStorageMap, formattedReader: StoredReader) => ReaderStorageMap
) => {
  // force the job to be non-blocking
  return new Promise((resolve, reject) =>
    setTimeout(() => {
      try {
        const { formattedReader, cleanedReaders } = cleanReaders(locationId, paymentUrl, reader);
        const data = modifyData({ ...cleanedReaders }, formattedReader);
        localStorage.setItem(localStorageKeys.lastConnectedTerminal, JSON.stringify(data));
        resolve(data);
      } catch (error) {
        reject(error);
      }
    }, 0)
  );
};

const addReaderToList = (data: ReaderStorageMap, formattedReader: StoredReader) => ({
  ...data,
  [formattedReader.locationId]: formattedReader,
});

const removeReaderFromList = (data: ReaderStorageMap, formattedReader: StoredReader) => {
  const storedReader = data[formattedReader.locationId];
  if (storedReader && storedReader.readerId === formattedReader.readerId) {
    delete data[formattedReader.locationId];
  }
  return data;
};

export const storeReader = async (locationId: string, paymentUrl: string, reader: TerminalReader | StoredReader) => {
  try {
    await cleanAndModifyStorageData(locationId, paymentUrl, reader, addReaderToList);
  } catch (error) {
    console.error('Failed to save reader: ', error);
  }
};

export const removeReader = async (locationId: string, paymentUrl: string, reader: TerminalReader | StoredReader) => {
  try {
    await cleanAndModifyStorageData(locationId, paymentUrl, reader, removeReaderFromList);
  } catch (error) {
    console.error('Failed to remove reader: ', error);
  }
};

export const getStoredReader = (locationId: string) => {
  if (locationId.startsWith('tml_')) {
    throw new Error('Invalid locationId');
  }
  const storedReaders = getStoredReadersMap();
  const data = storedReaders?.[locationId];
  if (!isValidStoredReader(data)) {
    // Remove the invalid reader from the stored readers
    const { [locationId]: _, ...newReaders } = storedReaders;
    localStorage.setItem(localStorageKeys.lastConnectedTerminal, JSON.stringify(newReaders));
  }
  return isValidStoredReader(data) ? data : undefined;
};
