import { Success2Graphic } from '@frontend/assets';
import { Heading, Modal, ModalControlModalProps, SecondaryButton, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { FC } from 'react';
import { SelfUpgradeModalTrackingIds } from '../../../constants/tracking-ids';

interface SelfUpgradeSuccessModalProps extends ModalControlModalProps {
  featureName: string;
  onboardingCallUrl: string;
}

export const SelfUpgradeSuccessModal: FC<SelfUpgradeSuccessModalProps> = ({
  featureName,
  onboardingCallUrl,
  ...modalProps
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'self-upgrade-success-modal' });

  const handleScheduleOnboardingCall = () => {
    window.open(onboardingCallUrl, '_blank', 'noopener noreferrer');
  };

  return (
    <Modal {...modalProps} maxWidth={600}>
      <Modal.Header
        onClose={modalProps.onClose}
        closeBtnTrackingId={SelfUpgradeModalTrackingIds.successModalCloseAction}
      />
      <Modal.Body
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(2),
          padding: theme.spacing(3, 0),
        }}
      >
        <Text textAlign='center'>
          {t(
            'You are successfully subscribed to {{featureName}}. If you need additional assistance, please schedule an optional onboarding call by clicking Schedule Call below.',
            { featureName }
          )}
        </Text>
        <Success2Graphic />
        <Heading textAlign='center' level={2}>
          {t('You are subscribed!')}
        </Heading>
        <article
          css={{
            border: `1px solid ${theme.colors.neutral20}`,
            borderRadius: theme.borderRadius.medium,
            padding: theme.spacing(2),
            marginTop: theme.spacing(3),
            display: 'flex',
            gap: theme.spacing(2),
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>
            <Heading css={{ fontSize: theme.fontSize(16) }}>{t('Optional Onboarding Call - 45 min')}</Heading>
            <Text textAlign='left' size='small' color='light'>
              {t('with your onboarding specialist')}
            </Text>
          </div>
          <div>
            <SecondaryButton
              css={{ width: 150 }}
              onClick={handleScheduleOnboardingCall}
              trackingId={SelfUpgradeModalTrackingIds.onboardingCallAction}
            >
              <Icon name='calendar' size={16} css={{ marginRight: theme.spacing(1) }} />
              {t('Schedule Call')}
            </SecondaryButton>
          </div>
        </article>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Okay')}
        primaryTrackingId={SelfUpgradeModalTrackingIds.successModalOkayAction}
        onPrimaryClick={modalProps.onClose}
      />
    </Modal>
  );
};
