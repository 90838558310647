import { Appointment } from '@weave/schema-gen-ts/dist/schemas/appointments/v3/model.pb';
import { AppointmentsApiQueries } from '@frontend/api-appointments';
import { PersonsV3 } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, Modal, ModalControlModalProps, SpinningLoader } from '@frontend/design-system';
import { AppointmentsList } from '../appointments-list';

type AppointmentSelectorModalProps = {
  groupId: string;
  personId: string;
  includePast?: boolean;
  onSelect: (appointment: Appointment) => void;
  onSkip: () => void;
  onClose: () => void;
};
type FullModalProps = ModalControlModalProps & AppointmentSelectorModalProps;

export const AppointmentSelectorModal = ({
  groupId,
  personId,
  includePast,
  onSelect,
  onSkip,
  ...rest
}: FullModalProps) => {
  return (
    <Modal
      css={{
        minWidth: 600,
      }}
      {...rest}
    >
      <AppointmentSelectorModalContents
        groupId={groupId}
        personId={personId}
        includePast={includePast}
        onSelect={onSelect}
        onSkip={onSkip}
        onClose={rest.onClose}
      />
    </Modal>
  );
};

export const AppointmentSelectorModalContents = ({
  groupId,
  personId,
  includePast,
  onSelect,
  onSkip,
  onClose,
}: AppointmentSelectorModalProps) => {
  const { t } = useTranslation('appointment-selector');
  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery({
    personId,
    locationIds: [groupId],
  });
  const personName = personQuery.data?.preferredName || personQuery.data?.firstName;
  const appointmentsQuery = AppointmentsApiQueries.useListPersonAppointments({
    locationId: groupId,
    personId,
    includePast,
  });

  return (
    <>
      <Modal.Header onClose={onClose}>
        {personName
          ? t("{{personName}}'s Upcoming Appointments", {
              personName,
            })
          : t('Upcoming Appointments')}
      </Modal.Header>
      <Text color='light' size='small' css={{ margin: theme.spacing(1, 3) }}>
        {t('Select an appointment')}
      </Text>
      <Modal.Body css={{ width: '100%', maxHeight: 500, padding: theme.spacing(1, 0) }}>
        {appointmentsQuery.isLoading ? (
          <SpinningLoader />
        ) : (
          <AppointmentsList appointments={appointmentsQuery.data?.appointments ?? []} onSelect={onSelect} />
        )}
      </Modal.Body>
      <Modal.Actions secondaryLabel={t('Skip')} onSecondaryClick={onSkip} showPrimary={false} />
    </>
  );
};
