import { FC } from 'react';
import { FormsSubmissions, FormsWritebacks } from '@frontend/api';
import { Chip } from '@frontend/design-system';
import { BADGE_LABELS } from '../../../../shared/constants';
import { isDefaultUTCDate } from '../../../../shared/utils';
import FormStatusHoverWrapper, { FormStatusHoverWrapperProps } from './hover-wrapper/hover-wrapper.component';

interface FormStatusBadgeProps extends FormStatusHoverWrapperProps {
  writebackStatusCode: FormsWritebacks.Types.WritebackStatusCode;
  submittionDate?: string;
  hasPMSIntegration?: boolean;
  isArchived?: boolean;
  reason?: FormsWritebacks.Types.WritebackNAReason;
  reviewStatus?: FormsSubmissions.Types.ReviewStatus;
  needsReview?: boolean;
  isExpired?: boolean;
}

const FormStatusBadge: FC<React.PropsWithChildren<FormStatusBadgeProps>> = ({
  writebackStatusCode,
  reviewStatus,
  submittionDate,
  isArchived,
  reason,
  needsReview,
  isExpired,
  hoverContent,
}) => {
  if (isArchived) {
    return (
      <FormStatusHoverWrapper hoverContent={hoverContent}>
        <Chip variant='neutral'>{BADGE_LABELS.ARCHIVED}</Chip>
      </FormStatusHoverWrapper>
    );
  }

  if (isExpired) {
    return (
      <FormStatusHoverWrapper hoverContent={hoverContent}>
        <Chip variant='warn'>{BADGE_LABELS.EXPIRED}</Chip>
      </FormStatusHoverWrapper>
    );
  }

  if (reviewStatus === 'Approved') {
    return (
      <FormStatusHoverWrapper hoverContent={hoverContent}>
        <Chip variant='success'>{BADGE_LABELS.REVIEW_APPROVED}</Chip>
      </FormStatusHoverWrapper>
    );
  }

  if (reviewStatus === 'Rejected') {
    return (
      <FormStatusHoverWrapper hoverContent={hoverContent}>
        <Chip variant='critical'>{BADGE_LABELS.REVIEW_REJECTED}</Chip>
      </FormStatusHoverWrapper>
    );
  }

  if (needsReview) {
    return (
      <FormStatusHoverWrapper hoverContent={hoverContent}>
        <Chip variant='eggplant'>{BADGE_LABELS.NEEDS_REVIEW}</Chip>
      </FormStatusHoverWrapper>
    );
  }

  if (!submittionDate || isDefaultUTCDate(submittionDate)) {
    return (
      <FormStatusHoverWrapper hoverContent={hoverContent}>
        <Chip variant='neutral'>{BADGE_LABELS.NOT_SUBMITTED}</Chip>
      </FormStatusHoverWrapper>
    );
  }

  if (reason === 'WRITEBACK_NA_CSV' || reason === 'WRITEBACK_NA_CC' || reason === 'WRITEBACK_INCAPABLE_SRC') {
    return (
      <FormStatusHoverWrapper hoverContent={hoverContent}>
        {/* TODO */}
        <Chip variant='warn'>{BADGE_LABELS.NOT_SYNCABLE_CONTACT}</Chip>
      </FormStatusHoverWrapper>
    );
  }

  switch (writebackStatusCode) {
    case 'Pending':
      return (
        <FormStatusHoverWrapper hoverContent={hoverContent}>
          <Chip variant='eggplant'>{BADGE_LABELS.NOT_SYNCED}</Chip>
        </FormStatusHoverWrapper>
      );

    case 'Failed':
      return (
        <FormStatusHoverWrapper hoverContent={hoverContent}>
          <Chip variant='critical'>{BADGE_LABELS.FAILED_SYNC}</Chip>
        </FormStatusHoverWrapper>
      );

    case 'SUCCESS':
    case 'Fulfilled':
      return (
        <FormStatusHoverWrapper hoverContent={hoverContent}>
          <Chip variant='success'>{BADGE_LABELS.SYNCED}</Chip>
        </FormStatusHoverWrapper>
      );

    case 'Manual':
      return (
        <FormStatusHoverWrapper hoverContent={hoverContent}>
          {/* TODO */}
          <Chip variant='warn'>{BADGE_LABELS.NEEDS_APPROVAL}</Chip>
        </FormStatusHoverWrapper>
      );

    case 'Submitted':
      return (
        <FormStatusHoverWrapper hoverContent={hoverContent}>
          {/* TODO */}
          <Chip variant='success'>{BADGE_LABELS.SUBMITTED}</Chip>
        </FormStatusHoverWrapper>
      );

    default:
      return null;
  }
};

export default FormStatusBadge;
