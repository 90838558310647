import { FC } from 'react';
import { css } from '@emotion/react';
import { Channel } from 'stream-chat';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useAlert, TextLink, ConfirmationModal, useModalControl } from '@frontend/design-system';
import { MIN_MODAL_WIDTH } from '../../../constants';
import { useTeamChatStore } from '../../../providers';

interface LeaveChannelProps {
  className?: string;
  channel: Channel;
}

export const LeaveChannel: FC<LeaveChannelProps> = ({ channel, className }) => {
  const { t } = useTranslation('team-chat');
  const { error } = useAlert();
  const { openModal, modalProps } = useModalControl();
  const { resetActiveConversation, removeConversation, currentUser } = useTeamChatStore([
    'resetActiveConversation',
    'removeConversation',
    'currentUser',
  ]);

  const leaveChannel = async () => {
    try {
      const text = `${currentUser?.firstName} ${currentUser?.lastName} left the conversation`;
      if (currentUser?.userID) await channel.removeMembers([currentUser.userID], { text });

      resetActiveConversation();
      if (channel.id) removeConversation(channel.id);
    } catch (err) {
      error(t('Failed to leave conversation'));
    }
  };

  return (
    <>
      <TextLink css={css({ padding: theme.spacing(2) })} onClick={openModal} className={className}>
        <Text color={'error'} weight='bold'>
          {t('Leave Channel')}
        </Text>
      </TextLink>
      <ConfirmationModal
        onConfirm={leaveChannel}
        title={t('Leave Conversation')}
        maxWidth={MIN_MODAL_WIDTH}
        destructive
        {...modalProps}
      >
        <Text>{t('Are you sure you want to leave this conversation?')}</Text>
      </ConfirmationModal>
    </>
  );
};
