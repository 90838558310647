import { useEffect } from 'react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { TagsV2, TagsUtils } from '@frontend/api-tag';
import { checkAggregateACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  Chip,
  PopoverMenu,
  PopoverMenuItem,
  SearchField,
  TextButton,
  useFormField,
  Text,
  useAlert,
} from '@frontend/design-system';
import { useInboxFiltersShallowStore } from '../../..';
import { FilterItemPopoverCheckboxField } from '../filter-item-popover-checkbox-field';
import { GetItemPropsFn, GetMenuPropsFn } from '../types';
import { TagsFilterPopoverTextButton } from './tags-filter-popover-text-button';

type TagsFilterPopoverMenuProps = {
  getMenuProps: GetMenuPropsFn;
  getItemProps: GetItemPropsFn;
  groupIds?: string[];
  toggleItems: (tagIds: string[]) => void;
  selectedTags: string[];
  closeParentMenu: () => void;
};

export const TagsFilterPopoverMenu = ({
  getMenuProps,
  getItemProps,
  groupIds,
  toggleItems,
  selectedTags,
  closeParentMenu,
}: TagsFilterPopoverMenuProps): ReturnType<typeof PopoverMenu> => {
  const { t } = useTranslation('inbox');
  const alert = useAlert();
  const { selectedLocationIds, selectedOrgId } = useAppScopeStore();
  const { inboxFilters, setInboxFilters } = useInboxFiltersShallowStore('setInboxFilters');
  const resolvedGroupIds = groupIds ? selectedLocationIds.filter((id) => groupIds.includes(id)) : selectedLocationIds;
  const { aggregateValue: hasTagACL } = checkAggregateACL(resolvedGroupIds, Permission.TAG_MANAGE, 'any');
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const searchField = useFormField({
    type: 'text',
  });
  const tagsList = TagsV2.Queries.useListTagsQuery({
    request: { orgId: selectedOrgId, groupIds: resolvedGroupIds },
    options: {
      select: (data) => {
        const enabledTags = data.tags.filter((tag) => (tag.isDefaultTag || tag.smartTagId ? tag.enabled : true));
        return {
          ...data,
          tags: enabledTags,
        };
      },
    },
  });
  const filteredTags = searchField.value
    ? tagsList.data?.tags.filter((tag) => tag.name.toLowerCase().includes(searchField.value.toLowerCase()))
    : tagsList.data?.tags;

  const handleSelectAll = () => {
    toggleItems(filteredTags?.filter((tag) => !selectedTags.includes(tag.id)).map((tag) => tag.id) ?? []);
  };

  const handleClear = () => {
    toggleItems(filteredTags?.filter((tag) => selectedTags.includes(tag.id)).map((tag) => tag.id) ?? []);
  };
  const hasAllSelected = filteredTags?.every((tag) => selectedTags.includes(tag.id));
  const hasNoneSelected = filteredTags?.every((tag) => !selectedTags.includes(tag.id));

  useEffect(() => {
    const removedSelectedTags = selectedTags.filter((tagId) => !tagsList.data?.tags.some((tag) => tag.id === tagId));
    if (removedSelectedTags.length) {
      toggleItems(removedSelectedTags);
      setInboxFilters({
        ...inboxFilters,
        tags: selectedTags.filter((tagId) => !removedSelectedTags.includes(tagId)),
      });
      alert.info(
        t('{{count}} tags have been removed from your filters, because they are no longer applicable.', {
          count: removedSelectedTags.length,
        })
      );
    }
  }, [JSON.stringify(tagsList.data?.tags)]);

  return (
    <PopoverMenu {...getMenuProps()}>
      <PopoverMenuItem
        as='span'
        {...getItemProps({
          index: 0,
          disableCloseOnSelect: true,
          onFocusCapture: (e) => {
            e.preventDefault();
            e.stopPropagation();
            searchField.onFocus();
          },
          onBlurCapture: (e) => {
            e.preventDefault();
            e.stopPropagation();
            searchField.onBlur();
          },
        })}
        css={{
          maxWidth: 220,
          ':hover': {
            backgroundColor: 'inherit',
          },
          ':focus': {
            backgroundColor: 'inherit',
          },
        }}
      >
        <SearchField
          {...searchField}
          name='tag-search'
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      </PopoverMenuItem>
      <PopoverMenuItem
        as='span'
        {...getItemProps({
          index: 1,
          disableCloseOnSelect: true,
        })}
        css={{
          marginTop: theme.spacing(1),
          padding: theme.spacing(1, 0.75),
          borderBottom: `1px solid ${theme.colors.neutral20}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ':hover': {
            backgroundColor: 'inherit',
          },
          ':focus': {
            backgroundColor: 'inherit',
          },
        }}
      >
        <TextButton
          css={{
            color: theme.font.colors.primary,
            fontWeight: theme.font.weight.semibold,
          }}
          onClick={handleSelectAll}
          disabled={hasAllSelected}
          trackingId={`${InboxPrefixes.List}-filters-popover-tags-select-all`}
        >
          {t('Select All')}
        </TextButton>
        <TextButton
          css={{
            color: theme.font.colors.primary,
            fontWeight: theme.font.weight.semibold,
          }}
          onClick={handleClear}
          disabled={hasNoneSelected}
          trackingId={`${InboxPrefixes.List}-filters-popover-tags-clear`}
        >
          {t('Clear')}
        </TextButton>
      </PopoverMenuItem>
      <div
        css={{
          maxHeight: 240,
          overflowY: 'auto',
        }}
      >
        {filteredTags?.length ? (
          filteredTags.map((tag, index) => (
            <PopoverMenuItem
              key={tag.id}
              {...getItemProps({
                index: index + 2,
                disableCloseOnSelect: true,
                onClickCapture: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleItems([tag.id]);
                },
              })}
              css={{
                width: '100%',
                cursor: 'pointer',
                '*': {
                  cursor: 'pointer',
                },
              }}
              trackingId={`${InboxPrefixes.List}-filters-popover-tags-select-tag`}
            >
              <FilterItemPopoverCheckboxField
                isChecked={selectedTags.includes(tag.id)}
                label={<Chip.Tag color={TagsUtils.convertStringToTagColor(tag.color)}>{tag.name}</Chip.Tag>}
                name={tag.name}
              />
            </PopoverMenuItem>
          ))
        ) : (
          <Text
            size='medium'
            textAlign='center'
            color='light'
            css={{
              padding: theme.spacing(1),
            }}
          >
            {t('No tags to display.')}
          </Text>
        )}
      </div>
      {hasTagACL && (
        <TagsFilterPopoverTextButton
          {...getItemProps({
            index: (filteredTags?.length ?? 0) + 2,
            onClick: () => {
              settingsNavigate({ to: '/tags/quick-create', context: { name: searchField.value } });
              closeParentMenu();
            },
          })}
          iconName='plus-small'
          label={searchField.value ? t("Create '{{tagName}}' Tag", { tagName: searchField.value }) : t('Create Tag')}
          trackingId={`${InboxPrefixes.List}-filters-popover-tags-create${searchField.value ? '-with-name' : ''}`}
        />
      )}
      <TagsFilterPopoverTextButton
        {...getItemProps({
          index: (filteredTags?.length ?? 0) + 3,
          onClick: () => {
            settingsNavigate({ to: '/tags' });
            closeParentMenu();
          },
        })}
        iconName='settings-small'
        label={t('Tag Settings')}
        trackingId={`${InboxPrefixes.List}-filters-popover-tags-settings`}
      />
    </PopoverMenu>
  );
};
