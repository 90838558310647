import { useQuery } from 'react-query';
import { VoicemailConfigApi } from '@frontend/api-voicemail-config';
import { useAppScopeStore } from '@frontend/scope';
import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { InstructionFieldsProps, VoicemailPromptPayload } from '../types';
import { InstructionFields } from './base';

export const VoicemailPromptInstructionFields = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<VoicemailPromptPayload>['onChange'];
  option: VoicemailPromptPayload;
}) => {
  const { singleLocationId, accessibleLocationData } = useAppScopeStore();
  const location = accessibleLocationData[singleLocationId];
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? location.phoneTenantId ?? '';
  const { data = [], isLoading: isLoadingBoxes } = useQuery({
    queryKey: queryKeys.listTenantVoicemailBoxes(tenantId),
    queryFn: () => VoicemailConfigApi.listVoicemailBoxes({ tenantId: tenantId }),
    select: (data) => {
      return data.voicemailBoxes
        .sort((a, b) => a.mailbox.name.localeCompare(b.mailbox.name))
        .map((voicemailBox) => ({
          value: voicemailBox.mailbox.id,
          label: voicemailBox.mailbox.name,
        }));
    },
  });

  if (isLoadingBoxes) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<VoicemailPromptPayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          voicemailBoxId: data,
        },
        value: {
          voicemailBoxId: option.voicemailBoxId,
          voicemailGreetingId: option.voicemailGreetingId || option.systemMediaId || '',
        },
        meta: {
          voicemailGreetingId: {
            voicemailBoxId: option.voicemailBoxId,
            tenantId,
            requestedTypes: { custom: true, standard: true, mailbox: true },
          },
        },
      }}
    />
  );
};

const schema = {
  type: 'voicemailPrompt',
  fields: {
    voicemailBoxId: {
      label: 'Voicemail Box',
      name: 'voicemailBoxId',
      type: 'dropdown',
      helperText: 'This is some helper text for A',
      meta: undefined,
    },
    voicemailGreetingId: {
      label: 'Greeting',
      name: 'voicemailGreetingId',
      type: 'dropdown',
      kind: 'media',
      helperText: 'This is some helper text for B that is so very long and will take a few rows to display',
      meta: {
        voicemailBoxId: '',
        tenantId: '',
        requestedTypes: { custom: true, standard: true, mailbox: true },
      },
    },
  },
} as const;
