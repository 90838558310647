import { FC, useState, useEffect, useCallback, useRef } from 'react';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { RelatedType } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { useTranslation } from '@frontend/i18n';
import { SendInThreadModal } from '@frontend/integrated-messaging';
import { pendoTags } from '../../../../../constants';
import { useAppointmentInfo } from '../../../../../hooks';
import { useSendFormsContext, useSendFormsStore } from '../../../../../providers';
import DeliveryOption from '../delivery-option/delivery-option.component';

const SendAsMessage: FC = () => {
  const [showSendInThreadModal, setShowSendInThreadModal] = useState(false);
  const { locationId } = useSendFormsContext();
  const {
    personId,
    formOrPacketToSend,
    showMessageComponent,
    solicitedLink,
    appointmentInfo,
    temporarilyHideModal,
    selectedDeliveryOption,
  } = useSendFormsStore([
    'personId',
    'formOrPacketToSend',
    'showMessageComponent',
    'clearStore',
    'appointmentInfo',
    'solicitedLink',
    'temporarilyHideModal',
    'selectedDeliveryOption',
  ]);
  const modalRestoreRef = useRef(false);

  const { t } = useTranslation('forms');
  const { appointmentAt } = useAppointmentInfo();

  const openSendInThreadModalHandler = () => {
    if (!formOrPacketToSend || !formOrPacketToSend.id) {
      return;
    }

    setShowSendInThreadModal(true);
  };

  const closeMessageThreadModalHandler = useCallback(() => {
    setShowSendInThreadModal(false);
  }, []);

  useEffect(() => {
    if (selectedDeliveryOption !== 'message' || modalRestoreRef.current) {
      return;
    }

    if (!temporarilyHideModal && !showSendInThreadModal) {
      openSendInThreadModalHandler();
    }
    modalRestoreRef.current = true;
  }, [temporarilyHideModal, selectedDeliveryOption, showSendInThreadModal]);

  return (
    <>
      <DeliveryOption
        icon='messaging-small'
        displayName={t('Share in Message')}
        messageMode='message'
        onClick={openSendInThreadModalHandler}
        show={showMessageComponent}
        trackingId={pendoTags.steps.deliveryMethod.direct.via.sms}
      />

      <SendInThreadModal
        show={showSendInThreadModal}
        onClose={closeMessageThreadModalHandler}
        onBack={closeMessageThreadModalHandler}
        groupId={locationId}
        personId={personId}
        templateType={TemplateType_Slug.MANUAL_FORMS_MESSAGE}
        linkData={{
          link: solicitedLink,
          relatedId: '',
          relatedType: RelatedType.RELATED_TYPE_FORM,
        }}
        contextPreviewProps={{
          iconName: 'forms',
          title: formOrPacketToSend?.name ?? '',
          subtitle:
            !!appointmentInfo && `${appointmentAt}, ${appointmentInfo?.practitioner}, ${appointmentInfo?.pmsType}`,
        }}
      />
    </>
  );
};

export default SendAsMessage;
