import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text, NotificationBadge } from '@frontend/design-system';
import { useOnlineSchedulingMultiLocationCustomizationFlag } from '../../../../../context/OnlineSchedulingMultiLocationCustomizationFlagContext';
import { useScheduleActionsContext } from '../ScheduleActionsContext';
import { ScheduleRequestsTrackingIds } from './trackingIds';

export const ScheduleRequestTabHeader = () => {
  const { t } = useTranslation('scheduleCalendarActions');
  const { locationIdWiseCustomizationFlagDetails } = useOnlineSchedulingMultiLocationCustomizationFlag();
  const { isAppointmentRequestCountLoading, scheduleRequestsTotalCount, scheduleRequestsCountByLocation } =
    useScheduleActionsContext();

  const totalScheduleRequestCount = scheduleRequestsCountByLocation?.reduce((acc, { total, locationId }) => {
    if (locationId && locationIdWiseCustomizationFlagDetails?.[locationId]) {
      return acc + (total || 0);
    }
    return acc;
  }, 0);

  const shouldRenderScheduleRequestCount =
    !!scheduleRequestsTotalCount && !!totalScheduleRequestCount && !isAppointmentRequestCountLoading;

  return (
    <div
      data-trackingid={ScheduleRequestsTrackingIds.requestTabBtn}
      css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}
    >
      <Text as='span' size='medium'>
        {t('Requests')}
      </Text>
      {shouldRenderScheduleRequestCount && <NotificationBadge>{totalScheduleRequestCount}</NotificationBadge>}
      {isAppointmentRequestCountLoading && <SpinningLoader size='xs' />}
    </div>
  );
};
