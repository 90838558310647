import { kebabCase } from 'lodash-es';
import { VoicemailApi } from '@frontend/api-voicemails';
import { getWeaveToken } from '@frontend/auth-helpers';
import { getInitialParams } from '@frontend/env';
import { VoicemailWithCallRecord } from '../all-calls/types';

export const downloadVoicemail = async (url: string, callerName: string, date?: string) => {
  const blob = await VoicemailApi.downloadVoicemail(url);
  const objectURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = objectURL;
  link.download = `${kebabCase(`${callerName} ${date}`)}-recording.wav`;

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(objectURL);
};

export const generateMediaPath = (message: VoicemailWithCallRecord | undefined) => {
  const weaveToken = getWeaveToken();

  const currentEnv = getInitialParams().backendApi;
  const mediaId = message?.forwardedMessageId || message?.mediaId;
  if (!mediaId) {
    return '';
  }
  const mediaPath = `${currentEnv}/desktop/v1/voicemails/${mediaId}/download?token=${weaveToken}&location_id=${message?.locationId}`;
  return mediaPath;
};
