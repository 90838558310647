import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ChangeEventHandler, MouseEventHandler, useReducer } from 'react';
import { dialerInitialState, dialerReducer } from './dialer-reducer';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { XIcon } from '@frontend/design-system';

type DialerInputProps = {
  value: string;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleReset: MouseEventHandler<SVGSVGElement>;
};

export const DialerInput = ({ value, handleInputChange, handleKeyDown, handleReset }: DialerInputProps) => {
  return (
    <div css={styles}>
      <label htmlFor='dialInput'></label>
      <div className='input-wrap'>
        <input
          type='text'
          id='dialInput'
          placeholder='Dial'
          value={formatPhoneNumber(value)}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        {!!value && <XIcon className='x-icon' size={18} onClick={handleReset} />}
      </div>
    </div>
  );
};

const useDialer = () => {
  const [state, dispatch] = useReducer(dialerReducer, dialerInitialState);

  const addDialChar = (char: string) => {
    dispatch({ type: 'add', payload: char });
  };

  const setDialNumber = (value: string) => {
    dispatch({ type: 'set', payload: value });
  };

  const deleteLastChar = () => {
    dispatch({ type: 'delete' });
  };

  const resetInput = () => {
    dispatch({ type: 'reset' });
  };

  return {
    dialNumber: state.dialNumber,
    addDialChar,
    setDialNumber,
    deleteLastChar,
    resetInput,
  };
};

export default useDialer;

const styles = css`
  margin: auto;
  display: block;
  text-align: left;
  width: 286px;
  input {
    text-align: center;
    width: 100%;
    height: ${theme.spacing(6)};
    padding: ${theme.spacing(1.5, 4, 1.5, 3)};
    box-sizing: border-box;
    border-radius: ${theme.borderRadius.small};
    background: #ffffff;
    outline: none;
    font-size: ${theme.fontSize(28)};
    color: ${theme.colors.text.subdued};
    margin-top: 2px;
    border: 1px solid ${theme.colors.neutral30};
    &:focus {
      border: 1px solid ${theme.colors.primary[30]};
    }
    &[aria-invalid='true'] {
      border: 1px solid ${theme.colors.critical30}; //the theme critical color doesn't show up well on dark
    }
    &.large {
      font-size: 1.8em;
    }
    &.medium {
      font-size: 1.5em;
    }
    &.small {
      font-size: 1.2em;
    }
  }
  .input-wrap {
    position: relative;
    .x-icon {
      position: absolute;
      right: ${theme.spacing(1.5)};
      top: ${theme.spacing(2)};
      padding-top: 2px;
      cursor: pointer;
      color: ${theme.colors.neutral50};
      &:hover {
        color: ${theme.colors.black};
      }
    }
  }
`;
