import { useCallback } from 'react';
import { SEND_FORM_STEPS } from '../constants';
import { useSendFormsContext, useSendFormsStore } from '../providers';
import { SendFormStep, SendFormsOrigin } from '../types';

interface UseStepsResults {
  goToNextStep: () => void;
  goBackAStep: () => void;
  resetSteps: () => void;
  isLastStep: () => boolean;
  goToStep: (step: SendFormStep) => void;
}

export const useSteps = (): UseStepsResults => {
  const { origin } = useSendFormsContext();
  const { setCurrentStep, steps, currentStep } = useSendFormsStore(['setCurrentStep', 'steps', 'currentStep']);

  const goToNextStep = useCallback(() => {
    const currentIndex = steps.indexOf(currentStep);

    if (currentIndex === steps.length - 1) {
      return;
    }

    setCurrentStep(steps[currentIndex + 1]);
  }, [steps, currentStep]);

  const goBackAStep = useCallback(() => {
    const currentIndex = steps.indexOf(currentStep);

    if (currentIndex === 0) {
      return;
    }

    setCurrentStep(steps[currentIndex - 1]);
  }, [steps, currentStep]);

  const goToStep = useCallback((step: SendFormStep) => {
    setCurrentStep(step);
  }, []);

  const resetSteps = useCallback(() => {
    const firstStep =
      origin === SendFormsOrigin.FORMS ? SEND_FORM_STEPS.formsOrigin[0] : SEND_FORM_STEPS.contactsOrigin[0];
    setCurrentStep(firstStep);
  }, [origin]);

  const isLastStep = useCallback(() => {
    return currentStep === steps[steps.length - 1];
  }, [currentStep, steps]);

  return {
    goToNextStep,
    goBackAStep,
    goToStep,
    resetSteps,
    isLastStep,
  };
};
