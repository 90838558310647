import { FormEventHandler } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { PrimaryButton } from '@frontend/design-system';

interface Props {
  disabled?: boolean;
  onClick: FormEventHandler;
}

export const SubmitButton = ({ disabled, onClick, ...rest }: Props) => {
  // Technically, it should be an IconButton, but with that it needs a lot of style override.
  return (
    <PrimaryButton
      {...rest}
      css={styles.button}
      disabled={disabled}
      onClick={onClick}
      size='small'
      trackingId='team-chat-v2-send-messge'
    >
      <Icon color='white' name='send' />
    </PrimaryButton>
  );
};

const styles = {
  button: css`
    padding: 0;
    width: ${theme.spacing(4)};

    svg {
      margin-left: 0;
      width: ${theme.spacing(2.5)};
    }
  `,
};
