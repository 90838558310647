import { css } from '@emotion/react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { hasSchemaACL, isWeaveUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { CheckIcon, Heading, PrimaryButton, Text } from '@frontend/design-system';
import { URLs } from '../../constants';
import { useShouldShowSubscribeCard } from '../../hooks';
import { trackingIds } from '../../tracking-ids';

type CheckedListItemProps = { value: string };

const CheckedListItem = ({ value }: CheckedListItemProps) => (
  <div css={styles.checkItem}>
    <CheckIcon />
    <Text css={{ marginLeft: theme.spacing(1) }}>{value}</Text>
  </div>
);

const CardContent = () => {
  const { t } = useTranslation('analytics');

  return (
    <>
      <CheckedListItem value={t('Full transcript + summary of each call')} />
      <CheckedListItem value={t('Missed revenue opportunity identification')} />
      <CheckedListItem value={t('Call categories + appointment types')} />
      <CheckedListItem value={t('Customer + staff sentiment analysis')} />
    </>
  );
};

export const CallIntelSubscribeCard = () => {
  const { t } = useTranslation('analytics');
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const canManageAddOns = hasSchemaACL(selectedLocationIdsWithParents[0], Permission.MANAGE_ADD_ON_SUBSCRIPTIONS);

  const { flags: customizationFlags } = useCustomizationFlagShallowStore('flags');

  const shouldShowSubscriptionCard = useShouldShowSubscribeCard({
    flagDetails: customizationFlags?.callintelligence,
    verify: 'showSubscriptionCard',
  });

  return shouldShowSubscriptionCard ? (
    <>
      {/* This card is temporary and will be replaced with SignUpPageSubscriptionCard once we are good to offer self subscribe feature */}
      <section css={styles.talkToSalesCard}>
        <Heading level={2}>
          {t(
            'With Call Intelligence, unlock higher revenue each month and improve overall performance of your office location(s)'
          )}
        </Heading>
        <div css={{ marginTop: theme.spacing(2) }}>
          <CardContent />
        </div>
        <PrimaryButton
          disabled={!canManageAddOns || isWeaveUser()}
          onClick={() => window.open(URLs.CALL_INTEL_TALK_TO_SALES, '_blank')}
          style={{
            marginTop: theme.spacing(3),
            textTransform: 'none',
          }}
          trackingId={trackingIds.subscriptions.scheduleCallForCallIntel}
        >
          {t('Schedule a Call')}
        </PrimaryButton>
      </section>
    </>
  ) : null;
};

const styles = {
  checkItem: css`
    align-items: center;
    display: flex;
    margin-top: ${theme.spacing(1)};
  `,

  talkToSalesCard: css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    margin-top: ${theme.spacing(3)};
    max-width: 364px;
    padding: ${theme.spacing(2)};
  `,
};
