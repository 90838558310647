import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Heading,
  PrimaryButton,
  RadioField,
  useDebouncedValue,
  useFormField,
  PopoverMenu,
  usePopoverMenu,
  PopoverMenuItem,
  NakedButton,
  Text,
  ListFieldProps,
} from '@frontend/design-system';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../../../../../stores/use-schedule-actions-appointment-list-store';
import {
  ProviderCheckList,
  Clear,
  ProviderSearchField,
  SelectAll,
} from '../../../../components/HeaderBar/Filter/ProviderCheckList';
import { ScheduleMassMessageFilterTrackingIds } from '../trackingIds';
import { AppointmentFilterType } from './../types';

type FilterModalProps = {
  closeFilterModal: () => void;
  appointmentStatuses: string[];
  filterAppointmentList: ({ hasStatusFilter, selectedStatus, selectedProviders }: AppointmentFilterType) => void;
  providerCheckListField: ListFieldProps;
  appointmentStatusRadioField: any;
};

export const FilterModal = ({
  closeFilterModal,
  appointmentStatuses,
  filterAppointmentList,
  providerCheckListField,
  appointmentStatusRadioField,
}: FilterModalProps) => {
  const { t } = useTranslation('scheduleCalendarActions');

  const { defaultProviderList, setProviderList, setHasActiveFilters, setStatusFilter } =
    useScheduleActionsAppointmentListInfoShallowStore(
      'setProviderList',
      'defaultProviderList',
      'setHasActiveFilters',
      'setStatusFilter'
    );

  const { selectedLocationIds } = useAppScopeStore();
  const hasOnlyOneLocation = selectedLocationIds.length === 1;

  const {
    getTriggerProps,
    getMenuProps,
    getItemProps,
    close: closeProviderCheckList,
  } = usePopoverMenu({
    placement: 'right-start',
    middlewareOptions: {
      offset: {
        mainAxis: 0,
        crossAxis: -8,
      },
    },
  });

  const closeFilterMenu = () => {
    closeFilterModal();
    closeProviderCheckList();
  };

  const applyFilters = () => {
    const hasStatusFilter = appointmentStatusRadioField?.value?.toLowerCase() !== 'all';
    const hasProviderFilter = providerCheckListField.value?.length !== defaultProviderList.length;
    const hasFilters = hasStatusFilter || hasProviderFilter;
    const selectedProviders = defaultProviderList.filter((provider) =>
      providerCheckListField.value?.includes(provider.id ?? '')
    );

    setHasActiveFilters(hasFilters);
    filterAppointmentList({
      hasStatusFilter,
      selectedStatus: appointmentStatusRadioField?.value ?? 'all',
      selectedProviders,
    });
    setProviderList(providerCheckListField.value ?? []);
    setStatusFilter && setStatusFilter(appointmentStatusRadioField?.value ?? '');
    closeFilterMenu();
  };

  const searchField = useFormField({ type: 'text' });
  const debouncedSearchTerm = useDebouncedValue(searchField.value);

  const searchProviders = useMemo(() => {
    return defaultProviderList.filter(({ firstName, lastName, publicDisplayName, resourceName }) => {
      // Account for the possibility of the provider name being set to publicDisplayName, or firstName + lastName, or resourceName, in the use-get-providers-details-for-calendar.ts hook.
      const name = publicDisplayName || `${firstName} ${lastName}`.trim() || resourceName || '';
      const nameMatch = name.toLowerCase().replaceAll(/[.,*()]/g, '');
      const search = debouncedSearchTerm.toLowerCase().replaceAll(/[.,*()]/g, '');
      return nameMatch?.includes(search);
    });
  }, [debouncedSearchTerm, defaultProviderList, debouncedSearchTerm]);

  const handleSelectAll = () => {
    providerCheckListField.onChange({
      value: [...searchProviders.map(({ id }) => id), ...(providerCheckListField.value ?? [])],
      name: 'providers',
    });
  };

  const handleClear = () => {
    providerCheckListField.onChange({ value: [], name: 'providers' });
  };

  return (
    <>
      {appointmentStatuses.length > 1 && (
        <>
          <Heading level={3} css={{ marginBottom: theme.spacing(2) }}>
            {t('Confirmation Status')}
          </Heading>
          <RadioField
            {...appointmentStatusRadioField}
            name='appointment-status-radio-field'
            label=''
            css={{
              borderBottom: `1px solid ${theme.colors.neutral20}`,
              marginBottom: theme.spacing(1),
              paddingBottom: theme.spacing(1),
            }}
          >
            {appointmentStatuses.map((status) => {
              return (
                <RadioField.Radio key={status} value={status} css={{ textTransform: 'capitalize' }}>
                  {status}
                </RadioField.Radio>
              );
            })}
          </RadioField>
        </>
      )}
      <NakedButton
        {...getTriggerProps()}
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'left',
          margin: theme.spacing(2, 0, 3, 0),
          width: '100%',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'left',
            gap: theme.spacing(1),
          }}
        >
          <Icon name='user' />
          <Text css={{ fontSize: theme.fontSize(16) }}>{t('Provider')}</Text>
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            height: theme.spacing(4),
          }}
        >
          {!!providerCheckListField.value?.length && (
            <Text
              css={{
                color: theme.colors.primary70,
                padding: theme.spacing(0.25, 0.5),
                borderRadius: theme.borderRadius.small,
                backgroundColor: theme.colors.primary10,
              }}
              size='small'
            >
              {providerCheckListField.value?.length}
            </Text>
          )}

          <Icon
            name='alt-caret-right-tiny'
            css={{
              color: theme.colors.neutral50,
            }}
          />
        </div>
      </NakedButton>
      <PopoverMenu {...getMenuProps()} css={{ maxWidth: 220, padding: theme.spacing(2) }}>
        <PopoverMenuItem
          as='span'
          {...getItemProps({
            index: 0,
            disableCloseOnSelect: true,
            onFocusCapture: (e) => {
              e.preventDefault();
              e.stopPropagation();
              searchField.onFocus();
            },
            onBlurCapture: (e) => {
              e.preventDefault();
              e.stopPropagation();
              searchField.onBlur();
            },
          })}
          css={{ padding: 0 }}
        >
          <ProviderSearchField searchFieldProps={searchField} />
        </PopoverMenuItem>
        <PopoverMenuItem
          as='span'
          {...getItemProps({
            index: 1,
            disableCloseOnSelect: true,
          })}
          css={{
            marginTop: theme.spacing(1),
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            ':hover': {
              backgroundColor: 'inherit',
            },
            ':focus': {
              backgroundColor: 'inherit',
            },
          }}
        >
          <SelectAll
            isDisabled={false}
            handleSelectAll={handleSelectAll}
            trackingId={ScheduleMassMessageFilterTrackingIds.selectAllProvidersFilterButton}
          />
          <Clear
            handleClear={handleClear}
            trackingId={ScheduleMassMessageFilterTrackingIds.clearProviderFilterButton}
          />
        </PopoverMenuItem>
        <div css={{ padding: theme.spacing(0), paddingRight: 0 }}>
          <ProviderCheckList
            searchProviders={searchProviders}
            checkListProps={providerCheckListField}
            locations={selectedLocationIds}
            hasOnlyOneLocation={hasOnlyOneLocation}
          />
        </div>
      </PopoverMenu>

      <PrimaryButton onClick={applyFilters}>{t('Apply')}</PrimaryButton>
    </>
  );
};
