import { RequiredProperties } from './../../../type-utils/type-helpers';
import { KeyNames } from '../../../constants/key-names';
import { CellPositionCord, CellPositionsConfig } from '../table-types';
import { Row } from 'react-table';
import { isEqual } from 'lodash-es';
import { getAllFocusableElements, isInteractiveElement, handleEnter, handleEscape } from './table-focus-utils';
import { addFocusableState, removeFocusableState } from './table-focus-utils';
import { onlyText } from 'react-children-utilities';

const getFirstCellPosition = (tableId: string): CellPositionCord => ({
  tableId,
  x: 0,
  y: -1,
});

type FocusCellType = {
  cellPosition?: CellPositionCord;
  currentCellPositionRef?: React.MutableRefObject<CellPositionCord>;
  tableId?: string;
  cellPositionsConfig?: CellPositionsConfig;
  rows?: Row[];
};

const focusNextTabableElement = (cellElem: HTMLElement, isClickEvent?: boolean) => {
  const focusOnInteractiveElement = isClickEvent ? true : !cellElem.querySelector('[data-focusable]');
  const focusableElements = getAllFocusableElements(cellElem);
  if (focusableElements.length > 0 && focusOnInteractiveElement) {
    focusableElements[0].focus();
    return;
  }
  cellElem.focus();
};

type FocusCell = (
  cellPosition: CellPositionCord,
  currentCellPositionRef: React.MutableRefObject<CellPositionCord>,
  isClickEvent?: boolean
) => void;
export const focusCell: FocusCell = (cellPosition, currentCellPositionRef, isClickEvent) => {
  if (isClickEvent) {
    const { tableId, x, y } = currentCellPositionRef.current;
    const prevFocusedElement = document.querySelector(
      `[table-cell-position="${tableId}-${x}-${y}"]`
    ) as HTMLElement | null;
    const focusableElements = getAllFocusableElements(prevFocusedElement);

    removeFocusableState(prevFocusedElement, focusableElements);
  }

  const { tableId, x, y } = cellPosition;
  const cellElem = document.querySelector(`[table-cell-position="${tableId}-${x}-${y}"]`) as HTMLElement | null;

  if (cellElem) {
    const focusableElements = getAllFocusableElements(cellElem);
    addFocusableState(cellElem, focusableElements);
    focusNextTabableElement(cellElem, isClickEvent);
    currentCellPositionRef.current = cellPosition;
  }
};

type FocusNextCellType = RequiredProperties<
  FocusCellType,
  'cellPositionsConfig' | 'tableId' | 'currentCellPositionRef'
>;
const focusNextCell = ({ cellPositionsConfig, tableId, currentCellPositionRef }: FocusNextCellType) => {
  const prevFocusedCellPosition = currentCellPositionRef.current;

  const { x, y } = prevFocusedCellPosition;
  const { maximumX, maximumY } = cellPositionsConfig;

  let newX = x + 1;
  let newY = y;

  if (newX > maximumX) {
    newX = 0;
    newY = y + 1;
  }

  if (newY > maximumY) return;

  const cellElem = document.querySelector(`[table-cell-position="${tableId}-${x}-${y}"]`) as HTMLElement | null;
  const focusableElements = getAllFocusableElements(cellElem);
  removeFocusableState(cellElem, focusableElements);

  const newCellPosition = { tableId, x: newX, y: newY };
  focusCell(newCellPosition, currentCellPositionRef);
};

type FocusPrevCell = RequiredProperties<FocusCellType, 'cellPositionsConfig' | 'tableId' | 'currentCellPositionRef'>;
const focusPrevCell = ({ cellPositionsConfig, tableId, currentCellPositionRef }: FocusPrevCell) => {
  const prevFocusedCellPosition = currentCellPositionRef.current;

  if (isEqual(currentCellPositionRef.current, getFirstCellPosition(tableId))) return;

  const { x, y } = prevFocusedCellPosition;
  const cellElem = document.querySelector(`[table-cell-position="${tableId}-${x}-${y}"]`) as HTMLElement | null;
  const focusableElements = getAllFocusableElements(cellElem);
  removeFocusableState(cellElem, focusableElements);
  const { maximumX } = cellPositionsConfig;

  let newX = x - 1;
  let newY = y;

  if (newX < 0) {
    newX = maximumX;
    newY = y - 1;
  }

  if (newY < -1) return;

  const newCellPosition = { tableId, x: newX, y: newY };
  focusCell(newCellPosition, currentCellPositionRef);
};

type FocusUpCellType = RequiredProperties<FocusCellType, 'tableId' | 'currentCellPositionRef'>;
const focusUpCell = ({ currentCellPositionRef, tableId }: FocusUpCellType) => {
  const prevFocusedCellPosition = currentCellPositionRef.current;

  if (isEqual(currentCellPositionRef.current, getFirstCellPosition(tableId))) return;

  const { x, y } = prevFocusedCellPosition;
  const newY = y - 1;

  if (newY < -1) return;
  const cellElem = document.querySelector(`[table-cell-position="${tableId}-${x}-${y}"]`) as HTMLElement | null;
  const focusableElements = getAllFocusableElements(cellElem);
  removeFocusableState(cellElem, focusableElements);

  const newCellPosition = { tableId, x, y: newY };
  focusCell(newCellPosition, currentCellPositionRef);
};

type FocusDownCellType = RequiredProperties<
  FocusCellType,
  'cellPositionsConfig' | 'tableId' | 'currentCellPositionRef'
>;

const focusDownCell = ({ currentCellPositionRef, cellPositionsConfig, tableId }: FocusDownCellType) => {
  const prevFocusedCellPosition = currentCellPositionRef.current;

  const { x, y } = prevFocusedCellPosition;
  const { maximumY } = cellPositionsConfig;

  const newY = y + 1;
  if (newY > maximumY) return;

  const cellElem = document.querySelector(`[table-cell-position="${tableId}-${x}-${y}"]`) as HTMLElement | null;
  const focusableElements = getAllFocusableElements(cellElem);
  removeFocusableState(cellElem, focusableElements);

  const newCellPosition = { tableId, x, y: newY };
  focusCell(newCellPosition, currentCellPositionRef);
};

type CopyCellTextType = RequiredProperties<
  FocusCellType,
  'cellPositionsConfig' | 'tableId' | 'currentCellPositionRef' | 'rows'
>;

const copyCellText = ({ currentCellPositionRef, rows }: CopyCellTextType) => {
  if (!currentCellPositionRef.current) return;

  const { x, y } = currentCellPositionRef.current;
  const isHeader = y === -1;

  if (isHeader) {
    const firstRowCell = rows[0].cells[x];
    const { column } = firstRowCell;
    const headerValue = column.Header;

    const textValue = onlyText(headerValue as React.ReactNode);
    navigator.clipboard.writeText(textValue);

    return;
  }
  const currentCell = rows[y].cells[x];

  const { value } = currentCell;
  const textValue = onlyText(value as React.ReactNode);
  navigator.clipboard.writeText(textValue);
};

export const keyPressHandler = (keypress: KeyboardEvent['key'], e: KeyboardEvent) => {
  switch (keypress) {
    case KeyNames.Up: {
      if (isInteractiveElement()) return;
      e.preventDefault();
      return focusUpCell;
    }
    case KeyNames.Down: {
      if (isInteractiveElement()) return;
      e.preventDefault();
      return focusDownCell;
    }
    case KeyNames.Right: {
      if (isInteractiveElement()) return;
      e.preventDefault();
      return focusNextCell;
    }
    case KeyNames.Left: {
      if (isInteractiveElement()) return;
      e.preventDefault();
      return focusPrevCell;
    }
    case 'Copy': {
      e.preventDefault();
      return copyCellText;
    }
    case KeyNames.Enter: {
      if (handleEnter()) {
        e.preventDefault();
      }
      return;
    }
    case KeyNames.Escape: {
      e.preventDefault();
      handleEscape();
      return;
    }
    default:
      return;
  }
};

export const isCopy = ({
  key,
  ctrlKey,
  metaKey,
}: {
  key: KeyboardEvent['key'];
  ctrlKey: KeyboardEvent['ctrlKey'];
  metaKey: KeyboardEvent['metaKey'];
}) => {
  return (ctrlKey || metaKey) && key === 'c';
};
