import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { usePracticeAnalyticsStore } from './use-practice-analytics-store';

export const usePaNavigate = () => {
  const navigate = useNavigate();
  const { setIsPageReady } = usePracticeAnalyticsStore();

  const handleCustomNavigate = useCallback((props: Parameters<ReturnType<typeof useNavigate>>[0]) => {
    // PA does a lot of FE aggregations which takes time to render the page (feels like page is stuck)
    // With the help of isPageReady flag, we are showing the loading spinner and giving it some time to render itself before navigating to the next page
    // This flag will be reset in the next page's useEffect (on mount)
    // TODO :: in future aggregations will be moved to BE and this logic will be removed
    setIsPageReady(false);
    setTimeout(() => {
      navigate(props);
    }, 500);
  }, []);

  return handleCustomNavigate;
};
