import { LoaderFn } from '@tanstack/react-location';
import { QueryClient } from 'react-query';
import { authnClient } from '@frontend/auth';
import { getLoginData, getUser, getWeaveToken, isWeaveTokenBufferActive, PortalUser } from '@frontend/auth-helpers';
import { RouteWithPath, RouteOptions, getRoutesFromPaths, WeaveLoaderData } from '@frontend/file-based-routing';
import { AuthenticatedRoute } from '../../components/authenticated-route';
import NotFoundPage from '../../pages/404';

type Options = {
  queryClient?: QueryClient;
  missingModuleFallback: RouteOptions['missingModuleFallback'];
  moduleFetchErrorFallback: RouteOptions['moduleFetchErrorFallback'];
};

export function getWeaveRoutes(options: Options) {
  const paths = import.meta.glob<boolean, string, { [key: string]: any }>('/src/pages/**/[a-z[]*.tsx');
  const routes = getRoutesFromPaths(paths, {
    missingModuleFallback: options.missingModuleFallback,
    moduleFetchErrorFallback: options.moduleFetchErrorFallback,
    AccessControl: AuthenticatedRoute,
    modifyProps: (routeMatch, opts) => ({ match: { routeMatch, opts }, queryClient: options.queryClient }),
  })
    .filter((route): route is RouteWithPath => !!route.path)
    .map((route) => {
      if (route.path.startsWith('/public')) {
        return { ...route, meta: { type: 'public' as const }, path: route.path.replace('/public', '') };
      } else {
        return { ...route, meta: { type: 'private' as const } };
      }
    })
    .filter((route) => {
      return import.meta.env.MODE === 'development' ? true : !route.path?.startsWith('portal/example');
    })
    .concat([{ path: '*', meta: { type: 'public' }, element: <NotFoundPage /> }])
    .map((route) => {
      return {
        ...route,
        loader,
      };
    });
  return routes;
}

export function getWeaveSettingsRoutes(options: Options) {
  const paths = import.meta.glob<boolean, string, { [key: string]: any }>('/src/pages/settings/**/[a-z[]*.tsx');
  const routes = getRoutesFromPaths(paths, {
    missingModuleFallback: options.missingModuleFallback,
    moduleFetchErrorFallback: options.moduleFetchErrorFallback,
    AccessControl: AuthenticatedRoute,
    modifyProps: (routeMatch, opts) => ({ match: { routeMatch, opts }, queryClient: options.queryClient }),
  })
    .filter((route): route is RouteWithPath => !!route.path)
    .map((route) => {
      if (route.path.startsWith('/public')) {
        return { ...route, meta: { type: 'public' as const }, path: route.path.replace('/public', '') };
      } else {
        return { ...route, meta: { type: 'private' as const } };
      }
    })
    .concat([{ path: '*', meta: { type: 'public' }, element: <NotFoundPage /> }])
    .map((route) => {
      const path = route.path.startsWith('/settings') ? route.path.replace('/settings', '') : route.path;

      return {
        ...route,
        path,
        loader,
      };
    });
  return routes;
}

const loader: LoaderFn<
  WeaveLoaderData<{
    LoaderData: { authenticated: boolean; user: PortalUser; lastLocationId: string };
  }>
> = async () => {
  const token = getWeaveToken();
  const authenticated = authnClient.isUserAuthenticated() || isWeaveTokenBufferActive();
  const user: PortalUser | undefined = token ? getUser() ?? (await authnClient.fetchUser()) : undefined;
  const lastLocationId = user ? getLoginData(user.userID)?.lastLocationIds?.[0] : undefined;

  /**
   * Data returned here will be available on each route, accessible via `useMatch`
   * We could test for page access here as well (authenticated but not authorized)
   */
  return { authenticated, user, lastLocationId };
};
