import { useEffect, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useMatchMedia } from '@frontend/responsiveness';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { FieldChangeEvent, Modal, ModalControlModalProps, TextareaField, useFormField } from '@frontend/design-system';

const SIGNATURE_MODAL_SIZE = 600;
type TextSignatureModalProps = ModalControlModalProps & {
  initSignatureValue: string;
  onSubmit: (signature: string) => void;
  children?: React.ReactNode;
};

export const SignatureModal = ({
  initSignatureValue,
  onSubmit,
  children,
  ...modalControlProps
}: TextSignatureModalProps) => {
  const { t } = useTranslation('inbox');
  const modalTitle = initSignatureValue ? t('Edit Your Signature') : t('Add Your Signature');
  const primaryLabel = initSignatureValue ? t('Update Signature') : t('Add Signature');
  const [signatureDraft, setSignatureDraft] = useState(initSignatureValue);
  const fieldProps = useFormField({ type: 'text', value: '' });
  const hasSpace = useMatchMedia({ minWidth: SIGNATURE_MODAL_SIZE + 100 });

  const reset = () => {
    setSignatureDraft(initSignatureValue);
  };

  useEffect(() => {
    reset();
  }, [initSignatureValue, modalControlProps.show]);

  return (
    <Modal minWidth={hasSpace ? SIGNATURE_MODAL_SIZE : undefined} {...modalControlProps}>
      <Modal.Header title={modalTitle}>{modalTitle}</Modal.Header>
      <Modal.Body css={{ padding: theme.spacing(2, 0) }}>
        <TextareaField
          {...fieldProps}
          value={signatureDraft}
          onChange={(e: FieldChangeEvent<HTMLTextAreaElement> | undefined) => {
            // @ts-ignore DS types are wrong
            setSignatureDraft(e?.target.value);
          }}
          label={t('Signature')}
          name={t('Signature')}
        />
        {children}
      </Modal.Body>
      <Modal.Actions
        primaryLabel={primaryLabel}
        disablePrimary={signatureDraft === initSignatureValue}
        primaryTrackingId={`${InboxPrefixes.Signature}-update-modal-button`}
        onPrimaryClick={() => onSubmit(signatureDraft)}
        secondaryLabel={t('Cancel')}
        secondaryTrackingId={`${InboxPrefixes.Signature}-cancel-modal-button`}
        onSecondaryClick={() => {
          modalControlProps.onClose();
          reset();
        }}
      />
    </Modal>
  );
};
