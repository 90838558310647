import { PracticeAnalyticsTypes } from '../../practice';
import { generateNames, getRandomDate, getRandomInt, requiredPatientInfoFields } from './mock-generators';
import { getMockProcedures } from './procedures';

export const restorativeTreatmentPlanPatientsDetails = (): PracticeAnalyticsTypes.PatientInfo[] =>
  generateNames(15).map((name, id) => {
    const mockProcedures = getMockProcedures();
    const amount = mockProcedures.reduce((acc: number, cur) => acc + cur.procedureAmount, 0);

    return {
      ...name,
      ...requiredPatientInfoFields,
      diagnosed: getRandomDate(-1, -14),
      id: `${id}`,
      procedures: mockProcedures,
      productionAmount: amount,
      providerFirstName: '',
      providerLastName: '',
      providers: '',
    };
  });

export const restorativeTreatmentPlan = (): PracticeAnalyticsTypes.RestorativeTreatmentPlanResponse => {
  const totalAcceptedRestorative = getRandomInt(150, 180);

  return {
    location: {
      industryAvg: {
        totals: {
          totalAcceptedRestorative,
          totalDiagnosedRestorative: totalAcceptedRestorative + getRandomInt(10, 30),
          totalUnscheduledTreatmentRestorative: getRandomInt(500, 1000),
        },
      },
      restorativeTreatmentPlan: {
        benchmarks: [
          {
            value: 0.48,
            label: 'Top Diagnosed',
          },
          {
            value: 0.72,
            label: 'Top Accepted',
          },
        ],
        details: {
          patients: restorativeTreatmentPlanPatientsDetails(),
        },
        totals: {
          accepted: getRandomInt(50, 100),
          acceptedPercent: Math.random() * (0.6 - 0.8) + 0.8,
          diagnosed: getRandomInt(100, 200),
          diagnosedPercent: Math.random() * (0.5 - 0.3) + 0.3,
          qualifiedVisits: getRandomInt(200, 300),
          unscheduledTreatment: getRandomInt(500, 1000),
        },
      },
    },
  };
};
