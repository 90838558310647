import { RelatedID } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { SMSSignatureV1 } from '@frontend/api-sms-signature';
import { sanitizePhoneNumber } from '@frontend/phone-numbers';
import { useMutation } from '@frontend/react-query-helpers';
import { SchemaIO, SchemaManualSMSScheduledService } from '@frontend/schema';
import { sentry } from '@frontend/tracking';
import { addSignatureToMessage, formatPhoneWithCountryCode } from '../utils';
import { useInvalidateInboxList } from './use-invalidate-inbox-list';
import { useUpdateScheduledMessages } from './use-update-scheduled-messages';

type ScheduleMessageIO = SchemaIO<(typeof SchemaManualSMSScheduledService)['Schedule']>;

type UseScheduleMessageMutation = {
  groupId: string;
  threadId: string;
  personPhone: string;
  departmentId: string;
  personId?: string;
  userId: string;
  onSuccess?: (res: ScheduleMessageIO['output']) => void;
  onError?: () => void;
};

export const useScheduleMessageMutation = ({
  groupId,
  threadId,
  personPhone,
  departmentId,
  personId,
  userId,
  onSuccess,
  onError,
}: UseScheduleMessageMutation) => {
  const { upsert, remove } = useUpdateScheduledMessages();
  const invalidateInboxList = useInvalidateInboxList();
  const { data: signature = '' } = SMSSignatureV1.Queries.useGetSignatureQuery({
    request: {
      groupId,
      userId,
    },
    options: {
      enabled: !!userId,
      select: (data) => {
        return data.signature.signature;
      },
    },
  });
  const formattedPersonPhone = formatPhoneWithCountryCode(sanitizePhoneNumber(personPhone));

  return useMutation({
    mutationFn: ({
      message,
      scheduledTime,
      mediaIds,
      messageIdToReplace,
      relatedIds,
      pausable = true,
    }: {
      message: string;
      scheduledTime: Date | string;
      mediaIds?: string[];
      messageIdToReplace?: string;
      relatedIds: RelatedID[];
      pausable: boolean;
    }) => {
      if (!userId) {
        throw new Error('no user');
      }

      const sendAt = typeof scheduledTime === 'string' ? scheduledTime : scheduledTime.toISOString();

      return SchemaManualSMSScheduledService.Schedule({
        locationId: groupId,
        threadId,
        departmentId,
        personPhone: formattedPersonPhone,
        personId,
        body: messageIdToReplace ? message : addSignatureToMessage(message, signature),
        mediaIds,
        scheduledBy: userId,
        sendAt,
        relatedIds,
        pausable,
      });
    },
    onSuccess: async (res, { messageIdToReplace }) => {
      try {
        if (messageIdToReplace) {
          try {
            await SchemaManualSMSScheduledService.Delete({
              messageId: messageIdToReplace,
              locationId: groupId,
              deletedBy: userId,
            });
            remove({ threadId, groupId, smsId: messageIdToReplace });
          } catch (error) {
            sentry.warn({
              error,
              topic: 'messages',
              addContext: {
                name: 'ScheduleMessage edit context',
                context: {
                  sendResponse: res,
                  messageIdToReplace,
                  request: {
                    messageId: messageIdToReplace,
                    locationId: groupId,
                    deletedBy: userId,
                  },
                  errorMessage: JSON.stringify(error),
                  threadId,
                },
              },
            });
          }
        }
        upsert(res.scheduledSms);
        onSuccess?.(res);
        invalidateInboxList();
      } catch (err) {
        onError?.();
        sentry.warn({
          error: err,
          topic: 'messages',
          addContext: {
            name: 'ScheduleMessage edit context (2)',
            context: {
              sendResponse: res,
              messageIdToReplace,
              request: {
                messageId: messageIdToReplace,
                locationId: groupId,
                deletedBy: userId,
              },
              errorMessage: JSON.stringify(err),
              threadId,
            },
          },
        });
      }
    },
    onError,
  });
};
