export const buildAudioLibraryPath = ({
  media,
  token,
}: {
  media: { id: string; path: string; isGlobal: boolean };
  token: string;
}): string => {
  if (media.path === '') {
    return '';
  }

  const mediaUrl = new URL(media.path);
  mediaUrl.searchParams.append('token', token as string);
  if (media.isGlobal) {
    mediaUrl.searchParams.append('isGlobalMedia', 'true');
  }
  return mediaUrl.toString();
};
