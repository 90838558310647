import { PersonsV3 } from '@frontend/api-person';
import { SendInThreadStateProps } from '../components';
import { InboxQueries, MessagesHooks, MessagesTypes, MessagesUtils } from '@frontend/api-messaging';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { getUser } from '@frontend/auth-helpers';
import { useDebouncedValue, useFileUpload, useAlert, ModalProps } from '@frontend/design-system';
import { useDraft } from '@frontend/api-sms-draft';
import { useThreadMedia } from './use-thread-media';
import {
  DynamicFieldProperty_Enum,
  Template,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { TemplatorV2Queries } from '@frontend/api-templator-v2';
import { DestinationType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { useGetDefaultTemplate } from './use-get-default-template';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { LinkData, PropertyBindingsData } from '../types';
import { RelatedID } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { TEMPLATE_TYPE_LINK_MAP } from '../constants';
import { convertBindingsDataToPropertyBindingsList, convertLinkDataToProperty } from '../utils';
import { isEqual } from 'lodash-es';
import { DepartmentsQueries } from '@frontend/api-departments';
import { SendInThreadModalProps } from '../modals';

type UseSendInThreadStateArgs = Omit<SendInThreadModalProps, 'onBack' | keyof ModalProps> & {
  propertyBindings?: PropertyBindingsData;
  onSend?: () => void;
  onSendError?: () => void;
  onSchedule?: () => void;
  onScheduleError?: () => void;
};

type SendInThreadState = {
  contentProps: SendInThreadStateProps;
  sendMessage: () => void;
  scheduleMessage: (scheduledTime: Date | string, pausable: boolean) => void;
  setDraftToRenderedTemplate: (template: Template, propertyBindings?: PropertyBindingsData) => void;
};

export const useSendInThreadState = ({
  groupId,
  initThreadId,
  initPersonPhone,
  personId,
  onSend,
  onSendError,
  onSchedule,
  onScheduleError,
  onThreadChange,
  templateType,
  linkData,
  propertyBindings,
  initialBody,
  initialMediaIds,
}: UseSendInThreadStateArgs): SendInThreadState => {
  const { t } = useTranslation('integrated-messaging');
  const alert = useAlert();
  const { selectedOrgId, getLocationName, accessibleLocationData, selectedLocationIds } = useAppScopeStore();
  const locationData = accessibleLocationData[groupId];
  const currentUser = getUser();
  const [bodyValue, setBodyValue] = useState(initialBody ?? '');
  const [previousThreadId, setPreviousThreadId] = useState(initThreadId);
  const debouncedBodyValue = useDebouncedValue(bodyValue, 1000);
  const [selectedPersonPhone, setSelectedPersonPhone] = useState(initPersonPhone);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<string>();
  const [threadSelectionHasChanged, setThreadSelectionHasChanged] = useState(false);
  const getDefaultTemplate = useGetDefaultTemplate({
    groupIds: [groupId],
  });
  const [initTemplate, setInitTemplate] = useState<Template>();
  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      locationIds: [groupId],
      personId: personId ?? '',
    },
    {
      enabled: !!personId,
      select: (data) => ({
        ...data,
        contactInfo: data.contactInfo?.filter(
          (contact) => contact.type !== ContactType_Enum.EMAIL && !!contact.destination
        ),
      }),
    }
  );
  const { lastUpdatedThread } = MessagesHooks.useLastUpdatedThread({
    personId: personId ?? '',
    groupIds: [groupId],
    personPhones: initPersonPhone ? [initPersonPhone] : undefined,
  });
  const threadIdQuery = InboxQueries.getThreadId(
    {
      locationId: groupId,
      personPhone: selectedPersonPhone ?? '',
      departmentId: selectedDepartmentId,
      calculateMissing: true,
    },
    {
      enabled:
        (!!selectedPersonPhone && (selectedPersonPhone !== initPersonPhone || !!selectedDepartmentId)) ||
        !lastUpdatedThread?.id,
    }
  );
  const threadId = threadSelectionHasChanged ? threadIdQuery.data?.threadId : initThreadId;
  const { deleteMutation, shallowUpdate } = useDraft({
    threadId: threadId ?? '',
    userId: currentUser?.userID ?? '',
    orgId: selectedOrgId,
    locationId: groupId,
    groupIds: selectedLocationIds,
  });
  const {
    metadata,
    messages,
    scheduledMessages,
    isLoadingFirstPage,
    hasOlderMessages,
    fetchOlderMessages,
    mediaQueries,
  } = MessagesHooks.useThread({
    threadId: threadId ?? '',
    groupId,
    providedPersonPhone: selectedPersonPhone,
    getThreadRetryLimit: 1,
  });
  const selectedDepartmentQuery = DepartmentsQueries.useListDefaultSMSQuery(
    { locationId: groupId },
    {
      select: (data) => {
        return data.smsNumbers?.find((number) => number.id === selectedDepartmentId);
      },
      enabled: !!selectedDepartmentId,
    }
  );

  const departmentsQuery = DepartmentsQueries.useListDefaultSMSQuery({ locationId: groupId });
  const locationPhone =
    departmentsQuery.data?.smsNumbers?.find((dept) => dept.id === selectedDepartmentId)?.smsNumber?.number ||
    messages[0]?.locationPhone ||
    '';

  const onSelectPersonPhone = (personPhone: string) => {
    if (!threadSelectionHasChanged) setThreadSelectionHasChanged(true);
    setSelectedPersonPhone(personPhone);
  };

  const onSelectDepartmentId = (departmentId?: string) => {
    if (!threadSelectionHasChanged) setThreadSelectionHasChanged(true);
    setSelectedDepartmentId(departmentId);
  };

  const { threadMedia, uploadFiles, removeMediaItem, clearMedia } = useThreadMedia({
    threadId: threadId ?? '',
    groupId,
    departmentId: selectedDepartmentId ?? '',
    personPhone: selectedPersonPhone ?? '',
    locationPhone,
    maxFileCount: 10,
    loadDraft: false,
    onError: () => {
      alert.error(t('Error uploading image. Please try again.'));
    },
    onExceedMaxFileCount: (excessCount) => {
      alert.error(
        t('You can only send up to 10 images in a single message. {{count}} images were not uploaded.', {
          count: excessCount,
        })
      );
    },
    mediaIds: initialMediaIds,
  });

  const imageUploadProps = useFileUpload({
    acceptedFileType: ['jpeg', 'png', 'jpg'],
    onFileUpload: (files) => {
      uploadFiles(files);
      imageUploadProps.resetFiles();
    },
  });

  const clearDraft = () => {
    deleteMutation.mutate({ threadId: threadId ?? '', userId: currentUser?.userID ?? '', orgId: selectedOrgId });
    clearMedia();
    setBodyValue('');
  };

  const { mutate: sendMessage } = MessagesHooks.useSendMessageMutation(
    {
      locationId: groupId,
    },
    {
      onSuccess: () => {
        clearDraft();
        onSend?.();
      },
      onError: () => {
        onSendError?.();
      },
    }
  );
  const { mutate: scheduleMessage } = MessagesHooks.useScheduleMessageMutation({
    groupId,
    threadId: threadId ?? '',
    personPhone: selectedPersonPhone ? MessagesUtils.formatPhoneWithCountryCode(selectedPersonPhone) : '',
    departmentId: selectedDepartmentId ?? '',
    personId: personId ?? '',
    userId: currentUser?.userID ?? '',
    onSuccess: () => {
      clearDraft();
      onSchedule?.();
    },
    onError: () => {
      onScheduleError?.();
    },
  });

  const [renderData, setRenderData] = useState<{
    payload: Parameters<(typeof TemplatorV2Queries)['useRender']>[0];
    templateId: string;
    linkData?: LinkData;
  }>();
  const renderedTemplateQuery = TemplatorV2Queries.useRender(
    renderData?.payload
      ? {
          ...renderData.payload,
          keepEmptyDynamicFields: true,
        }
      : {
          templateTypeSlug: TemplateType_Slug.UNSPECIFIED_TEMPLATE_TYPE,
          templateString: '',
          bindingsList: [],
          timezone: locationData?.timezone ?? '',
          // TODO: get the locale dynamically once we have a way to do so
          locale: 'en_US',
          destinationType: DestinationType_Enum.SMS,
        },
    {
      enabled: !!renderData,
      onSuccess: ({ message: { message } }) => {
        setBodyValue(message);
      },
    }
  );

  const mediaIds = threadMedia.reduce<string[]>((acc, media) => {
    if (media.mediaObj?.mediaId) acc.push(media.mediaObj.mediaId);
    return acc;
  }, []);

  const getRelatedIds = (body: string): RelatedID[] => {
    if (!templateType || !linkData || !body.includes(linkData.link)) return [];
    const linkMap = TEMPLATE_TYPE_LINK_MAP[templateType];
    return [
      {
        type: linkMap.relatedType,
        id: linkData.relatedId,
      },
    ];
  };

  const handleSend = () => {
    sendMessage({
      locationId: groupId,
      threadId: threadId ?? '',
      personPhone: selectedPersonPhone ? MessagesUtils.formatPhoneWithCountryCode(selectedPersonPhone) : '',
      departmentId: selectedDepartmentId,
      body: bodyValue,
      media: mediaIds.map((mediaId) => ({ mediaId })),
      programSlugId: MessagesTypes.KnownProgramSlugIds.MANUAL_MESSAGES,
      relatedIds: getRelatedIds(bodyValue),
    });
  };

  const handleSchedule = (scheduledTime: Date | string, pausable: boolean) => {
    scheduleMessage({
      message: bodyValue,
      scheduledTime,
      mediaIds,
      relatedIds: getRelatedIds(bodyValue),
      pausable,
    });
  };

  useEffect(() => {
    if (renderedTemplateQuery.data) {
      setBodyValue(renderedTemplateQuery.data.message.message);
    }
  }, [renderedTemplateQuery.data]);

  useEffect(() => {
    if (previousThreadId)
      shallowUpdate({
        threadId: previousThreadId,
        draft: { body: debouncedBodyValue },
        departmentId: selectedDepartmentId ?? '',
        personPhone: selectedPersonPhone ?? '',
        locationPhone,
      });
  }, [debouncedBodyValue]);

  useEffect(() => {
    if (metadata?.departmentId && !selectedDepartmentId) setSelectedDepartmentId(metadata.departmentId);
  }, [metadata?.departmentId]);

  useEffect(() => {
    if (threadId && previousThreadId !== threadId) {
      if (previousThreadId)
        deleteMutation.mutate({ threadId: previousThreadId, userId: currentUser?.userID ?? '', orgId: selectedOrgId });
      shallowUpdate({
        threadId,
        departmentId: selectedDepartmentId ?? '',
        personPhone: selectedPersonPhone ?? '',
        locationPhone,
        draft: {
          body: bodyValue,
          medias: threadMedia.map((media) => ({
            mediaId: media.mediaObj?.mediaId || media.id,
          })),
        },
      });
      setPreviousThreadId(threadId);
    }
  }, [threadId]);

  useEffect(() => {
    if (threadId)
      shallowUpdate({
        threadId,
        departmentId: selectedDepartmentId ?? '',
        personPhone: selectedPersonPhone ?? '',
        locationPhone,
        draft: {
          medias:
            initialMediaIds?.map((mediaId) => ({
              mediaId,
            })) ?? [],
        },
      });
  }, [JSON.stringify(initialMediaIds)]);

  useEffect(() => {
    if ((lastUpdatedThread?.id || !!personQuery.data?.personId) && !initThreadId) {
      setSelectedPersonPhone(
        lastUpdatedThread?.messages[0]?.personPhone ?? personQuery.data?.contactInfo?.[0]?.destination
      );
      setSelectedDepartmentId(lastUpdatedThread?.departmentId);
      setPreviousThreadId(lastUpdatedThread?.id);
      setThreadSelectionHasChanged(true);
    }
  }, [lastUpdatedThread?.id, personQuery.data?.personId]);

  const defaultBindingsData = useMemo<PropertyBindingsData>(
    () => ({
      [DynamicFieldProperty_Enum.ORG_NAME]: getLocationName(selectedOrgId),
      [DynamicFieldProperty_Enum.BUSINESS_GROUP_NAME]: getLocationName(groupId),
      [DynamicFieldProperty_Enum.BUSINESS_GROUP_PHONE]:
        selectedDepartmentQuery.data?.smsNumber?.number || messages?.[0]?.locationPhone,
      [DynamicFieldProperty_Enum.FIRST_NAME]: personQuery.data?.firstName,
      [DynamicFieldProperty_Enum.LAST_NAME]: personQuery.data?.lastName,
      [DynamicFieldProperty_Enum.PREFERRED_NAME]: personQuery.data?.preferredName || personQuery.data?.firstName,
    }),
    [
      groupId,
      selectedOrgId,
      personQuery.data?.firstName,
      personQuery.data?.lastName,
      personQuery.data?.preferredName,
      messages?.[0]?.locationPhone,
      getLocationName,
    ]
  );

  const setDraftToRenderedTemplate = useCallback<SendInThreadState['setDraftToRenderedTemplate']>(
    (template, propertyBindingsData = {}) => {
      const linkDataPropertyBindings =
        linkData && templateType ? convertLinkDataToProperty({ linkData, templateType }) : undefined;
      const bindingsList = convertBindingsDataToPropertyBindingsList({
        ...defaultBindingsData,
        ...linkDataPropertyBindings,
        ...propertyBindings,
        ...propertyBindingsData,
      });
      setRenderData((prev) => {
        const newPayload = {
          templateTypeSlug: template.templateTypeSlug,
          templateString: template.templateString,
          bindingsList,
          timezone: locationData?.timezone ?? '',
          // TODO: get the locale dynamically once we have a way to do so
          locale: 'en_US',
          destinationType: DestinationType_Enum.SMS,
        };

        if (renderedTemplateQuery.data && isEqual(newPayload, prev?.payload)) {
          setBodyValue(renderedTemplateQuery.data.message.message);
        }

        return {
          payload: newPayload,
          templateId: template.templateId,
        };
      });
    },
    [
      selectedOrgId,
      defaultBindingsData,
      propertyBindings,
      locationData?.timezone,
      setRenderData,
      linkData,
      templateType,
      convertLinkDataToProperty,
      renderedTemplateQuery?.data,
      setBodyValue,
    ]
  );

  useEffect(() => {
    if (templateType) {
      const template = getDefaultTemplate({ type: templateType });
      if (template) setInitTemplate(template);
    }
  }, [groupId, templateType]);

  useEffect(() => {
    const hasPerson = personId ? !!personQuery.data : true;
    if (hasPerson && initTemplate) {
      setDraftToRenderedTemplate(initTemplate);
    }
  }, [initTemplate, personId, personQuery.data, JSON.stringify(linkData), JSON.stringify(propertyBindings)]);

  useEffect(() => {
    if (threadId) {
      onThreadChange?.({
        threadId,
        personPhone: selectedPersonPhone || '',
        locationPhone: selectedDepartmentQuery.data?.smsNumber?.number || messages?.[0]?.locationPhone || '',
        departmentId: selectedDepartmentId || '',
      });
    }
  }, [threadId]);

  return {
    contentProps: {
      imageUploadProps,
      groupId,
      threadId: threadId ?? '',
      person: personQuery.data,
      selectedPersonPhone: selectedPersonPhone ?? '',
      onSelectPersonPhone,
      selectedDepartmentId,
      onSelectDepartmentId,
      bodyValue,
      setBodyValue,
      onSend: handleSend,
      onSchedule: handleSchedule,
      messages,
      scheduledMessages,
      isLoadingFirstPage,
      hasOlderMessages,
      mediaQueries,
      fetchOlderMessages,
      threadMedia,
      removeMediaItem,
    },
    sendMessage: handleSend,
    scheduleMessage: handleSchedule,
    setDraftToRenderedTemplate,
  };
};
