import { ReactNode } from 'react';
import { keyframes } from '@emotion/react';
import {
  GenerateReplyRequest,
  SubmitFeedbackRequest,
  FeedbackType,
  Refinement,
} from '@weave/schema-gen-ts/dist/schemas/sms/reply-assistant/v1/reply_assistant_service.pb';
import { AiResponseActions } from '@frontend/ai-response-actions';
import { TFunction } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { ExtraAction } from '@frontend/super-textarea';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Chip, ModalControlResponse, UsePopoverMenuResponse } from '@frontend/design-system';

type actionType = {
  disabled: boolean;
  t: TFunction<'inbox', undefined>;
};

type signatureActionType = actionType & {
  signature: string;
  textSignatureModalControls: ModalControlResponse;
};

type templateActionType = actionType & {
  templatesPopoverControls: UsePopoverMenuResponse<HTMLButtonElement | HTMLAnchorElement>;
};

type aiAssistantActionType = actionType & {
  canShowAiAssistant: boolean;
  aiReplyAssistantFeatureFlag: boolean;
  generateAiResponse: (reqBody: GenerateReplyRequest) => void;
  threadId: string;
  groupId: string;
  personName: string;
  locationName: string;
  bodyValue: string;
};

type aiResponseActionsType = {
  threadId: string;
  groupId: string;
  patientName: string;
  locationName: string;
  bodyValue: string;
  userId: string;
  canShowAiResponseActions: boolean;
  isGenerateAiResponseLoading: boolean;
  canShowResponseFeedback: boolean;
  canUndo: boolean;
  canRedo: boolean;
  shouldOpenInRefinementMode: boolean;
  closeAiResponseActions: () => void;
  generateAiResponse: (args: GenerateReplyRequest) => void;
  undoResponse: () => void;
  redoResponse: () => void;
  submitFeedback: (args: Omit<SubmitFeedbackRequest, 'draft'>) => void;
};

const getIconStyles = (disabled: boolean) => ({
  color: disabled ? theme.colors.neutral20 : theme.colors.neutral70,
});

export const getSignatureAction = ({
  disabled,
  signature,
  t,
  textSignatureModalControls,
}: signatureActionType): ExtraAction => ({
  icon: <Icon name='signature' css={getIconStyles(disabled)} />,
  onClick: () => textSignatureModalControls.triggerProps.onClick(),
  label: !!signature ? t('Edit Text Signature') : t('Add Text Signature'),
  trackingId: `${InboxPrefixes.Signature}-text-area-edit-button`,
  disabled,
});

export const getTemplateAction = ({ disabled, templatesPopoverControls, t }: templateActionType): ExtraAction => ({
  icon: (
    <>
      <Icon name='template' css={getIconStyles(disabled)} />
      <Icon
        name='caret-down-tiny'
        css={{
          transform: templatesPopoverControls.isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.2s ease-in-out',
        }}
      />
    </>
  ),
  disabled: disabled,
  label: templatesPopoverControls.isOpen ? '' : t('Templates'),
  css: { minWidth: '44px', gap: '4px', padding: '4px' },
  ...templatesPopoverControls.getTriggerProps(),
});

export const getAiAssistantAction = ({
  disabled,
  canShowAiAssistant,
  aiReplyAssistantFeatureFlag,
  generateAiResponse,
  threadId,
  groupId,
  personName,
  locationName,
  bodyValue,
  t,
}: aiAssistantActionType): ExtraAction | null =>
  canShowAiAssistant && aiReplyAssistantFeatureFlag && !disabled
    ? {
        icon: (
          <Icon
            name='aiassistant'
            css={{
              ...getIconStyles(disabled),
              zIndex: theme.zIndex.high,
              animation: `${keyframes`
                0% { opacity: 0; } 
                100% { opacity: 1; }`} 0.5s linear`,
            }}
          />
        ),
        onClick: () =>
          generateAiResponse({
            threadId,
            locationId: groupId,
            patientName: personName,
            officeName: locationName,
            previousDraft: bodyValue,
          }),
        disabled,
        label: (
          <>
            <Chip css={{ marginRight: '4px' }} variant='seaweed'>
              Beta
            </Chip>
            {t('Weave Assistant')}
          </>
        ),
      }
    : null;

export const SMSAiResponseActions = ({
  threadId,
  groupId,
  patientName,
  locationName,
  bodyValue,
  userId,
  canShowAiResponseActions,
  isGenerateAiResponseLoading,
  canShowResponseFeedback,
  canUndo,
  canRedo,
  shouldOpenInRefinementMode,
  closeAiResponseActions,
  generateAiResponse,
  undoResponse,
  redoResponse,
  submitFeedback,
}: aiResponseActionsType): ReactNode => (
  <AiResponseActions
    onRegenerate={() =>
      generateAiResponse({
        threadId,
        locationId: groupId,
        patientName,
        officeName: locationName,
        previousDraft: bodyValue,
      })
    }
    onShorten={() =>
      generateAiResponse({
        threadId,
        locationId: groupId,
        refinement: Refinement.REFINEMENT_SHORTEN,
        previousDraft: bodyValue, // Shorten works better with previous draft.
        patientName,
        officeName: locationName,
      })
    }
    onLengthen={() =>
      generateAiResponse({
        threadId,
        locationId: groupId,
        refinement: Refinement.REFINEMENT_LENGTHEN,
        previousDraft: bodyValue, // Lengthen works better with previous draft.
        patientName,
        officeName: locationName,
      })
    }
    onUndo={undoResponse}
    onRedo={redoResponse}
    onThumbsUp={() =>
      submitFeedback({
        threadId,
        locationId: groupId,
        userId,
        feedbackType: FeedbackType.FEEDBACK_TYPE_GOOD,
      })
    }
    onThumbsDown={() =>
      submitFeedback({
        threadId,
        locationId: groupId,
        userId,
        feedbackType: FeedbackType.FEEDBACK_TYPE_BAD,
      })
    }
    shouldRender={canShowAiResponseActions}
    isGenerateAiResponseLoading={isGenerateAiResponseLoading}
    canShowResponseFeedback={canShowResponseFeedback}
    closeActions={closeAiResponseActions}
    isRefinementsDisabled={!bodyValue}
    isRedoDisabled={!canRedo}
    isUndoDisabled={!canUndo}
    shouldOpenInRefinementMode={shouldOpenInRefinementMode}
  />
);
