export enum SendFormsOrigin {
  FORMS,
  CONTACTS,
  NONE,
}

export enum SendFormStep {
  SELECT_CONTACT,
  SELECT_APPOINTMENT,
  SELECT_DELIVERY_METHOD,
  SELECT_DOCUMENT,
  SELECTED_MESSAGE_MODE,
}

export type DeliveryOption = 'email' | 'message' | 'kiosk';

export enum ContactNumberType {
  HOME,
  MOBILE,
  WORK,
}
