import {
  InfiniteData,
  QueryFunctionContext,
  QueryKey,
  SetDataOptions,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useQueries,
  useQueryClient,
} from 'react-query';
import { PersonAPI, PersonTypes } from '@frontend/api-person';
import { useQuery, ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import {
  SchemaIO,
  SchemaManualSMSScheduledService,
  SchemaSMSBlockNumberService,
  SchemaSMSSearchService,
  SchemaSMSService,
} from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { Unpromise } from '@frontend/types';
import { SchemaPhoneNumbersService } from './services';
import { validateQueryEnabledWithDefault } from './utils';
import { MessagesApi } from '.';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

const baseQueryKey = ['inbox'];

export type GetThreadQueryKeyArgs = {
  locationId: string;
  threadId: string;
  taggedSmsId?: string;
};
type GetThreadKey = (string | Record<string, string>)[];

export const queryKeys = {
  listThreads: (req: ListThreadsIO['input']) => [...queryKeys.inboxListBase, { ...req }],
  // WARNING: if you change this query key, then `parseGetThread` needs to be updated as well
  getThread: ({ locationId, threadId, taggedSmsId }: GetThreadQueryKeyArgs): GetThreadKey => {
    const res: GetThreadKey = [locationId, ...baseQueryKey, 'getThread', threadId];
    if (taggedSmsId) res.push({ taggedSmsId });
    return res;
  },
  parseGetThread: (queryKey: QueryKey): { threadId: string; locationId: string; taggedSmsId?: string } | undefined => {
    if (!Array.isArray(queryKey)) return undefined;
    const locationId = queryKey[0];
    const lastKeyEntry = queryKey.at(-1);
    const hasTaggedSmsId = typeof lastKeyEntry === 'object' && 'taggedSmsId' in lastKeyEntry;
    const threadId = hasTaggedSmsId ? queryKey.at(-2) : queryKey.at(-1);
    const taggedSmsId = hasTaggedSmsId ? lastKeyEntry.taggedSmsId : undefined;
    return { threadId, locationId, taggedSmsId };
  },
  getThreadId: (req: GetThreadIdIO['input']) => [...baseQueryKey, 'getThreadId', { ...req }],
  listMultiTags: (groupIds: string[]) => [...baseQueryKey, 'listMultiTags', ...groupIds],
  unreadCountBase: [...baseQueryKey, 'unreadCount'],
  unreadCount: (groupIds: string[]) => [...queryKeys.unreadCountBase, { groupIds }],
  personExtended: (personId: string, groupId?: string) => [...baseQueryKey, 'personExtended', personId, { groupId }],
  listThreadScheduledMessages: (groupId: string, threadId: string) => [
    ...baseQueryKey,
    'listThreadScheduledMessages',
    groupId,
    threadId,
  ],
  listScheduledThreads: (req: ListScheduledThreadsIO['input']) => [
    ...queryKeys.inboxListBase,
    'listScheduledThreads',
    { ...req },
  ],
  isBlocked: (groupId: string, personPhone: string) => [...baseQueryKey, 'isBlocked', groupId, personPhone],
  inboxListBase: [...baseQueryKey, 'list-threads'],
  smsSearchV1: (req: SMSSearchV1IO['input']) => [...baseQueryKey, 'sms-search-v1', { ...req }],
  locationSmsNumbers: (locationId: string) => [...baseQueryKey, 'locationSmsNumbers', locationId],
};

type ListThreadsIO = SchemaIO<(typeof SchemaSMSService)['ListThreads']>;
type ListThreadPageParams =
  | {
      lastThreadId: string;
      lastCreatedAt: string;
    }
  | undefined;
export const useListThreadsInfiniteQuery = <T = ListThreadsIO['output']>(
  req: ListThreadsIO['input'],
  options?: UseInfiniteQueryOptions<ListThreadsIO['output'], unknown, T>
) =>
  useInfiniteQuery({
    queryKey: queryKeys.listThreads(req),
    queryFn: ({ pageParam }: QueryFunctionContext<string | readonly unknown[], ListThreadPageParams>) => {
      return SchemaSMSService.ListThreads({
        ...req,
        lastThreadId: pageParam?.lastThreadId || req.lastThreadId,
        lastCreatedAt:
          pageParam?.lastCreatedAt || req.lastCreatedAt || new Date(new Date().getTime() + 5000).toISOString(),
      });
    },
    getNextPageParam: (lastPage, allPages): ListThreadPageParams => {
      const lastThread = lastPage.threads.at(-1);
      const lastPageIsNotFull = req.threadLimit === undefined ? lastPage.count === 0 : lastPage.count < req.threadLimit;
      if (lastPageIsNotFull || !lastThread) return undefined;
      const lastMessage = lastThread.messages.at(-1) ?? allPages.at(-1)?.threads.at(-1)?.messages.at(-1);
      if (!lastMessage) return undefined;

      return {
        lastThreadId: lastThread.id,
        lastCreatedAt: lastMessage.createdAt,
      };
    },
    ...defaultOptions,
    ...options,
  });

const THREAD_MESSAGE_LIMIT = 50;
type GetThreadIO = SchemaIO<(typeof SchemaSMSService)['GetThread']>;
type GetThreadInfiniteQueryProps = Omit<GetThreadIO['input'], 'messageSkip'>;
type ThreadResponse = GetThreadIO['output'];
type GetThreadPageParams =
  | {
      type: 'tagged';
      direction: 'next' | 'previous';
      smsId: string;
      createdAt: string;
      messageSkip?: never;
    }
  | {
      type: 'default';
      direction?: never;
      smsId?: never;
      createdAt?: never;
      messageSkip: number;
    };
const TAGGED_PAGINATION_LIMITS = {
  BEFORE_MESSAGE: 9,
  AFTER_MESSAGE: 15,
};

/**
 * Important note: 'Next' and 'Previous' function opposite of what you might expect for this query.
 * To fetch older messages, use `fetchNextPage`. To fetch newer messages, use `fetchPreviousPage`.
 */
export const getThreadInfiniteQuery = (
  { threadId, locationId, messageLimit = THREAD_MESSAGE_LIMIT, ...req }: GetThreadInfiniteQueryProps,
  options?: UseInfiniteQueryOptions<ThreadResponse>
) => {
  const queryKey = queryKeys.getThread({
    locationId,
    threadId,
    taggedSmsId: req.taggedSmsId,
  });
  const isPaginatingFromTaggedSms = req.taggedSmsId && req.taggedCreatedAt;

  return useInfiniteQuery({
    ...defaultOptions,
    queryKey,
    queryFn: ({ pageParam }: QueryFunctionContext<string | readonly unknown[], GetThreadPageParams>) => {
      if (!pageParam || pageParam?.type === 'default')
        return SchemaSMSService.GetThread({
          threadId,
          locationId,
          messageLimit,
          ...req,
          tagsEnabled: true,
          messageSkip: pageParam?.messageSkip ?? 0,
        });

      const { taggedSmsId: _id, taggedCreatedAt: _created, ...reqWithoutInitTaggedParams } = req;

      if (pageParam.direction === 'next') {
        return SchemaSMSService.GetThread({
          threadId,
          locationId,
          messageLimit,
          ...reqWithoutInitTaggedParams,
          tagsEnabled: true,
          pagingOlderSmsId: pageParam.smsId,
          pagingCreatedAt: pageParam.createdAt,
        });
      } else {
        return SchemaSMSService.GetThread({
          threadId,
          locationId,
          messageLimit,
          ...reqWithoutInitTaggedParams,
          tagsEnabled: true,
          pagingNewerSmsId: pageParam.smsId,
          pagingCreatedAt: pageParam.createdAt,
        });
      }
    },
    // Inbox scroll action down for fetching newer messages
    getPreviousPageParam: (firstPage, allPages): GetThreadPageParams | undefined => {
      const hasFirstPage = firstPage.thread.messages.length === 0;
      if (hasFirstPage) {
        return undefined;
      }

      if (isPaginatingFromTaggedSms) {
        if (allPages.length === 1) {
          const messagesBeforeTaggedSms = firstPage.thread.messages.slice(
            0,
            firstPage.thread.messages.findIndex((sms) => sms.id === req.taggedSmsId)
          );
          if (messagesBeforeTaggedSms.length < TAGGED_PAGINATION_LIMITS.BEFORE_MESSAGE) {
            return undefined;
          }
        }

        const oldestSms = firstPage.thread.messages.at(0);
        if (oldestSms)
          return {
            type: 'tagged',
            direction: 'previous',
            smsId: oldestSms.id,
            createdAt: oldestSms.createdAt,
          };
      }

      return undefined;
    },
    // Inbox scroll action up for fetching older messages
    getNextPageParam: (lastPage, allPages): GetThreadPageParams | undefined => {
      const hasLastPage = lastPage.thread.messages.length === 0;
      if (hasLastPage) return undefined;
      if (isPaginatingFromTaggedSms) {
        if (allPages.length === 1) {
          const messagesAfterTaggedSms = lastPage.thread.messages.slice(
            lastPage.thread.messages.findIndex((sms) => sms.id === req.taggedSmsId) + 1
          );
          if (messagesAfterTaggedSms.length < TAGGED_PAGINATION_LIMITS.AFTER_MESSAGE) return undefined;
        }

        const newestSms = lastPage.thread.messages.at(-1);

        if (newestSms)
          return {
            type: 'tagged',
            direction: 'next',
            smsId: newestSms.id,
            createdAt: newestSms.createdAt,
          };
      }
      const messageSkip = allPages.reduce((acc, page) => acc + page.thread.messages.length, 0);
      return {
        type: 'default',
        messageSkip,
      };
    },
    retry: false,
    ...options,
    enabled: validateQueryEnabledWithDefault(!!threadId && threadId !== 'new', options?.enabled),
  });
};

export const useSetThreadInfiniteData = () => {
  const queryClient = useQueryClient();

  return ({
    threadId,
    locationId,
    thread,
  }: {
    threadId: string;
    locationId: string;
    thread: ThreadResponse['thread'];
  }) => {
    const queryKey = queryKeys.getThread({ locationId, threadId });
    const queryExists = !!queryClient.getQueryState(queryKey, { exact: true })?.data;
    if (queryExists)
      queryClient.setQueryData<InfiniteData<ThreadResponse>>(queryKey, () => ({
        pages: [{ thread }],
        pageParams: [undefined],
      }));
  };
};

type GetThreadIdIO = SchemaIO<(typeof SchemaSMSService)['LookupThreadId']>;
export const getThreadId = (req: GetThreadIdIO['input'], options?: UseQueryOptions<GetThreadIdIO['output']>) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.getThreadId(req),
    queryFn: () => SchemaSMSService.LookupThreadId(req),
    ...options,
    enabled: validateQueryEnabledWithDefault(!!req.personPhone && !!req.locationId, options?.enabled),
  });

export const listMultiTags = (
  groupIds: string[],
  options?: UseQueryOptions<
    {
      groupId: string;
      tags: SchemaIO<(typeof SchemaSMSService)['ListTags']>['output']['tags'];
    }[]
  >
) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.listMultiTags(groupIds),
    queryFn: () =>
      Promise.all(
        groupIds.map(async (id) => {
          const tagsRes = await SchemaSMSService.ListTags({ locationId: id }).catch(() => ({ tags: [] }));
          return { groupId: id, tags: tagsRes.tags ?? [] };
        })
      ),
    ...options,
  });

type UnreadCountResponse = Unpromise<ReturnType<typeof MessagesApi.getUnreadCount>>;
type ExtendedQueryOptions = UseQueryOptions<UnreadCountResponse> & { avoidLastAutomated?: boolean };
export const useUnreadCount = (options?: ExtendedQueryOptions) => {
  const { selectedLocationIds } = useAppScopeStore();

  const { avoidLastAutomated, ...rest } = options ?? {};
  const query = useQuery({
    queryKey: queryKeys.unreadCount(selectedLocationIds),
    queryFn: () =>
      MessagesApi.getUnreadCount({
        locationId: selectedLocationIds[0] ?? '',
        groupIds: selectedLocationIds,
        avoidLastAutomated,
      }),
    ...rest,
    enabled: validateQueryEnabledWithDefault(selectedLocationIds.length > 0, options?.enabled),
  });

  return query.data?.count ?? 0;
};

export const useModifyUnreadCount = () => {
  const queryClient = useQueryClient();
  const { selectedLocationIds } = useAppScopeStore();

  return (type: 'increment' | 'decrement', groupIds?: string[], options?: SetDataOptions) => {
    const queryKey = queryKeys.unreadCount(groupIds ?? selectedLocationIds);
    const queryExists = !!queryClient.getQueryState(queryKey, { exact: true })?.data;
    if (queryExists)
      queryClient.setQueryData<UnreadCountResponse>(
        queryKey,
        (oldData) => ({
          count: Math.max((oldData?.count ?? 0) + (type === 'increment' ? 1 : -1), 0),
        }),
        options
      );
  };
};

type UsePersonExtendedRequest = {
  personId: string;
  groupId?: string;
};
export const usePersonExtended = (
  { personId, groupId }: UsePersonExtendedRequest,
  options?: UseQueryOptions<PersonTypes.Person>
) =>
  useQuery({
    queryKey: queryKeys.personExtended(personId, groupId),
    queryFn: () => PersonAPI.getPersonExtended(personId, groupId),
    ...defaultOptions,
    ...options,
    enabled: validateQueryEnabledWithDefault(!!personId, options?.enabled),
  });

type ListThreadScheduledMessagesIO = SchemaIO<(typeof SchemaManualSMSScheduledService)['GetThread']>;
export const useThreadScheduledMessages = (
  req: ListThreadScheduledMessagesIO['input'],
  options?: UseQueryOptions<ListThreadScheduledMessagesIO['output']>
) =>
  useQuery({
    queryKey: queryKeys.listThreadScheduledMessages(req.locationId ?? '', req.threadId ?? ''),
    queryFn: () => SchemaManualSMSScheduledService.GetThread(req),
    ...defaultOptions,
    ...options,
    enabled: validateQueryEnabledWithDefault(!!req.locationId && !!req.threadId, options?.enabled),
  });

type ListScheduledThreadsIO = SchemaIO<(typeof SchemaManualSMSScheduledService)['ListThreads']>;
export const listScheduledThreads = (
  req: ListScheduledThreadsIO['input'],
  options?: UseQueryOptions<ListScheduledThreadsIO['output']>
) =>
  useQuery({
    queryKey: queryKeys.listScheduledThreads(req),
    queryFn: () => SchemaManualSMSScheduledService.ListThreads(req),
    ...defaultOptions,
    ...options,
    enabled: validateQueryEnabledWithDefault(req.locationIds.length > 0, options?.enabled),
  });

type IsBlockedIO = SchemaIO<(typeof SchemaSMSBlockNumberService)['IsBlocked']>;
export const useIsBlocked = (req: IsBlockedIO['input'], options?: UseQueryOptions<IsBlockedIO['output']>) =>
  useQuery({
    queryKey: queryKeys.isBlocked(req.locationId, req.personPhone),
    queryFn: () => SchemaSMSBlockNumberService.IsBlocked(req),
    ...defaultOptions,
    ...options,
    enabled: validateQueryEnabledWithDefault(!!req.locationId && !!req.personPhone, options?.enabled),
  });

export const useCalculateThreadIds = (
  reqs: Omit<GetThreadIdIO['input'], 'calculateMissing'>[],
  options?: Omit<
    UseQueryOptions<
      GetThreadIdIO['output'],
      unknown,
      { locationId: string; personPhone: string; departmentId?: string; threadId: string }
    >,
    'select'
  >
) =>
  useQueries(
    reqs.map((req) => ({
      queryKey: queryKeys.getThreadId(req),
      queryFn: () => SchemaSMSService.LookupThreadId({ ...req, calculateMissing: true }),
      ...defaultOptions,
      ...options,
      select: (data: GetThreadIdIO['output']) => {
        return {
          locationId: req.locationId,
          personPhone: req.personPhone,
          departmentId: req.departmentId,
          threadId: data.threadId,
        };
      },
      enabled: validateQueryEnabledWithDefault(!!req.personPhone, options?.enabled),
    }))
  );

type SMSSearchV1IO = SchemaIO<(typeof SchemaSMSSearchService)['Search']>;
type SMSSearchV1PageParams =
  | {
      lastCreatedAt: string;
      lastSmsId: string;
    }
  | undefined;
export const useSMSSearchV1InfiniteQuery = <T = SMSSearchV1IO['output']>(
  req: SMSSearchV1IO['input'],
  options?: UseInfiniteQueryOptions<SMSSearchV1IO['output'], unknown, T>
) =>
  useInfiniteQuery({
    queryKey: queryKeys.smsSearchV1(req),
    queryFn: ({ pageParam }: QueryFunctionContext<string | readonly unknown[], SMSSearchV1PageParams>) =>
      SchemaSMSSearchService.Search({
        ...req,
        lastCreatedAt:
          pageParam?.lastCreatedAt || req.lastCreatedAt || new Date(new Date().getTime() + 5000).toISOString(),
        lastSmsId: pageParam?.lastSmsId || req.lastSmsId,
      }),
    getNextPageParam: (lastPage): SMSSearchV1PageParams => {
      const lastResult = lastPage.items.at(-1);
      const lastPageIsNotFull = req.limit === undefined ? !!lastPage.items.length : lastPage.items.length < req.limit;
      if (lastPageIsNotFull || !lastResult) return undefined;

      return {
        lastCreatedAt: lastResult.createdAt,
        lastSmsId: lastResult.smsId,
      };
    },
    ...defaultOptions,
    ...options,
  });

type SMSPhoneNumbersIO = SchemaIO<(typeof SchemaPhoneNumbersService)['ListSMSPhoneNumbers']>;
export const useLocationsSMSNumbers = <E = unknown>(
  locationIds: string[],
  options?: Omit<
    UseQueryOptions<SMSPhoneNumbersIO['output'], E, SMSPhoneNumbersIO['output'] & { locationId: string }>,
    'select'
  >
) =>
  useQueries(
    locationIds.map((locationId) => ({
      queryKey: queryKeys.locationSmsNumbers(locationId),
      queryFn: () =>
        SchemaPhoneNumbersService.ListSMSPhoneNumbers(
          {},
          {
            headers: {
              'Location-Id': locationId,
            },
          }
        ),
      select: (data: SMSPhoneNumbersIO['output']) => ({ ...data, locationId }),
      ...defaultOptions,
      ...options,
    }))
  );
