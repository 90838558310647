import { useEffect, useMemo } from 'react';
import { CallIntelMockData, CallIntelligenceApi } from '@frontend/api-analytics';
import { Chart, XAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { CallIntelSubViewId, useCallIntelStore } from '../../../hooks';
import { queryKeys } from '../../../query-keys';
import { callIntelligenceUtils, formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { OverviewSummaryOpportunitiesStack } from '../charts';
import { CustomTooltipTitle } from '../custom-tooltip-title';
import { SubViewOpportunitiesInlineStats } from './sub-view-opportunities-inline-stats';

type Props = {
  isDemoAccount?: boolean;
  metricsFor?: string;
  onChangeLoadingState?: (isLoading: boolean) => void;
  sentimentCode: CallIntelSubViewId;
};

const formatChartData = <T extends Record<string, any>>(data?: T | null) => {
  return {
    groups: Object.entries(data || {}).map(([name, value]) => ({
      name,
      values: {
        [name]: value,
      },
    })),
  };
};

export const OpportunitiesBySentimentsSubView = ({
  isDemoAccount,
  metricsFor,
  onChangeLoadingState,
  sentimentCode,
}: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { dataColors, dataLabels, filterHintText, filters, subView } = useCallIntelStore();

  const drillDownFilters = callIntelligenceUtils.getDrillDownFilterKeys(subView);
  const queryKey = queryKeys.callIntelligence(
    `sentiments-drill-down-${JSON.stringify(filters)}-${JSON.stringify(drillDownFilters)}`
  );

  const filteredOverviewSentimentsDrillDown = useMemo(() => {
    return CallIntelMockData.getFilteredOverviewSentimentsDrillDown(
      CallIntelMockData.overviewSentimentsDrillDown,
      filters
    );
  }, [filters]);

  const { data: aggregatedData, isFetching: isLoadingAggregatedData } = useScopedQuery({
    queryKey,
    queryFn: () =>
      isDemoAccount
        ? null
        : drillDownFilters
        ? CallIntelligenceApi.getOverviewDetails({ drillDownOptions: drillDownFilters, filters })
        : null,
    onError: (err) => {
      alert.error(t('Failed to fetch overview data'));
      console.error(err);
    },
    refetchOnWindowFocus: false,
    select: (data) => (isDemoAccount ? filteredOverviewSentimentsDrillDown : data),
    staleTime: 1000 * 60 * 5,
  });

  const chartData = useMemo(() => {
    return {
      appointmentTypes: formatChartData(aggregatedData?.appointmentTypes),
      category: formatChartData(aggregatedData?.categories),
    };
  }, [aggregatedData]);

  useEffect(() => {
    onChangeLoadingState?.(isLoadingAggregatedData);
  }, [isLoadingAggregatedData]);

  return (
    <div>
      <SubViewOpportunitiesInlineStats
        data={aggregatedData}
        isDemoAccount={isDemoAccount}
        isLoading={isLoadingAggregatedData}
        metricsFor={metricsFor}
        sentimentCode={sentimentCode}
      />
      <OverviewSummaryOpportunitiesStack
        data={aggregatedData}
        isDemoAccount={isDemoAccount}
        isLoading={isLoadingAggregatedData}
        sentimentCode={sentimentCode}
      />
      <Chart.HorizontalContainer marginBottom={0}>
        <Chart colors={dataColors.categories || {}} commonTooltipLabel={t('Calls')} labels={dataLabels.categories}>
          <Chart.Header
            expandable={false}
            leftElement={isDemoAccount ? <DemoChip /> : null}
            subtitle={filterHintText}
            title={t('{{name}} Call Categories', { name: dataLabels.sentiments?.[sentimentCode] })}
          />
          <Chart.BarChart
            appearance={{
              customTooltipTitle: ({ groupName }) => {
                return <CustomTooltipTitle suffix={dataLabels.categories?.[groupName]} />;
              },
              customXAxisTick: ({ labels, groupName, ...rest }) => {
                return (
                  <XAxisLabelValueTick
                    {...rest}
                    color={dataColors.categories?.[groupName]}
                    label={labels?.[groupName] || groupName}
                    value={formatters.value.format(
                      aggregatedData?.categories?.[groupName as keyof typeof aggregatedData.categories]
                    )}
                  />
                );
              },
              margin: { bottom: 20 },
              showXAxis: true,
            }}
            data={chartData.category}
            formatValue={formatters.value.format}
          />
        </Chart>

        <Chart
          colors={dataColors.appointmentTypes || {}}
          commonTooltipLabel={t('Calls')}
          labels={dataLabels.appointmentTypes}
        >
          <Chart.Header
            expandable={false}
            leftElement={isDemoAccount ? <DemoChip /> : null}
            subtitle={filterHintText}
            title={t('{{name}} Call Appointment Types', { name: dataLabels.sentiments?.[sentimentCode] })}
          />
          <Chart.BarChart
            appearance={{
              customTooltipTitle: ({ groupName }) => {
                return <CustomTooltipTitle suffix={dataLabels.appointmentTypes?.[groupName]} />;
              },
              customXAxisTick: ({ labels, groupName, ...rest }) => {
                return (
                  <XAxisLabelValueTick
                    {...rest}
                    color={dataColors.appointmentTypes?.[groupName]}
                    label={labels?.[groupName] || groupName}
                    value={formatters.value.format(
                      aggregatedData?.appointmentTypes?.[groupName as keyof typeof aggregatedData.appointmentTypes]
                    )}
                  />
                );
              },
              margin: { bottom: 20 },
              showXAxis: true,
            }}
            data={chartData.appointmentTypes}
            formatValue={formatters.value.format}
          />
        </Chart>
      </Chart.HorizontalContainer>
    </div>
  );
};
