import { css } from '@emotion/react';
import { Checkbox, Radio } from '../../../';
import { useControlledField } from '../../../';
import { TableData } from '../../table-data-type';
import { Row } from 'react-table';

type SelectRowCellProps<T extends TableData> = {
  onRowSelect?: (row: Row<T>) => void;
  rowObject: Row<T>;
  isSingleSelect?: boolean;
};

export const SelectRowCell = <T extends TableData>({
  rowObject,
  isSingleSelect,
  onRowSelect,
}: SelectRowCellProps<T>) => {
  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      `}
    >
      {isSingleSelect ? (
        <RadioSelect rowObject={rowObject} onRowSelect={onRowSelect} />
      ) : (
        <CheckboxSelect rowObject={rowObject} onRowSelect={onRowSelect} />
      )}
    </div>
  );
};

export const RadioSelect = <T extends TableData>({
  rowObject,
  onRowSelect,
}: {
  rowObject: Row<T>;
  onRowSelect?: (row: Row<T>) => void;
}) => {
  const { toggleRowSelected, isSelected, isDisabled } = rowObject;
  const selectRowCheckboxProps = useControlledField({
    type: 'radio',
    value: 'radio-select',
    onChange: () => toggleRowSelected(),
  });

  const { value: _, ...rest } = selectRowCheckboxProps;

  return (
    <Radio
      disabled={!!isDisabled}
      name='radio-select'
      tabIndex={0}
      checked={isSelected}
      {...rest}
      onChange={(e) => {
        rest.onChange(e);
        onRowSelect?.(rowObject);
      }}
    />
  );
};

export const CheckboxSelect = <T extends TableData>({
  rowObject,
  onRowSelect,
}: {
  rowObject: Row<T>;
  onRowSelect?: (row: Row<T>) => void;
}) => {
  const { toggleRowSelected, isSelected, isDisabled } = rowObject;
  const selectRowCheckboxProps = useControlledField({
    type: 'checkbox',
    value: isSelected,
    onChange: () => {
      toggleRowSelected();
      onRowSelect?.(rowObject);
    },
  });

  return <Checkbox disabled={!!isDisabled} name='check' {...selectRowCheckboxProps} error={false} />;
};
