import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';

interface Props extends ModalControlModalProps {
  isFax?: boolean;
}

export const NumberNotPortableInfoModal = ({ isFax, ...modalProps }: Props) => {
  const { t } = useTranslation('onboarding');

  const numberTypeText = isFax ? t('Fax') : t('Phone');

  return (
    <Modal {...modalProps} maxWidth={500}>
      <Modal.Header onClose={modalProps.onClose}>{t('Number Not Portable')}</Modal.Header>
      <Modal.Body>
        <Text css={{ margin: theme.spacing(1, 0) }}>
          {t(
            'The {{numberType}} number you listed for Porting is not able to port over to Weave. Please reach out to support regarding this {{numberType}} number to see if there is anything we can do on our end to help.',
            {
              numberType: numberTypeText,
            }
          )}
        </Text>
      </Modal.Body>
      <Modal.Actions primaryLabel={t('Close')} onPrimaryClick={modalProps.onClose} />
    </Modal>
  );
};
