import { useTranslation } from '@frontend/i18n';
import { HistoryQueries } from '@frontend/api-messaging';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from '@tanstack/react-location';
import dayjs from 'dayjs';
import {
  DatePickerField,
  Heading,
  ModalControlModalProps,
  MultiselectField,
  TableFilters,
  useForm,
} from '@frontend/design-system';
import {
  Appointment,
  AppointmentFilterOption_FilterType,
  ListAppointmentsRequest,
} from '@weave/schema-gen-ts/dist/schemas/messaging/etl/history/v1/service.pb';
import { renderMultiSelectOptions } from './render-multiselect';
import { RecordTable } from './record-table';

type TableFiltersProps = {
  modalProps: ModalControlModalProps;
  setShowNotificationBadge: (showNotification: boolean) => void;
  handleFiltersUpdate: (filters: Partial<ListAppointmentsRequest>) => void;
  handlePageUpdate: (page: number) => void;
  currentFilters: ListAppointmentsRequest;
};

export const AppointmentsListTable = () => {
  const { t } = useTranslation('messages');
  const navigate = useNavigate();

  const [requestParams, setRequestParams] = useState<ListAppointmentsRequest>({
    pageSize: 100,
  });
  const [page, setPage] = useState<number>(1);

  const handleFiltersUpdate = (newFilters: Partial<ListAppointmentsRequest>) => {
    setRequestParams({
      pageBoundaryAt: '',
      pageBoundaryId: '',
      pageDirection: undefined,
      ...requestParams,
      ...newFilters,
    });
    setPage(1);
  };

  const { data: appointmentsData = [], isLoading, isFetching } = HistoryQueries.useListAppointments(requestParams);

  return (
    <>
      <Heading level={2}>{t('Appointments')}</Heading>
      <RecordTable<Appointment, ListAppointmentsRequest>
        colConfig={[
          {
            Header: t('Appointment Id'),
            id: 'appointmentId',
            width: 80,
            accessor: (data) => data.appointmentId,
            disableSortBy: true,
            cellConfig: {
              element: 'TextLink',
              onClick: (_: unknown, record) => {
                const recordId = record.appointmentId;
                navigate({ to: `/portal/messages/record-history/appointment/${recordId}` });
              },
            },
          },
          {
            Header: t('Appointment At'),
            id: 'appointmentAt',
            width: 80,
            accessor: (data) => (data.appointmentAt ? dayjs(data.appointmentAt).format('YYYY/MM/DD, h:mm A') : ''),
            disableSortBy: true,
          },
          {
            Header: t('Modified At'),
            id: 'modifiedAt',
            width: 80,
            accessor: (data) => (data.modifiedAt ? dayjs(data.modifiedAt).format('YYYY/MM/DD, h:mm A') : ''),
            disableSortBy: true,
          },
          {
            Header: t('Status'),
            id: 'status',
            width: 80,
            accessor: (data) => data.status,
            disableSortBy: true,
          },
          {
            Header: t('Type'),
            id: 'type',
            width: 60,
            accessor: (data) => (data.type && Array.isArray(data.type) ? data.type.join(' ') : data.type),
            disableSortBy: true,
          },
          {
            Header: t('Person Id'),
            id: 'personId',
            width: 80,
            accessor: (data) => data.personId,
            disableSortBy: true,
          },
        ]}
        data={appointmentsData}
        isLoading={isLoading || isFetching}
        manualFiltersRender={(modalProps, setShowNotification) => (
          <AppointmentsListTableFilters
            modalProps={modalProps}
            setShowNotificationBadge={setShowNotification}
            currentFilters={requestParams}
            handleFiltersUpdate={handleFiltersUpdate}
            handlePageUpdate={setPage}
          />
        )}
        page={page}
        setPage={setPage}
        requestParams={requestParams}
        setRequestParams={setRequestParams}
      />
    </>
  );
};

const AppointmentsListTableFilters = ({
  modalProps,
  setShowNotificationBadge,
  currentFilters,
  handleFiltersUpdate,
  handlePageUpdate,
}: TableFiltersProps) => {
  type AppointmentsListFilterFields = ReturnType<typeof getFieldProps>['name'];

  const { t } = useTranslation('messages');

  const {
    data: filterOptions = [],
    isLoading: filterOptionsIsLoading,
    isFetching: filterOptionsIsFetching,
  } = HistoryQueries.useAppointmentFilterOptions();

  const { getFieldProps, seedValues, formProps } = useForm({
    fields: {
      pageBoundaryAt: {
        type: 'datePicker',
      },
      statuses: {
        type: 'multiselect',
      },
      types: {
        type: 'multiselect',
      },
      operatories: {
        type: 'multiselect',
      },
      practitioners: {
        type: 'multiselect',
      },
      sourceTenantIds: {
        type: 'multiselect',
      },
    },
    onSubmit: (data) => {
      if (data.pageBoundaryAt) {
        data.pageBoundaryAt = dayjs(data.pageBoundaryAt).toISOString();
      }
      handleFiltersUpdate(data);
      handlePageUpdate(1);
    },
  });

  const resetIndividualFilter = (id: AppointmentsListFilterFields) => {
    seedValues({
      [id]: [],
    });
    handleFiltersUpdate({ [id]: [] });
  };

  const resetPageBoundaryAt = () => {
    seedValues({
      pageBoundaryAt: '',
    });
    handleFiltersUpdate({ pageBoundaryAt: '' });
  };

  useEffect(() => {
    const { pageSize, ...restOfFilters } = currentFilters;
    const hasFiltersActive = Object.values(restOfFilters).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });
    setShowNotificationBadge(!hasFiltersActive);
  }, [currentFilters]);

  const fieldValues = useMemo(() => {
    const filterOptionsByType = filterOptions?.find(
      ({ filterType }) => filterType === AppointmentFilterOption_FilterType.TYPE
    );
    const filterOptionsByStatus = filterOptions?.find(
      ({ filterType }) => filterType === AppointmentFilterOption_FilterType.STATUS
    );
    const filterOptionsByPractitioner = filterOptions?.find(
      ({ filterType }) => filterType === AppointmentFilterOption_FilterType.PRACTITIONER
    );
    const filterOptionsByOperatory = filterOptions?.find(
      ({ filterType }) => filterType === AppointmentFilterOption_FilterType.OPERATORY
    );
    const filterOptionsBySourceTenantId = filterOptions?.find(
      ({ filterType }) => filterType === AppointmentFilterOption_FilterType.SOURCE_TENANT
    );

    return {
      types: Object.values(filterOptionsByType?.filterOptions ?? {}).filter(Boolean),
      statuses: Object.values(filterOptionsByStatus?.filterOptions ?? {}).filter(Boolean),
      practitioners: Object.values(filterOptionsByPractitioner?.filterOptions ?? {}).filter(Boolean),
      operatories: Object.values(filterOptionsByOperatory?.filterOptions ?? {}).filter(Boolean),
      sourceTenantNames: Object.values(filterOptionsBySourceTenantId?.filterOptions ?? {}).filter(Boolean),
      sourceTenantIds: Object.keys(filterOptionsBySourceTenantId?.filterOptions ?? {}).filter(Boolean),
    };
  }, [filterOptions]);

  if (filterOptionsIsLoading || filterOptionsIsFetching) return null;

  return (
    <TableFilters
      {...modalProps}
      formProps={formProps}
      onApplyClick={() => {
        setShowNotificationBadge(true);
      }}
    >
      <TableFilters.Section
        disableClear={!currentFilters['pageBoundaryAt']}
        sectionHeaderLabel={t('Date')}
        onClearClick={() => resetPageBoundaryAt()}
      >
        <DatePickerField label={t('Date')} {...getFieldProps('pageBoundaryAt')} />
      </TableFilters.Section>
      <TableFilters.Section
        disableClear={!currentFilters['statuses'] || currentFilters['statuses'].length === 0}
        sectionHeaderLabel={t('Status')}
        onClearClick={() => resetIndividualFilter('statuses')}
      >
        <MultiselectField {...getFieldProps('statuses')} label={t('Status')} placeholder={t('Status')}>
          {renderMultiSelectOptions(fieldValues.statuses)}
        </MultiselectField>
      </TableFilters.Section>
      <TableFilters.Section
        disableClear={!currentFilters['types'] || currentFilters['types'].length === 0}
        sectionHeaderLabel={t('Appointment Type')}
        onClearClick={() => resetIndividualFilter('types')}
      >
        <MultiselectField {...getFieldProps('types')} label={t('Appointment Type')} placeholder={t('Appointment Type')}>
          {renderMultiSelectOptions(fieldValues.types)}
        </MultiselectField>
      </TableFilters.Section>
      <TableFilters.Section
        disableClear={!currentFilters['operatories'] || currentFilters['operatories'].length === 0}
        sectionHeaderLabel={t('Operatory')}
        onClearClick={() => resetIndividualFilter('operatories')}
      >
        <MultiselectField {...getFieldProps('operatories')} label={t('Operatory')} placeholder={t('Operatory')}>
          {renderMultiSelectOptions(fieldValues.operatories)}
        </MultiselectField>
      </TableFilters.Section>
      <TableFilters.Section
        disableClear={!currentFilters['practitioners'] || currentFilters['practitioners'].length === 0}
        sectionHeaderLabel={t('Practitioner')}
        onClearClick={() => resetIndividualFilter('practitioners')}
      >
        <MultiselectField {...getFieldProps('practitioners')} label={t('Practitioner')} placeholder={t('Practitioner')}>
          {renderMultiSelectOptions(fieldValues.practitioners)}
        </MultiselectField>
      </TableFilters.Section>
      <TableFilters.Section
        disableClear={!currentFilters['sourceTenantIds'] || currentFilters['sourceTenantIds'].length === 0}
        sectionHeaderLabel={t('Tenant Name')}
        onClearClick={() => resetIndividualFilter('sourceTenantIds')}
      >
        <MultiselectField {...getFieldProps('sourceTenantIds')} label={t('Tenant Name')} placeholder={t('Tenant Name')}>
          {renderMultiSelectOptions(fieldValues.sourceTenantNames, fieldValues.sourceTenantIds)}
        </MultiselectField>
      </TableFilters.Section>
    </TableFilters>
  );
};
