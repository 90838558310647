import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { uniqueId, isEqual } from 'lodash-es';
import { useQuery } from 'react-query';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { ScheduleRequestsApi, ScheduleTypes, ScheduleDefaults } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Tabs, useModalControl, useAlert, SpinningLoader, styles } from '@frontend/design-system';
import { CustomFieldEditor } from '../../components/CustomFieldsEditor/CustomFieldEditor';
import { CustomFieldsButtonFooter } from './CustomFieldsButtonFooter';
import { CustomFieldsIllustrationContainer } from './CustomFieldsIllustrationContainer';
import { CustomFieldsLocationSelector } from './CustomFieldsLocationSelector';
import { FieldList } from './FieldList';
import { PreviewForm } from './PreviewForm';

export function CustomFields() {
  const { t } = useTranslation('schedule');
  const { success, warning } = useAlert();
  const isMedium = useMatchMedia({ minWidth: breakpoints.medium.min });
  const { selectedLocationIds } = useAppScopeStore();
  const isMultiLocation = selectedLocationIds.length > 1;
  const [selectedLocationId, setSelectedLocationId] = useState<string>(!isMultiLocation ? selectedLocationIds[0] : '');

  const { isLoading: isLoadingCustomizationFlags, locationIdWiseCustomizationFlagDetails } =
    CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
      locationIds: selectedLocationIds,
      enabled: isMultiLocation,
      customizationFlag: Feature.ONLINE_SCHEDULING,
    });

  const locationIds =
    Object.keys(locationIdWiseCustomizationFlagDetails)?.filter(
      (locationId) => locationIdWiseCustomizationFlagDetails[locationId]
    ) || selectedLocationIds;

  const modalControl = useModalControl();
  const {
    data = { locationId: selectedLocationId, overrideFields: ScheduleDefaults.overrideFields, customFields: [] },
    refetch: refetchCustomFields,
  } = useQuery({
    queryKey: ['custom-fields', selectedLocationId],
    enabled: !!selectedLocationId,
    queryFn: () => ScheduleRequestsApi.getCustomFields(selectedLocationId),
    cacheTime: 5 * 60 * 1000, // 5 minutes
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const [mode, setMode] = useState<'Create' | 'Update'>('Create');
  const [saving, setSaving] = useState<boolean>(false);
  const [customFields, setCustomFields] = useState<ScheduleTypes.CustomField[]>([]);
  const [overrideFields, setOverrideFields] = useState<
    Record<keyof ScheduleTypes.PatientInfo, ScheduleTypes.CustomField>
  >(ScheduleDefaults.overrideFields);
  const [selectedField, setSelectedField] = useState<Partial<ScheduleTypes.CustomField>>({});
  const [previewForm, setPreviewForm] = useState<Partial<ScheduleTypes.PatientInfo>>({});

  useEffect(() => {
    const formValuesJSON = localStorage.getItem('custom-fields-preview');
    if (formValuesJSON) {
      const newPreviewForm = JSON.parse(formValuesJSON);
      if (!isEqual(newPreviewForm, previewForm)) {
        setPreviewForm(JSON.parse(formValuesJSON));
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('custom-fields-preview', JSON.stringify(previewForm));
  }, [previewForm]);

  useEffect(() => {
    setSelectedLocationId(!isMultiLocation ? selectedLocationIds[0] : '');
  }, [selectedLocationIds, isMultiLocation]);

  const saveData = async (
    locationId: string,
    originalData: ScheduleTypes.CustomFieldsResponse,
    customFields: ScheduleTypes.CustomField[],
    overrideFields: Record<keyof ScheduleTypes.PatientInfo, ScheduleTypes.CustomField>
  ) => {
    try {
      setSaving(true);
      const response = await ScheduleRequestsApi.upsertCustomFields(locationId, {
        ...originalData,
        customFields,
        overrideFields,
      });
      setSaving(false);
      if (response?.locationId === locationId) {
        success({ message: t('Custom fields have been saved!') });
      } else {
        warning({ message: t('Could not save custom fields. Please try again.') });
      }
    } catch (error) {
      console.error('error while creating custom fields', error);
      warning({ message: t('Could not save custom fields. Please try again.') });
    } finally {
      setSaving(false);
      refetchCustomFields();
    }
  };

  const handleSave = useCallback(() => {
    if (selectedLocationId) {
      saveData(selectedLocationId, data, customFields, overrideFields);
    }
  }, [selectedLocationId, data, customFields, overrideFields]);

  const handleReset = useCallback(() => {
    setCustomFields([]);
    setOverrideFields(ScheduleDefaults.overrideFields);
  }, [ScheduleDefaults.overrideFields]);

  useEffect(() => {
    if (selectedLocationId) {
      setCustomFields(data?.customFields || []);
      setOverrideFields(data?.overrideFields || ScheduleDefaults.overrideFields);
    }
  }, [data?.customFields, data?.overrideFields, selectedLocationId]);

  const handleEditField = (field: ScheduleTypes.CustomField) => {
    setMode('Update');
    setSelectedField(field);
    modalControl.triggerProps.onClick();
  };

  const handleToggleShow = (field: ScheduleTypes.CustomField) => {
    if (field.custom) {
      handleUpdateField({ ...field, show: !field.show });
      return;
    }
    setOverrideFields((fields) => {
      const updateFields = { ...fields };
      updateFields[field.key as keyof ScheduleTypes.PatientInfo] = { ...field, show: !field.show };
      return updateFields;
    });
  };

  const handleUpdateField = (field: ScheduleTypes.CustomField) => {
    if (field.custom) {
      setCustomFields((fields) => {
        const newFields = [...fields];
        const index = fields.findIndex((f) => f.id === field.id);
        if (index > -1) {
          newFields[index] = field;
        } else {
          newFields.push(field);
        }
        return newFields;
      });
    } else {
      setOverrideFields((fields) => {
        return { ...fields, [field.key]: field };
      });
    }
  };

  const handleAddField = () => {
    setMode('Create');
    setSelectedField({
      id: uniqueId(),
      hideable: true,
      custom: true,
    });
    modalControl.triggerProps.onClick();
  };

  const handleDelete = (field: ScheduleTypes.CustomField) => {
    setCustomFields((fields) => {
      return fields.filter((f) => f.id !== field.id);
    });
  };

  const handleUpdateForm = (values: ScheduleTypes.PatientInfo) => {
    setPreviewForm({ ...previewForm, ...values });
  };

  if (isLoadingCustomizationFlags) {
    return (
      <div css={styles.flexCenter}>
        <SpinningLoader />
      </div>
    );
  }

  return (
    <>
      {isMedium ? (
        <div css={customFieldsFormContainerStyles}>
          {selectedLocationIds.length > 1 && (
            <div css={{ padding: theme.spacing(2, 3), borderBottom: `thin solid ${theme.colors.neutral20}` }}>
              <CustomFieldsLocationSelector
                selectedLocationId={selectedLocationId}
                setSelectedLocationId={setSelectedLocationId}
                locationIds={locationIds}
              />
            </div>
          )}
          {selectedLocationId ? (
            <>
              <div
                css={css({
                  display: 'flex',
                  width: '100%',
                })}
              >
                <div
                  css={css({
                    minWidth: 300,
                    width: `calc(40% - ${theme.spacing(3)})`,
                    padding: theme.spacing(3),
                    borderRight: `thin solid ${theme.colors.neutral20}`,
                  })}
                >
                  <FieldList
                    customFields={customFields}
                    overrideFields={overrideFields}
                    onAddField={handleAddField}
                    onToggleShowField={handleToggleShow}
                    onDelete={handleDelete}
                    onEditField={handleEditField}
                  />
                </div>
                <div css={css({ width: `calc(60% - ${theme.spacing(3)})`, padding: theme.spacing(2) })}>
                  <PreviewForm
                    form={previewForm}
                    onUpdateForm={handleUpdateForm}
                    customFields={customFields}
                    overrideFields={overrideFields}
                  />
                </div>
              </div>
              <CustomFieldsButtonFooter isSaving={saving} onSave={handleSave} onReset={handleReset} />
            </>
          ) : (
            <CustomFieldsIllustrationContainer />
          )}
        </div>
      ) : (
        <>
          {selectedLocationIds.length > 1 && (
            <div css={{ padding: theme.spacing(2, 0) }}>
              <CustomFieldsLocationSelector
                selectedLocationId={selectedLocationId}
                setSelectedLocationId={setSelectedLocationId}
                locationIds={locationIds}
              />
            </div>
          )}
          {selectedLocationId ? (
            <Tabs initialTab='field-list-tab'>
              <Tabs.Bar>
                <Tabs.Tab id='field-list-tab' controls='field-list-panel'>
                  {t('Field List')}
                </Tabs.Tab>
                <Tabs.Tab id='preview-form-tab' controls='preview-form-panel'>
                  {t('Preview Form')}
                </Tabs.Tab>
              </Tabs.Bar>

              <Tabs.Panel id='field-list-panel' controller='field-list-tab' css={css({ padding: theme.spacing(3, 0) })}>
                <FieldList
                  customFields={customFields}
                  overrideFields={overrideFields}
                  onAddField={handleAddField}
                  onToggleShowField={handleToggleShow}
                  onDelete={handleDelete}
                  onEditField={handleEditField}
                />
              </Tabs.Panel>
              <Tabs.Panel
                id='preview-form-panel'
                controller='preview-form-tab'
                css={css({ padding: theme.spacing(3, 0) })}
              >
                <PreviewForm
                  fullsize={true}
                  form={previewForm}
                  onUpdateForm={handleUpdateForm}
                  customFields={customFields}
                  overrideFields={overrideFields}
                />
              </Tabs.Panel>
              <CustomFieldsButtonFooter isSaving={saving} onSave={handleSave} onReset={handleReset} />
            </Tabs>
          ) : (
            <div css={{ borderRadius: theme.spacing(1), boxShadow: theme.shadows.light, padding: theme.spacing(2) }}>
              <CustomFieldsIllustrationContainer />
            </div>
          )}
        </>
      )}

      <CustomFieldEditor mode={mode} modalControl={modalControl} field={selectedField} onChange={handleUpdateField} />
    </>
  );
}

const customFieldsFormContainerStyles = css({
  boxShadow: theme.shadows.light,
  borderRadius: theme.borderRadius.small,
  backgroundColor: theme.colors.white,
});
