import { useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton, PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';
import { useCalendarEventsConfigurationShallowStore } from '../../../hooks';
import { useAppointmentsInfoShallowStore } from '../../../hooks/use-appointments-info-store';
import { CalendarEventsEnums } from '../../../views/Calendar/types';
import { isUUID } from '../../../views/Calendar/utils';

interface Props {
  eventId: string;
  eventTitle: string;
  providerId: string;
  providerName: string;
}

export const AppointmentEventActionMenu = ({ eventId, eventTitle, providerId, providerName }: Props) => {
  const { t } = useTranslation('scheduleCalendarEvents');
  const { appointments, selectedDate } = useAppointmentsInfoShallowStore('appointments', 'selectedDate');
  const { setConfiguredCalendarEvent } = useCalendarEventsConfigurationShallowStore('setConfiguredCalendarEvent');

  const popoverMenuProps = usePopoverMenu({ placement: 'right' });

  const appointmentInfo = useMemo(() => {
    return appointments?.find((appointment) => appointment.id === eventId);
  }, [eventId, appointments]);

  const handleEventClick = () => {
    if (!appointmentInfo) {
      return;
    }
    const startDate = dayjs(appointmentInfo.start);
    const endDate = dayjs(appointmentInfo.start).add(appointmentInfo.duration ?? 0, 'm');

    setConfiguredCalendarEvent({
      eventId,
      isValid: true,
      calendarDateValue: selectedDate as string,
      locationId: appointmentInfo.locationId ?? '',
      calendarEventType: CalendarEventsEnums.APPOINTMENT_EVENT,
      startDate: formatDate(startDate, 'MM/DD/YYYY'),
      endDate: formatDate(endDate, 'MM/DD/YYYY'),
      startHour: formatDate(startDate, 'hh:mm A'),
      endHour: formatDate(endDate, 'hh:mm A'),
      providerId: providerId,
      providerName: providerName,
      name: eventTitle,
      patientId: appointmentInfo.person?.personId ?? '',
      createdBySourceId: appointmentInfo.createdBySourceId ?? '',
      workstationIds: appointmentInfo.workstationIds ?? [],
      appointmentType: appointmentInfo.type,
      isNonIntegratedSource: appointmentInfo.isNonIntegratedSource ?? true,
    });
  };

  const isValidAppointment = appointmentInfo && (appointmentInfo?.isNonIntegratedSource ?? true) && isUUID(providerId);

  if (!isValidAppointment) {
    return null;
  }

  return (
    <>
      <IconButton css={iconButtonStyles} {...popoverMenuProps.getTriggerProps()} label={t('More Icon')}>
        <Icon name='more-small' />
      </IconButton>
      <PopoverMenu {...popoverMenuProps.getMenuProps()}>
        <PopoverMenuItem {...popoverMenuProps.getItemProps({ index: 0, onClick: handleEventClick })}>
          <Icon name='calendar-small' />
          {t('Manage Appointment')}
        </PopoverMenuItem>
      </PopoverMenu>
    </>
  );
};

const iconButtonStyles = css({
  padding: theme.spacing(0.15),
  minWidth: 'auto',
  height: 'auto',
  width: 'auto',
});
