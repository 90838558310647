import { MessagesTypes } from '@frontend/api-messaging';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { ItemContent } from 'react-virtuoso';
import { ThreadListVirtuosoContext } from '../types';
import { TimeDividerRow } from '@frontend/integrated-messaging';
import { SmsVirtuosoItem } from './sms-virtuoso-item';
import { ScheduledSmsVirtuosoItem } from './scheduled-sms-virtuoso-item';
import { theme } from '@frontend/theme';

export const VirtuosoItemWrapper: ItemContent<MessagesTypes.DateList | ScheduledSms, ThreadListVirtuosoContext> = (
  _index,
  item,
  context
) => {
  if (typeof item === 'string') {
    return (
      <TimeDividerRow
        timestamp={new Date(item)}
        css={context.bulkSelectionIsActive ? { padding: theme.spacing(1.5, 0, 4) } : undefined}
      />
    );
  }

  if ('sendAt' in item) {
    return <ScheduledSmsVirtuosoItem message={item} context={context} />;
  }

  return <SmsVirtuosoItem message={item} context={context} />;
};
