import { InvoiceModel } from '@frontend/api-invoices';
import { PersonAPI } from '@frontend/api-person';

export const useInvoicePerson = (invoice?: InvoiceModel) => {
  const locationId = invoice?.locationId;
  const personId = invoice?.person.id;

  const { data: person } = PersonAPI.usePersonExtended({
    personId: personId ?? '',
    locationId,
  });
  return person;
};
