import {
  CheckboxField,
  ChecklistField,
  Chip,
  ModalControlModalProps,
  Text,
  TextLink,
  useControlledField,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { FC, useEffect, useState } from 'react';
import { SelfUpgradeBaseModal } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeBaseModal';
import { FORMS_DIGITIZATION_FEE, FORMS_PRICE } from './constants';
import { FormsDigitizationModalTrackingIds } from './tracking-ids';

interface FormsDigitizationOptionModalProps extends ModalControlModalProps {
  isLoading: boolean;
  locationIds: string[];
  onTermsClick: () => void;
  onUpgradeClick: (selectedLocationIds: string[]) => void;
}

const DIGITIZATION_LINK =
  'https://www.weavehelp.com/hc/en-us/articles/6954019502107-Upload-Sample-Forms-into-Digital-Forms';

export const FormsDigitizationOptionModal: FC<FormsDigitizationOptionModalProps> = ({
  isLoading,
  locationIds,
  onTermsClick,
  onUpgradeClick,
  ...modalProps
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'digitization-selection-modal' });
  const { accessibleLocationData } = useAppScopeStore();

  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>(locationIds);

  const locationSelectProps = useControlledField({
    type: 'checklist',
    value: selectedLocationIds,
    onChange: setSelectedLocationIds,
  });

  const selectAllProps = useControlledField({
    type: 'checkbox',
    value: selectAllChecked,
    onChange: setSelectAllChecked,
  });

  useEffect(() => {
    setSelectedLocationIds(selectAllChecked ? locationIds : []);
  }, [selectAllChecked, locationIds]);

  return (
    <SelfUpgradeBaseModal
      maxWidth={800}
      onSecondaryAction={modalProps.onClose}
      secondaryActionLabel={t('Cancel')}
      secondaryActionTrackingId={FormsDigitizationModalTrackingIds.dismissAction}
      primaryActionLabel={t('Confirm Upgrade')}
      primaryActionTrackingId={FormsDigitizationModalTrackingIds.upgradeAction}
      onPrimaryAction={() => onUpgradeClick(selectedLocationIds)}
      onTermsClick={onTermsClick}
      isLoading={isLoading}
      {...modalProps}
    >
      <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1), width: 600 }}>
        <Text weight='bold' size='large'>
          {t('Additional Options')}
        </Text>
        <Text size='medium'>
          {t('Forms Digitization (${{amount}} one time payment per location)', { amount: FORMS_DIGITIZATION_FEE })}{' '}
          <TextLink href={DIGITIZATION_LINK} target='_blank' rel='noreferrer'>
            {t('Learn More')}
          </TextLink>
        </Text>
      </div>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <CheckboxField name={'all-locations-check'} label={t('All locations')} {...selectAllProps} />
        <ChecklistField {...locationSelectProps} name='location-select' label='' disabled={selectAllChecked}>
          {locationIds.map((locationId) => {
            return (
              <ChecklistField.Option key={locationId} name={locationId}>
                <Chip.SingleChip css={{ maxWidth: theme.spacing(30), backgroundColor: theme.colors.neutral10 }}>
                  {accessibleLocationData[locationId]?.name}
                </Chip.SingleChip>
              </ChecklistField.Option>
            );
          })}
        </ChecklistField>
        <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <Text weight='bold' size='medium'>
            {t('${{amount}} one time payment', { amount: FORMS_DIGITIZATION_FEE * selectedLocationIds.length })}
          </Text>
          <Text weight='bold' size='medium'>
            {t('${{amount}}/mo', { amount: FORMS_PRICE * locationIds.length })}
          </Text>
        </div>
      </div>
    </SelfUpgradeBaseModal>
  );
};
