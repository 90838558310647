import { useTranslation } from '@frontend/i18n';
import { PrimaryButton, SecondaryButton } from '@frontend/design-system';
import { usePaymentRequestQuickFilter } from '../../../hooks';
import { quickFilterStyles } from '../../../styles';

export const QuickFilters = () => {
  const { t } = useTranslation('payments');
  const { isFilterActive, handleQuickFilter } = usePaymentRequestQuickFilter();

  const buttonsConfig = [
    {
      label: t('All Invoices'),
      variant: isFilterActive('allInvoices') ? 'primary' : 'secondary',
      onClick: () => handleQuickFilter('allInvoices'),
    },
    {
      label: t('Paid'),
      variant: isFilterActive('paid') ? 'primary' : 'secondary',
      onClick: () => handleQuickFilter('paid'),
    },
    {
      label: t('Refunded'),
      variant: isFilterActive('refunded') ? 'primary' : 'secondary',
      onClick: () => handleQuickFilter('refunded'),
    },
    {
      label: t('Outstanding'),
      variant: isFilterActive('unpaid') ? 'primary' : 'secondary',
      onClick: () => handleQuickFilter('unpaid'),
    },
  ];

  return (
    <div css={quickFilterStyles.buttonContainer}>
      {buttonsConfig.map(({ label, variant, onClick }) => {
        const Button = variant === 'primary' ? PrimaryButton : SecondaryButton;
        return (
          <Button size='small' onClick={onClick} css={quickFilterStyles.quickFilterButton} key={label}>
            {label}
          </Button>
        );
      })}
    </div>
  );
};
