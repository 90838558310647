import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useMatchMedia, breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { NakedButton, Text, TimeRangeField, useFormField, useTooltip } from '@frontend/design-system';
import * as styles from './styles';

type ScheduleBreakProps = {
  startTime: string;
  endTime: string;
  officeTimeRange: string[];
  onChange: (timeRange: string[]) => void;
  onDelete: () => void;
  validateBreaks: (startTime: string, endTime: string) => string;
};

export function ScheduleBreak({
  startTime,
  endTime,
  onChange,
  onDelete,
  validateBreaks,
  officeTimeRange,
}: ScheduleBreakProps) {
  const { t } = useTranslation('schedule');
  const isMobile = useMatchMedia({ maxWidth: breakpoints.xsmall.max });
  const [breakError, setBreakError] = useState<string>('');
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();

  const { error: fieldError, ...timeRangeField } = useFormField(
    {
      type: 'timeRange',
      value: [startTime, endTime],
      interval: 15,
      minTime: officeTimeRange[0],
      maxTime: officeTimeRange[1],
    },
    [startTime, endTime, officeTimeRange]
  );

  useEffect(() => {
    if (timeRangeField?.value?.length) {
      const [startTime, endTime] = timeRangeField.value;
      onChange(timeRangeField.value);
      setBreakError(validateBreaks(startTime, endTime));
    }
  }, [timeRangeField?.value]);

  const error = fieldError || breakError;

  return (
    <div css={!isMobile ? scheduleBreakContainer : scheduleBreakContainerMobile}>
      {!isMobile && (
        <label css={breakLabelStyles}>
          <Text as='span'>{t('Break')}</Text>
        </label>
      )}
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <TimeRangeField
          {...timeRangeField}
          css={{ backgroundColor: theme.colors.white }}
          error=''
          name='breakTimeRange'
          label=''
          onBlur={(e) => {
            timeRangeField.onBlur(e);
            onChange(timeRangeField.value);
          }}
        />

        <NakedButton {...triggerProps} css={deleteButtonStyles} size='small' name='DeleteIcon' onClick={onDelete}>
          <Icon size={16} name='trash' />
        </NakedButton>

        <Tooltip {...tooltipProps}>
          <Text as='span' color='white'>
            {t('Delete Break')}
          </Text>
        </Tooltip>
      </div>

      {error && (
        <Text css={styles.errorStyle} color='error'>
          {error}
        </Text>
      )}
    </div>
  );
}

const breakLabelStyles = css({ width: 'fit-content', marginLeft: theme.spacing(5) });

const deleteButtonStyles = css({
  marginLeft: theme.spacing(2),
  width: 16,
  height: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const scheduleBreakContainer = css({
  display: 'grid',
  backgroundColor: theme.colors.neutral5,
  gridTemplateColumns: 'minmax(auto, 50%) 1fr',
  alignItems: 'center',
  padding: theme.spacing(1.5, 4),
  width: '100%',
});

const scheduleBreakContainerMobile = css({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.colors.neutral5,
  marginBottom: theme.spacing(2),
});
