import { FC, useState, ReactNode } from 'react';
import { PrimaryButton, SpinningLoader } from '@frontend/design-system';

interface ButtonWithLoaderProps {
  onClick: () => Promise<void>;
  title: ReactNode;
}

export const ButtonWithLoader: FC<ButtonWithLoaderProps> = ({ onClick, title, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onButtonClick = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <PrimaryButton disabled={isLoading} onClick={onButtonClick} {...rest}>
      {isLoading ? <SpinningLoader size='small' /> : title}
    </PrimaryButton>
  );
};
