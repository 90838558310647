import { MediaApi, MediaQueries, MediaTypes } from '@frontend/api-media';
import { useDraft } from '@frontend/api-sms-draft';
import { getUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { genUUIDV4 } from '@frontend/string';
import { Media } from '@weave/schema-gen-ts/dist/shared/sms/v1/enums.pb';
import { useCallback, useEffect, useState } from 'react';
import { ThreadSendingMediaItem } from '../types';

const convertDraftMediaToThreadMedia = (draftMedia: Media[]): ThreadSendingMediaItem[] =>
  draftMedia.map((mediaItem) => ({
    id: mediaItem.mediaId || genUUIDV4(),
    mediaObj: mediaItem,
    uploading: false,
    hasError: false,
  }));

const convertUploadResponseToMedia = (response: MediaTypes.MediaUploadResponse): Media => {
  return {
    mediaId: response.ID,
    url: response.PublicURL || response.SecureURL,
    filename: response.Name,
    createdAt: response.CreatedAt,
  };
};

type UseThreadMediaArgs = {
  threadId: string;
  groupId: string;
  loadDraft?: boolean;
  updateDraft?: boolean;
  onChange?: (media: ThreadSendingMediaItem[]) => void;
  maxFileCount?: number;
  onError?: (error: unknown) => void;
  onExceedMaxFileCount?: (excessCount: number) => void;
  mediaIds?: string[];
  departmentId: string;
  personPhone: string;
  locationPhone: string;
};

type ThreadMediaItemWithoutPreview = Omit<ThreadSendingMediaItem, 'previewSrc'>;

type UseThreadMediaResult = {
  isLoadingMedia: boolean;
  threadMedia: ThreadSendingMediaItem[];
  uploadFiles: (files: File[]) => Promise<void>;
  removeMediaItem: (id: string) => void;
  clearMedia: (withDelete?: boolean) => void;
};

/**
 * Hook to manage media for a thread
 * @param threadId
 * @param groupId
 * @param loadDraft - If false, media from saved drafts will not be used
 * @param updateDraft - If false, the draft will not be updated when the media changes
 * @param maxFileCount
 * @param onError
 * @param onExceedMaxFileCount
 * @param mediaIds - If provided, this media will be used for the initial state of the thread media. When changed, the
 * threadMedia will be update to the media from the provided mediaIds, overwriting any current media.
 */
export const useThreadMedia = ({
  threadId,
  groupId,
  loadDraft = true,
  updateDraft = true,
  onChange,
  onError,
  onExceedMaxFileCount,
  maxFileCount,
  mediaIds: providedMediaIds,
  departmentId,
  personPhone,
  locationPhone,
}: UseThreadMediaArgs): UseThreadMediaResult => {
  const { selectedOrgId: orgId, selectedLocationIds } = useAppScopeStore();
  const user = getUser();
  const userId = user?.userID ?? '';
  const [threadMediaWithoutPreviews, setThreadMediaWithoutPreviews] = useState<ThreadMediaItemWithoutPreview[]>([]);
  const [needsMediaFromDraft, setNeedsMediaFromDraft] = useState(loadDraft);
  const { draftQuery, shallowUpdate: shallowUpdateDraft } = useDraft(
    {
      orgId,
      userId,
      threadId,
      locationId: groupId,
      groupIds: selectedLocationIds,
    },
    {
      draftQueryOptions: {
        onSuccess: (draft) => {
          if (needsMediaFromDraft && draft.threadId === threadId) {
            setThreadMediaWithoutPreviews(convertDraftMediaToThreadMedia(draft?.medias ?? []));
            setNeedsMediaFromDraft(false);
          }
        },
      },
    }
  );
  const threadMediaIds = threadMediaWithoutPreviews.flatMap(({ mediaObj }) =>
    mediaObj?.mediaId ? [mediaObj.mediaId] : []
  );
  const mediaPreviewQueries = MediaQueries.useMmsMedia(
    { mediaIds: providedMediaIds ?? threadMediaIds, locationId: groupId },
    { enabled: !!providedMediaIds?.length || !!threadMediaIds.length }
  );
  const providedMediaIsLoading = !!providedMediaIds && mediaPreviewQueries.some((query) => query.isLoading);
  const threadMediaWithPreviews = providedMediaIds
    ? providedMediaIds.map<ThreadSendingMediaItem>((mediaId) => ({
        id: mediaId,
        mediaObj: {
          mediaId,
        },
        hasError: false,
        uploading: false,
        previewSrc: mediaPreviewQueries.find((query) => query.data?.mediaId === mediaId)?.data?.src,
      }))
    : threadMediaWithoutPreviews.map<ThreadSendingMediaItem>((mediaItem) => ({
        ...mediaItem,
        previewSrc: mediaPreviewQueries.find((query) => query.data?.mediaId === mediaItem.mediaObj?.mediaId)?.data?.src,
      }));

  const handleMediaChange = useCallback<typeof setThreadMediaWithoutPreviews>(
    (newVal) => {
      setThreadMediaWithoutPreviews((prev) => {
        const shouldUpdateDraft = updateDraft && threadId === draftQuery.data?.threadId && !needsMediaFromDraft;
        if (typeof newVal === 'function') {
          const resolvedNewVal = newVal(prev);
          onChange?.(resolvedNewVal);
          if (shouldUpdateDraft)
            shallowUpdateDraft({
              threadId,
              departmentId,
              personPhone,
              locationPhone,
              draft: {
                medias: resolvedNewVal.flatMap(({ mediaObj }) =>
                  mediaObj ? [{ ...mediaObj, mediaId: mediaObj.mediaId ?? '' }] : []
                ),
              },
            });
          return resolvedNewVal;
        }
        onChange?.(newVal);
        if (shouldUpdateDraft)
          shallowUpdateDraft({
            threadId,
            departmentId,
            personPhone,
            locationPhone,
            draft: {
              medias: newVal.flatMap(({ mediaObj }) =>
                mediaObj ? [{ ...mediaObj, mediaId: mediaObj.mediaId ?? '' }] : []
              ),
            },
          });
        return newVal;
      });
    },
    [onChange, setThreadMediaWithoutPreviews, updateDraft, shallowUpdateDraft, threadId]
  );

  const uploadFiles = useCallback(
    async (files: File[]) => {
      const filesToAdd =
        maxFileCount === undefined ? files : files.slice(0, maxFileCount - threadMediaWithoutPreviews.length);
      const exceedsMax = filesToAdd.length !== files.length;
      if (exceedsMax) {
        onExceedMaxFileCount?.(files.length - filesToAdd.length);
      }
      const newThreadMediaItems: ThreadMediaItemWithoutPreview[] = filesToAdd.map((file) => ({
        id: genUUIDV4(),
        uploading: true,
        hasError: false,
        file,
      }));
      setThreadMediaWithoutPreviews((media) => media.concat(newThreadMediaItems));

      try {
        const responsesWithIds = await Promise.all(
          newThreadMediaItems.map(async ({ file, id }) => {
            if (!file) return;
            try {
              const response = await MediaApi.uploadMedia(
                {
                  data: file,
                  filename: file.name,
                  type: MediaTypes.MediaUploadTypes.MMS,
                  publicity: true,
                },
                groupId
              );
              return {
                response,
                id,
              };
            } catch (error) {
              setThreadMediaWithoutPreviews((media) => {
                return media.map((mediaItem) => {
                  if (mediaItem.id === id) {
                    return { ...mediaItem, uploading: false, hasError: true };
                  }
                  return mediaItem;
                });
              });
              throw error;
            }
          })
        );
        const mediaFromResponses = responsesWithIds.reduce<{ media: Media; id: string }[]>((acc, response) => {
          if (response) acc.push({ id: response.id, media: convertUploadResponseToMedia(response.response) });
          return acc;
        }, []);
        handleMediaChange((media) =>
          media.map((mediaItem) => {
            const mediaResponse = mediaFromResponses.find((mediaResponse) => mediaResponse.id === mediaItem.id);
            return mediaResponse ? { ...mediaItem, uploading: false, mediaObj: mediaResponse.media } : mediaItem;
          })
        );
      } catch (error) {
        onError?.(error);
      }
    },
    [handleMediaChange, maxFileCount, onError, onExceedMaxFileCount, groupId]
  );

  const removeMediaItem = useCallback(
    (id: string) => {
      handleMediaChange((media) => {
        const mediaItem = media.find((mediaItem) => mediaItem.id === id);
        if (mediaItem?.mediaObj?.mediaId) {
          MediaApi.deleteMedia(mediaItem.mediaObj.mediaId, MediaTypes.MediaUploadTypes.MMS);
        }
        return media.filter((mediaItem) => mediaItem.id !== id);
      });
    },
    [handleMediaChange]
  );

  const clearMedia = useCallback((withDelete = false) => {
    setThreadMediaWithoutPreviews((prev) => {
      if (withDelete) {
        prev.forEach((mediaItem) => {
          if (mediaItem.mediaObj?.mediaId) {
            MediaApi.deleteMedia(mediaItem.mediaObj.mediaId, MediaTypes.MediaUploadTypes.MMS);
          }
        });
      }
      return [];
    });
  }, []);

  useEffect(() => {
    if (loadDraft) {
      setNeedsMediaFromDraft(true);
      clearMedia();
    }
  }, [threadId, userId, orgId]);

  useEffect(() => {
    if (needsMediaFromDraft && draftQuery.data?.threadId === threadId) {
      setThreadMediaWithoutPreviews(convertDraftMediaToThreadMedia(draftQuery.data?.medias ?? []));
      setNeedsMediaFromDraft(false);
    }
  }, [needsMediaFromDraft, draftQuery.data?.threadId]);

  useEffect(() => {
    if (providedMediaIds !== undefined) {
      setThreadMediaWithoutPreviews(
        providedMediaIds.map((mediaId) => ({ id: mediaId, uploading: false, hasError: false, mediaObj: { mediaId } }))
      );
    } else {
      setThreadMediaWithoutPreviews(convertDraftMediaToThreadMedia(draftQuery.data?.medias ?? []));
    }
  }, [JSON.stringify(providedMediaIds)]);

  return {
    isLoadingMedia: providedMediaIsLoading,
    threadMedia: threadMediaWithPreviews,
    uploadFiles,
    removeMediaItem,
    clearMedia,
  };
};
