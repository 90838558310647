import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { TextField, Text, BaseFieldProps } from '@frontend/design-system';
import { MAX_CHANNEL_NAME_LENGTH } from '../../../constants';

interface CreateChannelProps {
  fieldProps: BaseFieldProps<string, '', HTMLInputElement>;
}

export const CreateChannel: FC<CreateChannelProps> = ({ fieldProps }) => {
  const { t } = useTranslation('team-chat');

  return (
    <>
      <TextField
        name={t('Create channel')}
        label=''
        placeholder={t('Name')}
        css={textAreaStyle}
        autoComplete='off'
        startAdornment='#'
        maxLength={MAX_CHANNEL_NAME_LENGTH}
        {...fieldProps}
      />
      <Text css={modalTextStyle}>
        {t(
          'Channels are where conversations happen around a topic, choose a name that is easy to understand and find.'
        )}
      </Text>
    </>
  );
};

export const textAreaStyle = css({
  margin: theme.spacing(1, 0, 0, 0),
});

export const modalTextStyle = css({
  color: theme.colors.black,
  marginTop: theme.spacing(1),
});

export const modalButtonStyle = css({ float: 'right', width: 'fit-content' });
