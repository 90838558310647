export const pendoTags = {
  close: 'app-forms-send-exp-close',
  steps: {
    appointments: {
      back: 'app-forms-send-exp-appointments-back',
      next: 'app-forms-send-exp-appointments-next',
      select: 'app-forms-send-exp-appointments-select',
      deselect: 'app-forms-send-exp-appointments-deselect',
    },
    contacts: {
      select: 'app-forms-send-exp-select-contact',
      search: 'app-forms-send-exp-search-contact',
    },
    deliveryMethod: {
      direct: {
        select: 'app-forms-send-exp-delivery-method-direct-select',
        via: {
          sms: 'app-forms-send-exp-delivery-method-direct-via-sms',
          email: 'app-forms-send-exp-delivery-method-direct-via-email',
        },
      },
      kiosk: {
        select: 'app-forms-send-exp-delivery-method-kiosk-select',
        launch: 'app-forms-send-exp-delivery-method-kiosk-launch',
      },
      back: 'app-forms-send-exp-delivery-method-back',
    },
    composeMessage: {
      back: 'app-forms-send-exp-compose-message-back',
    },
    attachments: {
      clickToUpload: 'app-forms-send-exp-attachments-click-to-upload',
    },
    documentSelection: {
      select: {
        packet: 'app-forms-send-exp-document-selection-select-packet',
        form: 'app-forms-send-exp-document-selection-select-form',
      },
      tab: {
        packets: 'app-forms-send-exp-document-selection-tab-packets',
        forms: 'app-forms-send-exp-document-selection-tab-forms',
      },
      next: 'app-forms-send-exp-document-selection-next',
    },
  },
  send: 'app-forms-send-exp-send',
  cancel: 'app-forms-send-exp-cancel',
  skip: 'app-forms-send-exp-skip',
};
