import { FC } from 'react';
import { AutoRecallAnalyticsTypes } from '@frontend/api-analytics';
import { Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DemoChip } from '../demo-chip';
import { useAutoRecallStore } from './hooks';

type AutoRecallChartsProps = {
  chartInfoText?: string;
  isLoading?: boolean;
  summaryStats?: AutoRecallAnalyticsTypes.RecallSummaryStats;
};

const formatSneakPeekValue = (total?: number, value?: number) => {
  const percentage = ((value || 0) / (total || 0)) * 100;
  return `${value} (${isNaN(percentage) ? 0 : percentage.toFixed(2)}%)`;
};

export const AutoRecallCharts: FC<React.PropsWithChildren<AutoRecallChartsProps>> = ({
  chartInfoText,
  isLoading,
  summaryStats = {},
}) => {
  const { t } = useTranslation('analytics');
  const { isDemoAccount } = useAutoRecallStore();

  return (
    <Chart
      colors={{
        notReceived: theme.colors.critical30,
        notScheduled: theme.colors.warning50,
        scheduled: theme.colors.secondary.seaweed40,
      }}
      isLoading={isLoading}
      labels={{
        notReceived: t('Not Received'),
        notScheduled: t('Not Scheduled'),
        scheduled: t('Appointment Scheduled'),
      }}
    >
      <Chart.Header leftElement={isDemoAccount && <DemoChip />} subtitle={chartInfoText} title={t('Auto Recall')} />
      <Chart.PieChart
        appearance={{ hideTooltip: true }}
        data={{
          centerMetric: summaryStats?.total,
          groups: [
            { name: 'scheduled', value: summaryStats?.scheduled || 0 },
            { name: 'notReceived', value: summaryStats?.notReceived || 0 },
            { name: 'notScheduled', value: summaryStats?.notScheduled || 0 },
          ],
        }}
      />
      <Chart.Legends
        values={{
          scheduled: formatSneakPeekValue(summaryStats?.total, summaryStats?.scheduled),
          notReceived: formatSneakPeekValue(summaryStats?.total, summaryStats?.notReceived),
          notScheduled: formatSneakPeekValue(summaryStats?.total, summaryStats?.notScheduled),
        }}
      />
    </Chart>
  );
};
