import { FC, useState, useEffect } from 'react';
import appConfig from '@frontend/env';
import { CenteredSpinningLoader } from '../../../../../../shared/components';
import { containerStyle, getIframeStyle } from './experience-view.styles';
import { PreviewFileType } from './experience-view.types';

interface ExperienceViewProps {
  token: string;
  type: PreviewFileType;
  locationId: string;
  hideFooterLinks?: boolean;
  disableReviewMode?: boolean;
  isReloading?: boolean;
}

export const ExperienceView: FC<ExperienceViewProps> = ({
  token,
  type,
  hideFooterLinks,
  disableReviewMode,
  isReloading,
  locationId,
}) => {
  const { FORMS_EXPERIENCE_URL } = appConfig;
  const [isLoading, setIsLoading] = useState(true);
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (isReloading) {
      setIsLoading(true);
    }
  }, [isReloading]);

  // Prepare the URL for the experience view
  useEffect(() => {
    if (token) {
      const url = new URL(`${FORMS_EXPERIENCE_URL}/form-preview`);

      switch (type) {
        case 'form':
          url.searchParams.append('formId', token);
          break;

        case 'packet':
          url.searchParams.append('packetId', token);
          break;

        case 'template':
          url.searchParams.append('templateId', token);
          break;
      }

      url.searchParams.append('companyId', locationId);

      if (hideFooterLinks) {
        url.searchParams.append('hideFooterLinks', 'true');
      }

      if (disableReviewMode) {
        url.searchParams.append('disableReviewMode', 'true');
      }

      setUrl(url.toString());
    }
  }, [token, type, hideFooterLinks, disableReviewMode, locationId]);

  if (isReloading) {
    return <CenteredSpinningLoader />;
  }

  return (
    <div css={containerStyle}>
      {isLoading && (
        <div>
          <CenteredSpinningLoader />
        </div>
      )}

      <iframe src={url} css={getIframeStyle(!isLoading)} onLoad={() => setIsLoading(false)} />
    </div>
  );
};
