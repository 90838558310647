import dayjs from 'dayjs';

const shortenNumber = (value?: number) => {
  const units = ['k', 'M', 'B', 'T'];

  for (let i = units.length - 1; i >= 0; i--) {
    const decimal = 1000 ** (i + 1);
    if ((value || 0) >= decimal) {
      return +((value || 0) / decimal).toFixed(1) + units[i];
    }
  }

  return (value || 0).toString();
};

export const formatters = {
  currency: {
    format: (value?: number) =>
      new Intl.NumberFormat('en-US', {
        currency: 'USD',
        maximumFractionDigits: 0,
        style: 'currency',
      }).format(value || 0),
    shortenNumber: (value?: number) => `$${shortenNumber(value || 0)}`,
  },

  date: {
    format: (value: string, withoutYear?: boolean) => dayjs(value).format(withoutYear ? 'DD MMM' : 'DD MMM, YYYY'),
  },

  percent: {
    appendPercent: (value?: number) => `${Math.round(value || 0)}%`,
    format: (value?: number) => `${Math.round((value || 0) * 100)}%`,
  },

  value: {
    format: (value?: number) => new Intl.NumberFormat('en-US').format(value || 0),
    shortenNumber,
  },
};
