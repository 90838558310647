import { css } from '@emotion/react';
import { PhoneCallsTypes } from '@frontend/api';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ChecklistField, FormRow, ModalControlModalProps, TableFilters, useForm } from '@frontend/design-system';
import { CallRecordsFiltersType } from '../../hooks/use-phone-config-state-store';

interface FilterProps {
  defaultFilters: CallRecordsFiltersType;
  filters: CallRecordsFiltersType;
  onChange: (args: CallRecordsFiltersType) => void;
}

type ConditionalProps = {
  modalProps: ModalControlModalProps;
  setShowNotificationBadge: (showNotification: boolean) => void;
};

export const filterDigits = (value: string, withSpecialChars?: boolean) =>
  value.replace(withSpecialChars ? /[^0-9-()-]/g : /[^0-9]/g, '');

export const CallsFilter = ({
  defaultFilters,
  filters,
  modalProps,
  onChange,
  setShowNotificationBadge,
}: FilterProps & ConditionalProps) => {
  const { t } = useTranslation('calls', { keyPrefix: 'calls-filter' });

  const { formProps, getFieldProps, reset } = useForm({
    fields: {
      filterByStatus: { type: 'checklist', value: filters.status },
    },
  });

  const statusFilterProps = getFieldProps('filterByStatus');

  const handleSidebarFilters = () => {
    onChange({
      ...filters,
      status: statusFilterProps.value as PhoneCallsTypes.CallStatus[],
    });

    setShowNotificationBadge && setShowNotificationBadge(statusFilterProps.value.length > 0);
  };

  const handleFiltersReset = () => {
    onChange(defaultFilters);
    reset();
    modalProps?.onClose();
  };

  return (
    <TableFilters
      width='medium'
      {...modalProps}
      onApplyClick={handleSidebarFilters}
      onClearAllClick={handleFiltersReset}
    >
      <TableFilters.Section sectionHeaderLabel={''}>
        <form {...formProps}>
          <FormRow>
            <ChecklistField {...getFieldProps('filterByStatus')} css={styles.heading} label={t('Results')}>
              <ChecklistField.Option name='missed' key='missed'>
                {t('Missed')}
              </ChecklistField.Option>
              <ChecklistField.Option name='abandoned' key='abandoned'>
                {t('Abandoned')}
              </ChecklistField.Option>
              <ChecklistField.Option name='answered' key='answered'>
                {t('Answered')}
              </ChecklistField.Option>
            </ChecklistField>
          </FormRow>
        </form>
      </TableFilters.Section>
    </TableFilters>
  );
};

const styles = {
  heading: css`
    > label {
      font-weight: 700;
      font-size: ${theme.fontSize(20)};
    }
  `,
};
