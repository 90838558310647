import { FC, useMemo } from 'react';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { ActivePatientsMetricMulti, ActivePatientsMetricSingle, ActivePatientsTable, SummaryMetric } from '..';
import { InlineStats } from '../..';
import { usePracticeAnalyticsStore } from '../../../hooks';
import { queryKeys } from '../../../query-keys';
import { formatters } from '../../../utils';

const query = gql`
  query {
    location {
      activePatients {
        total
      }
      activePatientsScheduled {
        percentage
      }
      patientDemographics {
        buckets {
          label
          total
          subbuckets {
            label
            total
            persons {
              id
              FirstName: firstName
              LastName: lastName
              PMID: pmid
              MobilePhone: mobilePhone
              HomePhone: homePhone
              WorkPhone: workPhone
              Email: email
              PreferredName: preferredName
              Birthdate: birthdate
              Gender: gender
              age
              primaryInsurance
              nextVisit
            }
          }
        }
        details {
          insuranceProviders(top: 5) {
            label
            total
            persons {
              id
              FirstName: firstName
              LastName: lastName
              PMID: pmid
              MobilePhone: mobilePhone
              HomePhone: homePhone
              WorkPhone: workPhone
              Email: email
              PreferredName: preferredName
              Birthdate: birthdate
              Gender: gender
              age
              primaryInsurance
              nextVisit
            }
          }
        }
      }
    }
  }
`;

export const ActivePatientsSubView: FC<React.PropsWithChildren<PracticeAnalyticsTypes.MetricSubViewProps>> = (
  props
) => {
  const { exportEnabled } = props;
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount } = usePracticeAnalyticsStore();

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(
      `summaryMetricActivePatientsDemographics-${isDemoAccount}-${JSON.stringify(filters.locations?.sort() || [])}`
    ),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.ActivePatientsDemographicsResponse>({
            locationIds: filters.locations,
            queries: [query],
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    select: (data) => (isDemoAccount ? demoData?.activePatientsDemographics : data),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const processedData = useMemo(() => {
    const updatedData = PracticeAnalyticsAggregations.activePatientsDetails(data, filters.locations?.length);
    const { activePatients, activePatientsScheduled } = updatedData.aggregatedData || {};

    return {
      data: updatedData,
      quickStats: [
        {
          label: t('Active Patients'),
          value: formatters.value.format(activePatients?.total),
        },
        {
          label: t('Scheduled Percentage'),
          value: formatters.percent.format(activePatientsScheduled?.percentage),
        },
      ],
    };
  }, [data?.data]);

  const Component = multipleLocationsSelected ? ActivePatientsMetricMulti : ActivePatientsMetricSingle;

  return (
    <>
      <InlineStats data={processedData.quickStats} isDemoAccount={isDemoAccount} isLoading={isLoading} />

      {!multipleLocationsSelected && <SummaryMetric clickNoop hideActivePatientsScheduledMetric isDrillDownPage />}

      <Component {...props} data={processedData.data} isLoading={isLoading} />

      <ActivePatientsTable data={processedData.data} isExportEnabled={exportEnabled} isLoading={isLoading} />
    </>
  );
};
