import { Heading, PrimaryButton, Text, emptyStateGraphics, useModalControl } from '@frontend/design-system';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useSoftphoneWidgetControl } from '@frontend/softphone2';
import {
  DeviceSelectModal,
  PhoneSyncButton,
  PhoneSyncModal,
  usePhonePairSuccessHandler,
  usePhoneSyncControl,
} from '@frontend/phone-sync';
import { useAppScopeStore } from '@frontend/scope';
import { PhoneSyncApi, useQueryDevices } from '@frontend/api-phone-sync';

export const EmptySyncState = () => {
  const { t } = useTranslation('dialpad');
  const EmptyStateSyncPhone = emptyStateGraphics.sync_your_phone;
  const { selectedLocationIds } = useAppScopeStore();
  const modal = useModalControl();

  const { buttonProps, modalProps } = usePhoneSyncControl();
  const { data: deviceList } = useQueryDevices(selectedLocationIds);

  const availableDevices = deviceList?.filter((device) => device.availableInContext);
  const hasAvailableDevices = availableDevices && availableDevices.length > 0;

  const onSyncSuccess = usePhonePairSuccessHandler()(selectedLocationIds);

  return (
    <section css={styles.container}>
      <EmptyStateSyncPhone
        css={css`
          height: 200px;
        `}
      />
      <Heading css={styles.fixedWidth} level={3} textAlign='center'>
        {t('No Phone Connected')}
      </Heading>
      <Text css={styles.fixedWidth} textAlign='center'>
        {t('To enable Dial Pad, please connect to a desk phone.')}
      </Text>
      <div style={{ display: 'flex', gap: theme.spacing(1), width: 'auto' }}>
        {hasAvailableDevices && (
          <>
            <PrimaryButton size='large' css={{ width: 'auto' }} {...modal.triggerProps}>
              {t('Connect Device')}
            </PrimaryButton>
            <DeviceSelectModal
              variant='dialpad'
              deviceSelectModal={modal}
              closeModal={modal.closeModal}
              locationIds={selectedLocationIds}
            />
          </>
        )}
        <PhoneSyncButton
          {...buttonProps}
          variant={hasAvailableDevices ? 'secondary' : 'primary'}
          btnLabel={t('Pair New Device')}
        />
        <PhoneSyncModal
          {...modalProps}
          locationIds={selectedLocationIds}
          onSyncSuccess={onSyncSuccess}
          syncFn={PhoneSyncApi.pairDevice}
        />
      </div>
    </section>
  );
};

export const DialpadUnavailableState = () => {
  const { t } = useTranslation('dialpad');
  const open = useSoftphoneWidgetControl((ctx) => ctx?.open);
  const isOpen = useSoftphoneWidgetControl((ctx) => ctx.isOpen);
  const EmptyStateSyncPhone = emptyStateGraphics.sync_your_phone;
  return (
    <section css={styles.container}>
      <EmptyStateSyncPhone
        css={css`
          height: 200px;
        `}
      />
      <Heading level={3} css={styles.fixedWidth} textAlign='center'>
        {t('Dial Pad Unavailable When Softphone is Connected')}
      </Heading>
      <Text css={styles.fixedWidth} textAlign='center'>
        {t(
          'The Dial Pad is currently unavailable because a Softphone is connected that can make calls through the Weave app.'
        )}
      </Text>
      <div css={styles.primaryBtn}>
        <PrimaryButton size='large' onClick={() => !isOpen && open()}>
          {t('Open Softphone')}
        </PrimaryButton>
      </div>
    </section>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: ${theme.spacing(1)};
  `,

  primaryBtn: css`
    margin-top: ${theme.spacing(3)};
  `,

  fixedWidth: css`
    width: 400px;
    color: ${theme.colors.neutral50};
  `,
};
