import { theme } from '@frontend/theme';
import { Heading, Text, TextButton, contextFactory } from '@frontend/design-system';
import { css } from '@emotion/react';
import { GlobalSearchFeatures, useGlobalSearchFeatureLabelMap } from '../config';
import { useGlobalSearch } from '../provider';
import { useTranslation } from '@frontend/i18n';
import { GlobalSearchTrackingIds } from '../constants';

type GlobalSearchSectionProps = {
  id: GlobalSearchFeatures;
  children: React.ReactNode;
};

type GlobalSearchSection = {
  id: GlobalSearchFeatures;
};

export const [GlobalSearchSectionContext, useGlobalSearchSection] = contextFactory<GlobalSearchSection>();

export const GlobalSearchSection = ({ children, id }: GlobalSearchSectionProps) => {
  const { searchFor, viewAllFeature } = useGlobalSearch(['searchFor', 'viewAllFeature']);

  if ((searchFor !== 'all' && id !== searchFor) || (viewAllFeature && viewAllFeature !== id)) return null;

  return (
    <GlobalSearchSectionContext.Provider
      value={{
        id,
      }}
    >
      <section
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(1)};
          flex: 1;
          overflow-y: auto;
        `}
      >
        {children}
      </section>
    </GlobalSearchSectionContext.Provider>
  );
};

type GlobalSearchSectionContentProps = {
  children: React.ReactNode;
  count: string | number | null | undefined;
  hasResults?: boolean;
  showViewAll?: boolean;
};

export const GlobalSearchSectionContent = ({
  count,
  children,
  hasResults,
  showViewAll = true,
}: GlobalSearchSectionContentProps) => {
  const { t } = useTranslation('global-search');
  const { viewAllFeature, setViewAllFeature, setIsExpandedView, debouncedSearchTerm } = useGlobalSearch([
    'viewAllFeature',
    'setViewAllFeature',
    'setIsExpandedView',
    'debouncedSearchTerm',
  ]);
  const labelMap = useGlobalSearchFeatureLabelMap();
  const { id } = useGlobalSearchSection();

  return (
    <>
      <header
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.spacing(1)};
          padding: ${theme.spacing(1, 1, 0, 1)};
        `}
      >
        {viewAllFeature !== id ? (
          <>
            <Text weight='semibold'>{labelMap[id]}</Text>
            {hasResults && (
              <>
                {showViewAll && !viewAllFeature && (
                  <TextButton
                    trackingId={GlobalSearchTrackingIds.viewAllFeature(id)}
                    css={{
                      color: theme.font.colors.primary,
                      fontWeight: theme.font.weight.bold,
                    }}
                    onClick={() => {
                      setViewAllFeature(id);
                      setIsExpandedView(true);
                    }}
                  >
                    {t('View All')}
                  </TextButton>
                )}
                <Text
                  size='small'
                  color='subdued'
                  css={css`
                    padding: ${theme.spacing(0, 0.5, 0, 0.5)};
                    border-radius: ${theme.borderRadius.small};
                    background-color: ${theme.colors.neutral10};
                  `}
                >
                  {count}
                </Text>
              </>
            )}
          </>
        ) : (
          <Heading
            css={css`
              margin-top: ${theme.spacing(1)};
              margin-bottom: ${theme.spacing(1)};
            `}
            level={3}
          >
            {t("Showing All {{feature}} for '{{searchTerm}}'", {
              feature: labelMap[id],
              searchTerm: debouncedSearchTerm,
            })}
          </Heading>
        )}
      </header>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding: ${theme.spacing(0, 1)};
          padding-bottom: ${theme.spacing(2)};
        `}
      >
        {children}
      </div>
    </>
  );
};

GlobalSearchSection.Content = GlobalSearchSectionContent;
