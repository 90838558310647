import { SchemaContactLists, SchemaContacts, SchemaDeviceContactLists } from '@frontend/schema';
import {
  CreateContactListType,
  GetContactListsType,
  GetContactListType,
  GetContactListDevicesType,
  CreateContactType,
  ListContactsType,
  DeleteContactType,
  GetContactListContactsType,
  ReplaceContactListContactsType,
  UpdateContactType,
  DeleteContactListType,
  UpdateContactListType,
  GetDevicesContactLists,
  ReplaceDevicesType,
  AddDeviceToContactLists,
  ListContactListsContactType,
} from './types';

// Contact List
export const createContactList = (name: string, locationId?: string): Promise<CreateContactListType['output']> => {
  return SchemaContactLists.Create(
    {
      contactList: {
        name,
        id: '',
      },
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const getContactLists = (locationId?: string): Promise<GetContactListsType['output']> => {
  return SchemaContactLists.List(
    {
      includeDevices: true,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const getContactList = (contactListId: string, locationId?: string): Promise<GetContactListType['output']> => {
  return SchemaContactLists.Read(
    {
      contactListId,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const getContactListDevices = (
  contactListId: string,
  locationId?: string
): Promise<GetContactListDevicesType['output']> => {
  return SchemaContactLists.ListDevices(
    {
      contactListId,
      includeDevices: true,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const getContactListContacts = (
  contactListId: string,
  locationId?: string
): Promise<GetContactListContactsType['output']> => {
  return SchemaContactLists.ListContacts(
    {
      contactListId,
      includeContacts: true,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const replaceContactListContacts = (
  contactListId: string,
  contactIds: string[],
  locationId?: string
): Promise<ReplaceContactListContactsType['output']> => {
  return SchemaContactLists.ReplaceContacts(
    {
      contactListId,
      contactIds,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const deleteContactList = (
  contactListId: string,
  locationId?: string
): Promise<DeleteContactListType['output']> => {
  return SchemaContactLists.Delete(
    {
      contactListId,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const updateContactList = ({
  id,
  name,
  locationId,
}: {
  id: string;
  name: string;
  locationId?: string;
}): Promise<UpdateContactListType['output']> => {
  return SchemaContactLists.Update(
    {
      contactList: {
        id,
        name,
      },
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const assignContactListToDevices = ({
  contactListId,
  deviceIds,
  locationId,
}: {
  contactListId: string;
  deviceIds: string[];
  locationId?: string;
}): Promise<ReplaceDevicesType['output']> => {
  return SchemaContactLists.ReplaceDevices(
    {
      contactListId,
      deviceIds,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

// Contacts

export const createContact = ({
  name,
  number,
  locationId,
}: {
  name: string;
  number: string;
  locationId?: string;
}): Promise<CreateContactType['output']> => {
  return SchemaContacts.Create(
    {
      contact: {
        name,
        number,
        id: '',
      },
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const getContacts = (locationId?: string): Promise<ListContactsType['output']> => {
  return SchemaContacts.List({}, locationId ? { headers: { 'Location-Id': locationId } } : undefined);
};

export const deleteContact = (id: string, locationId?: string): Promise<DeleteContactType['output']> => {
  return SchemaContacts.Delete({ id }, locationId ? { headers: { 'Location-Id': locationId } } : undefined);
};

export const updateContact = ({
  id,
  name,
  number,
  locationId,
}: {
  id: string;
  name: string;
  number: string;
  locationId?: string;
}): Promise<UpdateContactType['output']> => {
  return SchemaContacts.Update(
    {
      contact: {
        name,
        id,
        number,
      },
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const getContactListsbyContactID = ({
  id,
  locationId,
}: {
  id: string;
  locationId?: string;
}): Promise<ListContactListsContactType['output']> => {
  return SchemaContacts.ListContactLists(
    {
      id,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

// Devices

export const getDevicesContactLists = (id: string, locationId?: string): Promise<GetDevicesContactLists['output']> => {
  return SchemaDeviceContactLists.GetContactLists(
    {
      deviceId: id,
      includeContactLists: true,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const assignDevicetoContactLists = ({
  deviceId,
  contactListIds,
  locationId,
}: {
  deviceId: string;
  contactListIds: string[];
  locationId?: string;
}): Promise<AddDeviceToContactLists['output']> => {
  return SchemaDeviceContactLists.ReplaceContactLists(
    {
      deviceId,
      contactListIds,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};
