import { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { camelCase } from 'lodash-es';
import { ScheduleTypes } from '@frontend/api-schedule';
import { AvailableLanguages, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { titleCase } from '@frontend/string';
import { theme } from '@frontend/theme';
import {
  TextField,
  FormRow,
  DropdownField,
  useForm,
  ModalControlResponse,
  NakedButton,
  CheckboxField,
  useModalControl,
  Modal,
} from '@frontend/design-system';
import { Translate } from '../../images';
import { CustomField } from '../CustomFieldsForm';
import { OptionEditor } from './OptionsEditor';
import { optionStyles, subFieldStyles } from './styles';
import { TranslationOption } from './TranslationEditor';
import { TranslationViewer } from './TranslationViewer';

interface FieldProps {
  field?: Partial<ScheduleTypes.CustomField>;
  mode?: 'Create' | 'Update';
  onChange: (value: ScheduleTypes.CustomField) => void;
  modalControl: ModalControlResponse;
}

const defaultTranslations: Record<AvailableLanguages, string> = {} as Record<AvailableLanguages, string>;

export function CustomFieldEditor({ field, onChange, modalControl, mode = 'Create' }: FieldProps) {
  const labelTranslations = useModalControl();
  const optionsTranslations = useModalControl();
  const subFieldTranslations = useModalControl();
  const { t } = useTranslation('schedule');
  const [options, setOptions] = useState<ScheduleTypes.Option[]>(field?.options || []);
  const [subFields, setSubFields] = useState<Record<string, ScheduleTypes.Option> | undefined>(field?.subFields);
  const [subField, setSubField] = useState<string | undefined>();
  const [selectedSubField, setSelectedSubField] = useState<ScheduleTypes.Option | undefined>();
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | undefined>();
  const [translations, setTranslations] = useState<Record<AvailableLanguages, string>>(
    field?.translations || { ...defaultTranslations }
  );

  const selectedOption = useMemo(() => {
    if (selectedOptionIndex !== undefined) {
      return options?.[selectedOptionIndex];
    }
    return undefined;
  }, [selectedOptionIndex, options]);

  const typeMap = {
    text: t('Text'),
    dropdown: t('Dropdown'),
    date: t('Date'),
    checkbox: t('Checkbox'),
    number: t('Number'),
  };

  useEffect(() => {
    if (field?.translations) {
      setTranslations(field?.translations);
    }
  }, [field?.translations]);

  const { getFieldProps, formProps, values, reset, seedValues } = useForm({
    fields: {
      type: { type: 'dropdown', required: true },
      label: { type: 'text', required: true },
      required: { type: 'checkbox' },
      newUserOnly: { type: 'checkbox', required: false },
    },
  });

  useEffect(() => {
    if (modalControl.modalProps.show && field && (field?.label || field?.type)) {
      seedValues({
        type: field.type,
        label: field.label,
        newUserOnly: field.newUserOnly,
        required: field.required,
      });
      setTranslations(field.translations || { ...defaultTranslations });
      setOptions(field.options || []);
      setSubFields(field.subFields);
    } else if (!modalControl.modalProps.show) {
      reset();
      setOptions([]);
      setSubFields(undefined);
      setTranslations({ ...defaultTranslations });
    }
  }, [field, modalControl.modalProps.show]);

  const handleSubmit = useCallback(() => {
    if (!field) {
      return;
    }
    const key = field.custom || !field.key ? camelCase(values.label) : field.key;
    const customField: ScheduleTypes.CustomField = {
      key,
      ...field,
      label: String(values.label),
      type: String(values.type) as ScheduleTypes.SupportedInputTypes,
      newUserOnly: !!values.newUserOnly,
    };
    if (!field.requiredIsNotOptional) {
      customField.required = !!values.required;
    }
    customField.options = options;
    customField.subFields = subFields;
    customField.translations = translations;

    onChange(customField);
    reset();
    modalControl.closeModal();
  }, [values, options, translations, subFields]);

  useEffect(() => {
    if (field?.options) {
      setOptions(field.options);
    }
  }, [field?.options]);

  const handleAddOption = (option: ScheduleTypes.Option) => {
    setOptions([...options, option]);
  };

  const handleAddTranslation = ({ language, text }: TranslationOption) => {
    setTranslations({ ...translations, [language]: text });
  };

  const handleOptionAddTranslation = useCallback(
    ({ language, text }: TranslationOption) => {
      if (selectedOption && selectedOptionIndex !== undefined) {
        const option: ScheduleTypes.Option = { ...options[selectedOptionIndex] };
        option.translations = option.translations || { ...defaultTranslations };
        option.translations[language] = text;
        const opts = [...options];
        opts[selectedOptionIndex] = option;
        setOptions(opts);
      }
    },
    [selectedOption, selectedOptionIndex, options]
  );

  const handleAddSubFieldLanguage = useCallback(
    ({ language, text }: TranslationOption) => {
      if (selectedSubField && subField !== undefined) {
        const field: ScheduleTypes.Option = { ...selectedSubField };
        field.translations = field.translations || { ...defaultTranslations };
        field.translations[language] = text;
        const fields = { ...subFields, [subField]: field };
        setSelectedSubField(field);
        setSubFields(fields);
      }
    },
    [selectedSubField, subField, subFields]
  );

  const handleRemoveOptionLanguage = useCallback(
    (language: AvailableLanguages) => {
      if (selectedOption && selectedOptionIndex !== undefined) {
        const option: ScheduleTypes.Option = { ...selectedOption };
        option.translations = option.translations || { ...defaultTranslations };
        delete option.translations[language];
        const opts = [...options];
        opts[selectedOptionIndex] = option;
        setOptions(opts);
      }
    },
    [selectedOption, selectedOptionIndex, options]
  );

  const handleRemoveSubFieldLanguage = useCallback(
    (language: AvailableLanguages) => {
      if (selectedSubField && subField !== undefined) {
        const field: ScheduleTypes.Option = { ...selectedSubField };
        field.translations = field.translations || { ...defaultTranslations };
        delete field.translations[language];
        const fields = { ...subFields };
        fields[subField] = field;
        setSubFields(fields);
      }
    },
    [selectedSubField, subField, options]
  );

  const handleRemoveLanguage = (lang: AvailableLanguages) => {
    const trans = { ...translations };
    delete trans[lang];
    setTranslations(trans);
  };

  const handleRemoveOption = (i: number) => {
    const opts = [...options];
    opts.splice(i, 1);
    setOptions(opts);
  };

  const openOptionTranslations = (i: number) => {
    setSelectedOptionIndex(i);
    optionsTranslations.openModal();
  };

  const openSubFieldTranslations = (subField: string) => {
    if (subFields?.[subField]) {
      setSubField(subField);
      setSelectedSubField({ ...subFields?.[subField] });
      subFieldTranslations.openModal();
    }
  };

  const updateSubFieldLabel = useCallback(
    (value: any, subFieldKey: any) => {
      if (field?.subFields?.[subFieldKey]) {
        const newOption: ScheduleTypes.Option = { ...field?.subFields?.[subFieldKey], label: value[subFieldKey] };

        setSubFields({ ...subFields, [subFieldKey]: newOption });
      }
    },
    [subFields]
  );

  const updateSubFieldRequired = useCallback(
    (value: any, subFieldKey: any) => {
      if (field?.subFields?.[subFieldKey]) {
        const newOption: ScheduleTypes.Option = { ...field?.subFields?.[subFieldKey], required: !!value[subFieldKey] };

        setSubFields({ ...subFields, [subFieldKey]: newOption });
      }
    },
    [subFields]
  );

  return (
    <>
      <Modal {...modalControl.modalProps}>
        <Modal.Header onClose={() => modalControl.closeModal()}>{t('{{mode}} Field', { mode })}</Modal.Header>
        <Modal.Body>
          <form
            {...formProps}
            css={css({
              padding: theme.spacing(1),
              margin: 'auto',
              maxWidth: 600,
              marginTop: theme.spacing(2),
            })}
          >
            <FormRow
              css={css({
                '> div': {
                  flexGrow: 1,
                },
                '> button': {
                  flexBasis: 'initial',
                },
              })}
            >
              <TextField label={t('Label')} {...getFieldProps('label')} />
              <NakedButton {...labelTranslations.triggerProps}>
                <Translate />
              </NakedButton>
            </FormRow>
            <FormRow>
              <CheckboxField
                disabled={field?.requiredIsNotOptional}
                label={t('Required')}
                {...getFieldProps('required')}
              />
            </FormRow>
            {field?.custom && (
              <FormRow>
                <DropdownField label={t('Type')} {...getFieldProps('type')}>
                  {Object.entries(typeMap).map(([key, value]) => (
                    <DropdownField.Option key={key} value={key}>
                      {value}
                    </DropdownField.Option>
                  ))}
                </DropdownField>
              </FormRow>
            )}

            {values?.type === 'dropdown' && (
              <FormRow>
                <div css={optionStyles}>
                  <label>{t('Options')}</label>
                  <dl>
                    {options.map(({ value, label }, i: number) => (
                      <li key={`${value}_${i}`}>
                        <dt>{label}</dt>
                        <dd>{value}</dd>
                        <NakedButton onClick={() => openOptionTranslations(i)}>
                          <Translate />
                        </NakedButton>
                        <NakedButton onClick={() => handleRemoveOption(i)}>
                          <Icon name='trash' />
                        </NakedButton>
                      </li>
                    ))}
                    <OptionEditor onAdd={handleAddOption} />
                  </dl>
                </div>
              </FormRow>
            )}

            {subFields && (
              <FormRow>
                <div css={subFieldStyles}>
                  <label>{t('Sub Fields')}</label>
                  <div className='sub-field-rows'>
                    {Object.entries(subFields).map(([subFieldKey, option], i: number) => (
                      <div className='sub-field-row' key={`${subFieldKey}_${i}`}>
                        <CustomField
                          field={{ type: 'text', label: titleCase(option.value), key: subFieldKey }}
                          value={option.label}
                          onChange={(value) => updateSubFieldLabel(value, subFieldKey)}
                        />
                        {values.required && (
                          <CustomField
                            field={{ type: 'checkbox', label: t('Required'), key: subFieldKey }}
                            value={option.required}
                            onChange={(value) => updateSubFieldRequired(value, subFieldKey)}
                          />
                        )}
                        <NakedButton onClick={() => openSubFieldTranslations(subFieldKey)}>
                          <Translate />
                        </NakedButton>
                      </div>
                    ))}
                  </div>
                </div>
              </FormRow>
            )}
            {(field?.custom || field?.supportsNewUserOnly) && (
              <FormRow>
                <CheckboxField label={t('For New Patients Only')} {...getFieldProps('newUserOnly')} />
              </FormRow>
            )}
          </form>
        </Modal.Body>
        <Modal.Actions
          onPrimaryClick={handleSubmit}
          primaryLabel={mode === 'Create' ? t('Add Field') : t('Update')}
          onSecondaryClick={() => modalControl.closeModal()}
          secondaryLabel={t('Cancel')}
          disablePrimary={!values.label || !values.type}
        />
      </Modal>

      <Modal {...labelTranslations.modalProps} maxWidth={600}>
        <Modal.Header>{t('Add Label Translations for the label: {{label}}', { label: values.label })}</Modal.Header>
        <Modal.Body>
          <TranslationViewer translations={translations} onRemove={handleRemoveLanguage} onAdd={handleAddTranslation} />
        </Modal.Body>
        <Modal.Actions onPrimaryClick={labelTranslations.closeModal} primaryLabel={t('Done')} />
      </Modal>

      <Modal {...optionsTranslations.modalProps} maxWidth={600}>
        <Modal.Header>
          {t('Add Option Translations for Option: {{optionLabel}}', { optionLabel: selectedOption?.label })}
        </Modal.Header>
        <Modal.Body>
          <TranslationViewer
            translations={selectedOption?.translations}
            onRemove={handleRemoveOptionLanguage}
            onAdd={handleOptionAddTranslation}
          />
        </Modal.Body>
        <Modal.Actions onPrimaryClick={optionsTranslations.closeModal} primaryLabel={t('Done')} />
      </Modal>

      <Modal {...subFieldTranslations.modalProps} maxWidth={600}>
        <Modal.Header>
          {t('Add Sub Field Translations for: {{subField}}', { subField: selectedSubField?.label })}
        </Modal.Header>
        <Modal.Body>
          <TranslationViewer
            translations={selectedSubField?.translations}
            onRemove={handleRemoveSubFieldLanguage}
            onAdd={handleAddSubFieldLanguage}
          />
        </Modal.Body>
        <Modal.Actions onPrimaryClick={subFieldTranslations.closeModal} primaryLabel={t('Done')} />
      </Modal>
    </>
  );
}
