import { FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { useAppScopeStore } from '@frontend/scope';
import { getSubscriptionChipData, getTrialChipData } from '../utils';
import { FeatureLocationTableData } from '../types';
import { useMemo } from 'react';

export const useFeatureLocationSubscriptionTableData = (
  data: FreeTrialSubscriptionTypes.IsMultiEligibleForSelfUpgradeOrTrialDetail[]
): FeatureLocationTableData[] => {
  const { accessibleLocationData } = useAppScopeStore();

  const tableData = useMemo(() => {
    return data.map((row) => ({
      ...row,
      locationName: accessibleLocationData[row.locationId]?.name || row.locationId,
      trialStatusChipData: getTrialChipData({ trialStatus: row.trialStatus }),
      subscriptionStatusChipData: getSubscriptionChipData({ subscriptionStatus: row.subscriptionStatus }),
    }));
  }, [data, accessibleLocationData]);

  return tableData;
};
