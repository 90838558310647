import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { AutoRecallAnalyticsApi, AutoRecallAnalyticsTypes } from '@frontend/api-analytics';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { DemoDataBanner, NWXNavigationModal } from '../components';
import { AutoRecallCharts, AutoRecallFilters, AutoRecallReport } from '../components/auto-recall';
import {
  useAutoRecallAnalyticsDemoData,
  useAutoRecallStore,
  useIsAutoRecallAnalyticsDemoAccount,
} from '../components/auto-recall/hooks';
import { queryKeys } from '../query-keys';
import { appendCurrentTimeZone } from '../utils';

export const AutoRecallAnalytics = () => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { filterHintText, filters, setDemoData, setIsDemoAccount } = useAutoRecallStore();
  const [apiPayload, setApiPayload] = useState<AutoRecallAnalyticsTypes.Filters>();
  const [summaryStats, setSummaryStats] = useState<AutoRecallAnalyticsTypes.RecallSummaryStats | undefined>();
  const [isFetchingTableData, setIsFetchingTableData] = useState<boolean>(false);
  const isDemoAccount = useIsAutoRecallAnalyticsDemoAccount();
  const demoData = useAutoRecallAnalyticsDemoData();

  const { data, isFetching: isFetchingSummaryData } = useScopedQuery({
    queryKey: queryKeys.autoRecallAnalytics(`summary${JSON.stringify(apiPayload)}-${isDemoAccount}`),
    queryFn: () => (isDemoAccount ? null : AutoRecallAnalyticsApi.getAutoRecallData(apiPayload || {})),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return isDemoAccount ? demoData?.chartsData : data;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const handleDateRangeChange = () => {
    const { startDate, endDate } = filters;

    if (!startDate || !endDate) {
      return;
    }

    const start = dayjs(startDate);
    const end = dayjs(endDate);

    setApiPayload({
      endDate: appendCurrentTimeZone(end.format('YYYY-MM-DDT23:59:59')),
      startDate: appendCurrentTimeZone(start.format('YYYY-MM-DDT00:00:00')),
    });
  };

  useEffect(() => {
    const notReceived = data?.FailedAttempt?.length || 0;
    const notScheduled = data?.Attempted?.length || 0;
    const scheduled = data?.Success?.length || 0;
    const total = scheduled + notReceived + notScheduled;

    setSummaryStats({ notReceived, notScheduled, scheduled, total });
  }, [data]);

  useEffect(() => {
    handleDateRangeChange();
  }, [filters]);

  useEffect(() => {
    setDemoData(demoData);
  }, [demoData]);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
  }, [isDemoAccount]);

  return (
    <>
      <NWXNavigationModal />
      {isDemoAccount && (
        <DemoDataBanner title={t("You're taking Auto Recall Analytics for a test drive with demo data!")} />
      )}
      <Page
        action={<AutoRecallFilters isLoadingData={isFetchingTableData || isFetchingSummaryData} />}
        css={styles.page}
        subtitle={t('Showing results for {{filtersInfo}}', { filtersInfo: filterHintText })}
        title={t('Auto Recall')}
      >
        <div css={styles.contentWrapper}>
          <AutoRecallCharts isLoading={isFetchingSummaryData} summaryStats={summaryStats} />
          <AutoRecallReport
            isLoading={isFetchingSummaryData}
            onFetchStateChange={setIsFetchingTableData}
            overviewData={data}
          />
        </div>
      </Page>
    </>
  );
};

const styles = {
  page: css`
    max-width: 100%;
  `,

  contentWrapper: css`
    width: 100%;
  `,
};
