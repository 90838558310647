import { NavSize } from './breakpoints';
import { useMediaMatches } from './use-media-matches';

export const useNavSize = (): NavSize => {
  const { matches } = useMediaMatches();
  const isWiderThanMobile = matches.mediumMin();
  const isMediumRange = matches.mediumMinMax();

  if (!isWiderThanMobile) {
    return NavSize.Mobile;
  } else if (isMediumRange) {
    return NavSize.Mini;
  } else {
    return NavSize.Desktop;
  }
};
