import { LocationFeatureResponse } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/location_features.pb';
import { CustomizationFlagTypes } from '@frontend/api-customization-flags';

type ShouldShowSubscribeCard = {
  flagDetails?: LocationFeatureResponse;
  verify: 'showSubscriptionCard' | 'showFreeTrialSubscriptionCard';
};

export const useShouldShowSubscribeCard = ({ flagDetails, verify }: ShouldShowSubscribeCard): boolean => {
  const isFreeTrialActive = !!flagDetails?.isFreeTrial;
  const isFeatureAlreadyActive = flagDetails?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;

  if (verify === 'showFreeTrialSubscriptionCard') {
    return !isFreeTrialActive && !isFeatureAlreadyActive;
  } else if (verify === 'showSubscriptionCard') {
    return isFreeTrialActive || !isFeatureAlreadyActive;
  }
  return false;
};
