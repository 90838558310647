import { SchemaSMSBlockNumberService } from '@frontend/schema';

type ToggleBlockArgs = {
  isBlocked: boolean;
  locationId: string;
  weaveUserId: string;
  personPhone: string;
  onSuccess?: (blockResult: boolean) => void;
  onError?: (blockResult: boolean) => void;
};

export const toggleBlock = async ({
  isBlocked,
  locationId,
  weaveUserId,
  personPhone,
  onSuccess,
  onError,
}: ToggleBlockArgs) => {
  try {
    isBlocked
      ? await SchemaSMSBlockNumberService.UnblockNumber({
          locationId,
          weaveUserId,
          personPhone,
        })
      : await SchemaSMSBlockNumberService.BlockNumber({
          locationId,
          weaveUserId,
          personPhone,
        });
    onSuccess?.(!isBlocked);
  } catch {
    onError?.(isBlocked);
  }
};
