import { useSoftphoneCallState } from '..';
import { ContextualCallInfo, OutgoingCall } from '../../../types';
import { useSoftphoneAudio } from '../../softphone-audio-provider';
import { useSoftphoneClient } from '../../softphone-client-provider';
import { useSoftphoneDialer } from '../../softphone-dialer-provider';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';
import { useSoftphoneSettings } from '../../softphone-settings-provider';
import { useToggleHold } from './use-hold-call';

export const usePlaceCall = () => {
  const client = useSoftphoneClient((ctx) => ctx.client);
  const domain = useSoftphoneClient((ctx) => ctx.domain);
  const resolvedDialAddress = useSoftphoneDialer((ctx) => ctx.resolvedDialAddress);
  const addCall = useSoftphoneCallState((ctx) => ctx.addCall);
  const setPrimaryCall = useSoftphoneCallState((ctx) => ctx.setPrimaryCall);
  const setIsPlaceCallLoading = useSoftphoneCallState((ctx) => ctx.setIsPlaceCallLoading);
  const play = useSoftphoneAudio((ctx) => ctx.play);
  const { emit } = useSoftphoneEventsEmitter();
  const { toggleHoldAll } = useToggleHold();
  const currentPhoneNumber = useSoftphoneSettings((ctx) => ctx.selectedOutboundPhoneNumber?.number);
  const restart = useSoftphoneSettings((ctx) => ctx.restart);

  const placeCallDirect = (numOrURI: string, callInfo?: ContextualCallInfo) => {
    if (!client) {
      return Promise.reject(new Error('No Client'));
    }

    if (!numOrURI) {
      return Promise.reject(new Error('No Phone number or URI'));
    }
    setIsPlaceCallLoading(true);
    const uri = (numOrURI.includes('@') ? numOrURI : `${numOrURI}@${domain}`).replace('sip:', '');

    return client
      .call(
        `sip:${uri}`,
        {
          extraHeaders: [`P-Asserted-Identity: <sip:${currentPhoneNumber}@${domain}>`],
        },
        {
          requestDelegate: {
            onReject(response) {
              console.log('onReject invite', response);
              if (response.message.statusCode === 403) {
                restart();
              }
            },
          },
        }
      )
      .then((inviter) => {
        const call = {
          id: inviter.id,
          inviter: inviter,
          placedAt: new Date(),
          onHoldSince: undefined,
          remoteParty: {
            displayName: inviter.remoteIdentity.displayName || undefined,
            uri: inviter.remoteIdentity.uri.user ?? 'unknown',
          },
          ...callInfo,
        } satisfies OutgoingCall;

        //Every time we place a new call, any existing calls are put on hold
        toggleHoldAll(true);
        addCall(call);
        //Every time we place a new call, we set it as the primary call
        setPrimaryCall(call.id);
        play.outgoingCall();
        emit('outgoing-call.sent', call);
        return call;
      })
      .catch((err) => {
        emit('outgoing-call.failed', err);
        throw err;
      })
      .finally(() => setIsPlaceCallLoading(false));
  };

  const placeCallFromDialer = (callInfo?: ContextualCallInfo) => {
    if (!resolvedDialAddress) {
      return Promise.reject(new Error('No Phone number or URI'));
    }

    return placeCallDirect(resolvedDialAddress, callInfo);
  };

  return {
    placeCallDirect,
    placeCallFromDialer,
  };
};
