import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { default as CallIntelBannerImg } from '../../../assets/call-intel-banner.png';
import { SCHEDULE_CALL_CHILLIPIPER_URL, SUBSCRIPTION_SETTINGS_URL } from '../../../constants/self-upgrade';
import { FeatureUpgradeModalComponentProps } from '../../../types';
import { FeatureUpgradeDetailsBaseModal } from '../FeatureUpgradeDetailsBaseModal';
import { CALL_INTEL_PRICE, CALL_INTEL_VIDEO_URL } from './constants';

export const CallIntelUpgradeDetailsModal: FC<FeatureUpgradeModalComponentProps> = (props) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'call-intel-details-modal' });

  const handleScheduleCall = () => {
    window.open(SCHEDULE_CALL_CHILLIPIPER_URL, '_blank');
  };

  return (
    <FeatureUpgradeDetailsBaseModal
      featureAccountSettingsUrl={SUBSCRIPTION_SETTINGS_URL}
      featureName={t('Call Intel')}
      onScheduleCallClick={handleScheduleCall}
      bundleFeature={t('Elite Feature')}
      {...props}
    >
      <FeatureUpgradeDetailsBaseModal.Title title={t('Call Intel')} price={CALL_INTEL_PRICE} />
      <FeatureUpgradeDetailsBaseModal.ScrollableContent>
        <FeatureUpgradeDetailsBaseModal.ScrollableContent.VideoText
          videoUrl={CALL_INTEL_VIDEO_URL}
          text={t(
            'Call Intelligence uses AI to analyze and summarize recorded office calls, identifying potential missed revenue opportunities and insights.'
          )}
        />
        <CallIntelFeatureCards />
        <FeatureUpgradeDetailsBaseModal.ScrollableContent.BannerText
          imageUrl={CallIntelBannerImg}
          imageAltText={t('Call Intel Banner')}
          title={t('Get Call Intel')}
        />
      </FeatureUpgradeDetailsBaseModal.ScrollableContent>
      <FeatureUpgradeDetailsBaseModal.Action />
    </FeatureUpgradeDetailsBaseModal>
  );
};

export const CallIntelFeatureCards: FC = () => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'call-intel-details-modal' });

  return (
    <div css={featureCardsLayoutStyles}>
      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard title={t('Save Money')} icon='dollar-sign-small'>
        <Text size='medium'>
          {t(
            'Stop overspending on staffing and unlock hidden potential. Call Intelligence provides valuable insights for just $199/month, saving you thousands compared to the average dental office employee cost of $4,500+.'
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>

      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard
        title={t('Optimize Your Practice')}
        icon='search-small'
      >
        <Text size='medium'>
          {t(
            "Imagine having a blind spot indicator for your calls. Call Intelligence reveals hidden opportunities you might miss, just like a car's indicator helps you avoid collisions. Gain valuable insights and optimize your practice's performance."
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>

      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard
        title={t('Improve Patient Experience')}
        icon='emoji-small'
      >
        <Text size='medium'>
          {t(
            'Analytics provide valuable information, but Call Intelligence bridges the gap by offering deeper context and understanding of your call data, allowing you to optimize operations and improve patient experience.'
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>
    </div>
  );
};

const featureCardsLayoutStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
});
