import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.neutral10};
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(2)};
`;
