import { Photos } from '@frontend/photos';
import { theme } from '@frontend/theme';
import { NakedButton, Text } from '@frontend/design-system';

type PersonSelectionListItemProps = {
  personId: string;
  personName: string;
  onSelect: () => void;
};

export const PersonSelectionListItem = ({ personId, personName, onSelect }: PersonSelectionListItemProps) => {
  return (
    <NakedButton
      onClick={onSelect}
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 1),
        borderBottom: `1px solid ${theme.colors.neutral20}`,
        width: '100%',
        ':hover': {
          backgroundColor: theme.colors.neutral5,
        },
        ':focus': {
          backgroundColor: theme.colors.neutral5,
          outline: 'none',
        },
      }}
      tabIndex={0}
    >
      <span
        css={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Photos.ContactProfilePhoto personId={personId} name={personName} disableClick />
        <Text weight='bold'>{personName}</Text>
      </span>
    </NakedButton>
  );
};
