import { Interpolation, Theme, css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { XIcon, useOnClickOutside } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { formatPhoneNumber } from '../utils/phone-utils';
import { backgroundColor } from '../utils/constants';
import { ReturnUseDialerProps } from './use-dialer-types';

export type DialerProps = {
  className?: string;
  customStyles?: Interpolation<Theme>;
  focusOnMount?: boolean;
  placeholderLabel?: string;
  currentDial: string | undefined;
  setCurrentDial: (value: string | undefined) => void;
} & ReturnUseDialerProps;

export const DialerInput = ({
  className,
  customStyles,
  inputState,
  inputRef,
  dispatch,
  handleKeyDown,
  focusOnMount = true,
  placeholderLabel,
  currentDial,
  setCurrentDial,
  children,
}: PropsWithChildren<DialerProps>) => {
  const container = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('generic-dialpad-accessories');

  useOnClickOutside({
    ref: container,
    handler: (e) => {
      //svgs seem to trigger whether they're inside the element or not, so this accounts for that
      const className = (e?.target as HTMLElement).classList;

      if (![...className].includes('x-icon')) {
        dispatch({ type: 'blur' });
      }
    },
  });

  const maskedDial = formatPhoneNumber(currentDial ?? '');

  useEffect(() => {
    if (focusOnMount) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <div ref={container} className={className} css={!!customStyles ? customStyles : style}>
      <label htmlFor='transfer-address'></label>
      <div className='input-wrap'>
        <input
          autoComplete='off'
          type='text'
          placeholder={placeholderLabel ? placeholderLabel : t('Name or Number')}
          aria-invalid={!!inputState.error && inputState.blurred}
          onFocus={() => dispatch({ type: 'focus' })}
          onPaste={(e) => dispatch({ type: 'set', payload: (e.target as HTMLInputElement).value })}
          onChange={(e) => setCurrentDial(e.target.value)}
          onKeyDown={handleKeyDown}
          value={maskedDial ?? ''}
          ref={inputRef}
          data-trackingid='generic-dialer-input'
        />
        {!!currentDial && <XIcon className='x-icon' onMouseDown={() => dispatch({ type: 'reset' })} size={16} />}
      </div>
      {children}
      {inputState.blurred && inputState.error && <p className='error-message'>{inputState.error}</p>}
    </div>
  );
};

const style = css`
  margin: auto;
  display: block;
  text-align: left;
  width: 90%;
  input {
    text-align: center;
    width: 100%;
    height: ${theme.spacing(6)};
    padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
    box-sizing: border-box;
    border-radius: ${theme.borderRadius.small};
    background: ${backgroundColor};
    outline: none;
    font-size: ${theme.fontSize(20)};
    font-weight: ${theme.font.weight.bold};
    color: ${theme.colors.text.white};
    margin-top: 2px;
    border: 1px solid ${theme.colors.neutral30};
    &:focus {
      border: 1px solid ${theme.colors.primary50};
    }
    &[aria-invalid='true'] {
      border: 1px solid ${theme.colors.critical30};
    }
    &.large {
      font-size: 1.8em;
    }
    &.medium {
      font-size: 1.5em;
    }
    &.small {
      font-size: 1.2em;
    }
    &::placeholder {
      color: ${theme.colors.neutral30};
      font-weight: bold;
    }
  }
  .input-wrap {
    position: relative;
    .x-icon {
      position: absolute;
      right: ${theme.spacing(1.25)};
      top: ${theme.spacing(2.25)};
      padding: 1px;
      cursor: pointer;
      color: ${theme.colors.neutral20};
      &:hover {
        color: ${theme.colors.white};
      }
    }
  }
  .error-message {
    color: ${theme.colors.critical30};
    margin-top: ${theme.spacing(0.5)};
    font-size: ${theme.fontSize(12)};
  }
`;
