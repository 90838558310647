import { useLocation } from '@tanstack/react-location';
import { useNotificationContext } from '@frontend/notifications';
import { usePhoneOverrideShallowStore } from '@frontend/shared';
import { useSoftphoneManager } from '@frontend/weave-softphone-manager';
import { NotificationsDisabledRibbon } from './notifications-disabled';
import { OverrideNotificationRibbon } from './override-notification';
import { SoftphoneInAnotherTabRibbon } from './softphone-in-another-tab';

export const RibbonManager = () => {
  const showSoftphoneDeferredTabRibbon = useSoftphoneManager((ctx) => ctx.deferredToOtherTab);
  const { maxLocationsSelected } = useNotificationContext();
  const { overrides } = usePhoneOverrideShallowStore('overrides');
  const enabledOverrides = overrides?.filter((override) => override.enabled) || [];
  const { current } = useLocation();
  const isWeaveApp = !current.pathname.startsWith('/portal');

  return (
    <div>
      {maxLocationsSelected && <NotificationsDisabledRibbon />}
      {showSoftphoneDeferredTabRibbon && <SoftphoneInAnotherTabRibbon />}
      {enabledOverrides.length > 0 && isWeaveApp && <OverrideNotificationRibbon />}
    </div>
  );
};
