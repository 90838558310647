import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { CountryCodes } from '@frontend/geography';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  Modal,
  Text,
  TextLink,
  useModalControl,
  NakedButton,
  FullscreenModal,
  useControlledField,
  CheckboxField,
} from '@frontend/design-system';
import { useStepControl } from '../../../hooks';
import { Step } from '../../common';
import { Card } from './card';
import { getInitialPortOrder, isCompletePortOrder, isIncompletePortOrder } from './helpers';
import { MultiplePortRequestModal } from './multiple-port-request-modal';
import { PortRequestCard } from './port-request-card';
import { PortRequestForm } from './port-request-form';

const MAX_PORT_ORDERS = 10;

export const PortRequestStep = () => {
  const { t } = useTranslation('onboarding');
  const { debug, intakeForm, updateIntakeForm, stepNavigation } = useStepControl();
  const [currentPortOrderIndex, setCurrentPortOrderIndex] = useState<number>(-1);
  const [portOrders, setPortOrders] = useState(intakeForm?.portOrders ?? []);
  const confirmSkipPortingModal = useModalControl();
  const skipPortingFieldProps = useControlledField({
    type: 'checkbox',
    value: intakeForm?.skipPortingSection ?? false,
    onChange: (skipPortingSection: boolean) => {
      if (skipPortingSection) {
        confirmSkipPortingModal.openModal();
      } else {
        updateIntakeForm({ ...intakeForm, skipPortingSection: false });
      }
    },
  });

  const isCanadianOffice = intakeForm?.billingAddress?.country === CountryCodes.Canada;
  const portRequestFormModalControl = useModalControl();
  const multiplePortRequestModalControl = useModalControl();
  const describePortingModalControl = useModalControl();
  const incompletePortRequestModalControl = useModalControl();

  const handleSkipPortingSectionConfirmClick = () => {
    setPortOrders([]);
    updateIntakeForm({
      ...intakeForm,
      skipPortingSection: true,
      // reset porting section related fields
      portOrders: null,
      acceptedPhonePortLoa: false,
      phoneAndInternetBundled: IntakeFormTypes.UserAnswer.Unanswered,
      otherServicesUsingPhoneLines: IntakeFormTypes.UserAnswer.Unanswered,
      stepProgress: (intakeForm?.stepProgress ?? []).filter(
        (step) => step.task !== IntakeFormTypes.Tasks.AccountInfo && step.task !== IntakeFormTypes.Tasks.PortingTerms
      ),
    });
    confirmSkipPortingModal.closeModal();
  };
  const handlePrevClick = () => {
    stepNavigation.onPrevClick({});
  };
  const handleNextClick = () => {
    stepNavigation.onNextClick({});
  };
  const handleStartNewPortOrder = () => {
    setCurrentPortOrderIndex(-1);
    portRequestFormModalControl.openModal();
  };
  const handleEditPortOrder = (index: number) => {
    setCurrentPortOrderIndex(index);
    portRequestFormModalControl.openModal();
  };
  const handlePortRequestFormModalClose = () => {
    portRequestFormModalControl.closeModal();
    setCurrentPortOrderIndex(-1);
  };
  const handleRemovePortOrder = (index: number) => {
    const newPortOrders = portOrders.filter((_, currIndex) => currIndex !== index);

    setPortOrders(newPortOrders);
    updateIntakeForm({ portOrders: newPortOrders }, true);
  };

  const savePortOrderData = (portOrder: IntakeFormTypes.IntakeFormPortOrder) => {
    let updatedPortOrders: IntakeFormTypes.IntakeFormPortOrder[];

    if (currentPortOrderIndex === -1) {
      updatedPortOrders = [...portOrders, portOrder];

      // Since this new port order will now be added to the portOrders array, the current
      // index should no longer be -1 but instead the index of this port order in the
      // array (which is the last index since it was appended at the end of the array).
      setCurrentPortOrderIndex(updatedPortOrders.length - 1);
    } else {
      // Updating an existing port order so replace it in the portOrders array.
      updatedPortOrders = portOrders.map((curr, index) => {
        if (index === currentPortOrderIndex) {
          return portOrder;
        }
        return curr;
      });
    }
    updateIntakeForm(
      intakeForm?.skipPortingSection
        ? { portOrders: updatedPortOrders, skipPortingSection: false }
        : { portOrders: updatedPortOrders },
      true
    );
    setPortOrders(updatedPortOrders);
  };

  // True if at least one port order in the portOrders array is incomplete, false otherwise
  const hasAnyIncompletePortOrder = useMemo(
    () => portOrders.some((portOrder) => isIncompletePortOrder(portOrder, isCanadianOffice)),
    [portOrders]
  );

  // True if at least one port order in the portOrders array is complete, false otherwise
  const hasAnyCompletePortOrder = useMemo(
    () => portOrders.some((portOrder) => isCompletePortOrder(portOrder, isCanadianOffice)),
    [portOrders]
  );
  const getPortOrderByIndex = (index: number) => {
    if (!portOrders || index === -1) {
      return getInitialPortOrder(intakeForm?.billingAddress?.country);
    }

    return portOrders[index];
  };

  return (
    <>
      <Step>
        <Step.Header title={t("Let's Submit Your Phone Number Port Request")} />
        <Step.Question
          text=''
          subtext={t(
            'Click the button below to fill out all the necessary information to submit a port request. We will need to collect your phone account information, phone numbers, and a copy of your most recent phone bill.'
          )}
        />
        <Step.Body>
          {portOrders.map((portOrder, index) => (
            <PortRequestCard
              key={index}
              portOrder={portOrder}
              isCanadianOffice={isCanadianOffice}
              onEdit={() => handleEditPortOrder(index)}
              onRemove={() => handleRemovePortOrder(index)}
            />
          ))}

          {portOrders.length < MAX_PORT_ORDERS && (
            <Card css={startNewPortRequestCardStyle} data-testid='portRequestCardContainer'>
              <NakedButton
                data-testid='startPortRequestButton'
                onClick={handleStartNewPortOrder}
                trackingId={`${IntakePrefixes.PortingInformation}-start-new-port-btn`}
              >
                <Icon name='plus' />
                <Text>{t('Start a New Port Request')}</Text>
              </NakedButton>
            </Card>
          )}

          <TextLink
            weight='bold'
            textAlign='center'
            size='large'
            data-testid='multiple-port-request-modal-button'
            trackingId={`${IntakePrefixes.PortingInformation}-submit-more-port-modal-btn`}
            {...multiplePortRequestModalControl.triggerProps}
            css={{ marginTop: theme.spacing(2) }}
          >
            {t('Do I need to submit more than one port request?')}
          </TextLink>

          <TextLink
            weight='bold'
            textAlign='center'
            size='large'
            data-testid='describe-porting-modal-button'
            trackingId={`${IntakePrefixes.PortingInformation}-what-does-port-mean-btn`}
            {...describePortingModalControl.triggerProps}
            css={{ marginTop: theme.spacing(2) }}
          >
            {t('What does phone number porting mean?')}
          </TextLink>

          {intakeForm?.isMultiForm && (
            <CheckboxField
              {...skipPortingFieldProps}
              name='skipPortingSection'
              label={t('Skip porting information section')}
              trackingId={`${IntakePrefixes.PortingInformation}-skip-porting-section-checkbox`}
              css={{ marginTop: theme.spacing(4) }}
            />
          )}
        </Step.Body>
        <Step.Navigation
          onPrevClick={handlePrevClick}
          onNextClick={
            hasAnyIncompletePortOrder && !intakeForm?.skipPortingSection
              ? incompletePortRequestModalControl.openModal
              : handleNextClick
          }
          disableNext={!intakeForm?.skipPortingSection && !hasAnyCompletePortOrder}
          nextButtonTestId='submitPortRequestNextButton'
          nextButtonTrackingId={`${IntakePrefixes.PortingInformation}-start-port-request-next-btn`}
          backButtonTrackingId={`${IntakePrefixes.PortingInformation}-start-port-request-back-btn`}
        />

        <FullscreenModal {...portRequestFormModalControl.modalProps} onClose={handlePortRequestFormModalClose}>
          <FullscreenModal.Body>
            <PortRequestForm
              portOrderInitialState={getPortOrderByIndex(currentPortOrderIndex)}
              debug={debug}
              intakeForm={intakeForm as IntakeFormTypes.IntakeForm}
              onClose={handlePortRequestFormModalClose}
              onSave={savePortOrderData}
            />
          </FullscreenModal.Body>
        </FullscreenModal>
        <MultiplePortRequestModal modalProps={multiplePortRequestModalControl.modalProps} />
        <Modal {...incompletePortRequestModalControl.modalProps} maxWidth={500}>
          <Modal.Header>{t('Incomplete Port Request')}</Modal.Header>
          <Modal.Body>
            <Text textAlign='center' css={{ marginBottom: theme.spacing(2) }}>
              {t(
                'You have one or more port requests that have not been completed. Incomplete port requests will not be submitted. Do you still wish to proceed?'
              )}
            </Text>
          </Modal.Body>
          <Modal.Actions
            primaryLabel={t('Continue')}
            onPrimaryClick={handleNextClick}
            onSecondaryClick={incompletePortRequestModalControl.closeModal}
          />
        </Modal>
        <Modal {...confirmSkipPortingModal.modalProps} maxWidth={500}>
          <Modal.Header>{t('Are you sure?')}</Modal.Header>
          <Modal.Body>
            <Text textAlign='center' css={{ marginBottom: theme.spacing(1) }}>
              {t(
                'All porting information steps will be skipped and any port request data that you may have entered will be lost.'
              )}
            </Text>
          </Modal.Body>
          <Modal.Actions
            primaryLabel={t("Yes, I'm sure")}
            secondaryLabel={t('Go back')}
            onSecondaryClick={confirmSkipPortingModal.closeModal}
            onPrimaryClick={handleSkipPortingSectionConfirmClick}
          />
        </Modal>
        <Modal {...describePortingModalControl.modalProps} maxWidth={500}>
          <Modal.Header>{t('Phone Number Porting')}</Modal.Header>
          <Modal.Body>
            <Text css={{ marginBottom: theme.spacing(2) }}>
              {t(
                'Porting is the action of moving a phone number from one service provider to another (like WEAVE!). In order to begin the porting process, we will need a few things from you:'
              )}
            </Text>
            <ul css={{ marginBottom: theme.spacing(2), li: { lineHeight: 1.5 } }}>
              <li>
                <Text>{t('your company name')}</Text>
              </li>
              <li>
                <Text>{t('the authorized user (The name of the person who originally created the account)')}</Text>
              </li>
              <li>
                <Text>{t('account number')}</Text>
              </li>
              <li>
                <Text>{t('service address')}</Text>
              </li>
            </ul>
            <Text css={{ marginBottom: theme.spacing(2) }}>
              {isCanadianOffice
                ? t(
                    "Some providers do also require a transfer pin. It is best to match this information to exactly what is on your invoice if possible. Once your number(s) are successfully ported to Weave, you'll be all set and ready to go!"
                  )
                : t(
                    "Some providers do also require a transfer pin. It is best to match this information to exactly what is on your invoice if possible. Don't worry, at this time we are only collecting your information. You will speak with your Implementation Manager before the port request is submitted. Once your number(s) are successfully ported to Weave, you'll be all set and ready to go!"
                  )}
            </Text>
          </Modal.Body>
          <Modal.Actions primaryLabel={t('Close')} onPrimaryClick={describePortingModalControl.closeModal} />
        </Modal>
      </Step>
    </>
  );
};

const startNewPortRequestCardStyle = css`
  display: flex;
  align-items: stretch;
  justify-content: center;
  > button {
    padding: ${theme.spacing(1.5, 0)};
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: ${theme.spacing(1)};
  }
`;
