import { useEffect, useRef, useState } from 'react';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { SMS } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { useTranslation } from '@frontend/i18n';
import { ThreadBodyUtils } from '@frontend/thread-body';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text } from '@frontend/design-system';
import { ShareInMessageVirtuosoContext, ThreadItem } from '../../types';
import { VirtuosoMessageBubble } from './virtuoso-message-bubble';

type ThreadBodyProps = {
  messages: SMS[];
  scheduledMessages: ScheduledSms[];
  context: ShareInMessageVirtuosoContext;
  fetchOlderMessages: () => void;
};

export const ThreadBody = ({ messages, scheduledMessages, context, fetchOlderMessages, ...rest }: ThreadBodyProps) => {
  const { t } = useTranslation('integrated-messaging');
  const [isAtTop, setIsAtTop] = useState(false);
  const ref = useRef<VirtuosoHandle>(null);

  const data = [...ThreadBodyUtils.createThreadDateList(messages), ...scheduledMessages];

  useEffect(() => {
    if (context.hasOlderMessages && isAtTop) fetchOlderMessages();
  }, [context.hasOlderMessages]);

  return (
    <Virtuoso<ThreadItem, ShareInMessageVirtuosoContext>
      // This key remounts the virtuoso component when the threadId changes as a way to reset its internal state
      key={context.threadId}
      data={data}
      ref={ref}
      alignToBottom
      followOutput={(isAtBottom) => {
        if (isAtBottom) return context.threadIsLoading ? 'auto' : 'smooth';
        return false;
      }}
      context={context}
      firstItemIndex={100000 - data.length}
      initialTopMostItemIndex={{ index: 'LAST', behavior: 'auto' }}
      itemContent={(_, message, context) => <VirtuosoMessageBubble message={message} context={context} />}
      startReached={() => {
        if (context.hasOlderMessages) fetchOlderMessages();
      }}
      atTopStateChange={(isAtTop) => setIsAtTop(isAtTop)}
      components={{
        Footer: ({ context }) => {
          if (context?.isNewThread) {
            return (
              <Text css={{ margin: 0, padding: theme.spacing(2, 0), textAlign: 'center' }}>
                {t('No messages yet.')}
              </Text>
            );
          }

          return null;
        },
        Header: ({ context }) => {
          if (context?.hasOlderMessages || context?.threadIsLoading) {
            return (
              <div css={{ display: 'flex', justifyContent: 'center', padding: theme.spacing(2, 0) }}>
                <SpinningLoader size='medium' />
              </div>
            );
          }

          if (!context?.isNewThread && !context?.hasOlderMessages) {
            return (
              <Text css={{ margin: 0, padding: theme.spacing(2, 0), textAlign: 'center' }}>
                🎉 {t('You have reached the end')}
              </Text>
            );
          }

          return null;
        },
      }}
      {...rest}
    />
  );
};
