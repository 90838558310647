import { css } from '@emotion/react';
import { FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { AwardIcon, CalendarIcon, Chip, Table, TableColumnConfig, TableRowActions } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FC, useMemo } from 'react';
import { useFeatureLocationSubscriptionTableData } from './hooks/useFeatureLocationSubscriptionTableData';
import { featureSignUpTrackingIds } from './trackingIds';
import { FeatureLocationTableData } from './types';

interface FeatureLocationSubscriptionTableProps {
  tableData: FreeTrialSubscriptionTypes.IsMultiEligibleForSelfUpgradeOrTrialDetail[];
  isLoading?: boolean;
  disableActions?: boolean;
  onTrialAction: (locationIds: string[]) => void;
  onUpgradeAction: (locationIds: string[]) => void;
  onScheduleCallAction?: () => void;
}

export const FeatureLocationSubscriptionTable: FC<FeatureLocationSubscriptionTableProps> = ({
  tableData,
  isLoading,
  disableActions,
  onTrialAction,
  onUpgradeAction,
  onScheduleCallAction,
}) => {
  const { t } = useTranslation('subscription', { keyPrefix: 'location-subscription-table' });

  const convertedTableData = useFeatureLocationSubscriptionTableData(tableData);

  const tableColumnData = useMemo<TableColumnConfig<FeatureLocationTableData>[]>(
    () => [
      {
        accessor: ({ locationName }) => locationName,
        cellRenderer: (locationName) => (
          <Chip.SingleChip css={{ maxWidth: theme.spacing(30) }}>{locationName}</Chip.SingleChip>
        ),
        id: 'locationName',
        Header: t('Location'),
        sortType: (rowA, rowB) => rowA.original.locationName.localeCompare(rowB.original.locationName),
      },
      {
        accessor: ({ subscriptionStatusChipData }) => subscriptionStatusChipData,
        cellRenderer: (subscriptionStatusChipData) => (
          <Chip variant={subscriptionStatusChipData.variant}>{subscriptionStatusChipData.label}</Chip>
        ),
        id: 'subscription',
        Header: t('Subscription'),
        sortType: (rowA, rowB) =>
          rowA.original.subscriptionStatusChipData.label.localeCompare(rowB.original.subscriptionStatusChipData.label),
      },
      {
        accessor: ({ trialStatusChipData }) => trialStatusChipData,
        cellRenderer: (trialStatusChipData) => (
          <Chip variant={trialStatusChipData.variant}>
            <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.25) }}>
              {trialStatusChipData.icon}
              {trialStatusChipData.label}
            </div>
          </Chip>
        ),
        id: 'trial',
        Header: t('Trial'),
        sortType: (rowA, rowB) =>
          rowA.original.trialStatusChipData.label.localeCompare(rowB.original.trialStatusChipData.label),
      },
    ],
    []
  );

  const rowActions = useMemo<TableRowActions<FeatureLocationTableData>>(
    () => ({
      actions: [
        {
          label: t('Upgrade Subscription'),
          Icon: AwardIcon,
          onClick: (row) => onUpgradeAction([row.locationId]),
          hide: (data) =>
            disableActions ||
            data.isStateActive ||
            data.subscriptionStatus !== FreeTrialSubscriptionTypes.SubscriptionStatusForSelfUpgrade.SUBSCRIBE,
          trackingId: featureSignUpTrackingIds.tableUpgradeSubscriptionAction,
        },
        {
          label: t('Start Free Trial'),
          Icon: AwardIcon,
          onClick: (row) => onTrialAction([row.locationId]),
          hide: (data) =>
            disableActions ||
            data.isStateActive ||
            data.trialStatus !== FreeTrialSubscriptionTypes.TrialStatus.TRIAL_ELIGIBLE,
          trackingId: featureSignUpTrackingIds.tableStartTrialAction,
        },
        {
          label: t('Schedule Call'),
          Icon: CalendarIcon,
          onClick: onScheduleCallAction,
          hide: (data) =>
            disableActions ||
            data.isStateActive ||
            data.subscriptionStatus !== FreeTrialSubscriptionTypes.SubscriptionStatusForSelfUpgrade.SCHEDULE_CALL,
          trackingId: featureSignUpTrackingIds.tableScheduleCallAction,
        },
      ],
    }),
    [disableActions]
  );

  return (
    <Table<FeatureLocationTableData>
      colConfig={tableColumnData}
      data={convertedTableData}
      wrapperStyle={tableStyles}
      rowActions={rowActions}
      isLoading={isLoading}
    />
  );
};

const tableStyles = css({
  padding: theme.spacing(2),
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.medium,
  boxShadow: theme.shadows.light,
});
