import {
  DropdownField,
  FormRow,
  Modal,
  Stepper,
  Text,
  TextField,
  useDebouncedValue,
  useFormField,
} from '@frontend/design-system';
import { useCreateSoftphoneContext } from '../../../../store/create-softphone-provider';
import { useTranslation } from '@frontend/i18n';
import { UsersApi, UsersQueries } from '@frontend/api-users';
import { FC, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { DevicesApi } from '@frontend/api-devices';
import { spacingStyles, type CreateSoftphonePropTypes, inputFieldStyles } from '..';
import { useQuery } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';

type Props = CreateSoftphonePropTypes;

export const FirstStep: FC<React.PropsWithChildren<Props>> = ({
  getFieldProps,
  values,
  isMultiLocation,
  locationIds,
}: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'create-new-softphone' });
  const locationId = locationIds[0];

  const { data: users } = useQuery({
    queryKey: [locationId, ...UsersQueries.queryKeys.users()],
    queryFn: () => UsersApi.list.users(isMultiLocation, locationId),
  });

  const errorMessage = useRef('');
  const [value, setValue] = useState(getFieldProps('extension').value);
  const debouncedSearch = useDebouncedValue(value);
  const [disableNextStepButton, setDisableNextStepButton] = useState(false);
  const { closeModal } = useCreateSoftphoneContext();

  const { mutate: mutateExtensionAvailability } = useMutation(
    (payload: string) => DevicesApi.checkExtensionAvailability(payload, locationId),
    {
      onSuccess: ({ availability, message }) => {
        if (availability === 'IN_USE' || availability === 'FORBIDDEN') {
          errorMessage.current = message;
        } else {
          errorMessage.current = '';
          getFieldProps('extension').onChange({ name: 'extension', value });
        }
      },
      onSettled: () => {
        setDisableNextStepButton(false);
      },
    }
  );

  const extensionFieldProps = useFormField(
    {
      required: true,
      type: 'text',
      value,
      validator: () => errorMessage.current,
      validateOnChange: true,
    },
    [value, errorMessage.current]
  );

  useEffect(() => {
    if (debouncedSearch) {
      mutateExtensionAvailability(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    setValue(extensionFieldProps.value);
  }, [extensionFieldProps.value]);

  const next = () => {
    const extension = getFieldProps('extension').value;
    const userFound = users?.find((user) => user.UserID === values?.userID);
    getFieldProps('firstName').onChange({ name: 'firstName', value: `${userFound?.FirstName}` });
    getFieldProps('deviceName').onChange({
      name: 'deviceName',
      value: `${extension + ' ' + userFound?.FirstName}'s Softphone`,
    });
    getFieldProps('internalIDName').onChange({
      name: 'internalIDName',
      value: `${extension + ' ' + userFound?.FirstName}'s Softphone`,
    });
  };

  return (
    <>
      <Modal.Header onClose={closeModal}>{t('Select User and Extension')}</Modal.Header>
      <Modal.Body>
        <Stepper.Content>
          <Text css={spacingStyles(3)}>
            {t('Select the user who will be assigned the softphone device and choose an extension number.')}
          </Text>
          <FormRow css={inputFieldStyles}>
            <DropdownField {...getFieldProps('userID')} label={t('Select a User')}>
              {!!users &&
                users
                  .filter((el) => !el.softphone)
                  .sort((a, b) => a.FirstName.localeCompare(b.FirstName))
                  .map((user) => {
                    return (
                      <DropdownField.Option key={user.UserID} value={user.UserID}>
                        {user.FirstName + ' ' + user.LastName}
                      </DropdownField.Option>
                    );
                  })}
            </DropdownField>
          </FormRow>
          <FormRow css={inputFieldStyles}>
            <TextField
              name={'Extension Number'}
              helperText={t('Choose an extension number that is not currently in use.')}
              label={t('Extension Number')}
              maxLength={6}
              autoComplete='off'
              {...extensionFieldProps}
              onKeyDown={() => {
                value.length < 6 ? setDisableNextStepButton(true) : undefined;
              }}
            />
          </FormRow>
        </Stepper.Content>
        <Stepper.ButtonBar>
          <Stepper.NextButton
            onClick={next}
            css={{ height: theme.spacing(5), width: 'fit-content' }}
            isValid={!errorMessage.current && !!debouncedSearch && !disableNextStepButton}
          >
            {t('Continue')}
          </Stepper.NextButton>
        </Stepper.ButtonBar>
      </Modal.Body>
    </>
  );
};
