import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { pluralize } from '../../../utils';
import { StatusText, merchantStatusStyles } from '../sections';

export const MerchantStatusGroup = () => {
  const { t } = useTranslation('payments', { keyPrefix: 'settings' });
  const { merchantsData } = useMerchant();

  const {
    locationCount,
    payoutsEnabled,
    payoutsDisabledCount,
    chargesEnabled,
    chargesDisabledCount,
    allMerchantsFetched,
  } = useMemo(() => {
    const merchantsDataArray = Object.values(merchantsData);
    const locationCount = merchantsDataArray.length;
    const payoutsDisabledCount = merchantsDataArray.filter((merchantData) => !merchantData.payoutsEnabled).length;
    const chargesDisabledCount = merchantsDataArray.filter((merchantData) => !merchantData.chargesEnabled).length;
    const allMerchantsFetched = merchantsDataArray.every(
      ({ merchantFetched, merchantLoading }) => merchantFetched && !merchantLoading
    );
    return {
      locationCount,
      allMerchantsFetched,
      payoutsDisabledCount,
      chargesDisabledCount,
      payoutsEnabled: payoutsDisabledCount <= 0,
      chargesEnabled: chargesDisabledCount <= 0,
    };
  }, [merchantsData]);

  return (
    <section>
      <Text size='large'>
        {t(`Viewing status for {{locationCount}}`, { locationCount })} location{pluralize(locationCount)}
      </Text>
      <Text
        size='medium'
        color='light'
        css={css`
          margin: ${theme.spacing(0, 0, 2)};
        `}
      >
        {t(`View one location to see merchant account details`)}
      </Text>
      {allMerchantsFetched && (
        <div css={merchantStatusStyles.accountInfoContainer}>
          <StatusText
            label={t('Payment Status')}
            status={chargesEnabled}
            count={!chargesEnabled ? chargesDisabledCount : 0}
            trackingId='paymentstatus'
          />
          <StatusText
            label={t('Payout Status')}
            status={payoutsEnabled}
            count={!payoutsEnabled ? payoutsDisabledCount : 0}
            trackingId='payoutstatus'
          />
        </div>
      )}
    </section>
  );
};
