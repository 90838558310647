import { useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { ServiceProvidersTypes } from '@frontend/api-service-providers';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { TextLink, Text } from '@frontend/design-system';
import { ProviderAvailability } from './provider-availability';
import { ProviderList } from './provider-list';

dayjs.extend(isoWeek);
dayjs.extend(isBetween);

interface Props {
  locationId: string;
  selectedApptType?: AppointmentTypesTypes.AppointmentType;
  selectedProvider?: ServiceProvidersTypes.ServiceProvider | string;
  selectedApptTypeProviders?: ServiceProvidersTypes.ServiceProvider[];
  selectedDateTime: string[];
  weekInView: string;
  apptLeadTimeDate: string;
  setWeekInView: (weekInView: string) => void;
  clearSelected: () => void;
  setSelectedDateTimes: (dateTime: string[]) => void;
  setSelectedProvider: (provider: ServiceProvidersTypes.ServiceProvider | string) => void;
  reqNumApptTimes: number;
  setAvailableOpenings: (numOfOpenings: number) => void;
}

export default function ProviderSelect({
  locationId,
  selectedApptType,
  selectedProvider,
  selectedApptTypeProviders,
  selectedDateTime,
  weekInView,
  setWeekInView,
  apptLeadTimeDate,
  clearSelected,
  setSelectedDateTimes,
  setSelectedProvider,
  reqNumApptTimes,
  setAvailableOpenings,
}: Props) {
  const { t } = useTranslation('schedule');
  const [dateIsDisabled, setDateIsDisabled] = useState(true);
  const [day, setDay] = useState('');

  const selectProvider = (provider: ServiceProvidersTypes.ServiceProvider | string, day?: string) => {
    setSelectedProvider(provider);
    day && setDay(day.toLocaleLowerCase());
  };

  const displayWeekRange = () => {
    return `${dayjs(weekInView).startOf('isoWeek').format('ddd, MMM D')} - ${dayjs(weekInView)
      .endOf('isoWeek')
      .format('ddd, MMM D')}`;
  };

  const nextWeekInView = () => {
    const nextWeekStart = dayjs(weekInView).startOf('isoWeek').add(7, 'day').format();
    setWeekInView(nextWeekStart);
    setDateIsDisabled(false);
  };

  const previousWeekInView = () => {
    const previousWeek = dayjs(weekInView).startOf('isoWeek').subtract(7, 'day').format();
    const dateCheck = dayjs(previousWeek).isBefore(apptLeadTimeDate, 'day') ? apptLeadTimeDate : previousWeek;

    const isInWeek = dayjs(apptLeadTimeDate).isBetween(
      formatDate(previousWeek, 'YYYY-MM-DD'),
      dayjs(previousWeek).endOf('isoWeek').format('YYYY-MM-DD'),
      'day',
      '[]'
    );

    if (isInWeek) {
      setWeekInView(dateCheck);
      setDateIsDisabled(false);
    }

    if (previousWeek > apptLeadTimeDate) {
      setWeekInView(dateCheck);
      setDateIsDisabled(false);
    } else {
      setDateIsDisabled(true);
    }
  };

  return (
    <div>
      {selectedProvider && selectedApptType ? (
        <>
          <TextLink
            css={{
              margin: theme.spacing(2, 0, 4, 0),
              cursor: 'pointer',
              color: theme.colors.neutral70,
              display: 'block',
            }}
            onClick={() => {
              setDay('');
              clearSelected();
              setAvailableOpenings(0);
            }}
          >
            {'< '}
            {t('Back to provider list')}
          </TextLink>
          <ProviderAvailability
            selectedProvider={selectedProvider}
            day={day}
            locationId={locationId}
            weekInView={weekInView}
            apptType={selectedApptType}
            selected={selectedDateTime}
            setSelected={setSelectedDateTimes}
            reqNumApptTimes={reqNumApptTimes}
            setAvailableOpenings={setAvailableOpenings}
          />
        </>
      ) : (
        <>
          <Text>
            {t(
              'Browse through the list of providers and check their upcoming appointment availability with just a glance. If you need more details, like specific time slots, just click on their name in the table.'
            )}
          </Text>
          <>
            {!selectedApptTypeProviders?.length ? (
              <Text as='h3' color='light' className='left'>
                {t('No Providers Available for your selected appointment type')}
              </Text>
            ) : (
              <div css={styles.gridLayout}>
                <div className='right'>
                  <Text
                    as='h3'
                    weight='bold'
                    size='large'
                    css={{
                      display: 'inline',
                      marginRight: theme.spacing(1),
                    }}
                  >
                    {displayWeekRange()}
                  </Text>
                  <Icon
                    name='caret-left'
                    onClick={() => previousWeekInView()}
                    aria-disabled={dateIsDisabled}
                    css={styles.caretIcon(dateIsDisabled)}
                  />
                  <Icon name='caret-right' onClick={() => nextWeekInView()} css={styles.caretIcon()} />
                </div>
                <div className='content'>
                  <ProviderList
                    providers={selectedApptTypeProviders}
                    apptType={selectedApptType}
                    locationId={locationId}
                    selectProvider={selectProvider}
                    weekInView={weekInView}
                  />
                </div>
              </div>
            )}
          </>
        </>
      )}
    </div>
  );
}

const styles = {
  gridLayout: css({
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: '"left right" "content content"',
    '@media only screen and (max-width: 650px)': {
      gridTemplateAreas: '"left left" "right right" "content content"',
    },
    '.content': {
      maxHeight: 380,
      overflowY: 'auto',
      gridArea: 'content',
      justifySelf: 'left',
      width: '100%',
      borderTop: `1px solid ${theme.colors.neutral20}`,
      marginTop: theme.spacing(2),
    },
    '.content .chip': {
      maxWidth: 'unset',
    },
    '.left': {
      gridArea: 'left',
      justifySelf: 'start',
      alignSelf: 'center',
      fontSize: theme.fontSize(16),
      marginBottom: theme.spacing(0),
      '@media only screen and (max-width: 650px)': {
        margin: theme.spacing(0, 0, 1, 0),
      },
    },
    '.right': {
      gridArea: 'right',
      justifySelf: 'end',
      alignSelf: 'center',
      color: theme.colors.neutral90,
      textAlign: 'right',
      h3: {
        fontSize: theme.fontSize(20),
        marginBottom: theme.spacing(0),
      },
      '@media only screen and (max-width: 650px)': {
        svg: {
          marginLeft: theme.spacing(2),
        },
      },
    },
  }),
  caretIcon: (disabled?: boolean) =>
    css({
      color: disabled ? theme.colors.neutral20 : theme.colors.neutral90,
      verticalAlign: 'sub',
      cursor: disabled ? '' : 'pointer',
    }),
};
