import { Media } from '@weave/schema-gen-ts/dist/schemas/massemail/templates/v1/templates.pb';
import { keyBy } from 'lodash-es';
import { UseMutationOptions, useQueryClient, UseQueryOptions, useMutation, useQueries } from 'react-query';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { Unpromise } from '@frontend/types';
import {
  deleteMedia,
  getAttachmentMedia,
  getGalleryMedia,
  getLogoMedia,
  getSystemMedia,
  uploadMedia,
  getSingleMmsMedia,
} from './api';
import { MediaUploadResponse, MediaUploadTypes, UploadMediaPayload } from './types';
import { formatMedia } from './utils';

export const queryKeys = {
  attachment: ['media', 'attachment'],
  gallery: ['media', 'gallery'],
  system: ['media', 'system'],
  galleryKeyed: ['media', 'gallery', 'keyed'],
  systemKeyed: ['media', 'system', 'keyed'],
  categorized: ['media', 'categorized'],
  primaryLogo: ['media', 'logo', 'primary'],
  mmsMedia: ['media', 'mms'],
};

export const getScopedQueryKey = (locationIds: string | string[], key: string[]) =>
  Array.isArray(locationIds) ? [locationIds, ...key] : [[locationIds], ...key];

export const useGetSystemMedia = () => useScopedQuery({ queryKey: queryKeys.system, queryFn: getSystemMedia });

export const useGetAttachmentMedia = () =>
  useScopedQuery({ queryKey: queryKeys.attachment, queryFn: getAttachmentMedia });

export const useGetGalleryMedia = () =>
  useScopedQuery({
    queryKey: queryKeys.gallery,
    queryFn: getGalleryMedia,
  });

export const useGetGalleryMediaKeyed = (options: UseQueryOptions<Record<string, MediaUploadResponse>> = {}) => {
  return useScopedQuery({
    queryKey: queryKeys.galleryKeyed,
    queryFn: () =>
      getGalleryMedia().then((gallery) => {
        return keyBy(gallery, (item) => item.ID);
      }),
    ...options,
  });
};

export const useGetSystemMediaKeyed = (options: UseQueryOptions<Record<string, MediaUploadResponse>> = {}) => {
  return useScopedQuery({
    queryKey: queryKeys.systemKeyed,
    queryFn: () =>
      getSystemMedia().then((gallery) => {
        return keyBy(gallery, (item) => item.ID);
      }),
    ...options,
  });
};

type MediaTemplates = Record<'media', Media[]>;

export const useDeleteMedia = (options: Partial<UseMutationOptions<unknown, unknown, string>> = {}) => {
  const { onMutate, onError, ...rest } = options;
  const { singleLocationId } = useAppScopeStore();
  const queryClient = useQueryClient();
  const galleryKey = getScopedQueryKey(singleLocationId, queryKeys.gallery);
  const categorizedKey = getScopedQueryKey(singleLocationId, queryKeys.categorized);
  return useMutation({
    mutationFn: (mediaId: string) => deleteMedia(mediaId, MediaUploadTypes.Gallery),
    mutationKey: [...galleryKey, 'delete'],
    onMutate: async (mediaId: string) => {
      const prev = await queryClient.getQueryData<MediaTemplates>(categorizedKey);
      queryClient.setQueryData<MediaTemplates>(categorizedKey, (old) => ({
        media: old?.media?.filter((item) => item.id !== mediaId) || [],
      }));
      await onMutate?.(mediaId);
      return { prev };
    },
    onError: (_err, _payload, context) => {
      if (!context?.prev) {
        return;
      }
      queryClient.setQueryData(categorizedKey, context.prev);
      onError?.(_err, _payload, context);
    },
    ...rest,
  });
};

export const useUploadMedia = (
  options: Partial<UseMutationOptions<MediaUploadResponse, unknown, UploadMediaPayload>> = {},
  type: MediaUploadTypes,
  locationId?: string
) => {
  const { onSuccess, ...rest } = options;
  const { singleLocationId } = useAppScopeStore();
  const queryClient = useQueryClient();
  const attachmentKey = getScopedQueryKey(locationId ?? singleLocationId, queryKeys.attachment);
  const galleryKey = getScopedQueryKey(locationId ?? singleLocationId, queryKeys.gallery);
  const categorizedKey = getScopedQueryKey(locationId ?? singleLocationId, queryKeys.categorized);
  return useMutation({
    mutationFn: (media) => uploadMedia(media),
    mutationKey: [...categorizedKey, 'upload'],
    onSuccess: (response, payload, context) => {
      if (type === MediaUploadTypes.Attachment) {
        queryClient.setQueryData<MediaUploadResponse[]>(attachmentKey, (old) => [...(old ?? []), response]);
      } else {
        queryClient.setQueryData<{ media: MediaUploadResponse[] }>(galleryKey, (old) => ({
          media: [...(old?.media ?? []), response],
        }));
      }
      queryClient.setQueryData<MediaTemplates>(categorizedKey, (old) => {
        const newMedia = formatMedia(response);
        return {
          media: [newMedia, ...(old?.media || [])],
        };
      });
      onSuccess?.(response, payload, context);
    },
    ...rest,
  });
};

export const useGetPrimaryLogo = (
  options: UseQueryOptions<Unpromise<ReturnType<typeof getLogoMedia>>[number]> = {}
) => {
  return useScopedQuery({
    queryKey: queryKeys.primaryLogo,
    queryFn: () => getLogoMedia().then((medias) => medias[0]),
    ...options,
  });
};

type MediaResult = {
  mediaId: string;
  src: string;
};
type UseMmsMediaArgs = {
  mediaIds: string[];
  locationId?: string;
};
export const useMmsMedia = <E = unknown>(
  { mediaIds, locationId }: UseMmsMediaArgs,
  options?: Omit<UseQueryOptions<string, E, MediaResult>, 'select'>
) => {
  return useQueries(
    mediaIds.map((id) => ({
      queryKey: [...queryKeys.mmsMedia, { id, locationId }],
      queryFn: () => getSingleMmsMedia(id, locationId),
      select: (data: Unpromise<ReturnType<typeof getSingleMmsMedia>>): MediaResult => ({
        mediaId: id,
        src: data,
      }),
      ...options,
    }))
  );
};
export type UseMmsMediaItem = ReturnType<typeof useMmsMedia>[number];
