import { InstructionFields } from './base';
import { CallQueuePayload, InstructionFieldsProps } from '../types';
import { useQuery } from 'react-query';

import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { CallQueueApi } from '@frontend/api';

export const CallQueueInstructionField = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<CallQueuePayload>['onChange'];
  option: CallQueuePayload;
}) => {
  const { data = [], isLoading } = useQuery({
    queryKey: queryKeys.callQueues(),
    queryFn: () => {
      return CallQueueApi.list();
    },
    select: (data) => {
      return data
        .sort((a, b) => a.Name.localeCompare(b.Name))
        .map((callQueue) => ({
          value: callQueue.ID,
          label: callQueue.Name,
        }));
    },
  });

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<CallQueuePayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          callQueueId: data ?? [],
        },
        value: {
          callQueueId: option.callQueueId,
          callerName: option.callerName,
        },
      }}
    />
  );
};

const schema = {
  type: 'callQueue',
  fields: {
    callQueueId: {
      label: 'Call Queue',
      name: 'callQueueId',
      type: 'dropdown',
      helperText: 'This is some helper text for A',
    },
    callerName: {
      label: 'Caller Label',
      name: 'callerName',
      type: 'text',
    },
  },
} as const;
