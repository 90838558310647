import { css } from '@emotion/react';
import { OfficeHoursApi } from '@frontend/api-office-hours';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import { Alert } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { OfficeHoursSettings } from './OfficeHoursSettings';

type OfficeHoursSettingsProps = {
  locationId: string;
};

export const OfficeHoursGlobalSettings = ({ locationId }: OfficeHoursSettingsProps) => {
  const { t } = useTranslation('schedule');

  const {
    isLoading: isLoadingOfficeHours,
    isError: officeHoursFetchError,
    isFetching: isFetchingOfficeHours,
    refetch: refetchData,
    data: officeHoursData,
  } = useLocalizedQuery({
    queryKey: queryKeys.officeClosedTimes(locationId),
    queryFn: () => OfficeHoursApi.getOfficeHours(locationId),
    retry: 1,
  });

  return (
    <div css={officeHoursContainerStyles}>
      {officeHoursFetchError && (
        <Alert type='error'>{t('Unable to fetch Office Hours. Please try again later.')}</Alert>
      )}
      <OfficeHoursSettings
        locationId={locationId}
        isLoading={isLoadingOfficeHours || isFetchingOfficeHours}
        officeHoursData={officeHoursData}
        refetchOfficeHours={refetchData}
      />
    </div>
  );
};

const officeHoursContainerStyles = css({
  boxShadow: theme.shadows.light,
  backgroundColor: theme.colors.white,
});
