import { FC } from 'react';
import { theme } from '@frontend/theme';
import { Chip, Dot, Text } from '@frontend/design-system';

interface InfoModuleComponentProps {
  title: string;
  data: string | null;
  additionInfo?: string | null;
  additionData?: string | null;
}
export const InfoModuleComponent: FC<InfoModuleComponentProps> = ({ title, data, additionInfo, additionData }) => {
  return (
    <>
      <Text color='light' size='medium'>
        {title}
      </Text>
      <Text css={{ fontSize: theme.fontSize(18) }} weight='bold'>
        {data}
      </Text>

      {additionData && (
        <Text color='light' size='medium'>
          {additionData}
        </Text>
      )}
      {additionInfo && (
        <Chip leftElement={<Dot color='warning' css={{ position: 'relative' }} />} variant='neutral'>
          {additionInfo}
        </Chip>
      )}
    </>
  );
};
