import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { ScheduleTypes } from '@frontend/api-schedule';
import { createShallowStore, createStore } from '@frontend/store';

interface AppointmentsFilterDefaultDataStore {
  defaultLocationIds: string[];
  defaultProviders: ScheduleTypes.SchemaProvider[];
  defaultAppointmentTypes: AppointmentTypesTypes.AppointmentType[];
  hasActiveFilters: boolean;
  selectedProviders: ScheduleTypes.SchemaProvider[];
  selectedProviderIds: string[];
  selectedAppointmentTypesIds: string[];
  isUnconfirmedOnly: boolean;
  setDefaultLocationIds: (locationIds: string[]) => void;
  setDefaultProviders: (providers: ScheduleTypes.SchemaProvider[]) => void;
  setDefaultAppointmentTypes: (appointmentTypes: AppointmentTypesTypes.AppointmentType[]) => void;
  setHasActiveFilters: (hasActiveFilters: boolean) => void;
  setSelectedProviders: (providers: ScheduleTypes.SchemaProvider[]) => void;
  setSelectedProviderIds: (providerIds: string[]) => void;
  setSelectedAppointmentTypesIds: (appointmentTypesIds: string[]) => void;
  setIsUnconfirmedOnly: (isUnconfirmedOnly: boolean) => void;
  resetFiltersData: () => void;
}

export const useAppointmentFilterDefaultDataStore = createStore<AppointmentsFilterDefaultDataStore>(
  (set) => ({
    defaultLocationIds: [],
    defaultProviders: [],
    defaultAppointmentTypes: [],
    hasActiveFilters: false,
    selectedProviders: [],
    selectedProviderIds: [],
    selectedAppointmentTypesIds: [],
    isUnconfirmedOnly: false,
    setHasActiveFilters: (hasActiveFilters: boolean) => {
      set({ hasActiveFilters });
    },
    setDefaultLocationIds: (locationIds: string[]) => {
      set({ defaultLocationIds: locationIds });
    },
    setDefaultProviders: (providers: ScheduleTypes.SchemaProvider[]) => {
      set({ defaultProviders: providers });
    },
    setDefaultAppointmentTypes: (appointmentTypes: AppointmentTypesTypes.AppointmentType[]) => {
      set({ defaultAppointmentTypes: appointmentTypes });
    },
    setSelectedProviders: (providers: ScheduleTypes.SchemaProvider[]) => {
      set({ selectedProviders: providers });
    },
    setSelectedProviderIds: (providerIds: string[]) => {
      set({ selectedProviderIds: providerIds });
    },
    setSelectedAppointmentTypesIds: (appointmentTypesIds: string[]) => {
      set({ selectedAppointmentTypesIds: appointmentTypesIds });
    },
    setIsUnconfirmedOnly: (isUnconfirmedOnly: boolean) => {
      set({ isUnconfirmedOnly });
    },
    resetFiltersData: () => {
      set({
        selectedProviders: [],
        selectedProviderIds: [],
        selectedAppointmentTypesIds: [],
        isUnconfirmedOnly: false,
      });
    },
  }),
  {
    name: 'AppointmentsFilterDefaultData',
  }
);

export const useAppointmentFilterDefaultDataShallowStore = createShallowStore<AppointmentsFilterDefaultDataStore>(
  useAppointmentFilterDefaultDataStore
);
