import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import { ScheduleTypes } from '@frontend/api-schedule';
import { ServiceProvidersApi, ServiceProvidersConstants } from '@frontend/api-service-providers';
import { EmptyStates } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Accordion,
  DatePickerField,
  Heading,
  ListRow,
  NakedButton,
  NakedUl,
  SpinningLoader,
  Text,
  useFormField,
} from '@frontend/design-system';
import { queryKeys } from '../../../../../../query-keys';
import { useScheduleActionsContainerMethod } from '../../../../../context/ScheduleActionsContainerContext';
import { useGetCombinedProvidersList } from '../../../../../hooks';
import { useAppointmentsInfoShallowStore } from '../../../../../hooks/use-appointments-info-store';
import { useGetProvidersListForMultipleLocationIds } from '../../../../../hooks/use-get-providers-list';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../../../../stores/use-schedule-actions-appointment-list-store';
import { PatientListRow } from '../../../components/PatientListRow';
import { FiltersContainer } from './Filters/FilterContainer';
import { ScheduleMassMessageTrackingIds } from './trackingIds';

export const ScheduleMassMessages = () => {
  const todayDate = dayjs().format('MM/DD/YYYY').toString();
  const { selectedLocationIds } = useAppScopeStore();

  const { setAppointmentList, appointmentList, setDefaultAppointmentList, statusFilter, setDefaultProviderList } =
    useScheduleActionsAppointmentListInfoShallowStore(
      'setAppointmentList',
      'appointmentList',
      'setDefaultAppointmentList',
      'statusFilter',
      'setDefaultProviderList'
    );

  const { selectedDate: scheduleActionsDate, setSelectedDate } = useScheduleActionsContainerMethod();

  const { selectedDate: calendarViewDate } = useAppointmentsInfoShallowStore('selectedDate');

  const selectedDate = scheduleActionsDate || calendarViewDate;
  const calendarProps = useFormField({
    type: 'datePicker',
    value: selectedDate || (todayDate as string),
  });

  const isValidDate = calendarProps?.value && !calendarProps.error;

  useEffect(() => {
    if (isValidDate) {
      const formattedDate = dayjs(String(calendarProps.value)).format('MM/DD/YYYY').toString();
      setSelectedDate(formattedDate);
    }
  }, [calendarProps?.value, isValidDate]);

  const [startDate, endDate] = useMemo(() => {
    if (isValidDate) {
      const startDateInUTC = dayjs(String(calendarProps.value)).utc().toISOString();
      const endDateInUTC = dayjs(String(calendarProps.value)).add(1, 'day').utc().toISOString();
      return [startDateInUTC, endDateInUTC];
    }
    return ['', ''];
  }, [calendarProps?.value, isValidDate]);

  const { data: appointmentsData, isLoading: isAppointmentsDataLoading } = useLocalizedQuery({
    queryKey: queryKeys.appointments(selectedLocationIds, startDate, endDate),
    queryFn: () =>
      ServiceProvidersApi.listAppointments({
        between: { start: startDate, end: endDate },
        equals: { locationIds: selectedLocationIds },
      }),
    retry: 1,
    cacheTime: 10 * ServiceProvidersConstants.ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    staleTime: 10 * ServiceProvidersConstants.ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    enabled: !!selectedLocationIds && !!startDate && !!endDate,
  });

  useEffect(() => {
    !statusFilter && setAppointmentList(appointmentsData?.appointments ?? []);
    setDefaultAppointmentList(appointmentsData?.appointments ?? []);
  }, [appointmentsData?.appointments]);

  // get and set the provider list from the store
  const { data: providers } = useGetProvidersListForMultipleLocationIds({ locationIds: selectedLocationIds });

  // get the full provider list, including the providers that have appointments but no official provider created. This is to ensure that all providers are displayed in the provider filter, and all appointments can be filtered by their assigned provider.
  const fullProviderList = useGetCombinedProvidersList(
    appointmentsData ?? {},
    (providers ?? []) as ScheduleTypes.Provider[]
  );

  useEffect(() => {
    setDefaultProviderList(fullProviderList ?? []);
  }, [fullProviderList]);

  if (isAppointmentsDataLoading) {
    return <SpinningLoader />;
  }

  return (
    <>
      <div css={{ display: 'flex', gap: 8, marginBottom: theme.spacing(2), alignItems: 'flex-start' }}>
        <DatePickerField
          data-trackingid={ScheduleMassMessageTrackingIds.datePickerField}
          {...calendarProps}
          css={{ width: '190px', backgroundColor: theme.colors.white }}
          name=''
          label={''}
        />

        <FiltersContainer />
      </div>
      {selectedLocationIds.length === 1 ? (
        <SingleLocationView locationId={selectedLocationIds[0]} appointments={appointmentList || []} />
      ) : (
        <MultiLocationView appointments={appointmentList || []} />
      )}
    </>
  );
};

type SingleLocationViewProps = {
  appointments: Appointment[] | undefined;
  locationId: string;
};

const SingleLocationView = ({ appointments, locationId }: SingleLocationViewProps) => {
  const { t } = useTranslation('scheduleCalendarActions');
  const { setHideTabs, setSelectedLocationId } = useScheduleActionsContainerMethod();

  return (
    <>
      <ListRow
        css={css`
          padding: ${theme.spacing(2)};
          background-color: white;
        `}
      >
        <ListRow.Content>
          <Heading level={3}>{t('{{count}} Patients', { count: appointments?.length })}</Heading>
        </ListRow.Content>
        <ListRow.Trail css={contactPatientsStyles}>
          <NakedButton
            id='contact-patients-btn'
            trackingId={ScheduleMassMessageTrackingIds.contactPatientsBtn}
            disabled={!appointments?.length}
            onClick={() => {
              setSelectedLocationId(locationId);
              setHideTabs(true);
            }}
          >
            {t('Contact Patients')}
          </NakedButton>
        </ListRow.Trail>
      </ListRow>
      <NakedUl
        css={css`
          overflow: auto;
          max-height: 450px;
          background-color: white;
        `}
      >
        {!!appointments?.length ? (
          <>
            {appointments.map((appt) => {
              return (
                <PatientListRow
                  appointments={appointments}
                  key={appt.id}
                  appt={appt}
                  shouldShowAppointmentModal
                  locationId={locationId}
                />
              );
            })}
          </>
        ) : (
          <EmptyStates emptyStateConfig={{ type: 'schedule' }} />
        )}
      </NakedUl>
    </>
  );
};

type MultiLocationViewProps = {
  appointments: Appointment[] | undefined;
};

const MultiLocationView = ({ appointments }: MultiLocationViewProps) => {
  const { getLocationName, selectedLocationIds } = useAppScopeStore();

  const multiLocationGrouped = useMemo(() => {
    return appointments?.reduce((acc, obj) => {
      if (!obj.locationId) return acc;
      if (!acc[obj.locationId]) {
        acc[obj.locationId] = [];
      }
      acc[obj.locationId].push(obj);
      return acc;
    }, {} as { [key: string]: Appointment[] });
  }, [appointments]);

  const numOfAppointments = (locationId: string) => Object.keys(multiLocationGrouped?.[locationId] ?? '').length;
  return (
    <>
      <Accordion variant='location' size='large' showBoxShadow chevronSize={16}>
        {selectedLocationIds?.map((locationId: string) => (
          <Accordion.Item key={locationId} value={locationId}>
            <Accordion.Header disabled={!numOfAppointments(locationId)} css={{ height: '52px' }}>
              <Accordion.Header.Location title={getLocationName(locationId) ?? 'Default'} />
              <Text color={!numOfAppointments(locationId) ? 'disabled' : 'default'} css={{ marginLeft: 'auto' }}>
                {numOfAppointments(locationId)}
              </Text>
            </Accordion.Header>
            <Accordion.Body css={{ padding: 0 }}>
              <SingleLocationView locationId={locationId} appointments={multiLocationGrouped?.[locationId]} />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

const contactPatientsStyles = css({
  '#contact-patients-btn:hover': {
    backgroundColor: `${theme.colors.primary5} !important`,
  },
  '#contact-patients-btn': {
    color: theme.colors.primary50,
    fontWeight: theme.font.weight.bold,
    padding: theme.spacing(1, 0.5),
    borderRadius: theme.borderRadius.small,
  },
  '#contact-patients-btn:disabled': {
    color: theme.colors.neutral20,
    cursor: 'not-allowed',
  },
  '#contact-patients-btn:active': {
    color: theme.colors.primary70,
    backgroundColor: `${theme.colors.primary5} !important`,
  },
});
