import { css } from '@emotion/react';
import { InvoiceModel } from '@frontend/api-invoices';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, useModalControl } from '@frontend/design-system';
import { RefundError, RefundForm, RefundSuccess } from '../';
import { useRefundRequest } from '../../../hooks';

type RefundModalProps = ReturnType<typeof useModalControl>['modalProps'] & {
  invoice: InvoiceModel;
};

export const RefundModal = ({ invoice, ...props }: RefundModalProps) => {
  return (
    <Modal
      {...props}
      css={css`
        padding: ${theme.spacing(3, 0)};
      `}
    >
      <RefundModalBody invoice={invoice} closeModal={() => props.onClose()} />
    </Modal>
  );
};

type RefundModalBodyProps = {
  invoice: InvoiceModel;
  closeModal: () => void;
};

function RefundModalBody({ invoice, closeModal }: RefundModalBodyProps) {
  const { error, refund, refundState, requestTimedOut } = useRefundRequest({ invoice, closeModal });
  const { t } = useTranslation('payments');

  if (!invoice) {
    return <Text>{t('No invoice selected')}</Text>;
  } else {
    return (
      <>
        <Modal.Header>{t('Refund Payment')}</Modal.Header>
        <Divider css={{ margin: 0 }} />
        {!refundState.requested && <RefundForm refundAction={refund} invoice={invoice} payment={invoice.payment} />}
        {refundState.requested && !error && (
          <RefundSuccess
            cardholderName={invoice.person.name}
            amountInCents={refundState.amount ?? 0}
            last4={invoice.payment?.paymentDetails.lastFour}
            onOkay={closeModal}
          />
        )}
        {refundState.requested && requestTimedOut && (
          <RefundError errorMessage={error?.error?.message} onTryAgain={closeModal} />
        )}
      </>
    );
  }
}
