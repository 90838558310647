import { memo } from 'react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { Chart, PieChartData } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { useCallIntelStore } from '../../../hooks';
import { formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { CallIntelInfoTips } from '../call-intel-info-tips';
import { CustomTooltipTitle } from '../custom-tooltip-title';

type Sentiments = Record<CallIntelligenceTypes.SentimentEnum | string, number>;

type Props = {
  className?: string;
  internalPage?: boolean;
  isDemoAccount?: boolean;
  isLoading?: boolean;
  sentiments?: Sentiments | null;
};

type FormattedChartData = {
  pie: PieChartData;
  sneakPeak: { legendId: string; name: string; value: number }[];
  percentages: Record<string, number>;
};

export const defaultSentiments: Record<CallIntelligenceTypes.SentimentEnum, number> = {
  [CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE]: 0,
  [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL]: 0,
  [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE]: 0,
  [CallIntelligenceTypes.SentimentEnum.SENTIMENT_UNKNOWN]: 0,
};

const orderSentiments = (sentiments?: Sentiments | null): Sentiments => {
  const orderedSentiments = defaultSentiments;

  Object.keys(defaultSentiments).forEach((key) => {
    orderedSentiments[key as CallIntelligenceTypes.SentimentEnum] = sentiments?.[key] ?? 0;
  });

  return orderedSentiments;
};

const formatChartData = (
  sentiments?: Sentiments | null,
  sentimentsWithEmoji?: Record<CallIntelligenceTypes.SentimentEnum | string, string>
): FormattedChartData => {
  const orderedSentiments = orderSentiments(sentiments);
  const total = Object.values(orderedSentiments).reduce((acc, value) => acc + value, 0);

  return Object.entries(orderedSentiments).reduce(
    (acc, [id, value]) => {
      acc.pie.centerMetric = total;

      acc.pie.groups.push({
        name: id,
        value,
      });

      acc.sneakPeak.push({
        legendId: id,
        name: sentimentsWithEmoji ? sentimentsWithEmoji[id] ?? id : id,
        value,
      });

      acc.percentages[id] = value / total;

      return acc;
    },
    {
      pie: {
        groups: [],
      },
      sneakPeak: [],
      percentages: {},
    } as FormattedChartData
  );
};

export const SentimentsChart = memo(({ className, isDemoAccount, isLoading, sentiments, internalPage }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const { current } = useLocation();
  const { dataColors, dataLabels, filters, filterHintText, setFiltersToRestore, setSubView, subView } =
    useCallIntelStore();
  const chartData = formatChartData(sentiments, dataLabels.sentimentsWithEmoji);

  const renderChart = (chartData: FormattedChartData) => (
    <>
      <Chart.PieChart
        appearance={{
          customTooltipTitle: ({ groupName }) => {
            return internalPage ? (
              <CustomTooltipTitle suffix={dataLabels.sentimentsWithEmoji?.[groupName]} />
            ) : (
              dataLabels.sentimentsWithEmoji?.[groupName]
            );
          },
          customTooltipData: (id) => {
            return [
              {
                color: dataColors.sentiments?.[id] || '',
                formattedValue: formatters.percent.format(chartData.percentages[id]),
                id,
                label: t('of Calls'),
              },
              {
                color: dataColors.sentiments?.[id] || '',
                formattedValue: formatters.value.format(sentiments?.[id]),
                id,
                label: t('Calls'),
              },
            ];
          },
        }}
        data={sentiments ? chartData.pie : { groups: [] }}
        formatValue={formatters.value.format}
        onClick={
          internalPage
            ? undefined
            : ({ name }) => {
                setFiltersToRestore(filters);
                setSubView({
                  id: name,
                  type: 'sentiment',
                });
                navigate({
                  search: {
                    type: 'sentiment',
                  },
                  to: `${current.pathname}/${name.toLowerCase()}`,
                });
              }
        }
      />
      <Chart.Legends />
    </>
  );

  return (
    <Chart
      css={className}
      colors={dataColors.sentiments || {}}
      isLoading={isLoading}
      labels={dataLabels.sentimentsWithEmoji || {}}
    >
      <Chart.Header
        expandable={false}
        bottomElement={
          internalPage ? null : (
            <Text color='subdued' size='medium'>
              {t('Click each segment to learn more.')}
            </Text>
          )
        }
        infoTip={!internalPage && <CallIntelInfoTips tip='sentiments' />}
        leftElement={isDemoAccount ? <DemoChip /> : null}
        subtitle={filterHintText}
        title={
          internalPage && subView.id
            ? t('{{name}} Customer Sentiment', {
                name:
                  subView.type === 'appointment-type'
                    ? dataLabels.appointmentTypes?.[subView.id]
                    : dataLabels.categories?.[subView.id],
              })
            : t('Customer Sentiment')
        }
      />
      {renderChart(chartData)}
    </Chart>
  );
});

SentimentsChart.displayName = 'SentimentsChart';
