import { ReactNode } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps } from '@frontend/design-system';
import { useFormPacketSelector } from '../../hooks';
import { FormPacketFilters } from '../form-packet-filters';
import { FormPacketList } from '../form-packet-list';

type FormPacketSelectorModalProps = ModalControlModalProps & {
  selectorProps: FormPacketSelectorModalContentsProps;
  children?: ReactNode;
};

/**
 * @param children - (optional) Useful for adding `Modal.Actions`
 */
export const FormPacketSelectorModal = ({ selectorProps, children, ...rest }: FormPacketSelectorModalProps) => {
  const { t } = useTranslation('form-packet-selector');

  return (
    <Modal
      css={{
        minWidth: 'min(90vw, 500px)',
        maxWidth: 'min(90vw, 500px)',
        minHeight: 'min(90vh, 500px)',
        maxHeight: 'min(90vh, 500px)',
      }}
      {...rest}
    >
      <Modal.Header onClose={rest.onClose}>{t('Select Packet or Form')}</Modal.Header>
      <FormPacketSelectorModalContents {...selectorProps} />
      {children}
    </Modal>
  );
};

type FormPacketSelectorModalContentsProps = ReturnType<typeof useFormPacketSelector>['selectorProps'];
/**
 * Useful for reusing the contents of the modal in other modal components. For example, flows with multiple modals
 * benefit from being able to replace the contents of the modal instead of closing and opening multiple modals.
 */
export const FormPacketSelectorModalContents = ({
  formPacketFiltersProps,
  formPacketListProps,
}: FormPacketSelectorModalContentsProps) => {
  return (
    <>
      <FormPacketFilters
        {...formPacketFiltersProps}
        css={{
          padding: theme.spacing(1, 3),
          width: '100%',
        }}
      />
      <Modal.Body>
        <FormPacketList {...formPacketListProps} />
      </Modal.Body>
    </>
  );
};
