import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';

type ActionsPopoverProps = Pick<
  ReturnType<typeof usePopoverMenu<HTMLButtonElement>>,
  'getMenuProps' | 'getItemProps' | 'close'
> & {
  onShorten: () => void;
  onLengthen: () => void;
};

export const ActionsPopover: FC<ActionsPopoverProps> = ({
  getMenuProps,
  getItemProps,
  close,
  onShorten,
  onLengthen,
}) => {
  const { t } = useTranslation();

  const handleShorten = () => {
    onShorten();
    close();
  };

  const handleLengthen = () => {
    onLengthen();
    close();
  };

  return (
    <PopoverMenu
      {...getMenuProps()}
      css={{
        width: 125,
        borderRadius: 4,
      }}
    >
      <PopoverMenuItem
        key={'shorten'}
        {...getItemProps({
          index: 0,
          onClick: handleShorten,
        })}
        css={{ cursor: 'pointer' }}
      >
        <Icon name='shorten-small' />
        {t('Shorten')}
      </PopoverMenuItem>
      <PopoverMenuItem
        key={'lengthen'}
        {...getItemProps({ index: 1, onClick: handleLengthen })}
        css={{ cursor: 'pointer' }}
      >
        <Icon name='lengthen-small' />
        {t('Lengthen')}
      </PopoverMenuItem>
    </PopoverMenu>
  );
};
