import { SchemaIO, SchemaPlatformLocationFeature } from '@frontend/schema';

export type TrialSignupRequest = SchemaIO<(typeof SchemaPlatformLocationFeature)['TrialSignup']>['input'];

export type GetTrialStageBannerRequestType = SchemaIO<
  (typeof SchemaPlatformLocationFeature)['GetTrialStageBanner']
>['input'];

export type UpdateTrialBannerUserActionRequestType = SchemaIO<
  (typeof SchemaPlatformLocationFeature)['TrialBannerUserAction']
>['input'];

export type FreeTrialEligibilityRequest = SchemaIO<
  (typeof SchemaPlatformLocationFeature)['IsEligibleForFreeTrial']
>['input'];

export { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
export { TrialStageBanner } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/platform-enums/location_feature_enum.pb';

// Add new trial release flags of respective feature in the below enums
export enum FreeTrialFeatureFlagsEnum {
  ONLINE_SCHEDULING_FREE_TRIALS_FEATURE_FLAG = 'free-trials-release',
  PRACTICE_ANALYTICS_FREE_TRIALS_FEATURE_FLAG = 'practice-analytics-free-trial-enabled',
  DIGITAL_FORMS_FREE_TRIALS_FLAG = 'digital-forms-free-trials',
  WIV_FREE_TRIALS_FLAG = 'wiv-free-trial',
  TEXT_CONNECT_FREE_TRIALS_FLAG = 'text-connect-free-trial',
}
