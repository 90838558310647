import { AdaptoActions } from '@frontend/adapto-actions';
import { useTranslation } from '@frontend/i18n';
import { BulkSelectionActionType } from '../../../types';
import { useBulkListContext } from '@frontend/bulk-list-provider';
import { InboxPrefixes } from '@frontend/tracking-prefixes';

type TextWritebacksActionProps = {
  setBulkSelectionType: (type: BulkSelectionActionType) => void;
};

export const TextWritebacksAction = ({ setBulkSelectionType }: TextWritebacksActionProps) => {
  const { t } = useTranslation('inbox');
  const { openSelection } = useBulkListContext();

  return (
    <AdaptoActions.Action
      label={t('Text Writeback')}
      icon='data-sync'
      onClick={() => {
        setBulkSelectionType(BulkSelectionActionType.TEXT_WRITEBACK);
        openSelection();
      }}
      trackingId={`${InboxPrefixes.Thread}-writebacks-start`}
    />
  );
};
