import { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FormsLibrary } from '@frontend/api';
import { formatDate } from '@frontend/date';
import { Icon } from '@frontend/icons';
import { Text, useTooltip } from '@frontend/design-system';
import { pendoTags } from '../../../../../../shared/constants';
import { convertUTCtoISO } from '../../../../../../shared/utils';
import { formTileStyle } from './packet-view.style';

interface Props {
  form: FormsLibrary.Types.PacketFormV2;
  preview: string;
  onRemoveForm: (id: string) => void;
  onTileClick: (id: string) => void;
}
export const PacketTile: FC<Props> = ({ form, preview, onRemoveForm, onTileClick }) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'top',
  });
  const { dateTime, id, name } = form;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const formatLastUpdated = (date: string) => {
    try {
      const stringDateTime = formatDate(new Date(convertUTCtoISO(date)), 'MMM DD, YYYY');
      return `Updated ${stringDateTime}`;
    } catch (error) {
      console.error('[Packets Tile] Error formatting date:', error);
      return '';
    }
  };

  return (
    <section
      ref={setNodeRef}
      style={style}
      {...attributes}
      css={formTileStyle}
      onClick={() => onTileClick(id)}
      data-trackingid={pendoTags.library.packets.previewPacketForm}
    >
      {!isDragging && <Tooltip {...tooltipProps}>Drag and reorder</Tooltip>}
      <span className='drag-area' {...listeners}>
        <span
          {...(!isDragging && { ...triggerProps })}
          data-trackingid={pendoTags.library.packets.rearrangePacketForms}
        >
          <Icon name='dragand-drop-handle' />
        </span>
      </span>
      <div className='preview'>
        <img src={preview} alt='tile preview' />
      </div>
      <section className='tile-details'>
        <Text className='form-name' as={'h4'} weight='bold'>
          {name}
        </Text>
        <div className='tile-footer'>
          <Text size='medium' color='subdued'>
            {formatLastUpdated(dateTime)}
          </Text>
          <Icon
            data-trackingid={pendoTags.library.packets.deleteFormTile}
            className='remove-form'
            name='trash-small'
            onClick={(e) => {
              e.stopPropagation();
              onRemoveForm(id);
            }}
          />
        </div>
      </section>
    </section>
  );
};
