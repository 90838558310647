import { ScheduleAvailabilityTypes } from '@frontend/api-schedule-availability';
import { SpinningLoader, styles } from '@frontend/design-system';
import { DaySchedule } from './DaySchedule';

const days: ScheduleAvailabilityTypes.DayOfWeek[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

interface Props {
  schedule?: ScheduleAvailabilityTypes.Schedule;
  updateSchedule: (schedule: ScheduleAvailabilityTypes.Schedule) => void;
  isLoading: boolean;
}

export const ScheduleAvailability = ({ schedule, updateSchedule, isLoading }: Props) => {
  const onToggleDay = (dayOfWeek: ScheduleAvailabilityTypes.DayOfWeek) => {
    return (value: boolean) => {
      const scheduleClone = { ...schedule } as ScheduleAvailabilityTypes.Schedule;
      if (value) {
        scheduleClone[dayOfWeek] = [
          {
            breaks: [],
            startTime: '09:00',
            endTime: '17:00',
          },
        ];
      } else {
        delete scheduleClone[dayOfWeek];
      }
      updateSchedule(scheduleClone);
    };
  };

  const onUpdateDay = (dayOfWeek: string) => {
    return (e: ScheduleAvailabilityTypes.DisplaySchedule) => {
      let days = {};
      if (Object.keys(e).length !== 0) {
        days = { [dayOfWeek]: [e] };
      }
      const scheduleClone = { ...schedule, ...days };
      updateSchedule(scheduleClone);
    };
  };

  if (!schedule || isLoading) {
    return (
      <div css={[styles.flexCenter, { height: '50vh' }]}>
        <SpinningLoader />
      </div>
    );
  }

  return (
    <>
      {days.map((dayOfWeek) => (
        <DaySchedule
          key={dayOfWeek}
          day={dayOfWeek}
          displaySchedule={schedule?.[dayOfWeek]?.[0]}
          onToggleDay={onToggleDay(dayOfWeek)}
          onUpdate={onUpdateDay(dayOfWeek)}
        />
      ))}
    </>
  );
};
