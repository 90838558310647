import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ButtonBar, PrimaryButton, SecondaryButton } from '@frontend/design-system';
import { useTableFilter } from './table-filter-provider';

type TableFilterActionsProps = {
  onApply: () => void;
  onCancel?: () => void;
};

export const TableFilterActions = ({ onCancel, onApply, ...rest }: TableFilterActionsProps) => {
  const { popoverProps } = useTableFilter();
  const { t } = useTranslation('payments');

  const handleCancel = () => {
    popoverProps.close();
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  const handleApply = () => {
    popoverProps.close();
    if (typeof onApply === 'function') {
      onApply();
    }
  };

  return (
    <ButtonBar
      css={css`
        padding: ${theme.spacing(2, 3, 3)};
      `}
      {...rest}
    >
      <SecondaryButton onClick={handleCancel} data-trackingid={'shrd-portal-flyout-btn-cancel'}>
        {t('Cancel')}
      </SecondaryButton>
      <PrimaryButton onClick={handleApply} data-trackingid={'shrd-portal-flyout-btn-apply'}>
        {t('Apply')}
      </PrimaryButton>
    </ButtonBar>
  );
};
