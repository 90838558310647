import { FC } from 'react';
import { css } from '@emotion/react';
import { Address } from '@weave/schema-gen-ts/dist/schemas/phone/address/v1/address_service.pb';
import { useQuery, useQueryClient } from 'react-query';
import { E911Api, E911QueryKeys } from '@frontend/api-e911-addresses';
import { http } from '@frontend/fetch';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { FormRow, Modal, Stepper, Text, useAlert } from '@frontend/design-system';
import { CreateSoftphonePropTypes, spacingStyles, stepperButtonBarStyle } from '..';
import { useCreateSoftphone } from '../../../../store/create-softphone-provider';
import { E911AddressForm, E911Select } from '../../e911';

type Props = CreateSoftphonePropTypes;

export const ThirdStep: FC<React.PropsWithChildren<Props>> = ({ getFieldProps, values, tenantId }: Props) => {
  const { setShowConfirmedExitModal } = useCreateSoftphone(['setShowConfirmedExitModal']);

  const { data: { addresses } = { addresses: [] } } = useQuery({
    queryKey: [tenantId, ...E911QueryKeys.queryKeys.listAddresses()],
    queryFn: () => E911Api.list({ tenantId: tenantId ?? '' }),
  });

  const queryClient = useQueryClient();
  const alerts = useAlert();

  const { t } = useTranslation('phone', { keyPrefix: 'new-softphone' });
  const addNewAddress = getFieldProps('assignedAddressID').value === 'addNewAddress';

  const goBack = (id?: string) => {
    getFieldProps('assignedAddressID').onChange({ name: 'assignedAddressID', value: id });
  };

  const { mutate: createAddress, isLoading: isLoadingCreateAddress } = useMutation(
    (address: Address) => E911Api.create(address),
    {
      onSuccess: (data) => {
        alerts.success(t('Address created successfully'));
        queryClient.invalidateQueries([tenantId, ...E911QueryKeys.queryKeys.listAddresses()]);
        goBack(data.id);
      },
      onError: (error) => {
        if (http.isHttpError(error) && error.status === 422) {
          alerts.error(t('Unable to verify address with your E911 provider'));
        } else {
          alerts.error(t('Failed to add E911 address'));
        }
      },
    }
  );

  const handleNewAddressSave = (values: Address) => {
    createAddress({ ...values });
  };

  return (
    <>
      {addNewAddress ? (
        <>
          <Modal.Header onClose={() => setShowConfirmedExitModal(true)}>{t('Add a New E911 Address')}</Modal.Header>
          <Text css={spacingStyles(3)}>
            <Trans t={t} deviceName={values?.deviceName} firstName={values?.firstName}>
              An E911 address assigned to <b>{values?.deviceName}</b> is a federal regulation and provides{' '}
              <b>{values?.firstName}’s</b> physical location for emergency services. Add an address that is the primary
              working location where <b>{values?.firstName}</b> will be using this softphone.
            </Trans>
          </Text>
          <E911AddressForm
            onBack={goBack}
            onSave={handleNewAddressSave}
            isForm={false}
            isLoading={isLoadingCreateAddress}
          />
        </>
      ) : (
        <>
          <Modal.Header onClose={() => setShowConfirmedExitModal(true)}>{t('Assign an E911 Address')}</Modal.Header>
          <Modal.Body>
            <Text css={spacingStyles(3)}>
              <Trans t={t} deviceName={values?.deviceName} firstName={values?.firstName}>
                An E911 address assigned to <b>{values?.deviceName}</b> is a federal regulation and provides{' '}
                <b>{values?.firstName}’s</b> physical location for emergency services. Assign a primary working location
                where <b>{values?.firstName}</b> will be using this softphone.
              </Trans>
            </Text>
            <FormRow css={[spacingStyles(5), `width: 325px;`]}>
              <E911Select
                label={t('Select an E911 Address')}
                addAnAddress
                addressId={values?.assignedAddressID ?? ''}
                addresses={addresses}
                onChange={(id) => {
                  getFieldProps('assignedAddressID').onChange({ value: id, name: 'assignedAddressID' });
                }}
              />
            </FormRow>
          </Modal.Body>
          <Stepper.ButtonBar css={stepperButtonBarStyle}>
            <Stepper.PreviousButton>
              <Icon name='back' />
              <Text
                css={css`
                  margin-left: ${theme.spacing(1)};
                `}
                color='primary'
                weight='bold'
              >
                {t('Back')}
              </Text>
            </Stepper.PreviousButton>
            <div>
              <Stepper.NextButton trackingId='skip-e911-address' isOptionalStep isSecondaryButton>
                {t('Assign Later')}
              </Stepper.NextButton>
              <Stepper.NextButton trackingId='proceed-e911-address' isOptionalStep>
                {t('Continue')}
              </Stepper.NextButton>
            </div>
          </Stepper.ButtonBar>
        </>
      )}
    </>
  );
};
