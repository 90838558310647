import { ReactNode, createContext, useContext, useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { useQuery } from 'react-query';
import {
  StreamChat,
  Channel,
  OwnUserResponse,
  UserResponse,
  EventHandler,
  Event,
  FormatMessageResponse,
  MessageResponse,
  ErrorFromResponse,
} from 'stream-chat';
import { PortalUser, getUser, isWeaveUser } from '@frontend/auth-helpers';
import { useIdle } from '@frontend/document';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useChatNotification, useNotificationSettingsShallowStore } from '@frontend/notifications';
import { ExtensiblePopup, usePopupBarManager } from '@frontend/popup-bar';
import { useAppScopeStore } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { useWebsocketEventSubscription } from '@frontend/websocket';
import { theme } from '@frontend/theme';
import { Avatar, useTooltip, useAlert } from '@frontend/design-system';
import { ChatComponent } from '../components/chat-tab';
import { ChatStatusIcon } from '../components/primitives';
import { USER_PRESENCE_CHECK_INTERVAL, getUserPresence, useWeavePresence } from '../hooks';
import { chatStore, useChatStatusShallowStore } from '../store';
import {
  CategorizedChats,
  Chat,
  ChatListItem,
  Message,
  Recipient,
  StreamChatType,
  StatusDuration,
  StreamUserResponse,
  UserStatusData,
  StreamChatError,
  Reaction,
  ReactionMethodTypes,
} from '../types';
import { updateConversationsStatus } from '../utils';
import { ChatStrategy } from './strategy';
import { usePopupNotificationQuery } from './use-popup-notification-query';

const DEBUG = false;
const logger = (...props: any) => {
  if (DEBUG) console.log(...props);
};

const CHAT_TOKEN_URL = '/team-chat/v1/token';
const USERS_QUERY_LIMIT = 30;
const CHANNELS_QUERY_LIMIT = 30;
const MESSAGES_QUERY_LIMIT = 25;

const getTeamId = (orgId: string) => {
  return `ORG_ID_${orgId}`;
};

type CustomChat = Chat & { conversation?: Channel<StreamChatType>; hasMoreMessages?: boolean; unreadCount?: number };
type CustomChatComponentProps = { popup: ExtensiblePopup<CustomChat> };

type ImageAttachmentAsset = Array<{
  asset_url: string;
  thumb_url: string;
  image_url: string;
  type: 'image';
}>;

const formatUser = (user?: StreamUserResponse): Recipient => {
  if (!user) return {} as Recipient;
  const { userStatus } = user;
  return {
    userID: user.id,
    firstName: user.name?.split(' ')[0] ?? '',
    lastName: user.name?.split(' ')[1] ?? '',
    locationIDs: user?.teams ?? [],
    status: {
      userId: user.id,
      isOnline: getUserPresence(user.weavePresence?.online, user.weavePresence?.expiresAt),
      ...(userStatus && {
        status: userStatus?.statusText,
        statusDuration: userStatus?.statusDuration,
        statusExpiration: userStatus?.statusExpiration,
      }),
    },
    weavePresence: user.weavePresence,
  };
};

const fetchUsers = async (client: StreamChat<StreamChatType>, orgId: string) => {
  /*
    This function fetches all users in the current location and formats them to the Recipient type.
    For pagination purposes, it fetches the users in batches of 30, and continues fetching until there are no more users.
    Also presence is enabled to get the presence change event of the users.
  */
  try {
    let hasMoreUsers = true;
    const userList: StreamUserResponse[] = [];
    let offset = 0;
    while (hasMoreUsers) {
      const { users } = await client.queryUsers(
        { teams: { $contains: getTeamId(orgId) } },
        { name: 1 },
        { presence: true, limit: USERS_QUERY_LIMIT, offset }
      );
      userList.push(...users);
      offset += USERS_QUERY_LIMIT;
      hasMoreUsers = users.length === USERS_QUERY_LIMIT;
    }

    // during the migration, users which were not part of the location anymore were named as 'deleted user'
    // we are filtering out those users
    // This requires a permanent fix from the backend
    return userList.filter((user) => user.name !== 'deleted user').map(formatUser);
  } catch (error) {
    logger('error fetching users', error);
    return [];
  }
};

const initialize = async (orgId: string) => {
  const { apiKey, token } = await http.get<{ token: string; apiKey: string }>(CHAT_TOKEN_URL);
  const client = StreamChat.getInstance<StreamChatType>(apiKey);
  const user = getUser();
  const userID = user?.userID ?? '';
  // Adding org id to the user id to make it compatible with the stream chat
  const streamUserID = `ORG_ID_${orgId}_${userID}`;

  // Initialize current user with userId
  await client.connectUser({ id: streamUserID }, token);
  const recipients: Recipient[] = await fetchUsers(client, orgId);

  return { client, recipients };
};

type StreamContextValue = {
  client?: StreamChat<StreamChatType>;
  availableRecipients: Recipient[];
  conversations: CategorizedChats;
  orgId: string;
  isStreamClientConnected: boolean;
};
const StreamContext = createContext<StreamContextValue>({} as StreamContextValue);

type ProviderProps = {
  children?: ReactNode;
  availableRecipients?: Recipient[];
};

const DISCONNECT_COUNTDOWN = 1000 * 5;
const disconnect = debounce(
  ({
    client,
    isDisconnecting,
    setIsDisconnecting,
    cb,
    setClient,
  }: {
    client: StreamChat<StreamChatType>;
    isDisconnecting: boolean;
    setIsDisconnecting: (val: boolean) => void;
    setClient: Dispatch<SetStateAction<StreamChat<StreamChatType> | undefined>>;
    cb?: () => void;
  }) => {
    const _client = client;
    if (_client.user && !isDisconnecting) {
      setIsDisconnecting(true);

      return _client
        .disconnectUser()
        .then(() => {
          cb && cb();
          // client.disconnectUser set the user to null in the client object, so we need to set the client again.
          setClient(_client);
        })
        .finally(() => {
          setIsDisconnecting(false);
        });
    }

    return Promise.resolve();
  },
  DISCONNECT_COUNTDOWN
);

const connect = ({
  token,
  client,
  user,
  isConnecting,
  selectedOrgId,
  setIsConnecting,
}: {
  token: string;
  selectedOrgId: string;
  user?: PortalUser;
  client: StreamChat<StreamChatType>;
  isConnecting: boolean;
  setIsConnecting: (val: boolean) => void;
}) => {
  if (token && !client?.user && !isConnecting) {
    const streamUserID = `ORG_ID_${selectedOrgId}_${user?.userID}`;
    setIsConnecting(true);
    return client.connectUser({ id: streamUserID }, token).finally(() => {
      setIsConnecting(false);
    });
  }

  return Promise.resolve();
};

// Idle timeout for disconnecting the user from the stream
const IDLE_TIMEOUT = 1000 * 60;
const useActiveConnect = ({
  selectedOrgId,
  user,
  client,
  setClient,
}: {
  selectedOrgId: string;
  user?: PortalUser;
  client?: StreamChat<StreamChatType> | null;
  setClient: Dispatch<SetStateAction<StreamChat<StreamChatType> | undefined>>;
}) => {
  const { status } = useChatStatusShallowStore('status');
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState<boolean>(!!client?.user);
  const idle = useIdle(IDLE_TIMEOUT);
  const { setActivePopup } = usePopupBarManager();

  const { data } = useQuery({
    queryKey: ['stream-token', user?.userID ?? ''],
    queryFn: () => http.get<{ token: string; apiKey: string }>(CHAT_TOKEN_URL),
    enabled: !!user?.userID && user.type !== 'weave',
  });

  useEffect(() => {
    if (data && status === 'active' && !idle) {
      if (!user?.userID) return;

      disconnect.cancel();
      setIsConnected(true);
      const client = StreamChat.getInstance(data.apiKey);
      connect({
        client,
        isConnecting,
        selectedOrgId,
        setIsConnecting,
        token: data.token,
        user,
      });
    } else if (client?.user && status === 'idle') {
      disconnect({
        client,
        isDisconnecting,
        setIsDisconnecting,
        cb: () => {
          setActivePopup([]);
          setIsConnected(false);
        },
        setClient,
      });
    }
  }, [idle, status, user?.userID]);

  useEffect(() => {
    const handler = () => {
      if (document.visibilityState !== 'visible' && client?.user) {
        setIsDisconnecting(true);
        disconnect({
          client,
          isDisconnecting,
          setIsDisconnecting,
          cb: () => {
            setActivePopup([]);
            setIsConnected(false);
          },
          setClient,
        });
      } else if (document.visibilityState === 'visible' && status === 'active' && data) {
        disconnect.cancel();
        const client = StreamChat.getInstance(data?.apiKey);
        setIsConnected(true);
        connect({
          client,
          isConnecting,
          setIsConnecting,
          token: data?.token,
          user,
          selectedOrgId,
        });
      }
    };

    document.addEventListener('visibilitychange', handler);

    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
  }, [client, data, status]);

  return { isConnected };
};

export const StreamProvider = ({ children, availableRecipients: initialRecipients = [] }: ProviderProps) => {
  const clientRef = useRef<StreamChat<StreamChatType> | null>(null);
  const [client, setClient] = useState<StreamChat<StreamChatType> | undefined>();
  const [availableRecipients, setAvailableRecipients] = useState<Recipient[]>(initialRecipients);
  const active = chatStore((state) => state.active);
  const { selectedOrgId } = useAppScopeStore();
  const shouldInitialize = useRef<boolean>(true);
  const user = getUser();

  const { conversations, fetchConversations } = useClientEvents({
    client,
    availableRecipients,
    setAvailableRecipients,
    setClient,
  });

  useWeavePresence({ teamId: getTeamId(selectedOrgId) });

  useEffect(() => {
    logger('initializing stream client?', active ? 'yes' : 'no');
    if (!active || !user) {
      return;
    }
    if (shouldInitialize.current && !isWeaveUser()) {
      shouldInitialize.current = false;
      initialize(selectedOrgId).then(({ client, recipients }) => {
        clientRef.current = client;
        setClient(client);
        setAvailableRecipients(recipients);
        fetchConversations(client);
      });
    }

    return () => {
      logger('unmount');
      // Used the ref to disconnect the user, because the client is not available in the state when the component is unmounted
      shouldInitialize.current = true;
      setClient(undefined);
      clientRef.current
        ?.disconnectUser()
        .then(() => {
          logger('disconnected stream client');
          clientRef.current = null;
          setClient(undefined);
        })
        .catch((e) => logger('error disconnecting stream client', e));
    };
  }, [active, selectedOrgId]);

  if (!client) {
    return <>{children}</>;
  }
  return (
    <StreamContext.Provider
      value={{
        client,
        availableRecipients,
        conversations,
        orgId: selectedOrgId,
        isStreamClientConnected: !!client.user,
      }}
    >
      {children}
    </StreamContext.Provider>
  );
};

// This is the same component as in Twilio strategy, copied it because there might be custom changes with respect to stream
const ChatAvatar = ({
  name,
  isAuthorInConversation,
  isOnline,
  status,
}: {
  name: string;
  isAuthorInConversation: boolean;
  isOnline?: boolean | null;
  status?: string;
}) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'top', trigger: 'hover' });

  const hideSetStatus = useHasFeatureFlag('hide-team-chat-set-status-stream');
  const isStreamChatEnabled = useHasFeatureFlag('team-chat-use-stream');

  const shouldShowSetStatus = isStreamChatEnabled ? !hideSetStatus : true;

  return (
    <Avatar css={!isAuthorInConversation ? { '> *': { background: theme.colors.critical30 } } : undefined} name={name}>
      {isOnline && <Avatar.Dot variant='success' />}
      {shouldShowSetStatus && status && (
        <>
          <span
            style={{
              position: 'absolute',
              bottom: -4,
              right: -2,
              background: 'transparent',
            }}
            {...triggerProps}
          >
            <ChatStatusIcon />
          </span>
          <Tooltip {...tooltipProps}>{status}</Tooltip>
        </>
      )}
    </Avatar>
  );
};

const useStreamClient = () => {
  const context = useContext(StreamContext);

  return context;
};

export const ProviderComponent = ({ children, availableRecipients }: ProviderProps) => {
  return <StreamProvider availableRecipients={availableRecipients}>{children}</StreamProvider>;
};

const useGetConversations = () => {
  const { conversations } = useStreamClient();

  return () => conversations;
};

interface ProcessMessageProps {
  message:
    | ((FormatMessageResponse<StreamChatType> | MessageResponse<StreamChatType>) & {
        reaction_groups?: Record<
          string,
          { count: number; first_reaction_at: string; last_reaction_at: string; sum_scores: number }
        >;
      })
    | undefined;
  currentUser: OwnUserResponse<StreamChatType> | UserResponse<StreamChatType> | undefined;
  recipients: Recipient[];
  isAuthorInConversation?: boolean;
  isFirstUnread?: boolean;
  channelId: string;
}

const processMessage = ({
  message,
  currentUser,
  recipients,
  isAuthorInConversation = true,
  isFirstUnread = false,
  channelId,
}: ProcessMessageProps): Message => {
  const userId = message?.user?.id;
  const authorUser = recipients.find((rec) => rec.userID === userId) ?? formatUser(currentUser);
  if (!message || !authorUser) return {} as Message;
  const { status } = authorUser.status;
  const attachments: string[] = [];
  const reactions: Reaction[] = [];

  if (message?.attachments?.length) {
    message.attachments.forEach(({ asset_url, image_url }) => {
      const attachment = asset_url || image_url;

      if (attachment) {
        attachments.push(attachment);
      }
    });
  }

  if (message?.reaction_groups) {
    for (const reaction in message.reaction_groups) {
      const obj = message.reaction_groups[reaction];
      reactions.push({
        type: reaction,
        count: obj.count,
        hasOwnReaction: message?.own_reactions?.some((ownReaction) => ownReaction.type === reaction) ?? false,
        firstReaction: dayjs(obj.first_reaction_at).valueOf(),
      });
    }
  }

  const isEdited = !!message.message_text_updated_at;
  const timestamp = (isEdited ? message.message_text_updated_at : message.created_at) || '';

  return {
    id: message.id,
    avatar: authorUser ? (
      <ChatAvatar
        name={message?.user?.name ?? ''}
        isAuthorInConversation={isAuthorInConversation}
        isOnline={getUserPresence(message.user?.weavePresence?.online, message.user?.weavePresence?.expiresAt)}
        status={status ?? undefined}
      />
    ) : (
      <Avatar />
    ),
    direction: message?.user?.id === currentUser?.id ? 'outbound' : 'inbound',
    timestamp: timestamp ? new Date(timestamp) : '',
    text: message.text ?? '',
    hasBeenUpdated: isEdited,
    isFirstUnread,
    type: message?.type,
    meta: { source: message },
    attachments,
    channelId,
    ...(reactions.length && { reactions: reactions.sort((a, b) => a.firstReaction - b.firstReaction) }),
  };
};

type CustomChatListItem = ChatListItem & {
  conversation: Channel<StreamChatType>;
  hasMoreMessages: boolean;
  unreadCount?: number;
};

const processConversation = (
  channel: Channel<StreamChatType>,
  currentUser: OwnUserResponse<StreamChatType> | UserResponse<StreamChatType> | undefined
): CustomChatListItem => {
  /**
   * This list will not include people who have left the conversation.
   */
  const { messages, members, read } = channel.state;
  const membersList = Object.values(members).filter((member) => member.user_id !== currentUser?.id);
  const isPrivate = !!(channel?.data?.is_dm || channel.id?.startsWith('!members'));
  const recipients = membersList.map(({ user }) => formatUser(user));
  const status = isPrivate && recipients.length === 1 && membersList[0] ? getStatus(membersList[0]?.user) : undefined;
  // The SDK has a method to get the unread count, but it doesn't work if there is not already a last read index
  const unreadCount = channel.countUnread();
  const isFirstUnread =
    !!unreadCount && !!read[currentUser?.id ?? '']?.last_read_message_id
      ? read[currentUser?.id ?? '']?.last_read_message_id === messages[messages.length - 1]?.id
      : false;
  const name =
    !isPrivate && channel?.data?.name ? channel?.data?.name : membersList.map((member) => member.user?.name).join(', ');

  return {
    id: channel.id ?? '',
    isPrivate,
    name,
    conversation: channel,
    /**
     * We want all available recipients when processing messages, because messages can be from people who have since left the conversation.
     * These recipients are no longer participants - not included within the conversation object.
     */
    messages: messages.map((message) =>
      processMessage({
        message,
        currentUser,
        recipients,
        /**
         * Author of message has left if not in the participants list
         */
        isAuthorInConversation:
          membersList.some((p) => p.user_id === message.user?.id) || currentUser?.id === message.user?.id,
        isFirstUnread,
        channelId: channel.id ?? '',
      })
    ),
    recipients,
    // Only define the status if it is a direct message, with only one other participant
    status,
    unreadCount: unreadCount ?? 0,
    hasUnread: !!unreadCount,
    type: 'chat',
    hasMoreMessages: messages.length === MESSAGES_QUERY_LIMIT,
  };
};

const getConversations = async (
  client: StreamChat<StreamChatType>,
  conversations: CategorizedChats,
  orgId: string,
  channelId?: string
) => {
  let directMessages: ChatListItem[] = [...conversations.directMessages];
  let groupChats: ChatListItem[] = [...conversations.groupChats];
  let mostRecent: ChatListItem[] = [...conversations.mostRecent];
  try {
    //We are fetching only the channel with the provided channel id, in this way we can reduce the number of channels to be fetched
    if (channelId) {
      // Fetch only the channel with the provided channel id
      const channel = await client?.getChannelById('team', channelId, {});
      channel.watch();
      const processedChannel = processConversation(channel, client?.user);
      if (processedChannel.isPrivate) {
        directMessages = [processedChannel, ...directMessages.filter((chat) => chat.id !== processedChannel.id)];
      }
      if (!processedChannel.isPrivate) {
        groupChats = [processedChannel, ...groupChats.filter((chat) => chat.id !== processedChannel.id)];
      }
      mostRecent = [processedChannel, ...mostRecent.filter((chat) => chat.id !== processedChannel.id)].slice(0, 5);
    } else {
      // Add pagination to the channel fetch query
      /*
        This function fetches all channels in the current location and processes it.
        For pagination purposes, it fetches the channels in batches of 30, and continues fetching until there are no more channels.
        Also watch is enabled to get the channel events.
    */
      let hasMoreChannels = true;
      const channels: Channel<StreamChatType>[] = [];
      let offset = 0;
      while (hasMoreChannels) {
        const paginatedChannels = await client.queryChannels(
          {
            type: 'team',
            team: { $in: [getTeamId(orgId)] },
            members: { $in: [client.user?.id ?? ''] },
          },
          [{ last_message_at: -1 }],
          {
            watch: true,
            state: true,
            presence: true,
            limit: CHANNELS_QUERY_LIMIT,
            offset,
            message_limit: MESSAGES_QUERY_LIMIT,
          }
        );
        channels.push(...paginatedChannels);
        offset += CHANNELS_QUERY_LIMIT;
        hasMoreChannels = paginatedChannels.length === CHANNELS_QUERY_LIMIT;
      }

      const processedChannels = channels.map((channel) => processConversation(channel, client?.user));
      directMessages = processedChannels
        .filter(({ isPrivate }) => isPrivate)
        .sort(({ name: name1 }, { name: name2 }) =>
          name1 && name2 ? (name2.toLocaleLowerCase() > name1.toLocaleLowerCase() ? -1 : 1) : 0
        );
      groupChats = processedChannels
        .filter(({ isPrivate }) => !isPrivate)
        .sort(({ name: name1 }, { name: name2 }) =>
          name1 && name2 ? (name2.toLocaleLowerCase() > name1.toLocaleLowerCase() ? -1 : 1) : 0
        );
      mostRecent = processedChannels.slice(0, 5);
    }

    return {
      mostRecent,
      threadChats: [],
      directMessages,
      groupChats,
    };
  } catch (e) {
    logger(channelId ? `error fetching channel ${e}` : `error fetching channels ${e}`);
    return {
      mostRecent,
      threadChats: [],
      directMessages,
      groupChats,
    };
  }
};

const useConversations = () => {
  const [conversations, setConversations] = useState<CategorizedChats>({
    mostRecent: [],
    groupChats: [],
    threadChats: [],
    directMessages: [],
  });
  const { selectedOrgId } = useAppScopeStore();

  const get = (client: StreamChat<StreamChatType>, channelId?: string) =>
    getConversations(client, conversations, selectedOrgId, channelId).then((res) => {
      setConversations(res);
      return res;
    });

  return { conversations, fetchConversations: get, setConversations };
};

const useClientEvents = ({
  client,
  availableRecipients = [],
  setAvailableRecipients,
  setClient,
}: {
  client?: StreamChat<StreamChatType> | null;
  availableRecipients: Recipient[];
  setAvailableRecipients: Dispatch<SetStateAction<Recipient[]>>;
  setClient: Dispatch<SetStateAction<StreamChat<StreamChatType> | undefined>>;
}) => {
  const prevUserPresence = useRef<Record<string, boolean>>({});
  const { selectedOrgId } = useAppScopeStore();
  const { conversations, fetchConversations, setConversations } = useConversations();
  const user = getUser();

  const { isConnected } = useActiveConnect({ selectedOrgId, user, client, setClient });

  const { addPopup: addChat, activePopup, setPopupList: setChats } = usePopupBarManager<Chat>();
  const { notificationSettings } = useNotificationSettingsShallowStore('notificationSettings');

  const { create, remove } = useChatNotification({
    onView: async (notification) => {
      if (notification.payload.provider !== 'stream') {
        return;
      }
      const channelId = notification.payload.channelId;
      const targetChat = [...conversations.directMessages, ...conversations.groupChats].find(
        (chat) => chat.id === channelId
      );

      if (targetChat && !activePopup.includes(targetChat.id) && client) {
        addChat(targetChat);
      }
      remove(notification.id);
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const recipients = [...availableRecipients];
      let changed = false;
      const changedUsers: string[] = [];

      const userPresence: Record<string, boolean> = { ...prevUserPresence.current };

      recipients.forEach(({ userID, weavePresence }) => {
        if (!weavePresence) {
          return;
        }

        const isOnline = getUserPresence(weavePresence.online, weavePresence.expiresAt);

        if (isOnline !== userPresence[userID]) {
          userPresence[userID] = isOnline;
          changed = true;
          changedUsers.push(userID);
        }
      });

      if (changed) {
        setAvailableRecipients(recipients);
        prevUserPresence.current = userPresence;
      }
    }, USER_PRESENCE_CHECK_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [availableRecipients, selectedOrgId]);

  const streamUserID = `ORG_ID_${selectedOrgId}_${user?.userID}`;

  useWebsocketEventSubscription(NotificationType.CHAT_MESSAGE, (payload: any) => {
    // Show notification
    const { message, channel_id, created_at } = payload.params;
    if (!isConnected && message?.user?.id !== streamUserID) {
      create({
        id: message?.id ?? '',
        payload: {
          message: message?.text ?? '',
          authorName: message?.user?.name ?? '',
          provider: 'stream',
          channelId: channel_id ?? '',
        },
        timestamp: new Date(created_at ?? '').toLocaleString(),
        type: 'chat-message-new',
        state: {
          paused: false,
          timeout: notificationSettings.durationMs,
          status: 'unread',
        },
      });
    }
  });

  const handleClientEvents: EventHandler = (event: Event) => {
    if (event.type === 'connection.changed' && client) {
      /**
       * This is supposed to run when the user connects or disconnects, but I've only seen it run when the user connects
       * We need to refetch the list for the chat tray when the user connects.
       *
       * We replace the open chat popups' underlying data with the new data from the server, and attach/detach event listeners.
       */

      fetchConversations(client).then((conversations) => {
        setChats((chats) => {
          const allChats = [...conversations.directMessages, ...conversations.groupChats];
          return chats.map((chat) => {
            const targetChat = allChats.find((c) => c.id === chat.id);
            if (targetChat) {
              const channel = client?.getChannelById('team', targetChat.id, {});
              const newChat = processConversation(channel, client.user);
              (targetChat as CustomChatListItem).conversation.off(handleChannelEvents);
              newChat.conversation.on(handleChannelEvents);
              return newChat;
            }
            return chat;
          });
        });
      });
    }

    if (
      ['user.presence.changed', 'user.updated'].includes(event.type) &&
      event?.user?.teams?.includes(getTeamId(selectedOrgId))
    ) {
      const newRecipients = [...availableRecipients];
      const index = newRecipients.findIndex((r) => r.userID === event?.user?.id);

      if (index > -1) {
        newRecipients[index].status = getStatus(event.user);
      }
      // We are setting the recipients to the state, so that the user status can be updated in the chat list
      setAvailableRecipients(newRecipients);
      // If any chat is opened we need to update the user status in the chat popup
      setChats((chats) => {
        // we need to update each chat with the new user online info
        return chats.map((chat) => ({
          ...chat,
          recipients: (chat.recipients || []).map((recipient) => ({
            ...recipient,
            status: recipient.userID === event.user?.id ? getStatus(event.user) : recipient.status,
          })),
        }));
      });
      // update all the conversations with the new user status
      setConversations(({ directMessages, groupChats, mostRecent, threadChats }) => ({
        directMessages: updateConversationsStatus(directMessages, event),
        groupChats: updateConversationsStatus(groupChats, event),
        mostRecent: updateConversationsStatus(mostRecent, event),
        threadChats: updateConversationsStatus(threadChats, event),
      }));
    } else if (['notification.added_to_channel', 'notification.removed_from_channel'].includes(event.type) && client) {
      // When a user is added to the channel, we need to fetch the conversations again to update the conversation list
      fetchConversations(client);
    }
  };

  const handleChannelEvents: EventHandler = (event) => {
    if (!client) return;
    /**
     * The reality of our chat implementation is that it relies heavily on the conversation list. So this must be kept up-to-date for things to work smoothly.
     *
     * An example of this is when a chat popup has a new message, closing the popup and reopening the chat from the chat tray will not show the new message.
     */
    fetchConversations(client);
    switch (event.type) {
      case 'message.new':
        if (event?.message?.user?.id === client?.user?.id) return;
        create({
          id: event?.message?.id ?? '',
          payload: {
            message: event?.message?.text ?? '',
            authorName: event?.user?.name || '',
            provider: 'stream',
            channelId: event?.channel_id ?? '',
          },
          timestamp: new Date(event?.created_at ?? '').toLocaleString(),
          type: 'chat-message-new',
          state: {
            paused: false,
            timeout: notificationSettings.durationMs,
            status: 'unread',
          },
        });
        fetchConversations(client, event.channel_id);
        break;
      case 'notification.removed_from_channel':
      case 'channel.deleted': {
        const targetChat = [...conversations.directMessages, ...conversations.groupChats].find(
          (chat) => chat.id === event.channel_id
        );
        setChats((chats) => chats.filter((chat) => chat.id !== targetChat?.id));
        fetchConversations(client);
        break;
      }
      case 'member.removed':
      case 'channel.updated': {
        fetchConversations(client, event.channel_id).then(() => {
          setChats((chats) =>
            chats.map((chat) => {
              if (chat.id === event?.channel?.id) {
                // When channel gets updated, we update the recipients list with the new members
                const recipients =
                  event?.channel?.members
                    ?.map(({ user }) => formatUser(user as StreamUserResponse))
                    .filter(({ userID }) => userID !== client.user?.id) ?? chat.recipients;
                return { ...chat, name: event?.channel?.name || '', recipients };
              }
              return chat;
            })
          );
        });
        break;
      }
      case 'member.added':
      case 'message.deleted':
      case 'message.updated':
      case 'message.read':
        fetchConversations(client, event.channel_id);
        break;
    }
  };

  useEffect(() => {
    client && client?.on(handleClientEvents);

    return () => {
      client && client?.off(handleClientEvents);
    };
  }, [client, conversations]);

  useEffect(() => {
    const channels: CustomChat[] = [...conversations.directMessages, ...conversations.groupChats];
    if (client) {
      channels.forEach(({ conversation }) => {
        conversation?.on(handleChannelEvents);
      });
    }

    return () => {
      if (client) {
        channels.forEach(({ conversation }) => {
          conversation?.off(handleChannelEvents);
        });
      }
    };
  }, [client, availableRecipients, conversations, create]);

  return { isConnected, conversations, fetchConversations };
};

const findPrivateConversationWithParticipants = async ({
  client,
  recipients,
  orgId,
}: {
  client?: StreamChat<StreamChatType>;
  recipients: Recipient[];
  orgId: string;
}) => {
  try {
    const channels = await client?.queryChannels(
      {
        type: 'team',
        team: { $in: [getTeamId(orgId)] },
        members: { $eq: [...recipients.map((member) => member.userID), client.user?.id ?? ''] },
      },
      [{ last_message_at: -1 }],
      { watch: false }
    );
    const channelsFilteredByMembers = channels?.filter(
      (channel) => channel?.id?.startsWith('!members') || channel?.data?.is_dm
    );
    // The response is an array or undefined, and we are picking the first channel because we are only expecting one channel
    // So checking if the channel is an array or undefined and if has a length greater than 0 with the first channel id starting with '!members'
    if (channelsFilteredByMembers?.length) {
      return processConversation(channelsFilteredByMembers[0], client?.user);
    }
    return null;
  } catch (error) {
    logger('error finding private conversation with participants', error);
    return null;
  }
};

// This method was taken from the old desktop client for conformity
const generateUniqueName = (friendlyName: string) => {
  return `${friendlyName
    .toLocaleLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/ /g, '-')}-${Date.now().toString()}`;
};

const useCreateConversation = () => {
  const { client } = useStreamClient();
  const { setPopupList: setChats, setActivePopup } = usePopupBarManager();
  const { selectedOrgId } = useAppScopeStore();
  const { t } = useTranslation('chat');
  const { error } = useAlert();

  return async ({
    recipients,
    message,
    name,
    isPrivate,
    attachments,
  }: {
    recipients: Recipient[];
    message: string;
    name: string;
    isPrivate: boolean;
    attachments?: File[];
  }) => {
    try {
      /*
        When creating a new direct conversation, it is possible that there already exists a conversation between the participants.
        In this case, we do not actually want to create a new conversation, but update the existing one.
      */
      const activeConversation =
        isPrivate && (await findPrivateConversationWithParticipants({ client, recipients, orgId: selectedOrgId }));
      let chat: Channel<StreamChatType> | undefined;
      if (activeConversation) {
        // handle when user has an active conversation
        chat = activeConversation.conversation;
      } else {
        // create new conversation
        /* A unique id is generated for the conversation because id for direct message is generated by stream and to differentiate between
           a direct message and a group chat, we are adding a unique and custom id to it
           This method was taken from Twilio Strategy and will only be consumed when creating a group conversation
        */
        const channel = client?.channel('team', isPrivate ? null : generateUniqueName(name), {
          members: [client?.user?.id ?? '', ...recipients.map((i) => i.userID)],
          created_by_id: client?.user?.id,
          team: getTeamId(selectedOrgId),
          name: isPrivate ? undefined : name,
          is_dm: isPrivate,
        });
        await channel?.create({ watch: true, presence: true });
        await channel?.watch();
        chat = channel;
      }

      const newChat = chat && processConversation(chat, client?.user);
      if (newChat) {
        setChats((chats) => {
          const newChats = [...chats.filter((c) => c.id !== newChat.id).slice(0, -1), newChat];
          return newChats;
        });
        setActivePopup([newChat.id]);
      }

      const allImagesPromises = attachments?.map((attachment) => chat?.sendImage(attachment));
      const allImagesResults = await Promise.allSettled(allImagesPromises || []);

      const imageAttachments = allImagesResults.reduce((acc: ImageAttachmentAsset, result) => {
        if (result.status === 'fulfilled' && result.value?.file) {
          acc.push({
            asset_url: result.value.file,
            thumb_url: result.value.file,
            image_url: result.value.file,
            type: 'image',
          });
        }
        return acc;
      }, []);

      await chat?.sendMessage({
        text: message,
        created_by_id: client?.user?.id,
        attachments: attachments?.length ? imageAttachments : undefined,
      });

      return chat;
    } catch (err) {
      if (err instanceof ErrorFromResponse && err.code === StreamChatError.INPUT_ERROR) {
        error(t('You entered invalid data when creating a conversation.'));
      } else {
        error(t('We encountered an error, when trying to start a conversation.'));
      }
      return {} as Channel<StreamChatType>;
    }
  };
};

const useSendMessage = () => {
  const { client } = useStreamClient();
  const { selectedOrgId } = useAppScopeStore();
  const { t } = useTranslation('chat');
  const { error } = useAlert();

  return async ({
    message,
    conversationId,
    attachments,
  }: {
    message: string;
    conversationId: string;
    attachments?: File[];
  }) => {
    try {
      const channel = await client?.getChannelById('team', conversationId, { team: getTeamId(selectedOrgId) });
      await channel?.watch({ presence: true });

      const allImagesPromises = attachments?.map((attachment) => channel?.sendImage(attachment));
      const allImagesResults = await Promise.allSettled(allImagesPromises || []);
      const imageAttachments = allImagesResults.reduce((acc: ImageAttachmentAsset, result) => {
        if (result.status === 'fulfilled' && result.value?.file) {
          acc.push({
            asset_url: result.value.file,
            thumb_url: result.value.file,
            image_url: result.value.file,
            type: 'image',
          });
        }
        return acc;
      }, []);

      await channel?.sendMessage({
        text: message,
        created_by_id: client?.user?.id,
        attachments: attachments?.length ? imageAttachments : undefined,
      });
    } catch (err) {
      logger('error sending message', error);
      error(t('Unable to send the message. Please try again later.'));
    }
  };
};

const useTypingStatus = () => {
  const { client } = useStreamClient();
  const { selectedOrgId } = useAppScopeStore();

  return async ({ conversationId }: { conversationId: string }) => {
    const channel = await client?.getChannelById('team', conversationId, { team: getTeamId(selectedOrgId) });
    await channel?.keystroke();
  };
};

const useUpdateMessage = () => {
  const { client } = useStreamClient();
  const { t } = useTranslation('chat');
  const { error } = useAlert();

  return async ({
    message,
    body,
    removedImages,
    addedImages,
  }: {
    message: Message;
    body: string;
    removedImages?: string[];
    addedImages?: File[];
  }) => {
    try {
      const channel = await client?.getChannelById('team', message?.channelId ?? '', {});
      let imageAttachments = [];
      const removedImagesPromises = removedImages?.map((attachment) => channel?.deleteImage(attachment));
      await Promise.allSettled(removedImagesPromises || []);

      const addedImagesPromises = addedImages?.map((attachment) => channel?.sendImage(attachment));
      const addedImagesResults = await Promise.allSettled(addedImagesPromises || []);
      imageAttachments = [
        ...(message.attachments?.filter((image) => !removedImages?.includes(image)) ?? []),
        ...addedImagesResults.reduce((acc: string[], result) => {
          if (result.status === 'fulfilled' && result.value?.file) acc.push(result.value.file);
          return acc;
        }, []),
      ].map((image) => ({
        image_url: image,
        asset_url: image,
        thumb_url: image,
        type: 'image',
      }));

      await client?.updateMessage({
        id: message.id,
        text: body,
        created_by_id: client?.user?.id,
        ...(imageAttachments.length && { attachments: imageAttachments }),
      });
    } catch (err) {
      logger('error updating message', err);
      error(t('Unable to update the message. Please try again later.'));
    }
  };
};

const useRemoveMessage = () => {
  const { client } = useStreamClient();
  const { t } = useTranslation('chat');
  const { error } = useAlert();

  return async ({ message }: { message: Message }) => {
    try {
      await client?.deleteMessage(message.id, true);
    } catch (err) {
      logger('error deleting message', err);
      error(t('Unable to delete the message. Please try again later.'));
    }
  };
};

const useThreadOpened = () => {
  return async (channel: any) => {
    // When a chat popup is opened and focused we mark the chat as read
    await channel?.markRead();
  };
};

const useHandleRecipientSelection = () => {
  const { client } = useStreamClient();
  const { setPopupList: setChats } = usePopupBarManager();
  const { selectedOrgId } = useAppScopeStore();

  // This is called when a new group/Direct message is created
  return async ({ recipients, isPrivate }: { recipients: Recipient[]; isPrivate: boolean }) => {
    const activeConversation = await findPrivateConversationWithParticipants({
      client,
      recipients,
      orgId: selectedOrgId,
    });

    let chat: ChatListItem & { hasMoreMessages?: boolean };
    if (activeConversation && isPrivate) {
      /**
       * Set the id as 'new' so that it is displayed as a New Chat tab.
       * When processing this to send, we should use the active conversation's id
       */
      chat = {
        ...activeConversation,
        id: 'new',
        hasUnread: false,
        isPrivate,
      };
    } else {
      chat = {
        id: 'new',
        messages: [],
        recipients,
        isPrivate,
        hasUnread: false,
        unreadCount: 0,
        type: 'chat',
        hasMoreMessages: false,
      };
    }

    setChats((chats) => {
      const newChats = [...chats.slice(0, -1), chat];
      return newChats;
    });
  };
};
const useUpdateConversation = () => {
  const { client } = useStreamClient();
  const { setPopupList: setChats } = usePopupBarManager();
  const { t } = useTranslation('chat');
  const { error } = useAlert();

  return async ({
    chat,
    recipients,
    friendlyName,
  }: {
    chat: CustomChat;
    recipients: Recipient[];
    friendlyName: string;
  }) => {
    const channel = chat.conversation!;
    try {
      const outgoingParticipants = chat.recipients.filter((r) => !recipients.includes(r));
      const incomingParticipants = recipients.filter((r) => !chat.recipients.includes(r));

      if (outgoingParticipants.length) {
        // If any participants were removed from the conversation
        const text = `${client?.user?.name} removed ${outgoingParticipants
          .map((p) => `${p.firstName} ${p.lastName}`)
          .join(', ')} from the channel`;
        await channel.removeMembers(
          outgoingParticipants.map((p) => p.userID),
          { text }
        );
      }
      if (incomingParticipants.length) {
        // If any participants were added to the conversation
        const text = `${client?.user?.name} added ${incomingParticipants
          .map((p) => `${p.firstName} ${p.lastName}`)
          .join(', ')} to the channel`;
        await channel.addMembers(
          incomingParticipants.map((p) => p.userID),
          { text }
        );
      }
      if (!chat.isPrivate && channel?.data?.name !== friendlyName) {
        // If the name of the group was changed
        await channel.updatePartial({ set: { name: friendlyName } });
      }

      // Update the chat popup
      const newChat = processConversation(channel, client?.user);
      setChats((chats) => {
        const newChats = [...chats.slice(0, -1), newChat];
        return newChats;
      });
    } catch (err) {
      logger('error updating conversation', err);
      error(t('Unable to update the conversation. Please try again later.'));
    }
  };
};

const useLeaveConversation = () => {
  const { setPopupList: setChats } = usePopupBarManager();
  const { client } = useStreamClient();
  const { t } = useTranslation('chat');
  const { error } = useAlert();

  return async ({ chat }: { chat: CustomChat }) => {
    try {
      if (chat.isPrivate) return; // We don't want to leave private conversations

      const userId = client?.user?.id ?? '';
      await chat?.conversation?.removeMembers([userId], { text: `${client?.user?.name} left the channel` });
      // Leave the chat and close the popup if opened
      setChats((chats) => {
        const newChats = chats.filter((c) => c.id !== chat.id);
        return newChats;
      });
    } catch (err) {
      logger('error leaving conversation', err);
      error(t('Unable to leave the conversation. Please try again later.'));
    }
  };
};

const useDeleteConversation = () => {
  const { setPopupList: setChats } = usePopupBarManager();
  const { t } = useTranslation('chat');
  const { error } = useAlert();

  return async ({ chat }: { chat: CustomChat }) => {
    try {
      await chat?.conversation?.delete();
      // Delete the chat and close the popup if opened
      setChats((chats) => {
        const newChats = chats.filter((c) => c.id !== chat.id);
        return newChats;
      });
    } catch (err) {
      logger('error deleting conversation', err);
      error(t('Unable to delete the conversation. Please try again later.'));
    }
  };
};

export const getStatus = (user?: StreamUserResponse) => {
  if (!user) return {} as UserStatusData;
  return {
    isOnline: getUserPresence(user.weavePresence?.online, user.weavePresence?.expiresAt),
    userId: user?.id ?? '',
    status: user?.userStatus?.statusText,
    statusDuration: user?.userStatus?.statusDuration,
    statusExpiration: user?.userStatus?.statusExpiration,
  };
};

const useGetUserStatus = () => {
  const { client } = useStreamClient();

  return async () => {
    const user = client?.user as StreamUserResponse;

    return {
      isOnline: getUserPresence(user?.weavePresence?.online, user?.weavePresence?.expiresAt),
      userId: user?.id ?? '',
      status: user?.userStatus?.statusText,
      statusDuration: user?.userStatus?.statusDuration,
      statusExpiration: user?.userStatus?.statusExpiration,
    };
  };
};

const useSaveStatus = () => {
  const { client } = useStreamClient();
  const user = getUser();

  return async ({ status, duration }: { status?: string; duration?: StatusDuration }) => {
    let statusExp: number;
    switch (duration) {
      case 'hour':
        statusExp = dayjs().add(1, 'hour').valueOf();
        break;
      case 'today':
        statusExp = dayjs().endOf('day').valueOf();
        break;
      case 'week':
        statusExp = dayjs().endOf('week').valueOf();
        break;
      case 'never':
        statusExp = 0;
        break;
      default:
        statusExp = 0;
    }

    await client?.upsertUser({
      id: client?.user?.id as string,
      name: `${user?.firstName} ${user?.lastName}`,
      // @ts-ignore userStatus object does not exist on stream's user Response type
      userStatus: {
        statusText: status ?? null,
        statusDuration: duration ?? null,
        statusExpiration: statusExp ?? null,
      },
    });
  };
};

const useSaveSettings = () => {
  const { updateNotificationSetting } = usePopupNotificationQuery();

  return async ({ sound, popup }: { sound: boolean; popup: boolean }) => {
    localStorage.setItem('chat-notification-sound', JSON.stringify(sound));
    updateNotificationSetting(popup);
  };
};

const useGetSettings = () => {
  const { popup } = usePopupNotificationQuery();

  return async (): Promise<{ sound: boolean; popup: boolean }> => {
    return {
      sound: JSON.parse(localStorage.getItem('chat-notification-sound') ?? 'true') as boolean,
      popup,
    };
  };
};

const useUnreadCount = () => {
  const { conversations } = useStreamClient();

  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (conversations) {
      const totalUnread = [...conversations.directMessages, ...conversations.groupChats].reduce(
        (acc, chat) => (acc += chat.unreadCount),
        0
      );
      setUnreadCount(totalUnread);
    }
  }, [conversations]);

  return unreadCount;
};

const useUniqueGroupName = () => {
  const { conversations } = useStreamClient();

  return (name: string, id: string) =>
    conversations.groupChats?.find((chat) => chat.name === name && chat.id !== id) ? true : false;
};

const useReactionMethods = () => {
  const { client } = useStreamClient();
  const { t } = useTranslation('chat');
  const { error } = useAlert();

  return {
    sendReaction: async ({ messageId, emoji, conversationId }: ReactionMethodTypes) => {
      try {
        const channel = await client?.getChannelById('team', conversationId, {});
        await channel?.sendReaction(messageId, { type: emoji, user: client?.user });
      } catch (err) {
        logger('error sending reaction', err);
        error(t('Failed to add reaction.'));
      }
    },
    deleteReaction: async ({ messageId, emoji, conversationId }: ReactionMethodTypes) => {
      try {
        const channel = await client?.getChannelById('team', conversationId, {});
        await channel?.deleteReaction(messageId, emoji);
      } catch (err) {
        logger('error sending reaction', err);
        error(t('Failed to delete reaction.'));
      }
    },
  };
};

const api = {
  useGetConversations,
  useSendMessage,
  useTypingStatus,
  useUpdateMessage,
  useRemoveMessage,
  useThreadOpened,
  useCreateConversation,
  useHandleRecipientSelection,
  useUpdateConversation,
  useLeaveConversation,
  useDeleteConversation,
  useGetUserStatus,
  useSaveStatus,
  useSaveSettings,
  useGetSettings,
  useUnreadCount,
  useUniqueGroupName,
  useReactionMethods,
  configuration: {
    supportsAttachments: true,
    supportsReactions: true,
  },
};

const useTypingParticipants = (channel?: Channel<StreamChatType>) => {
  const [typingParticipants, setTypingParticipants] = useState<Recipient[]>([]);

  const typeStart = (event: Event) => {
    event?.user && setTypingParticipants((participants) => [...participants, formatUser(event?.user)]);
  };

  const typeEnd = (event: Event) => {
    const participant = formatUser(event?.user);
    setTypingParticipants((participants) => participants.filter((p) => p.userID !== participant.userID));
  };

  useEffect(() => {
    channel?.on('typing.start', typeStart);
    channel?.on('typing.stop', typeEnd);

    return () => {
      channel?.off('typing.start', typeStart);
      channel?.off('typing.stop', typeEnd);
    };
  }, [channel]);

  return typingParticipants;
};

const useMessageEvents = (chat: CustomChat, onNewMessageReceived: (unreadCount: number) => void) => {
  const { client, availableRecipients } = useStreamClient();

  const [messages, setMessages] = useState<Message[]>(chat.messages);
  let hasMoreMessages = chat.hasMoreMessages;

  const getPrevMessages = async () => {
    if (hasMoreMessages) {
      const previousResponse = await chat?.conversation?.query({
        messages: { id_lt: messages[0].id, limit: MESSAGES_QUERY_LIMIT },
      });
      previousResponse?.messages?.length &&
        setMessages((messages) => [
          ...previousResponse.messages.map((message) =>
            processMessage({
              message,
              currentUser: client?.user,
              recipients: availableRecipients,
              channelId: chat.id,
            })
          ),
          ...messages,
        ]);
      hasMoreMessages = previousResponse?.messages?.length === MESSAGES_QUERY_LIMIT;
    }
  };

  const onNewMessage = (event: Event) => {
    if (event.message) {
      setMessages((messages) => {
        return [
          ...messages,
          processMessage({
            message: event?.message,
            currentUser: client?.user,
            recipients: availableRecipients,
            channelId: chat.id,
          }),
        ];
      });
      onNewMessageReceived(event.unread_count ?? 0);
    }
  };

  const onMessageUpdated = (event: Event) => {
    setMessages((messages) => {
      return messages.map((message) =>
        message.id === event?.message?.id
          ? processMessage({
              message: event?.message,
              currentUser: client?.user,
              recipients: availableRecipients,
              channelId: chat.id,
            })
          : message
      );
    });
  };

  const onMessageDeleted = (event: Event) => {
    event.message?.id &&
      setMessages((messages) => {
        return messages.filter((message) => message.id !== event.message?.id);
      });
  };

  const handleReaction = (event: Event) => {
    event.message &&
      setMessages((messages) => {
        return messages.map((message) => {
          if (message.id === event.message?.id) {
            return processMessage({
              channelId: chat.id,
              currentUser: client?.user,
              recipients: availableRecipients,
              message: event.message,
            });
          }
          return { ...message };
        });
      });
  };

  useEffect(() => {
    if (chat?.conversation) {
      chat.conversation.on('message.new', onNewMessage);
      chat.conversation.on('message.updated', onMessageUpdated);
      chat.conversation.on('message.deleted', onMessageDeleted);
      chat.conversation.on('reaction.new', handleReaction);
      chat.conversation.on('reaction.deleted', handleReaction);
      chat.conversation.on('reaction.updated', handleReaction);
      /**
       * If the chat object gets updated (recreated), we need to re-sync the new messages with the state.
       *
       * Typically, there is no need to do this because this state is set up to keep track of all new messages.
       * However, if the chat gets disconnected, we recreate the chat object, and we might have new messages that came in while the chat was disconnected.
       */
      setMessages(chat.messages);
    }

    return () => {
      if (chat?.conversation) {
        chat.conversation.off('message.new', onNewMessage);
        chat.conversation.off('message.updated', onMessageUpdated);
        chat.conversation.off('message.deleted', onMessageDeleted);
        chat.conversation.off('reaction.new', handleReaction);
        chat.conversation.off('reaction.deleted', handleReaction);
        chat.conversation.off('reaction.updated', handleReaction);
      }
    };
  }, [chat?.conversation]);
  return { messages, getPrevMessages };
};

export const streamStrategy: Omit<ChatStrategy, 'message'> = {
  ProviderComponent,
  chat: api,
};
export const StreamChatComponent = (props: CustomChatComponentProps) => {
  const popupId = props.popup.id;
  const shouldUnreadCountUpdate = useRef(false);
  const unreadCount = useRef(props.popup.unreadCount ?? 0);
  const isFocused = useRef(false);
  const { availableRecipients, client, isStreamClientConnected } = useStreamClient();
  const onThreadOpen = useThreadOpened();
  const { activePopup } = usePopupBarManager();
  const prevActivePopupRef = useRef(activePopup);

  const { messages, getPrevMessages } = useMessageEvents(props.popup, onNewMessageReceived);

  useEffect(() => {
    // clear unread count when the chat is opened
    if (!isStreamClientConnected) {
      // if the client is not connected, we need to wait for the client to connect before marking the chat as read
      // so we set a flag to find out if the unread count should be updated when the client connects in the below useEffect
      shouldUnreadCountUpdate.current = true;
    } else if (unreadCount.current > 0) {
      onThreadOpen(props.popup.conversation);
    }
  }, []);

  // we can combine the above useEffect with the below useEffect, but channel.watch is async operation and will take some extra ms to complete
  useEffect(() => {
    (async function () {
      // if the client is connected and the shouldUnreadCountUpdate flag is set to update the unread count, we mark the chat as read
      if (shouldUnreadCountUpdate.current && isStreamClientConnected && client) {
        const channel = client.getChannelById('team', props.popup.id, {});
        // client doesn't have the latest state of the channel, so we need to watch the channel to get the latest state
        await channel.watch();
        if (channel.state.unreadCount > 0) {
          channel.markRead();
        }
        shouldUnreadCountUpdate.current = false;
      }
    })();
  }, [isStreamClientConnected, client]);

  useEffect(() => {
    // this useeffect is handles the case when a popup is minimized and then opened again
    // at this point we need to mark the chat as read again
    // we don't need to use popupList b'coz we're initializing the prevActivePopupRef with the activePopup
    (async function () {
      if (
        isStreamClientConnected &&
        client &&
        activePopup.includes(popupId) &&
        !prevActivePopupRef.current.includes(popupId)
      ) {
        const channel = client.getChannelById('team', props.popup.id, {});
        // client doesn't have the latest state of the channel, so we need to watch the channel to get the latest state
        await channel.watch();
        if (channel.state.unreadCount > 0) {
          channel.markRead();
        }
      }

      // we should only update the prevActivePopupRef when the client is connected
      // otherwise it will be outdated when client connects and this useEffect runs again
      if (isStreamClientConnected) {
        prevActivePopupRef.current = activePopup;
      }
    })();
  }, [activePopup, client, isStreamClientConnected]);

  const typingParticipants = useTypingParticipants(props?.popup?.conversation);
  const ids = typingParticipants
    .filter((p) => p.userID && p.userID !== client?.user?.id)
    .map((p) => p.userID)
    .filter(Boolean);

  const chat = { ...props.popup, messages };

  const onChatFocus = () => {
    isFocused.current = true;

    if (isStreamClientConnected && unreadCount.current > 0) {
      onThreadOpen(props.popup.conversation);
    } else if (!isStreamClientConnected && unreadCount.current > 0) {
      shouldUnreadCountUpdate.current = true;
    }
  };

  function onNewMessageReceived(latestUnreadCount: number) {
    // if the chat is focused, we mark the chat as read
    // this can't be done within useMessageEvents because we'll have to pass the dependency for isFocused to useEffect
    // which will cause the useEffect to run every time the isFocused(useState in that case) is updated
    if (isFocused.current && props.popup) {
      onThreadOpen(props.popup.conversation);
    }

    // due to complex nature of the chat architecure, the unread count is not updated in the chat popup
    // so we need to keep track of the unread count from the message events
    unreadCount.current = latestUnreadCount;
  }

  return (
    <ChatComponent
      chat={chat}
      availableRecipients={availableRecipients?.filter((rec) => rec.userID !== client?.user?.id) ?? []}
      sender={availableRecipients?.find((rec) => rec.userID === client?.user?.id)}
      typingRecipientIds={ids}
      fetchPreviousMessages={getPrevMessages}
      onFocus={onChatFocus}
      onBlur={() => {
        isFocused.current = false;
      }}
    />
  );
};
