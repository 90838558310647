import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import type { GetPixelFromTimestampParamsType, GetGridColumnsFromTimestampParamsType, EventData } from './types';

export const PX_PER_MINUTE = 2;
export const ZOOMED_IN_PX_PER_MINUTE = 4;
const ONE_MINUTE_IN_MILLISECONDS = 60000;
const ONE_HOUR_IN_MINUTES = 60;

const getPxPerMinute = (isZoomedIn?: boolean) => (isZoomedIn ? ZOOMED_IN_PX_PER_MINUTE : PX_PER_MINUTE);
export const ADJUSTED_ONE_HOUR_IN_MINUTES = ONE_HOUR_IN_MINUTES * PX_PER_MINUTE;
export const adjustedOneMinuteInMilliseconds = (isZoomedIn: boolean) =>
  ONE_MINUTE_IN_MILLISECONDS * (isZoomedIn ? ZOOMED_IN_PX_PER_MINUTE : PX_PER_MINUTE);
export const adjustedOneHourInMinutes = (isZoomedIn: boolean) =>
  ONE_HOUR_IN_MINUTES * (isZoomedIn ? ZOOMED_IN_PX_PER_MINUTE : PX_PER_MINUTE);

/**
 * Validates input hours are between 0 and 24, and endHour is not before startHour
 * @param {number} startHour - The hour to start from
 * @param {number} endHour - The hour to end at
 * @throws Will throw an error if startHour or endHour is not between 0 and 24, or if endHour is before startHour
 */

dayjs.extend(customParseFormat);
function validateHourInput(startHour: number, endHour: number): void {
  if (startHour < 0 || startHour > 24 || endHour < 0 || endHour > 24) {
    throw new Error('Hour should be between 0 and 24');
  }

  if (endHour < startHour) {
    throw new Error('End hour cannot be before start hour');
  }
}

/**
 * Parses a given timestamp into a valid `dayjs` object.
 *
 * This function tries multiple formats to parse the timestamp and throws an error if it's unable to parse it.
 *
 * @param {string | number} timestamp - The timestamp to parse, which can be a string in various formats or a number.
 *
 * @returns {dayjs.Dayjs} A valid `dayjs` object representing the parsed timestamp.
 *
 * @throws {Error} If the timestamp cannot be parsed into a valid format.
 *
 * @example
 * parseTimestamp('14:30'); // returns a dayjs object representing 14:30
 */
const parseTimestamp = (timestamp: string | number) => {
  let time = dayjs(timestamp);

  if (!time.isValid()) {
    time = dayjs(timestamp, 'HH:mm A');
  }

  if (!time.isValid()) {
    time = dayjs(timestamp, 'HH:mm');
  }

  if (!time.isValid()) {
    time = dayjs(timestamp, 'H A');
  }

  if (!time.isValid()) {
    throw new Error('Invalid timestamp format');
  }

  return time;
};

/**
 * Converts a timestamp to a 12-hour format time string.
 *
 * @param {string | number} timestamp - The input timestamp, which can be a string or a number.
 * @param {boolean} [showMeridiem=false] - Determines whether the output should include the AM/PM indicator.
 * @returns {string} The time in 12-hour format.
 *
 * @example
 * timestampTo12HourFormat('14:30'); // "02:30"
 * timestampTo12HourFormat(1586223820000, true); // E.g., "04:30 PM"
 */
export const timestampTo12HourFormat = (timestamp: string | number, showMeridiem = false): string => {
  const time = parseTimestamp(timestamp);

  return time.format(showMeridiem ? 'h:mm A' : 'h:mm');
};

/**
 * Formats an hour in 24-hour format to a 12-hour format with AM/PM label.
 *
 * @param {number} hour - The hour in 24-hour format (0 to 24).
 * @returns {string} The formatted hour in 12-hour format with AM/PM.
 *
 * @example
 * formatHourToLabel(0); // "12 AM"
 * formatHourToLabel(13); // "1 PM"
 * formatHourToLabel(23); // "11 PM"
 */
function formatHourToLabel(hour: number): string {
  const time = dayjs().hour(hour).minute(0);

  return time.format('h A');
}

/**
 * Generate an array of hours in a day within a specified range.
 * @param {number} startHour - The hour to start from (default is 0)
 * @param {number} endHour - The hour to end at (default is 24)
 * @returns {string[]} - An array of hours as strings in the format '[H] AM/PM'
 * @throws Will throw an error if startHour or endHour is not between 0 and 24, or if endHour is before startHour
 */
export function generateHourLabels(startHour = 0, endHour = 24): string[] {
  validateHourInput(startHour, endHour);

  const hours: string[] = [];

  for (let i = startHour; i <= endHour; i++) {
    const hourLabel = formatHourToLabel(i);
    hours.push(hourLabel);
  }

  return hours;
}

export function calculatePercentage(num: number, total: number): number {
  if (total === 0) {
    throw new Error('Division by zero is undefined');
  }
  return (num / total) * 100;
}

/**
 * Converts a timestamp string into an object containing its hours and minutes.
 *
 * @param {string} timestamp - The input timestamp as a string.
 * @returns {Object} An object with properties for hours and minutes.
 * @property {number} hours - The hours extracted from the timestamp.
 * @property {number} minutes - The minutes extracted from the timestamp.
 *
 * @example
 * timestampToHourAndMinutes('14:30'); // { hours: 14, minutes: 30 }
 */

export function timestampToHourAndMinutes(timestamp: string) {
  const time = parseTimestamp(timestamp);
  return { hours: time.hour(), minutes: time.minute() };
}

/**
 * Creates a function that calculates the number of minutes from a given timestamp to a starting hour.
 * @param {number} startHour - The hour to start from (default is 0)
 * @returns {Function} - A function that takes a timestamp string and returns the number of minutes from startHour to the timestamp
 */

export const maxTotalGridColumns = (startHour = 0, endHour = 0) => {
  const offset = 2;
  return (endHour - startHour + 1) * 60 - offset;
};

/**
 * Creates a function to compute the total minutes from a given timestamp,
 * bounded by specified start and end hours.
 *
 * @param {number} [startHour=0] - The starting hour for the calculation. Defaults to 0.
 * @param {number} [endHour=24] - The ending hour for the calculation. Defaults to 24.
 *
 * @returns {Function} A function that computes the total minutes from a timestamp.
 * @property {string} timestamp - A string timestamp.
 *
 * @example
 * const minutesFromTimestamp = createMinutesFromTimestampFunction(8, 16);
 * minutesFromTimestamp('10:30'); // 150
 */
export const createMinutesFromTimestampFunction =
  (startHour = 0, endHour = 24, isZoomedIn?: boolean) =>
  (timestamp: string) => {
    const pixelPerMinute = getPxPerMinute(isZoomedIn);

    const { hours, minutes } = timestampToHourAndMinutes(timestamp);

    const totalMinutes = (hours - startHour) * 60 + minutes + 1;

    const maxTotalMinutes = maxTotalGridColumns(startHour, endHour);

    if (totalMinutes < 0) {
      return 1; //offset
    }
    if (totalMinutes > maxTotalMinutes) {
      return maxTotalMinutes;
    }

    return totalMinutes * pixelPerMinute;
  };

/**
 * Gets the number of pixels from a given timestamp,
 *
 * @param {Object} {startHour, endHour, totalMinutes} - The starting hour, ending hour, and total minutes from the starting hour.
 * @returns {number} The number of pixels from the timestamp.
 */
export const getPixelsFromTimestamp = ({
  startHour,
  endHour,
  totalMinutes,
  isZoomedIn,
}: GetPixelFromTimestampParamsType) => {
  const maxTotalMinutes = maxTotalGridColumns(startHour, endHour);
  const pixelPerMinute = getPxPerMinute(isZoomedIn);

  if (totalMinutes < 0) {
    return 1; //offset
  }
  if (totalMinutes > maxTotalMinutes) {
    return maxTotalMinutes * pixelPerMinute;
  }

  return totalMinutes * pixelPerMinute;
};

/**
 * Checks if the end timestamp is of the next day compared to the start timestamp.
 *
 * @param {string} startHourTimestamp
 * @param {string} endHourTimeStamp
 * @returns {boolean}
 *
 * @example
 * endHourTimeStamp('10:30 AM', '11:30 AM'); // true
 * endHourTimeStamp('10:30 PM', '01:30 AM'); // false
 * endHourTimeStamp('20:30', '23:30'); // true
 * endHourTimeStamp('12:30', '01:30'); // false
 */
export const isEndHourValid = (startHourTimestamp: string, endHourTimeStamp: string): boolean => {
  const format = ['hh:mm A', 'HH:mm'];

  const startHour = dayjs(startHourTimestamp, format);
  const endHour = dayjs(endHourTimeStamp, format);
  const midnight = dayjs('12:00 AM', format);

  if (!startHour.isValid() || !endHour.isValid() || !midnight.isValid()) {
    return false;
  }

  const isEndHourAfterMidnight = endHour.isAfter(midnight);
  const isEndHourSameAsMidnight = endHour.isSame(midnight);
  const isEndHourBeforeStartHour = endHour.isBefore(startHour);

  if ((isEndHourAfterMidnight || isEndHourSameAsMidnight) && isEndHourBeforeStartHour) {
    return false;
  }

  return true;
};

/**
 * Creates a function to compute the grid columns from a given start and end timestamp,
 *
 * @param {number} [startHour=0] - The starting hour for the calculation. Defaults to 0.
 * @param {number} [endHour=24] - The ending hour for the calculation. Defaults to 24.
 *
 * @returns {Function} A function that computes the grid columns from a start and end timestamp.
 * @property {string} startHourTimestamp - A string timestamp.
 * @property {string} endHourTimeStamp - A string timestamp.
 *
 * @example
 * const getGridColumnsFromTimestamp = createGridColumnsFromStartAndEndTimestamp(8, 16);
 * getGridColumnsFromTimestamp(startHourTimestamp, endHourTimeStamp); {startGridColumn: 150, endGridColumn: 300}
 */
export const createGridColumnsFromStartAndEndTimestamp =
  (startHour = 0, endHour = 24, isZoomedIn?: boolean) =>
  ({ startHourTimestamp, endHourTimeStamp }: GetGridColumnsFromTimestampParamsType) => {
    let tempEndHourTimeStamp = endHourTimeStamp;

    if (!endHourTimeStamp) {
      tempEndHourTimeStamp = dayjs(startHourTimestamp, 'hh:mm A').add(30, 'minutes').format('hh:mm A');
    }

    const isSameDay = isEndHourValid(startHourTimestamp, endHourTimeStamp ? endHourTimeStamp : tempEndHourTimeStamp);
    const { hours: startHours, minutes: startMinutes } = timestampToHourAndMinutes(startHourTimestamp);
    const { hours: endHours, minutes: endMinutes } = timestampToHourAndMinutes(
      endHourTimeStamp ? endHourTimeStamp : tempEndHourTimeStamp
    );

    const nextDayOffset = isSameDay ? 0 : 24;

    const startTotalMinutes = (startHours - startHour) * 60 + startMinutes + 1;
    const endTotalMinutes = (endHours + nextDayOffset - startHour) * 60 + endMinutes;

    return {
      startGridColumn: getPixelsFromTimestamp({ startHour, endHour, totalMinutes: startTotalMinutes, isZoomedIn }),
      endGridColumn: getPixelsFromTimestamp({ startHour, endHour, totalMinutes: endTotalMinutes, isZoomedIn }),
    };
  };

export function calculateTimeProgress(totalDurationInHours: number, hoursOffset = 0) {
  const currentDate = new Date();

  const currentHours = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();

  const totalDurationInMinutes = totalDurationInHours * 60;
  const offsetInMinutes = hoursOffset * 60;

  const minutesPassedSinceOffset = currentHours * 60 + currentMinutes - offsetInMinutes;
  const progressPercentage = calculatePercentage(minutesPassedSinceOffset, totalDurationInMinutes);

  const hasCrossedTotalDuration = minutesPassedSinceOffset >= totalDurationInMinutes;

  return { progressPercentage, hasCrossedTotalDuration };
}

/**
 * Scrolls the container horizontally to the specified percentage.
 *
 * @param {number} percentage - The target percentage to which the container should be scrolled. Must be between 0 and 100.
 * @param {HTMLElement} container - The container element that needs to be scrolled.
 * @param {boolean} isVerticalView - A flag indicating whether the view is vertical or horizontal.
 * @returns {void}
 * @throws Will throw an error if the percentage is not between 0 and 100.
 */
export function scrollContainerToPercentage(percentage: number, container: HTMLElement, isVerticalView: boolean): void {
  if (percentage < 0 || percentage > 100) {
    console.error('Percentage must be between 0 and 100');
    return;
  }

  if (isVerticalView) {
    scrollContainerToPercentageVertically(percentage, container);
  } else {
    scrollContainerToPercentageHorizontally(percentage, container);
  }
}

export function scrollContainerToPercentageVertically(percentage: number, container: HTMLElement): void {
  const targetPoint = container.scrollHeight * (percentage / 100);

  const scrollPosition = Math.max(
    0,
    Math.min(targetPoint - container.clientHeight / 2, container.scrollHeight - container.clientHeight)
  );

  container.scrollTop = scrollPosition;
}

export function scrollContainerToPercentageHorizontally(percentage: number, container: HTMLElement): void {
  const targetPoint = container.scrollWidth * (percentage / 100);

  const scrollPosition = Math.max(
    0,
    Math.min(targetPoint - container.clientWidth / 2, container.scrollWidth - container.clientWidth)
  );

  container.scrollLeft = scrollPosition;
}

/**
 * Validates the start and end hours.
 *
 * @param {number} startHour - The starting hour. Must be between 0 and 23.
 * @param {number} endHour - The ending hour. Must be between 0 and 24 and must be greater than startHour.
 * @returns {void}
 * @throws Will throw an error if startHour or endHour are not valid.
 */
function validateHours(startHour: number, endHour: number) {
  if (startHour < 0 || startHour > 23 || endHour < 0 || endHour > 24 || startHour >= endHour) {
    throw new Error('Invalid start or end hour.');
  }
}

function calculateYPosition(event: React.MouseEvent<Element> | MouseEvent, element: Element) {
  const rect = element.getBoundingClientRect();
  const yRelativeToElement = event.clientY - rect.top;
  return element.scrollTop + yRelativeToElement;
}

function calculateXPosition(event: React.MouseEvent<Element> | MouseEvent, element: Element) {
  const rect = element.getBoundingClientRect();
  const xRelativeToElement = event.clientX - rect.left;
  return element.scrollLeft + xRelativeToElement;
}

function calculateHoursAndMinutes(
  positionWithinScrollWidth: number,
  startHour: number,
  sectionWidth: number,
  numberOfSections: number
) {
  const sectionIndex = Math.floor(positionWithinScrollWidth / sectionWidth);
  const positionWithinSection = positionWithinScrollWidth % sectionWidth;

  const hours = (sectionIndex % numberOfSections) + startHour;
  const minutes = Math.floor((positionWithinSection / sectionWidth) * 60);

  return { hours, minutes, sectionIndex };
}

function format12HourTime(hours: number, minutes: number, isZoomedIn?: boolean) {
  const pixelPerMinute = getPxPerMinute(isZoomedIn);

  const time = dayjs()
    .hour(hours)
    .minute(minutes / pixelPerMinute);
  return time.format('hh:mm A');
}

type Increment = 5 | 10 | 15;

/**
 * Rounds the provided number of minutes to the nearest specified increment.
 *
 * @param {number} mins - The number of minutes to round.
 * @param {Increment} [increment=5] - The increment to which the minutes should be rounded to. Valid values are 5, 10, or 15.
 * @returns {number} The rounded number of minutes.
 *
 * @example
 * toNearestIncrement(8); // 10
 * toNearestIncrement(8, 15); // 15
 */
export function toNearestIncrement(mins: number, increment: Increment = 5): number {
  const remainder = mins % increment;
  const halfIncrement = increment / 2;

  if (remainder === 0) {
    return mins;
  }

  return remainder <= halfIncrement ? mins - remainder : mins + (increment - remainder);
}

interface GetTimestamp {
  /**
   * The event from which to derive the timestamp.
   */
  event: React.MouseEvent<Element> | MouseEvent;
  /**
   * The reference element for calculating position.
   */
  element?: Element | null;
  /**
   * The starting hour of the period (e.g., 0 for midnight).
   * @default 0
   */
  startHour?: number;
  /**
   * The ending hour of the period (e.g., 24 for midnight of the next day).
   * @default 24
   */
  endHour?: number;
  /**
   * Round to the nearest increment of this value in minutes (e.g., 15 for quarter-hour increments).
   */
  nearestQuarter?: boolean;
  /**
   * A flag indicating whether or not to round to the nearest quarter.
   */
  nearestIncrement?: Increment;

  /**
   * A flag indicating whether the view is vertical or horizontal.
   */
  isVerticalView: boolean;
  /**
   * A flag indicating whether the view is zoomed in.
   */
  isZoomedIn?: boolean;
}

/**
 * Calculates a timestamp based on the position of an event relative to a given element.
 *
 * @param {GetTimestamp} params - The parameters used for calculating the timestamp.
 * @returns {Object} An object containing a `formattedTime` string in 12-hour format and `totalMinutes` which represents the number of minutes from midnight.
 */
export function getTimestamp({
  event,
  element,
  startHour = 0,
  endHour = 24,
  nearestIncrement,
  nearestQuarter = false,
  isVerticalView,
  isZoomedIn,
}: GetTimestamp) {
  validateHours(startHour, endHour);
  if (!element) return;
  const positionWithinScrollWidth = isVerticalView
    ? calculateYPosition(event, element)
    : calculateXPosition(event, element);
  const numberOfSections = endHour - startHour + 1;
  const sectionWidth = isVerticalView
    ? element.scrollHeight / numberOfSections
    : element.scrollWidth / numberOfSections;
  const { hours, minutes } = calculateHoursAndMinutes(
    positionWithinScrollWidth,
    startHour,
    sectionWidth,
    numberOfSections
  );

  const pixelPerMinute = getPxPerMinute(isZoomedIn);

  const correctedMinutes = nearestQuarter
    ? toNearestIncrement(minutes * pixelPerMinute, nearestIncrement)
    : minutes * pixelPerMinute;
  const formattedTime = format12HourTime(hours, correctedMinutes, isZoomedIn);

  const totalMinutes = (hours - startHour) * (60 * pixelPerMinute) + correctedMinutes;

  return { formattedTime, totalMinutes };
}

interface CheckIntersectionOptions {
  /**
   * The target element to check intersection for.
   */
  targetElement?: Element;
  /**
   * The container element within which other intersecting elements are looked up.
   */
  containerElement?: Element;
  /**
   * The selector used to query desired elements within the container for intersection check.
   * @default dataAttributeSelector='[data-event="true"]'
   */
  dataAttributeSelector?: string;
}

/**
 * Checks if the target element intersects with any of the desired elements
 * (identified by the data attribute) within a given container.
 *
 * @param {CheckIntersectionOptions} options - Options including target element, container, and optional data attribute selector.
 * @returns {boolean} Returns true if the target element intersects with any desired element, false otherwise.
 */
export const checkIntersection = ({
  targetElement,
  containerElement,
  dataAttributeSelector = '[data-event="true"]',
}: CheckIntersectionOptions): boolean => {
  if (!targetElement || !containerElement) return false;

  const elementBox = targetElement.getBoundingClientRect();
  const desiredElements = containerElement.querySelectorAll(dataAttributeSelector);

  return Array.from(desiredElements).some((element: Element) => {
    const desiredBox = element.getBoundingClientRect();

    return (
      elementBox.left < desiredBox.right &&
      elementBox.right > desiredBox.left &&
      elementBox.top < desiredBox.bottom &&
      elementBox.bottom > desiredBox.top
    );
  });
};

type EnsureVisibilityOptions = {
  topOffset?: number;
  leftOffset?: number;
  rightOffset?: number;
  bottomOffset?: number;
  isAppointment?: boolean;
};

export const EVENT_CARD_DEFAULT_MEASUREMENTS = {
  expandedWidth: 240,
  expandedWidthForAppointment: 600,
  condensedHeight: 40,
};

/**
 * Ensures the visibility of an element within a parent element.
 * If the element is not fully visible within the constraints set by the offsets,
 * the parent element will smoothly scroll to make it visible.
 *
 * @param {Element} element - The target element which visibility needs to be ensured.
 * @param {Element} parentElement - The parent element within which the target should be visible.
 * @param {EnsureVisibilityOptions} options - The offset options for ensuring visibility.
 */
export const ensureElementVisibility = (
  element: Element,
  parentElement: Element,
  options?: EnsureVisibilityOptions
) => {
  const { topOffset = 65, leftOffset = 78, rightOffset = 5, bottomOffset = 30 } = options ?? {};

  const parentRect = parentElement.getBoundingClientRect();
  const rect = element.getBoundingClientRect();

  if (rect.top < parentRect.top + topOffset) {
    parentElement.scrollTo({
      top: parentElement.scrollTop - (parentRect.top + topOffset) + rect.top,
      behavior: 'smooth',
    });
  } else if (rect.bottom > parentRect.bottom - bottomOffset) {
    parentElement.scrollTo({
      top: parentElement.scrollTop + rect.bottom - (parentRect.bottom - bottomOffset),
      behavior: 'smooth',
    });
  }

  const difference =
    (options?.isAppointment
      ? EVENT_CARD_DEFAULT_MEASUREMENTS.expandedWidthForAppointment
      : EVENT_CARD_DEFAULT_MEASUREMENTS.expandedWidth) - rect.width;
  const offset = difference < 0 ? 0 : difference;

  if (rect.left < parentRect.left + leftOffset) {
    parentElement.scrollTo({
      left: parentElement.scrollLeft - (parentRect.left + leftOffset) + rect.left,
      behavior: 'smooth',
    });
  } else if (rect.right > parentRect.right - offset - rightOffset) {
    parentElement.scrollTo({
      left: parentElement.scrollLeft + (rect.right + offset) - (parentRect.right - rightOffset),
      behavior: 'smooth',
    });
  }
};

/**
 * Calculates the distance of an element from the top and bottom edges of its parent element.
 *
 * @param {HTMLElement} element - The target element for which the distances should be calculated.
 * @param {HTMLElement} parentElement - The reference parent element to which the distances are relative.
 * @returns {Object} An object containing distances `top` and `bottom` representing the distance of the element from the top and bottom edges of the parent element, respectively.
 */
export const getDistanceFromParentEdges = (element: HTMLElement, parentElement: HTMLElement) => {
  const parentRect = parentElement.getBoundingClientRect();
  const rect = element.getBoundingClientRect();

  const topDistance = rect.top - parentRect.top;
  const bottomDistance = parentRect.bottom - rect.top;

  return {
    top: topDistance,
    bottom: bottomDistance,
  };
};

export const zIndex = {
  progressLine: 0,
  eventTimelineWrapper: 1,
  hoursOfTheDay: 2,
  backgroundOverlay: 3,
  eventCreatorCard: 4,
  providerSection: 5,
  eventCardDefault: 1,
  eventCardExpanded: 3,
} as const;

export const getDurationFromHours = (startHour: string, endHour: string) => {
  let durationString = '';
  const startTimeMatch = startHour === '00:00' || startHour === '12:00 AM';
  const endTimeMatch = endHour === '23:59' || endHour === '11:59 PM';

  if (startTimeMatch && endTimeMatch) {
    durationString = 'All day';
  } else {
    durationString = `${timestampTo12HourFormat(startHour, true)} ${
      endHour ? ` -  ${timestampTo12HourFormat(endHour, true)}` : ''
    }`;
  }
  return durationString;
};

/**
 * Groups overlapping events based on a specified overlap threshold.
 *
 * @param {EventData[]} events - The array of events to be grouped. Each event should have a `startHour` and `endHour` property.
 * @param {number} [overlapThreshold=5] - The minimum overlap duration (in minutes) for two events to be considered overlapping. Default is 5.

 * @returns {EventData[][]} - Returns an array of event groups. Each group is an array of events that overlap with each other.
 *
 * @example
 * 
 * const events = [
 *   { startHour: '10:00 AM', endHour: '11:00 AM' },
 *   { startHour: '10:30 AM', endHour: '11:30 AM' },
 *   { startHour: '12:00 PM', endHour: '1:00 PM' }
 * ];
 * const groupedEvents = groupOverlappingEvents(events, 15);
 * console.log(groupedEvents);
 * // Output: [ [ { startHour: '10:00 AM', endHour: '11:00 AM' }, { startHour: '10:30 AM', endHour: '11:30 AM' } ], [ { startHour: '12:00 PM', endHour: '1:00 PM' } ] ]
 */

export function groupOverlappingEvents(events: EventData[], overlapThreshold = 5): EventData[][] {
  const groups: EventData[][] = [];
  const activeGroups: EventData[][] = [];

  for (const event of events) {
    const { startHour, endHour } = event;
    const startTime = dayjs(startHour, 'h:mm A');
    const endTime = dayjs(endHour, 'h:mm A');

    let groupIndex = -1;
    for (let i = 0; i < activeGroups.length; i++) {
      const group = activeGroups[i];
      const lastEvent = group[group.length - 1];
      const lastEventEndTime = dayjs(lastEvent.endHour, 'h:mm A');
      const overlapDuration = Math.min(endTime.diff(startTime, 'minute'), lastEventEndTime.diff(startTime, 'minute'));
      if (overlapDuration > overlapThreshold) {
        groupIndex = i;
        break;
      }
    }

    if (groupIndex === -1) {
      activeGroups.push([event]);
      groups.push([event]);
    } else {
      activeGroups[groupIndex].push(event);
      groups[groupIndex].push(event);
    }
  }

  return groups;
}

/**
 * Determines the width ratio of an event based on its group index.
 *
 * @param {number} groupIndex - The index of the group to which the event belongs.
 *
 * @returns {number} - Returns the width ratio of the event. The width ratio is 1 for the first group, 0.7 for the second group, 0.4 for the third group, and 0.2 for the fourth group and beyond.
 *
 * @example
 *
 * const widthRatio = getEventWidthRatio(2);
 * console.log(widthRatio); // Output: 0.7
 */

export function getEventWidthRatio(groupIndex: number): number {
  let width = 1;

  if (groupIndex === 1) {
    width = 0.7;
  } else if (groupIndex === 2) {
    width = 0.4;
  } else if (groupIndex >= 3) {
    width = 0.2;
  }

  return width;
}
