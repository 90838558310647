import { ModalControlModalProps, Table, TableColumnConfig } from '@frontend/design-system';
import { useEffect, useState } from 'react';
import { RecordTableControlsParams, useRecordTableControls } from '../../hooks/use-record-table-controls';
import {
  ContactInfo,
  Order,
  Recall,
  Appointment,
  Person,
} from '@weave/schema-gen-ts/dist/schemas/messaging/etl/history/v1/service.pb';
import { TableInstance } from 'react-table';
import { MessagesTypes } from '@frontend/api-messaging';

type Props<RecordType extends MessagesTypes.SmsRecord, ListRequestType extends MessagesTypes.ListRequest> = {
  colConfig: TableColumnConfig<RecordType>[];
  data: RecordType[];
  isLoading?: boolean;
  manualFiltersRender?: (
    modalProps: ModalControlModalProps,
    setShowNotification: (showNotification: boolean) => void,
    tableInstance?: TableInstance<RecordType>
  ) => React.ReactNode;
  page: number;
  requestParams: ListRequestType;
  setRequestParams: (params: ListRequestType) => void;
  setPage: (page: number) => void;
};

const isAppointment = (data: MessagesTypes.SmsRecord): data is Appointment => {
  return 'appointmentId' in data;
};

const isEvent = (data: MessagesTypes.SmsRecord): data is Recall | Order => {
  return 'eventId' in data;
};

const isPerson = (data: MessagesTypes.SmsRecord): data is Person => {
  return 'personId' in data;
};

const isContactInfo = (data: MessagesTypes.SmsRecord): data is ContactInfo => {
  return 'contactInfoId' in data;
};

export const RecordTable = <
  RecordType extends MessagesTypes.SmsRecord,
  ListRequestType extends MessagesTypes.ListRequest
>({
  colConfig,
  data,
  isLoading,
  manualFiltersRender,
  page,
  setPage,
  requestParams,
  setRequestParams,
}: Props<RecordType, ListRequestType>) => {
  const [pageParams, setPageParams] = useState<RecordTableControlsParams>({});

  const hasMore = requestParams.pageSize ? !!data?.[requestParams.pageSize - 1] : false;

  useEffect(() => {
    if (data?.length) {
      const firstRecordId = setRecordId(data[0]);
      const firstRecordDate = setRecordDate(data[0]);
      const lastRecordId = setRecordId(data[data.length - 1]);
      const lastRecordDate = setRecordDate(data[data.length - 1]);
      setPageParams({
        firstRecordId,
        firstRecordDate,
        lastRecordId,
        lastRecordDate,
      });
    }
  }, [data, requestParams]);

  const { handlePageForward, handlePageBackward } = useRecordTableControls<ListRequestType>({
    pageParams,
    page,
    requestParams,
    setRequestParams,
    setPage,
  });

  const setRecordId = (data: MessagesTypes.SmsRecord): string | undefined => {
    if (isAppointment(data)) return data.appointmentId;
    if (isEvent(data)) return data.eventId;
    if (isPerson(data)) return data.personId;
    if (isContactInfo(data)) return data.contactInfoId;
    return undefined;
  };

  const setRecordDate = (data: MessagesTypes.SmsRecord): string | undefined => {
    const hasModifiedDate = isAppointment(data) || isEvent(data) || isPerson(data) || isContactInfo(data);
    if (hasModifiedDate) return data.modifiedAt;
    return undefined;
  };

  return (
    <Table
      colConfig={colConfig}
      isPaginated
      data={data}
      isLoading={isLoading}
      manualFiltersRender={manualFiltersRender}
      manualPaginationConfig={{
        page,
        hasNext: hasMore,
        hasPrevious: hasMore,
        defaultRowsPerPage: requestParams.pageSize,
        onNumRowsChange: (numRows) => {
          setRequestParams({ ...requestParams, pageSize: numRows });
          setPage(1);
        },
        rowsPerPageOptions: [10, 25, 50, 100],
        handleChange: (prevOrNext: 'prev' | 'next') => {
          if (prevOrNext === 'prev') handlePageBackward();
          if (prevOrNext === 'next') handlePageForward();
        },
      }}
    />
  );
};
