import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { UseMutationResult } from 'react-query';
import { NotificationSettingsTypes, TemplateTagUtils } from '@frontend/api';
import { ManualTemplatesTypes } from '@frontend/api-manual-templates';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { MessageTemplate, Text, TagType, ButtonBar, PrimaryButton, Heading } from '@frontend/design-system';
import { EditMessageTemplatePageWrapper } from './EditMessageTemplatePageWrapper';

interface Props {
  defaultTemplateText: string;
  initialTemplate?: Partial<NotificationSettingsTypes.NotificationSetting>;
  tags: TagType[];
  onSave: UseMutationResult<
    NotificationSettingsTypes.NotificationSettingsResponse | ManualTemplatesTypes.UpdateManualTemplateResponse,
    unknown,
    NotificationSettingsTypes.UpdateTemplateProps,
    unknown
  >;
  templateSubType: string;
  width?: string | number;
}

export const EditMessageTemplate = ({
  initialTemplate,
  tags,
  onSave,
  defaultTemplateText,
  templateSubType,
  width = 700,
}: Props) => {
  const { t } = useTranslation('schedule');
  const [notificationTemplate, setNotificationTemplate] = useState<string>(
    initialTemplate?.template?.text || defaultTemplateText
  );

  const updateNotificationTemplate = useCallback(() => {
    const standardizedTemplateText = TemplateTagUtils.standardizeTemplateTextTags(t, notificationTemplate);
    onSave.mutate({
      oldTemplate: initialTemplate,
      newTemplateText: standardizedTemplateText,
      templateSubType,
    });
  }, [initialTemplate?.template?.text, notificationTemplate]);

  return (
    <EditMessageTemplatePageWrapper width={width}>
      <MessageTemplate
        initialTemplate={initialTemplate?.template?.text || defaultTemplateText}
        tags={tags}
        onChange={(template) => {
          setNotificationTemplate(template);
        }}
        css={editTemplateSpacing}
      >
        <MessageTemplate.Editor placeholder={t('Customize your message')} css={editTemplateSpacing} />

        <Text as='h3' weight='bold' css={editTemplateSpacing}>
          {t('Drag & drop or click to add to your message')}
        </Text>
        <MessageTemplate.TagList css={editTemplateSpacing} />

        <Heading level={3} css={editTemplateSpacing}>
          {t('Message Preview')}
        </Heading>
        <MessageTemplate.Preview css={editTemplateSpacing} />
      </MessageTemplate>
      <ButtonBar css={buttonBarContainerStyles}>
        <PrimaryButton onClick={updateNotificationTemplate}>{t('Save')}</PrimaryButton>
        {/**TODO: Implement Reset feature once communicating the feature with the Message template team */}
        {/* <SecondaryButton disabled>{t('Reset')}</SecondaryButton> */}
      </ButtonBar>
    </EditMessageTemplatePageWrapper>
  );
};

const editTemplateSpacing = css({
  marginBottom: theme.spacing(2),
});

const buttonBarContainerStyles = css({
  justifyContent: 'start',
  padding: theme.spacing(2, 0),
  '> button': {
    width: 'fit-content',
  },
});
