import { memo, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useLocation } from '@tanstack/react-location';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart, YAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { usePaNavigate, usePracticeAnalyticsStore } from '../../hooks';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { CompareLocationsButton } from '../compare-locations-button';
import { DemoChip } from '../demo-chip';
import { monthlyTrendChartModal } from './monthly-trend-chart-modal';
import { PracticeAnalyticsInfoTips } from './practice-analytics-info-tips';

const query = gql`
  query {
    location {
      activePatientsScheduled {
        percentage
        benchmarks {
          value
          label
        }
      }
    }
  }
`;

export type PracticePercentages = {
  active?: number;
  average?: number;
  top?: number;
};

type Props = {
  onUpdatePatientPercentage: (percentages: PracticePercentages) => void;
  renderBottomContent: () => JSX.Element;
  windowWidth: number;
};

const colors = {
  activePractice: theme.colors.warning50,
  averagePractice: theme.colors.primary20,
  topPractice: theme.colors.secondary.seaweed30,
};

export const ActivePatientsScheduledMetric = memo(
  ({
    clickNoop,
    isDrillDownPage,
    onFetchStateChange,
    onUpdatePatientPercentage,
    renderBottomContent,
    windowWidth,
  }: PracticeAnalyticsTypes.MetricProps & Props) => {
    const alert = useAlert();
    const { t } = useTranslation('analytics');
    const navigate = usePaNavigate();
    const { current } = useLocation();
    const { demoData, filters, isDemoAccount } = usePracticeAnalyticsStore();

    const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

    const labels = {
      activePractice: multipleLocationsSelected ? t('Your Avg Practice') : t('Your Practice'),
      averagePractice: t('Average Practice'),
      topPractice: t('Top Practice'),
    };

    const activePatientsScheduledModal = monthlyTrendChartModal({
      defaultKey: 'percentage',
      metric: 'activePatientsScheduled',
      title: t('Trend: Active Patients Scheduled'),
      trackingId: trackingIds.practiceAnalytics.activeScheduledPatientsTrend,
    });

    const { data, isLoading } = useScopedQuery({
      queryKey: queryKeys.practiceAnalyticsCharts(
        `summaryMetricActivePatientsScheduled-${isDemoAccount}-${JSON.stringify(filters.locations)}`
      ),
      queryFn: () =>
        isDemoAccount || !filters.locations?.length
          ? null
          : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.ActivePatientsScheduledResponse>({
              locationIds: filters.locations,
              queries: [query],
            }),
      onError: () => {
        alert.error(t("Couldn't load the dashboard data. Please try again."));
      },
      select: (data) => (isDemoAccount ? demoData?.activePatientsScheduled : data),
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    });

    const aggregatedData = useMemo(() => {
      return PracticeAnalyticsAggregations.activePatientsScheduledSummary(data, filters.locations?.length);
    }, [data?.data]);

    const activePatientsScheduledData: BarChartData = {
      groups: [
        {
          name: 'activePractice',
          values: {
            activePractice: aggregatedData?.activePracticePercent || 0,
          },
        },
        {
          name: 'averagePractice',
          values: {
            averagePractice: aggregatedData?.averagePracticePercent || 0,
          },
        },
        {
          name: 'topPractice',
          values: {
            topPractice: aggregatedData?.topPracticePercent || 0,
          },
        },
      ],
    };

    useEffect(() => {
      onFetchStateChange?.(isLoading);
    }, [isLoading]);

    useEffect(() => {
      onUpdatePatientPercentage({
        active: aggregatedData.activePracticePercent,
        average: aggregatedData.averagePracticePercent,
        top: aggregatedData.topPracticePercent,
      });
    }, [aggregatedData]);

    return (
      <>
        <Chart
          bottomContentRenderer={windowWidth < breakpoints.medium.min ? renderBottomContent() : null}
          colors={colors}
          isLoading={isLoading}
          labels={labels}
          onClick={
            clickNoop
              ? undefined
              : () => {
                  navigate({
                    to: `${current.pathname}/unscheduled-patients`,
                  });
                }
          }
          trackingId={trackingIds.practiceAnalytics.activePatientsScheduledChart}
        >
          {isDrillDownPage ? (
            isDemoAccount && <DemoChip />
          ) : (
            <Chart.Header
              actions={[activePatientsScheduledModal.triggerProps]}
              bottomElement={multipleLocationsSelected ? <CompareLocationsButton /> : null}
              infoTip={<PracticeAnalyticsInfoTips tip='activePatientsScheduled' />}
              leftElement={isDemoAccount ? <DemoChip /> : null}
              title={t('Active Patients Scheduled')}
            />
          )}
          <Chart.BarChart
            appearance={{
              customXAxisTickFormat: (value) => `${value}%`,
              customYAxisTick: ({ labels, groupName, ...rest }) => {
                const typedVal = groupName as keyof typeof labels;

                return (
                  <YAxisLabelValueTick
                    {...rest}
                    clipLength={20}
                    label={labels?.[groupName] || groupName}
                    value={`${aggregatedData?.[`${typedVal}Percent`] || 0}%`}
                  />
                );
              },
              groupsGap: 24,
              hideTooltip: true,
              layout: 'vertical',
              margin: { right: 20, left: 100 },
              showGridLines: true,
              showXAxis: true,
              showYAxis: true,
              wrapperStyles: styles.chartWrapperStyles,
            }}
            data={activePatientsScheduledData}
          />
        </Chart>

        {activePatientsScheduledModal.modalRenderer()}
      </>
    );
  }
);

ActivePatientsScheduledMetric.displayName = 'ActivePatientsScheduledMetric';

const styles = {
  chartWrapperStyles: css`
    align-items: center;
    display: flex;
    height: 100%;
  `,
};
