import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useEffect, useMemo } from 'react';

import { SalesDevApi } from '@frontend/api';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { useModalControl } from '@frontend/design-system';

import {
  BillingAlertBanner,
  BillingAlertModal,
  OverdueBy50DaysMessage,
  OverdueBy70DaysMessage,
  Overdue75DaysMessage,
} from '../components';

const CACHE_AND_STALE_TIME_IN_HR = 1000 * 60 * 60; // 1 hour

type AlertMessageContentType = {
  isTerminating: boolean;
  messageContent: React.ReactNode;
};

export const LandingPageBillingAlertBannerAndModal = () => {
  const billingAlertModalProps = useModalControl();
  const { locationId } = useLocationDataShallowStore('locationId');
  const { getFlag } = useFeatureFlagShallowStore('getFlag');
  const isBillingEnabled = getFlag('Update Credit Card & Subscriber Info');
  const isNewBillingAlertsFeatureFlagEnabled = getFlag('billing-shutoff-release');

  const { isLoading: isInvoiceBalanceLoading, data: invoiceBalance } = useLocalizedQuery({
    queryKey: ['invoice-balance', 'billing-alerts'],
    queryFn: () => SalesDevApi.getInvoiceBalance(),
    enabled: !!locationId,
    retry: false,
    cacheTime: 12 * CACHE_AND_STALE_TIME_IN_HR, // 12 hours
    staleTime: 12 * CACHE_AND_STALE_TIME_IN_HR, // 12 hours
  });

  const alertMessageContent: AlertMessageContentType = useMemo(() => {
    let isTerminating = false;
    let messageContent = null;

    if (invoiceBalance && !isInvoiceBalanceLoading) {
      const isOverDueBy50Days = Number(invoiceBalance?.overdue50 ?? '0') > 0;
      const isOverDueBy70Days = Number(invoiceBalance?.overdue70 ?? '0') > 0;
      const isOverDueBy75Days = Number(invoiceBalance?.overdue75 ?? '0') > 0;

      const isOverdueBy50DaysOnly = isOverDueBy50Days && !isOverDueBy70Days && !isOverDueBy75Days;
      const isOverdueBy70DaysOnly = isOverDueBy70Days && !isOverDueBy75Days;
      const isOverdueBy75DaysOnly = isOverDueBy75Days;

      if (isOverdueBy50DaysOnly) {
        messageContent = <OverdueBy50DaysMessage />;
      } else if (isOverdueBy70DaysOnly && invoiceBalance?.warningDayCount) {
        isTerminating = true;
        messageContent = <OverdueBy70DaysMessage warningDayCount={invoiceBalance?.warningDayCount ?? 0} />;
      } else if (isOverdueBy75DaysOnly) {
        isTerminating = true;
        messageContent = <Overdue75DaysMessage />;
      }
    }
    return {
      isTerminating,
      messageContent,
    };
  }, [isInvoiceBalanceLoading, invoiceBalance]);

  useEffect(() => {
    if (alertMessageContent.messageContent) {
      billingAlertModalProps.openModal();
    }
  }, [alertMessageContent.messageContent]);

  if (!isNewBillingAlertsFeatureFlagEnabled || !isBillingEnabled || !alertMessageContent.messageContent) return null;

  return (
    <>
      <BillingAlertBanner
        billingAlertBannerMessageContent={alertMessageContent.messageContent}
        isTerminating={alertMessageContent.isTerminating}
      />
      <BillingAlertModal
        billingAlertMessageContent={alertMessageContent.messageContent}
        isTerminating={alertMessageContent.isTerminating}
        primaryTrackingId='billing-alerts-modal-close-primary-btn'
        {...billingAlertModalProps.modalProps}
      />
    </>
  );
};
