import { Options } from '@frontend/fetch';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { SchemaCommPreference, SchemaCommPreferenceConsent, SchemaIO } from '@frontend/schema';
import { GetPreferenceResponse } from '@weave/schema-gen-ts/dist/schemas/comm-preference/preference/v1/preference_service.pb';
import { UseMutationOptions, UseQueryOptions, useMutation, useQueries, useQuery, useQueryClient } from 'react-query';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

const preferencesToHide = [
  'picture-messages',
  'messaging-preferences',
  'weave-voicemail-notifications',
  'weave-password-reset',
  'marketing-messages',
];

const queryKeys = {
  preference: (params: SchemaGetPreference['input']) => ['preference', params],
  preferenceByToken: (token: string) => ['preferenceByToken', token],
  listEmail: () => ['listEmail'],
  listWeaveEmail: () => ['listWeaveEmail'],
  programs: (params: SchemaListPrograms['input']) => ['programs', params],
  listWeaveEmailUpdatePrograms: () => ['listWeaveEmailUpdatePrograms'],
};

type SchemaGetPreference = SchemaIO<(typeof SchemaCommPreference)['GetPreference']>;
export const useGetPreference = (request: SchemaGetPreference['input'], opts?: Options) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.preference(request),
    queryFn: () => SchemaCommPreference.GetPreference(request, opts),
    select: (response) =>
      response.preference?.programPreferences.filter((item) => !preferencesToHide.includes(item.programSlug!)),
    enabled: !!request.userChannelAddress && !!request.locationId,
    cacheTime: 0, // invalidate cache as soon as the component observing the query is unmounted
  });
export const useGetPreferences = (requests: { request: SchemaGetPreference['input']; opts?: Options }[]) =>
  useQueries(
    requests.map(({ request, opts }) => ({
      ...defaultOptions,
      queryKey: queryKeys.preference(request),
      queryFn: () => SchemaCommPreference.GetPreference(request, opts),
      select: (response: SchemaGetPreference['output']) =>
        response.preference?.programPreferences.filter((item) => !preferencesToHide.includes(item.programSlug!)),
      enabled: !!request.userChannelAddress && !!request.locationId,
    }))
  );

type SchemaListPrograms = SchemaIO<(typeof SchemaCommPreference)['ListPrograms']>;
export const useListPrograms = (
  request: SchemaListPrograms['input'],
  validation?: Options,
  opts: UseQueryOptions<SchemaListPrograms['output']['programs']> = {}
) =>
  useQuery({
    queryKey: queryKeys.programs(request),
    queryFn: () => SchemaCommPreference.ListPrograms(request, validation).then((res) => res.programs),
    ...opts,
  });

export const useListProgramsWithGroups = (
  request: SchemaListPrograms['input'],
  validation?: Options,
  opts: UseQueryOptions<SchemaListPrograms['output']> = {}
) =>
  useQuery({
    queryKey: queryKeys.programs(request),
    queryFn: () => SchemaCommPreference.ListPrograms(request, validation),
    ...opts,
  });

type SchemaListWeaveEmailUpdatePrograms = SchemaIO<(typeof SchemaCommPreference)['ListWeaveEmailUpdateProgramInfo']>;
export const useListWeaveEmailUpdatePrograms = (
  request: SchemaListWeaveEmailUpdatePrograms['input'],
  validation?: Options,
  opts: UseQueryOptions<SchemaListWeaveEmailUpdatePrograms['output']['weaveEmailUpdatePrograms']> = {}
) =>
  useQuery({
    queryKey: queryKeys.listWeaveEmailUpdatePrograms(),
    queryFn: () =>
      SchemaCommPreference.ListWeaveEmailUpdateProgramInfo(request, validation).then(
        (res) => res.weaveEmailUpdatePrograms
      ),
    ...opts,
  });

type SchemaRegisterConsent = SchemaIO<(typeof SchemaCommPreferenceConsent)['RegisterConsent']>;
export const useRegisterConsent = (
  request: SchemaRegisterConsent['input'],
  mutationOptions: UseMutationOptions<
    unknown,
    Error,
    SchemaRegisterConsent['input'],
    {
      previousSetting: GetPreferenceResponse | undefined;
    }
  > = {},
  opts?: Options
) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.preference({
    locationId: request.consent?.locationId,
    channel: request.consent?.channel,
    userChannelAddress: request.consent?.userChannelAddress,
  });

  return useMutation((req) => SchemaCommPreferenceConsent.RegisterConsent(req, opts), {
    ...defaultOptions,
    mutationKey: queryKey,
    onMutate: async (updatedSetting) => {
      await queryClient.cancelQueries(queryKey);
      const previousSetting = queryClient.getQueryData<GetPreferenceResponse>(queryKey);
      const newProgramPreferences = previousSetting?.preference?.programPreferences.map(
        (prev) =>
          updatedSetting.consent?.userProgramConsent?.find(({ programSlugId }) => programSlugId === prev.programSlug) ||
          prev
      );

      queryClient.setQueryData(queryKey, {
        ...previousSetting,
        programPreferences: newProgramPreferences,
      });
      return { previousSetting };
    },
    onError: (error: Error, updatedSetting, context) => {
      if (context) {
        queryClient.setQueriesData(queryKey, context.previousSetting);
      }
      if (mutationOptions.onError) {
        mutationOptions.onError(error, updatedSetting, context);
      }
    },
    ...mutationOptions,
  });
};

export const useListEmailProgramInfo = (opts: Options) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.listEmail(),
    queryFn: () => SchemaCommPreference.ListEmailProgramInfo({}, opts),
  });

export const useListWeaveEmailProgramInfo = (opts: Options) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.listWeaveEmail(),
    queryFn: () => SchemaCommPreference.ListWeaveEmailProgramInfo({}, opts),
  });

export const useGetPreferenceByToken = (token: string, opts?: Options) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.preferenceByToken(token),
    queryFn: () => SchemaCommPreference.GetPreferenceByToken({ token }, opts),
    enabled: !!token,
  });
