import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { Bool_Enum } from '@weave/schema-gen-ts/dist/shared/null/types.pb';
import { LocationsApi } from '@frontend/api-locations';
import { getDecodedWeaveToken, isWeaveUser } from '@frontend/auth-helpers';
import { Chat } from '@frontend/chat';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Location } from '@frontend/location-helpers';
import { useRefundStore, usePayoutStore } from '@frontend/payments';
import { useInvoiceStore } from '@frontend/payments-invoice-controller';
import { usePopupBarManager } from '@frontend/popup-bar';
import { useQuery } from '@frontend/react-query-helpers';
import { PickerLocation, attachLocationTypes, useAppScopeStore } from '@frontend/scope';
import { useContactPanelStore, useFeatureFlagStore, useMultiLocationFeature } from '@frontend/shared';
import { useSlidePanelShallowStore, useSlidePanelStore } from '@frontend/slide-panel';
import { TourGuideHooks } from '@frontend/tour-guide';
import { theme } from '@frontend/theme';
import {
  Heading,
  HomeIcon,
  Listbox,
  SearchField,
  SpinningLoader,
  styles,
  useDebouncedValue,
  useFormField,
  useListboxState,
  Text,
  Chip,
  useControlledField,
  useAlert,
} from '@frontend/design-system';
import { useConfigureLocation } from '../utils/configure-location';
import { PrivilegedMultiLocationSelector, UnprivilegedMultiLocationSelector } from './location-pickers';
import {
  extractParentLocations,
  transformLocationData,
  queryOrgLocationsWithTenantIds,
} from './location-pickers/shared/utils';
import { useLocationPickerStore, LocationPickerStoreProvider } from './use-location-picker';

type DataLocationSearchResults = PickerLocation;

type LocationPickerSearchResults = {
  locationResults: PickerLocation[];
  selectedLocationId: string | null;
  isLoading?: boolean;
  onSelect: ({ locationId, orgId }: { locationId: string; orgId?: string }) => void;
};

const SinglePickerLocationResults: React.FC<React.PropsWithChildren<LocationPickerSearchResults>> = ({
  locationResults,
  selectedLocationId,
  isLoading,
  onSelect,
}) => {
  const listboxProps = useListboxState(selectedLocationId ?? '');

  /**
   * This effect is to seed values to the listbox state.
   *
   * This is only necessary for the privileged picker due to the changing list of results (based on search term), and
   * `useListboxState` uses `useState` internally.
   */
  useEffect(() => {
    listboxProps.onSelect(selectedLocationId ?? '');
  }, [locationResults]);

  const { singleLocationId } = useAppScopeStore();
  const { configureLocationData } = useConfigureLocation();
  const navigate = useNavigate();
  const match = useMatch();
  const alerts = useAlert();
  const { t } = useTranslation('base');
  const { setShow } = useSlidePanelShallowStore('setShow');
  const closeSlidePanel = () => setShow(false);
  const { setActivePopup, setPopupList } = usePopupBarManager<Chat>();

  const handleLocationChange = async (locationId: string) => {
    const orgId = locationResults.find((location) => location.locationID === locationId)?.orgId;
    /*
      When changing location, we need to reset the popups, as the chat popup uses the Chat Strategy and when
      location changes no strategy is passed hence causing an app crash.
      In order to resolve this, we need to reset the popups and set the active popup to an empty array.
      So that the chat popup can be re-initialized with the new location.
      */
    setActivePopup([]);
    setPopupList([]);
    onSelect({ locationId, orgId });
  };

  useEffect(() => {
    if (listboxProps.value && listboxProps.value !== singleLocationId) {
      useInvoiceStore.reset();
      usePayoutStore.reset();
      useRefundStore.reset();
      TourGuideHooks.useTourGuideStore.reset();
      useFeatureFlagStore.reset();

      handleLocationChange(listboxProps.value as string);

      closeSlidePanel();
      configureLocationData({
        newLocationId: listboxProps.value as string,
        onSuccess(locationInfo: Location) {
          useContactPanelStore.reset();
          useSlidePanelStore.reset();
          if (locationInfo) {
            alerts.success(
              t('Successfully switched to {{name}}', { name: locationInfo.Name, interpolation: { escapeValue: false } })
            );

            if (localStorage.getItem('sent-to-location-picker-reason') === 'forbidden') {
              localStorage.removeItem('sent-to-location-picker-reason');
              navigate({ to: window.location.pathname, replace: true });
            } else if (match.id === '/portal/locations') {
              navigate({ to: '/portal' });
            }
          }
        },
        onError() {
          alerts.error({
            autoDismissAfter: 10000,
            message: t(
              'Failed to load location information. Please make sure you have no unpaid bills or contact support at (888) 579-5668 option 2, then option 3'
            ),
          });
        },
      });
    }
  }, [listboxProps.value]);

  return isLoading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1),
      }}
    >
      <SpinningLoader />
    </div>
  ) : (
    <Listbox {...listboxProps} css={{ overflow: 'auto' }}>
      {locationResults.map((locationResult) => (
        <Listbox.Option
          value={locationResult.locationID}
          key={locationResult.locationID}
          disabled={locationResult.hidden}
          css={{
            paddingLeft: locationResult.indent ? theme.spacing(2) : 0,
          }}
        >
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: '30px auto',
              gap: theme.spacing(1),
              alignItems: 'center',
              color:
                listboxProps.value === locationResult.locationID
                  ? theme.font.colors.default
                  : theme.font.colors.subdued,
              fontWeight:
                listboxProps.value === locationResult.locationID
                  ? theme.font.weight.semibold
                  : theme.font.weight.regular,
            }}
          >
            <div css={{ width: 16, justifySelf: 'center' }}>
              {!!locationResult.children?.length && <HomeIcon size={16} />}
            </div>
            {locationResult.name}
          </div>
        </Listbox.Option>
      ))}
    </Listbox>
  );
};

const UnprivilegedLocationPicker = ({
  selectedLocationId,
  closeModal,
}: {
  selectedLocationId: string | null;
  closeModal?: () => void;
}) => {
  const params = new URLSearchParams();
  const decodedWeaveToken = getDecodedWeaveToken();
  const [searchTerm, setSearchTerm] = useState('');
  const { isLocationsView } = useLocationPickerStore(['isLocationsView']);
  const searchFieldProps = useControlledField({ type: 'text', value: searchTerm, onChange: setSearchTerm });
  const { accessibleLocationData, setSelectedLocationIds, setSelectedOrgId, orgIdMap, setSelectedParentsIds } =
    useAppScopeStore();

  const clearSearch = () => {
    setSearchTerm('');
  };

  const searchValue = useDebouncedValue(searchTerm);
  const { isMultiLocationFeature } = useMultiLocationFeature();

  for (const id in decodedWeaveToken?.ACLS) {
    params.append('location_id', id);
  }

  const filteredLocations = Object.values(accessibleLocationData)?.filter((location) => {
    return location.name?.toLowerCase().includes(searchValue.toLowerCase());
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        overflow: 'hidden',
        flex: 1,
      }}
    >
      <SearchField
        {...searchFieldProps}
        placeholder={
          isMultiLocationFeature ? (isLocationsView ? 'Search Locations' : 'Search Organizations') : 'Search'
        }
        css={{ margin: 1 }}
        name='location-search'
      />
      {isMultiLocationFeature ? (
        <UnprivilegedMultiLocationSelector
          searchTerm={searchTerm === '' ? searchTerm : searchValue.toLowerCase()}
          closeModal={closeModal}
          clearSearch={clearSearch}
        />
      ) : (
        <SinglePickerLocationResults
          isLoading={!Object.keys(accessibleLocationData).length}
          locationResults={filteredLocations}
          selectedLocationId={selectedLocationId}
          onSelect={({ orgId, locationId }) => {
            if (orgId) {
              const orgInfo = orgIdMap?.[orgId];
              const isParentLocation = orgInfo?.parents?.find((location) => location.locationID === locationId);
              if (isParentLocation) {
                // Always put the actual selected location first
                setSelectedLocationIds([
                  locationId,
                  ...(orgInfo?.locations?.map((location) => location.locationID) ?? []),
                ]);
              } else {
                setSelectedLocationIds([locationId]);
              }
              setSelectedOrgId(orgId);
              setSelectedParentsIds(orgInfo?.parents?.map((parent) => parent.locationID) ?? []);
            }
          }}
        />
      )}
    </div>
  );
};

const PrivilegedLocationPicker = ({
  selectedLocationId,
  closeModal,
}: {
  selectedLocationId: string | null;
  closeModal?: () => void;
}) => {
  const searchFieldProps = useFormField({ type: 'text' });
  const { isMultiLocationFeature } = useMultiLocationFeature();
  const searchValue = useDebouncedValue(searchFieldProps.value);
  const {
    setSelectedLocationIds,
    setSelectedOrgId,
    setSelectedParentsIds,
    setAccessibleLocationData,
    setAccessibleLocationIds,
  } = useAppScopeStore();
  const { isLocationsView } = useLocationPickerStore(['isLocationsView']);

  const { data = [], isLoading } = useQuery(
    ['locations', searchValue],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const res = await LocationsApi.getPrivilegedLocations(query);

      const dataTransform = res?.locations?.map((location) => ({
        ...location,
        locationID: location.locationId,
        parentID: location.parentId,
        active: location.active ? Bool_Enum.TRUE : Bool_Enum.FALSE,
      })) as DataLocationSearchResults[];
      return dataTransform;
    },
    { enabled: !!searchValue }
  );

  const showSearchField = isMultiLocationFeature ? !isLocationsView : true;
  const showSearchOrganizationLabel = isMultiLocationFeature ? !isLocationsView : false;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        gap: theme.spacing(1),
        marginLeft: sideExtendSpacing,
        marginRight: sideExtendSpacing,
        marginBottom: sideExtendSpacing,
        flex: 1,
      }}
    >
      {showSearchField && (
        <div
          css={css`
            padding: ${theme.spacing(0, 3)};
          `}
        >
          <SearchField
            {...searchFieldProps}
            placeholder={showSearchOrganizationLabel ? 'Search Organizations' : undefined}
            css={{ margin: 1 }}
            name='location-search'
            autoComplete='off'
          />
        </div>
      )}
      {isMultiLocationFeature ? (
        <PrivilegedMultiLocationSelector
          isLoading={isLoading}
          locationResults={data}
          closeModal={closeModal}
          isPrivileged
          hasSearchTerm={!!searchValue}
        />
      ) : (
        <SinglePickerLocationResults
          isLoading={isLoading}
          locationResults={data}
          selectedLocationId={selectedLocationId}
          onSelect={async ({ locationId, orgId }) => {
            if (orgId) {
              const orgLocations = await queryOrgLocationsWithTenantIds(orgId);
              const selectOrgLocations = extractParentLocations(orgLocations);
              const accessibleLocations = attachLocationTypes(transformLocationData(orgLocations));

              const isParentLocation = selectOrgLocations.parents.find(
                (location) => location.locationID === locationId
              );

              if (isParentLocation) {
                setSelectedLocationIds([
                  locationId,
                  ...selectOrgLocations.locations.map((location) => location.locationID),
                ]);
                setAccessibleLocationIds([
                  locationId,
                  ...selectOrgLocations.locations.map((location) => location.locationID),
                ]);
              } else {
                setSelectedLocationIds([locationId]);
                setAccessibleLocationIds([locationId]);
              }

              setSelectedOrgId(orgId);
              setAccessibleLocationData(accessibleLocations);
              setSelectedParentsIds(selectOrgLocations?.parents?.map((location) => location.locationID) ?? []);
            }
          }}
        />
      )}
    </div>
  );
};

export const LocationPicker = ({ closeModal }: { closeModal?: () => void }) => {
  const { singleLocationId } = useAppScopeStore();

  return (
    <LocationPickerStoreProvider>
      {isWeaveUser() ? (
        <PrivilegedLocationPicker selectedLocationId={singleLocationId} closeModal={closeModal} />
      ) : (
        <UnprivilegedLocationPicker selectedLocationId={singleLocationId} closeModal={closeModal} />
      )}
    </LocationPickerStoreProvider>
  );
};

export const DecoratedLocationPicker = ({ closeModal }: { closeModal?: () => void }) => {
  const { t } = useTranslation('base');
  const { isMultiLocationFeature } = useMultiLocationFeature();
  const { selectedLocationIds, getLocationName } = useAppScopeStore();

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        maxHeight: '100%',
        height: '100%',
      }}
    >
      {!isMultiLocationFeature && selectedLocationIds[0] && (
        <>
          <Heading
            level={2}
            css={{
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
            }}
          >
            <Icon name='location' />
            <span css={styles.truncate} title={getLocationName(selectedLocationIds[0])}>
              {getLocationName(selectedLocationIds[0])}
            </span>
          </Heading>
          <hr css={{ height: 1, border: 'none', backgroundColor: theme.colors.neutral20 }} />
        </>
      )}
      {isMultiLocationFeature ? (
        <>
          <Text
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <span
              css={css`
                display: flex;
                gap: ${theme.spacing(1)};
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              <Trans t={t}>
                <Chip variant='seaweed'>Beta</Chip> <Text weight='bold'>Multi-location filtering</Text>
              </Trans>
            </span>
            <Trans t={t}>
              <Text size='medium' color='light'>
                On this page you can select more than one location to filter. Your filters may change on pages that
                don’t support this feature.
              </Text>
            </Trans>
          </Text>
        </>
      ) : (
        <Heading level={3} css={{ color: theme.colors.text.subdued }}>
          {t('Select Location')}
        </Heading>
      )}
      <LocationPicker closeModal={closeModal} />
    </div>
  );
};

const sideExtendSpacing = theme.spacing(-3);
