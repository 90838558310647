import { StatusType } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { InfiniteData } from 'react-query';
import { CallIntelligenceTypes } from '../../call-intelligence';
import { SchedulingOutcomeEnum, Task, TaskStatusEnum, TaskTypeEnum } from '../../call-intelligence/types';
import { generateNames, getRandomDateTime } from '../practice/mock-generators';

type CallRecordings = {
  appointmentType?: CallIntelligenceTypes.AppointmentTypeEnum;
  category?: CallIntelligenceTypes.CategoryEnum;
  count: number;
  sentiment?: CallIntelligenceTypes.SentimentEnum;
  showOnlyUnscheduled?: boolean;
};

//Constants

const appointmentTypes: CallIntelligenceTypes.AppointmentTypeEnum[] = [
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC,
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY,
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE,
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING,
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS,
];

const categories: CallIntelligenceTypes.CategoryEnum[] = [
  CallIntelligenceTypes.CategoryEnum.CATEGORY_BILLING,
  CallIntelligenceTypes.CategoryEnum.CATEGORY_EMERGENCY,
  CallIntelligenceTypes.CategoryEnum.CATEGORY_COMPLAINT,
  CallIntelligenceTypes.CategoryEnum.CATEGORY_SCHEDULING,
];

const taskDetailsMapping: Partial<Record<TaskTypeEnum, { title: string; description: string; citation: string }>> = {
  [TaskTypeEnum.TYPE_SCHEDULING]: {
    title: 'Send a confirmation text message.',
    description:
      'Once the texting application is working again, send a confirmation text message to Bob with the appointment date and time and a link to the new patient registration forms.',
    citation:
      'And as soon as the texting application comes up, I will send you a confirmation text of the appointment date, date, and time, and the link on where to go to fill out your registration forms, okay?',
  },
  [TaskTypeEnum.TYPE_INSURANCE]: {
    title: 'Call to review benefits and estimated cost.',
    description:
      'Call Bob on 07/09/2023 at 09:30 AM to review his benefits and estimated cost for his appointment on 07/12/2023 at 11:30 AM. His wife Sally Smith is the primary subscriber on the Delta Dental of Massachusetts insurance plan.',
    citation: "So we'll do 930. They'll give you a call once we receive your registration forms, okay?",
  },
};

export const officeUsers: CallIntelligenceTypes.OfficeUser[] = generateNames(20).map((name, id) => ({
  firstName: name.FirstName,
  lastName: name.LastName,
  status: StatusType.ACTIVE,
  userId: `demo-${id}`,
}));

export const phoneNumbers: string[] = [
  '1234567890',
  '2345678901',
  '3456789012',
  '4567890123',
  '5678901234',
  '6789012345',
  '7890123456',
  '8901234567',
  '9012345678',
  '0123456789',
];

const taskTypes: CallIntelligenceTypes.TaskTypeEnum[] = [
  CallIntelligenceTypes.TaskTypeEnum.TYPE_SCHEDULING,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_INSURANCE,
];

// Utility Functions

const getRandomElement = <T>(array: T[]): T => array[Math.floor(Math.random() * array.length)];

const getRandomSentiment = (): CallIntelligenceTypes.SentimentEnum =>
  getRandomElement([
    CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE,
    CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL,
    CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE,
    CallIntelligenceTypes.SentimentEnum.SENTIMENT_UNKNOWN,
  ]);

const getRandomContactType = (): CallIntelligenceTypes.ContactTypeEnum =>
  getRandomElement([
    CallIntelligenceTypes.ContactTypeEnum.CONTACT_EXISTING_PATIENT,
    CallIntelligenceTypes.ContactTypeEnum.CONTACT_NEW_PATIENT,
  ]);

const getRandomDirection = (): CallIntelligenceTypes.CallDirectionEnum =>
  getRandomElement([
    CallIntelligenceTypes.CallDirectionEnum.DIRECTION_INBOUND,
    CallIntelligenceTypes.CallDirectionEnum.DIRECTION_OUTBOUND,
  ]);

const getRandomTaskTypes = (count: number): CallIntelligenceTypes.TaskTypeEnum[] =>
  [...taskTypes].sort(() => 0.5 - Math.random()).slice(0, count);

const getRandomTaskStatus = (): TaskStatusEnum =>
  getRandomElement([TaskStatusEnum.STATUS_TODO, TaskStatusEnum.STATUS_IN_PROGRESS, TaskStatusEnum.STATUS_COMPLETED]);

const getRandomTasks = (taskTypes: TaskTypeEnum[], callId: string): Task[] => {
  const defaultTaskDetails = {
    title: '',
    description: '',
    citation: '',
  };

  return taskTypes.map((taskType, idx) => {
    const taskDetails = taskDetailsMapping[taskType] || defaultTaskDetails;
    return {
      assignee: '',
      citation: '',
      description: taskDetails.description,
      id: `demo-${idx}`,
      locationId: '',
      metadata: JSON.stringify({ citation: taskDetails.citation }),
      sourceId: callId,
      status: getRandomTaskStatus(),
      title: taskDetails.title,
      type: taskType,
    };
  });
};

// Demo Data Function

export const callRecordings = ({
  appointmentType,
  category,
  count,
  sentiment,
  showOnlyUnscheduled,
}: CallRecordings): InfiniteData<CallIntelligenceTypes.CallsResponse> => {
  return {
    pages: [
      {
        calls: generateNames(count).map((name, id) => {
          const schedulingOpportunity = Math.random() > 0.5;
          const taskCount = Math.floor(Math.random() * taskTypes.length) + 1;

          return {
            appointmentTypes: [appointmentType ?? getRandomElement(appointmentTypes)],
            categories: [category ?? getRandomElement(categories)],
            contactType: getRandomContactType(),
            direction: getRandomDirection(),
            id: `demo-${id}`,
            locationId: '001',
            mosScore: Math.floor(Math.random() * 6),
            officeNumber: {
              countryCode: 1,
              nationalNumber: getRandomElement(phoneNumbers),
            },
            officeUser: getRandomElement(officeUsers),
            person: {
              firstName: name.FirstName,
              id: `demo-${id}`,
              lastName: name.LastName,
            },
            phoneNumber: {
              countryCode: 1,
              nationalNumber: '234567890',
            },
            schedulingOpportunity: true,
            schedulingOutcome: showOnlyUnscheduled ? false : schedulingOpportunity ? Math.random() > 0.5 : false,
            sentiment: sentiment ?? getRandomSentiment(),
            startTime: getRandomDateTime(-30, -1),
            status: CallIntelligenceTypes.CallStatusEnum.CALL_STATUS_COMPLETE,
            taskCount,
            taskTypes: getRandomTaskTypes(taskCount),
          };
        }),
        pageConfig: {
          pageNumber: 1,
          pageSize: 10,
        },
      },
    ],
    pageParams: [],
  };
};

export const callMetadataResponse = (): CallIntelligenceTypes.CallMetadataResponse => {
  return {
    summary:
      "A patient named [PERSON_NAME] called to schedule an appointment for a tooth extraction (tooth #28) recommended by Dr. Mary Johnson. Since the office's text messaging service was down, the receptionist, Joan, gathered the necessary information over the phone. [PERSON_NAME] mentioned that an implant may be needed in the future. As the patient preferred an earlier appointment, Joan scheduled an extraction and bone graft procedure with Dr. Karl for July 12th at 11:30 AM. A $100 deposit was required to reserve the appointment, which [PERSON_NAME] agreed to pay using his Visa card. Joan explained that new radiographs would be needed as the existing ones were over a year old. [PERSON_NAME] provided his personal information, insurance details (Delta Dental of Massachusetts), and credit card details for the deposit. Joan confirmed the appointment details and advised that a confirmation text and a link to fill out registration forms would be sent once the texting application was functional. The insurance department would contact [PERSON_NAME] on September 9th at 9:30 AM to discuss benefits and estimated costs. [PERSON_NAME] confirmed the appointment time and thanked Joan for her assistance.",
    transcript: `
    [office] <1.718 --> 82.192> Thank you for calling Generic Organization. Your call is important to us. Please choose from one of the following options. If you are a patient looking to schedule an appointment, please press one. For questions regarding your account, please press two. If you are calling from a dental practice, please press three. For all other questions, please press four. Press seven to repeat this message. Please hold for a moment while we connect you to a team member who can assist you. Thank you for calling Generic Organization. This is Joan. Can I help you? Okay. Which tooth needs to be removed?
    [patient] <66.932 --> 100.528> Hi, Joan. Good morning. This is [PERSON_NAME] here. I just got a reference from Dr. Mary Johnson, and this is regarding a tooth extraction. Oh, no, that's difficult. I really don't know. It says some 28 something, so I don't know what exactly that means. But I've shared the referral document. and my insurance provider details on the text message, the number that was provided. Yeah.
    [office] <98.809 --> 110.837> Okay, yeah, unfortunately our text messaging is down at this moment, so I can't access it. So I will unfortunately have to ask you a bunch of questions
    [patient] <112.035 --> 112.275> Sure.
    [office] <112.699 --> 115.801> because I can't access that. Hold on for just a second.
    [patient] <116.017 --> 121.981> So in that case, just give me a minute. Let me get my prescription and the insurance details.
    [office] <131.274 --> 132.055> I don't know. I don't know.
    [patient] <162.745 --> 163.386> Hello?
    [office] <163.686 --> 163.906> Yes.
    [patient] <165.466 --> 165.827> Yeah.
    [office] <167.427 --> 169.868> Okay, so do you have the tooth number?
    [caller] <173.569 --> 181.592> Just a minute. Yeah, please tell me. What exactly do you want to know?
    [office] <182.472 --> 184.192> Which tooth needs to be removed?
    [patient] <185.713 --> 186.594> it says 28,
    [office] <187.933 --> 194.086> Okay, number 28. And are they going to, Do an implant in that area sometime in the future?
    [patient] <195.159 --> 204.324> yeah I think so what Mary Johnson ma'am has written she has suggested yeah extraction and implant.
    [office] <205.17 --> 212.033> Okay. And you wanted to schedule a consultation or do you want to do the extraction and the bone graft in the same visit?
    [patient] <210.708 --> 220.307> I want to I actually want to do the extraction because it has started paining now. So, I really want to get this done.
    [office] <221.348 --> 222.959> Okay, let me see what I have available.
    [patient] <246.116 --> 246.297> Yeah.
    [office] <268.666 --> 274.507> So I have Friday, July 12th available as Dr. Karl at 1130 with an 1115 check-in.
    [patient] <277.99 --> 281.774> I am fine. That seems fine. Do you have anything earlier than Friday?
    [office] <282.649 --> 283.809> That's the first available.
    [patient] <284.777 --> 291.765> That is the first available. All right. Just one more. I think so in that case, maybe a 11.30 appointment sounds good.
    [office] <292.731 --> 316.996> Okay. And when we schedule a consultation and extraction appointment in the same visit, it does require a deposit of $100. That $100 is used to reserve the appointment. When you come in for the appointment, it's applied to your estimated copayment, so it's deducted from the amount that you would owe. Or if you fail to give a 48-hour notice for a cancellation and reschedule, it would be used as a broken appointment fee.
    [patient] <319.301 --> 322.322> That's fine. We will get this $100 done. No problem.
    [office] <322.361 --> 327.812> Okay. All right. Let me go ahead and get this started. Extraction and bone graft.
    [patient] <329.544 --> 331.405> Joan, I just want to know one more thing.
    [office] <332.0 --> 332.301> Yes, sir.
    [patient] <333.115 --> 349.558> Mary Johnson had referred me to this Generic Organization somewhere around on say 9-20-23. So, do you need a fresh prescription for this or this is still valid?
    [office] <351.216 --> 355.637> You can go ahead and send that to us. Oh, you said you already texted it to us.
    [patient] <356.759 --> 359.88> Yeah, I had texted it. So, how do I send it to you now?
    [office] <360.958 --> 362.258> You can email it to us.
    [patient] <363.629 --> 364.85> Fine, I'll email it to you.
    [office] <365.679 --> 365.999> Okay.
    [patient] <365.811 --> 375.84> And yeah, I think, and I believe she has already shared my radiographs. She has sent to the office. That's what the prescription says. It
    [office] <376.52 --> 379.481> Okay, let me see if you're in my system. What is your date of birth?
    [patient] <381.084 --> 381.245> is 12-13-1986. 1213.
    [office] <390.945 --> 397.329> No, I don't have anything in here with that date of birth. 12, 16, 1986, 13. Okay, let's
    [patient] <398.255 --> 398.995> Yeah.
    [office] <399.87 --> 400.31> change
    [patient] <401.396 --> 421.842> Yeah, because anyway, I believe the radiographs must be almost a year old now.
    [office] <402.272 --> 428.132> that. Nope, that's still not coming up. Okay, let's go ahead and get started from scratch here. Well, if the radiographs are that old, we'll need to take new ones. May I get your last name, please?
    [patient] <427.27 --> 432.171> Yeah. It's David, D-A-V-I-D.
    [office] <434.594 --> 443.876> D-A-V-I-D. And your first name?
    [patient] <438.353 --> 447.075> Yeah, that's right. Bob, B-O-B.
    [office] <451.898 --> 503.186> Hi. And let's see, mail, 12-13-1986. And may I get your telephone number, please? And is there dental insurance that will be filing for you, sir?
    [patient] <504.838 --> 509.66> Yeah, I have my dental insurance details. If you need anything, tell me.
    [office] <510.27 --> 516.494> Is that through your employer? Okay. And what is her first name?
    [patient] <513.082 --> 520.126> No, it's to my wife's employer. Sally. S-A-L-L-Y.
    [office] <522.698 --> 523.418> Same last name?
    [patient] <525.088 --> 533.193> No, the last name is different. It's Smith. I'll just spell it for you. S-M-I-T-H.
    [office] <536.653 --> 538.074> And what is her date of birth?
    [patient] <540.42 --> 544.705> That is a good question. It would be 5, 8, 19, 89.
    [office] <550.344 --> 551.866> And what is the name of her insurance?
    [patient] <554.034 --> 554.795> Delta Dental.
    [office] <556.289 --> 557.05> Of California?
    [patient] <559.059 --> 561.922> I am assuming it is California because there is nothing written here.
    [office] <563.436 --> 567.679> Okay, and what is the ID number or social security number of your spouse?
    [patient] <569.581 --> 572.402> The ID number, you mean to say the subscriber number?
    [office] <572.842 --> 573.082> Yes.
    [patient] <574.723 --> 575.143> It's 111-000-1111. Yeah.
    [office] <583.488 --> 603.025> Okay, that does not look like a Delta Dental of California ID number. Are you looking at the card right now? On the back should be a mailing address. Can you tell me what state it goes to? Okay, so it's Delta Dental of Massachusetts.
    [patient] <598.217 --> 599.618> It goes to Massachusetts.
    [office] <608.67 --> 633.608> Delta of Massachusetts, where are you? Okay, I've got that saved. Okay, and tooth number 28. Okay, all
    [patient] <635.051 --> 635.711> Yeah.
    [office] <636.469 --> 651.495> right. And the last thing that we need to do is the deposit. And you can use Visa, MasterCard, American Express, or Discover. Okay, let me go ahead and get this set up.
    [patient] <647.655 --> 649.135> I will be using the Visa card.
    [office] <667.432 --> 670.454> Okay, and I am ready anytime you are, sir.
    [patient] <672.253 --> 673.575> Yeah, I'm ready.
    [office] <674.257 --> 674.918> Okay, go ahead.
    [patient] <678.983 --> 687.977> Yeah. Just a minute, I'll give it to you.
    [office] <680.963 --> 698.962> Yeah, with the card number, I can take that number to run the card. Leave it down, you know that, right? Okay.
    [patient] <702.229 --> 704.2> Just hold for a minute. Sorry.
    [office] <757.056 --> 760.977> Okay, are you ready? Okay, go ahead.
    [patient] <762.603 --> 763.444> The card number is 110011110011.
    [office] <778.021 --> 805.01> And the three, the expiration date please. and the three-digit code on the back. Okay, would you like me to send you a receipt? Okay.
    [patient] <803.846 --> 803.966> Sure.
    [office] <824.551 --> 840.027> Okay, I just sent you the receipt, and you are all set. And as soon as the texting application comes up, I will send you a confirmation text of the appointment date, date, and time, and the link on where to go to fill out your registration forms, okay?
    [patient] <841.038 --> 841.178> Okay.
    [office] <841.329 --> 861.086> I also need to schedule a They'll give you a call once the benefits have been checked after you submit your registration forms, and they'll go over your benefits and your estimated cost at that time. So they can call you on the 8th,
    [patient] <855.908 --> 862.852> Mm hmm.
    [office] <863.848 --> 868.212> and I have an 8 a.m. available for them to give you a call.
    [patient] <870.948 --> 871.268> Okay,
    [office] <872.395 --> 885.831> Okay. Oh, no, I don't. I'm sorry. We start at 830, so yes, they don't have that. So they could call you on front. I'm sorry. They'll have to call you on the 9th.
    [patient] <884.194 --> 888.396> that is tomorrow, right? 9.30 will do.
    [office] <888.372 --> 912.968> Okay. Yeah. So I have an 830, a 9, a 930, a 10, a 1030, 11, or 1130. Okay. So we'll do 930. They'll give you a call once we receive your registration forms, okay? All right, you're all set. Is there anything else I can do for you?
    [patient] <914.536 --> 915.517> Now that would be it.
    [office] <916.191 --> 919.674> Okay. All right, you're all set. Okay, thank you. All
    [patient] <919.422 --> 919.922> Thank you again.
    [office] <920.434 --> 920.935> right, bye-bye.
    [patient] <920.783 --> 921.825> Thank you. Thank you.
  `,
    callMetadata: {
      caller: JSON.stringify({
        date_of_birth: '12/13/1986',
        insurance: {
          name: 'Delta Dental of Massachusetts',
          phone: null,
          fax: null,
          email: null,
          insurance_ids: ['111-000-1111'],
        },
        dependents: [
          {
            name: 'Sally Smith',
            phone: null,
            email: null,
            date_of_birth: '05/08/1989',
            ssn: null,
          },
        ],
      }),
      receptionist: JSON.stringify({}),
    },
  };
};

export const callTaskListResponse = (
  taskCount: number,
  taskTypes: CallIntelligenceTypes.TaskTypeEnum[],
  callId: string
): CallIntelligenceTypes.CallTaskListResponse => {
  return {
    tasks: taskCount > 0 ? getRandomTasks(taskTypes, callId) : [],
  };
};

export const getFilteredCallRecordings = (
  callRecordings: InfiniteData<CallIntelligenceTypes.CallsResponse>,
  filters: CallIntelligenceTypes.Filters
): InfiniteData<CallIntelligenceTypes.CallsResponse> => {
  if (!callRecordings) return callRecordings;

  const filterCall = (call: CallIntelligenceTypes.Call): boolean => {
    const contactType =
      filters?.contactTypes?.length && call.contactType ? filters.contactTypes.includes(call.contactType) : true;

    const officeUsers =
      filters?.officeUsers?.length && call.officeUser?.userId
        ? filters.officeUsers.includes(call.officeUser?.userId)
        : true;

    const schedulingOutcome = filters?.schedulingOutcomes?.length
      ? (filters?.schedulingOutcomes?.includes(SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED) &&
          call.schedulingOutcome === true) ||
        (filters?.schedulingOutcomes?.includes(SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED) &&
          call.schedulingOutcome === false)
      : true;

    return contactType && officeUsers && schedulingOutcome;
  };

  return {
    pages: callRecordings.pages.map((page) => ({
      calls: page.calls?.filter(filterCall),
      pageConfig: { ...page.pageConfig },
    })),
    pageParams: callRecordings.pageParams,
  };
};
