import React, { ReactNode, useState } from 'react';

import { css } from '@emotion/react';
import { CheckboxField, Heading, PrimaryButton, Text, TextLink, useControlledField } from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';

import { SubscriptionsQueries, SubscriptionsTypes } from '@frontend/api-subscription';
import { SubscribeTermsModal } from './SubscribeTermsModal';
import { MAX_CARD_WIDTH } from './constant';
import { SubscribeModalController } from './subscribe-modals/SubscribeModalController';

type SubscriptionType = 'monthly' | 'annually';

type PricePanelProps = { value: string; subscriptionTerm: SubscriptionType };
const PricingPanel = React.memo(({ value, subscriptionTerm }: PricePanelProps) => {
  const { t } = useTranslation('subscribe', { keyPrefix: 'pricePanel' });
  return (
    <div css={pricingPanelStyle}>
      <Heading level={3}>{value}</Heading>{' '}
      <Text size='large'>{subscriptionTerm === 'monthly' ? t('/mo') : t('year')}</Text>
    </div>
  );
});

type IProps = React.HTMLAttributes<HTMLElement> & {
  productServiceName: string;
  pricingValue: string;
  subscriptionTerm: SubscriptionType;
  title: string;
  renderSubscriptionCardContent: ReactNode;
  additionalAddonsRenderContent?: ReactNode;
  subscriptionButtonTrackingId?: string;
  cancelSubscriptionButtonTrackingId?: string;
  isSubscriptionButtonDisabled?: boolean;
  feature: SubscriptionsTypes.Feature;
  isLoading: boolean;
  canSelfSubscribe?: boolean;
  canCancelSubscription?: boolean;
  onCancelSubscriptionClick?: () => void;
  onSubscribeClick?: () => void;
  onScheduleCallClick?: () => void;
  onSubscribeSuccessCloseClick?: () => void;
  additionalAddons?: SubscriptionsQueries.SchemaSubscribeRequest['additionalLines'];
  additionalTerms?: string[];
  onboarderSchedulingUrl?: string;
  shouldShowSubscribeModal?: boolean;
};
export const SignUpPageSubscriptionCard = React.memo(
  ({
    productServiceName,
    pricingValue,
    subscriptionTerm,
    title,
    renderSubscriptionCardContent,
    canSelfSubscribe = true,
    canCancelSubscription = false,
    onCancelSubscriptionClick,
    onScheduleCallClick,
    subscriptionButtonTrackingId = '',
    cancelSubscriptionButtonTrackingId = '',
    isSubscriptionButtonDisabled = false,
    feature,
    isLoading,
    additionalAddons,
    additionalAddonsRenderContent,
    additionalTerms,
    onboarderSchedulingUrl,
    onSubscribeSuccessCloseClick,
    shouldShowSubscribeModal = true,
    onSubscribeClick,
    ...rest
  }: IProps) => {
    const { t } = useTranslation('subscribe');
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [showSubscribeModal, setShowSubscribeModal] = useState<boolean>(false);

    const [termsAccepted, setTermsAccepted] = useState<boolean | undefined>(undefined);
    const [consentReceived, setConsentReceived] = useState<boolean | undefined>(undefined);

    const acceptProps = useControlledField({
      type: 'checkbox',
      value: !!termsAccepted,
      onChange: (value: boolean) => {
        setTermsAccepted(value);
      },
    });
    const consentProps = useControlledField({
      type: 'checkbox',
      value: !!consentReceived,
      onChange: (value: boolean) => {
        setConsentReceived(value);
      },
    });

    const handleTermsAndConditionsClick = (
      event: React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      event.stopPropagation();
      setShowTermsModal(true);
    };

    const handleScheduleOnboardingCall = () => {
      if (onboarderSchedulingUrl) {
        window.open(onboarderSchedulingUrl, '_blank');
      } else if (onScheduleCallClick) {
        onScheduleCallClick();
      }
    };

    const subscribeBtnHandler = () => {
      if (shouldShowSubscribeModal) {
        setShowSubscribeModal(true);
      } else {
        onSubscribeClick?.();
      }
    };

    return (
      <>
        <div css={subscriptionCardContainerStyles} {...rest}>
          <Heading level={2}>{title}</Heading>
          <div css={{ marginTop: theme.spacing(2) }}>{renderSubscriptionCardContent}</div>

          {additionalAddonsRenderContent && (
            <section css={additionalAddonsPanel}>{additionalAddonsRenderContent}</section>
          )}

          <PricingPanel value={pricingValue} subscriptionTerm={subscriptionTerm} />

          {canSelfSubscribe ? (
            <>
              <CheckboxField
                disabled={isSubscriptionButtonDisabled}
                css={checkboxStyle}
                name='accept-tnc-modal'
                {...acceptProps}
                label={
                  <span>
                    <Trans t={t}>
                      <Text css={{ fontSize: theme.fontSize(14) }}>
                        By checking this box and clicking "Subscribe", you acknowledge and agree to the{' '}
                        <TextLink onClick={(e) => handleTermsAndConditionsClick(e)}>terms and conditions</TextLink>.
                      </Text>
                    </Trans>
                  </span>
                }
              />

              <CheckboxField
                disabled={isSubscriptionButtonDisabled}
                css={checkboxStyle}
                name='accept-consent'
                {...consentProps}
                label={
                  <span>
                    <Text css={{ fontSize: theme.fontSize(14) }}>
                      {t(
                        'By selecting this checkbox, you acknowledge and consent to the proration of charges for this add-on feature, ensuring it aligns with your upcoming billing date. Additionally, please note that its billing frequency will be synchronized with the cadence of your main bundle service charges.'
                      )}
                    </Text>
                  </span>
                }
              />

              <PrimaryButton
                disabled={isSubscriptionButtonDisabled || !termsAccepted || !consentReceived}
                trackingId={subscriptionButtonTrackingId}
                onClick={subscribeBtnHandler}
              >
                {t('Subscribe')}
              </PrimaryButton>
            </>
          ) : (
            <>
              {canCancelSubscription && !canSelfSubscribe ? (
                <PrimaryButton
                  css={{ marginTop: theme.spacing(2) }}
                  disabled={isSubscriptionButtonDisabled}
                  onClick={onCancelSubscriptionClick}
                  destructive={true}
                  trackingId={cancelSubscriptionButtonTrackingId}
                >
                  {t('Cancel Subscription')}
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  disabled={isSubscriptionButtonDisabled}
                  trackingId={subscriptionButtonTrackingId}
                  onClick={onScheduleCallClick}
                >
                  {t('Schedule Call')}
                </PrimaryButton>
              )}
            </>
          )}
        </div>

        {showSubscribeModal && shouldShowSubscribeModal && (
          <SubscribeModalController
            feature={feature}
            isLoading={isLoading}
            onModalCloseClick={() => setShowSubscribeModal(false)}
            onSuccessCloseClick={() => onSubscribeSuccessCloseClick?.()}
            productServiceName={productServiceName}
            additionalAddons={additionalAddons}
            onScheduleOnboardingCall={handleScheduleOnboardingCall}
          />
        )}

        {showTermsModal && (
          <SubscribeTermsModal
            productServiceName={productServiceName}
            // trackingIdPrefix={featureTrackingIdPrefix}
            onCloseModal={() => setShowTermsModal(false)}
            additionalTerms={additionalTerms}
          />
        )}
      </>
    );
  }
);

const checkboxStyle = css`
  margin: ${theme.spacing(2, 0)};
  align-items: center;
`;

const subscriptionCardContainerStyles = css({
  padding: theme.spacing(2),
  borderRadius: theme.borderRadius.medium,
  border: `1px solid ${theme.colors.neutral20}`,
  maxWidth: MAX_CARD_WIDTH,
});

const pricingPanelStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  borderTop: `1px solid ${theme.colors.neutral20}`,
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),
});

const additionalAddonsPanel = css({
  borderTop: `1px solid ${theme.colors.neutral20}`,
  margin: theme.spacing(2, 0),
  paddingTop: theme.spacing(2),
});
