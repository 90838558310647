import { FC, useMemo } from 'react';
import { SMSAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { getHourlyInsightsLabel, hourlyInsightsLimiter } from '../../utils';
import { DemoChip } from '../demo-chip';
import { InfoTipPopover } from '../info-tip-popover';
import { useMessagingAnalyticsStore } from './hooks';

interface Props {
  generalChartsData: SMSAnalyticsTypes.ChartsData;
  isHourlyInsights?: boolean;
  isLoading?: boolean;
}

const colors = {
  apptReminder: theme.colors.success20,
  birthday: theme.colors.secondary.eggplant30,
  blank: theme.colors.secondary.seaweed30,
  incoming: theme.colors.secondary.seaweed30,
  manual: theme.colors.warning50,
  marketing: theme.colors.secondary.seaweed50,
  messagingPreference: theme.colors.primary20,
  missedCall: theme.colors.critical30,
  mms: theme.colors.critical30,
  outgoing: theme.colors.warning50,
  patient: theme.colors.primary20,
  paymentReminder: theme.colors.warning80,
  productReady: theme.colors.secondary.seaweed40,
  recallText: theme.colors.warning60,
  reviewRequests: theme.colors.neutral30,
  saveTheDate: theme.colors.primary60,
  sms: theme.colors.success30,
  unknown: theme.colors.secondary.eggplant30,
};

const labels = {
  apptReminder: i18next.t('Appointment Reminder', { ns: 'analytics' }),
  birthday: i18next.t('Birthday', { ns: 'analytics' }),
  blank: i18next.t('Incoming', { ns: 'analytics' }),
  incoming: i18next.t('Incoming', { ns: 'analytics' }),
  manual: i18next.t('Manual - Outgoing', { ns: 'analytics' }),
  marketing: i18next.t('Marketing', { ns: 'analytics' }),
  messagingPreference: i18next.t('Messaging Preference', { ns: 'analytics' }),
  missedCall: i18next.t('Missed Call', { ns: 'analytics' }),
  mms: i18next.t('MMS', { ns: 'analytics' }),
  outgoing: i18next.t('Outgoing', { ns: 'analytics' }),
  patient: i18next.t('Patient', { ns: 'analytics' }),
  paymentReminder: i18next.t('Payment Reminder', { ns: 'analytics' }),
  productReady: i18next.t('Product Ready', { ns: 'analytics' }),
  recallText: i18next.t('Recall Text', { ns: 'analytics' }),
  reviewRequests: i18next.t('Review Requests', { ns: 'analytics' }),
  saveTheDate: i18next.t('Save The Date', { ns: 'analytics' }),
  sms: i18next.t('SMS', { ns: 'analytics' }),
  unknown: i18next.t('Unknown', { ns: 'analytics' }),
};

const defaultBarChartAppearance = {
  collectiveTooltip: true,
  showXAxis: true,
  showYAxis: true,
  showGridLines: true,
};

const categorizedChartStackKeys = [
  'birthday',
  'blank',
  'marketing',
  'messagingPreference',
  'missedCall',
  'paymentReminder',
  'productReady',
  'recallText',
  'reviewRequests',
  'saveTheDate',
];

export const SMSChartsView: FC<React.PropsWithChildren<Props>> = ({
  generalChartsData,
  isHourlyInsights,
  isLoading,
}) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, isDemoAccount } = useMessagingAnalyticsStore();

  const messagingVolumeIncomingVsOutgoing: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { incoming, outgoing } = Object.values(generalChartsData.totalMessages)[0] || {};
      const limitedIncoming = hourlyInsightsLimiter(incoming as number[]);
      const limitedOutgoing = hourlyInsightsLimiter(outgoing as number[]);
      return {
        groups: limitedIncoming.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            incoming: value,
            outgoing: limitedOutgoing[i],
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(generalChartsData.totalMessages).map(([key, { incoming, outgoing }]) => ({
          name: key,
          values: {
            incoming: incoming as number,
            outgoing: outgoing as number,
          },
        })),
      };
    }
  }, [generalChartsData.totalMessages]);

  const messagingVolumeCatorized: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const {
        apptReminder,
        birthday,
        blank,
        manual,
        marketing,
        messagingPreference,
        missedCall,
        paymentReminder,
        productReady,
        recallText,
        reviewRequests,
        saveTheDate,
      } = Object.values(generalChartsData.totalMessageCategory)[0] || {};
      const limitedManual = hourlyInsightsLimiter(manual as number[]);
      const limitedMissedCall = hourlyInsightsLimiter(missedCall as number[]);
      const limitedApptReminder = hourlyInsightsLimiter(apptReminder as number[]);
      const limitedRecallText = hourlyInsightsLimiter(recallText as number[]);
      const limitedBirthday = hourlyInsightsLimiter(birthday as number[]);
      const limitedMarketing = hourlyInsightsLimiter(marketing as number[]);
      const limitedPaymentReminder = hourlyInsightsLimiter(paymentReminder as number[]);
      const limitedProductReady = hourlyInsightsLimiter(productReady as number[]);
      const limitedReviewRequests = hourlyInsightsLimiter(reviewRequests as number[]);
      const limitedSaveTheDate = hourlyInsightsLimiter(saveTheDate as number[]);
      const limitedMessagingPreference = hourlyInsightsLimiter(messagingPreference as number[]);
      const limitedBlank = hourlyInsightsLimiter(blank as number[]);
      return {
        groups: limitedManual.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            apptReminder: limitedApptReminder[i],
            manual: value,

            // Following are stacked together
            birthday: limitedBirthday[i],
            blank: limitedBlank[i],
            marketing: limitedMarketing[i],
            messagingPreference: limitedMessagingPreference[i],
            missedCall: limitedMissedCall[i],
            paymentReminder: limitedPaymentReminder[i],
            productReady: limitedProductReady[i],
            recallText: limitedRecallText[i],
            reviewRequests: limitedReviewRequests[i],
            saveTheDate: limitedSaveTheDate[i],
          },
        })),
        stackKeys: categorizedChartStackKeys,
      };
    } else {
      return {
        groups: Object.entries(generalChartsData.totalMessageCategory).map(
          ([
            key,
            {
              apptReminder,
              birthday,
              blank,
              manual,
              marketing,
              messagingPreference,
              missedCall,
              paymentReminder,
              productReady,
              recallText,
              reviewRequests,
              saveTheDate,
            },
          ]) => ({
            name: key,
            values: {
              apptReminder: apptReminder as number,
              manual: manual as number,

              // Following are stacked together
              birthday: birthday as number,
              blank: blank as number,
              marketing: marketing as number,
              messagingPreference: messagingPreference as number,
              missedCall: missedCall as number,
              paymentReminder: paymentReminder as number,
              productReady: productReady as number,
              recallText: recallText as number,
              reviewRequests: reviewRequests as number,
              saveTheDate: saveTheDate as number,
            },
          })
        ),
        stackKeys: categorizedChartStackKeys,
      };
    }
  }, [generalChartsData.totalMessageCategory]);

  const messagingVolumePatientVsUnknown: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { patient, unknown } = Object.values(generalChartsData.totalUnknownVsPatientSMS)[0] || {};
      const limitedPatient = hourlyInsightsLimiter(patient as number[]);
      const limitedUnknown = hourlyInsightsLimiter(unknown as number[]);
      return {
        groups: limitedPatient.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            patient: value,
            unknown: limitedUnknown[i],
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(generalChartsData.totalUnknownVsPatientSMS).map(([key, { patient, unknown }]) => ({
          name: key,
          values: {
            patient: patient as number,
            unknown: unknown as number,
          },
        })),
      };
    }
  }, [generalChartsData.totalUnknownVsPatientSMS]);

  const messagingVolumeSMSvsMMS: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { mms, sms } = Object.values(generalChartsData.totalMMSVsSMS)[0] || {};
      const limitedMMS = hourlyInsightsLimiter(mms as number[]);
      const limitedSMS = hourlyInsightsLimiter(sms as number[]);
      return {
        groups: limitedSMS.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            sms: value,
            mms: limitedMMS[i],
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(generalChartsData.totalMMSVsSMS).map(([key, { mms, sms }]) => ({
          name: key,
          values: {
            sms: sms as number,
            mms: mms as number,
          },
        })),
      };
    }
  }, [generalChartsData.totalMMSVsSMS]);

  return (
    <>
      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          infoTip={
            <InfoTipPopover>
              {t('Total incoming and outgoing messages. It includes both manual or automated messages')}
            </InfoTipPopover>
          }
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Total Message Count: Incoming vs Outgoing')}
        />
        <Chart.Legends />
        <Chart.BarChart appearance={defaultBarChartAppearance} data={messagingVolumeIncomingVsOutgoing} />
      </Chart>

      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          infoTip={<InfoTipPopover>{t('Tells you the number of messages for each category or type')}</InfoTipPopover>}
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Message Categories')}
        />
        <Chart.Legends />
        <Chart.BarChart appearance={defaultBarChartAppearance} data={messagingVolumeCatorized} />
      </Chart>

      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          infoTip={
            <InfoTipPopover>
              {t(
                'Tells you the number of messages sent or received with patients. If phone number is present in PMS/EHR then it is marked as Patient.'
              )}
            </InfoTipPopover>
          }
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Contact Type: Patient vs Unknown')}
        />
        <Chart.Legends />
        <Chart.BarChart appearance={defaultBarChartAppearance} data={messagingVolumePatientVsUnknown} />
      </Chart>

      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          infoTip={<InfoTipPopover>{t('Number of SMS or MMS sent or received')}</InfoTipPopover>}
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Message Content: SMS vs MMS')}
        />
        <Chart.Legends />
        <Chart.BarChart appearance={defaultBarChartAppearance} data={messagingVolumeSMSvsMMS} />
      </Chart>
    </>
  );
};
