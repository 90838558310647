import { useEffect } from 'react';
import { css } from '@emotion/react';
import { ScheduleAvailabilityTypes, ScheduleAvailabilityUtils } from '@frontend/api-schedule-availability';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { useFormField, TimeRangeField, CheckboxField, Text, NakedButton, useTooltip } from '@frontend/design-system';
import { ScheduleBreak } from './ScheduleBreaks';

const { addBreakToDisplaySchedule, checkOverlappingBreak, withinOpenHours, formatTimeString, TranslatedWeek } =
  ScheduleAvailabilityUtils;

type DayScheduleProps = {
  day: ScheduleAvailabilityTypes.DayOfWeek;
  displaySchedule?: ScheduleAvailabilityTypes.DisplaySchedule;
  onToggleDay: (breaks: boolean) => void;
  onUpdate: (schedule: ScheduleAvailabilityTypes.DisplaySchedule) => void;
};

export function DaySchedule({ day, displaySchedule, onToggleDay, onUpdate }: DayScheduleProps) {
  const { t } = useTranslation('schedule');
  const isMobile = useMatchMedia({ maxWidth: breakpoints.xsmall.max });
  const checkboxProps = useFormField({ type: 'checkbox', value: !!displaySchedule }, [displaySchedule]);

  const timeRangeField = useFormField(
    {
      type: 'timeRange',
      value: [displaySchedule?.startTime || '', displaySchedule?.endTime || ''],
      interval: 15,
    },
    [displaySchedule?.startTime, displaySchedule?.endTime]
  );

  useEffect(() => {
    const [startTime, endTime] = timeRangeField.value;

    const newDisplaySchedule = { ...displaySchedule };
    if (startTime) {
      newDisplaySchedule.startTime = formatTimeString(startTime);
    }
    if (endTime) {
      newDisplaySchedule.endTime = formatTimeString(endTime);
    }
    onUpdate(newDisplaySchedule as ScheduleAvailabilityTypes.DisplaySchedule);
  }, [timeRangeField.value[0], timeRangeField.value[1]]);

  const handleUpdateBreak = (index: number) => {
    return ([startTime, endTime]: string[]) => {
      const newDisplaySchedule = {
        ...(displaySchedule || {}),
      } as ScheduleAvailabilityTypes.DisplaySchedule;
      if (newDisplaySchedule.breaks) {
        newDisplaySchedule.breaks[index].startTime = formatTimeString(startTime);
        newDisplaySchedule.breaks[index].endTime = formatTimeString(endTime);
      }
      onUpdate(newDisplaySchedule);
    };
  };

  const handleDeleteBreak = (index: number) => {
    return () => {
      if (!displaySchedule) {
        return;
      }
      const breaks = [...(displaySchedule?.breaks || [])];
      breaks.splice(index, 1);
      onUpdate({ ...displaySchedule, breaks });
    };
  };

  const handleAddBlock = () => {
    if (!displaySchedule) {
      return;
    }
    onUpdate(addBreakToDisplaySchedule(displaySchedule));
  };

  const validateBreaks = (index: number) => (start: string, end: string) => {
    const isOverlappingBreak =
      checkOverlappingBreak({
        index,
        timeString: start,
        breaks: displaySchedule?.breaks || [],
      }) &&
      checkOverlappingBreak({
        index,
        timeString: end,
        breaks: displaySchedule?.breaks || [],
      });

    const isWithinOpenHours = withinOpenHours({
      end,
      start,
      hours: timeRangeField.value,
    });

    if (!isWithinOpenHours) {
      return t('Break is outside of scheduled hours');
    }
    if (!isOverlappingBreak) {
      return t('Break overlaps with another break');
    }
    return '';
  };

  const renderBreaksLabel = () => {
    if (!isMobile || !displaySchedule?.breaks?.length) {
      return null;
    }
    return <label css={{ textAlign: 'center', marginBottom: theme.spacing(2) }}>{t('Break')}</label>;
  };

  const isClosedDays = !displaySchedule?.startTime && !displaySchedule?.endTime;

  return (
    <>
      <div css={dayScheduleStyles(isMobile, isClosedDays)}>
        <CheckboxField
          label={TranslatedWeek()[day]}
          name={`${day}Schedule`}
          {...checkboxProps}
          onChange={(e: any) => {
            checkboxProps.onChange(e);
            onToggleDay(!!e.value);
          }}
        />
        {displaySchedule && (
          <div css={timeFieldContainerMobileStyles}>
            <TimeRangeField {...timeRangeField} name={`${day}TimeRange`} label={t('Time Range')} />
            <AddBreakButton onClick={handleAddBlock} />
          </div>
        )}
        {!displaySchedule && (
          <div css={closedDayStyles}>
            <Icon size={16} name='moon' css={{ marginRight: theme.spacing(1), color: theme.colors.neutral20 }} />
            <Text css={{ color: theme.colors.neutral20 }} as='span'>
              {t('Closed')}
            </Text>
          </div>
        )}
      </div>
      {displaySchedule && (
        <div css={scheduleBreakEventContainer(isMobile)}>
          {renderBreaksLabel()}
          {displaySchedule?.breaks?.map(({ startTime, endTime }: ScheduleAvailabilityTypes.Break, i: number) => (
            <ScheduleBreak
              key={`${startTime}_${endTime}_${i}`}
              startTime={startTime}
              endTime={endTime}
              validateBreaks={validateBreaks(i)}
              onChange={handleUpdateBreak(i)}
              onDelete={handleDeleteBreak(i)}
              officeTimeRange={timeRangeField.value}
            />
          ))}
        </div>
      )}
    </>
  );
}

const AddBreakButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('schedule');
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();
  return (
    <>
      <NakedButton {...triggerProps} css={addBreakButtonStyles} onClick={onClick}>
        <Icon size={8} name='plus-small' />
      </NakedButton>

      <Tooltip {...tooltipProps}>
        <Text as='span' color='white'>
          {t('Add Break')}
        </Text>
      </Tooltip>
    </>
  );
};

const addBreakButtonStyles = css({
  borderRadius: theme.borderRadius.full,
  border: `thin solid ${theme.colors.neutral50}`,
  marginLeft: theme.spacing(2),
  width: 16,
  height: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const dayScheduleStyles = (isMobile: boolean, isClosedDays: boolean) =>
  !isMobile ? dayScheduleRowStyles : dayScheduleRowStylesMobile(isClosedDays);

const dayScheduleRowStyles = css({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, 50%) 1fr',
  alignItems: 'center',
  padding: theme.spacing(1.5, 4),
});

const dayScheduleRowStylesMobile = (isClosedDays: boolean) =>
  css({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,
    ...(isClosedDays && { borderBottom: `thin solid ${theme.colors.neutral20}` }),
  });

const closedDayStyles = css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.colors.neutral5,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(1),
  width: '100%',
});

const timeFieldContainerMobileStyles = css({
  display: 'flex',
  alignItems: 'center',
});

const scheduleBreakEventContainer = (isMobile: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...(isMobile && { borderBottom: `thin solid ${theme.colors.neutral20}` }),
  });
