import { ModalControlResponse, useModalControl } from '@frontend/design-system';
import { FreeTrialModal } from '../components/feature-upgrade-action-modal/free-trial-modal/FreeTrialModal';

interface UseFreeTrialModalResults {
  FreeTrialModal: typeof FreeTrialModal;
  freeTrialModalControls: ModalControlResponse;
}

export const useFreeTrialModal = (): UseFreeTrialModalResults => {
  const modalControls = useModalControl();
  return {
    FreeTrialModal,
    freeTrialModalControls: modalControls,
  };
};
