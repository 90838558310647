import { useState, Dispatch, SetStateAction, useCallback } from 'react';
import { FormsLibrary } from '@frontend/api';
import { useAppScopeStore } from '@frontend/scope';
import { usePacketsListV2, useFormsListV2 } from '../../../shared/hooks';

export interface UseLibraryResults {
  selectedItemId: string;
  setSelectedItemId: Dispatch<SetStateAction<string>>;
  packetsListV2Props: ReturnType<typeof usePacketsListV2>;
  formsListV2Props: ReturnType<typeof useFormsListV2>;
  findForm: (formId: string) => FormsLibrary.Types.FormTemplateDataV2 | undefined;
  findPacket: (packetId: string) => FormsLibrary.Types.PacketDataV2 | undefined;
  getLocationName: (locationId: string) => string;
}

const useLibrary = (): UseLibraryResults => {
  const [selectedItemId, setSelectedItemId] = useState<string>('');
  const packetsListV2Props = usePacketsListV2();
  const formsListV2Props = useFormsListV2();

  const { getLocationName } = useAppScopeStore();

  const findForm = useCallback(
    (formId: string) => {
      const pages = formsListV2Props.infiniteQueryParams.data?.pages;

      if (!pages) {
        return;
      }

      for (const page of pages) {
        const form = page.rows.find((form) => form.id === formId);

        if (form) {
          return form;
        }
      }

      return;
    },
    [formsListV2Props.infiniteQueryParams.data?.pages, formsListV2Props.infiniteQueryParams.dataUpdatedAt]
  );

  const findPacket = useCallback(
    (packetId: string) => {
      const pages = packetsListV2Props.infiniteQueryParams.data?.pages;

      if (!pages) {
        return;
      }

      for (const page of pages) {
        const form = page.rows.find((packet) => packet.id === packetId);

        if (form) {
          return form;
        }
      }

      return;
    },
    [packetsListV2Props.infiniteQueryParams.data?.pages, packetsListV2Props.infiniteQueryParams.dataUpdatedAt]
  );

  return {
    selectedItemId,
    setSelectedItemId,
    packetsListV2Props,
    formsListV2Props,
    findForm,
    findPacket,
    getLocationName,
  };
};

export default useLibrary;
