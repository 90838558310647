import { css } from '@emotion/react';
import {
  FieldProps,
  IconButton,
  InfoRoundIconSmall,
  Modal,
  SwitchField,
  Text,
  TextLink,
  useModalControl,
} from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { memo } from 'react';
import { MediaPicker } from '../media-picker';
import { formConfigBase, SectionHeader, SettingSection } from './primitives';
import { useAppScopeStore } from '@frontend/scope';

interface IGreetingSetting {
  fields: {
    toggle: FieldProps<typeof formConfigBase, 'greetingToggle'>;
    media: FieldProps<typeof formConfigBase, 'greetingMedia'>;
  };
}

export const GreetingSetting = memo(({ fields }: IGreetingSetting) => {
  const { modalProps, triggerProps } = useModalControl();
  const { t } = useTranslation('phone', { keyPrefix: 'call-queue' });

  return (
    <SettingSection>
      <SectionHeader>
        <SwitchField
          css={{ width: 'max-content' }}
          labelPlacement='left'
          {...fields.toggle}
          label={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(1),
              }}
            >
              <Text as='span' weight='bold'>
                {t('Greeting')}
              </Text>
              <IconButton label='open info modal' size='small' {...triggerProps}>
                <InfoRoundIconSmall />
              </IconButton>
            </div>
          }
        />
        <Text size='medium' color='light'>
          {t('Add an optional greeting to play before the caller enters the queue.')}
        </Text>
      </SectionHeader>
      {fields.toggle.value && <GreetingControls field={fields.media} />}
      <Modal maxWidth={560} {...modalProps}>
        <Modal.Header
          css={css`
            text-align: left;
          `}
        >
          {t('Greeting Help')}
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
          <Text>{t('greeting-info>>main')}</Text>
          <div>
            <Text weight='bold'>{t('Example')}</Text>
            <Text>{t('greeting-info>>example')}</Text>
          </div>
          <Text>
            <Trans i18nKey='greeting-info>>more-info' t={t}>
              For more help on uploading a call queue greeting, visit{' '}
              {/* We need to have these point to the correct language page */}
              <TextLink href='https://weavehelp.com' target='_blank' rel='noreferrer'>
                Weave Help
              </TextLink>
              .
            </Trans>
          </Text>
        </Modal.Body>
      </Modal>
    </SettingSection>
  );
});

interface IGreetingControls {
  field: FieldProps<typeof formConfigBase, 'greetingMedia'>;
}

const GreetingControls = ({ field }: IGreetingControls) => {
  const { t } = useTranslation('phone', { keyPrefix: 'call-queue' });
  const { singleLocationId: locationId } = useAppScopeStore();

  return (
    <MediaPicker
      {...field}
      locationId={locationId}
      requestedTypes={{ custom: true, standard: false }}
      label={t('Select a Greeting')}
      onChange={(e) => {
        field.onChange(e);
      }}
      allowAddOption
    />
  );
};
