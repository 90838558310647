import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const builderFieldsStyle = css`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing(1.5)};
`;
