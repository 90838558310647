import { FC } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useTranslation } from '@frontend/i18n';
import { SCHEDULE_CALL_CHILLIPIPER_URL } from '../../../constants/self-upgrade';
import { FeatureSelfSubscribeModalProps } from '../../../types';
import { SelfUpgradeModal } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeModal';
import { CALL_INTEL_PRICE } from './constants';

export const CallIntelSelfSubscribeModal: FC<FeatureSelfSubscribeModalProps> = ({
  selfSubscribeEligibilityData,
  onUpgradeSuccess,
  ...modalProps
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'call-intel-details-modal' });
  const featureName = t('Call Intel');

  return (
    <SelfUpgradeModal
      featureEnum={Feature.CALL_INTELLIGENCE}
      featureName={featureName}
      price={CALL_INTEL_PRICE}
      subscriptionTerm='monthly'
      onUpgradeSuccess={onUpgradeSuccess}
      onboardingCallLink={SCHEDULE_CALL_CHILLIPIPER_URL}
      selfSubscribeEligibilityData={selfSubscribeEligibilityData}
      {...modalProps}
    />
  );
};
