import { SortDirection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { ListEmailCampaigns } from '../types';
import { getDirectionStorageKey } from './direction-storage';
import { EmailCampaign } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-campaign/v1/emailcampaign.pb';

export const sortCampaigns = (campaigns: ListEmailCampaigns): EmailCampaign[] =>
  campaigns?.filter(Boolean)?.sort((a, b) => {
    const sortDirection = getDirectionStorageKey();
    if (a.startSendAt === b.startSendAt) return 0;
    if (sortDirection === SortDirection.DESCENDING) return b.startSendAt?.localeCompare(a.startSendAt!) ?? 0;
    return a.startSendAt?.localeCompare(b.startSendAt!) ?? 0;
  }) ?? [];
