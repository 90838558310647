import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { i18next } from '@frontend/i18n';

export const SCHEDULE_CALL_CHILLIPIPER_URL = 'https://getweave.chilipiper.com/book/self-upgrade';
export const SUBSCRIPTION_SETTINGS_URL = '/home/dashboard#settings/subscriptions';

export enum SelfUpgradeActionType {
  NONE = 'NONE',
  REQUEST_UPGRADE = 'REQUEST_UPGRADE',
  SCHEDULE_CALL = 'SCHEDULE_CALL',
  FREE_TRIAL = 'FREE_TRIAL',
  UPGRADE_NOW = 'UPGRADE_NOW',
}

export const SelfUpgradeFeatureNameMap: Partial<Record<Feature, string>> = {
  [Feature.FORMS]: i18next.t('Digital Forms', { ns: 'featurePromotion>>feature' }),
  [Feature.REVIEWS_HOME_ICON]: i18next.t('Reviews', { ns: 'featurePromotion>>feature' }),
  [Feature.CALL_INTELLIGENCE]: i18next.t('Call Intelligence', { ns: 'featurePromotion>>feature' }),
  [Feature.PRACTICE_ANALYTICS]: i18next.t('Practice Analytics', { ns: 'featurePromotion>>feature' }),
};
