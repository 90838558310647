import { css } from '@emotion/react';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { ServiceProvidersTypes } from '@frontend/api-service-providers';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Chip, Avatar, NakedButton, Text } from '@frontend/design-system';
import { ProviderApptCount } from './provider-appt-count';

interface Props {
  providers?: ServiceProvidersTypes.ServiceProvider[];
  apptType?: AppointmentTypesTypes.AppointmentType;
  locationId: string;
  weekInView: string;
  selectProvider: (provider: ServiceProvidersTypes.ServiceProvider | string, day?: string) => void;
}

export const ProviderList = ({ providers, apptType, locationId, weekInView, selectProvider }: Props) => {
  const { t } = useTranslation('schedule');
  const weekdays = [t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday'), t('Sunday')];

  return (
    <>
      {providers && providers?.length > 1 && (
        <NakedButton css={styles.providerRow} onClick={() => selectProvider('any')}>
          <Avatar src={''} size='xl' css={{ marginRight: theme.spacing(2) }} />
          <div css={styles.nameBox}>
            <Text weight='bold' css={{ margin: theme.spacing(0, 0, 0.5, 0) }}>
              {t('No Preference')}
            </Text>
            <Chip>{t('Most Availability')}</Chip>
          </div>
          <section css={styles.apptBox}>
            {weekdays.map((day, index) => {
              const formattedDay = day.substring(0, 3);
              return (
                <div
                  key={`${day}=${index}`}
                  css={styles.apptBoxContent}
                  onClick={() => selectProvider('any', formattedDay)}
                >
                  <Text css={styles.dayText}>{formattedDay}</Text>
                  <ProviderApptCount
                    providerId='any'
                    apptTypeId={apptType?.id}
                    weekInView={weekInView}
                    locationId={locationId}
                    day={formattedDay}
                  />
                </div>
              );
            })}
          </section>
        </NakedButton>
      )}
      {providers?.map((provider) => {
        const { firstName, lastName, publicDisplayName, publicDisplayImage, id } = provider;
        const name = publicDisplayName ? publicDisplayName : `${firstName} ${lastName}`;
        return (
          <NakedButton
            data-cy='request-appointment-provider-select'
            key={id}
            onClick={() => selectProvider(provider)}
            css={styles.providerRow}
          >
            <Avatar name={`${name}`} src={publicDisplayImage} size='xl' css={{ marginRight: theme.spacing(2) }} />
            <Text weight='bold' css={styles.nameBox}>
              {name}
            </Text>
            <section css={styles.apptBox}>
              {weekdays.map((day, index) => {
                const formattedDay = day.substring(0, 3);
                return (
                  <div
                    key={`${day}=${index}`}
                    css={styles.apptBoxContent}
                    onClick={() => selectProvider(provider, formattedDay)}
                  >
                    <Text css={styles.dayText}>{formattedDay}</Text>
                    <ProviderApptCount
                      providerId={provider.id}
                      apptTypeId={apptType?.id}
                      weekInView={weekInView}
                      locationId={locationId}
                      day={formattedDay}
                    />
                  </div>
                );
              })}
            </section>
          </NakedButton>
        );
      })}
    </>
  );
};

const styles = {
  apptBox: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
    '@media only screen and (max-width: 650px)': {
      justifyContent: 'flex-start',
      overflow: 'auto',
    },
  }),
  apptBoxContent: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '68px',
  }),
  dayText: css({
    margin: 0,
  }),
  nameBox: css({
    marginRight: theme.spacing(2),
    minWidth: '150px',
    textAlign: 'left',
    '@media only screen and (max-width: 650px)': {
      margin: theme.spacing(2, 0, 3, 0),
    },
  }),
  providerRow: css({
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.neutral20}`,
    '@media only screen and (max-width: 650px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: 0,
    },
  }),
};
