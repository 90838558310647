import { ScheduleRequestSource } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { DayOfWeek as DayOfWeekSchema } from '@weave/schema-gen-ts/dist/shared/schedule/schedule.pb';
import dayjs from 'dayjs';
import { DataSourcesTypes } from '@frontend/api-data-sources';
import { sentry } from '@frontend/tracking';

type GetDayOfWeekReturnType = DayOfWeekSchema | '' | undefined;

export const getDayOfWeek = (date: string): GetDayOfWeekReturnType => {
  if (!date) return;

  // number of the day of the week from 0 (Sunday) to 6 (Saturday)
  const day = dayjs(date).isoWeekday();
  switch (day) {
    case 0:
      return DayOfWeekSchema.SUNDAY;
    case 1:
      return DayOfWeekSchema.MONDAY;
    case 2:
      return DayOfWeekSchema.TUESDAY;
    case 3:
      return DayOfWeekSchema.WEDNESDAY;
    case 4:
      return DayOfWeekSchema.THURSDAY;
    case 5:
      return DayOfWeekSchema.FRIDAY;
    case 6:
      return DayOfWeekSchema.SATURDAY;
    default:
      return '';
  }
};

export const formatDate = (dateStr: string): string => {
  const date = dayjs(dateStr);
  const today = dayjs();

  if (date.isSame(today, 'day')) {
    return `Today ${date.format('h:mm A')}`;
  } else if (date.isSame(today.subtract(1, 'day'), 'day')) {
    return `Yesterday ${date.format('h:mm A')}`;
  } else {
    return date.format('MMM D, h:mm A');
  }
};

const bookingLinkBaseURL = ['https://book2.weavedev.net', 'https://book2.getweave.com'];

export const validateIsWeaveBookingLink = (url: string): boolean => {
  try {
    const parsedURL = new URL(url);
    return bookingLinkBaseURL.includes(parsedURL.origin);
  } catch {
    sentry.warn({
      error: 'Invalid url provided for booking link',
      topic: 'schedule',
      addContext: {
        name: 'url details',
        context: {
          url,
        },
      },
    });
    return false;
  }
};

export const isValidBookingLink = (url: string, locationId: string): boolean => {
  return validateIsWeaveBookingLink(url) && url?.includes(locationId);
};

export const hasSameIds = (masterIds: string[], filterIds: string[]): boolean => {
  return masterIds.length === filterIds.length && masterIds.every((id) => filterIds.includes(id));
};

export const getIsIntegratedOffice = (syncApps: DataSourcesTypes.DataSource[]): boolean => {
  return syncApps?.some((syncApp) => syncApp.SourceType === 'Integration');
};

export const getBookingUrlWithSource = (bookingUrl: string, bookingSource: ScheduleRequestSource) => {
  const hasLocationPortalSlug = bookingUrl.includes('locationPortalSlug');
  return hasLocationPortalSlug ? `${bookingUrl}&source=${bookingSource}` : `${bookingUrl}?source=${bookingSource}`;
};

export const getValidScheduleRequestSourceEnum = (value: string): ScheduleRequestSource => {
  if (Object.values(ScheduleRequestSource).includes(value as ScheduleRequestSource)) {
    return value as ScheduleRequestSource;
  }
  return ScheduleRequestSource.WEBSITE;
};
