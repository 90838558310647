import {
  BaseFieldProps,
  DropdownField,
  Modal,
  ModalControlTriggerProps,
  TextLink,
  useModalControl,
} from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { DeptPhoneNumberApi, DeptPhoneNumberTypes } from '@frontend/api-department-phone-numbers';
import { ForwardingNumberApi } from '@frontend/api-forwarding-number';
import { useQuery } from '@frontend/react-query-helpers';
import { removeNonDigits } from '@frontend/phone-numbers';
import { queryKeys } from '../query-keys';
import { AddForwardingNumberModal } from './add-forwarding-number-modal';
import { useEffect } from 'react';

type AddForwardingNumberOptionProps = {
  triggerProps: ModalControlTriggerProps;
};
export const AddForwardingNumberOption = ({ triggerProps }: AddForwardingNumberOptionProps) => {
  const { t } = useTranslation('phone');
  return (
    <div onClick={triggerProps.onClick}>
      <DropdownField.Option value='' isSelectable={false}>
        <TextLink weight='bold' css={{ cursor: 'pointer' }}>
          {t('Add Forwarding Number')}
        </TextLink>
      </DropdownField.Option>
    </div>
  );
};

export const ForwardingNumberPicker = ({
  locationId,
  departmentId,
  name = 'forwardingNumberId',
  label,
  ...rest
}: {
  locationId: string;
  departmentId?: string;
  name?: string;
  label?: string;
} & BaseFieldProps<string, '', HTMLInputElement>) => {
  const { t } = useTranslation('phone');
  const { triggerProps, modalProps } = useModalControl();

  const { data: forwardingNumbers } = useQuery({
    queryKey: [locationId, ...queryKeys.forwardingNumbers()],
    queryFn: () => ForwardingNumberApi.list({ locationId }),
    select: (data) =>
      data.sort((a, b) => {
        const aName = a.name || '';
        const bName = b.name || '';
        return aName.localeCompare(bName);
      }),
    enabled: !!locationId,
  });

  const { data: numbers } = useQuery({
    enabled: !!departmentId,
    queryKey: queryKeys.listDepartmentPhoneNumbers(departmentId!),
    queryFn: () => DeptPhoneNumberApi.getDepartmentPhonenumbers({ departmentId }),
  });

  const deptPhoneNumbers = numbers?.departmentPhoneNumbers?.[0].departmentPhoneNumbers;
  const allowedForwardNumbers =
    forwardingNumbers?.filter((fwdItem) => {
      return !deptPhoneNumbers
        ?.filter((item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => !!item.isAssigned)
        .some(
          (deptPhone: DeptPhoneNumberTypes.DepartmentPhoneNumberType) =>
            removeNonDigits(deptPhone.number) === fwdItem.number
        );
    }) ?? [];

  const disallowedNumbers = deptPhoneNumbers
    ?.filter((item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => !!item.isAssigned)
    .map((item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => removeNonDigits(item.number));

  useEffect(() => {
    rest.onChange({
      name,
      value: rest.value,
    });
  }, [forwardingNumbers]);

  return (
    <>
      <DropdownField
        {...rest}
        name={name}
        label={label ?? t('Forwarding Number')}
        css={{
          width: '300px',
          marginRight: theme.spacing(0.25),
        }}
      >
        <AddForwardingNumberOption triggerProps={triggerProps} />
        {allowedForwardNumbers?.map((fwdNumber) => (
          <DropdownField.Option key={fwdNumber.id} value={fwdNumber.id}>
            {`${fwdNumber.name} (${fwdNumber.number})`}
          </DropdownField.Option>
        ))}
      </DropdownField>
      <Modal
        {...modalProps}
        css={{
          padding: theme.spacing(4, 1),
        }}
      >
        <AddForwardingNumberModal
          fwdNumbers={allowedForwardNumbers}
          disallowedNumbers={disallowedNumbers}
          locationID={locationId}
          changeForwardingNumber={(id: string) =>
            rest.onChange({
              name,
              value: id,
            })
          }
          closeModal={modalProps.onClose}
        />
      </Modal>
    </>
  );
};
