import { css } from '@emotion/react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { MerchantPlanTypes } from '@frontend/api-payments';
import { formatCentsToCurrency } from '@frontend/currency';
import { formatDate } from '@frontend/date';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { PaymentsFeatureFlags, useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { theme } from '@frontend/theme';
import {
  Chip,
  ContentLoader,
  CopyToClipboardButton,
  EditSimpleIcon,
  Heading,
  MessageIcon,
  SecondaryButton,
  Text,
  UserIcon,
  useTooltip,
} from '@frontend/design-system';
import { CollectPaymentModalSteps, useCollectPaymentModal, useValidPhoneNumberFromInvoice } from '../hooks';
import SunbitIcon from './assets/sunbit.svg?react';
import { LargeInfo } from './large-info';

const styles = {
  summary: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
  amount: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(4)};
    flex-wrap: wrap;
  `,
  sectionTitle: css`
    margin: ${theme.spacing(0, 0, 2)};
  `,
  iconButton: css`
    text-transform: none;
  `,
  icon: css`
    margin-right: ${theme.spacing(1)};
    width: ${theme.spacing(2)};
    height: ${theme.spacing(2)};
  `,
  divider: css`
    display: flex;
    align-items: center;
  `,
  orText: css`
    margin: ${theme.spacing(1, 1)};
  `,
  paymentMethods: css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${theme.spacing(20)}, 1fr));
    align-content: stretch;
    grid-gap: ${theme.spacing(1)};
    & > button {
      height: ${theme.spacing(8)};
    }
  `,
  sunbitIcon: (isAcceptableSunbitAmount: boolean) =>
    css`
      ${!isAcceptableSunbitAmount && `opacity: 0.25;`};
    `,
  sunbitButton: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
  `,
  sunbitButtonTitle: css`
    display: flex;
    align-items: center;
  `,
  modalActions: css`
    display: flex;
    flex-wrap: wrap;
    margin-top: ${theme.spacing(2)};
    justify-content: end;
    gap: ${theme.spacing(2)};
    align-items: center;
  `,
  modalActionsButton: css`
    width: auto;
  `,
};

const MINIMUM_SUNBIT_AMOUNT = 6000;

export const PaymentFlowList = () => {
  const { t } = useTranslation('payments');
  const { invoice, isLoading } = useSelectedInvoice();
  const { goToStep } = useCollectPaymentModal();
  const { closeModal } = useMultiStepModal();
  const { getLocationName } = useMultiQueryUtils();
  const { firstValidPhoneNumber } = useValidPhoneNumberFromInvoice(invoice);
  const { stripeLocationId, merchant } = useMerchant();
  const { aggregateValue: enableACHOnFile } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.ACHOnFile,
    locationIds: [invoice?.locationId ?? ''],
  });

  const isAcceptableSunbitAmount = !!invoice?.billedAmount && invoice.billedAmount >= MINIMUM_SUNBIT_AMOUNT;

  const customerPhoneNumberError = t('No valid phone number');
  const { Tooltip: TtpTooltip, triggerProps: ttpTooltipTriggerProps, tooltipProps: ttpTooltipProps } = useTooltip();
  const {
    Tooltip: SunbitTooltip,
    triggerProps: sunbitTooltipTriggerProps,
    tooltipProps: sunbitTooltipProps,
  } = useTooltip();

  const shouldShowTerminal = () =>
    !!stripeLocationId &&
    !!merchant?.planName &&
    (merchant?.planName === MerchantPlanTypes.FullPayments || merchant?.planName === MerchantPlanTypes.Full);

  if (isLoading || !invoice) return <ContentLoader show={true} />;

  return (
    <section>
      <div css={styles.summary}>
        <LargeInfo title={t('Customer')} info={invoice?.person.name} />
        <div css={styles.amount}>
          <LargeInfo title={t('Amount Owed')} info={formatCentsToCurrency(invoice?.billedAmount)} />
          <LargeInfo title={t('Payment Due')} info={formatDate(invoice?.billedAt, 'MMMM D, YYYY')} />
          <LargeInfo
            title={t('Location')}
            info={<Chip.SingleChip>{getLocationName(invoice?.locationId)}</Chip.SingleChip>}
          />
        </div>
      </div>
      <div css={styles.divider}>
        <Divider color={theme.colors.neutral20} />
      </div>
      <Heading level={3} css={styles.sectionTitle}>
        {t('Select Payment Method')}
      </Heading>
      <section css={styles.paymentMethods}>
        <SecondaryButton
          css={[styles.iconButton, styles.sunbitButton]}
          onClick={() => goToStep(CollectPaymentModalSteps.ShareInMessage)}
          disabled={!firstValidPhoneNumber}
          {...(!firstValidPhoneNumber ? ttpTooltipTriggerProps : {})}
        >
          <div css={styles.sunbitButtonTitle}>
            <MessageIcon css={styles.icon} />
            {t('Text to Pay')}
          </div>
          <TtpTooltip {...ttpTooltipProps}>{customerPhoneNumberError}</TtpTooltip>
        </SecondaryButton>
        {shouldShowTerminal() && (
          <SecondaryButton css={styles.iconButton} onClick={() => goToStep(CollectPaymentModalSteps.ReaderSelection)}>
            <Icon name='credit-card' css={styles.icon} />
            {t('Pay at Terminal')}
          </SecondaryButton>
        )}
        <SecondaryButton css={styles.iconButton} onClick={() => goToStep(CollectPaymentModalSteps.CardOnFile)}>
          <UserIcon css={styles.icon} />
          {enableACHOnFile ? t('Card/ACH on File') : t('Card on File')}
        </SecondaryButton>
        <SecondaryButton css={styles.iconButton} onClick={() => goToStep(CollectPaymentModalSteps.ManualCardEntry)}>
          <EditSimpleIcon css={styles.icon} />
          {t('Manual Card Entry')}
        </SecondaryButton>
        <SecondaryButton
          css={[styles.iconButton, styles.sunbitButton]}
          onClick={() => goToStep(CollectPaymentModalSteps.Sunbit)}
          disabled={!isAcceptableSunbitAmount || !firstValidPhoneNumber}
          {...(!firstValidPhoneNumber ? sunbitTooltipTriggerProps : {})}
        >
          <div css={styles.sunbitButtonTitle}>
            <SunbitIcon css={[styles.icon, styles.sunbitIcon(isAcceptableSunbitAmount)]} />
            {t('Sunbit')}
          </div>
          {!isAcceptableSunbitAmount && (
            <Text size='small' color='error'>
              {t('($60.00 minimum)')}
            </Text>
          )}
          {<SunbitTooltip {...sunbitTooltipProps}>{customerPhoneNumberError}</SunbitTooltip>}
        </SecondaryButton>
      </section>
      <div css={styles.modalActions}>
        <CopyToClipboardButton
          variant='tertiary'
          textToCopy={invoice?.links.payment ?? ''}
          tooltipText={t('Copy link')}
          tooltipSuccessText={t('Link copied!')}
          hasIcon={false}
          hasSuccessTooltip
        >
          {t('Copy payment link')}
        </CopyToClipboardButton>
        <SecondaryButton onClick={() => closeModal()} css={styles.modalActionsButton}>
          {t('Collect Later')}
        </SecondaryButton>
      </div>
    </section>
  );
};
