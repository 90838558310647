import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { OpeningRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import {
  Avatar,
  ContentLoader,
  DatePickerField,
  DropdownField,
  FormRow,
  MessageTemplate,
  NakedButton,
  Text,
  TimeField,
  useAlert,
} from '@frontend/design-system';
import { getIsIntegratedOffice } from '../../../../../../utils';
import { PersonSelector, PersonSelectorTypes } from '../../../../components/PersonSelector';
import { PersonSelectorFormValues } from '../../../../components/PersonSelector/types';
import { defaultTemplate } from '../constant';
import { useScheduleRequestModalContext } from '../Context/ScheduleRequestModalContext';
import { useScheduleRequestModalFormContext } from '../Context/ScheduleRequestModalFormContext';
import { ScheduleRequestsTrackingIds } from '../trackingIds';
import {
  convertToServerDate,
  getFormattedAppointmentType,
  getFullName,
  getScheduleRequestFullName,
  getShouldRenderSourceDropDown,
  getShouldRenderWorkstationsDropdown,
} from '../utils';

export const ScheduleRequestModalForm = () => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarRequest');
  const { selectedScheduleRequest } = useScheduleRequestModalContext();

  const {
    personSelectorDialogProps,
    manageScheduleRequestFormDetails,
    manageScheduleRequestModalDetails,
    selectedPerson,
    personSelectorProps,
    setTemplate,
  } = useScheduleRequestModalFormContext();

  const {
    access,
    isLoading,
    isSyncAppDetailsLoading,
    providers,
    workstations,
    appointmentTypes,
    selectedAppointmentDetails,
    syncAppDataSources,
    tags,
  } = manageScheduleRequestModalDetails;

  const isIntegratedOffice = getIsIntegratedOffice(syncAppDataSources);
  const shouldRenderDataSourceDropdown = getShouldRenderSourceDropDown(syncAppDataSources ?? []);

  const {
    scheduleRequestApprovalForm: { values, seedValues, getFieldProps, isComplete },
    customDateField,
    customTimeField,
  } = manageScheduleRequestFormDetails;

  const { mutateAsync: addWritebackCustomer, isLoading: isLoadingAddWritebackCustomer } =
    ScheduleQueries.useAddWritebackCustomer();

  const { mutateAsync: addCustomContact, isLoading: isAddingCustomContact } = ScheduleQueries.useMutateAddCustomContact(
    selectedScheduleRequest.locationId ?? ''
  );

  useEffect(() => {
    const sourceId = selectedPerson?.SourceID || syncAppDataSources?.[0]?.SourceID;
    seedValues({
      ...values,
      source: sourceId ?? '',
      appointmentType: selectedAppointmentDetails.appointmentId,
    });
  }, [syncAppDataSources?.[0]?.SourceID, selectedAppointmentDetails.appointmentId, selectedPerson?.SourceID]);

  const [filteredProviderList, filteredWorkstationList] = useMemo(() => {
    const filteredProviders = isIntegratedOffice
      ? providers?.filter((provider) => provider.sourceId === values.source)
      : providers;
    const filteredWorkstations = workstations?.filter((workstation) => workstation.sourceId === values.source);
    return [filteredProviders, filteredWorkstations];
  }, [values.source, providers, workstations, isIntegratedOffice]);

  useEffect(() => {
    if (filteredWorkstationList.length === 1 && isIntegratedOffice) {
      seedValues({
        ...values,
        workstation: filteredWorkstationList[0]?.id,
      });
    }
  }, [filteredWorkstationList, isIntegratedOffice]);

  const shouldRenderWorkstationDropdown = getShouldRenderWorkstationsDropdown(
    filteredWorkstationList,
    isIntegratedOffice
  );

  const disabledForm = !selectedPerson?.PersonID;

  const handleAddCustomContact = async (formValues: PersonSelectorFormValues) => {
    return await addCustomContact({
      first_name: formValues?.firstName ?? '',
      last_name: formValues?.lastName ?? '',
      phone_mobile: formValues?.phoneNumber ?? '',
      birthdate: formValues?.dateOfBirth ? convertToServerDate(formValues?.dateOfBirth) : '',
      email: formValues?.email ?? '',
      gender: formValues?.gender ?? '',
    });
  };

  const handleAddWritebackContact = async (formValues: PersonSelectorFormValues) => {
    const clientLocationId =
      formValues?.clientLocationId ||
      access?.ClientLocations?.find((clientLocation) => clientLocation?.SourceID === formValues?.source)
        ?.ClientLocationID;
    return await addWritebackCustomer({
      ClientLocationID: clientLocationId ?? '',
      LocationID: selectedScheduleRequest?.locationId ?? '',
      SourceID: formValues?.source ?? '',
      Person: {
        FirstName: formValues?.firstName ?? '',
        LastName: formValues?.lastName ?? '',
        MobilePhone: formValues?.phoneNumber ?? '',
        Email: formValues?.email ?? '',
        Gender: formValues?.gender ?? '',
        Birthdate: formValues?.dateOfBirth ? convertToServerDate(formValues?.dateOfBirth) : '',
        Address1: selectedScheduleRequest?.schedulee?.address?.street ?? '',
        City: selectedScheduleRequest?.schedulee?.address?.city ?? '',
        State: selectedScheduleRequest?.schedulee?.address?.state ?? '',
        Address2: selectedScheduleRequest?.schedulee?.address?.unit ?? '',
        PostalCode: selectedScheduleRequest?.schedulee?.address?.postalCode ?? '',
        HomePhone: '',
        MaritalStatus: '',
        MiddleName: '',
        PreferredName: '',
        ReferralSource: '',
        SSN: '',
        WorkPhone: '',
      },
    });
  };

  const handleAddCustomerContact = async (formValues: PersonSelectorFormValues) => {
    try {
      const isCustomContactSource =
        syncAppDataSources?.find((integration) => integration.SourceID === formValues.source)?.SourceType ===
        'CustomContact';

      const response =
        isIntegratedOffice && !isCustomContactSource
          ? await handleAddWritebackContact(formValues)
          : await handleAddCustomContact(formValues);
      if (response) {
        alert.success(t('Customer successfully created.'));
        queryClient.invalidateQueries({
          queryKey: [
            'person-contacts',
            selectedScheduleRequest?.schedulee?.phoneNumber ?? '',
            selectedScheduleRequest.locationId ?? '',
          ],
        });
        personSelectorProps.setPersonSelectorScreen(PersonSelectorTypes.PatientSelectorScreenEnum.PERSON_SEARCH);
      }
    } catch (e) {
      console.error(e);
      alert.error(t('We were unable to create the customer.'));
    }
  };

  const fullName = selectedPerson ? getFullName(selectedPerson) : getScheduleRequestFullName(selectedScheduleRequest);

  return (
    <>
      <section css={scheduleRequestModalDetailsStyle}>
        <ContentLoader show={isLoading || isSyncAppDetailsLoading} />
        <div css={scheduleRequestDetailsHeaderStyles}>
          <Avatar name={fullName} />
          <div css={{ marginLeft: theme.spacing(2) }}>
            <Text weight='bold' size='large'>
              {fullName}
            </Text>
            <Text size='small' color='light'>
              {formatPhoneNumber(selectedPerson?.MobilePhone ?? selectedScheduleRequest?.schedulee?.phoneNumber)}
            </Text>
          </div>
          <NakedButton
            trackingId={ScheduleRequestsTrackingIds.linkPatientBtn}
            css={linkPatientStyles}
            {...personSelectorDialogProps.getTriggerProps()}
          >
            <Icon color='primary' name='user-small' />
            <Text as='span' color='primary' weight='bold'>
              {t('Link Patient')}
            </Text>
          </NakedButton>
        </div>
        <form css={{ marginTop: theme.spacing(3) }}>
          <FormRow cols={[50, 50]}>
            <DropdownField
              css={fieldStyles}
              disabled={disabledForm}
              label={t('Appointment Type')}
              {...getFieldProps('appointmentType')}
            >
              {appointmentTypes?.map((item) => (
                <DropdownField.Option value={item?.id} key={item?.id}>
                  {getFormattedAppointmentType(item)}
                </DropdownField.Option>
              ))}
            </DropdownField>
            <DropdownField
              css={fieldStyles}
              disabled={disabledForm}
              label={t('Date & Time')}
              {...getFieldProps('dateAndTime')}
            >
              {selectedScheduleRequest?.requestedOpenings?.map((item: OpeningRequest) => (
                <DropdownField.Option value={item?.dateTime ?? ''} key={item?.dateTime ?? ''}>
                  {dayjs(item.dateTime).format('MMM DD @ hh:mm a')}
                </DropdownField.Option>
              ))}
              <DropdownField.Option value='custom'>Custom</DropdownField.Option>
            </DropdownField>
          </FormRow>
          {values.dateAndTime === 'custom' && (
            <FormRow cols={[50, 50]}>
              <DatePickerField name='customDate' label='Custom Date' css={fieldStyles} {...customDateField} />
              <TimeField name='customTime' label='Custom Time' css={fieldStyles} {...customTimeField} />
            </FormRow>
          )}
          <FormRow cols={[50, 50]}>
            {shouldRenderDataSourceDropdown && (
              <DropdownField
                css={fieldStyles}
                disabled={disabledForm}
                label={t('Select Source')}
                {...getFieldProps('source')}
              >
                {syncAppDataSources?.map(({ SourceID, SourceName, PracticeManagementSystem }) => (
                  <DropdownField.Option value={SourceID} key={SourceID}>
                    <Text as='span' css={{ marginRight: theme.spacing(1) }}>
                      {SourceName}
                    </Text>
                    <Text as='span' color='light' size='small'>
                      {PracticeManagementSystem}
                    </Text>
                  </DropdownField.Option>
                ))}
              </DropdownField>
            )}

            <DropdownField
              css={fieldStyles}
              disabled={disabledForm}
              label={t('Select Provider')}
              {...getFieldProps('provider')}
            >
              {filteredProviderList?.map(({ id, firstName, lastName }) => (
                <DropdownField.Option value={id ?? ''} key={id}>
                  {firstName} {lastName}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
          {shouldRenderWorkstationDropdown && (
            <FormRow>
              <DropdownField disabled={disabledForm} label={t('Workstation')} {...getFieldProps('workstation')}>
                {filteredWorkstationList?.map(({ id, workstationName }) => (
                  <DropdownField.Option value={id ?? ''} key={id}>
                    {workstationName}
                  </DropdownField.Option>
                ))}
              </DropdownField>
            </FormRow>
          )}
        </form>
        <MessageTemplate
          initialTemplate={defaultTemplate}
          tags={tags}
          draggableTags={true}
          onChange={(template) => {
            setTemplate(template);
          }}
          readOnly={!isComplete || disabledForm}
        >
          <MessageTemplate.Editor />
          <Text weight='light' color='light'>
            {t('You can also drag and drop, or click a tag below, to add your message')}
          </Text>
          <MessageTemplate.TagList />
        </MessageTemplate>
      </section>
      <PersonSelector
        addContactScreenTitle={t('Add Patient')}
        defaultSearchValue={selectedScheduleRequest?.schedulee?.phoneNumber ?? ''}
        locationId={selectedScheduleRequest.locationId ?? ''}
        handleAddCustomerContact={handleAddCustomerContact}
        isLoading={isLoadingAddWritebackCustomer || isAddingCustomContact}
        personSelectorDialogProps={personSelectorDialogProps}
        selectedPerson={selectedPerson}
        showDataSources={true}
        addNewButtonTrackingId={ScheduleRequestsTrackingIds.personSelectorAddNewPersonBtn}
        addToListButtonTrackingId={ScheduleRequestsTrackingIds.personSelectorAddToListBtn}
        {...personSelectorProps}
      />
    </>
  );
};

const linkPatientStyles = css({
  display: 'flex',
  alignItems: 'center',
  width: 130,
  marginLeft: 'auto',
  cursor: 'pointer',
  '> svg': {
    marginRight: theme.spacing(0.5),
  },
});

const scheduleRequestDetailsHeaderStyles = css({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.colors.neutral10}`,
  borderTop: `1px solid ${theme.colors.neutral10}`,
  padding: theme.spacing(2),
});

const scheduleRequestModalDetailsStyle = css({
  backgroundColor: 'white',
  padding: theme.spacing(2),
  borderRadius: theme.borderRadius.small,
  margin: theme.spacing(1),
});

const fieldStyles = css({ width: 192 });
