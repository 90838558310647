import { ChangeEvent, FC, MutableRefObject, useRef, useCallback, useEffect } from 'react';
import { format } from 'date-fns';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Text, TextButton, useAlert } from '@frontend/design-system';
import { useWritebackWidgetStore } from '../../../../../../providers';
import { useProviderSignature, useProviderReviewSteps } from '../../../../hooks';
import {
  imageUploadWrapperStyle,
  signatureActionButtonStyle,
  uploadedImagePreviewStyle,
} from './upload-signature.styles';

const UploadSignature: FC = () => {
  const { t } = useTranslation('forms');
  const alert = useAlert();
  const { goToNextStep } = useProviderReviewSteps();
  const {
    uploadedImage,
    setUploadedImage,
    saveSigntureTrigger,
    activeSignatureTab,
    setSaveSignatureTrigger,
    shouldSaveAsDefaultSignature,
  } = useWritebackWidgetStore([
    'uploadedImage',
    'setUploadedImage',
    'saveSigntureTrigger',
    'activeSignatureTab',
    'setSaveSignatureTrigger',
    'shouldSaveAsDefaultSignature',
  ]);

  const fileUploaderRef = useRef() as MutableRefObject<HTMLInputElement>;
  const uploadedImagePreview = useRef<string | undefined>(
    uploadedImage ? URL.createObjectURL(uploadedImage) : undefined
  );
  const { storeSignature } = useProviderSignature();

  const imageChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const file = event.target.files[0];

        setUploadedImage(file);
        uploadedImagePreview.current = URL.createObjectURL(file);
      }
    },
    [setUploadedImage]
  );

  const openFileUploader = useCallback(() => {
    fileUploaderRef.current.click();
  }, []);

  const saveUploadedSignature = useCallback(async () => {
    if (!uploadedImage) {
      alert.error(t('No signature image uploaded.'));
      setSaveSignatureTrigger(false);
      return;
    }

    await storeSignature(
      {
        type: 'image',
        data: uploadedImage,
        font_type: '',
        timestamp: format(new Date(), 'MMMM d, yyyy hh:mm:ss'),
      },
      shouldSaveAsDefaultSignature
    );

    setSaveSignatureTrigger(false);
    goToNextStep();

    if (shouldSaveAsDefaultSignature) {
      // refetchDefaultSignature();
    }
  }, [uploadedImage, storeSignature, shouldSaveAsDefaultSignature, setSaveSignatureTrigger, goToNextStep]);

  useEffect(() => {
    if (!saveSigntureTrigger || activeSignatureTab !== 'upload') {
      return;
    }

    saveUploadedSignature();
  }, [saveSigntureTrigger, activeSignatureTab, saveUploadedSignature]);

  return (
    <>
      <input
        ref={fileUploaderRef}
        accept='image/png, image/jpeg, image/jpg'
        type='file'
        hidden
        onChange={imageChangeHandler}
      />

      {!uploadedImagePreview.current ? (
        <div css={imageUploadWrapperStyle} onClick={openFileUploader}>
          <Icon name='plus' className='add-signature-image' size={40} />
          <Text color='light'>{t('Upload an image of your signature')}</Text>
        </div>
      ) : (
        <div css={uploadedImagePreviewStyle}>
          <img src={uploadedImagePreview.current} alt='Uploaded image preview' width='150' height='100' />
        </div>
      )}

      {uploadedImagePreview.current && (
        <TextButton css={signatureActionButtonStyle} onClick={openFileUploader}>
          {t('Replace Image')}
        </TextButton>
      )}
    </>
  );
};

export default UploadSignature;
