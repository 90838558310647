import { useEffect, useMemo, useRef } from 'react';
import { useMatchRoute, useSearch } from '@tanstack/react-location';
import {
  IntakeFormQueries,
  prepareIntakeFormStoreInfoFromLocationList,
  useIntakeFormShallowStore,
  useIntakeFormStore,
} from '@frontend/api-intake-form';
import { getUser, isWeaveUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { useAlert } from '@frontend/design-system';
import {
  INTAKE_FORM_PATHS,
  INTAKE_FORM_PATH_V2,
  INTAKE_FORM_V2_FEATURE_FLAG,
  NWX_DASHBOARD_PATH,
  PORTAL_DASHBOARD_PATH,
} from '../constants';

// Custom Hook used to determine whether a redirect to the onboarding intake form should
// occur for the current location.
export const useOnboardingRedirect = ({ isLocationReady }: { isLocationReady: boolean }) => {
  const { t } = useTranslation('onboarding');
  const alert = useAlert();
  const user = getUser();
  const urlSearchParam = useSearch<{ Search: { v: number } }>();
  const matchRoute = useMatchRoute();
  const useIsLoaderShownRef = useRef<boolean>(false);
  const { selectedLocationIdsWithParents, selectedParentsIds } = useAppScopeStore();
  const { setIntakeFormLocationListInfo } = useIntakeFormShallowStore('setIntakeFormLocationListInfo');

  const selectedLocationId = selectedLocationIdsWithParents?.[0] ?? '';
  const isParentLocation = selectedParentsIds.includes(selectedLocationId);
  const isIntakeFormV1Route = !!matchRoute({ to: INTAKE_FORM_PATHS.root, fuzzy: true });
  const isIntakeFormV2Route = !!matchRoute({ to: INTAKE_FORM_PATH_V2 });

  const intakeFormLocationsQuery = IntakeFormQueries.useIntakeFormLocationsQuery(selectedLocationId);

  useEffect(() => {
    if (intakeFormLocationsQuery.isError) {
      alert.error(t('Failed to retrieve onboarding form(s) meta data.'));
    }
  }, [intakeFormLocationsQuery.isError]);

  const intakeFormLocationListInfo = useMemo(() => {
    if (intakeFormLocationsQuery.isLoading) return { isShowIntakeForm: false, isSingleSoftwareOnlyLocation: false };
    const listInfo = prepareIntakeFormStoreInfoFromLocationList({
      intakeFormLocations: intakeFormLocationsQuery.data ?? [],
      userEmail: user?.username,
      isParentLocation: isParentLocation,
      isWeaveUser: isWeaveUser(),
    });
    useIntakeFormStore.reset();
    setIntakeFormLocationListInfo(listInfo);
    return listInfo;
  }, [intakeFormLocationsQuery.data, intakeFormLocationsQuery.isLoading]);

  const isLoadingOnboardingInfo = intakeFormLocationsQuery.isLoading || !isLocationReady;
  const isIntakeFormV2Enabled =
    useHasFeatureFlag(INTAKE_FORM_V2_FEATURE_FLAG) && intakeFormLocationListInfo.isSingleSoftwareOnlyLocation;
  let onboardingRedirectUrl = '';
  if (!isLoadingOnboardingInfo) {
    if (
      intakeFormLocationListInfo.isShowIntakeForm &&
      (isIntakeFormV2Enabled ? !isIntakeFormV2Route : !isIntakeFormV1Route)
    ) {
      onboardingRedirectUrl = isIntakeFormV2Enabled ? INTAKE_FORM_PATH_V2 : INTAKE_FORM_PATHS.root;
    } else if (!intakeFormLocationListInfo.isShowIntakeForm && (isIntakeFormV1Route || isIntakeFormV2Route)) {
      onboardingRedirectUrl = isIntakeFormV2Enabled ? NWX_DASHBOARD_PATH : PORTAL_DASHBOARD_PATH;
    }
  }

  const isHomeDashboardV2Page = !!matchRoute({ to: NWX_DASHBOARD_PATH }) && urlSearchParam.v === 2;

  // This is a workaround to prevent the loader from showing up when the user is already on the dashboard page
  useEffect(() => {
    if (isHomeDashboardV2Page && !isLoadingOnboardingInfo) {
      useIsLoaderShownRef.current = true;
    }
  }, [isHomeDashboardV2Page, isLoadingOnboardingInfo]);

  return {
    shouldShowOnboardingLoader: !useIsLoaderShownRef.current && isHomeDashboardV2Page && isLoadingOnboardingInfo,
    onboardingRedirectUrl,
  };
};
