import { useMemo } from 'react';
import { css } from '@emotion/react';
import { PaymentPlanDetailsResponse } from '@frontend/api-payment-plans';
import { getUser } from '@frontend/auth-helpers';
import { getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { getPaymentMethodName } from '@frontend/payments-card-on-file';
import { theme } from '@frontend/theme';
import { ForwardIcon, Modal, Text } from '@frontend/design-system';
import { formatCentsToCurrency, isoDateToDisplayLocalString, prettify } from '../../utils';
import { PaymentPlanStatusChip } from '../PaymentPlanStatusChip';

export const PlanChangesModal = ({
  fromData,
  toData,
  changeReason,
  handleUpdatePaymentPlan,
  onClose,
  viewType,
}: {
  fromData?: PaymentPlanDetailsResponse;
  toData?: PaymentPlanDetailsResponse;
  changeReason?: string;
  handleUpdatePaymentPlan?: () => void;
  onClose: () => void;
  viewType: 'paused' | 'view' | 'confirm';
}) => {
  const { t } = useTranslation('payments');
  const user = getUser();

  const modalHeader =
    viewType === 'paused'
      ? t('Paused Payment Plan')
      : viewType === 'view'
      ? t('Payment Plan Changes')
      : t('Confirm Changes');

  const headerDetailsMap = [
    {
      title: t('Date Updated'),
      value: getTodaysDate('MMMM DD, YYYY'),
    },
    {
      title: t('Updated by'),
      value: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim(),
    },
    {
      title: viewType === 'paused' ? t('Reason for Pausing') : t('Reason for Updating'),
      value: changeReason,
    },
  ];

  const dataHeadersMap = [
    {
      key: 'headers',
      value: t('Headers'),
    },
    {
      key: 'previous',
      value: t('Previous'),
    },
    {
      key: 'new',
      value: t('New'),
    },
  ];

  const dataMap = useMemo(
    () => [
      {
        key: 'planName',
        header: t('Payment Plan Name'),
        fromValue: fromData?.name,
        toValue: toData?.name,
      },
      {
        key: 'planStarted',
        header: t('Payment Plan Started'),
        fromValue: isoDateToDisplayLocalString(fromData?.createdAt || ''),
        toValue: isoDateToDisplayLocalString(toData?.schedule.startAt || ''),
      },
      {
        key: 'planAmount',
        header: t('Original Balance'),
        fromValue: formatCentsToCurrency(fromData?.schedule?.amount),
        toValue: formatCentsToCurrency(toData?.schedule.amount),
      },
      {
        key: 'planEnd',
        header: t('Final Payment Due'),
        fromValue: isoDateToDisplayLocalString(fromData?.schedule?.finalPaymentAt || ''),
        toValue: isoDateToDisplayLocalString(toData?.schedule?.finalPaymentAt || ''),
      },
      {
        key: 'planAmountRemaining',
        header: t('Remaining Balance'),
        fromValue: formatCentsToCurrency(fromData?.paymentSummary?.remainingAmount),
        toValue: formatCentsToCurrency(toData?.paymentSummary?.remainingAmount),
      },
      {
        key: 'planAmountPaid',
        header: t('Payment Amount'),
        fromValue: formatCentsToCurrency(fromData?.schedule?.paymentAmount),
        toValue: formatCentsToCurrency(toData?.schedule?.paymentAmount),
      },
      {
        key: 'planNextDue',
        header: t('Next Payment Due'),
        fromValue: isoDateToDisplayLocalString(fromData?.schedule?.nextPaymentAt || ''),
        toValue: isoDateToDisplayLocalString(toData?.schedule?.nextPaymentAt || ''),
      },
      {
        key: 'planTerm',
        header: t('Payment Term'),
        fromValue: prettify(fromData?.schedule?.frequency || 'MONTHLY', { outputCase: 'Sentence case', split: '_' }),
        toValue: prettify(toData?.schedule?.frequency || 'MONTHLY', { outputCase: 'Sentence case', split: '_' }),
      },
      {
        key: 'planRemainingTerm',
        header: t('Payments Remaining'),
        fromValue: fromData?.paymentSummary?.remainingTerms,
        toValue: toData?.paymentSummary?.remainingTerms,
      },
      {
        key: 'planBillingMethod',
        header: t('Billing Method'),
        fromValue: getPaymentMethodName(fromData?.paymentMethod),
        toValue: getPaymentMethodName(toData?.paymentMethod),
      },
    ],
    [fromData, toData]
  );

  return (
    <>
      <Modal.Header textAlign='left'>{modalHeader}</Modal.Header>
      <Modal.Body>
        <div
          css={css`
            display: flex;
            flex-flow: column;
            gap: ${theme.spacing(1)};
            margin-bottom: ${theme.spacing(3)};
            margin-top: ${theme.spacing(1)};
          `}
        >
          {headerDetailsMap.map(({ title, value }) => (
            <div
              key={`${title}-${value}`}
              css={css`
                display: flex;
                flex-flow: row;
                gap: ${theme.spacing(3)};
              `}
            >
              <Text
                weight='semibold'
                size='medium'
                css={css`
                  width: 124px;
                  color: ${theme.colors.neutral50};
                  text-wrap: nowrap;
                `}
              >
                {title}
              </Text>
              <Text size='medium' color='default'>
                {value}
              </Text>
            </div>
          ))}
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          {viewType !== 'paused' && (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                align-items: start;
                width: 100%;
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              {dataHeadersMap.map(({ key, value }) => (
                <Text
                  key={key}
                  weight='bold'
                  css={css`
                    font-size: ${theme.fontSize(18)};
                    visibility: ${key === 'headers' ? 'hidden' : 'visible'};
                    width: ${theme.spacing(21)};
                    text-align: start;
                    margin-left: ${key === 'new' && theme.spacing(5)};
                  `}
                >
                  {value}
                </Text>
              ))}
            </div>
          )}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${theme.spacing(1)};
            `}
          >
            {dataMap.map(({ key, header, fromValue, toValue }) => (
              <div
                key={key}
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: start;
                  align-items: start;
                  width: 100%;
                  height: max-content;
                `}
              >
                <Text
                  weight='semibold'
                  size='medium'
                  css={css`
                    color: ${theme.colors.neutral50};
                    width: ${theme.spacing(21)};
                  `}
                >
                  {header}
                </Text>
                {viewType === 'paused' ? (
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: ${theme.spacing(1)};
                    `}
                  >
                    {key !== 'planNextDue' ? (
                      <Text size='medium'>{toValue}</Text>
                    ) : (
                      <PaymentPlanStatusChip paymentPlanStatus={toData?.status} />
                    )}
                  </div>
                ) : (
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                    `}
                  >
                    <Text
                      size='medium'
                      id={`previous-${key}`}
                      css={css`
                        width: ${theme.spacing(21)};
                      `}
                    >
                      {fromValue}
                    </Text>
                    <div
                      css={css`
                        display: flex;
                        flex-direction: row;
                        gap: ${theme.spacing(3)};
                      `}
                      id={`new-${key}`}
                    >
                      <ForwardIcon
                        size={16}
                        css={css`
                          visibility: ${fromValue === toValue ? 'hidden' : 'visible'};
                        `}
                      />
                      <Text
                        size='medium'
                        css={
                          fromValue === toValue
                            ? css`
                                color: ${theme.colors.neutral50};
                                background-color: 'none';
                                font-weight: ${theme.font.weight.regular};
                                width: ${theme.spacing(21)};
                              `
                            : css`
                                color: ${theme.font.colors.primary};
                                background-color: ${theme.colors.primary10};
                                font-weight: ${theme.font.weight.bold};
                                width: ${theme.spacing(21)};
                              `
                        }
                      >
                        {toValue}
                      </Text>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      {viewType === 'confirm' && handleUpdatePaymentPlan ? (
        <Modal.Actions
          css={{ button: { width: 'fit-content' }, justifyContent: 'end', marginTop: theme.spacing(2) }}
          onPrimaryClick={handleUpdatePaymentPlan}
          primaryLabel={t('Confirm Changes')}
          onSecondaryClick={onClose}
          secondaryLabel={t('Continue Editing')}
        />
      ) : (
        <Modal.Actions
          css={{ button: { width: 'fit-content' }, justifyContent: 'end', marginTop: theme.spacing(2) }}
          onPrimaryClick={onClose}
          primaryLabel={t('Done')}
        />
      )}
    </>
  );
};
