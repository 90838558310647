import { useState } from 'react';
import { useQuery } from 'react-query';
import { FormsDigitization, FormsQueryKeys } from '@frontend/api';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';

export const useOnboardCompany = (locationId: string) => {
  const alert = useAlert();
  const { t } = useTranslation('forms');

  const [hasOnboardingError, setHasOnboardingError] = useState(false);

  const { isLoading } = useQuery(
    [FormsQueryKeys.onboarding, locationId],
    () => FormsDigitization.API.onboardCompany(locationId),
    {
      cacheTime: 0,
      staleTime: 0,
      onSettled: (data) => {
        if (data !== 200) {
          setHasOnboardingError(true);
          alert.error(t('forms onboard failed'));
          return;
        }

        setHasOnboardingError(false);
      },
      onError: () => {
        setHasOnboardingError(true);
        alert.error(t('forms onboard failed'));
      },
    }
  );

  return {
    isLoading,
    isError: hasOnboardingError,
  };
};
