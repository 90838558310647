import { FC, ReactNode, useRef } from 'react';
import { useFeatureUpgradeDetailsModalContext } from '../context';
import { FeatureCard } from './FeatureCard';
import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Text, TextLink } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { BannerTextContent } from './BannerTextContent';
import { VideoTextContent } from './VideoTextContent';

interface ScrollableContentProps {
  children: ReactNode[];
}

interface ScrollableContentComposition extends FC<ScrollableContentProps> {
  VideoText: typeof VideoTextContent;
  FeatureCard: typeof FeatureCard;
  BannerText: typeof BannerTextContent;
}

const CONTENT_HEIGHT = {
  small: 500,
  medium: 578,
};

export const ScrollableContent: ScrollableContentComposition = ({ children }) => {
  const {
    actionPosition,
    isTitleSectionVisible,
    isMultiUpgrade,
    isLearnMoreHidden,
    onScheduleCallClick,
    setIsTitleSectionVisible,
    setIsLearnMoreHidden,
    setActionPosition,
  } = useFeatureUpgradeDetailsModalContext();

  const { t } = useTranslation('featurePromotion', { keyPrefix: 'feature-upgrade-details-modal' });
  const scrollableContentRef = useRef<HTMLDivElement>(null);

  const handleScrollContent = () => {
    if (scrollableContentRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = scrollableContentRef.current;
      const scrollNormalizedValue = scrollTop / (scrollHeight - clientHeight);
      setIsTitleSectionVisible(scrollNormalizedValue < 0.1);
      setIsLearnMoreHidden(scrollNormalizedValue > 0.1);
      setActionPosition(scrollNormalizedValue < 0.95 ? 'bottom' : 'raised');
    }
  };

  const scrollToNextContent = () => {
    if (scrollableContentRef.current) {
      scrollableContentRef.current.querySelector('#content_1')?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  if (!children) {
    return null;
  }

  return (
    <>
      <div css={contentContainerStyles}>
        <div
          css={[contentStyles, { maxHeight: isTitleSectionVisible ? CONTENT_HEIGHT.small : CONTENT_HEIGHT.medium }]}
          ref={scrollableContentRef}
          onScroll={handleScrollContent}
        >
          {children.map((contentPart, index) => (
            <div
              key={index}
              css={[contentPartStyles, { minHeight: index === 0 ? CONTENT_HEIGHT.small : CONTENT_HEIGHT.medium }]}
              id={`content_${index}`}
            >
              {contentPart}
            </div>
          ))}
        </div>
        {children.length > 1 && (
          <div css={[learnMoreStyles, isLearnMoreHidden && opacityHiddenStyles]} onClick={scrollToNextContent}>
            <Text color='primary'>{t('Learn More')}</Text>
            <Icon name='caret-down-small' color='primary' size={14} />
          </div>
        )}
      </div>
      {actionPosition === 'bottom' && isMultiUpgrade && (
        <Trans t={t}>
          <Text>
            There are multiple locations eligible for upgrade or a free trial. Select the locations you want to upgrade
            and choose from the options below.
            <TextLink as='span' onClick={onScheduleCallClick}>
              {' Call Weave '}
            </TextLink>
            for more information.
          </Text>
        </Trans>
      )}
    </>
  );
};

const contentContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.colors.secondary.seaweed5,
  borderRadius: '16px',
  paddingTop: theme.spacing(2),
  overflowY: 'auto',
});

const contentStyles = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  padding: theme.spacing(0, 2),
  gap: theme.spacing(3),
  overflowY: 'auto',
  transition: 'max-height 0.5s ease',
});

const contentPartStyles = css({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
});

const learnMoreStyles = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  padding: theme.spacing(0.5, 0),
  opacity: 1,
  transition: 'opacity 0.5s ease',
});

const opacityHiddenStyles = css({
  opacity: 0,
  pointerEvents: 'none',
});

ScrollableContent.FeatureCard = FeatureCard;
ScrollableContent.VideoText = VideoTextContent;
ScrollableContent.BannerText = BannerTextContent;
