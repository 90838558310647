import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate, useMatch } from '@tanstack/react-location';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { ScheduleRequestsApi, ScheduleTypes } from '@frontend/api-schedule';
import { ServiceProvidersTypes } from '@frontend/api-service-providers';
import { WeaveLogo } from '@frontend/assets';
import { Page } from '@frontend/components';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useQuery } from '@frontend/react-query-helpers';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Heading, SpinningLoader, Stepper, Text } from '@frontend/design-system';
import { getValidScheduleRequestSourceEnum } from '../../../utils';
import ApptTypeSelect from './appt-type-select';
import { InfoForm } from './info-form';
import LocationSelect from './location-select';
import ProviderSelect from './ProviderSelect/provider-select';
import Success from './success';

const { getLocation, getApptTypes, getProviders } = ScheduleRequestsApi;

dayjs.extend(isoWeek);
dayjs.extend(isBetween);

interface Props {
  locationId: string;
  renderAppointmentTypePaymentsModule?: (paymentAmount: number) => JSX.Element;
  isPaymentSuccess?: boolean;
  shouldShowPaymentModule?: boolean;
  paymentTransactionId?: string;
}

const isValid = (formDetails?: ScheduleTypes.FormDetails) => {
  if (!formDetails?.formComplete) {
    return true;
  }
  return !!formDetails?.formErrors && !!Object.keys(formDetails?.formErrors).length;
};

export const RequestAppointment = ({
  locationId,
  renderAppointmentTypePaymentsModule,
  isPaymentSuccess = false,
  shouldShowPaymentModule = false,
  paymentTransactionId = '',
}: Props) => {
  const match = useMatch();
  const { t } = useTranslation('schedule');
  const navigate = useNavigate();
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });
  const [selectedLocation, setSelectedLocation] = useState<ScheduleTypes.ChildrenLocation>();
  const [hasChildren, setHasChildren] = useState<boolean>(false);
  const [selectedApptType, setSelectedApptType] = useState<AppointmentTypesTypes.AppointmentType>();
  const [selectedApptTypeProviders, setSelectedApptTypeProviders] = useState<ServiceProvidersTypes.ServiceProvider[]>();
  const [selectedProvider, setSelectedProvider] = useState<ServiceProvidersTypes.ServiceProvider | string>();
  const [selectedDateTime, setSelectedDateTimes] = useState<string[]>([]);
  const [formDetails, setFormDetails] = useState<ScheduleTypes.FormDetails>();
  const [error, setError] = useState<string | undefined>();
  const [completed, setCompleted] = useState(false);
  const [weekInView, setWeekInView] = useState('');
  const [availableOpenings, setAvailableOpenings] = useState<number>(0);

  const bookingSource = getValidScheduleRequestSourceEnum((match?.search?.source || '') as string);

  const apptLeadTimeDate = useMemo(() => {
    const apptLeadTime = (selectedApptType && selectedApptType.requestBufferDuration / 86400) || 0;
    return dayjs().add(apptLeadTime, 'day').startOf('day').format();
  }, [selectedApptType, selectedApptType?.requestBufferDuration]);

  useEffect(() => {
    setWeekInView(apptLeadTimeDate);
  }, [apptLeadTimeDate]);

  const {
    data: locationData,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ['locations', locationId],
    queryFn: () => getLocation(locationId),
    retry: 1,
  });

  const currentLocationId = useMemo(() => {
    if (selectedLocation?.locationId) {
      return selectedLocation.locationId;
    }
    return locationId;
  }, [selectedLocation]);

  useEffect(() => {
    if (locationData) {
      const { childrenLocations } = locationData;
      if (childrenLocations?.length) {
        setHasChildren(true);
      }
    }
  }, [locationData]);

  const { data: apptTypes } = useQuery({
    queryKey: ['appointmentTypes', currentLocationId],
    queryFn: () => getApptTypes(currentLocationId),
    retry: 1,
  });

  const { data: providers } = useQuery({
    queryKey: ['providers', currentLocationId],
    queryFn: () => getProviders(currentLocationId),
    retry: 1,
  });

  const selectLocation = (location: ScheduleTypes.ChildrenLocation) => {
    setSelectedLocation(location);
  };

  const selectApptType = (appt: AppointmentTypesTypes.AppointmentType) => {
    const { id } = appt;
    setSelectedApptType(appt);
    const availableProviders =
      apptTypes
        ?.find((appt) => {
          return appt.id === id;
        })
        ?.providerAssets?.filter((provider) => {
          return provider.available;
        })
        ?.map((provider) => {
          return providers?.find((prov) => {
            return prov.id === provider.id;
          });
        }) || [];
    setSelectedApptTypeProviders(availableProviders as ServiceProvidersTypes.ServiceProvider[]);
  };

  const clearSelectedLocation = () => {
    setSelectedLocation(undefined);
  };

  const clearSelectedApptType = () => {
    setSelectedApptType(undefined);
  };

  const clearSelectedProvider = () => {
    setSelectedProvider(undefined);
    setSelectedDateTimes([]);
  };

  const submitRequest = async () => {
    if (!formDetails?.patientInfo || !!formDetails.sent) {
      return;
    }
    const input = document.getElementsByTagName('input');
    const newValues = Object.values(input).reduce((values, field) => {
      if (field.required && field.value) {
        const name = field.name as keyof ScheduleTypes.PatientInfo;
        if (!formDetails?.patientInfo?.[name]) {
          return { ...formDetails?.patientInfo, ...values, [field.name]: field.value };
        }
      }
      return values;
    }, {} as Partial<ScheduleTypes.PatientInfo>);
    const completeFormValues = !!Object.entries(newValues).length ? newValues : formDetails?.patientInfo;

    const requestDetails = {
      dateTimeRequest: selectedDateTime,
      patientInfo: completeFormValues,
      apptType: selectedApptType,
      provider: selectedProvider,
    } as ScheduleTypes.FormDetails;

    const request = await ScheduleRequestsApi.requestAppointment(
      currentLocationId,
      requestDetails,
      paymentTransactionId,
      bookingSource
    );

    if (request.status === 'success') {
      setFormDetails?.({ ...formDetails, sent: dayjs().toISOString() });
      setCompleted(true);
    } else if (request.status === 'error') {
      console.error(request.message);
      setError(request.message);
    }
  };

  const reset = () => {
    setCompleted(false);
    clearSelectedApptType();
    clearSelectedProvider();
    setWeekInView('');
    clearSelectedLocation();
    setFormDetails({});
  };

  const displayApptType = () => {
    return (
      <Text as='span' css={styles.cardTitle}>
        {t('Appointment Type')}
        <Text as='span' css={styles.dot}>
          &bull;
        </Text>
        <Text as='span' weight='light' color='light'>
          {t('{{apptType}}', { apptType: selectedApptType?.name })}
        </Text>
      </Text>
    );
  };

  const displayProviderSelection = () => {
    let provider = 'No Preference';
    if (typeof selectedProvider !== 'string') {
      provider =
        `${selectedProvider?.publicDisplayName}` || `${selectedProvider?.firstName} ${selectedProvider?.lastName}`;
    }
    return (
      <Text as='span' css={styles.cardTitle}>
        {t('Provider & Time')}
        {!!selectedProvider && (
          <>
            <Text as='span' css={styles.dot}>
              &bull;
            </Text>
            <Text as='span' weight='light' color='light'>
              {t('{{provider}}', { provider })}
            </Text>
            <Text as='span' css={styles.dot}>
              &bull;
            </Text>
            {selectedDateTime?.map((date, index, array) => {
              const formattedDate = formatDate(date, 'ddd, MMM D h:mma');
              return (
                <Text key={`${index}-${formattedDate}`} as='span' weight='light' color='light'>
                  {t('{{date }}', { date: formattedDate })}
                  {index + 1 !== array.length && ', '}
                </Text>
              );
            })}
          </>
        )}
      </Text>
    );
  };

  // Required number of appointment times. If the selected appointment type has a minOpeningsPermitted, use that value. If it doesn't then try to find one that does and use that value. If none of them have a minOpeningsPermitted, default to 3.
  const slots = selectedApptType?.minOpeningsPermitted
    ? selectedApptType?.minOpeningsPermitted
    : apptTypes?.find((appt) => {
        return !!appt.minOpeningsPermitted;
      })?.minOpeningsPermitted ?? 3;

  const isValidSlotSelection =
    selectedDateTime.length === slots || (!!availableOpenings && selectedDateTime.length === availableOpenings);

  const getPatientInfoNextBtnHandler = () => {
    if (!appointmentPaymentAmount || !showPaymentsModule) return submitRequest;
    return;
  };

  useEffect(() => {
    clearSelectedProvider();
  }, [selectedApptType]);

  const appointmentPaymentAmount = useMemo<number>(() => {
    if (selectedApptType?.hasGlobalBookingDeposit && selectedApptType?.globalBookingDepositAmount) {
      return selectedApptType?.globalBookingDepositAmount;
    } else if (selectedApptType?.hasBookingDeposit && selectedApptType?.bookingDepositAmount) {
      return selectedApptType?.bookingDepositAmount;
    }
    return 0;
  }, [selectedApptType]);

  useEffect(() => {
    if (isError) {
      navigate({ to: '/error', replace: true });
    }
  }, [isError]);

  if (isLoading) {
    return (
      <Page css={styles.spinningLoader}>
        <SpinningLoader size='large' />
      </Page>
    );
  }

  const showPaymentsModule =
    shouldShowPaymentModule &&
    !!selectedApptType &&
    !!appointmentPaymentAmount &&
    !!renderAppointmentTypePaymentsModule;

  return (
    <Page css={styles.page}>
      {!isError && (
        <>
          <div css={styles.pageHeader}>
            {locationData?.officeName ? (
              <Heading as='h1' className='request-office-name'>
                {t('{{officeName}}', { officeName: locationData?.officeName })}
              </Heading>
            ) : (
              <span className='request-office-logo'>
                {/* Placeholder until we get the office logo */}
                <WeaveLogo />
              </span>
            )}
            <Heading as='h1' className='request-title'>
              {t('Request an Appointment')}
            </Heading>
          </div>
          {completed ? (
            <Success reset={reset} setFormDetails={setFormDetails} formDetails={formDetails} />
          ) : (
            <Stepper maxWidth={880} isHorizontal={isMobile} css={styles.stepper(isMobile)}>
              {hasChildren && (
                <Stepper.Card stepValue={selectedLocation?.officeName}>
                  <Stepper.Title>{t('Select a Location')}</Stepper.Title>
                  <Stepper.Content>
                    <LocationSelect
                      locations={locationData?.childrenLocations}
                      selectLocation={selectLocation}
                      selectedLocation={selectedLocation}
                    />
                  </Stepper.Content>
                  <Stepper.ButtonBar>
                    <Stepper.ClearButton clearStep={clearSelectedLocation}>{t('Clear')}</Stepper.ClearButton>
                    <Stepper.NextButton isValid={!!selectedLocation?.locationId} />
                  </Stepper.ButtonBar>
                </Stepper.Card>
              )}
              <Stepper.Card stepValue={displayApptType()}>
                <Stepper.Title>{t("Let's get you scheduled")}</Stepper.Title>
                <Stepper.Content>
                  <ApptTypeSelect
                    selectApptType={selectApptType}
                    apptTypes={apptTypes}
                    selectedApptType={selectedApptType}
                  />
                </Stepper.Content>
                <Stepper.ButtonBar>
                  <Stepper.ClearButton clearStep={clearSelectedApptType}>{t('Clear')}</Stepper.ClearButton>
                  {hasChildren && <Stepper.PreviousButton />}
                  <Stepper.NextButton isValid={!!selectedApptType?.id} />
                </Stepper.ButtonBar>
              </Stepper.Card>
              <Stepper.Card stepValue={displayProviderSelection()}>
                <Stepper.Title>{t('Choose your provider & time')}</Stepper.Title>
                <Stepper.Content>
                  <ProviderSelect
                    locationId={currentLocationId}
                    selectedApptType={selectedApptType}
                    selectedApptTypeProviders={selectedApptTypeProviders}
                    selectedProvider={selectedProvider}
                    setSelectedProvider={setSelectedProvider}
                    selectedDateTime={selectedDateTime}
                    setSelectedDateTimes={setSelectedDateTimes}
                    clearSelected={clearSelectedProvider}
                    apptLeadTimeDate={apptLeadTimeDate}
                    weekInView={weekInView}
                    setWeekInView={setWeekInView}
                    reqNumApptTimes={slots}
                    setAvailableOpenings={setAvailableOpenings}
                  />
                </Stepper.Content>
                <Stepper.ButtonBar>
                  <Stepper.ClearButton
                    clearStep={() => {
                      clearSelectedProvider();
                      setWeekInView(apptLeadTimeDate);
                    }}
                  >
                    {t('Clear')}
                  </Stepper.ClearButton>
                  <Stepper.PreviousButton />
                  <Stepper.NextButton isValid={isValidSlotSelection} />
                </Stepper.ButtonBar>
              </Stepper.Card>
              <Stepper.Card>
                <Stepper.Title icon={<Icon name='forms' />}>{t('Patient info')}</Stepper.Title>
                <Stepper.Content>
                  <InfoForm
                    locationId={currentLocationId}
                    officeName={locationData?.officeName}
                    formDetails={formDetails || {}}
                    setFormDetails={setFormDetails}
                  />
                </Stepper.Content>
                <Stepper.ButtonBar helperText={error}>
                  <Stepper.PreviousButton />
                  <Stepper.NextButton isValid={!isValid(formDetails)} onSubmit={getPatientInfoNextBtnHandler()}>
                    {renderAppointmentTypePaymentsModule && appointmentPaymentAmount ? t('Next') : t('Submit Request')}
                  </Stepper.NextButton>
                </Stepper.ButtonBar>
              </Stepper.Card>
              {showPaymentsModule && (
                <Stepper.Card stepValue={displayProviderSelection()}>
                  <Stepper.Title icon={<Icon name='credit-card' />}>
                    {formDetails?.patientInfo?.firstName && formDetails?.patientInfo?.lastName
                      ? t('{{firstName}} {{lastName}} • ${{amount}}', {
                          firstName: formDetails?.patientInfo?.firstName ?? '',
                          lastName: formDetails?.patientInfo?.lastName ?? '',
                          amount: appointmentPaymentAmount,
                        })
                      : t('Payment')}
                  </Stepper.Title>
                  <Stepper.Content>
                    {selectedApptType &&
                      appointmentPaymentAmount &&
                      renderAppointmentTypePaymentsModule(appointmentPaymentAmount)}
                  </Stepper.Content>
                  <Stepper.ButtonBar>
                    <Stepper.PreviousButton />
                    <Stepper.NextButton isValid={isValidSlotSelection && isPaymentSuccess} onSubmit={submitRequest}>
                      {t('Submit Request')}
                    </Stepper.NextButton>
                  </Stepper.ButtonBar>
                </Stepper.Card>
              )}
            </Stepper>
          )}
        </>
      )}
    </Page>
  );
};

const styles = {
  page: css({
    maxWidth: 880,
    margin: 'auto',
    padding: theme.spacing(2),
    '.request-office-logo, .request-office-name': {
      fontSize: theme.fontSize(24),
      margin: theme.spacing(0),
      '@media (max-width: 699px)': {
        fontSize: theme.fontSize(20),
        marginRight: theme.spacing(1),
      },
    },
    '.request-title': {
      fontSize: theme.fontSize(24),
      color: theme.colors.neutral50,
      margin: theme.spacing(0),
      textAlign: 'right',
      '@media (max-width: 699px)': {
        fontSize: theme.fontSize(20),
      },
    },
  }),
  stepper: (isMobile: boolean) =>
    css({
      margin: isMobile ? theme.spacing(-1) : theme.spacing(1),
    }),
  pageHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.colors.neutral20}`,
  }),
  cardTitle: css({
    fontSize: theme.fontSize(20),
    margin: theme.spacing(0),
    width: 'fit-content',
    span: {
      fontWeight: 'normal',
    },
    '@media (max-width: 699px)': {
      fontSize: theme.fontSize(16),
    },
  }),
  cardWidth: css({
    maxWidth: 692,
  }),
  dot: css({
    margin: theme.spacing(1),
  }),
  spinningLoader: css({
    textAlign: 'center',
    width: '100%',
  }),
};
