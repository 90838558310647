import mitt, { Handler } from 'mitt';
import { useEffect } from 'react';
import { GetWebsocketEventPayload, WebsocketEventMethods } from './events';

export type GetWebsocketEventHandler<T extends WebsocketEventMethods> = Handler<GetWebsocketEventPayload<T>>;
export type WebsocketEvents = {
  [K in WebsocketEventMethods]: GetWebsocketEventPayload<K>;
};

/**
 * This is deliberately not exported in an attempt to keep any usage of the publisher contained in the react tree.
 * It can be obtained by using the hook below
 */
const publisher = mitt<WebsocketEvents>();

/**
 * This is a specifically made pubsub for the weave websocket events. An improved version of this might ingest the publisher/emitter,
 * rather than hard coding it here, but this hard-coded version should work well for the first iteration
 */
export const useWebsocketEventSubscription = <T extends WebsocketEventMethods, H extends GetWebsocketEventHandler<T>>(
  event: T,
  handler: H
) => {
  useEffect(() => {
    publisher.on(event, handler);
    return () => {
      publisher.off(event, handler);
    };
  }, [handler, event]);
  return {
    publisher,
    event,
  };
};

export const useWebsocketEventPublisher = () => {
  return publisher;
};
