import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { CallInformation } from '../components/call-information';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { Button, Page } from '@frontend/generic-dialpad-accessories';
import { useSoftphoneRouter } from '../providers/softphone-router-provider';
import { isEstablishedCall, isTerminatedCall } from '../types';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Divider } from '@frontend/generic-dialpad-accessories';
import { HeldCall } from '../components/hold/held-call';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';

export const PageCallHome = () => {
  const { t } = useTranslation('softphone');
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const establishedCalls = useSoftphoneCallState((ctx) => ctx.establishedCalls);
  const setCurrentRoute = useSoftphoneRouter((ctx) => ctx.setSingleCallRoute);
  const disabled = !primaryCall || !isEstablishedCall(primaryCall) || isTerminatedCall(primaryCall);
  const otherHeldCalls = establishedCalls.filter((call) => !!call.onHoldSince && primaryCall?.id !== call.id);
  const mergeCalls = useSoftphoneCallActions((ctx) => ctx.mergeCalls);
  const canMerge = establishedCalls.length > 1;

  return (
    <Page>
      <PageHeaderWraps hasBackButton={false}>
        <CallInformation
          displayName={primaryCall?.remoteParty.displayName}
          uri={primaryCall?.remoteParty.uri ?? ''}
          hideCallDuration
        />
      </PageHeaderWraps>
      <Page.Body
        css={css`
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin: ${theme.spacing(3, 0)};
        `}
      >
        <Button.Round
          disabled={disabled}
          onClick={() => setCurrentRoute('park')}
          size='medium'
          iconName={'phone-parked-calls'}
          label={t('Park')}
        ></Button.Round>
        <Button.Round
          trackingId='softphone-active-transfer'
          disabled={disabled}
          onClick={() => setCurrentRoute('transfer')}
          size='medium'
          iconName={'phone-outgoing'}
          label={t('Transfer')}
        ></Button.Round>
        {canMerge && (
          <Button.Round
            disabled={disabled}
            onClick={() => {
              mergeCalls(establishedCalls);
            }}
            size='medium'
            iconName={'merge'}
            label={t('Merge')}
          ></Button.Round>
        )}
        <Button.Round
          trackingId='softphone-active-keypad'
          disabled={disabled}
          onClick={() => setCurrentRoute('keypad')}
          size='medium'
          iconName={'dial'}
          label={t('Keypad')}
        ></Button.Round>
        <Button.Round
          disabled={disabled}
          onClick={() => {
            setCurrentRoute('add-call');
          }}
          size='medium'
          iconName={'plus'}
          label={t('Add Call')}
        ></Button.Round>
      </Page.Body>
      <Page.Footer>
        {!!otherHeldCalls.length && (
          <>
            <Divider />
            <Text
              color='white'
              css={css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              {t('On Local Hold')}
            </Text>
            {otherHeldCalls.map((call) => (
              <HeldCall call={call}></HeldCall>
            ))}
          </>
        )}
      </Page.Footer>
    </Page>
  );
};
