import { PracticeAnalyticsTypes } from '../../practice';
import { getTrends } from './mock-generators';

export const monthlyTrendData = (): PracticeAnalyticsTypes.MonthlyTrendHistoricalData => ({
  location: {
    activePatients: {
      historicalData: getTrends<PracticeAnalyticsTypes.HistoricalDataTotal[]>(
        ['total'],
        [[2082], [2036], [2138], [2017], [2119], [2044], [2062], [2123], [2107], [2005], [2038], [2111]]
      ),
    },
    activePatientsScheduled: {
      historicalData: getTrends<PracticeAnalyticsTypes.HistoricalDataPercentage[]>(
        ['percentage'],
        [[0.46], [0.46], [0.44], [0.43], [0.44], [0.44], [0.45], [0.48], [0.49], [0.51], [0.53], [0.56]]
      ),
    },
    cancellations: {
      historicalData: getTrends<PracticeAnalyticsTypes.HistoricalDataPercentage[]>(
        ['percentage'],
        [[0.14], [0.09], [0.12], [0.08], [0.09], [0.07], [0.07], [0.08], [0.09], [0.07], [0.1], [0.16]]
      ),
    },
    hygieneReappointment: {
      historicalData: getTrends<PracticeAnalyticsTypes.HistoricalDataPercentageSameDay[]>(
        ['percentageSameDay'],
        [[0.66], [0.62], [0.62], [0.58], [0.69], [0.83], [0.83], [0.81], [0.82], [0.84], [0.92], [0.91]]
      ),
    },
    hygieneTreatmentPlan: {
      historicalData: getTrends<PracticeAnalyticsTypes.HistoricalDataTreatment[]>(
        ['diagnosedPercent', 'acceptedPercent'],
        [
          [0.9, 0.88],
          [0.87, 0.85],
          [0.86, 0.83],
          [0.86, 0.83],
          [0.88, 0.89],
          [0.91, 0.91],
          [0.91, 0.88],
          [0.88, 0.88],
          [0.91, 0.86],
          [0.91, 0.87],
          [0.93, 0.89],
          [0.89, 0.92],
        ]
      ),
    },
    newPatients: {
      historicalData: getTrends<PracticeAnalyticsTypes.HistoricalDataProduction[]>(
        ['total', 'production'],
        [
          [48, 16848],
          [62, 21762],
          [52, 18252],
          [50, 17550],
          [64, 22464],
          [41, 14391],
          [38, 13338],
          [39, 13689],
          [25, 8775],
          [29, 10179],
          [32, 11232],
          [16, 5616],
        ]
      ),
    },
    recapturedPatients: {
      historicalData: getTrends<PracticeAnalyticsTypes.HistoricalDataProduction[]>(
        ['total', 'production'],
        [
          [83, 22908],
          [104, 28184],
          [114, 31464],
          [96, 26016],
          [134, 36984],
          [125, 33875],
          [141, 38916],
          [134, 36314],
          [113, 31188],
          [130, 35230],
          [142, 39192],
          [156, 42276],
        ]
      ),
    },
    restorativeTreatmentPlan: {
      historicalData: getTrends<PracticeAnalyticsTypes.HistoricalDataTreatment[]>(
        ['diagnosedPercent', 'acceptedPercent'],
        [
          [0.31, 0.53],
          [0.31, 0.55],
          [0.3, 0.58],
          [0.37, 0.61],
          [0.31, 0.65],
          [0.28, 0.67],
          [0.34, 0.6],
          [0.34, 0.66],
          [0.34, 0.66],
          [0.36, 0.68],
          [0.39, 0.67],
          [0.35, 0.71],
        ]
      ),
    },
  },
});
