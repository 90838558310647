import { css } from '@emotion/react';
import { IconButton, Modal, ModalControlResponse, XIcon } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FC } from 'react';
import { useFeaturePromotionPage } from '../../hooks/useFeaturePromotionPage';

interface FeaturePromotionModalProps {
  modalControls: ModalControlResponse;
  feature: Feature;
  handleOpenUpgradeModal: () => void;
}

export const FeaturePromotionModal: FC<FeaturePromotionModalProps> = ({
  feature,
  handleOpenUpgradeModal,
  modalControls,
}) => {
  const { FeaturePromotionPage } = useFeaturePromotionPage(feature);

  if (!FeaturePromotionPage) {
    return null;
  }

  return (
    <Modal css={featurePromotionModalStyles} {...modalControls.modalProps}>
      <IconButton label='close' onClick={modalControls.closeModal} css={closeIconStyles}>
        <XIcon />
      </IconButton>
      <div css={featurePromotionContentStyles}>
        <FeaturePromotionPage mode='modal' handleOpenUpgradeModal={handleOpenUpgradeModal} />
      </div>
    </Modal>
  );
};

const featurePromotionModalStyles = css({
  position: 'relative',
  padding: theme.spacing(8, 5),
  maxWidth: '640px',
  overflowY: 'auto',
});

const featurePromotionContentStyles = css({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  padding: theme.spacing(0, 1),
});

const closeIconStyles = css({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
});
