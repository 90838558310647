import { FC } from 'react';
import { Channel } from 'stream-chat';
import { HideChannel, ArchiveChannel, DeleteChannel } from '../../common';
import { channelSettingsContainerStyle } from '../../common-styles';

interface ChannelSettingsProps {
  channel?: Channel;
  isArchived: boolean;
  isDm: boolean;
  isHidden: boolean;
}

export const ChannelSettings: FC<ChannelSettingsProps> = ({ isArchived, channel, isDm, isHidden }) => {
  return (
    <section css={channelSettingsContainerStyle}>
      {/* TODO: code commented intentionally, will pick this after feature discussion */}
      {/* <div className='channel-settings-item'>
        <Text weight='bold'>{t('Channel Reminders:')}</Text>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Text css={{ padding: theme.spacing(0.5, 0, 0, 0.5) }}>
            {t('Schedule reminders for important tasks and events to keep everyone on track.')}
          </Text>
          <TextLink>
            <Text color='primary' weight='bold' size='medium'>
              {t('Schedule')}
            </Text>
          </TextLink>
        </div>
      </div> */}
      {channel && isDm && <HideChannel className='channel-settings-item' isHidden={isHidden} channel={channel} />}
      {channel && !isDm && (
        <ArchiveChannel className='channel-settings-item' isArchived={isArchived} channel={channel} />
      )}
      {channel && !isDm && <DeleteChannel channel={channel} />}
    </section>
  );
};
