import { PracticeAnalyticsTypes } from '../../practice';
import { hygieneTreatmentPlanPatientsDetails } from './hygiene-treatment-plan';
import { generateNames, getRandomInt } from './mock-generators';
import { restorativeTreatmentPlanPatientsDetails } from './restorative-treatment-plan';

export const practitionerAnalysisSummary = (
  hygieneTreatmentPlan: PracticeAnalyticsTypes.HygieneTreatmentPlanResponse,
  restorativeTreatmentPlan: PracticeAnalyticsTypes.RestorativeTreatmentPlanResponse
): PracticeAnalyticsTypes.PractitionerAnalysisResponse => {
  return {
    location: {
      hygieneTreatmentPlan: {
        totals: {
          accepted: hygieneTreatmentPlan.location.hygieneTreatmentPlan?.totals?.accepted || 0,
          acceptedPercent: hygieneTreatmentPlan.location.hygieneTreatmentPlan?.totals?.acceptedPercent || 0,
          diagnosed: hygieneTreatmentPlan.location.hygieneTreatmentPlan?.totals?.diagnosed || 0,
          diagnosedPercent: hygieneTreatmentPlan.location.hygieneTreatmentPlan?.totals?.diagnosedPercent || 0,
          qualifiedVisits: hygieneTreatmentPlan.location.hygieneTreatmentPlan?.totals?.qualifiedVisits || 0,
          unscheduledTreatment: hygieneTreatmentPlan.location.hygieneTreatmentPlan?.totals?.unscheduledTreatment || 0,
        },
      },
      restorativeTreatmentPlan: {
        totals: {
          accepted: restorativeTreatmentPlan.location.restorativeTreatmentPlan?.totals?.accepted || 0,
          acceptedPercent: restorativeTreatmentPlan.location.restorativeTreatmentPlan?.totals?.acceptedPercent || 0,
          diagnosed: restorativeTreatmentPlan.location.restorativeTreatmentPlan?.totals?.diagnosed || 0,
          diagnosedPercent: restorativeTreatmentPlan.location.restorativeTreatmentPlan?.totals?.diagnosedPercent || 0,
          qualifiedVisits: restorativeTreatmentPlan.location.restorativeTreatmentPlan?.totals?.qualifiedVisits || 0,
          unscheduledTreatment:
            restorativeTreatmentPlan.location.restorativeTreatmentPlan?.totals?.unscheduledTreatment || 0,
        },
      },
    },
  };
};

const generatePractitionerTreatmentPlanTotal = (
  firstName: string,
  lastName: string
): PracticeAnalyticsTypes.PractitionerTreatmentPlanAnalytics => ({
  accepted: getRandomInt(50, 100),
  acceptedPercent: Math.random() * (0.6 - 0.8) + 0.8,
  diagnosed: getRandomInt(100, 200),
  diagnosedPercent: Math.random() * (0.5 - 0.2) + 0.2,
  practitionerName: `${firstName} ${lastName}`,
  qualifiedVisits: getRandomInt(200, 300),
  unscheduledTreatment: getRandomInt(50, 1000),
});

export const practitionerAnalysisDetails = (
  practitionerAnalysisSummary: PracticeAnalyticsTypes.PractitionerAnalysisResponse
): PracticeAnalyticsTypes.PractitionerAnalysisDetailsResponse => {
  return {
    location: {
      hygieneTreatmentPlan: {
        ...practitionerAnalysisSummary.location.hygieneTreatmentPlan,
        details: {
          patients: hygieneTreatmentPlanPatientsDetails(),
        },
      },
      restorativeTreatmentPlan: {
        ...practitionerAnalysisSummary.location.restorativeTreatmentPlan,
        details: {
          patients: restorativeTreatmentPlanPatientsDetails(),
        },
      },
      treatmentPlan: {
        practitionerTreatmentPlanAnalytics: {
          practitionerHygieneTreatmentPlanTotals: generateNames(10).map(({ FirstName, LastName }) =>
            generatePractitionerTreatmentPlanTotal(FirstName, LastName)
          ),
          practitionerRestorativeTreatmentPlanTotals: generateNames(10).map(({ FirstName, LastName }) =>
            generatePractitionerTreatmentPlanTotal(FirstName, LastName)
          ),
        },
      },
    },
  };
};
