import { FC, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { useTranslation } from '@frontend/i18n';
import { useControlledField, TextField, Text, RadioField, useAlert } from '@frontend/design-system';
import { useWritebackWidgetStore } from '../../../../../../providers';
import { FontClassToNameMapping } from '../../../../../../writeback-widget.constant';
import { TypedSignatureFont } from '../../../../../../writeback-widget.types';
import { useProviderSignature, useProviderReviewSteps } from '../../../../hooks';
import { containerStyle } from './types-signature.styles';

const FONT_OPTIONS: TypedSignatureFont[] = ['font1', 'font2', 'font3', 'font4'];

const TypedSignature: FC = () => {
  const { t } = useTranslation('forms');
  const alert = useAlert();
  const { storeSignature } = useProviderSignature();
  const { goToNextStep } = useProviderReviewSteps();
  const {
    setTypedSignatureFont,
    setTypedSignatureValue,
    typedSignatureFont,
    typedSignatureValue,
    saveSigntureTrigger,
    setSaveSignatureTrigger,
    activeSignatureTab,
    shouldSaveAsDefaultSignature,
  } = useWritebackWidgetStore([
    'typedSignatureFont',
    'typedSignatureValue',
    'setTypedSignatureFont',
    'setTypedSignatureValue',
    'saveSigntureTrigger',
    'setSaveSignatureTrigger',
    'activeSignatureTab',
    'shouldSaveAsDefaultSignature',
  ]);

  const typedSignatureProps = useControlledField({
    type: 'text',
    value: typedSignatureValue,
    onChange: (value: string) => {
      setTypedSignatureValue(value);
    },
  });

  const typedSignatureFontProps = useControlledField({
    type: 'radio',
    value: typedSignatureFont,
    onChange: (value: TypedSignatureFont) => {
      setTypedSignatureFont(value);
    },
  });

  const saveTypedSignature = useCallback(async () => {
    if (!typedSignatureValue) {
      alert.error(t('No signature typed.'));
      setSaveSignatureTrigger(false);
      return;
    }

    await storeSignature(
      {
        type: 'text',
        data: typedSignatureValue,
        font_type: FontClassToNameMapping[typedSignatureFont],
        timestamp: format(new Date(), 'MMMM d, yyyy hh:mm:ss'),
      },
      shouldSaveAsDefaultSignature
    );

    setSaveSignatureTrigger(false);
    goToNextStep();

    if (shouldSaveAsDefaultSignature) {
      // refetchDefaultSignature();
    }
  }, [
    typedSignatureValue,
    shouldSaveAsDefaultSignature,
    typedSignatureValue,
    setSaveSignatureTrigger,
    storeSignature,
    goToNextStep,
  ]);

  useEffect(() => {
    if (!saveSigntureTrigger || activeSignatureTab !== 'typed') {
      return;
    }

    saveTypedSignature();
  }, [activeSignatureTab, saveSigntureTrigger, saveTypedSignature]);

  return (
    <div css={containerStyle}>
      <TextField
        className={typedSignatureFontProps.value}
        {...typedSignatureProps}
        label=''
        name='signature-type'
        placeholder='Signature'
        autoComplete='off'
      />

      <Text className='text'>{t('Type Your Signature Above')}</Text>
      <RadioField {...typedSignatureFontProps} name='typeSignatureRadio' label=''>
        {FONT_OPTIONS.map((fontOption) => (
          <RadioField.Radio key={fontOption} value={fontOption}>
            <div className={`typed-signature ${fontOption}`}>{typedSignatureProps.value || 'Signature'}</div>
          </RadioField.Radio>
        ))}
      </RadioField>

      <link
        href='https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Fuzzy+Bubbles&family=Nothing+You+Could+Do&family=Caveat&display=swap'
        rel='stylesheet'
      />
    </div>
  );
};

export default TypedSignature;
