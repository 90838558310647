import { PracticeAnalyticsTypes } from '../../practice';
import { generateNames, getRandomDate, getRandomInt, requiredPatientInfoFields } from './mock-generators';

const cancellationsPatientsDetails = (): PracticeAnalyticsTypes.PatientInfo[] =>
  generateNames(15).map((name, id) => {
    const aptType = getRandomInt(1, 2) === 1 ? 'restorative' : 'hygiene';
    const nextApt = aptType === 'hygiene' ? getRandomDate(100, 140) : getRandomDate(7, 30);
    const hasRescheduled = getRandomInt(1, 3) === 1;

    return {
      ...requiredPatientInfoFields,
      ...name,
      cancelledAppointmentDate: getRandomDate(-30, -2),
      cancelledAppointmentType: aptType,
      id: `${id}`,
      nextAppointmentDate: !hasRescheduled && getRandomInt(0, 3) === 1 ? '' : nextApt,
      productionAmount: getRandomInt(200, 500),
      rescheduledAppointmentDate: hasRescheduled ? null : nextApt,
    };
  });

export const cancellations = (): PracticeAnalyticsTypes.CancellationsResponse => {
  const totalPersonsCount = getRandomInt(200, 300);
  const cancelled = getRandomInt(40, 70);
  const rescheduled = getRandomInt(0, 40);
  const cancelledUnscheduled = cancelled - rescheduled;

  const totalProductionRisk = getRandomInt(2000, 3000);
  const recapturedProduction = rescheduled ? getRandomInt(500, 1000) : 0;
  const unscheduledOpportunity = totalProductionRisk - recapturedProduction;

  return {
    location: {
      missedAppointments: {
        benchmarks: [
          {
            value: 0.09,
            label: 'Top',
          },
          {
            value: 0.15,
            label: 'Avg.',
          },
        ],
        details: {
          patients: cancellationsPatientsDetails(),
        },
        totals: {
          cancelled,
          cancelledUnscheduled,
          percentage: Math.random(),
          recapturedProduction,
          rescheduled,
          totalPersonsCount,
          totalProduction: totalProductionRisk + 3000, // Increase by 3000 to make it more realistic
          totalProductionRisk,
          unscheduledOpportunity,
        },
      },
    },
  };
};
