import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { PersonsV3 } from '@frontend/api-person';
import { useLocationFilter } from '@frontend/location-filter';
import { useDebouncedValue, useFormField } from '@frontend/design-system';
import { PersonSelectionListFiltersProps } from '../components';

type UseSelectRecipientListArgs = {
  groupIds?: string[];
  shouldFilterWithoutContactInfo?: boolean;
};

type UseSelectRecipientListResult = {
  filtersProps: PersonSelectionListFiltersProps;
  persons: Person[];
  isLoading: boolean;
  loadMore: () => void;
};

export const useSelectRecipientList = ({
  groupIds,
  shouldFilterWithoutContactInfo = true,
}: UseSelectRecipientListArgs): UseSelectRecipientListResult => {
  const locationFilter = useLocationFilter({
    selectableLocationIds: groupIds,
  });
  const searchFieldProps = useFormField({
    type: 'text',
  });
  const debouncedSearch = useDebouncedValue(searchFieldProps.value, 300);
  const personsQuery = PersonsV3.PersonQueries.usePersonSearchLegacyInfiniteQuery(
    {
      locationIds: locationFilter.filteredLocationIds,
      search: debouncedSearch,
      contactInfoTypeFilter: shouldFilterWithoutContactInfo
        ? [
            ContactType_Enum.PHONE_HOME,
            ContactType_Enum.PHONE_MOBILE,
            ContactType_Enum.PHONE_WORK,
            ContactType_Enum.UNSPECIFIED,
          ]
        : [],
    },
    {
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => ({
            ...page,
            persons: shouldFilterWithoutContactInfo
              ? page.persons.filter((person) => !!person.contactInfo?.length)
              : page.persons,
          })),
        };
      },
    }
  );
  const persons = personsQuery.data?.pages.flatMap((page) => page.persons) ?? [];

  return {
    filtersProps: {
      locationFilterButtonProps: locationFilter.buttonProps,
      searchFieldProps,
      hideLocationFilter: locationFilter.buttonProps.selectableLocationIds.length < 2,
    },
    isLoading: personsQuery.isLoading,
    persons,
    loadMore: personsQuery.fetchNextPage,
  };
};
