import { useMutation } from '@frontend/react-query-helpers';
import { UseMutationOptions } from 'react-query';
import { CreateReviewInvitationIO } from './types';
import { SchemaReviewsInvitationService } from './service';

export const mutationKeys = {
  baseKey: ['reviews-invitation'],
  createReviewInvitation: () => [...mutationKeys.baseKey, 'create-review-invitation'],
};

export const useReviewInvitationMutation = <E = unknown>(
  options?: UseMutationOptions<
    CreateReviewInvitationIO['output'],
    E,
    CreateReviewInvitationIO['input'] & { locationId: string }
  >
) =>
  useMutation({
    mutationKey: mutationKeys.createReviewInvitation(),
    mutationFn: ({ locationId, ...req }) =>
      SchemaReviewsInvitationService.CreateReviewInvitation(req, {
        headers: {
          'Location-Id': locationId,
        },
      }),
    ...options,
  });
