import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { PhoneMediaApi } from '@frontend/api-phone-media';
import { getWeaveToken } from '@frontend/auth-helpers';
import { i18next, useTranslation } from '@frontend/i18n';
import { PickerLocation } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  Table,
  Text,
  TextField,
  TrashIcon,
  useFormField,
  useModalControl,
  useAlert,
  Modal,
  ModalControlModalProps,
  Tabs,
  NakedUl,
  SearchField,
  PrimaryButton,
  SpinningLoader,
  Heading,
} from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { buildAudioLibraryPath } from '../../../utils/media-path';
import { AudioUploadModalContent } from '../../audio-picker/audio-upload-modal';
import { CachedAudioScrubber } from '../../common/cached-audio-scrubber';
import { SettingsCard } from '../../common/settings-card';
import { VoicemailGreeting } from './types';
import { useVoicemailBoxGreetingMutations } from './use-voicemail-box-mutations';

const GreetingsCardContent = ({
  tenantLocation,
  mailboxId,
  greetings = [],
  onGreetingChange,
  isDeviceBox,
}: {
  tenantLocation: PickerLocation;
  mailboxId: string;
  greetings?: VoicemailGreeting[];
  onGreetingChange: (incoming: { id: string; number: number; name: string }) => void;
  isDeviceBox: boolean;
}) => {
  const { t } = useTranslation('phone');
  const { modalProps, triggerProps, openModal } = useModalControl();
  const alerts = useAlert();

  const { remove } = useVoicemailBoxGreetingMutations(tenantLocation.phoneTenantId ?? '', mailboxId);

  const numberOptions = useMemo(() => {
    return Array.from({ length: isDeviceBox ? 2 : 10 }, (_, i) => {
      return {
        value: i.toString(),
        label: i.toString(),
        disabled: (i !== 0 && !!greetings?.find((d) => d.greetingNumber === i)) ?? false,
      };
    });
  }, [greetings]);

  return (
    <SettingsCard
      title={t('Greetings')}
      description={
        isDeviceBox
          ? t('Assign a greeting that callers will hear when leaving a voicemail after calling a device extension.')
          : t(
              'Optionally, assign greetings a number so that greetings can be managed by voicemail box outside of Call Routing.'
            )
      }
      action={{
        label: t('Add Greeting'),
        onClick: () => openModal(),
        ref: triggerProps.ref,
        trackingId: 'add-greeting',
      }}
    >
      <Table
        fullHeight
        fullHeightConfig={{
          maxHeight: 300,
          minHeight: 200,
        }}
        data={greetings}
        colConfig={[
          {
            id: 'name',
            accessor: (row) => row,
            Header: t('Name'),
            cellRenderer: (row: VoicemailGreeting) => (
              <div css={{ width: '100%' }}>
                <GreetingNameField greeting={row} onChange={onGreetingChange} />
              </div>
            ),
            sortType: (rowA, rowB) => {
              if (rowA.original.greetingName && rowB.original.greetingName) {
                return rowA.original.greetingName.localeCompare(rowB.original.greetingName);
              }

              return 0;
            },
          },
          {
            id: 'greetingNumber',
            accessor: (row) => row,
            Header: t('Greeting Number'),
            cellRenderer: (row: VoicemailGreeting) => (
              <div css={{ width: '100%' }}>
                <GreetingNumberField
                  isDeviceBox={isDeviceBox}
                  options={numberOptions}
                  greeting={row}
                  onChange={onGreetingChange}
                />
              </div>
            ),
            sortType: (rowA, rowB) => {
              return rowA.original.greetingNumber - rowB.original.greetingNumber;
            },
          },
          {
            id: 'lastUpdated',
            accessor: 'updatedAt',
            Header: t('Last Updated'),
            cellRenderer: (value) => {
              return value ? dayjs(value).format('LLL') : null;
            },
          },
          {
            id: 'greetingId',
            accessor: (row) => row,
            Header: t('Audio'),
            cellRenderer: (row) => <CachedAudioScrubber filePath={row.mediaPath} mediaId={row.greetingId} />,
            disableSortBy: true,
          },
        ]}
        rowActions={{
          actions: [
            {
              label: t('Delete'),
              Icon: TrashIcon,
              onClick: (row) => {
                remove.mutate(
                  { greetingId: row.greetingId, mailboxId: row.mailboxId },
                  {
                    onSuccess: () => {
                      alerts.success(t('Greeting deleted successfully.'));
                    },
                    onError: () => {
                      alerts.error(t('Failed to delete greeting.'));
                    },
                  }
                );
              },
            },
          ],
        }}
      />
      <SelectGreetingModal modalProps={modalProps} mailboxId={mailboxId} tenantLocation={tenantLocation} />
    </SettingsCard>
  );
};

/**
 * If the incoming change conflicts with an existing greeting, we update the greeting number of the existing greeting to 0.
 *
 * Any number of greetings can be set to the greeting number 0.
 */
const getUpdatedGreetings = (
  greetings: VoicemailGreeting[],
  incoming: { id: string; number: number; name: string }
) => {
  return greetings.map((greeting) => {
    if (greeting.greetingId === incoming.id) {
      return { ...greeting, greetingNumber: incoming.number, greetingName: incoming.name };
    } else if (greeting.greetingNumber === incoming.number) {
      return { ...greeting, greetingNumber: 0 };
    }

    return greeting;
  });
};

export const GreetingsCard = ({
  tenantLocation,
  mailboxId,
  onChange,
  greetings,
  isDeviceBox,
}: {
  tenantLocation: PickerLocation;
  mailboxId: string;
  onChange: (incoming: VoicemailGreeting[]) => void;
  greetings: VoicemailGreeting[];
  isDeviceBox: boolean;
}) => {
  const onGreetingChange = (incoming: { id: string; number: number; name: string }) => {
    const updatedGreetings = getUpdatedGreetings(greetings, incoming);

    onChange(updatedGreetings);
  };

  return (
    <GreetingsCardContent
      tenantLocation={tenantLocation}
      mailboxId={mailboxId}
      greetings={greetings}
      onGreetingChange={onGreetingChange}
      isDeviceBox={isDeviceBox}
    />
  );
};

const SelectGreetingModal = ({
  modalProps,
  mailboxId,
  tenantLocation,
}: {
  modalProps: ModalControlModalProps;
  mailboxId: string;
  tenantLocation: PickerLocation;
}) => {
  const { t } = useTranslation('phone');
  const { uploadAndCreateGreeting } = useVoicemailBoxGreetingMutations(tenantLocation.phoneTenantId ?? '', mailboxId);

  return (
    <Modal {...modalProps} minWidth={600}>
      <Modal.Header
        onClose={() => {
          // reset();
          modalProps.onClose();
        }}
      >
        {t('Add a Greeting')}
      </Modal.Header>
      <Modal.Body>
        <Tabs initialTab='library'>
          <Tabs.Bar css={{ marginBottom: theme.spacing(2) }}>
            <Tabs.Tab id='library' controls='library-panel'>
              {t('Media Library')}
            </Tabs.Tab>
            <Tabs.Tab id='upload' controls='upload-panel'>
              {t('Upload New')}
            </Tabs.Tab>
          </Tabs.Bar>
          <Tabs.Panel id='library-panel' controller='library'>
            <AudioListTab tenantLocation={tenantLocation} mailboxId={mailboxId} modalProps={modalProps} />
          </Tabs.Panel>
          <Tabs.Panel id='upload-panel' controller='upload'>
            <AudioUploadModalContent
              customUploadFn={(payload) => {
                if (!tenantLocation.phoneTenantId) {
                  return;
                }

                return uploadAndCreateGreeting.mutate(
                  {
                    mailboxId,
                    greetingName: payload.fileName,
                    greetingNumber: 0,
                    file: payload.file,
                  },
                  {
                    onSettled: () => {
                      modalProps.onClose();
                    },
                  }
                );
              }}
              tenantId={tenantLocation.phoneTenantId ?? ''}
              isUploading={uploadAndCreateGreeting.isLoading}
              modalProps={modalProps}
            />
          </Tabs.Panel>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

const AudioListTab = ({
  tenantLocation,
  mailboxId,
  modalProps,
}: {
  tenantLocation: PickerLocation;
  mailboxId: string;
  modalProps: ModalControlModalProps;
}) => {
  const { t } = useTranslation('phone');
  const token = getWeaveToken();
  const searchField = useFormField({ type: 'text' });
  const { data: phoneMedia = [], isLoading } = useQuery({
    /**
     * TODO: Change this to use the tenant ID instead of the location ID
     * We need to use the locationID for now because the endpoint is location based, switching locations in the
     */
    queryKey: [tenantLocation.locationID, ...queryKeys.settings.listVoicemailGreetings()],
    queryFn: () => {
      return PhoneMediaApi.list({ locationId: tenantLocation.locationID });
    },
  });
  const { create } = useVoicemailBoxGreetingMutations(tenantLocation.phoneTenantId ?? '', mailboxId);
  const alerts = useAlert();

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const filteredList = useMemo(
    () => phoneMedia.filter((media) => media.FileName.toLowerCase().includes(searchField.value.toLowerCase())),
    [phoneMedia, searchField.value]
  );

  useEffect(() => {
    const mediaId = phoneMedia[activeIndex]?.MediaID;
    if (mediaId) {
      (document.querySelector(`[data-list-id="${mediaId}"]`) as HTMLElement | undefined)?.focus();
    }
  }, [activeIndex, phoneMedia]);

  return (
    <div style={{ display: 'grid', gap: theme.spacing(2) }}>
      <SearchField {...searchField} name='audio-search' />
      {isLoading ? (
        <div style={{ display: 'grid', justifyItems: 'center' }}>
          <SpinningLoader />
          <Text>{t('Loading Audio Files')}</Text>
        </div>
      ) : (
        // This outer div gives the tab a fixed height
        <div
          style={{
            height: 400,
          }}
        >
          <NakedUl
            css={{
              display: 'grid',
              gap: theme.spacing(1),
              outline: 'none',
              overflow: 'auto',
              maxHeight: 400,
            }}
            onKeyDown={(e) => {
              if (e.code === 'ArrowDown') {
                setActiveIndex((index) => (index !== null ? Math.min(phoneMedia.length - 1, index + 1) : 0));
              } else if (e.code === 'ArrowUp') {
                setActiveIndex((index) => (index !== null ? Math.max(0, index - 1) : 0));
              }
            }}
          >
            {filteredList?.map((item, index) => (
              <li
                css={[
                  {
                    display: 'grid',
                    gridTemplateColumns: '1fr 40%',
                    gap: theme.spacing(2),
                    alignItems: 'center',
                    border: `1px solid ${theme.colors.neutral30}`,
                    borderRadius: theme.borderRadius.medium,
                    padding: theme.spacing(1),
                    cursor: 'pointer',
                    ':focus-within': {
                      outline: `2px solid ${theme.colors.primary50}`,
                      outlineOffset: -2,
                      background: theme.colors.primary5,
                    },
                  },
                  index === activeIndex &&
                    selectedIndex === activeIndex && {
                      '&,:focus-within': {
                        outline: `2px solid ${theme.colors.primary50}`,
                        outlineOffset: -2,
                        background: theme.colors.white,
                      },
                    },
                  index === selectedIndex && {
                    '&,:focus-within': {
                      outline: `2px solid ${theme.colors.primary50}`,
                      outlineOffset: -2,
                    },
                  },
                ]}
                onMouseDown={() => {
                  setActiveIndex(index);
                  setSelectedIndex(index);
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter' || e.code === 'Space') {
                    setSelectedIndex(index);
                  }
                }}
                data-list-id={item.MediaID}
                tabIndex={index === activeIndex ? 0 : -1}
              >
                <Text>{item.FileName}</Text>
                <CachedAudioScrubber
                  tabIndex={index === activeIndex ? 0 : -1}
                  filePath={
                    token
                      ? buildAudioLibraryPath({
                          media: { id: item.MediaID, path: item.FilePath, isGlobal: false },
                          token,
                        })
                      : ''
                  }
                  mediaId={item.MediaID}
                />
              </li>
            ))}
          </NakedUl>
        </div>
      )}
      <div
        style={{
          // Just to give the button focus state some space
          marginBottom: theme.spacing(1),
        }}
      >
        {selectedIndex !== null ? (
          <PrimaryButton
            disabled={selectedIndex === null}
            onClick={() => {
              if (selectedIndex !== null && tenantLocation.phoneTenantId) {
                const selectedAudio = phoneMedia[selectedIndex];
                create.mutate(
                  {
                    mailboxId,
                    greetingName: selectedAudio.FileName ?? '',
                    greetingNumber: 0,
                    mediaId: selectedAudio.MediaID,
                    tenantId: tenantLocation.phoneTenantId,
                  },
                  {
                    onSuccess: () => {
                      alerts.success(t('Successfully added greeting.'));
                    },
                    onError: () => {
                      alerts.error(t('Failed to add greeting.'));
                    },
                    onSettled: () => {
                      modalProps.onClose();
                    },
                  }
                );
              }
            }}
          >
            {t('Use Selected Audio')}
          </PrimaryButton>
        ) : (
          <div>
            <Heading level={2} textAlign='center'>
              {t("Don't see your media file?")}
            </Heading>
            <Text>
              {t(
                'Dial *86 on your phone to record a new media file, then refresh this page to have it appear here for selection.'
              )}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

const decorateLabelForDeviceBox = (label: string) => {
  if (label === '0') {
    return i18next.t('0: Unassigned', { ns: 'phone' });
  }

  if (label === '1') {
    return i18next.t('1: Assigned', { ns: 'phone' });
  }

  return label;
};

const GreetingNumberField = ({
  isDeviceBox,
  options,
  greeting,
  onChange,
}: {
  isDeviceBox: boolean;
  options: { value: string; label: string; disabled: boolean }[];
  greeting: VoicemailGreeting;
  onChange: (incoming: { id: string; number: number; name: string }) => void;
}) => {
  const field = useFormField(
    {
      type: 'dropdown',
      value: greeting.greetingNumber.toString(),
    },
    [greeting.greetingNumber]
  );

  return (
    <DropdownField
      {...field}
      onChange={(e) => {
        field.onChange(e);
        onChange({ id: greeting.greetingId, number: +e.value, name: greeting.greetingName ?? '' });
      }}
      name='greeting-number'
      label=''
    >
      {options.map((option) => {
        return (
          <DropdownField.Option key={option.value} value={option.value}>
            {isDeviceBox ? decorateLabelForDeviceBox(option.label) : option.label}
          </DropdownField.Option>
        );
      })}
    </DropdownField>
  );
};

const GreetingNameField = ({
  greeting,
  onChange,
}: {
  greeting: VoicemailGreeting;
  onChange: (incoming: { id: string; number: number; name: string }) => void;
}) => {
  const field = useFormField(
    {
      type: 'text',
      value: greeting.greetingName ?? '',
    },
    [greeting.greetingName]
  );

  return (
    <TextField
      {...field}
      onChange={(e) => {
        field.onChange(e);
        // @ts-ignore
        onChange({ id: greeting.greetingId, number: greeting.greetingNumber, name: e.target.value ?? '' });
      }}
      name='greeting-name'
      label=''
    />
  );
};
