import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { getUser } from '@frontend/auth-helpers';
import { useIsCallIntelEligibleAccount } from './use-is-call-intel-eligible-account';

export const useIsCallIntelDemoAccount = () => {
  const { flags } = useCustomizationFlagShallowStore('flags');

  const { isCallIntelEligibleAccount } = useIsCallIntelEligibleAccount();
  const isWeaveDemoUser = getUser()?.username.toLowerCase().includes('@demoweave');

  if (isWeaveDemoUser) {
    // Demo users can use any location for demo purpose
    return true;
  }

  const isCallIntelActive =
    flags.callintelligence?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE ||
    flags.callintelligence?.state === CustomizationFlagTypes.CustomizationFlagState.PROMOTE;

  if (isCallIntelEligibleAccount && !isCallIntelActive) {
    // Show demo data to all users when an eligible location has not activated the feature
    return true;
  }

  return false;
};
