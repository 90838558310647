import { DepartmentsQueries, DepartmentsUtils } from '@frontend/api-departments';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { usePopoverMenu } from '@frontend/design-system';
import { InboxFiltersPopoverSubmenuItem } from '../inbox-filters-popover-submenu-item';
import { GetItemPropsFn } from '../types';
import { DepartmentsFilterPopoverMenu } from './departments-filter-popover';

type DepartmentsFilterPopoverSubmenuItemProps = {
  getItemProps: GetItemPropsFn;
  index: number;
  toggleItems: (departmentIds: string[]) => void;
  selectedDepartments: string[];
  groupIds?: string[];
  divider?: 'top' | 'bottom';
  trackingId?: string;
};

export const DepartmentsFilterPopoverSubmenuItem = ({
  getItemProps,
  index,
  toggleItems,
  selectedDepartments,
  groupIds,
  divider,
  trackingId,
}: DepartmentsFilterPopoverSubmenuItemProps) => {
  const { t } = useTranslation('inbox');
  const { selectedLocationIds } = useAppScopeStore();
  const resolvedGroupIds = groupIds ? selectedLocationIds.filter((id) => groupIds.includes(id)) : selectedLocationIds;
  const departmentsPopover = usePopoverMenu<HTMLButtonElement>({
    placement: 'right-start',
    middlewareOptions: {
      offset: {
        mainAxis: 0,
        crossAxis: -8,
      },
    },
  });
  const departmentsQueries = DepartmentsQueries.useListDefaultSMSQueries(
    resolvedGroupIds.map((locationId) => ({ req: { locationId } }))
  );
  const departmentsByLocation = DepartmentsUtils.reduceDefaultSMSQueriesToLocationIdMap(
    departmentsQueries,
    ({ smsNumbers }) => smsNumbers ?? []
  );
  const locationDepartmentList = Object.values(departmentsByLocation.data ?? {});
  const anyLocationHasDepartments = locationDepartmentList.some((departments) => departments.length > 1);
  const departmentsList = locationDepartmentList.flat();

  if (!anyLocationHasDepartments) return null;

  return (
    <>
      <InboxFiltersPopoverSubmenuItem
        getItemProps={getItemProps}
        getSubmenuTriggerProps={departmentsPopover.getTriggerProps}
        index={index}
        iconName='department-small'
        label={t('Departments')}
        count={selectedDepartments.length}
        divider={divider}
        trackingId={trackingId}
      />
      <DepartmentsFilterPopoverMenu
        getMenuProps={departmentsPopover.getMenuProps}
        getItemProps={departmentsPopover.getItemProps}
        toggleItems={toggleItems}
        selectedDepartments={selectedDepartments}
        departmentsList={departmentsList}
      />
    </>
  );
};
