import { useState } from 'react';
import { css } from '@emotion/react';
import { DeviceType_Enum, ListDevice, ModelInfo } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { DevicesQueries } from '@frontend/api-devices';
import { LineKeysApi } from '@frontend/api-line-keys';
import { Trans, useTranslation } from '@frontend/i18n';
import { useQuery } from '@frontend/react-query-helpers';
import { NavSize, useNavSize } from '@frontend/responsiveness';
import { genUUIDV4 } from '@frontend/string';
import { theme } from '@frontend/theme';
import { CheckboxField, Modal, ModalControlModalProps, Text, useFormField } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { PreviewLayoutModal } from '../preview-layout/preview-only-layout';
import { useLineKeyState } from '../store/line-key-provider';
import { ExistingLayoutDevicesTable } from '../tables/use-existing-layout-table';
import { OverrideConfirmationModal } from './override-confirm.modal';

type Props = ModalControlModalProps & {
  device: ListDevice | undefined;
};

export const UseExistingLayoutModal = ({ device, ...rest }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const locationId = locationIds?.[0] ?? '';
  const { deviceId } = device ?? {};
  const [previewDevice, setPreviewDevice] = useState<ListDevice>();
  const [showOverrideConfirmationModal, setShowOverrideConfirmationModal] = useState(false);
  const { setFinalLineKeys } = useLineKeyState(['setFinalLineKeys']);
  const hideOverrideConfirmation = localStorage.getItem(`hide-override-confirmation-${locationId}`);
  const [filteredLocations, setFilteredLocations] = useState<string[]>([]);
  const navSize = useNavSize();
  const isMobile = navSize === NavSize.Mobile;

  const { data: models, isLoading: _isLoadingModels } = useQuery({
    queryKey: [locationId, 'line-keys', 'device-models'],
    queryFn: () => LineKeysApi.GetDeviceModelInfo(locationId),
    select: ({ models }) => {
      return models.reduce((acc, modelInfo) => {
        return {
          ...acc,
          [modelInfo.model]: modelInfo,
        };
      }, {} as Record<string, ModelInfo>);
    },
  });

  const { data: devices } = DevicesQueries.useGetDevicesList(
    {
      locationIds: filteredLocations.length ? filteredLocations : locationIds,
      deviceType: DeviceType_Enum.DESK_PHONE,
    },
    {
      select: (data) => {
        return data.devices.filter((device) => {
          return device.deviceId !== deviceId;
        });
      },
    }
  );

  const filteredDevices = devices?.filter(
    (device) => !device.make || (!models?.[device.model] && device.make) || !!models?.[device.model].lineKeys
  );

  const model = models?.[previewDevice?.model ?? ''];

  const { data: lineKeys } = useQuery({
    queryKey: ['device', 'line-keys', previewDevice?.deviceId, locationId],
    queryFn: () => LineKeysApi.GetLineKeys(previewDevice?.deviceId ?? '', locationId),
    enabled: !!previewDevice?.deviceId,
  });

  const handleClose = () => {
    setPreviewDevice(undefined);
    setShowOverrideConfirmationModal(false);
    rest.onClose();
  };

  const handleSave = () => {
    if (!!fieldProps.value) localStorage.setItem(`hide-override-confirmation-line-keys`, 'true');
    setFinalLineKeys(
      lineKeys?.lineKeys.reduce((acc, lineKey) => {
        return {
          ...acc,
          [genUUIDV4()]: lineKey,
        };
      }, {})
    );
    handleClose();
  };

  const fieldProps = useFormField({
    type: 'checkbox',
    value: false,
  });

  if (showOverrideConfirmationModal && !hideOverrideConfirmation) {
    return (
      <Modal {...rest} maxWidth={600}>
        <OverrideConfirmationModal
          onClose={handleClose}
          setShowOverrideConfirmationModal={setShowOverrideConfirmationModal}
          onSave={handleSave}
        >
          <Text
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {t('Using another device’s Line Key layout will override the current layout of this device.')}
          </Text>
          <Text
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <Trans t={t} deviceName={device?.name} previewDeviceName={previewDevice?.name}>
              <Text as='span'>
                Are you sure you want to apply{' '}
                <Text as='span' weight='bold'>
                  {{ previewDeviceName: previewDevice?.name }}
                </Text>{' '}
                Line Key layout to
              </Text>{' '}
              <Text as='span' weight='bold'>
                {{ deviceName: device?.name }}
              </Text>
              ?
            </Trans>
          </Text>
          <CheckboxField {...fieldProps} name='validate-override-box' label={t("Don't ask me again")}></CheckboxField>
        </OverrideConfirmationModal>
      </Modal>
    );
  }

  if (previewDevice) {
    return (
      <Modal {...rest} minWidth={isMobile ? 300 : 800} maxWidth={800}>
        <PreviewLayoutModal
          model={model}
          lineKeys={lineKeys?.lineKeys}
          onBack={() => setPreviewDevice(undefined)}
          onClose={handleClose}
          device={previewDevice}
        >
          <Modal.Actions
            secondaryLabel={t('Cancel')}
            onSecondaryClick={handleClose}
            primaryLabel={t('Use this Layout')}
            onPrimaryClick={() => {
              if (hideOverrideConfirmation) handleSave();
              else setShowOverrideConfirmationModal(true);
            }}
            primaryTrackingId='duplicate-use-this-layout'
          />
        </PreviewLayoutModal>
      </Modal>
    );
  }

  return (
    <Modal {...rest} maxWidth={800}>
      <ExistingLayoutDevicesTable
        setPreviewDevice={setPreviewDevice}
        device={device}
        models={models}
        devices={filteredDevices ?? []}
        filteredLocations={filteredLocations}
        setFilteredLocations={setFilteredLocations}
        {...rest}
      />
    </Modal>
  );
};
