import { FC, useCallback } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { PrimaryButton, NakedButton, SecondaryButton } from '@frontend/design-system';
import { pendoTags } from '../../constants';
import { useSteps, useSendDocument } from '../../hooks';
import { useSendFormsStore, useSendFormsContext } from '../../providers';
import { SendFormStep } from '../../types';
import { containerStyle, backButtonStyle, buttonGroupStyle } from './footer.styles';

const SendFormsFooter: FC = () => {
  const { t } = useTranslation('forms', { keyPrefix: 'SEND_FORMS_FOOTER' });

  const { sendFormsModalControls, origin } = useSendFormsContext();
  const {
    currentStep,
    isSending,
    isGeneratingSolicitedLink,
    solicitedLink,
    email,
    emailBody,
    emailSubject,
    messageMode,
    clearStore,
    isUploadInProgress,
  } = useSendFormsStore([
    'currentStep',
    'isSending',
    'isGeneratingSolicitedLink',
    'solicitedLink',
    'email',
    'emailBody',
    'emailSubject',
    'messageMode',
    'clearStore',
    'isUploadInProgress',
  ]);
  const { sendDocument } = useSendDocument();

  const { goToNextStep, goBackAStep, isLastStep } = useSteps();
  const isSelectAppointmentStep = currentStep === SendFormStep.SELECT_APPOINTMENT;

  const _isUploadInProgress = false;

  function goToNextStepHandler() {
    if (isSending) {
      return;
    }

    switch (currentStep) {
      case SendFormStep.SELECTED_MESSAGE_MODE:
        sendDocument();
        return;

      default:
        goToNextStep();
        break;
    }
  }

  function goBackAStepHandler() {
    if (isSending) {
      return;
    }

    switch (currentStep) {
      case SendFormStep.SELECT_APPOINTMENT:
        goBackAStep();
        break;

      case SendFormStep.SELECT_DELIVERY_METHOD:
        if (solicitedLink) {
          // Prevent user from going back to the appointment step if solicited link is generated
          return;
        }

        goBackAStep();
        break;

      default:
        goBackAStep();
        break;
    }
  }

  function cancelClickHandler() {
    if (isSending) {
      return;
    }
    sendFormsModalControls.closeModal();
    clearStore(origin);
  }

  function shouldDisableBackButton() {
    if (currentStep === SendFormStep.SELECT_DELIVERY_METHOD) {
      // Prevent user from going back to the appointment step if solicited link is generated
      return !!solicitedLink;
    }

    return isGeneratingSolicitedLink || isSending || _isUploadInProgress;
  }

  function shouldDisableCancelButton() {
    if (isUploadInProgress) {
      return true;
    }
    return isGeneratingSolicitedLink || isSending;
  }

  function shouldDisableNextButton() {
    if (isUploadInProgress) {
      return true;
    }
    switch (currentStep) {
      case SendFormStep.SELECTED_MESSAGE_MODE:
        return !(email && emailBody && emailSubject);

      default:
        return false;
    }
  }

  const shouldShowBackButton = useCallback(() => {
    if (currentStep === SendFormStep.SELECT_DOCUMENT) {
      return false;
    }

    return true;
  }, [currentStep]);

  const shouldShowNextButton = useCallback(() => {
    if (currentStep === SendFormStep.SELECT_DELIVERY_METHOD) {
      return false;
    }
    if (currentStep === SendFormStep.SELECTED_MESSAGE_MODE) {
      if (messageMode === 'kiosk') {
        return false;
      }
    }
    return true;
  }, [currentStep]);

  const getPendoTagForNextButton = useCallback(() => {
    if (isLastStep()) {
      return pendoTags.send;
    }

    switch (currentStep) {
      case SendFormStep.SELECT_APPOINTMENT:
        return pendoTags.steps.appointments.next;

      case SendFormStep.SELECT_DOCUMENT:
        return pendoTags.steps.documentSelection.next;

      default:
        return '';
    }
  }, [isLastStep, currentStep]);

  const getPendoTagForBackButton = useCallback(() => {
    switch (currentStep) {
      case SendFormStep.SELECT_APPOINTMENT:
        return pendoTags.steps.appointments.back;

      case SendFormStep.SELECT_DELIVERY_METHOD:
        return pendoTags.steps.deliveryMethod.back;

      default:
        return '';
    }
  }, [currentStep]);

  return (
    <footer css={containerStyle}>
      {shouldShowBackButton() && (
        <NakedButton
          css={backButtonStyle}
          onClick={goBackAStepHandler}
          disabled={shouldDisableBackButton()}
          trackingId={getPendoTagForBackButton()}
        >
          <Icon name='back-small' color={shouldDisableBackButton() ? 'disabled' : 'primary'} />
          {t('BACK')}
        </NakedButton>
      )}

      <div css={buttonGroupStyle}>
        {!isSelectAppointmentStep && (
          <SecondaryButton
            size='tiny'
            onClick={cancelClickHandler}
            disabled={shouldDisableCancelButton()}
            trackingId={isLastStep() && messageMode === 'kiosk' ? pendoTags.close : pendoTags.cancel}
          >
            {isLastStep() && messageMode === 'kiosk' ? t('CLOSE') : t('CANCEL')}
          </SecondaryButton>
        )}
        {shouldShowNextButton() && (
          <PrimaryButton
            size='tiny'
            onClick={goToNextStepHandler}
            disabled={shouldDisableNextButton()}
            trackingId={getPendoTagForNextButton()}
          >
            {isLastStep() ? t('SEND') : t('NEXT')}
          </PrimaryButton>
        )}
      </div>
    </footer>
  );
};

export default SendFormsFooter;
