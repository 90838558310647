import { useMemo } from 'react';
import { OfficeHoursWeekSchedule } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { useAppScopeStore } from '@frontend/scope';
import { CalendarView } from '../../components/calendar-view/calendar-view';
import { CalendarViewOffices, OfficeCalendarView } from '../../components/calendar-view/types';
import { NoProviderIllustrationContainer } from '../../components/NoProviderIllustrationContainer';
import { EventCardComponent } from '../../components/schedule-calendar-components';
import { useAppointmentsCalendarViewProps } from '../../context/AppointmentsCalendarViewContext';
import {
  useCalendarEventsConfigurationShallowStore,
  useGetOfficeHoursEvent,
  useGetProvidersDetailsForCalendar,
} from '../../hooks';
import { useAppointmentsInfoShallowStore } from '../../hooks/use-appointments-info-store';
import { useFilteredAppointmentListInfoShallowStore } from '../../stores/use-filtered-appointment-list-store';
import { useProvidersListShallowStore } from '../../stores/use-filtered-provider-list-store';
import { useIntegrationDetailsShallowStore } from '../../stores/use-integration-details';
import { CalendarEventType } from './types';
import { isUUID } from './utils';

export const CalendarTimeLineView = () => {
  const { getLocationName } = useAppScopeStore();
  const { isIntegratedOffice } = useIntegrationDetailsShallowStore('isIntegratedOffice');
  const { setConfiguredCalendarEvent } = useCalendarEventsConfigurationShallowStore('setConfiguredCalendarEvent');
  const {
    providersList: defaultProvidersList,
    providersExceptions,
    providersOfficeHours,
    selectedLocationIds,
    selectedDate,
    officeHours,
    officeHoursExceptions,
  } = useAppointmentsCalendarViewProps();
  const { appointments: calendarViewAppointments } = useAppointmentsInfoShallowStore('appointments');

  const { filteredAppointmentList } = useFilteredAppointmentListInfoShallowStore('filteredAppointmentList');

  const { selectedProvidersList } = useProvidersListShallowStore('selectedProvidersList');

  const { getOfficeHoursEvents } = useGetOfficeHoursEvent();

  const { getProvidersForCalendarObject } = useGetProvidersDetailsForCalendar({
    appointments: !!filteredAppointmentList.length ? filteredAppointmentList : calendarViewAppointments ?? [],
    providersList: !!selectedProvidersList.length ? selectedProvidersList : defaultProvidersList ?? [],
    providersExceptions: providersExceptions ?? [],
    providersOfficeHours: providersOfficeHours ?? [],
  });

  const getOfficeHoursEventsData = (locationId: string) => {
    const officeHoursData =
      officeHours?.find((office) => office.locationId === locationId)?.officeHours ?? ([] as OfficeHoursWeekSchedule);

    const officeHoursEvents = getOfficeHoursEvents({
      calendarDateValue: selectedDate,
      officeHoursData: officeHoursData,
      officeExceptions: officeHoursExceptions ?? [],
      locationId: locationId,
    });
    return officeHoursEvents;
  };

  const getOfficeCalendarViewObject = (locationId: string): OfficeCalendarView => {
    const officeHoursEvents = getOfficeHoursEventsData(locationId);
    return {
      officeName: getLocationName(locationId) ?? '',
      officeBreaks: officeHoursEvents,
      providers: getProvidersForCalendarObject(locationId),
      locationId: locationId,
      calendarDateValue: selectedDate as string,
    };
  };

  const calendarObject = useMemo<CalendarViewOffices>(() => {
    if (!!selectedLocationIds?.length) {
      return selectedLocationIds.map((locationId) => {
        return getOfficeCalendarViewObject(locationId ?? '');
      });
    }
    return [getOfficeCalendarViewObject(selectedLocationIds[0])];
  }, [
    location,
    officeHours,
    officeHoursExceptions,
    getOfficeHoursEvents,
    getProvidersForCalendarObject,
    selectedLocationIds,
  ]);

  const handleConfiguredCalendarEvent = (configuredCalendarEvent: CalendarEventType) => {
    const isProviderEvent = configuredCalendarEvent.providerId && isUUID(configuredCalendarEvent.providerId);
    const isOfficeEvent = configuredCalendarEvent.locationId && !configuredCalendarEvent.providerId;
    if (isOfficeEvent || isProviderEvent) {
      setConfiguredCalendarEvent(configuredCalendarEvent);
    }
  };

  return (
    <CalendarView
      data={calendarObject}
      onEventCreate={(startHour, endHour, providerName, providerId, isValid, locationId, calendarDateValue) => {
        handleConfiguredCalendarEvent({
          startHour,
          endHour,
          providerName,
          providerId,
          isValid,
          locationId,
          calendarDateValue,
        });
      }}
      EventCardComponent={EventCardComponent}
      NoProviderComponent={NoProviderIllustrationContainer}
      shouldAllowEventCreation={!isIntegratedOffice}
    />
  );
};
