import { Fragment, memo, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceLine,
  CartesianGrid,
  Cell,
  LabelList,
  Area,
} from 'recharts';
import { AxisInterval, CartesianViewBox } from 'recharts/types/util/types';
import { theme } from '@frontend/theme';
import { generateGradientDefs } from '../area-chart/gradient-area';
import { EmptyData, Tooltip, TooltipData, XAxisLabelValueTick, YAxisLabelValueTick } from '../atoms';
import { useChartContext } from '../chart.provider';
import { adjustHexColor, generateLegends, generateTooltipData } from '../helpers';
import { useIsVisible } from '../hooks';
import { FormatValue } from '../types';
import { Appearance, BarChartData, BarClick } from './types';

type Props = {
  appearance?: Appearance;
  customLegendsIds?: string[];
  data?: BarChartData;
  formatValue?: FormatValue;
  onClick?: (args: BarClick) => void;
};

const scrollerPadding = 10;
const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };
const barStrokeWidth = 2;

const axisLine = {
  stroke: theme.colors.neutral10,
  strokeDasharray: '1 1',
  strokeWidth: 1,
};

export const BarChart = memo(
  ({
    appearance = {},
    customLegendsIds,
    data = { areaKeys: [], groups: [], stackKeys: [] },
    formatValue,
    onClick,
  }: Props) => {
    const {
      barRadius = 8,
      barsGap = 5,
      barSize = 24,
      collectiveTooltip,
      customTooltipData,
      customTooltipTitle,
      customXAxisTick,
      customXAxisTickFormat,
      customYAxisTick,
      customYAxisTickFormat,
      groupsGap = 80,
      height = '280px',
      hideMarkers,
      hideTooltip,
      layout = 'horizontal',
      margin,
      maxHeight,
      maxValue,
      mode = 'grouped',
      showGridLines,
      showXAxis,
      showYAxis,
      width = '100%',
      wrapperStyles,
    } = appearance;
    const isHorizontalLayout = layout === 'horizontal';
    const {
      activeLegends,
      areSegmentsExpanded,
      colors,
      commonTooltipLabel,
      emptyStateConfig,
      labels,
      setCollapsedLegends,
      setPrimaryLegendsList,
    } = useChartContext();
    const scrollableWrapperRef = useRef<HTMLDivElement>(null);
    const [isScrollable, setIsScrollable] = useState<boolean>(false);
    const [hoveredGroup, setHoveredGroup] = useState<string | null>(null);
    const [hoveredSegment, setHoveredSegment] = useState<string | null>(null);
    const [tooltipData, setTooltipData] = useState<Record<string, TooltipData[]>>({});
    const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const isChartVisible = useIsVisible(scrollableWrapperRef);

    const isClickable = typeof onClick === 'function';
    const updatedMargin = { ...defaultMargin, ...margin };

    // Length - 1 is done to consider a clubbed bar for the stacked keys
    const stackedKeysLength = areSegmentsExpanded || !data.stackKeys?.length ? 0 : data.stackKeys.length - 1;

    const groupsCount = data.groups.length;
    const barsCount =
      mode === 'stacked'
        ? groupsCount
        : (Object.keys(data.groups[0]?.values || {}).length - stackedKeysLength) * groupsCount;
    // Technically we should be calculating totalGroupsGap by groupsGap * (groupsCount - 1)
    // But we need to add an additional groupGap because it will be divided 50% on top/left and 50% on bottom/right of the chart
    // So we are directly multiplying groupsGap with groupsCount
    const totalGroupsGap = (groupsGap || 1) * groupsCount;
    const totalBarsGap = mode === 'stacked' ? 0 : barsGap * (barsCount - groupsCount);
    // If bars are clickable, we need to include the stroke width in the totalBarsSize calculation
    const totalBarsStrokeWidth = isClickable ? (mode === 'stacked' ? groupsCount * 2 : barsCount * 2) : 0;
    const totalBarsSize = barSize * barsCount + totalBarsGap + totalBarsStrokeWidth;
    // Do not use minChartSize for any calculations, it is just for the chart container to have a minimum width
    const minChartSize =
      totalGroupsGap +
      totalBarsSize +
      (isHorizontalLayout
        ? // For vertical layout, when y-axis is visible add additional 60px (approx value of inbuilt xy-axis width)
          updatedMargin.left + updatedMargin.right + (showYAxis ? 60 : 0)
        : // For vertical layout, when x-axis is visible add additional 24px (approx value of inbuilt x-axis height)
          updatedMargin.top + updatedMargin.bottom + (showXAxis ? 24 : 0));
    const onlyOneBarEachGroup = barsCount === groupsCount;

    const customValueTickBase = maxValue ? maxValue / 4 : undefined;
    const customValueTicks =
      maxValue && customValueTickBase
        ? [0, customValueTickBase, customValueTickBase * 2, customValueTickBase * 3, maxValue]
        : undefined;

    const chartData = useMemo(() => {
      const groupedData: {
        name: string;
        [key: string]: number | string;
      }[] = [];

      const placeholders: Record<string, string[]> = {};

      data?.groups.forEach(({ name, values }) => {
        const group = {
          name,
          ...values,
        };
        groupedData.push(group);
        placeholders[name] = Object.entries(values).reduce(
          (acc, [key, value]) => (value ? acc : [...acc, key]),
          [] as string[]
        );
      });

      return {
        data: groupedData,
        placeholders,
      };
    }, [data]);

    const handleMouseLeave = () => {
      setHoveredGroup(null);
      setHoveredSegment(null);
    };

    const legendsFromData = useMemo(() => {
      if (!data?.groups.length) {
        return;
      }

      return generateLegends(data);
    }, [data]);

    const commonValueAxisTicks = {
      allowDecimals: false,
      axisLine: axisLine,
      domain: maxValue ? [0, maxValue] : undefined,
      fontSize: 14,
      interval: 'preserveStartEnd' as AxisInterval,
      tickCount: 5,
      tickLine: false,
      ticks: customValueTicks,
    };

    useEffect(() => {
      const legends = customLegendsIds ?? legendsFromData;
      setPrimaryLegendsList(legends ?? []);
    }, [legendsFromData]);

    useEffect(() => {
      if (!data?.groups.length) {
        return;
      }

      setTooltipData(generateTooltipData({ colors, commonTooltipLabel, customTooltipData, data, formatValue, labels }));
      setCollapsedLegends(data.stackKeys || []);
    }, [data]);

    useEffect(() => {
      if (scrollableWrapperRef.current && isChartVisible) {
        if (isHorizontalLayout) {
          scrollableWrapperRef.current.scrollLeft = 0;
          setIsScrollable(scrollableWrapperRef.current.scrollWidth > scrollableWrapperRef.current.clientWidth);
        } else {
          scrollableWrapperRef.current.scrollTop = 0;
          setIsScrollable(scrollableWrapperRef.current.scrollHeight > scrollableWrapperRef.current.clientHeight);
        }
      }
    }, [chartData.data, isChartVisible]);

    useEffect(() => {
      // Hide tooltip when scrolling
      const handleScroll = () => {
        setHoveredGroup(null);
        setHoveredSegment(null);
      };

      if (scrollableWrapperRef.current) {
        scrollableWrapperRef.current.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (scrollableWrapperRef.current) {
          scrollableWrapperRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }, []);

    return (
      <div
        css={[styles.scrollableWrapper, wrapperStyles]}
        ref={scrollableWrapperRef}
        style={
          isHorizontalLayout
            ? {
                maxHeight,
                paddingBottom: isScrollable ? scrollerPadding : 0,
              }
            : {
                maxHeight,
                paddingRight: isScrollable ? scrollerPadding : 0,
              }
        }
      >
        <div
          style={
            isHorizontalLayout
              ? {
                  height,
                  minWidth: minChartSize,
                  width,
                }
              : {
                  // For vertical layout, height should always be a dynamically calculated value.
                  height: minChartSize,
                  width,
                }
          }
        >
          {data.groups.length ? (
            <ResponsiveContainer width='100%' height='100%' key={Object.keys(data.groups).join()}>
              <ComposedChart
                barGap={barsGap}
                data={chartData.data}
                layout={layout}
                margin={updatedMargin}
                onMouseLeave={handleMouseLeave}
              >
                {showGridLines && <CartesianGrid stroke={theme.colors.neutral10} strokeDasharray='1 1' />}

                {isHorizontalLayout ? (
                  <>
                    <XAxis
                      axisLine={axisLine}
                      dataKey='name'
                      hide={!showXAxis}
                      interval={0}
                      tick={(props) => {
                        if (customXAxisTick) {
                          // Value will be undefined for groups with more than one bar
                          const value = onlyOneBarEachGroup
                            ? chartData.data.filter(({ name }) => name === props.payload.value)[0]?.[
                                props.payload.value
                              ]
                            : undefined;

                          return customXAxisTick?.({
                            groupName: props.payload.value,
                            isHovered: hoveredSegment === props.payload.value,
                            labels,
                            value,
                            x: props.x,
                            y: props.y,
                          });
                        } else {
                          return (
                            <XAxisLabelValueTick
                              {...props}
                              label={labels?.[props.payload.value] ?? props.payload.value}
                            />
                          );
                        }
                      }}
                      tickLine={false}
                    />
                    <YAxis {...commonValueAxisTicks} hide={!showYAxis} tickFormatter={customYAxisTickFormat} />
                  </>
                ) : (
                  <>
                    <XAxis
                      {...commonValueAxisTicks}
                      hide={!showXAxis}
                      tickFormatter={customXAxisTickFormat}
                      type='number'
                    />
                    <YAxis
                      axisLine={axisLine}
                      dataKey='name'
                      fontSize={14}
                      hide={!showYAxis}
                      padding={{ top: 0, bottom: 0 }}
                      tick={(props) => {
                        if (customYAxisTick) {
                          return customYAxisTick?.({
                            groupName: props.payload.value,
                            isHovered: hoveredSegment === props.payload.value,
                            labels,
                            x: props.x,
                            y: props.y,
                          });
                        } else {
                          return (
                            <YAxisLabelValueTick
                              {...props}
                              label={labels?.[props.payload.value] ?? props.payload.value}
                            />
                          );
                        }
                      }}
                      tickLine={false}
                      type='category'
                    />
                  </>
                )}

                {activeLegends.map((legend) => {
                  const color = colors[legend] || theme.colors.neutral50;
                  const isCurrentSegmentHovered = hoveredSegment === legend;

                  // This prevents from rendering bars without data when custom legends are provided
                  if (!legendsFromData?.includes(legend)) {
                    return null;
                  }

                  return (
                    <Fragment key={legend}>
                      {data.areaKeys?.includes(legend) ? (
                        <Fragment key={legend}>
                          {generateGradientDefs(`def-${legend}`, color, 0.1)}
                          <Area
                            dataKey={legend}
                            fill={`url(#def-${legend})`}
                            pointerEvents='none'
                            stroke={color}
                            strokeDasharray='3 3'
                            strokeWidth={2}
                            type='monotone'
                          />
                        </Fragment>
                      ) : (
                        <Bar
                          barSize={barSize}
                          dataKey={legend}
                          key={legend}
                          onClick={(props) =>
                            onClick?.({
                              barId: legend,
                              group: props.name,
                              value: props.value,
                            })
                          }
                          onMouseEnter={(state) => {
                            setHoveredGroup(state.name);
                            setHoveredSegment(state.tooltipPayload[0]?.name);
                          }}
                          onMouseLeave={handleMouseLeave}
                          onMouseMove={(_state, _index, event) => {
                            if (!hideTooltip) {
                              setTooltipPosition({
                                x: event.clientX,
                                y: event.clientY,
                              });
                            }
                          }}
                          radius={barRadius}
                          // When each group contains only one bar, make it stack to avoid bar width issue
                          stackId={
                            onlyOneBarEachGroup ||
                            mode === 'stacked' ||
                            (!areSegmentsExpanded && data.stackKeys?.includes(legend))
                              ? 'stack'
                              : undefined
                          }
                        >
                          {chartData.data.map((entry) => {
                            const isCurrentGroupHovered = hoveredGroup === entry.name;
                            const highlight = isCurrentGroupHovered && isCurrentSegmentHovered;

                            return (
                              <Cell
                                cursor={isClickable ? 'pointer' : 'default'}
                                fill={
                                  hoveredSegment
                                    ? isClickable
                                      ? highlight
                                        ? color
                                        : adjustHexColor(color, 65)
                                      : color
                                    : color
                                }
                                key={`cell-${entry.name}-${legend}`}
                                role={isClickable ? 'button' : 'presentation'}
                                strokeWidth={isClickable ? barStrokeWidth : 0}
                                stroke={
                                  isClickable
                                    ? hoveredSegment
                                      ? highlight
                                        ? adjustHexColor(color, 20, true)
                                        : adjustHexColor(color, 65)
                                      : adjustHexColor(color, 20, true)
                                    : 'none'
                                }
                              />
                            );
                          })}

                          {mode === 'grouped' && (
                            // This serves as a placeholder bar in the grouped mode to avoid showing blank spaces between bars
                            // For stacked modes, this isn't required as the bars are stacked on top of each other
                            <LabelList
                              content={({ height, name, width, x = 0, y = 0 }) => {
                                if (
                                  !chartData.placeholders[name || ''].includes(legend) ||
                                  (data.stackKeys?.includes(legend) && !areSegmentsExpanded)
                                ) {
                                  return null;
                                }

                                return isHorizontalLayout ? (
                                  <rect fill={color} height={2} width={width} x={x} y={(y as number) - 2} />
                                ) : (
                                  <rect fill={color} height={height} width={2} x={x} y={y} />
                                );
                              }}
                              dataKey={legend}
                            />
                          )}
                        </Bar>
                      )}
                    </Fragment>
                  );
                })}

                {!hideMarkers &&
                  data?.markers?.map(({ color, dottedLine = true, formatter, id, label, size = 2, value }) => (
                    <ReferenceLine
                      {...(isHorizontalLayout ? { y: value } : { x: value })}
                      ifOverflow='extendDomain'
                      key={id}
                      label={{
                        content: ({ viewBox }) => {
                          const { x = 0, y = 0, height = 0, width = 0 } = viewBox as CartesianViewBox;
                          const triangleSize = 8;
                          const halfTriangleSize = triangleSize / 2;
                          const fill = color || colors[id] || theme.colors.neutral50;
                          const polygons = [];

                          if (isHorizontalLayout) {
                            const triangleOffset = 2;
                            const xPosLeft = x + triangleOffset;
                            const xPosRight = x + width - triangleOffset;

                            polygons.push(
                              <polygon
                                fill={fill}
                                key={0}
                                points={`${xPosLeft - halfTriangleSize},${y} ${xPosLeft + halfTriangleSize},${
                                  y - halfTriangleSize
                                } ${xPosLeft + halfTriangleSize},${y + halfTriangleSize}`}
                              />,
                              <polygon
                                fill={fill}
                                key={1}
                                points={`${xPosRight + halfTriangleSize},${y} ${xPosRight - halfTriangleSize},${
                                  y - halfTriangleSize
                                } ${xPosRight - halfTriangleSize},${y + halfTriangleSize}`}
                              />
                            );
                          } else {
                            const triangleOffset = 4;
                            const yPosTop = y + triangleOffset;
                            const yPosBottom = y + height - triangleOffset;

                            polygons.push(
                              <polygon
                                fill={fill}
                                key={0}
                                points={`${x - halfTriangleSize},${yPosTop - halfTriangleSize} ${x},${
                                  yPosTop + halfTriangleSize
                                } ${x + halfTriangleSize},${yPosTop - halfTriangleSize}`}
                              />,
                              <polygon
                                fill={fill}
                                key={1}
                                points={`${x - halfTriangleSize},${yPosBottom + halfTriangleSize} ${x},${
                                  yPosBottom - halfTriangleSize
                                } ${x + halfTriangleSize},${yPosBottom + halfTriangleSize}`}
                              />
                            );
                          }

                          return (
                            <g>
                              {polygons}
                              {label && (
                                <text dy={-8} fontSize={14} x={x} y={y} textAnchor='middle'>
                                  <tspan fill={theme.colors.neutral50}>{label}</tspan>{' '}
                                  <tspan fontWeight='bold'>{formatter?.(value) ?? value}</tspan>
                                </text>
                              )}
                            </g>
                          );
                        },
                      }}
                      stroke={color || colors[id]}
                      strokeDasharray={dottedLine ? '10 3' : undefined}
                      strokeWidth={size}
                    />
                  ))}
              </ComposedChart>
            </ResponsiveContainer>
          ) : (
            <EmptyData emptyStateConfig={emptyStateConfig} />
          )}
        </div>
        {hoveredGroup && !hideTooltip && (
          <Tooltip
            data={
              hoveredSegment && !collectiveTooltip
                ? tooltipData[hoveredGroup].filter(({ id }) => id === hoveredSegment)
                : tooltipData[hoveredGroup]
            }
            isClickable={isClickable}
            itemType={mode === 'stacked' ? 'segment' : 'bar'}
            name={
              customTooltipTitle?.({
                groupName: hoveredGroup,
                hoveredSegment,
              }) || (collectiveTooltip ? hoveredGroup : hoveredSegment)
            }
            xPos={tooltipPosition.x}
            yPos={tooltipPosition.y}
          />
        )}
      </div>
    );
  }
);

BarChart.displayName = 'BarChart';

const styles = {
  scrollableWrapper: css`
    overflow: auto;
  `,
};
