import { useMutation } from 'react-query';
import { Options } from '@frontend/fetch';
import { LimitedSchemaMutationOptions } from '@frontend/react-query-helpers';
import { SchemaSmsReplyAssistantV1Service } from '../service';
import { GenerateReplyIO } from '../types';

export const getGenerateReplyMutationKey = () => ['SmsReplyAssistantV1', 'GenerateReply'] as const;

type MutationContext<C = unknown> = {
  // Add internal mutation context here if needed (useful for optimistic updates)
  otherContext?: C;
};

type UseGenerateReplyMutationArgs<
  E = Error,
  C = unknown,
  RequestOverride extends GenerateReplyIO['input'] = GenerateReplyIO['input']
> = {
  options?: LimitedSchemaMutationOptions<GenerateReplyIO, E, C, never, RequestOverride>;
  httpOptions?: Options;
  optimisticUpdate?: boolean;
};
/**
 * A hook that returns a mutation for the `GenerateReply` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useGenerateReplyMutation = <
  E = unknown,
  C = unknown,
  RequestOverride extends GenerateReplyIO['input'] = GenerateReplyIO['input']
>({ options, httpOptions }: UseGenerateReplyMutationArgs<E, C | undefined, RequestOverride> = {}) =>
  useMutation<GenerateReplyIO['output'], E, RequestOverride, MutationContext<C>>({
    mutationKey: getGenerateReplyMutationKey(),
    mutationFn: (req) => SchemaSmsReplyAssistantV1Service.GenerateReply(req, httpOptions),
    ...options,
    onMutate: async (request) => {
      // Implement optimistic updates here

      return {
        // Add custom context type here (if exists in `MutationContext`)
        otherContext: await options?.onMutate?.(request),
      };
    },
    onSuccess: (response, request, context) => {
      // Implement query updates or invalidation for affected queries here
      return options?.onSuccess?.(response, request, context?.otherContext);
    },
    onError: (error, request, context) => {
      // Revert optimistic update here (if implemented)

      return options?.onError?.(error, request, context?.otherContext);
    },
    onSettled: (response, error, request, context) => {
      // Only pass context of type C into provided `onSettled` option
      return options?.onSettled?.(response, error, request, context?.otherContext);
    },
  });
