import { useQuery } from 'react-query';
import { FormsQueryKeys, ProviderMapping } from '@frontend/api';

interface UseUsersProps {
  enabled?: boolean;
  locationId: string;
}

export interface UseUsersResults {
  users: ProviderMapping.Types.User[];
  isLoadingUsers: boolean;
}

const EMPTY_ARRAY: ProviderMapping.Types.User[] = [];

export const useUsers = (props: UseUsersProps): UseUsersResults => {
  const { enabled = true, locationId } = props;

  const { data, isLoading } = useQuery({
    queryFn: () => ProviderMapping.API.fetchUsers(locationId),
    queryKey: [FormsQueryKeys.providerMapping.getProviders, locationId],
    enabled: enabled,
    retry: 1,
  });

  const users = data ?? EMPTY_ARRAY;

  return {
    users,
    isLoadingUsers: isLoading,
  };
};
