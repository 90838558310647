import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { PrimaryButton, Text } from '@frontend/design-system';
import { useScrollToNavItem } from '../../../hooks/use-scroll-to-nav-link';
import { AddToGoogleBusinessProfileIllustration } from '../../../images';
import { NavLinkHrefEnum } from '../../../types';
import { bookingSiteSetupTrackingIds } from '../trackingIds';

export const AddToGoogleBusinessProfilePlaceholder = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('schedule');

  const { scrollToNavItem } = useScrollToNavItem();

  const handleNavigateToReviewPage = () => {
    navigate({ to: NavLinkHrefEnum.REVIEWS_SETTINGS });
    scrollToNavItem({
      href: NavLinkHrefEnum.REVIEWS_SETTINGS,
      parentSection: 'reviews',
    });
  };

  return (
    <section css={{ maxWidth: theme.spacing(45), textAlign: 'center' }}>
      <AddToGoogleBusinessProfileIllustration />
      <Text color='light'>
        {t(
          "Sign in to your Google Business Account on the Reviews page. Don't forget to return here to finish your booking link setup."
        )}
      </Text>
      <PrimaryButton
        trackingId={bookingSiteSetupTrackingIds.takeMeThereBtn}
        onClick={handleNavigateToReviewPage}
        css={primaryButtonStyles}
      >
        {t('Take Me There')}
      </PrimaryButton>
    </section>
  );
};

const primaryButtonStyles = css({ width: 'fit-content', marginTop: theme.spacing(2) });
