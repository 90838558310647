export const subscribeTrackingIds = {
  base: '-subscribe-modal',
  // States
  success: '_success',
  terms: '_terms',
  failure: '_failure',
  invoiceBalanceFailure: '_invoice-balance-failure',
  // Elements
  okayButton: '_okay-button',
  scheduleBtn: '_schedule-btn',
  acceptBtn: '_accept-btn',
  cancelBtn: '_cancel-btn',
  weaveTermsPageLink1: '_weave-terms-page-link-1',
  contactMethodLink: '_contact-method-link',
  weaveTermsPageLink2: '_weave-terms-page-link-2',
  billingPageLink: '_billing-page-link',
  weaveHelpLink: '_weave-help-link',
  closeIconBtn: '_close-icon-btn',
};
