import { useMemo } from 'react';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { EmptyStates } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Chip,
  ChecklistField,
  TextButton,
  SearchField,
  ListRow,
  ListFieldProps,
  BaseFieldProps,
} from '@frontend/design-system';

type ProviderSearchFieldProps = {
  searchFieldProps: BaseFieldProps;
};

type SelectAllProps = {
  isDisabled: boolean;
  handleSelectAll: () => void;
  trackingId?: string;
};

type ClearProps = {
  handleClear: () => void;
  trackingId?: string;
};

type ProviderCheckListProps = {
  searchProviders: Provider[];
  checkListProps: ListFieldProps;
  locations: string[];
  hasOnlyOneLocation: boolean;
};

export const ProviderSearchField = ({ searchFieldProps }: ProviderSearchFieldProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');
  return (
    <>
      <SearchField
        name='provider-search'
        placeholder={t('Search')}
        {...searchFieldProps}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      />
    </>
  );
};

export const SelectAll = ({ handleSelectAll, isDisabled, trackingId }: SelectAllProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');
  return (
    <>
      <TextButton
        trackingId={trackingId}
        css={{
          float: 'left',
          color: theme.colors.primary50,
          fontWeight: 'bold',
          margin: theme.spacing(2, 1, 2, 0),
        }}
        onClick={handleSelectAll}
        disabled={isDisabled}
      >
        {t('Select All')}
      </TextButton>
    </>
  );
};

export const Clear = ({ handleClear, trackingId }: ClearProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');
  return (
    <>
      <TextButton
        trackingId={trackingId}
        css={{
          float: 'right',
          color: theme.colors.primary50,
          fontWeight: 'bold',
          margin: theme.spacing(2, 0, 2, 1),
        }}
        onClick={handleClear}
      >
        {t('Clear')}
      </TextButton>
    </>
  );
};

export const ProviderCheckList = ({
  searchProviders,
  checkListProps,
  locations,
  hasOnlyOneLocation,
}: ProviderCheckListProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');
  const { getLocationName } = useAppScopeStore();

  const providerList = useMemo(() => {
    return locations.map((location) => {
      return searchProviders
        .filter((provider) => provider.locationId === location)
        .map(({ id, firstName, lastName, publicDisplayName, resourceName }) => {
          const providerName = publicDisplayName || `${firstName} ${lastName}` || resourceName || '';
          return (
            <ListRow key={id} css={{ borderBottom: 'none', padding: theme.spacing(0, 2, 1, 1) }}>
              <ListRow.Content>
                <ChecklistField.Option
                  key={`${id}-${location}`}
                  name={id ?? ''}
                  css={{ minWidth: 120, width: '100%', alignItems: 'center' }}
                >
                  {providerName}
                </ChecklistField.Option>
              </ListRow.Content>
              {!hasOnlyOneLocation && (
                <ListRow.Trail>
                  <Chip.Location css={{ minWidth: 120, maxWidth: 200 }}>
                    {getLocationName(location ?? '')}
                  </Chip.Location>
                </ListRow.Trail>
              )}
            </ListRow>
          );
        });
    });
  }, [locations, searchProviders, hasOnlyOneLocation]);

  return (
    <>
      {searchProviders.length === 0 ? (
        <EmptyStates
          emptyStateConfig={{
            type: 'schedule',
            header: `${t('No providers match the criteria')}`,
            description: '',
          }}
        />
      ) : (
        <>
          <ChecklistField
            name='calendar-provider-filter'
            labelPlacement='right'
            label={''}
            css={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}
            {...checkListProps}
          >
            {providerList}
          </ChecklistField>
        </>
      )}
    </>
  );
};
