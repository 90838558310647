import { KeyboardEvent, useCallback, useRef } from 'react';
import { ContentLoader, Text } from '@frontend/design-system';
import { AreaChart } from './area-chart';
import { Header, HorizontalContainer, Legends, SneakPeakBar } from './atoms';
import { BarChart, CategoryBarChart } from './bar-chart';
import { ChartProvider } from './chart.provider';
import { cardStyles, chartWrapperStyles } from './common-styles';
import { Leaderboard } from './leaderboard';
import { PieChart } from './pie-chart';
import { TinyAreaChart } from './tiny-area-chart';
import { ChartProps } from './types';

export const Chart = ({ bottomContentRenderer, children, error, onClick, trackingId, ...rest }: ChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const handleEnterKey = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (onClick && event.key === 'Enter') {
      onClick();
    }
  }, []);

  return (
    <ChartProvider {...rest}>
      <section className={`chart-card ${rest.className ?? ''}`} css={[chartWrapperStyles]}>
        <div
          aria-describedby='chart'
          className={typeof onClick === 'undefined' ? '' : 'click-enabled'}
          css={cardStyles}
          data-trackingid={onClick ? trackingId : undefined}
          onClick={onClick}
          onKeyDown={handleEnterKey}
          ref={chartRef}
          role={onClick ? 'button' : undefined}
          tabIndex={onClick ? 0 : undefined}
        >
          {children}
          {error && (
            <Text color='error' size='medium' textAlign='center'>
              {error}
            </Text>
          )}
          <ContentLoader show={rest.isLoading} size='small' />
        </div>
        {bottomContentRenderer && (
          <div className='weave-chart-bottom-content'>
            {typeof bottomContentRenderer === 'function' ? bottomContentRenderer() : bottomContentRenderer}
          </div>
        )}
      </section>
    </ChartProvider>
  );
};

Chart.AreaChart = AreaChart;
Chart.BarChart = BarChart;
Chart.CategoryBarChart = CategoryBarChart;
Chart.Header = Header;
Chart.HorizontalContainer = HorizontalContainer;
Chart.Leaderboard = Leaderboard;
Chart.Legends = Legends;
Chart.PieChart = PieChart;
Chart.SneakPeakBar = SneakPeakBar;
Chart.TinyAreaChart = TinyAreaChart;

export * as commonChartsStyles from './common-styles';

export { generateAreaGradientDefs } from './area-chart';
export { sequentialChartColors } from './helpers/colors';
export { XAxisLabelValueTick, YAxisLabelValueTick } from './atoms';

export type { AreaChartData } from './area-chart';
export type { BarChartAppearance, BarChartData, CategoryBarChartData } from './bar-chart';
export type { CustomAxisTickParams, CustomAxisTickProps, FormatValue } from './types';
export type { ChartHeaderAction, CustomLegendsData, CustomTooltipTitle } from './atoms';
export type { LeaderboardDataValueClickParams, LeaderboardProps } from './leaderboard';
export type { PieChartData } from './pie-chart/types';
