import { useEffect, useMemo, useRef, useState } from 'react';
import Fuse from 'fuse.js';
import { SoftphoneTypes } from '@frontend/api-softphone';
import { PersonTypes } from '@frontend/api-person';
import { UserWithPresence, useSoftphoneUsers } from '../providers/softphone-users-provider';
import { useSoftphoneDirectory } from '../providers/softphone-directory-provider';
import { CallHistoryItem, useSoftphoneHistory } from '../providers/softphone-history-provider';
import { Autocomplete } from '../components/autocomplete';
import { escapePhoneFormatting, isPhoneNumberURI, isPhoneNumber, formatAddress } from '../utils/formatting-utils';
import { uniqWith } from 'lodash-es';
import { Action } from './use-dialer';

type SearchResult = SoftphoneTypes.User | UserWithPresence | CallHistoryItem | Partial<PersonTypes.Person>;
const isUserState = (fuseResult: SearchResult): fuseResult is SoftphoneTypes.User | UserWithPresence => {
  return 'name' in fuseResult;
};
const isCallHistoryItem = (fuseResult: SearchResult): fuseResult is CallHistoryItem => {
  return 'to' in fuseResult;
};
const isPerson = (fuseResult: SearchResult): fuseResult is Partial<PersonTypes.Person> => {
  return 'PersonID' in fuseResult;
};

export type AutocompleteGroupItems = 'no_group_name' | 'last_called' | 'most_contacted' | 'extensions' | 'contacts';
type AutocompleteItems = {
  [P in AutocompleteGroupItems]: React.ReactNode[];
};

const initialFuse = new Fuse([], {});

type UseDialerAutocompleteProps = {
  currentDial: string | undefined;
  dispatch: (action: Action) => void;
};

export const useDialerAutocomplete = ({ currentDial, dispatch }: UseDialerAutocompleteProps) => {
  const usersWithPresence = useSoftphoneUsers((ctx) => ctx.usersWithPresence);
  const setNameSearch = useSoftphoneDirectory((ctx) => ctx.setNameSearch);
  const setPhoneSearch = useSoftphoneDirectory((ctx) => ctx.setPhoneSearch);
  const searchedPersonsByName = useSoftphoneDirectory((ctx) => ctx.searchedPersonsByName);
  const searchedPersonByPhone = useSoftphoneDirectory((ctx) => ctx.searchedPersonByPhone);
  const callHistory = useSoftphoneHistory((ctx) => ctx.history);
  const mostContacted = useSoftphoneHistory((ctx) => ctx.mostContacted);
  const lastCalled = useSoftphoneHistory((ctx) => ctx.lastCalled);
  const getUser = useSoftphoneUsers((ctx) => ctx.getUser);

  const [searchResults, setSearchResults] = useState<Fuse.FuseResult<SearchResult>[]>();

  const fuseRef = useRef<Fuse<SearchResult>>(initialFuse);

  const fuseOptions: Fuse.IFuseOptions<SearchResult> = useMemo(() => {
    return {
      includeScore: true,
      includeMatches: true,
      keys: [
        //person
        { name: 'FirstName', weight: 3 },
        { name: 'LastName', weight: 3 },
        { name: 'PreferredName', weight: 3 },
        { name: 'HomePhone', weight: 3 },
        { name: 'MobilePhone', weight: 3 },
        { name: 'WorkPhone', weight: 3 },

        //call history
        {
          name: 'person',
          weight: 2,
          getFn: (item) =>
            isCallHistoryItem(item) && item.person ? `${item.person.FirstName} ${item.person.LastName}` : '',
        },
        {
          name: 'to',
          weight: 2,
          getFn: (item) => {
            if (!isCallHistoryItem(item)) return '';
            const user = getUser(item.to);
            return user && user?.presenceUri === item.to ? `${user.name}` : item.to;
          },
        },

        //user/device
        {
          name: 'name',
          weight: 2,
        },
        { name: 'username', weight: 1 },
      ],
    };
  }, [getUser]);

  useEffect(() => {
    fuseRef.current = new Fuse([], fuseOptions);
  }, [fuseOptions]);

  useEffect(() => {
    fuseRef.current.setCollection([...usersWithPresence, ...callHistory]);
  }, [usersWithPresence, callHistory]);

  useEffect(() => {
    if (currentDial) {
      setSearchResults(fuseRef.current.search(currentDial, { limit: 20 }));
      if (currentDial.replace(/[\d]/g, '').length > 1) {
        setNameSearch(currentDial.replace(/[\d]/g, ''));
      } else if (currentDial && isPhoneNumber(currentDial)) {
        const escaped = escapePhoneFormatting(currentDial);
        setPhoneSearch(escaped);
      }
    }
  }, [usersWithPresence, callHistory, currentDial]);

  useEffect(() => {
    if (searchedPersonsByName?.length || searchedPersonByPhone || currentDial) {
      const next = [
        ...usersWithPresence,
        ...mostContacted,
        ...lastCalled,
        ...(searchedPersonsByName ?? []),
        ...(searchedPersonByPhone ? [searchedPersonByPhone] : []),
      ];

      fuseRef.current.setCollection(next);
      const results = fuseRef.current.search(currentDial ?? '', { limit: 20 });
      //filtering: if person and history item are both present, preserve history item, and remove person item
      //note: this can technically be On^2 so keep the these lists very short. If the lists get any bigger, we'll need to create a hash map with a lookup
      const filteredResults =
        results?.filter(({ item }, _i, arr) => {
          if (isPerson(item)) {
            return !arr.find(
              ({ item: otherItem }) => isCallHistoryItem(otherItem) && otherItem.person?.PersonID === item.PersonID
            );
          }
          return true;
        }) ?? [];

      const uniqFilteredResults = uniqWith(
        filteredResults,
        (a, b) => isCallHistoryItem(a.item) && isCallHistoryItem(b.item) && a.item.to === b.item.to
      );
      setSearchResults(uniqFilteredResults);
    }
  }, [callHistory, searchedPersonsByName, searchedPersonByPhone, usersWithPresence, currentDial]);

  const chooseAutocompleteItem = (user: SoftphoneTypes.User | UserWithPresence) => {
    dispatch({ type: 'choose-address', payload: { display: user.name, uri: user.presenceUri ?? '' } });
  };

  const autocompleteItems = (() => {
    const getAutocompleteItems = () => {
      // This will determine the arrangement it will show in the dropdown.
      const items: AutocompleteItems = {
        no_group_name: [],
        last_called: [],
        most_contacted: [],
        contacts: [],
        extensions: [],
      };

      if (!currentDial) {
        items['last_called'] = [
          ...lastCalled.map((item) => {
            const Component =
              item.person && isPhoneNumberURI(item.to) ? Autocomplete.CallHistoryPerson : Autocomplete.CallHistoryItem;
            return (
              <Component
                trackingId='softphone-dial-lastcalled'
                item={item}
                onClick={() => {
                  if (item.type === 'address') {
                    const matchingUser = getUser(item.to);
                    matchingUser && chooseAutocompleteItem(matchingUser);
                  } else {
                    dispatch({ type: 'choose-number', payload: formatAddress(item.to) });
                  }
                }}
              />
            );
          }),
        ];

        items['most_contacted'] = [
          ...mostContacted.map((item) => {
            const Component =
              item.person && isPhoneNumberURI(item.to) ? Autocomplete.CallHistoryPerson : Autocomplete.CallHistoryItem;
            return (
              <Component
                trackingId='softphone-dial-mostcontacted'
                item={item}
                onClick={() => {
                  if (item.type === 'address') {
                    const matchingUser = getUser(item.to);
                    matchingUser && chooseAutocompleteItem(matchingUser);
                  } else {
                    dispatch({ type: 'choose-number', payload: formatAddress(item.to) });
                  }
                }}
              />
            );
          }),
        ];

        items['extensions'] = usersWithPresence
          .slice(0, 20)
          .map((device) => <Autocomplete.Device device={device} onClick={() => chooseAutocompleteItem(device)} />);
      } else {
        searchResults?.forEach(({ item: result, matches }) => {
          if (isCallHistoryItem(result)) {
            const isPhoneNumber = isPhoneNumberURI(result.to);
            const isInMostContacted = mostContacted.some((item) => item.to === result.to);
            const isInLastCalled = lastCalled.some((item) => item.to === result.to);
            const Component =
              result.person && isPhoneNumber ? Autocomplete.CallHistoryPerson : Autocomplete.CallHistoryItem;
            if (isInMostContacted) {
              items['most_contacted'] = [
                ...items['most_contacted'],
                <Component
                  trackingId='softphone-dial-mostcontacted'
                  item={result}
                  onClick={() => {
                    if (result.type === 'address') {
                      const matchingUser = getUser(result.to);
                      matchingUser && chooseAutocompleteItem(matchingUser);
                    } else {
                      dispatch({ type: 'choose-number', payload: formatAddress(result.to) });
                    }
                  }}
                />,
              ];
            }
            if (isInLastCalled) {
              items['last_called'] = [
                ...items['last_called'],
                <Component
                  trackingId='softphone-dial-lastcalled'
                  matches={matches ?? []}
                  item={result}
                  onClick={() => {
                    if (result.type === 'address') {
                      const matchingUser = getUser(result.to);
                      matchingUser && chooseAutocompleteItem(matchingUser);
                    } else {
                      dispatch({ type: 'choose-number', payload: formatAddress(result.to) });
                    }
                  }}
                />,
              ];
            }
            return;
          } else if (isPerson(result)) {
            items['contacts'] = [
              ...items['contacts'],
              <Autocomplete.Person
                item={result}
                onClick={() => {
                  dispatch({ type: 'choose-number', payload: result.MobilePhone ?? result.HomePhone ?? '' });
                }}
              />,
            ];
            return;
          } else if (isUserState(result)) {
            items['extensions'] = [
              ...items['extensions'],
              <Autocomplete.Device
                device={result}
                matches={matches ?? []}
                onClick={() => chooseAutocompleteItem(result)}
              />,
            ];
            return;
          }
        });

        if (currentDial && isPhoneNumber(currentDial)) {
          items['no_group_name'].push(
            <Autocomplete.RawCall
              to={currentDial}
              onClick={() => {
                dispatch({ type: 'choose-number', payload: currentDial ?? '' });
              }}
            />
          );
        }
      }

      return items;
    };

    return getAutocompleteItems();
  })();

  return {
    autocompleteItems,
    fuseRef,
  };
};
