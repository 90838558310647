import {
  TrialStatus,
  SubscriptionAddOnEnums,
} from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FreeTrialsQueries } from '@frontend/api-free-trials';

type UseIsCIEligibleAccount = {
  isCallIntelEligibleAccount?: boolean;
  isFreeTrialEligible?: boolean;
  isLoading?: boolean;
};

// This is used in the Analytics app to determine if the location is eligible for the call intel feature.
export const useIsCallIntelEligibleAccount = (): UseIsCIEligibleAccount => {
  const { data: subscriptionData, isFetching: isLoadingStatus } = FreeTrialsQueries.useGetSubscriptionAddonsStatuses();

  const { data: selfUpgradeEligibility, isFetching: isLoadingSelfUpgradeEligibility } =
    FreeTrialsQueries.useGetIsEligibleForSelfUpgradeOrTrial(Feature.CALL_INTELLIGENCE);

  const subscriptionCardEnum = subscriptionData?.addons?.map((addon) => addon.addonEnum);
  const isEligibleLocation = subscriptionCardEnum?.includes(
    SubscriptionAddOnEnums.SUBSCRIPTION_ADDON_CALL_INTELLIGENCE
  );

  return {
    isCallIntelEligibleAccount: isEligibleLocation,
    isLoading: isLoadingStatus || isLoadingSelfUpgradeEligibility,
    isFreeTrialEligible: selfUpgradeEligibility?.trialStatus === TrialStatus.TRIAL_ELIGIBLE,
  };
};
