import { css } from '@emotion/react';
import { ForwardingNumberApi, ForwardingNumberTypes } from '@frontend/api-forwarding-number';
import {
  digitsOnly,
  ButtonBar,
  Modal,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useForm,
  ValidatorFieldState,
  FormValues,
  useAlert,
} from '@frontend/design-system';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { useQueryClient } from 'react-query';
import { queryKeys } from '../query-keys';
import { useTranslation } from '@frontend/i18n';

const createNewForwardingNumberValidator =
  (fwdNumbers: ForwardingNumberTypes.FwdNumberModel[], disallowedNumbers: string[] = [], locationId: string) =>
  ({ value }: ValidatorFieldState<'phone'>) => {
    /**
     * This was lifted directly from design-system.
     * design-system doesn't export the validators used internally
     */
    if (!/^\d{10,11}$/.test(digitsOnly(value))) {
      return 'Invalid phone number';
    }
    // This logic is used for multi-location.
    const currentLocationNumbers = fwdNumbers?.filter(
      (item) => item?.Labels?.some((label) => label.Value === locationId) || !item.Labels
    );
    /* below code is used only by departments. We cannot add the number used by the department in the forwarding numbers because it would cause a loop and the call will drop.
     * We can add other department numbers and are allowed to forward to that number
     */
    const isUsedByDepartment = disallowedNumbers.some((number) => number === digitsOnly(value));
    if (isUsedByDepartment) {
      return "Can't use department number";
    }
    if (currentLocationNumbers?.find(({ number }) => number === value)) {
      return 'Forwarding number already exists';
    }

    return '';
  };

type AddFwdNumProps = {
  fwdNumbers: ForwardingNumberTypes.FwdNumberModel[];
  disallowedNumbers?: string[]; // We need this to validate that the current department number cannot be added as a forwarding number when we are in department page.
  locationID: string;
  changeForwardingNumber: (ForwardingNumberID: string) => void;
  closeModal: () => void;
};

export const AddForwardingNumberModal = ({
  fwdNumbers,
  disallowedNumbers = [],
  locationID,
  changeForwardingNumber,
  closeModal,
}: AddFwdNumProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'overrides' });
  const alerts = useAlert();
  const queryClient = useQueryClient();

  const { mutate: addForwardingNumber } = useMutation(
    (payload: ForwardingNumberTypes.FwdNumberPayload) => ForwardingNumberApi.create(payload, locationID),
    {
      onSuccess: (data: ForwardingNumberTypes.FwdNumberResponse) => {
        queryClient.invalidateQueries([locationID, ...queryKeys.forwardingNumbers()]);
        changeForwardingNumber(data.data.id);
        closeModal();
        alerts.success(t('Successfully added forwarding number.'));
      },
      onError: () => {
        alerts.error(t('Failed to add forwarding number. Please try again.'));
      },
    }
  );

  const formConfigBase = {
    name: {
      type: 'text',
      required: true,
    },
    phone: {
      type: 'phone',
      required: true,
      validator: createNewForwardingNumberValidator(fwdNumbers, disallowedNumbers, locationID),
    },
  } as const;

  const onAddNumberSubmit = async (values: FormValues<typeof formConfigBase>) => {
    const { phone = '', name = '' } = values;
    addForwardingNumber({ name: name, number: phone });
  };

  const { formProps, getFieldProps, values } = useForm({ fields: formConfigBase });

  return (
    <>
      <Modal.Header onClose={closeModal} css={{ padding: 0 }}>
        {t('Add Forwarding Number')}
      </Modal.Header>
      <Modal.Body>
        <form
          {...formProps}
          css={css`
            margin-top: ${theme.spacing(3)};
          `}
        >
          <div>
            <TextField {...getFieldProps('name')} label={t('Name')} />
          </div>
          <div
            css={css`
              margin-top: ${theme.spacing(2)};
            `}
          >
            <TextField {...getFieldProps('phone')} label={t('Phone Number')} />
          </div>
          <ButtonBar css={{ marginTop: theme.spacing(4), padding: 0 }}>
            <SecondaryButton css={{ width: 'fit-content' }} onClick={closeModal}>
              {t('Cancel')}
            </SecondaryButton>
            <PrimaryButton
              css={{ width: 'fit-content' }}
              onClick={() => {
                onAddNumberSubmit(values);
              }}
            >
              {t('Add Number')}
            </PrimaryButton>
          </ButtonBar>
        </form>
      </Modal.Body>
    </>
  );
};
