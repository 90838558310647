import { FC, useEffect, useRef, useState } from 'react';
import { useMatch } from '@tanstack/react-location';
import { FormsSubmissions } from '@frontend/api';
import { DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import {
  FlyoutMenuAction,
  Heading,
  IconProps,
  Modal,
  PrimaryButton,
  SecondaryButton,
  SpinningLoader,
  Text,
  useFlyoutMenu,
  useTooltip,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { FormProviderIcon } from '../../../../../assets/icons/small';
import { pendoTags } from '../../../../../shared/constants';
import { useFormSubmission, useWeaveProviderUsers } from '../../../hooks';
import { changeProviderConfirmationModalStyle, hoverTextStyle, providerListFlyoutStyle } from './patient-info.styles';

interface ProviderInfoProps {
  reviewStatus: FormsSubmissions.Types.ReviewStatus;
  providerName: string | undefined;
  locationId: string;
}

const { useFormsACL } = DigitalFormScopeHooks;
const formsAdmin = 'Forms_Manager';
const formsAdminReplacement = 'Forms Manager';

export const ProviderInfo: FC<React.PropsWithChildren<ProviderInfoProps>> = ({
  reviewStatus,
  providerName,
  locationId,
}) => {
  const { hasPermissionToManageProviders } = useFormsACL({ locationId });
  const { params } = useMatch();
  const newProvider = useRef<null | string>(null);

  const [providerList, setProviderList] = useState<FlyoutMenuAction[]>([]);
  const { modalProps: confirmationModalProps, triggerProps: confirmationModalTriggerProps } = useModalControl();

  const submissionId = params.submissionid;

  const { updateProvider, isUpdatingProvider } = useFormSubmission(false);

  const {
    Tooltip: ProviderHoverLabel,
    tooltipProps: providerNameProps,
    triggerProps: providerNameTriggerProps,
  } = useTooltip({
    placement: 'bottom',
  });
  // TODO: replace useFlyoutMenu with usePopoverMenu
  const { active, FlyoutMenu, flyoutProps, triggerProps } = useFlyoutMenu();
  const alert = useAlert();

  const { pracitioners, isLoading, isError, pracitionerMapping } = useWeaveProviderUsers();

  const onProviderChange = (value: string) => {
    if ([providerName, formsAdmin].includes(value)) return;

    newProvider.current = value;
    confirmationModalTriggerProps.onClick();
  };

  const changeProvider = () => {
    const providerName = newProvider.current;

    if (providerName) {
      const providerDetails = pracitioners[pracitionerMapping[providerName]];

      if (providerDetails) {
        updateProvider({
          name: providerDetails.name,
          email: providerDetails.email,
          submissionId,
        });
        confirmationModalProps.onClose();
      }
    }
  };

  function updateProviderList() {
    if (isLoading || isError) {
      return;
    }

    const providerList: FlyoutMenuAction[] = [];

    if (providerName !== formsAdmin) {
      providerList.push({
        Icon: FormProviderIcon as FC<React.PropsWithChildren<IconProps>>,
        label: providerName as string,
        onClick: () => onProviderChange(providerName as string),
      });
    } else {
      providerList.push({
        Icon: FormProviderIcon as FC<React.PropsWithChildren<IconProps>>,
        label: formsAdminReplacement,
        onClick: () => onProviderChange(providerName as string),
      });
    }

    pracitioners.forEach((practitioner) => {
      if (practitioner.name !== providerName) {
        providerList.push({
          label: practitioner.name,
          onClick: () => onProviderChange(practitioner.name),
        });
      }
    });

    setProviderList(providerList);
  }

  useEffect(() => {
    updateProviderList();
  }, [isLoading, isError, providerName]);

  const onProviderNameClick = () => {
    if (hasPermissionToManageProviders && pracitioners.length === 1 && reviewStatus === 'Pending') {
      alert.info('There are no other providers to choose from');
    }
  };

  return (
    <div className='provider-info'>
      {isUpdatingProvider ? (
        <SpinningLoader size='small' css={{ padding: 0 }} />
      ) : (
        <div
          className='provider-detail-wrapper'
          data-ischangeable={hasPermissionToManageProviders && reviewStatus === 'Pending' && providerList.length > 1}
        >
          <div className='provider-detail-item'>
            <Text className='provider-change' color='light' {...triggerProps}>
              Change Provider
            </Text>
            <Text
              className='provide-detail-item provider-name'
              color='light'
              {...providerNameTriggerProps}
              onClick={onProviderNameClick}
            >
              <FormProviderIcon className={`provider-review-${reviewStatus.toLowerCase()}`} />
              {providerName === formsAdmin ? formsAdminReplacement : providerName}
              <ProviderHoverLabel {...providerNameProps}>
                <Text className='approver-title' css={hoverTextStyle} textAlign='center'>
                  Approver (provider)
                </Text>
                <Text css={hoverTextStyle} textAlign='center' color='white'>
                  {providerName === formsAdmin ? formsAdminReplacement : providerName}
                </Text>
              </ProviderHoverLabel>
            </Text>
          </div>
        </div>
      )}
      {hasPermissionToManageProviders && active && (
        <FlyoutMenu {...flyoutProps} actions={providerList} css={providerListFlyoutStyle} />
      )}
      <Modal {...confirmationModalProps} css={changeProviderConfirmationModalStyle}>
        <Heading level={2}>Change Approver?</Heading>
        <Text>
          Are you sure you want to change the approver of this submission? If you change the approver, the previously
          assigned approver will not be notified of this change.
        </Text>
        <Text>Do you want to continue?</Text>
        <div className='action-row'>
          <SecondaryButton onClick={confirmationModalProps.onClose} size='tiny'>
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={changeProvider} trackingId={pendoTags.providerReview.changeProvider} size='tiny'>
            Change Approver
          </PrimaryButton>
        </div>
      </Modal>
    </div>
  );
};
