import { useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { CalendarIcon, IconButton, PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';
import { useAppointmentsCalendarViewProps } from '../../context/AppointmentsCalendarViewContext';
import { useCalendarEventsConfigurationShallowStore } from '../../hooks';
import { CalendarEventsEnums } from '../../views/Calendar/types';

interface Props {
  eventId: string;
  eventTitle: string;
  providerId?: string;
  providerName?: string;
}

export const OutOfOfficeEventActionMenu = ({ eventId, eventTitle, providerId, providerName }: Props) => {
  const { t } = useTranslation('scheduleCalendarEvents');
  const data = useAppointmentsCalendarViewProps();
  const { setConfiguredCalendarEvent } = useCalendarEventsConfigurationShallowStore('setConfiguredCalendarEvent');
  const popoverMenuProps = usePopoverMenu({ placement: 'right' });

  const eventInfo = useMemo(() => {
    if (providerId) {
      return data.providersExceptions?.find((providerException) => providerException.id === eventId);
    } else {
      return data.officeHoursExceptions?.find((officeHoursException) => officeHoursException.id === eventId);
    }
  }, [eventId, data.appointments]);

  const handleEventClick = () => {
    if (!eventInfo) {
      return;
    }
    const startDate = dayjs(eventInfo.startDateTime);
    const endDate = dayjs(eventInfo.endDateTime);

    setConfiguredCalendarEvent({
      eventId,
      isValid: true,
      calendarDateValue: data.selectedDate as string,
      locationId: eventInfo.locationId ?? '',
      calendarEventType: providerId
        ? CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT
        : CalendarEventsEnums.OFFICE_HOURS_OUT_OF_OFFICE_EVENT,
      startDate: formatDate(startDate, 'MM/DD/YYYY'),
      endDate: formatDate(endDate, 'MM/DD/YYYY'),
      startHour: formatDate(startDate, 'hh:mm A'),
      endHour: formatDate(endDate, 'hh:mm A'),
      providerId: providerId,
      providerName: providerName,
      name: eventTitle,
    });
  };

  if (!eventInfo) {
    return null;
  }

  return (
    <>
      <IconButton css={iconButtonStyles} {...popoverMenuProps.getTriggerProps()} label={t('More Icon')}>
        <Icon name='more' size={16} />
      </IconButton>
      <PopoverMenu {...popoverMenuProps.getMenuProps()}>
        <PopoverMenuItem
          Icon={CalendarIcon}
          {...popoverMenuProps.getItemProps({ index: 0, onClick: handleEventClick })}
        >
          {t('Manage Out of Office Event')}
        </PopoverMenuItem>
      </PopoverMenu>
    </>
  );
};

const iconButtonStyles = css({
  padding: theme.spacing(0.15),
  minWidth: 'auto',
  height: 'auto',
  width: 'auto',
});
