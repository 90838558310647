import { ReviewActionFlowProps as ReviewActionFlowProps } from '../types';
import { useTranslation } from '@frontend/i18n';
import { ModalControlTriggerProps, useModalControl } from '@frontend/design-system';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { ReviewActionModal } from '../components';
import { ComponentProps } from '@frontend/types';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';

type UseReviewActionArgs = ReviewActionFlowProps & {
  onClose?: () => void;
};

type UseReviewActionResult =
  | {
      modalProps: ComponentProps<typeof ReviewActionModal>;
      triggerProps: ModalControlTriggerProps & {
        label: string;
        trackingId: string;
      };
    }
  | undefined;

/**
 * Hook to use the review invitation flow. This should be used in combination with the `ReviewActionModal`
 * @returns The props necessary for the `ReviewActionModal` and trigger props for wherever this action is
 * triggered. Returns `undefined` if the user does not have the necessary permissions for reviews.
 */
export const useReviewAction = ({
  groupId,
  personId,
  appointmentId,
  onClose,
}: UseReviewActionArgs): UseReviewActionResult => {
  const { t } = useTranslation('contact-actions');
  const modalControl = useModalControl({
    onClose,
  });
  const hasACL = hasSchemaACL(groupId, Permission.REVIEW_READ);

  if (!hasACL) return undefined;

  return {
    modalProps: {
      groupId,
      personId,
      appointmentId,
      ...modalControl.modalProps,
    },
    triggerProps: {
      ...modalControl.triggerProps,
      label: t('Request Review'),
      trackingId: `${ContactActionPrefixes.ReviewInvitation}`,
    },
  };
};
