import { FC, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { AutoRecallAnalyticsApi, AutoRecallAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { emptyStateGraphics, useAlert } from '@frontend/design-system';
import { TabbedTable, UserCard } from '..';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { ExportUtils, formatters } from '../../utils';
import { useAutoRecallStore } from './hooks';
import { DataUtils } from './utils';

type AutoRecallReportProps = {
  isLoading?: boolean;
  onFetchStateChange: (isFetching: boolean) => void;
  overviewData?: AutoRecallAnalyticsTypes.AutoRecallDataResponse | null;
};

type DataKey = 'Attempted' | 'FailedAttempt' | 'Success';

const DEFAULT_TAB = 'notScheduled';
const keyMap: Record<string, DataKey> = {
  notReceived: 'FailedAttempt',
  notScheduled: 'Attempted',
  scheduled: 'Success',
};

export const AutoRecallReport: FC<React.PropsWithChildren<AutoRecallReportProps>> = ({
  isLoading,
  onFetchStateChange,
  overviewData,
}) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { demoData, isDemoAccount } = useAutoRecallStore();

  const [activeTab, setActiveTab] = useState<string>(DEFAULT_TAB);

  const [pageNumbers, setPageNumbers] = useState<Record<string, number>>({
    notScheduled: 1,
    notReceived: 1,
    scheduled: 1,
  });

  const [pageSizes, setPageSizes] = useState<Record<string, number>>({
    notScheduled: 25,
    notReceived: 25,
    scheduled: 25,
  });

  const [hasNextPage, setHasNextPage] = useState<Record<string, boolean>>({
    notScheduled: false,
    notReceived: false,
    scheduled: false,
  });

  const queryString = `${activeTab}-${pageNumbers[activeTab]}-${pageSizes[activeTab]}}`;

  const getPatientsIds = (): string[] => {
    const key = keyMap[activeTab];
    const startIndex = (pageNumbers[activeTab] - 1) * pageSizes[activeTab];
    const endIndex = startIndex + pageSizes[activeTab];
    return overviewData?.[key]?.slice(startIndex, endIndex) || [];
  };

  const { data, isFetching } = useScopedQuery({
    queryKey: queryKeys.autoRecallAnalytics(
      `patientsInfo${JSON.stringify(overviewData)}${queryString}-${isDemoAccount}`
    ),
    queryFn: () =>
      isDemoAccount
        ? null
        : AutoRecallAnalyticsApi.getAutoRecallPatientsInfo(activeTab, pageSizes[activeTab], getPatientsIds()),
    onError: () => {
      alert.error(t("Couldn't load the data. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return isDemoAccount ? demoData?.tableData?.[activeTab] : data;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const tabsCommonConfig = {
    colConfig: [
      {
        Header: t('Patient Name'),
        headerLabel: t('Patient Name'),
        accessor: ({ FirstName, LastName, PersonID }: AutoRecallAnalyticsTypes.Patients) =>
          JSON.stringify({
            firstName: FirstName,
            lastName: LastName,
            userId: PersonID,
          }),
        cellRenderer: (value: string) => (
          <UserCard {...JSON.parse(value)} openProfileOnClick={!isDemoAccount} showOnlyName />
        ),
        disableSortBy: true,
        id: 'name',
        width: 250,
      },
      {
        Header: t('Mode of Contact'),
        headerLabel: t('Mode of Contact'),
        accessor: ({ ContactType }: AutoRecallAnalyticsTypes.Patients) => ContactType || '-',
        disableSortBy: true,
        id: 'ContactType',
        width: 150,
      },
      {
        Header: t('Overdue'),
        headerLabel: t('Overdue'),
        accessor: ({ Recall }: AutoRecallAnalyticsTypes.Patients) => DataUtils.calcOverdueDays(Recall) || '-',
        disableSortBy: true,
        id: 'Overdue',
        width: 150,
      },
      {
        Header: t('Contacted Date'),
        headerLabel: t('Contacted Date'),
        accessor: ({ DateCreated }: AutoRecallAnalyticsTypes.Patients) =>
          DateCreated ? formatters.date.format(DateCreated) : '-',
        disableSortBy: true,
        id: 'DateCreated',
        width: 150,
      },
    ],
    emptyStateConfig: 'users' as keyof typeof emptyStateGraphics,
    tableInstanceId: 'autoRecallReport',
  };

  useEffect(() => {
    setHasNextPage((prev) => ({ ...prev, [activeTab]: data?.hasMoreData || false }));
  }, [data]);

  useEffect(() => {
    onFetchStateChange(isFetching);
  }, [isFetching]);

  return (
    <TabbedTable
      exportFileName='auto-recall-report'
      initialTab={DEFAULT_TAB}
      isLoading={isLoading || isFetching}
      manualPaginationConfig={{
        handleChange: (action: 'next' | 'prev') => {
          if (action === 'next') {
            setPageNumbers({ ...pageNumbers, [activeTab]: pageNumbers[activeTab] + 1 });
          } else {
            setPageNumbers({ ...pageNumbers, [activeTab]: pageNumbers[activeTab] - 1 });
          }
        },
        onNumRowsChange: (size: number) => {
          setPageSizes({ ...pageSizes, [activeTab]: size });
        },
        defaultRowsPerPage: pageSizes[activeTab],
        hasNext: hasNextPage[activeTab],
        hasPrevious: pageNumbers[activeTab] > 1,
        page: pageNumbers[activeTab],
        rowsPerPageOptions: [25, 50, 75, 100],
      }}
      onTabChange={setActiveTab}
      processExportableData={(data) =>
        // Translation is not needed as this is for export purpose
        ExportUtils.processExportableData({
          columns: ['Patient Name', 'Mode of Contact', 'Overdue', 'Contacted Date'],
          data,
          deriveExportValue: DataUtils.deriveExportValue,
        })
      }
      tabs={{
        notScheduled: {
          ...tabsCommonConfig,
          data: data?.patients || [],
          label: t('Not Scheduled'),
          trackingId: trackingIds.autoRecallAnalytics.notScheduledTab,
        },
        notReceived: {
          ...tabsCommonConfig,
          data: data?.patients || [],
          label: t('Not Received'),
          trackingId: trackingIds.autoRecallAnalytics.notReceivedTab,
        },
        scheduled: {
          ...tabsCommonConfig,
          data: data?.patients || [],
          label: t('Appointment Scheduled'),
          trackingId: trackingIds.autoRecallAnalytics.scheduledTab,
        },
      }}
      wrapperStyle={css`
        max-height: 620px;
      `}
    />
  );
};
