import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import {
  ButtonBar,
  Heading,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextField,
  useModalControl,
} from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { ScheduleTypes } from '@frontend/api-schedule';
import { BlockType } from '../weekly-scheduler';
import { useTranslation } from '@frontend/i18n';
import {
  DAYS_OF_WEEK,
  DaysAndHoursFormFields,
  getScheduleBreakBlocks,
  noScheduleConflict,
  useScheduleForm,
} from '../office-hours-schedule.form';
import { OfficeHoursDeleteModal } from './delete-break.modal';

type DayError = {
  day: number;
  error: JSX.Element;
};

type Props = {
  initialSchedule?: ScheduleTypes.Schedule;
  otherSchedules: ScheduleTypes.Schedule[];
  updating: boolean;
  onSave: (schedule: ScheduleTypes.Schedule) => void;
  deleteSchedule: (scheduleID: string) => void;
  onClose: () => void;
};

const scheduleModalCss = css`
  margin: ${theme.spacing(0, 4, 0, 4)};
`;

type ContentProps = {
  updating: boolean;
  schedule: ScheduleTypes.Schedule;
  errors: DayError[];
  form: ReturnType<typeof useScheduleForm>;
  initialSchedule?: ScheduleTypes.Schedule;
  isNew: boolean;
  onCancel: () => void;
  onDelete: () => void;
  onSubmit: () => void;
  hasBreakNameError: boolean;
};

const ScheduleModalContent = ({
  updating,
  schedule,
  errors,
  form,
  initialSchedule,
  isNew,
  onCancel,
  onDelete,
  onSubmit,
  hasBreakNameError,
}: ContentProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  const [hasSelectedDay, sethasSelectedDay] = useState(false);
  const breakNameProps = form.getFieldProps('breakName');
  const { values } = form;

  useEffect(() => {
    const hasSelection = Object.entries(values)
      .filter(([key]) => key.startsWith('selected'))
      .some(([_, val]) => val);

    sethasSelectedDay(hasSelection);
  }, [values]);

  return (
    <Modal.Body>
      <div css={scheduleModalCss}>
        <form {...form.formProps}>
          {schedule.type === ScheduleTypes.ScheduleType.Open && (
            <>
              <Heading
                level={2}
                css={css`
                  margin-bottom: 0px;
                `}
              >
                {t('Phone Hours')}
              </Heading>
              <Text
                color='light'
                size='medium'
                css={css`
                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                {t('Set the hours for the days and time in which your office is open.')}
              </Text>
            </>
          )}
          {schedule.type === ScheduleTypes.ScheduleType.Break && (
            <>
              <Heading level={2}>{initialSchedule ? t('Set Break Schedule') : t('Break Settings')}</Heading>
              <Text
                color='light'
                size='medium'
                css={css`
                  margin-bottom: ${theme.spacing(3)};
                `}
              >
                {isNew
                  ? t(
                      'Add a break in which your phone routing options might be different from your open and closed phone hours and routing options.'
                    )
                  : t('Set the times for your break.')}
              </Text>
              <TextField
                {...breakNameProps}
                label='Break Name'
                containerCss={css`
                  // Add height to prevent layout shift when errors show up
                  height: 65px;
                  margin-bottom: ${theme.spacing(2)};
                `}
              />
            </>
          )}

          {errors &&
            errors.map(({ error }, index) => (
              <Text
                key={index}
                color='error'
                css={css`
                  color: ${theme.colors.status.critical};
                `}
              >
                {error}
              </Text>
            ))}
          <DaysAndHoursFormFields {...form} />
          {hasBreakNameError && !initialSchedule && (
            <div
              css={css`
                * {
                  margin: 0;
                  padding: 0;
                }
                text-align: center;
                border: 1px solid ${theme.colors.status.warning};
                border-radius: ${theme.spacing(1)};
                padding: ${theme.spacing(1)};
                margin: ${theme.spacing(3, 1, 1, 1)};
                box-sizing: border-box;
                background-color: rgba(255, 165, 31, 0.05);
              `}
            >
              {hasBreakNameError && hasSelectedDay && (
                <Text size='medium'>{t('Please add a break name to continue')}</Text>
              )}
              {hasBreakNameError && <Text size='medium'>{t('Please add a break name to continue')}</Text>}
            </div>
          )}

          <ButtonBar style={{ marginTop: theme.spacing(1) }}>
            <SecondaryButton onClick={onCancel} disabled={updating}>
              {t('Cancel')}
            </SecondaryButton>
            {!isNew && schedule.type === ScheduleTypes.ScheduleType.Break && (
              <SecondaryButton onClick={onDelete}>{t('Delete Break')}</SecondaryButton>
            )}
            <PrimaryButton
              onClick={onSubmit}
              disabled={updating || hasBreakNameError || errors.length > 0}
              trackingId='phone-portal-department-editBreaks-save-btn'
            >
              {t('Save')}
            </PrimaryButton>
          </ButtonBar>
        </form>
      </div>
    </Modal.Body>
  );
};

const checkScheduleConflict = (
  schedule: ScheduleTypes.Schedule,
  form: any,
  breakScheduleBlocks: BlockType[]
): DayError[] => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });

  if (schedule.type === ScheduleTypes.ScheduleType.Open) return [];

  const allConflicts = DAYS_OF_WEEK.flatMap<DayError | null>((day) => {
    if (!form.values[`selected-${day}`]) {
      return null;
    }

    const thisStart = `${form.values[`range-${day}`]?.[0]}`;
    const thisEnd = `${form.values[`range-${day}`]?.[1]}`;
    const otherBlocksThisDay = breakScheduleBlocks.filter((block) => DAYS_OF_WEEK[block.dayOfWeek] === day);

    const format = (time: any) => {
      const hour = time.substring(0, 2);
      const newHour = hour % 12 === 0 ? 12 : hour % 12;
      const minute = time.substring(3, 5);
      const suffix = hour < 12 ? 'am' : 'pm';
      const newFormat = newHour + ':' + minute + suffix;
      return newFormat;
    };

    const conflicts = otherBlocksThisDay
      .map((block) => {
        if (noScheduleConflict(thisStart, thisEnd, block.startTime, block.endTime)) {
          return null;
        }
        return {
          day: block.dayOfWeek,
          error: (
            <div
              css={css`
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              {t('You have a conflict on {{day}} with {{scheduleName}} ', {
                day: day,
                scheduleName: block.schedule.name,
              })}
              <strong>
                {format(block.startTime)} - {format(block.endTime)}
              </strong>
            </div>
          ),
        };
      })
      .filter((err) => !!err);

    return conflicts;
  }).filter((err): err is DayError => !!err);

  return allConflicts;
};

export const ScheduleModal = ({
  initialSchedule,
  otherSchedules,
  updating,
  onSave,
  deleteSchedule,
  onClose,
}: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  const isNew = !initialSchedule;
  const defaultSchedule: ScheduleTypes.Schedule = {
    scheduleId: '',
    name: '',
    type: ScheduleTypes.ScheduleType.Break,
    rules: [],
  };

  const [schedule, setSchedule] = useState(initialSchedule ?? defaultSchedule);
  const [hasBreakNameError, setHasBreakNameError] = useState<boolean>(false);
  const [errors, setErrors] = useState<DayError[]>([]);
  const { modalProps: confirmationModalProps, openModal, closeModal } = useModalControl();

  const breakScheduleBlocks = useMemo(() => getScheduleBreakBlocks(otherSchedules), [otherSchedules]);

  const form = useScheduleForm({
    selectedSchedule: schedule,
    filteredSchedules: otherSchedules,
    setBreakNameErr: setHasBreakNameError,
  });

  const updateSchedule = () => {
    const rules: ScheduleTypes.Rule[] = DAYS_OF_WEEK.map((day, i) => {
      if (!form.values[`selected-${day}`]) {
        return null;
      }

      return {
        dayOfWeek: i,
        startTime: form.values[`range-${day}`]?.[0],
        endTime: form.values[`range-${day}`]?.[1],
      };
    }).filter(Boolean) as ScheduleTypes.Rule[];

    const update: ScheduleTypes.Schedule = {
      ...schedule,
      rules,
    };
    onSave(update);
  };

  const onSubmit = () => {
    updateSchedule();
  };

  const onCancel = () => {
    onClose();
  };

  const onDelete = () => {
    openModal();
  };

  const onConfirmDelete = () => {
    if (schedule.type === ScheduleTypes.ScheduleType.Break) {
      deleteSchedule(schedule.scheduleId);
    } else {
      /**
       * We never get to this point because the delete button currently only shows up for breaks
       */
      const data: ScheduleTypes.Schedule = {
        ...schedule,
        rules: [],
      };
      onSave(data);
    }
    onClose();
  };

  useEffect(() => {
    if (!form.values.breakName) setHasBreakNameError(true);
    setSchedule({ ...schedule, name: form.values.breakName ?? '' });
  }, [form.values.breakName]);

  const header = !initialSchedule
    ? t('Add New Break')
    : initialSchedule.type === ScheduleTypes.ScheduleType.Open
    ? t('Edit Open Hours')
    : t('Edit {{scheduleName}} Hours', { scheduleName: schedule.name });

  const scheduleConflicts = checkScheduleConflict(schedule, form, breakScheduleBlocks);

  if (errors.length !== scheduleConflicts.length) {
    /* This will update the errors every time when there is a mismatch between current conflicts
     * and previous errors object. For example: Whenever a conflict is either removed or arisen between schedules.
     */
    setErrors(scheduleConflicts);
  } else if (errors.length && !scheduleConflicts.length) {
    /* This will be reset when there is no conflict between different schedules
     * and errors object had some values previously. it is important to compare with
     * previous errors as we don't want to rerun it every time and do infinite rerenders.
     */
    setErrors([]);
  }

  return (
    <>
      <Modal.Header>{header}</Modal.Header>
      <ScheduleModalContent
        updating={updating}
        schedule={schedule}
        errors={errors}
        form={form}
        initialSchedule={initialSchedule}
        isNew={isNew}
        onCancel={onCancel}
        onDelete={onDelete}
        onSubmit={onSubmit}
        hasBreakNameError={hasBreakNameError}
      />
      <OfficeHoursDeleteModal
        modalProps={confirmationModalProps}
        scheduleName={schedule.name || ''}
        updating={updating}
        closeModal={closeModal}
        onSubmit={onConfirmDelete}
      />
    </>
  );
};
