import { ListAppointmentsRequest_PageDirection } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/history/v1/service.pb';
import { MessagesTypes } from '@frontend/api-messaging';

type Props<ListRequestType extends MessagesTypes.ListRequest> = {
  pageParams: RecordTableControlsParams;
  page: number;
  requestParams: ListRequestType;
  setRequestParams: (params: ListRequestType) => void;
  setPage: (page: number) => void;
};

export type RecordTableControlsParams = {
  firstRecordId?: string;
  firstRecordDate?: string;
  lastRecordId?: string;
  lastRecordDate?: string;
};

export const useRecordTableControls = <ListRequestType extends MessagesTypes.ListRequest>({
  pageParams,
  page,
  setPage,
  setRequestParams,
  requestParams,
}: Props<ListRequestType>) => {
  const { firstRecordId, firstRecordDate, lastRecordId, lastRecordDate } = pageParams;

  const handlePageForward = () => {
    const lastItemParams = {
      pageBoundaryId: lastRecordId,
      pageBoundaryAt: lastRecordDate,
      pageDirection: ListAppointmentsRequest_PageDirection.PAGE_FORWARD,
    };
    setRequestParams({
      ...requestParams,
      ...lastItemParams,
    });
    setPage(page + 1);
  };

  const handlePageBackward = () => {
    const firstItemParams = {
      pageBoundaryId: firstRecordId,
      pageBoundaryAt: firstRecordDate,
      pageDirection: ListAppointmentsRequest_PageDirection.PAGE_BACKWARD,
    };
    setRequestParams({
      ...requestParams,
      ...firstItemParams,
    });
    setPage(page - 1);
  };

  return {
    handlePageForward,
    handlePageBackward,
  };
};
