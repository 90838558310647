import { css } from '@emotion/react';
import { useTranslation, Trans } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, PayIcon, Text } from '@frontend/design-system';
import { displayAmount, isoDateToDisplayLocalString } from '../../utils';

export const PaymentPlanPaymentSummary = ({ monthly, date }: { monthly: number; date?: string }) => {
  const { t } = useTranslation('payments');
  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-flow: row;
          align-items: center;
          height: 72px;
          border: 1px solid ${theme.colors.neutral20};
          border-radius: ${theme.borderRadius.medium};
          background-color: ${theme.colors.neutral5};
          padding: ${theme.spacing(1, 2)};
          gap: ${theme.spacing(1)};
        `}
      >
        <PayIcon />
        <div
          css={css`
            display: flex;
            flex-flow: column;
            justify-content: center;
          `}
        >
          <Heading level={3}>{t('Payment Plan Summary')}</Heading>
          <Trans>
            <Text size='large' weight='bold'>
              {displayAmount(monthly)}/mo starting {isoDateToDisplayLocalString(date || '')}
            </Text>
          </Trans>
        </div>
      </div>
      {monthly < 0.5 && (
        <Text
          color='error'
          size='medium'
          css={css`
            margin-top: ${theme.spacing(1)};
          `}
        >{`${t('Payment must be greater than ')} ${displayAmount(0.5)}`}</Text>
      )}
    </div>
  );
};
