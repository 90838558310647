import { SchemaIO } from '@frontend/schema';
import { useSMSDataV3QueryUpdaters } from '../../query-updaters';
import { ServiceMutations } from '../../types';
import { UseSMSDataV3MutationEndpointArgs, useSMSDataV3Mutation } from '../use-sms-data-v3-mutation';

/**
 * A hook that returns a mutation for the `SetThreadsArchived` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useSetThreadsArchivedMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations['SetThreadsArchived']>['input'] = SchemaIO<
    ServiceMutations['SetThreadsArchived']
  >['input']
>({
  options,
  ...args
}: UseSMSDataV3MutationEndpointArgs<'SetThreadsArchived', E, C | undefined, OtherOptions, RequestOverride> = {}) => {
  const { invalidateQueries } = useSMSDataV3QueryUpdaters();

  return useSMSDataV3Mutation<'SetThreadsArchived', E, C, OtherOptions, RequestOverride>({
    endpointName: 'SetThreadsArchived',
    ...args,
    options: {
      ...options,
      onSuccess: (response, request, context) => {
        invalidateQueries<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: {
            predicate: ({ queryKey }) => {
              const queryRequest = queryKey[2];
              const matchesGroupIds =
                queryRequest.groupIds?.some((groupId) => request.groupIds?.includes(groupId) ?? false) ?? true;
              const matchesLocationId = queryRequest.locationId === request.locationId;
              if (matchesGroupIds || matchesLocationId) return true;

              return false;
            },
          },
        });

        return options?.onSuccess?.(response, request, context);
      },
    },
  });
};
