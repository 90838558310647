import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FileUpload, Modal, ModalControlModalProps, Text } from '@frontend/design-system';
import { MAX_FILE_SIZE } from '../../constants';

interface Props {
  modalProps: ModalControlModalProps;
  onImageUpload: (files: File[]) => void;
}

export const ImageUploadModal = ({ modalProps, onImageUpload }: Props) => {
  const { t } = useTranslation('team-chat');

  const handleFileUpload = (files: File[]) => {
    onImageUpload(files);
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps} maxWidth={600}>
      <Modal.Header css={styles.header} onClose={modalProps.onClose} textAlign='left'>
        {t('Upload Image')}
      </Modal.Header>
      <Modal.Body>
        <FileUpload
          acceptedFileType={['jpeg', 'jpg', 'png']}
          helperText={
            <div css={styles.helperText}>
              <Text>{t('Drop image here')}</Text>
              <Text>{t('Accepted file formats: .jpeg, .jpp and .png')}</Text>
              <Text>{t('Max file size: 10MB')}</Text>
              <Text>{t('Max files allowed: 10')}</Text>
            </div>
          }
          maxFileSize={MAX_FILE_SIZE}
          multiple
          onFileUpload={handleFileUpload}
        />
      </Modal.Body>
    </Modal>
  );
};

const styles = {
  header: css`
    margin-bottom: ${theme.spacing(1)};
  `,

  helperText: css`
    margin: ${theme.spacing(0, 3)};
    text-align: center;
  `,
};
