import {
  CalendarException,
  ListAppointmentsResponse,
  ListCalendarExceptionReply,
} from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import {
  OfficeHoursDaySchedule,
  OfficeHoursForMultiLocation,
} from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { UseQueryResult } from 'react-query';
import { ScheduleTypes } from '@frontend/api-schedule';
import { ModalControlResponse } from '@frontend/design-system';
import { ScheduleMassMessageStepEnum } from './views/Calendar/types';

export type OfficeHoursDayScheduleType = OfficeHoursDaySchedule & {
  providerId?: string;
};

export type AppointmentsCalendarViewContextValueType = {
  selectedLocationIds: string[];
  defaultFilteredLocationIds: string[];
  providersList: ScheduleTypes.SchemaProvider[];
  selectedDate: string | string[];
  setSelectedDate: (date: string) => void;
  appointments: ListAppointmentsResponse;
  officeHours?: OfficeHoursForMultiLocation[];
  providersExceptions?: CalendarException[];
  providersOfficeHours?: OfficeHoursDayScheduleType[];
  officeHoursExceptions?: CalendarException[];
  setSelectedMultiLocationIds: (locationIds: string[]) => void;
};

export type AppointmentsCalendarViewProvidersListContextValueType = {
  selectedProvidersList: ScheduleTypes.Provider[];
};

export type CalendarViewRefetchMethodContext = {
  refetchProvidersExceptions: UseQueryResult<ListCalendarExceptionReply>['refetch'];
  refetchOfficeExceptions: UseQueryResult<ListCalendarExceptionReply>['refetch'];
  refetchAppointments: UseQueryResult<ListAppointmentsResponse>['refetch'];
};

export type EventsPanelContextType = {
  modalProps: ModalControlResponse;
};

export type ScheduleActionsContainerTypes = {
  setHideTabs: (val: boolean) => void;
  hideTabs: boolean;
  selectedLocationId: string | null;
  setSelectedLocationId: (val: string | null) => void;
  closeModal?: () => void;
  selectedDate: string;
  setSelectedDate: (val: string) => void;
  showTrayHeader?: boolean;
};

export type ScheduleMassMessageTypes = {
  setCurrStep: (val: ScheduleMassMessageStepEnum) => void;
  currStep: string;
  onClose: () => void;
};

export type ScheduleAlertsContextType = {
  refetchScheduleAlerts: () => void;
  clearAlert: (id: string, locationId?: string) => void;
  setSelectedLocationId: (locationId: string) => void;
};

export type ParentSections = 'reviews' | 'online-scheduling';
export enum NavLinkHrefEnum {
  REVIEWS_SETTINGS = '/portal/reviews/settings',
  REVIEWS_ANALYTICS = '/portal/reviews/analytics',
  OS_BOOKING_SITE = '/portal/online-scheduling/booking-site',
  OS_CUSTOM_FIELDS = '/portal/online-scheduling/custom-fields',
  OS_SETTINGS = '/portal/online-scheduling/settings',
}

export type CalendarEventContextType = {
  selectedLocationIds: string[];
  providersList: ScheduleTypes.SchemaProvider[];
};
