import { useEffect } from 'react';
import { css } from '@emotion/react';
import { ScheduleRequestSource } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { ReviewsApi, ReviewsQueries } from '@frontend/api-reviews';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import { ErrorBadgeIcon, Heading, SpinningLoader, Text } from '@frontend/design-system';
import { getBookingUrlWithSource } from '../../../utils';
import { AddBookingLinkToBusinessProfile } from './AddBookingLinkToBusinessProfile';
import { AddToGoogleBusinessProfilePlaceholder } from './AddToGoogleBusinessProfilePlaceholder';
import { GoogleBusinessProfileNotVerifiedBanner } from './GoogleBusinessProfileNotVerifiedBanner';
import { InvalidTokenBanner } from './InvalidTokenBanner';

type GoogleAccountContentProps = {
  locationId: string;
  bookingSiteURL: string;
};

const SpinningLoaderContainer = () => (
  <div css={{ marginTop: theme.spacing(2) }}>
    <SpinningLoader />
  </div>
);

const ErrorCard = () => {
  const { t } = useTranslation('schedule');
  return (
    <div css={errorCardStyles}>
      <ErrorBadgeIcon color='error' />
      <section>
        <Heading level={2}>{t('An error occurred while loading your settings.')}</Heading>
        <Text>{t('Try reloading the page. If the problem persists, contact Weave Support.')}</Text>
      </section>
    </div>
  );
};

export const GoogleAccountContent = ({ locationId, bookingSiteURL }: GoogleAccountContentProps) => {
  const { t } = useTranslation('schedule');
  const {
    data: mappings,
    isLoading: isLoadingMappings,
    isError: isErrorMappings,
    refetch: refetchMappings,
  } = ReviewsQueries.useGetMappings({
    queryKey: ['google-mappings', locationId],
    enabled: false,
  });

  const {
    data: integrationStatusData,
    isLoading: isLoadingIntegrationStatus,
    refetch: refetchIntegrationStatusData,
  } = useLocalizedQuery({
    queryFn: () => ReviewsApi.getGoogleIntegrationStatus({ locationId: locationId, isGoogle: true }),
    queryKey: ['google-integration-status', locationId],
    enabled: false,
  });

  const googleBusinessProfileBookingSiteURL = getBookingUrlWithSource(
    bookingSiteURL,
    ScheduleRequestSource.GOOGLE_BUSINESS_PROFILE
  );

  useEffect(() => {
    if (locationId) {
      refetchMappings();
      refetchIntegrationStatusData();
    }
  }, [locationId]);

  const mappingState = mappings?.googleMapping?.setupState?.toLowerCase();

  const hasGoogleAccountMappingSetup =
    mappings?.googleMapping && (mappingState === 'authsuccess' || mappingState === 'integrationsuccess');

  const hasValidGoogleAuthToken = integrationStatusData?.data?.google?.validToken;
  const isLocationVerified = integrationStatusData?.data?.google?.locationState?.isVerified;

  const shouldRenderAddBookingLinkToBusinessProfile = hasGoogleAccountMappingSetup && hasValidGoogleAuthToken;

  const hasInvalidToken = hasGoogleAccountMappingSetup && !hasValidGoogleAuthToken;

  if (isLoadingMappings || isLoadingIntegrationStatus) return <SpinningLoaderContainer />;
  if (isErrorMappings) return <ErrorCard />;

  return (
    <Page style={{ padding: 0 }} maxWidth={theme.spacing(80)}>
      <Heading level={3}>{t('Add to Google Business Profile')}</Heading>
      <Text>
        {t(
          'Add appointment booking directly to your Google Business Profile, enhancing accessibility and streamlining engagement. Connect now for effortless customer interaction.'
        )}
      </Text>
      {hasInvalidToken && <InvalidTokenBanner />}
      {shouldRenderAddBookingLinkToBusinessProfile && !isLocationVerified && <GoogleBusinessProfileNotVerifiedBanner />}
      {mappings?.googleMapping && shouldRenderAddBookingLinkToBusinessProfile ? (
        <AddBookingLinkToBusinessProfile
          googleMapping={mappings?.googleMapping}
          bookingSiteURL={googleBusinessProfileBookingSiteURL}
          locationId={locationId}
          isLocationVerified={!!isLocationVerified}
        />
      ) : (
        <AddToGoogleBusinessProfilePlaceholder />
      )}
    </Page>
  );
};

const errorCardStyles = css({
  display: 'flex',
  alignItems: 'center',
  border: `solid 1px ${theme.colors.critical50}`,
  borderRadius: theme.borderRadius.small,
  background: theme.colors.critical10,
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  gap: theme.spacing(2),
});
