import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useLocation } from '@tanstack/react-location';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { AnalyticsCommonApi } from '@frontend/api-analytics';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { StarIcon, TextLink, useModalControl, useAlert } from '@frontend/design-system';
import { trackingIds } from '../tracking-ids';
import { Feedback, FeedbackModal } from '.';

type Props = {
  productCode: Feature;
  showModal?: boolean;
  trackingId?: string;
};

export const FeedbackButton = ({ productCode, showModal = false, trackingId }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { modalProps, triggerProps, openModal } = useModalControl();
  const { current } = useLocation();
  const isWeaveApp = !current.pathname.startsWith('/portal');

  const productDetails = () => {
    if (productCode === Feature.CALL_INTELLIGENCE) {
      return {
        feedbackId: isWeaveApp ? 'call-intel-2-dot-0-feedback' : 'call-intel-portal-feedback',
        name: t('Call Intelligence'),
      };
    }

    return {
      feedbackId: '',
      name: '',
    };
  };

  const handleSubmit = (feedback: Feedback) => {
    setIsLoading(true);
    AnalyticsCommonApi.submitFeedback({
      payload: {
        feedback_id: productDetails().feedbackId,
        feedback_map: {
          allow_to_contact: `${feedback.allowWeaveToContact}`,
          feedback: feedback.message,
          rating: `${feedback.rating}`,
          route: current.pathname,
        },
        user_id: getUser()?.userID || '',
      },
      onSuccess: () => {
        alert.success(t('Feedback submitted. Thank you!'));
        modalProps.onClose();
        setIsLoading(false);
      },
      onError: (error) => {
        alert.error(t('Error submitting feedback. Please try again.'));
        console.error('Error submitting feedback', error);
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    if (showModal) openModal();
  }, [showModal]);

  return (
    <>
      <TextLink {...triggerProps} css={styles.button} trackingId={trackingId} weight='bold'>
        <StarIcon size={16} />
        {t('Submit Feedback')}
      </TextLink>
      <FeedbackModal
        isLoading={isLoading}
        modalProps={modalProps}
        productName={productDetails().name}
        onSubmit={handleSubmit}
        trackingIdBase={trackingIds.callIntel.generalFeedback}
      />
    </>
  );
};

const styles = {
  button: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
  `,
};
