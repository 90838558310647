import { http } from '@frontend/fetch';
import { ExportDataReferenceResponse } from '../common/types';
import { BASE_URL_ANALYTICS } from '../constants';
import { interceptDates } from '../helpers';
import { SMSReportResponse, Filters, FilterOptionsValues, MessageExportPayload, SMSChartsData } from './types';

const getDatesIntercepted = (filters: Filters) => {
  const interceptedDates = {
    startDate: filters.start_date,
    endDate: filters.end_date,
  };
  if (filters.start_date && filters.end_date) {
    const dates = interceptDates({
      endDate: filters.end_date,
      setFullDay: true,
      startDate: filters.start_date,
      timeZone: filters.time_zone,
    });
    interceptedDates.startDate = dates.startDate;
    interceptedDates.endDate = dates.endDate;
  }
  return {
    ...filters,
    start_date: interceptedDates.startDate,
    end_date: interceptedDates.endDate,
  };
};

export const getSMSReport = async (payload: Filters, pageSize: number, url?: string) => {
  if (!payload.location_id?.length || !payload.start_date || !payload.end_date) return;

  const { data, meta } = await http.post<SMSReportResponse>(
    url || `${BASE_URL_ANALYTICS}/getsms?limit=${pageSize}`,
    getDatesIntercepted(payload)
  );

  return { data, meta };
};

export const getFilterOptionsValues = async () => {
  const response = await http.get<{ data: FilterOptionsValues }>(`${BASE_URL_ANALYTICS}/smsreports/getfilters`);
  return response.data;
};

export const getExportableDetails = async (payload: MessageExportPayload) => {
  const response = await http.post<{ data: ExportDataReferenceResponse[] }>(
    `${BASE_URL_ANALYTICS}/downloadreports/getcsvasync`,
    getDatesIntercepted(payload)
  );
  return response.data;
};

export const getSMSReportingCharts = async (payload: Filters, isHourlyInsights?: boolean) => {
  if (!payload.location_id?.length || !payload.start_date || !payload.end_date) return;
  const { data } = await http.post<{ data: SMSChartsData[] }>(
    `${BASE_URL_ANALYTICS}/smsreports/${
      isHourlyInsights
        ? 'getsmshourlyaggregate' // To get hourly records
        : 'getsmsaggregate' // To get date wise records
    }`,
    getDatesIntercepted(payload)
  );
  return data;
};
