import { useEffect, useRef } from 'react';
import { FormsSubmission, FormsWritebacks } from '@frontend/api';
import { useDigitalFormsWritebackCapabilitiesContext } from '@frontend/digital-forms-scope';
import { INITIAL_WB_SETTINGS_STATE } from '../constant';
import { useWritebackWidgetStore } from '../store';
import { WritebackSettingState } from '../types';

const EMPTY_WRITEBACK_STATUS_FALLBACK: FormsWritebacks.Types.WritebackStatus[] = [];

interface UseWritebackSettingsConfigProps {
  submission: FormsSubmission.Types.NormalizedSubmissionDetailResponse;
  locationId: string;
}

export const useWritebackSettingsConfig = ({ submission, locationId }: UseWritebackSettingsConfigProps) => {
  const initRef = useRef(false);
  const sourceIdRef = useRef<string>('');

  const { hasFetchedWritebackCapabilities, getSourceCapabilities } = useDigitalFormsWritebackCapabilitiesContext();

  const { setAreWritebackSettingsReady, areSourceTenantsReady, sourceTenantId, sourceId, setSettings } =
    useWritebackWidgetStore([
      'setAreWritebackSettingsReady',
      'areSourceTenantsReady',
      'sourceTenantId',
      'sourceId',
      'setSettings',
    ]);

  const {
    writebackStatusCode,
    isArchive,
    writebackStatus: writebackSettingsFromSubmission = EMPTY_WRITEBACK_STATUS_FALLBACK,
    solicited = false,
  } = submission;

  /**
   * Re-initialize the writeback destination config
   * if the submission changes (especially when the submission
   * is re-fetched after retrying the sync).
   */
  useEffect(() => {
    initRef.current = false;
    setAreWritebackSettingsReady(false);
  }, [writebackStatusCode, isArchive, writebackSettingsFromSubmission, solicited]);

  // Initialize settings config
  useEffect(() => {
    if (initRef.current || !areSourceTenantsReady || !hasFetchedWritebackCapabilities || !sourceId) {
      return;
    }

    const sourceCapabilities = getSourceCapabilities(locationId, sourceId);

    const settingsUpdate = getUpdatedWritebackSettings({
      writebackStatusCode: writebackStatusCode,
      isArchived: isArchive,
      writebackSettingsFromSubmission,
      isSolicited: solicited,
      canUploadDocument: sourceCapabilities.showWritebackSettings.uploadDocument,
    });

    setSettings('Create Person', settingsUpdate.createPerson);
    setSettings('Update Person', settingsUpdate.updatePerson);
    setSettings('Upload Document', settingsUpdate.uploadDocument);
    setAreWritebackSettingsReady(true);
    initRef.current = true;
  }, [
    writebackStatusCode,
    isArchive,
    writebackSettingsFromSubmission,
    solicited,
    areSourceTenantsReady,
    hasFetchedWritebackCapabilities,
    sourceId,
    locationId,
    getSourceCapabilities,
  ]);

  // Update settings config if the sourceId changes
  useEffect(() => {
    if (sourceIdRef.current === sourceId) {
      return;
    }

    const sourceCapabilities = getSourceCapabilities(locationId, sourceId);

    const settingsUpdate = getUpdatedWritebackSettings({
      writebackStatusCode: writebackStatusCode,
      isArchived: isArchive,
      writebackSettingsFromSubmission,
      isSolicited: solicited,
      canUploadDocument: sourceCapabilities.showWritebackSettings.uploadDocument,
    });

    setSettings('Create Person', settingsUpdate.createPerson);
    setSettings('Update Person', settingsUpdate.updatePerson);
    setSettings('Upload Document', settingsUpdate.uploadDocument);
    sourceIdRef.current = sourceId;
  }, [
    sourceTenantId,
    sourceId,
    locationId,
    writebackStatusCode,
    isArchive,
    writebackSettingsFromSubmission,
    solicited,
    getSourceCapabilities,
  ]);
};

interface UpdateSettingsPayload {
  writebackStatusCode?: FormsWritebacks.Types.WritebackStatusCode;
  isArchived?: boolean;
  writebackSettingsFromSubmission: FormsWritebacks.Types.WritebackStatus[];
  isSolicited: boolean;
  canUploadDocument: boolean;
}

interface UpdateSettingsResult {
  createPerson: WritebackSettingState;
  updatePerson: WritebackSettingState;
  uploadDocument: WritebackSettingState;
}

function getUpdatedWritebackSettings({
  writebackStatusCode,
  isArchived,
  writebackSettingsFromSubmission,
  isSolicited,
  canUploadDocument,
}: UpdateSettingsPayload): UpdateSettingsResult {
  const result: UpdateSettingsResult = {
    createPerson: { ...INITIAL_WB_SETTINGS_STATE },
    updatePerson: { ...INITIAL_WB_SETTINGS_STATE },
    uploadDocument: { ...INITIAL_WB_SETTINGS_STATE },
  };

  // If the submission is archived or already synced, we should not allow any writeback
  if (writebackStatusCode === 'Fulfilled' || isArchived) {
    return result;
  }

  let isCreatePersonFulfilled = false;
  let isUpdatePersonFulfilled = false;

  for (const setting of writebackSettingsFromSubmission) {
    const shouldIgnoreSetting = setting.settingStatus === 'Fulfilled' || setting.settingStatus === 'Not Applicable';

    switch (setting.settingName) {
      case 'Create Person':
        isCreatePersonFulfilled = setting.settingStatus === 'Fulfilled';

        result.createPerson = {
          ...result.createPerson,
          settingId: setting.settingId,
          failed: setting.settingStatus === 'Failed',
          notApplicable: setting.settingStatus === 'Not Applicable',
          autoMode: setting.settingValue === 'Auto',
          manualMode: setting.settingValue === 'Manual',
          statusCode: setting.settingStatus,
        };

        if (!isSolicited && !shouldIgnoreSetting) {
          result.createPerson = {
            ...result.createPerson,
            value: true,
            shouldShowSwitch: true,
          };
        }
        break;

      case 'Update Person':
        isUpdatePersonFulfilled = setting.settingStatus === 'Fulfilled';

        result.updatePerson = {
          ...result.updatePerson,
          settingId: setting.settingId,
          failed: setting.settingStatus === 'Failed',
          notApplicable: setting.settingStatus === 'Not Applicable',
          autoMode: setting.settingValue === 'Auto',
          manualMode: setting.settingValue === 'Manual',
          statusCode: setting.settingStatus,
        };

        if (!shouldIgnoreSetting) {
          result.updatePerson = {
            ...result.updatePerson,
            value: true,
            shouldShowSwitch: true,
          };
        }
        break;

      case 'Upload Document':
        result.uploadDocument = {
          ...result.uploadDocument,
          settingId: setting.settingId,
          failed: setting.settingStatus === 'Failed',
          notApplicable: setting.settingStatus === 'Not Applicable',
          autoMode: setting.settingValue === 'Auto',
          manualMode: setting.settingValue === 'Manual',
          statusCode: setting.settingStatus,
        };

        if (!shouldIgnoreSetting && canUploadDocument) {
          result.uploadDocument = {
            ...result.uploadDocument,
            value: true,
            shouldShowSwitch: true,
          };
        }
        break;
    }

    if (writebackStatusCode === 'Failed') {
      if (result.createPerson.value || isCreatePersonFulfilled) {
        result.updatePerson = {
          ...result.updatePerson,
          value: false,
          shouldShowSwitch: false,
          notApplicable: true,
        };
      }
    }

    if (isUpdatePersonFulfilled) {
      result.createPerson = {
        ...result.createPerson,
        value: false,
        shouldShowSwitch: false,
        notApplicable: true,
      };
    }
  }

  return result;
}
