import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { ScheduleQueries } from '@frontend/api-schedule';
import { UsersQueries } from '@frontend/api-users';
import { InfinitePaginatedList } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Accordion, TextLink, useModalControl, useAlert } from '@frontend/design-system';
import { ScheduleRequestContext } from '../Context/ScheduleRequestContext';
import { ScheduleRequestModalContext, ScheduleRequestModalContextType } from '../Context/ScheduleRequestModalContext';
import { ScheduleRequestModal } from '../ScheduleRequestModal';
import { ScheduleRequestsTrackingIds } from '../trackingIds';
import { getScheduleRequestFullName } from '../utils';
import { ScheduleRequestHistoryTableRoute } from './constant';
import { ScheduleRequestDetails } from './ScheduleRequestDetails';
import { ScheduleRequestListItemHeader } from './ScheduleRequestListItemHeader';

interface ScheduleRequestListProps {
  locationId: string;
  height?: number;
}

const DEFAULT_MIN_HEIGHT = 300;

export const ScheduleRequestList = ({ locationId, height = DEFAULT_MIN_HEIGHT }: ScheduleRequestListProps) => {
  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarActions');
  const navigate = useNavigate();

  const [selectedScheduleRequest, setSelectedScheduleRequest] = useState<ScheduleRequest | null>(null);
  const scheduleRequestModalProps = useModalControl();

  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const isMultiLocation = selectedLocationIdsWithParents.length > 1;
  const { data: locationUsers } = UsersQueries.useGetUsers({
    params: isMultiLocation ? { showChildren: true } : {},
    opts: {
      cacheTime: 10 * 60 * 1000, // 10 mins
      staleTime: 10 * 60 * 1000, // 10 mins
    },
  });

  const scheduleRequestListQuery = ScheduleQueries.useGetScheduleRequestListInfiniteQuery({
    locationId,
    limit: 25,
    statuses: 'Pending',
    orderBy: '-createdAt',
    offset: 0,
  });

  const providerValue = useMemo(
    () => ({ locationUsers, refetchScheduleRequestList: () => scheduleRequestListQuery.refetch() }),
    [locationUsers, scheduleRequestListQuery.refetch]
  );

  const handleCloseScheduleRequestModal = () => {
    scheduleRequestModalProps.closeModal();
    setSelectedScheduleRequest(null);
  };

  const scheduleRequestModalContextValue = useMemo<ScheduleRequestModalContextType>(
    () => ({
      scheduleRequestModalProps,
      selectedScheduleRequest: selectedScheduleRequest as ScheduleRequest,
      handleCloseScheduleRequestModal,
      refetchScheduleRequestList: () => scheduleRequestListQuery.refetch(),
    }),
    [selectedScheduleRequest, scheduleRequestListQuery.refetch]
  );

  useEffect(() => {
    if (scheduleRequestListQuery.isError) {
      alert.error(t('Failed to load schedule request list'));
    }
  }, [scheduleRequestListQuery.isError]);

  const isEmptyScheduleRequestList = !scheduleRequestListQuery?.data?.pages?.[0]?.rows?.length;

  const navigateToScheduleRequestHistory = () => {
    navigate({ to: `${ScheduleRequestHistoryTableRoute}?locationId=${locationId}` });
  };

  return (
    <>
      <ScheduleRequestContext.Provider value={providerValue}>
        <Accordion
          css={{ ...(isEmptyScheduleRequestList && { background: theme.colors.white }) }}
          variant='blank'
          chevronSize={16}
          startingValue=''
          size='large'
        >
          <InfinitePaginatedList
            height={isEmptyScheduleRequestList ? DEFAULT_MIN_HEIGHT : height}
            infiniteQueryProps={scheduleRequestListQuery}
            emptyStateConfig={{
              type: 'schedule_requests',
              description: t('No New Requests'),
              header: '',
            }}
            renderListItem={({ listItem }) => {
              return (
                <Accordion.Item
                  trackingId={ScheduleRequestsTrackingIds.requestListItemAccordion}
                  style={{ borderBottom: `1px solid ${theme.colors.neutral10}` }}
                  value={listItem?.id ?? ''}
                  key={listItem?.id ?? ''}
                >
                  <Accordion.Header>
                    <ScheduleRequestListItemHeader
                      name={getScheduleRequestFullName(listItem)}
                      appointmentTime={listItem?.dateTime ?? ''}
                      personPhone={listItem?.schedulee?.phoneNumber ?? ''}
                      locationId={listItem.locationId ?? ''}
                      updateSelectedScheduleRequest={() => {
                        setSelectedScheduleRequest(listItem);
                        scheduleRequestModalProps.openModal();
                      }}
                      scheduleRequestId={listItem.id ?? ''}
                      css={{ borderBottom: `1px solid ${theme.colors.neutral40}` }}
                    />
                  </Accordion.Header>

                  <Accordion.Body>
                    <ScheduleRequestDetails
                      scheduleRequestId={listItem.id ?? ''}
                      locationId={listItem.locationId ?? ''}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              );
            }}
          />
        </Accordion>
      </ScheduleRequestContext.Provider>
      {selectedScheduleRequest && (
        <ScheduleRequestModalContext.Provider value={scheduleRequestModalContextValue}>
          <ScheduleRequestModal />
        </ScheduleRequestModalContext.Provider>
      )}
      {!scheduleRequestListQuery.isLoading && (
        <div css={viewHistoryContainerStyles}>
          <TextLink
            css={{ display: 'flex', alignItems: 'center' }}
            size='medium'
            weight='bold'
            onClick={navigateToScheduleRequestHistory}
            trackingId={ScheduleRequestsTrackingIds.viewHistoryBtn}
          >
            <Icon name='external-link-small' css={{ marginRight: theme.spacing(0.5) }} />
            {t('View History')}
          </TextLink>
        </div>
      )}
    </>
  );
};

const viewHistoryContainerStyles = css({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
  background: theme.colors.white,
  borderTop: `1px solid ${theme.colors.neutral10}`,
});
