import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';

export const SelfUpgradeLandingPageTrackingIds = {
  upgradeAction: 'landing-page-upgrade-action-button',
  scheduleCallAction: 'landing-page-schedule-call-action-button',
  requestUpgradeAction: 'landing-page-request-upgrade-action-button',
  freeTrialAction: 'landing-page-free-trial-action-button',
  multiLocationBannerUpgradeAction: 'landing-page-multi-location-banner-upgrade-action-button',
  viewDemoAction: 'landing-page-view-demo-action-button',
};

export const FeatureUpgradeModalTrackingIds = {
  upgradeAction: 'feature-upgrade-modal-upgrade-action-button',
  scheduleCallAction: 'feature-upgrade-modal-schedule-call-action-button',
  requestUpgradeAction: 'feature-upgrade-modal-request-upgrade-action-button',
  freeTrialAction: 'feature-upgrade-modal-free-trial-action-button',
  dismissAction: 'feature-upgrade-modal-dismiss-action-button',
};

export const SelfUpgradeModalTrackingIds = {
  nextAction: 'self-upgrade-modal-location-selection-next-action-button',
  upgradeAction: 'self-upgrade-modal-upgrade-action-button',
  scheduleCallAction: 'self-upgrade-modal-schedule-call-action-button',
  dismissAction: 'self-upgrade-modal-dismiss-action-button',
  termsCheckbox: 'self-upgrade-modal-terms-checkbox',
  viewTermsAction: 'self-upgrade-modal-view-terms-action-button',
  onboardingCallAction: 'self-upgrade-modal-onboarding-call-action-button',
  successModalCloseAction: 'self-upgrade-success-modal-close-action-button',
  successModalOkayAction: 'self-upgrade-success-modal-okay-action-button',
  failModalCloseAction: 'self-upgrade-fail-modal-close-action-button',
  billingLink: 'self-upgrade-modal-billing-link',
  weaveHelpLink: 'self-upgrade-modal-weave-help-link',
};

export const FreeTrialModalTrackingIds = {
  freeTrialAction: 'free-trial-modal-free-trial-action-button',
  dismissAction: 'free-trial-modal-dismiss-action-button',
};

export const RequestUpgradeModalTrackingIds = {
  requestUpgradeAction: 'request-upgrade-modal-request-upgrade-action-button',
  dismissAction: 'request-upgrade-modal-dismiss-action-button',
};

export const FormsUpgradeModalTrackingIds = {
  additionalDigitizationCheckbox: 'forms-upgrade-modal-additional-digitization-checkbox',
};

export const UpgradePromotionIconTrackingIdsMap: Partial<Record<Feature, string>> = {
  [Feature.FORMS]: 'upgrade-promotion-icon-forms',
};

export const DemoModalTrackingIds = {
  dismissAction: 'demo-modal-dismiss-action-button',
  upgradeAction: 'demo-modal-upgrade-action-button',
  scheduleCallAction: 'demo-modal-schedule-call-action-button',
  requestUpgradeAction: 'demo-modal-request-upgrade-action-button',
  freeTrialAction: 'demo-modal-free-trial-action-button',
};

export const WivPromotionTrackingIds = {
  upgradeAction: 'wiv-promotion-upgrade-action-button',
  scheduleCallAction: 'wiv-promotion-schedule-call-action-button',
  requestUpgradeAction: 'wiv-promotion-request-upgrade-action-button',
  freeTrialAction: 'wiv-promotion-free-trial-action-button',
};

export const getFeatureWiseTrackingId = (feature: Feature, trackingId: string) => {
  return `${feature}-${trackingId}`;
};
