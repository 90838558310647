import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { AnalyticsFeatureFlags } from '..';

enum SourceTypeEnum {
  INTEGRATED_PMS = 0,
  CUSTOM_CONTACT = 1,
  CSV = 2,
}

type SyncApp = {
  LocationID: string;
  SourceID: string;
  SourceType: SourceTypeEnum;
};

type SyncAppList = {
  data: SyncApp[];
};

export const useShowAppointmentAnalyticsNav = () => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const hasAnalyticsAclAccess = hasSchemaACL(locationId, Permission.ANALYTICS_READ);
  const hasAppointmentAnalyticsAclAccess = hasSchemaACL(locationId, Permission.ANALYTICS_READ_APPOINTMENT);
  const isAppointmentAnalyticsActive = useHasFeatureFlag(AnalyticsFeatureFlags.appointmentAnalytics);
  const isAppointmentAnalyticsDemoDataActive = useHasFeatureFlag(AnalyticsFeatureFlags.appointmentAnalyticsDemoData);

  // Check if location is eligible (only integrated locations are eligible for appointment analytics)
  const { data: isIntegratedLocation } = useScopedQuery({
    queryKey: 'appointment-analytics-support-for-location',
    queryFn: () => http.get<SyncAppList>('/desktop/v1/syncapp/list'),
    select: ({ data }) => {
      return !!data.filter(({ SourceType }) => SourceType === SourceTypeEnum.INTEGRATED_PMS).length;
    },
    staleTime: 1000 * 60 * 5,
  });

  return (
    (!!isIntegratedLocation &&
      (hasAppointmentAnalyticsAclAccess || hasAnalyticsAclAccess) &&
      isAppointmentAnalyticsActive) ||
    isAppointmentAnalyticsDemoDataActive
  );
};
