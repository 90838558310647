import { removeNonDigits } from '@frontend/phone-numbers';

export type DialerState = {
  dialNumber: string;
};

export const dialerInitialState: DialerState = { dialNumber: '' };

export type DialerAction =
  | { type: 'add'; payload: string }
  | { type: 'set'; payload: string }
  | { type: 'delete' }
  | { type: 'reset' };

export const dialerReducer = (state = dialerInitialState, action: DialerAction) => {
  switch (action.type) {
    case 'add':
      return { dialNumber: removeNonDigits(state.dialNumber + action.payload) };
    case 'set':
      return { dialNumber: removeNonDigits(action.payload) };
    case 'delete':
      return { dialNumber: state.dialNumber.slice(0, -1) };
    case 'reset':
      return { dialNumber: '' };
    default:
      return state;
  }
};
