import { memo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { theme } from '@frontend/theme';
import { Text, styles as dsStyles } from '@frontend/design-system';

interface Props {
  name: string;
  date: string;
}

const getTime = (dateTime: string) => {
  const date = dayjs(dateTime);
  const isToday = dayjs().isSame(date, 'day');

  // TODO :: Check if we need to humanize the date or return custom strings like "Yesterday, 12:00p" for more ranges
  return isToday ? date.format('hh:mma') : date.format('DD MMM, YYYY hh:mma');
};

export const Author = memo(({ date, name }: Props) => {
  return (
    <div css={styles.wrapper}>
      <Text css={dsStyles.truncate} weight='bold'>
        {name}
      </Text>
      <Text as='time' color='subdued' size='medium'>
        {getTime(date)}
      </Text>
    </div>
  );
});

Author.displayName = 'Author';

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
    width: fit-content;
  `,
};
