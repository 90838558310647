import { useMemo } from 'react';
import { FormsWritebacks } from '@frontend/api';
import { SettingWritebackStatusCode } from '../../setting-status/setting-status.types';

interface UseSettingWritebackStatusCodeProps {
  showSwitch: boolean;
  settingStatus: FormsWritebacks.Types.WritebackSettingStatusCode;
  isNotApplicable: boolean;
}

interface UseSettingWritebackStatusCodeResults {
  settingWritebackStatusCode: SettingWritebackStatusCode;
}

export const useSettingWritebackStatusCode = ({
  isNotApplicable,
  settingStatus,
  showSwitch,
}: UseSettingWritebackStatusCodeProps): UseSettingWritebackStatusCodeResults => {
  const settingWritebackStatusCode = useMemo<SettingWritebackStatusCode>(() => {
    if (settingStatus === 'Fulfilled') {
      return settingStatus;
    }

    if (isNotApplicable) {
      return 'Not Applicable';
    }

    if (!showSwitch) {
      return 'Writeback disabled';
    }

    return settingStatus;
  }, [isNotApplicable, settingStatus, showSwitch]);

  return { settingWritebackStatusCode };
};
