import { MouseEventHandler } from 'react';
import { PaymentButton, usePaymentAction } from '@frontend/contact-actions-payment';
import { CallButton, usePhoneCallAction } from '@frontend/contact-actions-place-call';
import { QuickFillButton, useQuickFillAction } from '@frontend/contact-actions-quickfill';
import { ReviewActionHooks, ReviewActionComponents } from '@frontend/contact-actions-review-invitation';
import { SendFormButton } from '@frontend/contact-actions-send-form';
import { MessageButton, useMessageAction } from '@frontend/contact-actions-send-message';
import { useContactPanelShallowStore } from '@frontend/shared';
import { ActionButton, UserIcon } from '@frontend/design-system';

export const dialUrl = `/desktop/v1/phones/dial`;
export const endUrl = `/desktop/v1/phones/end`;
export const holdUrl = `/desktop/v1/phones/hold`;
export const resumeUrl = `/desktop/v1/phones/resume`;

type Action = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const useViewAction = (personId?: string): Action => {
  const { setPersonId } = useContactPanelShallowStore('setPersonId');

  return {
    onClick: () => {
      if (personId) {
        setPersonId(personId);
      }
    },
  };
};

const ViewButton = ({ personId }: { personId?: string }) => {
  const trackingId = 'global-action-button-contact-profile';
  const { onClick } = useViewAction(personId);

  return (
    <ActionButton disabled={!personId} onClick={onClick} trackingId={trackingId}>
      <UserIcon size={16} />
    </ActionButton>
  );
};

export const actions = {
  usePhoneCallAction,
  useQuickFillAction,
  useMessageAction,
  useViewAction,
  usePaymentAction,
  useReviewAction: ReviewActionHooks.useReviewAction,
};

export const ContactActions = {
  /**
   * To use this button properly, you can provide this contexts-
   * - If this action is associated with a contact or person, provide it in the `person` prop, or at least the `personId`.
   * - If this action is associated with a specific phone number, or to provide a default phone number selection, provide it in the `phoneNumber` prop.
   * - if you want the call to happen from a specific office number, pass useOutboundNumber as true and provide the outbound number in the `outboundNumber` prop.
   */
  Call: CallButton,
  QuickFill: QuickFillButton,
  /**
   * To use this button properly, provide as much context as possible:
   * - If this action is associated with a contact or person, provide it in the `person` prop, or at least the `personId`.
   * - If this action is associated with a specific phone number, or to provide a default phone number selection, provide it in the `phoneNumber` prop.
   * - If this action is associated with a specific locationId, only departments/outbound numbers from that location will be available to select.
   * - If this action is associated with a specific department or outbound sms number, provide the associated departmentId in the `departmentId` prop.
   */
  Message: MessageButton,
  View: ViewButton,
  Payments: PaymentButton,
  Review: ReviewActionComponents.ReviewActionButton,
  SendForm: SendFormButton,
};
