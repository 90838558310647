import { css } from '@emotion/react';

export const containerStyle = css`
  display: flex;
  flex-basis: 40%;
`;

export const textFieldContainerStyle = css`
  flex: 1;
`;

export const textFieldStyle = css`
  width: 100%;
`;
