import { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { Draft } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import { RelatedID } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { MessagesHooks } from '@frontend/api-messaging';
import { useDraft, SMSDraftTypes } from '@frontend/api-sms-draft';
import { SmsReplyAssistantV1 } from '@frontend/api-sms-reply-assistant';
import { SMSSignatureV1 } from '@frontend/api-sms-signature';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { InboxTemplatesModals, useInboxTemplateFlows, TemplatesPopover } from '@frontend/inbox-templates';
import { LinkData, getRelatedIdsFromLinkData } from '@frontend/integrated-messaging';
import { useNotificationContext } from '@frontend/notifications';
import { useMatchMedia } from '@frontend/responsiveness';
import { SchemaSMSNotifierService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { SignatureModal as TextSignatureModal } from '@frontend/signature-modal';
import { SuperTextAreaRef, SuperTextarea } from '@frontend/super-textarea';
import { ThreadBodyComponents } from '@frontend/thread-body';
import { sentry } from '@frontend/tracking';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  useDebouncedValue,
  useModalControl,
  useFormField,
  KeyNames,
  useAlert,
  CalendarIcon,
  PlayIcon,
  usePopoverMenu,
} from '@frontend/design-system';
import { InboxType } from '../../../types';
import { AddonsPopover, getAddonsAction } from './addons-action';
import { getAiAssistantAction, getSignatureAction, getTemplateAction, SMSAiResponseActions } from './extra-actions';

type OnScheduleArgs = {
  message: string;
  scheduledTime: Date | string;
  relatedIds: RelatedID[];
  isPaused: boolean;
};

type SMSSuperTextAreaProps = {
  onSend: (message: string, relatedIds?: RelatedID[]) => void;
  onSchedule: ({ message, scheduledTime, relatedIds }: OnScheduleArgs) => void;
  hasMedia: boolean;
  openImageUploadModal: () => void;
  personPhone: string;
  threadId: string;
  groupId: string;
  disabled?: boolean;
  disableSend?: boolean;
  disableScheduleMessage?: boolean;
  scheduledMessageForEdit?: ScheduledSms;
  onCancelEdit: () => void;
  selectedPerson?: Person;
  locationPhone?: string;
  deleteScheduledMessage?: (smsId: string) => void;
  departmentId?: string;
  canGenerateAiResponse?: boolean;
  inboxType?: InboxType;
};

export const SMSSuperTextArea = ({
  onSend,
  onSchedule,
  hasMedia,
  openImageUploadModal,
  personPhone,
  threadId,
  groupId,
  disabled,
  disableSend,
  disableScheduleMessage,
  scheduledMessageForEdit,
  onCancelEdit,
  selectedPerson,
  locationPhone,
  deleteScheduledMessage,
  departmentId,
  canGenerateAiResponse,
  inboxType,
}: SMSSuperTextAreaProps) => {
  const notificationTrayContext = useNotificationContext();
  const { t } = useTranslation('inbox');
  const { selectedOrgId, selectedLocationIds, getLocationName } = useAppScopeStore();
  const [bodyValue, setBodyValue] = useState('');
  const user = getUser();
  const alert = useAlert();
  const [isTyping, setIsTyping] = useState(false);
  const debouncedBodyValue = useDebouncedValue(bodyValue, 1000);
  const { data: signature = '' } = SMSSignatureV1.Queries.useGetSignatureQuery({
    request: {
      groupId,
      userId: user?.userID ?? '',
    },
    options: {
      enabled: !!user?.userID,
      select: (data) => {
        return data.signature.signature;
      },
    },
  });
  const { mutateAsync: updateSignature } = SMSSignatureV1.Mutations.useUpsertSignatureMutation();
  const textSignatureModalControls = useModalControl();
  const signatureField = useFormField({ type: 'text', value: '' });
  const [signatureFieldValue, setSignatureFieldValue] = useState(signature);
  const debouncedSignatureValue = useDebouncedValue(signatureFieldValue, 1000);
  const superTextAreaRef = useRef<SuperTextAreaRef>(null);
  const disableSending = disabled || disableSend || signatureFieldValue !== signature;
  const scheduleMessageModalControls = useModalControl();
  const updateScheduledMessages = MessagesHooks.useUpdateScheduledMessages();
  const scheduledMessageIsInPast = scheduledMessageForEdit && scheduledMessageForEdit.sendAt < new Date().toISOString();

  const trackingIds = {
    send: `${InboxPrefixes.Thread}-send-button`,
    emoji: `${InboxPrefixes.Thread}-emoji-button`,
    image: `${InboxPrefixes.Thread}-image-button`,
    template: `${InboxPrefixes.Thread}-template-button`,
  };

  const isInboxOrDraftsRoute = [InboxType.INBOX, InboxType.DRAFTS].some((item) => inboxType === item);
  const {
    canShowAiAssistant,
    canShowAiResponseActions,
    canShowResponseFeedback,
    isGenerateAiResponseLoading,
    generateAiResponse,
    closeAiResponseActions,
    undoResponse,
    redoResponse,
    canUndo,
    canRedo,
    submitFeedback,
    shouldOpenInRefinementMode,
  } = SmsReplyAssistantV1.Hooks.useReplyAssistant({
    textForRefinement: bodyValue,
    isAiAssistantAvailable: !!canGenerateAiResponse && isInboxOrDraftsRoute,
    aiResponseActionCallback: (aiResponseGenerated: string | undefined) => {
      setBodyValue(aiResponseGenerated || '');
    },
    onAiResponseGenerationError: () => {
      alert.error(t('Error generating reply. Please try again.'));
    },
    onFeedbackSubmitSuccess: () => {
      alert.success(t('Feedback submitted successfully.'));
    },
    onFeedbackSubmitError: () => {
      alert.error(t('Error submitting feedback. Please try again.'));
    },
  });
  const locationName = getLocationName(groupId);

  const isAiResponseContainerMedium = useMatchMedia({ maxWidth: 1300 }) && canShowAiResponseActions;

  const { aggregateValue: aiReplyAssistantFeatureFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'nwx:sms-reply-assistant',
    aggregationStrategy: FeatureFlagQueries.AggregationStrategy.ANY,
    locationIds: selectedLocationIds,
  });

  const templatesPopoverControls = usePopoverMenu<HTMLButtonElement | HTMLAnchorElement>({
    placement: 'top-start',
    middlewareOptions: { offset: 1 },
  });
  const addonsPopoverControls = usePopoverMenu<HTMLButtonElement | HTMLAnchorElement>({
    placement: 'top-start',
    middlewareOptions: { offset: 2 },
  });

  const submitButtonLabel = scheduledMessageForEdit ? (
    <div
      css={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 2),
      }}
    >
      {scheduledMessageIsInPast ? (
        <>
          <CalendarIcon size={16} />
          {t('Reschedule')}
        </>
      ) : (
        <>
          <PlayIcon size={16} />
          {t('Resume Send')}
        </>
      )}
    </div>
  ) : (
    <Icon name='send' size={16} />
  );
  const [previousThreadId, setPreviousThreadId] = useState(threadId);
  const templatesFlows = useInboxTemplateFlows({
    groupId,
    person: selectedPerson,
    personPhone,
    locationPhone: locationPhone ?? '',
    onRenderTemplate: ({ bodyValue, linkData }) => {
      setRelatedIdLinkData([]);
      if (linkData?.length) addRelatedIdLinkData(linkData);
      setBodyValue(bodyValue);
    },
  });
  /* Don't use `_setRelatedIdLinkData`. Instead use its wrapper function `setRelatedIdLinkData` which handles updating the draft when used. */
  const [relatedIdLinkData, _setRelatedIdLinkData] = useState<LinkData[]>([]);
  const [needsBodyFromDraft, setNeedsBodyFromDraft] = useState(true);

  const setRelatedIdLinkData = useCallback<typeof _setRelatedIdLinkData>(
    (newVal) => {
      if (typeof newVal === 'function') {
        _setRelatedIdLinkData((prev) => {
          const calculatedValue = newVal(prev);

          if (!scheduledMessageForEdit && !needsBodyFromDraft)
            shallowUpdateDraft({
              threadId: previousThreadId,
              locationPhone: locationPhone ?? '',
              departmentId: departmentId ?? '',
              personPhone,
              draft: {
                relatedIds: convertLinkDataToDraftRelatedIds(calculatedValue),
              },
            });

          return calculatedValue;
        });
      } else {
        _setRelatedIdLinkData(newVal);
        if (!scheduledMessageForEdit && !needsBodyFromDraft)
          shallowUpdateDraft({
            threadId: previousThreadId,
            locationPhone: locationPhone ?? '',
            departmentId: departmentId ?? '',
            personPhone,
            draft: {
              relatedIds: convertLinkDataToDraftRelatedIds(newVal),
            },
          });
      }
    },
    [_setRelatedIdLinkData, scheduledMessageForEdit, needsBodyFromDraft]
  );

  const {
    draftQuery,
    shallowUpdate: shallowUpdateDraft,
    deleteMutation: { mutate: deleteDraft },
  } = useDraft(
    {
      threadId,
      userId: user?.userID ?? '',
      orgId: selectedOrgId,
      locationId: groupId,
      groupIds: selectedLocationIds,
    },
    {
      draftQueryOptions: {
        onSuccess: (draft) => {
          if (needsBodyFromDraft && draft?.threadId === threadId) {
            setBodyValue(draft.body);
            addRelatedIdLinkData(convertDraftRelatedIdsToLinkData(draft.relatedIds));
            setNeedsBodyFromDraft(false);
          }
        },
      },
    }
  );

  const convertLinkDataToDraftRelatedIds = (linkData: LinkData[]): RelatedID[] =>
    linkData.reduce<RelatedID[]>((acc, { relatedType, relatedId }) => {
      if (relatedType && relatedId) acc.push({ id: relatedId, type: relatedType });
      return acc;
    }, []);

  const convertDraftRelatedIdsToLinkData = (relatedIds: SMSDraftTypes.DraftRelatedID[]): LinkData[] =>
    relatedIds.reduce<LinkData[]>((acc, { type, id }) => {
      if (id && type) acc.push({ link: '', relatedId: id, relatedType: type });
      return acc;
    }, []);

  const addRelatedIdLinkData = (newLinkData: LinkData[]) => {
    setRelatedIdLinkData((prev) => {
      const filteredPrev = prev.filter(
        ({ relatedId }) => !newLinkData.some(({ relatedId: newId }) => newId === relatedId)
      );
      const result = [...filteredPrev, ...newLinkData];

      if (scheduledMessageForEdit || needsBodyFromDraft) return result;
      shallowUpdateDraft({
        threadId: previousThreadId,
        locationPhone: locationPhone ?? '',
        departmentId: departmentId ?? '',
        personPhone,
        draft: {
          relatedIds: result.reduce<RelatedID[]>((acc, { relatedType, relatedId }) => {
            if (relatedType && relatedId) acc.push({ id: relatedId, type: relatedType });
            return acc;
          }, []),
        },
      });

      return result;
    });
  };

  const handleBodyValueDebounce = (newVal: string) => {
    if (isTyping) {
      updateTyping(false);
    }

    if (!newVal) {
      onCancelEdit();
    }

    // Handle draft update if not editing a scheduled message
    if (scheduledMessageForEdit || needsBodyFromDraft) return;
    const draftUpdate: Partial<Draft> = {
      body: newVal,
    };
    draftUpdate.relatedIds =
      newVal.length === 0 ? [] : getRelatedIdsFromLinkData({ bodyValue: newVal, linkData: relatedIdLinkData });
    shallowUpdateDraft({
      threadId: previousThreadId,
      draft: draftUpdate,
      locationPhone: locationPhone ?? '',
      departmentId: departmentId ?? '',
      personPhone,
    });
  };

  const handleScheduleWithModal = () => {
    scheduleMessageModalControls.openModal();
  };

  const handleSendNow = () => {
    if (scheduledMessageForEdit)
      updateScheduledMessages.remove({ threadId, groupId, smsId: scheduledMessageForEdit.id });
    handleSend(bodyValue);
  };

  // const initScheduleTimestamp = scheduledMessageForEdit ? new Date(scheduledMessageForEdit.sendAt) : undefined;

  const handleUpdateSignature = (newVal: string) => {
    if (!user?.userID) return;
    updateSignature(
      { signature: newVal, groupId, userId: user?.userID ?? '' },
      {
        onSuccess: () => {
          alert.success(t('Signature successfully updated.'));
        },
        onError: () => {
          alert.error(t('An error occurred while updating your signature. Please try again.'));
        },
      }
    );
  };

  const handleSend = (message: string) => {
    updateTyping(false);
    const relatedIds = getRelatedIdsFromLinkData({
      bodyValue: message,
      linkData: relatedIdLinkData,
    });
    onSend(message, relatedIds);
    deleteDraft({
      orgId: selectedOrgId,
      threadId,
      userId: user?.userID ?? '',
    });
    setBodyValue(scheduledMessageForEdit ? draftQuery.data?.body ?? '' : '');
    if (!scheduledMessageForEdit) setRelatedIdLinkData([]);
  };

  const handleSchedule = (args: OnScheduleArgs) => {
    onSchedule(args);
    setBodyValue(scheduledMessageForEdit ? draftQuery.data?.body ?? '' : '');
    if (!scheduledMessageForEdit) setRelatedIdLinkData([]);
  };

  const updateTyping = (val: boolean) => {
    if (val !== isTyping && user?.userID) {
      try {
        SchemaSMSNotifierService.IndicateTyping({
          isTyping: val,
          personPhone,
          threadId,
          userId: user?.userID ?? '',
          groupId,
        });
      } catch (error) {
        sentry.warn({
          error,
          topic: 'messages',
          addContext: {
            name: `SMS SuperTextArea Context (updateTyping to ${val})`,
            context: {
              val,
              request: {
                isTyping: val,
                personPhone,
                threadId,
                userId: user?.userID ?? '',
                groupId,
              },
              errorMessage: JSON.stringify(error),
            },
          },
        });
      }
    }
    setIsTyping(val);
  };

  useEffect(() => {
    handleBodyValueDebounce(debouncedBodyValue);
  }, [debouncedBodyValue]);

  useEffect(() => {
    if (!bodyValue) setRelatedIdLinkData((prev) => prev.filter(({ link }) => !!link));
    if (!isTyping && !!bodyValue) {
      if (scheduledMessageForEdit && bodyValue === scheduledMessageForEdit.body) return;
      updateTyping(true);
    }
  }, [bodyValue]);

  useEffect(() => {
    if (debouncedSignatureValue !== signature) {
      handleUpdateSignature(debouncedSignatureValue);
      superTextAreaRef.current?.focus();
    }
  }, [debouncedSignatureValue]);

  useEffect(() => {
    if (signatureFieldValue !== signature && signatureFieldValue === debouncedSignatureValue) {
      setSignatureFieldValue(signature);
      if (!signature) superTextAreaRef.current?.focus();
    }
  }, [signature]);

  useEffect(() => {
    if (scheduledMessageForEdit) {
      setBodyValue(scheduledMessageForEdit.body);
      setRelatedIdLinkData(
        scheduledMessageForEdit.relatedIds.map(({ type, id }) => ({ relatedId: id, relatedType: type, link: '' }))
      );
    } else {
      setBodyValue(draftQuery.data?.body ?? '');
      setRelatedIdLinkData(convertDraftRelatedIdsToLinkData(draftQuery.data?.relatedIds ?? []));
    }
  }, [scheduledMessageForEdit]);

  useEffect(() => {
    if (threadId !== previousThreadId) {
      shallowUpdateDraft({
        threadId: previousThreadId,
        locationPhone: locationPhone ?? '',
        departmentId: departmentId ?? '',
        personPhone,
        draft: {
          body: bodyValue,
        },
      });
      setPreviousThreadId(threadId);
    }
    setNeedsBodyFromDraft(true);
    closeAiResponseActions();
    superTextAreaRef.current?.focus();
  }, [threadId]);

  useEffect(() => {
    if (needsBodyFromDraft && draftQuery.data?.threadId === threadId) {
      setBodyValue(draftQuery.data.body);
      addRelatedIdLinkData(convertDraftRelatedIdsToLinkData(draftQuery.data.relatedIds));
      setNeedsBodyFromDraft(false);
    }
  }, [needsBodyFromDraft, draftQuery.data?.threadId]);

  return (
    <>
      <SuperTextarea
        id='sms-super-textarea'
        ref={superTextAreaRef}
        value={bodyValue}
        onChange={setBodyValue}
        submitButtonLabel={submitButtonLabel}
        onFocus={() => {
          notificationTrayContext.markThreadNotificationsAsRead(threadId, groupId);
        }}
        styleProp={
          scheduledMessageForEdit
            ? css`
                background: theme.colors.warning5;
              `
            : undefined
        }
        css={
          scheduledMessageForEdit && {
            '.multi-action-btn button': {
              background: 'green',
              border: `1px solid ${theme.colors.success50}`,
            },
            '.multi-action-btn button[aria-haspopup="menu"]': {
              borderLeft: `${theme.spacing(2 / 8)} solid ${theme.colors.success70}`,
            },
            '#sms-super-textarea': {
              background: theme.colors.warning5,
            },
          }
        }
        onSubmit={
          scheduledMessageForEdit && !scheduledMessageIsInPast
            ? (message: string) => {
                if (scheduledMessageIsInPast) {
                  handleScheduleWithModal();
                } else {
                  const relatedIds = getRelatedIdsFromLinkData({
                    bodyValue: message,
                    linkData: relatedIdLinkData,
                  });
                  handleSchedule({
                    message,
                    scheduledTime: scheduledMessageForEdit.sendAt,
                    relatedIds,
                    isPaused: scheduledMessageForEdit?.pausable ?? true,
                  });
                }
              }
            : handleSend
        }
        placeholder={t('Reply to...')}
        sendOnEmpty={hasMedia}
        disabled={disabled}
        disableSend={disableSending}
        hasSubmit={disableScheduleMessage}
        isBodyValueLoading={isGenerateAiResponseLoading}
        multiActionButtonProps={
          disableScheduleMessage
            ? undefined
            : {
                actionType: 'dialog',
                Dialog: (
                  <ThreadBodyComponents.ScheduleMessagePopover
                    onSendNow={handleSendNow}
                    deleteScheduledMessage={deleteScheduledMessage}
                    scheduledMessageForEdit={scheduledMessageForEdit}
                    disableForm={disableScheduleMessage}
                    onSubmit={(scheduledTime: Date | string, isPaused: boolean) => {
                      const relatedIds = getRelatedIdsFromLinkData({ bodyValue, linkData: relatedIdLinkData });
                      handleSchedule({ message: bodyValue, scheduledTime, relatedIds, isPaused });
                      deleteDraft({ orgId: selectedOrgId, userId: user?.userID ?? '', threadId });
                      setBodyValue(scheduledMessageForEdit ? draftQuery.data?.body ?? '' : '');
                    }}
                  />
                ),
                dialogProps: {
                  css: {
                    padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
                    borderRadius: theme.borderRadius.small,
                  },
                },
                primaryButtonStyles: submitButtonLabel
                  ? css`
                      padding: 0;
                      > svg {
                        margin: 0;
                      }
                    `
                  : {},
              }
        }
        onImageClick={!isAiResponseContainerMedium ? openImageUploadModal : undefined}
        data-trackingid={`${InboxPrefixes.Thread}-reply-textarea`}
        trackingIds={trackingIds}
        subtext={
          !!signature &&
          !scheduledMessageForEdit && (
            <input
              {...signatureField}
              value={signatureFieldValue}
              onChange={(e) => {
                setSignatureFieldValue(e?.target.value);
              }}
              css={{
                // This padding lines up the text with the text inside the text area above it
                padding: theme.spacing(0.25),
                border: 'none',
                outline: 'none',
                color: theme.font.colors.light,
                width: '100%',
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(1),
              }}
              name={t('Signature')}
              onKeyDownCapture={(e) => {
                if (!bodyValue) {
                  if (e.key === KeyNames.Enter || e.key === KeyNames.Tab) {
                    e.preventDefault();
                    superTextAreaRef.current?.focus();
                  }
                }
              }}
              data-trackingid={`${InboxPrefixes.Signature}-inline-signature-edit`}
              autoComplete='off'
            />
          )
        }
        addOnsStyles={{ maxWidth: '84%' }}
        disableEmojis={isAiResponseContainerMedium} // disable on screens with width less than 1300px
        extraActions={
          isAiResponseContainerMedium
            ? [getAddonsAction({ disabled: !!(disabled || disableSend), addonsPopoverControls, t })]
            : [
                getSignatureAction({ disabled: !!(disabled || disableSend), signature, t, textSignatureModalControls }),
                getTemplateAction({ disabled: !!(disabled || disableSend), templatesPopoverControls, t }),
                getAiAssistantAction({
                  disabled: !!(disabled || disableSend),
                  canShowAiAssistant,
                  aiReplyAssistantFeatureFlag,
                  generateAiResponse,
                  threadId,
                  groupId,
                  personName: (selectedPerson?.preferredName || selectedPerson?.firstName) ?? '',
                  locationName,
                  bodyValue,
                  t,
                }),
              ].filter(Boolean)
        }
        customPanel={
          <SMSAiResponseActions
            {...{
              threadId,
              groupId,
              patientName: (selectedPerson?.preferredName || selectedPerson?.firstName) ?? '',
              locationName,
              bodyValue,
              userId: user?.userID ?? '',
              canShowAiResponseActions,
              isGenerateAiResponseLoading,
              canShowResponseFeedback,
              closeAiResponseActions,
              canUndo,
              canRedo,
              shouldOpenInRefinementMode,
              generateAiResponse,
              undoResponse,
              redoResponse,
              submitFeedback,
            }}
          />
        }
        minRows={!!signature && !scheduledMessageForEdit ? 3 : 4}
      />
      <TextSignatureModal
        {...textSignatureModalControls.modalProps}
        initSignatureValue={signature}
        onSubmit={(newVal) => {
          textSignatureModalControls.closeModal();
          handleUpdateSignature(newVal);
        }}
      />
      <InboxTemplatesModals {...templatesFlows.modalsProps} />
      <TemplatesPopover
        getMenuProps={templatesPopoverControls.getMenuProps}
        getItemProps={templatesPopoverControls.getItemProps}
        templateItems={templatesFlows.extraActions}
      />
      <AddonsPopover // For devices with screen width less than 1300px
        getMenuProps={addonsPopoverControls.getMenuProps}
        getItemProps={addonsPopoverControls.getItemProps}
        addEmojiToSendBody={(emojiData) => {
          emojiData.unified !== '' && superTextAreaRef.current?.insertText(emojiData.emoji, setBodyValue);
        }}
        onImageClick={openImageUploadModal}
        disabled={!!(disabled || disableSend)}
        trackingIds={trackingIds}
        extraActionItems={[
          getSignatureAction({ disabled: !!(disabled || disableSend), signature, t, textSignatureModalControls }),
          ...templatesFlows.extraActions,
        ]}
        close={addonsPopoverControls.close}
      />
    </>
  );
};
