import { FC, useRef, useEffect } from 'react';
import { FormPacketSelectorHooks } from '@frontend/form-packet-selector';
import { useSendFormsContext, useSendFormsStore } from '../../../../providers';

const AttachDocuments: FC = () => {
  const ignoreFirstUpdateRef = useRef(true);
  const containerRef = useRef<HTMLDivElement>(null); // Ref for the container
  const { locationId } = useSendFormsContext();
  const { acceptedFiles, rejectedFiles, setAcceptedFiles, setRejectedFiles, setIsUploadInProgress } = useSendFormsStore(
    ['acceptedFiles', 'rejectedFiles', 'setAcceptedFiles', 'setRejectedFiles', 'setIsUploadInProgress']
  );

  const {
    FormAttachments,
    formAttachmentProps,
    acceptedFiles: acceptedFilesUpdate,
    rejectedFiles: rejectedFilesUpdate,
    isUploadingFiles,
  } = FormPacketSelectorHooks.useFormAttachments({
    locationId,
    initialAcceptedFiles: acceptedFiles,
    initialRejectedFiles: rejectedFiles,
  });

  useEffect(() => {
    // Ignore the first update to prevent the initial state from being set twice
    if (ignoreFirstUpdateRef.current) {
      ignoreFirstUpdateRef.current = false;
      return;
    }

    // Update the store with the latest accepted and rejected files
    setAcceptedFiles(acceptedFilesUpdate);
    setRejectedFiles(rejectedFilesUpdate);
    setIsUploadInProgress(isUploadingFiles);

    // Scroll to the bottom of the container when new files are accepted
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [acceptedFilesUpdate, rejectedFilesUpdate]);

  return (
    <div ref={containerRef}>
      <FormAttachments {...formAttachmentProps} />
    </div>
  );
};

export default AttachDocuments;
