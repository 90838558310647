import { useQuery } from '@frontend/react-query-helpers';
import {
  GetLineKeyOptionsResponse,
  LineKey,
  LineKeyType_Enum,
  LocalContact,
} from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { LineKeysApi } from '@frontend/api-line-keys';
import { useEffect, useMemo } from 'react';
import { DropdownField, TextField, useFormField } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { LineKeyCardDropdownLabel } from '../constants';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useLineKeyContext, useLineKeyState } from '../store/line-key-provider';

type ExtensionOptionsType = Partial<GetLineKeyOptionsResponse>;

export const Content = ({ cardId, ...rest }: LineKey & { cardId: string }) => {
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const locationId = locationIds?.[0] ?? '';
  const { parkSlotNumber, extensionId, dataEndpointId, lineKeyType } = rest;

  const { data: lineKeyOptions } = useQuery({
    queryKey: ['line-keys', 'line-key-options', locationId],
    queryFn: () => LineKeysApi.GetLineKeyOptions(locationId),
  });

  const extensionOptions = {
    userExtensions: lineKeyOptions?.userExtensions,
    voicemailExtensions: lineKeyOptions?.voicemailExtensions,
    officeExtensions: lineKeyOptions?.officeExtensions,
  };

  switch (lineKeyType) {
    case LineKeyType_Enum.PARK_SLOT: {
      return <ParkSlot cardId={cardId} slotNumber={parkSlotNumber} />;
    }
    case LineKeyType_Enum.DATA_ENDPOINT:
    case LineKeyType_Enum.PERSONAL_VOICEMAIL: {
      const id = extensionId ?? dataEndpointId;
      return <DisabledSlot slotId={id} type={lineKeyType} />;
    }
    case LineKeyType_Enum.LOCAL_CONTACT: {
      return <ContactSlot cardId={cardId} contactOptions={lineKeyOptions?.localContacts} contactId={rest.contactId} />;
    }
    default: {
      return (
        <ExtensionSlot
          cardId={cardId}
          extensionOptions={extensionOptions}
          extensionId={extensionId}
          type={lineKeyType}
        />
      );
    }
  }
};

const ExtensionSlot = ({
  type,
  extensionId,
  extensionOptions,
  cardId,
}: {
  type: LineKeyType_Enum;
  extensionId: string;
  extensionOptions: ExtensionOptionsType;
  cardId: string;
}) => {
  const extensionDropdownProps = useFormField({ type: 'dropdown', value: extensionId }, []);
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { finalLineKeys, setFinalLineKeys } = useLineKeyState(['finalLineKeys', 'setFinalLineKeys']);
  const { lineKeyOptions: mappedLineKeyOptions } = useLineKeyContext();
  const options =
    type === LineKeyType_Enum.USER_EXTENSION
      ? extensionOptions.userExtensions
      : type === LineKeyType_Enum.OFFICE_EXTENSION
      ? extensionOptions.officeExtensions
      : extensionOptions.voicemailExtensions;

  return (
    <DropdownField
      {...extensionDropdownProps}
      label={t('{{name}}', { name: LineKeyCardDropdownLabel[type] })}
      name='extension-line-key-dropdown'
      css={css`
        margin-top: ${theme.spacing(2)};
      `}
      onChange={({ value }) => {
        const found = mappedLineKeyOptions?.[value];

        if (!!finalLineKeys && !!found) {
          setFinalLineKeys({
            ...finalLineKeys,
            [cardId]: {
              ...finalLineKeys[cardId],
              extensionId: value,
              name: found?.name ?? '',
            },
          });
        }

        extensionDropdownProps.onChange({ name: 'extension-line-key-dropdown', value });
      }}
    >
      {options?.map((extension) => {
        if (!extension) {
          return;
        }
        return (
          <DropdownField.Option
            // disabled={extensionIds?.includes(extension?.extensionId)}
            key={extension?.extensionId}
            value={extension?.extensionId}
          >
            {extension.name + ' '} {type === LineKeyType_Enum.USER_EXTENSION ? '' : extension.extensionNumber}
          </DropdownField.Option>
        );
      })}
    </DropdownField>
  );
};

const ContactSlot = ({
  contactId,
  cardId,
  contactOptions,
}: {
  contactId: string;
  cardId: string;
  contactOptions?: LocalContact[];
}) => {
  const contactDropdownProps = useFormField({ type: 'dropdown', value: contactId }, []);
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { finalLineKeys, setFinalLineKeys } = useLineKeyState(['finalLineKeys', 'setFinalLineKeys']);
  const { lineKeyOptions: mappedLineKeyOptions } = useLineKeyContext();

  return (
    <DropdownField
      {...contactDropdownProps}
      label={t('{{name}}', { name: LineKeyCardDropdownLabel['LOCAL_CONTACT'] })}
      name='contact-line-key-dropdown'
      css={css`
        margin-top: ${theme.spacing(2)};
      `}
      onChange={({ value }) => {
        const found = mappedLineKeyOptions?.[value];

        if (!!finalLineKeys && !!found) {
          setFinalLineKeys({
            ...finalLineKeys,
            [cardId]: {
              ...finalLineKeys[cardId],
              contactId: value,
              name: found?.name ?? '',
            },
          });
        }

        contactDropdownProps.onChange({ name: 'extension-line-key-dropdown', value });
      }}
    >
      {contactOptions?.map(({ name, number, contactId }) => {
        return (
          <DropdownField.Option key={contactId} value={contactId || ''}>
            {name + ' ' + formatPhoneNumber(number)}
          </DropdownField.Option>
        );
      })}
    </DropdownField>
  );
};

const DisabledSlot = ({ type, slotId }: { type: LineKeyType_Enum; slotId: string }) => {
  const disabledDropdownProps = useFormField({ type: 'dropdown', value: slotId }, []);
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });

  return (
    <DropdownField
      {...disabledDropdownProps}
      disabled={true}
      label={t('{{name}}', { name: LineKeyCardDropdownLabel[type] })}
      name='disabled-line-key-dropdown'
      css={css`
        margin-top: ${theme.spacing(2)};
      `}
    >
      <DropdownField.Option value={slotId}>{disabledDropdownProps.value}</DropdownField.Option>
    </DropdownField>
  );
};

const ParkSlot = ({ slotNumber, cardId }: { slotNumber: number; cardId: string }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { finalLineKeys, setFinalLineKeys } = useLineKeyState(['finalLineKeys', 'setFinalLineKeys']);

  const existingParkSlotIndexes =
    Object.values(finalLineKeys ?? {}).reduce(
      (acc, lineKey) => ({
        ...acc,
        [lineKey.parkSlotNumber ?? 'empty']: true,
      }),
      {} as Record<number, boolean>
    ) ?? {};

  const parkSlots = useMemo(() => {
    /* Minimum of 12 slots but max of 99 */
    const slots = 12 * Math.ceil(Object.keys(existingParkSlotIndexes).length / 12);

    return Array.from({ length: Math.min(slots, 99) }, (_, index) => {
      const parkSlotExists = existingParkSlotIndexes[index + 1];
      return (
        <DropdownField.Option disabled={!!parkSlotExists} value={`${index + 1}`} key={index + 1}>
          {`Hold ${index + 1}`}
        </DropdownField.Option>
      );
    });
  }, [existingParkSlotIndexes]);

  const parkSlotDropdownProps = useFormField({ type: 'dropdown', value: slotNumber.toString() }, []);

  return (
    <DropdownField
      {...parkSlotDropdownProps}
      label={t('{{name}}', { name: LineKeyCardDropdownLabel['PARK_SLOT'] })}
      name='line-key-dropdown'
      css={css`
        margin-top: ${theme.spacing(2)};
      `}
      onChange={({ value }) => {
        parkSlotDropdownProps.onChange({ name: value, value });
        if (!!finalLineKeys) {
          setFinalLineKeys({
            ...finalLineKeys,
            [cardId]: {
              ...finalLineKeys[cardId],
              parkSlotNumber: +value,
              name: `Hold ${value}`,
            },
          });
        }
      }}
    >
      {parkSlots.map((slot) => slot)}
    </DropdownField>
  );
};

export const LineKeyName = ({
  name,
  cardId,
  setError,
}: {
  name: string;
  cardId: string;
  setError: (val: boolean) => void;
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { finalLineKeys, setFinalLineKeys } = useLineKeyState(['finalLineKeys', 'setFinalLineKeys']);
  const lineKeyLabelProps = useFormField(
    {
      type: 'text',
      value: name,
      required: true,
      validateOnChange: true,
    },
    [name]
  );

  useEffect(() => {
    if (!!finalLineKeys) {
      setFinalLineKeys({
        ...finalLineKeys,
        [cardId]: {
          ...finalLineKeys[cardId],
          name: lineKeyLabelProps.value,
        },
      });
    }
  }, [lineKeyLabelProps.value]);

  useEffect(() => {
    if (!!lineKeyLabelProps.error) setError(true);
    else setError(false);
  }, [lineKeyLabelProps.error]);

  return (
    <TextField
      {...lineKeyLabelProps}
      label={t('Line Key Label')}
      name={`line-key-name-dropdown-${name}`}
      error={t('A Line Key Label is required for the device display')}
      css={css`
        margin-top: ${theme.spacing(2)};
      `}
    ></TextField>
  );
};
