import { FC } from 'react';
import {
  FormPacketSelectorComponents,
  FormPacketSelectorHooks,
  FormPacketSelectorTypes,
} from '@frontend/form-packet-selector';
import { useSendFormsContext, useSendFormsStore } from '../../../providers';
import { formPacketContainer } from './select-document.styles';

const { useFormPacketSelector } = FormPacketSelectorHooks;
const { FormPacketList, FormPacketFilters } = FormPacketSelectorComponents;

const SelectDocumentStep: FC = () => {
  const { locationId } = useSendFormsContext();
  const { setFormOrPacketToSend } = useSendFormsStore(['setFormOrPacketToSend']);
  const { selectorProps } = useFormPacketSelector({
    groupId: locationId,
    onSelect: (item, type) => {
      switch (type) {
        case FormPacketSelectorTypes.Category.FORMS: {
          const { id, reviewRequired, name, formPmsId } = item as FormPacketSelectorTypes.FormV2;
          setFormOrPacketToSend({
            id: id ?? '',
            type: FormPacketSelectorTypes.Category.FORMS,
            reviewRequired: reviewRequired ?? false,
            name: name ?? '',
            isNonEditable: !!formPmsId,
          });
          break;
        }

        case FormPacketSelectorTypes.Category.PACKETS: {
          const { id, reviewRequired, name, forms = [] } = item as FormPacketSelectorTypes.PacketV2;
          const formIds: string[] = [];

          for (const form of forms) {
            if (form.id) {
              formIds.push(form.id);
            }
          }

          setFormOrPacketToSend({
            id: id ?? '',
            type: FormPacketSelectorTypes.Category.PACKETS,
            reviewRequired: reviewRequired ?? false,
            name: name ?? '',
            isNonEditable: false,
            formIds,
          });
          break;
        }
      }
    },
  });

  return (
    <>
      <FormPacketFilters {...selectorProps.formPacketFiltersProps} />
      <div css={formPacketContainer}>
        <FormPacketList {...selectorProps.formPacketListProps} />
      </div>
    </>
  );
};

export default SelectDocumentStep;
