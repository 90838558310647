import { useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ScheduleTypes } from '@frontend/api-schedule';
import { SuccessGraphic, WeaveLogo } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text, PrimaryButton } from '@frontend/design-system';

interface Props {
  reset: () => void;
  setFormDetails: (formDetails: ScheduleTypes.FormDetails) => void;
  formDetails: ScheduleTypes.FormDetails | undefined;
}

export default function Success({ reset, formDetails, setFormDetails }: Props) {
  const { t } = useTranslation('schedule');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const execute = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('postrequest');
    setFormDetails?.({
      ...formDetails,
      captchaToken: token,
    });
  };

  const handleReCaptchaVerify = useCallback(() => execute(), [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <div css={styles.success}>
      <SuccessGraphic css={styles.successSVG} />
      <Heading as='h1'>{t('Request Submitted')}</Heading>
      <Text css={styles.successText}>
        {t('Your appointment request has been received and is pending review by our office staff.')}
      </Text>
      <PrimaryButton onClick={reset}>{t('Book Again')}</PrimaryButton>
      <div css={styles.weaveInfo}>
        <Text as='span' css={styles.weaveText}>
          {t('Scheduling powered by')}
        </Text>
        <WeaveLogo />
      </div>
    </div>
  );
}

const styles = {
  success: css({
    margin: 'auto',
    textAlign: 'center',
    h1: {
      textAlign: 'center',
      marginTop: theme.spacing(4),
      marginBottom: 0,
    },
    button: {
      width: 'fit-content',
    },
  }),
  successSVG: css({
    maxWidth: 350,
    marginTop: theme.spacing(1),
  }),
  successText: css({
    margin: theme.spacing(0, 2, 3, 2),
  }),
  weaveInfo: css({
    marginTop: theme.spacing(5),
    color: theme.colors.neutral50,
    svg: {
      color: theme.colors.neutral50,
      margin: theme.spacing(0.5),
      verticalAlign: 'text-bottom',
      height: theme.spacing(2),
      width: 'fit-content',
    },
  }),
  weaveText: css({
    color: theme.colors.neutral50,
  }),
};
