import { SchemaIO, SchemaManualSMSScheduledService } from '@frontend/schema';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { useQueryClient } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { Optional } from 'ts-toolbelt/out/Object/Optional';
import { queryKeys } from '../inbox-queries';
import { useInvalidateInboxList } from './use-invalidate-inbox-list';

type ThreadResponse = SchemaIO<(typeof SchemaManualSMSScheduledService)['GetThread']>['output'];
type ShallowUpdateType = Compulsory<Optional<ScheduledSms>, 'id' | 'locationId' | 'threadId'>;

export const useUpdateScheduledMessages = () => {
  const queryClient = useQueryClient();
  const invalidateInboxList = useInvalidateInboxList();

  const shallowUpdate = (sms: ShallowUpdateType, ignoreNullish = true) => {
    if (!sms.threadId || !sms.locationId || !sms.id) return;
    const filteredSms: ShallowUpdateType = ignoreNullish
      ? (Object.fromEntries(
          Object.entries(sms).filter(([key, value]) =>
            key !== 'locationId' && key !== 'id' && key !== 'threadId' ? value !== undefined && value !== null : true
          )
        ) as ShallowUpdateType)
      : sms;

    const queryKey = queryKeys.listThreadScheduledMessages(filteredSms.locationId, filteredSms.threadId);
    const queryExists = !!queryClient.getQueryState(queryKey, { exact: true })?.data;
    if (queryExists)
      queryClient.setQueryData<ThreadResponse | undefined>(queryKey, (oldData) => {
        if (!oldData) return oldData;

        return {
          scheduledSmss: oldData.scheduledSmss.map((message) =>
            message.id === filteredSms.id ? { ...message, ...filteredSms } : message
          ),
        };
      });
  };

  const upsert = (sms: ScheduledSms) => {
    const queryKey = queryKeys.listThreadScheduledMessages(sms.locationId, sms.threadId);
    const queryExists = !!queryClient.getQueryState(queryKey, { exact: true })?.data;
    if (queryExists)
      queryClient.setQueryData<ThreadResponse | undefined>(queryKey, (oldData) => {
        if (!oldData) return { scheduledSmss: [sms] };

        const messageExists = oldData.scheduledSmss.some((message) => message.id === sms.id);
        if (messageExists) {
          return {
            scheduledSmss: oldData.scheduledSmss.map((message) => (message.id === sms.id ? sms : message)),
          };
        }

        return {
          scheduledSmss: [...oldData.scheduledSmss, sms],
        };
      });
  };

  const remove = ({ threadId, groupId, smsId }: { threadId: string; groupId: string; smsId: string }) => {
    const queryKey = queryKeys.listThreadScheduledMessages(groupId, threadId);
    const queryExists = !!queryClient.getQueryState(queryKey, { exact: true })?.data;
    if (queryExists)
      queryClient.setQueryData<ThreadResponse | undefined>(queryKey, (oldData) => {
        if (!oldData) return oldData;

        return {
          scheduledSmss: oldData.scheduledSmss.filter((message) => message.id !== smsId),
        };
      });
    invalidateInboxList();
  };

  const invalidateQuery = ({ threadId, groupId }: { threadId: string; groupId: string }) => {
    const queryKey = queryKeys.listThreadScheduledMessages(groupId, threadId);
    queryClient.invalidateQueries(queryKey);
  };

  return {
    shallowUpdate,
    upsert,
    remove,
    invalidateQuery,
  };
};
