import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Heading, Text, useAlert } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { useSoftphoneRouter } from '../providers/softphone-router-provider';
import { CallInformation } from '../components/call-information';
import { Button, Page, Divider } from '@frontend/generic-dialpad-accessories';
import { HeldCall } from '../components/hold/held-call';
import { isEstablishedCall, isTerminatedCall } from '../types';
import { useCallerInfo } from '../hooks/use-caller-info';
import { SettingsRow } from '../components/settings-row';
import { MultipleHeldCalls } from '../components/hold/multiple-held-calls';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';

export const PageTransferHomeMobile = () => {
  const { t } = useTranslation('softphone');
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const setPrimaryCall = useSoftphoneCallState((ctx) => ctx.setPrimaryCall);
  const { toggleHold } = useSoftphoneCallActions((ctx) => ctx);
  const currentTransfer = useSoftphoneCallState((ctx) => ctx.currentTransfer);
  const setCurrentRoute = useSoftphoneRouter((ctx) => ctx.setAttendedTransferRoute);
  const mergeCalls = useSoftphoneCallActions((ctx) => ctx.mergeCalls);
  const { confirmWarmTransfer } = useSoftphoneCallActions((ctx) => ctx);
  const toggleMute = useSoftphoneCallActions((ctx) => ctx.toggleMute);
  const isMuted = useSoftphoneCallState((ctx) => ctx.isMuted);
  const alert = useAlert();
  const endCall = useSoftphoneCallActions((ctx) => ctx.endCall);

  const disabled = !primaryCall || !isEstablishedCall(primaryCall) || isTerminatedCall(primaryCall);
  const establishedCalls = useSoftphoneCallState((ctx) => ctx.establishedCalls);
  const callInfo = useCallerInfo({
    uri: primaryCall?.remoteParty.uri ?? '',
    displayName: primaryCall?.remoteParty.displayName,
  });

  const otherHeldCalls = establishedCalls.filter(
    (call) =>
      !!call.onHoldSince &&
      primaryCall?.id !== call.id &&
      call.id !== currentTransfer?.transferTarget?.id &&
      currentTransfer?.initialCall?.id !== call.id
  );
  const onHoldForTransfer =
    currentTransfer?.initialCall.id === primaryCall?.id
      ? currentTransfer?.transferTarget
      : currentTransfer?.initialCall;

  const swap = () => {
    if (!onHoldForTransfer || !isEstablishedCall(onHoldForTransfer)) {
      return;
    }
    if (!primaryCall || !isEstablishedCall(primaryCall)) {
      return;
    }

    toggleHold(primaryCall, true);
    toggleHold(onHoldForTransfer, false);
    setPrimaryCall(onHoldForTransfer.id);
  };

  if (!currentTransfer?.initialCall || !currentTransfer?.transferTarget) {
    return <Heading color='white'>{t('No Transfer Target')}</Heading>;
  }
  return (
    <Page>
      <PageHeaderWraps hasBackButton={false}></PageHeaderWraps>
      <Page.Body
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin: ${theme.spacing(3, 0)};
          gap: ${theme.spacing(8)};
        `}
      >
        <CallInformation
          textAlign='center'
          displayName={primaryCall?.remoteParty.displayName}
          uri={primaryCall?.remoteParty.uri ?? ''}
        />
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: ${theme.spacing(2)} ${theme.spacing(3)};
            justify-content: center;

            div:last-child {
              margin: auto;
              grid-column: span 4; /* Span two columns (centered) */
            }
          `}
        >
          <Button.Round
            onClick={(e) => {
              e.stopPropagation();
              toggleMute(!isMuted);
            }}
            trackingId='softphone-transfer-mute'
            selected={isMuted}
            disabled={disabled}
            iconName={isMuted ? 'mute' : 'microphone'}
            label={t('Mute')}
          ></Button.Round>
          <Button.Round
            disabled={disabled}
            onClick={() => setCurrentRoute('keypad')}
            iconName={'dial'}
            label={t('Keypad')}
          ></Button.Round>
          <Button.Round
            disabled={disabled || !isEstablishedCall(currentTransfer?.transferTarget) || !!otherHeldCalls?.length}
            onClick={() => {
              if (isEstablishedCall(currentTransfer?.transferTarget)) {
                mergeCalls([currentTransfer?.initialCall, currentTransfer?.transferTarget]);
              } else {
                alert.error(t('Invalid transfer destination'));
              }
            }}
            iconName={'merge'}
            label={t('Merge Calls')}
            trackingId='softphone-active-mergecalls'
          />
          <Button.Round
            disabled={disabled}
            onClick={() => {
              confirmWarmTransfer(callInfo.title);
            }}
            color='blue'
            iconName={'call-forward'}
            label={t('Transfer')}
          ></Button.Round>
          <Button.Round
            trackingId='softphone-transfer-end'
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              if (primaryCall) endCall(primaryCall);
            }}
            color='red'
            iconName={'phone-hangup'}
          ></Button.Round>
        </div>
      </Page.Body>
      <Page.Footer>
        {onHoldForTransfer && isEstablishedCall(onHoldForTransfer) && (
          <>
            <Divider />
            <Text
              color='white'
              css={css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              {t('On Hold For Warm Transfer')}
            </Text>
            <HeldCall call={onHoldForTransfer} onClick={() => swap()}></HeldCall>
          </>
        )}
        {!!otherHeldCalls.length && otherHeldCalls.length === 1 && (
          <>
            <Divider />
            <HeldCall call={otherHeldCalls[0]} disabled></HeldCall>
          </>
        )}
        {!!otherHeldCalls.length && otherHeldCalls.length > 1 && (
          <MultipleHeldCalls disabled numOfHeldCalls={otherHeldCalls.length} />
        )}
        <Divider />
        <SettingsRow />
      </Page.Footer>
    </Page>
  );
};
