import { useMemo, useState, useEffect } from 'react';
import { RadialBar, RadialBarCustomLayerProps } from '@nivo/radial-bar';
import { theme } from '@frontend/theme';

/**
 * TODO:
 * This component will be updated to a more reusable component.
 * This is just a temporary component to be used in the dashboard.
 */
export const DashboardAnalyticsProgressCircle = ({
  label,
  percentage = 0,
  trackingId,
}: {
  label: string;
  percentage: number;
  trackingId?: string;
}) => {
  const [initialAnimation, setInitialAnimation] = useState(false);
  const CustomLabel = useMemo(() => Metric(label), [label, percentage]);

  useEffect(() => {
    if (initialAnimation) return;

    setTimeout(() => {
      setInitialAnimation(true);
    }, 100);
  }, []);

  return (
    <RadialBar
      data-trackingid={trackingId}
      width={124}
      height={124}
      valueFormat={(v) => `${v}%`}
      maxValue={100}
      startAngle={0}
      isInteractive={false}
      endAngle={360}
      cornerRadius={100}
      innerRadius={0.7}
      tracksColor={theme.colors.neutral10}
      colors={[theme.colors.primary50]}
      animate={true}
      transitionMode={'startAngle'}
      data={[
        {
          id: 'default',
          data: [{ x: 'percentage', y: initialAnimation ? percentage : 0 }],
        },
      ]}
      layers={['tracks', 'bars', CustomLabel]}
    />
  );
};

const Metric =
  (label: string) =>
  ({ center, bars }: RadialBarCustomLayerProps) => {
    const labelSplit = label.split(' ');
    const baseY = center[1] - labelSplit.length * 7;

    return (
      <>
        {labelSplit.map((text, i) => (
          <text
            key={i}
            x={center[0]}
            y={baseY + i * 15}
            textAnchor='middle'
            dominantBaseline='central'
            style={{
              fontSize: theme.font.size.small,
              fontWeight: theme.font.weight.bold,
              fill: theme.font.colors.default,
              fontFamily: theme.font.family,
            }}
          >
            {text}
          </text>
        ))}
        <text
          x={center[0]}
          y={center[1] + 23}
          textAnchor='middle'
          dominantBaseline='central'
          style={{
            fontSize: theme.font.size.medium,
            fill: theme.font.colors.default,
            fontFamily: theme.font.family,
          }}
        >
          {bars[0].formattedValue}
        </text>
      </>
    );
  };
