import { useCallback } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useNotificationSettingsShallowStore, useSmsTagNotification } from '@frontend/notifications';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { GetWebsocketEventHandler, SmsTagEventPayload, useWebsocketEventSubscription } from '@frontend/websocket';

export const useWebsocketSmsTagEventSubscription = () => {
  const { create } = useSmsTagNotification();
  const { t } = useTranslation('notifications');
  const { notificationSettings } = useNotificationSettingsShallowStore('notificationSettings');

  const handler: GetWebsocketEventHandler<'SMSNotification'> = useCallback(
    (payload) => {
      //this handler will fire for any smsNotification event,
      //but we're only going to invoke the handler here if it matches an action from the SmsTag payload actions
      //casting the payload to only that type gives us exhaustive switch capability to make sure that happens
      //note this would be a lot easier/simpler if the websocket payload would contain a discriminating key for this
      const { params } = payload as SmsTagEventPayload;
      switch (params.action) {
        case 'tagCreated':
        case 'tagDeleted': {
          const notification = {
            id: typeof payload.id === 'number' ? payload.id.toString() : payload.id,
            timestamp: Date.now(),
            type: 'sms-tag',
            payload: {
              eventType: params.action === 'tagCreated' ? 'added' : 'removed',
            },
            state: {
              paused: false,
              timeout: notificationSettings.durationMs,
              status: 'unread',
            },
          } satisfies GetWeavePopNotificationByType<'sms-tag'>;
          create(notification, {
            os: {
              title: params.action === 'tagCreated' ? t('Tag Added') : t('Tag Removed'),
              body: params.tag_name,
            },
          });

          return;
        }
        case 'tagAddedToThreads':
        case 'tagRemovedFromThreads':
        case 'tagUpdated':
          return;
        default: {
          const _exhaustive: never = params.action;
          return _exhaustive;
        }
      }
    },
    [notificationSettings, create]
  );

  return useWebsocketEventSubscription('SMSNotification', handler);
};
