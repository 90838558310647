import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';
import { InboxFilter, InboxFilters, InboxStatus, InboxType } from '../types';

export const DEFAULT_INBOX_FILTER_VALUE: InboxFilters = {
  statuses: [],
  tags: [],
  departments: [],
};

export interface InboxFiltersStore {
  inboxFilters: InboxFilters;
  setInboxFilters: (val: InboxFilters) => void;
  clearInboxFilters: () => void;
}

export const useInboxFiltersStore = createStoreWithPersistAndSubscribe<InboxFiltersStore>(
  (set) => ({
    inboxFilters: DEFAULT_INBOX_FILTER_VALUE,
    setInboxFilters: (args: InboxFilters) =>
      set(
        (state) => {
          state.inboxFilters = { ...DEFAULT_INBOX_FILTER_VALUE, ...state.inboxFilters, ...args };
        },
        false,
        'SET_INBOX_FILTERS'
      ),
    clearInboxFilters: () => {
      set(
        (state) => {
          state.inboxFilters = DEFAULT_INBOX_FILTER_VALUE;
        },
        false,
        'CLEAR_INBOX_FILTERS'
      );
    },
  }),
  { name: 'InboxFiltersStorage', version: 1 },
  { name: 'InboxFilters', trace: true }
);

export const useInboxFiltersShallowStore = createShallowStore(useInboxFiltersStore);

const sortState = (state: Partial<InboxFilters>): Partial<InboxFilters> =>
  Object.fromEntries(Object.entries(state).map(([key, value]) => [key, [...value].sort()])) as Partial<InboxFilters>;

export const inboxFiltersAreEqual = (a: Partial<InboxFilters>, b: Partial<InboxFilters>): boolean => {
  const sortedA = sortState(a);
  const sortedB = sortState(b);
  return JSON.stringify(sortedA) === JSON.stringify(sortedB);
};

export const useInboxFilterStatusLabels = (): Record<InboxStatus, string> => {
  const { t } = useTranslation('inbox');
  return {
    [InboxStatus.UNREAD]: t('Unread'),
    [InboxStatus.UNREPLIED]: t('Unreplied'),
    [InboxStatus.READ]: t('Read'),
    [InboxStatus.REPLIED]: t('Replied'),
    [InboxStatus.ERROR]: t('Error'),
  };
};

export const useInboxTypeAndFiltersMapping = (inboxType: InboxType) => {
  const { inboxFilters } = useInboxFiltersShallowStore('inboxFilters', 'setInboxFilters');

  const hiddenFilters: InboxFilter[] | undefined = inboxType === InboxType.DRAFTS ? ['statuses', 'tags'] : undefined;

  const hasFilters = useMemo(() => {
    const visibleFilters = Object.keys(inboxFilters).filter((key) => !hiddenFilters?.includes(key as InboxFilter));
    return visibleFilters.some((key) => {
      const value = inboxFilters[key as InboxFilter];
      return Array.isArray(value) ? value.length > 0 : !!value;
    });
  }, [inboxFilters]);

  return { hiddenFilters, hasFilters };
};
