import { TagsUtils, TagsV2 } from '@frontend/api-tag';
import { ChatItem, PopoverMenu, PopoverMenuItem, usePopoverMenu, Text, TagChipProps } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { TagSelectionTypes } from '@frontend/tag-selection';
import { theme } from '@frontend/theme';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { FeedbackType } from '@weave/schema-gen-ts/dist/schemas/office-assistant/v1/office_assistant_service.pb';
import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { MouseEventHandler, useCallback, useMemo } from 'react';

type SmartTagFeedbackFn = (context: Pick<TagSelectionTypes.FeedbackContext, 'feedbackType' | 'smartTagId'>) => void;

type SMSTagProps = {
  smsId: string;
  tagId: string;
  showFeedbackPopover: boolean;
  removeTag: () => void;
  onSmartTagFeedbackClick: SmartTagFeedbackFn;
  tag?: Tag;
  isAutoApplied: boolean;
};

export const SMSTag = ({
  tagId,
  showFeedbackPopover,
  removeTag,
  tag: providedTag,
  smsId,
  isAutoApplied,
  onSmartTagFeedbackClick,
}: SMSTagProps) => {
  const { selectedOrgId } = useAppScopeStore();
  const tagQuery = TagsV2.Queries.useGetTagQuery({
    request: {
      id: tagId,
      orgId: selectedOrgId,
    },
    options: {
      enabled: !providedTag,
    },
  });

  const tag = providedTag ?? tagQuery.data?.tag;

  if (!tag) return null;

  if (isAutoApplied)
    return (
      <WeaveAssistantTag
        tag={tag}
        removeTag={removeTag}
        showFeedbackPopover={showFeedbackPopover}
        smsId={smsId}
        onFeedbackClick={onSmartTagFeedbackClick}
      />
    );
  return <ManualTag tag={tag} removeTag={removeTag} />;
};

SMSTag.displayName = ChatItem.Tag.displayName;

type RightElementProps = Pick<TagChipProps<MouseEventHandler<HTMLButtonElement>>, 'rightElement'>;

type ManualTagProps = {
  tag: Tag;
  removeTag: () => void;
};

const ManualTag = ({ tag, removeTag }: ManualTagProps) => (
  <ChatItem.Tag
    color={TagsUtils.convertStringToTagColor(tag.color)}
    isRemovable
    onClick={removeTag}
    onRemoveClick={removeTag}
    trackingId={`${InboxPrefixes.Tag}-dismiss-tag`}
  >
    {tag.name}
  </ChatItem.Tag>
);

ManualTag.displayName = ChatItem.Tag.displayName;

type WeaveAssistantTagProps = {
  smsId: string;
  tag: Tag;
  removeTag: () => void;
  onFeedbackClick: SmartTagFeedbackFn;
  showFeedbackPopover: boolean;
};

const WeaveAssistantTag = ({ tag, removeTag, showFeedbackPopover, smsId, onFeedbackClick }: WeaveAssistantTagProps) => {
  const { t } = useTranslation('inbox');

  const { getItemProps, getTriggerProps, getMenuProps, isOpen } = usePopoverMenu({
    placement: 'bottom-start',
  });

  const rightElementProps = useMemo<RightElementProps>(
    () =>
      showFeedbackPopover
        ? {
            rightElement: (
              <Icon
                name='caret-down-tiny'
                css={{
                  transition: 'transform 0.2s ease-in-out',
                  transform: `rotate(${isOpen ? 180 : 0}deg)`,
                }}
              />
            ),
          }
        : {},
    [showFeedbackPopover, Icon, isOpen]
  );

  const handleFeedbackClick = useCallback(
    (feedbackType: 'positive' | 'negative') => {
      onFeedbackClick({
        smartTagId: tag.id,
        feedbackType: feedbackType === 'positive' ? FeedbackType.FEEDBACK_TYPE_GOOD : FeedbackType.FEEDBACK_TYPE_BAD,
      });
    },
    [tag.id, smsId, onFeedbackClick]
  );

  return (
    <>
      <ChatItem.Tag
        color={TagsUtils.convertStringToTagColor(tag.color)}
        isRemovable={!showFeedbackPopover}
        onClick={showFeedbackPopover ? getTriggerProps().onClick : removeTag}
        onRemoveClick={showFeedbackPopover ? undefined : removeTag}
        leftElement={<Icon name='aiassistant-small' />}
        css={{
          // We need to add a bit more width to account for the left icon
          maxWidth: 150,
        }}
        {...rightElementProps}
        trackingId={`${InboxPrefixes.Tag}-${showFeedbackPopover ? 'smart-tag-menu-open' : 'dismiss-smart-tag'}`}
        ref={getTriggerProps().ref}
      >
        {tag.name}
      </ChatItem.Tag>
      {showFeedbackPopover && (
        <PopoverMenu {...getMenuProps()}>
          <Text
            css={{
              padding: theme.spacing(1, 2),
            }}
          >
            {t('Is this helpful?')}
          </Text>
          <PopoverMenuItem
            {...getItemProps({
              index: 0,
              onClick: () => {
                handleFeedbackClick('positive');
              },
            })}
            css={{
              cursor: 'pointer',
            }}
          >
            <Icon name='feedback-small' />
            <Text>{t('Helpful')}</Text>
          </PopoverMenuItem>
          <PopoverMenuItem
            {...getItemProps({
              index: 1,
              onClick: () => {
                handleFeedbackClick('negative');
              },
            })}
            css={{
              cursor: 'pointer',
            }}
          >
            <Icon name='feedback-bad-small' />
            <Text>{t('Not Helpful')}</Text>
          </PopoverMenuItem>
          <PopoverMenuItem
            {...getItemProps({
              index: 2,
              onClick: removeTag,
            })}
            css={{
              borderTop: `1px solid ${theme.colors.neutral20}`,
              cursor: 'pointer',
            }}
          >
            <Icon name='x-small' />
            <Text>{t('Remove Smart Tag')}</Text>
          </PopoverMenuItem>
        </PopoverMenu>
      )}
    </>
  );
};

WeaveAssistantTag.displayName = ChatItem.Tag.displayName;
