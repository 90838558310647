import { useCallback, useEffect } from 'react';
import { ExposedError, InternetMethodConfiguration, ErrorResponse, DiscoverResult, Reader } from '@stripe/terminal-js';
import { useMutation } from 'react-query';
import { useTranslation } from '@frontend/i18n';
import { useMerchant, paymentsSentry } from '@frontend/payments-hooks';
import { PaymentsTerminalController } from '@frontend/payments-terminal-controller';
import { useTerminalShallowStore } from '../../store';
import { useTerminal } from './use-terminal';
import { useTerminalMethods } from './use-terminal-methods';

export const useDiscoverReaders = (locationId?: string) => {
  const { t } = useTranslation('payments');
  const { stripeTerminal } = useTerminal();
  // TODO: this should filter down to all selected locations and not "all locations"
  const { stripeLocationId, locationId: selectedLocationId, merchantsData } = useMerchant();
  const { setConnectionError } = useTerminalShallowStore('setConnectionError');
  const { disconnectReader, clearReaderDisplay } = useTerminalMethods();

  const selectedStripeLocationId = locationId ? merchantsData[locationId]?.stripeLocationId : stripeLocationId;

  const { mutateAsync: discoverReadersHelper } = useMutation({
    mutationFn: async ({ config }: { config: InternetMethodConfiguration }) => {
      try {
        const result = await stripeTerminal?.discoverReaders(config);

        const error = (result as ErrorResponse).error;

        if (error) {
          console.error('Error discovering terminals: ', error);
          return { error, discoveredReaders: [] };
        }

        const discoveredReaders = (result as DiscoverResult).discoveredReaders;

        if (discoveredReaders.length === 0) {
          return { discoveredReaders: [] };
        } else {
          return { discoveredReaders };
        }
      } catch (error) {
        console.error('Error discovering terminals: ', error);
        paymentsSentry.error((error as ExposedError).message, 'discoverReaders', 'Error discovering terminals');
        return { error: error as ExposedError, discoveredReaders: [] };
      }
    },
  });

  const {
    data: discoveredReaders,
    mutate: discoverReadersMutation,
    isLoading: isDiscovering,
    error: discoverReadersError,
  } = useMutation({
    mutationFn: async (overrideLocationId?: string) => {
      if (!stripeTerminal) throw { message: t('Stripe terminal instance not set') };
      const stripeLocationId = overrideLocationId
        ? merchantsData[overrideLocationId]?.stripeLocationId
        : selectedStripeLocationId;
      if (!stripeLocationId) throw { message: t('Stripe Location Id is empty') };
      const config = { simulated: false, location: stripeLocationId };
      await disconnectReader(stripeTerminal!, 'discover readers mutation');
      await clearReaderDisplay();
      const { error, discoveredReaders } = await discoverReadersHelper({ config });
      if (error) throw error;
      return discoveredReaders;
    },
  });

  const discoverReaders = useCallback(
    (overrideLocationId?: string) => {
      if (!!(selectedLocationId || overrideLocationId) && !!stripeTerminal) {
        discoverReadersMutation(overrideLocationId);
      }
    },
    [selectedStripeLocationId, stripeTerminal, merchantsData]
  );

  const autoDiscoverReadersOnMount = useCallback(() => {
    if (!discoveredReaders && !isDiscovering && !discoverReadersError) {
      discoverReaders();
    }
  }, [discoverReaders, discoveredReaders, isDiscovering, discoverReadersError]);

  useEffect(() => {
    autoDiscoverReadersOnMount();
  }, [autoDiscoverReadersOnMount]);

  const isReaderConnected = (readerId: string) => {
    if (stripeTerminal) {
      return (
        stripeTerminal.getConnectionStatus() === 'connected' && readerId === stripeTerminal.getConnectedReader()?.id
      );
    }
    return false;
  };
  const sortReaders = (readers: Reader[]) => {
    const connectedReaders: Reader[] = [];
    const onlineReaders: Reader[] = [];
    const offlineReaders: Reader[] = [];
    for (const idx in readers) {
      if (isReaderConnected(readers[idx]?.id)) {
        connectedReaders.push(readers[idx]);
      } else if (readers[idx].status === 'online') {
        onlineReaders.push(readers[idx]);
      } else {
        offlineReaders.push(readers[idx]);
      }
    }

    return [connectedReaders, onlineReaders, offlineReaders];
  };

  const {
    mutateAsync: getStoredOnlineReader,
    error: storedOnlineReaderError,
    isLoading: gettingStoredOnlineReader,
  } = useMutation<Reader | undefined, ExposedError>({
    mutationFn: async () => {
      let storedOnlineReader: Reader | undefined;
      if (locationId ?? selectedLocationId) {
        const lastConnectedReader = PaymentsTerminalController.getStoredReader(locationId ?? selectedLocationId);
        if (lastConnectedReader && discoveredReaders && !discoverReadersError) {
          storedOnlineReader = discoveredReaders?.find(
            (reader) => reader.id === lastConnectedReader.readerId && reader.status === 'online'
          );

          if (!storedOnlineReader)
            throw {
              message: t('Previously connected terminal is not online or is not registered with this location.'),
            };
        }
      }
      return storedOnlineReader;
    },
    onError: (error) => {
      setConnectionError(error);
    },
  });

  const sortedReaders = sortReaders(discoveredReaders ?? []).flat();

  return {
    discoveredReaders,
    isDiscovering,
    discoverReaders,
    discoverReadersHelper,
    discoverReadersError,
    sortReaders,
    isReaderConnected,
    getStoredOnlineReader,
    storedOnlineReaderError,
    gettingStoredOnlineReader,
    sortedReaders,
    selectedLocationId,
  };
};
