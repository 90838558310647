import React from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

type DividerProps = {
  className?: string;
  as?: React.ElementType;
  color?: string;
  margin?: string | number;
};

export const hrStyle = (color: string = theme.colors.neutral10) => css`
  width: auto;
  border: 0;
  border-top: 1px solid ${color};
  margin-top: 0;
  margin-bottom: 0;
`;

export const dividerStyle = (color: string | undefined, margin: string | number | undefined) => css`
  ${hrStyle(color)}
  margin-top: ${margin ?? theme.spacing(2)};
  margin-bottom: ${margin ?? theme.spacing(2)};
  width: 100%;
`;

export const Divider = ({ as = 'hr', className, color, margin }: DividerProps) => {
  const Component = as;
  return <Component className={className} css={dividerStyle(color, margin)} />;
};
