import { css } from '@emotion/react';
import { Icon, type IconName } from '@frontend/icons';
import { useShellTheme } from './store';
import { IconButton, type IconButtonProps } from '@frontend/design-system';

type TitleBarButtonProps = Omit<IconButtonProps, 'children' | 'label'> & {
  label?: string;
  icon: IconName;
  hasBorder?: boolean;
};
export const TitleBarButton = ({ onClick, disabled, label, icon, hasBorder, ...rest }: TitleBarButtonProps) => {
  const { colorObject } = useShellTheme('colorObject');

  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      label={label}
      size='small'
      css={css`
        -webkit-app-region: no-drag;
        color: ${colorObject.iconColor};
        transition: 0.3s ease;
        border: ${hasBorder ? `1px solid ${colorObject.iconColor}` : 'none'};

        :hover:not(:disabled) {
          background-color: ${colorObject.hover};
        }
        :focus:not(:disabled) {
          background-color: ${colorObject.hover};
        }
        :disabled {
          color: ${colorObject.iconColor};
          opacity: 0.4;
        }
      `}
      aria-label={label}
      {...rest}
    >
      <Icon name={icon} />
    </IconButton>
  );
};
