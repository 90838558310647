import { useState, Dispatch, SetStateAction, memo, useMemo } from 'react';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import CallQueueAPI from '@frontend/api-call-queue';
import SipProfileAPI from '@frontend/api-sip-profile';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { PickerLocation, useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  useAlert,
  ModalControlModalProps,
  Modal,
  Table,
  Chip,
  Text,
  SearchField,
  useFormField,
} from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { trackingId } from '../../../tracking';
import { SettingsCard } from '../../common/settings-card';
import { PhoneSettingsLocationPicker } from '../../settings/location-picker';
import { CallQueueDevice, CallQueueDeviceUpdatePayload, CallQueueState, DeviceType } from './types';

export const useAssignDeviceAPI = (tenantLocation: PickerLocation) => {
  const { t } = useTranslation('phone');

  const alerts = useAlert();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [tenantLocation.phoneTenantId, ...queryKeys.settings.listCallQueueDevices()],
    queryFn: () => SipProfileAPI.List({ tenantId: tenantLocation.phoneTenantId }),
    select: (data) => {
      return data.sipProfiles
        ? data.sipProfiles.reduce((acc, profile) => {
            if (profile.device && profile.device.deviceType !== DeviceType.MOBILE_APP) {
              acc.push({
                sipProfileId: profile.sipProfileId,
                macAddress: profile.device.macAddress ?? '',
                name: profile.device.deviceName ?? '',
                locationIds: profile.device.location ? [profile.device.location.locationId] : [],
                deviceId: profile.device.deviceId ?? '',
              });

              return acc;
            } else {
              return acc;
            }
          }, [] as CallQueueDevice[])
        : [];
    },
    staleTime: 5 * 60 * 1000,
  });

  const mutation = useMutation({
    mutationFn: (payload: CallQueueDeviceUpdatePayload) => CallQueueAPI.UpdateDevices(payload),
    onMutate: async (payload) => {
      const queryKey = [tenantLocation.phoneTenantId, ...queryKeys.settings.readCallQueue(payload.callQueueId)];

      await queryClient.cancelQueries({ queryKey });

      const previousCallQueue = queryClient.getQueryData(queryKey);
      queryClient.setQueryData<CallQueueState>(queryKey, (old) => {
        if (!old) {
          return {} as CallQueueState;
        }
        return { ...old, devices: payload.devices };
      });

      return { previousCallQueue };
    },
    onSuccess: () => {
      alerts.success(t('Devices updated successfully.'));
    },
    onError: (_err, payload, context) => {
      const queryKey = [tenantLocation.phoneTenantId, ...queryKeys.settings.readCallQueue(payload.callQueueId)];

      alerts.error(t('System failed to update devices. Please try again.'));
      queryClient.setQueryData(queryKey, context?.previousCallQueue);
    },
    onSettled: (_data, _err, payload) => {
      const queryKey = [tenantLocation.phoneTenantId, ...queryKeys.settings.readCallQueue(payload.callQueueId)];

      queryClient.invalidateQueries({ queryKey });
    },
  });

  return { query, mutation };
};

const LimitMessage = ({ show }: { show: boolean }) => {
  const { t } = useTranslation('phone');

  return show ? (
    <div style={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
      <Icon name='info-small' />
      <Text color='subdued' size='small'>
        {t('You can only have a maximum of 20 devices.')}
      </Text>
    </div>
  ) : null;
};

export const AssignDeviceModal = ({
  modalProps,
  callQueueId,
  assignedDevices,
  updateDevices,
  tenantLocation,
}: {
  modalProps: ModalControlModalProps;
  callQueueId: string;
  assignedDevices: CallQueueDevice[];
  updateDevices: (devices: CallQueueDevice[]) => void;
  tenantLocation: PickerLocation;
}) => {
  const { t } = useTranslation('phone');
  const { query, mutation } = useAssignDeviceAPI(tenantLocation);
  const [devices, setDevices] = useState<CallQueueDevice[]>(assignedDevices);

  return (
    <Modal maxWidth={650} {...modalProps}>
      <Modal.Header onClose={modalProps.onClose}>{t('Assign Devices')}</Modal.Header>
      <Modal.Body css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <Text>{t('Assign devices to this call queue to ring when a call comes in.')}</Text>
        <AssignDeviceTable
          assignedDevices={devices}
          onSelectionChange={setDevices}
          availableDevices={query.data ?? []}
          isLoading={query.isLoading}
          tenantLocation={tenantLocation}
        />
        <LimitMessage show={devices.length > 20} />
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Save')}
        disablePrimary={devices.length > 20}
        onPrimaryClick={() => {
          updateDevices(devices);
          mutation.mutate({ callQueueId, devices: devices });
          modalProps.onClose();
        }}
        primaryTrackingId={trackingId({
          context: 'setting',
          feature: 'call-queues',
          details: 'save-assigned-devices',
        })}
      />
    </Modal>
  );
};

// This memo ensures that the table does not re-render when the parent component re-renders with the same props passed to the table.
const AssignDeviceTable = memo(
  ({
    assignedDevices,
    availableDevices,
    onSelectionChange,
    isLoading,
    tenantLocation,
  }: {
    assignedDevices: CallQueueDevice[];
    availableDevices: CallQueueDevice[];
    onSelectionChange: (devices: CallQueueDevice[]) => void;
    isLoading?: boolean;
    tenantLocation: PickerLocation;
  }) => {
    const { t } = useTranslation('phone');
    const { getLocationName } = useAppScopeStore();
    const searchField = useFormField({ type: 'text' });
    const [filteredLocations, setFilteredLocations] = useState<string[]>([]);

    const filteredAvailableDevices = useMemo(() => {
      const devicesFilteredByLocations =
        filteredLocations.length > 0
          ? availableDevices.filter((device) => {
              return device.locationIds.some((locationId) => filteredLocations.includes(locationId));
            })
          : availableDevices;

      return devicesFilteredByLocations.filter((device) =>
        device.name.toLowerCase().includes(searchField.value.toLowerCase())
      );
    }, [searchField.value, availableDevices, filteredLocations]);
    const hasChildLocations = tenantLocation && tenantLocation.children?.length;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto minmax(0, 300px)', gap: theme.spacing(4) }}>
          {tenantLocation?.locationType !== 'single' ? (
            <PhoneSettingsLocationPicker
              filteredLocations={filteredLocations}
              tenantLocation={tenantLocation}
              setFilteredLocations={setFilteredLocations}
            />
          ) : null}
          <SearchField css={{ justifySelf: 'end' }} {...searchField} name='device-search' />
        </div>
        <Table
          isPaginated
          rowSelectionConfig={{
            hideBulkSelection: true,
            initialState: Object.fromEntries(assignedDevices.map((device) => [device.sipProfileId, true])),
          }}
          isSelectable
          fullHeight
          fullHeightConfig={{
            maxHeight: 300,
            minHeight: 200,
          }}
          isLoading={isLoading}
          data={filteredAvailableDevices ?? []}
          colConfig={[
            { id: 'name', accessor: 'name', Header: t('Device Name') },
            { id: 'macAddress', accessor: 'macAddress', Header: t('MAC Address'), width: 150 },
            {
              id: 'location',
              Header: t('Location'),
              accessor: (data) => {
                const locationIds = data.locationIds;
                if (!locationIds) {
                  return null;
                }
                if (locationIds.length === 1) {
                  return { label: getLocationName(locationIds[0]), type: 'single' };
                } else if (locationIds.length > 1) {
                  return { label: t('{{count}} locations', { count: locationIds.length }), type: 'multi' };
                } else {
                  return { label: '--', type: 'none' };
                }
              },
              cellRenderer: (data: { label: string; type: 'multi' | 'single' | 'none' }) => {
                if (data.type === 'none') {
                  return data.label;
                }
                return data.type === 'multi' ? (
                  <Chip.MultiChip>{data.label}</Chip.MultiChip>
                ) : (
                  <Chip.SingleChip>{data.label}</Chip.SingleChip>
                );
              },
              width: 150,
              omit: !hasChildLocations,
              sortType: (a, b) => {
                return a.values.labels.label.localeCompare(b.values.labels.label);
              },
            },
          ]}
          uniqueRowId={(row) => row.sipProfileId}
          rowActions={{
            onRowSelect: (row) => {
              const newDevices = [...assignedDevices];
              if (!row.isSelected) {
                newDevices.push(row.original);
              } else {
                const removalIndex = newDevices.findIndex((device) => device.deviceId === row.original.deviceId);
                if (removalIndex > -1) {
                  newDevices.splice(removalIndex, 1);
                }
              }
              onSelectionChange(newDevices);
            },
          }}
        />
      </div>
    );
  }
);

export const AssignDeviceStepCard = ({
  selectedDeviceCount,
  assignedDevices,
  tenantLocation,
  setState,
}: {
  selectedDeviceCount: number;
  assignedDevices: CallQueueDevice[];
  tenantLocation: PickerLocation;
  setState: Dispatch<SetStateAction<CallQueueState>>;
}) => {
  const { t } = useTranslation('phone');
  const { query } = useAssignDeviceAPI(tenantLocation);

  const updateDevices = (devices: CallQueueDevice[]) => {
    setState((state) => ({ ...state, devices: devices }));
  };

  return (
    <SettingsCard title={t('Assign Devices')} condensed>
      <AssignDeviceTable
        assignedDevices={assignedDevices}
        onSelectionChange={updateDevices}
        availableDevices={query.data ?? []}
        isLoading={false}
        tenantLocation={tenantLocation}
      />
      <LimitMessage show={selectedDeviceCount > 20} />
    </SettingsCard>
  );
};
