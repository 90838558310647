import { useCalendarEventsFormTypeShallowStore } from '../../../../hooks';
import { CalendarEventsEnums } from '../../types';
import { NewAppointmentForm } from './NewAppointmentForm';
import { OutOfOfficeEvents } from './OutofOfficeEventForm';

type CalendarEventsModalContentProps = {
  closeModal: () => void;
};

export const CalendarEventsModalContent = ({ closeModal }: CalendarEventsModalContentProps) => {
  const { selectedForm } = useCalendarEventsFormTypeShallowStore('selectedForm');
  const isNewAppointmentEvent = selectedForm === CalendarEventsEnums.APPOINTMENT_EVENT;

  const { setSelectedForm } = useCalendarEventsFormTypeShallowStore('setSelectedForm');
  const handleCloseModal = () => {
    setSelectedForm('');
    closeModal();
  };

  return (
    <>
      {isNewAppointmentEvent && <NewAppointmentForm closeModal={handleCloseModal} />}
      {!isNewAppointmentEvent && selectedForm && (
        <OutOfOfficeEvents closeModal={handleCloseModal} eventType={selectedForm} />
      )}
    </>
  );
};
