import dayjs from 'dayjs';
import { getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { DatePickerField, IconButton, SecondaryButton, useControlledField } from '@frontend/design-system';
import { useAppointmentsCalendarViewProps } from '../../../../context/AppointmentsCalendarViewContext';
import { HeaderBarTrackingIds } from './trackingIds';

const DATE_FORMAT = 'MM/DD/YYYY';

type DateSelectionProps = {
  width?: number;
};

export const DateSelection = ({ width = 240 }: DateSelectionProps) => {
  const { t } = useTranslation('schedule');

  const { setSelectedDate, selectedDate } = useAppointmentsCalendarViewProps();

  const dateField = useControlledField({
    type: 'datePicker',
    value: typeof selectedDate === 'string' ? selectedDate : selectedDate[0],
    onChange: (date) => {
      setSelectedDate(date);
    },
  });

  return (
    <>
      <div css={{ width }}>
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <SecondaryButton
            css={{
              backgroundColor: theme.colors.white,
              border: `1px solid ${theme.colors.neutral30}`,
              fontWeight: 'bold',
              marginBottom: theme.spacing(1),
              width: 'fit-content',
            }}
            onClick={() => setSelectedDate(getTodaysDate(DATE_FORMAT))}
            disabled={dayjs(String(selectedDate)).isSame(dayjs(getTodaysDate('MM/DD/YYYY')))}
            trackingId={HeaderBarTrackingIds.dateNavigationTodayButton}
          >
            {t('Today')}
          </SecondaryButton>
          <div css={{ display: 'flex', justifyContent: 'row' }}>
            <IconButton
              showLabelOnHover
              label={t('Previous Day')}
              css={{
                backgroundColor: theme.colors.white,
                border: `1px solid ${theme.colors.neutral30}`,
                fontWeight: 'bold',
                marginBottom: theme.spacing(1),
                marginRight: theme.spacing(1),
                height: theme.spacing(4),
                width: theme.spacing(4),
              }}
              onClick={() => setSelectedDate(dayjs(String(selectedDate)).subtract(1, 'day').format(DATE_FORMAT))}
              trackingId={HeaderBarTrackingIds.dateNavigationPreviousDayButton}
            >
              <Icon name='caret-left' size={24} />
            </IconButton>
            <IconButton
              showLabelOnHover
              label={t('Next Day')}
              css={{
                backgroundColor: theme.colors.white,
                border: `1px solid ${theme.colors.neutral30}`,
                fontWeight: 'bold',
                marginBottom: theme.spacing(1),
                height: theme.spacing(4),
                width: theme.spacing(4),
              }}
              onClick={() => setSelectedDate(dayjs(String(selectedDate)).add(1, 'day').format(DATE_FORMAT))}
              trackingId={HeaderBarTrackingIds.dateNavigationNextDayButton}
            >
              <Icon name='caret-right' size={24} />
            </IconButton>
          </div>
        </div>

        <DatePickerField {...dateField} name='date' label='' css={{ height: theme.spacing(8), fontWeight: 'bold' }} />
      </div>
    </>
  );
};
