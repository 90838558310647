import { createProvider } from '@frontend/store';
import { SendFormStep, SendFormsOrigin } from '../types';
import { FormsSendingExperienceStore, AppointmentInfo } from './types';

/**
 * This store (context) is used to hold the state of the WritebackWidget
 */
export const { Provider: SendFormsStoreProvider, useStore: useSendFormsStore } =
  createProvider<FormsSendingExperienceStore>()((set) => ({
    formOrPacketToSend: undefined,
    setFormOrPacketToSend: (document) => set({ formOrPacketToSend: document }),

    solicitedLink: '',
    setSolicitedLink: (solicitedLink) => set({ solicitedLink }),

    isCreatingTransientForm: false,
    setIsCreatingTransientForm: (isCreatingTransientForm) => set({ isCreatingTransientForm }),

    currentStep: SendFormStep.SELECT_CONTACT,
    setCurrentStep: (step) => set({ currentStep: step }),

    steps: [],
    setSteps: (steps) => set({ steps }),

    isSending: false,
    setIsSending: (isSending) => set({ isSending }),

    isGeneratingSolicitedLink: false,
    setIsGeneratingSolictedLink: (isGeneratingSolicitedLink) => set({ isGeneratingSolicitedLink }),

    personId: '',
    setPersonId: (personId) => set({ personId }),

    firstName: '',
    setFirstName: (firstName) => set({ firstName }),

    lastName: '',
    setLastName: (lastName) => set({ lastName }),

    fullName: '',
    setFullName: (fullName) => set({ fullName }),

    appointmentInfo: null,
    setAppointmentInfo: (info: AppointmentInfo) =>
      set(() => ({
        appointmentInfo: { ...info },
      })),
    clearAppointmentInfo: () =>
      set({
        appointmentInfo: null,
      }),

    messageMode: '',
    setMessageMode: (messageMode) => set({ messageMode }),

    appointmentId: '',
    setAppointmentId: (appointmentId) => set({ appointmentId }),

    acceptedFiles: [],
    setAcceptedFiles: (files) => set({ acceptedFiles: files }),

    rejectedFiles: [],
    setRejectedFiles: (files) => set({ rejectedFiles: files }),

    clearStore: (origin) =>
      set({
        solicitedLink: '',
        isCreatingTransientForm: false,
        currentStep: origin === SendFormsOrigin.FORMS ? SendFormStep.SELECT_CONTACT : SendFormStep.SELECT_DOCUMENT,
        isSending: false,
        isGeneratingSolicitedLink: false,
        firstName: '',
        lastName: '',
        appointmentInfo: null,
        messageMode: '',
        appointmentId: '',
        acceptedFiles: [],
        rejectedFiles: [],
        isUploadInProgress: false,
        email: '',
        emailBody: '',
        emailSubject: 'Please complete these forms',
        showMessageComponent: true,
        fullName: '',
        selectedDeliveryOption: undefined,
      }),

    email: '',
    setEmail: (email) => set({ email }),

    emailBody: '',
    setEmailBody: (emailBody) => set({ emailBody }),

    emailSubject: 'Please complete these forms',
    setEmailSubject: (emailSubject) => set({ emailSubject }),

    showMessageComponent: true,
    setShowMessageComponent: (showMessageComponent) => set({ showMessageComponent }),

    isUploadInProgress: false,
    setIsUploadInProgress: (isUploadInProgress) => set({ isUploadInProgress }),

    temporarilyHideModal: false,
    setTemporarilyHideModal: (temporarilyHideModal) => set({ temporarilyHideModal }),

    selectedDeliveryOption: undefined,
    setSelectedDeliveryOption: (deliveryOption) => set({ selectedDeliveryOption: deliveryOption }),
  }));
