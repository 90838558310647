import { DigitalFormsQueries } from '@frontend/api-digital-forms';
import { useDebouncedValue, useFormField, useSwitchChipGroup } from '@frontend/design-system';
import { Category } from '../types/form-packet-selector.types';

type UseFormPacketListArgs = {
  groupId: string;
  singleSelect?: boolean;
};

export const useFormPacketList = ({ groupId, singleSelect = true }: UseFormPacketListArgs) => {
  const searchFieldProps = useFormField({
    type: 'text',
  });
  const debouncedSearchValue = useDebouncedValue(searchFieldProps.value, 300);
  const switchGroup = useSwitchChipGroup();
  const formsQuery = DigitalFormsQueries.useFormListV2(
    {
      companyIds: [groupId],
      searchKey: debouncedSearchValue,
      pageRequest: {
        pageLimit: 20,
        pageNumber: 1,
      },
    },
    {
      enabled: switchGroup.value.includes(Category.FORMS),
    }
  );
  const packetsQuery = DigitalFormsQueries.usePacketListV2(
    {
      companyIds: [groupId],
      searchKey: debouncedSearchValue,
      pageRequest: {
        pageLimit: 20,
        pageNumber: 1,
      },
    },
    {
      enabled: switchGroup.value.includes(Category.PACKETS),
    }
  );

  return {
    searchFieldProps: {
      ...searchFieldProps,
      name: 'form-packet-search',
    },
    switchChipGroupProps: {
      ...switchGroup,
      singleSelect,
    },
    formsQuery,
    packetsQuery,
  };
};
