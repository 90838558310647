import { css } from '@emotion/react';
import {
  ButtonBar,
  CaretLeftIcon,
  FileUpload,
  Heading,
  IconButton,
  SecondaryButton,
  Tray,
  useAlert,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { downloadCSV } from '@frontend/media-helpers';
import { BulkPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { RecipientsFooter } from '../recipients-footer';
import { RecipientsMode } from '../recipients';
import { UploadStep } from './upload-step';
import { ParseResult, parse } from 'papaparse';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import { MAX_BATCH_RECIPIENTS } from '../../../utils';

const CSV_EXAMPLE_NAME = 'recipient-list-example';
const CSV_EXAMPLE_CONTENT = `
First Name,Last Name,Phone Number,Preferred Name,Person ID
John,Doe,555-555-5555,John,123456
Tony,Dow,6365553226,Homer,
Bruce,Nolan,1-716-776-2323,,666666
Samuel,Zabaglione,(555) 755-2099,Sam,
`.trim();

type Props = {
  setMode: (mode: RecipientsMode) => void;
  setContactsToUpload: (contacts: Contact[]) => void;
};

type CsvRow = {
  firstname: string;
  lastname: string;
  phonenumber: string;
  preferredname?: string;
  personid?: string;
};

const useCsvParse = () => {
  const { t } = useTranslation('marketing');
  const alert = useAlert();

  return (files: File[]) => {
    return new Promise<ParseResult<CsvRow>>((resolve, reject) => {
      if (files[0]) {
        parse<CsvRow>(files[0], {
          header: true,
          transformHeader: (header) => header.toLowerCase().replace(/\s/g, ''),
          skipEmptyLines: true,
          complete: (results) => {
            resolve(results);
          },
          error: reject,
        });
      } else {
        reject(new Error(t('Please upload a CSV file.')));
      }
    }).catch((error) => {
      alert.error(error.message);
    });
  };
};

export const UploadModal = ({ setMode, setContactsToUpload }: Props) => {
  const { t } = useTranslation('messages');
  const parseCsv = useCsvParse();
  const dismissUpload = () => setMode('contacts');
  const alert = useAlert();

  const getInvalidContactMessage = (contacts: any[]) => {
    const invalidContacts = [];
    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];
      if (!contact.firstName || !contact.lastName || !contact.smsNumber) {
        // 2 because the first row is the CSV header and the index is 0-based
        invalidContacts.push(i + 2);
      }
    }

    let invalidContactMessage = '';
    if (contacts.length === 0) {
      invalidContactMessage = t('No contacts found in the file.');
    }

    if (invalidContacts.length > 0) {
      switch (invalidContacts.length) {
        case 1:
          invalidContactMessage = t(
            `There is an issue with row ${invalidContacts[0]}. Please fix the issue and try again.`
          );
          break;
        case 2:
          invalidContactMessage = t(
            `There are issues with rows ${invalidContacts.join(' and ')}. Please fix the issues and try again.`
          );
          break;
        case 3:
          invalidContactMessage = t(
            `There are issues with rows ${invalidContacts[0]}, ${invalidContacts[1]} and ${invalidContacts[2]}. Please fix the issues and try again.`
          );
          break;
        case 4:
          invalidContactMessage = t(
            `There are issues with rows ${invalidContacts[0]}, ${invalidContacts[1]}, ${invalidContacts[2]} and 1 more row. Please fix the issues and try again.`
          );
          break;
        default:
          invalidContactMessage = t(
            `There are issues with rows ${invalidContacts[0]}, ${invalidContacts[1]}, ${invalidContacts[2]} and ${
              invalidContacts.length - 3
            } more rows. Please fix the issues and try again.`
          );
          break;
      }
    }
    return invalidContactMessage;
  };

  const formatContactsData = (data: CsvRow[]): Contact[] =>
    data.map((row) => ({
      firstName: row.firstname,
      preferredName: row.preferredname,
      lastName: row.lastname,
      smsNumber: row.phonenumber?.replace(/\D/g, ''),
      externalId: row.personid,
    }));

  const handleFileUpload = async (files: File[]) => {
    const results = await parseCsv(files);
    if (results) {
      const contacts = formatContactsData(results.data);
      const invalidContactMessage = getInvalidContactMessage(contacts);
      if (invalidContactMessage) {
        alert.error(invalidContactMessage);
      } else {
        setContactsToUpload(contacts);
        setMode('contacts');
      }
    }
  };

  return (
    <>
      <Tray.Header>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <IconButton
            css={css`
              margin-right: ${theme.spacing(1)};
            `}
            label={t('Back')}
            onClick={dismissUpload}
            trackingId={`${BulkPrefixes.Recipients}-upload-title-back-btn`}
            showLabelOnHover
          >
            <CaretLeftIcon />
          </IconButton>
          <Heading
            level={2}
            css={css`
              margin: 0;
            `}
          >
            {t('Upload CSV List')}
          </Heading>
        </div>
      </Tray.Header>
      <Tray.Body
        css={css`
          padding-top: ${theme.spacing(1)};
          & .upload-step:last-child {
            border-bottom: none;
          }
        `}
      >
        <UploadStep
          title={t('Step 1:')}
          instructions={t('Download File.')}
          details={t('Download the empty CSV file below.')}
        >
          <SecondaryButton
            onClick={() => downloadCSV(CSV_EXAMPLE_CONTENT, CSV_EXAMPLE_NAME)}
            trackingId={`${BulkPrefixes.Recipients}-upload-download-btn`}
            size='large'
          >
            {t('Download Empty CSV')}
          </SecondaryButton>
        </UploadStep>
        <UploadStep
          title={t('Step 2:')}
          instructions={t('Copy & Paste Contacts Into Downloaded File.')}
          details={t('Maximum {{max}} contacts per message.', {
            max: MAX_BATCH_RECIPIENTS.toLocaleString(),
          })}
        />
        <UploadStep
          title={t('Step 3:')}
          instructions={t('Upload List Below.')}
          details={t('Any numbers known not to receive text messages will be excluded.')}
        >
          <FileUpload
            trackingId={`${BulkPrefixes.Recipients}-upload-csv-file-upload`}
            helperText={t('Drag & drop CSV or select from your computer')}
            multiple={false}
            onFileUpload={handleFileUpload}
            acceptedFileType='csv'
            keepPreviouslySelected={false}
            validateUpload={async (file: File) => {
              const results = await parseCsv([file]);
              if (results) {
                const contacts = formatContactsData(results.data);
                return getInvalidContactMessage(contacts);
              }
              return t('Please upload a CSV file.');
            }}
          />
        </UploadStep>
      </Tray.Body>
      <RecipientsFooter>
        <ButtonBar
          css={css`
            justify-content: flex-end;
            margin-top: ${theme.spacing(2)};
            padding: 0;
          `}
        >
          <SecondaryButton
            onClick={dismissUpload}
            css={buttonStyle}
            trackingId={`${BulkPrefixes.Recipients}-upload-cancel-btn`}
          >
            {t('Cancel')}
          </SecondaryButton>
        </ButtonBar>
      </RecipientsFooter>
    </>
  );
};

const buttonStyle = css`
  max-width: fit-content;
`;
