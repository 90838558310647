import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import Fuse from 'fuse.js';
import type { NavItem } from '@frontend/components';
import { GlobalSearch, useGlobalSearch, GlobalSearchTrackingIds } from '@frontend/global-search';
import { theme } from '@frontend/theme';
import { type IconProps, NakedButton, Text, styles } from '@frontend/design-system';
import { primaryNavContent } from '../../layout/global-nav/nav-content';

type TransformedNavConfig = {
  label: string;
  path: string;
  icon: React.FC<IconProps>;
  feature: string;
};

function transformNavConfig(navConfig: NavItem[]) {
  const flattenedItems: TransformedNavConfig[] = [];

  navConfig.forEach((item) => {
    if (item.show !== false) {
      item.subNavItems.forEach((subItem) => {
        flattenedItems.push({
          label: subItem.label,
          path: item.root + subItem.relativePath,
          icon: item.icon,
          feature: item.label,
        });
      });
    }
  });

  return flattenedItems;
}

const fuseOptions = {
  keys: ['label', 'feature'],
  includeScore: true,
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  includeMatches: true,
};

const defaultMaxResults = 4;

export const FeatureGlobalSearchModule = () => {
  const navigate = useNavigate();

  const flattenedNav = transformNavConfig(primaryNavContent() as unknown as NavItem[]);
  const fuse = new Fuse<TransformedNavConfig>(flattenedNav, fuseOptions);

  const { debouncedSearchTerm, viewAllFeature, close, addSearch } = useGlobalSearch([
    'debouncedSearchTerm',
    'viewAllFeature',
    'close',
    'addSearch',
  ]);

  const searchedFeatures = useMemo(() => {
    return fuse.search(debouncedSearchTerm).map((result) => result.item);
  }, [debouncedSearchTerm]);

  const maxResults = viewAllFeature === 'features' ? searchedFeatures : searchedFeatures.slice(0, defaultMaxResults);

  return (
    <GlobalSearch.Section.Content
      showViewAll={searchedFeatures.length > defaultMaxResults}
      hasResults={!!searchedFeatures.length}
      count={searchedFeatures.length}
    >
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: ${theme.spacing(1)};
        `}
      >
        {maxResults.length ? (
          maxResults.map((featureItem, index) => {
            return (
              <NakedButton
                key={index}
                trackingId={GlobalSearchTrackingIds.searchResultItem('features')}
                onClick={() => {
                  addSearch(debouncedSearchTerm, 'features');
                  navigate({ to: featureItem.path });
                  close();
                }}
                css={css`
                  display: block;
                  border: 1px solid ${theme.colors.neutral10};
                  border-radius: ${theme.borderRadius.small};
                  padding: ${theme.spacing(2)};
                  min-width: 234px;
                  flex: 1;
                  transition: background-color 0.3s ease;
                  background-color: ${theme.colors.white};
                  :hover {
                    background-color: ${theme.colors.neutral5};
                  }
                `}
              >
                <FeatureSearchItem feature={featureItem.feature} label={featureItem.label} Icon={featureItem.icon} />
              </NakedButton>
            );
          })
        ) : (
          <Text
            css={css`
              width: 100%;
            `}
            textAlign='center'
            color='light'
            size='medium'
          >
            No feature matches this search.
          </Text>
        )}
      </div>
    </GlobalSearch.Section.Content>
  );
};

export const FeatureSearchItem = ({
  feature,
  label,
  Icon,
}: {
  feature: string;
  label: string;
  Icon: React.FC<IconProps>;
}) => {
  return (
    <>
      <div
        role='text'
        css={css`
          width: 100%;
          display: flex;
          align-items: center;
          gap: ${theme.spacing(2)};
          svg {
            width: 24px;
            height: 24px;
          }
        `}
      >
        <Icon />
        <div
          css={css`
            overflow: hidden;
          `}
        >
          <Text css={styles.truncate} textAlign='left'>
            {label}
          </Text>
          <Text size='small' textAlign='left' color='subdued'>
            {feature}
          </Text>
        </div>
      </div>
    </>
  );
};
