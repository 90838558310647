import { FC, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { SMSAnalyticsApi, SMSAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { formatDateByTimezone, sumArrayIndexes } from '../../utils';
import { useMessagingAnalyticsStore } from './hooks';
import { SMSChartsView } from '.';

interface Props {
  onFetchStateChange: (isFetching: boolean) => void;
}

const defaultGeneralData: SMSAnalyticsTypes.ChartsData = {
  totalMessageCategory: {},
  totalMessages: {},
  totalMMSVsSMS: {},
  totalUnknownVsPatientSMS: {},
};

const combineValues = (...values: any[]) =>
  Array.isArray(values[0]) ? sumArrayIndexes({ arrays: values }) : values.reduce((sum, value) => sum + (value || 0), 0);

export const SMSReportCharts: FC<React.PropsWithChildren<Props>> = ({ onFetchStateChange }) => {
  const { demoData, filters, isDemoAccount } = useMessagingAnalyticsStore();
  // Extract unwanted filters for the charts view
  const { content_type, message_category, number, sms_type, source_type, status, ...restFilters } = filters;
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const locationData = getSelectedLocationData()[selectedLocationIdsWithParents[0]];
  const queryString = useMemo(() => `${JSON.stringify({ ...restFilters })}`, [restFilters]);
  const daysDiffInHours = dayjs(filters.end_date).diff(dayjs(filters.start_date), 'hours');
  const isHourlyInsights = !!(filters.location_id?.length === 1 && daysDiffInHours < 24);

  const { data: generalData, isFetching: isLoadingGeneralData } = useScopedQuery({
    queryKey: queryKeys.smsAnalyticsCharts(`${queryString}-general-data-${isDemoAccount}`),
    queryFn: () => (isDemoAccount ? null : SMSAnalyticsApi.getSMSReportingCharts(restFilters, isHourlyInsights)),
    onError: () => {
      alert.error(t("Couldn't load the sms reports charts. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return isDemoAccount ? (isHourlyInsights ? demoData?.hourlyData : demoData?.dailyData) : data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const getChartLabelText = (location_name: string, date: string) =>
    restFilters.location_id?.length === 1
      ? formatDateByTimezone(date, restFilters.start_date, locationData?.timezone)
      : location_name;

  const generalChartsData: SMSAnalyticsTypes.ChartsData = useMemo(
    () =>
      generalData
        ? generalData.reduce(
            (
              final,
              {
                location_name,
                start_date,
                total_category_apt_rem,
                total_category_birthday,
                total_category_blank,
                total_category_manual_messages,
                total_category_marketing_messages,
                total_category_messaging_preferences,
                total_category_missed_call,
                total_category_payment_reminders,
                total_category_product_ready,
                total_category_recall,
                total_category_review_requests,
                total_category_save_the_date,
                total_incoming_messages,
                total_mms,
                total_outgoing_messages,
                total_patients,
                total_sms,
                total_unknown,
              }
            ) => {
              const label = getChartLabelText(location_name, start_date);
              return {
                ...final,
                totalMessageCategory: {
                  ...final.totalMessageCategory,
                  [label]: {
                    manual: combineValues(total_category_manual_messages, final.totalMessageCategory[label]?.manual),
                    missedCall: combineValues(
                      total_category_missed_call,
                      final.totalMessageCategory[label]?.missedCall
                    ),
                    apptReminder: combineValues(
                      total_category_apt_rem,
                      final.totalMessageCategory[label]?.apptReminder
                    ),
                    recallText: combineValues(total_category_recall, final.totalMessageCategory[label]?.recallText),
                    birthday: combineValues(total_category_birthday, final.totalMessageCategory[label]?.birthday),
                    marketing: combineValues(
                      total_category_marketing_messages,
                      final.totalMessageCategory[label]?.marketing
                    ),
                    paymentReminder: combineValues(
                      total_category_payment_reminders,
                      final.totalMessageCategory[label]?.paymentReminder
                    ),
                    productReady: combineValues(
                      total_category_product_ready,
                      final.totalMessageCategory[label]?.productReady ?? 0
                    ),
                    reviewRequests: combineValues(
                      total_category_review_requests,
                      final.totalMessageCategory[label]?.reviewRequests
                    ),
                    saveTheDate: combineValues(
                      total_category_save_the_date,
                      final.totalMessageCategory[label]?.saveTheDate
                    ),
                    messagingPreference: combineValues(
                      total_category_messaging_preferences,
                      final.totalMessageCategory[label]?.messagingPreference
                    ),
                    blank: combineValues(total_category_blank, final.totalMessageCategory[label]?.blank),
                  },
                },
                totalMessages: {
                  ...final.totalMessages,
                  [label]: {
                    incoming: combineValues(total_incoming_messages, final.totalMessages[label]?.incoming),
                    outgoing: combineValues(total_outgoing_messages, final.totalMessages[label]?.outgoing),
                  },
                },
                totalMMSVsSMS: {
                  ...final.totalMMSVsSMS,
                  [label]: {
                    sms: combineValues(total_sms, final.totalMMSVsSMS[label]?.sms),
                    mms: combineValues(total_mms, final.totalMMSVsSMS[label]?.mms),
                  },
                },
                totalUnknownVsPatientSMS: {
                  ...final.totalUnknownVsPatientSMS,
                  [label]: {
                    patient: combineValues(total_patients, final.totalUnknownVsPatientSMS[label]?.patient),
                    unknown: combineValues(total_unknown, final.totalUnknownVsPatientSMS[label]?.unknown),
                  },
                },
              };
            },
            defaultGeneralData
          )
        : defaultGeneralData,
    [generalData]
  );

  useEffect(() => {
    onFetchStateChange(isLoadingGeneralData);
  }, [isLoadingGeneralData]);

  return (
    <div css={styles.wrapper}>
      <SMSChartsView
        generalChartsData={generalChartsData}
        isHourlyInsights={isHourlyInsights}
        isLoading={isLoadingGeneralData}
      />
    </div>
  );
};

const styles = {
  wrapper: css`
    height: calc(100% - ${theme.spacing(7)});
  `,

  exportableStyles: css`
    justify-content: end;
    padding: ${theme.spacing(0, 0, 2, 0)};
  `,
};
