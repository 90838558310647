import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import {
  CaretLeftIconSmall,
  Modal,
  ModalControlTriggerProps,
  PlusIconSmall,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextLink,
} from '@frontend/design-system';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from '@frontend/i18n';
import { ManualPairingContent } from './manual-sync-modal';
import { generateRandomPin } from '../utils';
import { PairingModalContent } from './syncing-modal';
import { PairDeviceResponse } from '@frontend/api-phone-sync';
import { SyncButtonProps, SyncModalProps } from './types';

const generateTrackingId = (trackingId = '', defaultTrackId: string, elementTrackingId: string) => {
  return !!trackingId ? `${trackingId}-${elementTrackingId}` : `${defaultTrackId}-${elementTrackingId}`;
};

export const PhoneSyncButton = ({
  variant = 'primary',
  disabled,
  btnLabel,
  onClick,
  setPin,
  triggerProps,
  trackingId,
}: SyncButtonProps & { setPin: Dispatch<SetStateAction<string>>; triggerProps: ModalControlTriggerProps }) => {
  const { t } = useTranslation('phoneSync');

  const label = btnLabel ? (
    btnLabel
  ) : (
    <>
      <PlusIconSmall /> {t('Pair New Device')}
    </>
  );

  let component;
  if (variant === 'tertiary') {
    component = (
      <TextLink
        weight='bold'
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}
        onClick={() => {
          onClick?.();
          setPin(generateRandomPin());
          triggerProps.onClick();
        }}
        trackingId={generateTrackingId(trackingId, `phn-portal-selectDevice`, `lnk-pairNewDevice`)}
      >
        {label}
      </TextLink>
    );
  } else {
    const Button = variant === 'primary' ? PrimaryButton : SecondaryButton;
    component = (
      <Button
        {...triggerProps}
        disabled={disabled}
        size='large'
        onClick={() => {
          onClick?.();
          setPin(generateRandomPin());
          triggerProps.onClick();
        }}
        css={{ display: 'flex', gap: theme.spacing(1), width: 'auto', svg: { margin: 0 } }}
        trackingId={generateTrackingId(trackingId, `phn-portal-deviceConnection`, `btn-pairNewDevice`)}
      >
        {label}
      </Button>
    );
  }
  return component;
};

const STEPS = {
  SYNC: 0,
  MANUAL: 1,
  SYNCING: 2,
  TIMEOUT: 3,
};

export const PhoneSyncModal: FC<React.PropsWithChildren<SyncModalProps>> = ({
  modalProps,
  locationIds,
  onBack,
  onClose,
  onSyncSuccess,
  pin,
  syncFn,
}) => {
  const [step, setStep] = useState(0);
  const [syncResponse, setSyncResponse] = useState<PairDeviceResponse | null>(null);
  const isAbortedRef = useRef(false);

  useEffect(() => {
    if (step === STEPS.MANUAL) {
      isAbortedRef.current = true;
    }
  }, [step]);

  // Invoked immediately in PhoneSyncContent when mounted
  const startSync = async (pin: string) => {
    try {
      const res = await syncFn(pin, locationIds);

      if (res) {
        setSyncResponse(res);
        setStep(STEPS.SYNCING);
      }
    } catch (e) {
      if (isAbortedRef.current) return; // This is to prevent the manual pair modal from timing out.
      const error = e as { message: string; code: number };
      if (error.message.includes('device pair timed out')) {
        setStep(STEPS.TIMEOUT);
      } else {
        console.log('start sync error', e);
      }
    }
  };

  // Invoked immediately in SyncingModalContent when mounted
  const syncSuccessHandler = () => {
    setTimeout(() => {
      if (syncResponse?.activeSipProfile) {
        onSyncSuccess(syncResponse.activeSipProfile);
        onClose();
      }
    }, 2000);
  };

  return (
    <>
      <Modal {...modalProps} maxWidth={400}>
        {step === STEPS.SYNC && (
          <PhoneSyncContent
            onBack={onBack}
            onClose={onClose}
            onManualSync={() => setStep(STEPS.MANUAL)}
            startSync={startSync}
            pin={pin}
          />
        )}
        {step === STEPS.MANUAL && (
          <ManualPairingContent
            locationIds={locationIds}
            onBack={() => setStep(STEPS.SYNC)}
            onClose={onClose}
            onSyncSuccess={onSyncSuccess}
          />
        )}
        {step === STEPS.SYNCING && <PairingModalContent onClose={onClose} onSyncSuccess={syncSuccessHandler} />}
        {step === STEPS.TIMEOUT && <TimeoutContent onClose={onClose} onRestart={() => setStep(STEPS.SYNC)} />}
      </Modal>
    </>
  );
};

export const TimeoutContent = ({ onRestart, onClose }: { onRestart: () => void; onClose: () => void }) => {
  const { t } = useTranslation('phoneSync');
  return (
    <>
      <Modal.Header textAlign='left' onClose={onClose}>
        {t('Pairing Has Timed Out')}
      </Modal.Header>
      <Modal.Body css={{ padding: theme.spacing(1, 0) }}>
        <Text size='medium'>{t('The pairing session has timed out.')}</Text>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Retry')}
        onPrimaryClick={onRestart}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
        primaryTrackingId='phn-portal-deviceConnection-btn-retryPairing'
        secondaryTrackingId='phn-portal-deviceConnection-btn-cancelRetryPairing'
      />
    </>
  );
};

export const PhoneSyncContent = ({
  onBack,
  onClose,
  onManualSync,
  startSync,
  pin,
}: {
  onBack?: () => void;
  onClose: () => void;
  onManualSync: () => void;
  startSync: (pin: string) => Promise<void>;
  pin: string;
}) => {
  const { t } = useTranslation('phoneSync');

  useEffect(() => {
    startSync(pin);
  }, []);

  return (
    <>
      <Modal.Header textAlign='left' onClose={onClose}>
        {t('Pair New Device')}
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), marginTop: theme.spacing(2) }}>
          <Text size='medium' css={{ margin: 0 }}>
            {t('Enter the number below into your Weave phone then press the dial button to initiate pairing.')}
          </Text>
          <div>
            <Text color='light' size='small' css={{ margin: 0 }}>
              {t('Pairing Number')}
            </Text>
            <Text
              weight='bold'
              css={css`
                font-size: 2.25rem;
                line-height: 40px;
                margin: 0;
              `}
              data-test-id='pair-device-pin'
            >
              {pin}
            </Text>
          </div>
          <Text size='medium' css={{ margin: 0 }}>
            <Trans t={t}>
              Or{' '}
              <TextLink
                css={css`
                  cursor: pointer;
                `}
                onClick={() => {
                  onManualSync();
                }}
                trackingId='phn-portal-deviceConnection-btn-manualSetup'
              >
                click here
              </TextLink>{' '}
              to complete the setup manually.
            </Trans>
          </Text>
        </div>
      </Modal.Body>
      <Modal.Actions
        backLabel={
          onBack ? (
            <span style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) }}>
              <CaretLeftIconSmall /> {t('Go Back')}
            </span>
          ) : undefined
        }
        onBackClick={onBack}
        backTrackingId='phn-portal-deviceConnection-btn-goBack'
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
        showPrimary={false}
        secondaryTrackingId='phn-portal-deviceConnection-btn-cancelPairNewDevice'
      />
    </>
  );
};
