import { useCallback } from 'react';
import { CalendarException } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { EventData } from '../components/calendar-view/types';
import { OfficeHoursDayScheduleType } from '../types';

export const useGetProvidersEvents = () => {
  const { t } = useTranslation('schedule');
  const getProvidersBreakEvents = useCallback(
    (providersOfficeHoursData: OfficeHoursDayScheduleType[], providerId: string) => {
      const breaks: EventData[] = [];
      providersOfficeHoursData
        ?.filter((providerOfficeHour) => providerId === providerOfficeHour.providerId)
        .map((providerOfficeHours) => {
          providerOfficeHours?.breaks?.map((breakItem) => {
            if (breakItem.startTime && breakItem.endTime) {
              breaks.push({
                startHour: breakItem.startTime,
                endHour: breakItem.endTime,
                type: 'unavailable',
                name: t('Break'),
                eventId: `break-${providerOfficeHours.dayOfWeek}-${breakItem.startTime}-${breakItem.endTime}`,
                startDate: formatDate(breakItem.startTime, 'MM/DD/YYYY'),
                endDate: formatDate(breakItem.endTime, 'MM/DD/YYYY'),
                locationId: providerOfficeHours.locationId,
              });
            }
          });
        });

      return breaks;
    },
    []
  );

  const getProvidersExceptionsEvent = useCallback(
    (providersExceptionsData: CalendarException[], providerId: string): EventData[] => {
      return providersExceptionsData
        .filter((providerException) => providerException.providerId === providerId)
        .map((providerException) => {
          const start = formatDate(dayjs(providerException.startDateTime).toDate(), 'hh:mm A');
          const end = formatDate(dayjs(providerException.endDateTime).toDate(), 'hh:mm A');

          return {
            startHour: start,
            endHour: end,
            type: 'unavailable',
            name: providerException.name ?? '',
            eventId: providerException.id ?? '',
            id: providerException.id ?? '',
            locationId: providerException.locationId ?? '',
            startDate: formatDate(dayjs(providerException.startDateTime).toDate(), 'MM/DD/YYYY'),
            endDate: formatDate(dayjs(providerException.endDateTime).toDate(), 'MM/DD/YYYY'),
          };
        });
    },
    []
  );

  return { getProvidersBreakEvents, getProvidersExceptionsEvent };
};
