export type CalendarEventType = {
  startHour: string;
  endHour: string;
  providerName?: string;
  providerId?: string;
  locationId: string;
  isValid: boolean;
  calendarDateValue: string;
  eventId?: string;
  calendarEventType?: CalendarEventsEnums | '';
  startDate?: string;
  endDate?: string;
  name?: string;
  patientId?: string;
  createdBySourceId?: string;
  workstationIds?: string[];
  appointmentType?: string;
  isNonIntegratedSource?: boolean;
};

export enum CalendarEventsEnums {
  APPOINTMENT_EVENT = 'APPOINTMENT_EVENT',
  PROVIDER_OUT_OF_OFFICE_EVENT = 'PROVIDER_OUT_OF_OFFICE_EVENT',
  OFFICE_HOURS_OUT_OF_OFFICE_EVENT = 'OFFICE_HOURS_OUT_OF_OFFICE_EVENT',
}

export type ExceptionEventParams = {
  name: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  isAvailable: boolean;
  id: string;
};

export type FilterStatusViews = 'Confirmed' | 'Unconfirmed' | 'Complete' | undefined;

export enum ScheduleMassMessageStepEnum {
  SELECT_PATIENTS_TO_CONTACT = 'SELECT_PATIENTS_TO_CONTACT',
  SEND_MASS_MESSAGE = 'SEND_MASS_MESSAGE',
}
