export type PhoneEventPayload = {
  id: string;
  method: 'PhoneSystemEventsLegacy';
  params: {
    PersonID: string;
    gender: string;
    birthdate: number;
    caller_id_name: string;
    caller_id_number: string;
    employee: string;
    event: 'outbound_call' | 'inbound_call' | 'hangup_call' | 'answer_call';
    first_name: string;
    household_id: string;
    household_name: string;
    last_name: string;
    location_id: string;
    phone_number: string;
    queue: string;
    uuid: string;
    version: string;
    workstation: string;
    transferred_by_name: string;
    transferred_by_number: string;
    recipient_location_id: string;
    recipient_location_name: string;
    client_location_name: string;
    client_location_id: string;
    data_source_name: string;
    department_id: string;
    weave_household_id: string;
  };
};

type LocationMatch = {
  location_id: string;
  location_name: string;
};

export type ContactMatch = {
  person_id: string;
  first_name: string;
  last_name: string;
  household_id: string;
  weave_household_id: string;
  gender: string;
  birthdate: {
    seconds: number;
  };
  patient_id: string;
  client_location_id: string;
  client_location_name: string;
  data_source_name: string;
  weave_locations_matched: LocationMatch[];
};

export type PhoneEventV2Payload = {
  id: string;
  method: 'PhoneSystemEventsV2';
  params: {
    channel_id: string;
    caller_context: string;
    caller_id_name: string;
    caller_id_number: string;
    contact_matches: ContactMatch[];
    phone_number: string;
    event: 'outbound_call' | 'inbound_call' | 'hangup_call' | 'answer_call';
    recipient_location_id: string;
    recipient_location_name: string;
    workstation: string;
    employee: string;
    version: string;
    sip_call_id: string;
    call_pop_head_of_household: {
      head_of_household_id: string;
      head_of_household_person_id: string;
    };
  };
};

export type PhoneCallQueueEventPayload = {
  id: string;
  method: 'PhoneCallQueueEvents';
  params: {
    queue_id: string;
    call_count: number;
    average_wait_time_in_seconds: number;
    wait_times_in_seconds: number[];
  };
};

export const isPhoneEventV2Payload = (
  payload: PhoneEventPayload | PhoneEventV2Payload
): payload is PhoneEventV2Payload => payload.method === 'PhoneSystemEventsV2';

export const isPhoneEventLegacyPayload = (
  payload: PhoneEventPayload | PhoneEventV2Payload
): payload is PhoneEventPayload => payload.method === 'PhoneSystemEventsLegacy';

export const getHeadofHousehold = (contacts: ContactMatch[]): ContactMatch => {
  return contacts.find((contact) => contact.household_id === contact.patient_id) || contacts[0];
};
