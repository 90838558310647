import { theme } from '@frontend/theme';
import { CallDuration } from '../../hooks/use-call-duration';
import { useSoftphoneCallState } from '../../providers/softphone-call-state-provider';
import { useSoftphoneDirectory } from '../../providers/softphone-directory-provider';
import { isEstablishedCall, isOutgoingCall } from '../../types';
import { Avatar, Text } from '@frontend/design-system';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useCallerInfo } from '../../hooks/use-caller-info';

export const CallBarHead = () => {
  const { t } = useTranslation('softphone');
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const mergedCallGroup = useSoftphoneCallState((ctx) => ctx.mergedCallGroup);
  const useGetPersonImageQuery = useSoftphoneDirectory((ctx) => ctx.queries.useGetPersonImageQuery);
  const firstMergedCall = mergedCallGroup?.reduce((first, call) => {
    if (!first) {
      return call;
    }
    return isEstablishedCall(call) && isEstablishedCall(first) && call.establishedAt < first.establishedAt
      ? call
      : first;
  }, mergedCallGroup[0]);
  const callWithTimer = firstMergedCall ? firstMergedCall : primaryCall;
  const startTime = callWithTimer
    ? isOutgoingCall(callWithTimer)
      ? callWithTimer.placedAt
      : isEstablishedCall(callWithTimer)
      ? callWithTimer?.establishedAt
      : undefined
    : undefined;

  const { contact, title } = useCallerInfo({
    uri: primaryCall?.remoteParty.uri ?? '',
    displayName: primaryCall?.remoteParty.displayName,
    enabled: !mergedCallGroup,
  });

  const { data: image } = useGetPersonImageQuery(contact?.PersonID ?? '', {
    enabled: !!contact && !mergedCallGroup,
  });
  const initials = contact ? (contact?.FirstName ?? '') + ' ' + (contact?.LastName ?? '') : undefined;
  const connected = primaryCall && isEstablishedCall(primaryCall);
  const isOnHold = mergedCallGroup?.length
    ? mergedCallGroup.every((call) => !!call.onHoldSince)
    : primaryCall?.onHoldSince;

  return (
    <>
      {!mergedCallGroup?.length && <Avatar name={initials} size='small' title={title} src={image} />}
      {connected && (
        <>
          {mergedCallGroup && mergedCallGroup.length > 1 ? (
            <Text
              css={css`
                margin-left: ${theme.spacing(1)};
              `}
              color='white'
              as={'span'}
              weight='bold'
            >
              {t('Group Call')}
            </Text>
          ) : isOnHold ? (
            <Text
              css={css`
                margin-left: ${theme.spacing(1)};
              `}
              color='white'
              as={'span'}
              weight='bold'
            >
              {t('HOLD')}
            </Text>
          ) : null}
          {!isOnHold && startTime && (
            <Text
              as='span'
              css={css`
                margin-left: ${theme.spacing(1)};
              `}
              color='white'
            >
              <CallDuration connectedAt={startTime ?? undefined} />
            </Text>
          )}
        </>
      )}
    </>
  );
};
