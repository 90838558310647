import { DevicesApi, DevicesQueries, DevicesQueryKey, DevicesTypes } from '@frontend/api-devices';
import { Chip, EditSimpleIcon, Table, TextLink, XIcon, useModalControl, useAlert } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useSearch } from '@tanstack/react-location';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { DeviceExtension, DeviceInclude_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { theme } from '@frontend/theme';
import { AssignDeviceToCallGroups } from '../../assign-device-modal/assign-device-to-call-groups';
import { useMutation } from '@frontend/react-query-helpers';
import { useQueryClient } from 'react-query';

type Props = {
  extension?: DeviceExtension;
  locationIds?: string[];
};

export const DeviceCallGroupAssignments = ({ extension, locationIds }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'devices' });
  const { id: deviceId } = useSearch<{ Search: { id: string } }>();
  const { navigate } = useSettingsNavigate();
  const assignDeviceToCallGroupModal = useModalControl();
  const alerts = useAlert();
  const queryClient = useQueryClient();

  const { data: { callGroups } = { callGroups: [] }, isLoading } = DevicesQueries.useGetDeviceExtensionCallGroups(
    {
      deviceId: deviceId as string,
      extensionId: extension?.extensionId ?? '',
    },
    {
      locationId: locationIds?.[0] ?? '',
    }
  );

  const callGroupIds = callGroups?.map((callgroup) => callgroup.id);

  const { mutateAsync } = useMutation({
    mutationFn: ({ deviceId, extensionId, callGroups }: DevicesTypes.UpdateDeviceExtensionCallGroups['input']) =>
      DevicesApi.UpdateDeviceExtensionCallGroups(
        {
          deviceId,
          extensionId,
          callGroups,
        },
        {
          headers: { 'Location-Id': locationIds?.[0] ?? '' },
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([
        locationIds,
        ...DevicesQueryKey.queryKeys.listDevices([
          DeviceInclude_Enum.CALL_GROUPS,
          DeviceInclude_Enum.E911_ADDRESS,
          DeviceInclude_Enum.REGISTRATION,
        ]),
      ]);
      queryClient.invalidateQueries([
        locationIds?.[0],
        ...DevicesQueryKey.queryKeys.listDeviceExtensionCallGroups(deviceId ?? ''),
      ]);
    },
    onError: () => {
      alerts.error(t('Unable to save edits to Call Group Assignments. Please try again.'));
    },
  });

  const addToCallGroups = (ids: string[]) => {
    const addedCallGroups = [...(callGroupIds ?? []), ...ids].filter((id) => !!id).map((id) => ({ id, name: '' }));

    return mutateAsync({
      deviceId: deviceId ?? '',
      extensionId: extension?.extensionId ?? '',
      callGroups: addedCallGroups,
    });
  };

  return (
    <>
      <Table
        emptyStateConfig={{
          type: 'sync_your_phone',
          header: t('No Call Groups'),
          description: t('You have not assigned this device to any call groups yet.'),
          action: {
            label: t('Assign Device to Call Groups'),
            onClick: () => assignDeviceToCallGroupModal.openModal(),
          },
        }}
        colConfig={[
          {
            Header: t('Location'),
            accessor: ({ location }) => location?.name,
            cellRenderer: (name) => (name ? <Chip.SingleChip>{name}</Chip.SingleChip> : ''),
            id: 'location-name',
            sortType: 'caseInsensitive',
            omit: locationIds?.length === 1,
          },
          {
            Header: t('Name'),
            accessor: ({ name }) => name,
            id: 'name',
            sortType: 'caseInsensitive',
          },
        ]}
        data={callGroups || []}
        hasResponsiveColWidths
        isLoading={isLoading}
        rowActions={{
          actions: [
            {
              label: t('Edit Call Group'),
              Icon: EditSimpleIcon,
              onClick: ({ id }) => {
                if (!!id) navigate({ to: '/phone/call-groups/:id', params: { id } });
              },
            },
            {
              label: t('Remove Device from Call Group'),
              Icon: XIcon,
              onClick: (row) => {
                const callGroupFiltered = callGroupIds?.filter((id) => id !== row.id).map((id) => ({ id, name: '' }));
                if (deviceId) {
                  mutateAsync({
                    deviceId,
                    extensionId: extension?.extensionId ?? '',
                    callGroups: callGroupFiltered,
                  }).then(() => {
                    alerts.success(t('Successfully removed this device from this call group'));
                  });
                }
              },
            },
          ],
        }}
      />
      {!!callGroups?.length && (
        <TextLink
          css={{
            marginTop: theme.spacing(2),
            ':hover, :focus': {
              textDecoration: 'none',
            },
          }}
          weight='bold'
          color='primary'
          onClick={() => assignDeviceToCallGroupModal.openModal()}
        >
          {t('Assign Device to Call Groups')}
        </TextLink>
      )}
      {assignDeviceToCallGroupModal.modalProps.show && (
        <AssignDeviceToCallGroups
          deviceName={extension?.name}
          callGroupIds={callGroupIds}
          locationIds={locationIds}
          onSave={addToCallGroups}
          {...assignDeviceToCallGroupModal.modalProps}
        />
      )}
    </>
  );
};
