import dayjs from 'dayjs';
import { MessageResponse } from 'stream-chat';
import { StreamMessageResponse, Message, Reaction } from '../types';

// Explicitly adding the reaction_group because it does not exists on MessageResponse
// The correct way would be to update the DefaultGeneric
// Will pick this when fixing the channel undefined issue
interface FormatMessage {
  channelId: string;
  currentUserId: string;
  message: (StreamMessageResponse | MessageResponse) & {
    reaction_groups?: Record<
      string,
      { count: number; first_reaction_at: string; last_reaction_at: string; sum_scores: number }
    >;
  };
}

export const formatMessage = ({ channelId, currentUserId, message }: FormatMessage): Message => {
  const reactions: Reaction[] = [];

  if (message.reaction_groups) {
    const ownReactions: Record<string, boolean> = {};

    message.own_reactions?.forEach((reaction) => {
      ownReactions[reaction.type] = true;
    });

    // I could see the user names available only on the latest_reactions and not on the reaction_groups
    // Hence using the latest_reactions to get the user names for the reactions
    // TODO :: Check if the latest reactions are always available or there is a better way to get the user names
    const users: Record<string, string[]> = {};
    message.latest_reactions?.forEach(({ type, user }) => {
      if (user?.name) {
        if (users[type]) {
          users[type].push(user.name);
        } else {
          users[type] = [user.name];
        }
      }
    });

    for (const reactionName in message.reaction_groups) {
      const reaction = message.reaction_groups[reactionName];
      reactions.push({
        name: reactionName,
        count: reaction.count,
        hasOwnReaction: !!ownReactions[reactionName],
        firstReaction: dayjs(reaction.first_reaction_at).valueOf(),
        users: users[reactionName] ?? [],
      });
    }
  }

  return {
    id: message.id,
    channelId: channelId,
    text: message.text ?? '',
    isEdited: !!message.message_text_updated_at,
    created: dayjs(message.created_at).toDate().toString(),
    lastUpdated: dayjs(message.updated_at).toDate().toString(),
    isUnread: false, // FIXME: add the correct value for isUnread,
    type: message.type,
    senderName: message.user?.name ?? '',
    isOwnMessage: message.user?.id === currentUserId,
    ...(message.attachments?.length && {
      attachments: message.attachments.reduce<string[]>((acc, { asset_url, image_url, type }) => {
        const attachment = asset_url || image_url; // IMPORTANT: mobile uploads doesn't contain asset_url
        if (type === 'image' && attachment) {
          acc.push(attachment);
        }

        return acc;
      }, []),
    }),
    ...(reactions.length && { reactions: reactions.sort((a, b) => a.firstReaction - b.firstReaction) }),
  };
};
