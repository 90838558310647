import { ScheduleTypes } from '@frontend/api-schedule';
import { ScheduleAvailabilityTypes } from '@frontend/api-schedule-availability';
import { http } from '@frontend/fetch';
import { SchemaIO, SchemaScheduleService } from '@frontend/schema';
import {
  CalendarExceptionForMultipleProviders,
  ProviderResponse,
  ProvidersOfficeHoursByDayOfWeekForMultipleProviders,
  ProvidersExceptionsForMultipleProvidersOfMultiLocations,
  ServiceProvider,
} from './types';

type SchemaScheduleListWorkstations = SchemaIO<(typeof SchemaScheduleService)['ListWorkstations']>;

type SchemaScheduleListAppointments = SchemaIO<(typeof SchemaScheduleService)['ListAppointments']>;

type SchemaScheduleGetAppointment = SchemaIO<(typeof SchemaScheduleService)['GetAppointment']>;

type SchemaScheduleCreateAppointment = SchemaIO<(typeof SchemaScheduleService)['CreateAppointment']>;

type SchemaScheduleUpdateAppointment = SchemaIO<(typeof SchemaScheduleService)['UpdateAppointment']>;

type SchemaScheduleDeleteAppointment = SchemaIO<(typeof SchemaScheduleService)['DeleteAppointment']>;

const LOCATION_ID_HEADER = 'Location-Id';
const setLocationIdHeader = (locationId?: string) => ({
  headers: locationId ? { [LOCATION_ID_HEADER]: locationId } : undefined,
});

export const getWorkstations = (
  request: SchemaScheduleListWorkstations['input']
): Promise<SchemaScheduleListWorkstations['output']> => {
  return SchemaScheduleService.ListWorkstations(request);
};

export const listAppointments = (
  request: SchemaScheduleListAppointments['input']
): Promise<SchemaScheduleListAppointments['output']> => {
  return SchemaScheduleService.ListAppointments(request);
};

export const getAppointment = (
  request: SchemaScheduleGetAppointment['input']
): Promise<SchemaScheduleGetAppointment['output']> => {
  return SchemaScheduleService.GetAppointment(request);
};

export const createAppointment = (
  request: SchemaScheduleCreateAppointment['input']
): Promise<SchemaScheduleCreateAppointment['output']> => {
  return SchemaScheduleService.CreateAppointment(request);
};

export const updateAppointment = (
  request: SchemaScheduleUpdateAppointment['input']
): Promise<SchemaScheduleUpdateAppointment['output']> => {
  return SchemaScheduleService.UpdateAppointment(request);
};

export const deleteAppointment = (
  request: SchemaScheduleDeleteAppointment['input']
): Promise<SchemaScheduleDeleteAppointment['output']> => {
  return SchemaScheduleService.DeleteAppointment(request);
};

const baseProviderUrl = 'schedule/api/v2/providers';
const baseUnifyProviderUrl = 'schedule/api/v2/providers-unify';
const baseProviderHoursUrl = 'schedule/api/v2/providerhours';

export const getParentLocationData = async (locationId: string) => {
  const ParentLocationData = await http.get<ScheduleTypes.Location>(
    `schedule/api/v2/locations?locationId=${locationId}`
  );
  return ParentLocationData;
};

export const getServiceProviderHours = async (providerId: string): Promise<ScheduleAvailabilityTypes.Schedule> => {
  if (!providerId) {
    return Promise.reject('requires provider id');
  }
  return await http.get<ScheduleAvailabilityTypes.Schedule>(`${baseProviderHoursUrl}/${providerId}`);
};

export const updateServiceProviderHours = (
  providerId: string,
  locationId: string,
  schedule: Partial<ScheduleAvailabilityTypes.Schedule>
): Promise<ScheduleAvailabilityTypes.Schedule> => {
  const scheduleClone: any = {};
  Object.entries(schedule).map(([day, value]) => {
    scheduleClone[day as ScheduleAvailabilityTypes.DayOfWeek] = value?.map((s) => ({
      ...s,
      locationId: s.locationId || locationId,
      dayOfWeek: day,
    }));
  });
  const dataObject = {
    locationId,
    schedule: scheduleClone,
  };
  return http.put<
    ScheduleAvailabilityTypes.Schedule,
    { locationId: string; schedule: ScheduleAvailabilityTypes.Schedule }
  >(`${baseProviderHoursUrl}/${providerId}`, dataObject);
};

export const listServiceProviders = async (locationId: string): Promise<ServiceProvider[]> => {
  const { providers } = await http.get<ProviderResponse>(baseProviderUrl, { headers: { 'Location-Id': locationId } });
  return providers;
};

export const listUnifyProviders = async (): Promise<ServiceProvider[]> => {
  const { providers } = await http.get<ProviderResponse>(baseUnifyProviderUrl);
  return providers;
};

export const getServiceProvider = async (id: string): Promise<ServiceProvider | undefined> => {
  if (!id) {
    return Promise.reject('requires id');
  }
  return http.get<ServiceProvider>(`${baseProviderUrl}/${id}`);
};

export const uploadPhotoToServiceProvider = async (
  id: string,
  locationId: string,
  image?: File
): Promise<ServiceProvider | undefined> => {
  if (!image) {
    return;
  }
  const formData = new FormData();
  formData.append('image', image);
  formData.append('source', 'weave');

  const savedProvider = await http.post<ServiceProvider>(`${baseProviderUrl}/${id}/photo`, formData, {
    ...setLocationIdHeader(locationId),
  });
  return savedProvider;
};

export const deleteUploadedPhoto = (serviceProviderId: string) => {
  return http.delete(`${baseProviderUrl}/${serviceProviderId}/photo`);
};

export const upsertServiceProvider = async (
  provider: Partial<ServiceProvider>,
  locationId: string,
  image?: File
): Promise<ServiceProvider | undefined> => {
  const url = provider.id ? `${baseProviderUrl}/${provider.id}` : baseProviderUrl;
  const method = provider.id ? 'put' : 'post';

  const savedProvider = await http[method]<ServiceProvider>(url, provider, {
    ...setLocationIdHeader(locationId),
  });

  if (image && savedProvider?.id) {
    return uploadPhotoToServiceProvider(savedProvider.id, locationId, image);
  }

  return savedProvider;
};

export const deleteServiceProvider = async (serviceProviderId: string): Promise<unknown> => {
  return http.delete(`${baseProviderUrl}/${serviceProviderId}`);
};

export const getProvidersExceptionsForMultipleProviders = async (
  req: CalendarExceptionForMultipleProviders['input']
): Promise<CalendarExceptionForMultipleProviders['output']> => {
  return SchemaScheduleService.ListCalendarExceptionForMultipleProviders(req);
};

export const getProvidersOfficeTimesForMultipleProviders = async (
  req: ProvidersOfficeHoursByDayOfWeekForMultipleProviders['input']
): Promise<ProvidersOfficeHoursByDayOfWeekForMultipleProviders['output']> =>
  SchemaScheduleService.ListProvidersOfficeHoursByDayOfWeekForMultipleProviders(req);

export const getProvidersExceptionsForMultipleProvidersOfMultiLocations = async (
  req: ProvidersExceptionsForMultipleProvidersOfMultiLocations['input']
): Promise<ProvidersExceptionsForMultipleProvidersOfMultiLocations['output']> => {
  return SchemaScheduleService.ListCalendarExceptionForMultipleProvidersOfMultiLocations(req);
};
