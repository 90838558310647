import { useMemo } from 'react';
import { css } from '@emotion/react';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import {
  DashboardWidget,
  DashboardWidgetFC,
  TimePeriodListBoxMenu,
  useDashboardWidget,
  useTimePeriodListBoxMenuState,
} from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ContentLoader, NakedUl, SwitchChipGroup, Text, styles, useSwitchChipGroup } from '@frontend/design-system';
import { useGetOpportunityListDetails } from '../../hooks';
import { OpportunityItem } from './opportunity-item';

/**
 * @dashboard-widget
 *
 * id: opportunity-list-widget
 * title: Opportunity List
 * description: Enhance patient care with a widget displaying hygiene and restorative treatment types, listing patients in each category with a total count.
 * icon: contacts-small
 */
export const OpportunityListWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('analytics');
  const { currentSize, widgetTrackingId } = useDashboardWidget();
  const { startDate, endDate, ...timePeriodMenuState } = useTimePeriodListBoxMenuState('yesterday');
  const { selectedLocationIds } = useAppScopeStore();
  const selectedStatusProps = useSwitchChipGroup({ defaultValue: ['hygiene'] });
  const { data, isLoading } = useGetOpportunityListDetails({
    filters: {
      endDate,
      startDate,
      locations: selectedLocationIds,
    },
    disabled: !startDate || !endDate || !selectedLocationIds.length,
    queryProps: {
      hygieneTreatmentPlan: true,
      restorativeTreatmentPlan: true,
      hygieneFollowUp: false,
    },
  });
  const { hygienePatients, restorativePatients } = useMemo(() => {
    const hygienePatients: PracticeAnalyticsTypes.PatientInfo[] = [];
    const restorativePatients: PracticeAnalyticsTypes.PatientInfo[] = [];

    Object.values(data?.data ?? {}).map((value) => {
      hygienePatients.push(...(value?.location.hygieneTreatmentPlan.details.patients ?? []));
      restorativePatients.push(...(value?.location.restorativeTreatmentPlan.details.patients ?? []));
    });

    return { hygienePatients, restorativePatients };
  }, [data?.data]);

  const isRestorativeSelected = selectedStatusProps.value[0] === 'restorative';
  const totalCount = isRestorativeSelected ? restorativePatients.length : hygienePatients.length;
  const isNarrowSize = currentSize.includes('narrow');
  const patients = isRestorativeSelected ? restorativePatients : hygienePatients;
  const hasData = !!restorativePatients.length || !!hygienePatients.length;
  return (
    <DashboardWidget>
      <DashboardWidget.Header>
        <div css={{ flexGrow: 1, display: 'flex', flexDirection: 'row-reverse' }}>
          <TimePeriodListBoxMenu {...timePeriodMenuState} readonly />
        </div>
      </DashboardWidget.Header>
      <DashboardWidget.Content css={[contentContainerStyle, { flexDirection: isNarrowSize ? 'column' : 'row' }]}>
        <ContentLoader show={isLoading} />
        {hasData && (
          <div
            css={{ display: 'flex', flexDirection: 'column', padding: isNarrowSize ? 0 : theme.spacing(1, 2, 0, 0) }}
          >
            <SwitchChipGroup css={{ margin: theme.spacing(0.5) }} {...selectedStatusProps} singleSelect={true}>
              <SwitchChipGroup.Option trackingId={widgetTrackingId('btn-hygiene')} value='hygiene'>
                {t('Hygiene')}
              </SwitchChipGroup.Option>
              <SwitchChipGroup.Option trackingId={widgetTrackingId('btn-restorative')} value='restorative'>
                {t('Restorative')}
              </SwitchChipGroup.Option>
            </SwitchChipGroup>

            <div css={countContainerStyle}>
              <Text weight='bold' css={{ fontSize: theme.fontSize(100), lineHeight: 1 }}>
                {totalCount > 99 ? '99+' : totalCount}
              </Text>
              {!isNarrowSize && (
                <Text size='small' color='light' css={{ textTransform: 'capitalize' }}>
                  {isRestorativeSelected ? t('Restorative') : t('Hygiene')} {t('Patients')}
                </Text>
              )}
            </div>
          </div>
        )}
        {!patients.length ? (
          <div css={[styles.flexCenter, { flexGrow: 1 }]}>
            <Text color='light'>{t('No opportunities available')}</Text>
          </div>
        ) : (
          <NakedUl css={[ulStyle, { marginTop: theme.spacing(isNarrowSize ? 0 : 1) }]}>
            {(isRestorativeSelected ? restorativePatients : hygienePatients).map((item) => (
              <OpportunityItem key={item.id} item={item} />
            ))}
          </NakedUl>
        )}
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

OpportunityListWidget.config = {
  size: {
    extraSmall: 'large-narrow',
    small: 'large-narrow',
    medium: 'large-wide',
    large: 'large-wide',
  },
  feature: 'schedule',
};

const contentContainerStyle = css({
  overflow: 'hidden',
  display: 'flex',
  width: '100%',
  height: '100%',
});

const countContainerStyle = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const ulStyle = css({
  minWidth: 330,
  overflow: 'auto',
  flexGrow: 1,
  background: theme.colors.neutral5,
  padding: theme.spacing(1),
});
