import { css } from '@emotion/react';
import { SwitchField, Text, useFormField } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useSoftphoneSettings } from '../../providers/softphone-settings-provider';
import { useEffect } from 'react';

export const CallWaitingIndicatorToggle = () => {
  const { t } = useTranslation('softphone');
  const setIsCallWaitingIndicatorBeepEnabled = useSoftphoneSettings((ctx) => ctx.setIsCallWaitingIndicatorBeepEnabled);
  const isCallWaitingIndicatorBeepEnabled = useSoftphoneSettings((ctx) => ctx.isCallWaitingIndicatorBeepEnabled);

  const switchProps = useFormField({
    type: 'switch',
    value: isCallWaitingIndicatorBeepEnabled,
  });

  useEffect(() => {
    setIsCallWaitingIndicatorBeepEnabled(switchProps.value);
  }, [switchProps.value]);

  return (
    <li>
      <SwitchField
        css={css`
          justify-content: start;
          label {
            color: white;
            width: fit-content;
            font-weight: bold;
            margin-right: auto;
          }
        `}
        labelPlacement='left'
        label={t('Call Waiting Indicator Beep')}
        name=''
        {...switchProps}
      />
      <Text className='settings-li-helper-text' color='light' size='small'>
        {t('You will hear a beep when an incoming call is received while you are on an active call.')}
      </Text>
    </li>
  );
};
