/**
 * TODO: Remove this file
 */

import { FormsWritebacks } from '@frontend/api';

const WritebackSetupStep = FormsWritebacks.Types.WritebackSetupStep;

export const WRITEBACK_SETUP_STEPS = {
  multiPMSLocation: [WritebackSetupStep.WELCOME, WritebackSetupStep.SELECT_DESTINATION, WritebackSetupStep.REVIEW],
  singlePMSLocation: [WritebackSetupStep.WELCOME, WritebackSetupStep.REVIEW],
};
