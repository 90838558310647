import { useState } from 'react';
import { Template, TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { InvoiceDetailModel } from '@frontend/api-invoices';
import { formatCentsToCurrency } from '@frontend/currency';
import { useTranslation } from '@frontend/i18n';
import { SendInThreadView, TemplateSelector, useSendInThreadState } from '@frontend/integrated-messaging';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { ThreadBodyComponents } from '@frontend/thread-body';
import { theme } from '@frontend/theme';
import { formatDate, MultiActionButton, Text, TextButton, useAlert } from '@frontend/design-system';
import { useValidPhoneNumberFromInvoice } from '../hooks';
import { useBnplLink } from './hooks';

interface SendInThreadModalProps {
  invoice: InvoiceDetailModel;
  isBnplRequest: boolean;
}

export const SendInThreadModal = ({ invoice, isBnplRequest }: SendInThreadModalProps) => {
  const { t } = useTranslation('integrated-messaging');
  const alert = useAlert();
  const { closeModal } = useMultiStepModal();
  const { firstValidPhoneNumber } = useValidPhoneNumberFromInvoice(invoice);
  const [selectedPhone, setSelectedPhone] = useState<string>(firstValidPhoneNumber ?? '');
  const { bnplTtpLink } = useBnplLink({ personPhone: selectedPhone, isBnplRequest });
  const [showTemplateSelection, setShowTemplateSelection] = useState(false);

  const templateType = TemplateType_Slug.MANUAL_PAYMENT_REQUEST;

  const { contentProps, sendMessage, scheduleMessage, setDraftToRenderedTemplate } = useSendInThreadState({
    groupId: invoice.locationId,
    personId: invoice.person.id ?? '',
    templateType: templateType,
    linkData: {
      link: isBnplRequest ? bnplTtpLink! : invoice.links.payment ?? '',
      relatedId: invoice.id,
    },
    onSend: () => {
      closeModal();
      alert.success(t('Message sent successfully.'));
    },
    onSendError: () => {
      alert.error(t('Error sending message. Please try again.'));
    },
    onSchedule: () => {
      closeModal();
      alert.success(t('Message scheduled successfully.'));
    },
    onScheduleError: () => {
      alert.error(t('Error scheduling message. Please try again.'));
    },
    onThreadChange: ({ personPhone }) => {
      setSelectedPhone(personPhone);
    },
  });

  return (
    <div
      css={{
        height: 'min-content',
      }}
    >
      {showTemplateSelection ? (
        <TemplateSelector
          groupId={invoice.locationId}
          templateTypes={templateType ? [templateType] : []}
          css={{
            padding: theme.spacing(2),
            height: `calc(100% - ${theme.spacing(4)})`,
            overflowY: 'hidden',
          }}
          onOpenSettings={() => {
            closeModal();
          }}
          onSelectTemplate={(template: Template) => {
            setDraftToRenderedTemplate(template);
            setShowTemplateSelection(false);
          }}
        />
      ) : (
        <SendInThreadView
          {...contentProps}
          hideSuperTextAreaSendButton
          css={{
            height: 'min(50vh, 1000px)',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: theme.borderRadius.medium,
            border: `1px solid ${theme.colors.neutral20}`,
            margin: theme.spacing(2, 0),
          }}
          bodyAndSendAreaContainerProps={{
            css: { flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' },
          }}
          onSelectTemplateClick={() => {
            setShowTemplateSelection(true);
          }}
          contextPreviewProps={{
            iconName: 'dollar-sign',
            title: <Text weight='bold'>{formatCentsToCurrency(invoice?.billedAmount)}</Text>,
            subtitle: <Text>Due Date: {formatDate(invoice?.billedAt, 'MMM D, YYYY')}</Text>,
          }}
        />
      )}
      {showTemplateSelection ? (
        <TextButton
          css={{ color: theme.font.colors.primary, fontWeight: theme.font.weight.bold }}
          onClick={() => setShowTemplateSelection(false)}
        >
          {t('Back')}
        </TextButton>
      ) : (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            margin: theme.spacing(0),
          }}
        >
          <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
            <MultiActionButton
              actionType='dialog'
              Dialog={
                <ThreadBodyComponents.ScheduleMessagePopover
                  onSendNow={sendMessage}
                  disableForm={!contentProps.bodyValue}
                  onSubmit={(scheduledTime, isPaused) => {
                    scheduleMessage(scheduledTime, isPaused);
                  }}
                />
              }
              dialogProps={{
                css: {
                  padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
                  borderRadius: theme.borderRadius.small,
                },
              }}
              label={t('Send Now')}
              onClick={sendMessage}
              size='small'
              disabled={!contentProps.bodyValue}
              css={{ whiteSpace: 'nowrap' }}
              trackingId={`collect-payment-send-button`}
            >
              {t('Send Now')}
            </MultiActionButton>
          </div>
        </div>
      )}
    </div>
  );
};
