import { FormsSubmissions } from '@frontend/api';
import { isUUID } from '../../shared/utils';

export function getCurrentCategoryFromPath(pathname: string) {
  return pathname.split('/')[3] as FormsSubmissions.Types.SubmissionLists;
}

export function getCurrentSubCategoryFromPath(pathname: string) {
  const splits = pathname.split('/');
  const subCategory = splits.length > 3 ? (splits[4] as FormsSubmissions.Types.SubmissionSubList) : undefined;

  if (subCategory && isUUID(subCategory)) {
    return undefined;
  }

  return subCategory;
}
