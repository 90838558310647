import { Dispatch, KeyboardEvent, SetStateAction, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { fontSize, theme } from '@frontend/theme';
import { BaseFieldProps, NakedButton, Text, TextLink, styles } from '@frontend/design-system';

interface EditableTextProps {
  isTitle?: boolean;
  fieldProps: BaseFieldProps;
  helperText?: string;
  disabled?: boolean;
  isToggledOpen?: boolean;
  setIsToggleOpen?: Dispatch<SetStateAction<boolean>>;
  hideEditIcon?: boolean;
  actionText?: string;
  onActionClick?: () => void;
  alwaysShowIcon?: boolean;
  hideUntilHovered?: boolean;
}

export const EditableText = ({
  isTitle = false,
  fieldProps,
  helperText,
  disabled = false,
  isToggledOpen,
  setIsToggleOpen,
  hideEditIcon,
  actionText,
  onActionClick,
  alwaysShowIcon = false,
  hideUntilHovered = false,
  ...rest
}: EditableTextProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [prevActive, setPrevActive] = useState(false);
  const fontSizeNumber = isTitle ? 36 : 24;
  const fontSizeStyles = css`
    font-size: ${fontSize(fontSizeNumber)};
  `;
  const buttonId = 'editable-text-action-button';

  const getBorderColor = () => {
    if (prevActive && !!fieldProps.error && fieldProps.required) {
      return theme.colors.critical50;
    } else if (isEditing && fieldProps.active) {
      return theme.colors.primary50;
    } else {
      if (isTitle && !isEditing) {
        return 'transparent';
      }
      return theme.colors.neutral10;
    }
  };

  const setFocus = () => {
    if (disabled) return;
    setIsEditing(true);
    setPrevActive(true);
    setTimeout(() => {
      const focusableElement = document.getElementById(fieldProps.id);
      if (focusableElement) focusableElement.focus();
    }, 0);
  };

  /* 
    This useEffect is to handle if we are triggering the input in edit mode outside the component
    if no function is passed to handle the state update, line 66 with return or else it will go into an infinite loop 
    when you try to toggle it
  */

  useEffect(() => {
    if (!setIsToggleOpen) return;
    if (isToggledOpen && !isEditing) {
      setIsEditing(true);
      setFocus();
    } else {
      setIsEditing(false);
      setIsToggleOpen(false);
    }
  }, [isToggledOpen]);

  const { active, touched, ...restFieldProps } = fieldProps;

  return (
    <>
      <div
        role='button'
        css={[
          css`
            padding-bottom: ${theme.spacing(1)};
            display: flex;
          `,
          hideUntilHovered
            ? css`
                border-bottom: 1px solid transparent;
                &:hover,
                &:focus-within {
                  border-bottom: 1px solid ${getBorderColor()};
                }
              `
            : css`
                border-bottom: 1px solid ${getBorderColor()};
              `,
        ]}
        onClick={setFocus}
        {...rest}
      >
        {isEditing && !disabled ? (
          <>
            <input
              {...restFieldProps}
              css={[
                fontSizeStyles,
                editInputStyles,
                css`
                  height: ${fontSizeNumber * 1.5}px;
                `,
              ]}
              onKeyDown={(e) => {
                if (e.key === 'Enter') setIsEditing(false);
              }}
              onBlur={(e) => {
                restFieldProps.onBlur(e);
                if (e.relatedTarget?.id === buttonId) return;
                setIsEditing(false);
                setIsToggleOpen?.(false);
              }}
            />
            {actionText && (
              <TextLink
                id={buttonId}
                weight='bold'
                css={{ padding: theme.spacing(0, 2) }}
                onClick={(e) => {
                  e.stopPropagation();
                  onActionClick?.();
                }}
              >
                {actionText}
              </TextLink>
            )}
          </>
        ) : (
          <NakedButton
            disabled={disabled}
            css={[
              readBtnStyles,
              css`
                &:hover,
                &:focus-within {
                  .edit-icon {
                    opacity: 1;
                  }
                }
              `,
            ]}
            onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
              if (e.key === 'Enter') {
                setFocus();
              }
            }}
          >
            <Text
              as='span'
              color={disabled ? 'disabled' : 'default'}
              weight='bold'
              css={[fontSizeStyles, readTextStyles, fieldProps.value === '' && placeholderColorStyles, styles.truncate]}
            >
              {fieldProps.value || fieldProps.placeholder}
            </Text>
            {!hideEditIcon && (
              <Icon
                name='edit-small'
                className='edit-icon'
                css={[
                  editIconStyles,
                  css`
                    opacity: ${alwaysShowIcon || fieldProps?.value?.length === 0 || disabled ? 1 : 0};
                  `,
                ]}
              />
            )}
          </NakedButton>
        )}
      </div>
      {!isTitle && helperText && (
        <Text size='small' css={helperTextStyles(!!fieldProps.error, prevActive)}>
          {helperText}
        </Text>
      )}
    </>
  );
};

const placeholderColor = theme.colors.neutral50;

const editInputStyles = css`
  background-color: transparent;
  font-weight: 700;
  border: none;
  outline: none;
  width: 100%;
  padding-left: ${theme.spacing(1)};
  &::placeholder {
    color: ${placeholderColor};
  }
`;

const readTextStyles = css`
  text-align: left;
  padding-left: ${theme.spacing(1)};
  margin: 0;
`;

const placeholderColorStyles = css`
  color: ${placeholderColor};
`;

const readBtnStyles = css`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  &:disabled {
    cursor: unset;
  }
`;

const editIconStyles = css`
  margin-left: ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};
`;

const helperTextStyles = (error: boolean, prevActive: boolean) => css`
  color: ${error && prevActive ? theme.colors.critical50 : theme.colors.neutral50};
  margin-top: ${theme.spacing(1)};
`;
