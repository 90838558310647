import { css } from '@emotion/react';
import { DialPadErrorState, ErrorBadgeIcon, Heading, Text, TextLink } from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ValidateDestroyError,
  ValidateDestroyErrorCode_Enum,
} from '@weave/schema-gen-ts/dist/schemas/phone-devices/lines/line_api.pb';
import { WeaveSupportLink } from '../../../weave-support-link';

type Props = {
  deviceName: string;
  errors?: ValidateDestroyError[];
  isSeverityFatal?: boolean;
};

export const RequiredError = ({ isSeverityFatal, errors, deviceName }: Props) => {
  if (isSeverityFatal) {
    return <FatalErrorMessage deviceName={deviceName} />;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(2)};
        margin-top: ${theme.spacing(3)};
      `}
    >
      {errors?.length === 1 && errors?.[0].code === ValidateDestroyErrorCode_Enum['VOICEMAIL_BOX_HAS_NEW_MESSAGES'] && (
        <ClearVoicemailResponse voicemailCount={errors?.[0].newMessageCount} deviceName={deviceName} />
      )}
      {errors?.length === 1 && errors?.[0].code === ValidateDestroyErrorCode_Enum['VOICEMAIL_BOX_IS_SHARED'] && (
        <ReassignVoicemailMessage error={errors[0]} deviceName={deviceName} />
      )}
      {errors?.length === 2 && <MultipleErrors deviceName={deviceName} errors={errors} />}
    </div>
  );
};

const FatalErrorMessage = ({ deviceName }: { deviceName: string }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'destroy-softphone' });

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
    >
      <DialPadErrorState
        size={200}
        css={css`
          margin-top: ${theme.spacing(4)};
        `}
      />
      <Heading
        level={3}
        css={css`
          font-weight: bold;
          color: ${theme.colors.critical50};
          margin: ${theme.spacing(3, 0, 1)};
        `}
      >
        {t('We’re having trouble retrieving information about this Softphone.')}
      </Heading>
      <Text>
        <Trans deviceName={deviceName}>
          Please contact <WeaveSupportLink /> to destroy {{ deviceName }}
        </Trans>
      </Text>
    </div>
  );
};

const MultipleErrors = ({ deviceName, errors }: { deviceName: string; errors?: ValidateDestroyError[] }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'destroy-softphone' });
  return (
    <>
      <Heading
        css={css`
          display: flex;
          gap: ${theme.spacing(1)};
        `}
      >
        <ErrorBadgeIcon color='error' />
        <Text as='span' color='error'>
          {t('2 Issues to Resolve (Required)')}
        </Text>
      </Heading>

      <Text>
        <Trans t={t} deviceName={deviceName}>
          Destroying{' '}
          <Text weight='bold' as='span'>
            {deviceName}
          </Text>{' '}
          will destroy its voicemail box. Please resolve the following issues:
        </Trans>
      </Text>

      <ol>
        {errors?.map((error) => {
          return error.code === ValidateDestroyErrorCode_Enum['VOICEMAIL_BOX_HAS_NEW_MESSAGES'] ? (
            <li key={ValidateDestroyErrorCode_Enum['VOICEMAIL_BOX_HAS_NEW_MESSAGES']}>
              <Text weight='bold'>
                {t('The voicemail box of {{deviceName}} has {{count}} unread messages.', {
                  count: error?.newMessageCount,
                  deviceName,
                })}
              </Text>
              <Text>
                {t(
                  'To prevent the permanent loss of important messages, please review and manage the unread messages in the voicemail box before destroying this Softphone.'
                )}
              </Text>
            </li>
          ) : error.code === ValidateDestroyErrorCode_Enum['VOICEMAIL_BOX_IS_SHARED'] ? (
            <li key={ValidateDestroyErrorCode_Enum['VOICEMAIL_BOX_IS_SHARED']}>
              <Text weight='bold'>
                {t('The voicemail box of {{deviceName}} is being shared by other devices. ', { deviceName })}
              </Text>
              <Text>
                {t(
                  'Please assign a different voicemail box to the following devices before destroying this Softphone:'
                )}
              </Text>
              <ErrorLinkText error={error} />
            </li>
          ) : (
            ''
          );
        })}
      </ol>
    </>
  );
};

const ClearVoicemailResponse = ({ deviceName, voicemailCount }: { deviceName: string; voicemailCount?: number }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'destroy-softphone' });

  return (
    <>
      <Heading
        css={css`
          display: flex;
          gap: ${theme.spacing(1)};
        `}
      >
        <ErrorBadgeIcon color='error' />
        <Text as='span' color='error'>
          {t('Manage {{count}} Unread Messsages (Required)', { count: voicemailCount })}
        </Text>
      </Heading>
      <Text>
        <Trans t={t} deviceName={deviceName}>
          Destroying{' '}
          <Text weight='bold' as='span'>
            {{ deviceName }}
          </Text>{' '}
          will destroy its voicemail box. To prevent the permanent loss of important messages, please review and manage
          the unread messages in the voicemail box before destroying this Softphone.
        </Trans>
      </Text>
    </>
  );
};

const ReassignVoicemailMessage = ({ error, deviceName }: { deviceName: string; error: ValidateDestroyError }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'destroy-softphone' });

  return (
    <>
      <Heading
        css={css`
          display: flex;
          gap: ${theme.spacing(1)};
        `}
      >
        <ErrorBadgeIcon color='error' />
        <Text as='span' color='error'>
          {t('Reassign Voicemail Box (Required)')}
        </Text>
      </Heading>
      <Text>
        <Trans t={t} deviceName={deviceName}>
          Destroying{' '}
          <Text weight='bold' as='span'>
            {{ deviceName }}
          </Text>{' '}
          will destroy its voicemail box. This Softphone's voicemail box is being shared by other devices. Please assign
          a different voicemail box to the following devices before destroying this Softphone:
        </Trans>
      </Text>
      <ErrorLinkText error={error} />
    </>
  );
};

const ErrorLinkText = ({ error }: { error: ValidateDestroyError }) => {
  return (
    <ul>
      {error.devicesUsingVoicemailBox?.map((device) => {
        return (
          <li
            key={device.device?.id}
            css={css`
              list-style-type: disc;
              &::marker {
                color: ${theme.colors.primary50};
              }
            `}
          >
            <TextLink
              rel='noreferrer'
              target='_blank'
              onClick={() => {
                window.open(`/portal#settings/phone/devices/${device?.device?.id}`);
              }}
            >
              {device.device?.name}
            </TextLink>
          </li>
        );
      })}
    </ul>
  );
};
