import { FC } from 'react';
import { EmojiClickData } from 'emoji-picker-react';
import { noop } from 'lodash-es';
import { TFunction } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useMatchMedia } from '@frontend/responsiveness';
import { ExtraAction } from '@frontend/super-textarea';
import { theme } from '@frontend/theme';
import {
  EmojiButton,
  IconButton,
  ImageIcon,
  PopoverMenu,
  PopoverMenuItem,
  usePopoverMenu,
  UsePopoverMenuResponse,
} from '@frontend/design-system';

type addonsActionType = {
  disabled: boolean;
  t: TFunction<'inbox', undefined>;
  addonsPopoverControls: UsePopoverMenuResponse<HTMLButtonElement | HTMLAnchorElement>;
};

type AddonsPopoverProps = Pick<
  ReturnType<typeof usePopoverMenu<HTMLButtonElement>>,
  'getMenuProps' | 'getItemProps' | 'close'
> & {
  disabled: boolean;
  trackingIds: { image: string; emoji: string };
  addEmojiToSendBody: (emojiData: EmojiClickData) => void;
  onImageClick: () => void;
  extraActionItems: ExtraAction[];
};

const getAddonsStyles = (disabled: boolean, isPopoverOpen: boolean) => ({
  color: disabled ? theme.colors.neutral20 : theme.colors.neutral70,
  transform: isPopoverOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.2s ease-in-out',
});

export const getAddonsAction = ({ disabled, t, addonsPopoverControls }: addonsActionType): ExtraAction => ({
  icon: <Icon name='caret-down' css={getAddonsStyles(disabled, addonsPopoverControls.isOpen)} />,
  label: addonsPopoverControls.isOpen ? '' : t('Add-ons'),
  showLabelOnHover: !addonsPopoverControls.isOpen,
  ...addonsPopoverControls.getTriggerProps(),
});

// Addons-action with popover for devices with screen width less than 1300px

export const AddonsPopover: FC<AddonsPopoverProps> = ({
  getMenuProps,
  getItemProps,
  trackingIds,
  onImageClick,
  addEmojiToSendBody,
  extraActionItems,
  disabled,
  close: closePopover,
}) => {
  const isViewportNarrow = useMatchMedia({ maxWidth: 480 });

  const handleEmojiSelect = (emojiData: EmojiClickData) => {
    addEmojiToSendBody(emojiData);
    closePopover();
  };

  return (
    <PopoverMenu
      {...getMenuProps()}
      css={{
        minWidth: 'max-content',
        width: 'max-content',
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 0),
      }}
    >
      <PopoverMenuItem key='emoji-action' css={{ padding: theme.spacing(0, 1) }} {...getItemProps({ index: 0 })}>
        <EmojiButton
          onSelect={handleEmojiSelect}
          disabled={disabled}
          trackingId={trackingIds.emoji ?? 'sms-emoji-button-click'}
          css={{
            fill: disabled ? theme.font.colors.disabled : theme.colors.neutral70,
          }}
          showLabelAlways
          popoverPlacement={isViewportNarrow ? 'top-end' : 'right'}
          pickerWidth={320}
        />
      </PopoverMenuItem>
      <PopoverMenuItem key='add-image-action' css={{ padding: theme.spacing(0, 1) }} {...getItemProps({ index: 1 })}>
        <IconButton
          type='button'
          label='Add image'
          aria-label='add image'
          data-trackingid={trackingIds.image}
          onClick={onImageClick || noop}
          disabled={disabled}
          trackingId={trackingIds.image ?? 'sms-image-button-click'}
          showLabelAlways
          size='small'
          css={{
            fill: disabled ? theme.colors.neutral20 : theme.colors.neutral70,
          }}
        >
          <ImageIcon />
        </IconButton>
      </PopoverMenuItem>
      {extraActionItems?.map((action, index) => {
        const { icon, disabled: iconDisabled, ...rest } = action;
        return (
          <PopoverMenuItem
            key={`${action.label}-${index}`}
            css={{ padding: theme.spacing(0, 1) }}
            {...getItemProps({ index: index + 2 })}
          >
            <IconButton
              type='button'
              {...rest}
              showLabelAlways
              size='small'
              showLabelOnHover={false}
              disabled={disabled || iconDisabled}
            >
              {icon}
            </IconButton>
          </PopoverMenuItem>
        );
      })}
    </PopoverMenu>
  );
};
