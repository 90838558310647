import { useMemo, useState } from 'react';
import { CaretDownIcon, PopoverMenu, PopoverMenuItem, Text, TextLink, usePopoverMenu } from '@frontend/design-system';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { formatPhoneNumber } from '../utils/phone-utils';

export const THEME_STYLES = {
  DARK: 'dark',
  LIGHT: 'light',
} as const;

type ThemeStyle = (typeof THEME_STYLES)[keyof typeof THEME_STYLES];

export type DeviceInformationProps = {
  deviceName: string;
  onChangeDeviceClick: () => void;
  changeDeviceText: string;
  showExtension: boolean;
  showOutboundCallerInfo?: boolean;
  extensionNumberText: string;
  currentPhoneNumberText: string | null;
  availablePhoneNumbers: { number: string; locationName: string }[];
  SelectOutboundIdMenuWrapper: React.FC<SelectOutboundIdMenuProps>;
  themeStyle?: ThemeStyle;
  trackingId?: string;
};

export const DeviceInformation = ({
  deviceName,
  onChangeDeviceClick,
  changeDeviceText,
  showExtension,
  extensionNumberText,
  currentPhoneNumberText,
  availablePhoneNumbers,
  SelectOutboundIdMenuWrapper,
  themeStyle = THEME_STYLES.DARK,
  showOutboundCallerInfo,
  trackingId,
}: DeviceInformationProps) => {
  const popoverMenuProps = usePopoverMenu({
    placement: 'bottom',
  });
  const actionsTriggerProps = popoverMenuProps.getTriggerProps({
    onClick: (e) => {
      e.stopPropagation();
    },
  });

  const textColor = themeStyle === THEME_STYLES.DARK ? 'white' : 'default';

  return (
    <>
      <div
        css={css`
          display: flex;
          gap: ${theme.spacing(1)};
        `}
      >
        <Text
          as='div'
          weight='bold'
          color={textColor}
          css={css`
            line-height: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: ${theme.spacing(21)};
          `}
        >
          {deviceName}
        </Text>
        <TextLink
          css={css`
            cursor: pointer;
          `}
          size='small'
          weight='bold'
          onClick={onChangeDeviceClick}
          trackingId={trackingId}
        >
          {changeDeviceText}
        </TextLink>
      </div>
      {showExtension && (
        <Text
          size='medium'
          css={css`
            color: ${theme.colors.neutral30};
          `}
        >
          {extensionNumberText}
        </Text>
      )}

      {showOutboundCallerInfo && (
        <div {...actionsTriggerProps} css={{ width: 'fit-content' }}>
          <Text
            size='medium'
            css={css`
              color: ${themeStyle === THEME_STYLES.DARK ? theme.colors.neutral30 : theme.colors.neutral50};
              display: flex;
              gap: ${theme.spacing(0.5)};
              align-items: center;
            `}
          >
            {currentPhoneNumberText}
            {availablePhoneNumbers.length > 1 && (
              <div
                css={css`
                  cursor: pointer;
                `}
                data-trackingid='phones-generic-dialpad-modal-outboundID'
              >
                <CaretDownIcon size={8} color={textColor} />
              </div>
            )}
          </Text>
          <SelectOutboundIdMenuWrapper {...popoverMenuProps} />
        </div>
      )}
    </>
  );
};

export type SelectOutboundIdMenuProps = {
  getMenuProps: ReturnType<typeof usePopoverMenu>['getMenuProps'];
  getItemProps: ReturnType<typeof usePopoverMenu>['getItemProps'];
  close: ReturnType<typeof usePopoverMenu>['close'];
  themeStyle?: 'dark' | 'light';
};

type OutboundSelectedPhoneNumberProps = {
  availablePhoneNumbers: { number: string; locationName: string }[];
  currentPhoneNumber: string | undefined;
  setCurrentPhoneNumber: (data: { number: string; locationName: string }) => void;
};

type ActionItemProps = {
  locationName: string;
  phoneNumber: string | undefined;
  onClick: () => void;
};

export const SelectOutboundIdMenu = ({
  getMenuProps,
  getItemProps,
  close: closePopover,
  availablePhoneNumbers,
  currentPhoneNumber,
  setCurrentPhoneNumber,
  themeStyle = 'dark',
}: SelectOutboundIdMenuProps & OutboundSelectedPhoneNumberProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const actions: ActionItemProps[] = useMemo(
    () =>
      availablePhoneNumbers.map(({ number, locationName }, i) => {
        if (currentPhoneNumber === number) {
          setSelectedIndex(i);
        }
        return {
          locationName,
          phoneNumber: number,
          onClick: () => {
            closePopover?.();
            setSelectedIndex(i);
            setCurrentPhoneNumber({
              number,
              locationName,
            });
          },
        };
      }),
    [availablePhoneNumbers]
  );

  const backgroundColor = themeStyle === 'dark' ? theme.colors.neutral80 : theme.colors.white;
  const textColor = themeStyle === 'dark' ? 'white' : 'default';
  const hoverFocusColor = themeStyle === 'dark' ? theme.colors.neutral70 : theme.colors.neutral10;

  return (
    <PopoverMenu
      {...getMenuProps()}
      initialFocus={selectedIndex}
      css={css`
        background-color: ${backgroundColor};
        button span {
          color: ${theme.colors.white};
        }
        button:hover {
          background-color: ${hoverFocusColor};
        }
        button:focus {
          background-color: ${hoverFocusColor};
        }
      `}
    >
      {actions.map(({ locationName, onClick, phoneNumber }: ActionItemProps, index) => (
        <PopoverMenuItem
          css={css`
            height: auto;
            display: block;
            text-align: left;
            padding-top: ${theme.spacing(1)};
            padding-bottom: ${theme.spacing(1)};
          `}
          key={phoneNumber}
          {...getItemProps({
            index,
            onClick,
          })}
        >
          <Text as='div' color={textColor} weight='bold'>
            {locationName}
          </Text>
          <Text as='div' color={textColor}>
            {formatPhoneNumber(phoneNumber ?? '')}
          </Text>
        </PopoverMenuItem>
      ))}
    </PopoverMenu>
  );
};
