import { css } from '@emotion/react';
import {
  Heading,
  Text,
  Modal,
  ModalControlModalProps,
  Table,
  ButtonBar,
  SecondaryButton,
  PrimaryButton,
  useAlert,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { useQuery } from '@frontend/react-query-helpers';
import { LineKeysApi } from '@frontend/api-line-keys';
import { LineKeyType_Enum, LocalContact } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { useState } from 'react';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useLineKeyContext, useLineKeyState } from '../store/line-key-provider';
import { genUUIDV4 } from '@frontend/string';
import { theme } from '@frontend/theme';

type Props = ModalControlModalProps & {
  scrollToBottom: () => void;
};

export const ContactsExtensionsModal = ({ scrollToBottom, ...rest }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const locationId = locationIds?.[0] ?? '';
  const [filteredGlobalData, setFilteredGlobalData] = useState<LocalContact[] | null>(null);
  const { addNewKey, finalLineKeys } = useLineKeyState(['addNewKey', 'finalLineKeys']);
  const { maxKeys } = useLineKeyContext();

  const alerts = useAlert();

  const [selectedContacts, setSelectedContacts] = useState<LocalContact[] | undefined>([]);

  const { data: lineKeyOptions } = useQuery({
    queryKey: ['line-keys', 'line-key-options', locationId],
    queryFn: () => LineKeysApi.GetLineKeyOptions(locationId),
  });

  const handleSave = () => {
    selectedContacts?.forEach((contact) => {
      addNewKey({
        [genUUIDV4()]: {
          name: contact.name ?? '',
          contactId: contact.contactId ?? '',
          lineKeyType: LineKeyType_Enum.LOCAL_CONTACT,
          extensionId: '',
          parkSlotNumber: 0,
          presenceEnabled: false,
          intercomEnabled: false,
          dataEndpointId: '',
        },
      });
    });
    // props.scrollToBottom();
    alerts.success(t('Successfully added Line Key', { count: selectedContacts?.length }));
    setSelectedContacts([]);
    scrollToBottom();
    rest.onClose();
  };

  const lineKeyCards = Object.keys(finalLineKeys ?? {});
  const numOfSlotsLeft = maxKeys - lineKeyCards.length;
  const isMaxed = Boolean(maxKeys - lineKeyCards.length - (selectedContacts?.length ?? 0) < 0);

  return (
    <Modal {...rest} maxWidth={800}>
      <Modal.Header textAlign='left' onClose={rest.onClose}>
        <>
          <Heading level={2} as='span'>
            {t('Contact')}
          </Heading>
          <Text weight='regular' color='light'>
            {t(
              'This Line Key type will allow you to speed dial the selected contacts from your local contact directory.'
            )}
          </Text>
        </>
      </Modal.Header>
      <Modal.Body>
        <Table
          data={filteredGlobalData ?? lineKeyOptions?.localContacts ?? []}
          isSelectable
          isPaginated
          hasGlobalSearch
          hasResponsiveColWidths
          fullHeight
          fullHeightConfig={{
            offset: 500,
          }}
          emptyStateConfig={{
            type: 'sync_your_phone',
            header: t('No Contacts'),
            description: t('You do not have any contacts stored in your local contact directory'),
          }}
          globalSearchConfig={{
            label: t('Search Device Name or Extension'),
            searchHandler: (term) => {
              const filteredData = lineKeyOptions?.localContacts?.filter(
                (data) =>
                  data.name?.toLocaleLowerCase().includes(term.toLocaleLowerCase()) ||
                  data.number?.toString().includes(term)
              );
              setFilteredGlobalData((filteredData as LocalContact[]) ?? null);
            },
          }}
          rowSelectionConfig={{
            onSelectionToggle: (_, data) => {
              setSelectedContacts(data);
            },
          }}
          colConfig={[
            {
              id: 'name',
              Header: t('Name'),
              accessor: (data) => data.name,
            },
            {
              id: 'phone-number',
              Header: t('Phone Number'),
              accessor: (data) => formatPhoneNumber(data.number),
            },
          ]}
          wrapperStyle={css`
            & > div:first-of-type > div:nth-of-type(2) {
              width: 300px;
            }
          `}
        />
      </Modal.Body>
      <ButtonBar css={buttonModalStyles}>
        {isMaxed && (
          <Text
            css={css`
              margin-right: ${theme.spacing(2)};
            `}
            color='light'
          >
            {t('You can only add {{count}} more Contact', { count: numOfSlotsLeft })}
          </Text>
        )}
        <SecondaryButton onClick={rest.onClose}>{t('Cancel')}</SecondaryButton>
        <PrimaryButton
          trackingId='add-contact-line-keys'
          disabled={!lineKeyOptions?.localContacts?.length || isMaxed}
          onClick={handleSave}
        >
          {t('Add Line Keys')}
        </PrimaryButton>
      </ButtonBar>
    </Modal>
  );
};

const buttonModalStyles = css`
  justify-content: end;
  align-items: center;

  button {
    width: fit-content;
  }
`;
