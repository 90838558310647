import React, { useMemo } from 'react';
import type { ScheduleTypes } from '@frontend/api-schedule';
import { useAppScopeStore } from '@frontend/scope';
import { contextFactory } from '@frontend/design-system';
import { useGetProvidersListForMultipleLocationIds } from '../hooks';
import type { CalendarEventContextType } from '../types';

// context
export const [CalendarEventContext, useCalendarEvent] = contextFactory<CalendarEventContextType>();

// provider
interface CalendarEventProviderProps {
  children: React.ReactNode;
  customSelectedLocationIds?: string[];
  customProvidersList?: ScheduleTypes.SchemaProvider[];
}

export const CalendarEventProvider = React.memo(
  ({ children, customSelectedLocationIds, customProvidersList }: CalendarEventProviderProps) => {
    const { selectedLocationIds } = useAppScopeStore();
    const locationIds = customSelectedLocationIds || selectedLocationIds || [];
    const { data: providers } = useGetProvidersListForMultipleLocationIds({ locationIds });
    const providersList = customProvidersList || providers || [];

    const value = useMemo(
      () => ({
        selectedLocationIds: locationIds,
        providersList: providersList,
      }),
      [locationIds, providersList]
    );

    return <CalendarEventContext.Provider value={value}>{children}</CalendarEventContext.Provider>;
  }
);

CalendarEventProvider.displayName = 'CalendarEventProvider';
