import { useEffect } from 'react';
import { useAppScopeStore } from '@frontend/scope';
import { WebsocketReadyState, subscribeLocations } from '@frontend/websocket';
import { useWeaveWebsocketConnection } from '../utils/websocket-connection';
import { useAllWebsocketEventSubscriptions } from './use-all-websocket-event-subscriptions';

export const WebsocketSubscriptionsProvider = ({ children }: { children: React.ReactNode }) => {
  const { selectedLocationIds } = useAppScopeStore();
  const ws = useWeaveWebsocketConnection();

  useEffect(() => {
    if (ws?.current?.readyState !== WebsocketReadyState.OPEN) return;
    if (!selectedLocationIds.length) return;
    /**
     * if the user has access to the new websocket service, and changes locations,
     * we need to let the websocket service know by sending a subscribe message
     */
    subscribeLocations({ locationIds: selectedLocationIds, ws: ws.current });
  }, [ws?.current?.readyState, selectedLocationIds.toString()]);

  useAllWebsocketEventSubscriptions();

  return <>{children}</>;
};
