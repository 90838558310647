import { FC } from 'react';
import { Icon, type IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton } from '@frontend/design-system';
import { useGeneratedLink } from '../../../../../hooks';
import { useSendFormsStore } from '../../../../../providers';
import { DeliveryOption as DeliveryOptionType } from '../../../../../types';
import { kioskMessageContainerStyle, kioskMessageButtonStyle, iconStyle } from './delivery-option.styles';

interface DeliveryOptionProps {
  icon: IconName;
  displayName: string;
  messageMode: DeliveryOptionType;
  onClick?: () => void;
  show?: boolean;
  trackingId?: string;
}

const DeliveryOption: FC<DeliveryOptionProps> = ({
  icon,
  displayName,
  messageMode,
  onClick,
  show = true,
  trackingId,
}) => {
  const { setMessageMode, setSelectedDeliveryOption } = useSendFormsStore([
    'setMessageMode',
    'setSelectedDeliveryOption',
  ]);
  const { generateLink } = useGeneratedLink();

  const handleClick = async () => {
    setMessageMode(messageMode);

    const { success } = await generateLink(messageMode);

    if (success && onClick) {
      onClick();
      setSelectedDeliveryOption(messageMode);
    }
  };

  return (
    <div css={kioskMessageContainerStyle}>
      <NakedButton
        css={kioskMessageButtonStyle}
        onClick={show ? handleClick : undefined}
        trackingId={trackingId}
        style={show ? {} : { color: theme.colors.neutral30 }}
      >
        <Icon name={icon} css={iconStyle} />
        <div>{displayName}</div>
      </NakedButton>
    </div>
  );
};

export default DeliveryOption;
