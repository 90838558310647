import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import {
  DeviceType_Enum,
  LineKey,
  ListDevice,
  ModelInfo,
} from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { DevicesQueries } from '@frontend/api-devices';
import { LineKeysApi, LineKeysTypes } from '@frontend/api-line-keys';
import { useQuery } from '@frontend/react-query-helpers';
import { useNavSize, NavSize } from '@frontend/responsiveness';
import { genUUIDV4 } from '@frontend/string';
import { theme } from '@frontend/theme';
import { ContentLoader } from '@frontend/design-system';
import { LineKeyCards } from '../../../components/devices/line-keys/cards-layout/cards';
import { LineKeyFooter } from '../../../components/devices/line-keys/footer';
import { DeviceLayout } from '../../../components/devices/line-keys/preview-layout';
import {
  LineKeyContext,
  LineKeyProvider,
  useLineKeyState,
} from '../../../components/devices/line-keys/store/line-key-provider';
import { isLocalContactExtension } from '../../../components/devices/line-keys/utils';
import { usePhoneSettingsShallowStore } from '../../../store/settings';

export const LineKeyWrapper = ({ deviceId }: { deviceId: string }) => {
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const locationId = locationIds?.[0] ?? '';

  const { data: lineKeyOptions } = useQuery({
    queryKey: ['line-keys', 'mapped-line-key-options', locationId],
    queryFn: () => LineKeysApi.GetLineKeyOptions(locationId),
    select: ({ localContacts, officeExtensions, userExtensions, voicemailExtensions }) => {
      const allExtensions: LineKeysTypes.ExtensionsUnionType[] = [
        ...userExtensions,
        ...voicemailExtensions,
        ...localContacts,
        ...officeExtensions,
      ];
      const mappedExtensions = allExtensions.reduce<Record<string, LineKeysTypes.ExtensionsUnionType>>((acc, sum) => {
        const id = isLocalContactExtension(sum) ? sum.contactId : sum.extensionId;
        return {
          ...acc,
          [id]: sum,
        };
      }, {});

      return mappedExtensions;
    },
  });

  const { data: lineKeys, isLoading: isLoadingLineKeys } = useQuery({
    queryKey: ['device', 'line-keys', deviceId, locationId],
    queryFn: () => LineKeysApi.GetLineKeys(deviceId, locationId),
    select: (lineKeys) => {
      const obj: Record<string, LineKey> = {};
      lineKeys?.lineKeys.forEach((item) => {
        const id = genUUIDV4();
        obj[id] = item;
      });
      return obj;
    },
  });

  const { data: device, isLoading: isLoadingDevice } = DevicesQueries.useGetDevicesList(
    {
      locationIds,
      deviceType: DeviceType_Enum.DESK_PHONE,
    },
    {
      select: ({ devices }) => {
        return devices.find((device) => device.deviceId === deviceId);
      },
    }
  );

  const { data: model, isLoading: isLoadingModel } = useQuery({
    queryKey: [locationId, 'line-keys', 'device-models'],
    queryFn: () => LineKeysApi.GetDeviceModelInfo(locationId),
    select: ({ models }) => {
      return models.find((model) => device?.model === model.model);
    },
  });

  const maxKeys = (model?.lineKeys?.maxKeys ?? 12) - (device?.extensions.length ?? 0);

  const isLoading = isLoadingModel || isLoadingDevice || isLoadingLineKeys;
  if (isLoading) return <ContentLoader show />;

  return (
    <LineKeyContext.Provider
      value={{
        lineKeyOptions,
        lineKeys,
        model,
        maxKeys,
        device,
      }}
    >
      <LineKeyProvider>
        <LineKeyContainer model={model} lineKeys={lineKeys} device={device} />
      </LineKeyProvider>
    </LineKeyContext.Provider>
  );
};

type Props = {
  device: ListDevice | undefined;
  lineKeys: Record<string, LineKey> | undefined;
  model: ModelInfo | undefined;
};

const LineKeyContainer = ({ device, lineKeys, model }: Props) => {
  const { finalLineKeys, setIsMenuBarOpen } = useLineKeyState(['finalLineKeys', 'setIsMenuBarOpen']);
  const deviceId = device?.deviceId ?? '';
  const [showDeviceLayoutPreview, setShowDeviceLayoutPreview] = useState(false);
  const navSize = useNavSize();
  const isSmallerThanDesktop = navSize !== NavSize.Desktop;
  const isMobile = navSize === NavSize.Mobile;
  const lineKeysArray = useMemo(() => {
    return Object.values(finalLineKeys ?? lineKeys ?? {});
  }, [lineKeys, finalLineKeys]);

  const heightPx = isSmallerThanDesktop ? theme.spacing(8) : theme.spacing(12);
  return (
    <div
      css={[
        lineKeyContainerStyles,
        css`
          height: calc(100vh - ${heightPx});
        `,
      ]}
    >
      <div css={styles({ isMobile, showDeviceLayoutPreview })}>
        {!showDeviceLayoutPreview && (
          <LineKeyCards setShowDeviceLayoutPreview={setShowDeviceLayoutPreview} deviceId={deviceId} />
        )}
        {(!isMobile || showDeviceLayoutPreview) && (
          <DeviceLayout
            previewOnly={isMobile}
            setIsMenuBarOpen={setIsMenuBarOpen}
            model={model}
            lineKeys={lineKeysArray}
            device={device}
            setShowDeviceLayoutPreview={setShowDeviceLayoutPreview}
          />
        )}
      </div>
      <LineKeyFooter />
    </div>
  );
};

const lineKeyContainerStyles = css`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const styles = ({ isMobile, showDeviceLayoutPreview }: { isMobile: boolean; showDeviceLayoutPreview: boolean }) => css`
  display: grid;
  grid-template-columns: ${isMobile ? 'auto' : 'auto 1fr'};
  overflow: hidden;
  height: 100%;

  ${showDeviceLayoutPreview &&
  isMobile &&
  `
     padding: ${theme.spacing(2)} 
  `}
`;
