import React from 'react';
import { useStyles } from '../../../../use-styles';
import { CaretLeftIcon, CaretRightIcon } from '../../../../icon';
import {
  CalendarContainer,
  CalendarMonth,
  CalendarControl,
  CalendarWeekHeader,
  CalendarTitle,
  CalendarMonths,
  CalendarYears,
} from '../../atoms';
import { IconButton } from '../../../icon-button';
import { useCalendarState, SELECT_BY_MAP } from '../../provider';

type SingleMonthDisplayProps = {
  btnDiameter?: number;
};

export const SingleMonthDisplay = ({ btnDiameter }: SingleMonthDisplayProps) => {
  const { months, selectBy } = useCalendarState();
  const { year, month, weeks } = months[0];
  const calWeekHeaderStyle = useStyles('CalendarCommonStyles', 'calWeekHeaderStyles');
  const calContainerStyle = useStyles('CalendarCommonStyles', 'calContainerStyles', {
    btnDiameter: btnDiameter ?? 6,
  });
  const controlsHeader = useStyles('SingleMonthCalendarControls', 'controlsHeader');
  const monthName = useStyles('SingleMonthCalendarControls', 'monthName');
  const calendarControls = useStyles('SingleMonthCalendarControls', 'calendarControls');

  return (
    <CalendarContainer css={calContainerStyle}>
      <header css={controlsHeader}>
        <CalendarTitle month={month} year={year} css={monthName} />
        <CalendarControl
          renderPrevButton={(props) => (
            <IconButton label={`Previous ${SELECT_BY_MAP[selectBy]}`} showLabelOnHover type='button' {...props}>
              <CaretLeftIcon color='light' size={20} />
            </IconButton>
          )}
          renderNextButton={(props) => (
            <IconButton label={`Next ${SELECT_BY_MAP[selectBy]}`} showLabelOnHover type='button' {...props}>
              <CaretRightIcon color='light' size={20} />
            </IconButton>
          )}
          css={calendarControls}
        />
      </header>
      {selectBy === 'day' && (
        <React.Fragment>
          <CalendarWeekHeader css={calWeekHeaderStyle} />
          <CalendarMonth weeks={weeks} />
        </React.Fragment>
      )}
      {selectBy === 'month' && <CalendarMonths className='calendar__single' month={month} year={year} />}
      {selectBy === 'year' && <CalendarYears className='calendar__single' year={year} />}
    </CalendarContainer>
  );
};
