import { format } from 'date-fns';
import { DownloadPayload, createDownloadLink } from './';

export function openPDFInNewWindow(base64Data: Blob) {
  const fileURL = URL.createObjectURL(base64Data);
  const pdfWindow = window.open(fileURL, '_blank');

  if (pdfWindow) {
    const interval = setInterval(() => {
      if (pdfWindow.closed) {
        URL.revokeObjectURL(fileURL as string);
        clearInterval(interval);
      }
    }, 500);
  }
}

export function generateFileName(pdfName: string) {
  const regExp = /[ #<${+%>!`&.*'|{?"=}/:\\@-]+/gi;
  const formattedPDFName = pdfName.trim().replace(regExp, '_');
  const date = format(Date.now(), 'yyyyMMdd');
  return `${formattedPDFName}_${date}`;
}

export function savePDF(data: DownloadPayload) {
  let { base64Data } = data;
  base64Data = `data:application/pdf;base64,${base64Data}`;
  return createDownloadLink({ ...data, base64Data });
}
