import { useEffect, useState } from 'react';
import { useTooltip } from '../tooltip';
import { Text, TextProps } from '../text';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CheckIconSmall, CopyIcon } from '../../icon';
import { useStyles } from '../../use-styles';
import { onlyText } from 'react-children-utilities';
import { IconButton } from '../icon-button';

export const CopyableText = ({
  children,
  tooltipText = 'Copy',
  tooltipSuccessText = 'Text copied!',
  text,
  trackingId,
  ...rest
}: TextProps & {
  text?: string;
  tooltipText?: string;
  tooltipSuccessText?: string;
  trackingId?: string;
}) => {
  const { triggerProps, tooltipProps, Tooltip } = useTooltip({ hoverDelay: 500 });
  const [copied, setCopied] = useState(false);
  const textStyles = useStyles('CopyableText', 'text');
  const tooltipStyles = useStyles('CopyableText', 'tooltip', { copied });

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [tooltipProps.isOpen]);

  return (
    <>
      <CopyToClipboard text={text || onlyText(children)} onCopy={() => setCopied(true)} data-trackingid={trackingId}>
        <Text {...triggerProps} css={textStyles} {...rest}>
          {children}
        </Text>
      </CopyToClipboard>
      <Tooltip {...tooltipProps} css={tooltipStyles}>
        {copied ? (
          <CheckIconSmall className='copy-status-icon' size={16} />
        ) : (
          <CopyIcon className='copy-status-icon' size={16} />
        )}
        <Text css={{ margin: 0 }} color='white'>
          {copied ? tooltipSuccessText : tooltipText}
        </Text>
      </Tooltip>
    </>
  );
};

export const CopyableTextButton = ({
  children,
  tooltipText = 'Copy',
  tooltipSuccessText = 'Text copied!',
  text,
  trackingId,
  ...rest
}: TextProps & {
  text?: string;
  tooltipText?: string;
  tooltipSuccessText?: string;
  trackingId?: string;
}) => {
  const { triggerProps, tooltipProps, Tooltip } = useTooltip({ hoverDelay: 500 });
  const [copied, setCopied] = useState(false);
  const tooltipStyles = useStyles('CopyableText', 'tooltip', { copied });

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [tooltipProps.isOpen]);

  return (
    <>
      <Text {...rest}>{children}</Text>
      <CopyToClipboard text={text || onlyText(children)} onCopy={() => setCopied(true)} data-trackingid={trackingId}>
        <IconButton size='small' label='' {...triggerProps}>
          <CopyIcon size={16} />
        </IconButton>
      </CopyToClipboard>
      <Tooltip {...tooltipProps} css={tooltipStyles}>
        {copied ? (
          <CheckIconSmall className='copy-status-icon' size={16} />
        ) : (
          <CopyIcon className='copy-status-icon' size={16} />
        )}
        <Text css={{ margin: 0 }} color='white'>
          {copied ? tooltipSuccessText : tooltipText}
        </Text>
      </Tooltip>
    </>
  );
};
