import React, { ReactNode } from 'react';

import { Interpolation, Theme, css } from '@emotion/react';
import { CheckboxField, Heading, PrimaryButton, SkeletonLoaders, useFormField } from '@frontend/design-system';
import { i18next } from '@frontend/i18n';
import { theme } from '@frontend/theme';

import { MAX_CARD_WIDTH } from './constant';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  renderFreeTrialCardContent: ReactNode;
  activateTrialButtonLabel: string;
  onActivateTrialClick: () => void;
  title?: string;
  acceptFreeTrialTermsTrackingId?: string;
  activateFreeTrialButtonTrackingId?: string;
  acceptTermsAndConditionLabel?: ReactNode | string;
  acceptTermsAndConditionValue?: boolean;
  isLoading?: boolean;
  isActivateTrialBtnDisabled?: boolean;
  cardStyles?: Interpolation<Theme>;
}

export const SignUpPageFreeTrialSubscriptionCard = React.memo(
  ({
    renderFreeTrialCardContent,
    activateTrialButtonLabel,
    onActivateTrialClick,
    title = i18next.t('Start Your Free Trial', { ns: 'onlineSchedulingSignUpPage' }),
    acceptTermsAndConditionLabel = '',
    acceptFreeTrialTermsTrackingId = '',
    activateFreeTrialButtonTrackingId = '',
    acceptTermsAndConditionValue = false,
    isLoading = false,
    isActivateTrialBtnDisabled = false,
    cardStyles,
    ...rest
  }: IProps) => {
    const acceptTermsAndConditionField = useFormField({
      type: 'checkbox',
      value: acceptTermsAndConditionValue,
    });

    const isActionDisabled =
      isActivateTrialBtnDisabled ?? acceptTermsAndConditionLabel
        ? !acceptTermsAndConditionField.value
        : isLoading ?? false;

    return (
      <div css={[freeTrialSubscriptionCard, cardStyles]} {...rest}>
        {isLoading ? (
          <FreeTrialCardLoader />
        ) : (
          <>
            <Heading level={2}>{title}</Heading>
            {renderFreeTrialCardContent}
            {acceptTermsAndConditionLabel && (
              <CheckboxField
                css={checkboxFieldStyle}
                name='free-trial-tnc-checkbox'
                trackingId={acceptFreeTrialTermsTrackingId}
                {...acceptTermsAndConditionField}
                label={acceptTermsAndConditionLabel}
              />
            )}
            <PrimaryButton
              onClick={onActivateTrialClick}
              trackingId={activateFreeTrialButtonTrackingId}
              disabled={isActionDisabled}
            >
              {activateTrialButtonLabel}
            </PrimaryButton>
          </>
        )}
      </div>
    );
  }
);

const FreeTrialCardLoader = () => {
  return (
    <>
      <SkeletonLoaders.Loader height={theme.spacing(8)} />
      {Array(3)
        .fill(0)
        .map((_, idx) => (
          <SkeletonLoaders.Loader key={idx} height={theme.spacing(2)} />
        ))}
    </>
  );
};

const checkboxFieldStyle = css({
  marginTop: theme.spacing(2),
});

const freeTrialSubscriptionCard = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.borderRadius.medium,
  border: `1px solid ${theme.colors.neutral20}`,
  maxWidth: MAX_CARD_WIDTH,
});
