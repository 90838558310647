import { useCallback, useEffect, useRef, useState } from 'react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { PaymentOrigin } from '@frontend/api-invoices';
import { SetupFutureUsage } from '@frontend/api-weave-pay';
import { HttpError } from '@frontend/fetch';
import { useMerchant } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import {
  ITerminalStrategyError,
  PaymentsTerminalController,
  PaymentsTerminalError,
  TerminalPaymentStatus,
} from '@frontend/payments-terminal-controller';
import { useShell } from '@frontend/shell-utils';
import { PickPartial, Prettify } from '@frontend/types';
import { CollectPaymentModalSteps, useCreatePaymentIntent, useDiscoverReaderQuery, useInvoicePerson } from '../hooks';
import { CollectReaderPaymentDisplay } from '../reader-payment-deprecated';
import { useSelectedReader } from './provider';
import { ReaderStatusAdapter } from './reader-status';

const RESTRICTED_TERMINAL_STATES: TerminalPaymentStatus[] = ['success', 'processing'];

export const CollectionStepReaderPayment = () => {
  const { resetSteps, closeModal } = useMultiStepModal();
  const { paymentsUrl, locationId, stripeLocationId } = useMerchant();
  const shell = useShell();

  // PAYMENT INTENT
  const { invoice } = useSelectedInvoice();
  const person = useInvoicePerson(invoice);
  const {
    paymentIntent,
    error: createIntentError,
    retryCreatePaymentIntent,
  } = useCreateTerminalPaymentIntent({ person, invoice });

  // TERMINAL
  const { selectedReader, setSelectedReader } = useSelectedReader();
  const {
    error: readerError,
    status: readerStatus,
    collectAndProcessPayment,
    cancelPayment,
    retryPayment,
  } = useTerminalPaymentSession({
    reader: selectedReader,
    paymentsUrl: paymentsUrl ?? undefined,
    locationId,
    appData: shell.isShell
      ? {
          type: 'shell',
          version: shell.version,
        }
      : {
          type: 'nwx',
          version: undefined,
        },
  });

  // INITIATE COLLECTION
  useEffect(() => {
    if (!paymentIntent?.paymentIntent || !paymentIntent.paymentId || !collectAndProcessPayment || readerError) {
      return;
    }
    collectAndProcessPayment(paymentIntent.paymentIntent, paymentIntent.paymentId, invoice?.id);
  }, [paymentIntent?.paymentIntent, collectAndProcessPayment, readerError]);

  const handleCancel = async () => {
    try {
      await cancelPayment();
    } catch (error) {
      // TODO: do nothing?
      console.log('Error cancelling payment', { error });
    } finally {
      resetSteps(CollectPaymentModalSteps.PaymentFlowList);
    }
  };

  const handleTryAgain = async () => {
    let intent = paymentIntent;
    try {
      if (!intent?.paymentIntent || !intent.paymentId) {
        const { intent: newPaymentIntent, paymentIntentError } = await retryCreatePaymentIntent();

        if (paymentIntentError) throw paymentIntentError;
        else intent = newPaymentIntent;
      }
      if (intent?.paymentIntent && intent.paymentId) {
        await retryPayment(intent.paymentIntent, intent.paymentId, invoice?.id);
      }
    } catch (error) {
      console.log('Error retrying payment', { error });
    }
  };

  const _handleEndActivePayment = () => {
    //TODO: implement this
    // connectSelectedReader(false);
    // need a way to override an active payment....
    // Server driven sends a cancel
    // sdk - would connect and override....
  };

  // TODO: implement terminal is in use
  const isTerminalInUse = false;
  const paymentSuccessful = readerStatus === 'success';
  const error = createIntentError ?? readerError;

  const discoverReaderQuery = useDiscoverReaderQuery({
    locationId,
    stripeLocationId,
    paymentsUrl: paymentsUrl ?? undefined,
  });

  return (
    <CollectReaderPaymentDisplay
      selectedReader={selectedReader}
      readerError={readerError}
      isTerminalInUse={isTerminalInUse}
      paymentSuccessful={paymentSuccessful}
      handleEndActivePayment={_handleEndActivePayment}
      onCancelClick={handleCancel}
      onChangePaymentMethodClick={resetSteps}
      onCloseClick={closeModal}
      onTryAgainClick={handleTryAgain}
      ReaderStatusOverrideComponent={<ReaderStatusAdapter status={readerStatus} error={error} />}
      availableReaders={discoverReaderQuery.data}
      onSelectedReaderChange={(terminal) => {
        if (!paymentsUrl) return; // this should never happen, we only show readers if there is a payments url
        return setSelectedReader(PaymentsTerminalController.createStoredReader(locationId, paymentsUrl, terminal));
      }}
      disableTerminalSelection={RESTRICTED_TERMINAL_STATES.includes(readerStatus)}
    />
  );
};

type UseTerminalPaymentSessionProps = Prettify<
  PickPartial<
    Omit<
      Parameters<typeof PaymentsTerminalController.createTerminalSession>[number],
      'onError' | 'onPaymentStatusChange' | 'enableServerDriven'
    >,
    'reader' | 'paymentsUrl' | 'sdkFailIfReaderInUse'
  >
>;
type TerminalActions = Awaited<ReturnType<typeof PaymentsTerminalController.createTerminalSession>>;

type UseCreatePaymentIntentProps = {
  person: ReturnType<typeof useInvoicePerson>;
  invoice: ReturnType<typeof useSelectedInvoice>['invoice'];
};

const useCreateTerminalPaymentIntent = ({ person, invoice }: UseCreatePaymentIntentProps) => {
  const [paymentIntent, setPaymentIntent] = useState<Awaited<ReturnType<typeof createPaymentIntent>>>();
  const [error, setError] = useState<ITerminalStrategyError>();

  const { createPaymentIntent } = useCreatePaymentIntent({
    amount: invoice?.billedAmount ?? 0,
    email: person?.Email,
    personId: person?.PersonID,
    setupFutureUsage: SetupFutureUsage.offSession,
    locationId: invoice?.locationId,
    origin: PaymentOrigin.Terminal,
    invoiceId: invoice?.id,
  });

  const createOnce = useRef(false);

  const tryCreatePaymentIntent = useCallback(async () => {
    let intent: typeof paymentIntent | undefined;
    let paymentIntentError: ITerminalStrategyError | undefined;
    if (!createOnce.current) {
      createOnce.current = true;
      try {
        intent = await createPaymentIntent();
        setPaymentIntent(intent);
      } catch (error) {
        if (error instanceof HttpError || error instanceof Error) {
          paymentIntentError = { action: 'initialize', message: error.message };
          setError(paymentIntentError);
        }
      }
    }
    return { intent, paymentIntentError };
  }, [createPaymentIntent]);

  useEffect(() => {
    tryCreatePaymentIntent();
  }, [createPaymentIntent, paymentIntent?.clientSecret]);

  const retryCreatePaymentIntent = async () => {
    createOnce.current = false;
    return tryCreatePaymentIntent();
  };

  return { paymentIntent, error, retryCreatePaymentIntent };
};

type CollectAndProcessPaymentArgs = Parameters<
  Awaited<ReturnType<(typeof PaymentsTerminalController)['createTerminalSession']>>['collectAndProcessPayment']
>[0];

const useTerminalPaymentSession = ({ reader, paymentsUrl, locationId, appData }: UseTerminalPaymentSessionProps) => {
  const [terminalActions, setTerminalActions] = useState<TerminalActions>();
  const [error, setError] = useState<ITerminalStrategyError>();
  const [status, setStatus] = useState<TerminalPaymentStatus>('initializing' as TerminalPaymentStatus);
  const mounted = useRef(true);

  const { aggregateValue: enableServerDriven } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'payments:nwx:server-driven-terminals',
    locationIds: [locationId],
  });

  // to ensure only one terminal payment happens, set to false to allow retries
  const terminalPaymentStarted = useRef(false);

  const initializePaymentSession = useCallback(async () => {
    let initializedActions: TerminalActions | undefined;
    let initializationError: ITerminalStrategyError | undefined;

    if (!reader || !paymentsUrl || !locationId) {
      return { initializedActions, initializationError };
    }

    if (RESTRICTED_TERMINAL_STATES.includes(status)) {
      return { initializedActions, initializationError };
    }

    try {
      // If terminalActions is already set, cancel the current action
      await cancelPayment();

      initializedActions = await PaymentsTerminalController.createTerminalSession({
        locationId,
        paymentsUrl,
        reader,
        appData,
        enableServerDriven,
        onPaymentStatusChange(status) {
          if (!mounted.current) return;
          setStatus(status);
        },
      });
      setTerminalActions(initializedActions);
    } catch (error) {
      if (error instanceof PaymentsTerminalError) {
        initializationError = { action: 'initialize', message: error.message };
      } else {
        initializationError = { action: 'initialize', message: 'An unexpected error occurred' };
      }
      setStatus('connecting');
      setError(initializationError);
    }

    return { initializedActions, initializationError };
  }, [reader, paymentsUrl, locationId, enableServerDriven]);

  useEffect(() => {
    initializePaymentSession();
  }, [initializePaymentSession]);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const collectAndProcessPayment = useCallback(
    async (
      paymentIntent: CollectAndProcessPaymentArgs['paymentIntent'],
      paymentId: CollectAndProcessPaymentArgs['paymentId'],
      invoiceId?: CollectAndProcessPaymentArgs['invoiceId'],
      actions?: TerminalActions,
      considerError = true
    ) => {
      actions = actions ?? terminalActions;
      if ((considerError && error) || !actions) {
        setStatus('connecting');
        setError({ action: 'collect', message: 'Terminal session not initialized' });
        return;
      }

      // Check needs to be here to prevent duplicate collects from running
      if (terminalPaymentStarted.current) {
        return;
      }

      try {
        terminalPaymentStarted.current = true;
        return await actions?.collectAndProcessPayment({ paymentIntent, paymentId, invoiceId });
      } catch (error) {
        if (!mounted.current) return;
        setStatus('connecting');
        if (error instanceof PaymentsTerminalError) {
          if (error.code === 'canceled') return; // good idea?
          setError(error);
        } else if (error instanceof Error) {
          console.log('error in collectAndProcessPayment', error);
          setError({ action: 'collect', message: error.message });
        } else {
          setError({ action: 'collect', message: 'An unexpected error occurred' });
        }
        return;
      }
    },
    [terminalActions, error]
  );

  const retryPayment = async (
    paymentIntent: CollectAndProcessPaymentArgs['paymentIntent'],
    paymentId: CollectAndProcessPaymentArgs['paymentId'],
    invoiceId: CollectAndProcessPaymentArgs['invoiceId']
  ) => {
    setError(undefined);
    terminalPaymentStarted.current = false;

    let actions = terminalActions;
    if (!actions) {
      const { initializedActions, initializationError } = await initializePaymentSession();
      if (initializationError) return;
      else actions = initializedActions;
    }

    await collectAndProcessPayment(paymentIntent, paymentId, invoiceId, actions, false);
  };

  const cancelPayment = useCallback(() => {
    setError(undefined);
    terminalPaymentStarted.current = false;
    if (!terminalActions) {
      // do nothing because terminal session not created yet
      return Promise.resolve();
    }
    return terminalActions?.cancelCurrentAction();
  }, [terminalActions?.cancelCurrentAction]);

  // Cancel payment on unmount
  useEffect(
    () => () => {
      if (terminalPaymentStarted.current) {
        cancelPayment().catch((error) => {
          if (!mounted.current) return;
          if (error instanceof PaymentsTerminalError) {
            setError(error);
          } else {
            setError({ action: 'cancel', message: 'An unexpected error occurred' });
          }
        });
      }
    },
    [cancelPayment]
  );

  // Store reader when successful
  const storeOnce = useRef(false);
  useEffect(() => {
    if (!paymentsUrl || status !== 'success' || !reader || !locationId || storeOnce.current) {
      return;
    }
    PaymentsTerminalController.storeReader(locationId, paymentsUrl, reader);
    storeOnce.current = true;
  }, [status, locationId, paymentsUrl, reader]);

  return {
    cancelPayment,
    collectAndProcessPayment: terminalActions?.collectAndProcessPayment ? collectAndProcessPayment : undefined,
    error,
    retryPayment,
    status,
  };
};
