import {
  DeviceInformation,
  SelectOutboundIdMenu,
  SelectOutboundIdMenuProps,
  THEME_STYLES,
} from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useDialpadManagerClient } from '../providers/dialpad-manager-provider';
import { useDialpadWidgetControl } from '../providers/dialpad-widget-control-provider';

interface Props {
  hideExtension?: boolean;
}

export const DeviceInformationWrapper = ({ hideExtension }: Props) => {
  const { t } = useTranslation('dialpad');
  const { navigate } = useSettingsNavigate();
  const connectedDevice = useDialpadManagerClient((ctx) => ctx.connectedDeskphone);
  const close = useDialpadWidgetControl((ctx) => ctx.close);
  const availablePhoneNumbers = useDialpadManagerClient((ctx) => ctx.availablePhoneNumbers);
  const currentOutboundPhoneNumber = useDialpadManagerClient((ctx) => ctx.currentOutboundPhoneNumber?.number);

  const deviceInfoProps = {
    deviceName: connectedDevice?.[0]?.name ?? '',
    onChangeDeviceClick: () => {
      navigate({ to: '/personal/phone/device-connection' });
      close();
    },
    changeDeviceText: t('Change Device'),
    showExtension: !hideExtension,
    extensionNumberText: t('Extension {{extensionNumber}}', { extensionNumber: connectedDevice?.[0]?.extension }),
    currentPhoneNumberText: currentOutboundPhoneNumber
      ? t('Outbound ID: {{currentPhoneNumber}}', { currentPhoneNumber: formatPhoneNumber(currentOutboundPhoneNumber) })
      : null,
    availablePhoneNumbers: availablePhoneNumbers ?? [],
    SelectOutboundIdMenuWrapper: SelectOutboundIdMenuWrapper,
    showOutboundCallerInfo: true,
    themeStyle: THEME_STYLES.LIGHT,
    trackingId: 'phn-portal-dialpad-lnk-changedevice',
  };
  return <DeviceInformation {...deviceInfoProps} />;
};

const SelectOutboundIdMenuWrapper = (popoverMenuProps: SelectOutboundIdMenuProps) => {
  const availablePhoneNumbers = useDialpadManagerClient((ctx) => ctx.availablePhoneNumbers);
  const setCurrentPhoneNumber = useDialpadManagerClient((ctx) => ctx.setCurrentOutboundNumber);
  const currentPhoneNumber = useDialpadManagerClient((ctx) => ctx.currentOutboundPhoneNumber?.number);
  return (
    <SelectOutboundIdMenu
      availablePhoneNumbers={availablePhoneNumbers}
      setCurrentPhoneNumber={setCurrentPhoneNumber}
      currentPhoneNumber={currentPhoneNumber}
      themeStyle='light'
      {...popoverMenuProps}
    />
  );
};
