import { CoreACLs, hasACL, isWeaveUser } from '@frontend/auth-helpers';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';

export const useCanDoAction = () => {
  const { locationId, locationIds } = useMultiQueryUtils();
  const { merchantsData } = useMerchant();

  const canRefund = hasACL(locationId, CoreACLs.PAYMENTS_REFUNDS);
  const canExport = hasACL(locationId, CoreACLs.PAYMENTS_EXPORTS);
  const paymentsBillingManagerLocations = locationIds.filter(
    (location) =>
      hasACL(location, [
        CoreACLs.BILLING_INFORMATION_READ,
        CoreACLs.BILLING_INFORMATION_WRITE,
        CoreACLs.PAYMENTS_REFUNDS,
        CoreACLs.PAYMENTS_EXPORTS,
        CoreACLs.PAYMENTS_EXPRESS_DASHBOARD,
      ]) || isWeaveUser()
  );
  const isPaymentsBillingManager = paymentsBillingManagerLocations.includes(locationId);
  const allowedPaymentsLocations = paymentsBillingManagerLocations.filter(
    (locationId) => !!merchantsData[locationId]?.hasPayments
  );

  return { canRefund, canExport, isPaymentsBillingManager, allowedPaymentsLocations };
};
