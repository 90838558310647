import {
  ContactDirectoriesMutation,
  ContactDirectoriesQuery,
  ContactDirectoriesQueryKeys,
} from '@frontend/api-contact-directories';
import { ContentLoader, Heading, Modal, ModalControlModalProps, Text, useAlert } from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useQueryClient } from 'react-query';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { css } from '@emotion/react';
import type { SelectedContactsType } from '../types';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import { CommonModalWrapper } from './modal-wrapper';

type Props = ModalControlModalProps & {
  contactDirectoryId?: string;
  selectedContacts: SelectedContactsType;
};
export const DeleteContactModal = ({ selectedContacts, contactDirectoryId, ...modalProps }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');
  const locationId = locationIds?.[0] ?? '';
  const { useGetContactDirectoriesByContactIds } = ContactDirectoriesQuery.useContactDirectoryQueries(locationId);
  const { contacts } = selectedContacts;
  const contactIds = contacts.map((contact) => contact.id);
  const label = t('Delete Contacts', { count: contacts.length });

  const { data: { contactLists } = { contactLists: [] }, isLoading } = useGetContactDirectoriesByContactIds({
    contactIds,
  });

  const { topMessage, bottomMessage } = renderText({
    contacts,
  });

  const { useDeleteContactMutation } = ContactDirectoriesMutation.useContactDirectoryMutations(locationId);

  const { mutateAsync: onDeleteContact } = useDeleteContactMutation();

  const handleSave = () => {
    const promise = contactIds?.map(async (id) => {
      return await onDeleteContact({ id });
    });

    Promise.all(promise)
      .then(() => {
        alerts.success(t('Successfully deleted {{count}} contacts', { count: contactIds?.length }));
        queryClient.invalidateQueries(ContactDirectoriesQueryKeys.queryKeys.getContacts(locationId));
        if (contactDirectoryId) {
          queryClient.invalidateQueries(
            ContactDirectoriesQueryKeys.queryKeys.getContactsbyContactDirectoryId(contactDirectoryId, locationId)
          );
        }
      })
      .finally(() => {
        modalProps.onClose();
      });
  };

  return (
    <>
      <ContentLoader show={isLoading} />
      <CommonModalWrapper disableCloseOnEscape disableCloseOnOverlayClick {...modalProps}>
        <Modal.Header onClose={modalProps.onClose}>
          <Heading level={2}>{label}</Heading>
        </Modal.Header>
        <Modal.Body
          css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), margin: theme.spacing(3, 0) }}
        >
          {topMessage}
          {!!contactLists?.length && (
            <>
              <ul>
                {contactLists?.map((list) => {
                  return (
                    <Text
                      as={'li'}
                      key={list.id}
                      css={css`
                        margin: ${theme.spacing(1, 0)};
                      `}
                    >
                      {list.name}
                    </Text>
                  );
                })}
              </ul>
            </>
          )}
          {bottomMessage}
        </Modal.Body>
        <Modal.Actions
          onPrimaryClick={handleSave}
          destructive
          primaryLabel={label}
          secondaryLabel={t('Cancel')}
          onSecondaryClick={modalProps.onClose}
        />
      </CommonModalWrapper>
    </>
  );
};

const renderText = ({ contacts }: { contacts: Contact[] }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });

  const topMessage =
    contacts?.length === 1 ? (
      <Text>
        {t('This contact will be deleted from the following contact lists and the devices using those lists:')}
      </Text>
    ) : (
      <Text>
        {t(
          'These contacts will be deleted and removed from the following contact lists and the devices using those lists:'
        )}
      </Text>
    );

  const bottomMessage =
    contacts.length === 1 ? (
      <Text>
        <Trans t={t} name={contacts[0].name}>
          Are you sure you want to delete <b>{{ name: contacts[0].name }}</b>?
        </Trans>
      </Text>
    ) : (
      <Text>
        <Trans t={t} count={contacts.length}>
          Are you sure you want to delete <b>{{ count: contacts.length }} contacts</b>?
        </Trans>
      </Text>
    );

  return {
    topMessage,
    bottomMessage,
  };
};
