import { useSoftphoneRouter, CurrentRoute as CurrentRouteType, Overlay } from '../providers/softphone-router-provider';
import { useSoftphoneSettings } from '../providers/softphone-settings-provider';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';
import { PageCallAddCall } from './call-add-call';
import { PageCallHome } from './call-home';
import { PageCallKeypad } from './call-keypad';
import { PageCallPark } from './call-park';
import { PageCallTransfer } from './call-transfer';
import { PageHomeKeypad } from './home-keypad';
import { PageHomeParkSlots } from './home-park-slots';
import { PageMergedCallList } from './merged-call-list';
import { PageMergedHome } from './merged-home';
import { PageMergedKeypad } from './merged-keypad';
import { PageNotFound } from './not-found';
import { SettingsOverlay } from './overlays/settings';
import { PageTransferHome } from './transfer-home';
import { PageTransferKeypad } from './transfer-keypad';
import { PageCallHomeMobile } from '../pages-mobile/call-home-mobile';
import { PageTransferHomeMobile } from '../pages-mobile/transfer-home-mobile';
import { PageMergedHomeMobile } from '../pages-mobile/merged-home-mobile';
import { HeldCallPageMobile } from '../pages-mobile/held-call-page-mobile';

export const Router = () => {
  const currentRoute = useSoftphoneRouter((ctx) => ctx.currentRoute);
  const currentOverlay = useSoftphoneRouter((ctx) => ctx.currentOverlay);
  const isShowingSettings = useSoftphoneSettings((ctx) => ctx.isShowingSettings);

  return (
    <>
      <CurrentOverlay currentOverlay={currentOverlay} />
      {!isShowingSettings && <CurrentRoute currentRoute={currentRoute} />}
    </>
  );
};

const CurrentOverlay = ({ currentOverlay }: { currentOverlay: Overlay | undefined }) => {
  switch (currentOverlay) {
    case 'settings':
      return <SettingsOverlay />;
  }
  return null;
};

const CurrentRoute = ({ currentRoute }: { currentRoute: CurrentRouteType }) => {
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);

  switch (currentRoute.section) {
    case 'home':
      switch (currentRoute.route) {
        case 'park-slots':
          return <PageHomeParkSlots />;
        case 'keypad':
        default:
          return <PageHomeKeypad />;
      }
    case 'single-call':
      switch (currentRoute.route) {
        case 'add-call':
          return <PageCallAddCall />;
        case 'keypad':
          return <PageCallKeypad />;
        case 'park':
          return <PageCallPark />;
        case 'transfer':
          return <PageCallTransfer />;
        case 'allCallsOnHold':
          return <HeldCallPageMobile />;
        case 'home':
        default:
          return isMobile ? <PageCallHomeMobile /> : <PageCallHome />;
      }
    case 'attended-transfer':
      switch (currentRoute.route) {
        case 'keypad':
          return <PageTransferKeypad />;
        case 'home':
        default:
          return isMobile ? <PageTransferHomeMobile /> : <PageTransferHome />;
      }
    case 'group-call':
      switch (currentRoute.route) {
        case 'calls-list':
          return <PageMergedCallList />;
        case 'keypad':
          return <PageMergedKeypad />;
        case 'home':
        default:
          return isMobile ? <PageMergedHomeMobile /> : <PageMergedHome />;
      }
  }
  return <PageNotFound />;
};
