import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { ContextMenuActions, useAlert } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { SchemaManualSMSScheduledService } from '@frontend/schema';
import { getUser } from '@frontend/auth-helpers';
import { sentry } from '@frontend/tracking';
import { MessagesHooks, SchemaSMSSharedModels } from '@frontend/api-messaging';
import { MediaInteractionUtils } from '../utils';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';

type UseScheduledSmsBubbleActions = {
  scheduledSms: ScheduledSms;
  onEdit?: () => void;
  onDelete?: () => void;
};

export const useScheduledSmsBubbleActions = ({ scheduledSms, onEdit, onDelete }: UseScheduledSmsBubbleActions) => {
  const { t } = useTranslation('inbox');
  const alert = useAlert();
  const user = getUser();
  const invalidateInboxList = MessagesHooks.useInvalidateInboxList();
  const { remove } = MessagesHooks.useUpdateScheduledMessages();

  const copyBody = () => {
    navigator.clipboard.writeText(scheduledSms.body);
    alert.success(t('Message copied'));
  };

  const deleteMessage = async (hideAlerts?: boolean) => {
    try {
      const res = await SchemaManualSMSScheduledService.Delete({
        messageId: scheduledSms.id,
        locationId: scheduledSms.locationId,
        deletedBy: user?.userID ?? '',
      });

      remove({ threadId: scheduledSms.threadId, groupId: res.locationId, smsId: res.messageId });
      invalidateInboxList();
      onDelete?.();
      if (!hideAlerts) alert.success(t('Message deleted'));
    } catch (err) {
      if (!hideAlerts) alert.error(t('Error deleting scheduled message. Please try again.'));
    }
  };

  const getBubbleActions = (mediaItem?: Partial<SchemaSMSSharedModels.Media>): ContextMenuActions => {
    const timeDifference = new Date(scheduledSms.sendAt).getTime() - new Date().getTime();
    const messageIsWithin5Minutes =
      scheduledSms.status !== OutboundMessageStatus.PAUSED && timeDifference < 5 * 60 * 1000 && timeDifference >= 0;
    const bubbleTypeSpecificActions: ContextMenuActions = mediaItem
      ? // Image bubble actions
        [
          {
            label: t('Copy Image'),
            trackingId: `${InboxPrefixes.ScheduledMessages}-copy-image`,
            onClick: () => {
              try {
                MediaInteractionUtils.copyImage(mediaItem.mediaId!);
                alert.success(t('Image copied'));
              } catch (err) {
                alert.error(t('Error copying image'));
                sentry.error({
                  error: err,
                  topic: 'messages',
                  severityLevel: 'error',
                  addContext: {
                    name: 'Scheduled message & media item',
                    context: {
                      scheduledSms,
                      mediaItem,
                      errMessage: 'Error copying image from scheduled message',
                    },
                  },
                });
              }
            },
            disabled: !mediaItem.mediaId,
          },
          {
            label: t('Download Image'),
            trackingId: `${InboxPrefixes.ScheduledMessages}-download-image`,
            onClick: () => {
              try {
                MediaInteractionUtils.downloadImage({
                  mediaId: mediaItem.mediaId!,
                  filename: mediaItem.filename ?? `${scheduledSms.personPhone}-${t('image-download')}`,
                });
                alert.success(t('Image downloaded'));
              } catch (err) {
                alert.error(t('Error downloading image'));
                sentry.error({
                  error: err,
                  topic: 'messages',
                  severityLevel: 'error',
                  addContext: {
                    name: 'Scheduled message & media item',
                    context: {
                      scheduledSms,
                      mediaItem,
                      errMessage: 'Error downloading image from scheduled message',
                    },
                  },
                });
              }
            },
            disabled: !mediaItem.mediaId,
          },
        ]
      : // Text bubble actions
        [
          {
            label: t('Copy Message'),
            trackingId: `${InboxPrefixes.ScheduledMessages}-copy-message`,
            onClick: copyBody,
          },
        ];

    return [
      ...bubbleTypeSpecificActions,
      // Currently the BE doesn't support deleting if the message is within 5 minutes of being sent
      ...(messageIsWithin5Minutes
        ? []
        : [
            {
              label: t('Delete Message'),
              trackingId: `${InboxPrefixes.ScheduledMessages}-delete-message`,
              onClick: () => deleteMessage(),
            },
            onEdit && {
              label: t('Edit Message'),
              trackingId: `${InboxPrefixes.ScheduledMessages}-edit-message`,
              onClick: onEdit,
            },
          ]),
    ].filter(Boolean);
  };

  return getBubbleActions;
};
