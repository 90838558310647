import { contextFactory } from '@frontend/design-system';

export interface OnlineSchedulingMultiLocationCustomizationFlagContextType {
  isFeatureHiddenInAllLocations: boolean;
  isFeatureActiveInAllLocations: boolean;
  locationIdWiseCustomizationFlagDetails: { [key: string]: boolean };
}

export const [
  OnlineSchedulingMultiLocationCustomizationFlagContext,
  useOnlineSchedulingMultiLocationCustomizationFlag,
] = contextFactory<OnlineSchedulingMultiLocationCustomizationFlagContextType>();
