import { useMemo } from 'react';
import { MorningHuddleTypes, PracticeAnalyticsMockData, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useAnalyticsOrgLocations } from './use-analytics-org-locations';
import { useIsPADemoAccount } from './use-is-pa-demo-account';

type ResponseType<T> = {
  data: Record<string, T | undefined>;
};

type UsePracticeAnalyticsDemoData = {
  activePatients: ResponseType<PracticeAnalyticsTypes.ActivePatientsResponse> | null | undefined;
  activePatientsDemographics:
    | ResponseType<PracticeAnalyticsTypes.ActivePatientsDemographicsResponse>
    | null
    | undefined;
  activePatientsDemographicsScheduled:
    | ResponseType<PracticeAnalyticsTypes.ActivePatientsDemographicsScheduledResponse>
    | null
    | undefined;
  activePatientsScheduled: ResponseType<PracticeAnalyticsTypes.ActivePatientsScheduledResponse> | null | undefined;
  cancellations: ResponseType<PracticeAnalyticsTypes.CancellationsResponse> | null | undefined;
  hygieneFollowUp: ResponseType<PracticeAnalyticsTypes.HygieneFollowUpResponse> | null | undefined;
  hygieneTreatmentPlan: ResponseType<PracticeAnalyticsTypes.HygieneTreatmentPlanResponse> | null | undefined;
  monthlyTrendHistoricalData: ResponseType<PracticeAnalyticsTypes.MonthlyTrendHistoricalData> | null | undefined;
  morningHuddleLastDayProduction: ResponseType<MorningHuddleTypes.LastDayProductionResponse> | null | undefined;
  morningHuddleProductionMTD: ResponseType<MorningHuddleTypes.MonthProductionResponse> | null | undefined;
  morningHuddleScheduledProduction: ResponseType<MorningHuddleTypes.ScheduledProductionResponse> | null | undefined;
  newPatients: ResponseType<PracticeAnalyticsTypes.NewPatientsTrend> | null | undefined;
  opportunityList: ResponseType<PracticeAnalyticsTypes.OpportunityList> | null | undefined;
  practitionerAnalysisDetails:
    | ResponseType<PracticeAnalyticsTypes.PractitionerAnalysisDetailsResponse>
    | null
    | undefined;
  practitionerAnalysisSummary: ResponseType<PracticeAnalyticsTypes.PractitionerAnalysisResponse> | null | undefined;
  recapturedPatients: ResponseType<PracticeAnalyticsTypes.RecapturedPatientsTrend> | null | undefined;
  restorativeTreatmentPlan: ResponseType<PracticeAnalyticsTypes.RestorativeTreatmentPlanResponse> | null | undefined;
};

// Just getting linter off my back.
// Setting any type as it can be anything depending on the use case and I don't want to over complicate the demo data hook
type Any = any;

export const usePracticeAnalyticsDemoData = (params?: Any): UsePracticeAnalyticsDemoData | null | undefined => {
  const isDemoAccount = useIsPADemoAccount();
  const { locationIds } = useAnalyticsOrgLocations({ module: 'PA' });

  const demoData = useMemo(() => {
    if (!isDemoAccount) {
      return null;
    }

    const activePatients: Record<string, PracticeAnalyticsTypes.ActivePatientsResponse> = {};
    const activePatientsDemographics: Record<string, PracticeAnalyticsTypes.ActivePatientsDemographicsResponse> = {};
    const activePatientsDemographicsScheduled: Record<
      string,
      PracticeAnalyticsTypes.ActivePatientsDemographicsScheduledResponse
    > = {};
    const activePatientsScheduled: Record<string, PracticeAnalyticsTypes.ActivePatientsScheduledResponse> = {};
    const cancellations: Record<string, PracticeAnalyticsTypes.CancellationsResponse> = {};
    const hygieneFollowUp: Record<string, PracticeAnalyticsTypes.HygieneFollowUpResponse> = {};
    const hygieneTreatmentPlan: Record<string, PracticeAnalyticsTypes.HygieneTreatmentPlanResponse> = {};
    const monthlyTrendHistoricalData: Record<string, PracticeAnalyticsTypes.MonthlyTrendHistoricalData> = {};
    const morningHuddleLastDayProduction: Record<string, MorningHuddleTypes.LastDayProductionResponse> = {};
    const morningHuddleProductionMTD: Record<string, MorningHuddleTypes.MonthProductionResponse> = {};
    const morningHuddleScheduledProduction: Record<string, MorningHuddleTypes.ScheduledProductionResponse> = {};
    const newPatients: Record<string, PracticeAnalyticsTypes.NewPatientsTrend> = {};
    const opportunityList: Record<string, PracticeAnalyticsTypes.OpportunityList> = {};
    const practitionerAnalysisDetails: Record<string, PracticeAnalyticsTypes.PractitionerAnalysisDetailsResponse> = {};
    const practitionerAnalysisSummary: Record<string, PracticeAnalyticsTypes.PractitionerAnalysisResponse> = {};
    const recapturedPatients: Record<string, PracticeAnalyticsTypes.RecapturedPatientsTrend> = {};
    const restorativeTreatmentPlan: Record<string, PracticeAnalyticsTypes.RestorativeTreatmentPlanResponse> = {};

    locationIds.forEach((locationId) => {
      const hygieneTreatmentData = PracticeAnalyticsMockData.hygieneTreatmentPlan();
      const restorativeTreatmentData = PracticeAnalyticsMockData.restorativeTreatmentPlan();
      const practitionerAnalysisSummaryData = PracticeAnalyticsMockData.practitionerAnalysisSummary(
        hygieneTreatmentData,
        restorativeTreatmentData
      );

      activePatients[locationId] = PracticeAnalyticsMockData.activePatients();
      activePatientsDemographics[locationId] = PracticeAnalyticsMockData.activePatientsDemographics();
      activePatientsDemographicsScheduled[locationId] = PracticeAnalyticsMockData.activePatientsDemographicsScheduled();
      activePatientsScheduled[locationId] = PracticeAnalyticsMockData.activePatientsScheduled();
      cancellations[locationId] = PracticeAnalyticsMockData.cancellations();
      hygieneFollowUp[locationId] = PracticeAnalyticsMockData.hygieneFollowUp().month;
      hygieneTreatmentPlan[locationId] = hygieneTreatmentData;
      monthlyTrendHistoricalData[locationId] = PracticeAnalyticsMockData.monthlyTrendData();
      morningHuddleLastDayProduction[locationId] = PracticeAnalyticsMockData.morningHuddleLastDayProduction();
      morningHuddleProductionMTD[locationId] = PracticeAnalyticsMockData.morningHuddleProductionMTD();
      morningHuddleScheduledProduction[locationId] = PracticeAnalyticsMockData.morningHuddleScheduledProduction(params);
      newPatients[locationId] = PracticeAnalyticsMockData.newPatients().month;
      opportunityList[locationId] = PracticeAnalyticsMockData.opportunityList();
      practitionerAnalysisSummary[locationId] = practitionerAnalysisSummaryData;
      practitionerAnalysisDetails[locationId] = PracticeAnalyticsMockData.practitionerAnalysisDetails(
        practitionerAnalysisSummaryData
      );
      recapturedPatients[locationId] = PracticeAnalyticsMockData.recapturedPatients().month;
      restorativeTreatmentPlan[locationId] = restorativeTreatmentData;
    });

    return {
      activePatients: {
        data: activePatients,
      },
      activePatientsDemographics: {
        data: activePatientsDemographics,
      },
      activePatientsDemographicsScheduled: {
        data: activePatientsDemographicsScheduled,
      },
      activePatientsScheduled: {
        data: activePatientsScheduled,
      },
      cancellations: {
        data: cancellations,
      },
      hygieneFollowUp: {
        data: hygieneFollowUp,
      },
      hygieneTreatmentPlan: {
        data: hygieneTreatmentPlan,
      },
      monthlyTrendHistoricalData: {
        data: monthlyTrendHistoricalData,
      },
      morningHuddleLastDayProduction: {
        data: morningHuddleLastDayProduction,
      },
      morningHuddleProductionMTD: {
        data: morningHuddleProductionMTD,
      },
      morningHuddleScheduledProduction: {
        data: morningHuddleScheduledProduction,
      },
      newPatients: {
        data: newPatients,
      },
      opportunityList: {
        data: opportunityList,
      },
      practitionerAnalysisDetails: {
        data: practitionerAnalysisDetails,
      },
      practitionerAnalysisSummary: {
        data: practitionerAnalysisSummary,
      },
      recapturedPatients: {
        data: recapturedPatients,
      },
      restorativeTreatmentPlan: {
        data: restorativeTreatmentPlan,
      },
    };
  }, [isDemoAccount, locationIds.sort().join('-')]);

  return demoData;
};
