import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { PlusIcon, PrimaryButton, useModalControl } from '@frontend/design-system';
import { CreatePaymentRequestModal } from '../../PaymentRequests';

const styles = {
  createButton: css`
    width: auto;
    padding-left: ${theme.spacing(1)};
  `,
  plusIcon: css`
    margin-right: ${theme.spacing(1)};
  `,
};

export const NewInvoiceAction = () => {
  const { t } = useTranslation('payments');
  const { modalProps: createModalProps, triggerProps: createModalTriggerProps } = useModalControl();

  return (
    <>
      <PrimaryButton size='large' css={styles.createButton} {...createModalTriggerProps}>
        <PlusIcon css={styles.plusIcon} />
        {t('New Invoice')}
      </PrimaryButton>
      <CreatePaymentRequestModal {...createModalProps} />
    </>
  );
};
