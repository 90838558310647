import { useEffect, useState } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { useQueryClient } from 'react-query';
import { IntakeFormQueries } from '@frontend/api-intake-form';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Heading, Text, useAlert } from '@frontend/design-system';
import SuccessImage from '../assets/large-flowers.svg';
import ErrorImage from '../assets/unsuccessful.jpg';
import { LoadingTruck } from '../components';
import { useDebugMode } from '../hooks';
import { useSelectIntakeFormSections, useSelectIntakeForm } from '../selectors/intake-form.selectors';
import { SubmitForm } from './submit-form';

enum FormCompletePageState {
  INITIAL,
  LOADING,
  ERROR,
  SUCCESS,
}

export const FormCompletePage = () => {
  const { t } = useTranslation('onboarding');
  const navigate = useNavigate();
  const alert = useAlert();
  const { getFormPath } = useSelectIntakeForm();
  const { activeSection } = useSelectIntakeFormSections();
  const { isDebugMode } = useDebugMode();
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const selectedLocationId = selectedLocationIdsWithParents?.[0] ?? '';
  const client = useQueryClient();
  const intakeFormCompleteMutation = IntakeFormQueries.mutateIntakeFormComplete({
    isDebugMode,
    onSuccess: () => {
      setPageState(FormCompletePageState.SUCCESS);
      // after 5sec, redirect to root route to continue with another incomplete intake-form or redirect to /portal
      setTimeout(() => {
        if (!isDebugMode) {
          // Invalidate onboarding locations query to refetch the locations with updated formCompletedAt field
          // as we just completed the form for one of the location
          // NOTE: Due to useOnboardingRedirect, this will auto redirect user to next incomplete location if any or to /portal
          client.invalidateQueries(IntakeFormQueries.queryKeys.onboardingLocations(selectedLocationId));
        }
      }, 5000);
    },
    onError: () => {
      setPageState(FormCompletePageState.ERROR);
      alert.error(t('Error submitting form data'));
    },
  });
  const [pageState, setPageState] = useState<FormCompletePageState>(FormCompletePageState.INITIAL);

  useEffect(() => {
    if (activeSection) {
      // If the form still has incomplete sections, redirect to the dashboard.
      console.warn('Can not submit form until all sections are completed.');
      navigate({ to: getFormPath('dashboard') });
    }
  }, [activeSection]);

  const handleCompleteFormClick = async () => {
    setPageState(FormCompletePageState.LOADING);
    intakeFormCompleteMutation.mutate();
  };

  switch (pageState) {
    case FormCompletePageState.LOADING:
      return <LoadingTruck message={t('Please wait while your form is submitted...')} />;
    case FormCompletePageState.ERROR:
      return (
        <>
          <img alt='error' src={ErrorImage} width='100' />
          <Text textAlign='center'>
            {t(
              "Uh oh! Looks like we had an issue submitting your form. Try refreshing the page - if that doesn't work, reach out to your sales rep and we'll get you taken care of."
            )}
          </Text>
        </>
      );
    case FormCompletePageState.SUCCESS:
      return (
        <>
          <img alt='success' src={SuccessImage} css={{ margin: theme.spacing(8, 0, 5, 0) }} />
          <Heading level={2}>{t('Success!')}</Heading>
          <Text textAlign='center'>
            {t(
              'Your form is successfully completed! Your sales rep will schedule your next call with you to meet your Onboarding specialist.'
            )}
          </Text>
          <Text textAlign='center' color='light'>
            {t('You will be redirected in a few seconds...')}
          </Text>
        </>
      );
    case FormCompletePageState.INITIAL:
    default:
      return <SubmitForm onClick={handleCompleteFormClick} />;
  }
};
