import { css } from '@emotion/react';
import { Heading, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { FC } from 'react';
import { FeatureSignUpContentType } from './types';

export const FeatureSignUpDetailCard: FC<FeatureSignUpContentType['featureContent']> = ({
  title,
  subTitle,
  contentText,
  videoUrl,
  highlightedContent,
  customContent,
}) => {
  return (
    <section css={cardStyles}>
      <div>
        <Heading level={2}>{title}</Heading>
        <Heading level={2} css={{ color: theme.colors.primary70 }}>
          {subTitle}
        </Heading>
      </div>
      <iframe src={videoUrl} css={videoFrameStyles} />
      <div>
        <Text as='span' weight='bold'>
          {highlightedContent}{' '}
        </Text>
        <Text as='span'>{contentText}</Text>
      </div>
      {customContent}
    </section>
  );
};

const cardStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  borderRadius: theme.borderRadius.medium,
  backgroundColor: theme.colors.white,
  padding: theme.spacing(3, 2, 2, 2),
});

const videoFrameStyles = css({
  width: '100%',
  aspectRatio: '16 / 9',
  border: `2px solid ${theme.colors.white}`,
});
