import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL, isWeaveUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Alert } from '@frontend/design-system';

type Props = {
  isFreeTrialActive?: boolean;
  isLocationEligible?: boolean;
  isSubscriptionActive?: boolean;
};

export const ManageSubscriptionAccessWarning = ({
  isFreeTrialActive,
  isLocationEligible = true,
  isSubscriptionActive,
}: Props) => {
  const { t } = useTranslation('analytics');
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const canManageAddons = hasSchemaACL(selectedLocationIdsWithParents[0], Permission.MANAGE_ADD_ON_SUBSCRIPTIONS);

  if (isFreeTrialActive || isSubscriptionActive) {
    return (
      // subscription is considered to be active only if free trial is not active but customization flag is
      <Alert css={{ marginBottom: theme.spacing(4) }} type='success'>
        {isFreeTrialActive ? t('Free trial is active') : isSubscriptionActive && t('Subscription already active')}
      </Alert>
    );
  }

  if (canManageAddons && isLocationEligible && !isWeaveUser()) {
    return null;
  }

  return (
    <Alert css={{ marginBottom: theme.spacing(4) }} type='warning'>
      {!isLocationEligible
        ? t('This feature is not yet available for this practice.')
        : t(
            'This action is only allowed by an account admin. Please have an admin visit this page to manage the subscription.'
          )}
    </Alert>
  );
};
