import { QueryFunction, QueryFunctionContext, QueryKey } from 'react-query';
import { http } from '../use-fetch';

/**
 * Query function wrapper that switches out the locationId header for each locationId in the selectedLocationIds array before invoking `queryFn`.
 *
 * This wrapper will invoke the `queryFn` once for *each* locationId in the `selectedLocationIds` array and return the results as an *flattened array*.
 * This means there will be no way to tell which result is associated with which locationId.
 *
 * If you want the results in key-value pairs, consider using `aggregatedDataQueryByLocationFn` instead.
 *
 * @example
 *
 * useQuery({
 *  queryFn: aggregatedDataQueryFn(fetchData, ['locationId1', 'locationId2']),
 *  ...
 * })
 */
export const aggregatedDataQueryFn =
  <TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
    queryFn: QueryFunction<TQueryFnData, TQueryKey>,
    selectedLocationIds: string[]
  ) =>
  async (...args: [context: QueryFunctionContext<TQueryKey, any>]) => {
    const prevLocationId = http.getLocationIdHeader() ?? '';
    return Promise.all(
      selectedLocationIds.map((selectedLocationId) => {
        http.setLocationIdHeader(selectedLocationId);
        return queryFn?.(...args);
      })
    )
      .then((data) => {
        /**
         * Assume that if only one locationId was provided, then the queryFn only returns one result
         */
        if (selectedLocationIds.length === 1) {
          return data[0];
        }
        /**
         * Otherwise, flatten the array of results.
         * This means that results for all locationIds will be merged together into a single array and
         * we won't be able to tell which result is associated with which locationId
         */
        return data.flat().filter(Boolean);
      })
      .finally(() => {
        http.setLocationIdHeader(prevLocationId);
      });
  };

/**
 * Query function wrapper that switches out the locationId header for each locationId in the selectedLocationIds array before invoking `queryFn`.
 *
 * This wrapper will invoke the `queryFn` once for *each* locationId in the `selectedLocationIds` array and return the results in locationId-data key-value pairs.
 *
 * If you want the results as a flattened array, consider using `aggregatedDataQueryFn` instead.
 *
 * @example
 *
 * useQuery({
 *  queryFn: aggregatedDataQueryByLocationFn(fetchData, ['locationId1', 'locationId2']),
 *  ...
 * })
 */
export const aggregatedDataQueryByLocationFn =
  <TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
    queryFn: QueryFunction<TQueryFnData, TQueryKey>,
    selectedLocationIds: string[]
  ) =>
  async (...args: [context: QueryFunctionContext<TQueryKey, any>]) => {
    const prevLocationId = http.getLocationIdHeader() ?? '';
    const b = Promise.all(
      selectedLocationIds.map((selectedLocationId) => {
        http.setLocationIdHeader(selectedLocationId);
        return queryFn?.(...args);
      })
    )
      .then((data) => {
        return data.reduce((acc, res, i) => ({ ...acc, [selectedLocationIds[i]]: res }), {});
      })
      .finally(() => {
        http.setLocationIdHeader(prevLocationId);
      });

    return b;
  };
