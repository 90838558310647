import { useLocation, useNavigate } from '@tanstack/react-location';
import { Chart, XAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { CallIntelSubViewType, useCallIntelStore } from '../../../hooks';
import { trackingIds } from '../../../tracking-ids';
import { formatters } from '../../../utils';
import { CompareLocationsButton } from '../../compare-locations-button';
import { DemoChip } from '../../demo-chip';
import { CallIntelInfoTipId, CallIntelInfoTips } from '../call-intel-info-tips';

type OpportunitiesByTypeChartProps = {
  focusLabel: string;
  infoTipId?: CallIntelInfoTipId;
  isDemoAccount?: boolean;
  isLoading?: boolean;
  onCompareLocationsClick?: (() => void) | null;
  subViewType: CallIntelSubViewType;
  title: string;
  types?: Record<string, number>;
};

export const OpportunitiesByTypeChart = ({
  focusLabel,
  infoTipId,
  isDemoAccount,
  isLoading,
  onCompareLocationsClick,
  subViewType,
  types = {},
  title,
}: OpportunitiesByTypeChartProps) => {
  const navigate = useNavigate();
  const { current } = useLocation();
  const { t } = useTranslation('analytics');
  const { dataColors, dataLabels, filters, filterHintText, setFiltersToRestore, setSubView } = useCallIntelStore();

  const dataKey = subViewType === 'appointment-type' ? 'appointmentTypes' : 'categories';
  const colors = dataColors[dataKey] || {};
  const labels = dataLabels[dataKey] || {};

  const chartDataGroups = Object.entries(types).map(([name, value]) => ({
    name,
    values: {
      [name]: value,
    },
  }));

  return (
    <Chart colors={colors} isLoading={isLoading} labels={{ ...labels, type: focusLabel }}>
      <Chart.Header
        bottomElement={
          <>
            <Text color='subdued' size='medium'>
              {t('Click each bar to learn more.')}
            </Text>
            {onCompareLocationsClick && (
              <CompareLocationsButton
                onClick={onCompareLocationsClick}
                trackingId={
                  dataKey === 'appointmentTypes'
                    ? trackingIds.callIntel.compareLocationsAppointments
                    : trackingIds.callIntel.compareLocationsCategories
                }
              />
            )}
          </>
        }
        infoTip={infoTipId ? <CallIntelInfoTips tip={infoTipId} /> : null}
        leftElement={isDemoAccount ? <DemoChip /> : null}
        subtitle={filterHintText}
        title={title}
      />
      <Chart.BarChart
        appearance={{
          customXAxisTick: ({ labels, groupName, ...rest }) => {
            const key = groupName as keyof typeof colors;
            return (
              <XAxisLabelValueTick
                {...rest}
                color={colors[key]}
                label={labels?.[groupName] || groupName}
                value={formatters.value.format(types[key])}
              />
            );
          },
          margin: { bottom: 20 },
          showXAxis: true,
        }}
        data={{
          groups: chartDataGroups,
        }}
        formatValue={formatters.value.format}
        onClick={({ group }) => {
          setFiltersToRestore(filters);
          setSubView({
            id: group,
            type: subViewType,
          });
          navigate({
            search: {
              type: subViewType,
            },
            to: `${current.pathname}/${group.toLowerCase()}`,
          });
        }}
      />
    </Chart>
  );
};
