import { QueryFunctionContext, useInfiniteQuery, useQuery } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { LimitedSchemaInfiniteQueryOptions, LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '../../service';
import { GetSubmissionsListIO, GetFormPDFAuthedIO } from '../../types';
import { defaultOptions } from '../config';
import { requestKeys } from '../keys';

export interface SubmissionListRequestParams {
  page?: number;
  limit?: number;
  solicited?: boolean;
  submitted?: boolean;
  submitted_from?: number;
  submitted_till?: number;
  submission_category?: 'completed' | 'retry_sync' | 'needs_review' | 'sync_manually' | 'reviewed';
  search?: string;
  order?: 'desc' | 'asc';
  sortBy?: 'submitted_at' | 'created_at' | 'archived_at';
  is_archive?: boolean;
  provider_email?: string;
  review_status?: 'pending' | 'approved' | 'rejected' | 'reviewed';
  company_ids?: string[];
}

interface SubmissionListResponse {
  rows: GetSubmissionsListIO['output']['data'];
  meta: GetSubmissionsListIO['output']['meta'];
}

export const useGetListOfSubmissions = <E = unknown, T = SubmissionListResponse>(
  req: Compulsory<GetSubmissionsListIO['input'], 'companyIds'>,
  params: SubmissionListRequestParams,
  options?: LimitedSchemaInfiniteQueryOptions<GetSubmissionsListIO, E, T>
) => {
  const queryKey = requestKeys.submissionsList(req);
  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }: QueryFunctionContext<string | readonly unknown[], SubmissionListRequestParams>) => {
      const response = await SchemaDigitalForms.GetSubmissions(req, {
        params: {
          ...params,
          page: pageParam?.page,
        },
      });

      return {
        rows: response.data,
        meta: response.meta,
      };
    },
    getNextPageParam: ({ meta }) => {
      if (!meta?.nextPage) {
        return undefined;
      }
      return { page: meta.nextPage };
    },
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useGetFormPDFAuthed = <E = unknown, T = GetFormPDFAuthedIO['output']>(
  req: GetFormPDFAuthedIO['input'],
  options?: LimitedSchemaQueryOptions<GetFormPDFAuthedIO, E, T>
) => {
  const queryKey = requestKeys.formPDFAuthed(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetFormPDFAuthed(req),
    staleTime: Infinity,
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};
