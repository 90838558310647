import { HistoryQueries } from '@frontend/api-messaging';
import { useEffect, useState } from 'react';
import { RecordDiff } from './record-diff';
import { ListsIcon, PreviewIcon, SpinningLoader, Table } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import dayjs from 'dayjs';
import { Order } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/history/v1/service.pb';
import { compareRecordStyles, currentRecordStyles } from './record-history-styles';

type Props = {
  recordId: string;
};

export const OrderDetails = ({ recordId }: Props) => {
  const { t } = useTranslation('messages');

  const [currentRecord, setCurrentRecord] = useState<Order>({});
  const [compareRecord, setCompareRecord] = useState<Order>({});

  const { data: orderData = [], isLoading, isFetching } = HistoryQueries.useOrderHistory(recordId);

  useEffect(() => {
    if (orderData.length) {
      setCurrentRecord(orderData?.filter((item) => item.isCurrent)[0].order || {});
    }
  }, [orderData]);

  if (isLoading || isFetching) {
    return <SpinningLoader />;
  }

  return (
    <>
      <Table
        colConfig={[
          {
            Header: t('Action'),
            id: 'action',
            width: 30,
            accessor: (data) => data?.action,
            disableSortBy: true,
          },
          {
            Header: t('Event At'),
            id: 'eventAt',
            width: 80,
            accessor: (data) => (data?.order?.eventAt ? dayjs(data?.order?.eventAt).format('YYYY/MM/DD, h:mm A') : ''),
            disableSortBy: true,
          },
          {
            Header: t('Modified At'),
            id: 'modifiedAt',
            width: 80,
            accessor: (data) =>
              data?.order?.modifiedAt ? dayjs(data?.order?.modifiedAt).format('YYYY/MM/DD, h:mm A') : '',
            disableSortBy: true,
          },
          {
            Header: t('Status'),
            id: 'status',
            width: 80,
            accessor: (data) => data?.order?.status,
            disableSortBy: true,
          },
          {
            Header: t('Type'),
            id: 'type',
            width: 60,
            accessor: (data) =>
              data?.order?.type && Array.isArray(data?.order?.type) ? data?.order?.type.join(' ') : data?.order?.type,
            disableSortBy: true,
          },
          {
            Header: t('Person Id'),
            id: 'personId',
            width: 80,
            accessor: (data) => data?.order?.personId,
            disableSortBy: true,
          },
        ]}
        data={orderData}
        isLoading={isLoading || isFetching}
        collapsedTableConfig={
          (orderData?.length ?? 0) > 4
            ? {
                rowsToShow: 4,
                collapseLabel: t('Show Less...'),
                expandLabel: t('Show More...'),
                startingValue: false,
              }
            : undefined
        }
        rowActions={{
          rowActionMenuLabel: '',
          actions: [
            {
              label: t('Show Details'),
              Icon: ListsIcon,
              onClick: (item) => {
                setCurrentRecord(item.order || {});
              },
            },
            {
              label: t('Compare'),
              Icon: PreviewIcon,
              onClick: (item) => {
                setCompareRecord(item.order || {});
              },
            },
          ],
        }}
        styleConfig={{
          rows: (rowData) => {
            if (rowData.order?.modifiedAt === currentRecord.modifiedAt) {
              return currentRecordStyles;
            }
            if (rowData.order?.modifiedAt === compareRecord.modifiedAt) {
              return compareRecordStyles;
            }
            return;
          },
        }}
      />
      <RecordDiff
        currentObj={currentRecord}
        compareObj={compareRecord}
        currentDate={dayjs(currentRecord.modifiedAt).format('YYYY/MM/DD, h:mm A')}
        compareDate={dayjs(compareRecord.modifiedAt).format('YYYY/MM/DD, h:mm A')}
      />
    </>
  );
};
