import { useEffect } from 'react';
import { MessagesTypes } from '@frontend/api-messaging';
import { FormFieldActionTypes, NumberField, useForm } from '@frontend/design-system';
import { SwitchedFilter } from './switched-filter';
import { BulkPrefixes } from '@frontend/tracking-prefixes';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

interface Props {
  labelMin: string;
  labelMax: string;
  filters: MessagesTypes.Filters;
  setFilters: (filters: MessagesTypes.Filters) => void;
}

export const AgeFilter = ({ labelMin, labelMax, filters, setFilters }: Props) => {
  const updateMinFilter = (value?: string) => {
    if (value) {
      setFilters({ ...filters, minAge: value });
    } else {
      const copy = { ...filters };
      delete copy.minAge;
      setFilters(copy);
    }
  };

  const updateMaxFilter = (value?: string) => {
    if (value) {
      setFilters({ ...filters, maxAge: value });
    } else {
      const copy = { ...filters };
      delete copy.maxAge;
      setFilters(copy);
    }
  };

  const minTrackingId = `${labelMin.toLowerCase().split(' ').join('-')}-filter-input`;
  const maxTrackingId = `${labelMin.toLowerCase().split(' ').join('-')}-filter-input`;

  // TODO: Add validation so the minimum is always less than the maximum
  const { getFieldProps, seedValues } = useForm({
    fields: {
      minFilter: {
        type: 'number',
        max: 99,
        min: 0,
      },
      maxFilter: {
        type: 'number',
        max: 99,
        min: 0,
      },
      enabled: {
        type: 'switch',
      },
    },
    fieldStateReducer: (state, action) => {
      if (
        action.type === FormFieldActionTypes.Update &&
        (action.payload.name === 'minFilter' || action.payload.name === 'maxFilter')
      ) {
        // enable toggle when selecting at least one
        const minValue = state.minFilter.value as string;
        const maxValue = state.maxFilter.value as string;
        if ((minValue || maxValue) && !state.enabled.value) {
          return {
            ...state,
            enabled: {
              ...state.enabled,
              value: true,
            },
          };
        }

        // disable toggle when unselecting
        if (!minValue && !maxValue && state.enabled.value) {
          return {
            ...state,
            enabled: {
              ...state.enabled,
              value: false,
            },
          };
        }
      }

      if (action.type === FormFieldActionTypes.Update && action.payload.name === 'enabled') {
        // removing selections when toggle is turned off
        if (!state.enabled.value) {
          return {
            ...state,
            enabled: {
              ...state.enabled,
              value: false,
            },
            minFilter: {
              ...state.minFilter,
              value: '',
            },
            maxFilter: {
              ...state.maxFilter,
              value: '',
            },
          };
        }
      }
      return state;
    },
  });

  useEffect(() => {
    seedValues({
      minFilter: (filters.minAge ?? '') as string,
      maxFilter: (filters.maxAge ?? '') as string,
      enabled: !!filters.minAge || !!filters.maxAge,
    });
  }, []);

  useEffect(() => {
    const minValue = getFieldProps('minFilter').value;
    const minFilterValue = minValue ? minValue : undefined;
    updateMinFilter(minFilterValue);
  }, [getFieldProps('minFilter').value]);

  useEffect(() => {
    const maxValue = getFieldProps('maxFilter').value;
    const maxFilterValue = maxValue ? maxValue : undefined;
    updateMaxFilter(maxFilterValue);
  }, [getFieldProps('maxFilter').value]);

  useEffect(() => {
    if (!getFieldProps('enabled').value) {
      updateMinFilter(undefined);
      updateMaxFilter(undefined);
    }
  }, [getFieldProps('enabled').value]);

  return (
    <SwitchedFilter getFieldProps={getFieldProps}>
      <div
        css={[
          css`
            display: grid;
            gap: ${theme.spacing(2)};
            grid-template-columns: auto auto;
          `,
        ]}
      >
        <NumberField
          {...getFieldProps('minFilter')}
          label={labelMin}
          data-testid={minTrackingId}
          data-trackingid={`${BulkPrefixes.Recipients}-${minTrackingId}`}
        />
        <NumberField
          {...getFieldProps('maxFilter')}
          label={labelMax}
          data-testid={maxTrackingId}
          data-trackingid={`${BulkPrefixes.Recipients}-${maxTrackingId}`}
        />
      </div>
    </SwitchedFilter>
  );
};
