import { SchemaIO, SchemaSMSService } from '@frontend/schema';
import { SchemaSMSSharedModels } from '../schema-types';
import { Optional } from 'ts-toolbelt/out/Object/Optional';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { InfiniteData, QueryKey, useQueryClient } from 'react-query';
import { InboxQueries } from '..';
import { Direction, Status } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { KnownThreadStatuses } from '../types';
import { useCallback, useMemo } from 'react';

type ThreadResponse = SchemaIO<(typeof SchemaSMSService)['GetThread']>['output'];
type BaseQueryContext = {
  threadId: string;
  locationId: string;
  taggedSmsId?: string;
};
type ShallowUpdateType = BaseQueryContext & {
  vals: Compulsory<Optional<SchemaSMSSharedModels.SMS>, 'id'>;
};
type UpsertType = Omit<BaseQueryContext, 'threadId' | 'locationId'> & {
  sms: SchemaSMSSharedModels.SMS;
};
type RemoveType = BaseQueryContext & {
  smsId: string;
  userId: string;
  permanent?: boolean;
};

export const useUpdateThread = () => {
  const queryClient = useQueryClient();

  const shallowUpdate = useCallback(
    ({ threadId, locationId, taggedSmsId, vals }: ShallowUpdateType, ignoreNullish = true) => {
      if (!threadId) return;
      const filteredNewVal: ShallowUpdateType['vals'] = ignoreNullish
        ? (Object.fromEntries(
            Object.entries(vals).filter(([key, value]) =>
              key !== 'locationId' && key !== 'id' ? value !== undefined && value !== null : true
            )
          ) as ShallowUpdateType['vals'])
        : vals;
      const queryKeys: QueryKey[] = [
        InboxQueries.queryKeys.getThread({
          locationId,
          threadId,
          taggedSmsId,
        }),
      ];
      if (taggedSmsId) {
        queryKeys.push(
          InboxQueries.queryKeys.getThread({
            locationId,
            threadId,
          })
        );
      }

      // Filter out queries that don't exist yet
      const queriesToUpdate = queryKeys.filter((key) => !!queryClient.getQueryState(key, { exact: true })?.data);

      queriesToUpdate.forEach((queryKey) => {
        queryClient.setQueryData<InfiniteData<ThreadResponse> | undefined>(queryKey, (oldData) => {
          if (!oldData) return undefined;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              thread: {
                ...page.thread,
                messages: page.thread.messages.map((message) =>
                  message.id === filteredNewVal.id ? { ...message, ...filteredNewVal } : message
                ),
              },
            })),
          };
        });
      });
    },
    [queryClient]
  );

  const upsert = useCallback(
    ({ taggedSmsId, sms }: UpsertType) => {
      if (taggedSmsId) {
        invalidateQuery({
          threadId: sms.threadId,
          locationId: sms.locationId,
          taggedSmsId,
        });
      }
      const queryKey = InboxQueries.queryKeys.getThread({
        locationId: sms.locationId,
        threadId: sms.threadId,
      });
      const queryExists = !!queryClient.getQueryState(queryKey, { exact: true })?.data;

      if (queryExists)
        queryClient.setQueryData<InfiniteData<ThreadResponse> | undefined>(queryKey, (oldData) => {
          if (!oldData) {
            return undefined;
          }

          const defaultNewThreadPage: ThreadResponse = {
            thread: {
              id: sms.threadId,
              locationId: sms.locationId,
              messages: [sms],
              person: {
                personId: sms.personId,
                firstName: '',
                lastName: '',
                preferredName: '',
              },
              status: convertSmsStatusToThreadStatus(sms.status, sms.direction),
              tagId: '',
              actionable: sms.actionable,
              departmentId: sms.departmentId,
              isBlocked: false,
              tagName: '',
              uniqueTags: [],
              isReplied: sms.direction === Direction.DIRECTION_OUTBOUND,
            },
          };

          const messageExists = oldData.pages.some((page) =>
            page.thread.messages.some((message) => message.id === sms.id)
          );
          if (messageExists) {
            const result = {
              ...oldData,
              pages: oldData.pages.map((page) => ({
                thread: {
                  ...page.thread,
                  messages: page.thread.messages.map((message) => (message.id === sms.id ? sms : message)),
                },
              })),
            };
            if (result.pages[0]?.thread.messages[0]?.direction === Direction.DIRECTION_OUTBOUND) {
              result.pages[0]!.thread.isReplied = true;
            }
            return result;
          }

          if (oldData.pages.length) {
            return {
              ...oldData,
              pages: oldData.pages.map((page, index) =>
                index === 0
                  ? {
                      ...page,
                      thread: {
                        ...page.thread,
                        messages: [sms, ...page.thread.messages],
                        isReplied: sms.direction === Direction.DIRECTION_OUTBOUND,
                      },
                    }
                  : page
              ),
            };
          }

          return {
            ...oldData,
            pages: [defaultNewThreadPage, ...oldData.pages],
          };
        });
      else
        invalidateQuery({
          threadId: sms.threadId,
          locationId: sms.locationId,
        });
    },
    [queryClient, convertSmsStatusToThreadStatus]
  );

  const remove = useCallback(
    ({ threadId, locationId, taggedSmsId, smsId, userId, permanent = false }: RemoveType) => {
      const queryKeys: QueryKey[] = [
        InboxQueries.queryKeys.getThread({
          locationId,
          threadId,
          taggedSmsId,
        }),
      ];

      if (taggedSmsId)
        queryKeys.push(
          InboxQueries.queryKeys.getThread({
            locationId,
            threadId,
          })
        );

      // Filter out queries that don't exist yet
      const queriesToUpdate = queryKeys.filter((key) => !!queryClient.getQueryState(key, { exact: true })?.data);

      queriesToUpdate.forEach((queryKey) => {
        queryClient.setQueryData<InfiniteData<ThreadResponse> | undefined>(queryKey, (oldData) => {
          if (!oldData) return undefined;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              thread: {
                ...page.thread,
                messages: permanent
                  ? page.thread.messages.filter((message) => message.id !== smsId)
                  : page.thread.messages.map((message) =>
                      message.id === smsId
                        ? {
                            ...message,
                            deletedAt: new Date().toISOString(),
                            deletedBy: userId,
                          }
                        : message
                    ),
              },
            })),
          };
        });
      });
    },
    [queryClient]
  );

  const invalidateQuery = useCallback(
    (context: BaseQueryContext) => {
      const queryKey = InboxQueries.queryKeys.getThread(context);
      queryClient.invalidateQueries(queryKey);
    },
    [queryClient]
  );

  return useMemo(
    () => ({ upsert, shallowUpdate, remove, invalidateQuery }),
    [upsert, shallowUpdate, remove, invalidateQuery]
  );
};

const convertSmsStatusToThreadStatus = (status: Status, direction: Direction): KnownThreadStatuses => {
  switch (status) {
    case Status.STATUS_NEW:
      return KnownThreadStatuses.NEW;
    case Status.STATUS_READ:
      return KnownThreadStatuses.READ;
    case Status.STATUS_ERROR:
      return KnownThreadStatuses.ERROR;
    default:
      return direction === Direction.DIRECTION_INBOUND ? KnownThreadStatuses.NEW : KnownThreadStatuses.READ;
  }
};
