import { useState } from 'react';
import { css } from '@emotion/react';
import {
  EditSimpleIcon,
  FlyoutMenuAction,
  IconButton,
  MoreIcon,
  NakedButton,
  PlusIcon,
  PopoverMenuItem,
  PopoverMenu,
  Tray,
  Text,
  TrashIcon,
  useModalControl,
  usePopoverMenu,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { RecipientModal } from './recipient-modal';
import { FilterModal } from './filter-modal';
import { MessagesTypes } from '@frontend/api-messaging';
import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/bulk/v1/service.pb.js';
import { BulkMessageButtonBar, BulkMessageModal, PersonModal } from '../../bulk-message-modal';
import { BulkPrefixes } from '@frontend/tracking-prefixes';
import { UploadModal } from './upload-modal';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import { MAX_BATCH_RECIPIENTS } from '../../utils';

type Props = {
  readOnly: boolean;
  quota?: number;
  selectedRecipients: Recipient[];
  setSelectedRecipients: (recipients: Recipient[]) => void;
  error?: string;
};

export type RecipientsMode = 'contacts' | 'filter' | 'upload';

export const Recipients = ({
  readOnly,
  quota = MAX_BATCH_RECIPIENTS,
  selectedRecipients,
  setSelectedRecipients,
  error,
}: Props) => {
  const { t } = useTranslation('messages');
  const { modalProps: trayProps, triggerProps: trayTriggerProps, ...trayControl } = useModalControl();
  const { getTriggerProps, getMenuProps, getItemProps, close } = usePopoverMenu({
    placement: 'left',
  });
  const { modalProps: clearModalProps, triggerProps: clearTriggerProps } = useModalControl();
  const { modalProps: recipientsModalProps, triggerProps: recipientsTriggerProps } = useModalControl();
  const [filtersToApply, setFiltersToApply] = useState<MessagesTypes.Filters>({});
  const [mode, setMode] = useState<RecipientsMode>('contacts');
  const [contactsToUpload, setContactsToUpload] = useState<Contact[]>([]);

  const actionsMenuProps = getMenuProps();
  const actionsTriggerProps = getTriggerProps({
    onClick: (e) => {
      e.stopPropagation();
    },
  });

  const openRecipientsModal = () => {
    if (readOnly) {
      recipientsTriggerProps.onClick();
    } else {
      trayControl.openModal();
    }
  };

  const closeTray = () => {
    setMode('contacts');
    trayControl.closeModal();
  };

  const closeClearModal = () => {
    setSelectedRecipients([]);
    clearModalProps.onClose();
  };

  const actions: FlyoutMenuAction[] = [
    {
      Icon: EditSimpleIcon,
      label: readOnly ? t('View List') : t('Edit List'),
      onClick: openRecipientsModal,
      trackingId: `${BulkPrefixes.Editor}-recipients-edit-list`,
    },
  ];

  if (!readOnly) {
    actions.push({
      Icon: TrashIcon,
      label: t('Clear List'),
      onClick: () => {
        clearTriggerProps.onClick();
      },
      trackingId: `${BulkPrefixes.Editor}-recipients-clear-list`,
    });
  }

  return (
    <>
      {selectedRecipients.length ? (
        <div
          onClick={openRecipientsModal}
          css={css`
            padding: ${theme.spacing(2)};
            border: 1px solid ${error ? theme.colors.critical50 : theme.colors.neutral30};
            border-radius: ${theme.borderRadius.small};
            cursor: pointer;
            :hover {
              border: 1px solid ${error ? theme.colors.critical70 : theme.colors.primary50};
            }
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            <div>
              <Text
                weight='semibold'
                color={error ? 'error' : 'default'}
                css={css`
                  margin-bottom: ${theme.spacing(1)};
                `}
              >
                {t('Recipients')}
              </Text>
              <Text
                css={css`
                  color: ${error ? theme.colors.critical50 : theme.colors.neutral40};
                `}
              >
                {t('Selected {{selected}}/{{quota}}', {
                  selected: selectedRecipients.length,
                  quota: quota > 0 ? quota : 0,
                })}
              </Text>
            </div>
            <div>
              <IconButton label='' {...actionsTriggerProps} trackingId={`${BulkPrefixes.Editor}-recipients-more-btn`}>
                <MoreIcon />
              </IconButton>
              <PopoverMenu {...actionsMenuProps}>
                {actions.map(({ Icon, label, onClick, trackingId, ...rest }: FlyoutMenuAction, index) => (
                  <PopoverMenuItem
                    {...rest}
                    Icon={Icon}
                    key={label}
                    data-trackingid={trackingId}
                    {...getItemProps({
                      index,
                      onClick: (e) => {
                        e.stopPropagation();
                        onClick();
                        close();
                      },
                    })}
                  >
                    {label}
                  </PopoverMenuItem>
                ))}
              </PopoverMenu>
            </div>
          </div>
        </div>
      ) : (
        <NakedButton
          onClick={openRecipientsModal}
          css={css`
            display: flex;
            align-items: center;
          `}
          data-testid='bulk-message-add-recipients-textlink'
          trackingId={`${BulkPrefixes.Editor}-add-recipients-btn`}
        >
          <PlusIcon
            color={error ? 'error' : 'default'}
            css={css`
              margin-right: ${theme.spacing(1)};
            `}
          />
          <Text color={error ? 'error' : 'default'}>{t('Add Recipients')}</Text>
        </NakedButton>
      )}
      {error ? (
        <Text
          color='error'
          size='small'
          css={css`
            margin-top: ${theme.spacing(1)};
          `}
        >
          {error}
        </Text>
      ) : null}
      {trayProps.show && (
        <Tray {...trayProps} width='large' onClose={closeTray}>
          {mode === 'contacts' && (
            <RecipientModal
              {...trayProps}
              filtersToApply={filtersToApply}
              selectedRecipients={selectedRecipients}
              setSelectedRecipients={setSelectedRecipients}
              setMode={setMode}
              contactsToUpload={contactsToUpload}
              setContactsToUpload={setContactsToUpload}
              quota={quota}
            />
          )}
          {mode === 'filter' && (
            <FilterModal filtersToApply={filtersToApply} setFiltersToApply={setFiltersToApply} setMode={setMode} />
          )}
          {mode === 'upload' && <UploadModal setMode={setMode} setContactsToUpload={setContactsToUpload} />}
        </Tray>
      )}
      <PersonModal {...recipientsModalProps} recipients={selectedRecipients} title={t('Recipients')} />
      <BulkMessageModal {...clearModalProps} onClose={clearModalProps.onClose} title={t('Delete Recipients')}>
        <Text
          css={css`
            text-align: left;
          `}
        >
          {t('Are you sure you want to clear your list?')}
        </Text>
        <BulkMessageModal.ButtonContainer>
          <BulkMessageButtonBar
            onAction={closeClearModal}
            onCancel={clearModalProps.onClose}
            cancelText={t('Cancel')}
            actionText={t('Clear')}
          />
        </BulkMessageModal.ButtonContainer>
      </BulkMessageModal>
    </>
  );
};
