import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { AnalyticsCommonTypes, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { LoadingSkeleton } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { LocationIcon, ModalControlModalProps, TableFilters, Text } from '@frontend/design-system';
import { DEFAULT_TIME_FILTER_PA } from '../../constants';
import { useAnalyticsOrgLocations, usePracticeAnalyticsStore } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { DateRange, findDatesRange, formatters, getMatchingDateRangeKey } from '../../utils';
import { filtersStyles } from '../../views/common-styles';
import {
  CheckListPopoverSelector,
  CustomPeriodGroups,
  LocationsSelector,
  TimePeriodPopoverSelector,
  TimePeriodSelector,
} from '../filter-selectors';
import { FiltersTray } from '../filters-tray';

type Props = {
  isLoadingData?: boolean;
  wrapperStyles?: CSSProperties;
} & (
  | {
      modalProps?: never;
      onlyChipFilters?: boolean;
      onlyTrayFilters?: never;
      setShowNotificationBadge?: never;
    }
  | {
      modalProps: ModalControlModalProps;
      onlyChipFilters?: never;
      onlyTrayFilters?: boolean;
      setShowNotificationBadge: (show: boolean) => void;
    }
);

const generateMonthsRange = (count: number, format: string) =>
  [...Array(count).keys()].reduce((acc, index) => {
    const key = dayjs().subtract(index, 'month').format(format);
    acc[key] = findDatesRange({ offset: index, range: 'month' });
    return acc;
  }, {} as Record<string, DateRange>);

const daysRangeMap = {
  yesterday: findDatesRange({ offset: 1, range: 'day' }),
  'last-7-days': findDatesRange({ offset: 7, range: 'day' }),
  'last-30-days': findDatesRange({ offset: 30, range: 'day' }),
};

const monthsRangeMap = generateMonthsRange(12, 'MMMM YYYY');

export const paDatesFilterMap = {
  ...daysRangeMap,
  ...monthsRangeMap,
};

const defaultDates = paDatesFilterMap[DEFAULT_TIME_FILTER_PA];

export const PracticeAnalyticsFilters = ({
  isLoadingData,
  modalProps,
  onlyChipFilters,
  onlyTrayFilters,
  setShowNotificationBadge,
  wrapperStyles,
}: Props) => {
  const { t } = useTranslation('analytics');
  const { isLoadingLocations, isMultiLocation, locationIds, locationNames } = useAnalyticsOrgLocations({
    module: 'PA',
  });

  const {
    filters,
    hasCustomFilters,
    locationIds: practiceAnalyticsLocationIds,
    resetStore,
    setDefaultFilters,
    setFilterHintText,
    setFilters,
    setLocationIds,
  } = usePracticeAnalyticsStore();

  const joinedLocationIds = locationIds.sort().join(',');
  const joinedPALocationIds = practiceAnalyticsLocationIds.sort().join(',');

  const [trayFilters, setTrayFilters] = useState<PracticeAnalyticsTypes.Filters>({});
  const [showFilteredBadge, setShowFilteredBadge] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const timeFilterLabels: Record<string, string> = {
    yesterday: t('Yesterday'),
    'last-7-days': t('Last 7 days'),
    'last-30-days': t('Last 30 days'),
  };

  const timePeriodGroups: CustomPeriodGroups = {
    datesMap: paDatesFilterMap,
    groups: [
      {
        period: Object.keys(daysRangeMap),
      },
      {
        label: t('Last 12 Months'),
        period: Object.keys(monthsRangeMap),
      },
    ],
    labels: timeFilterLabels,
  };

  const defaultFilters = useMemo(
    (): PracticeAnalyticsTypes.Filters => ({
      endDate: defaultDates.endDate,
      locations: locationIds || [],
      startDate: defaultDates.startDate,
    }),
    [joinedLocationIds]
  );

  const handleUpdateTrayFilters = (newFilters: Partial<PracticeAnalyticsTypes.Filters>) => {
    setTrayFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const applyDefaultFilters = () => {
    // Also used for resetting filters whenever required
    setDefaultFilters(defaultFilters);
    setErrors({});
    setFilters(defaultFilters);
  };

  const applyActiveFiltersToTrayState = () => {
    handleUpdateTrayFilters(filters);
  };

  const handleApplyTrayFilters = () => {
    // This method will be invoked when user clicks on apply button in the tray
    setFilters(trayFilters);
  };

  const handleUpdateErrors = (error: AnalyticsCommonTypes.filterSelectorError) => {
    setErrors((prevErrors) => ({ ...prevErrors, [error.name]: error.value }));
  };

  const hasErrors = useMemo(() => Object.values(errors).some((error) => error), [errors]);

  // We are not using directly hasCustomFilters to show filters badge as not all tabs will have the same set of applied filters
  const areRecordsFiltered = (): boolean => {
    const areDatesEqual =
      dayjs(filters.startDate).isSame(dayjs(defaultFilters.startDate), 'date') &&
      dayjs(filters.endDate).isSame(dayjs(defaultFilters.endDate), 'date');

    const areLocationsEqual = isEqual(filters.locations?.sort(), defaultFilters.locations?.sort());

    return !areDatesEqual || !areLocationsEqual;
  };

  const renderChipFilters = () => (
    <>
      {isMultiLocation && (
        <CheckListPopoverSelector<string>
          defaultValue={defaultFilters.locations}
          disabled={isLoadingData}
          Icon={LocationIcon}
          label={t('Location')}
          onChange={(locations) => {
            setFilters({ locations });
          }}
          options={locationNames}
          value={filters.locations}
          trackingId={trackingIds.practiceAnalytics.locationsFilter}
        />
      )}

      <TimePeriodPopoverSelector
        customDatesMap={paDatesFilterMap}
        customTimePeriods={Object.keys(paDatesFilterMap).reduce(
          (acc, key) => ({ ...acc, [key]: timeFilterLabels[key] || key.replaceAll('-', ' ') }),
          {}
        )}
        defaultPeriod={DEFAULT_TIME_FILTER_PA}
        endDate={filters.endDate}
        onChange={({ endDate, startDate }) => {
          setFilters({ endDate, startDate });
        }}
        startDate={filters.startDate}
        trackingId={trackingIds.practiceAnalytics.timeOverviewFilter}
      />
    </>
  );

  const renderTrayFilters = () => (
    <FiltersTray
      disableApply={hasErrors}
      disableTray={isLoadingData || isLoadingLocations}
      headerLabel={t('Filter Practice Analytics')}
      hideFiltersIcon={!!modalProps}
      modalProps={modalProps}
      onApplyFilters={handleApplyTrayFilters}
      onResetFilters={applyDefaultFilters}
      onRevertLocalChanges={applyActiveFiltersToTrayState}
      showFilteredBadge={showFilteredBadge}
      trackingId={trackingIds.practiceAnalytics.filtersOpen}
    >
      {isMultiLocation && (
        <TableFilters.Section sectionHeaderLabel={t('Location')}>
          <div css={filtersStyles.traySection}>
            <LocationsSelector
              disabled={isLoadingLocations}
              locations={locationNames}
              onChange={(value) => handleUpdateTrayFilters({ locations: value })}
              onError={handleUpdateErrors}
              trackingId={trackingIds.practiceAnalytics.locationsSelector}
              value={trayFilters.locations}
            />
          </div>
        </TableFilters.Section>
      )}

      <TableFilters.Section sectionHeaderLabel={t('Time')}>
        <div css={filtersStyles.traySection}>
          <TimePeriodSelector
            customPeriodGroups={timePeriodGroups}
            defaultPeriod={DEFAULT_TIME_FILTER_PA}
            endDate={trayFilters.endDate}
            onChange={({ endDate, startDate }) => {
              handleUpdateTrayFilters({ endDate, startDate });
            }}
            startDate={trayFilters.startDate}
          />
        </div>
      </TableFilters.Section>
    </FiltersTray>
  );

  useEffect(() => {
    // Update local state when main filters has changed
    applyActiveFiltersToTrayState();

    const timeFilter = getMatchingDateRangeKey({
      datesMap: paDatesFilterMap,
      endDate: filters.endDate,
      startDate: filters.startDate,
    });

    // This text is displayed in sub view pages and charts to indicate the currently applied filters
    setFilterHintText(
      timeFilterLabels[timeFilter || ''] ||
        `${formatters.date.format(filters.startDate || '')} - ${formatters.date.format(filters.endDate || '')}`
    );
  }, [filters]);

  useEffect(() => {
    // Avoid resetting when custom filters are already applied (user has navigated from sub page to the main page)
    if (!hasCustomFilters) {
      applyDefaultFilters();
    }
  }, [defaultFilters]);

  useEffect(() => {
    setShowNotificationBadge?.(showFilteredBadge);
  }, [showFilteredBadge]);

  useEffect(() => {
    setShowFilteredBadge(areRecordsFiltered());
  }, [defaultFilters, filters]);

  useEffect(() => {
    if (!practiceAnalyticsLocationIds.length) {
      setLocationIds(locationIds);
    } else if (joinedLocationIds !== joinedPALocationIds) {
      //  Reset when location is switched
      resetStore();
      setLocationIds(locationIds);
      applyDefaultFilters();
    }
  }, [joinedLocationIds, joinedPALocationIds]);

  return onlyChipFilters ? (
    renderChipFilters()
  ) : onlyTrayFilters ? (
    renderTrayFilters()
  ) : (
    <div css={filtersStyles.mainWrapper} style={wrapperStyles}>
      {renderChipFilters()}
      {renderTrayFilters()}
      {isLoadingLocations && (
        <LoadingSkeleton css={styles.loadingSkeleton}>
          <Text color='subdued'>{t('Loading Locations...')}</Text>
        </LoadingSkeleton>
      )}
    </div>
  );
};

const styles = {
  loadingSkeleton: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
  `,
};
