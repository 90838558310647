import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { PrimaryButton } from '@frontend/design-system';

const buttonStyles = css`
  max-width: 190px;
`;

export const FormsEnabledView = () => {
  const { locationId } = useLocationDataShallowStore('locationId');
  const { t } = useTranslation('forms');

  const openFormsDashboard = () => {
    window.open(`https://forms.getweave.com/forms?companyID=${locationId}`, '_blank');
  };

  return (
    <section>
      <PrimaryButton css={buttonStyles} onClick={openFormsDashboard}>
        {t('go to dashboard')}
      </PrimaryButton>
    </section>
  );
};
