import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';
import { theme } from '@frontend/theme';
import { CHAT_AREA_WIDTH } from '../../constants';
import { useTeamChatStore } from '../../providers';
import { ConversationBody } from './conversation-body';
import { ConversationTitle } from './conversation-title';

export const Conversation = () => {
  const { activeConversation } = useTeamChatStore(['activeConversation']);

  return (
    <AnimatePresence>
      <motion.section
        css={styles.container}
        initial={{ width: activeConversation ? CHAT_AREA_WIDTH : 0 }}
        animate={{ width: activeConversation ? CHAT_AREA_WIDTH : 0 }}
        exit={{ width: 0 }}
        transition={{ duration: 0.3 }}
        style={{ overflow: 'hidden' }}
      >
        {activeConversation && (
          <>
            <ConversationTitle />
            <ConversationBody />
          </>
        )}
      </motion.section>
    </AnimatePresence>
  );
};

const styles = {
  container: css`
    border-left: 1px solid ${theme.colors.neutral10};
    display: flex;
    flex-direction: column;
    width: ${CHAT_AREA_WIDTH}px;
    position: relative;
    height: 100%;
  `,
};
