import { FC } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Heading } from '@frontend/design-system';

interface BannerTextContentProps {
  title?: string;
  subTitle?: string;
  imageUrl: string;
  imageAltText: string;
}
export const BannerTextContent: FC<BannerTextContentProps> = ({ title, subTitle, imageUrl, imageAltText }) => {
  return (
    <div css={[columnCenterStyles, bannerLayoutStyles]}>
      <div css={[columnCenterStyles]}>
        {!!title && <Heading level={2}>{title}</Heading>}
        {!!subTitle && <Heading level={1}>{subTitle}</Heading>}
      </div>
      <img src={imageUrl} width={364} height={364} css={{ objectFit: 'contain' }} alt={imageAltText} />
    </div>
  );
};

const columnCenterStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const bannerLayoutStyles = css({
  gap: theme.spacing(3),
  paddingTop: theme.spacing(2),
});
