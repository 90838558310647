import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { StreamConversation, StreamUser } from '../../../types';
import { ChatAvatar } from '../../common';
import { isStreamUser } from './new-conversation';

export const DropdownMenuItem = ({ item }: { item: StreamConversation | StreamUser }) => {
  return (
    <div css={containerStyle}>
      {isStreamUser(item) ? (
        <>
          <ChatAvatar members={[item]} />
          <Text>
            {item.firstName} {item.lastName}
          </Text>
        </>
      ) : (
        <Text># {item.name}</Text>
      )}
    </div>
  );
};

const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
});
