import { useEffect, useMemo, useState } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Text,
  Tray,
  Accordion,
  NotificationBadge,
  ChecklistField,
  useDebouncedValue,
  useFormField,
  SwitchField,
} from '@frontend/design-system';
import { useAppointmentsCalendarViewProps } from '../../../../../context/AppointmentsCalendarViewContext';
import { useAppointmentFilterDefaultDataShallowStore } from '../../../../../stores/use-appointments-filter-store';
import { useFilteredAppointmentListInfoShallowStore } from '../../../../../stores/use-filtered-appointment-list-store';
import { useProvidersListShallowStore } from '../../../../../stores/use-filtered-provider-list-store';
import { hasSameIds } from '../../../../../utils';
import { HeaderBarFiltersTrackingIds } from '../trackingIds';
import { AppointmentTypesCheckList } from './AppointmentTypesCheckList';
import { LocationSelector } from './LocationSelector';
import { ProviderCheckList, Clear, ProviderSearchField, SelectAll } from './ProviderCheckList';

type CalendarFilterProps = {
  closeModal: () => void;
};

export const CalendarFilter = ({ closeModal }: CalendarFilterProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');

  const { selectedLocationIds } = useAppScopeStore();

  const { setFilteredAppointmentList: setAppointments } =
    useFilteredAppointmentListInfoShallowStore('setFilteredAppointmentList');

  const { setSelectedProvidersList: updateSelectedProvidersList, resetSelectedProvidersList } =
    useProvidersListShallowStore('setSelectedProvidersList', 'resetSelectedProvidersList');

  const [isFilterStateChanged, setIsFilterStateChanged] = useState<boolean>(false);

  const {
    selectedLocationIds: calendarLocationIds,
    providersList,
    appointments,
    setSelectedMultiLocationIds,
    defaultFilteredLocationIds,
  } = useAppointmentsCalendarViewProps();

  const [locationIds, setLocationIds] = useState<string[]>(calendarLocationIds);

  const {
    setHasActiveFilters,
    setDefaultProviders,
    defaultProviders,
    defaultAppointmentTypes,
    selectedProviders,
    setSelectedProviders,
    selectedProviderIds,
    setSelectedProviderIds,
    setSelectedAppointmentTypesIds,
    selectedAppointmentTypesIds,
    isUnconfirmedOnly,
    setIsUnconfirmedOnly,
    resetFiltersData,
  } = useAppointmentFilterDefaultDataShallowStore(
    'setDefaultProviders',
    'defaultProviders',
    'defaultAppointmentTypes',
    'selectedProviders',
    'setSelectedProviders',
    'setHasActiveFilters',
    'hasActiveFilters',
    'selectedProviderIds',
    'setSelectedProviderIds',
    'setSelectedAppointmentTypesIds',
    'selectedAppointmentTypesIds',
    'isUnconfirmedOnly',
    'setIsUnconfirmedOnly',
    'resetFiltersData'
  );

  const confirmationStatusField = useFormField(
    {
      type: 'switch',
      value: isUnconfirmedOnly,
    },
    [isUnconfirmedOnly]
  );

  const filteredProvidersByLocation = useMemo(() => {
    return (
      defaultProviders?.filter((provider) => {
        return locationIds.includes(provider.locationId ?? '');
      }) ?? []
    );
  }, [defaultProviders, locationIds]);

  const getSelectedProviderIds = (): string[] => {
    const filteredProviderIds = filteredProvidersByLocation.map(({ id }) => id ?? '');

    const selectedProviderIds = !!selectedProviders?.length
      ? selectedProviders.filter(({ id }) => id && filteredProviderIds.includes(id))?.map(({ id }) => id ?? '')
      : filteredProviderIds;

    return selectedProviderIds;
  };

  const providerCheckListField = useFormField(
    {
      type: 'checklist',
      value: getSelectedProviderIds(),
    },
    [locationIds, selectedProviders, filteredProvidersByLocation]
  );

  const filteredAppointmentTypesByLocationIds = useMemo(() => {
    return defaultAppointmentTypes.filter((appointmentType) => {
      return locationIds.includes(appointmentType.locationId ?? '');
    });
  }, [locationIds, defaultAppointmentTypes]);

  //TODO - Appointment type id doesn't exist in the appointment data. We'll have to match by type name.
  const getSelectedAppointmentTypesName = () => {
    const appointmentTypesNames =
      filteredAppointmentTypesByLocationIds
        .filter(({ name }) => selectedAppointmentTypesIds.includes(name))
        .map(({ name }) => name || '') ?? [];

    return appointmentTypesNames;
  };

  const appointmentTypesListField = useFormField(
    {
      type: 'checklist',
      value: getSelectedAppointmentTypesName(),
    },
    [selectedAppointmentTypesIds, filteredAppointmentTypesByLocationIds]
  );

  const providerSearchField = useFormField({ type: 'text' });
  const debouncedSearchTerm = useDebouncedValue(providerSearchField.value);

  const searchProviders = useMemo(() => {
    return filteredProvidersByLocation.filter(({ publicDisplayName, resourceName, firstName, lastName }) => {
      // Account for the possibility of the provider name being set to publicDisplayName, or resourceName, or firstName + lastName in the use-get-providers-details-for-calendar.ts hook.
      const name = publicDisplayName || resourceName || `${firstName} ${lastName}`.trim();
      const nameMatch = name.toLowerCase().replaceAll(/[.,*()]/g, '');
      const search = debouncedSearchTerm.toLowerCase().replaceAll(/[.,*()]/g, '');
      return nameMatch?.includes(search);
    });
  }, [debouncedSearchTerm, filteredProvidersByLocation]);

  const handleSelectAll = () => {
    providerCheckListField.onChange({
      value: [...searchProviders.map(({ id }) => id), ...providerCheckListField.value],
      name: 'provider filter',
    });
  };

  const handleClear = () => {
    providerCheckListField.onChange({ value: [], name: 'provider filter' });
  };

  const applyFilters = () => {
    const { appointments: calendarViewAppointments } = appointments;

    setSelectedMultiLocationIds(locationIds);

    const providersToUpdate =
      defaultProviders?.filter((provider) => providerCheckListField.value.includes(provider.id ?? '')) || [];

    if (providerCheckListField.value.length) {
      updateSelectedProvidersList(providersToUpdate);
      setSelectedProviders(providersToUpdate);
      setSelectedProviderIds(providersToUpdate.map(({ id }) => id ?? ''));
    }

    setSelectedAppointmentTypesIds(appointmentTypesListField.value ?? []);
    setIsUnconfirmedOnly(confirmationStatusField.value ?? false);

    const appointmentTypeAndStatus = calendarViewAppointments
      ?.filter((appointment) => {
        return !!appointmentTypesListField.value?.length
          ? appointmentTypesListField.value.includes(appointment.type ?? '')
          : appointment;
      })
      .filter((appointment) => {
        return !!confirmationStatusField.value
          ? appointment.statusOfficeView?.toUpperCase() === 'UNCONFIRMED'
          : appointment;
      });

    const filters =
      !!appointmentTypesListField.value?.length || !!confirmationStatusField.value
        ? appointmentTypeAndStatus
        : calendarViewAppointments;

    const appointmentArray: Appointment[] = [];

    filters?.forEach((appointment) => {
      const list = !!providersToUpdate.length ? providersToUpdate : providersList;
      list.forEach((provider) => {
        if (
          (appointment.practitionerName === provider.publicDisplayName ||
            appointment.practitionerName === `${provider.firstName} ${provider.lastName}`) &&
          appointment.locationId === provider.locationId
        ) {
          appointmentArray.push(appointment);
        }
      });
    });
    setAppointments(appointmentArray || []);
    setHasActiveFilters(true);
    closeModal();
  };

  const resetFilters = () => {
    setHasActiveFilters(false);
    setAppointments([]);
    resetSelectedProvidersList();
    resetFiltersData();
    setSelectedMultiLocationIds(defaultFilteredLocationIds);
    handleSelectAll();
    closeModal();
  };

  // useEffect to set the default providers as global default providers
  // when the calendarLocationIds and defaultFilteredLocationIds are the same which helps in filtering
  useEffect(() => {
    if (hasSameIds(calendarLocationIds, defaultFilteredLocationIds) && providersList) {
      setDefaultProviders(providersList);
    }
  }, [calendarLocationIds, defaultFilteredLocationIds, providersList]);

  // useEffect to check whether the filter is applied or not
  useEffect(() => {
    const hasSameProviderIds = hasSameIds(providerCheckListField.value, selectedProviderIds);
    const hasSameLocationIds = hasSameIds(locationIds, calendarLocationIds);
    const hasSameAppointmentTypesIds = hasSameIds(appointmentTypesListField.value ?? [], selectedAppointmentTypesIds);
    const hasConfirmationStatusChanged = confirmationStatusField.value !== isUnconfirmedOnly;
    const isFilterApplied =
      hasConfirmationStatusChanged || !hasSameAppointmentTypesIds || !hasSameLocationIds || !hasSameProviderIds;

    if (isFilterApplied) {
      setIsFilterStateChanged(true);
    } else {
      setIsFilterStateChanged(false);
    }
  }, [
    confirmationStatusField.value,
    locationIds,
    calendarLocationIds,
    isUnconfirmedOnly,
    selectedAppointmentTypesIds,
    selectedProviderIds,
    providerCheckListField.value,
    appointmentTypesListField.value,
  ]);

  const applyFiltersButtonTrackingId = () => {
    const locationFiltersSelected = !hasSameIds(locationIds, calendarLocationIds) ? '-locations' : '';
    const providerFiltersSelected =
      providerCheckListField.value.length !== filteredProvidersByLocation.length ? '-providers' : '';
    const appointmentTypeFiltersSelected = !!appointmentTypesListField.value?.length ? '-appointmentTypes' : '';
    const confirmationStatusFilterSelected = confirmationStatusField.value ? '-unconfirmedOnly' : '';

    return `${HeaderBarFiltersTrackingIds.applyFiltersButton}${locationFiltersSelected}${providerFiltersSelected}${appointmentTypeFiltersSelected}${confirmationStatusFilterSelected}`;
  };

  const hasOnlyOneLocation = selectedLocationIds.length === 1;

  return (
    <>
      {!hasOnlyOneLocation && (
        <Accordion size='large' showBoxShadow chevronSize={16}>
          <Accordion.Item value={'locations'} css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <Accordion.Header css={{ border: `1px solid ${theme.colors.neutral10}` }}>
              <Text size='medium' weight='bold' textAlign='left'>
                {t('Location')}
              </Text>
              <div css={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                <NotificationBadge css={{ backgroundColor: theme.colors.primary50, marginRight: theme.spacing(0.5) }}>
                  {locationIds.length}
                </NotificationBadge>
                <Text>{t('of {{count}}', { count: defaultFilteredLocationIds.length })}</Text>
              </div>
            </Accordion.Header>
            <Accordion.Body css={{ border: `1px solid ${theme.colors.neutral10}`, width: '100%' }}>
              <Text size='small' color='subdued' css={{ marginBottom: theme.spacing(2) }}>
                {t('View up to 4 locations at once')}
              </Text>
              <LocationSelector locationIds={locationIds} updateLocationIds={setLocationIds} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}

      <Accordion size='large' showBoxShadow chevronSize={16}>
        <Accordion.Item value={'provider list'} css={{ border: `1px solid ${theme.colors.neutral10}` }}>
          <Accordion.Header css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <Text size='medium' weight='bold'>
              {t('Provider')}
            </Text>
            <div css={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <NotificationBadge css={{ backgroundColor: theme.colors.primary50, marginRight: theme.spacing(0.5) }}>
                {providerCheckListField.value.length}
              </NotificationBadge>
              <Text>of {filteredProvidersByLocation.length}</Text>
            </div>
          </Accordion.Header>
          <Accordion.Body css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <ProviderSearchField searchFieldProps={providerSearchField} />
            <SelectAll
              isDisabled={providerCheckListField.value.length === filteredProvidersByLocation.length}
              trackingId={HeaderBarFiltersTrackingIds.selectAllProvidersButton}
              handleSelectAll={handleSelectAll}
            />
            <Clear trackingId={HeaderBarFiltersTrackingIds.clearAllProvidersButton} handleClear={handleClear} />
            <ProviderCheckList
              searchProviders={searchProviders}
              checkListProps={providerCheckListField}
              locations={locationIds}
              hasOnlyOneLocation={hasOnlyOneLocation}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion shouldRenderBody size='large' showBoxShadow chevronSize={16}>
        <Accordion.Item value={'appointment types'} css={{ border: `1px solid ${theme.colors.neutral10}` }}>
          <Accordion.Header css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <Text size='medium' weight='bold' textAlign='left'>
              {t('Appointment Types')}
            </Text>
            <div css={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <NotificationBadge css={{ backgroundColor: theme.colors.primary50, marginRight: theme.spacing(0.5) }}>
                {appointmentTypesListField.value?.length}
              </NotificationBadge>
              <Text>{t('of {{count}}', { count: filteredAppointmentTypesByLocationIds.length })}</Text>
            </div>
          </Accordion.Header>
          <Accordion.Body css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <ChecklistField
              {...appointmentTypesListField}
              name='appointmentTypes'
              labelPlacement='right'
              label={''}
              css={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}
            >
              <AppointmentTypesCheckList types={filteredAppointmentTypesByLocationIds} />
            </ChecklistField>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion shouldRenderBody size='large' showBoxShadow chevronSize={16}>
        <Accordion.Item value={'appointment status'} css={{ border: `1px solid ${theme.colors.neutral10}` }}>
          <Accordion.Header css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <Text size='medium' weight='bold' textAlign='left'>
              {t('Confirmation Status')}
            </Text>
            {confirmationStatusField.value && (
              <div
                css={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: theme.colors.primary50,
                  borderRadius: theme.borderRadius.full,
                  width: theme.spacing(3),
                  height: theme.spacing(3),
                }}
              >
                <Icon name='check-small' size={16} color='white' />
              </div>
            )}
          </Accordion.Header>
          <Accordion.Body css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <SwitchField
              {...confirmationStatusField}
              name='appointmentStatus'
              labelPlacement='left'
              label={t('Unconfirmed Only')}
              css={{ height: theme.spacing(5) }}
              trackingId={HeaderBarFiltersTrackingIds.unconfirmedOnlyAppointmentsSwitch}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* //TODO - We can uncomment this code when we can filter appointments by insurance status */}
      {/* <Accordion size='large' showBoxShadow chevronSize={16}>
            <Accordion.Item value={'insurance status'} css={{ border: `1px solid ${theme.colors.neutral10}` }}>
              <Accordion.Header css={{ border: `1px solid ${theme.colors.neutral10}` }}>
                <Text size='medium' weight='bold' textAlign='left'>
                  {t('Insurance Verification Status')}
                </Text>
              </Accordion.Header>
              <Accordion.Body css={{ border: `1px solid ${theme.colors.neutral10}` }}>
                <ChecklistField
                  {...getFieldProps('insuranceStatus')}
                  name='insuranceStatus'
                  labelPlacement='right'
                  label={''}
                  css={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}
                >
                  <InsuranceStatusCheckList />
                </ChecklistField>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}

      <Tray.Actions
        onPrimaryClick={applyFilters}
        disabledPrimary={!isFilterStateChanged}
        primaryTrackingId={applyFiltersButtonTrackingId()}
        primaryLabel={t('Apply Filters')}
        onSecondaryClick={resetFilters}
        secondaryLabel={t('Reset')}
        secondaryTrackingId={HeaderBarFiltersTrackingIds.resetFiltersButton}
      />
    </>
  );
};
