import { getLocationPaymentMethods, getPersonPaymentMethods } from '@frontend/api-payment-plans';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';

/**
 * @deprecated use PaymentPlanQueries from @frontend/api-payment-plans
 */
export const useGetPatientPaymentMethods = (patientId?: string) => {
  const { paymentsUrl } = useMerchant();
  const { locationId } = useMultiQueryUtils();

  const {
    isLoading,
    error: paymentMethodsError,
    data,
    refetch,
  } = useLocalizedQuery({
    queryKey: ['payment-plan-billing-methods', patientId],
    enabled: !!paymentsUrl && !!patientId,
    queryFn: () => getPersonPaymentMethods(paymentsUrl, patientId || '', locationId),
  });

  const { data: setupLinkData, isFetched: setupLinkFetched } = useLocalizedQuery({
    queryKey: ['payments-location-setup'],
    enabled: !!paymentsUrl,
    queryFn: () => getLocationPaymentMethods(paymentsUrl, locationId),
  });
  const setupLink = setupLinkData?.links?.setups;

  return {
    paymentMethods: data,
    setupLink,
    isLoading,
    setupLinkFetched: setupLinkFetched && setupLink,
    paymentMethodsError,
    refetch,
  };
};
