import { DeviceInclude_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';

export const queryKeys = {
  base: ['phone'] as const,
  deviceById: (id: string) => [...queryKeys.base, 'deviceById', id],
  listDevices: (include: DeviceInclude_Enum[] | undefined) => [...queryKeys.base, 'devices', 'list', include],
  deviceAddressById: (id: string) => [...queryKeys.base, 'device', 'e911-address', id],
  validateDestroyLine: (id: string) => [...queryKeys.base, 'validate-destroy-line', id],
  listDeviceExtensionCallGroups: (id: string) => [...queryKeys.base, id, 'device', 'extension', 'call-groups'],
  deviceByExtensionId: (id: string) => [...queryKeys.base, 'device', 'extension', id],
};
